import React from "react";


const RoundColorComponent = (props) => {
  const { color, onClick, value,index } = props;
 
  return (
    <div style={{display:"flex",flexDirection:"column"}}>
      {index!=0?( <div
      onClick={onClick}
      className={
       value
          ? "round-color-selected-container "
          : "round-color-container"
      }
      style={{ background: color.colorCode,}}
    >
     
    </div>):(<div
      onClick={onClick}
      className={
       value
          ? "round-color-selected-container x"
          : "round-color-container x"
      }
      style={{ background: color.colorCode,}}
    >
     
    </div>)}
      
    <span className="round-color-selected-container-text" >
    {color.colorName}    </span> 
    </div>
  );
};

export default RoundColorComponent;

