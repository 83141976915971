/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Layout,
    Typography,
    Input,
    Image,
    Button,
    Card,
    Space,
} from "antd";
import "../styles/landing.css";
import Carousel, { consts } from "react-elastic-carousel";
import HomeHeader from "../components/Header";
import { useBreakpoints } from './../react-responsive-hooks/index';
import ReNewedFooter from "../pages/ReNewedFooter";
import RenewedFooterMob from '../pages/RenewedFooterMob';
import "../App.css";
import StarIcon from '../assets/StarIcon.jpg';
import Badge$25 from '../assets/Badge$25.jpg';
import RenewedStrollers from '../assets/RenewedStrollers.jpg';
import RenewedBassinets from '../assets/RenewedBassinets.jpg';
import RenewedBouncers from '../assets/RenewedBouncers.jpg';
import RenewedCribs from '../assets/RenewedCribs.jpg';
import RenewedHighChair from '../assets/RenewedHighchair.jpg';
import RenewedActivityTravel from '../assets/RenewedActivityTravel.jpg';
import Brand4moms from '../assets/Brand4moms.jpg';
import BrandAuna from '../assets/BrandAuna.jpg';
import BrandBabybjorn from '../assets/BrandBabybjorn.jpg';
import BrandBabyzen from '../assets/BrandBabyzen.jpg';
import BrandBouabo from '../assets/BrandBouabo.jpg';
import BrandDoona from '../assets/BrandDoona.jpg';
import BrandHappiestbaby from '../assets/BrandHappiestbaby.jpg';
import BrandMinicity from '../assets/BrandMinicity.jpg';
import BrandStokke from '../assets/BrandStokke.jpg';
import BrandUppa from '../assets/BrandUppa.jpg';
import EasyTimeSaving from '../assets/EasyTime.jpg';
import SubmitItem from '../assets/SubmitItem.jpg';
import ExpertSupport from '../assets/ExpertSupport.jpg';
import SchedulePickup from '../assets/ScheduledPickup.jpg';
import LetRest from '../assets/DoRest.jpg';
import GetPaid from '../assets/GetPaid.jpg';
import FasterSales from '../assets/FasterSales.jpg';
import HigherEarning from '../assets/HigherEarning.jpg';
import SustainableChoice from '../assets/SubstainableChoice.jpg';
import BgShape from '../assets/BgShape.jpg';
import BannerImage from '../assets/BannerImage.jpg';
import {Helmet} from "react-helmet";
const { Text } = Typography;

const FeaturedBrand = [
    {image: Brand4moms}, {image: BrandAuna}, {image: BrandBabybjorn},{ image: BrandBabyzen}, {image: BrandBouabo},
     {image: BrandDoona}, {image: BrandHappiestbaby}, {image: BrandMinicity}, {image: BrandStokke}, {image: BrandUppa}
];

const ReNewedPage = () => {
    const { isXS } = useBreakpoints();

    return (
        <>

            <Helmet>
                <title>Markid - reNEWED</title>
            </Helmet>
            <HomeHeader />
            <Row justify="center" gutter={[16, 52]}>
                <Col span={24}/>
                        <Col>
                        <Col xs={{span:22, offset:1}} sm={{span:22, offset:1}} md={24} lg={24} xl={24} xxl={24}>
                            <Row justify={isXS && "center"}>
                                <Text className="selling-kidsgear">Selling kids’ gear, made easy</Text>
                            </Row>
                        </Col>
                        </Col>
            </Row>
            <Row justify="center" gutter={[16, {xs:25,sm:25,md:42,lg:42,xl:42,xxl:42}]}>
                <Col span={24}></Col>
                <Col>
                <Row justify={isXS && "center"}>
                    <Text className="renewed-details">
                        Our experts do the selling
                        <Image src={StarIcon} style={{ padding: "0px 10px" }} preview={false} />
                        You get paid
                        <Image src={StarIcon} style={{ padding: "0px 10px" }} preview={false} />
                        It’s that simple
                    </Text>
                    </Row>
                </Col>
            </Row>

            <Row gutter={[{xs:0,sm:10,md:16,lg:16,xl:16,xxl:16}, {xs:30,sm:30,md:30,lg:30,xl:30,xxl:30}]}>
                <Col span={24}></Col>
                <Col xl={7} lg={7} md={7} sm={0} xs={0}></Col>
                <Col xl={7} lg={7} md={7} sm={15} xs={15}>
                    <Row justify={isXS && "center"}>
                        <Col xs={21} sm={21} md={24} lg={24} xl={24} xxl={24}>
                        <Input placeholder="Enter your zip code" className="renewed-input" />
                        </Col>
                    </Row>
                </Col>
                <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                    <Row justify={isXS && "center"}>
                        <Button className="startSelling-button">start selling</Button>
                    </Row>
                </Col>
            </Row>

            <Row gutter={[16, 3]}>
                <Col span={24}/>
                <Col xl={7} lg={7} md={7} sm={0} xs={0}></Col>
                <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                    <Row> 
                        <Col 
                        xs={{span:24 ,offset: 1}}
                        sm={{span:24 ,offset: 1}}
                        md={{span:24 ,offset: 0}}
                        lg={{span:24 ,offset: 0}}
                        xl={{span:24 ,offset: 0}}
                        xxl={{span:24 ,offset: 0}}
                        >
                        <Text className="currently-in-nyc">Currently in NYC</Text>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row gutter={[0,{xs:30, sm:30, md:37, lg:37, xl:37, xxl:37}]} justify="center">
                <Col span={24}/>
                <Image src={BannerImage} preview={false} />
            </Row>

            <Row gutter={[48, 0]} justify='space-around'>
                <Col xl={1} lg={1} md={1} sm={0} xs={0}></Col>
                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                    <Row gutter={[16, 0]}>
                        <Row justify={isXS && "center"} gutter={[0, {xs:70, sm:70, md:120, lg:120, xl:120, xxl:120}]}>
                            <Col span={24}/>
                            <Col xl={20} lg={20} md={20} sm={20} xs={20}>
                                <Text className="details1">Markid renewed is our white-glove consignment service. </Text>
                            </Col>
                        </Row>        
                        <Row justify={isXS && "center"} gutter={[0, {xs:15, sm:15, md:25, lg:25, xl:25, xxl:25}]}>
                            <Col span={24}/>
                            <Col xl={20} lg={20} md={20} sm={20} xs={20}>
                                <Text className="details2">
                                    Our team does everything from pick-up to delivery and everything in between, so you can turn to what matters most.
                                </Text>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col xl={12} lg={12} md={12} sm={22} xs={22}>
                    <Row gutter={[{xs:20, sm:30, md:0, lg:0, xl:0, xxl:0}, {xs:0, sm:0, md:120, lg:120, xl:120, xxl:120}]}>
                        <Col span={24}/>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Row gutter={[0, {xs:20, sm:20, md:0, lg:0, xl:0, xxl:0}]}>
                                <Col span={24}/>
                                <Col>
                                    <Image src={SubmitItem} preview={false} />
                                </Col>
                            </Row>
                            <Row>
                                    <Text className="pickupdetail">1. Submit your item</Text>
                            </Row>
                            <Row gutter={[0, {xs:15, sm:15, md:6, lg:6, xl:6, xxl:6}]}>
                                <Col span={24}/>
                                <Col xl={16} lg={16} md={16} sm={24} xs={24} >
                                    <Text className="pickupdetail2">
                                        Submit a description of your
                                        item (We accept select items
                                        that meet our criteria for
                                        gently used, high-quality,
                                        high-demand kids’ gear).</Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Row gutter={[0, {xs:20, sm:20, md:0, lg:0, xl:0, xxl:0}]}>
                                <Col span={24}/>
                                <Image src={SchedulePickup} preview={false} />
                            </Row>
                            <Row>
                                <Text className="pickupdetail">2. Schedule pickup</Text>
                            </Row>
                            <Row gutter={[0, {xs:15, sm:15, md:6, lg:6, xl:6, xxl:6}]}>
                            <Col span={24}/>
                                <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                                    <Text className="pickupdetail2">
                                        We’ll handle the heavy lifting,
                                        just pick a convenient time and
                                        meet us at the door. We’ll pick
                                        up your item and deliver it to
                                        the buyer at no charge.</Text>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                    <Row gutter={[{xs:30, sm:30, md:0, lg:0, xl:0, xxl:0},{xs:10, sm:10, md:20, lg:20, xl:20, xxl:20}]}>
                        <Col span={24}/>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Row gutter={[0, {xs:20, sm:20, md:0, lg:0, xl:0, xxl:0}]}>
                                <Col span={24}/>
                                <Image src={LetRest} preview={false} />
                            </Row>
                            <Row>
                                <Text className="pickupdetail">3. Let us do the rest</Text>
                            </Row>
                            <Row gutter={[0, {xs:15, sm:15, md:6, lg:6, xl:6, xxl:6}]}>
                            <Col span={24}/>
                                <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                                    <Text className="pickupdetail2">
                                        Our team of experts will
                                        professionally inspect,
                                        clean, sanitize, and restore
                                        the gear to bring it to
                                        a lovingly renewed state. </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Row gutter={[0, {xs:20, sm:20, md:0, lg:0, xl:0, xxl:0}]}>
                                <Col span={24}/>
                                <Image src={GetPaid} preview={false} />
                            </Row>
                            <Row>
                                <Text className="pickupdetail">4. Get paid</Text>
                            </Row>
                            <Row gutter={[0, {xs:15, sm:15, md:6, lg:6, xl:6, xxl:6}]}>
                            <Col span={24}/>
                                <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                                    <Text className="pickupdetail2">
                                        Sit back and relax. We
                                        photograph, list, promote and
                                        sell your item so that you can get
                                        the best price for it.  When your
                                        item sells,  we pay you based on
                                        the final sale price, up to 80% .</Text>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row gutter={[0,30]} >
                        <Col span={24}/>
                        <Col span={isXS && 24}>
                            <Button className="startSelling-button arrange-button"
                             href="https://markid.typeform.com/to/Wj73oCrA?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx"
                             target='_blank'
                            >
                                 <span className='startButtontext'>Arrange a free pick up</span></Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row gutter={[0,{xs:70, sm:70, md:120, lg:120, xl:120, xxl:120}]} justify='center'>
                <Col span={24}/>
                <Col span={24}>
                  <Row gutter={[48,{xs:40, sm:40, md:90, lg:90, xl:90, xxl:90}]} style={{background: '#FFF8EF', padding: "0px 0px" }} justify='center' align="middle">
                    <Col span={24}/>
                    <Col xl={1} lg={1} md={1} sm={0} xs={0}>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                        <Row justify={isXS && "center"}>
                            <Image className="badgeIcon" src={Badge$25} preview={false} />
                        </Row>
                    </Col>
                     <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                            <Row justify={isXS ? "center" :"start"} align="bottom">
                                <Col xl={22} lg={22} md={22} sm={24} xs={20}>
                                    <Row>
                                        <Col span={24}/>
                                        <Text className="additional-details">Get an additional $25 credit for<br></br>
                                            each item sold with markid renewed</Text>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[0,{xs:20, sm:20, md:18, lg:18, xl:18, xxl:18}]} justify={isXS ? "center" :"start"} align="bottom">
                                <Col span={24}/>
                                <Col xl={24} lg={24} md={24} sm={24} xs={20}>
                                    <Text className="additional-details2">
                                        Don’t miss out - credits stack up fast when you consign multiple items with us.
                                        Once your item sells — you’ll receive your earnings from the sale plus
                                        a $25 credit to use towards your next purchase on markid.
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={0} xs={0}>
                        </Col>
                        <Col span={24}/>
                    </Row>
                </Col>
            </Row>

            <Row style={{ backgroundColor: '#E9F8F8'}}>
                <Col span={24}>
                    <Row justify="center" gutter={[0,{xs:40, sm:40, md:127, lg:127, xl:127, xxl:127}]} >
                        <Col span={24}/>
                        <Text className="why-choose-us">Why choose us?</Text>
                    </Row>
                </Col>
                <Row gutter={[{xs:10, sm:10, md:80, lg:80, xl:80, xxl:80},{xs:20, sm:20, md:52, lg:52, xl:52, xxl:52}]} 
                justify="center" style={{ zIndex: 999999 }} >
                    <Col span={24}/>
                    <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                    <Col xl={6} lg={6} md={12} sm={20} xs={20} className="chooseParent">
                        <Row justify={isXS && "center"}>
                        <Card bordered={false} className="choose-card">
                            <Image src={EasyTimeSaving} style={{ height: 'fit-content' }} preview={false} />
                            <Row gutter={[0,20]}>
                                <Col span={24}/>
                                <Text className="why-choose-us-detail">Easy & time-saving</Text>
                            </Row>
                            <Row gutter={[0,4]}>
                            <Col span={24}/>
                                <Text className="why-choose-us-detail2">Once you submit your item.We’ll take it from there, literally!</Text>
                            </Row>
                        </Card>
                        </Row>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={20} xs={20} className="chooseParent">
                    <Row justify={isXS && "center"}>
                        <Card bordered={false} className="choose-card">
                            <Image src={ExpertSupport} style={{ height: 100 }} preview={false} />
                            <Row gutter={[0,20]}>
                                <Col span={24}/>
                                <Text className="why-choose-us-detail">Expert support</Text>
                            </Row>
                            <Row gutter={[0,4]}>
                            <Col span={24}/>
                                <Text className="why-choose-us-detail2">Our team of experts take care
                                    of the details. From pick-up to
                                    cleaning, restoring & creating
                                    a stellar listing.</Text>
                            </Row>
                        </Card>
                        </Row>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={20} xs={20} className="chooseParent">
                    <Row justify={isXS && "center"}>
                        <Card bordered={false} className="choose-card">
                            <Image src={FasterSales} style={{ height: 100 }} preview={false} />
                            <Row gutter={[0,20]}>
                                <Col span={24}/>
                                <Text className="why-choose-us-detail">Faster sales</Text>
                            </Row>
                            <Row gutter={[0,4]}>
                            <Col span={24}/>
                                <Text className="why-choose-us-detail2">Reach more buyers with our
                                    multi-platform promotion.</Text>
                            </Row>
                        </Card>
                        </Row>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                    {/* <Col xl={24} lg={24} md={24} sm={0} xs={0} style={{ padding: 30 }}></Col> */}
                    <Col xl={4} lg={4} md={4} sm={0} xs={0}></Col>
                    <Col xl={6} lg={6} md={12} sm={20} xs={20} className="chooseParent">
                    <Row justify={isXS && "center"}>
                        <Card bordered={false} className="choose-card">
                            <Row>
                                <Image src={HigherEarning} style={{ height: 100 }} preview={false} />
                            </Row>
                            <Row  gutter={[0,20]}>
                                <Col span={24}/>
                                <Text className="why-choose-us-detail">Higher earning</Text>
                            </Row>
                            <Row gutter={[0,4]}>
                            <Col span={24}/>
                                <Text className="why-choose-us-detail2">Our optimized pricing is
                                    based on customer demand
                                    and resale pricing algorithms.
                                    You can earn up to 80% of
                                    the sale price for your items.</Text>
                            </Row>
                        </Card>
                        </Row>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={20} xs={20} className="chooseParent">
                    <Row justify={isXS && "center"}>
                        <Card bordered={false} className="choose-card">
                            <Row>
                                <Image src={SustainableChoice} style={{ height: 100 }} preview={false} />
                            </Row>
                            <Row gutter={[0,20]}>
                                <Col span={24}/>
                                <Text className="why-choose-us-detail">Sustainable choice</Text>
                            </Row>
                            <Row gutter={[0,4]}>
                            <Col span={24}/>
                                <Text className="why-choose-us-detail2">
                                    Each used kids’ item that gets a
                                    new life with another family,
                                    extends its lifecycle and reduces
                                    it’s carbon footprint by x%.
                                </Text>
                            </Row>
                        </Card>
                        </Row>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={0} xs={0}></Col>
                </Row>

            </Row>

            <Row justify="space-around">
                <Image src={BgShape} style={{ marginTop: isXS ? 0 :-150 }} preview={false} />
            </Row>

            <Row gutter={[0,{xs:70, sm:70, md:120, lg:120, xl:120, xxl:120}]} justify="center">
                <Col span={24}/>
                <Text className="why-choose-us">What we accept</Text>
            </Row>

            <Row gutter={[{xs:10, sm:10, md:0, lg:0, xl:0, xxl:0},{xs:30, sm:30, md:68, lg:68, xl:68, xxl:68}]} justify="space-around" 
            style={{padding:isXS ? "0px 15px" : "0px 93px"}}>
                <Col span={24}/>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Row justify="center">
                        <Image src={RenewedStrollers} preview={false} />
                    </Row>
                    <Row gutter={[0,23]} justify="center">
                        <Col span={24}/>
                        <Text className="renewed-items">Strollers</Text>
                     </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Row justify="center">
                        <Image src={RenewedBassinets} preview={false} />
                    </Row>
                    <Row gutter={[0,23]} justify="center">
                        <Col span={24}/>
                        <Text className="renewed-items">Bassinets</Text>
                     </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                <Row justify="center">
                    <Image src={RenewedBouncers} preview={false} />
                    </Row>
                    <Row gutter={[0,23]} justify="center">
                        <Col span={24}/>
                        <Text className="renewed-items">Cribs</Text>
                     </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                <Row justify="center">
                    <Image src={RenewedCribs} preview={false} />
                    </Row>
                    <Row gutter={[0,23]} justify="center">
                        <Col span={24}/>
                        <Text className="renewed-items">Bouncers</Text>
                     </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                <Row justify="center">
                    <Image src={RenewedHighChair} preview={false} />
                    </Row>
                    <Row gutter={[0,23]} justify="center">
                        <Col span={24}/>
                        <Text className="renewed-items">High Chairs</Text>
                     </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                <Row justify="center">
                    <Image src={RenewedActivityTravel} preview={false} />
                    </Row>
                    <Row gutter={[0,23]} justify="center">
                        <Col span={24}/>
                        <Text className="renewed-items">Activity & Travel</Text>
                     </Row>
                </Col>
            </Row>

            <Row gutter={[0,{xs:70, sm:70, md:120, lg:120, xl:120, xxl:120}]} justify="center" align="middle">
                <Col span={24}/>
                {/* <Col xl={12} lg={4} md={4} sm={12} xs={12}> */}
                {/* <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col> */}
                <Col>
                    <Row justify="center">
                        <Text className="why-choose-us">Featured brands we accept</Text>
                    </Row>
                </Col>
                {/* <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Row justify="end">
                        <Button className="see-all-button">See all</Button>
                    </Row>
                </Col> */}
            </Row>

            <Row gutter={[{xs:10, sm:10, md:0, lg:0, xl:0, xxl:0},{xs:30, sm:30, md:68, lg:68, xl:68, xxl:68}]} 
            justify="space-around" 
                style={{padding: isXS ? "0px 15px" : "0px 93px"}}>
                <Col span={24}/>
                {isXS ? 
             <Col span={24}>
                <Carousel
                itemsToShow={2.5}
                itemsToScroll={3}
                pagination={false}
                // renderArrow={false}
                // renderPagination={false}
                showArrows={false}
              >{ FeaturedBrand.map((item, i) => {
                return (
                        <Col span={24} key={i}>
                            <Image className="featureRenewedimg" src={item.image} preview={false} />
                        </Col>  
                )
                })}
           </Carousel> 
           </Col>
           :
                FeaturedBrand && FeaturedBrand.map((item, i) => {
                     return  <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4} key={i}>
                        <Image src={item.image} preview={false} />
                    </Col>   
                })}
                {/* <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Image src={BrandUppa} preview={false} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Image src={BrandBouabo} preview={false} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Image src={BrandBabyzen} preview={false} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Image src={BrandDoona} preview={false} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Image src={BrandAuna} preview={false} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <Image src={BrandBabybjorn} preview={false} />
                </Col> */}
            </Row>

            {/* <Row justify="space-around" gutter={[{xs:10, sm:10, md:0, lg:0, xl:0, xxl:0}, {xs:30, sm:30, md:50, lg:50,xl:50, xxl:50}]}  
            style={{padding: isXS ? "0px 15px" : "0px 93px"}}>
                <Col span={24}/>
                <Col xl={4} lg={4} md={4} sm={0} xs={0}>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Image src={BrandStokke} preview={false} />
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Image src={BrandHappiestbaby} preview={false} />
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Image src={Brand4moms} preview={false} />
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Image src={BrandMinicity} preview={false} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                </Col>
            </Row> */}

            <Row gutter={[25,{xs:35, sm:35, md:80, lg:80, xl:80, xxl:80}]} justify='center'>
                <Col span={24}/>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Row gutter={[0, {xs:20, sm:20, md:0, lg:0, xl:0, xxl:0}]} justify="center">
                        <Col 
                            xs={{span :16, order:2}}
                            sm={{span :16, order:2}}
                            md={{span :10, order:1}}
                            lg={{span :9, order:1}}
                            xl={{span :7, order:1}}
                            xxl={{span:6, order:1}}>
                            <Button className="startSelling-button2">start selling</Button>
                        </Col>
                        <Col
                        xs={{span :16, order:1}}
                        sm={{span :16, order:2}}
                        md={{span :10, order:2}}
                        lg={{span :9, order:2}}
                        xl={{span :7, order:2}}
                        xxl={{span:6, order:2}}
                        >
                            <Button className="learnmore-button">learn More</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isXS ? <RenewedFooterMob/> : <ReNewedFooter />}
        </>
    );
};
export default ReNewedPage;
