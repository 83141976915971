/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getAllBrands } from "../Actions/brandsAction";
import {
  addToCart,
  getCurrentUserWallet,
  getLastCart,
} from "../Actions/cartAction";
import {
  getAllpartnerproducts,
  getFilterpartnerProducts,
} from "../Actions/partnerProductsAction";
import RibbonTop from "../components/promotionPopUp";
import { getAllSubSizes } from "../Actions/categoriesAction";
import ExposedCategories from "../components/ExposedCategories";
import { Row, Col, Typography, Card, Button, Tabs, Spin } from "antd";
import {
  setNavShow,
  setNavList,
  setNavColors,
  setCurrentNavSelected,
} from "../Actions/brandsAction";
// import ProductTop from "../components/ProductTop";
import { setLoginSignup } from "../Actions/authAction";
import { getYearOfDetails } from "../Actions/lotsAction";
import {
  RightOutlined,
  LoadingOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import ExploreDeal from "../assets/ExploreDeal.svg";
import EDOne from "../assets/EDOne.svg";
import EDTwo from "../assets/EDTwo.svg";
import EDThree from "../assets/EDThree.svg";
import CollectionImg from "../assets/CollectionImg.png";
import "../App.css";
import MainHomeBanner from "../components/MainHomeBanner";
import MainHomePromotionalBanner from "../components/MainHomePromotionalBanner";
import MHCategorySection from "../components/MHCategorySection";
import MRSelection from "../components/MRSelection";
import Partnerselection from "../components/Partnerselection";
import { FeaturedSeller } from "../components/FeaturedSeller";
import { SmallCard, MediumCard, LargeCard } from "../components/SmallCard";
import { ExploreDealCard } from "../components/ExploreDealCard";
import WhyMarkid from "../components/WhyMarkid";
import PrelovedKidsItem from "../components/PrelovedKidsItem";
import ShopBrand from "../components/ShopBrand";
import { StyleCard } from "../components/StyleCard";
import StyleImgOne from "../assets/StyleImgOne.svg";
import StyleImgTwo from "../assets/StyleImgTwo.svg";
import StyleImgThree from "../assets/StyleImgThree.svg";
import { CollectionCard } from "../components/CollectionCard";
import { HomeCommunityCard } from "../components/HomeCommunityCard";

import RecentlySoldProducts from "../components/RecentlySoldProducts";
import RecentlyAddedProducts from "../components/RecentlyAddedProducts";
import HomepageProductCardList from "../components/HomepageProductCardList";
import { TestimonialsCard } from "../components/TestimonialsCard";
import SellerOne from "../assets/SellerOne.svg";
import SellerTwo from "../assets/SellerTwo.svg";
import SellerThree from "../assets/SellerThree.svg";
import CMImgOne from "../assets/CMImgOne.svg";
import CMImgTwo from "../assets/CMImgTwo.svg";
import CMImgThree from "../assets/CMImgThree.svg";
import CMImgFour from "../assets/CMImgFour.svg";
import CMImgFive from "../assets/CMImgFive.svg";
import CollectionOne from "../assets/CollectionOne.svg";
import CollectionTwo from "../assets/CollectionTwo.svg";
import CollectionThree from "../assets/CollectionThree.svg";
import CommunityOne from "../assets/CommunityOne.svg";
import CommunityTwo from "../assets/CommunityTwo.svg";
import CommunityThree from "../assets/CommunityThree.svg";
import TesImg from "../assets/TesImg.svg";
import TsOne from "../assets/TsOne.svg";
import TsTwo from "../assets/TsTwo.svg";
import Carousel, { consts } from "react-elastic-carousel";
import MainHomePageFooter from "../components/MainHomePageFooter";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import ReactGA from "react-ga";
import { useGaEvent } from "../hooks/useGaEvent";
import {
  getCategoriesGroup,
  getFeaturedSellerGroup,
  getCuratedesGroup,
  getExploresGroup,
  getBrandsGroup,
  getStylesGroup,
  getCollectionsGroup,
  getCommunitiesGroup,
  getTestimonialsGroup,
} from "../Actions/groupAction";
import { setFilterCondition } from "../Actions/brandsAction";
import {
  getCategoryProducts,
  getRecentlyAddedProducts,
  getRecentlySoldProducts,
  getAllRecentlySold,
  getverifiedProducts,
} from "../Actions/categoriesAction";
import { getUser } from "../Actions/userAction";
import { Helmet } from "react-helmet";
import lang from "../Utils/metatags.json";
import ShopNewItems from "../components/ShopNewItems";
import ShopfromSwoondle from "../components/ShopfromSwoondle";
import MarkidTrust from "../components/MarkidTrust";
import ShopNationWideShipping from "../components/ShopNationWideShipping";
import ShopByDealss from "../components/ShopByDealss";
import WhyShopOnMarkid from "../components/WhyShopOnMarkid";
import MarkNewsLetter from "../components/MarkNewsLetter";
import { getBrandlisting } from "../Actions/brandsAction";
import { Signinmodal } from "../components/Signinmodal";
import Emailpopup from "../components/Emailpopup";


const { Title, Text } = Typography;
const { TabPane } = Tabs;

const MainHomePage = (props) => {
  const { categoryRef } = props;
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: "#FD533F" }} spin />
  );
  const [tabKey, setTabkey] = useState("1");
  const [category, setCategory] = useState("cribs_and_furniture");
  const { isXS } = useBreakpoints();
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const { providerToken, showSignIn } = useSelector((state) => {
    const { providerToken } = state.auth;
    return {
      providerToken,
    };
  });
  if (token) {
    localStorage.setItem("access_token", token);
    if (!providerToken) {
      dispatch({ type: "SET_PROVIDER_TOKEN", payload: { token } });
    }
  }
  useEffect(() => {
    dispatch(getUser());
  }, [token]);

  const { gaPageView, gaEventClick } = useGaEvent();

  useEffect(() => {
    dispatch({ type: "RESET_ORDER_STATUS" });
    dispatch({ type: "RESER_FILTER_PRODUCTS" });
    gaPageView();
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    localStorage.removeItem("Brands");
    localStorage.removeItem("Sizes");
    localStorage.removeItem("Selected-Brands");
    localStorage.removeItem("Selected-Genders");
    localStorage.removeItem("Selected-YearofPurchase");
    localStorage.removeItem("Selected-Condition");
    localStorage.removeItem("Selected-Shipping");
    localStorage.removeItem("Selected-Size");
    localStorage.removeItem("Selected-Max-Price");
    localStorage.removeItem("Active-Filter");
    localStorage.removeItem("search");
    localStorage.removeItem("Selected-Price-Range");
  }, []);

  useEffect(() => {
    if (location.hash === "#categories") {
      setTimeout(() => categoryRef.current.scrollIntoView());
      //  categoryRef.current.scrollIntoView();
    }
  }, []);
  const {
    categoriesGroup,
    featuredSellersGroup,
    curatedesGroup,
    exploresGroup,
    brandsGroup,
    stylesGroup,
    collectionsGroup,
    communitiesGroup,
    testimonialsGroup,
    isGetCategoriesGroupLoading,
    isGetfeaturedSellersGroupLoading,
    isGetCuratedesGroupLoading,
    isGetExploresGroupLoading,
    isGetBrandsGroupLoading,
    isGetStylesGroupLoading,
    isGetCollectionsGroupLoading,
    isGetCommunitiesGroupLoading,
    isGetTestimonialsGroupLoading,
    isJoinCommunityLoading,
    cribsList,
    strollersList,
    gearList,
    clothingList,
    shoesList,
    toysList,
    feedingList,
    diaperingList,
    bathingList,
    sportsList,
    booksList,
    safetyList,
    maternityList,
    electronicsList,
    recentlyAdded,
    recentlySold,
    brandlist,
    verifiedproducts,
    partnerproducts,
    recentlySoldAll,
    currentUser,
    isAuthenticated,
  } = useSelector((state) => {
    const {
      categoriesGroup,
      featuredSellersGroup,
      curatedesGroup,
      exploresGroup,
      brandsGroup,
      stylesGroup,
      collectionsGroup,
      communitiesGroup,
      testimonialsGroup,
      isGetCategoriesGroupLoading,
      isGetfeaturedSellersGroupLoading,
      isGetCuratedesGroupLoading,
      isGetExploresGroupLoading,
      isGetBrandsGroupLoading,
      isGetStylesGroupLoading,
      isGetCollectionsGroupLoading,
      isGetCommunitiesGroupLoading,
      isGetTestimonialsGroupLoading,
    } = state.groups;
    const { partnerproducts } = state.products;
    const { currentUser } = state.user;
    const {
      cribsList,
      strollersList,
      gearList,
      clothingList,
      shoesList,
      toysList,
      feedingList,
      diaperingList,
      bathingList,
      sportsList,
      booksList,
      safetyList,
      maternityList,
      electronicsList,
      verifiedproducts,
      recentlyAdded,
      recentlySold,
      recentlySoldAll,
    } = state.categories;
    const { brandlist } = state.brands;
    const { isAuthenticated } = state.auth;
    const { isJoinCommunityLoading } = state.user;
    return {
      categoriesGroup,
      brandlist,
      featuredSellersGroup,
      curatedesGroup,
      exploresGroup,
      brandsGroup,
      stylesGroup,
      verifiedproducts,
      collectionsGroup,
      communitiesGroup,
      testimonialsGroup,
      isGetCategoriesGroupLoading,
      isGetfeaturedSellersGroupLoading,
      isGetCuratedesGroupLoading,
      isGetExploresGroupLoading,
      isGetBrandsGroupLoading,
      isGetStylesGroupLoading,
      isGetCollectionsGroupLoading,
      isGetCommunitiesGroupLoading,
      isGetTestimonialsGroupLoading,
      isJoinCommunityLoading,
      cribsList,
      strollersList,
      gearList,
      clothingList,
      shoesList,
      toysList,
      feedingList,
      diaperingList,
      bathingList,
      sportsList,
      booksList,
      safetyList,
      maternityList,
      electronicsList,
      recentlyAdded,
      recentlySold,
      partnerproducts,
      recentlySoldAll,
      currentUser,
      isAuthenticated,
    };
  });
  let currentPage = useLocation().pathname.split("/");
  //   const groupsLoader = isGetCategoriesGroupLoading, isGetfeaturedSellersGroupLoading, isGetCuratedesGroupLoading,
  //   isGetExploresGroupLoading, isGetBrandsGroupLoading, isGetStylesGroupLoading, isGetCollectionsGroupLoading,
  //   isGetCommunitiesGroupLoading, isGetTestimonialsGroupLoading,
  const [verified, setverified] = useState({});
  const [cribs, setcribs] = useState({});
  const [strollers, setstrollers] = useState({});
  const [gears, setgears] = useState({});
  const [feeding, setfeeding] = useState({});
  const [toys, settoys] = useState({});
  const [maternity, setmaternity] = useState({});
  const [clothing, setclothing] = useState({});
  const [shoes, setshoes] = useState({});
  const [diapering, setdiapering] = useState({});
  const [bathing, setbathing] = useState({});
  const [sports, setsports] = useState({});
  const [books, setbooks] = useState({});
  const [safety, setsafety] = useState({});
  const [electronics, setelectronics] = useState({});
  const { group: featuredGroup, groupType: featuredSellerGroupType } =
    featuredSellersGroup;
  const { group: curatedGroup, groupType: curatedGroupType } = curatedesGroup;
  const { group: exploresGroupDetail, groupType: exploresGroupType } =
    exploresGroup;
  const { group: brandsGroupDetail, groupType: brandsGroupType } = brandsGroup;
  const { group: stylesGroupDetail, groupType: stylesGroupType } = stylesGroup;
  const { group: collectionsGroupDetail, groupType: collectionsGroupType } =
    collectionsGroup;
  const { group: communitiesGroupDetail, groupType: communitiesGroupType } =
    communitiesGroup;
  const { group: testimonialsGroupDetail, groupType: testimonialsGroupType } =
    testimonialsGroup;

  const { groupName: featuredGroupName } = featuredGroup;
  const { groupName: curatedGroupName } = curatedGroup;
  const { groupName: exploresGroupName } = exploresGroupDetail;
  const { groupName: brandsGroupName } = brandsGroupDetail;
  const { groupName: stylesGroupName } = stylesGroupDetail;
  const { groupName: collectionsGroupName } = collectionsGroupDetail;
  const { groupName: communitiesGroupName } = communitiesGroupDetail;
  const { groupName: testimonialsGroupName } = testimonialsGroupDetail;
  const [showsignDialog, setshowsignDialog] = useState(false);
  const curatedItems = curatedGroupType.filter((item) => {
    return item.category === category;
  });

  const successMarkidCallBack = () => {
    dispatch(getLastCart(currentUser._id));
  };
  const failureMarkidCallBack = () => {};

  const manageCartItemAddition = () => {
    const lotsArr = JSON.parse(localStorage.getItem("userCart"))
      ? JSON.parse(localStorage.getItem("userCart"))
      : [];
    const data = {};
    data.cartId = currentUser ? currentUser.cartId : "";
    data.lots = lotsArr;
    data.addressId = null; // isAuthenticated? currentUser.addresses[0]._id: null
    data.email = currentUser ? currentUser.email : "";

    dispatch(
      addToCart(
        data,
        isAuthenticated,
        successMarkidCallBack,
        failureMarkidCallBack
      )
    );
  };

  useEffect(() => {
    // if (
    //   localStorage.getItem("access_token") === null &&
    //   isXS &&
    //   sessionStorage.getItem("modal") != "false"
    // ) {
    //   history.push("/onboardinghome");
    // }

    dispatch({ type: "GET_TAB_KEY", payload: "" });
    dispatch(getCategoriesGroup("categories"));
    // dispatch(getFeaturedSellerGroup("featuredSeller"));
    // dispatch(getCuratedesGroup("curatedByMarkid"));
    // dispatch(getExploresGroup("exploreTopDeals"));
    dispatch(getverifiedProducts("productType=true"));
    dispatch(getBrandsGroup("shopTrendingBrands"));
    // dispatch(getStylesGroup("styles"));
    // dispatch(getCollectionsGroup("collectionsYouMayLike"));
    dispatch(getCategoryProducts("cribs_and_furniture"));
    dispatch(getCategoryProducts("strollers"));
    dispatch(getCategoryProducts("gear"));
    dispatch(getCategoryProducts("clothing_and_accessories"));
    dispatch(getCategoryProducts("shoes"));
    dispatch(getCategoryProducts("toys_and_costumes"));
    dispatch(getCategoryProducts("feeding"));
    dispatch(getCategoryProducts("diapering"));
    dispatch(getCategoryProducts("bathing"));
    dispatch(getCategoryProducts("sports_bicycles"));
    dispatch(getCategoryProducts("books"));
    dispatch(getCategoryProducts("safety_equipment"));
    dispatch(getCategoryProducts("maternity"));
    dispatch(getCategoryProducts("electronics"));
    // dispatch( getAllSubSizes())
    // dispatch(getYearOfDetails());
    // dispatch(getCommunitiesGroup("community"));
    // dispatch(getTestimonialsGroup("testimonialsUser"));
    dispatch(setFilterCondition("X"));
    // dispatch(getYearOfDetails());
    // dispatch(getAllBrands());
    let url = "/api/v5/markid-products/algolia-search?productType=true";
    dispatch(getFilterpartnerProducts(null, url));
    dispatch(getAllpartnerproducts(""));
    dispatch(setLoginSignup("signup"));
    dispatch(getRecentlyAddedProducts());
    // dispatch(getAllRecentlySold());
    dispatch(getRecentlySoldProducts());
    dispatch(setCurrentNavSelected("shop"));

    // dispatch(getAllBrands());
    // if(isAuthenticated && JSON.parse(localStorage.getItem("userCart"))?.length===0){
    //   dispatch(getLastCart());
    // }

    // if(JSON.parse(localStorage.getItem("userCart"))?.length>0 && isAuthenticated && currentUser){
    //   //console.log("lmaooooo",JSON.parse(localStorage.getItem("userCart")))
    //   manageCartItemAddition();
    // }

    dispatch(setNavShow(true));

    const tempCatList = [
      {
        display: "Cribs and Furniture",
        value: "cribs_and_furniture",
        id: "5f8ab81836a0cd0ea2329ac0",
      },
      {
        display: "Strollers",
        value: "strollers",
        id: "5f8ab7d436a0cd0ea2329a97",
      },
      {
        display: "Gear",
        value: "gear",
        id: "5f8ab7e436a0cd0ea2329aa1",
      },
      {
        display: "Clothing",
        value: "clothing_and_accessories",
        id: "5f8ab78836a0cd0ea2329a45",
      },
      {
        display: "Shoes",
        value: "shoes",
        id: "5f8ab7a036a0cd0ea2329a5a",
      },
      {
        display: "Toys and Costumes",
        value: "toys_and_costumes",
        id: "5f8ab7c336a0cd0ea2329a7d",
      },
      {
        display: "Feeding",
        value: "feeding",
        id: "5f8ab83e36a0cd0ea2329ad3",
      },
      {
        display: "Diapering",
        value: "diapering",
        id: "5f8ab86c36a0cd0ea2329aec",
      },
      {
        display: "Bathing",
        value: "bathing",
        id: "5f8ab7f436a0cd0ea2329aac",
      },
      {
        display: "Sports and Bicycles",
        value: "sports_bicycles",
        id: "5f8ab84f36a0cd0ea2329ad4",
      },
      {
        display: "Books",
        value: "books",
        id: "5f8ab7ae36a0cd0ea2329a5b",
      },
      {
        display: "Safety & Health",
        value: "safety_equipment",
        id: "5f8ab85e36a0cd0ea2329ae2",
      },
      {
        display: "Maternity",
        value: "maternity",
        id: "5f8ab80536a0cd0ea2329ab9",
      },
      {
        display: "Electronics",
        value: "electronics",
        id: "5f8ab82d36a0cd0ea2329ac7",
      },
    ];

    const tempStore = tempCatList.map((obj) => {
      return { display: obj.displayValue, value: obj.category, id: obj._id };
    });
    dispatch(setNavList(tempCatList));
    dispatch(setNavColors({ bg: "#FDF5F4", text: "#21cad3" }));
  }, []);

  useEffect(() => {
    if (currentUser?.cartId) {
      if (JSON.parse(localStorage.getItem("userCart"))?.length > 0) {
        manageCartItemAddition();
      } else {
        dispatch(getLastCart(currentUser._id));
      }
    }
  }, [currentUser?.cartId]);

  const onTabClick = (key) => {
    setTabkey(key);
  };
  const handleTab = (category) => {
    setCategory(category);
  };
  const handleExplore = () => {
    // if(window.location.pathname === '/homepage'){
    if (window.location.pathname === "/") {
      history.push(
        `/handpicked/handpicked/Explore Top Deals/5fb585385f1dfa0e9e75720f`
      );
      categoryRef.current.scrollIntoView();
    } else {
      // history.push('/homepage');
    }
  };
  // const handleCurated = () => {
  //   // if(window.location.pathname === '/homepage'){
  //     if(window.location.pathname === '/'){
  //     // history.push(`/handpicked/handpicked/Explore Top Deals/5fb585385f1dfa0e9e75720f`);
  //     history.push(`/search-new/?refresh=true&renewed=true`);
  //     categoryRef.current.scrollIntoView();
  //     }else{
  //       // history.push('/homepage');
  //   }
  // }

  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1.5, itemsToScroll: 1 },
    { width: 768, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <LeftOutlined /> : <RightOutlined />;
    return (
      <button
        style={{
          background: "transparent",
          border: "none",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          fontSize: 25,
          fontWeight: "bolder",
          color: isEdge ? "#E4E4E4" : "#393939",
          cursor: "pointer",
        }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };

  const myDots = ({ pages, activePage, onClick }) => {
    return (
      <Row style={{ zIndex: 9 }}>
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              style={{
                borderRadius: "50%",
                background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                width: 15,
                height: 15,
                margin: isXS ? "40px 5px 0px" : "70px 5px 0px",
                cursor: "pointer",
              }}
              onClick={() => onClick(page)}
              // active={isActivePage}
            />
          );
        })}
      </Row>
    );
  };

  useEffect(() => {
    if (cribsList?.hits?.length > 0) {
      setcribs(cribsList);
    }
    if (verifiedproducts?.hits?.length > 0) {
      setverified(verifiedproducts);
    }
    if (strollersList?.hits?.length > 0) {
      setstrollers(strollersList);
    }
    if (maternityList?.hits?.length > 0) {
      setmaternity(maternityList);
    }
    if (gearList?.hits?.length > 0) {
      setgears(gearList);
    }
    if (clothingList?.hits?.length > 0) {
      setclothing(clothingList);
    }
    if (feedingList?.hits?.length > 0) {
      setfeeding(feedingList);
    }
    if (shoesList?.hits?.length > 0) {
      setshoes(shoesList);
    }
    if (toysList?.hits?.length > 0) {
      settoys(toysList);
    }
    if (electronicsList?.hits?.length > 0) {
      setelectronics(electronicsList);
    }
    if (diaperingList?.hits?.length > 0) {
      setdiapering(diaperingList);
    }
    if (bathingList?.hits?.length > 0) {
      setbathing(bathingList);
    }
    if (booksList?.hits?.length > 0) {
      setbooks(booksList);
    }
    if (sportsList?.hits?.length > 0) {
      setsports(sportsList);
    }
    if (safetyList?.hits?.length > 0) {
      setsafety(safetyList);
    }
  }, [
    cribsList,
    safetyList,
    sportsList,
    strollersList,
    maternityList,
    gearList,
    booksList,
    feedingList,
    electronicsList,
    diaperingList,
    shoesList,
    bathingList,
    toysList,
    clothingList,
    verifiedproducts,
  ]);
  const [close, setClose] = useState(sessionStorage.getItem("emailpop") || "true");
  return (
    <div className="homeScreen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{lang.homepage.title}</title>
        <meta name="description" content={lang.homepage.description} />
      </Helmet>
      { currentPage[1] == "" && sessionStorage.getItem("emailpop") === "true" && (
        
          <Emailpopup close={close} setClose={setClose} />
      )}

      <div className="categoriesBlock">{isXS && <ExposedCategories />}</div>

      <MainHomePromotionalBanner />
      {/* Category name  */}
      {/* <Spin indicator={antIcon} spinning={isGetCategoriesGroupLoading}>
        <div ref={categoryRef} id="Categories">
          <MHCategorySection categoriesItem={categoriesGroup} />
        </div>
      </Spin> */}
      {/* Category name  */}
      {/* RecentlySoldProducts */}
      {/* Shop from New Itesm Vs Gently USed  */}
      <div
        ref={categoryRef}
        id="categoryverified"
        className="commonShowcase categoryverified"
      >
        <HomepageProductCardList
          category={"Top Deals"}
          listingData={verified}
          link={() => {
            gaEventClick("Browse", "Buy from Verified shops", "See all");
            history.push("/verifiedshops");
          }}
        />
      </div>
      <ShopNewItems />

      {/* Shop From Swoondle Block  */}
      <ShopfromSwoondle />

      {/* Brands Block  */}
      <div className="brandsBlock">
        <div className="app-wrapper">
          <Spin indicator={antIcon} spinning={isGetBrandsGroupLoading}>
            <Typography className="sectionTitles title">
              Trending brands at Markid
            </Typography>
            <ShopBrand brandsAll={brandsGroupType} />
          </Spin>
        </div>
      </div>
      {/* Brands Block  */}
      {/* <ShopNationWideShipping /> */}
      {/* <div
        ref={categoryRef}
        id="recentlyAdded"
        className="commonShowcase recentlyAddedSoldShowcase"
      >
        <RecentlyAddedProducts
          recentlyAdded={recentlyAdded}
          partnerproducts={partnerproducts}
          link={() => {
            gaEventClick("Recently Added", "See all");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition("desc"));
          }}
        />
        
      </div> */}

      {/* Recently Sold Products  */}
      {/* <div
        ref={categoryRef}
        id="recentlySold"
        className="commonShowcase recentlyAddedSoldShowcase soldBlock"
      >
        <RecentlySoldProducts
          recentlySoldAll={recentlySoldAll}
          recentlySold={recentlySold}
          partnerproducts={partnerproducts}
          link={() => {
            gaEventClick("Recently Sold", "See all");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition("sold"));
          }}
        />
       
      </div> */}

      {/* {categoriesGroup.groupType.map((obj) => {
        return (
          <div
            ref={categoryRef}
            id="categoryFeeding"
            className="recentlyAddedSoldShowcase soldBlock"
          >
            {obj.displayValue}
            <HomepageProductCardList category={obj.displayValue} />
          </div>
        );
      })} */}

      {/* Strollers */}
      {/* {strollersList && strollersList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryStrollers"
        className="commonShowcase categoryStrollers"
      >
        <HomepageProductCardList
          category={"Strollers"}
          listingData={strollers}
          link={() => {
            gaEventClick("Browse", "Strollers", "See all");
            history.push(
              "/product-listing/category/strollers/5f8ab7d436a0cd0ea2329a97"
            );
          }}
        />
      </div>
      {/* )} */}
      {/* Cribs and Furniture */}
      {/* {cribsList && cribsList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryCribs"
        className="commonShowcase categoryCribs"
      >
        <HomepageProductCardList
          category={"Cribs and Furniture"}
          listingData={cribs}
          link={() => {
            gaEventClick("Browse", "Cribs and Furniture", "See all");
            history.push(
              "/product-listing/category/cribs_and_furniture/5f8ab81836a0cd0ea2329ac0"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* MarkId Trust Block  */}
      <MarkidTrust />

      {/* Gear */}
      {/* {gearList && gearList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryGear"
        className="commonShowcase categoryGear"
      >
        <HomepageProductCardList
          category={"Gear"}
          listingData={gears}
          link={() => {
            gaEventClick("Browse", "Gear", "See all");
            history.push(
              "/product-listing/category/gear/5f8ab7e436a0cd0ea2329aa1"
            );
          }}
        />
      </div>
      {/* )} */}
      {/* Baby Clothes */}
      {/* {clothingList && clothingList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryBabyClothes"
        className="commonShowcase categoryBabyClothes"
      >
        <HomepageProductCardList
          category={"Clothing"}
          listingData={clothing}
          link={() => {
            gaEventClick("Browse", "Clothes", "See all");
            history.push(
              "/product-listing/category/clothing_and_accessories/5f8ab78836a0cd0ea2329a45"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Shop nation-wide shipping */}

      {/* Feeding */}
      {/* {feedingList && feedingList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryFeeding"
        className="commonShowcase categoryFeeding"
      >
        <HomepageProductCardList
          category={"Feeding"}
          listingData={feeding}
          link={() => {
            gaEventClick("Browse", "Feeding", "See all");
            history.push(
              "/product-listing/category/feeding/5f8ab83e36a0cd0ea2329ad3"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Shoes */}
      <div
        ref={categoryRef}
        id="categoryShoes"
        className="commonShowcase categoryShoes"
      >
        <HomepageProductCardList
          category={"Shoes"}
          listingData={shoesList}
          link={() => {
            gaEventClick("Browse", "Shoes", "See all");
            history.push(
              "/product-listing/category/shoes/5f8ab7a036a0cd0ea2329a5a"
            );
          }}
        />
      </div>
      {/* Toys & Costumes */}
      {/* {toysList && toysList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryToys"
        className="commonShowcase categoryToys"
      >
        <HomepageProductCardList
          category={"Toys & costumes"}
          listingData={toys}
          link={() => {
            gaEventClick("Browse", "Toys & costumes", "See all");
            history.push(
              "/product-listing/category/toys_and_costumes/5f8ab7c336a0cd0ea2329a7d"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Shop By Deals Block  */}
      {/* <ShopByDealss /> */}

      {/* Maternity */}
      {/* {maternityList && maternityList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryMaternity"
        className="commonShowcase categoryMaternity"
      >
        <HomepageProductCardList
          category={"Maternity"}
          listingData={maternity}
          link={() => {
            gaEventClick("Browse", "Maternity", "See all");
            history.push(
              "/product-listing/category/maternity/5f8ab80536a0cd0ea2329ab9"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Electronics */}
      {/* {electronicsList && electronicsList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryElectroni"
        className="commonShowcase categoryElectronics"
      >
        <HomepageProductCardList
          category={"Electronics"}
          listingData={electronics}
          link={() => {
            gaEventClick("Browse", "Electronics", "See all");
            history.push(
              "/product-listing/category/electronics/5f8ab82d36a0cd0ea2329ac7"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Diapering */}
      {diaperingList && diaperingList.length > 0 && (
        <div
          ref={categoryRef}
          id="categoryDiapering"
          className="commonShowcase categoryDiapering"
        >
          <HomepageProductCardList
            category={"Diapering"}
            listingData={diapering}
            link={() => {
              gaEventClick("Browse", "Diapering", "See all");
              history.push(
                "/product-listing/category/diapering/5f8ab86c36a0cd0ea2329aec"
              );
            }}
          />
        </div>
      )}

      {/* Why Shop on Markid? Block  */}
      {/* <WhyShopOnMarkid /> */}

      {/* Bathing */}
      {/* {bathingList && bathingList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryBathing"
        className="commonShowcase categoryBathing"
      >
        <HomepageProductCardList
          category={"Bathing"}
          listingData={bathing}
          link={() => {
            gaEventClick("Browse", "Bathing", "See all");
            history.push(
              "/product-listing/category/bathing/5f8ab7f436a0cd0ea2329aac"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Books */}
      {/* {booksList && booksList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categoryBooks"
        className="commonShowcase categoryBooks"
      >
        <HomepageProductCardList
          category={"Books"}
          listingData={books}
          link={() => {
            gaEventClick("Browse", "Books", "See all");
            history.push(
              "/product-listing/category/books/5f8ab7ae36a0cd0ea2329a5b"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Preloved kids item  */}
      <PrelovedKidsItem />

      {/* Safety & Health */}
      {/* {safetyList && safetyList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categorySafety"
        className="commonShowcase categorySafety"
      >
        <HomepageProductCardList
          category={"Safety & health"}
          listingData={safety}
          link={() => {
            gaEventClick("Browse", "Safety & health", "See all");
            history.push(
              "/product-listing/category/safety_equipment/5f8ab85e36a0cd0ea2329ae2"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* Sports & Bicycle */}
      {/* {sportsList && sportsList.length > 0 && ( */}
      <div
        ref={categoryRef}
        id="categorySports"
        className="commonShowcase categorySports"
      >
        <HomepageProductCardList
          category={"Sports & bicycle"}
          listingData={sports}
          link={() => {
            gaEventClick("Browse", "Sports & bicycle", "See all");
            history.push(
              "/product-listing/category/sports_bicycles/5f8ab84f36a0cd0ea2329ad4"
            );
          }}
        />
      </div>
      {/* )} */}

      {/* MarkId MarkNewsLetter  */}
      <MarkNewsLetter />
      {/* <Signinmodal showsignDialog={showsignDialog} setshowsignDialog={setshowsignDialog}/> */}
    </div>
  );
};
export default MainHomePage;
