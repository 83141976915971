/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Layout, Button, Image, Space, Menu, Input, Select } from "antd";
import renewedLogo from "../assets/MarkidRenewed.jpg";
import markidLogo from "../assets/MarkidLogoFooter.svg";
import {useDispatch} from 'react-redux';
import { useBreakpoints } from './../react-responsive-hooks/index';
import {
  AppleFilled,
  AndroidFilled,
  MenuOutlined,
  SearchOutlined
} from "@ant-design/icons";
import "../styles/landing.css";
import "../styles/header.css";
import MenuBar from "../assets/MenuBar.svg";
import { useHistory } from "react-router";

const { Header, Content } = Layout;

const HomeHeader = () => {
  const dispatch = useDispatch();
  const [item, setItem] = useState("");
  const { isXS } = useBreakpoints();
const history = useHistory()
  const handleMenu = (value) => {
    setItem(value.key);
  }
  const handleTopMenu = (page, key) => {
    history.push(page);
    dispatch({type:'GET_TAB_KEY', payload:key});
  }
  return (
    <>
      <Row gutter={[20,{xs:10,sm:10,md:42, lg:42, xl:42, xxl:42}]} align="middle" className="headerCard">
        <Col span={24}/>
        {/* <Col xl={4} lg={4} md={4} sm={4} xs={2}></Col> */}
        <Col xl={4} lg={4} md={4} sm={16} xs={16}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <Image
                preview={false}
                alt="logo"
                src={renewedLogo}
                className="renewed-logo"
              />
            </Col>
          </Row>
        </Col>

        <Col xl={10} lg={8} md={8} sm={8} xs={8}>
          <Row align="middle" justify="end" gutter={[16, 0]}>
              <Menu
                // style={{ justifyContent: "space-around", display: "flex" }}
                theme="light"
                mode="horizontal"
                expandIcon
                defaultSelectedKeys={!isXS && ["item1"]}
                onSelect={handleMenu}
                overflowedIndicator={<img alt="" src={MenuBar} />}
              >
                {/* <Menu.Item key="item1">Blog</Menu.Item>
                <Menu.Item key={"item2"}>reNEWED</Menu.Item>
                <Menu.Item key={"item3"}>About Us</Menu.Item>
                <Menu.Item key={"item4"}>Contact Us</Menu.Item>
                <Menu.Item key={"item5"}> */}
                <Menu.Item key={"item1"} className={item === "item1" ? "selected-menu-item" :"unselected-menu-item"}
                // onClick={() => handleTopMenu('/homepage','item1')}
                onClick={() => handleTopMenu('/','item1')}
                >
                  Home</Menu.Item>
                <Menu.Item key={"item2"} className={item === "item2" ? "selected-menu-item renewed" :"unselected-menu-item renewed"}
                onClick={() => handleTopMenu('/blog', "item5")}
                >
                  Blog</Menu.Item>
                {/* <Menu.Item key={"item3"} className={item === "item3" ? "selected-menu-item" :"unselected-menu-item"}>About</Menu.Item> */}
                <Menu.Item key={"item4"} className={item === "item4" ? "selected-menu-item" :"unselected-menu-item"}
                onClick={() => handleTopMenu('/contact-us', 'item6')}
                >
                  Contact</Menu.Item>
                </Menu>
            </Row>
          </Col>
            <Col xl={10} lg={10} md={10} sm={12} xs={24}>
                <Row align="middle" gutter={[15,{xs:10,sm:10,md:0, lg:0, xl:0, xxl:0}]}>
                  <Col span={24}/>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    {/* <Select
                      // onClick={() => handleSearchLot()}
                      showSearch={true}
                      suffixIcon={<SearchOutlined/>}
                      className="search"
                    /> */}
                    <div className="searchParent">
                        <input className="search" />
                        <SearchOutlined className="searchIcon"/>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify={isXS && "center"} gutter={[15,{xs:20,sm:20,md:0, lg:0, xl:0, xxl:0}]}> 
                      <Col span={24}/>
                      <Col>
                          <Button
                           href="https://apps.apple.com/us/app/markid/id1436309782"
                           target='_blank'
                            // style={{ backgroundColor: "black", color: "white" }}
                            className="button-apple"
                            icon={<AppleFilled />}
                          >
                            iPhone
                          </Button>
                      </Col>
                      {/* <Col>
                        {/ * <Link to="/"> * /}
                          <Button
                          href="https://play.google.com/store/apps/details?id=com.markid"
                          target='_blank'
                            className="button-apple"
                            icon={<AndroidFilled />}
                          >
                            Android
                        </Button>
                        {/ * </Link> * /}
                      </Col> */}
                      </Row>
                    </Col>
                </Row>
          </Col>
          <Col span={24}/>
      </Row>
    </>

    // <Row align='middle' gutter={[32, 24]}>
    //   <Col xl={6} lg={6} md={6} sm={24} xs={24}>
    //     <Link to="/">
    //       <Image alt="logo" src={logo} preview={false} style={{paddingTop: 5}}/>
    //     </Link>
    //   </Col>

    //   <Col xl={12} lg={12} md={12} sm={24} xs={24}>
    //     <Button className="link-button" type="link">
    //       Blog
    //     </Button>
    //     <Button className="link-button" type="link">
    //       reNewed
    //     </Button>
    //     <Button className="link-button" type="link">
    //       About Us
    //     </Button>
    //     <Button className="link-button" type="link">
    //       Contact Us
    //     </Button>
    //   </Col>
    //   <Col xl={6} lg={6} md={6} sm={24} xs={24}>
    //     <Space direction="horizontal">
    //       <Button
    //         style={{ backgroundColor: "black", color: "white" }}
    //         icon={<AppleOutlined />}
    //       >
    //         iPhone
    //       </Button>

    //       <Button
    //         style={{ backgroundColor: "black", color: "white" }}
    //         icon={<AndroidOutlined />}
    //       >
    //         Android
    //       </Button>
    //     </Space>
    //   </Col>
    // </Row>
  );
};
export default HomeHeader;
