import React from "react";
import { Modal, Row, Col, Carousel } from "antd";
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";

export const EnlargedImages = (props) => {
  function onChange(a, b, c) {
  }
  const { showEnlargedImagesDialog, onCancelEnlargedImagesMethod } = props;
  const SampleArrow = (props) => {
    const { style, onClick, next } = props;
    return (
      <>
        <div style={{ ...style, display:'block', background: "red" }} onClick={onClick}>
          {next ? (
            <RightOutlined onClick={onClick}></RightOutlined>
          ) : (
            <LeftOutlined onClick={onClick} />
          )}
        </div>
      </>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    nextArrow: <SampleArrow next={true} />,
    prevArrow: <SampleArrow next={false} />,
  };
  const contentStyle = {
    height: "500px",
    lineHeight: "950px",
    backgroundColor: '#ededed'
  };
  return (
    <>
      <Modal
        visible={showEnlargedImagesDialog}
        onCancel={() => onCancelEnlargedImagesMethod()}
        centered
        footer={null}
      >
          
        <Row justify="center">
          <Col xl={20} lg={20} md={20} sm={20} xs={20}>
            <Carousel afterChange={onChange} {...settings}>
            <div>
                <h3 style={contentStyle}>
                    <Row align='bottom' justify='center' gutter={[8,0]}>
                        <Col>
                        <Avatar shape='square' />
                        </Col>
                        <Col>
                        <Avatar shape='square' />
                        </Col>
                        <Col>
                        <Avatar shape='square' />
                        </Col>
                        <Col>
                        <Avatar shape='square' />
                        </Col>
                        <Col>
                        <Avatar shape='square' />
                        </Col>
                    </Row>
                </h3>
              </div>
            </Carousel>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
