import API from '../Utils/API';

export const createPartnerOrder = (data, successCallBack, failureCallBack) => dispatch => {
    dispatch({ type: "CREATE_PARTNER_ORDER_REQUEST" })
    API.post('/api/v5/markid-orders/', data)
        .then(response => {
            dispatch({ type: "CREATE_PARTNER_ORDER_SUCCESS", payload: response.data })
            successCallBack();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "CREATE_PARTNER_ORDER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
              failureCallBack();
            } catch {
              // console.log(error);
            }
          });
  }