import constants from "../Utils/constants";
const initialState = {
  isGetAllBrandsLoading: false,
  isGetBrandByIdLoading: false,
  isGetBrandsByCategoryLoading: false,
  allBrands: [],
  brand: {},
  brandsByCategory: [],
  brandlist: [],
  brandlistloading: false,
  currnav: "shop",
  navShow: "true",
  navList: [
    {
      display: "Cribs and Furniture",
      value: "cribs_and_furniture",
      id: "5f8ab81836a0cd0ea2329ac0",
    },
    {
      display: "Strollers",
      value: "strollers",
      id: "5f8ab7d436a0cd0ea2329a97",
    },
    {
      display: "Gear",
      value: "gear",
      id: "5f8ab7e436a0cd0ea2329aa1",
    },
    {
      display: "Clothing",
      value: "clothing_and_accessories",
      id: "5f8ab78836a0cd0ea2329a45",
    },
    {
      display: "Shoes",
      value: "shoes",
      id: "5f8ab7a036a0cd0ea2329a5a",
    },
    {
      display: "Toys and Costumes",
      value: "toys_and_costumes",
      id: "5f8ab7c336a0cd0ea2329a7d",
    },
    {
      display: "Feeding",
      value: "feeding",
      id: "5f8ab83e36a0cd0ea2329ad3",
    },
    {
      display: "Diapering",
      value: "diapering",
      id: "5f8ab86c36a0cd0ea2329aec",
    },
    {
      display: "Bathing",
      value: "bathing",
      id: "5f8ab7f436a0cd0ea2329aac",
    },
    {
      display: "Sports and Bicycles",
      value: "sports_bicycles",
      id: "5f8ab84f36a0cd0ea2329ad4",
    },
    {
      display: "Books",
      value: "books",
      id: "5f8ab7ae36a0cd0ea2329a5b",
    },
    {
      display: "Safety & Health",
      value: "safety_equipment",
      id: "5f8ab85e36a0cd0ea2329ae2",
    },
    {
      display: "Maternity",
      value: "maternity",
      id: "5f8ab80536a0cd0ea2329ab9",
    },
    {
      display: "Electronics",
      value: "electronics",
      id: "5f8ab82d36a0cd0ea2329ac7",
    },
  ],
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_FILTER_CONDITION:
      return {
        ...state,
        filterCondition: action.payload,
      };
    case constants.SET_CURRENT_NAV:
      return {
        ...state,
        currnav: action.payload,
      };
    case constants.SET_NAV_COLORS:
      return {
        ...state,
        navcolors: action.payload,
      };
    case constants.SET_NAV_LIST:
      return {
        ...state,
        navList: action.payload,
      };
    case constants.SET_NAV_SHOW:
      return {
        ...state,
        navShow: action.payload, //selectedBrandName
      };
    case constants.GET_BRANDS_REQUEST:
      return {
        ...state,
        isGetAllBrandsLoading: true,
      };
    case constants.GET_BRANDS_SUCCESS:
      return {
        ...state,
        isGetAllBrandsLoading: false,
        allBrands: action.payload.brands,
      };
    case constants.GET_BRANDS_FAILURE:
      return {
        ...state,
        isGetAllBrandsLoading: false,
      };

    case constants.GET_BRAND_BY_ID_REQUEST:
      return {
        ...state,
        isGetBrandByIdLoading: true,
      };
    case constants.GET_BRAND_BY_ID_SUCCESS:
      return {
        ...state,
        isGetBrandByIdLoading: false,
        brand: action.payload,
      };
    case constants.GET_BRAND_BY_ID_FAILURE:
      return {
        ...state,
        isGetBrandByIdLoading: false,
      };

    case constants.GET_BRANDS_BY_CATEGORY_REQUEST:
      return {
        ...state,
        isGetBrandsByCategoryLoading: true,
      };
    case constants.GET_BRANDS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        isGetBrandsByCategoryLoading: false,
        brandsByCategory: action.payload.brands,
      };
    case constants.GET_BRANDS_BY_CATEGORY_FAILURE:
      return {
        ...state,
        isGetBrandsByCategoryLoading: false,
      };
    case constants.GET_BRANDLIST_REQUEST:
      return {
        ...state,
        brandlistloading: true,
      };
    case constants.GET_BRANDLIST_SUCCESS:
      return {
        ...state,
        brandlistloading: false,
        brandlist: action.payload,
      };
    case constants.GET_BRANDLIST_FAILURE:
      return {
        ...state,
        brandlistloading: false,
      };
    default:
      return state;
  }
};
export default brandsReducer;
