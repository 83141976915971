/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { getMarkidProductById } from "../Actions/partnerProductAction";
import verified from "../assets/verifiednew.png";
import { setLoginSignup } from "../Actions/authAction";
import * as yup from "yup";
import Moment from "moment";
import { useGaEvent } from "../hooks/useGaEvent";
import {
  Link,
  BrowserRouter as Router,
  Route,
  useHistory,
} from "react-router-dom";
import securepayvector from "../assets/securepay.svg";
import toolTip from "../assets/new-assets/tooltipnew.png";
// import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Card,
  Skeleton,
  Avatar,
  Divider,
  Button,
  Switch,
  Rate,
  Spin,
  Alert,
  Tooltip,
} from "antd";
import {
  CheckOutlined,
  BulbOutlined,
  BellOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { MessageSeller } from "../components/MessageSeller";
import { MakeOffer } from "../components/MakeOffer";
import { NotifySoon } from "../components/NotifySoon";
import {
  getCurrentUserWallet,
  getLastCart,
  addToCart,
  saveToLocalStorage,
} from "../Actions/cartAction";
import { EnlargedImages } from "../components/EnlargedImages";
import PLNotify from "../assets/PLNotify.svg";
import NotifiedIcon from "../assets/NotifiedIcon.svg";
import QuestionIcon from "../assets/NeedHelpIcon.png";
import TrueIcon from "../assets/trueicon.svg";
import {
  followUser,
  unFollowUser,
  getUser,
  notifyItem,
  unNotifyItem,
} from "../Actions/userAction";
import ShippingLogo from "../assets/Slice.svg";
import SignIn from "../pages/SignIn";
import { humanize } from "../Utils/util";
import { createMakeOffer } from "../Actions/offerAction";
const schema = yup.object().shape({
  price: yup.string("Offer Price is require"),
});
const { Text, Title } = Typography;
const PLCart = (props) => {
  const dispatch = useDispatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { productData, productLoader, sellerInfo } = props;
  const {
    condition,
    category,
    markidProductCategory,
    description,
    color,
    age,
    deliveryType,
    yearOfPurchase,
    location,
    size,
    negotiablePrice,
    name,
    isFree,
    brand,
    costPrice,
    salePrice,
    price,
    sellerAddress,
    inSoldPending,
    createdBy,
    _id,
    isActive,
    title,
  } = productData;

  const { name: sellerName } = sellerAddress || { sellerName: "" };
  let ProductId = _id;
  const history = useHistory();
  const {
    currentUser,
    following,
    isFollowUserLoading,
    isUnFollowUserLoading,
    isNotifyItemLoading,
    isUnNotifyItemLoading,
    currentCart,
    productTypeDetails,
    allLots,
    isAuthenticated,
    partnerDetails,
    currentCartId,
    currentCartIds,
    currentPLPItem,
    productDetails,
  } = useSelector((state) => {
    const {
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
    } = state.user;
    const { productTypeDetails } = state.productTypeDetails;
    const { partnerDetails, isGetPartnerDetailsLoading } = state.partnerDetails;
    const { productDetails } = state.partnerProducts;
    const { allLots } = state.lots;
    const { currentCart, currentCartId, currentCartIds, currentPLPItem } =
      state.cart;
    const { isAuthenticated } = state.auth;
    const { following } = currentUser || {};
    return {
      following,
      productTypeDetails,
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      currentCart,
      allLots,
      isAuthenticated,
      partnerDetails,
      currentCartId,
      currentCartIds,
      currentPLPItem,
      productDetails,
    };
  });
  // const { isActive } = productTypeDetails;

  const { isPostOfferLoading, errorMessage } = useSelector((state) => {
    const { isPostOfferLoading, errorMessage } = state.offer;
    return {
      isPostOfferLoading,
      errorMessage,
    };
  });
  const {
    handleSubmit,
    control,
    reset,
    errors,
    setValue: setFormValues,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { gaPageView, gaEventClick } = useGaEvent();

  useEffect(() => {}, [errorMessage]);
  useEffect(() => gaPageView());

  const meetupType = deliveryType === "meetup";
  const bothType = deliveryType === "both";
  const { notifyItems, _id: currentUserId } = currentUser;
  const { lots } = allLots || { lots: [] };
  let CartId = "";
  if (isAuthenticated) {
    currentCart &&
      currentCart.map((current) => {
        current._id === ProductId ? (CartId = current._id) : null;
      });
  } else {
    ProductId = currentPLPItem._id
      ? currentPLPItem._id
      : currentPLPItem.objectID;
    currentCartIds.forEach((item) => {
      if (item === ProductId) CartId = item;
    });
  }
  let NotifyItemId = "";
  // {
  //   notifyItems &&
  //     notifyItems.map((current) => {
  //       current === ProductId ? (NotifyItemId = current) : null;
  //     });
  // }

  const dueDate = Moment().add(5, "days").format("ddd , MMM Do");
  const Toduedate = Moment().add(7, "days").format("ddd , MMM Do");

  //   const CartID = currentCart && currentCart.length > 0 ? currentCart[0]: null;
  //   const CartID = ccID
  //   console.log({CartID})
  //   function onChange(checked) {
  //       console.log(`switch to ${checked}`);
  //     }
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  const [showMakeOfferDialog, setShowMakeOfferDialog] = useState(false);
  const [showNotifySoonDialog, setShowNotifySoonDialog] = useState(false);
  const [showEnlargedImagesDialog, setShowEnlargedImagesDialog] =
    useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const ManufactureVal = yearOfPurchase ? yearOfPurchase.substring(0, 4) : null;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleAddItemCart = (item) => {
    // item.productType = "P2P";
    gaEventClick("PDP", "Add to Cart", item?.name);

    const lotsArr = [...currentCartIds];
    if (productTypeDetails) {
      if (productTypeDetails.productType === "personToPerson") {
        lotsArr.push(productTypeDetails._id);
      } else {
        lotsArr.push(item.markidProductId);
      }
      handleCreateCart(lotsArr, item);
    } else {
      if(item.markidProductId)
      {
        dispatch(getMarkidProductById(
          item.markidProductId,
          successCallBackPDP,
          failureCallBackPDP
        ));
      }
 
    }
  };
  const handleCreateCart = (lotsArr, item) => {
    const data = {};
    data.cartId = currentUser.cartId;
    data.lots = lotsArr;
    data.addressId = null; // currentUser.addresses[0]._id? currentUser.addresses[0]._id: null;
    data.email = currentUser.email;
    if (
      !currentCartIds?.includes(item.objectID) &&
      !currentCartIds?.includes(item.markidProductId) &&
      !currentCartIds?.includes(currentPLPItem._id)
    ) {
      if (isAuthenticated) {
        dispatch(
          addToCart(data, isAuthenticated, successCallBackCart, failureCallBack)
        );
      } else {
        //item.objectID= item.markidProductId
        dispatch(saveToLocalStorage(currentPLPItem));
      }
    }
  };
  const successCallBackCart = () => {
    dispatch(getLastCart(currentUser._id));
    //history.push("/cart");
  };

  const successCallBackPDP = () => {
    //console.log(productDetails);
    const lotsArr = [...currentCartIds];

   
    lotsArr.push(productData.markidProductId);
    if (isAuthenticated) {
      handleCreateCart(lotsArr, productData);
    }
    else{
      dispatch(setLoginSignup("signup"));
      history.push("/onboarding");
    }
    
  };

  const failureCallBackPDP = () => {
    //dispatch(getLastCart())
    //history.push("/cart");
  };

  const handleNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(
        notifyItem(
          { id: ProductId },
          successCallBackNotifyMe,
          failureCallBackNotifyMe
        )
      );
    } else {
      gaEventClick("Navigation", "Add to Cart", "signup");
      history.push("/onboarding");
      // setShowSignInDialog(true)
    }
  };

  const successCallBackNotifyMe = () => {
    setShowNotifySoonDialog(true);
  };
  const failureCallBackNotifyMe = () => {};
  const handleUnNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(unNotifyItem({ id: ProductId }));
    } else {
      history.push("/onboarding");
      // setShowSignInDialog(true)
    }
  };
  const handleFollowUser = () => {
    if (isAuthenticated) {
      dispatch(followUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleUnFollowUser = () => {
    if (isAuthenticated) {
      dispatch(unFollowUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleOpenMakeAnOffer = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Make an offer");
      setShowMakeOfferDialog(true);
      // history.push(`/messages/`+ProductId)
    } else {
      gaEventClick("Navigation", "Make an offer", "signup");
      history.push("/onboarding");
    }
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const timeId = setTimeout(() => {
      setOpen(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);
  const handlemakeOfferClick = (data) => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Make an offer");
      data.lotId = productData._id;
      const p = parseFloat(productData.price);
      data.price = p;
      dispatch(createMakeOffer(data, successCallBack, failureCallBack));
    } else {
      gaEventClick("Navigation", "Make an offer", "signup");
      history.push("/onboarding");
    }
  };
  const successCallBack = () => {
    reset();
    history.push("/chat");
  };

  const failureCallBack = () => {};
  const forSellerUser = createdBy === currentUserId;
  return (
    <>
      <div className="addtocartweb">
        {/* <Card className="pLCartCard"> */}
        <Row
          justify="space-between"
          gutter={[0, 2]}
          className="description-heading"
        >
          {/* <Col span={24}></Col> */}
          <Col className="w-100">
            {/* <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
            {productLoader ? (
              <Skeleton.Button active={productLoader} size="default" />
            ) : (
              brand && <Text className="pLCartHeading">{brand}</Text>
            )}
          </Row> */}
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col span={24}></Col>
              <Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <h1 className="pLtitlepdp">
                    {name ? capitalize(name) : capitalize(title)}
                  </h1>
                )}
              </Col>
            </Row>
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col className="plcartpdp">
                {deliveryType === "shipping" && (
                  <div className="shippingMethod toship">Shipping</div>
                )}
                {/* {deliveryType === "meetup" && (
                  <div className="shippingMethod local">Local Pickup</div>
                )} */}
                {/* {deliveryType === "both" && (
                  <div className="shippingMethodParent2">
                    <div className="shippingMethod toship">Shipping</div> */}
                {/* <div
                      className="shippingMethod local"
                      style={{ marginLeft: "10px" }}
                    >
                      Local Pickup
                    </div> */}

                {/* <div className="shippingMethod toship both">
                      Shipping or Local Pick Up
                    </div> */}
                {/* </div>
                )} */}
                {title && salePrice?.value < 5000 && (
                  <div className="shippingMethod toship">Shipping</div>
                )}
                {title && salePrice?.value >= 5000 && (
                  <div className="shippingMethod toship">Free Shipping</div>
                )}
              </Col>

              {location && (
                <span className="availablepdp">
                  available at
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="default" />
                  ) : (
                    <span class="bold-txt">{capitalize(location)}</span>
                  )}{" "}
                </span>
              )}
            </Row>
            <Row
              gutter={[0, { xs: 8, sm: 8, md: 0, lg: 0, xl: 0 }]}
              className="priceAlign"
            >
              <Col span={24}></Col>
              <Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="default" />
                ) : (
                  (price || salePrice) &&
                  (!Number.isInteger(
                    (price ? price : salePrice.value) / 100
                  ) ? (
                    <Typography className="pLPrice">
                      ${((price ? price : salePrice.value) / 100).toFixed(2)}
                    </Typography>
                  ) : (
                    <Typography className="pLPrice">
                      ${Math.trunc((price ? price : salePrice.value) / 100)}
                    </Typography>
                  ))
                )}
              </Col>

              <Tooltip
                title="For shipping delivery options. Shipping price calculated based on location, shown at checkout."
                placement="rightTop"
              >
                {salePrice && salePrice.value <= 5000 && (
                  <span className="shippingfeepdp tooltip">
                    +Shipping fee <img src={toolTip} width="15px" />
                  </span>
                )}
                {deliveryType === "shipping" && isFree === false && (
                  <span className="shippingfeepdp tooltip">
                    +Shipping fee <img src={toolTip} width="15px" />
                  </span>
                )}
                {deliveryType === "both" && isFree === false && (
                  <span className="shippingfeepdp tooltip">
                    +Shipping fee <img src={toolTip} width="15px" />
                  </span>
                )}
              </Tooltip>
            </Row>
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              {costPrice > 0 && (
                <Col className="retailpricepdp">
                  Retail price{" "}
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="default" />
                  ) : (
                    price &&
                    (!Number.isInteger(costPrice / 100) ? (
                      <span className="strikePrice">
                        ${(costPrice / 100).toFixed(2)}
                      </span>
                    ) : (
                      <span className="strikePrice">
                        ${Math.trunc(costPrice / 100)}
                      </span>
                    ))
                  )}
                </Col>
              )}
            </Row>
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              {name && (
                <Col className="paysecuretxt">
                  <span>
                    <img src={securepayvector} width="13px" />
                  </span>{" "}
                  Your payment is secured with Stripe
                </Col>
              )}
            </Row>
            {title && (
              <Row
                gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
                style={{ marginTop: "12px" }}
              >
                <Typography className="pLcartpdpfree">
                  Free shipping over{" "}
                  <span className="plcarttextsmallunderline1">$50</span>
                  {/* <span className="plcarttextsmall">from a</span> */}
                </Typography>
              </Row>
            )}
            {title && (
              <Typography className="pLcartpdpfree">
                & Free Returns
                <span className="plcarttextsmall" style={{ marginLeft: "6px" }}>
                  from
                  <Tooltip
                        placement="bottomLeft"
                        title="This item is reviewed by our specialists and shipped straight from our verified shop to your home as part of our Verified shop program. The customer service staff at Markid is happy to answer any inquiries. All returns are free and shipping on orders over $50 is also free! "
                      >
                  <span
                    className="plcarttextsmallunderline"
                    style={{ marginLeft: "6px" }}
                  >
                    verified shops
                  </span>{" "}</Tooltip>
                  <img src={verified} width="18px" />
                </span>
              </Typography>
            )}

            <Row
              className="buttonsWrapper"
              gutter={[0, { xs: 8, sm: 8, md: 15, lg: 15, xl: 15 }]}
            >
              <Col span={24}></Col>
              {isActive === false && (
                <Button
                  block
                  className={"itemSoldButton"}
                  //  onClick={() => setShowNotifySoonDialog(true)}
                >
                  Item Not Available
                </Button>
              )}

              {(inSoldPending || productData?.isSold) && isActive === true ? (
                <Button
                  block
                  className={"itemSoldButton"}
                  //  onClick={() => setShowNotifySoonDialog(true)}
                >
                  Item sold
                </Button>
              ) : (
                isActive === true && (
                  <>
                    {ProductId === CartId ? (
                      productLoader ? (
                        <Skeleton.Button active={productLoader} width={500} />
                      ) : (
                        !meetupType &&
                        !forSellerUser && (
                          <Col span={24} style={{ marginRight: "12px" }}>
                            <Button
                              block
                              className={"button button-primary pLCartButton"}
                              onClick={() => {
                                gaEventClick("PDP", "Go To Cart");
                                history.push("/cart");
                              }}
                            >
                              GO TO CART
                            </Button>
                          </Col>
                        )
                      )
                    ) : productLoader ? (
                      <Skeleton.Button active={productLoader} />
                    ) : (
                      !meetupType &&
                      !forSellerUser && (
                        <Col span={24} style={{ marginRight: "12px" }}>
                          <Button
                            block
                            className={"button button-primary  pLCartButton"}
                            onClick={() => {
                              handleAddItemCart(productData);
                            }}
                          >
                            {/* {(currentCartIds.includes(currentPLPItem._id))?"ADD TO CART":"GO TO CART"} */}
                            ADD TO CART
                          </Button>
                        </Col>
                      )
                    )}
                  </>
                )
              )}
              {inSoldPending || forSellerUser ? null : (
                <>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : negotiablePrice === true ? (
                    <div className="tooltipext"></div>
                  ) : (
                    <form onSubmit={handleSubmit(handlemakeOfferClick)}>
                      {errorMessage && (
                        <Row justify="center" gutter={[20]}>
                          <Col span={24}></Col>
                          <Alert
                            type="error"
                            open={open}
                            message={errorMessage}
                          />
                        </Row>
                      )}
                      {productData?.name &&
                        isActive === true &&
                        negotiablePrice && (
                          <>
                            <div className="tooltipext"></div>
                          </>
                        )}
                    </form>
                  )}
                </>
              )}
              {title && (
                <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
                  <Typography className="pdpgetitbtwn">
                    Get it between{" "}
                    <span className="pdpgetitbtwnnxt">
                      {dueDate} - {Toduedate}
                    </span>
                  </Typography>
                </Row>
              )}

              {title && (
                <>
                  <Row>
                    <Col>
                      <img
                        src={securepayvector}
                        width="13px"
                        style={{ marginTop: "-6px" }}
                      />{" "}
                      <span className="Markidstripetxt">
                        Markid uses stripe to keep your payment information
                        secure. Markid sellers never receive your credit card
                        information.
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
          </Col>
        </Row>
        {/* <Divider
          style={{ background: "#707070", margin: "18px 0px", opacity: 0.3 }}
        /> */}

        <div className="desctrition-details">
          <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
            <Col span={24}></Col>
            <Col>
              <h1 className="pLcartpdp">Overview</h1>
            </Col>
          </Row>
          {condition && (
            <Row gutter={[0, 4]} className="pb-1">
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Condition</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  {condition === "new" && (
                    <Tooltip
                      title="New. Unopened packaging. Unused."
                      placement="bottom"
                    >
                      <Text className="pLvalue">
                        {capitalize("New with tag")}
                        {""}
                        <img src={toolTip} className="tooltip" width="15px" />
                      </Text>
                    </Tooltip>
                  )}
                  {/* <Text className="pLvalue">{capitalize(condition)}</Text> */}
                  {(condition === "Good" || condition === "good") && (
                    <Tooltip
                      title="Gently used. One / few minor flaws. Functional."
                      placement="bottom"
                    >
                      <Text className="pLvalue">
                        {capitalize(condition)}
                        {""}
                        <img src={toolTip} className="tooltip" width="15px" />
                      </Text>
                    </Tooltip>
                  )}
                  {(condition === "Great" || condition === "great") && (
                    <Tooltip
                      title="Like-new condition. No signs of wear. Fully functional."
                      placement="bottom"
                    >
                      <Text className="pLvalue">
                        {capitalize(condition)}
                        {""}
                        <img src={toolTip} className="tooltip" width="15px" />
                      </Text>
                    </Tooltip>
                  )}
                  {(condition === "Used" || condition === "used") && (
                    <Tooltip
                      title="Like-new condition. No signs of wear. Fully functional."
                      placement="bottom"
                    >
                      <Text className="pLvalue">
                        {capitalize(condition)}
                        {""}
                        <img src={toolTip} className="tooltip" width="15px" />
                      </Text>
                    </Tooltip>
                  )}
                  {(condition === "fair" || condition === "Fair") && (
                    <Tooltip
                      title="Used, functional, multiple flaws / defects. Signs of wear and use."
                      placement="bottom"
                    >
                      <Text className="pLvalue">
                        {capitalize(condition)}
                        {""}
                        <img src={toolTip} className="tooltip" width="15px" />
                      </Text>
                    </Tooltip>
                  )}
                </Col>
              )}
            </Row>
          )}
          {brand && (
            <Row gutter={[0, 4]} className="pb-1">
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Brand</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="default" />
              ) : (
                brand && <Text className="pLCartHeading">{brand}</Text>
              )}
            </Row>
          )}
          {deliveryType ? (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Delivery</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">
                    {/* {deliveryType === 'both' ? 'Shipping or Meetup' : capitalize(deliveryType)} */}
                    {deliveryType === "both"
                      ? "Shipping or Local Pick Up"
                      : deliveryType === "meetup"
                      ? "Local Pick Up"
                      : capitalize(deliveryType)}
                  </Text>
                </Col>
              )}
            </Row>
          ) : (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Delivery</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">
                    {/* {deliveryType === 'both' ? 'Shipping or Meetup' : capitalize(deliveryType)} */}
                    {capitalize("Shipping")}
                  </Text>
                </Col>
              )}
            </Row>
          )}
          <Row
            gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
            className="pb-1"
          >
            <Col span={24}></Col>
          </Row>
          <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
            <Col span={24}></Col>
            <Col>
              <h1 className="pLcartpdp">Item details</h1>
            </Col>
          </Row>

          {yearOfPurchase && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Purchased</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{ManufactureVal}</Text>
                </Col>
              )}
            </Row>
          )}

          {age && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Age</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{capitalize(age)}</Text>
                </Col>
              )}
            </Row>
          )}
          {size && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Size</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{capitalize(size)}</Text>
                </Col>
              )}
            </Row>
          )}
          {color && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Color</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{capitalize(color)}</Text>
                </Col>
              )}
            </Row>
          )}
          {(category || markidProductCategory) && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Category</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="default" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">
                    {humanize(category ? category : markidProductCategory)}
                  </Text>
                </Col>
              )}
            </Row>
          )}
          {inSoldPending || forSellerUser ? null : (
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col>
                {productLoader ? (
                  <Skeleton active={productLoader} size="small" />
                ) : (
                  <Typography className="pLNotifyDes">{description}</Typography>
                )}
              </Col>
              <Col></Col>
            </Row>
          )}
        </div>

        {/* </Card> */}
      </div>

      <MessageSeller
        onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)}
        showMessageSellerDialog={showMessageSellerDialog}
      />
      <MakeOffer
        onCancelMakeOfferDialog={() => setShowMakeOfferDialog(false)}
        showMakeOfferDialog={showMakeOfferDialog}
        makeOfferData={productData}
        sellerInfo={sellerInfo}
        negotiablePrice={negotiablePrice}
      />
      <NotifySoon
        onCancelNotifySoonMethod={() => setShowNotifySoonDialog(false)}
        showNotifySoonDialog={showNotifySoonDialog}
        notifyData={productData}
      />
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default PLCart;