import constants from "../Utils/constants";
const initialState = {
  isCreateSendOtpLoading: false,
  isCreateVerifyOtpLoading: false,
  isSignupWithFacebookLoading: false,
  isSignupWithAppleLoading: false,
  isSignupWithAmazonLoading: false,
  isEmailupdaesLoading: false,
  providerName: "", providerData: {}, fullName: "", email: "", providerToken: "", newUser: false, 
  isAuthenticated: false,
  loginSignup: "login",
  emailmessage:""
  // showSignIn: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_LOGIN_SIGNUP:
      return {
        ...state,
        loginSignup: action.payload,
      };
    case constants.CREATE_SEND_OTP_REQUEST:
      return {
        ...state,
        isCreateSendOtpLoading: true,
      };
    case constants.CREATE_SEND_OTP_SUCCESS:
      return {
        ...state,
        isCreateSendOtpLoading: false,
      };
    case constants.CREATE_SEND_OTP_FAILURE:
      return {
        ...state,
        isCreateSendOtpLoading: false,
        getOtperror: action.payload,
      };

    case constants.CREATE_VERIFY_OTP_REQUEST:
      return {
        ...state,
        isCreateVerifyOtpLoading: true,
      };
    case constants.CREATE_VERIFY_OTP_SUCCESS:{
      const { data } = action.payload
      const { token, user, new: newUser } = data
      return {
        ...state,
        isCreateVerifyOtpLoading: false,
        providerUser: user,
        providerToken: token,
        newUser,
        isAuthenticated: true
      };}
    case constants.CREATE_VERIFY_OTP_FAILURE:
      return {
        ...state,
        isCreateVerifyOtpLoading: false,
        verifyOtperror: action.payload,
      };

    case constants.SIGNUP_WITH_FACEBOOK_REQUEST:
      return {
        ...state,
        isSignupWithFacebookLoading: true,
      };
    case constants.SIGNUP_WITH_FACEBOOK_SUCCESS: {
      const { data } = action.payload
      const { token, user } = data
      const { fullName, email } = user
      return {
        ...state,
        isSignupWithFacebookLoading: false,
        providerToken: token,
        providerData: user,
        fullName: fullName,
        email: email
      };}
    case constants.SIGNUP_WITH_FACEBOOK_FAILURE:
      return {
        ...state,
        isSignupWithFacebookLoading: false,
      };

    case constants.SIGNUP_WITH_APPLE_REQUEST:
      return {
        ...state,
        isSignupWithAppleLoading: true,
      };
    case constants.SIGNUP_WITH_APPLE_SUCCESS:{
      const { data } = action.payload
      const { token, user } = data
      const { fullName, email } = user
      return {
        ...state,
        isSignupWithAppleLoading: false,
        providerToken: token,
        providerData: user,
        fullName: fullName,
        email: email
      };}
    case constants.SIGNUP_WITH_APPLE_FAILURE:
      return {
        ...state,
        isSignupWithAppleLoading: false,
      };

    case constants.SIGNUP_WITH_AMAZON_REQUEST:
      return {
        ...state,
        isSignupWithAmazonLoading: true,
      };
    case constants.SIGNUP_WITH_AMAZON_SUCCESS:
      return {
        ...state,
        isSignupWithAmazonLoading: false,
      };
    case constants.SIGNUP_WITH_AMAZON_FAILURE:
      return {
        ...state,
        isSignupWithAmazonLoading: false,
      };
      case constants.POST_EMAIL_UPDATES_REQUEST:
        return {
          ...state,
          isEmailupdaesLoading: true,
        };
      case constants.POST_EMAIL_UPDATES_SUCCESS:
        return {
          ...state,
          isEmailupdaesLoading: false,
          emailmessage:action.payload.data.message
        };
      case constants.POST_EMAIL_UPDATES_FAILURE:
        return {
          ...state,
          isEmailupdaesLoading: false,
        };
      // case "GET_USER_SUCCESS":
      //   return {
      //     ...state, isAuthenticated: true
      //   }
    // case "SHOW_SIGN_IN":
    //     return {
    //       ...state,
    //       showSignIn: true,
    //     };
    case "SET_PROVIDER_TOKEN":
      return {
        ...state, providerToken: action.payload.token
      }
    case "REMOVE_TOKEN":
      return {
        ...state,
        providerToken: '',
        providerUser: {},
        isAuthenticated: false,
      };
    case "CLEAR ERROR STATE":
      return {
        ...state,
        getOtperror: "",
        verifyOtperror: "",
      };
    case "RESET_APP":
      return { ...initialState };
      case constants.GET_USER_SUCCESS: 
      const existedToken = localStorage.access_token;
      return {...state, isAuthenticated : true, providerUser: action.payload, providerToken: existedToken}
    default:
      return state;
  }
};
export default authReducer;