/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Spin,
  Button,
  Empty,
  Dropdown,
  Menu,
  Card,
  Pagination,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SellerInfoCard } from "../components/SellerInfoCard";
import { SellerProductCard } from "../components/SellerProductCard";
import { LoadingOutlined } from "@ant-design/icons";
import { getUserById, getSellerUserLots, getSellerBadges } from "../Actions/userAction";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { Helmet } from "react-helmet";

const { Text } = Typography;
const SellerProfile = () => {
  const { isXS } = useBreakpoints();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const paramsToken = new URLSearchParams(location.search);
  const history = useHistory();
  const token = paramsToken.get("token");
  const {
    providerToken,
    isGetUserBYIdLoading,
    isGetSellerUserLotsLoading,
    userById,
    sellerUserLots,
    sellerBadges,
  } = useSelector((state) => {
    const { providerToken } = state.auth;
    const {
      isGetUserBYIdLoading,
      isGetSellerUserLotsLoading,
      userById,
      sellerUserLots,
      sellerBadges,
    } = state.user;
    return {
      providerToken,
      isGetUserBYIdLoading,
      isGetSellerUserLotsLoading,
      userById,
      sellerUserLots,
      sellerBadges,
    };
  });
  const { lots, total } = sellerUserLots || {};
  const pages = Math.ceil(total / 18);
  const [sortByFeatured, setSortByFeatured] = useState(["1"]);
  const [sortByFeaturedValue, setSortByFeaturedValue] = useState(["All"]);
  const [sortByLots, setSortByLots] = useState("");
  if (token) {
    localStorage.setItem("access_token", token);
    if (!providerToken) {
      dispatch({ type: "SET_PROVIDER_TOKEN", payload: { token } });
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(getUserById(id));
      dispatch(getSellerUserLots(null, `api/v2/users/${id}/lots`));
      dispatch(getSellerBadges(id));
      // dispatch(getSellerUserLots(id));
    }
  }, []);
  const buildQuery = (queries) => {
    let basicUrl = `api/v2/users/${id}/lots?`;
    for (let key in queries) {
      if (queries.hasOwnProperty(key)) {
        switch (key) {
          case "skip":
            basicUrl = `${basicUrl}&skip=${queries[key]}`;
            break;
          case "sortBy":
            basicUrl = `${basicUrl}&sortBy=${queries[key]}`;
            break;
        }
      }
    }
    return basicUrl;
  };
  const changesSortByLots = (sortingVal) => {
    setSortByLots(sortingVal);
    const q = buildQuery({ sortBy: sortingVal });
    dispatch(getSellerUserLots(null, q));
  };
  const sortingByLots = (sortingVal, name) => {
    setSortByFeaturedValue(name);
    changesSortByLots(sortingVal);
  };
  const handlePagination = (value) => {
    const q = buildQuery({ skip: (value - 1) * 18 });
    dispatch(getSellerUserLots(null, q));
  };
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return null;
    }
    if (type === "next") {
      return <a className="pagyNext">Next</a>;
    } else {
      return originalElement;
    }
  }
  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  const { itemType, itemName, id, search } = params;
  return (
    <div>
      <Helmet>
        <title>Markid - Seller Profile</title>
      </Helmet>
      <Spin
        style={{ top: 200, color: "#FF533F" }}
        indicator={antIcon}
        spinning={isGetUserBYIdLoading || isGetSellerUserLotsLoading}
      >
        <SellerInfoCard userById={userById} sellerBadges={sellerBadges} />

        <Card bordered={false} className="selletLotsBg">
          <div className="app-wrapper">
			{lots && lots.length>=1&&(<><Row
              gutter={[0, { xs: 0, sm: 0, md: 0, lg: 50, xl: 50, xxl: 50 }]}
              justify="space-between"
            >
              <Col span={24}></Col>
			  {lots && lots.length >= 0 && ( <Col>
                <Typography className="listingItemtext">
                  Item listings
                </Typography>
              </Col>)}
             
              <Col>
                <Row gutter={[9, 0]} className="sellerSort">
					
                  <Col>
                    <Typography className="pFSort">Sort by</Typography>
                  </Col>
                  <Col>
                    <Dropdown
                      overlayClassName="sellerSortFilter"
                      overlay={
                        <Menu
                          onClick={(e) => setSortByFeatured(e.key)}
                           selectedKeys={[sortByFeatured]}
                        >
                           
                        
                          <Menu.Item
                            onClick={() =>
                              sortingByLots("priceAsc", " Price low to high")
                            }
                            key="1"
                          >
                            Price low to high
                          </Menu.Item>
                          <Menu.Item
                            onClick={() =>
                              sortingByLots("priceDesc", "Price high to low")
                            }
                            key="2"
                          >
                            Price high to low
                          </Menu.Item>
                          <Menu.Item
                            onClick={() =>
                              sortingByLots("createdAtDesc", " Date of Listing")
                            }
                            key="3"
                          >
                            Date of Listing
                          </Menu.Item>
                        </Menu>
                      }
                      // visible={visibleInput}
                      placement="bottomCenter"
                      arrow
                    >
                      <Text
                        type="link"
                        // className="pFDelivery"
                        className="pFDeliveryVal"
                      >
                        {sortByFeaturedValue}{" "}
                        <DownOutlined
                          style={{
                            fontSize: 12,
                            fontWeight: "bolder",
                            marginLeft: "5px",
                            color: "#393939",
                          }}
                        />
                      </Text>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[30, 30]}>
              <Col span={24}></Col>
              {lots &&
                lots.map((val, i) => {
                  return (
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} key={i}>
                      <SellerProductCard lots={val} />
                    </Col>
                  );
                })}
            </Row></>)}
            
            {lots && lots.length >= 1 && (
              <Row
                gutter={[0, { xs: 21, sm: 21, md: 38, lg: 38, xl: 38 }]}
                align="middle"
                justify={isXS ? "center" : "end"}
              >
                <Col span={24} />
                <Col>
                  <Pagination
                    size="small"
                    showSizeChanger={false}
                    total={total}
                    pageSize={18}
                    onChange={(value) => handlePagination(value)}
                    itemRender={itemRender}
                  />
                </Col>
                {/* <Col span={24}></Col> */}
              </Row>
            )}
            {lots && lots.length <= 0 && (
              <div className="nolistingcenter">
                {/* <Empty /> */}

                <Col>
                  <div className="nolistings">No listings available !!!</div>
                </Col>
                <div  className="displaylistings">
					
                  <Button
                    block
                    className={"button button-primary pLCartButton"}
                    onClick={() => history.push("/seller/addproduct")}
                  >
                    SELL
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Spin>
    </div>
  );
};
export default SellerProfile;
