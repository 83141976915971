/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Typography,
  Input,
  Image,
  Card,
  Space,
  Carousel,
} from "antd";
import { EnvironmentFilled, SearchOutlined } from "@ant-design/icons";
import "../styles/landing.css";
import HomeHeader from "../components/Header";
import ReNewedBlog from "../components/ReNewedBlog";
import HowItWorks from "../components/HowItWorks";
import AreaNotFound from "../components/AreaNotFound";

const { Text } = Typography;
const { Header, Content, Footer } = Layout;
const Landing = () => {
  return (
    <>
     <HomeHeader />
        <ReNewedBlog />
        {/* <AreaNotFound /> */}
        <HowItWorks />
    </>
  );
};
export default Landing;
