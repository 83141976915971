import React from "react";
import { Card, Typography, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import "../App.css";
import { useBreakpoints } from './../react-responsive-hooks/index';
import ArrowRight from "../assets/ArrowRight.svg";
export const CollectionCard = (props) => {
  const { isXS } = useBreakpoints();
  const history = useHistory();
  const { collectionsData } = props
  // const { cover, collectioneName, forwardIcon } = props
  const {
    imageUrls,
    name,
    _id
  } = collectionsData
  // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const images = imageUrls && imageUrls.length > 0 ? `https://images.markid.io/resize?width=600&height=600&quality=50&type=webp&url=${imageUrls[0]}` : null;

  return (
    <div className="exploreParent collection-card">
      <Card className='cMCard' onClick={() => history.push(`/product-list/${name}/${_id}`)}
        cover={
          <img alt="" src={images} className='collectionImg' style={{ height: isXS ? 177 : 339 }} />
        }
      >
        <Row justify='space-between' className="collection-card-info">
          <Col span={18}>
            <Typography
              className="cMName"
            >
              {" "}
              {name}
            </Typography>
          </Col>
          <Col>
            <img alt="" src={ArrowRight} className="arrow-rt" />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
