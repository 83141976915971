import React, { useState, useEffect, useMemo } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { UpOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";
import { Scrollbars } from "react-custom-scrollbars";
import { useGaEvent } from "../hooks/useGaEvent";
import { useBreakpoints } from "./../react-responsive-hooks/index";

import { useHistory } from "react-router-dom";
import { count } from "ramda";
import { getAlgoliaBrands } from "../Actions/lotsAction";
import { useDispatch, useSelector } from "react-redux";
export const BrandFilter = (props) => {
  const {
    brandsByCategory,
    dropdownCallback,
    selectedBrand,
    itemType,
    checkdonBrandChange,
    currentPage,
    totalcount,
  } = props;
  const { algoliabrandlist } = useSelector((state) => {
    const { algoliabrandlist } = state.lots;
    return {
      algoliabrandlist,
    };
  });
  const { gaPageView, gaEventClick } = useGaEvent();
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { isXS, isSM } = useBreakpoints();
  const [key, setkey] = useState();
  useEffect(() => {
    if (!(isXS || isSM)) {
      if (currentPage[3] === "clothing_and_accessories") {
        setkey(1);
      }
      if (currentPage[3] === "maternity") {
        setkey(1);
      }
      if (currentPage[3] === "gear") {
        setkey(1);
      }
      if (currentPage[3] === "shoes") {
        setkey(1);
      }
      if (currentPage[3] === "feeding") {
        setkey(1);
      }
      if (currentPage[3] === "electronics") {
        setkey(1);
      }
      if (currentPage[3] === "safety_equipment") {
        setkey(1);
      }
      if (currentPage[3] === "strollers") {
        setkey(1);
      }
      if (currentPage[3] === "toys_and_costumes") {
        setkey(1);
      }
      if (currentPage[3] === "cribs_and_furniture") {
        setkey(1);
      }
      if (currentPage[3] === "bathing") {
        setkey(1);
      }
      if (currentPage[3] === "sports_bicycles") {
        setkey(1);
      }
      if (currentPage[3] === "diapering") {
        setkey(1);
      }
    }
  }, [currentPage]);

  const lower =
    totalcount &&
    Object.keys(totalcount.brand || {}).map((element) => {
      return element.toLowerCase();
    });
  const [query, setQuery] = useState("");
  const [flag, setflag] = useState();

  const match =
    totalcount &&
    Object.keys(totalcount.brand || [])
      .map((e) => e?.toLowerCase())
      .find((element) => {
        if (element?.includes(query.toLowerCase())) {
          return true;
        }
      });

  // useEffect(() => {
  //   if (Object.values(algoliabrandlist || {}) != null) {
  //     setflag(0);
  //   } else {
  //     setflag(1);
  //   }
  // }, [query]);
  useEffect(() => {
    dispatch(getAlgoliaBrands({ search: query }));
  }, [query]);

  return (
    <>
      <Collapse
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        onChange={() => {
          dropdownCallback();
          gaEventClick("Filter by", "Brand", "open/close");
        }}
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        {brandsByCategory.length > 0 || itemType != "category" ? (
          <Panel
            className="collapseContent"
            style={{
              padingTop: 15,
              paddingBottom: 19,
              marginTop: 15,
              marginBottom: 19,
            }}
            header={
              selectedBrand && selectedBrand.length > 0 ? (
                <Badge color="blue">
                  <Typography className="pFCollapseTitle">
                    Brand&nbsp;
                  </Typography>
                </Badge>
              ) : (
                <Typography className="pFCollapseTitle">Brand</Typography>
              )
            }
            key={key}
          >
            <Col span={24}>
              <div className="brandsSearch inputParent">
                <input
                  type="search"
                  placeholder="Search brands..."
                  className="searchHtmlInput"
                  autoComplete="off"
                  // autoFocus
                  onChange={(event) => setQuery(event.target.value)}
                />
                <SearchOutlined className="searchOutlined" />
              </div>
            </Col>
            {/* <Scrollbars style={{ height: 300 }}> */}
            <Row
              className={"scroll-container"}
              // style={{
              //   maxHeight: "220px",
              //   overflow: 'auto',
              // }}
              gutter={[0, 0]}
            >
              <Col span={22} className="fliters-Wrapper">
                {algoliabrandlist &&
                  query != "" &&
                  Object.values(algoliabrandlist || {}).map((val, i) => {
                    return (
                      <Row gutter={[12, 16]} key={i}>
                        <>
                          {" "}
                          <Col>
                            {/* {val.brand?.length>0 &&( */}
                            <Checkbox
                              onChange={() => {
                                checkdonBrandChange(val.value);
                                gaEventClick("Filter by", "Brand", val.value);
                              }}
                              //onClick={() => history.push(`/product-listing/${itemType}/${itemName}/${id}/brand=${val.brand}`)}
                              checked={selectedBrand.indexOf(val.value) !== -1}

                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                            {/* )} */}
                          </Col>
                          <Col>
                            <Typography className="conditionName">
                              {capitalize(val.value)}
                              {val.count > 0 ? (
                                <span className="ProductCount">
                                  ({val.count})
                                </span>
                              ) : (
                                <span>(0)</span>
                              )}
                            </Typography>
                          </Col>
                        </>
                      </Row>
                    );
                  })}
                {totalcount &&
                  query == "" &&
                  Object.entries(totalcount.brand || {})
                    // .filter((val) => {
                    //   if (query == "") {
                    //     return val;
                    //   } else if (
                    //     val[0]?.toLowerCase()?.includes(query?.toLowerCase())
                    //   ) {
                    //     return val;
                    //   }
                    // })
                    .map((val, i) => (
                      <Row gutter={[12, 16]} key={i}>
                        <>
                          {" "}
                          <Col>
                            {/* {val.brand?.length>0 &&( */}
                            <Checkbox
                              onChange={() => {
                                checkdonBrandChange(val[0]);
                                gaEventClick("Filter by", "Brand", val[0]);
                              }}
                              //onClick={() => history.push(`/product-listing/${itemType}/${itemName}/${id}/brand=${val.brand}`)}
                              checked={selectedBrand.indexOf(val[0]) !== -1}

                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                            {/* )} */}
                          </Col>
                          <Col>
                            <Typography className="conditionName">
                              {capitalize(val[0])}
                              {val[1] > 0 ? (
                                <span className="ProductCount">({val[1]})</span>
                              ) : (
                                <span>(0)</span>
                              )}
                            </Typography>
                          </Col>
                        </>
                      </Row>
                    ))}
                {algoliabrandlist &&
                  algoliabrandlist.length == 0 &&
                  query != "" && (
                    <>
                      <Typography className="conditionName">
                        No Results found
                      </Typography>
                    </>
                  )}
              </Col>
            </Row>
            {/* </Scrollbars> */}
          </Panel>
        ) : (
          <></>
        )}
      </Collapse>
    </>
  );
};
