/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import securepayvector from "../assets/securepay.svg";
import securePay from "../assets/secure_payment.svg";
import verified from "../assets/verifiednew.png";
import Moment from "moment";
import protect from "../assets/protection.svg";
import visa from "../assets/Mastercardlogo.png";
import visa1 from "../assets/VisaLogo.png";
import AmericanEx from "../assets/AmericanExpress.png";
import { getMarkidProductById } from "../Actions/partnerProductAction";
import { setLoginSignup } from "../Actions/authAction";
import stripe from "../assets/Stripelogo.png";
import verifiednew from "../assets/verifiednew.png";
import { useGaEvent } from "../hooks/useGaEvent";
import swwondlelogo from "../assets/swwondlelogo.png";
import { useHistory } from "react-router-dom";
import {
  getCurrentUserWallet,
  getLastCart,
  addToCart,
  saveToLocalStorage,
} from "../Actions/cartAction";
import {
  Row,
  Col,
  Typography,
  Avatar,
  Collapse,
  Divider,
  Card,
  Input,
  List,
  Rate,
  Space,
  Button,
  Image,
  Skeleton,
  Spin,
  Tooltip,
} from "antd";
import EmptyHeart from "../assets/UnFavorite.svg";
import FullHeart from "../assets/Favorite.svg";
import toolTip from "../assets/new-assets/tooltipnew.png";

import DummyPLImg from "../assets/DummyPLImg.svg";
import NotifiedIcon from "../assets/NotifiedIcon.svg";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import swoondlesociety from "../assets/swoondlesociety.png";
import { useForm, Controller } from "react-hook-form";
import "../App.css";
import {
  addToFavorites,
  removeFromFavorites,
  getLotById,
} from "../Actions/lotsAction";
import { LoadingOutlined } from "@ant-design/icons";
import { ProductImage } from "../components/ProductImages";
import SignIn from "../pages/SignIn";
import { MessageSeller } from "../components/MessageSeller";
import { NotifySoon } from "../components/NotifySoon";
import { MakeOffer } from "../components/MakeOffer";
import moment from "moment";
import PLNotify from "../assets/PLNotify.svg";
import SellerImg from "../assets/SellerImg.svg";
import TrueVector from "../assets/TrueVector.svg";
import swsociety from "../assets/swsociety.png";
import Badge1 from "../assets/Badge1.svg";
import Badge2 from "../assets/Badge2.svg";
import Badge3 from "../assets/Badge3.svg";
import Badge4 from "../assets/Badge4.svg";
import swbadge from "../assets/swbadge.png";
import {
  followUser,
  unFollowUser,
  getUser,
  notifyItem,
  unNotifyItem,
} from "../Actions/userAction";
import SkeletonButton from "antd/lib/skeleton/Button";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { getChatsWithSeller } from "../Actions/chatsAction";
import { getSellerBadges } from "../Actions/userAction";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { humanize } from "../Utils/util";
const { Text, Title } = Typography;
const PLDpage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isXS, isSM } = useBreakpoints();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { productData, productLoader, sellerInfo } = props;
  const {
    additionalImageLinks,
    imageLink,
    imageUrls,
    description,
    brand,
    name,
    title,
    gender,
    createdAt,
    color,
    isSold,
    inUserFavorites,
    _id,
    price,
    condition,
    category,
    deliveryType,
    yearOfPurchase,
    negotiablePrice,
    location,
    isActive,
    size,
    sellerAddress,
    favoritesCount,
    createdBy,
    isRenewed,
    salePrice,
    markidProductCategory,
  } = productData;
  const {
    _id: sellerId,
    fullName,
    photo,
    activeLotsCount,
    soldLotsCount,
    createdAt: SellerCreatedAt,
  } = sellerInfo || {};
  const Listed = moment(createdAt).format("MMMM YYYY");
  const { Panel } = Collapse;
  const [selectedProductImg, setselectedProductImg] = useState("");
  const [showMakeOfferDialog, setShowMakeOfferDialog] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  const [showNotifySoonDialog, setShowNotifySoonDialog] = useState(false);
  const {
    isAuthenticated,
    currentCart,
    currentUser,
    allLots,
    isFollowUserLoading,
    isUnFollowUserLoading,
    isNotifyItemLoading,
    isUnNotifyItemLoading,
    isGetChatsWithSellerLoading,
    sellerBadges,
    partnerDetails,
    providerToken,
    currentCartIds,
    productTypeDetails,
    currentPLPItem,
  } = useSelector((state) => {
    const { productTypeDetails } = state.productTypeDetails;
    const { providerToken, isAuthenticated } = state.auth;
    const { currentCart, currentCartIds, currentPLPItem } = state.cart;
    const { allLots } = state.lots;
    const {
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      sellerBadges,
    } = state.user;
    const { isGetChatsWithSellerLoading } = state.chats;
    const { partnerDetails, isGetPartnerDetailsLoading } = state.partnerDetails;
    return {
      isAuthenticated,
      productTypeDetails,
      currentCart,
      currentUser,
      sellerBadges,
      allLots,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      isGetChatsWithSellerLoading,
      partnerDetails,
      providerToken,
      currentCartIds,
      currentPLPItem,
    };
  });
  const dueDate = Moment().add(5, "days").format("ddd , MMM Do");
  const Toduedate = Moment().add(7, "days").format("ddd , MMM Do");
  const { gaPageView, gaEventClick } = useGaEvent();
  useEffect(() => gaPageView());

  const { fastResponder, fastShipper, top10 } = sellerBadges || {};
  const meetupType = deliveryType === "meetup";
  const { following, notifyItems, _id: currentUserId } = currentUser || {};
  const { lots } = allLots || { lots: [] };
  let ProductId = _id;
  let CartId = "";
  if (isAuthenticated) {
    currentCart &&
      currentCart.map((current) => {
        current._id === ProductId ? (CartId = current._id) : null;
      });
  } else {
    ProductId = currentPLPItem._id
      ? currentPLPItem._id
      : currentPLPItem.objectID;
    currentCartIds.forEach((item) => {
      if (item === ProductId) CartId = item;
    });
  }
  let NotifyItemId = "";
  {
    notifyItems &&
      notifyItems.map((current) => {
        current === ProductId ? (NotifyItemId = current) : null;
      });
  }
  const forSellerUser = createdBy === currentUserId;
  function callback(key) {
    // console.log(key);
  }
  const [imageArrays, setImageArrays] = useState([]);
  const [imageArraysTemp, setImageArraysTemp] = useState([]);
  let tempImgArray = [];

  const handleAddItemCart = (item) => {
    // item.productType = "P2P";
    gaEventClick("PDP", "Add to Cart", item?.title);

    const lotsArr = [...currentCartIds];

    if (productTypeDetails) {
      lotsArr.push(item.markidProductId);
      handleCreateCart(lotsArr, item);
    } else {
      if (item.markidProductId) {
        dispatch(
          getMarkidProductById(
            item.markidProductId,
            successCallBackPDP,
            failureCallBackPDP
          )
        );
      }
    }
  };

  const successCallBackPDP = () => {
    //console.log(productDetails);
    const lotsArr = [...currentCartIds];

    lotsArr.push(productData.markidProductId);
    if (isAuthenticated) {
      handleCreateCart(lotsArr, productData);
    } else {
      dispatch(setLoginSignup("signup"));
      history.push("/onboarding");
    }
  };

  const failureCallBackPDP = () => {
    //dispatch(getLastCart())
    //history.push("/cart");
  };
  const handleCreateCart = (lotsArr, item) => {
    const data = {};
    data.cartId = currentUser.cartId;
    data.lots = lotsArr;
    data.addressId = null; // currentUser.addresses[0]._id? currentUser.addresses[0]._id: null;
    data.email = currentUser.email;
    if (
      !currentCartIds?.includes(item.objectID) &&
      !currentCartIds?.includes(item.markidProductId) &&
      !currentCartIds?.includes(currentPLPItem._id)
    ) {
      if (isAuthenticated) {
        dispatch(
          addToCart(data, isAuthenticated, successCallBackCart, failureCallBack)
        );
      } else {
        item.objectID = item.markidProductId;
        dispatch(saveToLocalStorage(currentPLPItem));
      }
    }
  };
  const successCallBackCart = () => {
    dispatch(getLastCart(currentUser._id))
    //history.push("/cart");
  };

  useEffect(() => {
    if (imageLink) {
      tempImgArray.push(imageLink);
    }
    if (additionalImageLinks && additionalImageLinks.length) {
      additionalImageLinks.map((img) => {
        tempImgArray.push(img);
      });
    }
    setImageArraysTemp(tempImgArray);
  }, [imageLink, additionalImageLinks]);
  useEffect(() => {
    if (imageArraysTemp.length) {
      imageArraysTemp.map((img) => {
        if (!tempImgArray.includes(img)) {
          tempImgArray.push(img);
        }
      });
    }
    setImageArrays(tempImgArray);
  }, [imageArraysTemp]);
  const images =
    imageArrays && imageArrays.length > 0
      ? `https://images.markid.io/fit?width=600&height=500&quality=50&type=webp&url=${imageArrays[0]}`
      : null;
  // const { register, handleSubmit, errors, control } = useForm({});
  const ManufactureVal = yearOfPurchase ? yearOfPurchase.substring(0, 4) : null;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const onImgClick = (selected) => {
    setselectedProductImg(selected);
  };
  const handleAddToFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(addToFavorites({ id: _id }, successCallBack, failureCallBack));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleRemoveFromFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(
        removeFromFavorites({ id: _id }, successCallBack, failureCallBack)
      );
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const successCallBack = () => {
    dispatch(getLotById(_id));
  };
  const failureCallBack = () => {};
  // const handleAddItemCart = (item) => {
  //   let imgArray = [];
  //   imgArray.push(item.imageLink);
  //   item.name = item.title;
  //   item.price = parseInt(item.salePrice.value);
  //   item.imageUrls = imgArray;
  //   item.shippingPrice = "0";
  //   item.productType = "Partner";
  //   item.seller = {
  //     fullName: partnerDetails.name,
  //     photo: partnerDetails.image,
  //   };
  //   gaEventClick("PDP", "Add to Cart", item.name);
  //   dispatch({ type: "ADD_ITEM_CART", payload: { item: item } });
  //   // history.push("/markid-cart")
  //   history.push("/cart");
  // };
  const handleNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(
        notifyItem(
          { id: ProductId },
          successCallBackNotifyMe,
          failureCallBackNotifyMe
        )
      );
    } else {
      // setShowSignInDialog(true)
      gaEventClick("Navigation", "Add to Cart", "signup");
      history.push("/onboarding");
    }
  };
  const handleUnNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(unNotifyItem({ id: ProductId }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const successCallBackNotifyMe = () => {
    setShowNotifySoonDialog(true);
  };
  const failureCallBackNotifyMe = () => {};
  const handleFollowUser = () => {
    if (isAuthenticated) {
      dispatch(followUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleUnFollowUser = () => {
    if (isAuthenticated) {
      dispatch(unFollowUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleOpenMakeAnOffer = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Make an offer");
      setShowMakeOfferDialog(true);
      // history.push(`/messages/`+ProductId)
    } else {
      gaEventClick("Navigation", "Make an offer", "signup");
      history.push("/onboarding");
    }
  };
  const handleMessageSeller = () => {
    if (isAuthenticated) {
      dispatch(
        getChatsWithSeller(
          _id,
          sellerId,
          messageSellerSuccess,
          messageSellerFailure
        )
      );
      // history.push(`/messages`)
    } else {
      history.push("/onboarding");
    }
  };
  const messageSellerSuccess = () => {
    history.push("/chat");
  };
  const messageSellerFailure = () => {};

  return (
    <>
      <Row justify="center">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {
            productLoader ? (
              <Col span={24}>
                <Row justify={"center"} align="middle">
                  <Spin indicator={antIcon} spinning={productLoader} />
                </Row>
              </Col>
            ) : (
              // <div>
              //   <div style={{ position: 'absolute', left: -5 }}>
              //     {inSoldPending ? (
              //       <div className="sold-button-div">
              //         <Button type="text" className="sold-button">
              //           SOLD
              //         </Button>
              //       </div>
              //     ) :
              //       <div className="sold-button-div">
              //       </div>}
              //   </div>
              <div style={{ position: "relative" }}>
                {/* <div className="pLHeart">
                  {inUserFavorites ? (
                    <img
                      src={FullHeart}
                      alt=""
                      onClick={() => handleRemoveFromFavorite()}
                      className="favButton"
                    />
                  ) : (
                    <img
                      src={EmptyHeart}
                      alt=""
                      onClick={() => handleAddToFavorite()}
                      className="favButton"
                    />
                  )}
                  <Typography className="totalFavCount">
                    {favoritesCount}
                  </Typography>
                </div> */}
                {/* </div> */}
                <ProductImage
                  className="pLDImg"
                  productimg={selectedProductImg ? selectedProductImg : images}
                  //   productimg={selectedProductImg ? selectedProductImg : File1}
                />
              </div>
            )
            // <img className='product-image' src={images}/>
          }
        </Col>
      </Row>

      <Row gutter={[0, { xs: 15, sm: 15, md: 32, lg: 32 }]} justify="end">
        <Col span={24}></Col>
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <Row
            gutter={[16, 0]}
            style={{ marginRight: isXS || (isSM && "0px") }}
            className="thumbscroll"
          >
            {productLoader ? (
              <Skeleton.Image active={productLoader} size="small" />
            ) : (
              imageArrays &&
              imageArrays.map((val, i) => (
                <Col key={i}>
                  <ProductImage
                    className={
                      selectedProductImg === val
                        ? "selected-product-image"
                        : "unselected-product-image"
                    }
                    productimg={val}
                    selected={val}
                    onClick={(e) => onImgClick(val)}
                  />
                </Col>
              ))
            )}
          </Row>
        </Col>
      </Row>

      <div className="addtocartMob">
        <Row justify="center">
          <Col xs={24} className="pLCartParent">
            <Card>
              <Row justify="space-between" gutter={[0, 0]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Row gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Col>
                      {productLoader ? (
                        <Skeleton.Button active={productLoader} size="small" />
                      ) : (
                        title && (
                          <h1 className="pLBrand">{capitalize(title)}</h1>
                        )
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col className="plcartpdp">
                  {salePrice?.value < 5000 ? (
                    <div className="shippingMethod toship">Shipping</div>
                  ) : (
                    <div className="shippingMethod toship">Free Shipping</div>
                  )}
                </Col>

                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="default" />
                ) : (
                  salePrice &&
                  salePrice.value && (
                    <Col span={24}>
                      <Col span={24}></Col>
                      <Col className="marginforprice">
                        <Text className="pLPrice">
                          ${(salePrice.value / 100).toFixed(2)}
                        </Text>
                        {salePrice.value <= 5000 && (
                          <Tooltip
                            title="For shipping delivery options. Shipping price calculated based on location, shown at checkout."
                            placement="bottom"
                            trigger="click"
                          >
                            <span
                              className="shippingfeepdp tooltip"
                              style={{ marginLeft: "12px" }}
                            >
                              +Shipping fee <img src={toolTip} width="16px" />
                            </span>
                          </Tooltip>
                        )}
                      </Col>
                    </Col>
                  )
                )}
                {title && (
                  <Row
                    gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
                    style={{ marginTop: "12px" }}
                  >
                    <Typography className="pLcartpdpfreemob">
                      Free shipping over{" "}
                      <span className="plcarttextsmallunderline1">$50</span>
                      {/* <span className="plcarttextsmall">from a</span> */}
                    </Typography>
                  </Row>
                )}
                {title && (
                  <Typography className="pLcartpdpfreemob">
                    & Free Returns
                    <span
                      className="plcarttextsmallmob"
                      style={{ marginLeft: "6px" }}
                    >
                      from
                      <Tooltip
                        placement="bottomRight"
                        title="This item is reviewed by our specialists and shipped straight from our verified shop to your home as part of our Verified shop program. The customer service staff at Markid is happy to answer any inquiries. All returns are free and shipping on orders over $50 is also free! "
                      >
                        <span
                          className="plcarttextsmallunderlinemob"
                          style={{ marginLeft: "6px" }}
                        >
                          verified shops
                        </span>{" "}
                      </Tooltip>
                      <img src={verified} width="16px" />
                    </span>
                  </Typography>
                )}
              </Row>
              <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
                {/* <Col className="paysecuretxt">
                  <span>
                    <img src={securepayvector} />
                  </span>{" "}
                  Your payment is secured with Stripe
                </Col> */}
                {/* {title && (
              <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]} style={{marginTop:"12px"}}>
                <Typography className="pLcartpdpfree">
                  Free shipping over $50 & Free Returns{" "}
                  <span className="plcarttextsmall">from a </span><span className="plcarttextsmallunderline">
                Verified Shop{" "}
                <span>
                  <img src={verifiednew} width="18px" />
                </span>
              </span>

                </Typography>
                
              </Row>
            )} */}

                {/* {title && (
              <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
                <Typography className="pLcartpdpfree">
                  Get it between {dueDate} - {Toduedate}
                </Typography>
              </Row>
            )} */}
              </Row>

              <Row
                className="buttonsWrapper"
                gutter={[0, { xs: 8, sm: 8, md: 15, lg: 15, xl: 15 }]}
              >
                <Col span={24}></Col>
                {isSold && isActive === true ? (
                  <Button
                    block
                    className={"itemSoldButton"}
                    // onClick={() => setShowNotifySoonDialog(true)}
                  >
                    Item sold
                  </Button>
                ) : (
                  <>
                    {ProductId === CartId ? (
                      productLoader ? (
                        <Skeleton.Button active={productLoader} width={500} />
                      ) : (
                        !meetupType &&
                        !forSellerUser &&
                        isActive === true && (
                          <Col span={24}>
                            <Button
                              block
                              className={"button button-primary pLCartButton"}
                              onClick={() => {
                                gaEventClick("PDP", "Go To Cart");
                                history.push("/cart");
                              }}
                            >
                              GO TO CART
                            </Button>
                          </Col>
                        )
                      )
                    ) : productLoader ? (
                      <Skeleton.Button active={productLoader} />
                    ) : (
                      !meetupType &&
                      !forSellerUser &&
                      isActive === true && (
                        <Col span={24}>
                          <Button
                            block
                            //style={{width: "100%"}}
                            className={"button button-primary pLCartButton"}
                            onClick={() => {
                              handleAddItemCart(productData);
                            }}
                          >
                            ADD TO CART
                            {/* {(currentCartIds.includes(currentPLPItem._id))?"ADD TO CART":"GO TO CART"} */}
                          </Button>
                        </Col>
                      )
                    )}
                  </>
                )}

                {(isSold || !negotiablePrice || forSellerUser) &&
                isActive === true ? null : (
                  <>
                    <Col span={10}>
                      {productLoader ? (
                        <Skeleton.Button active={productLoader} size="small" />
                      ) : (
                        <>
                          <Button
                            block
                            onClick={() => handleOpenMakeAnOffer()}
                            className={"plfollowpdp"}
                          >
                            MAKE AN OFFER{" "}
                          </Button>
                        </>
                      )}
                    </Col>
                    <Col className="tooltipalign">
                      <Tooltip
                        placement="bottom"
                        title="Send an offer to the seller for this item. All offers made with shipping are binding and require a form of payment to send."
                        trigger="click"
                      >
                        <img src={toolTip} className="tooltip" width="16px" />
                      </Tooltip>
                    </Col>
                  </>
                )}
                {title && (
                  <Row
                    gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
                    style={{ marginTop: "20px" }}
                  >
                    <Typography className="pdpgetitbtwn">
                      Get it between{" "}
                      <span className="pdpgetitbtwnnxt">
                        {dueDate} - {Toduedate}
                      </span>
                    </Typography>
                  </Row>
                )}

                {title && (
                  <>
                    <Row>
                      <Col>
                        <img
                          src={securepayvector}
                          width="13px"
                          style={{ marginTop: "-6px" }}
                        />{" "}
                        <span className="Markidstripetxt">
                          Markid uses stripe to keep your payment information
                          secure. Markid sellers never receive your credit card
                          information.
                        </span>
                      </Col>
                    </Row>
                  </>
                )}
              </Row>
              <Divider style={{ color: "#707070", margin: "15px 0px" }} />
              <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col>
                  <h1 className="pLcartpdp">Overview</h1>
                </Col>
              </Row>
              {condition && (
                <Row>
                  <Col span={13}>
                    <Text className="pLKey">Condition</Text>
                  </Col>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : (
                    <Col span={11}>
                      {condition === "new" && (
                        <Tooltip
                          title="New. Unopened packaging. Unused."
                          placement="bottom"
                        >
                          <Text className="pLvalue">
                            {capitalize("New with tag")}
                            {""}
                            <img
                              src={toolTip}
                              className="tooltip"
                              width="16px"
                            />
                          </Text>
                        </Tooltip>
                      )}
                      {/* <Text className="pLvalue">{capitalize(condition)}</Text> */}
                      {(condition === "Good" || condition === "good") && (
                        <Tooltip
                          title="Gently used. One / few minor flaws. Functional."
                          placement="bottom"
                        >
                          <Text className="pLvalue">
                            {capitalize(condition)}
                            {""}
                            <img
                              src={toolTip}
                              className="tooltip"
                              width="16px"
                            />
                          </Text>
                        </Tooltip>
                      )}
                      {(condition === "Great" || condition === "great") && (
                        <Tooltip
                          title="Like-new condition. No signs of wear. Fully functional."
                          placement="bottom"
                        >
                          <Text className="pLvalue">
                            {capitalize(condition)}
                            {""}
                            <img
                              src={toolTip}
                              className="tooltip"
                              width="16px"
                            />
                          </Text>
                        </Tooltip>
                      )}
                      {(condition === "Used" || condition === "used") && (
                        <Tooltip
                          title="Like-new condition. No signs of wear. Fully functional."
                          placement="bottom"
                        >
                          <Text className="pLvalue">
                            {capitalize(condition)}
                            {""}
                            <img
                              src={toolTip}
                              className="tooltip"
                              width="16px"
                            />
                          </Text>
                        </Tooltip>
                      )}
                      {(condition === "fair" || condition === "Fair") && (
                        <Tooltip
                          title="Used, functional, multiple flaws / defects. Signs of wear and use."
                          placement="bottom"
                        >
                          <Text className="pLvalue">
                            {capitalize(condition)}
                            {""}
                            <img
                              src={toolTip}
                              className="tooltip"
                              width="16px"
                            />
                          </Text>
                        </Tooltip>
                      )}
                    </Col>
                  )}
                </Row>
              )}
              {brand && (
                <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                  <Col span={24}></Col>
                  <Col span={13}>
                    <Text className="pLKey">Brand</Text>
                  </Col>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : (
                    <Col span={11}>
                      <Text className="pLvalue">{capitalize(brand)}</Text>
                    </Col>
                  )}
                </Row>
              )}

              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Delivery</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">Shipping</Text>
                  </Col>
                )}
              </Row>
              <Row gutter={[0, 4]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Price</Text>
                </Col>
                {/* {productLoader ? (
                  <Skeleton.Button active={productLoader} size="default" />
                ) : (
                  <Col span={10}>
                    {negotiablePrice === true ? (
                      <Text className="pLvalue">Negotiable</Text>
                    ) : (
                      <Text className="product-cart-value">Firm</Text>
                    )}
                  </Col>
                )} */}
              </Row>
              <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col>
                  <h1 className="pLcartpdp">Item details</h1>
                </Col>
              </Row>

              {size && (
                <Row gutter={[0, 4]}>
                  <Col span={24}></Col>
                  <Col span={13}>
                    <Text className="pLKey">Size</Text>
                  </Col>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : (
                    <Col span={10}>
                      <Text className="pLvalue">{capitalize(size)}</Text>
                    </Col>
                  )}
                </Row>
              )}

              {markidProductCategory && (
                <Row gutter={[0, 4]}>
                  <Col span={24}></Col>
                  <Col span={13}>
                    <Text className="pLKey">Category</Text>
                  </Col>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="default" />
                  ) : (
                    <Col span={10}>
                      <Text className="pLvalue">
                        {humanize(markidProductCategory)}
                      </Text>
                    </Col>
                  )}
                </Row>
              )}
            </Card>
          </Col>
        </Row>
        <Divider style={{ color: "#707070", margin: "30px 0px" }} />{" "}
        <Row gutter={[0, 0]}>
          <Col>
            <img alt="" src={securePay} style={{ cursor: "pointer" }} />
          </Col>
          <Col xl={{ span: 6, offset: 1 }} xs={{ span: 17, offset: 1 }}>
            <Row gutter={[0, 3]}>
              <Text className="Plsecurepdp" style={{ cursor: "pointer" }}>
                Secure payments
              </Text>
              <Col span={3} />
            </Row>
            <Row>
              <Typography className="pLListedItemdesc">
                We keep your payment information secure using stripe. Markid
                sellers will never receive your credit card information.
              </Typography>

              <Col span={15} />
            </Row>
            <Row>
              <Col>
                <img src={stripe} width="60.61px" />
              </Col>
              <Col>
                <img src={visa} width="40.61px" />
              </Col>
              <Col>
                <img src={visa1} width="40.61px" />
              </Col>
              <Col>
                <img src={AmericanEx} width="40.61px" />
              </Col>
            </Row>
            {/* <Row>
              <Col span={4} style={{ marginRight: "10px" }}>
                <img src={stripe} />
              </Col>
              <Col span={16}>
                <img src={visa} />
              </Col>
            </Row> */}
          </Col>
          <Divider style={{ color: "#707070", margin: "30px 0px" }} />
          <Col>
            <img alt="" src={protect} style={{ cursor: "pointer" }} />
          </Col>
          <Col xl={{ span: 18, offset: 1 }} xs={{ span: 17, offset: 1 }}>
            <Row gutter={[0, 3]}>
              <Text className="Plsecurepdp" style={{ cursor: "pointer" }}>
                Markid’s buyer protection guarantee
              </Text>
              <Col span={3} />
            </Row>
            <Row>
              <Typography className="pLListedItemdesc">
                Your transactions are protected. All payments are held until you
                receive your item, or 100% refund guaranteed.
              </Typography>

              <Col span={15} />
            </Row>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 15]}>
          {/* <Typography className="plsimilarpdp"></Typography> */}
          {/* <Divider style={{ color: "#707070", margin: "15px 0px" }} /> */}
          <Col span={24}></Col>
          <Col xs={24}>
            {isActive === true && (
              <Row>
                <Col>
                  {productLoader ? (
                    <Skeleton active={productLoader} size="small" />
                  ) : (
                    <Typography className="sellertitlepdp">
                      Meet the seller
                    </Typography>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Row gutter={[0, 24]} className="sellerRating">
                <Col span={24} />
                <Col>
                  {productLoader ? (
                    <Skeleton.Image active={productLoader} size="small" />
                  ) : photo && photo ? (
                    <Avatar
                      shape="square"
                      style={{
                        height: 60,
                        width: 60,
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                      src={photo}
                      onClick={() =>
                        history.push(`/seller-profile/${sellerId}`)
                      }
                    />
                  ) : (
                    <img
                      alt=""
                      src={swwondlelogo}
                      style={{ cursor: "pointer" }}
                      // onClick={() =>
                      //   history.push(`/seller-profile/${sellerId}`)
                      // }
                    />
                  )}
                  <Row gutter={[0, 8]}>
                    <Col span={24} />
                    <Col>
                      {productLoader ? (
                        <Skeleton active={productLoader} size="small" />
                      ) : (
                        <Text className="pLVerifiedpdp style2">
                          <img src={verifiednew} width="12px" /> Verified
                        </Text>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={{ span: 15, offset: 1 }}
                  lg={{ span: 15, offset: 1 }}
                  md={{ span: 15, offset: 1 }}
                  sm={{ span: 18, offset: 1 }}
                  xs={{ span: 18, offset: 1 }}
                >
                  {productLoader ? (
                    <Skeleton.Input active={productLoader} size="small" />
                  ) : (
                    <Text
                      className="Plsellernamepdp"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/seller-profile/${sellerId}`)
                      }
                    >
                      {capitalize("Swoondle Society")}
                    </Text>
                  )}

                  <Row gutter={[0, 5]}>
                    <Col span={24} />
                    {productLoader ? (
                      <Skeleton.Button active={productLoader} size="small" />
                    ) : (
                      <Rate
                        defaultValue={5}
                        className="pLRatepdp"
                        disabled={true}
                      />
                    )}
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col />
                    {/* {productLoader ? (
                      <Skeleton active={productLoader} size="small" />
                    ) : (
                      <Text className="pLListedItempdp">
                        {"65"} items listed
                      </Text>
                    )}
                    <Text className="pLListedItempdp1">|</Text>
                    {productLoader ? (
                      <Skeleton active={productLoader} size="small" />
                    ) : (
                      <Text className="pLListedItempdp">{"21"} sold</Text>
                    )} */}
                    <Typography className="pLListedItemdesc">
                      This product is screened, photographed & verified by our
                      brand partner Swoondle Society.
                      <br />
                      Free Shipping nationwide over $50. This item is eligible
                      for Free Returns
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row>
              <div className="plsimilarcenter">
                {/* <Divider style={{ color: "#707070", margin: "15px 0px" }} /> */}
                <Typography className="plsimilarpdp">
                  Have a similar item?
                  <span
                    className="plsimilarpdp1"
                    onClick={() => {
                      if (providerToken) {
                        gaEventClick("PDP", "Sell on markid");
                        history.push("/seller/addproduct");
                      } else {
                        gaEventClick("Navigation", "Sell on markid", "SignUp");
                        history.push("/onboarding");
                        // window.location.reload(false);
                      }
                    }}
                  >
                    Sell it on Markid
                  </span>{" "}
                </Typography>
              </div>
            </Row>
          </Col>
        </Row>
        {isRenewed && (
          <Row justify="center" gutter={[0, 35]}>
            <Col span={22}>
              <Card className="rIBackground">
                <Row>
                  <Col>
                    <Typography className="rIText">
                      reNEWED Information
                    </Typography>
                  </Col>
                </Row>
              </Card>
              <Card className="rIDesBackground">
                <Row justify="start">
                  <Col>
                    <Typography className="riDes">
                      The simplest way to sell kid's gently used, high-quality
                      furniture and gear-effortlessly, sustainably and
                      purposefully.
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Col span={24}>
                    <Button block className={"pLMakeOffer"}>
                      KNOW MORE
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </div>

      <MakeOffer
        onCancelMakeOfferDialog={() => setShowMakeOfferDialog(false)}
        showMakeOfferDialog={showMakeOfferDialog}
        makeOfferData={productData}
        sellerInfo={sellerInfo}
      />
      <MessageSeller
        onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)}
        showMessageSellerDialog={showMessageSellerDialog}
      />
      <NotifySoon
        onCancelNotifySoonMethod={() => setShowNotifySoonDialog(false)}
        showNotifySoonDialog={showNotifySoonDialog}
        notifyData={productData}
      />
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default PLDpage;
