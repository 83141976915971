import React from "react";
import { Card, Typography, Row, Col } from "antd";
import "../App.css";
export const Productresults = (props) => {
  const { currentCategory, resultValue, search } = props;
  const {} = currentCategory || {};

  return (
    <div className="pCategoryParent categoryPage">
      {search && (
        <Typography className="searchresults">
          Search result for ‘{search}’
        </Typography>
      )}
      {resultValue > 0 ? (
        <Typography className="pCResultmob">
          {" "}
          Showing ({resultValue} RESULTS)
        </Typography>
      ) : (
        <Typography className="pCResultmob"> Showing RESULTS....</Typography>
      )}
    </div>
  );
};
