import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Card, Typography, Row, Col, Layout, Avatar, Button, Divider, Input, Spin, Affix } from "antd";
import { MoreOutlined, CloseOutlined, SendOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getMessagesByChatId, postMessageByChatId } from "../Actions/chatsAction";
import { getOffers } from "../Actions/offerAction";
import { Type0, Type1 } from "../components/TypeOfMessages"
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import ChatBackArrow from "../assets/ChatBackArrow.svg";
import moreIcon from '../assets/MoreIcon.jpg';
const { Header, Content } = Layout
export const ChatingSectionMob = (props) => {
  const dispatch = useDispatch()
  const { messagesByChatId, currentUser, isGetMessagesByChatIdLoading, offers } = useSelector((state) => {
    const { messagesByChatId, isGetMessagesByChatIdLoading } = state.chats
    const { currentUser } = state.user
    const { offers } = state.offer
    return {
      messagesByChatId,
      currentUser,
      isGetMessagesByChatIdLoading,
      offers
    };
  });
  const { status } = offers || {}
  const { messages } = messagesByChatId
  const { _id: currentUserId, blockedUsers } = currentUser
  const [questions, setQuestions] = useState(false)
  const [makeOffer, setMakeOffer] = useState(false)
  const [priceOffVal, setPriceOffVal] = useState("");
  const [lastType1MessageId, setLastType1MessageId] = useState('');
  const [chatLastAcceptedOfferMessageId, setchatLastAcceptedOfferMessageId] = useState('');
  const [chatCounterOfferMessageId, setChatCounterOfferMessageId] = useState('');
  const { userChating, ChatListData, currentChat, setUserChatListMobile, setUserChatMobile } = props
  const { chat, lot, interlocutor } = currentChat || {}
  const { photo, fullName } = interlocutor || {}
  const { name, price, deliveryType, createdBy: LotCreatedBy, imageUrls } = lot || {}
  const { _id, offerId, participants } = chat || {}
  const {innerHeight} = window;
  // const {lot} = currentChat
  const { userImage, userName } = userChating
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: { message: '' }
  });
  const { handleSubmit: mOHandleSubmit, control: mOControl, reset: mOReset, errors: moErrors, setValue: setFormValues } = useForm({
    defaultValues: {price: ''}
  });
  const watching = watch()
  const { message } = watching;

  let isBlocked;
  let participantsId = participants && participants[1];
  blockedUsers && blockedUsers.map((user) => {
    return (user === participantsId ) && (isBlocked = user)
  });

  const handleQuestionsClick = () => {
    setQuestions(true)
    setMakeOffer(false)
  }
  const handleMakeOfferClick = () => {
    setQuestions(false)
    setMakeOffer(true)
  }
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  useEffect(() => {
    dispatch(getMessagesByChatId(_id));
    dispatch(getOffers(offerId, _id))
  }, [_id]);
  const handleSendMessage = (data) => {
    data.type = 0
    dispatch(postMessageByChatId(_id, data))
    reset()
  }
  const handleBack = () => {
    setUserChatMobile(false)
    setUserChatListMobile(true)
  }
  const handlePriceOff = (offPriceInputVal) => {
    setFormValues("price", offPriceInputVal)
    setPriceOffVal(offPriceInputVal)
  }


  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
    if (messages) {
      let newLastType1MessageId = '';
      let newchatLastAcceptedOfferMessageId = '';
      let newchatCounterOfferMessageId = '';
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].type !== 0 && newLastType1MessageId === '') {
          newLastType1MessageId = messages[i]._id;
        }
        if ((messages[i].type === 3 || messages[i].type === 4) && newchatLastAcceptedOfferMessageId === '') {
          newchatLastAcceptedOfferMessageId = messages[i]._id;
        }
        if ((messages[i].type === 1 || messages[i].type === 2) && newchatCounterOfferMessageId === '') {
          newchatCounterOfferMessageId = messages[i]._id;
        }
        if (newchatLastAcceptedOfferMessageId !== '' && newLastType1MessageId !== '' && newchatCounterOfferMessageId !== '') {
          break;
        }
      }
      setLastType1MessageId(newLastType1MessageId);
      setchatLastAcceptedOfferMessageId(newchatLastAcceptedOfferMessageId);
      setChatCounterOfferMessageId(newchatCounterOfferMessageId);
    }
    dispatch(getOffers(offerId, _id))
  }, [messages]);


  const tenPercent = price * 10 / 100
  const tenPercentVal = price - tenPercent

  const sixPercent = price * 6 / 100
  const sixPercentVal = price - sixPercent

  const threePercent = price * 3 / 100
  const threePercentVal = price - threePercent
  if (currentChat) {
    return (
      <Layout style={{position:'relative'}}>
    {/* <Affix offsetTop={0} style={{ position: "sticky", zIndex: 10 }}> */}
        <Header className='chatHeader'>
          <Row justify='space-between'>
            <Col>
              <Row gutter={[8.56, 0]}>
                <Col>
                  <img alt="" src={ChatBackArrow}
                    onClick={() => handleBack()}
                  />
                </Col>
                <Col>
                  {photo ?
                    <Avatar shape='circle' size={50} src={photo} />
                    :
                    <Avatar shape='circle' size={50} src={UserBlanckImg} />
                  }
                </Col>
                <Col>
                  <Row gutter={[0, 10]}>
                    <Col span={24}></Col>
                    <Typography className='pMenu'>
                      {fullName}
                    </Typography>
                  </Row>
                  <Row gutter={[0, 6]}>
                    <Col span={24}></Col>
                    <Typography className='moniterdByMarkidText'>
                      All messages are monitered by markid
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row gutter={[0, 0]}>
                <Col>
                  {/* <MoreOutlined className='moreIcon' /> */}
                  <img src={moreIcon} alt="" className="moreIcon"/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
{/* </Affix> */}
        <Row>
          <Col span={24}>
            <Card className='chatItemNameCard'>
              <Row justify="start" align="bottom" gutter={[20,0]}>
                <Col xs={3}>
                      <Avatar shape='square' style={{borderRadius:'5'}} size={50} src={imageUrls && imageUrls[0]} />
                </Col>
                <Col span={1}/>
                <Col xs={19}>
                 <Row>
                    <Col>
                      <Typography className='chatItemname'>
                        {name &&
                          capitalize(name)
                        }
                      </Typography>
                    </Col>
                 </Row>
                 <Row justify='space-between' gutter={[0, 3]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className='chatPriceVal'>
                        Price - ${price ? price / 100 : null}
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className='chatShippingVal'>
                        Shipping
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Content className='chatContent'>
          <Row justify='center' align='bottom' style={{ height: makeOffer || questions ? 195 : innerHeight - 214, overflowY: 'scroll', overflowX: 'hidden', padding:'10px 0px 0px' }}>
            <Col span={22}>
              {isGetMessagesByChatIdLoading &&
                <Row justify='center'>
                  <Spin spinning={isGetMessagesByChatIdLoading} size='large' />
                </Row>
              }
              {/* <Content className='chatWindow'> */}
              {messages && (messages).map((val, i) => {
                if (val.createdBy !== currentUserId) {
                  return (
                    <Row gutter={[7.45, 48]} key={i}>
                      <Col>
                      {photo ?
                        <Avatar shape='circle' size={36.8} src={photo} />
                        :
                        <Avatar shape='circle' size={36.8} src={UserBlanckImg} />
                      }
                      </Col>
                      <Col>
                        <Row>
                          {val.type === 0 ?
                            <Type0 cName="reciverMessageCard"
                              messageData={val}
                              type='reciver'
                            />
                            :
                            <Type1 cName="reciverMessageCard"
                              messageData={val}
                              offerId={offerId}
                              chatId={_id}
                              type='reciver'
                              messageType={val.type}
                              deliveryType={deliveryType}
                              LotCreatedBy={LotCreatedBy}
                              currentUserId={currentUserId}
                              lastType1MessageId={lastType1MessageId}
                              chatLastAcceptedOfferMessageId={chatLastAcceptedOfferMessageId}
                              chatCounterOfferMessageId={chatCounterOfferMessageId}
                              messageId={val._id}
                              productData={lot}
                            />
                          }
                        </Row>
                      </Col>
                      <Col span={24}></Col>
                    </Row>
                  )
                }
                else {
                  // senderInformation
                  return (
                    <>
                      <Row gutter={[0, 30]} justify='end' key={i}>
                        <Col>
                          <Row>
                            {val.type === 0 ?
                              <Type0 cName="senderMessageCard"
                                messageData={val}
                                type='sender'
                              />
                              :
                              <Type1 cName="senderMessageCard"
                                messageData={val}
                                offerId={offerId}
                                chatId={_id}
                                type='sender'
                                messageType={val.type}
                                LotCreatedBy={LotCreatedBy}
                                currentUserId={currentUserId}
                                productData={lot}
                                lastType1MessageId={lastType1MessageId}
                                chatLastAcceptedOfferMessageId={chatLastAcceptedOfferMessageId}
                                chatCounterOfferMessageId={chatCounterOfferMessageId}
                                messageId={val._id}
                                deliveryType={deliveryType}
                              />
                            }
                          </Row>
                        </Col>
                        <Col span={24}></Col>
                      </Row>
                    </>
                  )
                }

              })}
            </Col>
          </Row>
          {isBlocked === participantsId ? <Row></Row>
          :
          <Affix offsetBottom={0} style={{ position: "sticky", zIndex: 10 }}>
            <div>
          <Row justify='center'>
            <Col span={24}>
              {questions &&
                <>
                  <CloseOutlined onClick={() => setQuestions(false)} className='closeBg' />
                  <Row justify='center'>
                    <Col span={24}>
                      <Card className='makeOfferCard'>
                        <Row gutter={[10, 10]} style={{ overflowX: 'scroll' }}>
                          <Col span={24}></Col>
                          <Col>
                            <Button className='questionsTextButton'>
                              What is the condition of the stroller?
                            </Button>
                          </Col>
                          <Col>
                            <Button className='questionsTextButton'>
                              How many years old is the stroller?
                            </Button>
                          </Col>
                          <Col>
                            <Button className='questionsTextButton'>
                              Is it foldable?
                            </Button>
                          </Col>
                          <Col>
                            <Button className='questionsTextButton'>
                              More
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </>
              }
              {makeOffer &&
                <>
                  <CloseOutlined onClick={() => setMakeOffer(false)} className='closeBg' />
                  <form onSubmit={mOHandleSubmit()}>
                  <Row justify='center'>
                    <Col span={24}>
                      <Card className='makeOfferCard'>
                        <Row>
                          <Col>
                            <Typography className='mOCurrentPrice'>
                              Current price - $ {price ? price / 100 : null}
                            </Typography>
                          </Col>
                        </Row>
                        <Row gutter={[10, 14]}>
                          <Col span={24}></Col>
                          <Col>
                            <Button className='questionsTextButton'
                             onClick={() => handlePriceOff(tenPercentVal / 100)}
                            >
                              $ {tenPercentVal / 100}
                            </Button>
                            <Row justify='center' gutter={[0, 5]}>
                              <Col span={24}></Col>
                              <Typography className='offPercentage'>
                                10% off
                              </Typography>
                            </Row>
                          </Col>
                          <Col>
                            <Button className='questionsTextButton'
                             onClick={() => handlePriceOff(sixPercentVal / 100)}
                            >
                              $ {sixPercentVal / 100}
                            </Button>
                            <Row justify='center' gutter={[0, 5]}>
                              <Col span={24}></Col>
                              <Typography className='offPercentage'>
                                6% off
                              </Typography>
                            </Row>
                          </Col>
                          <Col>
                            <Button className='questionsTextButton'
                             onClick={() => handlePriceOff(threePercentVal / 100)}
                            >
                              $ {threePercentVal / 100}
                            </Button>
                            <Row justify='center' gutter={[0, 5]}>
                              <Col span={24}></Col>
                              <Typography className='offPercentage'>
                                3% off
                              </Typography>
                            </Row>
                          </Col>
                        </Row>
                        <Row gutter={[15, 24]}>
                          <Col span={24}></Col>
                          <Col span={16}>
                            <Controller
                              as={<Input />}
                              bordered={false}
                              placeholder='$ Offer price'
                              className="phoneInput"
                              size='large'
                              addonBefore="$"
                              name="price"
                              control={mOControl}
                            />
                          </Col>
                          <Col>
                            <Button size='large' className='moSendButton'>Send</Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  </form>
                </>
              }
            </Col>
          </Row>
          <Row gutter={[0, 20]} justify='center'>
            <Col span={24} className="chatboxParent">
              <Card className='chatboxCard'>
              {(status === "CANCELED_BY_BUYER" || status === "CANCELED_BY_SELLER") &&
              <>
                    <Row gutter={[25.25, 0]} justify="space-around">
                      <Col>
                        <Button
                          type='text'
                          className={questions === true ? 'selectedquestionsButton' : 'questionsButton'}
                          onClick={() => handleQuestionsClick()}
                        >
                          Questions
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type='text'
                          className={makeOffer === true ? 'selectedquestionsButton' : 'questionsButton'}
                          onClick={() => handleMakeOfferClick()}
                        >
                          Make Offer
                        </Button>
                      </Col>
                    </Row>
                 <Divider style={{margin:'16px 0px'}} />
                 </>
                  } 
                <Row>
                  <Col span={24}>
                    <form onSubmit={handleSubmit(handleSendMessage)}>
                      <Controller
                        as={<Input />}
                        placeholder='Type your message'
                        className="messageInput"
                        addonAfter={
                          <Button
                            disabled={message && message.length > 0 ? false : true}
                            bordered={false} htmlType='submit' shape='circle' className={message && message.length > 0 ? 'activeSnedIconBg' : 'snedIconBg'} icon={<SendOutlined className='snedIcon'
                            />}></Button>
                        }
                        // size='large'
                        name="message"
                        control={control}
                      />
                    </form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          </div>
          </Affix>
        }
        </Content>
      </Layout>
    )
  }
  else {
    return (<Layout><Row justify='center' gutter={[0, 0]} align="bottom" className='blankchatContent'>
    </Row></Layout>);
  }
};
