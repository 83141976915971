/*eslint-disable*/
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Typography,
    Divider,
    Alert,
    Input,
    Button,
    Checkbox,
  } from "antd";
const { Text, Title } = Typography;
const OrderSummary = (props) => {
  const dispatch = useDispatch();
    const { heading, codeInput, markidCredit, itemTotal, itemTotalVal, shipping, shippingVal,
       salesTax, salesTaxVal, cartTotal, cartTotalVal, button, addressError } = props;
       const { currentUserWallet, useCredits } = useSelector((state) => {
        const { currentUserWallet, useCredits } = state.cart;
        return {
          currentUserWallet,
          useCredits
        };
      });
      const { balance } = currentUserWallet
      // const [useCredits, setUsecredits] = React.useState(false);
      const handleUseCredits = () => {
        dispatch({type: "USE_MARKID_CREDIT", payload: {useCredits: !useCredits}})
      }
      const numberItemTotalValue = parseFloat(itemTotalVal)
  return (
    <>
       <Row gutter={[0,48]}>
           <Col span={24}></Col>
              <Col>
                <Title level={4} className='notify-mep'>
                 {heading}
                </Title>
              </Col>    
            </Row>
            <Row gutter={[0,16]}>
           <Col span={24}></Col>
              {/* <Col>
              <Input suffix={<Button type='text'>CHECK</Button>} placeholder='Promo Code' />
              </Col> */}
              </Row>
              {balance > 0 &&
               <Row gutter={[0,8]}>
               <Col span={24}></Col>
                  <Col span={24}>
                  <Row justify="space-between">
              <Col>
                <Text className='items-total'>Markid Credits</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${balance.toFixed(2)}</Text>
              </Col>
            </Row>
                  </Col>
              <Col>
              <Checkbox checked={useCredits} onChange={() => handleUseCredits()}>Use my Markid credits</Checkbox>
                  </Col>
                  </Row>
}
                  <Divider />
            <Row justify="space-between" >
                <Col span={24}></Col>
              <Col>
                <Text className='items-total'>{itemTotal}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${itemTotalVal}</Text>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{shipping}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>{shippingVal}</Text>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{salesTax}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${salesTaxVal}</Text>
              </Col>
            </Row>
            {useCredits &&
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>Markid Credits</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>- ${ balance > numberItemTotalValue ? numberItemTotalValue : balance.toFixed(2)}</Text>
              </Col>
            </Row>
}
            <Divider />
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{cartTotal}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${useCredits ? ( balance > numberItemTotalValue ? 0.0 : itemTotalVal - balance): itemTotalVal}</Text>
              </Col>
            </Row>
            <Divider />
            {addressError &&
           <Row>
             <Col>
             <Alert message="Select Address" type="error" />
             </Col>
           </Row>
}
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
              {button}
              </Col>
            </Row>
    </>
  );
};
export default OrderSummary;
