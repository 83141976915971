import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col } from "antd";
// import { useHistory } from "react-router-dom";
import "../App.css";
export const WayToSellCard = (props) => {
  const { wTSTitle, subTitle, heartImgOne, titleOne, descriptionsOne, heartImgTwo, titleTwo, descriptionsTwo,
    heartImgThree, titleThree, descriptionsThree, heartImgFour, titleFour, descriptionsFour,
    availableCity, startButton, mob } = props
  return (
    <div className="wTSParent">
      <Card className='wTSCard'>
        <Row justify='center' gutter={[0, { xs: 24.64, sm: 24.64, md: 40, lg: 40 }]}>
          {!mob && <Col span={24}></Col>}
          <Col>
            <Typography
              className="wTSFirstTitle"
            >
              {wTSTitle}
            </Typography>
          </Col>
        </Row>
        <Row justify='center' gutter={[0, { xs: 4.11, sm: 4.11, md: 8, lg: 8 }]}>
          <Col span={24}></Col>
          <Col>
            <Typography
              className="wTSSubTitle"
            >
              {subTitle}
            </Typography>
          </Col>
        </Row>

        <Row gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0 }]}>
          <Col span={24}></Col>
          <Col xl={8} lg={8} sm={24} xs={24} className="sellCardIn">
            {heartImgOne}
            <Row gutter={[0, { xs: 4.68, sm: 4.68, md: 8, lg: 8 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography
                  className="describeTitle"
                >
                  {titleOne}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 4.1, sm: 4.1, md: 7, lg: 7 }]}>
              <Col span={24}></Col>
              <Col xl={18}>
                <Typography
                  className="describeDes"
                >
                  {descriptionsOne}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xl={8} lg={8} sm={24} xs={24}  className="sellCardIn">
            {heartImgTwo}
            <Row gutter={[0, { xs: 4.68, sm: 4.68, md: 8, lg: 8 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography
                  className="describeTitle"
                >
                  {titleTwo}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 4.1, sm: 4.1, md: 7, lg: 7 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography
                  className="describeDes"
                >
                  {descriptionsTwo}
                </Typography>
              </Col>
            </Row>
          </Col>
          {/* </Row>

        <Row gutter={[0, { xs: 29.36, sm: 29.36, md: 40, lg: 40 }]}> */}
          {/* <Col xl={6} lg={6} sm={24} xs={24}  className="sellCardIn">
            {heartImgThree}
            <Row gutter={[0, { xs: 4.68, sm: 4.68, md: 8, lg: 8 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography
                  className="describeTitle"
                >
                  {titleThree}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 4.1, sm: 4.1, md: 7, lg: 7 }]}>
              <Col>
                <Typography
                  className="describeDes"
                >
                  {descriptionsThree}
                </Typography>
              </Col>
            </Row>
          </Col> */}
          <Col xl={8} lg={8} sm={24} xs={24} className="sellCardIn lastCard">
            {heartImgFour}
            <Row gutter={[0, { xs: 4.68, sm: 4.68, md: 8, lg: 8 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography
                  className="describeTitle"
                >
                  {titleFour}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 4.1, sm: 4.1, md: 7, lg: 7 }]}>
              <Col>
                <Typography
                  className="describeDes"
                >
                  {descriptionsFour}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row gutter={[0, { xs: 39.52, sm: 39.52, md: 0, lg: 0 }]}>
          <Col>
            <Typography className='availableCity'
              style={{
                paddingTop: availableCity ? "none" : "55px",
                marginBottom: mob ? 5 : 3,
                fontSize: mob && '10px'
              }}>
              {availableCity}
            </Typography>
          </Col>
        </Row> */}
        <Row justify="center" gutter={[0, 12]}>
          {!mob && <Col span={24}></Col>}
          <Col sm={24} xs={24} lg={6} xl={6}>
            {startButton}
          </Col>
        </Row>
      </Card>
    </div>
  );
};
