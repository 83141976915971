import API from "../Utils/API";

export const getAllLots = (data) => (dispatch) => {
  dispatch({ type: "GET_ALL_LOTS_REQUEST" });
  let url = "/api/v2/lots/renewed";
  if (data && data.search) {
    url = `/api/v2/lots/renewed?search=${data.search}`;
  }
  // else if (data && data.category) {
  //   url = `/api/v2/lots?category=${data.category}`
  // }
  // else if (data && data.brand) {
  //   url = `/api/v2/lots?brands=${data.brand}`
  // }
  API.get(url)
    .then((response) => {
      dispatch({
        type: "GET_ALL_LOTS_SUCCESS",
        payload: response.data,
        search: data && data.search,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_ALL_LOTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
export const getAllmarkidProducts = (data) => (dispatch) => {
  //dispatch({ type: "GET_ALL_LOTS_REQUEST" });
  let url = "/api/v5/markid-products/search";
  if (data && data.search) {
    url = `/api/v5/markid-products/search?search=${data.search}`;
  }
  // else if (data && data.category) {
  //   url = `/api/v2/lots?category=${data.category}`
  // }
  // else if (data && data.brand) {
  //   url = `/api/v2/lots?brands=${data.brand}`
  // }
  API.get(url)
    .then((response) => {
      dispatch({
        type: "GET_ALL_MARKIDPRODUCTS_SUCCESS",
        payload: response.data,
        search: data && data.search,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_ALL_MARKIDPRODUCTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
export const getAlgoliaBrands = (data) => (dispatch) => {
  dispatch({ type: "GET_ALGOLIABRANDS_REQUEST" });
  let url = `/api/v5/markid-products/algolia-search/brands?search=${data.search}`;
  API.get(url)
    .then((response) => {
      dispatch({
        type: "GET_ALGOLIABRANDS_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_ALGOLIABRANDS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getAllSearchLots = (data) => (dispatch) => {
  dispatch({ type: "GET_NON_RENEWED_LOTS_REQUEST" });
  let url = "/api/v2/lots/";
  if (data && data.search) {
    url = `/api/v2/lots/?search=${data.search}`;
  }
  // else if (data && data.category) {
  //   url = `/api/v2/lots?category=${data.category}`
  // }
  // else if (data && data.brand) {
  //   url = `/api/v2/lots?brands=${data.brand}`
  // }
  API.get(url)
    .then((response) => {
      dispatch({
        type: "GET_NON_RENEWED_LOTS_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_NON_RENEWED_LOTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getFilterLots = (data, url) => (dispatch) => {
  dispatch({ type: "GET_FILTER_LOTS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_FILTER_LOTS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_FILTER_LOTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getYearOfDetails = (data) => (dispatch) => {
  dispatch({ type: "GET_YEAR_OF_DETAILS_REQUEST" });
  let url = "/api/v2/lots/details";
  if (data && data.category) {
    url = `/api/v2/lots/details?category=${data.category}`;
  } else if (data && data.brand) {
    url = `/api/v2/lots/details?brands=${data.brand}`;
  }
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_YEAR_OF_DETAILS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_YEAR_OF_DETAILS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getLotById = (Lot_id) => (dispatch) => {
  dispatch({ type: "GET_LOT_BY_ID_REQUEST" });
  API.get(`/api/v2/lots/${Lot_id}`)
    .then((response) => {
      dispatch({ type: "GET_LOT_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_LOT_BY_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const createLot =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "CREATE_LOT_REQUEST" });
    API.post("/api/v2/lots/create", data)
      .then((response) => {
        dispatch({ type: "CREATE_LOT_SUCCESS", payload: response });
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_LOT_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

export const addToFavorites =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "ADD_TO_FAVORITES_REQUEST" });
    API.post(`/api/v2/lots/${data.id}/add-to-favorites`, data)
      .then((response) => {
        dispatch({ type: "ADD_TO_FAVORITES_SUCCESS", payload: response });
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "ADD_TO_FAVORITES_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

export const removeFromFavorites =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "REMOVE_FROM_FAVORITES_REQUEST" });
    API.post(`/api/v2/lots/${data.id}/remove-from-favorites`, data)
      .then((response) => {
        dispatch({ type: "REMOVE_FROM_FAVORITES_SUCCESS", payload: response });
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "REMOVE_FROM_FAVORITES_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

export const uploadProductPhoto =
  (
    data,
    successCallBackUploadProductPhoto,
    failureCallBackUploadProductPhoto
  ) =>
  (dispatch) => {
    dispatch({ type: "UPLOAD_PRODUCT_PHOTO_REQUEST", payload: data });
    var formData = new FormData();
    formData.append("file", data.file);
    formData.append("fileName", data.fileName);
    API.post("/api/v1/utility/image-upload", formData)
      .then((response) => {
        dispatch({
          type: "UPLOAD_PRODUCT_PHOTO_SUCCESS",
          payload: response.data,
        });
        successCallBackUploadProductPhoto(response.data);
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UPLOAD_PRODUCT_PHOTO_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBackUploadProductPhoto();
        } catch {
          // console.log(error);
        }
      });
  };
