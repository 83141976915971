import React, { useEffect, useState } from "react";
import { Button } from "antd";
import Blogimg from "../assets/new-assets/Blogimg.png";
import bookmark1 from "../assets/new-assets/bookmark1.png";
import bookmark2 from "../assets/new-assets/bookmark2.png";
import shareicon from "../assets/new-assets/shareBlog.png";
import ReadMoreVector from "../assets/new-assets/ReadMoreVector.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ShareBlogModal } from "./ShareBlogModal";
import { getBlogsimages } from "../Actions/BlogsAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BlogAddBookmark, BlogRemoveBookmark } from "../Actions/BlogsAction";
const HeroArticleBlog = (props) => {
  const history = useHistory();
  const { Blogs, modifiedBlogs, Blogimages, Blogbookmark } = props;
  const [color, setColor] = useState("#fff");
  const notify = () => {
    if (localStorage.getItem("access_token")=== null){
    toast("LOGIN TO BOOKMARK!");
    }}
  useEffect(() => {
    if (modifiedBlogs[0]?.array[0]?.name === "sleeping") {
      setColor("#FFE8B6");
    } else if (modifiedBlogs[0]?.array[0]?.name === "Feeding") {
      setColor("#FFDBDB");
    } else if (modifiedBlogs[0]?.array[0]?.name === "Newborns") {
      setColor("#FFE8B6");
    } else if (modifiedBlogs[0]?.array[0]?.name === "Toddler Care") {
      setColor("#E3E6FFE5");
    } else if (modifiedBlogs[0]?.array[0]?.name === "Pregnancy & Postpartum") {
      setColor("#FECCFFE5");
    } else if (modifiedBlogs[0]?.array[0]?.name === "Sleep Time") {
      setColor("#CDF3FF");
    } else if (modifiedBlogs[0]?.array[0]?.name === "Childcare") {
      setColor("#FFBEA3E5");
    } else if (modifiedBlogs[0]?.array[0]?.name === "Parent Corner") {
      setColor("#D3FFCCE5");
    }
  }, [Blogs]);
  const dispatch = useDispatch();
  const [showBlogDialog, setshowBlogDialog] = useState(false);
  const handleOpenBlog = () => {
    setshowBlogDialog(true);
  };
  const [isBackgroundRed, setisBackgroundRed] = useState(false);
  const handleAddBookmark = () => {
    if (localStorage.getItem("access_token") != null) {
      if (!Blogbookmark?.bookmarks?.includes(Blogs?.id)) {
        setisBackgroundRed(true);
        dispatch(BlogAddBookmark(Blogs?.id));
        toast("ADDED TO FAVORITES")
      } else {
        setisBackgroundRed(false);
        dispatch(BlogRemoveBookmark(Blogs?.id));
        toast("REMOVED FROM FAVORITES")
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      if (Blogbookmark?.bookmarks?.includes(Blogs?.id)) {
        setisBackgroundRed(true);
      }
    }
  }, []);
  return (
    <>
      <div class="BlogPost">
        <img
          src={Blogimages?.guid.rendered}
          className="HeroBlogimageHeight"
          alt="Avatar"
          style={{ width: "100%" }}
        />
        <span>
          <Button onClick={() => handleOpenBlog()}>
            <img src={shareicon} className="BlogHeroImageIcons" />
          </Button>
          <Button
            onClick={() => {
              handleAddBookmark();notify()
            }}
          >
            {!isBackgroundRed ? (
              <img src={bookmark1} className="BlogImageIcons" />
            ) : (
              <img src={bookmark2} className="BlogImageIcons" />
            )}
          </Button><ToastContainer/>
        </span>
        {modifiedBlogs && modifiedBlogs[0]?.array[0]?.name && (<span className="BlogHerotagsIcon">
          {" "}
          <div className="Blogtags" style={{ background: color }}>
            <p>{modifiedBlogs[0]?.array[0]?.name}</p>
          </div>
        </span>)}
        
        <div class="BlogHeroContainer">
          {/* <div className="BlogTimeReading">
            <b>x mins read</b>
          </div> */}
          <h2>
            <b
              className="BlogParagraphHeading"
              dangerouslySetInnerHTML={{ __html: Blogs?.title.rendered }}
            />
          </h2>
          <p
            className="BlogParagraph"
            dangerouslySetInnerHTML={{ __html: Blogs?.excerpt.rendered }}
          />

<div className="Blogdatetime">
            <p className="blogPostTime"> {moment(new Date(Blogs?.date)).format("MMMM do, yyyy")}{" "}</p>
              <Button
                onClick={() => {
                  history.push(`/learn/blogdetail/${Blogs?.id}`);
                }}
              >
                <div class="BlogReadMore">
                  Read More
                  <img src={ReadMoreVector} />
                </div>
              </Button>
            </div>
        </div>
        {/* <div class="BlogHeroContainer"></div> */}
      </div>
      <ShareBlogModal
        Blogsid={Blogs?.id}
        showBlogDialog={showBlogDialog}
        onCancelBlogDialog={() => setshowBlogDialog(false)}
      />
    </>
  );
};
export default HeroArticleBlog;