
/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
	Row,
	Col,
	Card,
	Button,
	Spin,
	Typography,
	Layout,
} from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import CheckoutPaymentForm from "../components/CheckoutPaymentForm";
import { useBreakpoints } from './../react-responsive-hooks/index';
import PriceDetails from "../components/PriceDetails";
import "../App.css";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from "react-router-dom";
import CheckoutTopBar from "../components/CheckoutTopBar";
import { useGaEvent } from "../hooks/useGaEvent";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const appearance = {
	theme: 'stripe',

	variables: {
		colorPrimary: '#0570de',
		colorBackground: '#ffffff',
		colorText: '#30313d',
		colorDanger: '#df1b41',
		fontFamily: 'Ideal Sans, system-ui, sans-serif',
		spacingUnit: '2px',
		borderRadius: '4px',
		// See all possible variables below
	}
};

const CheckoutPayment = (props) => {
	// const {innerHeight} = window;
	const { gaEventClick } = useGaEvent();
	const history = useHistory()
	const [current,setCurrent]=useState(1)
	const { isXS } = useBreakpoints();
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const { nextMethod, prevMethod } = props
	const { currentCart, currentAddressId, currentUser, currentUserWallet, isPostCartLoading, isPostOrderLoading, offerPaymentLoader, orderDetails, productsInCart } = useSelector((state) => {
		const { currentCart, currentAddressId, currentUserWallet, isPostCartLoading, productsInCart } = state.cart;
		const { currentUser } = state.user;
		const { isPostOrderLoading, orderDetails } = state.order;
		const { offerPaymentLoader } = state.offer
		return {
			currentCart,
			currentAddressId,
			currentUserWallet,
			currentUser,
			isPostCartLoading,
			isPostOrderLoading,
			offerPaymentLoader,
			orderDetails,
			productsInCart
		};
	});
	
	const [usePaymentMethod, setUsePaymentMethod] = useState(true)
	const [callStripeApi, setCallStripeApi] = useState(false)
	const [paymentLoader, setPaymentLoader] = useState(false);
	const [shippingCharges, setShippingCharges] = useState(0)
	const [freeShipping, setFreeShipping] = useState(false)
	// const handleUsePaymentMethod = () => {
	// 	setUsePaymentMethod(false)
	// }
	const [ unavailableProducts, setUnavailableProducts ] = useState([])
	useEffect(() =>{
		if(productsInCart){
			productsInCart.map((products) =>{
				if(products.isSold == true && unavailableProducts.indexOf(products.lotId) === -1){
					unavailableProducts.push(products.lotId)
				}
			})
		}
	},[productsInCart])
	let tempPrice = 0;
	useEffect(() => {
		if (currentCart && currentCart.length > 0) {
			currentCart.map((cartItems) => {
				if (cartItems.isFree == false) {
					tempPrice = parseInt(cartItems.shippingPrice) + tempPrice
					setShippingCharges(tempPrice)
				}
				else if (cartItems.isFree == true) {
					setFreeShipping(true)
				}
			})
		}
	}, [currentCart])
	useEffect(() => {
		if (shippingCharges && shippingCharges <= 0 && tempPrice <= 0) {
			setShippingCharges(parseFloat(currentCart[0].shippingPrice))
		}
	}, [shippingCharges])
	return (
		<Layout>
			 <CheckoutTopBar current={current}/>
			<div className='checkoutBG'>
				<div className="app-wrapper">
					<Spin indicator={antIcon} spinning={offerPaymentLoader}>
						<Row gutter={[0, { xs: 30, sm: 30, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
							{/* <Col span={24}></Col> */}
							<Col span={24}>
								<Row gutter={[{ xs: 13, sm: 13, md: 28, lg: 28, xl: 28, xxl: 28 }, 0]} justify="start">
									<Col>
										<Row gutter={[0, { xs: 5, sm: 5, md: 8, lg: 8, xl: 8, xxl: 8 }]} justify="start">
											<Col span={24}></Col>
											<ArrowLeftOutlined className='checkoutBackIcon' onClick={() => history.goBack()} />
										</Row>
									</Col>
									<Col>
										<Typography className='afterBackIconTitle'>
											Select your payment method
										</Typography>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row justify="between" gutter={[0, { xs: 19, sm: 19, md: 35, lg: 35, xl: 35, xxl: 35 }]}>
							<Col span={24}></Col>
							<Col span={24}>
								<Row gutter={[0, 0]} justify='space-between' className="shoppingCartParent page-payment">
									{/* <Col span={24}></Col> */}
									<Col className="shoppingCart-leftpane">
										{/* {isXS && 
									<Row>
										<Col span={24} className="PaymentTitle">
											Payment Method
										</Col>
									</Row>
									} */}
										<Typography className='subTitle text-uppercase'>Payment method</Typography>
										<Elements stripe={stripePromise} options={appearance}>
											<CheckoutPaymentForm className="ui"
												paymentLoader={paymentLoader}
												setPaymentLoader={setPaymentLoader}
												prevMethod={prevMethod}
												nextMethod={nextMethod}
												setUsePaymentMethod={setUsePaymentMethod}
												callStripeApi={callStripeApi}
												shippingPrice={shippingCharges}
												freeShipping={freeShipping} />
										</Elements>
									</Col>
									<Col className="shoppingCart-rightpane">
										<Row gutter={[0, { xs: 50, sm: 50, md: 0, lg: 0, xl: 0, xxl: 0 }]} className="priceDetailParent">
											<Col span={24} />
											<Card className='priceDetailsCard'>
												<PriceDetails
													heading="Price details"
													itemTotal={`Items total (${currentCart.length})`}
													itemTotalVal={(_.sumBy(currentCart, function (o) { return o.price; }) / 100).toFixed(2)}
													shipping="Shipping"
													// shippingVal={(shippingCharges / 100).toFixed(2)}
													shippingVal={(_.sumBy(currentCart, function (o) { return o.isFree === false ? (parseFloat(o.shippingPrice) || 0) : 0 ; }) / 100).toFixed(2)}
													salesTax="Sales Tax"
													salesTaxVal={(0).toFixed(2)}
													cartTotal="Cart Total"
													cartTotalVal="199.61"
													button={
														usePaymentMethod ?
															<>
															{unavailableProducts.length ? 
															<span style={{"color":"red", "display":"block", "margin-bottom": "10px", 
																"text-align": "center", "font-size": "15px"}}>
																Looks like product(s) in cart is Out of stock!
															</span> 
															: <></>}
															<Button
																block
																disabled={currentCart.length === 0}
																className={unavailableProducts.length == 0 ? 'button button-primary pay-btn' : 'button button-primary pay-btn disabled' }
														
																onClick={() => {
																	gaEventClick('Checkout', 'Pay now'); 
																	setCallStripeApi(true);
																	setCurrent(2);
																}}
															>
																Pay Now
															</Button>
															</>
															:
															<>
															{unavailableProducts.length ? 
															<span style={{"color":"red", "display":"block", "margin-bottom": "10px", 
																"text-align": "center", "font-size": "15px"}}>
																Looks like product(s) in cart is Out of stock!
															</span> 
															: <></>}
															<Button
																block
																disabled={currentCart.length === 0}
																className={unavailableProducts.length == 0 ? 'button button-primary pay-btn' : 'button button-primary pay-btn disabled' }
																htmlType='submit'
																form="checkoutstripeForm"
																
															>
																Pay now
															</Button>
															</>}
												/>
											</Card>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Spin>
				</div>
			</div>
		</Layout>
	);
};
export default CheckoutPayment;

    