import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Button, Card, Typography, Divider, Image, Collapse, Spin } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import "../App.css";
import ReactStoreBadges from 'react-store-badges'
import MarkidCash from "../assets/MarkidCash.svg";
import MarkidCredit from "../assets/MarkidCredit.svg";
import profileVisa from "../assets/new-assets/icon-visacard.png";
import MasterCard from "../assets/new-assets/icon-mastercard.png";
import DiscoverCard from "../assets/DiscoverCard.png";
import AmericanExpress from "../assets/AmericanExpress.png";
import editIcon from "../assets/edit-Icon.svg";
import ProfileStripeLogo from "../assets/ProfileStripeLogo.svg";
import ProfileVLogo from "../assets/ProfileVLogo.svg";
import ProfileChip from "../assets/ProfileChip.svg";
import RemoveIcon from "../assets/RemoveIcon.svg";
import { getCards, deleteCard, setDefaultCard, getBalance } from "../Actions/paymentAction";
import { useDispatch, useSelector } from "react-redux";
import SavedCardForm from "../components/SavedCardForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert } from "antd";
import moment from 'moment';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Panel } = Collapse;
const ProfileAddresses = (props) => {
	const { cashValue, creditValue } = props;
	const dispatch = useDispatch();
	const [cdcForm, setCDCForm] = useState(false);
	const { handleSubmit, control, errors } = useForm({
	});
	const { cards, isGetCardsLoading, isSetDefaultCardLoading, isDeleteCardLoading } = useSelector((state) => {
		const { cards, isGetCardsLoading, isSetDefaultCardLoading, isDeleteCardLoading } = state.payment;
		return {
			cards,
			isGetCardsLoading,
			isSetDefaultCardLoading,
			isDeleteCardLoading
		};
	});
	useEffect(() => {
		dispatch(getCards())
		dispatch(getBalance())
	}, []);

	const handleDestroyedCard = (data) => {
		dispatch(deleteCard(data, successDeleteCard, failiureDeleteCard))
	}
	const successDeleteCard = () => {
		dispatch(getCards())
	}
	const failiureDeleteCard = () => { }

	const handleDefaultCard = (data) => {
		dispatch(setDefaultCard(data, successDefaultCard, failiureDefaultCard))
	}
	const successDefaultCard = () => {
		dispatch(getCards())
	}
	const failiureDefaultCard = () => { }
	return (
		<>
			{!cdcForm &&
				<>
					<Spin style={{ top: 200, color: '#FF533F' }} indicator={antIcon} spinning={isGetCardsLoading || isDeleteCardLoading}>
						<Card bordered={false} className="profileFavoriteCard">
							<Row justify="space-between" gutter={[0, 0]}>
								<Col span={24}></Col>
								<Col xl={12} lg={12} md={12} sm={10} xs={10}>
									<Typography className="profileDetail">Payment</Typography>
								</Col>
								<Col xl={12} lg={12} md={12} sm={14} xs={14} className="ProfileCol">
									<Button className="buttonEdit"
										onClick={() => setCDCForm(true)}
									>
										<PlusOutlined />
										<Typography component="span" className="buttonEdit addbtnText">Add payment method</Typography>
									</Button>
								</Col>
								{/* <Col span={24}></Col> */}
								<Col span={24}>
									<Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
										<Col span={24} />
										<Col span={24}>
											<Typography className="profileWithdraws">Saved payment methods</Typography>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row gutter={[32, { xs: 13, sm: 13, md: 18, lg: 18, xl: 18, xxl: 18 }]}>
								<Col span={24}></Col>
								{cards && cards.map((val, i) => {
									return (
										<Col xl={10} lg={10} md={10} sm={24} xs={24} key={i}>
											<Card className="profilePayment">
												<Row className="cardHeader">
													<Col xl={6} lg={6} md={6} sm={6} xs={6}>
														{val.brand === "Visa" &&
															<img alt="" src={profileVisa} />
														}
														{val.brand === "MasterCard" &&
															<img alt="" src={MasterCard} style={{ width: 64, height: 22 }} />
														}
														{val.brand === "Discover" &&
															<img alt="" src={DiscoverCard} style={{ width: 64, height: 22 }} />
														}
														{val.brand === "American Express" &&
															<img alt="" src={AmericanExpress} style={{ width: 64, height: 22 }} />
														}
													</Col>
													<Col xl={18} lg={18} md={18} sm={18} xs={18} className="ProfileCol">
														<Button className="removeCard" icon={<img alt="" src={RemoveIcon} />}
															onClick={() => handleDestroyedCard({ stripeCardId: val.id })}
														> </Button>
													</Col>
												</Row>
												<Row gutter={[0, 16]}>

													<Col xl={12} lg={12} md={12} sm={12} xs={12}>
														<Typography className="ProfileName text-capitalize">Name</Typography>
														<Typography className="ProfileName2">{val.name}</Typography>
													</Col>
													<Col xl={12} lg={12} md={12} sm={12} xs={12} className="ProfileCol">
														<img src={ProfileChip} alt="" />
													</Col>
													<Col xl={14} lg={14} md={14} sm={14} xs={14}>
														{val.funding === "credit" &&
															<>
																<Typography className="ProfileName  text-capitalize">credit card</Typography>
																<Typography className="ProfileName2 flex" justify='space-between'>
																	<div className="cardBefore">
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dotspace"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dotspace"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>
																		<span className="dot"></span>

																	</div>{val.last4}</Typography>
															</>
														}
													</Col>
													<Col xl={10} lg={10} md={10} sm={10} xs={10} className="ProfileCol">
														<Typography className="ProfileName  text-capitalize">Valid Till</Typography>
														<Typography className="ProfileName2">{val.exp_month}/{val.exp_year}</Typography>
													</Col>
												</Row>
											</Card>
											<Row justify='space-between' gutter={[0, 0]} align="middle" className="cardFooter">
												<Col>
													<Typography className="profile-poweredby">powered by <img alt="" src={ProfileStripeLogo} /></Typography>
												</Col>
												<Col>
													{val.isDefault ?
														<Button className="btn-default">Default</Button>
														:
														<Spin style={{ color: '#FFFFFF' }} indicator={antIcon} spinning={isSetDefaultCardLoading}>
															<Button className="makeDefaultCardButton"
																onClick={() => handleDefaultCard({ stripeCardId: val.id })}
															>
																Make default
															</Button>
														</Spin>
													}
												</Col>

											</Row>
										</Col>
									);
								})}
								{/* <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                        <Card className="profilePayment" >
                            <Row gutter={[0, 16]}>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Image src={profileVisa} preview={false} />
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="ProfileCol">
                                    <Image preview={false} src={RemoveIcon} />
                                    <Button className="buttonEdit">Remove</Button>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Typography className="ProfileName">Name</Typography>
                                    <Typography className="ProfileName2">William G</Typography>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="ProfileCol">
                                    <Image src={ProfileChip} />
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Typography className="ProfileName">credit card</Typography>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="ProfileCol">
                                    <Typography className="ProfileName">Valid Till</Typography>
                                </Col>
                            </Row>
                        </Card>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Typography className="profile-poweredby">powered by <Image src={ProfileVLogo} /></Typography>
                        </Col>
                    </Col> */}

							</Row>
						</Card>
					</Spin>


					<Card bordered={false} className="walletCard" >
						<Row justify="space-between" gutter={[0, 0]}>
							<Col span={10}>
								<Typography className="profileDetail">Wallet</Typography>
							</Col>

						</Row>
						<Row gutter={[32, { xs: 25, sm: 25, md: 30, lg: 30, xl: 30, xxl: 30 }]} >
							<Col span={24}></Col>
							<Col xl={10} lg={12} md={12} sm={24} xs={24}>
								<Card className="profilePaymentCard">
									<Row gutter={[0, 0]}>
										<Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
											<img alt="" src={MarkidCash} />
										</Col>
										<Row gutter={[0, 0]}>
											<Col span={24} />
											<Col span={24}>
												<Typography className="cashvalue2">${creditValue}</Typography>
											</Col>
											<Col span={24}>
												<Typography className="profileWalletDetails">Your total markid credit is worth ${cashValue}</Typography>
											</Col>
										</Row>
										{/* <Row gutter={[0, { xs: 5, sm: 5, md: 8, lg: 8, xl: 8, xxl: 8 }]}>
											<Col span={24} />
											<Col xl={20} lg={20} md={20} sm={20} xs={20}>
												<Typography className="profileWalletDetails">Your total markid credit is worth ${cashValue}</Typography>
											</Col>
										</Row> */}
									</Row>
								</Card>
							</Col>
							<Col xl={10} lg={12} md={12} sm={24} xs={24}>
								<Card className="profilePaymentCard" >
									<Row gutter={[0, 0]}>
										<Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
											<img src={MarkidCredit} alt="" />
										</Col>
										<Row gutter={[0, 0]}>
											<Col span={24} />
											<Col xl={24} lg={24} md={24} sm={24} xs={24}>
												<Typography className="cashvalue2">${cashValue} </Typography>
											</Col>
											<Col xl={24} lg={24} md={24} sm={24} xs={24}>
												<Typography className="profileWalletDetails">Your total markid cash includes ${cashValue} + {creditValue} Markid Credit</Typography>
											</Col>
										</Row>
										{/* <Row gutter={[0, { xs: 5, sm: 5, md: 8, lg: 8, xl: 8, xxl: 8 }]}>
											<Col span={24} />
											<Col xl={22} lg={22} md={22} sm={22} xs={22}>
												<Typography className="profileWalletDetails">Your total markid cash includes ${cashValue} + {creditValue} Markid Credit</Typography>
											</Col>
										</Row> */}
									</Row>

								</Card>
							</Col>

						</Row>
						{/* <Row gutter={[0, 16]}>
							<Divider />
						</Row> */}
						<Row justify="space-between" gutter={[0, 0]} className="withdrawCard">
							<Col span={24}></Col>
							<Col xl={12} lg={12} md={12} sm={10} xs={10}>
								<Typography className="profileWithdraws">Withdraws</Typography>
							</Col>
							<Col xl={12} lg={12} md={12} sm={14} xs={14} className="ProfileCol">
								{/* <Button className="buttonEdit">Withdraws to bank</Button> */}
							</Col>
							<Col span={24}>
								{/* <Typography className="profileWithdrawsDetails">Withdraw availabe cash to your bank account</Typography> */}
								<Alert severity="info" message="Get the app to withdraw amount to your bank account" />

							</Col>

						</Row>
						{/* <Row gutter={[0, 16]}>
							<Divider />
						</Row> */}
						<Row className="transactionsCard">
							<Col span={24}>
								{/* <Collapse defaultActiveKey={['1']} ghost expandIconPosition="right">
									<Panel header="Transaction logs" key="1" className="profileWithdraws">
									<Alert severity="info" message="Get the app to view transactions"/>
									</Panel>
								</Collapse> */}
								<Typography className="profileWithdraws">Transaction logs</Typography>
								<Alert severity="info" message="Get the app to view transactions" />
							</Col>
						</Row>
						<Row gutter={[21, 62.29]} justify='center'>
							<Col span={24}></Col>
							{/* <Col>
              <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                />
              </Col> */}

							<Col>
								<ReactStoreBadges
									platform={'ios'}
									url={'https://apps.apple.com/us/app/markid/id1436309782'}
									target="_"
									locale={'en-us'}
								/>
							</Col>

						</Row>
					</Card>
				</>
			}
			{cdcForm &&
				<Elements stripe={stripePromise}>
					<SavedCardForm
						setCDCForm={setCDCForm}
					/>
				</Elements>
			}
		</>


	);
};
export default ProfileAddresses;
