import React, {useState,useEffect}from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useGaEvent } from "../hooks/useGaEvent";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";

const { Panel } = Collapse;
const ColorFilter = (props) => {
  const {
    totalcount,
    currentPage,
    selectedColor,
    dropdownCallback,
    checkdonColorChange,
  } = props;
  const { gaPageView, gaEventClick } = useGaEvent();
  const { isXS, isSM} = useBreakpoints();
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const [key, setkey] = useState();

  return (
    <>
    {totalcount && (totalcount.color||{}.length>0) && (<Collapse
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        onChange={()=>{gaEventClick("Filter by", "Color","open/close")}}
        bordered={false}
        defaultActiveKey={["12"]}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        <Panel
          className="collapseContent"
          style={{
            padingTop: 15,
            paddingBottom: 19,
            marginTop: 15,
            marginBottom: 19,
          }}
          header={
            selectedColor && selectedColor.length > 0 ? (
              <Badge color="blue">
                <Typography className="pFCollapseTitle">Color&nbsp;</Typography>
              </Badge>
            ) : (
              <Typography className="pFCollapseTitle">Color</Typography>
            )
          }
          key={key}
        >
          {totalcount &&
            Object.entries(totalcount.color || {}).map((val, i) => (
              <Row gutter={[12, 16]} key={i}>
                <Col>
                  <Checkbox
                    onChange={() => {checkdonColorChange(val[0]);gaEventClick("Filter by", "Color",val[0])}}
                    checked={selectedColor.indexOf(val[0]) !== -1}
                  />
                </Col>
                <div
                  className="round-color-container-filter"
                  alt=""
                  style={{ backgroundColor: val[0] }}
                ></div>
                <Col>
                  <Typography className="conditionName">
                    {capitalize(val[0])}{" "}
                    {val[1] > 0 ? (
                      <span className="ProductCount">({val[1]})</span>
                    ) : (
                      <span>(0)</span>
                    )}
                  </Typography>
                </Col>
              </Row>
            ))}
        </Panel>
      </Collapse>)}
      
    </>
  );
};

export default ColorFilter;
