/*eslint-disable*/
import {
    Input,
    Card,
    Typography,
    Layout,
    Row,
    Col,
    Steps,
  } from "antd";
  import { ArrowLeftOutlined } from "@ant-design/icons";
  import React from "react";
  
  const { Step } = Steps;
  const steps = [
    {
      title: "List your item",
      
      // content: 'First-content',
    },
    {
      title: "Collection method",
      // content: 'Second-content',
    },
    {
      title: "Setup Payment",
      // content: 'Last-content',
    },
  ];
  
  const { Header, Content, Footer } = Layout;
  const PageHeader = () => {
    const [current, setCurrent] = React.useState(0);
  
    return (
      <>
        <Card>
          <Row justify="space-between">
            <Col>
              <ArrowLeftOutlined />
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Steps current={current} size="small" labelPlacement="vertical" responsive='true'>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} style={{fontSize: '1.2vw'}}/>
                ))}
              </Steps>
            </Col>
            <Col></Col>
          </Row>
        </Card>
      </>
    );
  };
  export default PageHeader;
  