import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Button, Alert, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useBreakpoints } from './../../react-responsive-hooks/index';
import { useForm, Controller } from "react-hook-form";
import MCImage from "../../assets/new-assets/community-img.png";
import "./MarkidCommunity.css"
import { Helmet } from "react-helmet";

const MarkidCommunity = () => {
  const { isXS, isSM } = useBreakpoints();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
    },
  });
  return (
    <>
      <Helmet>
        <title>Markid - Community</title>
      </Helmet>
      <Card bordered={false} className="mCCardBg">
        <Row justify='center' gutter={[0, { xs: 15, sm: 15, md: 37, lg: 37, xl: 37, xxl: 37 }]} className="app-wrapper">
          <Col span={24}></Col>
          <Col xxl={24} xl={24} sm={24} xs={24}>
            <Card bordered={false} className='mCCard'>
              <Row justify='center' >
                <div className='mCImgBg'>
                  <Row justify='center' >
                    <img alt="" src={MCImage} className='mCImage' />
                  </Row>
                </div>
                {/* <img alt="" src={MarkidCommunityImg} /> */}
              </Row>
              <Row justify='center' >

                <Col sm={15} xs={15} lg={15} xl={15}>
                  <Typography className='mCTitle'>
                    Good things are on the way!
                  </Typography>
                  <Typography className='mCDes'>
                    Our Markid Community is only growing! Sign up for our newsletter to be alerted when the Markid Community Platform Launches
                  </Typography>
                </Col>
              </Row>

              <Row justify='center' gutter={[10, 45.29]}>
                <Col span={24}></Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="text-center">
                  <Controller as={Input} name="email" placeholder="Enter your email id" control={control} size='large' className='mCEmailInput' />
                  <Button size='large' className='mCButton' icon={<RightOutlined />} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>

    </>
  );
};
export default MarkidCommunity;
