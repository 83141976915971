/*eslint-disable*/
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Avatar,
  Collapse,
  Divider,
  Card,
  Input,
  List,
  Rate,
  Space,
  Button,
  Image,
  Skeleton,
  Spin,
} from "antd";
import EmptyHeart from "../assets/UnFavorite.svg";
import FullHeart from "../assets/Favorite.svg";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import { useForm, Controller } from "react-hook-form";
import "../App.css";
import { addToFavorites, removeFromFavorites, getLotById } from "../Actions/lotsAction";
import { LoadingOutlined } from "@ant-design/icons";
import { ProductImage } from "../components/ProductImages";
import SignIn from "../pages/SignIn";
import moment from 'moment';
import { useGaEvent } from "../hooks/useGaEvent";

const { gaPageView, gaEventClick } = useGaEvent();

const { Text, Title } = Typography;
const ProductDescription = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { productData, productLoader } = props;
  const { imageUrls, description, brand, gender, createdAt, color, inSoldPending, inUserFavorites, _id, price, condition, category, deliveryType, yearOfPurchase, negotiablePrice, location } = productData;
  const Listed = moment(createdAt).format("MMMM YYYY");
  const { Panel } = Collapse;
  const [selectedProductImg, setselectedProductImg] = useState("");
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const { isAuthenticated, currentCart } = useSelector((state) => {
    const { isAuthenticated } = state.auth;
    const { currentCart } = state.cart;
    return {
      isAuthenticated,
      currentCart
    };
  });
  const ProductId = _id
  let CartId = ""
  {
    currentCart &&
      currentCart.map((current) => {
        current._id === ProductId ? CartId = current._id : null
      })
  }
  function callback(key) {
    // console.log(key);
  }
  const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  // const { register, handleSubmit, errors, control } = useForm({});
  const ManufactureVal = yearOfPurchase ? yearOfPurchase.substring(0, 4) : null
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const onImgClick = (selected) => {
    setselectedProductImg(selected);
  };
  const handleAddToFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(addToFavorites({ id: _id }, successCallBack, failureCallBack));
    } else {
      setShowSignInDialog(true)
    }
  };
  const handleRemoveFromFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(removeFromFavorites({ id: _id }, successCallBack, failureCallBack));
    } else {
      setShowSignInDialog(true)
    }
  };
  const successCallBack = () => {
    dispatch(getLotById(_id));
  };
  const failureCallBack = () => { };
  const handleAddItemCart = (item) => {
    gaEventClick("PDP", "Add to Cart", item.title ? item.title : item.name);
    dispatch({ type: 'ADD_ITEM_CART', payload: { item: item } })
    history.push("/markid-cart")
  }
  return (
    <>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row style={{ height: productLoader ? 300 : '100%' }} className="product-image-background" justify="center" align='middle' >
            {
              productLoader ? (
                <Col span={24}>
                  <Row justify={'center'} align='middle'>
                    <Spin indicator={antIcon} spinning={productLoader} />
                  </Row>
                </Col>
              ) : (
                <div>
                  <div style={{ position: 'absolute', left: -5 }}>
                    {inSoldPending ? (
                      <div className="sold-button-div">
                        <Button type="text" className="sold-button">
                          SOLD
                        </Button>
                      </div>
                    ) :
                      <div className="sold-button-div">
                      </div>}
                  </div>
                  <div style={{ position: 'absolute', right: -10 }}>
                    <div className="fev-icon-div">
                      {inUserFavorites ? (
                        <img
                          src={FullHeart}
                          alt=""
                          onClick={() => handleRemoveFromFavorite()}
                        />
                      ) : (
                        <img
                          src={EmptyHeart}
                          alt=""
                          onClick={() => handleAddToFavorite()}
                        />
                      )}
                    </div>
                  </div>
                  <ProductImage className="product-image" productimg={selectedProductImg ? selectedProductImg : images} />
                </div>
              )
              // <img className='product-image' src={images}/>
            }
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="center" className="thumbscroll">
        <Col span={24}></Col>
        {imageUrls &&
          imageUrls.map((val, i) => (
            <Col key={i}>
              <ProductImage
                className={selectedProductImg === val ? "selected-product-image" : "unselected-product-image"}
                productimg={val}
                selected={val}
                style={{ marginLeft: 18 }}
                onClick={(e) => onImgClick(val)}
              />
              {/* <img className='color-product-image' src={File2}/> */}
            </Col>
          ))}
        {/* <Col>
        <ProductImage
        className='color-product-image'
        productImg={File3}
        />
          <img className='color-product-image' src={File3}/>
        </Col> */}
        {/* <Col>
        <ProductImage
        className='color-product-image'
        productImg={File4}
        />
          <img className='color-product-image' src={File4}/>
        </Col> */}
        {/* <Col>
        <ProductImage
        className='color-product-image'
        productImg={File1}
        />
          <img className='color-product-image' src={File1}/>
        </Col> */}
      </Row>
      {description && (
        <Row gutter={[0, 32]}>
          <Col span={24}></Col>
          <Col span={24}>
            <Title level={3} className="des-title">
              Description
            </Title>
          </Col>
          {productLoader ? (
            <Skeleton.Button active={productLoader} size="small" />
          ) : (
            <Col>
              <Typography className="product-des">
                {capitalize(description)}
                {/* The coco stylewood lounger contains the wooden frame, seat pad + 5
            point safety harness curved, single-piece baltic birch wood frame
            with plush, cotton baby-facing seat pad with polyester rear lining.
            formed in pressed wood. smooth. self-rocking motion. adjustable
            5-point padded safety harness. */}
              </Typography>
            </Col>
          )}
        </Row>
      )}

      <div className="addtocartMob">
        <Row justify="start">
          <Text style={{ color: "#FF533F" }}>reNEWED</Text>
        </Row>
        <Row justify="space-between" gutter={[0, 8]}>
          <Col span={24}></Col>
          <Col>
            <Row>
              {productLoader ?
                <Skeleton.Button active={productLoader} size="default" /> :
                name && <Text className="renewed-work-heading">{capitalize(name)}</Text>}
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col>
                {productLoader ?
                  <Skeleton.Button active={productLoader} size="small" /> :
                  brand && <Typography className="benefit">{capitalize(brand)}</Typography>}
              </Col>
            </Row>
          </Col>
          {productLoader ?
            <Skeleton.Button active={productLoader} size="default" /> :
            price && (
              <Col>
                <Text className="main-price">${price / 100}</Text>
              </Col>
            )}
        </Row>
        <Divider />
        {condition && (
          <Row justify="space-between">
            <Col>
              <Text className="product-cart-details">Condition:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="small" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(condition)}</Text>
              </Col>
            }
          </Row>
        )}
        {category && (
          <Row gutter={[0, 4]} justify="space-between">
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Category:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="default" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(category)}</Text>
              </Col>
            }
          </Row>
        )}
        {deliveryType && (
          <Row gutter={[0, 4]} justify="space-between">
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Delivery Type:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="small" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(deliveryType)}</Text>
              </Col>
            }
          </Row>
        )}
        {yearOfPurchase && (
          <Row justify="space-between" gutter={[0, 4]}>
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Manufactured:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="small" /> :
              <Col>
                <Text className="product-cart-value">{ManufactureVal}</Text>
              </Col>
            }
          </Row>
        )}
        {location && (
          <Row justify="space-between" gutter={[0, 4]}>
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Location:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="default" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(location)}</Text>
              </Col>
            }
          </Row>
        )}
        {/* {size && (
        <Row justify="space-between" gutter={[0, 4]}>
          <Col span={24}></Col>
          <Col>
            <Text className="product-cart-details">Size:</Text>
          </Col>
          {productLoader ?
        <Skeleton.Button active={productLoader} size="small" /> :
          <Col>
            <Text className="product-cart-value">{capitalize(size)}</Text>
          </Col>
}
        </Row>
      )} */}
        <Row justify="space-between" gutter={[0, 4]}>
          <Col span={24}></Col>
          <Col>
            <Text className="product-cart-details">Price:</Text>
          </Col>
          {productLoader ?
            <Skeleton.Button active={productLoader} size="default" /> :
            <Col>
              {negotiablePrice === true ? (
                <Text className="product-cart-value">Negotiable</Text>
              ) : (
                <Text className="product-cart-value">Firm</Text>
              )}
            </Col>
          }
        </Row>
        {inSoldPending ? null
          :
          <Row gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col span={24}>
              {ProductId === CartId ?
                productLoader ? <Skeleton.Button active={productLoader} width={500} />
                  :
                  <Button
                    block
                    className={"squarebutton"}
                    onClick={() => {
                      gaEventClick("PDP", "Go To Cart");
                      history.push("/markid-cart");
                    }}
                  >
                    GO TO CART
                  </Button>
                :
                productLoader ? <Skeleton.Button active={productLoader} />
                  :
                  <Button
                    block
                    className={"squarebutton"}
                    onClick={() => handleAddItemCart(productData)}
                  >
                    ADD TO CART

                  </Button>
              }
            </Col>
          </Row>
        }
      </div>

      <Divider />
      <Row justify="center">
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            onChange={callback}
            expandIconPosition="right"
            bordered={false}
          >
            <Panel
              header={
                <Row>
                  <Text className="colleps-title">Item details</Text>
                </Row>
              }
              key="1"
            >
              <Row justify="start" gutter={[32, 16]}>
                <Col span={24}></Col>
                <Col>
                  <Title strong level={5}>
                    Listed
                  </Title>
                </Col>
                <Col>
                  {Listed &&
                    Listed
                  }
                </Col>
              </Row>
              {brand && (
                <Row justify="start" gutter={[32, 4]}>
                  <Col span={24}></Col>
                  <Col>
                    <Title strong level={5}>
                      Brand
                    </Title>
                  </Col>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : (
                    <Col>{capitalize(brand)}</Col>
                  )}
                </Row>
              )}
              {color &&
                <Row justify="start" gutter={[32, 4]}>
                  <Col span={24}></Col>
                  <Col>
                    <Title strong level={5}>
                      Color
                    </Title>
                  </Col>
                  <Col>
                    {color}
                  </Col>
                </Row>
              }
              {gender && (
                <Row justify="start" gutter={[32, 4]}>
                  <Col span={24}></Col>
                  <Col>
                    <Title strong level={5}>
                      Gender
                    </Title>
                  </Col>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : (
                    <Col>{capitalize(gender)}</Col>
                  )}
                </Row>
              )}
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row justify='center'>
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["1"]}
            onChange={callback}
            expandIconPosition="right"
            bordered={false}
          >
            <Panel
              showArrow={true}
              header={
                  <Text className='colleps-title'>What is Markid reNEWED?</Text>
              }
              key="1"
            >
              <Row gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                  <Typography className='product-des'>
                    reNEWED is our hand-picked selection of pre-loved items that are inspected, professionally cleaned, sanitized, and restored to a lovingly reNEWED state. Just the way you’d like them - for your kids.
                  </Typography>
                </Col>
              </Row>

              <Row gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Row>
                    <Col>
                      <Avatar size={50} style={{ backgroundColor: '#FFD09C' }}>
                        1
                      </Avatar>
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Col>
                      <Title className='benefit' style={{ minHeight: 112 }} level={4}>
                        Hand-Picked For You
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                      <Typography className='product-des'>
                        Markid reNEWED is a collection of top brands and items that you want for your family. From strollers to high chairs, we sell the best items for your kids, all in one place.
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Row>
                    <Col>
                      <Avatar size={50} style={{ backgroundColor: '#FFD09C' }}>
                        2
                      </Avatar>
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Col>
                      <Title className='benefit' style={{ minHeight: 112 }} level={4}>
                        Quality Assured
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                      <Typography className='product-des'>
                        All reNEWED products are professionally sanitized, cleaned, restored, and tested for functionality by our team. We bring all items back to a reNEWED state so you get the most out of your pre-loved kids’ gear.
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Row>
                    <Col>
                      <Avatar size={50} style={{ backgroundColor: '#FFD09C' }}>
                        3
                      </Avatar>
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Col>
                      <Title className='benefit' style={{ minHeight: 112 }} level={4}>
                        The Sustainable, Affordable Choice
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                      <Typography className='product-des'>
                        reNEWED gives you top items at a discounted price, so you don’t have to sacrifice your wallet for items you love. By purchasing pre-loved, you and your family help extend the life of kids’ gently-used items - and make a better choice for you and for the planet.
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      {/*<Row justify='center'>
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            collapsible="header"
            onChange={callback}
            expandIconPosition="right"
            bordered={false}
          >
            <Panel
              showArrow={true}
              header={
                <Row justify="start" align="middle">
                  <Text className='colleps-title'>Ratings and reviews</Text>
                </Row>
              }
              key="1"
            >
              <Row justify="start" gutter={[0, 4]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Blogger Ratings</Text>
                </Col>
              </Row>
              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row>
                      <Col>
                        <Title level={5} className='rating-blog-title'>
                          My kid loves the lounger
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Typography className='raring-blog-des'>
                          It is a great piece of furniture - Very high quality.
                          Unfortunately. my 4-month old prefers his battery
                          operated swings with loud sounds and colors. So far,
                          this loungers has very limited usages for us. We use
                          it for 15 minute increments to keep him occupied while
                          we get ready in the morning.
                        </Typography>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={24}></Col>
                      <Col>
                        <Avatar size={50}style={{backgroundColor: '#FFD09C'}}/>
                      </Col>
                      <Col>
                      <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Title level={5} className='rating-user-name'>
                          Celiene Adams
                        </Title>
                        </Col>
                        </Row>
                      </Col>
                      <Col>
                      <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Typography className='rating-user-des'>(Mom of two aged 3 and 5)</Typography>
                        </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[0, 48]}>
                <Col span={24}></Col>
                <Col>
                  <Text>PRODUCT RATINGS (AMAZON)</Text>
                </Col>
              </Row>
              <Row gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row justify="space-between">
                      <Col xl={12} lg={12} md={12} sm={22} xs={22}>
                        <Rate allowHalf style={{ fontSize: 30 }} defaultValue={4} />
                        <Row justify="center">
                          <Col>
                            <Title strong level={5}>
                              4.5 out of 5
                            </Title>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={22} xs={22}>
                        <Typography style={{ textAlign: "start" }}>
                          Out of 3.711 global ratings with top features such as
                          thickness, easy assembly. Good sleep quality and
                          sturdinees
                        </Typography>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row>
                      <Col>
                        <Title strong level={5} className='rating-blog-title'>
                          Very good quality
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Typography className='raring-blog-des'>
                         This is very good quality lounger. My newborn sleeps comfortably in it. It is easy
                         to handle machine washable. As a mother, I first look at the comfort of my child
                         and for that it certainly exceed my expectation.
                        </Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} justify='space-between'>
                      <Col span={24}></Col>
                      <Col>
                      <Space direction='horizontal'>
                        <Avatar size={50}style={{backgroundColor: '#FFD09C'}}/>
                        <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Title level={5} className='rating-user-name'>
                          Umasankar
                        </Title>
                        </Col>
                        </Row>
                        </Space>
                      </Col>
                      <Col>
                        <Rate defaultValue={5} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row>
                      <Col>
                      <Title strong level={5} className='rating-blog-title'>
                          Breathable and nice looking
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <Typography className='raring-blog-des'>
                         Shipment was very fast. I selected regular shipping, but I received it in 2 days! This
                         product looks pretty nice. I really like the light blue/purple color. Hopefully my 
                         baby likes it, too!
                        </Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} justify='space-between'>
                      <Col span={24}></Col>
                      <Col>
                      <Space direction='horizontal'>
                        <Avatar size={50}style={{backgroundColor: '#FFD09C'}}/>
                        <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Title level={5} className='rating-user-name'>
                          Amber Frushon
                        </Title>
                        </Col>
                        </Row>
                        </Space>
                      </Col>
                      <Col>
                        <Rate defaultValue={4} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[0,8]} justify='start'>
                  <Col span={24}></Col>
                  <Col>
                  <Button type='text' className='view-more'>View more</Button>
                  </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row> */}

      {/* <Row>
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            collapsible="header"
            onChange={callback}
            expandIconPosition="right"
            bordered={false}
          >
            <Panel
              showArrow={true}
              header={
                <Row justify="start" align="middle">
                  <Text strong>Disscusion</Text>
                </Row>
              }
              key="1"
            >
              <Row justify="start" gutter={[32, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Controller
                    as={Input}
                    size="large"
                    name="phoneNumber"
                    control={control}
                    placeholder="Start a disscusion"
                  />
                </Col>
              </Row>

              <List>
                <List.Item actions={[<div>REPLY</div>]}>
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={<div>Would love to buy this for my newborn!</div>}
                  />
                </List.Item>

                <List.Item actions={[<div>REPLY</div>]}>
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={<div>Can I use this for my 2 year old?</div>}
                    description={<div>Yes You can.</div>}
                  />
                </List.Item>
                <List.Item actions={[<div>REPLY</div>]}>
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={
                      <div>
                        Plan to use it for both my kids, Would this be good
                        enough?
                      </div>
                    }
                  />
                </List.Item>
              </List>
            </Panel>
          </Collapse>
        </Col>
      </Row> */}
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default ProductDescription;