import API from '../Utils/API';

export const getUser = () => (dispatch ) => {
    dispatch({ type: "GET_USER_REQUEST" });
    API.get("api/v2/users/me")
      .then((response) => {
        dispatch({ type: "GET_USER_SUCCESS", payload: response.data.user });
        //successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_USER_FAILURE",
            payload: error.response.data,
          });
          //failureCallBack();
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };

  export const getUserById = (userId) => (dispatch) => {
    dispatch({ type: "GET_USER_BY_ID_REQUEST" });
    API.get(`api/v2/users/${userId}`)
      .then((response) => {
        dispatch({ type: "GET_USER_BY_ID_SUCCESS", payload: response.data.user });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_USER_BY_ID_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };

  export const getSellerUserLots = (data,url) => (dispatch) => {
    dispatch({ type: "GET_SELLER_USER_LOTS_REQUEST" });
    API.get(url)
    // API.get(`api/v2/users/${userId}/lots`)
      .then((response) => {
        dispatch({ type: "GET_SELLER_USER_LOTS_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_SELLER_USER_LOTS_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };

  export const getSellerBadges = (userId) => (dispatch) => {
    dispatch({ type: "GET_SELLER_BADGES_REQUEST" });
    API.get(`api/v2/users/${userId}/sellerBadges`)
      .then((response) => {
        dispatch({ type: "GET_SELLER_BADGES_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_SELLER_BADGES_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };

  export const getUserFavirotesLots = () => (dispatch) => {
    dispatch({ type: "GET_USER_FAVORITES_LOTS_REQUEST" });
    API.get("api/v2/users/me/favorites")
      .then((response) => {
        dispatch({ type: "GET_USER_FAVORITES_LOTS_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_USER_FAVORITES_LOTS_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };

  export const getUserRatings = (data) => (dispatch) => {
    dispatch({ type: "GET_RATINGS_BY_USER_ID_REQUEST" });
    API.get(`api/v2/rating/${data}`)
      .then((response) => {
        dispatch({ type: "GET_RATINGS_BY_USER_ID_SUCCESS", payload: response.data.user });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_RATINGS_BY_USER_ID_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };

export const updateUser = (data, successCallBackUpdateUser, failureCallUpdateUser) => dispatch => {
    dispatch({ type: "UPDATE_USER_REQUEST" })
    API.patch('/api/v2/users/me/edit', data)
        .then(response => {
            dispatch({ type: "UPDATE_USER_SUCCESS", payload: response.data })
            successCallBackUpdateUser()
            dispatch(getUser())
        })
        .catch((error) => {
            try {
              dispatch({
                type: "UPDATE_USER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
              failureCallUpdateUser();
            } catch {
              // console.log(error);
            }
          });
}
export const uploadUserPhoto = (data, successCallBackUploadUserPhoto, failureCallBackUploadUserPhoto) => dispatch => {
  dispatch({ type: "UPLOAD_USER_PHOTO_REQUEST", payload: data })
  var formData = new FormData();
  formData.append('file', data.file);
  formData.append('fileName', data.fileName);
  API.post('/api/v1/utility/image-upload', formData)
      .then(response => {
          dispatch({ type: "UPLOAD_USER_PHOTO_SUCCESS", payload: response.data })
          successCallBackUploadUserPhoto(response.data)
      })
      .catch((error) => {
          try {
            dispatch({
              type: "UPLOAD_USER_PHOTO_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            failureCallBackUploadUserPhoto();
          } catch {
            // console.log(error);
          }
        });
}
export const notifyItem =
  (data, successCallBackNotifyMe, failureCallBackNotifyMe) => (dispatch) => {
    dispatch({ type: "NOTIFY_ITEM_REQUEST" });
    API.patch(`/api/v2/users/me/notify-item/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "NOTIFY_ITEM_SUCCESS", payload: response });
        dispatch(getUser())
        successCallBackNotifyMe();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "NOTIFY_ITEM_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBackNotifyMe();
        } catch {
          // console.log(error);
        }
      });
  };

  export const unNotifyItem =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "UNNOTIFY_ITEM_REQUEST" });
    API.patch(`/api/v2/users/me/unnotify-item/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UNNOTIFY_ITEM_SUCCESS", payload: response });
        dispatch(getUser())
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UNNOTIFY_ITEM_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

export const followUser =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "FOLLOW_USER_REQUEST" });
    API.patch(`/api/v2/users/me/follow-user/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "FOLLOW_USER_SUCCESS", payload: response });
        dispatch(getUser())
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "FOLLOW_USER_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

export const unFollowUser =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "UNFOLLOW_USER_REQUEST" });
    API.patch(`/api/v2/users/me/unfollow-user/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UNFOLLOW_USER_SUCCESS", payload: response });
        dispatch(getUser())
        successCallBack();

      })
      .catch((error) => {
        try {
          dispatch({
            type: "UNFOLLOW_USER_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

  export const addAddress = (data, successCallBackAddress, failureCallBackAddress) => dispatch => {
    dispatch({ type: "ADD_ADDRESS_REQUEST" })
    API.patch('/api/v2/users/me/add-address', data)
        .then(response => {
            dispatch({ type: "ADD_ADDRESS_SUCCESS", payload: response.data })
            const addresses = response.data.user.addresses
            successCallBackAddress(addresses[addresses.length -1 ]._id)
            dispatch(getUser())
            // successCallBackAddress()

        })
        .catch((error) => {
            try {
              dispatch({
                type: "ADD_ADDRESS_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
              failureCallBackAddress();
            } catch {
              // console.log(error);
            }
          });
}
export const updateAddress = (addressId, data, successCallBackUpdateAddress, failureCallBackUpdateAddress) => dispatch => {
  dispatch({ type: "UPDATE_ADDRESS_REQUEST" })
  API.patch(`/api/v2/users/me/update-address/${addressId}`, data)
      .then(response => {
          dispatch({ type: "UPDATE_ADDRESS_SUCCESS", payload: response.data })
          const addresses = response.data.user.addresses
          // successCallBackAddress(addresses[addresses.length -1 ]._id)
          dispatch(getUser())
          successCallBackUpdateAddress()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "UPDATE_ADDRESS_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
            failureCallBackUpdateAddress();
          } catch {
            // console.log(error);
          }
        });
}
export const removeAddress = (addressId) => dispatch => {
  dispatch({ type: "REMOVE_ADDRESS_REQUEST" })
  API.patch(`/api/v2/users/me/delete-address/${addressId}`)
      .then(response => {
          dispatch({ type: "REMOVE_ADDRESS_SUCCESS", payload: response.data })
          const addresses = response.data.user.addresses
          dispatch(getUser())
      })
      .catch((error) => {
          try {
            dispatch({
              type: "REMOVE_ADDRESS_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
          } catch {
            // console.log(error);
          }
        });
}

export const setAsDefaultAddress = (addressId) => dispatch => {
  dispatch({ type: "SET_AS_DEAFULT_ADDRESS_REQUEST" })
  API.patch(`/api/v2/users/me/update-default-address/${addressId}`)
      .then(response => {
          dispatch({ type: "SET_AS_DEAFULT_ADDRESS_SUCCESS", payload: response.data })
          const addresses = response.data.user.addresses
          dispatch(getUser())
      })
      .catch((error) => {
          try {
            dispatch({
              type: "SET_AS_DEAFULT_ADDRESS_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
          } catch {
            // console.log(error);
          }
        });
}

export const addBrands = (data, successCallBackBrandsAndCategories, failureCallBackBrandsAndCategories) => dispatch => {
  dispatch({ type: "ADD_BRANDS_REQUEST" })
  API.patch('/api/v2/users/me/add-brands', data)
      .then(response => {
          dispatch({ type: "ADD_BRANDS_SUCCESS", payload: response.data })
          successCallBackBrandsAndCategories()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "ADD_BRANDS_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
            failureCallBackBrandsAndCategories();
          } catch {
            // console.log(error);
          }
        });
}

export const addZipcode = (data, successCallBackZipcode, failureCallBackZipcode) => dispatch => {
  dispatch({ type: "ADD_ZIPCODE_REQUEST" })
  API.patch('/api/v2/users/me/add-zipcode', data)
      .then(response => {
          dispatch({ type: "ADD_ZIPCODE_SUCCESS", payload: response.data })
          successCallBackZipcode()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "ADD_ZIPCODE_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
            failureCallBackZipcode();
          } catch {
            // console.log(error);
          }
        });
}

export const addCategories = (data, successCallBackBrandsAndCategories, failureCallBackBrandsAndCategories) => dispatch => {
  dispatch({ type: "ADD_CATEGORIES_REQUEST" })
  API.patch('/api/v2/users/me/add-categories', data)
      .then(response => {
          dispatch({ type: "ADD_CATEGORIES_SUCCESS", payload: response.data })
          successCallBackBrandsAndCategories()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "ADD_CATEGORIES_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
            failureCallBackBrandsAndCategories();
          } catch {
            // console.log(error);
          }
        });
}

export const addKids = (data, successCallBackKids, failureCallBackKids) => dispatch => {
  dispatch({ type: "ADD_KIDS_REQUEST" })
  API.post('/api/v2/kids/', data)
      .then(response => {
          dispatch({ type: "ADD_KIDS_SUCCESS", payload: response.data })
          successCallBackKids()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "ADD_KIDS_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
            failureCallBackKids();
          } catch {
            // console.log(error);
          }
        });
}

export const joinCommunity = (data, successCallBackJoinCommunity, failureCallBackJoinCommunity) => dispatch => {
  dispatch({ type: "JOIN_COMMUNITY_REQUEST" })
  API.patch(`/api/v2/users/me/join-community/${data.id}`)
      .then(response => {
          dispatch({ type: "JOIN_COMMUNITY_SUCCESS", payload: response.data })
          dispatch(getUser())
          successCallBackJoinCommunity()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "JOIN_COMMUNITY_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 15000);
            failureCallBackJoinCommunity();
          } catch {
            // console.log(error);
          }
        });
}
export const moreLotsFromThisSeller =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "MORE_FROM_THIS_SELLER_REQUEST" });
    let url = null;
    if (data.limit) {
      url = `/api/v2/users/${data.id}/lots?limit=` + data.limit; 
    } else url = `/api/v2/users/${data.id}/lots`;
    API.get(url, data)
      .then((response) => {
        dispatch({ type: 'MORE_FROM_THIS_SELLER_SUCCESS', payload: response });
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: 'MORE_FROM_THIS_SELLER_FAILURE',
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: 'CLEAR ERROR STATE' }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

  export const similarItems =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "SIMILAR_ITEMS_REQUEST" });
    let url = null;
    if (data.limit) {
      url = `/api/v2/recommendations/similar-lots/${data.id}?limit=` + data.limit;
    } else url = `/api/v2/recommendations/similar-lots/${data.id}`;
    API.get(url, data)
      .then((response) => {
        dispatch({ type: "SIMILAR_ITEMS_SUCCESS", payload: response });
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "SIMILAR_ITEMS_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };
export const ContactUS = (data, successCallBack, failureCallBack) => dispatch => {
  dispatch({ type: "CONTACT_US_REQUEST" })
  API.post('/api/v2/mails/contact-us', data)
      .then(response => {
          dispatch({ type: "CONTACT_US_SUCCESS", payload: response.data })
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
          successCallBack();
      })
      .catch((error) => {
          try {
            dispatch({
              type: "CONTACT_US_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            failureCallBack();
          } catch {
            // console.log(error);
          }
        });
}

export const blockUser = (userId, successCallBlockUser, failureCallBlockUser) => dispatch => {
  dispatch({ type: "BLOCK_USER_REQUEST" })
  API.patch(`/api/v2/users/me/block-user/${userId}`)
      .then(response => {
          dispatch({ type: "BLOCK_USER_SUCCESS", payload: response.data })
          dispatch(getUser())
          successCallBlockUser()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "BLOCK_USER_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            failureCallBlockUser();
          } catch {
            // console.log(error);
          }
        });
}

export const unBlockUser = (userId, successCallUnBlockUser, failureCallUnBlockUser) => dispatch => {
  dispatch({ type: "UNBLOCK_USER_REQUEST" })
  API.patch(`/api/v2/users/me/unblock-user/${userId}`)
      .then(response => {
          dispatch({ type: "UNBLOCK_USER_SUCCESS", payload: response.data })
          dispatch(getUser())
          successCallUnBlockUser()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "UNBLOCK_USER_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            failureCallUnBlockUser();
          } catch {
            // console.log(error);
          }
        });
}

export const reportUser = (userId, successCallReportUser, failureCallReportUser) => dispatch => {
  dispatch({ type: "REPORT_USER_REQUEST" })
  API.post(`/api/v2/users/${userId}/report`)
      .then(response => {
          dispatch({ type: "REPORT_USER_SUCCESS", payload: response.data })
          dispatch(getUser())
          successCallReportUser()
      })
      .catch((error) => {
          try {
            dispatch({
              type: "REPORT_USER_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            failureCallReportUser();
          } catch {
            // console.log(error);
          }
        });
}