import React from "react";
import { Typography, Row, Col } from "antd";
import { useBreakpoints } from './../react-responsive-hooks/index';
import "../App.css";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import _ from 'lodash';
import { useHistory } from "react-router";
export const FeaturedSeller = (props) => {
  const { isXS } = useBreakpoints();
  const history = useHistory()
  const { featuredSellers } = props;
  const { photo, fullName, bio, _id: sellerId } = featuredSellers || {};
  return (
      <div className="featuredSellerCard">
        <Row gutter={[0,0]}>
          <Col span={24}>
            {
              photo ? (
                <img alt="" src={photo} className="featuredSellerImg" style={{objectFit:'cover'}}/>
              ) : (
                <img alt="" src={UserBlanckImg} className="featuredSellerImg" />
              )
            }
          </Col>
          <div className="featuredParent">
            <Row>
              <Col span={24}>
                <Typography className="fSName"> {fullName}</Typography>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]} justify="start" style={{marginTop : bio ? "0px" : isXS ? "50px" : "30px"}}>
                  <Col span={24}></Col>
                  <Typography className="fSDes">
                    {" "}
                    {bio && bio.length > 10 ? 
                    bio.substring(0,25) + '...' 
                    : bio}
                    {/* {descriptions} */}
                  </Typography>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 10]} justify="start">
                  <Col span={24}></Col>
                  <Col>
                    <button type="text" className="fSView" onClick={() => history.push(`/seller-profile/${sellerId}`)}>
                      View Details
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
            </div>
        </Row>
      </div>
  );
};
