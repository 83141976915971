import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Row,
    Col,
    Typography,
    Avatar,
    Divider,
    Card
} from "antd";
import "../App.css";
import _ from "lodash";
import {  useParams,useLocation } from "react-router-dom";
import { useBreakpoints } from './../react-responsive-hooks/index';
import DeliveryImg from "../assets/DeliveryImg.svg";
const { Text, Title } = Typography;
const CheckoutOrderConfirmation = () => {
    const { isXS } = useBreakpoints();
    const { orderDetails, partnerOrderDetails, finalshippingprice,availPromotionsCheck,firstpurchasecheck, currentCart, useCredits } = useSelector((state) => {
        const { orderDetails, partnerOrderDetails } = state.order;
        const { availPromotionsCheck, currentCart,finalshippingprice,firstpurchasecheck, useCredits } = state.cart;
        return {
            orderDetails,
            partnerOrderDetails,
            firstpurchasecheck,
            availPromotionsCheck,
            currentCart,
            useCredits,
            finalshippingprice
        };
    });
    const params = useParams();
    const { lots, orderTotalInCents, shippingTotalInCents, usedMarkidCreditInCents, orderNumber, offerId } = orderDetails
    const { lot } = lots || {}
    const { firstpurchase } = params;
    const [shippingCharges, setShippingCharges] = useState(0)
    const [itemPrices, setItemPrices] = useState(0)
    const [itemShipPrice, setItemShipPrice] = useState(0)
    const [itemTotalPrices, setItemTotalPrices] = useState(0)
    let tempPrice = 0;
    useEffect(() => {
        if (lots && lots.length > 0) {
            lots.map((cartItems) => {
                if (cartItems.lot.isFree == false) {
                    tempPrice = parseInt(cartItems.lot.shippingPrice) + tempPrice
                    setShippingCharges(tempPrice)
                }
            })
        }
    }, [lots])
    useEffect(() => {
        if (partnerOrderDetails) {
            if (partnerOrderDetails.insertedP2Pdocs && partnerOrderDetails.insertedPartnerDocs) {
                if (partnerOrderDetails.insertedP2Pdocs.length && partnerOrderDetails.insertedPartnerDocs.length) {
                    setItemPrices(parseFloat(parseFloat((_.sumBy(lots, function (o) { return o.lot.price; }) / 100).toFixed(2)) + parseFloat((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? (parseFloat(partnerOrderDetails.insertedPartnerDocs[0].netPriceAmount.value) - parseFloat(partnerOrderDetails.insertedPartnerDocs[0].shippingCost.value)) / 100 : 0) : 0)))
                    setItemShipPrice((shippingCharges + (parseInt((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? partnerOrderDetails.insertedPartnerDocs[0].shippingCost.value : 0) : 0))) / 100)

                }
            }
        }
    }, [partnerOrderDetails])
    useEffect(() => {
        setItemTotalPrices(parseFloat(parseFloat(itemPrices) + parseFloat(itemShipPrice)))
    }, [itemPrices, itemShipPrice])

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
    const grouped = groupBy(currentCart, currentCart => currentCart.productType)
    let groupPartnerProducts = grouped.get("Partner")
    let groupP2pProducts = grouped.get("P2P")
    const [partnerDiscount, setPartnerDiscount] = useState(0)
    const [p2pDiscount, setP2pDiscount] = useState(0)
    const location = useLocation();
    useEffect(() => {
        if (groupPartnerProducts && (availPromotionsCheck == "both" || availPromotionsCheck == "partner")) {
            setPartnerDiscount(groupPartnerProducts.reduce((a, b) => { return a + b.price * 0.2 }, 0))
        }
    }, [groupPartnerProducts])
    useEffect(() => {
        if (groupP2pProducts && (availPromotionsCheck == "both" || availPromotionsCheck == "p2p")) {
            setP2pDiscount(groupP2pProducts.reduce((a, b) => { return a + b.price * 0.1 }, 0))
        }
    }, [groupP2pProducts])
    let currentPage = useLocation().pathname.split("/");
 
    return (
        <>
            <Row gutter={[0, { xs: 47, sm: 47, md: 60, lg: 60, xl: 60, xxl: 60 }]}>
                <Col span={24}></Col>
                <Col span={24} className="orderConfirmedParent">
                    <Card className='shoppingCartCard'>
                        <Row justify='center' gutter={[0, 6]}>
                            <Col span={24}></Col>
                            <Col>
                                <img alt="" src={DeliveryImg} />
                            </Col>
                        </Row>
                        <Row justify='center' gutter={[0, 21]}>
                            <Col span={24}></Col>
                            <Col>
                                <Typography className='orderThankYouText'>
                                    Thank you, your order <span className='orderId'>{orderNumber}</span> is confirmed!
                                </Typography>
                            </Col>
                        </Row>

                        <Row justify='center' gutter={[0, 12]}>
                            <Col span={24}></Col>
                            <Col>
                                <Typography className='orderThankYouDec'>
                                    You will receive an invoice mailed to you shortly. Meanwhile you can chat with the seller, interact with parents on our communities or even try selling on Markid.
                                </Typography>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[0, { xs: 50, sm: 50, md: 40, lg: 40, xl: 40, xxl: 40 }]}>
                <Col span={24}></Col>
                <Col>
                    <Typography className='orderDetailText'>
                        Order details
                    </Typography>
                </Col>
            </Row>

            <Row gutter={[0, 14]}>
                <Col span={24}></Col>
                <Col span={24} className="checkoutConfirmedParent">
                    <Card className='shoppingCartCard'>
                        {lots ?
                            <>
                                {lots.map((val, i) => (
                                    <div key={i}>
                                        <Row justify='space-between'>
                                            <Col>
                                                <Row gutter={[{ xs: 20, sm: 20, md: 33.55, lg: 33.55, xl: 33.55, xxl: 33.55 }, 0]} justify={isXS && "start"}>
                                                    <Col>
                                                        <Avatar size={65} shape='square' src={val && val.lot.imageUrls?.[0]} style={{ borderRadius: 4 }} />
                                                    </Col>
                                                    <Col>

                                                        <Typography className='orderItemName'>
                                                            {val && val.lot.name}
                                                        </Typography>
                                                        {isXS &&
                                                            <Typography className='orderItemPrice'>
                                                                ${val && val.lot.price / 100}
                                                            </Typography>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {!isXS && <Col>
                                                <Typography className='orderItemPrice'>
                                                    ${val && val.lot.price / 100}
                                                </Typography>
                                            </Col>}
                                        </Row>
                                        <Divider style={{ margin: "25px 0px 0px" }} />
                                    </div>
                                ))}
                            </> : <></>}
                        {(partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ?
                            <>
                                {partnerOrderDetails.insertedPartnerDocs.length ?
                                    partnerOrderDetails.insertedPartnerDocs[0].lineItems.map((val, i) => (
                                        <div key={i}>
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Row gutter={[{ xs: 20, sm: 20, md: 33.55, lg: 33.55, xl: 33.55, xxl: 33.55 }, 0]} justify={isXS && "start"}>
                                                        <Col>
                                                            <Avatar size={65} shape='square' src={val && val.product.imageLink} style={{ borderRadius: 4 }} />
                                                        </Col>
                                                        <Col>

                                                            <Typography className='orderItemName'>
                                                                {val && val.product.title}
                                                            </Typography>
                                                            {isXS &&
                                                                <Typography className='orderItemPrice'>
                                                                    ${val && (val.product.price.value) / 100}
                                                                </Typography>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {!isXS && <Col>
                                                    <Typography className='orderItemPrice'>
                                                        ${val && (val.product.price.value) / 100}
                                                    </Typography>
                                                </Col>}
                                            </Row>
                                            <Divider style={{ margin: "25px 0px 0px" }} />
                                        </div>
                                    )) : <></>}
                            </>
                            : <></>}
                        <Row gutter={[0, { xs: 25, sm: 25, md: 16, lg: 16, xl: 16, xxl: 16 }]} justify="space-between">
                            <Col span={24}></Col>
                            <Col>
                                <Row gutter={[0, 0]}>
                                    <Col></Col>
                                    <Col>
                                        <Typography className='pDKey'>Items total({parseInt((lots && lots.length) ? lots.length : 0) + parseInt((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? partnerOrderDetails.insertedPartnerDocs[0].lineItems.length : 0) : 0)})</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                            {currentPage[3]=="firstpurchase"
                                    ? <Typography className='pDVal'>${parseFloat((orderTotalInCents/100)- finalshippingprice).toFixed(2)}</Typography>
                                    : <Typography className='pDVal'>${parseFloat(parseFloat((_.sumBy(lots, function (o) { return o.lot.price; }) / 100).toFixed(2)) + parseFloat((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? (parseFloat(partnerOrderDetails.insertedPartnerDocs[0].netPriceAmount.value) - parseFloat(partnerOrderDetails.insertedPartnerDocs[0].shippingCost.value)) / 100 : 0) : 0))}</Typography>
                                }
                                
                              {/* {partnerOrderDetails &&partnerOrderDetails.insertedP2Pdocs.length==0
                                    ? <Typography className='pDVal'>${parseFloat(orderTotalInCents- shippingCharges) / 100}</Typography>
                                    : <Typography className='pDVal'>${parseFloat(parseFloat((_.sumBy(lots, function (o) { return o.lot.price; }) / 100).toFixed(2)) + parseFloat((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? (parseFloat(partnerOrderDetails.insertedPartnerDocs[0].netPriceAmount.value) - parseFloat(partnerOrderDetails.insertedPartnerDocs[0].shippingCost.value)) / 100 : 0) : 0))}</Typography>
                                }    */}

                            </Col>
                        </Row>
                        <Row gutter={[0, 25]} justify="space-between">
                            <Col span={24}></Col>
                            <Col>
                                <Row gutter={[0, 0]}>
                                    <Col></Col>
                                    <Col>
                                        <Typography className='pDKey'>Shipping</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Typography className='pDVal'>{((shippingCharges + (parseInt((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? partnerOrderDetails.insertedPartnerDocs[0].shippingCost.value : 0) : 0))) / 100).toFixed(2) > 0 ? "$" : ""}
                                    {((shippingCharges + (parseInt((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? partnerOrderDetails.insertedPartnerDocs[0].shippingCost.value : 0) : 0))) / 100).toFixed(2) > 0 ?
                                        ((shippingCharges + (parseInt((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? partnerOrderDetails.insertedPartnerDocs[0].shippingCost.value : 0) : 0))) / 100).toFixed(2) : "Free Shipping"}</Typography>
                            </Col>
                        </Row>
                        {groupPartnerProducts && (availPromotionsCheck == "both" || availPromotionsCheck == "partner") ?
                            <Row gutter={[0, 25]} justify="space-between">
                                <Col span={24}></Col>
                                <Col>
                                    <Row gutter={[0, 0]}>
                                        <Col></Col>
                                        <Col>
                                            <Typography className='pDKey'>Swoondle Discount</Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Typography className='pDVal'>- ${(partnerDiscount / 100).toFixed(2)}</Typography>
                                </Col>
                            </Row>
                            :
                            <></>
                        }
                        {groupP2pProducts && (availPromotionsCheck == "both" || availPromotionsCheck == "p2p") ?
                            <Row gutter={[0, 25]} justify="space-between">
                                <Col span={24}></Col>
                                <Col>
                                    <Row gutter={[0, 0]}>
                                        <Col></Col>
                                        <Col>
                                            <Typography className='pDKey'>Markid Discount</Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Typography className='pDVal'>- ${(p2pDiscount / 100).toFixed(2)}</Typography>
                                </Col>
                            </Row>
                            :
                            <></>
                        }
                        <Row gutter={[0, 25]} justify="space-between">
                            <Col span={24}></Col>
                            <Col>
                                <Row gutter={[0, 0]}>
                                    <Col></Col>
                                    <Col>
                                        <Typography className='pDKey'>Markid Credits</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            {usedMarkidCreditInCents && usedMarkidCreditInCents > 0 &&
                                <Col>
                                    <Typography className='pDVal'>- ${usedMarkidCreditInCents.toFixed(2)}</Typography>
                                </Col>
                            }
                        </Row>
                        <Divider style={{ margin: '25px 0px 0px' }} />
                        <Row gutter={[0, 25]} justify="space-between">
                            <Col span={24}></Col>
                            <Col>
                                <Row gutter={[0, 0]}>
                                    <Col></Col>
                                    <Col>
                                        <Text className='cartTotalKeyVal'>Cart total</Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Text className='cartTotalKeyVal'>${(orderTotalInCents > 0) ? parseFloat(orderTotalInCents / 100) : parseFloat(partnerOrderDetails.insertedPartnerDocs[0].netPriceAmount.value / 100).toFixed(2)}</Text>
                                {/* <Text className='cartTotalKeyVal'>${((itemTotalPrices > 0) ? itemTotalPrices : parseFloat(parseFloat(orderTotalInCents ? orderTotalInCents : 0) + parseFloat((!orderTotalInCents && partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? (partnerOrderDetails.insertedPartnerDocs.length ? partnerOrderDetails.insertedPartnerDocs[0].netPriceAmount.value : 0) : 0)) / 100).toFixed(2)}</Text> */}
                            </Col>

                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
export default CheckoutOrderConfirmation;

