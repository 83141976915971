import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Row, Col, Typography, Input, Modal, Spin, Alert } from "antd";
import {
  LoadingOutlined,
  UserOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "../App.css";
import step1 from "../assets/step1.png";
import {
  getOtp,
  createVerifyOtp,
  // signupWithApple,
  signupWithFacebook,
} from "../Actions/authAction";
import OtpInput from "react-otp-input";
import AppleSignin from "react-apple-signin-auth";
import FacebookLogin from "react-facebook-login";
import { getAllLots } from "../Actions/lotsAction";
import { updateUser } from "../Actions/userAction";
import Avatar from "antd/lib/avatar/avatar";
import AmazonImg from "../assets/AmazonImg.svg";
import AppleImg from "../assets/AppleImg.svg";
import FacebookImg from "../assets/FacebookImg.svg";
const { Title, Text } = Typography;
const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const schema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
});
const emailSchema = yup.object().shape({
  fullName: yup.string().required("Name is require field"),
  email: yup.string().required("Email is require field"),
});
const SignIn = (props) => {
  const { showSignInDialog, onCancelSignInMethod } = props;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const {
    isCreateSendOtpLoading,
    isCreateVerifyOtpLoading,
    getOtperror,
    verifyOtperror,
    // email,
    // fullName,
    // newUser,
    isUpdateUserLoading,
    providerToken
  } = useSelector((state) => {
    const {
      isCreateSendOtpLoading,
      isCreateVerifyOtpLoading,
      getOtperror,
      verifyOtperror,
      email,
      fullName,
      newUser,
      providerToken,
    } = state.auth;
    const { isUpdateUserLoading } = state.user;
    return {
      isCreateSendOtpLoading,
      isCreateVerifyOtpLoading,
      getOtperror,
      verifyOtperror,
      email,
      fullName,
      newUser,
      isUpdateUserLoading,
      providerToken,
    };
  });
 
  const dispatch = useDispatch();
  const history = useHistory();
  const [current, setCurrent] = React.useState(0);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [FbError, setFbError] = React.useState("");
  const [seconds, setSeconds] = React.useState(60);
  const [resedEnable, setResedEnable] = React.useState(false);
  const prev = () => {
    setCurrent(current - 1);
  };
  const handleClick = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    if (providerToken) {
      onCancelSignInMethod();
    }
  }, [providerToken]);
  React.useEffect(() => {
    handleClick();
  }, [getOtperror]);
  React.useEffect(() => {
    const script = document.createElement("script");
    // window.onAmazonLoginReady = function() {
    //   amazon.Login.setClientId('YOUR-CLIENT-ID');
    // };
    // (function(d) {
    //   var a = d.createElement('script'); a.type = 'text/javascript';
    //   a.async = true; a.id = 'amazon-login-sdk';
    //   a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
    //   d.getElementById('amazon-root').appendChild(a);
    // })(document);

    // script.src = "https://use.typekit.net/foobar.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);
  React.useEffect(() => {
    handleClick();
  }, [verifyOtperror]);
  // React.useEffect(() => {
  //   if (seconds > 0) {
  //     setTimeout(() => setSeconds(seconds - 1), 1000);
  //   } else {
  //     // setSeconds();
  //     setResedEnable(true)
  //   }
  // }, [seconds]);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { handleSubmit, control, errors, reset, getValues, formState } = useForm({
    resolver: yupResolver(schema),
    mode:'onChange',
    defaultValues: { phone: "" },
  });

  const { handleSubmit: handleEmailSubmit, getValues: emailGetValues, control: emailControl , errors: emailErrors, formState: emailFormState } = useForm({
    resolver: yupResolver(emailSchema),
    mode:'onChange',
    defaultValues: { email: "", fullName: "" },
  });
  const {phone} = getValues();
  const{ fullName } = emailGetValues()
  const handleOtpChange = (otp) => {
    setOtp(otp);
  };
  const handleOtp = (data) => {
    if (data.phone) {

    } else {
      data = {}
      data.phone = phoneNumber
    }
    dispatch(getOtp(data, successCallBackGetOtp, failureCallBackGetOtp));
    setPhoneNumber(data.phone);
    reset();
  };
  const successCallBackGetOtp = () => {
    if (current === 0 ) {
    setCurrent(current + 1);
    }
  };
  const failureCallBackGetOtp = () => {};

  const handleVerifyOtp = () => {
    dispatch(
      createVerifyOtp(
        { code: otp, phone: `1${phoneNumber}` },
        successCallBackVerifyOtp,
        failureCallBackVerifyOtp,
        reset()
      )
    );
  };
  const successCallBackVerifyOtp = (retunNewUser) => {
    // onCancelSignInMethod();
    if (retunNewUser === true) {
      setCurrent(current + 1);
      setOtp("");
    } else {
      onCancelSignInMethod();
      setCurrent(0);
      setOtp("");
      dispatch(getAllLots());
    }
  };
  const failureCallBackVerifyOtp = () => {};
  // const handleApple = (data) => {
  //   dispatch(signupWithApple(data));
  // };
  const responseFacebook = (response) => {
    // const { accessToken, id, name, picture, userID, signedRequest } = response;
    if (response.accessToken) {
      dispatch(signupWithFacebook(response));
      onCancelSignInMethod();
    } else {
      setFbError("Something went wrong. Try again.");
    }
  };
  const handleAmazonLogin = () => {
    // var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
    // http://localhost:3000/api/v1/auth/amazon
    history.push("/amazon")
    // let options = {
    //   // redirect_uri: "https://renewed.markid.io",
    // };
    // options.scope = "profile";
    // // options.pkce = true;
    // options.response_type = "code"
    // options.popup = false
    // window.amazon.Login.authorize(options, function (response) {
    //     if (response.error) {
    //         console.log('oauth error ' + response.error);
    //         return;
    //     }
    //     window.amazon.Login.retrieveToken(response.code, function (response) {
    //         if (response.error) {
    //             console.log('oauth error ' + response.error);
    //             return;
    //         }
    //         window.amazon.Login.retrieveProfile(response.access_token,
    //         function (response) {
    //             console.log('Hello, ' + response.profile.Name);
    //             console.log('Your e-mail address is ' + response.profile.PrimaryEmail);
    //             console.log('Your unique ID is ' + response.profile.CustomerId);
    //             if (window.console && window.console.log)
    //                 window.console.log(response);
    //         });
    //     });
    // });
    // window.amazon.Login.authorize(options, function(response) {
    //   console.log({response})
    // });
    // return false
  };
  const handleUpdateUser = (data) => {
    dispatch(
      updateUser(data, successCallBackUpdateUser, failureCallUpdateUser)
    );
  };
  const successCallBackUpdateUser = () => {
    onCancelSignInMethod();
    setCurrent(0);
    dispatch(getAllLots());
  };
  const failureCallUpdateUser = () => {};
  const handleCancel = () => {
    onCancelSignInMethod();
    reset();
    setCurrent(0);
  };
  // const userImgName = fullName ? fullName.charAt(0) : null;
  return (
    <>
      <Modal
        title={
          current === 1 ? <ArrowLeftOutlined onClick={() => prev()} /> : null
        }
        visible={showSignInDialog}
        onCancel={() => handleCancel()}
        centered
        footer={null}
        width={500}
        // style={{height: 520}}
      >
        {current === 0 && (
          <Spin indicator={antIcon} spinning={isCreateSendOtpLoading}>
            <form id="getOtp" onSubmit={handleSubmit(handleOtp)}>
              {/* <Row gutter={[32, 0]} justify="center">
                <Col span={24}></Col>
                <Col>
                  <img
                    style={{ height: "30px", width: "100px" }}
                    alt="logo"
                    src={logo}
                  />
                </Col>
                <Col span={24}></Col>
                <Col span={24}></Col>
              </Row> */}
              <Row justify="center" gutter={[0, 32]}>
                <Col span={24}></Col>
                <Col>
                  <Title level={4} className="signInHeading">
                    Sign up or login to continue
                  </Title>
                </Col>
              </Row>
              <Row justify="center" gutter={[16, 32]}>
                <Col span={24}></Col>
                <Col>
                  <a href="" id="LoginWithAmazon" onClick={handleAmazonLogin}>
                    <img src={AmazonImg} />
                    {/* <AmazonCircleFilled
                      style={{
                        fontSize: 40,
                      }}
                    /> */}
                  </a>
                </Col>
                {/* <Col>
                  <AppleSignin
                    authOptions={{
                      clientId: "com.markid.auth",
                      scope: "email name",
                      redirectURI: process.env.REACT_APP_DOMAIN+'apple',
                      state: "state",
                      nonce: "nonce",
                      usePopup: true,
                    }}
                    uiType="dark"
                    className="apple-auth-btn"
                    noDefaultStyle={false}
                    buttonExtraChildren="Continue with Apple"
                    onSuccess={(response) => console.log('check310', response)}
                    onError={(error) => console.error('error 311', error)}
                    skipScript={false}
                    // iconProp={{ style: { marginTop: "10px" } }}
                    render={(props) => (
                      <div style={{ cursor: "pointer" }} {...props}>
                        <img alt="" src={AppleImg} />
                      </div>
                    )}
                  />
                </Col> */}
                <Col>
                  <FacebookLogin
                    appId="450135898816373"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    textButton=""
                    redirect_uri={process.env.REACT_APP_DOMAIN}
                    disableMobileRedirect={true}
                    icon={<img alt="" src={FacebookImg} className="fa fa-facebook" />}
                  />
                </Col>
              </Row>

              <Row justify="center" gutter={[0, 48]}>
                <Col span={24}></Col>
                <Col span={24}></Col>
                <Col>
                  <Text className="useText">Or use mobile number</Text>
                </Col>
              </Row>

              <Row justify="center" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col xl={18} lg={18} md={18} sm={12} xs={12} className="signinPhoneInput">
                  <Controller
                    as={<Input addonBefore="+1" className="phoneInput" bordered={false} />}
                    // addonBefore="+1" 
                    // className="signinPhoneInput"
                    name="phone"
                    control={control}
                    placeholder="Phone number"
                  />
                  {errors.phone && phone.length === 0 && (
                    <p style={{ color: "red" }}>
                      Phone number is required
                    </p>
                  )}
                  {formState.isSubmitted && errors.phone && errors.phone?.message && (
                    <p style={{ color: "red" }}>
                        {errors.phone.message}
                      </p>
                  )}
                </Col>
              </Row>
              <Row justify="center" gutter={[0, 30]}>
                <Col span={24}></Col>
                <Col>
                  <Button htmlType="submit" className={"signinButton"}>
                    CONTINUE
                  </Button>
                </Col>
              </Row>
              {FbError && (
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Alert
                      type="error"
                      closable
                      onClose={handleClose}
                      open={open}
                      message={FbError}
                    ></Alert>
                  </Col>
                </Row>
              )}
              {getOtperror && (
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Alert
                      type="error"
                      onClose={handleClose}
                      open={open}
                      message={getOtperror.error.message}
                    ></Alert>
                  </Col>
                </Row>
              )}
            </form>
          </Spin>
        )}
        {current === 1 && (
          <>
            <Spin indicator={antIcon} spinning={isCreateVerifyOtpLoading}>
              <form onSubmit={handleSubmit(handleVerifyOtp)}>
                <Row justify="center" gutter={[4, 32]}>
                  <Col span={24}></Col>
                  <Col>
                    <Text className="verifyHeading">Verify it's you</Text>
                  </Col>
                </Row>
                <Row justify="center" gutter={[0, 10]}>
                  <Col span={24}></Col>
                  <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                    <Typography className="sendOtpText">
                      We have sent an OTP to verify your number +1{phoneNumber}
                    </Typography>
                  </Col>
                </Row>
                {/* <Row justify="center" gutter={[4, 0]}>
                  <Col>
                    <Text>{phoneNumber}</Text>
                  </Col>
                </Row> */}

                <Row justify="center" gutter={[0, 6]}>
                  <Col span={24}></Col>
                  <Col
                    justify="center"
                    align="middle"
                    display="contents"
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <img alt="step1" src={step1} className="verifyImg" />
                    {/* <CheckCircleOutlined  style={{maxWidth: '100%'}}/> */}
                  </Col>
                  <Col span={24}></Col>
                </Row>

                <Row gutter={[0, 48]} justify="center">
                  <Col span={24}></Col>
                  <Col>
                    <OtpInput
                      inputStyle="inputStyle"
                      value={otp}
                      isInputNum={true}
                      onChange={handleOtpChange}
                      numInputs={6}
                    />
                  </Col>
                </Row>
                <Row justify="center" gutter={[8, 23]}>
                  <Col>
                    <Button
                    type='text'
                    disabled={!resedEnable}
                    loading={isCreateSendOtpLoading}
                    htmlType='submit'
                    form="getOtp"
                      className="resendLink"
                      onClick={() => {
                        if (resedEnable) {
                          handleOtp(phoneNumber);
                          setSeconds(60)
                          setResedEnable(false)
                        } else {
                          return null;
                        }
                      }}
                    >
                      Resend
                    </Button>
                  </Col>
                  {/* <Col>
                    <Text>(0:{seconds})</Text>
                  </Col> */}
                </Row>
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Button htmlType="submit" className="signinButton">
                      Verify
                    </Button>
                  </Col>
                </Row>
                {verifyOtperror && (
                  <Row justify="center" gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Col>
                      <Alert
                        type="error"
                        onClose={handleClose}
                        open={open}
                        message={verifyOtperror.error.message}
                      ></Alert>
                    </Col>
                  </Row>
                )}
              </form>
            </Spin>
          </>
        )}
        {current === 2 && (
          <>
            <Spin indicator={antIcon} spinning={isUpdateUserLoading}>
              <form onSubmit={handleEmailSubmit(handleUpdateUser)}>
                <Row justify="center" gutter={[4, 32]}>
                  <Col span={24}></Col>
                  <Col>
                    <Text style={{ fontSize: "3.2vh" }} strong>
                      Your Details
                    </Text>
                  </Col>
                </Row>
                <Row justify="center" gutter={[8, 20]}>
                  <Col span={24}></Col>
                  <Col
                    justify="center"
                    align="middle"
                    display="contents"
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    {fullName && fullName.length > 0 ? 
    <Avatar size={64}>{fullName.charAt(0)}</Avatar>
:
                    <Avatar size={64} icon={<UserOutlined />} />
}
                    {/* <CheckCircleOutlined  style={{maxWidth: '100%'}}/> */}
                  </Col>
                  <Col span={24}></Col>
                </Row>
                <Row justify="center" gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Col span={16}>
                    <Controller
                      as={Input}
                      style={{ backgroundColor: "#f6f6f6" }}
                      name="fullName"
                      control={emailControl}
                      placeholder="Name"
                    />
                   {emailFormState.isSubmitted && emailErrors.fullName && emailErrors.fullName?.message && (
                    <p style={{ color: "red", marginBottom: 0 }}>
                        {emailErrors.fullName.message}
                      </p>
                  )}
                    {/* {errors.first_name && (
                      <span style={{ color: "red" }}>Name is required</span>
                    )} */}
                  </Col>
                  <Col span={16}>
                    <Controller
                      as={Input}
                      style={{ backgroundColor: "#f6f6f6" }}
                      name="email"
                      control={emailControl}
                      placeholder="Email"
                    />
                    {emailFormState.isSubmitted && emailErrors.email && emailErrors.email?.message && (
                    <p style={{ color: "red", marginBottom: 0 }}>
                        {emailErrors.email.message}
                      </p>
                  )}
                  </Col>
                </Row>
                <Row justify="center" gutter={[0, 48]}>
                  <Col span={24}></Col>
                  <Col>
                    <Button htmlType="submit" className={"signinButton"}>
                      Save
                    </Button>
                  </Col>
                </Row>
                {/* {verifyOtperror && (
                  <Row justify="center" gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Col>
                      <Alert
                        type="error"
                        onClose={handleClose}
                        open={open}
                        message={verifyOtperror.error.message}
                      ></Alert>
                    </Col>
                  </Row>
                )} */}
              </form>
            </Spin>
          </>
        )}
      </Modal>
    </>
  );
};
export default SignIn;
