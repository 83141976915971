import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Card, Typography, Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import "../App.css";
// import EmptyHeart from "../assets/UnFavorite.svg";
import FullHeart from "../assets/Favorite.svg";
// import EmptyWishlist from "../assets/EmptyWishlist.svg";
// import FillWishlist from "../assets/FillWishlist.svg";
import SignIn from "../pages/SignIn";

export const RecommendedCard = (props) => {
    const [showSignInDialog, setShowSignInDialog] = useState(false);
    // const dispatch = useDispatch();
    // const history = useHistory();
    // const { isAuthenticated } = useSelector((state) => {
    //   const { isAuthenticated } = state.auth;
    //   return {
    //     isAuthenticated,
    //   };
    // });
    const {
    //   imageUrls,
    //   name,
      // brand,
      // category,
      // inSoldPending,
      // inUserFavorites,
    //   price,
      // costPrice,
      // chips,
      // shipping,
    //   deliveryType,
      itemName,
      cover,
      price,
      shipping,
      image
      // bordered,
      // sold,
    //   _id,
      // rating,
      // toatalReviews,
    } = props;
    // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  //   const handleAddToFavorite = () => {
  //     if (isAuthenticated === true) {
  //       dispatch(addToFavorites({ id: _id }, successCallBack, failureCallBack));
  //     } else {
  //       setShowSignInDialog(true)
  //     }
  //   };
  //   const handleRemoveFromFavorite = () => {
  //     if (isAuthenticated === true) {
  //       dispatch(removeFromFavorites({ id: _id }, successCallBack, failureCallBack));
  //     } else {
  //       setShowSignInDialog(true)
  //     }
  //   };
  //   const successCallBack = () => {
  //     dispatch(getAllLots());
  //   };
  //   const failureCallBack = () => { };
    // const capitalize = (s) => {
    //   if (typeof s !== 'string') return ''
    //   return s.charAt(0).toUpperCase() + s.slice(1)
    // }
    return (
      <>
        <div className="pLSimilarParent">
          <Card className='pLSimilarCard'
              cover={
                image && 
                <LazyLoadImage effect="blur" style={{width:"100%"}} src={image}  alt="" />
              }
              >
            {/* <img src={images} alt="sample" className="item-image" onClick={() => history.push(`/products/${name}/${_id}`)} /> */}
            {/* <div className="itemButtonSold">
              {inSoldPending ? (
                <div className="sold-button-div">
                  <Button type="text" className="sold-button">
                    SOLD
                  </Button>
                </div>
              ) :
                <div className="sold-button-div">
                </div>}
            </div> */}
            <div className="pLitemButtonLike">
              <div>
                {/* {inUserFavorites ? ( */}
                  <img
                    src={FullHeart}
                    alt=""
                  //   onClick={() => handleRemoveFromFavorite()}
                  />
                {/* ) : (
                  <img
                    src={EmptyHeart}
                    alt=""
                  //   onClick={() => handleAddToFavorite()}
                  />
                )} */}
              </div>
            </div>
  
  
            <div 
          //   onClick={() => history.push(`/products/${name}/${_id}`)} 
          //   className="category-details"
            >
              <Typography
                className="pLSCardTitle"
              //   style={{ paddingBottom: brand ? 0 : 20 }}
              >
                  {itemName}
              {/* {capitalize(name)} */}
              </Typography>
              {/* <Typography className="brand-name"> {capitalize(brand)}</Typography> */}
              {/* <Typography className="brand-name">{category}</Typography> */}
              <Row gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col>
                  <Row gutter={[0, 0]}>
                    <Col>
                      <Typography className="pLSPrice">
                          {price}
                          {/* ${price / 100} */}
                          </Typography>
                    </Col>
                  </Row>
                </Col>
                {/* <Col>
                  {rating}
                  <Row justify="end">
                    <Typography>{toatalReviews}</Typography>
                  </Row>
                </Col> */}
              </Row>
              <Row gutter={[0, 8]} justify='end'>
                <Col span={24}></Col>
                {/* <Col span={12}>
                  <Typography className="renewed-text">reNEWED</Typography>
                </Col> */}
                <Col span={12}>
                  <Typography className="pLSShipping">
                    {/* {deliveryType === "both" ? ( */}
                      <Typography>{shipping}</Typography>
                    {/* ) : (
                      capitalize(deliveryType)
                    )} */}
                  </Typography>
                </Col>
              </Row>
  
            </div>
  
            {/* <div className="categoryFooterLeft">
              <Typography className="renewed-text">reNEWED</Typography>
            </div>
            <div className="categoryFooterRight">
              <Typography className="shipping">
                Free Shipping
              </Typography>
            </div> */}
  
          </Card>
        </div>
        <SignIn
          onCancelSignInMethod={() => setShowSignInDialog(false)}
          showSignInDialog={showSignInDialog}
        />
      </>
    );
  };
  