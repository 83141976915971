// eslint-disable-next-line
export default {
  CREATE_SEND_OTP_REQUEST: "CREATE_SEND_OTP_REQUEST",
  CREATE_SEND_OTP_SUCCESS: "CREATE_SEND_OTP_SUCCESS",
  CREATE_SEND_OTP_FAILURE: "CREATE_SEND_OTP_FAILURE",

  CREATE_VERIFY_OTP_REQUEST: "CREATE_VERIFY_OTP_REQUEST",
  CREATE_VERIFY_OTP_SUCCESS: "CREATE_VERIFY_OTP_SUCCESS",
  CREATE_VERIFY_OTP_FAILURE: "CREATE_VERIFY_OTP_FAILURE",

  SIGNUP_WITH_FACEBOOK_REQUEST: "SIGNUP_WITH_FACEBOOK_REQUEST",
  SIGNUP_WITH_FACEBOOK_SUCCESS: "SIGNUP_WITH_FACEBOOK_SUCCESS",
  SIGNUP_WITH_FACEBOOK_FAILURE: "SIGNUP_WITH_FACEBOOK_FAILURE",

  SIGNUP_WITH_APPLE_REQUEST: "SIGNUP_WITH_APPLE_REQUEST",
  SIGNUP_WITH_APPLE_SUCCESS: "SIGNUP_WITH_APPLE_SUCCESS",
  SIGNUP_WITH_APPLE_FAILURE: "SIGNUP_WITH_APPLE_FAILURE",

  SIGNUP_WITH_AMAZON_REQUEST: "SIGNUP_WITH_AMAZON_REQUEST",
  SIGNUP_WITH_AMAZON_SUCCESS: "SIGNUP_WITH_AMAZON_SUCCESS",
  SIGNUP_WITH_AMAZON_FAILURE: "SIGNUP_WITH_AMAZON_FAILURE",

  POST_EMAIL_UPDATES_REQUEST: "POST_EMAIL_UPDATES_REQUEST",
  POST_EMAIL_UPDATES_SUCCESS: "POST_EMAIL_UPDATES_SUCCESS",
  POST_EMAIL_UPDATES_FAILURE: "POST_EMAIL_UPDATES_FAILURE",

  SET_LOGIN_SIGNUP: "SET_LOGIN_SIGNUP",

  CREATE_CONSIGNMENT_REQUEST: "CREATE_CONSIGNMENT_REQUEST",
  CREATE_CONSIGNMENT_SUCCESS: "CREATE_CONSIGNMENT_SUCCESS",
  CREATE_CONSIGNMENT_FAILURE: "CREATE_CONSIGNMENT_FAILURE",

  CREATE_ACCOUNT_REQUEST: "CREATE_ACCOUNT_REQUEST",
  CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_FAILURE: "CREATE_ACCOUNT_FAILURE",

  CREATE_LOGIN_LINK_REQUEST: "CREATE_LOGIN_LINK_REQUEST",
  CREATE_LOGIN_LINK_SUCCESS: "CREATE_LOGIN_LINK_SUCCESS",
  CREATE_LOGIN_LINK_FAILURE: "CREATE_LOGIN_LINK_FAILURE",

  GET_BALANCE_REQUEST: "GET_BALANCE_REQUEST",
  GET_BALANCE_SUCCESS: "GET_BALANCE_SUCCESS",
  GET_BALANCE_FAILURE: "GET_BALANCE_FAILURE",

  CHECK_PAYOUT_STATUS_REQUEST: "CHECK_PAYOUT_STATUS_REQUEST",
  CHECK_PAYOUT_STATUS_SUCCESS: "CHECK_PAYOUT_STATUS_SUCCESS",
  CHECK_PAYOUT_STATUS_FAILURE: "CHECK_PAYOUT_STATUS_FAILURE",

  GET_CARDS_REQUEST: "GET_CARDS_REQUEST",
  GET_CARDS_SUCCESS: "GET_CARDS_SUCCESS",
  GET_CARDS_FAILURE: "GET_CARDS_FAILURE",

  ADD_CARD_REQUEST: "ADD_CARD_REQUEST",
  ADD_CARD_SUCCESS: "ADD_CARD_SUCCESS",
  ADD_CARD_FAILURE: "ADD_CARD_FAILURE",

  DELETE_CARD_REQUEST: "DELETE_CARD_REQUEST",
  DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
  DELETE_CARD_FAILURE: "DELETE_CARD_FAILURE",

  GET_DEFAULT_CARD_REQUEST: "GET_DEFAULT_CARD_REQUEST",
  GET_DEFAULT_CARD_SUCCESS: "GET_DEFAULT_CARD_SUCCESS",
  GET_DEFAULT_CARD_FAILURE: "GET_DEFAULT_CARD_FAILURE",

  SET_DEFAULT_CARD_REQUEST: "SET_DEFAULT_CARD_REQUEST",
  SET_DEFAULT_CARD_SUCCESS: "SET_DEFAULT_CARD_SUCCESS",
  SET_DEFAULT_CARD_FAILURE: "SET_DEFAULT_CARD_FAILURE",

  CHECK_MARKID_FEE_REQUEST: "CHECK_MARKID_FEE_REQUEST",
  CHECK_MARKID_FEE_SUCCESS: "CHECK_MARKID_FEE_SUCCESS",
  CHECK_MARKID_FEE_FAILURE: "CHECK_MARKID_FEE_FAILURE",

  PROCESS_PAYMENT_REQUEST: "PROCESS_PAYMENT_REQUEST",
  PROCESS_PAYMENT_SUCCESS: "PROCESS_PAYMENT_SUCCESS",
  PROCESS_PAYMENT_FAILURE: "PROCESS_PAYMENT_FAILURE",

  GET_PAYMENT_METHOD_REQUEST: "GET_PAYMENT_METHOD_REQUEST",
  GET_PAYMENT_METHOD_SUCCESS: "GET_PAYMENT_METHOD_SUCCESS",
  GET_PAYMENT_METHOD_FAILURE: "GET_PAYMENT_METHOD_FAILURE",

  GET_ALGOLIABRANDS_REQUEST: "GET_ALGOLIABRANDS_REQUEST",
  GET_ALGOLIABRANDS_SUCCESS: "GET_ALGOLIABRANDS_SUCCESS",
  GET_ALGOLIABRANDS_FAILURE: "GET_ALGOLIABRANDS_FAILURE",

  GET_ALL_LOTS_REQUEST: "GET_ALL_LOTS_REQUEST",
  GET_ALL_LOTS_SUCCESS: "GET_ALL_LOTS_SUCCESS",
  GET_ALL_LOTS_FAILURE: "GET_ALL_LOTS_FAILURE",

  GET_ALL_PARTNERPRODUCTS_REQUEST: "GET_ALL_PARTNERPRODUCTS_REQUEST",
  GET_ALL_PARTNERPRODUCTS_SUCCESS: "GET_ALL_PARTNERPRODUCTS_SUCCESS",
  GET_ALL_PARTNERPRODUCTS_FAILURE: "GET_ALL_PARTNERPRODUCTS_FAILURE",

  GET_ALL_MARKIDPRODUCTS_REQUEST: "GET_ALL_MARKIDPRODUCTS_REQUEST",
  GET_ALL_MARKIDPRODUCTS_SUCCESS: "GET_ALL_MARKIDPRODUCTS_SUCCESS",
  GET_ALL_MARKIDPRODUCTS_FAILURE: "GET_ALL_MARKIDPRODUCTS_FAILURE",

  GET_NON_RENEWED_LOTS_REQUEST: "GET_NON_RENEWED_LOTS_REQUEST",
  GET_NON_RENEWED_LOTS_SUCCESS: "GET_NON_RENEWED_LOTS_SUCCESS",
  GET_NON_RENEWED_LOTS_FAILURE: "GET_NON_RENEWED_LOTS_FAILURE",

  GET_FILTER_LOTS_REQUEST: "GET_FILTER_LOTS_REQUEST",
  GET_FILTER_LOTS_SUCCESS: "GET_FILTER_LOTS_SUCCESS",
  GET_FILTER_LOTS_FAILURE: "GET_FILTER_LOTS_FAILURE",
  RESER_FILTER_PRODUCTS: "RESER_FILTER_PRODUCTS",

  GET_FILTER_PARTNERPRODUCTS_REQUEST: "GET_FILTER_PARTNERPRODUCTS_REQUEST",
  GET_FILTER_PARTNERPRODUCTS_SUCCESS: "GET_FILTER_PARTNERPRODUCTS_SUCCESS",
  GET_FILTER_PARTNERPRODUCTS_FAILURE: "GET_FILTER_PARTNERPRODUCTS_FAILURE",

  GET_YEAR_OF_DETAILS_REQUEST: "GET_YEAR_OF_DETAILS_REQUEST",
  GET_YEAR_OF_DETAILS_SUCCESS: "GET_YEAR_OF_DETAILS_SUCCESS",
  GET_YEAR_OF_DETAILS_FAILURE: "GET_YEAR_OF_DETAILS_FAILURE",

  FILTER_LOTS: "FILTER_LOTS",

  FILTER_PARTNERPRODUCTS: "FILTER_PARTNERPRODUCTS",

  GET_LOT_BY_ID_REQUEST: "GET_LOT_BY_ID_REQUEST",
  GET_LOT_BY_ID_SUCCESS: "GET_LOT_BY_ID_SUCCESS",
  GET_LOT_BY_ID_FAILURE: "GET_LOT_BY_ID_FAILURE",

  CREATE_LOT_REQUEST: "CREATE_LOT_REQUEST",
  CREATE_LOT_SUCCESS: "CREATE_LOT_SUCCESS",
  CREATE_LOT_FAILURE: "CREATE_LOT_FAILURE",

  RESET_SEARCH: "RESET_SEARCH",

  GET_BLOGS_REQUEST: "GET_BLOGS_REQUEST",
  GET_BLOGS_SUCCESS: "GET_BLOGS_SUCCESS",
  GET_BLOGS_FAILURE: "GET_BLOGS_FAILURE",

  GET_BLOGS_IMAGE_REQUEST: "GET_BLOGS_IMAGE_REQUEST",
  GET_BLOGS_IMAGE_SUCCESS: "GET_BLOGS_IMAGE_SUCCESS",
  GET_BLOGS_IMAGE_FAILURE: "GET_BLOGS_IMAGE_FAILURE",

  GET_BLOG_IMAGE_ID_REQUEST: "GET_BLOG_IMAGE_ID_REQUEST",
  GET_BLOG_IMAGE_ID_SUCCESS: "GET_BLOG_IMAGE_ID_SUCCESS",
  GET_BLOG_IMAGE_ID_FAILURE: "GET_BLOG_IMAGE_ID_FAILURE",

  GET_BLOGSTAGS_REQUEST: "GET_BLOGSTAGS_REQUEST",
  GET_BLOGSTAGS_SUCCESS: "GET_BLOGSTAGS_SUCCESS",
  GET_BLOGSTAGS_FAILURE: "GET_BLOGSTAGS_FAILURE",

  GET_BLOGS_TAGS_REQUEST: "GET_BLOGS_TAGS_REQUEST",
  GET_BLOGS_TAGS_SUCCESS: "GET_BLOGS_TAGS_SUCCESS",
  GET_BLOGS_TAGS_FAILURE: "GET_BLOGS_TAGS_FAILURE",

  GET_BLOGS_BY_ID_REQUEST: "GET_BLOGS_BY_ID_REQUEST",
  GET_BLOGS_BY_ID_SUCCESS: "GET_BLOGS_BY_ID_SUCCESS",
  GET_BLOGS_BY_ID_FAILURE: "GET_BLOGS_BY_ID_FAILURE",

  ADD_BOOKMARK_REQUEST: "ADD_BOOKAMRK_REQUEST",
  ADD_BOOKMARK_SUCCESS: "ADD_BOOKMARK_SUCCESS",
  ADD_BOOKMARK_FAILURE: "ADD_BOOKMARK_FAILURE",

  REMOVE_BOOKMARK_REQUEST: "REMOVE_BOOKAMRK_REQUEST",
  REMOVE_BOOKMARK_SUCCESS: "REMOVE_BOOKMARK_SUCCESS",
  REMOVE_BOOKMARK_FAILURE: "REMOVE_BOOKMARK_FAILURE",

  GET_BLOGBOOKMARK_REQUEST: "GET_BLOGBOOKAMRK_REQUEST",
  GET_BLOGBOOKMARK_SUCCESS: "GET_BLOGBOOKMARK_SUCCESS",
  GET_BLOGBOOKMARK_FAILURE: "GET_BLOGBOOKMARK_FAILURE",

  ADD_TO_FAVORITES_REQUEST: "ADD_TO_FAVORITES_REQUEST",
  ADD_TO_FAVORITES_SUCCESS: "ADD_TO_FAVORITES_SUCCESS",
  ADD_TO_FAVORITES_FAILURE: "ADD_TO_FAVORITES_FAILURE",

  REMOVE_FROM_FAVORITES_REQUEST: "REMOVE_FROM_FAVORITES_REQUEST",
  REMOVE_FROM_FAVORITES_SUCCESS: "REMOVE_FROM_FAVORITES_SUCCESS",
  REMOVE_FROM_FAVORITES_FAILURE: "REMOVE_FROM_FAVORITES_FAILURE",

  GET_RATINGS_BY_USER_ID_REQUEST: "GET_RATINGS_BY_USER_ID_REQUEST",
  GET_RATINGS_BY_USER_ID_SUCCESS: "GET_RATINGS_BY_USER_ID_SUCCESS",
  GET_RATINGS_BY_USER_ID_FAILURE: "GET_RATINGS_BY_USER_ID_FAILURE",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  GET_SELLER_USER_LOTS_REQUEST: "GET_SELLER_USER_LOTS_REQUEST",
  GET_SELLER_USER_LOTS_SUCCESS: "GET_SELLER_USER_LOTS_SUCCESS",
  GET_SELLER_USER_LOTS_FAILURE: "GET_SELLER_USER_LOTS_FAILURE",

  GET_SELLER_BADGES_REQUEST: "GET_SELLER_BADGES_REQUEST",
  GET_SELLER_BADGES_SUCCESS: "GET_SELLER_BADGES_SUCCESS",
  GET_SELLER_BADGES_FAILURE: "GET_SELLER_BADGES_FAILURE",

  GET_USER_FAVORITES_LOTS_REQUEST: "GET_USER_FAVORITES_LOTS_REQUEST",
  GET_USER_FAVORITES_LOTS_SUCCESS: "GET_USER_FAVORITES_LOTS_SUCCESS",
  GET_USER_FAVORITES_LOTS_FAILURE: "GET_USER_FAVORITES_LOTS_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  BLOCK_USER_REQUEST: "BLOCK_USER_REQUEST",
  BLOCK_USER_SUCCESS: "BLOCK_USER_SUCCESS",
  BLOCK_USER_FAILURE: "BLOCK_USER_FAILURE",

  UNBLOCK_USER_REQUEST: "UNBLOCK_USER_REQUEST",
  UNBLOCK_USER_SUCCESS: "UNBLOCK_USER_SUCCESS",
  UNBLOCK_USER_FAILURE: "UNBLOCK_USER_FAILURE",

  REPORT_USER_REQUEST: "REPORT_USER_REQUEST",
  REPORT_USER_SUCCESS: "REPORT_USER_SUCCESS",
  REPORT_USER_FAILURE: "REPORT_USER_FAILURE",

  NOTIFY_ITEM_REQUEST: "NOTIFY_ITEM_REQUEST",
  NOTIFY_ITEM_SUCCESS: "NOTIFY_ITEM_SUCCESS",
  NOTIFY_ITEM_FAILURE: "NOTIFY_ITEM_FAILURE",

  UNNOTIFY_ITEM_REQUEST: "UNNOTIFY_ITEM_REQUEST",
  UNNOTIFY_ITEM_SUCCESS: "UNNOTIFY_ITEM_SUCCESS",
  UNNOTIFY_ITEM_FAILURE: "UNNOTIFY_ITEM_FAILURE",

  CONTACT_US_REQUEST: "CONTACT_US_REQUEST",
  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",
  CONTACT_US_FAILURE: "CONTACT_US_FAILURE",

  FOLLOW_USER_REQUEST: "FOLLOW_USER_REQUEST",
  FOLLOW_USER_SUCCESS: "FOLLOW_USER_SUCCESS",
  FOLLOW_USER_FAILURE: "FOLLOW_USER_FAILURE",

  UNFOLLOW_USER_REQUEST: "UNFOLLOW_USER_REQUEST",
  UNFOLLOW_USER_SUCCESS: "UNFOLLOW_USER_SUCCESS",
  UNFOLLOW_USER_FAILURE: "UNFOLLOW_USER_FAILURE",

  ADD_ADDRESS_REQUEST: "ADD_ADDRESS_REQUEST",
  ADD_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_FAILURE: "ADD_ADDRESS_FAILURE",

  UPDATE_ADDRESS_REQUEST: "UPDATE_ADDRESS_REQUEST",
  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",

  REMOVE_ADDRESS_REQUEST: "REMOVE_ADDRESS_REQUEST",
  REMOVE_ADDRESS_SUCCESS: "REMOVE_ADDRESS_SUCCESS",
  REMOVE_ADDRESS_FAILURE: "REMOVE_ADDRESS_FAILURE",

  SET_AS_DEAFULT_ADDRESS_REQUEST: "SET_AS_DEAFULT_ADDRESS_REQUEST",
  SET_AS_DEAFULT_ADDRESS_SUCCESS: "SET_AS_DEAFULT_ADDRESS_SUCCESS",
  SET_AS_DEAFULT_ADDRESS_FAILURE: "SET_AS_DEAFULT_ADDRESS_FAILURE",

  ADD_ZIPCODE_REQUEST: "ADD_ZIPCODE_REQUEST",
  ADD_ZIPCODE_SUCCESS: "ADD_ZIPCODE_SUCCESS",
  ADD_ZIPCODE_FAILURE: "ADD_ZIPCODE_FAILURE",

  ADD_BRANDS_REQUEST: "ADD_BRANDS_REQUEST",
  ADD_BRANDS_SUCCESS: "ADD_BRANDS_SUCCESS",
  ADD_BRANDS_FAILURE: "ADD_BRANDS_FAILURE",

  ADD_CATEGORIES_REQUEST: "ADD_CATEGORIES_REQUEST",
  ADD_CATEGORIES_SUCCESS: "ADD_CATEGORIES_SUCCESS",
  ADD_CATEGORIES_FAILURE: "ADD_CATEGORIES_FAILURE",

  UPLOAD_USER_PHOTO_REQUEST: "UPLOAD_USER_PHOTO_REQUEST",
  UPLOAD_USER_PHOTO_SUCCESS: "UPLOAD_USER_PHOTO_SUCCESS",
  UPLOAD_USER_PHOTO_FAILURE: "UPLOAD_USER_PHOTO_FAILURE",

  UPLOAD_PRODUCT_PHOTO_REQUEST: "UPLOAD_PRODUCT_PHOTO_REQUEST",
  UPLOAD_PRODUCT_PHOTO_SUCCESS: "UPLOAD_PRODUCT_PHOTO_SUCCESS",
  UPLOAD_PRODUCT_PHOTO_FAILURE: "UPLOAD_PRODUCT_PHOTO_FAILURE",

  ADD_KIDS_REQUEST: "ADD_KIDS_REQUEST",
  ADD_KIDS_SUCCESS: "ADD_KIDS_SUCCESS",
  ADD_KIDS_FAILURE: "ADD_KIDS_FAILURE",

  JOIN_COMMUNITY_REQUEST: "JOIN_COMMUNITY_REQUEST",
  JOIN_COMMUNITY_SUCCESS: "JOIN_COMMUNITY_SUCCESS",
  JOIN_COMMUNITY_FAILURE: "JOIN_COMMUNITY_FAILURE",

  MORE_FROM_THIS_SELLER_REQUEST: "MORE_FROM_THIS_SELLER_REQUEST",
  MORE_FROM_THIS_SELLER_SUCCESS: "MORE_FROM_THIS_SELLER_SUCCESS",
  MORE_FROM_THIS_SELLER_FAILURE: "MORE_FROM_THIS_SELLER_FAILURE",

  SIMILAR_ITEMS_REQUEST: "SIMILAR_ITEMS_REQUEST",
  SIMILAR_ITEMS_SUCCESS: "SIMILAR_ITEMS_SUCCESS",
  SIMILAR_ITEMS_FAILURE: "SIMILAR_ITEMS_FAILURE",

  ADD_ITEM_CART: "ADD_ITEM_CART",

  EMPTY_ITEM_CART: "EMPTY_ITEM_CART",

  REMOVE_ITEM_CART: "REMOVE_ITEM_CART",

  SELECT_ADDRESS_ID: "SELECT_ADDRESS_ID",

  USE_MARKID_CREDIT: "USE_MARKID_CREDIT",

  ADD_ITEM_CART_OVER_CURRENT_CART: "ADD_ITEM_CART_OVER_CURRENT_CART",

  GET_LAST_CART_REQUEST: "GET_LAST_CART_REQUEST",
  GET_LAST_CART_SUCCESS: "GET_LAST_CART_SUCCESS",
  GET_LAST_CART_FAILURE: "GET_LAST_CART_FAILURE",

  POST_CART_REQUEST: "POST_CART_REQUEST",
  POST_CART_SUCCESS: "POST_CART_SUCCESS",
  POST_CART_FAILURE: "POST_CART_FAILURE",

  SET_CURRENT_CART: "SET_CURRENT_CART",
  DELETE_CURRENT_CART: "DELETE_CURRENT_CART",
  EMPTY_CURRENT_CART: "EMPTY_CURRENT_CART",

  UPDATE_CART_REQUEST: "UPDATE_CART_REQUEST",
  UPDATE_CART_SUCCESS: "UPDATE_CART_SUCCESS",
  UPDATE_CART_FAILURE: "UPDATE_CART_FAILURE",

  POST_STRIPE_PAYMENT_REQUEST: "POST_STRIPE_PAYMENT_REQUEST",
  POST_STRIPE_PAYMENT_SUCCESS: "POST_STRIPE_PAYMENT_SUCCESS",
  POST_STRIPE_PAYMENT_FAILURE: "POST_STRIPE_PAYMENT_FAILURE",

  GET_WALLET_REQUEST: "GET_WALLET_REQUEST",
  GET_WALLET_SUCCESS: "GET_WALLET_SUCCESS",
  GET_WALLET_FAILURE: "GET_WALLET_FAILURE",

  VERIFY_PROMO_CODE_REQUEST: "VERIFY_PROMO_CODE_REQUEST",
  VERIFY_PROMO_CODE_SUCCESS: "VERIFY_PROMO_CODE_SUCCESS",
  VERIFY_PROMO_CODE_FAILURE: "VERIFY_PROMO_CODE_FAILURE",

  APPLY_PROMO_CODE_REQUEST: "APPLY_PROMO_CODE_REQUEST",
  APPLY_PROMO_CODE_SUCCESS: "APPLY_PROMO_CODE_SUCCESS",
  APPLY_PROMO_CODE_FAILURE: "APPLY_PROMO_CODE_FAILURE",

  REMOVE_PROMO_CODE_REQUEST: "REMOVE_PROMO_CODE_REQUEST",
  REMOVE_PROMO_CODE_SUCCESS: "REMOVE_PROMO_CODE_SUCCESS",
  REMOVE_PROMO_CODE_FAILURE: "REMOVE_PROMO_CODE_FAILURE",

  GET_USER_ORDERS_REQUEST: "GET_USER_ORDERS_REQUEST",
  GET_USER_ORDERS_SUCCESS: "GET_USER_ORDERS_SUCCESS",
  GET_USER_ORDERS_FAILURE: "GET_USER_ORDERS_FAILURE",

  POST_ORDER_REQUEST: "POST_ORDER_REQUEST",
  POST_ORDER_SUCCESS: "POST_ORDER_SUCCESS",
  POST_ORDER_FAILURE: "POST_ORDER_FAILURE",

  RESET_CART: "RESET_CART",

  RESET_ORDER_STATUS: "RESET_ORDER_STATUS",

  GET_BRANDS_REQUEST: "GET_BRANDS_REQUEST",
  GET_BRANDS_SUCCESS: "GET_BRANDS_SUCCESS",
  GET_BRANDS_FAILURE: "GET_BRANDS_FAILURE",
  SET_NAV_SHOW: "SET_NAV_SHOW",
  SET_NAV_LIST: "SET_NAV_LIST",
  SET_NAV_COLORS: "SET_NAV_COLORS",
  SET_CURRENT_NAV: "SET_CURRENT_NAV",

  SET_FILTER_CONDITION: "SET_FILTER_CONDITION",

  GET_BRAND_BY_ID_REQUEST: "GET_BRAND_BY_ID_REQUEST",
  GET_BRAND_BY_ID_SUCCESS: "GET_BRAND_BY_ID_SUCCESS",
  GET_BRAND_BY_ID_FAILURE: "GET_BRAND_BY_ID_FAILURE",

  GET_BRANDS_BY_CATEGORY_REQUEST: "GET_BRANDS_BY_CATEGORY_REQUEST",
  GET_BRANDS_BY_CATEGORY_SUCCESS: "GET_BRANDS_BY_CATEGORY_SUCCESS",
  GET_BRANDS_BY_CATEGORY_FAILURE: "GET_BRANDS_BY_CATEGORY_FAILURE",

  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",

  GET_SUBSIZES_REQUEST: "GET_SUBSIZES_REQUEST",
  GET_SUBSIZES_SUCCESS: "GET_SUBSIZES_SUCCESS",
  GET_SUBSIZES_FAILURE: "GET_SUBSIZES_FAILURE",

  GET_CATEGORY_BY_ID_REQUEST: "GET_CATEGORY_BY_ID_REQUEST",
  GET_CATEGORY_BY_ID_SUCCESS: "GET_CATEGORY_BY_ID_SUCCESS",
  GET_CATEGORY_BY_ID_FAILURE: "GET_CATEGORY_BY_ID_FAILURE",

  GET_RECENTLY_ADDED_PRODUCTS: "GET_RECENTLY_ADDED_PRODUCTS",
  GET_RECENTLY_ADDED_PRODUCTS_SUCCESS: "GET_RECENTLY_ADDED_PRODUCTS_SUCCESS",
  GET_RECENTLY_ADDED_PRODUCTS_FAILURE: "GET_RECENTLY_ADDED_PRODUCTS_FAILURE",

  GET_ALL_RECENTLY_SOLD: "GET_ALL_RECENTLY_SOLD",
  GET_ALL_RECENTLY_SOLD_SUCCESS: "GET_ALL_RECENTLY_SOLD_SUCCESS",
  GET_ALL_RECENTLY_SOLD_FAILURE: "GET_ALL_RECENTLY_SOLD_FAILURE",

  GET_RECENTLY_SOLD_PRODUCTS: "GET_RECENTLY_SOLD_PRODUCTS",
  GET_RECENTLY_SOLD_PRODUCTS_SUCCESS: "GET_RECENTLY_SOLD_PRODUCTS_SUCCESS",
  GET_RECENTLY_SOLD_PRODUCTS_FAILURE: "GET_RECENTLY_SOLD_PRODUCTS_FAILURE",

  //categories constants START

  GET_CRIBS_PRODUCTS_REQUEST: "GET_CRIBS_PRODUCTS_REQUEST",
  GET_STROLLERS_PRODUCTS_REQUEST: "GET_STROLLERS_PRODUCTS_REQUEST",
  GET_GEAR_PRODUCTS_REQUEST: "GET_GEAR_PRODUCTS_REQUEST",
  GET_CLOTHING_PRODUCTS_REQUEST: "GET_CLOTHING_PRODUCTS_REQUEST",
  GET_SHOES_PRODUCTS_REQUEST: "GET_SHOES_PRODUCTS_REQUEST",
  GET_TOYS_PRODUCTS_REQUEST: "GET_TOYS_PRODUCTS_REQUEST",
  GET_FEEDING_PRODUCTS_REQUEST: "GET_FEEDING_PRODUCTS_REQUEST",
  GET_DIAPERING_PRODUCTS_REQUEST: "GET_SHOES_PRODUCTS_REQUEST",
  GET_BATHING_PRODUCTS_REQUEST: "GET_BATHING_PRODUCTS_REQUEST",
  GET_SPORTS_PRODUCTS_REQUEST: "GET_SPORTS_PRODUCTS_REQUEST",
  GET_BOOKS_PRODUCTS_REQUEST: "GET_SHOES_PRODUCTS_REQUEST",
  GET_SAFETY_PRODUCTS_REQUEST: "GET_SAFETY_PRODUCTS_REQUEST",
  GET_MATERNITY_PRODUCTS_REQUEST: "GET_MATERNITY_PRODUCTS_REQUEST",
  GET_ELECTRONICS_PRODUCTS_REQUEST: "GET_ELECTRONICS_PRODUCTS_REQUEST",
  GET_VERIFIED_PRODUCTS_REQUEST: "GET_VERIFIED_PRODUCTS_REQUEST",

  GET_CRIBS_SUCCESS: "GET_CRIBS_SUCCESS",
  GET_STROLLERS_SUCCESS: "GET_STROLLERS_SUCCESS",
  GET_GEAR_SUCCESS: "GET_GEAR_SUCCESS",
  GET_CLOTHING_SUCCESS: "GET_CLOTHING_SUCCESS",
  GET_SHOES_SUCCESS: "GET_SHOES_SUCCESS",
  GET_TOYS_SUCCESS: "GET_TOYS_SUCCESS",
  GET_FEEDING_SUCCESS: "GET_FEEDING_SUCCESS",
  GET_DIAPERING_SUCCESS: "GET_DIAPERING_SUCCESS",
  GET_BATHING_SUCCESS: "GET_BATHING_SUCCESS",
  GET_SPORTS_SUCCESS: "GET_SPORTS_SUCCESS",
  GET_BOOKS_SUCCESS: "GET_BOOKS_SUCCESS",
  GET_SAFETY_SUCCESS: "GET_SAFETY_SUCCESS",
  GET_MATERNITY_SUCCESS: "GET_MATERNITY_SUCCESS",
  GET_ELECTRONICS_SUCCESS: "GET_ELECTRONICS_SUCCESS",
  GET_VERIFIED_SUCCESS: "GET_VERIFIED_SUCCESS",

  GET_CATEGORIES_PRODUCTS_FAILURE: "GET_CATEGORIES_PRODUCTS_FAILURE",

  //categories constants end

  GET_COMMUNITIES_REQUEST: "GET_COMMUNITIES_REQUEST",
  GET_COMMUNITIES_SUCCESS: "GET_COMMUNITIES_SUCCESS",
  GET_COMMUNITIES_FAILURE: "GET_COMMUNITIES_FAILURE",

  GET_CATEGORIES_GROUP_REQUEST: "GET_CATEGORIES_GROUP_REQUEST",
  GET_CATEGORIES_GROUP_SUCCESS: "GET_CATEGORIES_GROUP_SUCCESS",
  GET_CATEGORIES_GROUP_FAILURE: "GET_CATEGORIES_GROUP_FAILURE",

  GET_FEATURED_SELLER_GROUP_REQUEST: "GET_FEATURED_SELLER_GROUP_REQUEST",
  GET_FEATURED_SELLER_GROUP_SUCCESS: "GET_FEATURED_SELLER_GROUP_SUCCESS",
  GET_FEATURED_SELLER_GROUP_FAILURE: "GET_FEATURED_SELLER_GROUP_FAILURE",

  GET_CURATED_BY_MARKID_GROUP_REQUEST: "GET_CURATED_BY_MARKID_GROUP_REQUEST",
  GET_CURATED_BY_MARKID_GROUP_SUCCESS: "GET_CURATED_BY_MARKID_GROUP_SUCCESS",
  GET_CURATED_BY_MARKID_GROUP_FAILURE: "GET_CURATED_BY_MARKID_GROUP_FAILURE",

  GET_EXPLORE_TOP_DEALS_GROUP_REQUEST: "GET_EXPLORE_TOP_DEALS_GROUP_REQUEST",
  GET_EXPLORE_TOP_DEALS_GROUP_SUCCESS: "GET_EXPLORE_TOP_DEALS_GROUP_SUCCESS",
  GET_EXPLORE_TOP_DEALS_GROUP_FAILURE: "GET_EXPLORE_TOP_DEALS_GROUP_FAILURE",

  GET_BRANDS_GROUP_REQUEST: "GET_BRANDS_GROUP_REQUEST",
  GET_BRANDS_GROUP_SUCCESS: "GET_BRANDS_GROUP_SUCCESS",
  GET_BRANDS_GROUP_FAILURE: "GET_BRANDS_GROUP_FAILURE",

  GET_STYLES_GROUP_REQUEST: "GET_STYLES_GROUP_REQUEST",
  GET_STYLES_GROUP_SUCCESS: "GET_STYLES_GROUP_SUCCESS",
  GET_STYLES_GROUP_FAILURE: "GET_STYLES_GROUP_FAILURE",

  GET_COLLECTIONS_GROUP_REQUEST: "GET_COLLECTIONS_GROUP_REQUEST",
  GET_COLLECTIONS_GROUP_SUCCESS: "GET_COLLECTIONS_GROUP_SUCCESS",
  GET_COLLECTIONS_GROUP_FAILURE: "GET_COLLECTIONS_GROUP_FAILURE",

  GET_COMMUNITY_GROUP_REQUEST: "GET_COMMUNITY_GROUP_REQUEST",
  GET_COMMUNITY_GROUP_SUCCESS: "GET_COMMUNITY_GROUP_SUCCESS",
  GET_COMMUNITY_GROUP_FAILURE: "GET_COMMUNITY_GROUP_FAILURE",

  GET_TESTIMONIALS_GROUP_REQUEST: "GET_TESTIMONIALS_GROUP_REQUEST",
  GET_TESTIMONIALS_GROUP_SUCCESS: "GET_TESTIMONIALS_GROUP_SUCCESS",
  GET_TESTIMONIALS_GROUP_FAILURE: "GET_TESTIMONIALS_GROUP_FAILURE",

  GET_AGE_GROUPS_REQUEST: "GET_AGE_GROUPS_REQUEST",
  GET_AGE_GROUPS_SUCCESS: "GET_AGE_GROUPS_SUCCESS",
  GET_AGE_GROUPS_FAILURE: "GET_AGE_GROUPS_FAILURE",

  POST_ESTIMATED_CHARGES_REQUEST: "POST_ESTIMATED_CHARGES_REQUEST",
  POST_ESTIMATED_CHARGES_SUCCESS: "POST_ESTIMATED_CHARGES_SUCCESS",
  POST_ESTIMATED_CHARGES_FAILURE: "POST_ESTIMATED_CHARGES_FAILURE",

  PATCH_RECENT_SEARCHES_REQUEST: "PATCH_RECENT_SEARCHES_REQUEST",
  PATCH_RECENT_SEARCHES_SUCCESS: "PATCH_RECENT_SEARCHES_SUCCESS",
  PATCH_RECENT_SEARCHES_FAILURE: "PATCH_RECENT_SEARCHES_FAILURE",

  PATCH_POPULAR_SEARCHES_REQUEST: "PATCH_POPULAR_SEARCHES_REQUEST",
  PATCH_POPULAR_SEARCHES_SUCCESS: "PATCH_POPULAR_SEARCHES_SUCCESS",
  PATCH_POPULAR_SEARCHES_FAILURE: "PATCH_POPULAR_SEARCHES_FAILURE",

  GET_CHATS_BY_LOT_ID_REQUEST: "GET_CHATS_BY_LOT_ID_REQUEST",
  GET_CHATS_BY_LOT_ID_SUCCESS: "GET_CHATS_BY_LOT_ID_SUCCESS",
  GET_CHATS_BY_LOT_ID_FAILURE: "GET_CHATS_BY_LOT_ID_FAILURE",

  GET_CHATS_REQUEST: "GET_CHATS_REQUEST",
  GET_CHATS_SUCCESS: "GET_CHATS_SUCCESS",
  GET_CHATS_FAILURE: "GET_CHATS_FAILURE",

  GET_CHAT_BY_ID_REQUEST: "GET_CHAT_BY_ID_REQUEST",
  GET_CHAT_BY_ID_SUCCESS: "GET_CHAT_BY_ID_SUCCESS",
  GET_CHAT_BY_ID_FAILURE: "GET_CHAT_BY_ID_FAILURE",

  GET_MESSAGES_BY_CHAT_ID_REQUEST: "GET_MESSAGES_BY_CHAT_ID_REQUEST",
  GET_MESSAGES_BY_CHAT_ID_SUCCESS: "GET_MESSAGES_BY_CHAT_ID_SUCCESS",
  GET_MESSAGES_BY_CHAT_ID_FAILURE: "GET_MESSAGES_BY_CHAT_ID_FAILURE",

  GET_CHATS_WITH_SELLER_REQUEST: "GET_CHATS_WITH_SELLER_REQUEST",
  GET_CHATS_WITH_SELLER_SUCCESS: "GET_CHATS_WITH_SELLER_SUCCESS",
  GET_CHATS_WITH_SELLER_FAILURE: "GET_CHATS_WITH_SELLER_FAILURE",

  DELETE_CHAT_BY_ID_REQUEST: "DELETE_CHAT_BY_ID_REQUEST",
  DELETE_CHAT_BY_ID_SUCCESS: "DELETE_CHAT_BY_ID_SUCCESS",
  DELETE_CHAT_BY_ID_FAILURE: "DELETE_CHAT_BY_ID_FAILURE",

  GET_OFFERS_REQUEST: "GET_OFFERS_REQUEST",
  GET_OFFERS_SUCCESS: "GET_OFFERS_SUCCESS",
  GET_OFFERS_FAILURE: "GET_OFFERS_FAILURE",

  POST_OFFER_REQUEST: "POST_OFFER_REQUEST",
  POST_OFFER_SUCCESS: "POST_OFFER_SUCCESS",
  POST_OFFER_FAILURE: "POST_OFFER_FAILURE",

  UPDATE_OFFER_REQUEST: "UPDATE_OFFER_REQUEST",
  UPDATE_OFFER_SUCCESS: "UPDATE_OFFER_SUCCESS",
  UPDATE_OFFER_FAILURE: "UPDATE_OFFER_FAILURE",

  GET_OFFER_DECLINE_REASONS_REQUEST: "GET_OFFER_DECLINE_REASONS_REQUEST",
  GET_OFFER_DECLINE_REASONS_SUCCESS: "GET_OFFER_DECLINE_REASONS_SUCCESS",
  GET_OFFER_DECLINE_REASONS_FAILURE: "GET_OFFER_DECLINE_REASONS_FAILURE",

  OFFER_CANCEL_BY_BUYER_REQUEST: "OFFER_CANCEL_BY_BUYER_REQUEST",
  OFFER_CANCEL_BY_BUYER_SUCCESS: "OFFER_CANCEL_BY_BUYER_SUCCESS",
  OFFER_CANCEL_BY_BUYER_FAILURE: "OFFER_CANCEL_BY_BUYER_FAILURE",

  OFFER_CANCEL_BY_SELLER_REQUEST: "OFFER_CANCEL_BY_SELLER_REQUEST",
  OFFER_CANCEL_BY_SELLER_SUCCESS: "OFFER_CANCEL_BY_SELLER_SUCCESS",
  OFFER_CANCEL_BY_SELLER_FAILURE: "OFFER_CANCEL_BY_SELLER_FAILURE",

  ACCEPT_OFFER_REQUEST: "ACCEPT_OFFER_REQUEST",
  ACCEPT_OFFER_SUCCESS: "ACCEPT_OFFER_SUCCESS",
  ACCEPT_OFFER_FAILURE: "ACCEPT_OFFER_FAILURE",

  OFFER_LOT_SOLD_REQUEST: "OFFER_LOT_SOLD_REQUEST",
  OFFER_LOT_SOLD_SUCCESS: "OFFER_LOT_SOLD_SUCCESS",
  OFFER_LOT_SOLD_FAILURE: "OFFER_LOT_SOLD_FAILURE",

  LOT_DELIVERED_REQUEST: "LOT_DELIVERED_REQUEST",
  LOT_DELIVERED_SUCCESS: "LOT_DELIVERED_SUCCESS",
  LOT_DELIVERED_FAILURE: "LOT_DELIVERED_FAILURE",

  OFFER_APPLY_PIN_REQUEST: "OFFER_APPLY_PIN_REQUEST",
  OFFER_APPLY_PIN_SUCCESS: "OFFER_APPLY_PIN_SUCCESS",
  OFFER_APPLY_PIN_FAILURE: "OFFER_APPLY_PIN_FAILURE",

  OFFER_DISPUTE_REQUEST: "OFFER_DISPUTE_REQUEST",
  OFFER_DISPUTE_SUCCESS: "OFFER_DISPUTE_SUCCESS",
  OFFER_DISPUTE_FAILURE: "OFFER_DISPUTE_FAILURE",

  MADE_OFFER_DATA: "MADE_OFFER_DATA",

  OFFER_PAYMENT_STATRTED: "OFFER_PAYMENT_STATRTED",

  POST_MESSAGE_BY_CHAT_ID_REQUEST: "POST_MESSAGE_BY_CHAT_ID_REQUEST",
  POST_MESSAGE_BY_CHAT_ID_SUCCESS: "POST_MESSAGE_BY_CHAT_ID_SUCCESS",
  POST_MESSAGE_BY_CHAT_ID_FAILURE: "POST_MESSAGE_BY_CHAT_ID_FAILURE",

  POST_MARK_AS_SEEN_BY_CHAT_ID_REQUEST: "POST_MARK_AS_SEEN_BY_CHAT_ID_REQUEST",
  POST_MARK_AS_SEEN_BY_CHAT_ID_SUCCESS: "POST_MARK_AS_SEEN_BY_CHAT_ID_SUCCESS",
  POST_MARK_AS_SEEN_BY_CHAT_ID_FAILURE: "POST_MARK_AS_SEEN_BY_CHAT_ID_FAILURE",

  POST_MARK_AS_RATED_BY_CHAT_ID_REQUEST:
    "POST_MARK_AS_RATED_BY_CHAT_ID_REQUEST",
  POST_MARK_AS_RATED_BY_CHAT_ID_SUCCESS:
    "POST_MARK_AS_RATED_BY_CHAT_ID_SUCCESS",
  POST_MARK_AS_RATED_BY_CHAT_ID_FAILURE:
    "POST_MARK_AS_RATED_BY_CHAT_ID_FAILURE",

  GET_PUBLISHED_HANDPICKED_REQUEST: "GET_PUBLISHED_HANDPICKED_REQUEST",
  GET_PUBLISHED_HANDPICKED_SUCCESS: "GET_PUBLISHED_HANDPICKED_SUCCESS",
  GET_PUBLISHED_HANDPICKED_FAILURE: "GET_PUBLISHED_HANDPICKED_FAILURE",

  GET_HANDPICKED_ITEM_REQUEST: "GET_HANDPICKED_ITEM_REQUEST",
  GET_HANDPICKED_ITEM_SUCCESS: "GET_HANDPICKED_ITEM_SUCCESS",
  GET_HANDPICKED_ITEM_FAILURE: "GET_HANDPICKED_ITEM_FAILURE",

  SET_CURRENT_LOCATION_ZIP: "SET_CURRENT_LOCATION_ZIP",

  GET_TAB_KEY: "GET_TAB_KEY",

  GET_MARKID_PRODUCT_BY_ID_REQUEST: "GET_MARKID_PRODUCT_BY_ID_REQUEST",
  GET_MARKID_PRODUCT_BY_ID_SUCCESS: "GET_MARKID_PRODUCT_BY_ID_SUCCESS",
  GET_MARKID_PRODUCT_BY_ID_FAILURE: "GET_MARKID_PRODUCT_BY_ID_FAILURE",

  GET_PARTNER_PRODUCT_BY_ID_REQUEST: "GET_PARTNER_PRODUCT_BY_ID_REQUEST",
  GET_PARTNER_PRODUCT_BY_ID_SUCCESS: "GET_PARTNER_PRODUCT_BY_ID_SUCCESS",
  GET_PARTNER_PRODUCT_BY_ID_FAILURE: "GET_PARTNER_PRODUCT_BY_ID_FAILURE",

  GET_PARTNER_BY_ID_REQUEST: "GET_PARTNER_BY_ID_REQUEST",
  GET_PARTNER_BY_ID_SUCCESS: "GET_PARTNER_BY_ID_SUCCESS",
  GET_PARTNER_BY_ID_FAILURE: "GET_PARTNER_BY_ID_FAILURE",

  MORE_FROM_THIS_PARTNER_REQUEST: "MORE_FROM_THIS_PARTNER_REQUEST",
  MORE_FROM_THIS_PARTNER_SUCCESS: "MORE_FROM_THIS_PARTNER_SUCCESS",
  MORE_FROM_THIS_PARTNER_FAILURE: "MORE_FROM_THIS_PARTNER_FAILURE",

  PARTNER_PRODUCT_SHIIPING_CHARGE_REQUEST:
    "PARTNER_PRODUCT_SHIIPING_CHARGE_REQUEST",
  PARTNER_PRODUCT_SHIIPING_CHARGE_SUCCESS:
    "PARTNER_PRODUCT_SHIIPING_CHARGE_SUCCESS",
  PARTNER_PRODUCT_SHIIPING_CHARGE_FAILURE:
    "PARTNER_PRODUCT_SHIIPING_CHARGE_FAILURE",

  CREATE_PARTNER_ORDER_REQUEST: "CREATE_PARTNER_ORDER_REQUEST",
  CREATE_PARTNER_ORDER_SUCCESS: "CREATE_PARTNER_ORDER_SUCCESS",
  CREATE_PARTNER_ORDER_FAILURE: "CREATE_PARTNER_ORDER_FAILURE",

  PRODUCT_TYPE_REQUEST: "PRODUCT_TYPE_REQUEST",
  PRODUCT_TYPE_SUCCESS: "PRODUCT_TYPE_SUCCESS",
  PRODUCT_TYPE_FAILURE: "PRODUCT_TYPE_FAILURE",

  GET_FILTER_TO_DISPLAY_REQUEST: "GET_FILTER_TO_DISPLAY_REQUEST",
  GET_FILTER_TO_DISPLAY_SUCCESS: "GET_FILTER_TO_DISPLAY_SUCCESS",
  GET_FILTER_TO_DISPLAY_FAILURE: "GET_FILTER_TO_DISPLAY_FAILURE",

  GET_SIZE_FILTER_REQUEST: "GET_SIZE_FILTER_REQUEST",
  GET_SIZE_FILTER_SUCCESS: "GET_SIZE_FILTER_SUCCESS",
  GET_SIZE_FILTER_FAILURE: "GET_SIZE_FILTER_FAILURE",

  GET_AGE_FILTER_REQUEST: "GET_AGE_FILTER_REQUEST",
  GET_AGE_FILTER_SUCCESS: "GET_AGE_FILTER_SUCCESS",
  GET_AGE_FILTER_FAILURE: "GET_AGE_FILTER_FAILURE",

  GET_BRANDLIST_REQUEST: "GET_BRANDLIST_REQUEST",
  GET_BRANDLIST_SUCCESS: "GET_BRANDLIST_SUCCESS",
  GET_BRANDLIST_FAILURE: "GET_BRANDLIST_FAILURE",

  AVAIL_PROMOTIONS_CHECK_REQUEST: "AVAIL_PROMOTIONS_CHECK_REQUEST",
  AVAIL_PROMOTIONS_CHECK_SUCCESS: "AVAIL_PROMOTIONS_CHECK_SUCCESS",
  AVAIL_PROMOTIONS_CHECK_FAILURE: "AVAIL_PROMOTIONS_CHECK_FAILURE",

  PARTNER_DISCOUNT_AMOUNT: "PARTNER_DISCOUNT_AMOUNT",
  P2P_DISCOUNT_AMOUNT: "P2P_DISCOUNT_AMOUNT",
  FINAL_SHIPPINGPRICE:"FINAL_SHIPPINGPRICE",
  FIRST_PURCHASE:"FIRST_PURCHASE",
  CURRENT_PLP_ITEM: "CURRENT_PLP_ITEM",
};
