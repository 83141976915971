import React, { useState } from "react";
import { Card, Typography, Row, Col, Switch, Dropdown, Menu,Divider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import closeBtn from "../assets/new-assets/close-icon.png";
import { useGaEvent } from "../hooks/useGaEvent";
import "../App.css";
import renewedRoatate from "../assets/renewedRoatate.svg";
import { PLBreadCrumb } from "./partnerBreadCrumb";
import partnerSearch from "./partnerSearch";
import ProductTop from "./searchpartner";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useBreakpoints } from "./../react-responsive-hooks/index";
const { Text } = Typography;

export const PFiltered = (props) => {
  const {
    category,
    itemType,
    brand,
    selectedAge,
    selectedSize,
    updateSelectedAge,
    updateSelectedBrand,
    changesSortByLots,
    filtertags,
    renewed,
    handleRenewedChange,
    handPickedName,
    search,
    zipCode,
    setSelectedAge,
    setSelectedBrand,
    setSelectedpricevalues,
    selectedCondition,
    setSelectedSize,
    selectedpricevalues,
    selectedshippingarr,
    updateSelectedSize,
    selectedBrand,
    selectedGender,
    updateSelectedGender,
    setSelectedPurchaseYear,
    setSelectedGender,
    setSelectedCondition,
    updateSelectedCondition,
    selectedPurchaseYear,
    updateSelectedPurchaseYear,
    updateSelectedShipping,
    setshowsortfilterDialog,
    showsortfilterDialog
  } = props;
  const { brand: brandName } = brand || "";
  const { gaPageView, gaEventClick } = useGaEvent();
  const [switchVal, setSwitchVal] = useState(
    renewed !== null && renewed !== undefined ? renewed : false
  );
  const [sortByFeatured, setSortByFeatured] = useState(["1"]);
  const [sortByFeaturedValue, setSortByFeaturedValue] = useState(["Relevance"]);
  function onChange(checked) {
    setSwitchVal(checked);
    handleRenewedChange(!switchVal);
  }
  const sortingByLots = (sortingVal, title) => {
    setSortByFeaturedValue(title);
    changesSortByLots(sortingVal);
  };
  const removefiltertags = (val) => {
    if (selectedBrand?.includes(val)) {
      const newPeople = selectedBrand.filter((person) => person !== val);
      setSelectedBrand(newPeople);
      updateSelectedBrand(val);
    }
    if (selectedGender?.includes(val)) {
      const newPeople = selectedGender.filter((person) => person !== val);
      setSelectedGender(newPeople);
      updateSelectedGender(val);
    }
    if (selectedPurchaseYear.includes(val)) {
      const newPeople = selectedGender.filter((person) => person !== val);
      setSelectedPurchaseYear(newPeople);
      updateSelectedPurchaseYear(val);
    }
    if (selectedCondition?.includes(val)) {
      const newPeople = selectedCondition.filter((person) => person !== val);
      setSelectedCondition(newPeople);
      updateSelectedCondition(val);
    }
    // if (selectedColor?.includes(val)) {
    //   const newPeople = selectedColor.filter((person) => person !== val);
    //   setselectedColor(newPeople);
    //   updateSelectedColor(val);
    // }

    if (selectedshippingarr?.includes(val)) {
      // const newPeople = selectedshippingarr.filter((person) => person !== val);
      // setSelectedshippingarr(newPeople);
      const newState = { shipping: val, value: false };
      updateSelectedShipping(newState, 0);
    }
    if (selectedAge?.includes(val)) {
      const newPeople = selectedAge.filter((person) => person !== val);
      setSelectedAge(newPeople);
      updateSelectedAge(val);
    }
    if (selectedSize?.includes(val)) {
      const newPeople = selectedSize.filter((person) => person !== val);
      setSelectedSize(newPeople);
      updateSelectedSize(val);
    }
    if (selectedpricevalues?.includes(val)) {
      if (val === "$0 to $10") {
        const newPeople = selectedpricevalues.filter(
          (person) => person !== val
        );
        setSelectedpricevalues(newPeople);
      }
      if (val === "$10 to $50") {
        const newPeople = selectedpricevalues.filter(
          (person) => person !== val
        );
        setSelectedpricevalues(newPeople);
      }
      if (val === "$50 to $100") {
        const newPeople = selectedpricevalues.filter(
          (person) => person !== val
        );
        setSelectedpricevalues(newPeople);
      }
    }
  };
  const handleCancel = () => {
    setshowsortfilterDialog(false);
  };
  // const deliveryMenu = (
  //   <Menu>
  //     <Menu.Item></Menu.Item>
  //   </Menu>
  // );
  // const featuredMenu = (
  // <Menu onClick={(e) => setSortByFeatured(console.log(e.key))} selectedKeys={[sortByFeatured]}>
  //     <Menu.Item onClick={() => sortingByLots('priceAsc')} key='1'>
  //       Price low to high
  //     </Menu.Item>
  //     <Menu.Item onClick={() => sortingByLots('priceDesc')} key='2'>
  //       Price high to low
  //     </Menu.Item>
  //     <Menu.Item onClick={() => sortingByLots('createdAtDesc')} key='3'>
  //       Date of Listing
  //     </Menu.Item>
  //   </Menu>
  // )
  const { isXS, isSM } = useBreakpoints();
  const handleclick = (e) => {
    setSortByFeaturedValue(e.target.value[1]);
    changesSortByLots(e.target.value);
  };
  const optionsList = [
    { label: "Relevance", name: "", keyName: "Relevance" },
    {
      label: "Price high to low",
      name: "priceDesc",
      keyName: "Price high to low",
    },
    {
      label: "Price low to high",
      name: "priceAsc",
      keyName: "Price low to high",
    },
  ];
  let getTagsList = () => {
    if (filtertags?.length > 0) {
      return filtertags.map((category) => {
        return (
          <Col className="tag">
            {category}{" "}
            <span
              className="removeTag"
              onClick={() => removefiltertags(category)}
            >
              x
            </span>
          </Col>
        );
      });
    }
  };
  return (
    <div className="pFilteredParent">
      <div className='app-wrapper'>
      <Row className='app-breadcrumb' gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }]}>
        {/* <Col span={24}></Col> */}
        <Col span={2}></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="pFcard">
            <Row className="partners_filter" justify="space-between" align="middle" >

              {/* <Col sm={10} xl={8}>
                <div className="pBreadCrumbWeb">
                  <PLBreadCrumb markid="Home" category={(itemType === 'category' && category.displayValue) || (itemType === 'brand' && brandName) || (itemType === 'handpicked' && handPickedName)} />
                  <div className="staticBreadcrumb">
                    <ul>
                      <li>Home</li>
                      <li className="active">Partner Profile</li>
                    </ul>
                  </div>
                </div>

              </Col> */}
              <ProductTop />

              <div className="pFItemMob">
                  <div>
                    <BottomSheet open={showsortfilterDialog}>
                      <Col onClick={handleCancel}>
                        <img className="ShareModalimgClose" src={closeBtn} />
                      </Col>
                      <Row justify="space-between" className="pFilterHeader">
                        <Col>
                          <Typography className="pFilterTitle">
                            Sort by
                          </Typography>
                        </Col>
                      </Row>
                      <Divider
                        style={{
                          borderTop: "1px solid #D4D4D4",
                          marginTop: 12,
                          marginBottom: 1,
                        }}
                      />
                      <Col onClick={() => {sortingByLots("", "Relevance");setshowsortfilterDialog(false);gaEventClick("Sort by","Relevance")}}>
                        <Typography className="pFSortByTitle">
                          Relevance
                        </Typography>
                      </Col>
                      <Col
                        onClick={() =>
                          {sortingByLots("priceDesc", "Price high to low");setshowsortfilterDialog(false);gaEventClick("Sort by","Price high to low")}
                        }
                      >
                        <Typography className="pFSortByTitle">
                          Price: High to low
                        </Typography>
                      </Col>
                      <Col
                        onClick={() =>
                          {sortingByLots("priceAsc", " Price low to high");setshowsortfilterDialog(false);gaEventClick("Sort by","Price low to high")}
                        }
                      >
                        <Typography className="pFSortByTitle">
                          Price: Low to High
                        </Typography>
                      </Col>
                      {/* <Col
                        onClick={() =>
                          sortingByLots("createdAtDesc", " Date of Listing")
                        }
                      >
                        <Typography className="pFSortByTitle">
                          Date Of listing
                        </Typography>
                      </Col> */}
                    </BottomSheet>
                  </div>
                </div>
              <div className="pFItemWeb">
                <Col span={24}>
                  <Row gutter={[0, 0]}>
                    <Col>
                      <Row gutter={[66, 0]}>
                        <Col>
                          <Row gutter={[14, 0]}>
                            <Col>
                              <Row gutter={[5, 0]}>
                                {/* <Col>
                                  <img alt="" src={renewedRoatate} />
                                </Col> */}
                                {/* <Col>
                                  <Typography className="pFReNewedText">reNEWED</Typography>
                                </Col> */}
                              </Row>
                            </Col>
                            {/* <Col>
                              <Switch onChange={onChange} className={switchVal ? 'pSwitch' : 'pSwitchOff'} checked={switchVal} />
                            </Col> */}
                          </Row>
                        </Col>
                        {/* <Col>
                          <Dropdown
                            overlay={deliveryMenu}
                            // visible={visibleInput}
                            placement="bottomCenter"
                            arrow
                          >
                            <Text type="link" className="pFDelivery">
                              Delivery{" "}
                              <DownOutlined
                                style={{ marginLeft: "5px", color: "#393939" }}
                              />
                            </Text>
                          </Dropdown>
                        </Col> */}
                        <Col>
                          <Row gutter={[9, 0]}>
                            <Col>
                              <Typography className="pFSort">
                                Sort by
                              </Typography>
                            </Col>
                            <Col>
                              <Dropdown
                                overlay={
                                  <Menu
                                    onClick={(e) => null}
                                    /* 20211124 Varun: Commneted until sort functionality is added to lots-searchable api */
                                    // setSortByFeatured(
                                    //     e.key
                                    // )

                                    selectedKeys={[sortByFeatured]}
                                  >
                                    <Menu.Item
                                      onClick={() =>{
                                        sortingByLots("", "Relevance");gaEventClick("Sort by","Relevance")
                                      }}
                                      key="0"
                                    >
                                      Relevance
                                    </Menu.Item>
                                    <Menu.Item
                                      onClick={() =>{
                                        sortingByLots(
                                          "priceAsc",
                                          " Price low to high"
                                        );
                                        gaEventClick("Sort by","Price low to high")
                                      }}
                                      key="1"
                                      // disabled={sortByFeatured === sortByFeaturedValue ? true : false}
                                    >
                                      Price low to high
                                    </Menu.Item>
                                    <Menu.Item
                                      onClick={() =>{
                                        sortingByLots(
                                          "priceDesc",
                                          "Price high to low"
                                        )
                                        ; gaEventClick("Sort by","Price high to low")
                                      }}
                                      key="2"
                                    >
                                      Price high to low
                                    </Menu.Item>
                                    {/* <Menu.Item onClick={() => sortingByLots('createdAtDesc', ' Date of Listing')} key="3">
                                      Date of Listing
                                    </Menu.Item> */}
                                  </Menu>
                                }
                                // visible={visibleInput}
                                placement="bottomCenter"
                                arrow
                              >
                                <Text
                                  type="link"
                                  // className="pFDelivery"
                                  className="pFDeliveryVal"
                                >
                                  {sortByFeaturedValue}{" "}
                                  <DownOutlined
                                    style={{
                                      marginLeft: "5px",
                                      fontSize: 12,
                                      color: "#393939",
                                      fontWeight: "bolder",
                                    }}
                                  />
                                </Text>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col>
                    
                      <div className="pFItemWeb">
                        <Button className="saveSearchButton">
                          Save this search
                          <HeartOutlined />
                        </Button>
                      </div>
                    </Col> */}
                  </Row>

                  {/* <div className='pFItemMob'>
            <Row justify='space-between' gutter={[0,15]}>
            <Col span={24}></Col>
            <Col>
            <Button className='saveSearchButton'>
            Save this search
            <HeartOutlined />
            </Button>
            </Col>
            </Row>
          </div> */}
                </Col>
              </div>
            </Row>
          </Card>
        </Col>
        {/* {!isXS && <Row className="fiterTags">{getTagsList()}</Row>} */}
      </Row>
      </div>
    </div>
  );
};
