/*eslint-disable*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Button } from "antd";
import _ from 'lodash';
import "../App.css";

const TestComponent = ({
  categoryImage,
  categoryName,
  categoryVal,
  categoryId,
}) => {
  const history = useHistory();
  const handleCategoryVal = () => {
    history.push(`/product-listing/category/${categoryVal}/${categoryId}`);
  };
  return (
    <Row justify="center">
      <Col onClick={() => handleCategoryVal()} style={{ cursor: "pointer" }}>
        <Row justify="center" gutter={[0, { xs: 20, sm: 15, md: 18, lg: 23, xl: 23, xxl: 23 }]}>
          <img src={categoryImage} alt="" className="categoryImg" />
          {/* <Col span={24}></Col> */}
        </Row>
        <Row justify='center' gutter={[0, 23]}>
          <Col span={24}></Col>
          <Typography className="categoryName">{categoryName}</Typography>
        </Row>
      </Col>
    </Row>
  );
};
const MHCategorySection = (props) => {
  const { group, groupType } = props.categoriesItem;
  const { groupName } = group;
  const [seeItems, setSeeItems] = useState(6);
  const [seeAll, setSeeAll] = useState(true);
  const handleShowCategories = () => {
    setSeeAll(false);
    setSeeItems(groupType.length);
  };

  const len = Math.ceil(_.divide(24, groupType.length) * 2);
  const handleLessCategories = () => {
    setSeeAll(true);
    setSeeItems(6);
  };
  return (
    <div className="MHCategoryscreen app-wrapper">
      {/* <Row
        justify="center"
        gutter={[0, { xs: 100, sm: 100, md: 138, lg: 138, xl: 138, xxl: 138 }]}
      > */}
      <Row
        justify="center"
        gutter={[0, { xs: 70, sm: 70, md: 70, lg: 90, xl: 90, xxl: 90 }]}
      >

        <Col span={24}></Col>
        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}></Col>
        <Col>
          <Typography className="cSTitle category-title">{groupName}</Typography>
        </Col>
      </Row>
      <Row justify="center">
        <Col xl={23} lg={23} md={23} sm={22} xs={22}>
          <Row
            gutter={[
              { xs: 35, sm: 15, md: 44, lg: 44, xl: 44, xxl: 44 },
              { xs: 20, sm: 20, md: 50, lg: 50, xl: 50, xxl: 50 },
            ]}
            justify="center"
          >
            <Col span={24}></Col>
            {groupType &&
              groupType.map(
                (val, i) =>
                  i < seeItems && (
                    <Col xl={len} lg={len} md={8} sm={12} xs={12} key={i}>
                      <TestComponent
                        categoryImage={val.v2ImageUrl}
                        categoryName={val.displayValue}
                        key={i}
                        categoryVal={val.category}
                        categoryId={val._id}
                      />
                    </Col>
                  )
              )}
          </Row>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 55]}>
        <Col span={24}></Col>
        <Col>
          {seeAll ? (
            <Button
              size="large"
              className="button cSeeAllButton button-bordered"
              onClick={() => handleShowCategories()}
            >
              See all
            </Button>
          ) : (
            <Button
              size="large"
              className="button cSeeAllButton button-bordered"
              onClick={() => handleLessCategories()}
            >
              See less
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default MHCategorySection;
