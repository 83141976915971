import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Layout,
  Typography,
  Card,
  Button,
  Space,
  Empty,
  Input,
  Radio,
  Checkbox,
  Divider,
  Upload,
  message,
  Spin,
  Alert,
  Select,
  Modal,
} from "antd";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import ProductDetailsUploadInformationSection from "../components/ProductDetailsUploadInformationSection";
import { useForm, Controller } from "react-hook-form";
import Brand1 from "../assets/brand-1.png";
import Brand2 from "../assets/brand-2.png";
import Brand3 from "../assets/brand-3.png";
import Brand4 from "../assets/brand-4.png";
import Brand5 from "../assets/brand-5.png";
import Brand6 from "../assets/brand-6.png";
import { getAllCategories } from "../Actions/categoriesAction";
import EditIcon from "../assets/EditIcon.svg";
import CameraIcon from "../assets/camera.png";
import VideoIcon from "../assets/video.png";
import RemoveIcon from "../assets/RemoveIcon.svg";
import { useParams } from "react-router-dom";
import { SelectBrand } from "../components/SelectBrand";
import SelectColourSeller from "../components/SelectColourSeller";
import RoundColorComponent from "../components/RoundColorComponent";
import { getUser, uploadUserPhoto } from "../Actions/userAction";
import { getCurrentUserWallet } from "../Actions/cartAction";
import {
  booksAgeList,
  clothingSizeList,
  maternitySizeList,
  oneSizeList,
  shoesSizeList,
  toysAndCostumesAgeList,
} from "../Utils/webseller";
import { getEstimatedCharges } from "../Actions/shippingAction";
import { addAddress, removeAddress } from "../Actions/userAction";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { SellerCancelModal } from "../components/sellerCancelModal";
import { uploadProductPhoto } from "../Actions/lotsAction";
import { UpdateAddress } from "../components/CheckoutAddress";
const { TextArea } = Input;

const SellerProductDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { title } = params;
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // name: "",
      // address: "",
      // secondAddress: "",
      // city: "",
      // state: "",
      // zip: "",
      // phone: "",
    },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
  }, []);
  const {
    allCategories,
    currentUser,
    isAddAddressLoading,
    rates,
    addresserror,
    currentAddressId,
    productImageURL,
  } = useSelector((state) => {
    const { currentUser, isAddAddressLoading, addresserror } = state.user;
    const { currentAddressId } = state.cart;
    const { allCategories } = state.categories;
    const { allBrands } = state.brands;
    const { rates } = state.shipping;
    const { productImageURL } = state.lots;
    return {
      currentUser,
      isAddAddressLoading,
      addresserror,
      allCategories,
      allBrands,
      currentAddressId,
      rates,
      productImageURL,
    };
  });
  const [selectedBrand, setSelectedBrand] = useState(
    localStorage.getItem("activeBrand")
  );
  const { photo } = productImageURL || {};
  const [count, setCount] = useState(0);
  const [addressForm, setAddressForm] = useState(false);
  const [updateAddressForm, setUpdateAddressForm] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [open, setOpen] = useState(false);
  const handleAddressChange = (addressId) => {
    dispatch({ type: "SELECT_ADDRESS_ID", payload: { addressId } });

    // setAddressError(false)
  };
  const [shippingAddress, setShippingAddress] = useState("");
  const [addressModalType, setAddressModalType] = useState("");
  const handleUpdateAddressForm = (addressId) => {
    setAddressModalType("edit");
    setUpdateAddressForm(true);
    currentUser.addresses.map((deliveryAddress) => {
      if (deliveryAddress._id == addressId) {
        setShippingAddress(deliveryAddress);
      }
    });
  };
  const handleRemoveAddress = (addressId) => {
    dispatch(removeAddress(addressId));
  };
  const handleNewAddressForm = () => {
    setUpdateAddressForm(false);
    setAddressForm(true);
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [selectedState, setSelectedState] = useState("");
  const handleSelectedState = (e) => {
    setSelectedState(e);
  };
  const [selectedColors, setSelectedColors] = useState([]);
  const [cancelDialog, setcancelDialog] = useState(false);
  const handleAddAddress = (e) => {
    e.preventDefault();
    var data = {
      name: e.target[0].value || "",
      phone: e.target[1].value || "",
      address: e.target[2].value || "",
      secondAddress: e.target[3].value || "",
      city: e.target[4].value || "",
      state: selectedState || "",
      zip: e.target[6].value || "",
    };

    if (
      zipValidation === "" &&
      nameValidation === "" &&
      phoneValidation ==="" &&
      address1Validation === ""&&
      cityValidation===""&&
      stateValidation===""
    ) {
      dispatch(
        addAddress(data, successCallBackAddress, failureCallBackAddress)
      );
    }
  };
  React.useEffect(() => {
  }, [addresserror]);
  const [showcancelDialog, setShowcancelDialog] = useState(false);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const US_STATES = [
    {
      label: "Alabama",
      value: "AL",
    },
    {
      label: "Alaska",
      value: "AK",
    },
    {
      label: "American Samoa",
      value: "AS",
    },
    {
      label: "Arizona",
      value: "AZ",
    },
    {
      label: "Arkansas",
      value: "AR",
    },
    {
      label: "California",
      value: "CA",
    },
    {
      label: "Colorado",
      value: "CO",
    },
    {
      label: "Connecticut",
      value: "CT",
    },
    {
      label: "Delaware",
      value: "DE",
    },
    {
      label: "District Of Columbia",
      value: "DC",
    },
    {
      label: "Federated States Of Micronesia",
      value: "FM",
    },
    {
      label: "Florida",
      value: "FL",
    },
    {
      label: "Georgia",
      value: "GA",
    },
    {
      label: "Guam",
      value: "GU",
    },
    {
      label: "Hawaii",
      value: "HI",
    },
    {
      label: "Idaho",
      value: "ID",
    },
    {
      label: "Illinois",
      value: "IL",
    },
    {
      label: "Indiana",
      value: "IN",
    },
    {
      label: "Iowa",
      value: "IA",
    },
    {
      label: "Kansas",
      value: "KS",
    },
    {
      label: "Kentucky",
      value: "KY",
    },
    {
      label: "Louisiana",
      value: "LA",
    },
    {
      label: "Maine",
      value: "ME",
    },
    {
      label: "Marshall Islands",
      value: "MH",
    },
    {
      label: "Maryland",
      value: "MD",
    },
    {
      label: "Massachusetts",
      value: "MA",
    },
    {
      label: "Michigan",
      value: "MI",
    },
    {
      label: "Minnesota",
      value: "MN",
    },
    {
      label: "Mississippi",
      value: "MS",
    },
    {
      label: "Missouri",
      value: "MO",
    },
    {
      label: "Montana",
      value: "MT",
    },
    {
      label: "Nebraska",
      value: "NE",
    },
    {
      label: "Nevada",
      value: "NV",
    },
    {
      label: "New Hampshire",
      value: "NH",
    },
    {
      label: "New Jersey",
      value: "NJ",
    },
    {
      label: "New Mexico",
      value: "NM",
    },
    {
      label: "New York",
      value: "NY",
    },
    {
      label: "North Carolina",
      value: "NC",
    },
    {
      label: "North Dakota",
      value: "ND",
    },
    {
      label: "Northern Mariana Islands",
      value: "MP",
    },
    {
      label: "Ohio",
      value: "OH",
    },
    {
      label: "Oklahoma",
      value: "OK",
    },
    {
      label: "Oregon",
      value: "OR",
    },
    {
      label: "Palau",
      value: "PW",
    },
    {
      label: "Pennsylvania",
      value: "PA",
    },
    {
      label: "Puerto Rico",
      value: "PR",
    },
    {
      label: "Rhode Island",
      value: "RI",
    },
    {
      label: "South Carolina",
      value: "SC",
    },
    {
      label: "South Dakota",
      value: "SD",
    },
    {
      label: "Tennessee",
      value: "TN",
    },
    {
      label: "Texas",
      value: "TX",
    },
    {
      label: "Utah",
      value: "UT",
    },
    {
      label: "Vermont",
      value: "VT",
    },
    {
      label: "Virgin Islands",
      value: "VI",
    },
    {
      label: "Virginia",
      value: "VA",
    },
    {
      label: "Washington",
      value: "WA",
    },
    {
      label: "West Virginia",
      value: "WV",
    },
    {
      label: "Wisconsin",
      value: "WI",
    },
    {
      label: "Wyoming",
      value: "WY",
    },
  ];
  const { isXS } = useBreakpoints();
  const successCallBackAddress = (addressId) => {
    setAddressForm(false);
    dispatch({ type: "SELECT_ADDRESS_ID", payload: { addressId } });
    reset();
    setshowAddAddressModal(false);
  };
  const failureCallBackAddress = () => {};

  useEffect(() => {
    localStorage.setItem("currentAddressID", currentAddressId);
  }, [currentAddressId]);

  const { addresses } = currentUser || {};
  useEffect(() => {
    if (allCategories && allCategories.length == 0) {
      dispatch(getAllCategories());
    }
  }, [allCategories]);

  const [activeCategory, setActiveCategory] = useState(
    localStorage.getItem("activeCategory")
  );
  const [manufacturedYear, setManufacturedYear] = useState();
  const [purchaseYearsList, setPurchaseYearsList] = useState();
  const [shippingList, setshippingList] = useState(
    localStorage.getItem("deliveryMethod")
  );
  const [purchasedYear, setPurchasedYear] = useState(
    localStorage.getItem("activepurchasedYear")
  );
  const [selectedBrandImage, setSelectedBrandImage] = useState(
    JSON.parse(localStorage.getItem("brandlogo"))
  );

  const [gender, setGender] = useState(localStorage.getItem("activeGender"));
  const [negotiablePrice, setNegotiablePrice] = useState("yes");
  const [condition, setcondition] = useState(
    localStorage.getItem("activeCondition")
  );

  const [selectedAgeSize, setSelectedAgeSize] = useState("");
  const [length, setLength] = useState(localStorage.getItem("activeLength"));
  const [width, setWidth] = useState(localStorage.getItem("activeWidth"));
  const [height, setheight] = useState(localStorage.getItem("activeHeight"));
  const [weight, setWeight] = useState(localStorage.getItem("activeWeight"));
  const [pay, setpay] = useState("");
  const parcelDimensions = {
    length: length,
    width: width,
    height: height,
    weight: weight,
  };

  const addressTo = {
    name: "Ankur Dhawan",
    street1: "20 River Terrace",
    city: "New York",
    state: "New York",
    zip: "10282",
    country: "USA",
    phone: "9179727654",
  };
  const [add, setAdd] = useState(0);

  useEffect(() => {
    if (height && width && length && weight && addresses != null) {
      const dataInput = {
        addressFrom: addresses[add],
        addressTo: addressTo,
        parcelDimensions: parcelDimensions,
      };
      dispatch(getEstimatedCharges(dataInput));
    }
  }, [height, weight, length, width, addresses, add]);

  let setActive = (e, category) => {
    localStorage.setItem("activeCategory", category.category);
    setActiveCategory(category.category);
    setSelectedAgeSize("");
    localStorage.removeItem("age");
    localStorage.removeItem("size");
  };

  const [showBrandDialog, setshowBrandDialog] = useState(false);
  const [showColorDialog, setshowColorDialog] = useState(false);
  const [showAddAddressModal, setshowAddAddressModal] = useState(false);
  const handleOpenBrand = () => {
    setshowBrandDialog(true);
  };
  const handleOpenColor = () => {
    setshowColorDialog(true);
  };
  const handleOpenAddAddressModal = () => {
    setAddressModalType("add");
    setshowAddAddressModal(true);
  };
  const handleCloseAddAddressModal = () => {
    setshowAddAddressModal(false);
    setUpdateAddressForm(false);
  };
  const handleCancelDialog = () => {
    setShowcancelDialog(true);
  };
  const [imageFileList, setImageFileList] = useState([]);
  const [videoFileList, setVideoFileList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [tempImgArray, setTempImgArray] = useState([]);
  const [imageUploadCheck, setImageUploadCheck] = useState(false);

  useEffect(() => {
    if (fileList != []) {
      // dispatch(uploadUserPhoto({file: fileList, fileName: fileList.name}, successCallBackUpdateImage, failureCallUpdateUser))
      dispatch(
        uploadProductPhoto(
          { file: fileList, fileName: fileList.name },
          successCallBackUpdateImage,
          failureCallUpdateUser
        )
      );
    }
  }, [fileList]);
  const successCallBackUpdateImage = (data) => {
    setImageUploadCheck(true);
  };
  const failureCallUpdateUser = () => {};

  useEffect(() => {
    if (imageUploadCheck && photo != null && !tempImgArray.includes(photo)) {
      setTempImgArray([...tempImgArray, photo]);
      setImageUploadCheck(false);
    }
  }, [imageUploadCheck]);

  useEffect(() => {
    if (tempImgArray.length > 0) {
      localStorage.setItem("ImageLinks", JSON.stringify(tempImgArray));
    }
  }, [tempImgArray]);
  const [imagevalidation, setImageValidation] = useState(
    "* Product Image is required"
  );
  const [shippingValidation, setshippingValidation] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (imageFileList.length != 0) {
      setImageValidation("");
    } else {
      setImageValidation("* Product Image is required");
    }
  }, [imageFileList]);

  useEffect(() => {
    if (localStorage.getItem("imagesUploaded") != null) {
      setImageFileList(JSON.parse(localStorage.getItem("imagesUploaded")));
    }
  }, []);

  let getImageUploadField = () => {
    const handleImageChange = ({ fileList }) => {
      if (fileList.length > 0) {
        const isLt2M = fileList[fileList.length - 1].size / 1024 / 1024 < 2;
        if (!isLt2M) {
          fileList.pop();
        }
      }
      setImageFileList(fileList);
      localStorage.setItem("imagesUploaded", JSON.stringify(fileList));
    };
    const handleVideoChange = ({ fileList }) => {
      setVideoFileList(fileList);
    };

    const beforeImageUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    };

    const beforeVideoUpload = (file) => {
      const isJpgOrPng = file.type === "image/mp4";
      if (!isJpgOrPng) {
        message.error("You can only upload mp4 file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Video must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    };

    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };

    return (
      <>
        <Upload
          className="uploadImage"
          action={process.env.REACT_APP_DOMAIN}
          listType="picture-card"
          fileList={imageFileList}
          //   onPreview={this.handlePreview}
          // beforeUpload={beforeImageUpload}
          onChange={(value) => handleImageChange(value)}
          // customRequest={dummyRequest}
          showUploadList={{ showPreviewIcon: false }}
          accept=".png,.jpeg,.jpg"
          beforeUpload={(file) => {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
              message.error("Image must smaller than 2MB!");
            }
            setFileList(file);
            return false && isLt2M;
          }}
        >
          {imageFileList.length >= 8 ? null : (
            <Button
              type="text"
              icon={<img alt="" src={CameraIcon} />}
              className="uploadImageButton"
            >
              Add Image
            </Button>
          )}
        </Upload>
        {/* <Upload
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={videoFileList}
          //   onPreview={this.handlePreview}
          beforeUpload={beforeVideoUpload}
          onChange={(value) => handleVideoChange(value)}
        >
          {videoFileList.length >= 1 ? null : (
            <Button
              type="text"
              icon={<img alt="" src={VideoIcon} />}
              className="uploadImageButton"
            >
              Add Video (optional)
            </Button>
          )}
        </Upload> */}
      </>
    );
  };

  const retailPriceRef = useRef("");
  const sellingPriceRef = useRef("");
  const [zipValidation, setzipvalidation] = useState("");
  const [cityValidation, setcityvalidation] = useState("");
  const [stateValidation, setstatevalidation] = useState("");
  const [address1Validation, setaddress1validation] = useState("");
  const [phoneValidation, setphonevalidation] = useState("");
  const [nameValidation, setnamevalidation] = useState("");
  const [descriptionValidation, setdescriptionValidation] = useState("");
  const [categoryValidation, setCategoryValidation] = useState("");
  const [brandValidation, setbrandValidation] = useState("");
  const [manufacturedValidation, setmanufacturedValidation] = useState("");
  const [purchasedValidation, setpurchasedValidation] = useState("");
  const [genderValidation, setgenderValidation] = useState("");
  const [conditionValidation, setconditionValidation] = useState("");
  const [ageSizeValidation, setAgeSizeValidation] = useState("");
  const [sellingPriceValidation, setSellingPriceValidation] = useState("");
  const [lengthvalidation, setlengthValidation] = useState("");
  const [widthvalidation, setwidthValidation] = useState("");
  const [payvalidation, setpayValidation] = useState("");
  const [heightvalidation, setheightValidation] = useState("");
  const [weightvalidation, setweightValidation] = useState("");
  const [colorValidation, setcolorValidation] = useState("");
  const handleClick = () => {
    setShowErrorMessage(true);
    if (imageFileList.length > 0) {
      localStorage.setItem("imagesUploaded", JSON.stringify(imageFileList));
    }
    if (!localStorage.getItem("productDescription")) {
      setdescriptionValidation("*description is required");
    }
    if (!activeCategory) {
      setCategoryValidation("*category is required");
    }
    if (!selectedBrandImage || !(selectedBrand && selectedBrand)) {
      setbrandValidation("*Brand is required");
    }

    if (!localStorage.getItem("activeColor")) {
      setcolorValidation("*color is required");
    }
    if (activeCategory == "strollers" && !manufacturedYear) {
      setmanufacturedValidation("*manufactured  year is required");
    }
    if (!gender) {
      setgenderValidation("*gender is required");
    }
    if (!condition) {
      setconditionValidation("*condition is required");
    }
    if (!purchasedYear) {
      setpurchasedValidation("*purchased year is required");
    }

    if (!selectedAgeSize) {
      setAgeSizeValidation(`* ${ageSizeText} is required`);
    }
    if (
      localStorage.getItem("deliveryMethod")?.length === 0 ||
      !JSON.parse(localStorage.getItem("deliveryMethod"))
    ) {
      setshippingValidation("*delivery method is required");
    }
    if (shippingList) {
      if (
        shippingList[0] &&
        shippingList[0] == "Nationwide Shipping" &&
        !length
      ) {
        setlengthValidation("*length is required");
      }
      if (
        (shippingList[0] && shippingList[0]) == "Nationwide Shipping" &&
        !weight
      ) {
        setweightValidation("*weight is required");
      }
      if (
        (shippingList[0] && shippingList[0]) == "Nationwide Shipping" &&
        !height
      ) {
        setheightValidation("*height is required");
      }
      if (
        (shippingList[0] && shippingList[0]) == "Nationwide Shipping" &&
        !width
      ) {
        setwidthValidation("*width is required");
      }
    }
    if (!localStorage.getItem("Pay")) {
      setpayValidation("*This field is mandatory");
    }
    if (!sellingPriceRef.current.input.value) {
      setSellingPriceValidation("* Selling Price is required");
    } else if (sellingPriceRef.current.input.value) {
      setSellingPriceValidation("");
    }
    if (
      sellingPriceRef.current.input.value &&
      retailPriceRef.current.input.value
    ) {
      if (
        sellingPriceRef.current.input.value > retailPriceRef.current.input.value
      ) {
        setSellingPriceValidation(
          "* Selling Price can not be more than Retail Price"
        );
      }
      if (
        retailPriceRef.current.input.value >=
        sellingPriceRef.current.input.value
      ) {
        setSellingPriceValidation("");
      }
    }
    validateForm();
  };
  const [zipCode, setZipCode] = useState();
  const [Name, setName] = useState();
  const [address1, setaddress1] = useState();
  const [phone, setphone] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const handleaddressclick = () => {
    if (!Name) {
      setnamevalidation("*Name is required");
    }
    if (!address1) {
      setaddress1validation("*address line 1 is required");
    }
    if (!phone) {
      setphonevalidation("*Phone number is required");
    }
    if (!city) {
      setcityvalidation("*City is required");
    }
    if (!selectedState) {
      setstatevalidation("*State is required");
    }
    if (!zipCode) {
      setzipvalidation("*zipCode is required");
    }
  };

  const validateForm = () => {
    if (
      localStorage.getItem("productDescription") &&
      ((localStorage.getItem("activeCategory") === "strollers" &&
        localStorage.getItem("activeManufacturedYear")) ||
        localStorage.getItem("activeCategory")) &&
      localStorage.getItem("activepurchasedYear") &&
      localStorage.getItem("productTitle") &&
      ((JSON.parse(localStorage.getItem("deliveryMethod"))?.[0] ==
        "Nationwide Shipping" &&
        localStorage.getItem("activeWidth") &&
        localStorage.getItem("activeLength") &&
        localStorage.getItem("activeHeight") &&
        localStorage.getItem("activeWeight") &&
        localStorage.getItem("Pay")) ||
        JSON.parse(localStorage.getItem("deliveryMethod"))?.[0] ==
          "Local Pick Up") &&
      localStorage.getItem("sellingPrice") &&
      localStorage.getItem("activeGender") &&
      localStorage.getItem("activeColor") &&
      localStorage.getItem("imagesUploaded") &&
      (localStorage.getItem("size") || localStorage.getItem("age")) &&
      localStorage.getItem("activeCondition") &&
      localStorage.getItem("activeBrand")
    ) {
      history.push("/seller/preview");
    }
  };
  useEffect(() => {
    localStorage.setItem("negotiablePrice", "yes");
    localStorage.setItem("productTitle", title);
  }, []);
  const [finalSellingPrice, setFinalSellingPrice] = useState(0);
  useEffect(() => {
    setFinalSellingPrice(localStorage.getItem("sellingPrice"));
  }, []);
  let HandleInput = (e) => {
    let current = e.target;
    if (current.name == "retailPrice") {
      localStorage.setItem("retailPrice", current.value);
    } else if (current.name == "sellingPrice") {
      setFinalSellingPrice(current.value);
      localStorage.setItem("sellingPrice", current.value);
    } else if (current.name == "productDescription") {
      localStorage.setItem("productDescription", current.value);
    } else if (current.name == "productTitle") {
      localStorage.setItem("productTitle", current.value);
    } else if (current.name == "productHeight") {
      localStorage.setItem("activeHeight", current.value);
    }
  };

  let getCategoriesList = () => {
    if (allCategories.length) {
      return allCategories.map((category) => {
        return (
          <li
            className={
              category.category == activeCategory
                ? "category active"
                : "category"
            }
            onClick={(event) => {
              setActive(event, category);
              setCategoryValidation("");
            }}
          >
            {category.displayValue}
          </li>
        );
      });
    }
  };

  const brandImagesList = [
    { logoImage: Brand1, id: 1, Brandname: "UPPAbaby" },
    { logoImage: Brand2, id: 2, Brandname: "Graco" },
    { logoImage: Brand3, id: 3, Brandname: "Bugaboo" },
    { logoImage: Brand4, id: 4, Brandname: "Thule" },
    { logoImage: Brand5, id: 5, Brandname: "Stokke" },
    { logoImage: Brand6, id: 6, Brandname: "Mima" },
  ];

  let getBrandsList = () => {
    const Brands = localStorage.getItem("activeBrand");
    return (
      <>
        {brandImagesList.map((brand) => {
          return (
            <li
              className={
                brand.Brandname === Brands ? "OnImageSelectseller" : "brand"
              }
              onClick={() => {
                setSelectedBrandImage(brand);
                localStorage.setItem("brandlogo", JSON.stringify(brand));
                setbrandValidation("");
                setSelectedBrand([]);
                localStorage.setItem("activeBrand", brand.Brandname);
              }}
            >
              <img alt="Brand Logo" src={brand.logoImage} />
            </li>
          );
        })}
      </>
    );
  };

  const [selectedColor, setSelectedColor] = useState([]);
  function onColorSelectFunction(color) {
    setSelectedColor([color]);
  }

  function setManufacturedYearFunction(year) {
    let totalYears = [];
    setManufacturedYear(year);
    for (let i = year; i <= new Date().getFullYear(); i++) {
      totalYears.push({ value: i });
    }
    setPurchaseYearsList([...totalYears]);
  }

  useEffect(() => {
    if (localStorage.getItem("activeManufacturedYear")) {
      setManufacturedYearFunction(
        localStorage.getItem("activeManufacturedYear")
      );
    }
  }, []);

  let getYearOfManufactureList = () => {
    const yearList = [
      { value: "2014" },
      { value: "2015" },
      { value: "2016" },
      { value: "2017" },
      { value: "2018" },
      { value: "2019" },
      { value: "2020" },
      { value: "2021" },
    ];

    return yearList.map((years) => {
      return (
        <li
          className={
            manufacturedYear === years.value ? "years active" : "years"
          }
          onClick={() => {
            setManufacturedYearFunction(years.value);
            localStorage.setItem("activeManufacturedYear", years.value);
            setmanufacturedValidation("");
          }}
        >
          {years.value}
        </li>
      );
    });
  };

  useEffect(() => {
    if (localStorage.getItem("activepurchasedYear")) {
      setPurchasedYear(localStorage.getItem("activepurchasedYear"));
      setpurchasedValidation("");
    }
  }, []);

  let getYearOfPurchaseList = () => {
    if (manufacturedYear) {
      return purchaseYearsList.map((years) => {
        return (
          <li
            className={purchasedYear === years.value ? "years active" : "years"}
            onClick={() => {
              setPurchasedYear(years.value);
              setpurchasedValidation("");
              localStorage.setItem("activepurchasedYear", years.value);
            }}
          >
            {years.value}
          </li>
        );
      });
    } else {
      const yearList = [
        { value: "2014" },
        { value: "2015" },
        { value: "2016" },
        { value: "2017" },
        { value: "2018" },
        { value: "2019" },
        { value: "2020" },
        { value: "2021" },
      ];
      return yearList.map((years) => {
        return (
          <li
            className={purchasedYear === years.value ? "years active" : "years"}
            onClick={() => {
              setPurchasedYear(years.value);
              setpurchasedValidation("");
              localStorage.setItem("activepurchasedYear", years.value);
            }}
          >
            {years.value}
          </li>
        );
      });
    }
  };
  const onSubmit = (data) => {
    console.log(data);
  };
  let getColorsList = () => {
    const colorList = [
      { colorCode: "", colorName: "no color" },
      { colorCode: "#FFFFFF", colorName: "White" },
      { colorCode: " #393939", colorName: "black" },
      { colorCode: "#D4D8E3", colorName: "Grey" },
      { colorCode: "#2A853D", colorName: "green" },
      { colorCode: "#FF0000", colorName: "red" },
      { colorCode: "#2F80ED", colorName: "blue" },
    ];
    const colors = localStorage.getItem("activeColor");
    return colorList.map((color, index) => {
      return (
        <div className="colourContainersmall">
          <React.Fragment key={index}>
            <RoundColorComponent
              index={index}
              value={color.colorName === colors}
              onClick={() => {
                onColorSelectFunction(color);
                localStorage.setItem("activeColor", color.colorName);
                setSelectedColors([]);
                setcolorValidation("");
              }}
              color={color}
            />
          </React.Fragment>
        </div>
      );
    });
  };

  const handleGender = (event) => {
    setGender(event.target.value);
  };

  const handleNegotiablePrice = (event) => {
    setNegotiablePrice(event.target.value);
  };
  let getGenderOptions = () => {
    localStorage.setItem("activeGender", gender);
    return (
      <Radio.Group
        name="radiogroup"
        defaultValue={localStorage.getItem("activeGender")}
      >
        <Radio
          value={"male"}
          className="gender"
          checked={gender === "boy"}
          onChange={handleGender}
          onClick={() => {
            setgenderValidation("");
          }}
        >
          Boy
        </Radio>
        <Radio
          value={"female"}
          className="gender"
          checked={gender === "girl"}
          onChange={handleGender}
          onClick={() => {
            setgenderValidation("");
          }}
        >
          Girl
        </Radio>
        <Radio
          value={"neutral"}
          className="gender"
          checked={gender === "neutral"}
          onChange={handleGender}
          onClick={() => {
            setgenderValidation("");
          }}
        >
          Neutral
        </Radio>
      </Radio.Group>
    );
  };
  const handleCondition = (event) => {
    setcondition(event.target.value);
  };
  let getConditionOptions = () => {
    localStorage.setItem("activeCondition", condition);
    return (
      <Radio.Group
        name="radiogroup"
        defaultValue={localStorage.getItem("activeCondition")}
      >
        <Radio
          value={"new"}
          checked={condition === "New"}
          className="conditions"
          onChange={handleCondition}
          onClick={() => {
            setconditionValidation("");
          }}
        >
          New
        </Radio>
        <Radio
          value={"Great"}
          checked={condition === "Great"}
          className="conditions"
          onChange={handleCondition}
          onClick={() => {
            setconditionValidation("");
          }}
        >
          Great
        </Radio>
        <Radio
          value={"Good"}
          checked={condition === "Good"}
          className="conditions"
          onChange={handleCondition}
          onClick={() => {
            setconditionValidation("");
          }}
        >
          Good
        </Radio>
        <Radio
          value={"Fair"}
          checked={condition === "Fair"}
          className="conditions"
          onChange={handleCondition}
          onClick={() => {
            setconditionValidation("");
          }}
        >
          Fair
        </Radio>
      </Radio.Group>
    );
  };

  let getNegotiableStatus = () => {
    localStorage.setItem("negotiablePrice", negotiablePrice);
    return (
      <Radio.Group
        name="radiogroup"
        defaultValue={localStorage.getItem("negotiablePrice")}
      >
        <Radio
          value={"yes"}
          className="negotiableCheck"
          checked={negotiablePrice === "yes"}
          onChange={handleNegotiablePrice}
        >
          Yes
        </Radio>
        <Radio
          value={"no"}
          className="negotiableCheck"
          checked={negotiablePrice === "no"}
          onChange={handleNegotiablePrice}
        >
          No
        </Radio>
      </Radio.Group>
    );
  };

  const [ageSizeList, setAgeSizeList] = useState([]);
  const [ageSizeText, setAgeSizeText] = useState();
  useEffect(() => {
    if (activeCategory && activeCategory != undefined) {
      if (activeCategory == "clothing_and_accessories") {
        setAgeSizeList(clothingSizeList);
      } else if (activeCategory == "shoes") {
        setAgeSizeList(shoesSizeList);
      } else if (activeCategory == "books") {
        setAgeSizeList(booksAgeList);
      } else if (activeCategory == "toys_and_costumes") {
        setAgeSizeList(toysAndCostumesAgeList);
      } else if (activeCategory == "maternity") {
        setAgeSizeList(maternitySizeList);
      } else {
        setAgeSizeList(oneSizeList);
      }

      if (
        activeCategory == "cribs_and_furniture" ||
        activeCategory == "strollers" ||
        activeCategory == "clothing_and_accessories" ||
        activeCategory == "shoes" ||
        activeCategory == "feeding" ||
        activeCategory == "diapering" ||
        activeCategory == "bathing" ||
        activeCategory == "maternity" ||
        activeCategory == "electronics"
      ) {
        setAgeSizeText("Size");
      } else {
        setAgeSizeText("Age");
      }
    }
  }, [activeCategory]);

  useEffect(() => {
    if (localStorage.getItem("age") || localStorage.getItem("size")) {
      setSelectedAgeSize(
        localStorage.getItem("age") || localStorage.getItem("size")
      );
      setAgeSizeValidation("");
    }
  }, []);

  let setAgeSizeData = (ages) => {
    setSelectedAgeSize(ages.value);
  };
  useEffect(() => {
    if (selectedAgeSize) {
      localStorage.setItem("age", ageSizeText == "Age" ? selectedAgeSize : "");
      localStorage.setItem(
        "size",
        ageSizeText == "Size" ? selectedAgeSize : ""
      );
    }
  }, [selectedAgeSize]);

  let getAgesList = () => {
    return ageSizeList.map((ages) => {
      return (
        <li
          className={ages.value === selectedAgeSize ? "ages active" : "ages"}
          onClick={() => {
            setAgeSizeData(ages);
            setAgeSizeValidation("");
          }}
        >
          {ages.value}
        </li>
      );
    });
  };
  const plainOptions = ["Nationwide Shipping"];

  useEffect(() => {
    if (localStorage.getItem("deliveryMethod")) {
      setshippingList(JSON.parse(localStorage.getItem("deliveryMethod")));
      onChange(JSON.parse(localStorage.getItem("deliveryMethod")));
    }
  }, []);

  function onChange(checkedValues) {
    localStorage.setItem("deliveryMethod", JSON.stringify(checkedValues));
    setshippingList(checkedValues);
    setshippingValidation("");
  }

  return (
    <div className="websellerProductDetailsWrapper">
      <div className="app-wrapper">
        <Row gutter={[0, { xs: 25, sm: 25, md: 38, lg: 38, xl: 38, xxl: 38 }]}>
          <Col span={24}></Col>
          <Col offset={0}>
            <Row gutter={[28, 0]}>
              <Col>
                <Row
                  gutter={[0, { xs: 5, sm: 5, md: 8, lg: 8, xl: 8, xxl: 8 }]}
                >
                  <Col span={24}></Col>
                  <ArrowLeftOutlined
                    className="productDetailsUploadBackIcon"
                    onClick={() => history.goBack()}
                  />
                </Row>
              </Col>
              <Col className="productDetailsUploadTitle">
                <h1 className="afterBackIconTitle web">Product Details</h1>
                <Row gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Typography>
                    All field with asterisk (*) is mandatory
                  </Typography>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <form
          className="productDetailsUploadForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row
            justify="center"
            gutter={[0, { xs: 20, sm: 20, md: 35, lg: 35, xl: 35, xxl: 35 }]}
          >
            <Col span={24}></Col>
            <Col span={24}>
              <Row
                gutter={[0, 0]}
                justify="space-between"
                className="productDetailsParent"
              >
                <Col className="productDetailsUploadSection productImageUpload">
                  <Card className="productImageUploadCard">
                    <h2>
                      Add Product Photos{" "}
                      <span style={{ color: "#FD533F" }}>*</span>
                    </h2>
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography>
                        Add upto 8 images so buyers can see every details.
                      </Typography>
                    </Row>
                    {getImageUploadField()}
                    {showErrorMessage && (
                      <span style={{ color: "red" }}>{imagevalidation}</span>
                    )}
                  </Card>
                </Col>

                {/* xl={8} lg={8} md={11} sm={22} xs={22} */}
                <Col className="productDetailsUploadSection aboutSection">
                  <Card>
                    <ProductDetailsUploadInformationSection
                      title="Some tips to get you started…"
                      list={[
                        "Update your user profile with relevant details and add a profile picture. Profiles with profile pictures are more trusted!",

                        "Verify yourself by adding your payout details. The “Verified badge” is visible on your profile as well as your listing. Buyers naturally feel more reassured when buying from a verified seller.",
                      ]}
                    />
                  </Card>
                  <Card>
                    <ProductDetailsUploadInformationSection
                      title="Great photos sell faster!"
                      list={[
                        "Add as many clear photos as you can",
                        "Take your photos in good lighting that show the condition of your item",
                        "Include photos of any accessories you may include",
                      ]}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={24}></Col>

            <Col span={24}>
              <Row
                gutter={[0, 0]}
                justify="space-between"
                className="productDetailsParent"
              >
                <Col className="productDetailsUploadSection productTitleUpload">
                  <Card className="productImageUploadCard">
                    <h2>
                      Enter the title of the product for listing{" "}
                      <span style={{ color: "#FD533F" }}>*</span>
                    </h2>
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography>
                        Provide the product title so your listing sell faster
                      </Typography>
                    </Row>
                    <h3>Enter product title</h3>
                    <Row gutter={[0, 15]}>
                      <Col span={24}></Col>
                      <Input
                        control={control}
                        className="productDetailsInput"
                        as={Input}
                        name="productTitle"
                        defaultValue={title}
                        placeholder="Enter your product title"
                        size="large"
                        onInput={(event) => {
                          HandleInput(event);
                        }}
                      />
                    </Row>
                  </Card>
                </Col>
                {/* xl={8} lg={8} md={11} sm={22} xs={22} */}
                <Col className="productDetailsUploadSection aboutSection">
                  <Card>
                    <ProductDetailsUploadInformationSection
                      title="Product Title"
                      list={[
                        "Longer titles sell faster! If known, include year, brand, and model to attract more interested buyer",
                      ]}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row
                gutter={[0, 0]}
                justify="space-between"
                className="productDetailsParent"
              >
                <Col className="productDetailsUploadSection productDescriptionUpload">
                  <Card className="productDescriptionUploadCard">
                    <h2>
                      How would you describe it?{" "}
                      <span style={{ color: "#FD533F" }}>*</span>
                    </h2>
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography>
                        Provide the description of the items that you want to
                        sell
                      </Typography>
                    </Row>
                    <h3>Description of the product</h3>
                    <Row gutter={[0, 12]}>
                      <Col span={24}></Col>
                      <Col span={24}>
                        <Controller
                          as={TextArea}
                          name="productDescription"
                          control={control}
                          rows={4}
                          maxLength={240}
                          placeholder="Enter Description of the product"
                          defaultValue={localStorage.getItem(
                            "productDescription"
                          )}
                          className="productDescriptionTextArea"
                          {...register("description", { required: true })}
                          onInput={(event) => {
                            HandleInput(event);
                            setCount(event.target.value.length);
                            localStorage.setItem(
                              "descriptionCount",
                              event.target.value.length
                            );
                            setdescriptionValidation("");
                          }}
                        />
                        {localStorage.getItem("descriptionCount") ? (
                          <span style={{ color: "red" }}>
                            {localStorage.getItem("descriptionCount")}/240
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>{count}/240</span>
                        )}

                        {
                          <div style={{ color: "red" }}>
                            {descriptionValidation}
                          </div>
                        }
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {/* xl={8} lg={8} md={11} sm={22} xs={22} */}
                <Col className="productDetailsUploadSection aboutSection">
                  <Card>
                    <ProductDetailsUploadInformationSection
                      title="Product description"
                      list={[
                        "Include any product details you may know in the description",
                        "Include special shipping information in the description when appropriate",
                        "Buyers are more likely to be interested and buy your product when there is less confusion regarding the specifications of your product and listing",
                      ]}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row
                gutter={[0, 0]}
                justify="space-between"
                className="productDetailsParent"
              >
                <Col className="productDetailsUploadSection productDetailUpload">
                  <Card className="productDetailUploadCard">
                    <h2>Enter Product Details</h2>
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography>
                        Provide the details of items that you want to sell
                      </Typography>
                    </Row>
                    <Col span={24}></Col>

                    <div className="productAttributeSection categorySection">
                      <h3>
                        Category <span style={{ color: "#FD533F" }}>*</span>
                      </h3>
                      <ul className="categoryList">{getCategoriesList()} </ul>
                      <div>
                        {<li style={{ color: "red" }}>{categoryValidation}</li>}
                      </div>
                    </div>

                    <div className="productAttributeSection brandSection">
                      <h3>
                        Select your products Brands{" "}
                        <span style={{ color: "#FD533F" }}>*</span>
                      </h3>
                      <ul className="brandList">{getBrandsList()}</ul>
                      <span className="moreOptions">
                        Don't see your brand?{" "}
                        <Button onClick={() => handleOpenBrand()}>
                          Other brands
                        </Button>{" "}
                        {localStorage.getItem("activeBrand") && (
                          <span
                            style={{ color: "black" }}
                            className="Inputselectedtext"
                          >
                            Selected Brand
                            <Input
                              className="productShipmentDetailsInput Inputselected"
                              name="productTitle"
                              placeholder=""
                              value={localStorage.getItem("activeBrand")}
                            />
                          </span>
                        )}
                      </span>

                      <div>
                        {<li style={{ color: "red" }}>{brandValidation}</li>}
                      </div>
                    </div>

                    <div className="productAttributeSection yearOfManufactureSection">
                      <h3>
                        Year of Manufacture{" "}
                        {activeCategory == "strollers" && (
                          <span style={{ color: "#FD533F" }}>*</span>
                        )}
                      </h3>
                      <ul className="yearsList">
                        {getYearOfManufactureList()}
                      </ul>
                      <div>
                        {
                          <li style={{ color: "red" }}>
                            {manufacturedValidation}
                          </li>
                        }
                      </div>
                    </div>

                    <div className="productAttributeSection colorsSection">
                      <h3>
                        Color of the product{" "}
                        <span style={{ color: "#FD533F" }}>*</span>
                      </h3>
                      <div className="colorsAlignment">
                        <ul className="colorsList">{getColorsList()}</ul>
                      </div>
                      <span className="moreOptions">
                        Don't see your color?{" "}
                        <Button onClick={() => handleOpenColor()}>
                          More color options
                        </Button>{" "}
                        {localStorage.getItem("activeColor") && (
                          <span
                            style={{ color: "black" }}
                            className="Inputselectedtext"
                          >
                            Selected Color
                            <Input
                              className="productShipmentDetailsInput Inputselected"
                              name="productTitle"
                              placeholder=""
                              value={localStorage.getItem("activeColor")}
                            />
                          </span>
                        )}
                      </span>
                      {/* <ColorOfProduct /> */}
                      <div style={{ color: "red" }}>{colorValidation}</div>
                    </div>

                    <div className="productAttributeSection gendersSection">
                      <h3>
                        Who can use this item (Gender){" "}
                        <span style={{ color: "#FD533F" }}>*</span>
                      </h3>
                      {getGenderOptions()}
                      <div>
                        {
                          <li style={{ color: "red", marginTop: "24px" }}>
                            {genderValidation}
                          </li>
                        }
                      </div>
                    </div>

                    <div className="productAttributeSection conditionssSection">
                      <h3>
                        Conditon of the product{" "}
                        <span style={{ color: "#FD533F" }}>*</span>
                      </h3>
                      {getConditionOptions()}
                      <div>
                        {
                          <div style={{ color: "red", marginTop: "24px" }}>
                            {conditionValidation}
                          </div>
                        }
                      </div>
                    </div>

                    <div className="productAttributeSection yearOfPurchaseSection">
                      <h3>
                        Purchased year{" "}
                        <span style={{ color: "#FD533F" }}>*</span>
                      </h3>
                      <ul className="yearsList">{getYearOfPurchaseList()}</ul>
                      <div>
                        {
                          <li style={{ color: "red" }}>
                            {purchasedValidation}
                          </li>
                        }
                      </div>
                    </div>

                    {ageSizeList && ageSizeList.length ? (
                      <div className="productAttributeSection ageSection">
                        <h3>
                          {ageSizeText}{" "}
                          <span style={{ color: "#FD533F" }}> *</span>
                        </h3>
                        <ul className="agesList">{getAgesList()}</ul>
                        <span style={{ color: "red" }}>
                          {ageSizeValidation}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Card>
                </Col>
                {/* xl={8} lg={8} md={11} sm={22} xs={22} */}
                <Col className="productDetailsUploadSection aboutSection">
                  {/* <Card>
                    <ProductDetailsUploadInformationSection
                      title="Product Details"
                      list={[
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        "Felis viverra nec tincidunt pretium eu quam.",
                        "Ut gravida enim elementum tristique.",
                        "Natoque nisl vitae ac sit porttitor sollicitudin vitae porta.",
                      ]}
                    />
                  </Card> */}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row
                gutter={[0, 0]}
                justify="space-between"
                className="productDetailsParent"
              >
                <Col className="productDetailsUploadSection productShippingUpload">
                  <Card className="productShippingUploadCard">
                    <h2>
                      Enter Shipping Details{" "}
                      <span style={{ color: "#FD533F" }}>*</span>
                    </h2>
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography>
                        Enter the address where you product is located (All
                        fields are required)
                      </Typography>
                    </Row>
                    <Col span={24}></Col>

                    <div className="productAttributeSection deliveryMethodSection">
                      <h3>
                        Delivery Method{" "}
                        <span style={{ color: "#FD533F" }}>*</span>
                      </h3>
                      <Checkbox.Group
                        defaultValue={shippingList}
                        options={plainOptions}
                        onChange={onChange}
                      />
                      <div style={{ color: "red", marginTop: "24px" }}>
                        {shippingValidation}
                      </div>
                    </div>
                    {shippingList && shippingList[0] == "Nationwide Shipping" && (
                      <>
                        <div className="productAttributeSection productSizeSection">
                          <h3>
                            Size of the package in inches (Length*Width*Height)
                          </h3>
                          <Input
                            className="productShipmentDetailsInput productSizeInput"
                            name="productTitle"
                            type="number"
                            pattern="[0-9]*"
                            placeholder="Length Inch eg.45.2"
                            size="large"
                            defaultValue={localStorage.getItem("activeLength")}
                            onChange={(e) => {
                              setLength(e.target.value);
                              localStorage.setItem(
                                "activeLength",
                                e.target.value
                              );
                              setlengthValidation("");
                            }}
                          />
                          <div>
                            {
                              <div style={{ color: "red", marginTop: "24px" }}>
                                {lengthvalidation}
                              </div>
                            }
                          </div>
                          <Input
                            className="productShipmentDetailsInput productSizeInput"
                            name="productTitle"
                            type="number"
                            pattern="[0-9]*"
                            placeholder="Width Inch eg.45.2"
                            size="large"
                            defaultValue={localStorage.getItem("activeWidth")}
                            onChange={(e) => {
                              setWidth(e.target.value);
                              localStorage.setItem(
                                "activeWidth",
                                e.target.value
                              );
                              setwidthValidation("");
                            }}
                          />
                          <div style={{ color: "red", marginTop: "24px" }}>
                            {widthvalidation}
                          </div>
                          <Input
                            className="productShipmentDetailsInput productSizeInput"
                            name="productTitle"
                            placeholder="Height Inch eg.45.2"
                            size="large"
                            type="number"
                            pattern="[0-9]*"
                            defaultValue={localStorage.getItem("activeHeight")}
                            onChange={(e) => {
                              setheight(e.target.value);
                              localStorage.setItem(
                                "activeHeight",
                                e.target.value
                              );
                              setheightValidation("");
                            }}
                          />
                        </div>
                        <div style={{ color: "red", marginTop: "24px" }}>
                          {heightvalidation}
                        </div>
                        <Row gutter={[0, 0]}>
                          <Col>
                            <h3>Weight of product in pounds</h3>
                            <Input
                              control={control}
                              className="productShipmentDetailsInput productWeightInput"
                              name="productTitle"
                              placeholder="Pound (lbs)     eg.45.2"
                              size="large"
                              type="number"
                              pattern="[0-9]*"
                              defaultValue={localStorage.getItem(
                                "activeWeight"
                              )}
                              onInput={(e) => {
                                setWeight(e.target.value);
                                localStorage.setItem(
                                  "activeWeight",
                                  e.target.value
                                );
                                setweightValidation("");
                              }}
                            />
                            <div style={{ color: "red", marginTop: "24px" }}>
                              {weightvalidation}
                            </div>
                          </Col>
                          {localStorage.getItem("activeLength") &&
                            localStorage.getItem("activeWidth") &&
                            localStorage.getItem("activeHeight") &&
                            localStorage.getItem("activeWeight") &&
                            addresses[0] && (
                              <Col>
                                <h3>Estimated Shipping Charges</h3>
                                <Input
                                  control={control}
                                  className="productShipmentChargesInput productWeightInput"
                                  as={Input}
                                  name="estimatedShipping"
                                  placeholder="$"
                                  size="small"
                                  defaultValue={""}
                                  value={rates && rates[0]?.amount}
                                  onChange={localStorage.setItem(
                                    "estimatedCharges",
                                    rates && rates[0]?.amount
                                  )}
                                />
                              </Col>
                            )}
                          {/* {!localStorage.getItem("activeLength") &&
                            !localStorage.getItem("activeWidth") &&
                            !localStorage.getItem("activeHeight") &&
                            !localStorage.getItem("activeWeight") &&
                            !addresses && (
                              <Col>
                                <h3>Estimated Shipping Charges</h3>
                                <Input
                                  control={control}
                                  className="productShipmentChargesInput productWeightInput"
                                  as={Input}
                                  name="estimatedShipping"
                                  placeholder="$"
                                  size="large"
                                  defaultValue={""}
                                />
                              </Col>
                            )} */}
                          {!addresses[0] &&
                            localStorage.getItem("activeLength") &&
                            localStorage.getItem("activeWidth") &&
                            localStorage.getItem("activeHeight") &&
                            localStorage.getItem("activeWeight") && (
                              <Col>
                                <h3 style={{ color: "#FD533F" }}>
                                  {" "}
                                  *Please enter the shipping address to get the
                                  estimated shipping charges
                                </h3>
                              </Col>
                            )}
                        </Row>
                      </>
                    )}

                    <div className="productAttributeSection deliveryAddressSection">
                      {shippingList &&
                        shippingList[0] == "Nationwide Shipping" && (
                          <>
                            <h3>
                              Will you offer free shipping{" "}
                              <span style={{ color: "red" }}>*</span>
                            </h3>
                            <Checkbox.Group
                              value={localStorage.getItem("Pay")}
                              defaultValue={localStorage.getItem("Pay")}
                            >
                              <Checkbox
                                value={"yes"}
                                checked={pay == "yes"}
                                onChange={(e) => {
                                  setpay(e.target.value);
                                  localStorage.setItem("Pay", e.target.value);
                                  setpayValidation("");
                                }}
                              >
                                Yes
                              </Checkbox>

                              <Checkbox
                                type="radio"
                                value={"no"}
                                checked={pay == "no"}
                                onChange={(e) => {
                                  setpay(e.target.value);
                                  localStorage.setItem("Pay", e.target.value);
                                  setpayValidation("");
                                }}
                              >
                                No
                              </Checkbox>
                            </Checkbox.Group>
                            <div style={{ color: "red", marginTop: "24px" }}>
                              {payvalidation}
                            </div>
                          </>
                        )}
                      {shippingList &&
                        shippingList[0] == "Nationwide Shipping" && (
                          <>
                            <h3>Delivery Address</h3>
                            {addresses &&
                              addresses.map((val, i) => (
                                <Row gutter={[0, 14]} key={i}>
                                  <Col span={24}></Col>
                                  <Col span={24}>
                                    <Card className="shoppingCartCard">
                                      <Row key={i} justify="space-between">
                                        <Col span={24}></Col>
                                        <Col>
                                          <Row gutter={[19, 0]}>
                                            <Col
                                              span={2}
                                              style={{
                                                paddingTop: 5,
                                                paddingRight: 20,
                                              }}
                                            >
                                              <Radio
                                                defaultValue={
                                                  val._id === currentAddressId
                                                }
                                                key={i}
                                                onChange={() => {
                                                  handleAddressChange(val._id);
                                                  setAdd(i);
                                                }}
                                                checked={
                                                  val._id === currentAddressId
                                                }
                                                className="addressRadio"
                                              />
                                            </Col>
                                            <Col span={20}>
                                              <Row gutter={[17, 0]}>
                                                <Col>
                                                  <Typography className="cEAUserName">
                                                    {val.name}
                                                  </Typography>
                                                </Col>
                                              </Row>
                                              <Row
                                                gutter={[
                                                  0,
                                                  {
                                                    xs: 18,
                                                    sm: 18,
                                                    md: 0,
                                                    lg: 0,
                                                    xl: 0,
                                                    xxl: 0,
                                                  },
                                                ]}
                                              >
                                                <Col span={24} />
                                                <Typography className="cEAddress">
                                                  {val.address},{" "}
                                                  {val.secondAddress}
                                                  {val.secondAddress
                                                    ? ","
                                                    : ""}{" "}
                                                  {val.city}, {val.state},{" "}
                                                  {val.zip}
                                                </Typography>
                                              </Row>
                                              <Row
                                                gutter={[
                                                  0,
                                                  {
                                                    xs: 6,
                                                    sm: 6,
                                                    md: 0,
                                                    lg: 0,
                                                    xl: 0,
                                                    xxl: 0,
                                                  },
                                                ]}
                                              >
                                                <Col span={24} />
                                                <Typography className="cEAddress">
                                                  Mobile -{" "}
                                                  <span className="ceAMobileVal">
                                                    {val.phone}
                                                  </span>
                                                </Typography>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>

                                        <Col>
                                          <Row
                                            gutter={[
                                              0,
                                              {
                                                xs: 33,
                                                sm: 33,
                                                md: 0,
                                                lg: 0,
                                                xl: 0,
                                                xxl: 0,
                                              },
                                            ]}
                                          >
                                            <Col span={24} />
                                            <Col style={{ paddingLeft: 10 }}>
                                              <Button
                                                type="text"
                                                icon={
                                                  <img
                                                    alt=""
                                                    src={EditIcon}
                                                    style={{ marginRight: 10 }}
                                                  />
                                                }
                                                className="cEAActionText"
                                                onClick={() =>
                                                  handleUpdateAddressForm(
                                                    val._id
                                                  )
                                                }
                                              >
                                                Edit
                                              </Button>

                                              <Divider type="vertical" />
                                              <Button
                                                type="text"
                                                icon={
                                                  <img
                                                    alt=""
                                                    src={RemoveIcon}
                                                    style={{ marginRight: 10 }}
                                                  />
                                                }
                                                className="cEAActionText"
                                                onClick={() =>
                                                  handleRemoveAddress(val._id)
                                                }
                                              >
                                                Remove
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                </Row>
                              ))}
                          </>
                        )}
                      {shippingList &&
                        shippingList[0] == "Nationwide Shipping" && (
                          <>
                            <Row
                              gutter={[
                                0,
                                {
                                  xs: 25,
                                  sm: 25,
                                  md: 30,
                                  lg: 30,
                                  xl: 30,
                                  xxl: 30,
                                },
                              ]}
                            >
                              <Col span={24}></Col>
                              <Col>
                                <Button
                                  className="addAddressButton"
                                  onClick={() => handleOpenAddAddressModal()}
                                  type="text"
                                  icon={<PlusOutlined />}
                                >
                                  Add new shipping address
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                    </div>
                  </Card>
                </Col>
                {/* xl={8} lg={8} md={11} sm={22} xs={22} */}
                <Col className="productDetailsUploadSection aboutSection">
                  {/* <Card>
                    <ProductDetailsUploadInformationSection
                      title="Delivery Details"
                      list={[
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        "Felis viverra nec tincidunt pretium eu quam.",
                        "Ut gravida enim elementum tristique.",
                        "Natoque nisl vitae ac sit porttitor sollicitudin vitae porta.",
                      ]}
                    />
                  </Card> */}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row
                gutter={[0, 0]}
                justify="space-between"
                className="productDetailsParent"
              >
                <Col className="productDetailsUploadSection productPaymentUpload">
                  <Card className="productPaymentUploadCard">
                    <h2>
                      How much are you selling it for?{" "}
                      <span style={{ color: "#FD533F" }}>*</span>
                    </h2>
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography>
                        Provide the pricing details of items that you want to
                        sell
                      </Typography>
                    </Row>

                    <h3>Retail Price</h3>
                    <Row gutter={[0, 15]}>
                      <Col span={24}></Col>
                      <Input
                        ref={retailPriceRef}
                        control={control}
                        className="productDetailsInput"
                        name="retailPrice"
                        placeholder="$  eg. 750"
                        size="large"
                        defaultValue={localStorage.getItem("retailPrice")}
                        onInput={(event) => {
                          HandleInput(event);
                        }}
                      />
                    </Row>

                    <h3>
                      Selling Price <span style={{ color: "#FD533F" }}>*</span>
                    </h3>
                    <Row gutter={[0, 15]}>
                      <Col span={24}></Col>
                      <Input
                        ref={sellingPriceRef}
                        control={control}
                        className="productDetailsInput"
                        name="sellingPrice"
                        placeholder="$  eg. 750"
                        size="large"
                        defaultValue={localStorage.getItem("sellingPrice")}
                        onInput={(event) => {
                          HandleInput(event);
                        }}
                      />
                    </Row>
                    <span style={{ color: "red" }}>
                      {sellingPriceValidation}
                    </span>
                    {finalSellingPrice > 0 ? (
                      <>
                        <h3>Final Selling Price</h3>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <span
                            style={{
                              background: "#fff",
                              border: "1px solid #707070",
                              padding: "10px",
                              borderRadius: "5px",
                              fontSize: "16px",
                            }}
                          >
                            ${" "}
                            {pay == "yes"
                              ? finalSellingPrice - rates[0]?.amount
                              : finalSellingPrice}
                          </span>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="productAttributeSection negotiablePriceCheckSection">
                      <h3>Is this price negotiable?</h3>
                      {getNegotiableStatus()}
                    </div>
                  </Card>
                </Col>
                {/* xl={8} lg={8} md={11} sm={22} xs={22} */}
                <Col className="productDetailsUploadSection aboutSection">
                  <Card>
                    <ProductDetailsUploadInformationSection
                      title="Product Pricing"
                      list={[
                        "Check listings similar to yours to price your item the most effectively",
                        "Items that are competitively priced will gain more traction with interested buyers",
                      ]}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="left" gutter={[0, 0]}>
            <Col span={24}></Col>
            <Col>
              <Button
                className="productUploadCancelButton"
                onClick={handleCancelDialog}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                // className={
                //   isButtonEnabled
                //     ? "button button-primary productUploadSaveButton"
                //     : "productUploadCancelButton"
                // }
                className="button button-primary productUploadSaveButton"
                htmlType="submit"
                onClick={handleClick}
              >
                Save and continue
              </Button>
            </Col>
          </Row>
        </form>
      </div>
      <SelectBrand
        setSelectedBrand={setSelectedBrand}
        selectedBrand={selectedBrand}
        setbrandValidation={setbrandValidation}
        setSelectedBrandImage={setSelectedBrandImage}
        selectedBrandImage={selectedBrandImage}
        showBrandDialog={showBrandDialog}
        onCancelBrandDialog={() => setshowBrandDialog(false)}
      />
      <SelectColourSeller
        setcolorValidation={setcolorValidation}
        selectedColors={selectedColors}
        setSelectedColors={setSelectedColors}
        setSelectedColor={setSelectedColor}
        selectedColor={selectedColor}
        showColorDialog={showColorDialog}
        onCancelColorDialog={() => setshowColorDialog(false)}
      />
      <Modal
        footer={null}
        width={800}
        visible={
          addressModalType == "add" ? showAddAddressModal : updateAddressForm
        }
        onCancel={() => handleCloseAddAddressModal()}
      >
        <Spin
          indicator={antIcon}
          spinning={isAddAddressLoading}
          style={{ color: "#FF533F" }}
        >
          {addressModalType == "add" ? (
            <>
              <Row
                gutter={[
                  0,
                  { xs: 50, sm: 50, md: 40, lg: 40, xl: 40, xxl: 40 },
                ]}
              >
                <Col span={24}></Col>
                <Col>
                  <Typography className="addnewAddressText">
                    Add new Address
                  </Typography>
                </Col>
              </Row>

              <Row gutter={[0, 14]}>
                <Col span={24}></Col>
                <Col span={24} className="shoppingCartParent2">
                  <Card className="shoppingCartCard">
                    <form
                      onSubmit={(e) => {
                        handleSubmit(handleAddAddress(e));
                      }}
                    >
                      <Row gutter={[30, 0]}>
                        <Col span={24}></Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                          <Row
                            gutter={[
                              0,
                              {
                                xs: 25,
                                sm: 25,
                                md: 30,
                                lg: 30,
                                xl: 30,
                                xxl: 30,
                              },
                            ]}
                          >
                            <Col span={24}></Col>
                            <Typography className="addAddressInputTitle">
                              Name
                            </Typography>
                          </Row>
                          <Row gutter={[0, 15]}>
                            <Col span={24}></Col>
                            <Input
                              as={Input}
                              name="name"
                              placeholder="Type your name"
                              control={control}
                              onChange={(e) => {
                                setName(e.target.value);
                                setnamevalidation("");
                              }}
                              size="large"
                              className="addAddressInput"
                              rules={{ required: true }}
                            />
                            {/* {errors.name && errors.name.type === "required" && (
                              <span role="alert" className="error">
                                Name is required
                              </span>
                            )} */}
                            <div style={{ color: "red" }}>{nameValidation}</div>
                          </Row>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                          <Row gutter={[0, 30]}>
                            <Col span={24}></Col>
                            <Typography className="addAddressInputTitle">
                              Phone number
                            </Typography>
                          </Row>
                          <Row gutter={[0, 15]}>
                            <Col span={24}></Col>
                            <Input
                              as={Input}
                              name="phone"
                              type="number"
                              pattern="[0-9]*"
                              placeholder="Type your phone number"
                              control={control}
                              size="large"
                              onChange={(e) => {
                                setphone(e.target.value);
                                setphonevalidation("");
                              }}
                              className="addAddressInput"
                              rules={{ required: true }}
                            />
                            {/* {errors.phone && errors.phone.type === "required" && (
                              <span role="alert" className="error">
                                Phone number is required
                              </span>
                            )} */}
                            <div style={{ color: "red" }}>
                              {phoneValidation}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      {addresserror && (
                        <Row justify="center" gutter={[0, 8]}>
                          <Col span={24}></Col>
                          <Col>
                            <Alert
                              type="error"
                              onClose={handleClose}
                              open={open}
                              message={addresserror.error.message}
                            ></Alert>
                          </Col>
                        </Row>
                      )}
                      <Row gutter={[30, 8]}>
                        <Col span={24}></Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                          <Row gutter={[0, 20]}>
                            <Col span={24}></Col>
                            <Typography className="addAddressInputTitle">
                              Address
                            </Typography>
                          </Row>
                          <Row gutter={[0, 15]}>
                            <Col span={24}></Col>
                            <Input
                              as={Input}
                              name="address"
                              placeholder="Address line 1"
                              control={control}
                              onChange={(e) => {
                                setaddress1(e.target.value);
                                setaddress1validation("");
                              }}
                              size="large"
                              className="addAddressInput"
                              rules={{ required: true }}
                            />
                            {/* {errors.address &&
                              errors.address.type === "required" && (
                                <span role="alert" className="error">
                                  Address is required
                                </span>
                              )} */}
                            <div style={{ color: "red" }}>
                              {address1Validation}
                            </div>
                          </Row>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                          <Row
                            gutter={[
                              0,
                              {
                                xs: 15,
                                sm: 15,
                                md: 52,
                                lg: 52,
                                xl: 52,
                                xxl: 52,
                              },
                            ]}
                          >
                            <Col span={24}></Col>
                            <Controller
                              as={Input}
                              name="secondAddress"
                              placeholder="Address line 2"
                              control={control}
                              size="large"
                              className="addAddressInput"
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        gutter={[
                          8,
                          { xs: 0, sm: 0, md: 8, lg: 8, xl: 8, xxl: 8 },
                        ]}
                      >
                        <Col span={24}></Col>
                        <Col
                          xl={8}
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          className="city-block"
                        >
                          <Row gutter={[0, 20]}>
                            <Col span={24}></Col>
                            <Typography className="addAddressInputTitle">
                              City
                            </Typography>
                          </Row>
                          <Row gutter={[0, 15]}>
                            <Col span={24}></Col>
                            <Input
                              as={Input}
                              name="city"
                              placeholder="Type your city name"
                              control={control}
                              size="large"
                              onChange={(e)=>{setCity(e.target.value);setcityvalidation("")}}
                              className="addAddressInput"
                              rules={{ required: true }}
                            />
                            {/* {errors.city && errors.city.type === "required" && (
                              <span role="alert" className="error">
                                City is required
                              </span>
                            )} */}
                            <div style={{ color: "red" }}>
                              {cityValidation}
                            </div>
                          </Row>
                        </Col>
                        <Col
                          xl={8}
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          className="state-block"
                        >
                          <Row gutter={[0, 20]}>
                            <Col span={24}></Col>
                            <Typography className="addAddressInputTitle">
                              State
                            </Typography>
                          </Row>
                          <Row gutter={[0, 15]}>
                            <Col span={24}></Col>
                            <Select
                              style={{ width: "100%" }}
                              size="large"
                              onChange={(e) => {handleSelectedState(e);setstatevalidation("")}}
                            >
                              {US_STATES.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Select>

                          </Row>
                          <div style={{ color: "red" }}>
                              {stateValidation}
                            </div>
                        </Col>
                        <Col
                          xl={8}
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          className="zipcode-block"
                        >
                          <Row gutter={[0, 20]}>
                            <Col span={24}></Col>
                            <Typography className="addAddressInputTitle">
                              Zipcode
                            </Typography>
                          </Row>
                          <Row gutter={[0, 15]}>
                            <Col span={24}></Col>
                            <Input
                              as={Input}
                              name="zip"
                              type="number"
                              pattern="[0-9]*"
                              placeholder="Type your Zipcode"
                              control={control}
                              onInput={(e) => {
                                setZipCode(e.target.value);
                                setzipvalidation("");
                              }}
                              size="large"
                              className="addAddressInput"
                              rules={{ required: true }}
                            />
                            {/* {errors.zip && errors.zip.type === "required" && (
                              <span role="alert" className="error">
                                Zipcode is required
                              </span>
                            )} */}
                            <div style={{ color: "red" }}>{zipValidation}</div>
                          </Row>
                        </Col>
                      </Row>

                      <Row
                        gutter={[
                          8,
                          { xs: 41, sm: 41, md: 50, lg: 50, xl: 50, xxl: 50 },
                        ]}
                        justify={isXS ? "center" : "end"}
                      >
                        <Col span={24}></Col>
                        <Col>
                          <Button
                            className="btn-cancel"
                            onClick={() => setAddressForm(false)}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            htmlType="submit"
                            className="createAddressButton"
                            onClick={handleaddressclick}
                          >
                            Add Address
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <UpdateAddress
              updateAddressForm={true}
              selectedAddressId={shippingAddress._id}
              setUpdateAddressForm={setUpdateAddressForm}
            />
          )}
        </Spin>
      </Modal>
      <SellerCancelModal
        showcancelDialog={showcancelDialog}
        setShowcancelDialog={setShowcancelDialog}
      />
    </div>
  );
};

export default SellerProductDetails;
