import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Typography, Card, Button, Space, Empty } from "antd";
import { SellerCancelModal } from "../components/sellerCancelModal";
import { useSelector, useDispatch } from "react-redux";
import { useGaEvent } from "../hooks/useGaEvent";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ProductTop from "../components/ProductTop";
import PMenu from "../components/PMenu";
import CategoryHeader from "../components/CategoryHeader";
import PriceDetails from "../components/PriceDetails";
import ShoppingCart from "../components/ShoppingCart";
import { BrowserRouter as Router, Route, useHistory, useParams } from "react-router-dom";
import { getCurrentUserWallet, getLastCart } from "../Actions/cartAction";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { Inactivemodal } from "../components/Inactivemodal";

const { Text, Title } = Typography;
const { Header, Content, Footer } = Layout;
const CheckoutExperience = () => {
  const { gaEventClick } = useGaEvent();
  const { innerHeight } = window;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showcancelDialog, setShowcancelDialog] = useState(false);
  const [shippingCharges, setShippingCharges] = useState(0);
  const { currentCart, isAuthenticated, lastCart, providerToken, currentUser } = useSelector((state) => {
    const { currentCart, lastCart } = state.cart;
    const { isAuthenticated, providerToken } = state.auth;
    const { currentUser } = state.user;
    return {
      lastCart,
      currentCart,
      isAuthenticated,
      providerToken,
      currentUser
    };
  });
  const handleactivecheck = () => {
    if (currentCart?.find((item) => item.isActive === false)) {
      setShowcancelDialog(true);
    } else {
      handleChackoutClick();
    }
  };
  const handleChackoutClick = () => {
    if (isAuthenticated) {
      let lots = currentCart.map((x) => x.name).join(", ");
      gaEventClick("Cart", "Checkout", lots);
      history.push("/your-info");
    } else {
      gaEventClick("Navigation", "Checkout", "Signup");
      history.push("/onboarding/isCheckout/true");
    }
  };
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(getCurrentUserWallet());
    dispatch(getLastCart(currentUser._id));
    if(id)
    {
      if(!providerToken)
      {
        history.push('/onboarding/isCheckout/true');
      }
    }
  }, []);

  let tempPrice = 0;
  useEffect(() => {
    if (currentCart && currentCart.length > 0) {
      currentCart.map((cartItems) => {
        if (cartItems.isFree == false) {
          tempPrice = parseInt(cartItems.shippingPrice) + tempPrice;
          setShippingCharges(tempPrice);
        }
      });
    }
  }, [currentCart]);
  return (
    <>
      <Helmet>
        <title>Markid - Checkout</title>
      </Helmet>
      <div
        className="checkoutBG cartPage"
        style={{ height: currentCart && currentCart <= 0 ? innerHeight : null }}
      >
        <div className="app-wrapper">
          <Row
            gutter={[0, { xs: 25, sm: 25, md: 38, lg: 38, xl: 38, xxl: 38 }]}
          >
            <Col span={24}></Col>
            <Col offset={0}>
              <Row gutter={[28, 0]}>
                <Col>
                  <Row
                    gutter={[0, { xs: 5, sm: 5, md: 8, lg: 8, xl: 8, xxl: 8 }]}
                  >
                    <Col span={24}></Col>
                    <ArrowLeftOutlined
                      className="checkoutBackIcon"
                      onClick={() => {
                        gaEventClick("Navigation", "Go back from cart");
                        history.goBack();
                      }}
                    />
                  </Row>
                </Col>
                <Col className="checkoutTitle">
                  <h1 className="afterBackIconTitle web">Shopping cart</h1>
                  <h1 className="afterBackIconTitle mob">Secure chekout</h1>

                  <Row gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Typography className="totalCartItemText">
                      You have {currentCart.length} items in your cart
                      {/* {currentCart.length > 1 ? 'items' : 'item'}) items in your cart*/}
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {currentCart && currentCart.length > 0 ? (
            <Row
              justify="center"
              gutter={[0, { xs: 20, sm: 20, md: 35, lg: 35, xl: 35, xxl: 35 }]}
            >
              <Col span={24}></Col>
              <Col span={24}>
                <Row
                  gutter={[0, 0]}
                  justify="space-between"
                  className="shoppingCartParent page-checkout"
                >
                  {/* xl={{ span: 13, offset: 1 }} lg={{ span: 14, offset: 1 }} md={{ span: 11, offset: 1 }} sm={22} xs={22} */}
                  <Col className="shoppingCart-leftpane">
                    <Card className="shoppingCartCard">
                      <ShoppingCart />
                    </Card>
                  </Col>
                  {/* xl={8} lg={8} md={11} sm={22} xs={22} */}
                  <Col className="shoppingCart-rightpane">
                    <Row
                      gutter={[
                        0,
                        { xs: 50, sm: 50, md: 0, lg: 0, xl: 0, xxl: 0 },
                      ]}
                      className="priceDetailParent"
                    >
                      <Col span={24} />
                      <Card className="priceDetailsCard">
                        <PriceDetails
                          heading="Price details"
                          itemTotal={`Items total (${currentCart.length})`}
                          itemTotalVal={(
                            _.sumBy(currentCart, function (o) {
                              return o.price;
                            }) / 100
                          ).toFixed(2)}
                          shipping="Shipping"
                          // shippingVal={(shippingCharges / 100).toFixed(2)}
                          shippingVal={(
                            _.sumBy(currentCart, function (o) {
                              return o.isFree === false
                                ? parseFloat(o.shippingPrice) || 0
                                : 0;
                            }) / 100
                          ).toFixed(2)}
                          salesTax="Sales Tax"
                          salesTaxVal={(0).toFixed(2)}
                          cartTotal="Cart Total"
                          cartTotalVal="199.61"
                          button={
                            <Button
                              block
                              disabled={currentCart.length === 0}
                              className={"button button-primary checkout-btn"}
                              onClick={() => handleactivecheck()}
                              // onClick={() =>history.push('/shipping')}
                              // onClick={() => handleCreateCart()}
                            >
                              CHECKOUT
                            </Button>
                          }
                        />
                      </Card>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row gutter={[0, 48]} justify="center">
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <Typography className="totalCartItemText">
                      You have nothing in your cart
                    </Typography>
                  }
                />
              </Col>
            </Row>
          )}
        </div>
      </div>

      <Inactivemodal
        showcancelDialog={showcancelDialog}
        setShowcancelDialog={setShowcancelDialog}
      />
      {/* <checkoutModal showcancelDialog={showcancelDialog} setShowcancelDialog={setShowcancelDialog} /> */}
    </>
  );
};
export default CheckoutExperience;
