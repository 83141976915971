import constants from "../Utils/constants";
import { getMarkidProductById } from "../Actions/partnerProductAction";
import API from "../Utils/API";
import _ from "lodash";
const initialState = {
  currentCart: [],
  currentAddressId: "",
  isVerifyPromoCodeLoading: false,
  isApplyPromoCodeLoading: false,
  isRemovePromoCodeLoading: false,
  isPostCartLoading: false,
  isUpdateCartLoading: false,
  isGetWalletLoading: false,
  isPostStripePaymentLoading: false,
  isGetLastCartCartLoading: false,
  currentUserWallet: { balance: 0.0 },
  useCredits: false,
  latestClientSecret: "",
  currentCartId: "",
  lastCart: [],
  partnerProductShippingPrice: {},
  ispartnerProductShippingPriceLoading: false,
  isAvailPromotionsCheckLoading: false,
  availPromotionsCheck: "",
  discountAmount: "",
  p2pdiscountAmount: "",
  finalshippingprice:[],
  firstpurchasecheck:"",
  productsInCart: [],
  currentCartIds: [],
  cartMap: {},
  currentPLPItem: {}
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_CURRENT_CART:
      const tempArr = [...state.currentCartIds];
      if (!tempArr.includes(action.payload.id)) {
        tempArr.push(action.payload.id);
      }

      return {
        ...state,
        currentCartIds: tempArr,
      };
    case constants.DELETE_CURRENT_CART:
      const tempArr2 = [...state.currentCartIds];
      tempArr2.splice(tempArr2.indexOf(action.payload.id), 1);
      return {
        ...state,
        currentCartIds: tempArr2,
      };

    case constants.EMPTY_CURRENT_CART:
      return {
        ...state,
        currentCartIds: [],
      };

    case constants.ADD_ITEM_CART:
      const item = action.payload.item;
      const newCart = [];
      newCart.push(item);
      return {
        ...state,
        currentCart: newCart,
      };
    case constants.ADD_ITEM_CART_OVER_CURRENT_CART:
      const cartItem = action.payload.item;
      const updatedCart = [...state.currentCart];
      updatedCart.push(cartItem);
      return {
        ...state,
        currentCart: updatedCart,
      };
    case constants.PARTNER_PRODUCT_SHIIPING_CHARGE_REQUEST:
      return {
        ...state,
        ispartnerProductShippingPriceLoading: true,
      };
    case constants.PARTNER_PRODUCT_SHIIPING_CHARGE_SUCCESS:
      let partnerProductShippingPrice = action.payload;
      return {
        ...state,
        ispartnerProductShippingPriceLoading: false,
        partnerProductShippingPrice,
      };
    case constants.PARTNER_PRODUCT_SHIIPING_CHARGE_FAILURE:
      return {
        ...state,
        ispartnerProductShippingPriceLoading: false,
      };

    case constants.CURRENT_PLP_ITEM:
      return {
        ...state,
        currentPLPItem: action.payload.item,
      };


    case constants.REMOVE_ITEM_CART:
      const removeItem = action.payload.item;
      const updateCart = state.currentCart;
      // const uCart = _.remove(updateCart, function (i) {
      //   return i._id === removeItem._id;
      // });
      updateCart.splice(updateCart.indexOf(removeItem), 1);
      //console.log()
      return {
        ...state,
        currentCart: updateCart,
      };
    case constants.EMPTY_ITEM_CART:
      return {
        ...state,
        currentCart: [],
        lastCart: [],
      };
    case constants.GET_LAST_CART_REQUEST:
      return {
        ...state,
        isGetLastCartCartLoading: true,
      };
    case constants.GET_LAST_CART_SUCCESS:
      return {
        ...state,
        isGetLastCartCartLoading: false,
        lastCart: action.payload.lastCartIds,
        currentCartIds: action.payload.addedCartItems,
        currentCart: action.payload.currCartItems,
      };
    case constants.GET_LAST_CART_FAILURE:
      return {
        ...state,
        isGetLastCartCartLoading: false,
      };

    case constants.POST_CART_REQUEST:
      return {
        ...state,
        isPostCartLoading: true,
      };
    case constants.POST_CART_SUCCESS:
      const cart = action.payload.cartData.cart;
      //const cartMap = action.payload.map;
      return {
        ...state,
        isPostCartLoading: false,
        currentCartId: cart._id,
        //cartMap: cartMap,
        productsInCart: action.payload.cartData.lotDetails,
        currentCartIds: action.payload.cartData.cart.lots,
      };
    case constants.POST_CART_FAILURE:
      return {
        ...state,
        isPostCartLoading: false,
      };

    case constants.UPDATE_CART_REQUEST:
      return {
        ...state,
        isUpdateCartLoading: true,
      };
    case constants.UPDATE_CART_SUCCESS:
      return {
        ...state,
        isUpdateCartLoading: false,
      };
    case constants.UPDATE_CART_FAILURE:
      return {
        ...state,
        isUpdateCartLoading: false,
      };

    case constants.POST_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        isPostStripePaymentLoading: true,
      };
    case constants.POST_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isPostStripePaymentLoading: false,
        latestClientSecret: action.payload.clientSecret,
      };
    case constants.POST_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        isPostStripePaymentLoading: false,
      };

    case constants.GET_WALLET_REQUEST:
      return {
        ...state,
        isGetWalletLoading: true,
      };
    case constants.GET_WALLET_SUCCESS:
      return {
        ...state,
        isGetWalletLoading: false,
        currentUserWallet: action.payload,
      };
    case constants.GET_WALLET_FAILURE:
      return {
        ...state,
        isGetWalletLoading: false,
      };

    case constants.VERIFY_PROMO_CODE_REQUEST:
      return {
        ...state,
        isVerifyPromoCodeLoading: true,
      };
    case constants.VERIFY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isVerifyPromoCodeLoading: false,
      };
    case constants.VERIFY_PROMO_CODE_FAILURE:
      return {
        ...state,
        isVerifyPromoCodeLoading: false,
      };

    case constants.APPLY_PROMO_CODE_REQUEST:
      return {
        ...state,
        isApplyPromoCodeLoading: true,
      };
    case constants.APPLY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isApplyPromoCodeLoading: false,
      };
    case constants.APPLY_PROMO_CODE_FAILURE:
      return {
        ...state,
        isApplyPromoCodeLoading: false,
      };

    case constants.REMOVE_PROMO_CODE_REQUEST:
      return {
        ...state,
        isRemovePromoCodeLoading: true,
      };
    case constants.REMOVE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isRemovePromoCodeLoading: false,
      };
    case constants.REMOVE_PROMO_CODE_FAILURE:
      return {
        ...state,
        isRemovePromoCodeLoading: false,
      };

    case constants.AVAIL_PROMOTIONS_CHECK_REQUEST:
      return {
        ...state,
        isAvailPromotionsCheckLoading: true,
      };
    case constants.AVAIL_PROMOTIONS_CHECK_SUCCESS:
      return {
        ...state,
        isAvailPromotionsCheckLoading: false,
        availPromotionsCheck: action.payload,
      };
    case constants.AVAIL_PROMOTIONS_CHECK_FAILURE:
      return {
        ...state,
        isAvailPromotionsCheckLoading: false,
      };

    case constants.PARTNER_DISCOUNT_AMOUNT:
      return {
        ...state,
        discountAmount: action.payload,
      };
    case constants.P2P_DISCOUNT_AMOUNT:
      return {
        ...state,
        p2pdiscountAmount: action.payload,
      };
      
      case constants.FINAL_SHIPPINGPRICE:
        return {
          ...state,
          finalshippingprice: action.payload,
        };
        case constants.FIRST_PURCHASE:
          return {
            ...state,
            firstpurchasecheck: action.payload,
          };
  
    case constants.SELECT_ADDRESS_ID:
      return {
        ...state,
        currentAddressId: action.payload.addressId,
      };
    case constants.USE_MARKID_CREDIT:
      return {
        ...state,
        useCredits: action.payload.useCredits,
      };

    case constants.GET_USER_SUCCESS:
      const currentUser = action.payload;
      const { addresses } = currentUser;
      let currentAddressId = "";
      if (addresses && addresses.length > 0) {
        currentAddressId = addresses[0]._id;
      }
      return {
        ...state,
        currentAddressId,
      };
    case constants.RESET_CART:
      return {
        ...initialState,
        currentCart: [],
        lastCart: [],
      };
    case "RESET_APP":
      return { ...initialState };
    default:
      return state;
  }
};
export default cartReducer;
