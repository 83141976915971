// import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import '../App.css';
import { Row, Col, Typography, Avatar } from 'antd';
// import ProfileImage from "../assets/ProfileImage.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
import SaqureBlankImg from "../assets/SaqureBlankImg.jpeg";
const ProfileInformation = (props) => {
    const { UserPhoto, UserfullName, Usermobile, Useremail, location } = props;
    const { isXS, isSM } = useBreakpoints();
    return (
        <Row gutter={[{ xs: 0, sm: 0, md: 80, lg: 80, xl: 80, xxl: 80 }, 0]} className="profileInformation">
            <Col className="userprofile-imgWrap">
                {/* size={(isXS || isSM) ? 160 : 180} */}
                {UserPhoto ?
                    <Avatar shape='square' src={UserPhoto} className="profile-image" />
                    :
                    <Avatar shape='square' src={SaqureBlankImg} className="profile-image" />
                }
                {/* <Image preview={false} src={ProfileImage} className="profile-image" /> */}
            </Col>
            <Col className="userprofile-details">
                <Row justify="start" gutter={[0, 20]}>
                    {isXS && <Col span={24} />}
                    <Col>
                        <Typography className="user-name">{UserfullName}</Typography>
                    </Col>
                    {/* <Col span={14} /> */}
                </Row>

                <Row gutter={[0, 15]} className="profileLabels">
                    <Col xl={3} lg={3} md={3} sm={5} xs={5}>
                        <Typography className="label">Mobile</Typography>
                    </Col>
                    <Col xl={20} lg={20} md={20} sm={19} xs={19}>
                        <Typography className="value">+{Usermobile}</Typography>
                    </Col>
                    {/* <Col span={24}></Col> */}
                    <Col xl={3} lg={3} md={3} sm={5} xs={5}>
                        <Typography className="label">Email</Typography>
                    </Col>
                    <Col xl={20} lg={20} md={20} sm={19} xs={19}>
                        <Typography className="value">{Useremail}</Typography>
                    </Col>
                    {/* <Col span={24}></Col> */}
                    <Col xl={3} lg={3} md={3} sm={5} xs={5}>
                        <Typography className="label">Zipcode</Typography>
                    </Col>
                    <Col xl={20} lg={20} md={20} sm={19} xs={19}>
                        <Typography className="value">{location}</Typography>
                    </Col>

                </Row>
            </Col>
        </Row>

    );
};
export default ProfileInformation;


