import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Card, Typography, Divider, Image, Avatar, Pagination, Empty } from 'antd';
import { useBreakpoints } from './../react-responsive-hooks/index';
import '../App.css';
import ProfileAddressEditIcon from '../assets/ProfileAddressEditIcon.svg';
import ProfileRemoveIcon from '../assets/ProfileRemoveIcon.svg';
import ProfileSetasDefaultIcon from '../assets/ProfileSetasDefaultIcon.svg';
// import ProfileStrollers from "../assets/ProfileStrollers.svg";
import { getCurrentUserOrders } from '../Actions/orderAction';
import _ from 'lodash';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

const ProfileAllOrders = (props) => {
  const dispatch = useDispatch();
  const { isXS } = useBreakpoints();
  const { currentUserOrders, currentUser } = useSelector((state) => {
    const { currentUserOrders } = state.order;
    const { currentUser } = state.user
    return {
      currentUserOrders,
      currentUser
    };
  });

  useEffect(() => {
    dispatch(getCurrentUserOrders(currentUser._id));
  }, []);

  const perPage = 3;
  const [pageValue, setPageValue] = useState({
    min: 0,
    max: 3,
  });
  const handlePagination = (val) => {
    setPageValue({
      min: (val - 1) * perPage,
      max: val * perPage,
    });
  };
  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return null;
    }
    if (type === 'next') {
      return <a className="pagyNext">Next</a>;
    } else {
      return originalElement;
    }
  }
  return (
    <Card bordered={false} className="profileFavoriteCard">
      <Row justify="space-between" gutter={[0, { xs: 9, sm: 9, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
        <Col span={10}>
          <Typography className="profileDetail">All orders</Typography>
        </Col>
        <Col span={24}></Col>
      </Row>
      {currentUserOrders && currentUserOrders.length > 0 && (
        <Row gutter={[32, { xs: 15, sm: 15, md: 25, lg: 25, xl: 25, xxl: 25 }]} justify="space-between">
          {currentUserOrders.slice(pageValue.min, pageValue.max).map((val, i) => {
            const { order } = val || {};
            const { createdAt: OrderCreatedDate, orderNumber, orderTotalInCents } = order || {};
            const { lineItems } = val || {};
            return (
              <Col xl={24} lg={24} md={24} sm={24} xs={24} key={i}>
                <Card bordered={false} className="ordersHistory">
                  <Row className="top-block" gutter={[0, 16]} justify="space-between">
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      {val.lineItems.map((orderProductData, i) => {
                        return (
                          <>
                            <Row gutter={[20, 0]}>
                              <Col className="orderedItemImg">
                                <Avatar size={isXS ? 93 : 112} shape="square" src={orderProductData.product.imageLink} style={{ borderRadius: 10 }} />
                              </Col>
                              <Col className="orderedItemDetails">
                                <Typography className="ordersName">{orderProductData.product.title}</Typography>
                                {isXS && (
                                  <Row gutter={[0, 0]}>
                                    <Col span={24} />
                                    <Col span={24}>
                                      {/* {status && status == 'SOLD' && <Button className="shippedBtn">Shipped</Button>}
                                      {status && status == 'DELIVERED' && <Button className="shippedBtn">Delivered</Button>} */}
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            </Row>
                            {i < val.lineItems.length - 1 ? <Divider style={{ margin: "13px 0px", border: "none" }} /> : <></>}
                          </>
                        )
                      })}
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row justify="space-between">
                        <Col xs={24} justify="space-between">
                          <Row gutter={[0, 8]}
                          style={{marginTop:"40px"}}>
                            {!isXS && (
                              <Col span={24}>
                                {val.status.toUpperCase() && val.status.toUpperCase() == 'INPROGRESS' && <Button className="shippedBtntransit">In Transit</Button>}
                                {val.status.toUpperCase() && val.status.toUpperCase() == 'SHIPPED' && <Button className="shippedBtn">Shipped</Button>}
                                {val.status.toUpperCase() && val.status.toUpperCase() == 'DELIVERED' && <Button className="shippedBtn">Delivered</Button>}
                              </Col>
                            )}
                          </Row>
                          {/* <Row gutter={[0, 8]} style={{ paddingTop: '60px' }}> */}
                          {/* <Col span={24} style={{ textAlign: 'end' }}>
                                     <Button >sniipped</Button>
                                 </Col> */}
                          {/* </Row> */}
                        </Col>
                        {/* <Col span={24}>
                             </Col> */}
                        {/* <Col xl={12} lg={6} md={6} sm={24} xs={24} >
                                     <Row gutter={[0, 8]}>
                                         <Col span={24}></Col>
                                         <Col span={6}>
                                             <Typography className="detail">Color:</Typography>
                                         </Col>
                                         <Col span={18}>
                                             <Typography className="detail2">{color}</Typography>
                                         </Col>
                                         <Col span={24}></Col>
                                         <Col span={6}>
                                             <Typography className="detail">Sold to:</Typography>
                                         </Col>
                                         <Col span={18}>
                                             <Typography className="detail2">Mobile</Typography>
                                         </Col>
                                     </Row>
                                 </Col> */}
                      </Row>
                      {val.shipments.length ? 
                       <Row gutter={[8, { xs: 8, sm: 8, md: 64 }]} justify="space-between">
                         <Col xs={24}></Col>
                         <Col xs={24}>
                           <Row justify="end">
                             <Button className="button button-primary btn-trackorder">
                               {/* <a style={{color:"#FD533F"}} href={trackingUrl+'track-order'} target="/track-order" > */}
                               <a href={val.shipments[0].trackingUrl} target="_blank">
                                 Track Order
                               </a>
                             </Button>
                           </Row>
                         </Col>
                       </Row> : <></>
                      }
                    </Col>
                  </Row>
                  <Row className="bottom-block" justify="space-between">
                    <Col>
                      <Row gutter={[65, { xs: 7.5, sm: 7.5, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
                        <Col>
                          <Typography className="oDKey">Date</Typography>
                          <Row gutter={[0, 4]}>
                            <Col span={24}></Col>
                            <Typography className="oDVal">{moment(val.placedDate).format('Do MMM')}</Typography>
                          </Row>
                        </Col>
                        <Col>
                          <Typography className="oDKey">Order number</Typography>
                          <Row gutter={[0, 4]}>
                           <Col span={24}></Col>
                           <Typography.Text className="oDVal" style={{ width: '100%' }} ellipsis={{ tooltip: val._id }}>
                             #{val._id}
                           </Typography.Text>
                         </Row>
                        </Col>
                        {isXS && <Col span={24} />}
                        <Col>
                          <Typography className="oDKey">Total amount</Typography>
                          <Row gutter={[0, 4]}>
                            <Col span={24}>
                              <Typography className="oDVal">${(val.netPriceAmount.value / 100).toFixed(2)}</Typography>
                            </Col>
                          </Row>
                        </Col>
                        {isXS && (
                          <Col>
                            <Typography className="oDKey">Shipping type</Typography>
                            <Row gutter={[0, 4]}>
                              <Col span={24}></Col>
                              <Typography className="oDVal">Shipping</Typography>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    {!isXS && (
                      <Col>
                        <Typography className="oDKey">Shipping type</Typography>
                        <Row gutter={[0, 4]}>
                          <Col span={24}></Col>
                          <Typography className="oDVal">Shipping</Typography>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Card>
                {/* <Card className="profileOrders2">
                 <Row justify='center'>
                         <Col>
                             <Typography className="rIText">
                                 reNEWED Information
                             </Typography>
                         </Col>
                     </Row>
               </Card> */}
              </Col>
            )
            //  return currentUserOrders.map((val, i) => {
            //    const { imageUrls, title, color, status } = (val && val.product) || {};
            //    const { transactionId, trackingUrl } = (val && val.offer) || {};
            //    const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
            //    return (
            //      <Col xl={24} lg={24} md={24} sm={24} xs={24} key={i}>
            //        <Card bordered={false} className="ordersHistory">
            //          <Row className="top-block" gutter={[0, 16]} justify="space-between">
            //            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            //              <Row gutter={[20, 0]}>
            //                <Col className="orderedItemImg">
            //                  <Avatar size={isXS ? 93 : 112} shape="square" src={images} style={{ borderRadius: 10 }} />
            //                </Col>
            //                <Col className="orderedItemDetails">
            //                  <Typography className="ordersName">{title}</Typography>
            //                  {isXS && (
            //                    <Row gutter={[0, 0]}>
            //                      <Col span={24} />
            //                      <Col span={24}>
            //                        {status && status == 'SOLD' && <Button className="shippedBtn">Shipped</Button>}
            //                        {status && status == 'DELIVERED' && <Button className="shippedBtn">Delivered</Button>}
            //                      </Col>
            //                    </Row>
            //                  )}
            //                </Col>
            //              </Row>
            //            </Col>
            //            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            //              <Row justify="space-between">
            //                {/* <Row gutter={[0, 8]}>
            //                          <Col span={24}></Col>
            //                          <Col span={6}>
            //                              <Typography className="detail">Mobile</Typography>
            //                          </Col>
            //                          <Col span={18}>
            //                              <Typography className="detail2">Mobile</Typography>
            //                          </Col>
            //                          <Col span={24}></Col>
            //                          <Col span={6}>
            //                              <Typography className="detail">Email</Typography>
            //                          </Col>
            //                          <Col span={18}>
            //                              <Typography className="detail2">Mobile</Typography>
            //                          </Col>
            //                      </Row> */}
            //                {/* <Col xl={8} lg={6} md={6} sm={12} xs={12}> */}
            //                <Col xs={24} justify="space-between">
            //                  <Row gutter={[0, 8]}>
            //                    {!isXS && (
            //                      <Col span={24}>
            //                        {status && status == 'SOLD' && <Button className="shippedBtn">Shipped</Button>}
            //                        {status && status == 'DELIVERED' && <Button className="shippedBtn">Delivered</Button>}
            //                      </Col>
            //                    )}
            //                  </Row>
            //                  {/* <Row gutter={[0, 8]} style={{ paddingTop: '60px' }}> */}
            //                  {/* <Col span={24} style={{ textAlign: 'end' }}>
            //                              <Button >sniipped</Button>
            //                          </Col> */}
            //                  {/* </Row> */}
            //                </Col>
            //                {/* <Col span={24}>
            //                      </Col> */}
            //                {/* <Col xl={12} lg={6} md={6} sm={24} xs={24} >
            //                              <Row gutter={[0, 8]}>
            //                                  <Col span={24}></Col>
            //                                  <Col span={6}>
            //                                      <Typography className="detail">Color:</Typography>
            //                                  </Col>
            //                                  <Col span={18}>
            //                                      <Typography className="detail2">{color}</Typography>
            //                                  </Col>
            //                                  <Col span={24}></Col>
            //                                  <Col span={6}>
            //                                      <Typography className="detail">Sold to:</Typography>
            //                                  </Col>
            //                                  <Col span={18}>
            //                                      <Typography className="detail2">Mobile</Typography>
            //                                  </Col>
            //                              </Row>
            //                          </Col> */}
            //              </Row>
            //              {trackingUrl && (
            //                <Row gutter={[8, { xs: 8, sm: 8, md: 64 }]} justify="space-between">
            //                  <Col xs={24}></Col>
            //                  <Col xs={24}>
            //                    <Row justify="end">
            //                      <Button className="button button-primary btn-trackorder">
            //                        {/* <a style={{color:"#FD533F"}} href={trackingUrl+'track-order'} target="/track-order" > */}
            //                        <a href={trackingUrl} target="_blank">
            //                          Track Order
            //                        </a>
            //                      </Button>
            //                    </Row>
            //                  </Col>
            //                </Row>
            //              )}
            //            </Col>
            //          </Row>
            //          <Row className="bottom-block" justify="space-between">
            //            <Col>
            //              <Row gutter={[65, { xs: 7.5, sm: 7.5, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
            //                <Col>
            //                  <Typography className="oDKey">Date</Typography>
            //                  <Row gutter={[0, 4]}>
            //                    <Col span={24}></Col>
            //                    <Typography className="oDVal">{moment(OrderCreatedDate).format('Do MMM')}</Typography>
            //                  </Row>
            //                </Col>
            //                <Col>
            //                  <Typography className="oDKey">Order number</Typography>
            //                  <Row gutter={[0, 4]}>
            //                    <Col span={24}></Col>
            //                    <Typography.Text className="oDVal" style={{ width: '100%' }} ellipsis={{ tooltip: transactionId }}>
            //                      {/* {orderNumber} */}
            //                      #{transactionId}
            //                    </Typography.Text>
            //                  </Row>
            //                </Col>
            //                {isXS && <Col span={24} />}
            //                <Col>
            //                  <Typography className="oDKey">Total amount</Typography>
            //                  <Row gutter={[0, 4]}>
            //                    <Col span={24}></Col>
            //                    {/* <Typography className="oDVal">${val.price.value / 100}</Typography> */}
            //                  </Row>
            //                </Col>
            //                {isXS && (
            //                  <Col>
            //                    <Typography className="oDKey">Shipping type</Typography>
            //                    <Row gutter={[0, 4]}>
            //                      <Col span={24}></Col>
            //                      <Typography className="oDVal">Shipping</Typography>
            //                    </Row>
            //                  </Col>
            //                )}
            //              </Row>
            //            </Col>
            //            {!isXS && (
            //              <Col>
            //                <Typography className="oDKey">Shipping type</Typography>
            //                <Row gutter={[0, 4]}>
            //                  <Col span={24}></Col>
            //                  <Typography className="oDVal">Shipping</Typography>
            //                </Row>
            //              </Col>
            //            )}
            //          </Row>
            //        </Card>
            //        {/* <Card className="profileOrders2">
            //          <Row justify='center'>
            //                  <Col>
            //                      <Typography className="rIText">
            //                          reNEWED Information
            //                      </Typography>
            //                  </Col>
            //              </Row>
            //        </Card> */}
            //      </Col>
            //    );
            //  });
          })}

          {currentUserOrders && currentUserOrders.length > 3 && (
            <Col span={24} className="profile-pagination">
              <Row justify="end">
                <Pagination
                  total={currentUserOrders.length}
                  onChange={handlePagination}
                  defaultCurrent={1}
                  defaultPageSize={perPage}
                // style={{margin:"0px 24px"}}
                />
              </Row>
            </Col>
          )}
          {/* {currentUserOrders && currentUserOrders.length >= 18 && ( */}
          {/* <Row gutter={[0, {xs:21, sm:21, md:38,lg:38,xl:38}]} align="middle">
                      <Col span={24}/>
                      <Col>
                        <Pagination
                          size="small"
                          showSizeChanger={false}
                          total={3}
                          pageSize={3}
                        //   onChange={(value) => handlePagination(value)}
                          itemRender={itemRender}
                        />
                      </Col>
                    </Row> */}
          {/* )} */}

          {/* 
{currentUserOrders.map((val, i) => {
                 const { addresses } = val.orderAddress || {}  
                 const orderAddresses = 
                 _.uniqBy(addresses, '_id');   
                      return (
                        orderAddresses && orderAddresses.map((val, i) => {
                        const { name, address, city, state, zip } = val || {}    
                            return (
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card className="profileAddressCard" >
                        <Row gutter={[0, 16]}>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Typography className="profileAddressName">{name}</Typography>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Typography className="profile-address">
                                {name}, {address}, {city}, {state}, {zip}
                                </Typography>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                )})
                )
            })} */}
          {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card className="profileAddressCard" >
                        <Row gutter={[0, 16]}>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Typography className="profileAddressName">William G Keller</Typography>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Typography className="profile-address">
                                    William G Keller, Evergreen, Street, York
                                    town Heights, New York, 10001
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Divider />
                        </Row>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Image preview={false} src={ProfileAddressEditIcon} />
                                <Button className="profile-button">Edit</Button>
                                <Divider type="vertical" style={{ height: 20 }} />
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <Image preview={false} src={ProfileRemoveIcon} />
                                <Button className="profile-button"> Remove</Button>
                                <Divider type="vertical" style={{ height: 20 }} />
                            </Col>
                            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Image preview={false} src={ProfileSetasDefaultIcon} />
                                <Button className="profile-button">Set as Default</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col> */}
          <Col span={24}></Col>
        </Row>
      )}
      {currentUserOrders && currentUserOrders.length <= 0 && (
        <Row justify="center">
          <Col span={24}></Col>
          <Col>
            <Empty description="Your order list is empty." />
          </Col>
        </Row>
      )}
    </Card>
  );
};
export default ProfileAllOrders;
