import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Spin } from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "../App.css";
import SignIn from "../pages/SignIn";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { joinCommunity } from "../Actions/userAction";
export const HomeCommunityCard = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const { isXS } = useBreakpoints();
  const { communitiesData } = props
  const {
    imageUrls,
    title,
    description,
    _id
  } = communitiesData
  const CommunityId = _id

  const dispatch = useDispatch()
  const history = useHistory()
  const { currentUser, communities, isAuthenticated, isJoinCommunityLoading } = useSelector((state) => {
    const { currentUser, isJoinCommunityLoading } = state.user;
    const { isAuthenticated } = state.auth;
    const { communities } = currentUser || {}
    return {
      currentUser,
      isAuthenticated,
      communities,
      isJoinCommunityLoading
    };
  });
  let UserCommunityId = ""
  {
    communities &&
      communities.map((current) => {
        return (
          current === CommunityId ? UserCommunityId = current : null
        )
      })
  }
  const handleJoinCommunity = () => {
    if (isAuthenticated) {
      dispatch(joinCommunity({ id: CommunityId }));
    }
    else {
      // setShowSignInDialog(true)
      history.push('/onboarding')
    }
  }
  return (
    <div>
      <Card className='comCard'
      // cover={
      //   <Row justify='center'>

      //   </Row>
      // }
      >
        <Row justify='center'>
          <Col>
            {/* style={{ height: isXS ? 100 : 129, width: isXS ? 90 : 100 }} */}
            <div className="img-wrap">
              <img className="comCard-img" alt="" src={imageUrls} />
            </div>
            <h2 className="comName"> {title}</h2>
            <p className="comDes">  {description}</p>
            {CommunityId === UserCommunityId ?
              <Button className='button button-primary joinNowButton'>Joined</Button>
              :
              <Button className='button button-primary joinNowButton' onClick={() => handleJoinCommunity()}>Join Now</Button>
            }
            {/* <Typography
              className="comName"
            >
              {" "}
              {title}
            </Typography> */}
          </Col>
        </Row>
        {/* <Row justify='center' gutter={[0, 8]}>
          <Col span={24}></Col>
          <Col>
            <Typography className='comDes'>
              {description}
            </Typography>
          </Col>
        </Row> */}
        {/* <Row justify='center' gutter={[0, 33]}>
          <Col span={24}></Col>
          <Col>
            <Spin indicator={antIcon} spinning={isJoinCommunityLoading}>
            {CommunityId === UserCommunityId ?
              <Button className='button button-primary joinNowButton'>Joined</Button>
              :
              <Button className='button button-primary joinNowButton' onClick={() => handleJoinCommunity()}>Join Now</Button>
            }
            </Spin>
          </Col>
        </Row> */}
      </Card>

      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </div>

  );
};
