import React from "react";
import { Row, Col, Typography, Card, Button, Tabs, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { setFilterCondition } from "../Actions/brandsAction";
import { useDispatch, useSelector } from "react-redux";
import { useGaEvent } from "../hooks/useGaEvent";

const ShopNewItems = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { gaPageView, gaEventClick } = useGaEvent();
  return (
    <div className="shopNewItemsWrapper">
      <Row className="shopNewItems app-wrapper">
        <Col
          className="blockItem shopNewItemsBlock"
          onClick={() => {
            gaEventClick("Browse", "Shop New Items");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition("new"));
          }}
        >
          <h2>Shop new with tag</h2>
        </Col>
        <Col
          className="blockItem gentlyItemsBlock"
          onClick={() => {
            gaEventClick("Browse", "Shop gently used");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition("used"));
          }}
        >
          <h2>Shop gently used</h2>
        </Col>
      </Row>
    </div>
  );
};

export default ShopNewItems;
