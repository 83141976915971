import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginSignup } from "../Actions/authAction";
import { getProductType } from "../Actions/productType";
import {
  setNavShow,
  setNavList,
  setNavColors,
  setCurrentNavSelected,
} from "../Actions/brandsAction";
import debounce from "lodash/debounce";
import { useGaEvent } from "../hooks/useGaEvent";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import algoliasearch from "algoliasearch";
// import { Autocomplete } from "./auto-complete";
import { ProductItem } from "./product-item";
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Alert,
  Modal,
  Menu,
  Dropdown,
  Input,
  Badge,
  AutoComplete,
  Divider,
} from "antd";
import _ from "lodash";
import ShippingLogo from "../assets/Slice.svg";
import Logo from "../assets/logo.svg";
import LocationPin from "../assets/LocationPin.svg";
import ClockIcon from "../assets/clock.svg";
import UsertIcon from "../assets/UsertIcon.svg";
import CartIcon from "../assets/new-assets/cart-icon.svg";
import searchIcon from "../assets/searchIcon.svg";
import hamburgerIcon from "../assets/hamburgerNew.png";
import MessageIcon from "../assets/MessageIcon.svg";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PMenu from "../components/PMenu";
import * as yup from "yup";
import Icon, {
  SearchOutlined,
  DownOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import "../styles/landing.css";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import NavBottomRow from "../components/NavBottomRow";
import NavProfileMenu from "../components/NavProfileMenu";
import NavMenuMob from "../components/NavMenuMob";
import {
  getAllLots,
  getAllmarkidProducts,
  getSearchLot,
} from "../Actions/lotsAction";
import { Autocomplete } from "./AutoCompleteSearch";

const Navbar = ({ categoryRef, markidlogo }) => {
  const appId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
  const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
  const searchClient = algoliasearch(appId, apiKey);
  const {
    currentUser,
    providerToken,
    currentCart,
    allLots,
    searchedLots,
    recentSearches,
    messages,
    popularSearches,
    isAuthenticated,
    allChats,
    categoriesGroup,
    brandsGroup,
    currentLocationZip,
    navShow,
    navList,
    navcolors,
    currnav,
    productTypeDetails,
  } = useSelector((state) => {
    const { productTypeDetails } = state.productTypeDetails;
    const { allLots, searchedLots } = state.lots;
    const { providerToken, isAuthenticated } = state.auth;
    const { currentUser, currentLocationZip } = state.user;
    const { currentCart } = state.cart;
    const { allChats } = state.chats;
    const { navShow, navList, navcolors, currnav } = state.brands;
    const { messagesByChatId } = state.chats;
    const { messages } = messagesByChatId;
    const { recentSearches, popularSearches } = state.searches;
    const { categoriesGroup, brandsGroup } = state.groups;
    return {
      currentUser,
      providerToken,
      currentCart,
      allLots,
      searchedLots,
      recentSearches,
      popularSearches,
      isAuthenticated,
      messages,
      allChats,
      currentLocationZip,
      categoriesGroup,
      brandsGroup,
      navShow,
      navList,
      navcolors,
      currnav,
      productTypeDetails,
    };
  });

  const dispatch = useDispatch();
  const { gaPageView, gaEventClick } = useGaEvent();
  const params = useParams();
  const { search: urlSearch } = params;
  const location = useLocation();
  const refreshParams = new URLSearchParams(location.search);

  const [search, setSearch] = useState(
    urlSearch && urlSearch.length > 0 ? urlSearch : ""
  );
  const [modal, setModal] = useState(false);
  const [zipCode, setZipCode] = useState(
    currentLocationZip ? currentLocationZip : "10100"
  );
  const [openSearch, setOpenSearch] = useState(false);
  const { Search } = Input;
  const [collapsed, setCollapsed] = useState(false);
  const { Text } = Typography;
  const toggleCollapsed = () => {
    setOpenSearch(false);
    setCollapsed(!collapsed);
  };
  // if(!localStorage.getItem('markidlogo'))
  // {
  //   localStorage.setItem("markidlogo", Logo);
  // }

  const { fullName, photo, zipCode: UserZipCode } = currentUser || {};
  const userImgName = fullName ? fullName.charAt(0) : null;

  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();

  const [navlist, setNavlist] = useState(navList);
  const [currentNav, setCurrentNav] = useState(currnav);
  const [colorNav, setColorNav] = useState({ bg: "#FDF5F4", text: "#21cad3" });

  const history = useHistory();

  const handleOnChangeInAutoComplete = (e, d) => {
    setModal(false);

    if (e !== "888" && e !== "999") {
      if (d.name) {
        setSearch(d.name);
        history.push(`/product-list/${d.name}/${d._id}`);
      } else {
        setSearch(e);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    dispatch({ type: "REMOVE_TOKEN" });
    dispatch({ type: "RESET_APP" });
    // history.push("/homepage");
    history.push("/");
  };

  const updatedDesing = (items) => {
    //Search Suggestion function
    // return items.map((item) => {
    //   return { ...item, label: <Row gutter={[6, 8]}><Col>{item.brand} </Col><Col style={{ fontWeight: 'bold' }}>{item.label}</Col></Row> }
    // })
  };

  const onDropdownVisibleChange = (test) => {
    // if (!test && search.length > 0) {
    //   history.push(`/search-new/${search}?refresh=true`)
    // }
  };

  const renderItem = (title, key) => {
    return {
      value: key,
      label: title,
    };
  };

  const renderTitle = (icon, title, showClear) => (
    <Row justify="space-between">
      <Col>
        <Row gutter={[12, 0]}>
          <Col>{icon}</Col>
          <Col>{title}</Col>
        </Row>
      </Col>
      {/* <Col>
        {showClear &&
          <a
            className='clearAll'
            //  href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clear all
          </a>
        }
      </Col> */}
    </Row>
  );

  const options =
    recentSearches && recentSearches.length > 0
      ? [
          {
            label: renderTitle(
              <img alt="" src={ClockIcon} />,
              <Typography className="reviewedSellerName">
                Recent Searches
              </Typography>,
              true
            ),
            options: [
              renderItem(
                <Row gutter={[15, 15]} key="1">
                  {recentSearches.map((val, i) => {
                    return (
                      <Col key={i}>
                        <Button
                          size="small"
                          className="searchesTabsButton"
                          onClick={() => handleReacentPopular(val.searchQuery)}
                        >
                          {val.searchQuery}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>,
                "888"
              ),
            ],
          },
          // <Divider />,
          // {
          //   label: renderTitle(<img alt="" src={TrendingUpIcon} />, <Typography className='reviewedSellerName'>Popular Searches</Typography>, false),
          //   options: [renderItem(
          //     <Row gutter={[15, 15]} key='2'>
          //       {popularSearches.map((val, i) => {
          //         return (
          //           <Col key={i}>
          //             <Button size='small' className='searchesTabsButton' onClick={() => handleReacentPopular(val.searchQuery)}>
          //               {val.searchQuery}
          //             </Button>
          //           </Col>
          //         )
          //       })}
          //     </Row>, '999'
          //   )],
          // },
        ]
      : [];

  const handleProfile = (id) => {
    gaEventClick("Navigation", "Profile");
    if (id != undefined) {
      history.push(`/profile/${id}`);

      document.body.scrollTo({ top: 700, behavior: "smooth" });
    } else {
      history.push(`/profile`);
    }
  };

  const handleSearch = (data) => {
    dispatch(getAllmarkidProducts({ search: data }));
    //dispatch(getAllLots({ search: data }))
  };

  const handleOnSelect = (value, option) => {
    setSearch(option.label);
    if (history.location.pathname.includes("/product/")) {
      const path = {
        pathname: `/products/${option.label}/${value}?refresh=true`,
        state: { pId: value },
      };
      history.push(path);
    } else {
      history.push(`/product-list/${option.name}/${value}?refresh=true`);
      dispatch({ type: "RESET_SEARCH" });
    }
  };
  const handleReacentPopular = (searchQuery) => {
    history.push(`/search-new/${searchQuery}?refresh=true&zipCode=${zipCode}`);
    setModal(false);
  };

  const [redirect, setRedirect] = useState(false);
  const redirectCheck = (id, obj) => {
    dispatch({ type: "CURRENT_PLP_ITEM", payload: { item: obj } });
    dispatch(
      getProductType(id, successRedirectCallBack, failureRedirectCallBack)
    );
  };
  const successRedirectCallBack = () => {
    setRedirect(true);
  };
  const failureRedirectCallBack = () => {
    setRedirect(false);
  };
  useEffect(() => {
    if (redirect) {
      {
        productTypeDetails.productType != "partnerToPerson"
          ? history.push(`/product-list/${productTypeDetails.offerId}`)
          : history.push(
              `/partner-product/${productTypeDetails.title}/${productTypeDetails.offerId}`
            );
      }
    }
  }, [productTypeDetails, redirect]);

  const onKeyPressVisibleChange = (query) => {
    // dispatch(setNavShow(false));
    // setModal(false);
    // if (
    //   (test.code === "Enter" || test.key === "Enter") &&
    //   modal === true &&
    //   search.length > 0
    // ) {
    //   gaEventClick("Navigation", "Search", search);
    //   setOpenSearch(false);
    //   history.push(`/search-new/${search}?refresh=true&zipCode=${zipCode}`);
    //   localStorage.removeItem("Sizes");
    //   localStorage.removeItem("Brands");
    //   localStorage.removeItem("Selected-Brands");
    //   localStorage.removeItem("Selected-Genders");
    //   localStorage.removeItem("Selected-YearofPurchase");
    //   localStorage.removeItem("Selected-Condition");
    //   localStorage.removeItem("Selected-Shipping");
    //   localStorage.removeItem("Selected-Size");
    //   localStorage.removeItem("Selected-Max-Price");
    //   localStorage.removeItem("Active-Filter");
    //   localStorage.removeItem("Selected-Price-Range");
    //   setModal(false);
    // } else if (
    //   (test.code === "Enter" || test.key === "Enter") &&
    //   modal === false
    // )
    gaEventClick("Navigation", "Search", query.state.query);
    setOpenSearch(false);
    history.push(
      `/search-new/${query.state.query}?refresh=true&zipCode=${zipCode}`
    );
    localStorage.removeItem("Sizes");
    localStorage.removeItem("Sizes");
    localStorage.removeItem("Brands");
    localStorage.removeItem("Selected-Brands");
    localStorage.removeItem("Selected-Genders");
    localStorage.removeItem("Selected-YearofPurchase");
    localStorage.removeItem("Selected-Condition");
    localStorage.removeItem("Selected-Shipping");
    localStorage.removeItem("Selected-Size");
    localStorage.removeItem("Selected-Max-Price");
    localStorage.removeItem("Active-Filter");
    localStorage.removeItem("Selected-Price-Range");
  };

  useEffect(() => {
    if (currentNav === "learn") {
      // const tempStore = [
      //   { display: "How We Work", value: "How We Work", id: 1 },
      //   { display: "Blog", value: "Blog", id: 2 },
      // ];

      setNavlist([]);
      dispatch(setNavList([]));
      setColorNav({ bg: "#FFFDF8", text: "#F2A000" });
      dispatch(setNavColors({ bg: "#FFFDF8", text: "#F2A000" }));
    }
    if (currentNav === "shop") {
      const tempStore = categoriesGroup.groupType.map((obj) => {
        return {
          display: obj.displayValue.replace("and", "&"),
          value: obj.category,
          id: obj._id,
        };
      });

      setNavlist(tempStore);
      dispatch(setNavList(tempStore));
      setColorNav({ bg: "#ECF8F8", text: "#21CAD3" });
      dispatch(setNavColors({ bg: "#ECF8F8", text: "#21CAD3" }));
    }
    if (currentNav === "brands") {
      const tempStore = brandsGroup.groupType.map((obj) => {
        return { display: obj.brand, value: obj.brand, id: obj._id };
      });

      setNavlist(tempStore);
      dispatch(setNavList(tempStore));
      setColorNav({ bg: "#ECF8F8", text: "#21CAD3" });
      dispatch(setNavColors({ bg: "#ECF8F8", text: "#21CAD3" }));
    }
    if (currentNav === "sell") {
      setColorNav({ bg: "#FEFAED", text: "#15A8FB" });
      dispatch(setNavColors({ bg: "#FEFAED", text: "#15A8FB" }));
    }
  }, [currentNav]);

  return (
    <>
      {(isXL || isLG || isXXL) && (
        <div>
          <Row className="firstNavRow">
            <Col className="firstRowNavItems">
              <img
                alt="Markid Home"
                style={{ padding: " 5px 5px" }}
                src={markidlogo}
                onClick={() => {
                  history.push("/");
                  setCurrentNav("shop");
                  dispatch(setCurrentNavSelected("shop"));
                  dispatch(setNavShow(true));
                }}
              />
            </Col>
            <Col
              className="firstRowNavItems"
              style={
                currentNav === "shop"
                  ? { color: colorNav.text, background: colorNav.bg }
                  : { color: "#393939", background: "#fff" }
              }
              onClick={() => {
                setCurrentNav("shop");
                dispatch(setCurrentNavSelected("shop"));
                dispatch(setNavShow(true));
              }}
            >
              Shop
            </Col>
            <Col
              className="firstRowNavItems"
              style={
                currentNav === "brands"
                  ? { color: colorNav.text, background: colorNav.bg }
                  : { color: "#393939", background: "#fff" }
              }
              onClick={() => {
                setCurrentNav("brands");
                dispatch(setCurrentNavSelected("brands"));
                dispatch(setNavShow(true));
              }}
            >
              Brands
            </Col>
            <Col
              className="firstRowNavItems"
              style={
                currentNav === "learn"
                  ? { color: colorNav.text, background: colorNav.bg }
                  : { color: "#393939", background: "#fff" }
              }
              onClick={() => {
                gaEventClick("Navigation", "Blog");
                dispatch(setCurrentNavSelected("learn"));
                setCurrentNav("learn");
                history.push(`/learn/blog`);
                dispatch(setNavShow(false));
                sessionStorage.setItem("emailpop",true)
              }}
            >
              <div
                style={
                  currentNav === "learn"
                    ? { color: colorNav.text, background: colorNav.bg }
                    : { color: "#393939", background: "#fff" }
                }
              >
                Blog
              </div>
            </Col>
            <Col
              className="firstRowNavItems"
              style={
                currentNav === "sell"
                  ? { color: colorNav.text, background: colorNav.bg }
                  : { color: "#393939", background: "#fff" }
              }
              onClick={() => {
                gaEventClick("Navigation", "Sell");
                if (providerToken) {
                  history.push("/seller/addproduct");
                  dispatch(setCurrentNavSelected("sell"));
                  setCurrentNav("sell");
                  dispatch(setNavShow(false));
                } else {
                  history.push("/onboarding");
                  dispatch(setNavShow(false));
                }
              }}
            >
              Sell
            </Col>

            <Col
              lg={{ span: 7, offset: 0 }}
              xl={{ span: 7, offset: 0 }}
              xxl={{ span: 7, offset: 0 }}
              className="firstRowNavItems"
              style={{ margin: "auto 20px" }}
            >
              {/* <AutoComplete
              
                className="inputBorder"
                onSearch={debounce(handleSearch, 500)}
                onSelect={(v) => (search.length > 0 ? handleOnSelect : { v })}
                onChange={(e, d) => {
                  handleOnChangeInAutoComplete(e, d)
                }}
                onDropdownVisibleChange={onDropdownVisibleChange}
                value={search}
                options={
                  search.length > 0 ? updatedDesing(searchedLots) : options
                }
                // size='large'
                style={{ width: "118%", position: "relative" }}
              >
                <div className="inputParent">
                  <input
                    id="inputEx"
                    type="search"
                    onKeyDown={(x) => onKeyPressVisibleChange(x)}
                    placeholder="search for clothes, costumes,shoes etc..."
                    className="searchHtmlInput"
                    autoComplete="off"
                    // autoFocus
                  />
                  <SearchOutlined className="searchOutlined" />
                </div>
              </AutoComplete> */}
              <Autocomplete
                onSubmit={(query) => {
                  onKeyPressVisibleChange(query);
                }}
                placeholder="Search clothes, costumes, shoes, etc..."
                openOnFocus={true}
                debug={true}
              />
              {/* <Autocomplete
                onSubmit={(query) => {
                  onKeyPressVisibleChange(query);
                }}
                placeholder="Search clothes, costumes, shoes, etc..."
                openOnFocus={true}
                onStateChange = {(state) => {
                  console.log("XXXX",state.state.isOpen)
                  if(state.state.isOpen){
                    //dispatch({ type: "SHOW_PROMO_RIBBON", payload: {val: false} })
                  }
                  else{
                    //dispatch({ type: "SHOW_PROMO_RIBBON", payload: {val: true} })
                  }
                  
                }}
                getSources={({ query }) => [
                  {
                    sourceId: "products",

                    getItems() {
                      return getAlgoliaResults({
                        searchClient,
                        queries: [
                          {
                            indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
                            query,
                            params: {
                              filters:
                                "isActive:true AND isSold:false AND (deliveryType:shipping OR deliveryType:both)",
                            },
                          },
                        ],
                      });
                    },
                    templates: {
                      item({ item, components }) {
                        return (
                          <ProductItem
                            onclick={() => {
                              redirectCheck(item.objectID, item);
                            }}
                            hit={item}
                            components={components}
                          />
                        );
                      },
                    },
                  },
                ]}
              /> */}
            </Col>

            {providerToken && (
              <Col className="firstRowNavItems">
                <Badge count={allChats.unseenCount}>
                  <img
                    alt="Messages"
                    src={MessageIcon}
                    onClick={() => history.push("/chat")}
                  />
                </Badge>
              </Col>
            )}
            <Col className="firstRowNavItems">
              <Badge count={currentCart.length}>
                <img
                  alt="Shopping Cart"
                  src={CartIcon}
                  onClick={() => {
                    gaEventClick("Navigation", "Cart");
                    history.push("/cart");
                  }}
                />
              </Badge>
            </Col>
            <Col className="firstRowNavItems">
              {providerToken && providerToken ? (
                <Row gutter={[8, 0]} justify="center">
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {photo ? (
                      <Avatar
                        //  style={{ backgroundColor: "#93CFCB" }}
                        // size={30}
                        src={photo}
                      />
                    ) : (
                      <Avatar
                        className="text-capitalize"
                        style={{
                          backgroundColor: "#FD533F",
                          fontWeight: 600,
                        }}
                        // size={30}
                      >
                        {userImgName}
                      </Avatar>
                    )}
                    <NavProfileMenu
                      style={{
                        position: "absolute",
                        top: "110px",
                        right: "20px",
                      }}
                      currentUser={currentUser}
                      fullName={fullName}
                      handleProfile={handleProfile}
                    />
                  </Col>
                </Row>
              ) : (
                <Row align="middle" justify="center">
                  <Col>
                    <img
                      alt="User Account"
                      src={UsertIcon}
                      onClick={() => {
                        gaEventClick("Navigation", "Onboarding");
                        if (!providerToken) {
                          history.push("/onboarding");
                        }
                      }}
                      // onClick={() => setShowSignInDialog(true)}
                      // style={{ width: 17 }}
                    />{" "}
                  </Col>
                </Row>
              )}
            </Col>
            {!providerToken && (
              <>
                <Col
                  className="firstRowNavItems"
                  style={{ fontWeight: 500, color: "#FD533F" }}
                  onClick={() => {
                    gaEventClick("Navigation", "Login");
                    dispatch(setLoginSignup("login"));
                    history.push("/onboarding");

                    dispatch(setNavShow(false));
                  }}
                >
                  Log in
                </Col>
                <Col
                  className="firstRowNavItems"
                  style={{ fontWeight: 700, color: "#FD533F" }}
                  onClick={() => {
                    gaEventClick("Navigation", "Signup");
                    dispatch(setLoginSignup("signup"));
                    history.push("/onboarding");
                    dispatch(setNavShow(false));
                  }}
                >
                  Signup
                </Col>
              </>
            )}
          </Row>
          {/* && currentNav && currentNav !== "sell" && navlist.length > 0*/}
          <div
            className={currentNav === "brands" ? "brandsNav" : ""}
            style={
              colorNav
                ? { backgroundColor: colorNav.bg }
                : { background: "#ccc" }
            }
          >
            {navShow && navList?.length > 0 && (
              <NavBottomRow
                currentNav={currnav}
                setCurrentNav={setCurrentNav}
                colorNav={navcolors}
                navlist={navList}
              />
            )}
          </div>
        </div>
      )}

      {(isXS || isSM || isMD) && (
        <Row className="firstNavRowMob">
          <Col
            xs={2}
            sm={2}
            md={2}
            className="firstRowNavItemsMob"
            onClick={() => toggleCollapsed()}
          >
            <img alt="Menu" src={hamburgerIcon} />
          </Col>
          <Col xs={3} sm={3} md={2} className="firstRowNavItemsMob">
            <Badge count={currentCart.length}>
              <img
                alt="Shopping Cart"
                src={CartIcon}
                onClick={() => {
                  gaEventClick("Navigation", "Cart");
                  setCollapsed(false);
                  setOpenSearch(false);
                  history.push("/cart");
                }}
              />
            </Badge>
          </Col>

          <Col xs={12} sm={12} md={16} className="firstRowNavItemsMob">
            <img
              alt="Markid Home"
              style={{ padding: "5px 5px" }}
              src={markidlogo}
              onClick={() => {
                setCollapsed(false);
                setOpenSearch(false);
                history.push("/");
              }}
            />
          </Col>

          <Col xs={2} sm={2} md={2} className="firstRowNavItemsMob">
            {/* <img
              alt="Search"
              src={searchIcon}
              onClick={() => {
                setCollapsed(false);
                // setOpenSearch(true);
              }}
            /> */}
          </Col>
          {providerToken ? (
            <Col
              xs={3}
              sm={3}
              md={2}
              className="firstRowNavItemsMob"
              onClick={() => {
                gaEventClick("Navigation", "Profile");
                setOpenSearch(false);
                setCollapsed(false);
                history.push("/profile/1");
              }}
            >
              {photo ? (
                <Avatar
                  //  style={{ backgroundColor: "#93CFCB" }}
                  // size={30}
                  src={photo}
                />
              ) : (
                <Avatar
                  className="text-capitalize"
                  style={{
                    backgroundColor: "#FD533F",
                    fontWeight: 600,
                  }}
                  // size={30}
                >
                  {userImgName}
                </Avatar>
              )}
            </Col>
          ) : (
            <Col
              xs={4}
              sm={3}
              md={2}
              className="firstRowNavItemsMob"
              style={{
                fontWeight: 500,
                color: "#727383",
                fontSize: "16px",
                fontFamily: "Montserrat",
              }}
              onClick={() => {
                gaEventClick("NavbarLoginMob", "Login");
                dispatch(setLoginSignup("login"));
                history.push("/onboarding");

                dispatch(setNavShow(false));
              }}
            >
              Log in
            </Col>
          )}
        </Row>
      )}
      {/* {(isXS || isSM || isMD)  && (
        <div 
        style={{ margin: "20px 10px" }}  
        >
          <AutoComplete
            className="inputBorder"
            onSearch={debounce(handleSearch, 500)}
            onSelect={(v) => (search.length > 0 ? handleOnSelect : { v })}
            onChange={(e, d) => handleOnChangeInAutoComplete(e, d)}
            onDropdownVisibleChange={onDropdownVisibleChange}
            value={search}
            options={search.length > 0 ? updatedDesing(searchedLots) : options}
            // size='large'
            style={{ width: "100%", position: "relative" }}
          >
            <div className="inputParent">
              <Input
                // style={{ borderBottom: "1px solid grey", background: "#ccc" }}
                id="inputEx"
                type="search"
                onKeyDown={(x) => onKeyPressVisibleChange(x)}
                placeholder="Search Markid..."
                className="searchHtmlmobInput"
                autoComplete="off"
                // autoFocus
              />
              <SearchOutlined className="searchOutlined" />
            </div>
          </AutoComplete>
        </div>
      )} */}
      {(isXS || isSM || isMD) && collapsed && (
        <NavMenuMob
          toggleCollapsed={toggleCollapsed}
          collapsed={collapsed}
          categoriesGroup={categoriesGroup}
          brandsGroup={brandsGroup}
          photo={photo}
          userImgName={userImgName}
          fullName={fullName}
          setCollapsed={setCollapsed}
          providerToken={providerToken}
          setCurrentNav={setCurrentNav}
        />
      )}
    </>
  );
};

export default Navbar;
