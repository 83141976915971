import React, { useState, useEffect } from "react";
import { Card, Typography, Row, Col, Button, Spin, Input, Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getEmailUpdates } from "../Actions/authAction";
import { useGaEvent } from "../hooks/useGaEvent";
import { getBrandlisting } from "../Actions/brandsAction";

const MarkNewsLetter = () => {
  const dispatch = useDispatch();
  const { gaEventClick } = useGaEvent();
  const [emailInput, setemailInput] = useState("");
  const handleUpdates = () => {
    const dataInput = {
      email: emailInput,
    };
    if (dataInput != null) {
      dispatch(getEmailUpdates(dataInput));
    }
  };
  

  return (
    <div className="MarkidNewsletterWrapper">
      <div className="MarkidNewsletter app-wrapper">
        <Typography className="sectionTitles title">Don’t miss out!</Typography>
        <Typography className="description">
          Sign up for our newsletter to keep updated about new products, tips &
          exclusive offers. We promise not to spam :)
        </Typography>

        <div className="inputWrapper">
          <Input.Group compact>
            <Input
              className="input"
              placeholder="Email"
              onInput={(e) => {
                setemailInput(e.target.value);
              }}
              onKeyDown={(e) => e.key === "Enter" && handleUpdates()}
            />
            <Tooltip title="Receive Updates">
              <Button
                className="btnSubmit"
                onClick={() => {
                  gaEventClick("SUBSCRIBE", "Dontmissout")
                  handleUpdates();
                }}
                type="dashed"
                icon={
                  <ArrowRightOutlined
                    style={{ fontSize: "16px", color: "#393939" }}
                  />
                }
              />
            </Tooltip>
            {/* <Button type="primary" className='btn-submit'>Submit</Button> */}
          </Input.Group>
        </div>
      </div>
    </div>
  );
};

export default MarkNewsLetter;
