import { Button, Row, Col, Typography, Card, Modal } from "antd";
import React, { useState } from "react";

import promoWeb from "../assets/new-assets/partnerpopup1.png";
import promoMob from "../assets/new-assets/mwebpromo.png";

import promoClose from "../assets/new-assets/close-icon.svg";
const RibbonTop = ({close,setClose}) => {
  
    const onClose = () => {
      sessionStorage.setItem("pop",false)
        setClose("false");
      };


  return (
    <>
    {close=="true" && ( <div className="pFItemWeb" class="fixed-content">
        <div class="Promocontainer">
          <img src={promoWeb} alt="Snow" className="partnerpopupimg" />
          
          <div className="Promocentered" style={{width: "420px"}}>
              <Button className="promoClose"  onClick={onClose}><img src={promoClose}/></Button>
            {/* 20% off on your first Swoondle purchase from Markid{" "} */}
          </div>
          {/* <div class="promoText2">Automatically applied at checkout</div> */}
        </div>

      </div>)}
     {close=="true" && (  <div className="pFItemMob">
      <div class="Promocontainer" className="fixed-content-Mob">
          <img src={promoMob} alt="Snow" />
          
          <div className="PromocenteredMob">
            20% off your first Swoondle purchase from Markid{" "}<Button class="promoClose" onClick={onClose}><img src={promoClose}/></Button>
          </div>
          <div class="promoText2Mob">Automatically applied at checkout</div>
        </div>

    
      </div>)}
    
    </>
  );
};
export default RibbonTop;
