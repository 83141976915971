import {
    createAutocomplete,
  } from '@algolia/autocomplete-core';
  import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
  import { Hit } from '@algolia/client-search';
  import algoliasearch from 'algoliasearch/lite';
  import { useHistory } from "react-router-dom";
  import { getProductType } from "../Actions/productType";
  import { useDispatch, useSelector } from "react-redux";
  import React, { useEffect, useState } from "react";
  
  import { ClearIcon } from '../vectors/ClearIcon';
  import { Highlight } from '../vectors/Highlight';
  import { SearchIcon } from '../vectors/SearchIcon';
import { ProductItem } from './product-item';
  
  const appId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
  const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
  const searchClient = algoliasearch(
    appId,
    apiKey
  );


 

  
  export function Autocomplete(props) {
    const [autocompleteState, setAutocompleteState] = React.useState({
      collections: [],
      completion: null,
      context: {},
      isOpen: false,
      query: '',
      activeItemId: null,
      status: 'idle',
    });
    const autocomplete = React.useMemo(() =>
        createAutocomplete({
          onStateChange({ state }) {
            setAutocompleteState(state);
          },
          getSources() {
            return [
              {
                sourceId: 'products',
                getItems({ query }) {
                  return getAlgoliaResults({
                    searchClient,
                    queries: [
                      {
                        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
                        query,
                        params: {
                            filters:
                              "isActive:true AND isSold:false AND (deliveryType:shipping OR deliveryType:both)",
                          },
                        },
                    ],
                  });
                },
                getItemUrl({ item }) {
                  return item.url;
                },
              },
            ];
          },
          ...props,
        }),
      [props]
    );
    const inputRef = React.useRef(null);
    const formRef = React.useRef(null);
    const panelRef = React.useRef(null);
    const { getEnvironmentProps } = autocomplete;

    const dispatch = useDispatch();
    const history = useHistory();
    const {
        productTypeDetails,
      } = useSelector((state) => {
        const { productTypeDetails } = state.productTypeDetails;
        return {
          productTypeDetails,
        };
      });
  
    React.useEffect(() => {
      if (!formRef.current || !panelRef.current || !inputRef.current) {
        return undefined;
      }
  
      const { onTouchStart, onTouchMove } = getEnvironmentProps({
        formElement: formRef.current,
        inputElement: inputRef.current,
        panelElement: panelRef.current,
      });
  
      window.addEventListener('touchstart', onTouchStart);
      window.addEventListener('touchmove', onTouchMove);
  
      return () => {
        window.removeEventListener('touchstart', onTouchStart);
        window.removeEventListener('touchmove', onTouchMove);
      };
    }, [getEnvironmentProps, formRef, inputRef, panelRef]);


    const [redirect, setRedirect] = useState(false);
    const redirectCheck = (id, obj) => {
        dispatch({ type: "CURRENT_PLP_ITEM", payload: { item: obj } });
        dispatch(
          getProductType(id, successRedirectCallBack, failureRedirectCallBack)
        );
      };
      const successRedirectCallBack = () => {
        setRedirect(true);
      };
      const failureRedirectCallBack = () => {
        setRedirect(false);
      };
      useEffect(() => {
        if (redirect) {
          {
            productTypeDetails.productType != "partnerToPerson"
              ? history.push(`/product-list/${productTypeDetails.offerId}`)
              : history.push(
                  `/partner-product/${productTypeDetails.title}/${productTypeDetails.offerId}`
                );
          }
        }
      }, [productTypeDetails, redirect]);
  
    return (
      <div className="aa-Autocomplete" {...autocomplete.getRootProps({})}>
        <form
          ref={formRef}
          className="aa-Form"
          {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        >
          <div className="aa-InputWrapperPrefix">
            <label className="aa-Label" {...autocomplete.getLabelProps({})}>
              <button className="aa-SubmitButton" type="submit" title="Submit">
                <SearchIcon />
              </button>
            </label>
          </div>
          <div className="aa-InputWrapper">
            <input
              className="aa-Input"
              ref={inputRef}
              {...autocomplete.getInputProps({ inputElement: inputRef.current })}
              onBlur={() => setAutocompleteState({...autocompleteState, isOpen: false})}
            />
          </div>
          <div className="aa-InputWrapperSuffix">
            <button className="aa-ClearButton" title="Clear" type="reset">
              <ClearIcon />
            </button>
          </div>
        </form>
  
        {autocompleteState.isOpen && (
          <div
            ref={panelRef}
            className={[
              'aa-Panel',
              'aa-Panel--desktop',
              autocompleteState.status === 'stalled' && 'aa-Panel--stalled',
            ]
              .filter(Boolean)
              .join(' ')}
            {...autocomplete.getPanelProps({})}
          >
            <div className="aa-PanelLayout aa-Panel--scrollable">
              {autocompleteState.collections.map((collection, index) => {
                const { source, items } = collection;
  
                return (
                  <section key={`source-${index}`} className="aa-Source">
                    {items.length > 0 && (
                      <ul className="aa-List" {...autocomplete.getListProps()}>
                        {items.map((item) => {
                          return (
                            <li
                              key={item.objectID}
                              className="aa-Item"
                              {...autocomplete.getItemProps({ item, source })}
                            >
                                <ProductItem
                                    onclick={() => {
                                    redirectCheck(item.objectID, item);
                                    }}
                                    hit={item}
                                    // components={components}
                                />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </section>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
  