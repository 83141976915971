import React from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "antd";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
//import { setBrandName } from "../Actions/brandsAction";

export const PLBreadCrumb = (props) => {
 
const history = useHistory();
const dispatch = useDispatch();
const { markid, category, brand, name, routeId,  routeName} = props
  return (
       <Breadcrumb>

            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            // onClick={() => history.push('/homepage')}
            onClick={() => { 
              history.push('/');}}
            >
              {markid}
              </Breadcrumb.Item>
    
            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            onClick={() => { history.push(`/product-listing/category/${routeName}/${routeId}`)}}
            >
              {category}
              </Breadcrumb.Item>
    
            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            >
              {brand}
              </Breadcrumb.Item>
    
            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            >
              {name}
            </Breadcrumb.Item>
          </Breadcrumb>
  );
};