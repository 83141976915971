/*eslint-disable*/
import React from "react";
import {
  Row,
  Col,
  Layout,
  Typography,
  Card,
  Button,
  Space,
} from "antd";
import { useSelector, useDispatch } from "react-redux";

import { ArrowLeftOutlined } from "@ant-design/icons";
import FeedTopbar from "../components/FeedTopbar";
import CategoryHeader from "../components/CategoryHeader";
import  MarkidCheckout from "../components/MarkidCheckout";
import CatrItems from "../components/CartItems";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import _ from "lodash";
const { Text, Title } = Typography;
const { Header, Content, Footer } = Layout;
const MarkidCart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const {currentCart} = useSelector((state) => {
    const { currentCart } = state.cart;
    return {
      currentCart,
    };
  });
  return (
		<>
			<FeedTopbar />

			{/* <CategoryHeader /> */}

			<Row gutter={[0,16]}>
				<Col span={24}></Col>
        <Col offset={2}>
          <Space direction='horizontal'>
        <ArrowLeftOutlined className='back-icon' onClick={() => history.goBack()}/>
        <Row gutter={[0,8]}>
          <Col span={24}></Col>
          <Col>
					<Title level={4} className='your-cart-title'>
						Your cart ({currentCart.length} {currentCart .length > 1 ? 'items' : 'item'})
					</Title>
          </Col>
          </Row>
          </Space>
				</Col>
			</Row>

      <Row justify="center">
        <Col xl={13} lg={14} md={11} sm={24} xs={24}>
          <Card bordered={false}>
              <CatrItems />
          </Card>
        </Col>
        <Col xl={8} lg={8} md={11} sm={24} xs={24}>
          <Card className='total-item-chekout-card' bordered={false}>
           <MarkidCheckout 
           heading="Total"
           itemTotal="Items total"
           itemTotalVal={(_.sumBy(currentCart, function(o) { return o.price; }) / 100).toFixed(2)}         
           shipping="Shipping"
           shippingVal="Free"
           salesTax="Sales Tax"
           salesTaxVal={(0).toFixed(2)}
           cartTotal="Cart Total"
           cartTotalVal="199.61"
           button={ <Button
            block
            disabled={currentCart.length === 0}
            className={'squarebutton'}
            onClick={() =>history.push('/shipping')}
            // onClick={() => handleCreateCart()}
          >
            CHECKOUT
          </Button>}
           />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default MarkidCart;
