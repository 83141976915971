import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col } from "antd";
// import { useHistory } from "react-router-dom";
import "../App.css";
import FullHeart from "../assets/Favorite.svg";
export const RelatedItem = (props) => {
const { cover, itemName, price, shipping } = props
  return (
    <div className="pLRelatedParent">
         <Card className='pLSimilarCard' style={{marginBottom: 5}}
            cover={cover}
            >
          {/* <img src={images} alt="sample" className="item-image" onClick={() => history.push(`/products/${name}/${_id}`)} /> */}
          {/* <div className="itemButtonSold">
            {inSoldPending ? (
              <div className="sold-button-div">
                <Button type="text" className="sold-button">
                  SOLD
                </Button>
              </div>
            ) :
              <div className="sold-button-div">
              </div>}
          </div> */}

          <div className="pLitemButtonLike">
            <div>
              {/* {inUserFavorites ? ( */}
                <img
                  src={FullHeart}
                  alt=""
                //   onClick={() => handleRemoveFromFavorite()}
                />
              {/* ) : (
                <img
                  src={EmptyHeart}
                  alt=""
                //   onClick={() => handleAddToFavorite()}
                />
              )} */}
            </div>
          </div>


          <div 
        //   onClick={() => history.push(`/products/${name}/${_id}`)} 
        //   className="category-details"
          >
            <Typography
              className="pLSCardTitle"
            //   style={{ paddingBottom: brand ? 0 : 20 }}
            >
              {" "}
              {itemName}
              {/* {capitalize(name)} */}
            </Typography>
            {/* <Typography className="brand-name"> {capitalize(brand)}</Typography> */}
            {/* <Typography className="brand-name">{category}</Typography> */}
            <Row gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col>
                <Row gutter={[0, 0]}>
                  <Col>
                    <Typography className="pLSPrice">
                        ${price}
                        {/* ${price / 100} */}
                        </Typography>
                  </Col>
                </Row>
              </Col>
              {/* <Col>
                {rating}
                <Row justify="end">
                  <Typography>{toatalReviews}</Typography>
                </Row>
              </Col> */}
            </Row>
            <Row gutter={[0, 8]} justify='end'>
              <Col span={24}></Col>
              {/* <Col span={12}>
                <Typography className="renewed-text">reNEWED</Typography>
              </Col> */}
              <Col span={12}>
                <Typography className="pLSShipping">
                  {/* {deliveryType === "both" ? ( */}
                    <Typography>{shipping}</Typography>
                  {/* ) : (
                    capitalize(deliveryType)
                  )} */}
                </Typography>
              </Col>
            </Row>
          </div>
        </Card>
        </div>
  );
};
