import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
const BuyerHub = (props) => {
  const { Panel } = Collapse;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Markid - Buyer Hub</title>
      </Helmet>
      <div class="container" id="f-container" className="contentwrap">
        <div class="Faq_content_wrapper__2zvae  col-lg-8 col-sm-12">
          <span class="">
            <h1 id="buyer-hub">BUYER HUB</h1>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Getting started — Personalize your experience on Markid!
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      While setting up your profile, put in details like your
                      little one’s age and gender, categories of goodies you’re
                      looking for, as well as your brand preferences. Once
                      you’ve set this up, you’ll find an inventory of items
                      tailored especially for your needs!
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Is buying on markid safe?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      Please refer{" "}
                      <a href="#buyer-protection">Buyer Protection</a> section.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Finding the best product for your kid.
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <ul>
                      <li>
                        The simplest way to shop is by using relevant keywords
                        in the search bar from the “Explore” tab. 🔍
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>‘HANDPICKED’</strong> items are based on
                        personalized details you’ve provided while setting up
                        your profile. <strong>‘JUST ADDED’</strong> items are
                        those that have been newly listed by sellers. You’ll
                        also find listings that are closely associated with
                        items you have searched for recently or in the past
                        under{" "}
                        <strong>
                          ‘BASED ON SEARCH HISTORY’. ‘ITEMS NEAR YOU’
                        </strong>{" "}
                        will show you listings made by sellers close to your own
                        location. 🏷️
                      </li>
                    </ul>
                    <ul>
                      <li>
                        If the item you’re searching for isn’t yet listed on
                        Markid, you’ll receive suggestions for equivalent or
                        related items. ✔️
                      </li>
                    </ul>
                    <ul>
                      <li>
                        For best results, we recommend applying filters such as
                        gender, size, condition, price range, shipping type,
                        brands, and location. ☑️
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Buying is as easy as A, B, C!
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <ul>
                      <li>
                        If you know what you’re looking for, you can use the
                        search bar to search using specific keywords and
                        hashtags. #️⃣
                      </li>
                    </ul>
                    <ul>
                      <li>
                        If you would like to browse for what’s available, start
                        with the Shop tab to find recently-listed local items.
                        📜
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Select the neighborhoods you want to search across or
                        try broadening your search to include items available to
                        be shipped. 📍 Found something you like but not ready to
                        make an offer yet? Make sure to ‘favorite’ it so that
                        you can come back and check it out later. ❤️
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Really like items from a certain seller? Start following
                        them by clicking the Follow button on their profile so
                        that you can see their listings on your “Following” feed
                        and be notified when they post a new item. 👌
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    I just made an offer. What happens next?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      When you make an offer, we send a notification to the
                      seller to confirm the item’s availability. The seller has
                      24 hours to accept, counter, or decline your offer. Offers
                      are binding so be sure you are ready to pay the amount you
                      offer!
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>

            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    My offer was accepted by the seller. What happens next?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      Once an offer is mutually agreed upon, you have 24 hours
                      to make the payment for your purchase. Depending on what
                      option the seller has chosen, one of the two delivery
                      options will take place:
                    </p>
                    <p>
                      <strong>Local Pick Up</strong> 🤝
                    </p>
                    <ul>
                      <li>
                        If the seller has selected local pick up as the delivery
                        option, you and the seller will use the “Chat” icon on
                        the item listing to find a time and place to meet. 💬
                      </li>
                    </ul>
                    <ul>
                      <li>
                        When you meet with your seller and receive the item,
                        inspect it before accepting it. If you go home with the
                        item without inspecting it, we will not be able to help
                        you with any disputes afterward. 🔍
                      </li>
                    </ul>
                    <ul>
                      <li>
                        If you’re satisfied with the order, make sure to provide
                        the 4 digit payment pin to the seller. You can find this
                        in your messages tab under your seller’s name . 👍
                      </li>
                    </ul>
                    <ul>
                      <li>
                        To ensure that the seller delivers the item as
                        described, we do not release the payment to the seller
                        until the buyer confirms receipt of the item. ✔️
                      </li>
                    </ul>
                    <p>
                      <strong>SHIPPING</strong> 🚚
                    </p>
                    <ul>
                      <li>
                        We will send you a confirmation email regarding your
                        order. 📧
                      </li>
                    </ul>
                    <ul>
                      <li>
                        You will receive another email from us with the tracking
                        information once the seller has shipped the item. 📭
                      </li>
                    </ul>
                    <ul>
                      <li>
                        When the item arrives, you have 48 hours to confirm that
                        your purchase matches the description in the listing. If
                        there are any issues reported after the 48-hour window,
                        we won’t be able to guarantee a return.
                      </li>
                    </ul>
                    <p>
                      <strong>MEET-UP TIPS</strong> 🙂
                    </p>
                    <ul>
                      <li>
                        When coordinating a meet-up, we strongly recommend
                        picking a public location to meet up. 🗺️
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Keep your conversations within the app. Do not put any
                        personal info in the message, including your address,
                        email, phone numbers, account number, and any other
                        sensitive details. ❌
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Be friendly, punctual, and polite when communicating
                        with the seller. 😇
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Can I cancel my offer?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      You may cancel an offer before the seller accepts it.
                      However, all offers are binding once the seller accepts
                      your offer. When the seller accepts an offer, the item
                      will be immediately marked as SOLD. Please make sure
                      you’re certain that you like an item before you make an
                      offer.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>

            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How do I report an issue with a purchased item?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      All deliveries carry a Markid promise to buyers and
                      therefore we’ll provide our complete support to resolve
                      delivery issues. You can reach out to us with any problems
                      at <a href="support@markid.io">support@markid.io</a>.
                    </p>
                    <ul>
                      <li>
                        <p>
                          When an item is delivered through Markid, you have 48
                          hours from the time you receive the item to verify
                          that its condition matches the description in its
                          listing.
                        </p>
                      </li>
                      <li>
                        <p>
                          If there are any issues (damage is not mentioned in
                          the listing, or the item is not as described, or an
                          item is missing) please let us know immediately and we
                          will gladly assist you in resolving the situation. 💬
                        </p>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        If there are discrepancies, report it to us and we will
                        initiate a review. Both buyers and sellers may be
                        required to provide additional information to assist us
                        with the review. 📝
                      </li>
                    </ul>
                    <ul>
                      <li>
                        If the item is found to be misrepresented in the
                        listing, i.e. it does not match the listing, or an item
                        is missing, we will cancel the transaction and initiate
                        a refund to the buyer. The seller will be responsible
                        for the return shipping of the item. 🚫
                      </li>
                    </ul>
                    <ul>
                      <li>
                        The buyer will receive a prepaid shipping label to start
                        the return process. The buyer will have to ensure that
                        the item is packed and shipped back to the seller within
                        3 business days, in the same condition as it was
                        received. 🚚
                        <a name="buyer-protection"></a>
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>

            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={<Typography className="faqheading">Buyer protection on markid</Typography>}
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <h5 id="how-buyer-protection-works">
                      How buyer protection works
                    </h5>
                    <p>
                      When an item is <strong>paid for and shipped</strong>{" "}
                      through Markid, the buyer may be protected if certain
                      problems occur. Buyer protection covers up to the original
                      purchase price of the item for eligible issues, as
                      described in this document.
                    </p>
                    <h5 id="before-you-file-a-claim">
                      Before you file a claim
                    </h5>
                    <p>
                      <strong>First</strong>, contact the seller to ask for a
                      refund. You have <strong>two days</strong> to do this
                      after the item arrives. You can always ask the seller
                      questions in chat, let them know the request is coming, or
                      send them photos. You'll need to tap{" "}
                      <strong>“How's the item”</strong> and then tap{" "}
                      <strong>“Contact seller”</strong> to request a refund. If
                      you don't get the answer you're hoping for, you have{" "}
                      <strong>two more days</strong> to let us know. If you
                      bought an item via nationwide shipping, you and the seller
                      can sort out many issues yourselves. If that doesn't work
                      and you request a refund through Markid, we'll keep track
                      of your request and the response, and help you out if you
                      can't reach a conclusion.
                      <strong>Let the seller</strong> know if you didn't get
                      what you expected. They can{" "}
                      <strong>respond to your request</strong> through Markid.
                    </p>
                    <h5 id="what-is-covered">What is covered</h5>
                    <p>
                      The following scenarios are covered when an item is bought
                      and shipped through Markid:
                    </p>
                    <ul>
                      <li>There was undisclosed damage to the item</li>
                      <li>An item is incorrect or missing</li>
                      <li>The item received is not as described</li>
                      <li>
                        The buyer's payment is held by markid until the
                        transaction is complete. The transaction is considered
                        complete if no buyer protection claim is made within a
                        2-day period after item delivery. At that point, the
                        payment is released to the seller.
                      </li>
                      <li>
                        Once the payment has been released to the seller, all
                        sales are final and no refunds will be issued.
                      </li>
                    </ul>
                    <h5 id="what-isnt-covered">What isn't covered</h5>
                    <ul>
                      <li>An item doesn't fit or the buyer doesn't like it</li>
                      <li>An item is prohibited by markid</li>
                      <li>
                        Any purchase that was made in cash or with an in-person
                        cashless payment
                      </li>
                      <li>
                        The purchase was conducted through payment or shipping
                        systems outside of Markid
                      </li>
                    </ul>
                    <h5 id="how-to-use-buyer-protection">
                      How to use buyer protection
                    </h5>
                    <ol>
                      <li>
                        Within two days of receiving the item,{" "}
                        <strong>let the seller know</strong> if you didn't get
                        what you expected. They can respond to your request
                        through Markid.
                      </li>
                      <li>
                        If they decline or do not answer, Contact Us at{" "}
                        <a href="support@markid.io">support@markid.io</a>.{" "}
                      </li>
                      <li>
                        Markid will review the request and take corresponding
                        action. Markid may request additional information or
                        documentation, and reserves the right to deny buyer
                        requests.
                      </li>
                      <li>
                        On approval, markid will provide return instructions,
                        and the buyer will handle the item accordingly.
                      </li>
                      <li>
                        Markid will issue a refund up to the original price of
                        the item, sent to the original form of payment.
                      </li>
                    </ol>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
          </span>
        </div>
      </div>
    </>
  );
};

export default BuyerHub;
