import React, { useEffect, useMemo, useState } from "react";
import HeroArticleBlog from "../components/HeroArticleBlog";
import { Row, Col, Spin, Input, Select, AutoComplete, Tag } from "antd";
import Allblogposts from "../components/Allblogposts";
import { getAllBlogs, getAllTags } from "../Actions/BlogsAction";
import { useDispatch, useSelector } from "react-redux";
import bookmark from "../assets/new-assets/tabBookmark.png";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import BlogResultNotFound from "../components/SearchResultNotFound/BlogResultNotFound";
import { useParams } from "react-router-dom";
import { getBlogsimages } from "../Actions/BlogsAction";
import Emailpopup from "../components/Emailpopup";
import { getBlogBookmarks } from "../Actions/BlogsAction";
const { Search } = Input;
const MainBlogPage = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { Blogs, Blogtags, isBlogsLoading, Blogimages, Blogbookmarks, providerToken} =
    useSelector((state) => {
      const { providerToken } = state.auth;
      const { Blogs, Blogtags, isBlogsLoading, Blogimages, Blogbookmarks } =
        state.Blogs;
      return {
        Blogs,
        isBlogsLoading,
        Blogtags,
        Blogimages,
        Blogbookmarks,
        providerToken
      };
    });

    const [imagesArray, setImagesArray] = useState([])
  const handleSearch = (data) => {
    let flag = 0;
    let test = Blogtags.forEach((val, i) => {
      if (
        val.name.toLowerCase().replace(" ", "") ===
        data.toLowerCase().replace(" ", "")
      ) {
        flag = 1;
        dispatch(getAllBlogs({ search: val.id }));
      }
    });
    if (flag == 0) {
      dispatch(getAllBlogs({ search: data }));
    }
  };
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(getAllBlogs());
    dispatch(getAllTags());
  }, []);
  useEffect(() => {
    if (Blogs.length <= 0) {
      dispatch(getAllBlogs({ search: id }));
    }
  }, [id]);
  const [imagearray,setimagearray]=useState([])
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => {
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    setActiveTab("tab2");
  };
  const modifiedBlogs = useMemo(() => {
    let test = Blogs.map((eachPost, index) => {
      let array = [];
      eachPost.tags.forEach((id, i) => {
        Blogtags.find((eachTag) => {
          if (eachTag.id === id) {
            array.push({ name: eachTag.name });
            Blogs[index].newTags = array;
          }
        });
      });
      return {
        ...eachPost,
        array,
      };
    });
    return test;
  }, [Blogs, Blogtags]);

  const options = Blogtags.map((data) => {
    return {
      value: data.name,
    };
  });

  useEffect(() => {
    const imagedatanew = Blogs.map((val,i)=>{
      dispatch(getBlogsimages(val.id));
      // imagearray.push(Blogimages)
    })
    
    if (localStorage.getItem("access_token") != null) {
      dispatch(getBlogBookmarks());
    }

  }, [Blogs]);

  useEffect(()=> {
    setImagesArray((prevImage)=> ([...prevImage, ...Blogimages]))
  }, [Blogimages, ])

  const [close, setClose] = useState(
    sessionStorage.getItem("emailpop") || "true"
  );

  return (
    <>
    <Emailpopup close={close} setClose={setClose}/>
      <div className="BlogPageContainer">
   
        <div className="BlogTop">

        <h2>Blog</h2>
              <div className="searchBarTag">
                <AutoComplete
                  className="searchTagwidth"
                  options={options}
                  placeholder="Search topics or select tags..."
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={handleSearch}
                ></AutoComplete>
                 <SearchOutlined className="searchOutlined" />
              </div>
              
          {/* <ul>
            <li>
              <h2>Blog</h2>
              <div span="10" className="searchBarTag">
                <AutoComplete
                  className="searchTagwidth"
                  options={options}
                  placeholder="Search topics or select tags..."
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={handleSearch}
                ></AutoComplete>
                 <SearchOutlined className="searchOutlined" />
              </div>
            </li>
          </ul> */}
        </div>
        <span>
          <ul className="Bloglistingcategory">
            <span
              className={
                activeTab === "tab1" ? "BlogTabActive" : "Blogtabinactive"
              }
              onClick={handleTab1}
            >
              All
            </span>
            {providerToken &&(
            <span
              className={
                activeTab === "tab2" ? "BlogTabActive" : "Blogtabinactive"
              }
              onClick={handleTab2}
            >
              <img src={bookmark} className="tabBookmarkimg"/>
              My Favorites
            </span>)}
          </ul>
        </span>
        <div className="Blogparent">
          {Blogs && Blogs.length <= 0 && (
            <BlogResultNotFound Blogtags={Blogtags} />
          )}
          <Spin
            style={{ top: 200, color: "#FF533F" }}
            indicator={antIcon}
            spinning={isBlogsLoading}
          >
            {activeTab === "tab1" ? (
              <div className="Blogalignment">
                <Row className="BlogFirstrow">
                  {Blogs && Blogs.length >= 1 && (
                    <>
                        <Col className="firstRow"
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 16, offset: 0 }}
                          lg={{ span: 16, offset: 0 }}
                          xl={{ span: 16, offset: 0 }}
                          style={{ marginBottom: "0px" }}
                        >
                          <HeroArticleBlog
                            Blogs={Blogs[0]}
                            modifiedBlogs={modifiedBlogs}
                            Blogimages={
                              imagesArray.find(
                              (image) => image?.post === Blogs[0].id
                            )
                          }
                            Blogbookmark={Blogbookmarks}
                          />
                        </Col>
                        {Blogs && Blogs.length >= 1 && (
                          <Col className="blogTop_rightItem"
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 8, offset: 0 }}
                            lg={{ span: 8, offset: 0 }}
                            xl={{ span: 8, offset: 0 }}
                            style={{
                              position: "relative",
                              marginBottom: "0px",
                            }}
                          >
                            {modifiedBlogs &&
                              modifiedBlogs.map((val, i) => {
                                {
                                  if (i == 1)
                                    return (
                                      <Row
                                        justify="space-around"
                                        gutter={[
                                          {
                                            xs: 0,
                                            sm: 0,
                                            md: 30,
                                            lg: 30,
                                            xl: 30,
                                          },
                                          {
                                            xs: 15,
                                            sm: 15,
                                            md: 45,
                                            lg: 45,
                                            xl: 45,
                                          },
                                        ]}
                                      >
                                        <Col key={i}>
                                          <Allblogposts
                                            Blogs={val}
                                            key={i}
                                            modifiedBlogs={modifiedBlogs}
                                            Blogimages={
                                              imagesArray.find(
                                              (image) => image?.post === val?.id
                                            )
                                          }
                                            Blogbookmark={Blogbookmarks}
                                          />
                                        </Col>
                                      </Row>
                                    );
                                }
                              })}
                          </Col>
                        )}
                    </>
                  )}
                </Row>
                {modifiedBlogs && modifiedBlogs.length >= 2 && (
                    <div className="masonry " >
                    {/* <div className="item"> */}
                    {/* <Row
                      className="blogItem"
                      gutter={[
                        {
                          xs: 35,
                          sm: 35,
                          md: 30,
                          lg: 30,
                          xl: 30,
                        },
                        {
                          xs: 0,
                          sm: 0,
                          md: 20,
                          lg: 20,
                          xl: 20,
                        },
                      ]}
                    > */}
                      {modifiedBlogs &&
                        modifiedBlogs.map((val, i) => {

                          {
                            
                            if (i > 1)
                              return (
                                // <Col  xl={ 8 }
                                // lg={8 }
                                // md={ 8 }
                                // sm={ 24 }
                                // xs={24 }>
                                <div className="masonryAlign">
                                <Allblogposts
                                  modifiedBlogs={modifiedBlogs}
                                  Blogs={val}
                                  key={i}
                                  Blogimages={
                                    imagesArray.find(
                                    (image) => image?.post === val?.id
                                  )
                                }
                                  Blogbookmark={Blogbookmarks}
                                /></div>
                                // </Col>
                              );
                          }
                        })}
                    {/* </Row> */}
                    {/* </div> */}
                  </div>
                )}
              </div>
            ) : (
              modifiedBlogs &&
              modifiedBlogs.length >= 2 && (
                <div className="masonry" >
                  {/* <div className="item"> */}
                  {/* <Row
                    className="blogItem"
                    justify="space-around"
                    gutter={[
                      {
                        xs: 35,
                        sm: 35,
                        md: 30,
                        lg: 30,
                        xl: 30,
                      },
                      {
                        xs: 35,
                        sm: 35,
                        md: 45,
                        lg: 45,
                        xl: 45,
                      },
                    ]}
                  > */}
                    {modifiedBlogs &&
                      modifiedBlogs.map((val, i) => {
                        {

                          if (Blogbookmarks?.bookmarks?.includes(val.id))
                         
                            return (
                              // <Col  xl={ 8 }
                              //   lg={8 }
                              //   md={ 8 }
                              //   sm={ 24 }
                              //   xs={24 }>
                              <div  className="masonryAlign">
                              <Allblogposts
                                modifiedBlogs={modifiedBlogs}
                                Blogs={val}
                                key={i}
                                Blogimages={
                                  imagesArray.find(
                                  (image) => image?.post === val?.id
                                )
                              }
                              
                                Blogbookmark={Blogbookmarks}
                              /></div>
                              // </Col>
                            );
                        
                          
                        }
                      })}
                  {/* </Row> */}
                  {/* </div> */}
                </div>
              )
            )}
          </Spin>
        </div>
      </div>
    </>
  );
};
export default MainBlogPage;