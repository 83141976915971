import React from "react";
import { Row, Col, Typography, Card } from "antd";
const { Meta } = Card;
const CommunityCard = (props) => {
  const { cover, title, descriptions, members, button } = props;
  return (
        <Card cover={cover}>
          <Row justify='start'>
              <Col>
              <Meta
                title={title}
                description={descriptions}
                style={{ textAlign: "start" }}
              />
              </Col>
              </Row>

              <Row gutter={[27, 32]} justify='space-between'>
                <Col span={24}></Col>
                <Col span={24}></Col>
                <Col>
                  <Typography>
                      {members}
                      </Typography>
                </Col>
                <Col style={{marginTop: '-5px'}}>
                    {button}
                    </Col>
              </Row>
            
        </Card>
  );
};

export default CommunityCard;
