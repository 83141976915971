import React, {useState} from "react";
import { Button, Row, Col, Card, Typography, Collapse } from "antd";
import logo from "../assets/logo.svg";
import {ItemCard} from "../components/ItemCard";
const { Title, Text } = Typography;
const CustomiseExperience = (props) => {
    const [selectedCategory, setselectedCategory] = useState({});
    const [selectedBrand, setselectedBrand] = useState({});
    const { Panel } = Collapse;
    function callback(key) {
      // console.log(key);
    }
    const onCardClick = (selected) => {
        const { type} = selected;
        switch (type) {
            case "catagory":
                // code block
                setselectedCategory(selected);
                break;
          case "brand":
            setselectedBrand(selected);
            break;
          default:
          // code block
        }
      };
  return (
    <>
    <Row justify='center'>
    <Col xl={{span:14, offset: 2 }} lg={{span:14, offset: 2 }} md={{span:16, offset: 2 }} sm={22} xs={22}>
            <Card>
      <Row gutter={[48, 16]}>
        <Col span={24}></Col>
        <Col xl={6} lg={6} md={6} sm={24} xs={24}>
          <img
            alt="logo"
            src={logo}
            // preview={false}
          />
        </Col>
        <Col span={24}></Col>
        <Col span={24}></Col>
      </Row>

      <Row justify="center" gutter={(0, 48)}>
        <Col span={24}></Col>
        <Col>
          <Title level={2}>
              Let us customise your experience
          </Title>
        </Col>
      </Row>
      <Row justify='center' gutter={[16, 8]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col span={24}></Col>
        {/* <Col xl={9} lg={9} md={9} sm={24} xs={24}></Col> */}
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["1"]}
            collapsible='header'
            onChange={callback}
            expandIconPosition="right"
            bordered={false}
            >
            <Panel
              showArrow={true}
              header={
                <Row justify='start' align='middle'>
                  
                  <Text>Pick your favourite brands</Text>
                  
                </Row>
              }
              key="1"
            >
                <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Oeuf"
                  id={{ type: "brand", i: 1 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Stokke"
                  id={{ type: "brand", i: 2 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Citi Mini"
                  id={{ type: "brand", i: 3 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Citi Select"
                  id={{ type: "brand", i: 4 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Uppababy"
                  id={{ type: "brand", i: 5 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Disney"
                  id={{ type: "brand", i: 6 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Fisherprice"
                  id={{ type: "brand", i: 7 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Joovy"
                  id={{ type: "brand", i: 8 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Cap Kids"
                  id={{ type: "brand", i: 9 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                <Button
                style={{color: 'black'}}
                 type='link'
                >
                    SEE MORE
                </Button>
              </Col>
              <Col span={24}></Col>
            </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row gutter={[16, 8]} justify='center'>
        <Col span={24}></Col>
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            onChange={callback}
            expandIconPosition="right"
            bordered={false}
          >
            <Panel
              showArrow={true}
              header={
                <Row justify="start" align='middle'>
                <Text>Pick your preferred categories ddd</Text>
              </Row>
              }
              key="2"
            >
                <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 1 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Strollers</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 2 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Loungers</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 3 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Furniture</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 4 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Toys</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 5 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Bouncers</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 6 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Books</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 7 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Clothes</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 8 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Shoes</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 9 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>carriers</Text>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                <Button
                style={{color: 'black'}}
                 type='link'
                >
                    SEE MORE
                </Button>
              </Col>
              <Col span={24}></Col>
            </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>


        <Row justify="center" gutter={[0, 24]}>
          <Col span={24}></Col>
          <Col>
            <Button
              className={'squarebutton'}
            >
              CONTINUE
            </Button>
          </Col>
          <Col span={24}></Col>
        </Row>
        </Card>
        </Col>
        <Col xl={8} lg={8} md={8} sm={22} xs={22}>
            <Card style={{ backgroundColor: "#d8d8d8", height: 331 }} />
          </Col>
          </Row>
    </>
  );
};
export default CustomiseExperience;
