/*eslint-disable*/
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Avatar,
  Collapse,
  Divider,
  Card,
  Input,
  List,
  Rate,
  Space,
  Button,
  Image,
  Skeleton,
  Spin,
  Tooltip,
} from "antd";
import EmptyHeart from "../assets/UnFavorite.svg";
import FullHeart from "../assets/Favorite.svg";
import DummyPLImg from "../assets/DummyPLImg.svg";
import securepayvector from "../assets/securepay.svg";
import protect from "../assets/protection.svg";
import visa from "../assets/Mastercardlogo.png";
import visa1 from "../assets/VisaLogo.png";
import AmericanEx from "../assets/AmericanExpress.png";
import stripe from "../assets/Stripelogo.png";
import securePay from "../assets/securepay.png";
import NotifiedIcon from "../assets/NotifiedIcon.svg";
import File1 from "../assets/File1.JPG";
import TrueVector from "../assets/verifiedvectorpdp.png";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import { useForm, Controller } from "react-hook-form";
import "../App.css";
import {
  addToFavorites,
  removeFromFavorites,
  getLotById,
} from "../Actions/lotsAction";
import { setLoginSignup } from "../Actions/authAction";
import { LoadingOutlined } from "@ant-design/icons";
import { ProductImage } from "../components/ProductImages";
import SignIn from "../pages/SignIn";
import { MessageSeller } from "../components/MessageSeller";
import { NotifySoon } from "../components/NotifySoon";
import { MakeOffer } from "../components/MakeOffer";
import moment from "moment";
import PLNotify from "../assets/PLNotify.svg";
import SellerImg from "../assets/SellerImg.svg";
import Badge1 from "../assets/Badge1.svg";
import Badge2 from "../assets/Badge2.svg";
import Badge3 from "../assets/Badge3.svg";
import Badge4 from "../assets/Badge4.svg";
import {
  followUser,
  unFollowUser,
  getUser,
  notifyItem,
  unNotifyItem,
} from "../Actions/userAction";
import { getMarkidProductById } from "../Actions/partnerProductAction";
import SkeletonButton from "antd/lib/skeleton/Button";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import {
  getCurrentUserWallet,
  getLastCart,
  addToCart,
  saveToLocalStorage
} from "../Actions/cartAction";
import { getChatsWithSeller } from "../Actions/chatsAction";
import { getSellerBadges } from "../Actions/userAction";
import { useBreakpoints } from './../react-responsive-hooks/index';
const { Text, Title } = Typography;
import toolTip from "../assets/new-assets/tooltipnew.png";
import { useGaEvent } from "../hooks/useGaEvent";

const PLDescription = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isXS, isSM } = useBreakpoints();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { productData, productLoader, sellerInfo } = props;
  const {
    imageUrls,
    description,
    brand,
    name,
    gender,
    createdAt,
    color,
    age,
    isFree,
    inSoldPending,
    inUserFavorites,
    _id,
    price,
    condition,
    category,
    deliveryType,
    isActive,
    yearOfPurchase,
    costPrice,
    negotiablePrice,
    location,
    size,
    sellerAddress,
    favoritesCount,
    createdBy,
    isRenewed,
  } = productData;
  const {
    _id: sellerId,
    fullName,
    photo,
    activeLotsCount,
    soldLotsCount,
    createdAt: SellerCreatedAt,
  } = sellerInfo || {};
  const Listed = moment(createdAt).format("MMMM YYYY");
  const { Panel } = Collapse;
  const [selectedProductImg, setselectedProductImg] = useState("");
  const [showMakeOfferDialog, setShowMakeOfferDialog] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  const [showNotifySoonDialog, setShowNotifySoonDialog] = useState(false);
  const {
    isAuthenticated,
    providerToken,
    currentCart,
    currentUser,
    currentCartIds,
    allLots,
    isFollowUserLoading,
    isUnFollowUserLoading,
    isNotifyItemLoading,
    isUnNotifyItemLoading,
    isGetChatsWithSellerLoading,
    sellerBadges,
    productTypeDetails,
    currentPLPItem
  } = useSelector((state) => {
    const { isAuthenticated, providerToken } = state.auth;
    const { currentCart, currentCartIds, currentPLPItem } = state.cart;
    const { productTypeDetails } = state.productTypeDetails
    const { allLots } = state.lots;
    const {
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      sellerBadges,
    } = state.user;
    const { isGetChatsWithSellerLoading } = state.chats;
    return {
      isAuthenticated,
      providerToken,
      currentCart,
      currentUser,
      sellerBadges,
      allLots,
      isFollowUserLoading,
      isUnFollowUserLoading,
      productTypeDetails,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      isGetChatsWithSellerLoading,
      currentCartIds,
      currentPLPItem
    };
  });
  const { gaPageView, gaEventClick } = useGaEvent();
  const { fastResponder, fastShipper, top10 } = sellerBadges || {};
  const meetupType = deliveryType === "meetup";
  const { following, notifyItems, _id: currentUserId } = currentUser || {};
  const { lots } = allLots || { lots: [] };
  let ProductId = _id;
  let CartId = "";
  if(isAuthenticated)
  {
    currentCart &&
      currentCart.map((current) => {
        current._id === ProductId ? (CartId = current._id) : null;
      });
  }
  else
  {
    ProductId = currentPLPItem._id?currentPLPItem._id:currentPLPItem.objectID;
    currentCartIds.forEach((item)=>{
      if(item === ProductId )
        CartId=  item;
    } );
  }
  let NotifyItemId = "";
  {
    notifyItems &&
      notifyItems.map((current) => {
        current === ProductId ? (NotifyItemId = current) : null;
      });
  }

  //  const {isActive}=productTypeDetails

  const forSellerUser = createdBy === currentUserId;
  function callback(key) {
    // console.log(key);
  }
  // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const images =
    imageUrls && imageUrls.length > 0
      ? `https://images.markid.io/fit?width=600&height=500&quality=50&type=webp&url=${imageUrls[0]}`
      : null;

  // const { register, handleSubmit, errors, control } = useForm({});
  const ManufactureVal = yearOfPurchase ? yearOfPurchase.substring(0, 4) : null;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const onImgClick = (selected) => {
    setselectedProductImg(selected);
  };



  const handleAddItemCart = (item) => {
    // item.productType = "P2P";
    gaEventClick("PDP", "Add to Cart", item?.name);
    
    const lotsArr = [...currentCartIds];
    if (productTypeDetails) {
    if(productTypeDetails.productType==="personToPerson")
    {
      lotsArr.push(productTypeDetails._id);
    }
    else
    {
      lotsArr.push(item.markidProductId);
    }

    handleCreateCart(lotsArr, item);
  }
  else{
    if(item.markidProductId)
    {
      dispatch(getMarkidProductById(
        item.markidProductId,
        successCallBackPDP,
        failureCallBackPDP
      ));
    }
  }

  };

  const successCallBackPDP = () => {
    //console.log(productDetails);
    const lotsArr = [...currentCartIds];

   
    lotsArr.push(productData.markidProductId);
    if (isAuthenticated) {
      handleCreateCart(lotsArr, productData);
    }
    else{
      dispatch(setLoginSignup("signup"));
      history.push("/onboarding");
    }
    
  };

  const failureCallBackPDP = () => {
    //dispatch(getLastCart())
    //history.push("/cart");
  };

  const handleCreateCart = (lotsArr, item) => {
    const data = {};
    data.cartId = currentUser.cartId;
    data.lots = lotsArr;
    data.addressId = null// currentUser.addresses[0]._id? currentUser.addresses[0]._id: null;
    data.email = currentUser.email;
    if(!currentCartIds?.includes(item.objectID) && !currentCartIds?.includes(item.markidProductId) && !currentCartIds?.includes(currentPLPItem._id) )
    {
      if(isAuthenticated)
      {
        dispatch(addToCart(data, isAuthenticated, successCallBackCart, failureCallBack))
      }
      else{
        item.objectID= item.markidProductId
        dispatch(saveToLocalStorage(currentPLPItem));
      }
      
    }
   

    
  };
  const successCallBackCart = () => {
    dispatch(getLastCart(currentUser._id))
    //history.push("/cart");
  };


  const handleAddToFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(addToFavorites({ id: _id }, successCallBack, failureCallBack));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleRemoveFromFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(
        removeFromFavorites({ id: _id }, successCallBack, failureCallBack)
      );
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const successCallBack = () => {
    dispatch(getLotById(_id));
  };
  const failureCallBack = () => {};
  // const handleAddItemCart = (item) => {
  //   gaEventClick("PDP", "Add to Cart", item.title ? item.title : item.name);
  //   item.productType = "P2P";
  //   dispatch({ type: "ADD_ITEM_CART", payload: { item: item } });
  //   // history.push("/markid-cart")
  //   history.push("/cart");
  // };
  const handleNotifyItem = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Get Notified", ProductId);
      dispatch(
        notifyItem(
          { id: ProductId },
          successCallBackNotifyMe,
          failureCallBackNotifyMe
        )
      );
    } else {
      // setShowSignInDialog(true)
      gaEventClick("Navigation", "Get Notified", "signup");
      history.push("/onboarding");
    }
  };
 
  const handleUnNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(unNotifyItem({ id: ProductId }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const successCallBackNotifyMe = () => {
    setShowNotifySoonDialog(true);
  };
  const failureCallBackNotifyMe = () => {};
  const handleFollowUser = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Follow", createdBy);
      dispatch(followUser({ id: createdBy }));
    } else {
      gaEventClick("Navigation", "Follow", "signup");
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleUnFollowUser = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Unfollow", createdBy);
      dispatch(unFollowUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      gaEventClick("Navigation", "Unfollow", "signup");
      history.push("/onboarding");
    }
  };
  const handleOpenMakeAnOffer = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Make an offer");
      setShowMakeOfferDialog(true);
      // history.push(`/messages/`+ProductId)
    } else {
      gaEventClick("Navigation", "Make an offer", "signup");
      history.push("/onboarding");
    }
  };
  const handleMessageSeller = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Message Seller");
      dispatch(
        getChatsWithSeller(
          _id,
          sellerId,
          messageSellerSuccess,
          messageSellerFailure
        )
      );
      // history.push(`/messages`)
    } else {
      gaEventClick("Navigation", "Message Seller", "SignUp");
      history.push("/onboarding");
    }
  };
  const messageSellerSuccess = () => {
    history.push("/chat");
  };
  const messageSellerFailure = () => {};
 
  return (
    <>
      <Row justify="center">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {
            productLoader ? (
              <Col span={24}>
                <Row justify={"center"} align="middle">
                  <Spin indicator={antIcon} spinning={productLoader} />
                </Row>
              </Col>
            ) : (
              // <div>
              //   <div style={{ position: 'absolute', left: -5 }}>
              //     {inSoldPending ? (
              //       <div className="sold-button-div">
              //         <Button type="text" className="sold-button">
              //           SOLD
              //         </Button>
              //       </div>
              //     ) :
              //       <div className="sold-button-div">
              //       </div>}
              //   </div>
              <div style={{ position: "relative" }}>
                {/* <div className="pLHeart">
                  {inUserFavorites ? (
                    <img
                      src={FullHeart}
                      alt=""
                      onClick={() => handleRemoveFromFavorite()}
                      className="favButton"
                    />
                  ) : (
                    <img
                      src={EmptyHeart}
                      alt=""
                      onClick={() => handleAddToFavorite()}
                      className="favButton"
                    />
                  )}
                  <Typography className="totalFavCount">
                    {favoritesCount}
                  </Typography>
                </div> */}
                {/* </div> */}
                <ProductImage
                  className="pLDImg"
                  productimg={selectedProductImg ? selectedProductImg : images}
                  //   productimg={selectedProductImg ? selectedProductImg : File1}
                />
              </div>
            )
            // <img className='product-image' src={images}/>
          }
        </Col>
      </Row>

      <Row gutter={[0, { xs: 15, sm: 15, md: 32, lg: 32 }]} justify="end">
        <Col span={24}></Col>
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <Row
            gutter={[16, 0]}
            style={{ marginRight: isXS || (isSM && "0px") }}
            className="thumbscroll"
          >
            {productLoader ? (
              <Skeleton.Image active={productLoader} size="small" />
            ) : (
              imageUrls &&
              imageUrls.map((val, i) => (
                <Col key={i}>
                  <ProductImage
                    className={
                      selectedProductImg === val
                        ? "selected-product-image"
                        : "unselected-product-image"
                    }
                    productimg={val}
                    selected={val}
                    onClick={(e) => onImgClick(val)}
                  />
                  {/* <img className='color-product-image' src={File2}/> */}
                </Col>
              ))
            )}
          </Row>
        </Col>
        {/* ))} */}
        {/* <Col span={4}>
          <ProductImage
            className={selectedProductImg === File3? "selected-product-image" : "unselected-product-image"}
            productimg={File3}
            onClick={() => onImgClick(File3)}
          />
        </Col>
        <Col span={4}>
          <ProductImage
            className={selectedProductImg === File4? "selected-product-image" : "unselected-product-image"}
            productimg={File4}
            onClick={() => onImgClick(File4)}
          />
        </Col>
        <Col span={4}>
          <ProductImage
            className={selectedProductImg === File2 ? "selected-product-image" : "unselected-product-image"}
            productimg={File2}
            onClick={() => onImgClick(File2)}
          />
        </Col> */}
      </Row>

      <div className="addtocartMob">
        <Row justify="center">
          <Col xs={24} className="pLCartParent">
            {/* <Card> */}
            <Row justify="space-between" gutter={[0, 0]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    {productLoader ? (
                      <Skeleton.Button active={productLoader} size="small" />
                    ) : (
                      name && <h1 className="pLBrand">{capitalize(name)}</h1>
                    )}
                  </Col>
                </Row>
              </Col>
              <div className="locationwrapper">
                <Col className="plcartpdp">
                  {deliveryType === "shipping" && (
                    <div className="shippingMethod toship">Shipping</div>
                  )}
                  {/* {deliveryType === "meetup" && (
                    <div className="shippingMethod local">Local Pickup</div>
                  )} */}
                  {/* {deliveryType === "both" && (
                    <div className="shippingMethodParent">
                      <div className="shippingMethod toship">Shipping</div>
                      <div className="shippingMethod local">Local Pickup</div> */}

                      {/* <div className="shippingMethod toship both">
                     Shipping or Local Pick Up
                   </div> */}
                    {/* </div>
                  )} */}
                </Col>
                {location && (
                  <span className="availablepdp">
                    available at
                    {productLoader ? (
                      <Skeleton.Button active={productLoader} size="default" />
                    ) : (
                      <span class="bold-txt">{capitalize(location)}</span>
                    )}{" "}
                  </span>
                )}
              </div>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="default" />
              ) : (
                <Col span={24}>
                  <Col span={24}></Col>
                  <Col className="marginforprice">
                    <Text className="pLPrice">${price / 100}</Text>
                    <Tooltip
                      title="For shipping delivery options. Shipping price calculated based on location, shown at checkout."
                      placement="bottom"
                      trigger="click"
                    >
                      {deliveryType == "shipping" && isFree === false && (
                        <span
                          className="shippingfeepdp tooltip"
                          style={{ marginLeft: "12px" }}
                        >
                          +Shipping fee <img src={toolTip} width="16px" />
                        </span>
                      )}
                      {deliveryType == "both" && isFree === false && (
                        <span
                          className="shippingfeepdp tooltip"
                          style={{ marginLeft: "12px" }}
                        >
                          +Shipping fee <img src={toolTip} width="16px" />
                        </span>
                      )}
                    </Tooltip>
                  </Col>
                </Col>
              )}
            </Row>

            {costPrice > 0 && (
              <Col className="retailpricepdp">
                Retail price{" "}
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="default" />
                ) : (
                  price &&
                  (!Number.isInteger(costPrice / 100) ? (
                    <span className="strikePrice">
                      ${(costPrice / 100).toFixed(2)}
                    </span>
                  ) : (
                    <span className="strikePrice">
                      ${Math.trunc(costPrice / 100)}
                    </span>
                  ))
                )}
              </Col>
            )}
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col className="paysecuretxt">
                <span>
                  <img src={securepayvector} />
                </span>{" "}
                Your payment is secured with Stripe
              </Col>
            </Row>

            <Row
              className="buttonsWrapper"
              gutter={[0, { xs: 8, sm: 8, md: 15, lg: 15, xl: 15 }]}
            >
              <Col span={24}></Col>
              {isActive==false && ( <Button
                  block
                  className={"itemSoldButton"}
                  //  onClick={() => setShowNotifySoonDialog(true)}
                >
                  Item Not Available
                </Button>)}
              {inSoldPending &&isActive===true ? (
                <Button
                  block
                  className={"itemSoldButton"}
                  // onClick={() => setShowNotifySoonDialog(true)}
                >
                  Item sold
                </Button>
              ) : (
                <>
                  {ProductId === CartId ? (
                    productLoader ? (
                      <Skeleton.Button active={productLoader} width={500} />
                    ) : (
                      !meetupType &&
                      !forSellerUser && isActive===true &&(
                        <Col span={10} style={{marginRight:"8px"}}>
                         
                          {negotiablePrice && (deliveryType=="both"|| deliveryType=="shipping")?(  <Button
                            block
                            className={"button button-primary pLCartButton"}
                            onClick={() => {
                              gaEventClick("PDP", "Go To Cart");
                              history.push("/cart");
                            }}
                          >
                            GO TO CART
                           
                          </Button>):( <Button
                            block
                            className={"button button-primary pLCartButton"}
                            onClick={() => {
                              gaEventClick("PDP", "Go to Cart");
                              history.push("/cart");
                            }}
                          >
                            GO TO CART
                          </Button>)}
                        </Col>
                      )
                    )
                  ) : productLoader ? (
                    <Skeleton.Button active={productLoader} />
                  ) : (
                    !meetupType &&
                    !forSellerUser && isActive===true&&  (
                      <Col span={10} style={{marginRight:"8px"}}>
                        {negotiablePrice && (deliveryType=="both")?( <Button
                          // block
                          className={"button button-primary pLCartButton"}
                          onClick={() => handleAddItemCart(productData)}
                        >
                          ADD TO CART  
                           {/* <div className="small">(SHIPPING)</div> */}
                        </Button>):(<Button
                          // block
                          className={"button button-primary pLCartButton"}
                          onClick={() => handleAddItemCart(productData)}
                        >
                          ADD TO CART  
                        </Button>)}
                       
                      </Col>
                    )
                  )}
                </>
              )}
              {(inSoldPending  || forSellerUser) && isActive===true ? null : (
                <>
                  <Col span={10}>
                    {productLoader ? (
                      <Skeleton.Button active={productLoader} size="small" />
                    ) : (
                      <>
                        
                        {/* {deliveryType == "both" && !negotiablePrice && isActive===true && (
                          <Button
                            block
                            onClick={() => handleOpenMakeAnOffer()}
                            className={"plfollowpdp"}
                          >
                            MAKE AN OFFER{" "}
                          </Button>
                        )}
                        {deliveryType == "meetup" && isActive===true && (
                          <Button
                            style={{ textAlign: "center" }}
                            onClick={() => handleOpenMakeAnOffer()}
                            className={"plfollowpdp"}
                          >
                            MAKE AN OFFER{" "}
                          </Button>
                        )}
                        {(deliveryType == "both") && negotiablePrice && isActive===true &&(
                          <Button
                            style={{ textAlign: "center" }}
                            onClick={() => handleOpenMakeAnOffer()}
                            className={"plmakeofferpdp"}
                          >
                            MAKE AN OFFER{" "}
                            <div className="small">(LOCAL PICK UP )</div>
                          </Button>
                        )}
                        {(deliveryType == "shipping") && negotiablePrice && isActive===true &&(
                          <Button
                            style={{ textAlign: "center" }}
                            onClick={() => handleOpenMakeAnOffer()}
                            className={"plfollowpdp"}
                          >
                            MAKE AN OFFER{" "}
                           
                          </Button>
                        )} */}
                      </>
                    )}
                  </Col>{" "}
                  {/* {deliveryType === "shipping" && isActive===true && negotiablePrice &&(
                    <Col className="tooltipalign">
                      <Tooltip
                        placement="bottom"
                        title="Send an offer to the seller for this item. All offers made with shipping are binding and require a form of payment to send."
                        trigger="click"
                      >
                        <img
                          src={toolTip}
                          className="tooltipship"
                          width="16px"
                          height="16px"
                        />
                      </Tooltip>
                    </Col>
                  )} */}
                  {/* {deliveryType === "both" && isActive===true && negotiablePrice && (
                    <Col className="tooltipalign">
                      <Tooltip
                        placement="bottom"
                        title="Make an offer for local pick up of this item. Seller can accept, reject, or counter your offer. 

                      If your offer is accepted this item will be marked as sold, and you can begin to coordinate with the seller to pick up this item."
                        trigger="click"
                      >
                        <img
                          src={toolTip}
                          className="tooltipboth"
                          width="16px"
                          height="16px"
                        />
                      </Tooltip>{" "}
                    </Col>
                  )} */}
                  {/* {deliveryType === "meetup"&& isActive===true && (
                    <Tooltip
                      placement="bottom"
                      title="Make an offer for local pick up of this item. Seller can accept, reject, or counter your offer. 

                      If your offer is accepted this item will be marked as sold, and you can begin to coordinate with the seller to pick up this item."
                      trigger="click"
                    >
                      <img
                        src={toolTip}
                        className="tooltipmeetup"
                        width="16px"
                        height="16px"
                      />
                    </Tooltip>
                  )} */}
                </>
              )}
            </Row>

            <Divider style={{ color: "#707070", margin: "15px 0px" }} />
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col span={24}></Col>
              <Col>
                <h1 className="pLcartpdp">Overview</h1>
              </Col>
            </Row>
            {condition && (
              <Row>
                <Col span={13}>
                  <Text className="pLKey">Condition</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    {condition === "new" && (
                      <Tooltip
                        title="New. Unopened packaging. Unused."
                        placement="bottom"
                      >
                        <Text className="pLvalue">
                          {capitalize("New with tag")}
                          {""}
                          <img src={toolTip} className="tooltip" width="16px" />
                        </Text>
                      </Tooltip>
                    )}
                    {/* <Text className="pLvalue">{capitalize(condition)}</Text> */}
                    {(condition === "Good" || condition === "good") && (
                      <Tooltip
                        title="Gently used. One / few minor flaws. Functional."
                        placement="bottom"
                      >
                        <Text className="pLvalue">
                          {capitalize(condition)}
                          {""}
                          <img src={toolTip} className="tooltip" width="16px" />
                        </Text>
                      </Tooltip>
                    )}
                    {(condition === "Great" || condition === "great") && (
                      <Tooltip
                        title="Like-new condition. No signs of wear. Fully functional."
                        placement="bottom"
                      >
                        <Text className="pLvalue">
                          {capitalize(condition)}
                          {""}
                          <img src={toolTip} className="tooltip" width="16px" />
                        </Text>
                      </Tooltip>
                    )}
                    {(condition === "fair" || condition === "Fair") && (
                      <Tooltip
                        title="Used, functional, multiple flaws / defects. Signs of wear and use."
                        placement="bottom"
                      >
                        <Text className="pLvalue">
                          {capitalize(condition)}
                          {""}
                          <img src={toolTip} className="tooltip" width="16px" />
                        </Text>
                      </Tooltip>
                    )}
                  </Col>
                )}
              </Row>
            )}
            {brand && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Brand</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">{brand}</Text>
                  </Col>
                )}
              </Row>
            )}
            {deliveryType && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Delivery</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">
                      {/* {deliveryType === 'both' ? 'Shipping or Meetup' : capitalize(deliveryType)} */}
                      {deliveryType === "both"
                        ? "Shipping or Local Pick Up"
                        : deliveryType === "meetup"
                        ? "Local Pick Up"
                        : capitalize(deliveryType)}
                    </Text>
                  </Col>
                )}
              </Row>
            )}
            <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
              <Col span={24}></Col>
              <Col span={13}>
                <Text className="pLKey">Price</Text>
              </Col>
              {/* {productLoader ? (
                <Skeleton.Button active={productLoader} size="default" />
              ) : (
                <Col span={11}>
                  {negotiablePrice === true ? (
                    <Text className="pLvalue">Negotiable</Text>
                  ) : (
                    <Text className="product-cart-value">Non negotiable</Text>
                  )}
                </Col>
              )} */}
            </Row>
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col span={24}></Col>
              <Col>
                <h1 className="pLcartpdp">Item details</h1>
              </Col>
            </Row>

            {yearOfPurchase && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Purchased</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">{ManufactureVal}</Text>
                  </Col>
                )}
              </Row>
            )}
            {/* {location && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Location</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="default" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">{capitalize(location)}</Text>
                  </Col>
                )}
              </Row>
            )} */}
            {age && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Age</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">{capitalize(age)}</Text>
                  </Col>
                )}
              </Row>
            )}
            {size && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Size</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">{capitalize(size)}</Text>
                  </Col>
                )}
              </Row>
            )}
            {color && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Color</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">{capitalize(color)}</Text>
                  </Col>
                )}
              </Row>
            )}
            {category && (
              <Row gutter={[0, { xs: 15, sm: 15, md: 8, lg: 8, xl: 8 }]}>
                <Col span={24}></Col>
                <Col span={13}>
                  <Text className="pLKey">Category</Text>
                </Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="default" />
                ) : (
                  <Col span={11}>
                    <Text className="pLvalue">{category}</Text>
                  </Col>
                )}
              </Row>
            )}

            {inSoldPending || forSellerUser ? null : (
              <Row gutter={[0, 15]}>
                <Col span={24}></Col>
                <Col>
                  {productLoader ? (
                    <Skeleton active={productLoader} size="small" />
                  ) : (
                    <Typography className="pLNotifyDes">
                      {description}
                    </Typography>
                  )}
                </Col>
              </Row>
            )}
            {/* </Card> */}
          </Col>
        </Row>
        <Divider style={{ color: "#707070", margin: "30px 0px" }} />{" "}
        <Row gutter={[0, 0]}>
          <Col>
            <img alt="" src={securePay} style={{ cursor: "pointer" }} />
          </Col>
          <Col xl={{ span: 6, offset: 1 }} xs={{ span: 17, offset: 1 }}>
            <Row gutter={[0, 3]}>
              <Text className="Plsecurepdp" style={{ cursor: "pointer" }}>
                Secure payments
              </Text>
              <Col span={3} />
            </Row>
            <Row>
              <Typography className="pLListedItemdesc">
                We keep your payment information secure using stripe. Markid
                sellers will never receive your credit card information.
              </Typography>

              <Col span={15} />
            </Row>
            <Row>
                      <Col >
                        <img src={stripe} width="60.61px"/>
                      </Col>
                      <Col >
                        <img src={visa}width="40.61px" />
                      </Col>
                      <Col >
                        <img src={visa1}width="40.61px" />
                      </Col>
                      <Col >
                        <img src={AmericanEx} width="40.61px" />
                      </Col>
                    </Row>
            {/* <Row>
              <Col span={4} style={{ marginRight: "10px" }}>
                <img src={stripe} width="50.61px" />
              </Col>
              <Col span={16}>
                <img src={visa} />
              </Col>
            </Row> */}
          </Col>
          <Divider style={{ color: "#707070", margin: "30px 0px" }} />
          <Col>
            <img alt="" src={protect} style={{ cursor: "pointer" }} />
          </Col>
          <Col xl={{ span: 18, offset: 1 }} xs={{ span: 17, offset: 1 }}>
            <Row gutter={[0, 3]}>
              <Text className="Plsecurepdp" style={{ cursor: "pointer" }}>
                Markid’s buyer protection guarantee
              </Text>
              <Col span={3} />
            </Row>
            <Row>
              <Typography className="pLListedItemdesc">
                Your transactions are protected. All payments are held until you
                receive your item, or 100% refund guaranteed.
              </Typography>

              <Col span={15} />
            </Row>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 15]}>
          {/* <Divider style={{ color: "#707070", margin: "15px 0px" }} /> */}
          {inSoldPending || forSellerUser ? (
            <></>
          ) : (
            <div className="getNotifiedWrapper">
              <Spin indicator={antIcon} spinning={isNotifyItemLoading}>
                <Typography className="getNotified">
                  <span
                    style={{ color: "#FD533F" }}
                    onClick={() => handleNotifyItem(ProductId)}
                  >
                    {" "}
                    Get notified{" "}
                  </span>
                  <span className="">
                    when the price drops or when a similar item is posted.
                  </span>{" "}
                  <Tooltip
                    title="You'll be notified when the price drops on this item, or when a similar item is posted. "
                    placement="left"
                  >
                    <img src={toolTip} className="tooltip" width="16px" />
                  </Tooltip>
                </Typography>
              </Spin>
            </div>
          )}

          {/* <Typography className="plsimilarpdp"></Typography> */}
          {/* <Divider style={{ color: "#707070", margin: "15px 0px" }} /> */}
          <Col span={24}></Col>
          <Col xs={24}>
            <Row>
              <Col>
                {productLoader ? (
                  <Skeleton active={productLoader} size="small" />
                ) : (
                  <Typography className="sellertitlepdp">
                    Meet the seller
                  </Typography>
                )}
              </Col>
            </Row>
            <Row>
              <Row gutter={[0, 24]} className="sellerRating">
                <Col span={24} />
                <Col>
                  {productLoader ? (
                    <Skeleton.Image active={productLoader} size="small" />
                  ) : photo && photo ? (
                    <Avatar
                      shape="square"
                      style={{
                        height: 60,
                        width: 60,
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                      src={photo}
                      onClick={() =>
                        history.push(`/seller-profile/${sellerId}`)
                      }
                    />
                  ) : (
                    <img
                      alt=""
                      src={UserBlanckImg}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/seller-profile/${sellerId}`)
                      }
                    />
                  )}
                  <Row gutter={[0, 8]}>
                    <Col span={24} />
                    <Col>
                      {productLoader ? (
                        <Skeleton active={productLoader} size="small" />
                      ) : (
                        <Text className="pLVerifiedpdp style2">
                          {/* <img src={TrueVector} /> Verified */}
                        </Text>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={{ span: 15, offset: 1 }}
                  lg={{ span: 15, offset: 1 }}
                  md={{ span: 15, offset: 1 }}
                  sm={{ span: 18, offset: 1 }}
                  xs={{ span: 18, offset: 1 }}
                >
                  {productLoader ? (
                    <Skeleton.Input active={productLoader} size="small" />
                  ) : (
                    fullName && (
                      <Text
                        className="Plsellernamepdp"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(`/seller-profile/${sellerId}`)
                        }
                      >
                        {capitalize(fullName)}
                      </Text>
                    )
                  )}

                  <Row gutter={[0, 5]}>
                    <Col span={24} />
                    {productLoader ? (
                      <Skeleton.Button active={productLoader} size="small" />
                    ) : (
                      <Rate
                        defaultValue={5}
                        className="pLRatepdp"
                        disabled={true}
                      />
                    )}
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col />
                    {productLoader ? (
                      <Skeleton active={productLoader} size="small" />
                    ) : (
                      <Text className="pLListedItempdp">
                        {activeLotsCount + soldLotsCount} items listed
                      </Text>
                    )}
                    <Text className="pLListedItempdp1">|</Text>
                    {productLoader ? (
                      <Skeleton active={productLoader} size="small" />
                    ) : (
                      <Text className="pLListedItempdp">
                        {soldLotsCount} sold
                      </Text>
                    )}
                    <Typography className="pLListedItemdesc">
                      New to gently used kids clothing , shoes , and accessories
                      ship same or next day ✨
                    </Typography>
                  </Row>
                </Col>
              </Row>

              {!inSoldPending && !forSellerUser && (
                <>
                  <Col span={24}></Col>
                  <Col span={12}>
                    {productLoader ? (
                      <Skeleton.Button active={productLoader} size="small" />
                    ) : (
                      <Spin
                        indicator={antIcon}
                        spinning={isGetChatsWithSellerLoading}
                      >
                        <Button
                          block
                          onClick={() => handleMessageSeller()}
                          className={"button button-bordered pLMakeOfferpdp"}
                        >
                          MESSAGE SELLER
                        </Button>
                      </Spin>
                    )}
                  </Col>
                </>
              )}
              {!inSoldPending && !forSellerUser && (
                <Col span={12}>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : following && following.length > 0 ? (
                    <Spin indicator={antIcon} spinning={isUnFollowUserLoading}>
                      <div className="tooltipmobwrap">
                        <Button
                          type="text"
                          className="plfollowpdp"
                          onClick={() => handleUnFollowUser(createdBy)}
                        >
                          UnFollow
                        </Button>
                        <Tooltip
                          placement="bottomLeft"
                          title="Get notified when this seller publishes a new item to sell"
                        >
                          <img src={toolTip} className="tooltip" width="16px" />
                        </Tooltip>
                      </div>
                    </Spin>
                  ) : (
                    <Spin indicator={antIcon} spinning={isFollowUserLoading}>
                      <div className="tooltipmobwrap">
                        <Button
                          type="text"
                          className="plfollowpdp"
                          onClick={() => handleFollowUser(createdBy)}
                        >
                          Follow
                        </Button>
                        <Tooltip
                          placement="bottomLeft"
                          title="Get notified when this seller publishes a new item to sell"
                          trigger="click"
                        >
                          <img src={toolTip} className="tooltip" width="16px" />
                        </Tooltip>
                      </div>
                    </Spin>
                  )}
                </Col>
              )}
              <div className="plsimilarcenter">
                {/* <Divider style={{ color: "#707070", margin: "15px 0px" }} /> */}
                <Typography className="plsimilarpdp">
                  Have a similar item?
                  <span
                    className="plsimilarpdp1"
                    onClick={() => {
                      if (providerToken) {
                        gaEventClick("PDP", "Sell on markid");
                        history.push("/seller/addproduct");
                      } else {
                        gaEventClick("Navigation", "Sell on markid", "SignUp");
                        history.push("/onboarding");
                      }
                    }}
                  >
                    Sell it on Markid
                  </span>{" "}
                </Typography>
              </div>
            </Row>

            {/* <Row gutter={[0, 0]}>
              <Col>
                {productLoader ? (
                  <Skeleton active={productLoader} size="small" />
                ) : (
                  <Typography className='pLSellerBadge'>
                    Seller badges
                  </Typography>
                )}
              </Col>
            </Row>
            {productLoader ? (
              <Skeleton active={productLoader} size="small" />
            ) : (
              <Row gutter={[7, 13]}>
                <Col span={24}></Col>
                {top10 &&
                  <Col xs={6}>
                    <Row justify="center" gutter={[0, 2]}>
                      <Avatar className="badgeImg" size={54.5} src={Badge1} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="badgeTitle">
                          Top 10% seller
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
                {createdAt &&
                  <Col xs={6}>
                    <Row justify="center">
                      <Avatar className="badgeImg" size={54.5} src={Badge2} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="badgeTitle">
                          Member since {moment(createdAt).format("YYYY")}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
                {fastResponder &&
                  <Col xs={6}>
                    <Row justify="center">
                      <Avatar className="badgeImg" size={54.5} src={Badge3} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="badgeTitle">
                          Fast Responder
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
                {fastShipper &&
                  <Col xs={6}>
                    <Row justify="center">
                      <Avatar className="badgeImg" size={54.5} src={Badge4} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="badgeTitle">
                          Fast Shipper
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
              </Row>
            )}
            */}
            {/* <Divider style={{ color: '#707070', margin: "20px 0px 35px" }} /> */}
          </Col>
        </Row>
        {isRenewed && (
          <Row justify="center" gutter={[0, 35]}>
            <Col span={22}>
              <Card className="rIBackground">
                <Row>
                  <Col>
                    <Typography className="rIText">
                      reNEWED Information
                    </Typography>
                  </Col>
                </Row>
              </Card>
              <Card className="rIDesBackground">
                <Row justify="start">
                  <Col>
                    <Typography className="riDes">
                      The simplest way to sell kid's gently used, high-quality
                      furniture and gear-effortlessly, sustainably and
                      purposefully.
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Col span={24}>
                    <Button
                      block
                      className={"button button-bordered pLMakeOffer"}
                    >
                      KNOW MORE
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        {/* <Row gutter={[0,30]} justify='center'>
        <Col span={24}></Col>
        <Col span={22}>
        <Card className='rIBackground'>
        <Row>
              <Col>
                <Typography className="rIText">
                Need help?
                </Typography>
              </Col>
            </Row>
            </Card>
          <Card className="rIDesBackground">
            <Row justify="start">
              <Col>
                <Typography className="riDes">
                Request a call, chat and more
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Button
                  block
                  href={process.env.REACT_APP_STATIC_DOMAIN+'contact'}
                  target="_"
                  className={"pLMakeOffer"}
                >
                 Contact us
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row> */}
      </div>

      {/*<Row justify='center'>
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            collapsible="header"
            onChange={callback}
            expandIconPosition="end"
            bordered={false}
          >
            <Panel
              showArrow={true}
              header={
                <Row justify="start" align="middle">
                  <Text className='colleps-title'>Ratings and reviews</Text>
                </Row>
              }
              key="1"
            >
              <Row justify="start" gutter={[0, 4]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Blogger Ratings</Text>
                </Col>
              </Row>
              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row>
                      <Col>
                        <Title level={5} className='rating-blog-title'>
                          My kid loves the lounger
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Typography className='raring-blog-des'>
                          It is a great piece of furniture - Very high quality.
                          Unfortunately. my 4-month old prefers his battery
                          operated swings with loud sounds and colors. So far,
                          this loungers has very limited usages for us. We use
                          it for 15 minute increments to keep him occupied while
                          we get ready in the morning.
                        </Typography>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={24}></Col>
                      <Col>
                        <Avatar size={50}style={{backgroundColor: '#FFD09C'}}/>
                      </Col>
                      <Col>
                      <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Title level={5} className='rating-user-name'>
                          Celiene Adams
                        </Title>
                        </Col>
                        </Row>
                      </Col>
                      <Col>
                      <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Typography className='rating-user-des'>(Mom of two aged 3 and 5)</Typography>
                        </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[0, 48]}>
                <Col span={24}></Col>
                <Col>
                  <Text>PRODUCT RATINGS (AMAZON)</Text>
                </Col>
              </Row>
              <Row gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row justify="space-between">
                      <Col xl={12} lg={12} md={12} sm={22} xs={22}>
                        <Rate allowHalf style={{ fontSize: 30 }} defaultValue={4} />
                        <Row justify="center">
                          <Col>
                            <Title strong level={5}>
                              4.5 out of 5
                            </Title>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={22} xs={22}>
                        <Typography style={{ textAlign: "start" }}>
                          Out of 3.711 global ratings with top features such as
                          thickness, easy assembly. Good sleep quality and
                          sturdinees
                        </Typography>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row>
                      <Col>
                        <Title strong level={5} className='rating-blog-title'>
                          Very good quality
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Typography className='raring-blog-des'>
                         This is very good quality lounger. My newborn sleeps comfortably in it. It is easy
                         to handle machine washable. As a mother, I first look at the comfort of my child
                         and for that it certainly exceed my expectation.
                        </Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} justify='space-between'>
                      <Col span={24}></Col>
                      <Col>
                      <Space direction='horizontal'>
                        <Avatar size={50}style={{backgroundColor: '#FFD09C'}}/>
                        <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Title level={5} className='rating-user-name'>
                          Umasankar
                        </Title>
                        </Col>
                        </Row>
                        </Space>
                      </Col>
                      <Col>
                        <Rate defaultValue={5} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Card style={{ backgroundColor: "#f9f9f9" }}>
                    <Row>
                      <Col>
                      <Title strong level={5} className='rating-blog-title'>
                          Breathable and nice looking
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <Typography className='raring-blog-des'>
                         Shipment was very fast. I selected regular shipping, but I received it in 2 days! This
                         product looks pretty nice. I really like the light blue/purple color. Hopefully my 
                         baby likes it, too!
                        </Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} justify='space-between'>
                      <Col span={24}></Col>
                      <Col>
                      <Space direction='horizontal'>
                        <Avatar size={50}style={{backgroundColor: '#FFD09C'}}/>
                        <Row gutter={[0,8]}>
                        <Col span={24}></Col>
                        <Col>
                        <Title level={5} className='rating-user-name'>
                          Amber Frushon
                        </Title>
                        </Col>
                        </Row>
                        </Space>
                      </Col>
                      <Col>
                        <Rate defaultValue={4} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[0,8]} justify='start'>
                  <Col span={24}></Col>
                  <Col>
                  <Button type='text' className='view-more'>View more</Button>
                  </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row> */}

      {/* <Row>
        <Col xl={24} lg={24} md={24} sm={22} xs={22}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            collapsible="header"
            onChange={callback}
            expandIconPosition="end"
            bordered={false}
          >
            <Panel
              showArrow={true}
              header={
                <Row justify="start" align="middle">
                  <Text strong>Disscusion</Text>
                </Row>
              }
              key="1"
            >
              <Row justify="start" gutter={[32, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Controller
                    as={Input}
                    size="large"
                    name="phoneNumber"
                    control={control}
                    placeholder="Start a disscusion"
                  />
                </Col>
              </Row>
              <List>
                <List.Item actions={[<div>REPLY</div>]}>
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={<div>Would love to buy this for my newborn!</div>}
                  />
                </List.Item>
                <List.Item actions={[<div>REPLY</div>]}>
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={<div>Can I use this for my 2 year old?</div>}
                    description={<div>Yes You can.</div>}
                  />
                </List.Item>
                <List.Item actions={[<div>REPLY</div>]}>
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={
                      <div>
                        Plan to use it for both my kids, Would this be good
                        enough?
                      </div>
                    }
                  />
                </List.Item>
              </List>
            </Panel>
          </Collapse>
        </Col>
      </Row> */}
      <MakeOffer
        onCancelMakeOfferDialog={() => setShowMakeOfferDialog(false)}
        showMakeOfferDialog={showMakeOfferDialog}
        makeOfferData={productData}
        sellerInfo={sellerInfo}
      />
      <MessageSeller
        onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)}
        showMessageSellerDialog={showMessageSellerDialog}
      />
      <NotifySoon
        onCancelNotifySoonMethod={() => setShowNotifySoonDialog(false)}
        showNotifySoonDialog={showNotifySoonDialog}
        notifyData={productData}
      />
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default PLDescription;