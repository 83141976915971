import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { setBrandName } from "../Actions/brandsAction";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Alert,
  Modal,
  Dropdown,
  Menu,
  Input,
  Badge,
  AutoComplete,
  Divider,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";

const NavProfileMenu = (props) => {
  const { handleProfile, fullName ,currentUser} = props;
  const { Text } = Typography;
  const location = useLocation();
  const history = useHistory();
  const { _id } = currentUser || {};
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("access_token");

    dispatch({ type: "REMOVE_TOKEN" });
    dispatch({ type: "RESET_APP" });
    // history.push("/homepage");
    history.push("/");
  };

  return (
    <Dropdown
    style={{marginRight:"10px"}}
      placement="top"
      overlay={
        <Menu className="myAccountMenu">
          <Menu.Item className="user">
            Hello <span className="text-capitalize">{fullName}</span>
          </Menu.Item>
          <Menu.Item onClick={() => handleProfile(1)}>View Profile</Menu.Item>
          
         
          <Menu.Item onClick={() => history.push(`/seller-profile/${_id}`)} className="menu-divider">My listings</Menu.Item>
          <Menu.Item onClick={() => handleProfile(4)} >
            Favorite Items
          </Menu.Item>
          <Menu.Item onClick={() => handleProfile(5)}>
            Purchases History
          </Menu.Item>
          <Menu.Item onClick={() => handleProfile(5)}>Sales History</Menu.Item>

          <Menu.Item onClick={() => handleProfile(3)} className="menu-divider">
            Payments
          </Menu.Item>
          <Menu.Item onClick={() => handleProfile(3)}>Wallets</Menu.Item>
          <Menu.Item className="logout" onClick={() => handleLogout()}>
            Log Out
          </Menu.Item>
        </Menu>
      }
    >
      <Col className="flex">
        <Text className="userName text-capitalize">{fullName}</Text>{" "}
      </Col>
    </Dropdown>
  );
};

export default NavProfileMenu;
