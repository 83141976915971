import API from '../Utils/API';

export const createPayment = (data) => dispatch => {
    dispatch({ type: "CREATE_ACCOUNT_REQUEST" })
    API.post('/api/v2/payments/create-account', data)
        .then(response => {
            dispatch({ type: "CREATE_ACCOUNT_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "CREATE_ACCOUNT_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const getLoginLink = () => dispatch => {
    dispatch({ type: "CREATE_LOGIN_LINK_REQUEST" })
    API.get('/api/v2/payments/create-login-link')
        .then(response => {
            dispatch({ type: "CREATE_LOGIN_LINK_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "CREATE_LOGIN_LINK_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const getBalance = () => dispatch => {
    dispatch({ type: "GET_BALANCE_REQUEST" })
    API.get('/api/v2/payments/get-balance')
        .then(response => {
            dispatch({ type: "GET_BALANCE_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "GET_BALANCE_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const checkPayoutStatus = (data) => dispatch => {
    dispatch({ type: "CHECK_PAYOUT_STATUS_REQUEST" })
    API.post('/api/v2/payments/check-payouts-status', data)
        .then(response => {
            dispatch({ type: "CHECK_PAYOUT_STATUS_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "CHECK_PAYOUT_STATUS_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const getCards = () => dispatch => {
    dispatch({ type: "GET_CARDS_REQUEST" })
    API.get('/api/v2/payments/get-cards')
        .then(response => {
            dispatch({ type: "GET_CARDS_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "GET_CARDS_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const addCard = (data, successAddCard, faliureAddCard) => dispatch => {
    dispatch({ type: "ADD_CARD_REQUEST" })
    API.post('/api/v2/payments/add-card', data)
        .then(response => {
            dispatch({ type: "ADD_CARD_SUCCESS", payload: response.data })
            const sAC = successAddCard ? successAddCard() : null
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "ADD_CARD_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
                const fAC = faliureAddCard ? faliureAddCard() : null
            } catch {
                // console.log(error);
            }
        });
}

export const deleteCard = (data, successDeleteCard, failiureDeleteCard) => dispatch => {
    dispatch({ type: "DELETE_CARD_REQUEST" })
    API.post('/api/v2/payments/delete-card', data)
        .then(response => {
            dispatch({ type: "DELETE_CARD_SUCCESS", payload: response.data })
            const sDC = successDeleteCard ? successDeleteCard() : null
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "DELETE_CARD_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
                const fDC = failiureDeleteCard ? failiureDeleteCard() : null
            } catch {
                // console.log(error);
            }
        });
}

export const getDefaultCard = () => dispatch => {
    dispatch({ type: "GET_DEFAULT_CARD_REQUEST" })
    API.get('/api/v2/payments/get-default-card')
        .then(response => {
            dispatch({ type: "GET_DEFAULT_CARD_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "GET_DEFAULT_CARD_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const setDefaultCard = (data, successDefaultCard, failiureDefaultCard) => dispatch => {
    dispatch({ type: "SET_DEFAULT_CARD_REQUEST" })
    API.post('/api/v2/payments/set-default-card', data)
        .then(response => {
            dispatch({ type: "SET_DEFAULT_CARD_SUCCESS", payload: response.data })
            const sDefaultCard = successDefaultCard ? successDefaultCard() : null
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "SET_DEFAULT_CARD_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
                const fDefaultCard = failiureDefaultCard ? failiureDefaultCard() : null
            } catch {
                // console.log(error);
            }
        });
}

export const checkMarkidFee = () => dispatch => {
    dispatch({ type: "CHECK_MARKID_FEE_REQUEST" })
    API.get('/api/v2/payments/check-markid-fee')
        .then(response => {
            dispatch({ type: "CHECK_MARKID_FEE_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "CHECK_MARKID_FEE_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const processPayment = (data) => dispatch => {
    dispatch({ type: "PROCESS_PAYMENT_REQUEST" })
    API.post('/api/v2/payments/process-payment', data)
        .then(response => {
            dispatch({ type: "PROCESS_PAYMENT_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "PROCESS_PAYMENT_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const getPaymentMethod = () => dispatch => {
    dispatch({ type: "GET_PAYMENT_METHOD_REQUEST" })
    API.get('/api/v2/payments/get-payment-method')
        .then(response => {
            dispatch({ type: "GET_PAYMENT_METHOD_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "GET_PAYMENT_METHOD_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}