import React, { useState } from "react";
import { Card, Typography, Row, Col, Tabs, Button, Avatar, Badge, Popover } from "antd";
// import { MoreOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import BlanckImportantImg from "../assets/BlanckImportantImg.svg";
import Star from "../assets/Star.svg";
import Trash from "../assets/Trash.svg";
import Info from "../assets/Info.svg";
import Block from "../assets/Block.svg";
import UnblockIcon from "../assets/UnblockIcon.svg";
import moreIcon from '../assets/MoreIcon.jpg';
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { getChats, getMessagesByChatId, deleteChatById } from "../Actions/chatsAction";
import { blockUser, unBlockUser } from "../Actions/userAction";
import _ from "lodash";

const { TabPane } = Tabs;

moment.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: '1 min',
        mm: '%d mins',
        h: '1 hour',
        hh: '%d hours',
        d: '1 day',
        dd: '%d days',
        M: '1 month',
        MM: '%d months',
        y: '1 y',
        yy: '%d years'
    }
});

export const ChatListCardMob = (props) => {
    const dispatch = useDispatch()
    const { userImage, userName, lastMessage, lastMessageTime, userId, chatId, participantsId, onclick, value, count } = props
    // const [chatListMenu, setChatListMenu] = useState(false)
    const { currentUser, messages } = useSelector((state) => {
        const { currentUser } = state.user;
        const { messagesByChatId } = state.chats;
        const { messages } = messagesByChatId;
        return {
            currentUser,
            messages
        };
    });
    const { blockedUsers } = currentUser || {}
    let blockedUserId = ""
    blockedUsers &&
        blockedUsers.map((current) => {
            return (
                current === participantsId ? blockedUserId = current : null
            )
        })
    const [pop, setPop] = useState(false);
    const [chatColor, setChatColor] = useState("");


    const handleCardColor = (data) => {
        data === userId ? setChatColor(data) : setChatColor("")
    }
    const handleDeleteChat = (chatId) => {
        dispatch(deleteChatById(chatId, successDeleteChat, failiureDeleteChat))
    }
    const successDeleteChat = () => {
        dispatch(getChats())
        dispatch(getMessagesByChatId(chatId))
    }
    const failiureDeleteChat = () => { }

    const handleBlockUser = (userId) => {
        dispatch(blockUser(userId, successCallBlockUser, failureCallBlockUser));
        setPop(false);
    }
    const successCallBlockUser = () => {
        dispatch(getChats())
        dispatch(getMessagesByChatId(chatId))
    }
    const failureCallBlockUser = () => { }

    const handleUnBlockUser = (userId) => {
        dispatch(unBlockUser(userId, successCallUnBlockUser, failureCallUnBlockUser));
        setPop(false);
    }
    const successCallUnBlockUser = () => {
        dispatch(getChats())
        dispatch(getMessagesByChatId(chatId))
    }
    const handleMoreIcon = (value) => {
        setPop(true);
    }
    const failureCallUnBlockUser = () => { }
    const content = (
        <div>
            {participantsId === blockedUserId &&
                <>
                    <Row justify="end">
                        <Col>
                            <img src={moreIcon} alt="" style={{ marginRight: -10 }} onClick={() => setPop(false)} className="moreIcon" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type='text' icon={<img alt="" src={UnblockIcon} style={{ paddingRight: 9 }} />} className='chatListMenu'
                                onClick={() => handleUnBlockUser(userId)}
                            >
                                UnBlock User
                            </Button>
                        </Col>
                    </Row>
                </>
            }
            {participantsId !== blockedUserId &&
                <>
                    <Row justify="end">
                        <Col>
                            <img src={moreIcon} alt="" onClick={() => setPop(false)} className="moreIcon" />
                        </Col>
                    </Row>
                    {/* <Row>
                <Col>
                    <Button type='text' icon={<img alt="" src={Star} style={{ paddingRight: 9 }} />} className='chatListMenu'>
                        Mark as important
                    </Button>
                </Col>
            </Row> */}
                    <Row>
                        <Col>
                            <Button type='text' icon={<img alt="" src={Trash} style={{ paddingRight: 9 }} />} className='chatListMenu'
                                onClick={() => handleDeleteChat(chatId)}
                            >
                                Delete Chat
                            </Button>
                        </Col>
                    </Row>
                    {/* <Row>
                <Col>
                    <Button type='text' icon={<img alt="" src={Info} style={{ paddingRight: 9 }} />} className='chatListMenu'>
                        Report User
                    </Button>
                </Col>
            </Row> */}
                    <Row>
                        <Col>
                            <Button type='text' icon={<img alt="" src={Block} style={{ paddingRight: 9 }} />} className='chatListMenu'
                                onClick={() => handleBlockUser(userId)}
                            >
                                Block User
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        </div>
    );
    return (
        <div className="chatListMobParent">
            <Card bordered={false} onClick={() => handleCardColor(userId)}
                className={chatColor === userId ? 'chatListCard cardColor' : 'chatListCard'} >
                <Row justify='space-between'>
                    <Col>
                        <Row gutter={[9, 0]} onClick={() => onclick(value)}>
                            <Col className="avatarWrap">
                                <Badge count={0} offset={[-10, 40]}>
                                    {userImage ?
                                        <Avatar shape='circle' size={50} src={userImage} />
                                        :
                                        <Avatar shape='circle' size={50} src={UserBlanckImg} />
                                    }
                                </Badge>
                            </Col>
                            <Col>
                                <Typography className='chatListUserName'>
                                    {userName && userName.length > 20 ? userName.substring(0, 15) + "..." : userName}
                                </Typography>
                                <Row gutter={[0, 10]}>
                                    <Col span={24}></Col>
                                    <Col>
                                        <Typography className='chatListLastMessage'>
                                            {lastMessage}
                                        </Typography>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Typography className='chatListMessageTime'>
                            {moment(lastMessageTime).startOf('seconds').fromNow()}
                        </Typography>
                        <Row gutter={[0, 10]} justify='end'>
                            <Col span={24}></Col>
                            {pop ?
                                <Col>
                                    <Popover overlayInnerStyle={{
                                        marginRight: "-34px",
                                        marginTop: '-16px'
                                    }} className="popover" placement="leftTop" content={content} trigger="click">
                                        {props.children}
                                    </Popover>
                                </Col>
                                :
                                <Col>
                                    <img src={moreIcon} alt="" className="moreIcon" onClick={() => handleMoreIcon(value)} />
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export const UserChatListMob = (props) => {
    const dispatch = useDispatch()
    const { chat, currentUser, messagesByChatId } = useSelector((state) => {
        const { currentUser } = state.user
        const { currentChat, messagesByChatId } = state.chats
        const { chat } = currentChat
        return { chat, currentUser, messagesByChatId }
    });

    const { participants } = chat || {}
    const { blockedUsers } = currentUser || {}

    let participantsId = participants && participants[1];

    let blockedUserId = ""
    blockedUsers &&
        blockedUsers.map((current) => {
            return (
                current === participantsId ? blockedUserId = current : null
            )
        });

    const { ChatListData, setUserChatListMobile, setUserChatMobile } = props
    const [tabKey, setTabkey] = useState("1");

    function chatTabPan(key) {
        setTabkey(key)
    }
    const handleChat = (val) => {
        dispatch({ type: "SET_CURRENT_CHAT", payload: { chat: val } })
        setUserChatListMobile(false)
        setUserChatMobile(true);
    }
    return (
        <div className="chatListParent">
            <Card className='chatListBgCard'>
                <Tabs defaultActiveKey="1" onChange={chatTabPan}
                    renderTabBar={(props, DefaultTabBar) => (
                        <DefaultTabBar {...props} className='chatListTabBar'>
                            {node => (
                                <div key={node.key} index={node.key}>
                                    {node}
                                </div>
                            )}
                        </DefaultTabBar>
                    )
                    }
                >
                    <TabPane key="1"
                        tab={<Button type='text' className={tabKey === "1" ? 'chatListSelectedMobTab' : 'chatListUnSelectedMobTab'} >All messages</Button>}>
                        <Row style={{ overflowY: 'scroll', height: 454 }}
                            justify={ChatListData && ChatListData.length > 0 ? "" : "center"}
                        >
                            <Col span={24}>
                                {ChatListData && ChatListData.length > 0 ?
                                    <Row gutter={[0, 20]}>
                                        <Col span={24}></Col>
                                        {ChatListData.map((val, i) => {

                                            return (
                                                <Col key={i} span={24}
                                                    className={
                                                        (blockedUserId === val.interlocutor?._id) ?
                                                            'chatListCard blockedColor' : 'chatListCard cardColor'}
                                                >
                                                    <ChatListCardMob
                                                        chatId={val.chat?._id}
                                                        onclick={handleChat}
                                                        value={val}
                                                        count={
                                                            val.interlocutor?._id === val.lastMessage?.addressedTo ? true : false
                                                        }
                                                        userId={val.interlocutor?._id}
                                                        participantsId={val.chat.participants[1]}
                                                        blockedUsers={val.interlocutor?.blockedUsers}
                                                        userImage={val.interlocutor?.photo}
                                                        userName={val.interlocutor?.fullName}
                                                        lastMessage={val.lastMessage?.message}
                                                        lastMessageTime={val.lastMessage?.createdAt}
                                                    >
                                                        <img src={moreIcon} alt="" className='moreIcon' />
                                                    </ChatListCardMob>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    :
                                    <>
                                        <Row gutter={[0, 46.67]} justify='center'>
                                            <Col span={24}></Col>
                                            <Col span={24}></Col>
                                            <Col span={24}></Col>
                                            <Col>
                                                <img alt="" src={BlanckImportantImg} />
                                            </Col>
                                        </Row>
                                        <Row gutter={[0, 33]} justify='center'>
                                            <Col span={24}></Col>
                                            <Col>
                                                <Typography className='blanckImportantText'>
                                                    No messages, yet?
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane key="2"
                        tab={<Button type='text' className={tabKey === "2" ? 'chatListSelectedMobTab chatImp' : 'chatListUnSelectedMobTab chatImp'}>Important</Button>}>
                        <Row style={{ overflowY: 'scroll', height: 454 }} justify='center'>
                            <Col>
                                <Row gutter={[0, 46.67]} justify='center'>
                                    <Col span={24}></Col>
                                    <Col span={24}></Col>
                                    <Col span={24}></Col>
                                    <Col>
                                        <img alt="" src={BlanckImportantImg} />
                                    </Col>
                                </Row>
                                <Row gutter={[0, 33]} justify='center'>
                                    <Col span={24}></Col>
                                    <Col>
                                        <Typography className='blanckImportantText'>
                                            There is no important message
                                        </Typography>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    );
};
