/*eslint-disable*/
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Card,
  Avatar
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../App.css";
const { Text, Title } = Typography;
const CartItemsCard = (props) => {
  const dispatch = useDispatch();
    const { imageUrls, name, price, recommendedShippingPrice, deliveryType, closeIcon, itemImg, itemName, itemShippingPrice, shippingPrice, totalPrice, mode, sellerName} = props.currentCart
    const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const handleRemoveItemCart = (item) => {
      dispatch({type:'REMOVE_ITEM_CART', payload:{item:item}})
    }
  return (
    <>
      <Row gutter={[0,16]}>
        <Col span={24}></Col>
        <Col span={24}>
          <Card>
            <Row justify="end">
            <CloseOutlined onClick={() => handleRemoveItemCart(props.currentCart)}/>
            </Row>
            <Row gutter={[48, 0]}>
              <Col>
              <Row gutter={[8,8]}>
                <Col>
                <Avatar shape="square" size={140} src={images} />
                </Col>
                <Col>
                <Row gutter={[0,4]}>
                  <Col span={24}></Col>
                  <Col>
                  <Title className='renewed-info' level={4}>
                  {capitalize(name)}
                  </Title>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[0, 48]} justify="space-between">
                  <Col span={24}></Col>
                  <Col>
                    <Typography className='cart-shipping-price'>
                        ${price / 100} + Shipping Free
                    </Typography>
                  </Col>
                  <Col>
                    <Typography className='m-cart-price'>
                        {/* {shippingPrice} */}
                    </Typography>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={24}></Col>
                  <Col>
                    <Typography className='total-price'>
                        ${price / 100}
                    </Typography>
                  </Col>
                  {/* <Col>
                    <Typography className='mode'>
                        {deliveryType === "both" ?
                        "Shipping"
                        :
                        capitalize(deliveryType)
}
                    </Typography>
                  </Col> */}
                </Row>
              </Col>
                </Row>
                </Col>
              </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default CartItemsCard;
