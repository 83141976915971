import constants from "../Utils/constants";
const initialState = {
    isGetPublishedHandPickedLoading: false, isGetHandPickedItemLoading: false, publishedHandPicked: [], handPickedItem: []
};

const handpickedReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PUBLISHED_HANDPICKED_REQUEST:
      return {
          ...state, isGetPublishedHandPickedLoading: true
      }
  case constants.GET_PUBLISHED_HANDPICKED_SUCCESS:
      return {
          ...state, isGetPublishedHandPickedLoading: false, publishedHandPicked: action.payload
      }
  case constants.GET_PUBLISHED_HANDPICKED_FAILURE:
      return {
          ...state, isGetPublishedHandPickedLoading: false
      }

      case constants.GET_HANDPICKED_ITEM_REQUEST:
        return {
            ...state, isGetHandPickedItemLoading: true
        }
    case constants.GET_HANDPICKED_ITEM_SUCCESS:
        return {
            ...state, isGetHandPickedItemLoading: false, handPickedItem: action.payload
        }
    case constants.GET_HANDPICKED_ITEM_FAILURE:
        return {
            ...state, isGetHandPickedItemLoading: false
        }

    case "RESET_APP":
      return { ...initialState };
    default:
      return state;
  }
};
export default handpickedReducer;
