import React, {useState} from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Typography, Input, Button, Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import { messageSeller } from "../Actions/userAction";
import { getChatsWithSeller } from "../Actions/chatsAction";
import { useHistory } from "react-router-dom";
const { TextArea } = Input;
export const MessageSeller = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const history = useHistory();
  const { showMessageSellerDialog, onCancelMessageSellerMethod, lotDetail } = props;
  const { _id: lotId, seller} = lotDetail || {}
  const { _id: sellerID} = seller || {}
  const { isMessageSellerLoading, failureMessage, isAuthenticated, currentUser } = useSelector((state) => {
    const { isMessageSellerLoading, failureMessage, currentUser } = state.user;
    const { isAuthenticated } = state.auth;
    return {
      isMessageSellerLoading, failureMessage, isAuthenticated, currentUser
    };
  });
  const { _id: currentUserId} = currentUser
  const [open, setOpen] = useState(false);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {text: ''}
  });
  const handleCancel = () => {
    onCancelMessageSellerMethod();
  };
  const handleMessageSeller = (data) => {
    data.user = sellerID
    data.lotId = lotId
    data.buyerId = currentUserId
    if (isAuthenticated) {
    dispatch(getChatsWithSeller(data, successCallBack, failureCallBack));
    }
    else {
      history.push('/onboarding')
    }
  }
  const successCallBack = () => {
    reset() 
    handleCancel()
    history.push('/chat')
  };
  const failureCallBack = () => {};
  return (
    <>
      <Modal
        title={
          <Typography className='pLBrand'>
          Message seller
          </Typography>
        }
        visible={showMessageSellerDialog}
        onCancel={() => handleCancel()}
        centered
        footer={null}
        width={600}
        className="messageSellerParent"
      >
        <form onSubmit={handleSubmit(handleMessageSeller)}>
          <Row>
            <Col>
              <Typography className='mAOTitle'>
                To: Markid reNEWED
              </Typography>
            </Col>
          </Row>
          <Row gutter={[0, 12]}>
            <Col span={24}></Col>
            <Col span={24}>
              <Controller
                as={TextArea}
                name="message"
                control={control}
                rows={4}
                placeholder="Write your message here"
                className='messageSellerInput'
              />
            </Col>
          </Row>

<Row justify='center' gutter={[0,48]}>
  <Col span={24}></Col>
  <Col>
  {/* {successMessage &&
  <Alert 
  onClose={handleClose}
  open={open}
  message={successMessage} 
  type="success" 
  showIcon 
  />
  } */}
{failureMessage && 
<Alert 
onClose={handleClose}
open={open}
message={failureMessage} 
type="error" 
showIcon />
}
  </Col>
</Row>
          <Row justify="end" gutter={[8, 30]}>
            <Col span={24}></Col>
            {/* <Col>
              <Button type="text" icon={<PaperClipOutlined />}>
                ATTACH IMAGES
              </Button>
            </Col> */}
            <Col>
            <Spin indicator={antIcon} spinning={isMessageSellerLoading}>
              <Button 
              htmlType='submit'
              className='squarebutton'>
                SEND
              </Button>
              </Spin>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};
