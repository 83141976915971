import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Button, Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useForm, Controller } from "react-hook-form";
import FacebookIcon from '../assets/Facebook.jpg';
import InstagramIcon from '../assets/Instagram.jpg';
import TwitterIcon from '../assets/twitter.jpg';
import ContactUsImage from "../assets/ContactUsImage.jpg";
import CUTitleImg from "../assets/CUTitleImg.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { ContactUS } from "../Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import lang from "../Utils/metatags.json"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TextArea } = Input
const ContactUs = () => {
  const { isXS } = useBreakpoints();
  const dispatch = useDispatch()
  const history = useHistory()
  const {innerHeight, innerWidth} = window;
  const { isAuthenticated, isContactUsLoading, successMessage } = useSelector((state) => {
    const { isAuthenticated } = state.auth;
    const { isContactUsLoading, successMessage } = state.user
    return {
      isAuthenticated,
      isContactUsLoading,
      successMessage
    };
  });
  const [open, setOpen] = useState(false);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const handleContactUs = (data) => {
    if (isAuthenticated) {
    dispatch(ContactUS(data, successCallBack, failureCallBack));
    }
    else {
      history.push('/onboarding')
    }
  }
  const successCallBack = () => {
    reset() 
  };
  const failureCallBack = () => {};
  return (
    <div style={{
      overflow:'hidden', 
      height: isXS ? 'unset' : innerHeight - 150, 
      }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{lang.contactUs.title}</title>
        <meta name="description" content={lang.contactUs.description} />
      </Helmet>
    <div className="pCardBackgound">
      <Row 
      justify="center"
        gutter={[0, {xs:20, sm:20, md:50, lg:50, xl:50, xxl:50}]}>
        <Col span={24}></Col>
        {!isXS && 
        <Col>
          <div className='cUCardOne'>
            <LazyLoadImage style={{height:innerHeight - 250}} effect="blur" alt="" src={ContactUsImage} />
          </div>
        </Col>
        }
        <Col>
        <Spin style={{top: 200, color:'#FF533F'}} indicator={antIcon} spinning={isContactUsLoading}>
          <div className='cUCardTwo' 
          style={{
            overflow: isXS ? 'unset':'hidden', 
            overflowY: isXS ? 'unset' : 'scroll', 
            height: isXS ? '100%' : innerHeight - 250,
          }}
          >
            <form onSubmit={handleSubmit(handleContactUs)}>
            <Row align="middle" gutter={[8, {xs:10, sm:10, md:16, lg:16, xl:16, xxl:16}]}>
              <Col span={24}></Col>
              <Col>
                <h1 className='cUTitle'>
                  Contact us
                </h1>
              </Col>
              <Col>
                <img className="contactImage" alt="" src={CUTitleImg} />
              </Col>
            </Row>
            <Row gutter={[8, {xs:25, sm:25, md:30, lg:30,xl:30, xxl:30}]}>
              <Col span={24}></Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Row gutter={[0, 0]}>
                  <Typography className="addAddressInputTitle">Name</Typography>
                </Row>
                <Row gutter={[0, {xs:15, sm:15, md:12, lg:12,xl:12, xxl:12}]}>
                  <Col span={24}></Col>
                  <Controller as={Input} name="name" placeholder="Enter your first name" control={control} size='large' className='addAddressInput' />
                </Row>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Row gutter={[0, {xs:10, sm:10, md:0, lg:0,xl:0, xxl:0}]}>
                  <Col span={24}/>
                  <Typography className="addAddressInputTitle">Email</Typography>
                </Row>
                <Row gutter={[0, {xs:15, sm:15, md:12, lg:12,xl:12, xxl:12}]}>
                  <Col span={24}></Col>
                  <Controller as={Input} name="email" placeholder="Enter your email id" control={control} size='large' className='addAddressInput' />
                </Row>
              </Col>
            </Row>
            <Row gutter={[8, {xs:35, sm:35, md:30, lg:30,xl:30, xxl:30}]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Row gutter={[0, 0]}>
                  <Typography className="addAddressInputTitle">Message</Typography>
                </Row>
                <Row gutter={[0, {xs:15, sm:15, md:12, lg:12,xl:12, xxl:12}]}>
                  <Col span={24}></Col>
                  <Controller as={TextArea} rows={isXS ? 8 : 5} name="text" placeholder="Enter your message" control={control} size='large' className='addAddressInput' />
                </Row>
              </Col>
              </Row>
              {successMessage &&
              <Row gutter={[8, {xs:25, sm:25, md:40, lg:40,xl:40, xxl:40}]} justify='center'>
                <Col span={24}></Col>
                <Col>
                <Alert 
                onClose={handleClose}
                open={open}
                message={successMessage}
                type="success"
                showIcon
                 />
                </Col>
                </Row>
}

              <Row gutter={[8, {xs:25, sm:25, md:40, lg:40,xl:40, xxl:40}]}>
                <Col span={24}></Col>
                <Col>
                  <Button htmlType='submit' size='large' className='cUSubmitButton'>
                    Submit
                  </Button>
                </Col>
              </Row>
              <Row gutter={[{xs:25, sm:25, md:0, lg:0,xl:0, xxl:0}, {xs:0, sm:0, md:50, lg:50,xl:50, xxl:50}]} justify='space-between'>
                <Col span={24}></Col>
                <Col xs={isXS && 12}>
                <Row gutter={[0,{xs:50, sm:50, md:0, lg:0, xl:0, xxl:0}]}>
                  <Col span={24}/>
                  <Col>
                    <Typography className='cUKey'>
                    Email
                    </Typography>
                    </Col>
                    </Row>
                  <Row gutter={[0,5]}>
                    <Col span={24}></Col>
                    <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                    <Typography className='cUVal'>
                      info@markid.io
                    </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col xs={isXS && 12}>
                <Row gutter={[0,{xs:50, sm:50, md:0, lg:0, xl:0, xxl:0}]}>
                  <Col span={24}/>
                  <Col>
                    <Typography className='cUKey'>
                      Address
                    </Typography>
                  </Col>
                  </Row>
                  <Row gutter={[0,5]}>
                    <Col span={24}></Col>
                    <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                      <Typography className='cUVal'>
                      68, 34th Street, Suite B534, New York, NY 11232
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col>
                <Row gutter={[0,{xs:25, sm:25, md:0, lg:0, xl:0, xxl:0}]}>
                  <Col span={24}/>
                  <Col>
                    <Typography className='cUKey'>
                        Call us
                    </Typography>
                  </Col>
                  </Row>
                  <Row gutter={[0,5]}>
                    <Col span={24}></Col>
                    <Typography className='cUVal'>
                      52473432
                    </Typography>
                  </Row>
                </Col>
              </Row>
              <Col span={24}>
              <Row gutter={[0,0]} justify={isXS ? "center" : "space-between"} align="middle">
                <Col 
                xs={{order:2, span:24}}
                sm={{order:2, span:24}}
                md={{order:1, span:12}}
                lg={{order:1, span:12}}
                xl={{order:1, span:12}}
                xxl={{order:1, span:12}}
                 className='cUMarkidRights'>
                  <Row gutter={[0, {xs:18,sm:18,md:42,lg:42,xl:42, xxl:42}]} justify={isXS && "center"}>
                  <Col span={24}></Col>
                    <Col>
                      © 2021 Markid All rights reserved
                  </Col>
                  </Row>
                </Col>
                <Col
                 xs={{order:1, span:24}}
                 sm={{order:1, span:24}}
                 md={{order:2, span:12}}
                 lg={{order:2, span:12}}
                 xl={{order:2, span:12}}
                 xxl={{order:2, span:12}}
                >
                  <Row justify={isXS ? "center" : "end"}  gutter={[20, {xs:50,sm:50,md:42,lg:42,xl:42, xxl:42}]} align="top">
                  <Col span={24}></Col>
                    <Col>
                      <LazyLoadImage effect="blur" src={TwitterIcon} alt=""/>
                    </Col>
                    <Col>
                      <LazyLoadImage effect="blur" src={FacebookIcon} alt=""/>
                    </Col>
                    <Col>
                      <LazyLoadImage effect="blur" src={InstagramIcon} alt=""/>
                    </Col>
                  </Row>
                </Col>
                <Col span={15}></Col>
              </Row>
              </Col>
              </form>
          </div>
          </Spin>
        </Col>
        <Col span={24}></Col>
      </Row>
    </div>
    </div>
  );
};
export default ContactUs;
