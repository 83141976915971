import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Input, Button, Alert, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useBreakpoints } from "./../../react-responsive-hooks/index";
import { useForm, Controller } from "react-hook-form";
import NotFoundSearch from "../../assets/new-assets/noresults-found.png";
import { useHistory } from "react-router-dom";
import "./ResultNotFound.css"
import { Link, useParams, useLocation } from "react-router-dom";
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from "react-redux";
import { getAllLots, getAllmarkidProducts } from "../../Actions/lotsAction";
import ProductTop from "../searchpartner";
import { getAllpartnerproducts } from "../../Actions/partnerProductsAction";
const ResultNotFound = (props) => {
  const history = useHistory();
  const {
    currentUser,
    providerToken,
    currentCart,
    allproducts,
    searchedproducts,
    recentSearches,
    messages,
    popularSearches,
    isAuthenticated,
    allChats,
    currentLocationZip,
  } = useSelector((state) => {
    const { allproducts, searchedproducts } = state.products;
    const { providerToken, isAuthenticated } = state.auth;
    const { currentUser, currentLocationZip } = state.user;
    const { currentCart } = state.cart;
    const { allChats } = state.chats;
    const { messagesByChatId } = state.chats;
    const { messages } = messagesByChatId;
    const { recentSearches, popularSearches } = state.searches;
    return {
      currentUser,
      providerToken,
      currentCart,
      allproducts,
      searchedproducts,
      recentSearches,
      popularSearches,
      isAuthenticated,
      messages,
      allChats,
      currentLocationZip,
    };
  });
  const {
    
    updateResetAllFilter,
   
  } = props;
  const { partnerproducts } = allproducts || { partnerproducts: [] };
  const { fullName, photo, zipCode: UserZipCode } = currentUser || {};
  // const { providerToken } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const { search: urlSearch } = params;

  const location = useLocation();
  const refreshParams = new URLSearchParams(location.search);
  const refresh = refreshParams.get("refresh");
  const userImgName = fullName ? fullName.charAt(0) : null;
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [token, setToken] = useState(providerToken);

  const [search, setSearch] = useState(
    urlSearch && urlSearch.length > 0 ? urlSearch : ""
  );
  const [zipCode, setZipCode] = useState(currentLocationZip);
  const [zipError, setZipError] = useState("");
  const [newZip, setNewZip] = useState("");
  const [visibleInput, setVisibleInput] = useState(false);
  const [newsearch, setnewsearch] = useState("");
  const [items, setItems] = useState("");
 
  const handleSearch = (data) => {
    dispatch(getAllmarkidProducts({ search: data }))
    history.push(`/search-new/${localStorage.getItem("search") != null ? localStorage.getItem("search") : '' }?refresh=true`)
    // dispatch(getAllLots ({ search: data }))
  }
  const reset = () => {
		window.location.reload()
	};
  
  return (
    <Card bordered={false} className="rNFCardBg">
      <div className="app-wrapper">
        <Row justify='center' gutter={[0, { xs: 0, sm: 0, md: 37, lg: 37, xl: 37, xxl: 37 }]}>
          <Col span={24}></Col>
          <Col xxl={24} xl={24} sm={24} xs={24}>
            <Card bordered={false} className='rNFCard'>

              <Row justify='center' >
                <div className='rNFImgBg'>
                  <Row justify='center' >
                    <img alt="" src={NotFoundSearch} className='rNFImage' />
                  </Row>
                </div>
                {/* <img alt="" src={MarkidCommunityImg} /> */}
              </Row>
              <Row justify='center' gutter={[0, 0]}>
                <Col span={24}></Col>
                <Col sm={15} xs={15}>
                  <Typography className='rNFTitle'>
                    Oops - There’s Nothing Here!
                  </Typography>
                </Col>
              </Row>
              <Row justify='center' gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col sm={16} xs={16}>
                  {/* <Typography className='rNFDes'>
                    Keep checking back to see if the item you’re looking for has been listed
                  </Typography> */}
                </Col>
              </Row>
              <Row justify='center' gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col className="btn-col">

                  {/* <Button className="button sellButton button-primary"
                    onClick={handleSearch}

                  >
                    search more</Button> */}
                    <Button
                    
                      className={"button button-primary  pLCartButton"}
                      onClick={() => handleSearch()}
                    >
                      Search markid products 
                    </Button>
                </Col>
                <Col span={24}></Col>
                <Col className="btn-col">


                <Button type="text" className="resetAllButton" onClick={() => reset()}>
              Reset All Filters
            </Button>

                </Col></Row>


            </Card>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default ResultNotFound;
