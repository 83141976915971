import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Button, Alert, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useBreakpoints } from './../../react-responsive-hooks/index';
import { useForm, Controller } from "react-hook-form";
import NotFoundSearch from "../../assets/new-assets/noresults-found.png";
import "./ResultNotFound.css"
const ResultNotFound = () => {
  const { isXS, isSM } = useBreakpoints();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
    },
  });
  return (
    <Card bordered={false} className="rNFCardBg">
      <div className="app-wrapper">
        <Row justify='center' gutter={[0, { xs: 0, sm: 0, md: 37, lg: 37, xl: 37, xxl: 37 }]}>
          <Col span={24}></Col>
          <Col xxl={24} xl={24} sm={24} xs={24}>
            <Card bordered={false} className='rNFCard'>

              <Row justify='center' >
                <div className='rNFImgBg'>
                  <Row justify='center' >
                    <img alt="" src={NotFoundSearch} className='rNFImage' />
                  </Row>
                </div>
                {/* <img alt="" src={MarkidCommunityImg} /> */}
              </Row>
              <Row justify='center' gutter={[0, 0]}>
                <Col span={24}></Col>
                <Col sm={15} xs={15}>
                  <Typography className='rNFTitle'>
                    Oops - There’s Nothing Here!
                  </Typography>
                </Col>
              </Row>
              <Row justify='center' gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col sm={16} xs={16}>
                  <Typography className='rNFDes'>
                    Keep checking back to see if the item you’re looking for has been listed
                  </Typography>
                </Col>
              </Row>

            </Card>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default ResultNotFound;
