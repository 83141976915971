import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Spin, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "../App.css";
import { Carousel } from "antd";
import sellerhub from "../assets/new-assets/How1.png";
import toptips1 from "../assets/new-assets/toptips1.png";
import toptips2 from "../assets/new-assets/toptips2.png";
import toptips3 from "../assets/new-assets/toptips3.png";
import shape from "../assets/new-assets/styleBg.png";
import How2 from "../assets/new-assets/How2.png";
import How3 from "../assets/new-assets/How3.png";
import How4 from "../assets/new-assets/How4.png";
import SignIn from "../pages/SignIn";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { joinCommunity } from "../Actions/userAction";
import { TypeOneReciver } from "./TypeOfMessages";
export const ToptipsforSelling = (props) => {
  const history = useHistory();
  const carouselRef = useRef(null);
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  const calculateTop = () => {
    let width = 0;
    if (isXS) {
      width = 302;
    } else if (isLG) {
      width = 335;
    } else if (isSM) {
      width = 200;
    } else if (isMD) {
      width = 200;
    } else if (isXL) {
      width = 320;
    } else if (isXXL) {
      width = 500;
    }
    return width;
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  return (
    <Row>
      <Col span={24} className="heroSlider">
        {(isXL || isLG || isXXL || isMD) && (
          <div>
            <section class="about_us_section">
              <div class="row">
                <div class="container">
                  <img
                    src={shape}
                    alt="advisors-img"
                    width="100%"
                    height="160px"
                  />
                  <div class="Toptipsbackground">
                    <Row
                      gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
                      justify="center"
                    >
                      <Col span={24}></Col>
                      {/* <Col span={24}></Col> */}
                      {/* <Col span={24}></Col> */}
                      <Col>
                        <Typography className="featuredSeller p title">
                          Selling tips
                        </Typography>
                      </Col>
                      <Col></Col>
                    </Row>
                    <div className="Howcontainer">
                      <Row
                        gutter={{ xs: 15, sm: 16, md: 24, lg: 32 }}
                        className="imagecenter display-flex-columns"
                      >
                        <Card className="Howtipscard">
                          <Col
                            className="gutter-row min-width-100-percent"
                            id="n-imagecontainer"
                            span={6}
                          >
                            <img
                              src={toptips1}
                              alt="advisors-img"
                              width="54px"
                              height="79px"
                            />

                            <span>
                              <Typography className="Toptexttitle">
                                Take well-lit photos and write a detailed
                                description
                              </Typography>
                              <p className="Toptextdesc">
                                Make sure your item photos are taken in a
                                well-lit area and the item is clear and fully
                                visible. Include all known details in your
                                description including the year, model, color,
                                and condition including any specific signs of
                                wear and/or tear.
                              </p>
                            </span>
                          </Col>
                        </Card>

                        <Col span={1}></Col>
                        <Card className="Howtipscard">
                          <Col
                            className="gutter-row min-width-100-percent"
                            id="n-imagecontainer"
                            span={6}
                          >
                            <img
                              src={toptips2}
                              alt="advisors-img"
                              width="60px"
                              height="79px"
                            />

                            <span>
                              <Typography className="Toptexttitle">
                                Price competitively
                              </Typography>
                              <p className="Toptextdesc">
                                Take into consideration the condition, model,
                                and year bought when pricing your item.
                                Reference other similar sold items to see what
                                your item is going for to help adequately price
                                your item.
                              </p>
                            </span>
                          </Col>
                        </Card>

                        <Col span={1}></Col>
                        <Card className="Howtipscard">
                          <Col
                            className="gutter-row min-width-100-percent"
                            id="n-imagecontainer"
                            span={6}
                          >
                            <img
                              src={toptips3}
                              alt="advisors-img"
                              width="60px"
                              height="79px"
                            />

                            <span>
                              <Typography className="Toptexttitle">
                                Allow buyers to send you offers.
                              </Typography>
                              <p className="Toptextdesc">
                                Buyers are more likely to reach out when you are
                                more flexible with your pricing. By allowing
                                offers, you can communicate with more buyers for
                                a faster sale.
                              </p>
                            </span>
                          </Col>
                        </Card>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        {(isXS || isSM) && (
          <div>
            {" "}
            <Row
              gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
              justify="center"
            >
              <Col>
                <Typography className="Seller p title">
                  Selling tips
                </Typography>
              </Col>
              <Col></Col>
            </Row>
            <div></div>
            <Card className="comCard">
              <Row justify="center">
                <Col>
                  {/* style={{ height: isXS ? 100 : 129, width: isXS ? 90 : 100 }} */}
                  <div className="img-wrap">
                    <img className="toptips-img" alt="" src={toptips1} />
                  </div>
                  <h2 className="toptipsName">
                    {" "}
                    Take well-lit photos and write a detailed description
                  </h2>
                  <p className="toptipsDes">
                    {" "}
                    Make sure your item photos are taken in a well-lit area and
                    the item is clear and fully visible. Include all known
                    details in your description including the year, model,
                    color, and condition including any specific signs of wear
                    and/or tear.
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className="comCard">
              <Row justify="center" style={{ marginTop: "45px" }}>
                <Col>
                  {/* style={{ height: isXS ? 100 : 129, width: isXS ? 90 : 100 }} */}
                  <div className="img-wrap">
                    <img className="toptips-img" alt="" src={toptips2} />
                  </div>
                  <h2 className="toptipsName"> Price competitively</h2>
                  <p className="toptipsDes">
                    {" "}
                    Take into consideration the condition, model, and year
                    bought when pricing your item. Reference other similar sold
                    items to see what your item is going for to help adequately
                    price your item.
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className="comCard">
              <Row justify="center" style={{ marginTop: "45px" }}>
                <Col>
                  {/* style={{ height: isXS ? 100 : 129, width: isXS ? 90 : 100 }} */}
                  <div className="img-wrap">
                    <img className="toptips-img" alt="" src={toptips3} />
                  </div>
                  <h2 className="toptipsName">
                    {" "}
                    Allow buyers to send you offers.
                  </h2>
                  <p className="toptipsDes">
                    {" "}
                    Buyers are more likely to reach out when you are more
                    flexible with your pricing. By allowing offers, you can
                    communicate with more buyers for a faster sale.
                  </p>
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </Col>

      {/* For Mobile  */}
    </Row>
  );
};
