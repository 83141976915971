export const clothingSizeList = [
    { value: "Preemie" },
    { value: "Newborn" },
    { value: "0-3 Months" },
    { value: "3-6 Months" },
    { value: "6-9 Months" },
    { value: "9-12 Months" },
    { value: "12-18 Months" },
    { value: "18-24 Months" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
    { value: "6" },
    { value: "7" },
    { value: "XXS" },
    { value: "XS" },
    { value: "S" },
    { value: "8" },
    { value: "9" },
    { value: "10" },
    { value: "11" },
    { value: "12" },
    { value: "14" },
    { value: "16+" },
    { value: "M" },
    { value: "L" },
    { value: "XL" },
    { value: "XXL" },
    { value: "Onesize" }
];

export const shoesSizeList = [
    { value: "Newborn" },
    { value: "0 - 3 Months" },
    { value: "3 - 6 Months" },
    { value: "6 - 9 Months" },
    { value: "9 - 12 Months" },
    { value: "12-18 Months" },
    { value: "18-24 Months" },
    { value: "7.5T" },
    { value: "8T" },
    { value: "8.5T" },
    { value: "9T" },
    { value: "9.5T" },
    { value: "10T" },
    { value: "10.5T" },
    { value: "11T" },
    { value: "11.5T" },
    { value: "12" },
    { value: "12.5" },
    { value: "13" },
    { value: "2" },
    { value: "2.5" },
    { value: "3" },
    { value: "3.5" },
    { value: "4" },
    { value: "4.5" },
    { value: "5" },
    { value: "5.5" },
    { value: "6" },
    { value: "6.5" },
    { value: "7" },
    { value: "Onesize" },
]

export const booksAgeList = [
    { value: "Infants" },
    { value: "Toddlers" },
    { value: "PRE-K" },
    { value: "Kindergarten" },
    { value: "1st Grade" },
    { value: "2nd Grade" },
    { value: "3rd Grade" },
    { value: "4th Grade" },
    { value: "5th Grade" },
    { value: "6th Grade" },
    { value: "7th Grade" },
    { value: "8th Grade" },
    { value: "9th Grade" },
    { value: "10th Grade" },
    { value: "11th Grade" },
    { value: "12th Grade" },
]

export const toysAndCostumesAgeList = [
    { value: "Newborn" },
    { value: "0-3 Months" },
    { value: "3-6 Months" },
    { value: "6-9 Months" },
    { value: "9-12 Months" },
    { value: "12-18 Months" },
    { value: "18-24 Months" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
    { value: "6" },
    { value: "7" },
    { value: "8" },
    { value: "9" },
    { value: "10" },
    { value: "11" },
    { value: "12" },
    { value: "14" },
    { value: "16+" },
    { value: "Onesize" },
]

export const maternitySizeList = [
    { value: "0" },
    { value: "2" },
    { value: "4" },
    { value: "6" },
    { value: "8" },
    { value: "10" },
    { value: "12" },
    { value: "14" },
    { value: "16" },
    { value: "18" },
    { value: "20" },
    { value: "22" },
    { value: "24" },
    { value: "26" },
    { value: "28" },
    { value: "30" },
    { value: "32" },
    { value: "34" },
    { value: "36" },
    { value: "XXS" },
    { value: "XS" },
    { value: "S" },
    { value: "M" },
    { value: "L" },
    { value: "XL" },
    { value: "XXL" },
    { value: "Onesize" },
]

export const oneSizeList = [
    { value: "Onesize" }
] 