import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const CommunityGuide = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>Markid - Community Guidelines</title>
      </Helmet>
      <div class="container" id="f-container" className="contentwrap"><div class="communityGuide_content_wrapper col-lg-8 col-sm-12"><span class=""><h1 id="markid-community-guidelines">Markid Community Guidelines</h1>
        <h4 id="a-community-for-parents-👶">A Community For Parents 👶</h4>
        <p>Markid is a resource dedicated to parents of children under 18 and parents-to-be.  Therefore, we request that all items posted are relevant to children under 18.</p>
        <h4 id="pictures-and-descriptions-📷">Pictures and Descriptions 📷</h4>
        <p>It’s always helpful to present pictures of items against a white background and provide an accurate description of the same. Not only is it informative to a potential buyer but also increases the chances of a sale!</p>
        <h4 id="communication-📲">Communication 📲</h4>
        <p>It’s perfectly natural if you happen to change your mind about a transaction (either as a seller or a buyer). In such a scenario, make sure to message your fellow parent in advance so that they can plan accordingly.</p>
        <h4 id="be-polite-and-courteous-when-messaging-💬">Be Polite and Courteous when Messaging 💬</h4>
        <p>The messaging feature is there to facilitate seamless coordination between the buyer and the seller. Please be respectful in your communication with your fellow parents.</p>
        <h4 id="respect-your-fellow-parents-decisions-🤍">Respect your Fellow Parent’s Decisions 🤍</h4>
        <p>We’re all eager to buy and sell our used products, and sometimes parents may message to inquire. As buyers, please refrain from pressurizing a sale onto your fellow parents. Similarly, as sellers, do respect the final price set by your fellow parents.</p>
        <h4 id="respect-your-fellow-parents-time-📅">Respect your Fellow Parents’ Time 📅</h4>
        <p>For Local pick ups, please show up on time. If you’re running late, just drop a message to your fellow parent and keep them informed.</p>
        <h4 id="resolving-issues-✅">Resolving Issues ✅</h4>
        <p>If you face a problem with an item you have purchased, try to work it out with the selling parent in a calm, respectful way. In most cases, they’ll be open to finding a solution.</p>
        <p>In addition, when interacting with buyers, sellers, or the Markid team, please refrain from any of the following ❌:</p>
        <ul>
          <li>Profanity or hate speech;</li>
          <li>Threats of violence or actual violence;</li>
          <li>Harassment, including but not limited to sexual harassment or any comments related to another person’s looks, relationship status, etc;</li>
          <li>Comments not related to the transaction;</li>
          <li>Pressurizing a fellow parent to engage in a transaction;</li>
          <li>Spam and unsolicited offers;</li>
          <li>Impersonation of another buyer or seller;</li>
          <li>Misleading, fake, or malicious communication;</li>
          <li>Use of disallowed content on your public profile, including offensive or vulgar profile names and photos, or names and photos that infringe on the intellectual property rights of others;</li>
          <li>Use of the Markid name, Markid images, or any other trademarks owned by Markid;</li>
          <li>Personal contact details other than those required to complete a transaction;</li>
          <li>Posting another person's private information, including contact information.</li>
        </ul>
        <p>Markid may, at its discretion, remove content that violates the above guidelines. In severe cases of repeated violations, we may suspend or cancel an account or take other actions. For more information, please check out our <a href="/terms-and-conditions">Terms and Conditions</a>.</p>
      </span></div></div>

    </>
  );
};



export default CommunityGuide;