import { combineReducers } from "redux";
import authReducer from './authReducer';
import consignmentReducer from './consignmentReducer';
import paymentReducer from './paymentReducer';
import lotsReducer from './lotsReducer';
import partnerProductsReducer from "./partnerProductsReducer";
import userReducer from './userReducer';
import cartReducer from './cartReducer';
import orderReducer from './orderReducer';
import brandsReducer from './brandsReducer';
import categoriesReducer from './categoriesReducer';
import groupsReducer from './groupReducer';
import communitiesReducer from './communitiesReducer';
import ageGroupReducer from './ageGroupReducer';
import searchesReducer from './searchesReducer';
import chatsReducer from './chatsReducer';
import offerReducer from './offerReducer';
import handpickedReducer from './handpickedReducer';
import partnerProductlistReducer from './partnerProductlistReducer';
import partnerDetailsReducer from './partnerDetailsReducer'
import productDetailsReducer from "./productTypeReducer";
import filtersReducer from "./filtersReducer";
import shippingReducer from "./shippingReducer";
import BlogReducer from "./BlogReducer";
const rootReducer = combineReducers({
    auth: authReducer,
    consignment: consignmentReducer,
    payment: paymentReducer,
    lots: lotsReducer,
    partnerProducts: partnerProductsReducer, 
    user: userReducer,
    cart: cartReducer,
    order: orderReducer,
    brands: brandsReducer,
    categories: categoriesReducer,
    groups: groupsReducer,
    communities: communitiesReducer,
    ageGroups: ageGroupReducer,
    searches: searchesReducer,
    chats: chatsReducer,
    offer: offerReducer,
    handPicked: handpickedReducer,
    products:partnerProductlistReducer,
    partnerDetails: partnerDetailsReducer,
    productTypeDetails: productDetailsReducer,
    filters: filtersReducer,
    shipping: shippingReducer,
    Blogs:BlogReducer,
})

export default rootReducer;