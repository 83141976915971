/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Row, Col, Typography, Spin, Empty, Dropdown, Menu, Card, Pagination } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SellerInfoCard } from "../components/SellerInfoCard";
import { SellerProductCard } from "../components/SellerProductCard";
import { LoadingOutlined } from "@ant-design/icons";
import { similarItems } from "../Actions/userAction";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { AllSimillarLots } from "../components/AllSimillarLots";
const { Text } = Typography
const SimillarProduct = () => {
	const { isXS } = useBreakpoints();
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const dispatch = useDispatch();
	const params = useParams()
	const location = useLocation();
	const paramsToken = new URLSearchParams(location.search)
	const history = useHistory();
	const token = paramsToken.get("token")
	const { providerToken, similarLots, isSimilarItemsLoading } = useSelector((state) => {
		const { providerToken } = state.auth;
		const {
			similarLots,
			isSimilarItemsLoading,
		} = state.user;
		return {
			similarLots,
			isSimilarItemsLoading,
		};
	});
	const perPage = 16;
	const [pageValue, setPageValue] = useState({
		min: 0,
		max: 16
	})
	if (token) {
		localStorage.setItem("access_token", token);
		if (!providerToken) {
			dispatch({ type: "SET_PROVIDER_TOKEN", payload: { token } })
		}
	}
	useEffect(() => {
		window.scrollTo(0, 0);
		if (id) {
			dispatch(similarItems({ id }))
		}
	}, []);
	const breakPoints = [
		// { width: 1, itemsToShow: 1 },
		{ width: 550, itemsToShow: 2, itemsToScroll: 2 },
		{ width: 768, itemsToShow: 4, itemsToScroll: 4 },
		{ width: 1200, itemsToShow: 4 }
	];

	const { id } = params;
	const handlePagination = (val) => {
		setPageValue({
			min: (val - 1) * perPage,
			max: val * perPage
		});
	}
	return (
		<div>
			<Spin style={{ top: 200, color: '#FF533F' }} indicator={antIcon} spinning={(isSimilarItemsLoading)}>
				<Card className='selletLotsBg'>
					<Row gutter={[0, 50]} justify='space-between'>
						<Col span={24}></Col>
						<Col>
							<Typography className='listingItemtext'>
								Simillar Items
							</Typography>
						</Col>
					</Row>
					<Row gutter={[35, 35]}>
						<Col span={24}></Col>
						{similarLots &&
							similarLots.slice(pageValue.min, pageValue.max).map((val, i) => {
								return (
									<Col xl={6} lg={6} md={12} sm={12} xs={12} key={i}>
										<AllSimillarLots similarItems={val} />
									</Col>
								)
							})}
						{similarLots && similarLots.length <= 0 &&
							<Empty />
						}
					</Row>

					<Row justify="end" align="middle" gutter={[0, 32]} className="profile-pagination">
						<Col span={24}></Col>
						<Pagination
							style={{ background: 'transpernt' }}
							size={isXS ? 'small' : 'default'}
							total={similarLots && similarLots.length}
							defaultCurrent={1}
							defaultPageSize={perPage}
							showSizeChanger={false}
							onChange={handlePagination}
						/>
					</Row>

				</Card>
			</Spin>
		</div>
	);
};
export default SimillarProduct;
