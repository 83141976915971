import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Typography,
} from "antd";
import "../styles/landing.css";
const { Text, Title, Paragraph } = Typography;
const HowItWorks = () => {
  return (
    <>
      <Row justify="center" gutter={[16, 48]}>
        <Col span={24}></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Title level={1} strong>
            How it works
          </Title>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 8]}>
        <Col span={24}></Col>
		<Col span={2}></Col>
        <Col xl={7} lg={7} md={7} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>
          <Row justify="start">
            <Text>1</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>Submit your item</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              When you're ready to sell, just a description of your item and our
              team of experts will review and confirm your submission within one
              business day.
            </Text>
          </Row>
        </Col>

        <Col xl={7} lg={7} md={7} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>

          <Row justify="start">
            <Text>2</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>Door step pickup</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              We'll handle the heavy lifting, just meet us at the door
            </Text>
          </Row>
        </Col>

        <Col xl={7} lg={7} md={7} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>

          <Row justify="start">
            <Text>3</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>Get paid</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              Out of sight, out of mind, right intoyour wallet. Let us do all
              the work to get the most value out of your items.
            </Text>
          </Row>
        </Col>
      </Row>

      <Row  justify='center' gutter={[0, 48]}>
        <Col span={24}></Col>

          {/* <Col span={2}></Col> */}
          <Col xl={10} lg={10} md={10} sm={20} xs={20}>
            <Card style={{ backgroundColor: "#d8d8d8", height: 331 }} />
          </Col>
          <Col
            xl={10}
            lg={10}
            md={10}
            sm={20}
            xs={20}
          >
            <Card style={{ backgroundColor: " #f6f6f6" }}>
              <Row justify="start" gutter={[0, 32]}>
                <Col span={24}></Col>
                <Col>
                  <Title level={4} strong>
                    Why sell with us?
                  </Title>
                </Col>
              </Row>
              <Row justify="start" gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Easy and time saving</Text>
                </Col>
              </Row>

              <Row justify="start" gutter={[0, 8]}>
                <Col span={24}></Col>

                <Text style={{ textAlign: "start" }}>
                  Once you submit your item details online, We'll take it from
                  there literally! Free pick up or drop off to our warehouse in
                  Brooklyn
                </Text>
              </Row>

              <Row justify="start" gutter={[0, 32]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Button
                    size="large"
                    className={'squarebutton'}
                  >
                    ARRANGE A FREE PICK UP
                  </Button>
                </Col>
                <Col span={24}></Col>
              </Row>
            </Card>
          </Col>

      </Row>

      <Row justify="center" gutter={[16, 48]}>
        <Col span={24}></Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>

          <Row justify="start">
            <Text>1</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>We bring the suppot</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              our team of experts will verify,price,promote and sell the item
            </Text>
          </Row>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>

          <Row justify="start">
            <Text>2</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>Faster sales</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              Reach more buyers cross platform promotion
            </Text>
          </Row>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>

          <Row justify="start">
            <Text>3</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>Higher earning</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              We provide the best, earn the best price for your items. You can
              earn up to 80% of your item's sale price!
            </Text>
          </Row>
        </Col>
        <Col span={24}></Col>
      </Row>

      <Row justify="center" gutter={[16, 2]}>
        <Col span={24}></Col>
        <Col xl={6} lg={6} md={6} sm={24} xs={24}></Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>

          <Row justify="start">
            <Text>4</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>Sustainable selling made easy</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              Every used kid's item that is given a new life with another family
              reduces the carbon footprint of the item by X% on average.
            </Text>
          </Row>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: "#d8d8d8", height: 120 }} />
            </Col>
          </Row>

          <Row justify="start">
            <Text>5</Text>
          </Row>
          <Row justify="start">
            <Title level={5}>Give back to your community</Title>
          </Row>
          <Row justify="start">
            <Text style={{ textAlign: "start" }}>
              For every sale made with markid, we donate 1% of our own revenue
              to non-profits.
            </Text>
          </Row>
        </Col>

        <Col xl={6} lg={6} md={6} sm={24} xs={24}></Col>
      </Row>

      <Row justify="center" gutter={[16, 40]}>
        <Col span={24}></Col>
        <Col xl={15} lg={15} md={15} sm={20} xs={20}>
          <Paragraph>
            All Markid reNEWED products are thoughtfully inspected, lovingly
            restored, meticulously sanitized to work and feel like new.
          </Paragraph>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 0]}>
        <Col xl={15} lg={15} md={15} sm={20} xs={20}>
          <Paragraph>
            Plus you have our marked promise behind you-Markid buyer protection
            ensures you get the best value for your purchase. If you're not
            entirely satisfied with your buy. We would happily accept a return
          </Paragraph>
        </Col>
      </Row>

      <Row>
        <Col span={24}></Col>
        <Row justify="center" gutter={[0, 48]}>
          <Col xl={10} lg={10} md={10} sm={20} xs={20}>
            <Card style={{ backgroundColor: "#d8d8d8", height: 430 }} />
          </Col>
          <Col
            xl={10}
            lg={10}
            md={10}
            sm={20}
            xs={20}
          >
            <Card style={{ backgroundColor: " #f6f6f6" }}>
              <Row justify="start" gutter={[0, 32]}>
                <Col span={24}></Col>
                <Row justify="start">
                  <Text style={{ textAlign: "start" }}>
                    We offer a hand-picked selection of x of the best, most
                    truted and loved brands. Our aim is to create a curated
                    experience for parents to shop and buy only the best product
                    for their kids-easily sustainably and at the best prices.
                  </Text>
                </Row>
              </Row>
              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Text strong style={{ textAlign: "start" }}>
                  High quality preowned kids' products from the brands you love,
                  at great reNEWED prices
                </Text>
                <Col span={24}></Col>
              </Row>

              <Row justify="start" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Text style={{ textAlign: "start" }}>
                  Our aim is to transform the way parents buy and sell kids'
                  preowned products. As more and more parents embrace conscious
                  consumption, sustainabillity and maximising their kids'
                  experiences-we aim to lead the way in revolutionising the
                  resale marketplace for kids'products.
                </Text>
                <Col span={24}></Col>
                <Col span={24}></Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Row>

      <Row justify="center" gutter={[0, 48]}>
        <Col span={24}></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Title level={1} strong>
            What we accept
          </Title>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 16]}>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Strollers</Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>High chairs</Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Bouncers</Card>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 16]}>
        <Col span={24}></Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>
            Cribs and bassinets
          </Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Loungers</Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Gear</Card>
        </Col>
      </Row>

      <Row justify="center" gutter={[0, 48]}>
        <Col span={24}></Col>
        <Col xl={24} lg={24} md={24} sm={20} xs={20}>
          <Title level={1} strong>
            Brands we accept
          </Title>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 16]}>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Uppababy</Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Snoo</Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Stokke</Card>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 16]}>
        <Col span={24}></Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Uppababy</Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Snoo</Card>
        </Col>
        <Col xl={6} lg={6} md={6} sm={20} xs={20}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>Stokke</Card>
        </Col>
      </Row>

      <Row justify='center' gutter={[8, 48]}>
        <Col span={24}></Col>
		<Col span={24}></Col>
        <Col xl={3} lg={3} md={3} sm={24} xs={24}>
            <Button className={'squarebutton'}>
              GET STARTED
            </Button>
        </Col>
        <Col xl={3} lg={3} md={3} sm={24} xs={24}>
            <Button className={'buttonwithborder'}>LEARN MORE</Button>
        </Col>
		<Col span={24}></Col>
		<Col span={24}></Col>
		</Row>
		
    </>
  );
};
export default HowItWorks;
