/*eslint-disable*/
import React, { useRef, useState } from "react";
import { Row, Col, Card, AutoComplete, Input, Typography, Button } from "antd";
import { useSelector } from "react-redux";
import "../App.css";
import { MenuOutlined } from "@ant-design/icons";
import MenuBar from "../assets/MenuBar.svg";
import MHBanner1 from "../assets/new-assets/slider-1.jpg";
import MHBanner2 from "../assets/new-assets/Banner.png";
import MHBanner3 from "../assets/new-assets/slider-3.jpg";
import MobileBannerNew from "../assets/new-assets/mobile_banner.jpg";
import MobileBanner1 from "../assets/MobileBanner1.jpg";
import MobileBanner2 from "../assets/new-assets/Mobile-Banner.png";
import MobileBanner3 from "../assets/MobileBanner3.jpg";
import Illustration from "../assets/Illustration.svg";
//import Carousel from 'react-elastic-carousel';
import { Carousel } from 'antd';
import { FeaturedSeller } from "./FeaturedSeller";
import DotContainer from "./DotComponent";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { useHistory } from "react-router-dom";
const { Title } = Typography;
const MainHomeBanner = (props) => {
	const history = useHistory()
	const {currentLocationZip } = useSelector(
	  (state) => {
		const { currentLocationZip } = state.user;
		return {
		  currentLocationZip,
		};
	  }
	);
    let zipCode = currentLocationZip || "10100";
	const carouselRef = useRef(null)
	const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
	const calculateTop = () => {
		let width = 0;
		if (isXS) {
			width = 302
		}
		else if (isLG) {
			width = 335
		}
		else if (isSM) {
			width = 200
		}
		else if (isMD) {
			width = 200
		}
		else if (isXL) {
			width = 320
		}
		else if (isXXL) {
			width = 500
		}
		return width
	}
	const breakPoints = [
		{ width: 1, itemsToShow: 1 },
		{ width: 550, itemsToShow: 1, itemsToScroll: 1 },
		{ width: 768, itemsToShow: 1, itemsToScroll: 1 },
		{ width: 1200, itemsToShow: 1 }
	];

	const myDots = ({ pages, activePage, onClick }) => {
		return (
			<Row justify="end" style={{ width: '100%', padding: "5px 15px", zIndex: 9 }}>
				{pages.map((page) => {
					const isActivePage = activePage === page;
					return (
						<div
							key={page}
							style={{
								borderRadius: "50%",
								background: isActivePage ? "#B5B5B5" : "#E4E4E4",
								width: 15,
								height: 15,
								margin: "70px 5px 0px",
								cursor: 'pointer'
							}}
							onClick={() => onClick(page)}
						//   active={isActivePage}
						/>
					);
				})}
			</Row>
		);
	}
	// console.log({ isXS, isLG, isSM, isXL, isXXL })
	const onNextStart = (currentItem, nextItem) => {
		if (currentItem.index && nextItem === 2) {
			// we hit the last item, go to first item
			//   carouselRef.current.goTo(0);
			setTimeout(() => {
				try {
					carouselRef.current.goTo(0)
				} catch (error) { }
			}, 1500) // same tim
		}
	};

	const onPrevStart = (currentItem, nextItem) => {
		if (currentItem.index === nextItem.index) {
			// we hit the first item, go to last item
			carouselRef.current.goTo(items.length);
		}
	};
	return (
		<Row className="app-wrapper style2">
			<Col span={24}>


				{(isXL || isLG || isXXL || isMD) && <Carousel className="noPaddingCarousel heroSlider"
					breakPoints={breakPoints}
					renderPagination={myDots}
					itemPadding={[0, 0, 0, 0]}
					itemsToShow={1}
					itemsToScroll={1}
					showArrows={false}
					outerSpacing={0}
					//autoPlaySpeed={2500}
					//enableAutoPlay={true}
					ref={carouselRef}
					//	onPrevStart={onPrevStart}
					//	onChange={onNextStart}
					autoplay
				>
					{/* src={MHBanner1} src={MHBanner2} src={MHBanner3}*/}
					<img alt="" src={MHBanner1} className='bannerImgWeb slider1' />
					<img alt="" src={MHBanner2} className='bannerImgWeb slider2' />
					<img alt="" src={MHBanner3} className='bannerImgWeb slider3' />

				</Carousel>}
				{(isXS || isSM) && <Carousel className="noPaddingCarousel"
					breakPoints={breakPoints}
					renderPagination={({ pages, activePage, onClick }) => {
						return (
							<Row justify='center' style={{ width: '100%', paddingTop: '68%', paddingBottom: 0, zIndex: 10 }} >
								{pages.map(page => {
									const isActivePage = activePage === page
									return (
										<div
											style={{
												borderRadius: "50%",
												background: isActivePage ? "#B5B5B5" : "#E4E4E4",
												width: 15,
												height: 15,
												margin: "70px 5px 0px",
												cursor: 'pointer'
											}}
											key={page}
											onClick={() => onClick(page)}
										// active={isActivePage}
										/>

									)
								})}
							</Row>
						)
					}}
					itemPadding={[0, 0, 0, 0]}
					itemsToShow={1}
					itemsToScroll={1}
					showArrows={false}
					outerSpacing={0}
					//autoPlaySpeed={2500}
					//enableAutoPlay={true}
					ref={carouselRef}
					//onPrevStart={onPrevStart}
					//onChange={onNextStart}
					autoplay
				>

					<img alt="" src={MobileBanner1} className='bannerImgMob' />
					<img alt="" src={MobileBanner2} className='bannerImgMob' />
					<img alt="" src={MobileBanner3} className='bannerImgMob' />


				</Carousel>}
			</Col>
			{/*position: 'absolute', width: "100%", top: calculateTop(), */}
			<Col span={24} style={{ position: 'absolute', width: "100%", top: 'calculateTop()' }} className="herobanner">
				<Row className="mHBParent">
					<div className="app-wrapper">
						<Col xxl={{ span: 8, offset: 0 }} xl={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} md={{ span: 10, offset: 0 }} sm={{ span: 22, offset: 0 }} xs={{ span: 22, offset: 0 }}>

							<Card bordered={false} className='mHBCard'>
								{/* gutter={[0, { xs: 27.57, sm: 27.57, md: 40, lg: 40 }]} */}
								<Row>
									<Col>
										<Typography level={4} className='mHBTitle'>The parent</Typography>
										<Typography level={4} className='mHBTitle'>marketplace</Typography>
									</Col>
								</Row>
								{/* gutter={[0, { xs: 14.86, sm: 14.86, md: 22, lg: 22, xl: 18, xxl: 18 }]} */}
								<Row >
									<Col span={24}></Col>
									<Col>
										<Typography className='mHBDes'>
											Join a community of parents to shop and sell kid’s preloved, gently used items easily and seamlessly.
										</Typography>
									</Col>
								</Row>
								{/* gutter={[{ xl: 12.42, xs: 12.42, sm: 12.42, md: 18, lg: 18 }, { xs: 17.81, sm: 17.81, md: 25, lg: 25, xl: 25, xxl: 25 }]} */}
								<Row>
									<Col span={24}></Col>
									<Col>
										<Button className='button shopNowButton button-primary'
											onClick={() => history.push(`/search-new?refresh=true&zipCode=${zipCode}`)}>
											Shop Now
										</Button>
									</Col>
								</Row>
								<Row gutter={[0, { xs: 14.86, sm: 14.86, md: 22, lg: 56, xl: 56, xxl: 56 }]}>
									<Col span={24}></Col>
									<Col className="image-wrap">
										<img alt="" src={Illustration} className='IllustrationWeb' />
										<img alt="" src={Illustration} className='IllustrationMob' />
									</Col>
								</Row>
							</Card>


						</Col>
					</div>
				</Row>
			</Col>

		</Row>


	);
};
export default MainHomeBanner;
