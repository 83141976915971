import React from "react";
import { Row, Col, Button, Card, Typography, Divider,  Image } from "antd";
import "../App.css";
import ProfileAddressEditIcon from "../assets/ProfileAddressEditIcon.svg";
import ProfileRemoveIcon from "../assets/ProfileRemoveIcon.svg";
import ProfileSetasDefaultIcon from "../assets/ProfileSetasDefaultIcon.svg";

const ProfileMarkidReNewed = (props) => {

    return (

        <Card bordered={false} className="profileCard">
            <Row justify="space-between" gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col span={10}>
                    <Typography className="profileDetail">Address</Typography>
                </Col>
                <Col span={10} style={{ textAlign: 'end' }}>
                    <Button className="buttonEdit">+ Add address</Button>
                </Col>
            </Row>
            <Row gutter={[32, 32]} justify="space-between">
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Card className="profileAddressCard" >
                        <Row gutter={[0, 16]}>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Typography className="profileAddressName">William G Keller</Typography>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'end' }}>
                                <Button className="profileAddressButton">Default</Button>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Typography className="profile-address">
                                    William G Keller, Evergreen, Street, York
                                    town Heights, New York, 10001
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Divider />
                        </Row>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Image preview={false} src={ProfileAddressEditIcon} />
                                <Button  className="profile-button">Edit</Button>
                                <Divider type="vertical" style={{height:20}}/> 
                                </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <Image preview={false} src={ProfileRemoveIcon} />
                                <Button className="profile-button"> Remove</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card className="profileAddressCard" >
                        <Row gutter={[0, 16]}>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Typography className="profileAddressName">William G Keller</Typography>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Typography className="profile-address">
                                    William G Keller, Evergreen, Street, York
                                    town Heights, New York, 10001
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Divider />
                        </Row>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Image preview={false} src={ProfileAddressEditIcon} />
                                <Button  className="profile-button">Edit</Button>
                                <Divider type="vertical" style={{height:20}}/> 
                                </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <Image preview={false} src={ProfileRemoveIcon}/>
                                <Button className="profile-button"> Remove</Button>
                                <Divider type="vertical" style={{height:20}}/> 
                            </Col>
                            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Image preview={false} src={ProfileSetasDefaultIcon} />
                                <Button className="profile-button">Set as Default</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card className="profileAddressCard" >
                        <Row gutter={[0, 16]}>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Typography className="profileAddressName">William G Keller</Typography>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Typography className="profile-address">
                                    William G Keller, Evergreen, Street, York
                                    town Heights, New York, 10001
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Divider />
                        </Row>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Image preview={false} src={ProfileAddressEditIcon} />
                                <Button  className="profile-button">Edit</Button>
                                <Divider type="vertical" style={{height:20}}/> 
                                </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <Image preview={false} src={ProfileRemoveIcon} />
                                <Button className="profile-button"> Remove</Button>
                                <Divider type="vertical" style={{height:20}}/> 
                            </Col>
                            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Image preview={false} src={ProfileSetasDefaultIcon} />
                                <Button className="profile-button">Set as Default</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Card>

    );
};
export default ProfileMarkidReNewed;
