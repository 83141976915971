/*eslint-disable*/
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Select,
  Input,
} from "antd";
import { AppleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "../App.css";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { createOrder } from "../Actions/orderAction";
import _ from "lodash";
const { Text, Title } = Typography;
const schema = yup.object().shape({
  name_on_card: yup.string("Name on card require"),
});
const PaymentForm = (props) => {
  const dispatch = useDispatch();
  const { prevMethod, nextMethod } = props
  const history = useHistory();
  const { latestClientSecret, currentAddressId, currentUser, currentCart, currentCartId, useCredits, balance } = useSelector((state) => {
    const { latestClientSecret, currentAddressId, currentCart, currentCartId, useCredits, currentUserWallet } = state.cart;
    const { currentUser } = state.user;
    const { balance } = currentUserWallet
    return {
      latestClientSecret,
      currentAddressId,
      currentUser,
      currentCart,
      currentCartId,
      currentUserWallet,
      useCredits,
      balance
    };
  });
  const [error, setError] = useState({});
  const [cdcForm, setCDCForm] = useState(false);
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { name_on_card: '', card_number: '', mm: '', yy: '', cvv: '' }
  });
  const stripe = useStripe();
  const elements = useElements();

  const handleStripeSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      { error }
      // console.log('[error]', error);
    } else {
      { paymentMethod }
      // console.log('[PaymentMethod]', paymentMethod);
    }
    payWithCard(stripe, paymentMethod, cardElement)
  };
  const payWithCard = (stripe, paymentMethod, cardElement) => {
    stripe.confirmCardPayment(latestClientSecret, { payment_method: { card: cardElement } }).then((result) => {
      if (result.error) {
        setError(error)
      }
      else {
        { result }
        // console.log({result})
        const data = {}
        data.cartId = currentCartId
        data.stripePiId = latestClientSecret
        data.addressId = currentAddressId
        data.buyerUserId = currentUser._id
        data.buyerEmail = currentUser.email
        data.buyerPhone = currentUser.phone
        data.orderTotalInCents = result.paymentIntent.amount
        data.usedMarkidCreditInCents = useCredits ? balance : 0.0
        data.usedMarkidCredit = useCredits
        data.lotItems = _.map(currentCart, '_id');
        data.paymentMethodType = result.paymentIntent.payment_method_types[0]
        dispatch(createOrder(data, successCallBack, failureCallBack));
        // nextMethod()
      }
    })
  }
  const successCallBack = () => {
    dispatch({ type: "RESET_CART" })
    nextMethod()
  };
  const failureCallBack = () => { };
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#87bbfd"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
  };
  return (
    <>
      <Row align='middle'>
        <Col span={24}></Col>
        <Col>
          <Row gutter={[8, 0]} align='middle'>
            <Col>
              <ArrowLeftOutlined className='back-icon' onClick={() => prevMethod()} />
            </Col>
            <Col>
              <Row gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col>
                  <Title level={4} className='your-cart-title'>
                    Select your payment method
                  </Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[8, 16]}>
        <Col span={24}></Col>
        <Col>
          <Button size='large' className='pay-button' onClick={() => setCDCForm(true)}>Credit or Debit card</Button>
          {/* <Button size='large' className='pay-button'>Stripe</Button> */}
        </Col>
        {/* <Col>
        <Button size='large' className='pay-button'>Venmo</Button>
        </Col> */}
        {/* <Col>
        <Button size='large' icon={<AppleOutlined />} className='pay-button'>Pay</Button>
        </Col> */}
        {/* <Col>
        <Button size='large' className={'squarebutton'}>Credit or Debit card</Button>
        </Col> */}
      </Row>
      <Divider />
      {cdcForm && (
        <div>
          <Row gutter={[0, 8]}>
            <Col span={24}></Col>
            <Col>
              <Title level={4} className='notify-me'>
                Enter Details
              </Title>
            </Col>
          </Row>
          <form onSubmit={(e) => handleSubmit(handleStripeSubmit(e))} id="stripeForm">
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>

              <Col span={24}>
                <Row>
                  <Text className='brand-name'>Name on Card</Text>
                </Row>
                <Controller as={Input} name="name_on_card" control={control} />
                {errors.name_on_card && (
                  <span style={{ color: "red" }}>
                    Name on card is required
                  </span>
                )}
              </Col>
              <Col style={{ width: "30em" }}>
                <CardElement
                // options={CARD_OPTIONS} 
                // onChange={(e) => {
                //   setError(e.error);
                //   setCardComplete(e.complete);
                // }}
                />
              </Col>
            </Row>
            {/* <Row gutter={[32,8]}>
          <Col span={24}></Col>
        <Col xl={22} lg={22} md={22} sm={24} xs={24}>
            <Row>
              <Text className='brand-name'>Card Number</Text>
            </Row>
            <Controller as={Input} name="card_number" control={control} />
          </Col>
          </Row>
        <Row gutter={[0,32]} justify='space-between'>
          <Col span={24}></Col>
          <Col>
  
            <Row gutter={[12,0]}>
              <Col>
              <Row>
              <Text className='brand-name'>Month</Text>
              </Row>
            <Controller as={Select} name="mm" control={control} placeholder="MM"/>
            </Col>
            <Col>
            <Row>
              <Text className='brand-name'>Year</Text>
              </Row>
            <Controller
              as={Select}
              name="yy"
              control={control}
              placeholder="YY"
            />
            </Col>
            </Row>
          </Col>
          <Col xl={5} lg={5} md={5} sm={5} xs={5}>
            <Row>
              <Text className='brand-name'>CVV</Text>
            </Row>
            <Controller as={Input} name="cvv" control={control} />
          </Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8} style={{lineHeight: 5}}>
          <Button className='add-card-button'>
                  ADD CARD
                </Button>
          </Col>
        </Row> */}
          </form>
        </div>
      )}
    </>
  );
};
export default PaymentForm;
