import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Card, Typography, Divider, Image, Spin, Input, Alert, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../App.css";
import { useBreakpoints } from './../react-responsive-hooks/index';
import ProfileAddressEditIcon from "../assets/ProfileAddressEditIcon.svg";
import ProfileRemoveIcon from "../assets/ProfileRemoveIcon.svg";
// import ProfileSetasDefaultIcon from "../assets/ProfileSetasDefaultIcon.svg";
import { addAddress, removeAddress, updateAddress } from "../Actions/userAction";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const US_STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];
export const ProfileAddresses = (props) => {
  const dispatch = useDispatch();
  const { isXS } = useBreakpoints();
  const { isAddAddressLoading, addresserror } = useSelector((state) => {
    const { isAddAddressLoading, addresserror } = state.user;
    return {
      isAddAddressLoading,
      addresserror,
    };
  });
  const [viewAddress, setViewAddress] = useState(true)
  const [addAddressForm, setAddAddressForm] = useState(false)
  const [updateAddressForm, setUpdateAddressForm] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState('');
  // const [defaultAddress, setDefaultAddress] = useState(0)
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
  }, [addresserror]);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { UserAddresses } = props
  const handleOpenAddAddress = () => {
    setViewAddress(false)
    setUpdateAddressForm(false)
    setAddAddressForm(true)
  }
  const handleUpdateAddressForm = (addressId) => {
    setViewAddress(false)
    setAddAddressForm(false)
    setUpdateAddressForm(true)
    setSelectedAddressId(addressId)
  }
  const handleRemoveAddress = (addressId) => {
    dispatch(removeAddress(addressId));
  }
  const handleSetAsDefaultAddress = (addressId) => {
    // dispatch(removeAddress(addressId));
  }
  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: "",
      address: "",
      secondAddress: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
    },
  });
  const handleAddAddress = (data) => {
    dispatch(addAddress(data, successCallBackAddress, failureCallBackAddress));
  };
  const successCallBackAddress = () => {
    setAddAddressForm(false)
    setViewAddress(true)
    // setAddressForm(false);
    // dispatch({ type: "SELECT_ADDRESS_ID", payload: { addressId } })
    reset();
  };
  const failureCallBackAddress = () => { };
  const handleCancel = () => {
    setAddAddressForm(false)
    setUpdateAddressForm(false)
    setViewAddress(true)
  }
  return (
    <>
      {viewAddress &&
        <Card bordered={false} className="ProfileAddressCard-parent">
          <Row justify="space-between" align="middle" gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
            <Col span={24}></Col>
            <Col span={10}>
              <Typography className="profileDetail">Address</Typography>
            </Col>
            <Col span={10} style={{ textAlign: 'end' }}>
              <Button className="buttonEdit"
                onClick={() => handleOpenAddAddress()}>
                + Add address</Button>
            </Col>
          </Row>
          <Row gutter={[32, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]} justify="space-between">
            <Col span={24}></Col>
            {UserAddresses &&
              UserAddresses.map((val, i) => (
                <Col xl={12} lg={12} md={12} sm={24} xs={24} key={i}>
                  <Card bordered={false} className="profileAddressCard" >
                    <Row gutter={[0, 16]} align="middle">
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className="profileAddressName text-capitalize">{val.name}</Typography>
                      </Col>
                      {i === 0 &&
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'end' }}>
                          <Button className="btn-default">Default</Button>
                        </Col>
                      }
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="profile-address">
                          {val.name}, {val.address}, {val.secondAddress}{val.secondAddress ? "," : ""} {val.city}, {val.state}, {val.zip}
                        </Typography>
                      </Col>
                    </Row>
                    {/* <Row gutter={[0, 16]}>
                      <Divider />
                    </Row> */}
                    <Row className="profile-actions">
                      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Button className="profile-button btn-edit"
                          onClick={() => handleUpdateAddressForm(val._id)}><Image preview={false} src={ProfileAddressEditIcon} /><span className="btn-text">Edit</span></Button>
                        <Button className="profile-button btn-remove" onClick={() => handleRemoveAddress(val._id)}><Image preview={false} src={ProfileRemoveIcon} /><span className="btn-text">Remove</span></Button>

                      </Col>
                      {/* <Col xxl={8} xl={8} lg={8} md={6} sm={12} xs={12}>
                        <Image preview={false} src={ProfileAddressEditIcon} />
                        <Button className="profile-button"
                          onClick={() => handleUpdateAddressForm(val._id)}>Edit</Button>
                        <Divider type="vertical" style={{ height: 20 }} />
                      </Col>
                      <Col xxl={16} xl={16} lg={16} md={8} sm={12} xs={12}>
                        <Image preview={false} src={ProfileRemoveIcon} />
                        <Button className="profile-button" onClick={() => handleRemoveAddress(val._id)}> Remove</Button>
                        <Divider type="vertical" style={{ height: 20 }} />
                      </Col> */}
                      {/* <Col xxl={16} xl={16} lg={16} md={8} sm={12} xs={12}>
                                    <Image preview={false} src={ProfileRemoveIcon} />
                                    <Button className="profile-button" onClick={() => handleSetAsDefaultAddress(val._id)}> Set as default</Button>
                                </Col> */}
                      {/* <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Image preview={false} src={ProfileSetasDefaultIcon} />
                                <Button className="profile-button"
                                // onClick={() => setDefaultAddress(val._id === i)}
                                >
                                    Set as Default</Button>
                            </Col> */}
                    </Row>
                  </Card>
                </Col>
              ))}
            {/* <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Card className="profileAddressCard" >
                        <Row gutter={[0, 16]}>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Typography className="profileAddressName">William G Keller</Typography>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Typography className="profile-address">
                                    William G Keller, Evergreen, Street, York
                                    town Heights, New York, 10001
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Divider />
                        </Row>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Image preview={false} src={ProfileAddressEditIcon} />
                                <Button className="profile-button">Edit</Button>
                                <Divider type="vertical" style={{ height: 20 }} />
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <Image preview={false} src={ProfileRemoveIcon} />
                                <Button className="profile-button"> Remove</Button>
                                <Divider type="vertical" style={{ height: 20 }} />
                            </Col>
                            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Image preview={false} src={ProfileSetasDefaultIcon} />
                                <Button className="profile-button">Set as Default</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Card className="profileAddressCard" >
                        <Row gutter={[0, 16]}>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Typography className="profileAddressName">William G Keller</Typography>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Typography className="profile-address">
                                    William G Keller, Evergreen, Street, York
                                    town Heights, New York, 10001
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Divider />
                        </Row>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Image preview={false} src={ProfileAddressEditIcon} />
                                <Button className="profile-button">Edit</Button>
                                <Divider type="vertical" style={{ height: 20 }} />
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <Image preview={false} src={ProfileRemoveIcon} />
                                <Button className="profile-button"> Remove</Button>
                                <Divider type="vertical" style={{ height: 20 }} />
                            </Col>
                            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Image preview={false} src={ProfileSetasDefaultIcon} />
                                <Button className="profile-button">Set as Default</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col> */}
          </Row>
        </Card>
      }

      {addAddressForm &&
        <Card bordered={false} className="ProfileAddressCard">
          <Spin indicator={antIcon} spinning={isAddAddressLoading} style={{ color: '#FF533F' }}>
            <Row gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography className="profileDetail">
                  Add new Address
                </Typography>
              </Col>
            </Row>

            <Row gutter={[0, 0]} className="profileAddressForm">
              <Col span={24}></Col>
              <Col span={24}>
                <form onSubmit={handleSubmit(handleAddAddress)}>
                  <Row gutter={[30, 0]}>
                    <Col span={24}></Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
                      <Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle text-capitalize">Name</Typography>
                      </Row>
                      <Row gutter={[0, { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 }]}>
                        <Col span={24}></Col>
                        <Controller as={Input} name="name" placeholder="Type your name" control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                        {errors.name && errors.name.type === "required" && (<span role="alert" className="error">Name is required</span>)}
                      </Row>
                    </Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24} className="ml-10">
                      <Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">Phone number</Typography>
                      </Row>
                      <Row gutter={[0, { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 }]}>
                        <Col span={24}></Col>
                        <Controller as={Input} name="phone" placeholder='Type your phone number' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                        {errors.phone && errors.phone.type === "required" && (<span role="alert" className="error">Phone numebr is required</span>)}
                      </Row>
                    </Col>
                  </Row>
                  {addresserror && (
                    <Row justify="center" gutter={[0, 8]}>
                      <Col span={24}></Col>
                      <Col>
                        <Alert
                          type="error"
                          onClose={handleClose}
                          open={open}
                          message={addresserror.error.message}
                        ></Alert>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={[30, 8]}>
                    <Col span={24}></Col>
                    <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
                      <Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">Street address or P.O Box</Typography>
                      </Row>
                      <Row gutter={[0, { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 }]}>
                        <Col span={24}></Col>
                        <Controller as={Input} name="address" placeholder='Address line 1' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                        {errors.address && errors.address.type === "required" && (<span role="alert" className="error">Address is required</span>)}
                      </Row>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={24} xs={24} className="ml-10">
                      <Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">City</Typography>
                      </Row>
                      <Row gutter={[0, { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 }]}>
                        <Col span={24}></Col>
                        <Controller as={Input} name="city" placeholder='Type your city name' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                        {errors.city && errors.city.type === "required" && (<span role="alert" className="error">City is required</span>)}
                      </Row>
                    </Col>
                    {/* <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">Address 2</Typography>
                      </Row>
                      <Row gutter={[0, { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 }]}>
                        <Col span={24}></Col>
                        <Controller as={Input} name="secondAddress" placeholder='Address line 2' control={control} size='large' className='addAddressInput' />
                      </Row>
                    </Col> */}
                  </Row>

                  <Row gutter={[30, 8]}>
                    <Col span={24}></Col>

                    <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                      <Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">State</Typography>
                      </Row>
                      <Row gutter={[0, { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 }]}>
                        <Col span={24}></Col>
                        <Controller
                          as={Select}
                          control={control}
                          options={US_STATES}
                          name="state"
                          placeholder='Select your state name'
                          className='stateSelector'
                          // style={{ width: "100%" }}
                          size='large'
                          rules={{ required: true }}
                        />
                        {errors.state && errors.state.type === "required" && (<span role="alert" className="error">State is required</span>)}
                      </Row>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={24} xs={24} className="ml-10">
                      <Row gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">Zipcode</Typography>
                      </Row>
                      <Row gutter={[0, { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 }]}>
                        <Col span={24}></Col>
                        <Controller as={Input} name="zip" placeholder='Type your Zipcode' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                        {errors.zip && errors.zip.type === "required" && (<span role="alert" className="error">Zipcode is required</span>)}
                      </Row>
                    </Col>

                  </Row>

                  <Row gutter={[8, { xs: 45, sm: 45, md: 50, lg: 50, xl: 50, xxl: 50 }]} justify={isXS ? 'center' : 'center'}>
                    <Col span={24}></Col>
                    {/* <Col>
                      <Button onClick={() => handleCancel()} size='large'>Cancel</Button>
                    </Col> */}
                    <Col className="no-padding">
                      <Button htmlType="submit" className='button button-primary saveChanges' size='large'>Save</Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Spin>
        </Card>
      }
      {updateAddressForm &&
        <Card bordered={false} className="profileCard no-padding">
          <UpdateAddress
            updateAddressForm={updateAddressForm}
            setUpdateAddressForm={setUpdateAddressForm}
            setViewAddress={setViewAddress}
            setAddAddressForm={setAddAddressForm}
            selectedAddressId={selectedAddressId}
          />
        </Card>
      }
    </>
  );
};

export const UpdateAddress = (props) => {
  const { updateAddressForm, setUpdateAddressForm, setViewAddress, setAddAddressForm, selectedAddressId } = props
  const dispatch = useDispatch();
  const { currentUser, isUpdateAddressLoading } = useSelector((state) => state.user);
  const { addresses } = currentUser || {};
  const the_address = addresses.filter((address) => address._id === selectedAddressId);
  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    mode: 'all'
  });
  const handleUpdateAddress = (data) => {
    dispatch(updateAddress(selectedAddressId, data, successCallBackUpdateAddress, failureCallBackUpdateAddress));
  };
  const successCallBackUpdateAddress = () => {
    setUpdateAddressForm(false);
    setViewAddress(true)
    reset();
  };
  const failureCallBackUpdateAddress = () => { };
  const handleCancel = () => {
    setAddAddressForm(false)
    setUpdateAddressForm(false);
    setViewAddress(true)
  }
  return (
    <>
      {updateAddressForm && (
        <>
          <Card bordered={false} className="ProfileAddressCard">

            <Spin indicator={antIcon} spinning={isUpdateAddressLoading} style={{ color: '#FF533F' }}>
              <Row gutter={[0, 0]}>
                <Col span={24}></Col>
                <Col>
                  <Typography className="profileDetail">
                    Edit address
                  </Typography>
                </Col>
              </Row>

              <Row gutter={[0, 0]} className="profileAddressForm">
                <Col span={24}></Col>
                <Col span={24}>
                  <form onSubmit={handleSubmit(handleUpdateAddress)}>
                    <Row gutter={[30, 8]}>
                      <Col span={24}></Col>
                      <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">Name</Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller as={Input} defaultValue={the_address[0].name} name="name" placeholder="Type your name" control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                          {errors.name && errors.name.type === "required" && (<span role="alert" className="error">Name is required</span>)}
                        </Row>
                      </Col>
                      <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24} className="ml-10">
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">Phone number</Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller as={Input} defaultValue={the_address[0].phone} name="phone" placeholder='Type your phone number' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                          {errors.phone && errors.phone.type === "required" && (<span role="alert" className="error">Phone numebr is required</span>)}
                        </Row>
                      </Col>
                    </Row>
                    {/* {addresserror && (
                      <Row justify="center" gutter={[0, 8]}>
                        <Col span={24}></Col>
                        <Col>
                          <Alert
                            type="error"
                            onClose={handleClose}
                            open={open}
                            message={addresserror.error.message}
                          ></Alert>
                        </Col>
                      </Row>
                    )} */}
                    <Row gutter={[30, 8]}>
                      <Col span={24}></Col>
                      <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">Address</Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller as={Input} defaultValue={the_address[0].address} name="address" placeholder='Address line 1' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                          {errors.address && errors.address.type === "required" && (<span role="alert" className="error">Address is required</span>)}
                        </Row>
                      </Col>

                      <Col xl={10} lg={10} md={10} sm={24} xs={24} className="ml-10">
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">City</Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller as={Input} defaultValue={the_address[0].city} name="city" placeholder='Type your city name' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                          {errors.city && errors.city.type === "required" && (<span role="alert" className="error">City is required</span>)}
                        </Row>
                      </Col>
                      {/* <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">Address 2</Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller as={Input} defaultValue={the_address[0].secondAddress} name="secondAddress" placeholder='Address line 2' control={control} size='large' className='addAddressInput' />
                        </Row>
                      </Col> */}
                    </Row>

                    <Row gutter={[8, 8]}>
                      <Col span={24}></Col>

                      <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">State</Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller
                            as={Select}
                            control={control}
                            options={US_STATES}
                            defaultValue={the_address[0].state}
                            name="state"
                            placeholder='Select your state name'
                            className='stateSelector'
                            // style={{ width: "100%" }}
                            size='large'
                            rules={{ required: true }}
                          />
                          {errors.state && errors.state.type === "required" && (<span role="alert" className="error">State is required</span>)}
                        </Row>
                      </Col>
                      <Col xl={10} lg={10} md={10} sm={24} xs={24} className="ml-10">
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">Zipcode</Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller as={Input} defaultValue={the_address[0].zip} name="zip" placeholder='Type your Zipcode' control={control} size='large' className='addAddressInput' rules={{ required: true }} />
                          {errors.zip && errors.zip.type === "required" && (<span role="alert" className="error">Zipcode is required</span>)}
                        </Row>
                      </Col>

                    </Row>

                    <Row gutter={[8, { xs: 45, sm: 45, md: 50, lg: 50, xl: 50, xxl: 50 }]} justify="center">
                      <Col span={24}></Col>
                      {/* <Col>
                        <Button onClick={() => handleCancel()} size='large'>Cancel</Button>
                      </Col> */}
                      <Col>
                        <Button
                          htmlType="submit"
                          // onClick={() => handleUpdateAddress(the_address[0]._id)}
                          className='button button-primary saveChanges' size='large'>Save changes</Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Spin>
          </Card>
        </>
      )}
    </>
  );
};