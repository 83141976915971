import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Input,
  Row,
  Col,
  Divider,
  Collapse,
  Slider,
  Checkbox,
  Button,
  Badge,
} from "antd";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { useGaEvent } from "../hooks/useGaEvent";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";
import closeBtn from "../assets/new-assets/close-icon.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "../App.css";
import _ from "lodash";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrands } from "../Actions/brandsAction";
import { getAllCategories } from "../Actions/categoriesAction";
import { product } from "ramda";
import { getSizeFilter } from "../Actions/filtersAction";
// import { moreLotsFromThisPartner } from "../Actions/partnerProductsAction";
const { Panel } = Collapse;

const PFilteredItemMob = (props) => {
  const {
    // lots,
    itemType,
    selectedBrand,
    updateSelectedBrand,
    selectedGender,
    updateSelectedGender,
    selectedSize,
    updateSelectedSize,
    total,
    totalcount,
    // selectedAge ,updateSelectedAge,
    selectedCondition,
    updateSelectedCondition,
    selectedShipping,
    updateSelectedShipping,
    selectedPriceRange,
    updateSelectedPriceRannge,
    updateResetAllFilter,
    brandsByCategory,
    filtertags,
    setdisplaydata,
    resetclose,
    showfilterDialog,
    handledisplayAll,
    setshowfilterDialog,
  } = props;

  const sortBrandsByCategory = _.sortBy(brandsByCategory, "brand");
  const dispatch = useDispatch();
  let {
    yearOfDetails,
    brandsToShowInUI,
    partnerproducts,
    morePartnerProducts,
    sizeFilters,
  } = useSelector((state) => {
    let { yearOfDetails, partnerproducts, morePartnerProducts } =
      state.products;
    const { sizeFilters } = state.filters;
    const { allBrands } = state.brands;
    // const { allCategories } = state.categories
    return {
      // checkedValues,
      //brandsToShowInUI: allBrands,
      // allCategories,
      yearOfDetails,
      partnerproducts,
      morePartnerProducts,
      sizeFilters,
    };
  });
  const { gaPageView, gaEventClick } = useGaEvent();
  const checkdonPriceChange = (range) => {
    if (range === "$0 to $10") {
      gaEventClick("Filter by", "SwoondlePlp-price","$0 to $10")
      onChangedPriceRange([0, 10]);
      priceValues[0].value = true;
      priceValues[1].value = false;
      priceValues[2].value = false;

      setPriceValues(priceValues);
    }
    if (range === "$10 to $50") {
      gaEventClick("Filter by", "SwoondlePlp-price","$10 to $50")
      onChangedPriceRange([10, 50]);
      priceValues[1].value = true;
      priceValues[0].value = false;
      priceValues[2].value = false;
      setPriceValues(priceValues);
    }
    if (range === "$50 to $100") {
      gaEventClick("Filter by", "SwoondlePlp-price","$50 to $100")
      onChangedPriceRange([50, 100]);
      priceValues[2].value = true;
      priceValues[0].value = false;
      priceValues[1].value = false;
      setPriceValues(priceValues);
    }
  };
  const history = useHistory();
  const { product } = yearOfDetails || {};
  let { uniqueValues, minPrice, maxPrice } =
    product && product.length > 0 ? product[0] : {};
  const location = useLocation();

  // const [checkedValues, setCheckedValues] = useState('')
  if (uniqueValues) uniqueValues = uniqueValues.filter((el) => el !== null);
  /* 20211124 Varun: added filter to uniqueValues array to remove null values returned from api */
  // const categories = (_.uniqBy(lots, 'category')).filter(e =>  e.category)
  // const brands = allBrands;
  // const brands = (_.uniqBy(lots, 'brand')).filter(e =>  e.brand)
  const genders = [
    { gender: "Boy", value: "male" },
    { gender: "Girl", value: "female" },
    { gender: "Neutral", value: "neutral" },
  ];
  // const minimumPrice = _.minBy(lots, 'price');
  // const {price: minimumPriceVal} = minimumPrice || {}
  // const maximumPrice = _.maxBy(lots, 'price');
  // const {price: maximumPriceVal} = maximumPrice || {}
  const purchaseYear = uniqueValues && uniqueValues.sort();

  // const lotsByAge = (_.uniqBy(lots, 'age')).filter(e =>  e.age)
  const conditions = [
    { condition: "New", value: "new" },
    { condition: "Great", value: "great" },
    { condition: "Good", value: "good" },
    { condition: "Fair", value: "fair" },
  ];

  const [priceValues, setPriceValues] = useState([
    { title: "$0 to $10", value: false },
    { title: "$10 to $50", value: false },
    { title: "$50 to $100", value: false },
  ]);

  const [Brands, setBrands] = useState([]);
  const [BrandsList, setBrandsList] = useState([]);
  let brandArray = [];

  const [Sizes, setSizes] = useState([]);
  const [SizesList, setSizesList] = useState([]);
  let sizeArray = [];
  let tempSizeArray = [];

  // useEffect(() => {
  //   if (partnerproducts) {
  //     partnerproducts.map((productList) => {
  //       if (
  //         brandArray.indexOf(productList.brand) === -1 &&
  //         productList.brand != undefined &&
  //         productList.brand != ""
  //       ) {
  //         brandArray.push(productList.brand);
  //       }
  //     });
  //   }
  //   if(localStorage.getItem("Brands") != null && brandArray.length != JSON.parse(localStorage.getItem("Brands")).length){
  //     setBrandsList(JSON.parse(localStorage.getItem("Brands")))
  //   }
  //   else{
  //     setBrandsList(brandArray);
  //     localStorage.setItem("Brands", JSON.stringify(brandArray))
  //   }
  // }, [partnerproducts]);

  // useEffect(() => {
  //   if(localStorage.getItem("Brands") != null){
  //     setBrandsList(JSON.parse(localStorage.getItem("Brands")))
  //   }
  //   else{
  //     if (partnerproducts) {
  //       partnerproducts.map((productList) => {
  //         if (
  //           brandArray.indexOf(productList.brand) === -1 &&
  //           productList.brand != undefined &&
  //           productList.brand != ""
  //         ) {
  //           brandArray.push(productList.brand);
  //         }
  //       });
  //       setBrandsList(brandArray);
  //       localStorage.setItem("Brands", JSON.stringify(brandArray) )
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (partnerproducts) {
  //     partnerproducts.map((productList) => {
  //       if (brandArray.indexOf(productList.brand) === -1 && productList.brand != undefined && productList.brand != "") {
  //         brandArray.push(productList.brand);
  //       }
  //     });
  //   }
  //   if(localStorage.getItem("Brands") != null){
  //     if(brandArray[0] == JSON.parse(localStorage.getItem("Brands"))[0] && brandArray.length == JSON.parse(localStorage.getItem("Brands")).length){
  //       setBrandsList(JSON.parse(localStorage.getItem("Brands")))
  //     }
  //     else{
  //       setBrandsList(brandArray);
  //       localStorage.setItem("Brands", JSON.stringify(brandArray))
  //     }
  //   }
  //   else{
  //     setBrandsList(brandArray);
  //     localStorage.setItem("Brands", JSON.stringify(brandArray))
  //   }
  // }, []);

  // useEffect(()=>{
  //   if(partnerproducts){
  //     partnerproducts.map((productList)=>{
  //       if(brandArray.indexOf(productList.brand) === -1 && productList.brand != undefined && productList.brand != ''){
  //         brandArray.push(productList.brand)
  //       }
  //     })
  //     setBrandsList(brandArray)
  //   }
  // },[])

  // useEffect(()=>{
  //   if(partnerproducts){
  //     if(partnerproducts.length){
  //       dispatch(moreLotsFromThisPartner(partnerproducts[0].createdBy))
  //     }
  //   }
  // },[partnerproducts])

  useEffect(() => {
    if (morePartnerProducts) {
      morePartnerProducts.map((productList) => {
        if (
          brandArray.indexOf(productList.brand) === -1 &&
          productList.brand != undefined &&
          productList.brand != ""
        ) {
          brandArray.push(productList.brand);
        }
      });
    }
    if (partnerproducts) {
      partnerproducts.map((productList) => {
        if (
          brandArray.indexOf(productList.brand) === -1 &&
          productList.brand != undefined &&
          productList.brand != ""
        ) {
          brandArray.push(productList.brand);
        }
      });
    }
    if (localStorage.getItem("Brands") != null) {
      if (JSON.parse(localStorage.getItem("Brands")).length) {
        setBrandsList(JSON.parse(localStorage.getItem("Brands")));
      } else {
        setBrandsList(brandArray);
        localStorage.setItem("Brands", JSON.stringify(brandArray));
      }
    } else {
      setBrandsList(brandArray);
      localStorage.setItem("Brands", JSON.stringify(brandArray));
    }
  }, [morePartnerProducts, partnerproducts]);

  useEffect(() => {
    if (BrandsList.length) {
      BrandsList.map((brandVal) => {
        brandArray.push({ brand: brandVal, value: brandVal });
      });
      setBrands(brandArray);
    }
  }, [BrandsList]);

  // useEffect(() => {
  //   if (partnerproducts) {
  //     partnerproducts.map((productList) => {
  //       if (
  //         sizeArray.indexOf(productList.size) === -1 &&
  //         productList.size != undefined &&
  //         productList.size != ""
  //       ) {
  //         sizeArray.push(productList.size);
  //       }
  //     });
  //   }
  //   if(localStorage.getItem("Sizes") != null && sizeArray.length != JSON.parse(localStorage.getItem("Sizes")).length){
  //     setSizesList(JSON.parse(localStorage.getItem("Sizes")))
  //   }
  //   else{
  //     setSizesList(sizeArray);
  //     localStorage.setItem("Sizes", JSON.stringify(sizeArray) )
  //   }
  // }, [partnerproducts]);

  // useEffect(() => {
  //   if (partnerproducts) {
  //     partnerproducts.map((productList) => {
  //       if (sizeArray.indexOf(productList.size) === -1 &&productList.size != undefined &&productList.size != ""){
  //         sizeArray.push(productList.size);
  //       }
  //     });
  //   }
  //   if(localStorage.getItem("Sizes") != null){
  //     if(sizeArray[0] == JSON.parse(localStorage.getItem("Sizes"))[0] && sizeArray.length == JSON.parse(localStorage.getItem("Sizes").length)){
  //       setSizesList(JSON.parse(localStorage.getItem("Sizes")))
  //     }
  //     else{
  //       setSizesList(sizeArray);
  //       localStorage.setItem("Sizes", JSON.stringify(sizeArray))
  //     }
  //   }
  //   else{
  //       setSizesList(sizeArray);
  //       localStorage.setItem("Sizes", JSON.stringify(sizeArray))
  //   }
  // }, []);

  // useEffect(()=>{
  //   if(partnerproducts){
  //     partnerproducts.map((productList)=>{
  //       if(sizeArray.indexOf(productList.size) === -1 && productList.size != undefined && productList.size != ''){
  //         sizeArray.push(productList.size)
  //       }
  //     })
  //     setSizesList(sizeArray)
  //   }
  // },[])

  useEffect(() => {
    if (morePartnerProducts) {
      morePartnerProducts.map((productList) => {
        if (
          sizeArray.indexOf(productList.size) === -1 &&
          productList.size != undefined &&
          productList.size != ""
        ) {
          sizeArray.push(productList.size);
        }
      });
    }
    if (partnerproducts) {
      partnerproducts.map((productList) => {
        if (
          brandArray.indexOf(productList.brand) === -1 &&
          productList.brand != undefined &&
          productList.brand != ""
        ) {
          brandArray.push(productList.brand);
        }
      });
    }
    if (localStorage.getItem("Sizes") != null) {
      if (JSON.parse(localStorage.getItem("Sizes")).length) {
        setSizesList(JSON.parse(localStorage.getItem("Sizes")));
      } else {
        setSizesList(sizeArray);
        localStorage.setItem("Sizes", JSON.stringify(sizeArray));
      }
    } else {
      setSizesList(sizeArray);
      localStorage.setItem("Sizes", JSON.stringify(sizeArray));
    }
  }, [morePartnerProducts, partnerproducts]);

  // useEffect(() => {
  //   if (SizesList.length) {
  //     SizesList.map((sizeVal) => {
  //       sizeArray.push({ size: sizeVal, value: sizeVal });
  //     });
  //     setSizes(sizeArray);
  //   }
  // }, [SizesList]);

  useEffect(() => {
    dispatch(getSizeFilter("clothing_and_accessories"));
  }, []);

  useEffect(() => {
    if (sizeFilters) {
      if (sizeFilters.length) {
        sizeFilters.map((sizeVal) => {
          if (tempSizeArray.indexOf(sizeVal.size) === -1) {
            tempSizeArray.push(sizeVal.size);
          }
        });
        tempSizeArray.map((sizeVal) => {
          sizeArray.push({ size: sizeVal, value: sizeVal });
        });
      }
    }
    setSizes(sizeArray);
  }, [sizeFilters]);

  // const shipping = [{shipping:'Free Shipping', value: false},{shipping:'Shipping', value: false}, {shipping:'Meetup', value: false}]
  // const sliderMin = minPrice > 0 ? minPrice / 100 : 0;
  // const sliderMax = maxPrice > 0 ? maxPrice / 100 : 100;
  const sliderMin = 0;
  const sliderMax = 2500;
  const priceFilter = {
    [sliderMin]: (
      <Typography.Text
        style={{ width: "80px" }}
        ellipsis={{ tooltip: sliderMin }}
        className="pFSliderPriceVal"
      >
        ${sliderMin}
      </Typography.Text>
    ),
    [sliderMax]: (
      <Typography.Text
        style={{ width: "80px" }}
        ellipsis={{ tooltip: sliderMax }}
        className="pFSliderPriceVal"
      >
        ${sliderMax}
      </Typography.Text>
    ),
    // [sliderMin]: <Typography className='pFSliderPriceVal'>${sliderMin}</Typography>,
    // [sliderMax]: <Typography className='pFSliderPriceVal'>${sliderMax}</Typography>,
  };
  // let currentOpenPanelKey = '';
  let currentSearchElement = useLocation().search.split("&");
  useEffect(() => {
    if (currentSearchElement == "") {
      localStorage.removeItem("Active-Filter");
    }
  }, []);
  const [currentOpenPanelKey, setCurrentOpenPanelKey] = useState("");
  function dropdownCallback(key) {
    if (key.length === 0) {
      setCurrentOpenPanelKey("");
      localStorage.removeItem("Active-Filter");
    } else if (key.length === 1) {
      setCurrentOpenPanelKey(key[0]);
      localStorage.setItem("Active-Filter", key[0]);
    } else if (key.length === 2) {
      setCurrentOpenPanelKey(key[1]);
      localStorage.setItem("Active-Filter", key[1]);
    } else {
      setCurrentOpenPanelKey(key[key.length - 1]);
      localStorage.setItem("Active-Filter", key[key.length - 1]);
    }
  }

  useEffect(() => {
    // if (sortBrandsByCategory && sortBrandsByCategory.length > 0) {
    //   brandsToShowInUI = sortBrandsByCategory;
    // } else {
    //   dispatch(getAllBrands());
    // }
    dispatch(getAllCategories());
  }, []);
  const onChangedPriceRange = (range) => {
    updateSelectedPriceRannge(range);
    localStorage.setItem("Selected-Price-Range", range[1]);
  };
  const checkdonBrandChange = (checkedValues) => {
    updateSelectedBrand(checkedValues);
  };
  const checkdonGenderChange = (checkedValues) => {
    updateSelectedGender(checkedValues);
  };
  const checkdonSizeChange = (checkedValues) => {
    updateSelectedSize(checkedValues);
  };
  // const checkdonAgeChange = (checkedValues) => {
  //   const isExists = _.includes(selectedAge, checkedValues)
  //   const sAge = selectedAge
  //   if(isExists){
  //     const removeIndex = selectedAge.findIndex( item => item === checkedValues );
  //     sAge.splice( removeIndex, 1 );
  //   }
  //   else {
  //     sAge.push(checkedValues)
  //   }
  //   updateSelectedAge(sAge)
  // }
  const checkdonConditionChange = (checkedValues) => {
    updateSelectedCondition(checkedValues);
  };

  const checkdonShippingChange = (checkedValues) => {
    const findIndex = selectedShipping.findIndex(
      (item) => item.shipping === checkedValues.shipping
    );
    const matchObject = selectedShipping[findIndex];
    matchObject.value =
      matchObject.value === checkedValues.value
        ? !matchObject.value
        : matchObject.value;
    updateSelectedShipping(matchObject, findIndex);
  };
  //   const checkdonSoldUnSoldChange = (checkedValues) => {
  //   updateSelectedShipping(checkedValues)
  // }
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleResetAll = () => {
    setshowfilterDialog(true);
    updateResetAllFilter();
    // localStorage.removeItem("Selected-Brands");
    // localStorage.removeItem("Selected-Genders");
    // localStorage.removeItem("Selected-YearofPurchase");
    // localStorage.removeItem("Selected-Condition");
    // localStorage.removeItem("Selected-Shipping");
    // localStorage.removeItem("Selected-Size");
    // localStorage.removeItem("Selected-Max-Price");
    // localStorage.removeItem("Selected-Price-Range");
    // history.replace({
    //   search: "",
    // });
  };

  // let selectedBrandFilter = JSON.parse(localStorage.getItem("Selected-Brands"))
  // let selectedGenderFilter = JSON.parse(localStorage.getItem("Selected-Genders"))
  // let selectedConditionFilter = JSON.parse(localStorage.getItem("Selected-Condition"))
  // let selectedShippingFilter = JSON.parse(localStorage.getItem("Selected-Shipping"))
  // let selectedSizeFilter = JSON.parse(localStorage.getItem("Selected-Size"))

  // let selectedBrandFilter = useLocation().search.split('?brand=').join(',').split('&brand=').join(',').split('%2C').join(',').split(',');
  // let selectedGenderFilter = useLocation().search.split('?gender=').join(',').split('&gender=').join(',').split('%2C').join(',').split(',');
  // let selectedPurchaseYearFilter = useLocation().search.split('?purchaseYear=').join(',').split('&purchaseYear=').join(',').split('%2C').join(',').split(',');
  // let selectedConditionFilter = useLocation().search.split('?condition=').join(',').split('&condition=').join(',').split('%2C').join(',').split(',');
  // let selectedShippingFilter = useLocation().search.split('?shipping=').join(',').split('&shipping=').join(',').split('%2C').join(',').split(',');
  // let selectedSizeFilter = useLocation().search.split('?size=').join(',').split('&size=').join(',').split('%2C').join(',').split(',');

  const [brandsvalue, setbrandsvalue] = useState([]);
  const [gendervalue, setgendervalue] = useState([]);
  const [conditionvalue, setconditionvalue] = useState([]);
  const [shippingvalue, setshippingvalue] = useState([]);
  const [sizevalue, setsizevalue] = useState([]);

  // useEffect(()=>{
  //   if(selectedBrandFilter && selectedBrandFilter.length > 0){
  //     selectedBrandFilter.map((selectedBrandFilters) => {
  //       if(selectedBrandFilters != ''){
  //         updateSelectedBrand(selectedBrandFilters)
  //         brandsvalue.push(selectedBrandFilters);
  //       }
  //     })
  //   }
  //   if(selectedGenderFilter && selectedGenderFilter.length > 0){
  //     selectedGenderFilter.map((selectedGenderFilters) => {
  //       if(selectedGenderFilters != ''){
  //         updateSelectedGender(selectedGenderFilters)
  //         gendervalue.push(selectedGenderFilters);
  //       }
  //     })
  //   }
  //   if(selectedConditionFilter && selectedConditionFilter.length > 0){
  //     selectedConditionFilter.map((selectedConditionFilters) => {
  //       if(selectedConditionFilters != ''){
  //         updateSelectedCondition(selectedConditionFilters)
  //         conditionvalue.push(selectedConditionFilters);
  //       }
  //     })
  //   }
  //   if(selectedShippingFilter && selectedShippingFilter.length > 0){
  //     selectedShippingFilter.map((selectedShippingFilters) => {
  //       if(selectedShippingFilters != ''){
  //         updateSelectedShipping(selectedShippingFilters)
  //         shippingvalue.push(selectedShippingFilters);
  //       }
  //     })
  //   }
  //   if(selectedSizeFilter && selectedSizeFilter.length > 0){
  //     selectedSizeFilter.map((selectedSizeFilters) => {
  //       if(selectedSizeFilters != ''){
  //         updateSelectedSize(selectedSizeFilters)
  //         sizevalue.push(selectedSizeFilters);
  //       }
  //     })
  //   }
  // },[])

  const addQuery = (key, value) => {
    let pathname = location.pathname;
    let searchParams = new URLSearchParams(location.search);
    let selectedBrandCheck = brandsvalue.indexOf(value);
    let selectedGenderCheck = gendervalue.indexOf(value);
    let selectedConditionCheck = conditionvalue.indexOf(value);
    let selectedShippingCheck = shippingvalue.indexOf(value);
    let selectedSizeCheck = sizevalue.indexOf(value);

    if (key == "brand") {
      if (selectedBrandCheck === -1) {
        brandsvalue.push(value);
      } else {
        if (selectedBrandCheck > -1) {
          brandsvalue.splice(selectedBrandCheck, 1);
        }
      }
      setbrandsvalue(brandsvalue);
      searchParams.set(key, brandsvalue);
      // localStorage.setItem("Selected-Brands", JSON.stringify(brandsvalue))
    } else if (key == "gender") {
      if (selectedGenderCheck === -1) {
        gendervalue.push(value);
      } else {
        if (selectedGenderCheck > -1) {
          gendervalue.splice(selectedGenderCheck, 1);
        }
      }
      setgendervalue(gendervalue);
      searchParams.set(key, gendervalue);
      // localStorage.setItem("Selected-Genders", JSON.stringify(gendervalue))
    } else if (key == "condition") {
      if (selectedConditionCheck === -1) {
        conditionvalue.push(value);
      } else {
        if (selectedConditionCheck > -1) {
          conditionvalue.splice(selectedConditionCheck, 1);
        }
      }
      setconditionvalue(conditionvalue);
      searchParams.set(key, conditionvalue);
      // localStorage.setItem("Selected-Condition", JSON.stringify(conditionvalue))
    } else if (key == "shipping") {
      if (selectedShippingCheck === -1) {
        shippingvalue.push(value);
      } else {
        if (selectedShippingCheck > -1) {
          shippingvalue.splice(selectedShippingCheck, 1);
        }
      }
      setshippingvalue(shippingvalue);
      searchParams.set(key, shippingvalue);
      // localStorage.setItem("Selected-Shipping", JSON.stringify(shippingvalue))
    } else if (key == "size") {
      if (selectedSizeCheck === -1) {
        sizevalue.push(value);
      } else {
        if (selectedSizeCheck > -1) {
          sizevalue.splice(selectedSizeCheck, 1);
        }
      }
      setsizevalue(sizevalue);
      searchParams.set(key, sizevalue);
      // localStorage.setItem("Selected-Size", JSON.stringify(sizevalue))
    }
    // history.push(`?brand=${(brandsvalue ? brandsvalue : '')}`)
    // history.push({
    //   pathname: pathname,
    //   search: searchParams.toString(),
    // });
  };
  const { isXS, isSM } = useBreakpoints();
  const handleCancel = () => {
    if (filtertags.length <= 0 && resetclose === true) {
      setdisplaydata(true);
    } else {
      setdisplaydata(false);
    }
    setshowfilterDialog(false);
  };
  return (
    <>
      <BottomSheet open={showfilterDialog}>
        <Card className="pFilterItemMain">
          <Row justify="space-between" className="pFilterHeader">
            <Col onClick={handleCancel}>
              <Col>
                <Button type="text" className="resetAllButton style2">
                  Cancel
                </Button>
              </Col>
            </Col>
            <Col>
              <Typography className="pFilterTitle">Filter</Typography>
            </Col>

            <Col>
              <Button
                type="text"
                className="resetAllButton style2"
                onClick={()=>{handleResetAll();gaEventClick("Filter by", "Clear all")}}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Row justify="center" className="justifyresults">
            <Col style={{ margin: "0px 12px" }}>
              <Typography className="applyresults">{total}</Typography>
            </Col>
            <Col>
              <Typography className="applyresults">Results</Typography>
            </Col>
          </Row>
          <Divider
            style={{
              borderTop: "1px solid #D4D4D4",
              marginTop: 12,
              marginBottom: 1,
            }}
          />
          <div className="pFilterItemCard">
            <Card>
              <Row className="removeColumnPadding">
                <Col span={24}>
                  <Collapse
                    className="pFCollaps"
                    // defaultActiveKey={currentOpenPanelKey}
                    expandIconPosition="right"
                   onChange={ 
                      ()=>{gaEventClick("Filter by", "SwoondlePlp","open/close")}
                    }
                    bordered={false}
                    activeKey={localStorage.getItem("Active-Filter")}
                    expandIcon={({ isActive }) => {
                      return !isActive ? (
                        <img src={plusicon} className="collapsIcon" />
                      ) : (
                        <img src={minussign} className="collapsIcon" />
                      );
                    }}
                  >
                    {/* <Panel
                    className="collapseContent"
                    style={{
                      padingTop: 15,
                      paddingBottom: 19,
                      marginTop: 15,
                      marginBottom: 19,
                    }}
                    header={
                      selectedBrand && selectedBrand.length > 0 ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">
                            Brand&nbsp;
                          </Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">
                          Brand
                        </Typography>
                      )
                    }
                    key={6}
                  >
                    <Row
                    style={ (Brands.length > 8) ? { height: 220,
                      overflowY: "scroll"} : { height: "unset"} }
                      gutter={[0, 0]}
                    >
                      <Col span={22}>
                        {Brands &&
                          Brands.map((val, i) => (
                            <Row gutter={[12, 16]} key={i}>
                              <Col>
                                <Checkbox
                                  onChange={() => checkdonBrandChange(val.brand)}
                                  checked={selectedBrand.indexOf(val.brand) !== -1}
                                  onClick={() => {
                                    addQuery("brand", val.brand);
                                  }}
                                  //onClick={() => addQuery("brand", val.brand)}
                                  // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                                />
                              </Col>
                              <Col>
                                <Typography className="conditionName">
                                  {capitalize(val.brand)}
                                </Typography>
                              </Col>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </Panel> */}
                    <Panel
                      className="collapseContent"
                      style={{
                        padingTop: 15,
                        paddingBottom: 19,
                        marginTop: 15,
                        marginBottom: 19,
                      }}
                      header={
                        selectedGender &&
                        Object.keys(selectedGender).length > 0 ? (
                          <Badge color="blue">
                            <Typography className="pFCollapseTitle">
                              Gender&nbsp;
                            </Typography>
                          </Badge>
                        ) : (
                          <Typography className="pFCollapseTitle">
                            Gender
                          </Typography>
                        )
                      }
                      key={1}
                    >
                      {totalcount &&
                        Object.entries(totalcount.gender || {}).map(
                          (val, i) => (
                            <Row gutter={[12, 16]} key={i}>
                              <Col>
                                <Checkbox
                                  onChange={() => {checkdonGenderChange(val[0]);gaEventClick("Filter by", "SwoondlePlp-Gender",val[0])}}
                                  checked={
                                    selectedGender.indexOf(val[0]) !== -1
                                  }
                                />
                              </Col>
                              <Col>
                                <Typography className="conditionName">
                                  {val[0] == "unisex" && <span>Unisex</span>}
                                  {val[0] == "neutral" && <span>Neutral</span>}
                                  {val[0] == "male" && <span>Boy</span>}
                                  {val[0] == "female" && <span>Girl</span>}
                                  {val[1] > 0 &&
                                  (val[0] == "unisex" ||
                                    val[0] == "neutral" ||
                                    val[0] == "male" ||
                                    val[0] == "female") ? (
                                    <span className="ProductCount">
                                      ({val[1]})
                                    </span>
                                  ) : (
                                    <span>(0)</span>
                                  )}
                                </Typography>
                              </Col>
                            </Row>
                          )
                        )}
                    </Panel>

                    <Panel
                      className="collapseContent"
                      style={{
                        padingTop: 15,
                        paddingBottom: 19,
                        marginTop: 15,
                        marginBottom: 19,
                      }}
                      header={
                        selectedSize && Object.keys(selectedSize).length > 0 ? (
                          <Badge color="blue">
                            <Typography className="pFCollapseTitle">
                              Size&nbsp;
                            </Typography>
                          </Badge>
                        ) : (
                          <Typography className="pFCollapseTitle">
                            Size
                          </Typography>
                        )
                      }
                      key={2}
                    >
                      <Row
                        className={
                          totalcount &&
                          Object.keys(totalcount.size || {}).length > 5
                            ? "filter-scroll "
                            : ""
                        }
                      >
                        <Col span={22}>
                          {totalcount &&
                            Object.entries(totalcount.size || {}).map(
                              (val, i) => (
                                <Row gutter={[12, 16]} key={i}>
                                  <Col>
                                    <Checkbox
                                      onChange={() =>
                                        {checkdonSizeChange(val[0]);gaEventClick("Filter by", "SwoondlePlp-Size",val[0])}
                                      }
                                      //onClick={() => addQuery("size", val.size)}
                                      checked={
                                        selectedSize.indexOf(val[0]) !== -1
                                      }

                                      // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                                    />
                                  </Col>
                                  <Col>
                                    <Typography className="conditionName">
                                      {capitalize(val[0])}
                                      {val[1] > 0 ? (
                                        <span className="ProductCount">
                                          ({val[1]})
                                        </span>
                                      ) : (
                                        <span>(0)</span>
                                      )}
                                    </Typography>
                                  </Col>
                                </Row>
                              )
                            )}
                        </Col>
                      </Row>
                    </Panel>
                    <Panel
                      className="collapseContent"
                      style={{
                        padingTop: 15,
                        paddingBottom: 19,
                        marginTop: 15,
                        marginBottom: 19,
                      }}
                      header={
                        selectedPriceRange &&
                        Object.keys(selectedPriceRange).length == 2 ? (
                          <Badge color="blue">
                            <Typography className="pFCollapseTitle">
                              Price&nbsp;
                            </Typography>
                          </Badge>
                        ) : (
                          <Typography className="pFCollapseTitle">
                            Price
                          </Typography>
                        )
                      }
                      key={3}
                    >
                      <Row className="priceRowOne">
                        <Col>
                          <Input
                            placeholder="min"
                            onChange={(val) => {
                              //setSliderMinVal(val);
                              updateSelectedPriceRannge({
                                max: selectedPriceRange.max,
                                min: val,
                              });
                              onChangedPriceRange([
                                selectedPriceRange.min,
                                selectedPriceRange.max,
                              ]);
                            }}
                            value={selectedPriceRange?.min}
                            defaultValue={sliderMin}
                          />
                        </Col>
                        <Col>
                          <Input
                            placeholder="max"
                            onChange={(val) => {
                              updateSelectedPriceRannge({
                                min: selectedPriceRange.min,
                                max: val,
                              });
                              onChangedPriceRange([
                                selectedPriceRange.min,
                                selectedPriceRange.max,
                              ]);
                            }}
                            value={selectedPriceRange?.max}
                            defaultValue={sliderMax}
                          />
                        </Col>
                      </Row>
                      {priceValues?.map((val, i) => {
                        return (
                          <Row gutter={[12, 16]}>
                            <Col>
                              <Checkbox
                                onChange={() => checkdonPriceChange(val.title)}
                                checked={val.value}
                              />
                            </Col>
                            <Col>
                              <Typography className="conditionName">
                                {val.title}
                              </Typography>
                            </Col>
                          </Row>
                        );
                      })}
                    </Panel>
                    {/* <Panel
                    className="collapseContent"
                    style={{
                      padingTop: 15,
                      paddingBottom: 19,
                      marginTop: 15,
                      marginBottom: 19,
                    }}
                    header={
                      selectedPurchaseYear && selectedPurchaseYear !== '' ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">Year of purchase&nbsp;</Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">Year of purchase</Typography>
                      )
                    }
                    key={4}
                  >
                    {purchaseYear &&
                      purchaseYear.map((val, i) => (
                        <Row gutter={[12, 16]} key={i}>
                          <Col>
                            <Checkbox
                              onChange={() => checkdonPurchaseYearChange(val)}
                              checked={selectedPurchaseYear === val}
                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                          </Col>
                          <Col>
                            <Typography className="conditionName">{val}</Typography>
                          </Col>
                        </Row>
                      ))}
                  </Panel> */}

                    <Panel
                      className="collapseContent"
                      style={{
                        padingTop: 15,
                        paddingBottom: 19,
                        marginTop: 15,
                        marginBottom: 19,
                      }}
                      header={
                        selectedCondition && selectedCondition.length > 0 ? (
                          <Badge color="blue">
                            <Typography className="pFCollapseTitle">
                              Condition&nbsp;
                            </Typography>
                          </Badge>
                        ) : (
                          <Typography className="pFCollapseTitle">
                            Condition
                          </Typography>
                        )
                      }
                      key={4}
                    >
                      {/* <div>* /}
                    {/* <Checkbox.Group  onChange={checkdonChange} className='collapseContent'> */}
                      {totalcount &&
                        Object.entries(totalcount.condition || {}).map(
                          (val, i) => (
                            <Row gutter={[12, 16]} key={i}>
                              <Col>
                                <Checkbox
                                  onChange={() =>
                                    {checkdonConditionChange(val[0]);gaEventClick("Filter by", "SwoondlePlp-condition",val[0])}
                                  }
                                  checked={
                                    selectedCondition.indexOf(val[0]) !== -1
                                  }
                                />
                              </Col>
                              <Col>
                                <Typography className="conditionName">
                                  {capitalize(val[0])}{" "}
                                  {val[1] > 0 ? (
                                    <span className="ProductCount">
                                      ({val[1]})
                                    </span>
                                  ) : (
                                    <span>(0)</span>
                                  )}
                                </Typography>
                              </Col>
                            </Row>
                          )
                        )}
                    </Panel>
                    {/* <Panel
                    className="collapseContent"
                    style={{
                      padingTop: 15,
                      paddingBottom: 19,
                      marginTop: 15,
                      marginBottom: 19,
                    }}
                    header={
                      selectedShipping && (selectedShipping[0].value === true || selectedShipping[1].value === true || selectedShipping[2].value === true) ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">Delivery&nbsp;</Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">Delivery</Typography>
                      )
                    }
                    key={6}
                  >
                    {selectedShipping &&
                      selectedShipping.map((val, i) => (
                        <Row gutter={[12, 16]} key={i}>
                          <Col>
                            <Checkbox
                              onChange={() => checkdonShippingChange(val)}
                              checked={val.value}
                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                          </Col>
                          <Col>
                            <Typography className="conditionName">{capitalize(val.shipping)}</Typography>
                          </Col>
                        </Row>
                      ))}
                  </Panel> */}
                  </Collapse>
                </Col>
              </Row>
              <Button
                style={{ marginTop: "20px" }}
                type="text"
                className={"button button-primary  pLapplybutton"}
                onClick={handledisplayAll}
              >
                Apply
              </Button>
              {/* <Row className="removeColumnPadding"></Row> */}

              {/* {itemType === "brand" && 
        <>
        <Row className="removeColumnPadding">
          <Col span={24}>
            <Collapse
              className='pFCollaps'
              defaultActiveKey={["0"]}
              expandIconPosition="right"
              onChange={callback}
              bordered={false}
              expandIcon={({ isActive }) => {
                return !isActive ? (

                  <DownOutlined className='collapsIcon' />
                ) : (
                  <UpOutlined className='collapsIcon' />
                );
              }}
            >
              <Panel className='collapseContent' header={
                <Typography className='pFCollapseTitle'>Category</Typography>
              } key="1">
                 {categories &&
              categories.map((val, i) => (
                <Row gutter={[0, 16]} key={i}>
                  <Checkbox
                  //   className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                  />
                  <Typography className="conditionName">
                    {humanize(val.category)}
                  </Typography>
                </Row>
                  ))}
              </Panel>
            </Collapse>
          </Col>
        </Row>
</>
} */}

              {/* {itemType !== 'brand' && (
              <>
                <Row className="removeColumnPadding">
                  <Col span={24}>
                    <Collapse
                      className="pFCollaps"
                      defaultActiveKey={['0']}
                      expandIconPosition="right"
                      onChange={callback}
                      bordered={false}
                      ghost
                      expandIcon={({ isActive }) => {
                        return !isActive ? <DownOutlined className="collapsIcon" /> : <UpOutlined className="collapsIcon" />;
                      }}
                    >
                      <Panel
                        className="collapseContent"
                        header={
                          selectedBrand && selectedBrand.length > 0 ? (
                            <Badge color="blue">
                              <Typography className="pFCollapseTitle">Brand&nbsp;</Typography>
                            </Badge>
                          ) : (
                            <Typography className="pFCollapseTitle">Brand</Typography>
                          )
                        }
                        key="1"
                      >
                        {/ * <Row>
                  <Col span={24}></Col>
                  <Col>
                  <AutoComplete
                  className="brandsearchbarvariation"
                  // onSearch={debounce(handleSearch, 500)}
                  // onSelect={handleOnSelect}
                  // onChange={(e) => setSearch(e)}
                  // value={search}
                  // defaultOpen={true}
                  style={{ width: "100%" }}
                >
                  <Input.Search size='large' placeholder="Search for brand" />
                </AutoComplete>
                  </Col>
                </Row> * /}
                        <Row
                          style={{
                            height: 220,
                            overflowY: 'scroll',
                          }}
                          gutter={[0, 0]}
                        >
                          {/ * <Col span={24}></Col> * /}
                          <Col span={22}>
                            {brandsToShowInUI &&
                              brandsToShowInUI.map((val, i) => (
                                <Row gutter={[12, 16]} key={i}>
                                  <Col>
                                    <Checkbox
                                      onChange={() => checkdonBrandChange(val.brand)}
                                      checked={selectedBrand.indexOf(val.brand) !== -1}
                                      // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                                    />
                                  </Col>
                                  <Col>
                                    <Typography className="conditionName">{capitalize(val.brand)}</Typography>
                                  </Col>
                                </Row>
                              ))}
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col />
                </Row>
              </>
            )} */}

              {/* <Divider
              style={{
                borderTop: '1px solid #D4D4D4',
                marginTop: 15,
                marginBottom: 19,
              }}
            /> */}
              {/* <Row className="removeColumnPadding">
              <Col span={24}>
                <Collapse
                  className="pFCollaps"
                  defaultActiveKey={['0']}
                  expandIconPosition="right"
                  ghost
                  onChange={callback}
                  bordered={false}
                  expandIcon={({ isActive }) => {
                    return !isActive ? <DownOutlined className="collapsIcon" /> : <UpOutlined className="collapsIcon" />;
                  }}
                >
                  <Panel
                    className="collapseContent"
                    header={
                      selectedGender && selectedGender.length > 0 ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">Gender&nbsp;</Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">Gender</Typography>
                      )
                    }
                    key="1"
                  >
                    {genders &&
                      genders.map((val, i) => (
                        <Row gutter={[12, 16]} key={i}>
                          <Col>
                            <Checkbox
                              onChange={() => checkdonGenderChange(val)}
                              // checked={selectedGender && selectedGender.value.indexOf(val.value) !== -1}
                              checked={selectedGender.value === val.value}
                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                          </Col>
                          <Col>
                            <Typography className="conditionName">{capitalize(val.gender)}</Typography>
                          </Col>
                        </Row>
                      ))}
                  </Panel>
                </Collapse>
              </Col>
            </Row> */}

              {/* <Divider
              style={{
                borderTop: '1px solid #D4D4D4',
                marginTop: 15,
                marginBottom: 19,
              }}
            />
            <Row className="removeColumnPadding">
              <Col span={24}>
                <Collapse
                  className="pFCollaps"
                  defaultActiveKey={['0']}
                  expandIconPosition="right"
                  onChange={callback}
                  ghost
                  bordered={false}
                  expandIcon={({ isActive }) => {
                    return !isActive ? <DownOutlined className="collapsIcon" /> : <UpOutlined className="collapsIcon" />;
                  }}
                >
                  <Panel
                    className="collapseContent"
                    header={
                      selectedPriceRange && Object.keys(selectedPriceRange).length == 2 ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">Price&nbsp;</Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">Price</Typography>
                      )
                    }
                    key="1"
                  >
                    <Slider range={true} tipFormatter={(value) => `$${value}`} min={sliderMin} max={sliderMax} onAfterChange={(value) => onChangedPriceRange(value)} marks={priceFilter} className="pFSlider" />
                  </Panel>
                </Collapse>
              </Col>
            </Row> */}

              {/* <Divider
              style={{
                borderTop: '1px solid #D4D4D4',
                marginTop: 15,
                marginBottom: 19,
              }}
            />
            <Row className="removeColumnPadding">
              <Col span={24}>
                <Collapse
                  className="pFCollaps"
                  defaultActiveKey={['0']}
                  expandIconPosition="right"
                  onChange={callback}
                  bordered={false}
                  ghost
                  expandIcon={({ isActive }) => {
                    return !isActive ? <DownOutlined className="collapsIcon" /> : <UpOutlined className="collapsIcon" />;
                  }}
                >
                  <Panel
                    className="collapseContent"
                    header={
                      selectedPurchaseYear && selectedPurchaseYear !== '' ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">Year of purchase&nbsp;</Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">Year of purchase</Typography>
                      )
                    }
                    key="1"
                  >
                    {purchaseYear &&
                      purchaseYear.map((val, i) => (
                        <Row gutter={[12, 16]} key={i}>
                          <Col>
                            <Checkbox
                              onChange={() => checkdonPurchaseYearChange(val)}
                              checked={selectedPurchaseYear === val}
                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                          </Col>
                          <Col>
                            <Typography className="conditionName">{val}</Typography>
                          </Col>
                        </Row>
                      ))}
                  </Panel>
                </Collapse>
              </Col>
            </Row> */}

              {/* <Divider
              style={{
                borderTop: '1px solid #D4D4D4',
                marginTop: 15,
                marginBottom: 19,
              }}
            />
            {/ * <Row className="removeColumnPadding">
          <Col span={24}>
            <Collapse
              className='pFCollaps'
              defaultActiveKey={["0"]}
              expandIconPosition="right"
              onChange={callback}
              bordered={false}
              expandIcon={({ isActive }) => {
                return !isActive ? (

                  <DownOutlined className='collapsIcon' />
                ) : (
                  <UpOutlined className='collapsIcon' />
                );
              }}
            >
              <Panel className='collapseContent' header={
                <Typography className='pFCollapseTitle'>Age</Typography>
              } key="1">
                 {lotsByAge &&
              lotsByAge.map((val, i) => (
                <Row gutter={[0, 16]} key={i}>
                  <Checkbox onChange={() => checkdonAgeChange(val.age)}
                    className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                  />
                  <Typography className="conditionName">
                    {capitalize(val.age)}
                  </Typography>
                </Row>
                  ))}
              </Panel>
            </Collapse>
          </Col>
        </Row> * /}

            <Row className="removeColumnPadding">
              <Col span={24}>
                <Collapse
                  className="pFCollaps"
                  defaultActiveKey={['0']}
                  expandIconPosition="right"
                  onChange={callback}
                  ghost
                  bordered={false}
                  expandIcon={({ isActive }) => {
                    return !isActive ? <DownOutlined className="collapsIcon" /> : <UpOutlined className="collapsIcon" />;
                  }}
                >
                  <Panel
                    className="collapseContent"
                    header={
                      selectedCondition && selectedCondition.length > 0 ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">Condition&nbsp;</Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">Condition</Typography>
                      )
                    }
                    key="1"
                  >
                    {/ * <div> * /}
                    {/ * <Checkbox.Group  onChange={checkdonChange} className='collapseContent'> * /}
                    {conditions &&
                      conditions.map((val, i) => (
                        <Row gutter={[12, 16]} key={i}>
                          <Col>
                            <Checkbox
                              onChange={() => checkdonConditionChange(val)}
                              checked={selectedCondition.value === val.value}
                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                          </Col>
                          <Col>
                            <Typography className="conditionName">{capitalize(val.condition)}</Typography>
                          </Col>
                        </Row>
                      ))}
                    {/ * <Row gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Checkbox
                  //   className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                  />
                  <Typography className="conditionName">
                    Like New
                  </Typography>
                </Row>
                <Row gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Checkbox
                  //   className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                  />
                  <Typography className="conditionName">
                    Gently Used
                  </Typography>
                </Row>
                <Row gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Checkbox
                  //   className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                  />
                  <Typography className="conditionName">
                    Well Used
                  </Typography>
                </Row> * /}
                    {/ * </Checkbox.Group> * /}
                    {/ * </div> * /}
                  </Panel>
                </Collapse>
              </Col>
            </Row> * /}

            <Divider
              style={{
                borderTop: '1px solid #D4D4D4',
                marginTop: 15,
                marginBottom: 19,
              }}
            />
            <Row className="removeColumnPadding">
              <Col span={24}>
                <Collapse
                  className="pFCollaps"
                  defaultActiveKey={['0']}
                  expandIconPosition="right"
                  onChange={callback}
                  bordered={false}
                  ghost
                  expandIcon={({ isActive }) => {
                    return !isActive ? <DownOutlined className="collapsIcon" /> : <UpOutlined className="collapsIcon" />;
                  }}
                >
                  <Panel
                    className="collapseContent"
                    header={
                      selectedShipping && (selectedShipping[0].value === true || selectedShipping[1].value === true || selectedShipping[2].value === true) ? (
                        <Badge color="blue">
                          <Typography className="pFCollapseTitle">Shipping&nbsp;</Typography>
                        </Badge>
                      ) : (
                        <Typography className="pFCollapseTitle">Shipping</Typography>
                      )
                    }
                    key="1"
                  >
                    {selectedShipping &&
                      selectedShipping.map((val, i) => (
                        <Row gutter={[12, 16]} key={i}>
                          <Col>
                            <Checkbox
                              onChange={() => checkdonShippingChange(val)}
                              checked={val.value}
                              // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                            />
                          </Col>
                          <Col>
                            <Typography className="conditionName">{capitalize(val.shipping)}</Typography>
                          </Col>
                        </Row>
                      ))}
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            {/ * <Divider style={{borderTop:"1px solid #D4D4D4", marginTop:15, marginBottom:19}}/>
        <Row className="removeColumnPadding">
          <Col span={24}>
            <Collapse
              className='pFCollaps'
              defaultActiveKey={["0"]}
              expandIconPosition="right"
              onChange={callback}
              bordered={false}
              ghost
              expandIcon={({ isActive }) => {
                return !isActive ? (

                  <DownOutlined className='collapsIcon' />
                ) : (
                  <UpOutlined className='collapsIcon' />
                );
              }}
            >
              <Panel className='collapseContent' header={
                <Typography className='pFCollapseTitle'>Sold/UnSold</Typography>
              } key="1">
                <Row gutter={[12, 16]}>
                  <Col>
                  <Checkbox onChange={() => checkdonSoldUnSoldChange(true)}
                  />
                  </Col>
                  <Col>
                  <Typography className="conditionName">
                   Sold
                  </Typography>
                  </Col>
                </Row>
                <Row gutter={[12, 16]}>
                  <Col>
                  <Checkbox onChange={() => checkdonSoldUnSoldChange(false)}
                  />
                  </Col>
                  <Col>
                  <Typography className="conditionName">
                   UnSold
                  </Typography>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row> */}
              {/* <Divider
              style={{
                borderTop: '1px solid #D4D4D4',
                marginTop: 15,
                marginBottom: 0,
              }}
            /> */}
            </Card>
          </div>
        </Card>
      </BottomSheet>
    </>
  );
};

export default PFilteredItemMob;
