/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Spin,
  Typography,
  // Breadcrumb,
  // Dropdown,
  Menu,
  Button,
  // Divider,
  // Avatar,
  // Tag,
  Image,
  // Rate,
  Carousel,
} from "antd";
import {
  // DownOutlined,
  // DownCircleOutlined,
  HeartOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import FeedTopbar from "../components/FeedTopbar";
// import CategoryHeader from "../components/CategoryHeader";
import { CategoryCard } from "../components/CategoryCard";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import File5 from "../assets/File5.JPG";
import File6 from "../assets/File6.JPG";
// import MarketplaceImg from "../assets/MarketPlace1.png";
import MarkidReNewedImg from "../assets/MarkidReNewedImg.png";
import MarkidFooter from "../components/Footer";
import "../App.css";
import Avatar from "antd/lib/avatar/avatar";
import { getAllLots } from "../Actions/lotsAction";
import { getUser } from "../Actions/userAction";
import { useLocation, useParams, useHistory } from "react-router-dom";
const { Text, Title } = Typography;
// const { Header, Content, Footer } = Layout;
const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const CategoryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search)
  const history = useHistory();
  const token = params.get("token")

  const { providerToken, showSignIn } = useSelector((state) => {
    const { providerToken } = state.auth;
    return {
      providerToken,
    };
  });
  if (token) {
    localStorage.setItem("access_token", token);
    if (!providerToken) {
      dispatch({type: "SET_PROVIDER_TOKEN", payload: {token}})
    }
  }
  useEffect(() => {
    dispatch(getUser());
  }, [token]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1">1</Menu.Item>
  //     <Menu.Item key="2">2</Menu.Item>
  //     <Menu.Item key="3">3</Menu.Item>
  //   </Menu>
  // );
  useEffect(() => {
    dispatch(getAllLots());
  }, []);
  const { allLots, isGetAllLotsLoading } = useSelector((state) => {
    const { allLots, isGetAllLotsLoading } = state.lots;
    return {
      allLots,
      isGetAllLotsLoading,
    };
  });
  const { lots } = allLots || { lots: [] };
  return (
		<>
			<FeedTopbar providerToken={providerToken} />


      {/* <Row gutter={[0, 32]} justify="center">
        <Col span={24}></Col>
        <Col>
          <Image preview={false} src={MarketplaceImg} alt="sample" className="marketplaceImage" />
        </Col>
      </Row> */}
      <Row
        className="banner"
      >

        <Col span={24}></Col>

        <Col offset={2} xl={10} lg={10} md={10} sm={10} xs={24}>

          <Row>
            <Col span={24}>
              <Text className="homePageTitle">The Parent Marketplace</Text>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Text className="homePageParagraph">
                Join a community of parents to shop and sell kids' preloved
                gently used items easily and seamlessly
              </Text>
            </Col>
          </Row>


        </Col>

        <Col xl={10} lg={10} md={10} sm={10} xs={24}>
          <Row align='bottom' gutter={[0, 48]}>
            <Col span={24}></Col>
            <Col span={24}></Col>
            <Col>
              <img
                src={MarkidReNewedImg}
                className="bannerimage"

              />
            </Col>
          </Row>
        </Col>

      </Row>

      {/* <Row gutter={[0, 16]}>
				<Col span={24}></Col>
				<Col xl={9} lg={9} md={9} sm={9} xs={9}>
					<Breadcrumb>
						<Breadcrumb.Item>Home</Breadcrumb.Item>
						<Breadcrumb.Item>
							<a href="">Clothing</a>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<a href="">Dresses</a>
						</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row> */}

			{/* <Row gutter={[0, 16]} justify="space-between">
        <Col span={24}></Col>
        <Col xl={9} lg={9} md={9} sm={9} xs={9}>
					<Title level={5}>
						ReNewed <Text>(375 listings)</Text>
					</Title>
				</Col>
        <Col>
					<Typography>Sort By:</Typography>
					<Dropdown overlay={menu}>
						<Typography>
							New <DownOutlined />
						</Typography>
					</Dropdown>
				</Col>
      </Row> */}

			{/* <Row gutter={[18,48]} justify="center">
				<Col span={24}></Col>
				<Col>
					<Dropdown overlay={menu}>
						<Button type='text'>
							Age <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={menu}>
						<Button type='text'>
							Size <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={menu}>
						<Button type='text'>
							Brand <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={menu}>
						<Button type='text'>
							Location <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={menu}>
						<Button type='text'>
							Condition <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={menu}>
						<Button type='text'>
							Shipping <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={menu}>
						<Button type='text'>
							Price <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Button type='text'>More filters</Button>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			{/* <Row gutter={[0, 48]} justify="center">
        <Col span={24}></Col>
        <Col>
          <Row gutter={[16, 0]}>
            <Col>
              <Text className="category-name">Strollers</Text>
            </Col>
            <Col>
              <Row gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col>
                  <Text className="total-category-list">(375 listings)</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
					<Typography>Sort By:</Typography>
					<Dropdown overlay={menu}>
						<Typography>
							New <DownOutlined />
						</Typography>
					</Dropdown>
				</Col>
      </Row> */}
      <Spin indicator={antIcon} spinning={isGetAllLotsLoading}>
        <Row
          // style={{ paddingBottom: 5 }}
          gutter={[16, 75]}
          justify="center"
          wrap={true}
        >
          <Col span={24}></Col>
          {/* <Carousel autoplay {...settings}> */}

          {lots &&
            lots.map((val, i) => (
              <Col xl={5} lg={7} md={7} sm={10} xs={11} key={i} offset={1}>
                <CategoryCard lots={val} />
              </Col>
            ))}
          {/* </Carousel> */}
          <Col span={24}></Col>
        </Row>
      </Spin>
      {/* <Row justify="center">
				<Col>
					<DownCircleOutlined style={{ fontSize: "50px" }} />
				</Col>
			</Row> */}
			{/* <Row>
        <CategoryCard lots={lots.length > 0 ? lots[1] : null} />
      </Row> */}
			<MarkidFooter />
		</>
	);
};
export default CategoryPage;
