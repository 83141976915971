/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
	Row,
	Col,
	Card,
	Button,
	Spin,
	Typography,
	Layout,
	Radio,
	Divider,
	Input
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import PriceDetails from "../components/PriceDetails";
import "../App.css";
import { loadStripe } from '@stripe/stripe-js';
import { createOrder } from "../Actions/orderAction";
import { useHistory } from "react-router-dom";
import StripeLogo from "../assets/StripeLogo.svg";
import { createMakeOffer } from "../Actions/offerAction";
import { updateOfferByBuyer } from "../Actions/offerAction";
import { getCards } from "../Actions/paymentAction";
import profileVisa from "../assets/profileVisa.svg";
import MasterCard from "../assets/MasterCard.svg";
import DiscoverCard from "../assets/DiscoverCard.png";
import AmericanExpress from "../assets/AmericanExpress.png";
import { useBreakpoints } from './../react-responsive-hooks/index';
const schema = yup.object().shape({
	// name_on_card: yup.string("Name on card require"),
});
const OfferMadePayment = (props) => {
	const history = useHistory()
	const dispatch = useDispatch();
	const { isXS } = useBreakpoints();
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const { madeOfferData, validate, setValidate, paymentLoader, setPaymentLoader, handleOfferUpdateByBuyer, setUsePaymentMethod, callStripeApi } = props
	const { currentAddressId, currentUser, currentUserWallet, isPostCartLoading, isPostOrderLoading, latestClientSecret, useCredits, balance, createdOffer, cards, isGetCardsLoading } = useSelector((state) => {
		const { currentAddressId, currentUserWallet, isPostCartLoading, latestClientSecret, useCredits } = state.cart;
		const { currentUser } = state.user;
		const { isPostOrderLoading } = state.order;
		const { createdOffer } = state.offer
		const { cards, isGetCardsLoading } = state.payment;
		const { balance } = currentUserWallet
		return {
			currentAddressId,
			currentUserWallet,
			currentUser,
			isPostCartLoading,
			isPostOrderLoading,
			latestClientSecret,
			useCredits,
			balance,
			createdOffer,
			cards,
			isGetCardsLoading
		};
	});
	useEffect(() => {
		dispatch(getCards())
	}, []);

	useEffect(() => {
		_.forEach(cards, function (value) {
			if (value.isDefault) {
				setSelectedCard(value.id)
				setCDCForm(false)
			}
		})
		if (cards.length === 0) {
			setUsePaymentMethod(false)
		}
	}, [cards]);

	useEffect(() => {
		if (callStripeApi === true) {
			//  payWithCard(stripe)
		}
	}, [callStripeApi]);

	const { offerId } = createdOffer || {}
	const { offerData } = madeOfferData[0]
	const { lotId, price, deliveryNeeded } = offerData || {}
	const [error, setError] = useState({});
	const [cdcForm, setCDCForm] = useState(
		cards && cards.length <= 0 ? true : false
	);
	const [value, setValue] = React.useState(1);
	const [selectedCard, setSelectedCard] = useState("");

	const { handleSubmit, control, errors, getValues, watch } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name_on_card: '', card_number: '', mm: '', yy: '', cvv: '' }
	});

	const stripe = useStripe();
	const elements = useElements();


	const handleStripeSubmit = async (event) => {
		// Block native form submission.
		event.preventDefault();

		/* Commented previous payment logic : payment was initiated on the confirm offer page when product was od dilevaryType shipping and with parice negitiable */
		/* 		
		dispatch({type: "OFFER_PAYMENT_STATRTED", payload: {offerPaymentLoader: true}})
		setPaymentLoader(true)

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			setPaymentLoader(false);
			dispatch({type: "OFFER_PAYMENT_STATRTED", payload: {offerPaymentLoader: false}})
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);
		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		if (error) {
			setPaymentLoader(false)
			setValidate(error)
			// console.log('[error]', error);
			dispatch({type: "OFFER_PAYMENT_STATRTED", payload: {offerPaymentLoader: false}})
			return;
		}
		// else {
		// 	setPaymentLoader(false)
		// 	// { paymentMethod }
		// 	// console.log('[PaymentMethod]', paymentMethod);
		// }
		payWithCard(stripe, paymentMethod, cardElement)
			*/
		/* End of commented previous payment logic */
	};

	const payWithCard = (stripe, paymentMethod, cardElement) => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: true } })
		stripe.confirmCardPayment(latestClientSecret, { payment_method: paymentMethod ? { card: cardElement } : selectedCard }).then((result) => {
			if (result.error) {
				setError(error)
				dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
				setPaymentLoader(false)
			}
			else {
				{ result }
				// console.log({result})
				const data = {}
				// data.cartId = currentCartId
				data.stripePiId = latestClientSecret
				data.addressId = currentAddressId
				data.buyerUserId = currentUser._id
				data.buyerEmail = currentUser.email
				data.buyerPhone = currentUser.phone
				data.orderTotalInCents = result.paymentIntent.amount
				// data.orderNumber = generateRandomString
				data.usedMarkidCreditInCents = useCredits ? balance : 0.0
				data.usedMarkidCredit = useCredits
				data.lotItems = lotId
				// data.lotItems = _.map(currentCart, '_id');
				data.paymentMethodType = result.paymentIntent.payment_method_types[0]
				dispatch(createOrder(data, successCallBack, failureCallBack));
				// nextMethod()
			}
		})
	}
	const successCallBack = () => {
		// const newData = {}
		// newData.addressId = currentAddressId
		// newData.lotId = lotId
		// newData.price = price
		// // newData.deliveryNeeded = deliveryNeeded
		// dispatch(createMakeOffer(newData, successCallBackMakeAnOffer, failureCallBackMakeAnOffer));

	};
	const failureCallBack = () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		setPaymentLoader(false)
	};

	const successCallBackMakeAnOffer = () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		setPaymentLoader(false)
		history.push("/chat")
	}
	const failureCallBackMakeAnOffer = () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		setPaymentLoader(false)
	};
	const CARD_OPTIONS = {
		iconStyle: "solid",
		style: {
			base: {
				iconColor: "#c4f0ff",
				color: "#fff",
				fontWeight: 500,
				fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
				fontSize: "16px",
				fontSmoothing: "antialiased",
				":-webkit-autofill": {
					color: "#fce883"
				},
				"::placeholder": {
					color: "#87bbfd"
				}
			},
			invalid: {
				iconColor: "#ffc7ee",
				color: "#ffc7ee"
			}
		}
	};

	const onChangeCard = (cardId) => {
		setSelectedCard(cardId)
		setCDCForm(false)
		setUsePaymentMethod(true)
	}
	const handleOpenCardForm = () => {
		setSelectedCard("")
		setCDCForm(true)
		setUsePaymentMethod(false)
	}
	return (
		<>
			<Row gutter={[0, 5]} className="paymentCard">
				<Col span={24}></Col>
				{cards && cards.map((val, i) => {
					return (
						<Col span={24} key={i}>
							<Card className='shoppingCartCard'>
								<Row justify='space-between'>
									<Col xs={12}>
										<Row gutter={[{ xl: 20, lg: 20, md: 20, xs: 0, sm: 0 }, 0]}>
											<Col>
												<Radio.Group onChange={() => onChangeCard(val.id)} value={selectedCard}>
													<Radio className='payRedioText' value={val.id}>
														Credit card ending in {val.last4}
													</Radio>
												</Radio.Group>
											</Col>
											{val.isDefault &&
												<Col>
													<Button className="profileAddressButton">Default</Button>
												</Col>
											}
										</Row>
									</Col>
									<Col xs={{ span: 4, offset: 4 }}>
										{val.brand === "Visa" &&
											<img alt="" src={profileVisa} />
										}
										{val.brand === "MasterCard" &&
											<img alt="" src={MasterCard} style={{ width: 64, height: 22 }} />
										}
										{val.brand === "Discover" &&
											<img alt="" src={DiscoverCard} style={{ width: 64, height: 22 }} />
										}
										{val.brand === "American Express" &&
											<img alt="" src={AmericanExpress} style={{ width: 64, height: 22 }} />
										}
									</Col>
								</Row>
							</Card>
						</Col>
					);
				})}
			</Row>

			{/* <Row gutter={[0, 25]}>
                <Col span={24}></Col>
                <Col span={24}>
                    <Card className='shoppingCartCard'>
                        <Row justify='space-between'>
                            <Col>
                                <Radio className='payRedioText'>
                                    Google pay
                                </Radio>
                            </Col>
                            <Col>
                                <img alt="" src={GooglePayLogo} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row> */}
			{!cdcForm &&
				<Row gutter={[0, 25]}>
					<Col span={24}></Col>
					<Col>
						<Button className="buttonEdit"
							onClick={() => handleOpenCardForm()}
							icon={<PlusOutlined />}
						>
							Add new payment method</Button>
					</Col>
				</Row>
			}
			{cdcForm && (
				<Row gutter={[0, { xs: 14, sm: 14, md: 5, lg: 5, xl: 5, xxl: 5 }]} className="paymentCard">
					<Col span={24}></Col>
					<Col span={24} className="checkoutPaymentParent">
						<Card className='shoppingCartCard'>
							<Row justify='space-between'>
								<Col>
									<Radio className='addressRadio' defaultChecked={1}>
										Debit/Credit card
									</Radio>
								</Col>
								{/* <Col>
                    <img alt="" src={GooglePayLogo}/>
                    </Col> */}
							</Row>
							<Divider />
							{/* <form onSubmit={(e) => handleSubmit(handleStripeSubmit(e))} id="offermadestripeForm"> */}
							<form onSubmit={(e) => handleSubmit(handleSendOffer(e))} id="offermadestripeForm">
								<Row gutter={[0, 16]}>
									<Col span={24}></Col>

									<Col span={24}>
										<Row>
											<Typography className='brand-name'>Name</Typography>
										</Row>
										<Controller as={Input} name="name" control={control} require='true' />
										{errors.name_on_card && (
											<span style={{ color: "red" }}>
												Name is required
											</span>
										)}
									</Col>
									<Col style={{ width: isXS ? innerWidth - 85 : "30em" }}>
										<CardElement
										// options={CARD_OPTIONS} 
										// onChange={(e) => {
										//   setError(e.error);
										//   setCardComplete(e.complete);
										// }}
										/>
									</Col>
								</Row>
							</form>
						</Card>
						{/* <Button size='large' className='pay-button' onClick={() => setCDCForm(true)}>Credit or Debit card</Button> */}
					</Col>
				</Row>
			)}
		</>
	);
};
export default OfferMadePayment;
