import { Layout, Row, Col, Typography } from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import logo from "../assets/logo.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
// import MobileStoreButton from 'react-mobile-store-button';
import AppStoreButton from "@fenderdigital/react-app-store-button";
import "../App.css";
import { Link } from "react-router-dom";
import { createFromIconfontCN } from "@ant-design/icons";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
});

const { Title, Text } = Typography;
const { Footer } = Layout;

const ReNewedFooter = () => {
  const { isXS } = useBreakpoints();
  //   const iOSUrl =
  //     "https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8";

  return (
    <Row gutter={[24, { xs: 70, sm: 70 }]} align="middle">
      <Col span={24} />
      <Layout>
        <div className="footerParent">
          <Footer style={{ backgroundColor: "#E9F8F8" }}>
            <Row gutter={[24, 8]} justify="start">
              <Row
                gutter={[0,{ xs: 30, sm: 43}]}>
                <Col span={24} />
                <Link to="/">
                  <img className="markidLogo" alt="logo" src={logo} />
                </Link>
              </Row>
              <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }}>
                <Row justify="center" gutter={[8,{ xs: 50, sm: 50}]}>
                  <Col span={24} />
                  <Text className="downloadApp" type="secondary">Download the app</Text>
                </Row>
                <Row justify="center" gutter={[16, { xs: 15, sm: 15}]}>
                  <Col span={24}></Col>
                  <Col>
                    <AppStoreButton
                      className="mobile-store-button"
                      variant="google"
                    />
                  </Col>
                  <Col>
                    <AppStoreButton
                      className="mobile-store-button"
                      variant="apple"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }}>
                <Row>
                  <Col xs={12} sm={12}>
                    <Row
                      gutter={[8,{ xs: 25, sm: 25}]}>
                      <Col span={24} />
                      <Text className="footer-header">Shop</Text>
                    </Row>
                    <Row gutter={[0, 21]}>
                      <Col span={24}></Col>
                      <Col>
                        <Link to="/" className="footer-detail">
                          Home
                        </Link>
                      </Col>
                    </Row>
                    <Row gutter={[0, 23]}>
                      <Col span={24}></Col>
                      <Col>
                        <Link to="/" className="footer-detail">
                          Categories
                        </Link>
                      </Col>
                    </Row>
                    <Row gutter={[0, 23]}>
                      <Col span={24}></Col>
                      <Col>
                        <Link to="/" className="footer-detail">
                          ReNewed
                        </Link>
                      </Col>
                    </Row>
                    <Row gutter={[0, 23]}>
                      <Col span={24}></Col>
                      <Col>
                        <Link to="/" className="footer-detail">
                          Contact Us
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} xs={12}>
                    <Row gutter={[8,{ xs: 25, sm: 25 },]}>
                      <Col span={24} />
                      <Text className="footer-header">Inside markid</Text>
                    </Row>
                    <Row gutter={[0, 21]}>
                      <Col span={24}></Col>
                      <Col>
                        <Link to="/" className="footer-detail">
                          Contact Us
                        </Link>
                      </Col>
                    </Row>
                    <Row gutter={[0, 23]}>
                      <Col span={24}></Col>
                      <Col>
                        <Link to="/" className="footer-detail">
                          About Us
                        </Link>
                      </Col>
                    </Row>
                    <Row gutter={[0, 23]}>
                      <Col span={24}></Col>
                      <Col>
                        <Link to="/" className="footer-detail">
                          Investors
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
              <Col xs={24} sm={24}>
                <Row gutter={[ 8,{ xs: 25, sm: 25 }]}>
                  <Col span={24} />
                  <Text className="footer-header">FAQ</Text>
                </Row>
                <Row gutter={[0, 21]}>
                  <Col span={24}></Col>
                  <Col>
                    <Link to="/" className="footer-detail">
                      General Questions
                    </Link>
                  </Col>
                </Row>
                <Row gutter={[0, 23]}>
                  <Col span={24}></Col>
                  <Col>
                    <Link to="/" className="footer-detail">
                      Buyer Hub
                    </Link>
                  </Col>
                </Row>
                <Row gutter={[0, 23]}>
                  <Col span={24}></Col>
                  <Col>
                    <Link to="/" className="footer-detail">
                      Seller Hub
                    </Link>
                  </Col>
                </Row>
                <Row gutter={[0, 23]}>
                  <Col span={24}></Col>
                  <Col>
                    <Link to="/" className="footer-detail">
                      Community Guide
                    </Link>
                  </Col>
                </Row>
              </Col>
              </Row>
              </Col>
            </Row>

            {/* <Row align="middle"> */}
            {/* <Col span={24}> */}
            {/* <Card style={{ backgroundColor: "#E9F8F8" }}> */}
            <Row gutter={[0, 19]}>
              <Col span={24}>
                <Row>
                  <Col span={24} />
                  <Col span={24}> 
                    <Row justify="space-between" gutter={[10,0]}>
                      <Col>
                        <Text className="footer-button">Privacy Policy</Text>
                      </Col>
                      <Col>
                        <Text className="footer-button">Investors</Text>
                      </Col>
                      <Col>
                        <Text className="footer-button">Terms & Condition</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="center" gutter={[30, {xs:20, sm:20}]}>
                      <Col span={24}/>
                      <Col>
                        <IconFont
                          type="icon-facebook"
                          className="socialIcons"
                        />
                      </Col>
                      <Col>
                        <InstagramOutlined className="socialIcons" />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="center"  gutter={[30, {xs:20, sm:20}]}>
                      <Col span={24}/>
                      <Text className="footer-button">
                        2021 markid Inc. All rights reserved
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24} />
            </Row>
            {/* </Card> */}
            {/* </Col> */}
            {/* </Row> */}
          </Footer>
        </div>
      </Layout>
    </Row>
  );
};
export default ReNewedFooter;
