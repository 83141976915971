import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Spin, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "../App.css";
import { Carousel } from "antd";
import sellerhub from "../assets/new-assets/sellerhub.png";
import learnmore2 from "../assets/new-assets/why2.png";
import learnmore3 from "../assets/new-assets/why3.png";
import learnmore4 from "../assets/new-assets/why4.png";
import SignIn from "../pages/SignIn";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { joinCommunity } from "../Actions/userAction";
import { TypeOneReciver } from "./TypeOfMessages";
export const WhyMarketPlace = (props) => {
  const history = useHistory();
  const carouselRef = useRef(null);
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  const calculateTop = () => {
    let width = 0;
    if (isXS) {
      width = 302;
    } else if (isLG) {
      width = 335;
    } else if (isSM) {
      width = 200;
    } else if (isMD) {
      width = 200;
    } else if (isXL) {
      width = 320;
    } else if (isXXL) {
      width = 500;
    }
    return width;
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  return (
    <Row>
      <Col span={24} className="heroSlider">
        {(isXL || isLG || isXXL || isMD) && (
         <div >
            <section class="about_us_section">

              <div class="row">
                <div class="container" >
                <Row
                    gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
                    justify="center"
                  >
                    <Col>
                      <Typography className="featuredSeller p title" style={{marginTop:"90px", marginBottom:"80px"}}>
                      Why marketplace?
                      </Typography>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row
                   
                    gutter={{ xs: 15, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                    className="imagecenter display-flex-columns"
                  >
                    <Col
                      className="gutter-row min-width-100-percent"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <img
                        className="whymarketplace"
                        src={sellerhub}
                        alt="advisors-img"
                        width="113px"
                        height="75px"
                      />

                      <span>
                        <Typography class=" image_text_place" style={{marginTop:"18px"}}>
                          Designed for parents
                        </Typography>
                        <p className="whymarketplacetext">
                          We make selling your kids’ preloved items easy and
                          seamless so you can turn to what matters most - being
                          a parent.
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                      class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="whymarketplace"
                          src={learnmore2}
                          alt="advisors-img"
                          width="113px"
                          height="75px"
                        />
                      </a>
                      <span>
                        <Typography class="image_text_place" style={{marginTop:"18px"}}>
                          Shop kids’resale favorites
                        </Typography>
                        <p className="whymarketplacetext">
                          From Strollers to cribs, books to toys and clothing.
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                      class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="whymarketplace"
                          src={learnmore3}
                          alt="advisors-img"
                          width="113px"
                          height="75px"
                        />
                      </a>
                      <span>
                        <Typography class="image_text_place" style={{marginTop:"18px"}}>
                          Our team has your back
                        </Typography>
                        <p className="whymarketplacetext">
                          Buy kids preloved items with confidence. Our team is
                          here to guide you if you need help.{" "}
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                    //   class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="whymarketplace"
                          src={learnmore4}
                          alt="advisors-img"
                          width="113px"
                          height="75px"
                        />
                      </a>
                      <span>
                        <Typography className="image_text_place" style={{marginTop:"18px"}}>
                          Buy & sell kids’ preloved
                        </Typography>
                        <p className="whymarketplacetext">
                          Join a growing community of parents,connected in their
                          mission to pass on a greener planet to our kids.
                        </p>
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            </div>
        )}
         {(isXS || isSM) && (
          <div className="mobilesellerBannerContent">
            <section class="about_us_section">
              <div class="row">
                <div class="container">
                <Row
              gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
              justify="center"
            >
              <Col>
                <Typography className="featuredSeller p title" style={{marginTop:"24px"}}>
                Why marketplace?
                </Typography>
              
              </Col>
              <Col></Col>
            </Row>
                  <Row
                    gutter={{ xs: 15, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                    className="imagecenter display-flex-columns"
                  >
                    <Col
                      className="gutter-row min-width-100-percent"
                      id="n-imagecontainer"
                      span={6}
                     
                    >
                      <img
                        className="whymarketplace"
                        src={sellerhub}
                        alt="advisors-img"
                        width="113px"
                        height="75px"
                      />

                      <span>
                        <Typography class=" image_text_place">
                          Designed for parents
                        </Typography>
                        <p className="whymarketplacetext">
                          We make selling your kids’ preloved items easy and
                          seamless so you can turn to what matters most - being
                          a parent.
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                      class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="whymarketplace"
                          src={learnmore2}
                          alt="advisors-img"
                          width="113px"
                          height="75px"
                        />
                      </a>
                      <span>
                        <Typography class="image_text_place">
                          Shop kids’resale favorites
                        </Typography>
                        <p className="whymarketplacetext">
                          From Strollers to cribs, books to toys and clothing.
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                      class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="whymarketplace"
                          src={learnmore3}
                          alt="advisors-img"
                          width="113px"
                          height="75px"
                        />
                      </a>
                      <span>
                        <Typography class="image_text_place">
                          Our team has your back
                        </Typography>
                        <p className="whymarketplacetext">
                          Buy kids preloved items with confidence. Our team is
                          here to guide you if you need help.{" "}
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                    //   class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="whymarketplace"
                          src={learnmore4}
                          alt="advisors-img"
                          width="113px"
                          height="75px"
                        />
                      </a>
                      <span>
                        <Typography className="image_text_place">
                          Buy & sell kids’ preloved
                        </Typography>
                        <p className="whymarketplacetext">
                          Join a growing community of parents,connected in their
                          mission to pass on a greener planet to our kids.
                        </p>
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
           
          </div>
        )}
      </Col>

      {/* For Mobile  */}
    </Row>
  );
};
