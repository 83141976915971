import constants from "../Utils/constants";
const initialState = {
  isGetAllCategoriesLoading: false,
  isGetCategoryByIdLoading: false,
  allCategories: [],
  category: [],
  isProductListLoading: false,
  cribsList: [],
  strollersList: [],
  gearList: [],
  clothingList: [],
  shoesList: [],
  toysList: [],
  feedingList: [],
  diaperingList: [],
  bathingList: [],
  sportsList: [],
  booksList: [],
  safetyList: [],
  maternityList: [],
  electronicsList: [],
  recentlyAdded: [],
  recentlySold: [],
  recentlySoldAll: [],
  verifiedproducts:[],
  allsubsizes:[],
  allsizelist:[]
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {


    case constants.GET_ALL_RECENTLY_SOLD:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_ALL_RECENTLY_SOLD_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        recentlySoldAll: action.payload.data,
      };

    case constants.GET_ALL_RECENTLY_SOLD_FAILURE:
      return {
        ...state,
        isProductListLoading: true,
      };


    case constants.GET_RECENTLY_SOLD_PRODUCTS:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_RECENTLY_SOLD_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        recentlySold: action.payload.data,
      };

    case constants.GET_RECENTLY_SOLD_PRODUCTS_FAILURE:
      return {
        ...state,
        isProductListLoading: true,
      };

    case constants.GET_RECENTLY_ADDED_PRODUCTS:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_RECENTLY_ADDED_PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        recentlyAdded: action.payload.data,
      };

    case constants.GET_RECENTLY_ADDED_PRODUCTS_FAILURE:
      return {
        ...state,
        isProductListLoading: true,
      };

    case constants.GET_CRIBS_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_CRIBS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        cribsList: action.payload.data,
      };
    case constants.GET_STROLLERS_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_STROLLERS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        strollersList: action.payload.data,
      };
    case constants.GET_GEAR_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_GEAR_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        gearList: action.payload.data,
      };
    case constants.GET_CLOTHING_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_CLOTHING_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        clothingList: action.payload.data,
      };
    case constants.GET_SHOES_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_SHOES_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        shoesList: action.payload.data,
      };
    case constants.GET_TOYS_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_TOYS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        toysList: action.payload.data,
      };
    case constants.GET_FEEDING_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_FEEDING_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        feedingList: action.payload.data,
      };
    case constants.GET_DIAPERING_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_DIAPERING_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        diaperingList: action.payload.data,
      };
    case constants.GET_BATHING_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_BATHING_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        bathingList: action.payload.data,
      };
    case constants.GET_SPORTS_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_SPORTS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        sportsList: action.payload.data,
      };
    case constants.GET_BOOKS_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_BOOKS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        booksList: action.payload.data,
      };
    case constants.GET_SAFETY_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_SAFETY_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        safetyList: action.payload.data,
      };
    case constants.GET_MATERNITY_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_MATERNITY_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        maternityList: action.payload.data,
      };

    case constants.GET_ELECTRONICS_PRODUCTS_REQUEST:
      return {
        ...state,
        isProductListLoading: true,
      };
    case constants.GET_ELECTRONICS_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        electronicsList: action.payload.data,
      };
      case constants.GET_VERIFIED_SUCCESS:
      return {
        ...state,
        isProductListLoading: false,
        verifiedproducts: action.payload.data,
      };

    case constants.GET_CATEGORIES_PRODUCTS_FAILURE:
      return {
        ...state,
        isProductListLoading: true,
      };

    // ?????????????????????????????????

    case constants.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        isGetAllCategoriesLoading: true,
      };
    case constants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isGetAllCategoriesLoading: false,
        allCategories: action.payload.categories,
      };
      case constants.GET_SUBSIZES_SUCCESS:
        return {
          ...state,
          isGetAllCategoriesLoading: false,
          allsubsizes: action.payload.result,
          allsizelist:action.payload.sizelist,
        };
    case constants.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        isGetAllCategoriesLoading: false,
      };

    case constants.GET_CATEGORY_BY_ID_REQUEST:
      return {
        ...state,
        isGetCategoryByIdLoading: true,
      };
    case constants.GET_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        isGetCategoryByIdLoading: false,
        category: action.payload,
      };
    case constants.GET_CATEGORY_BY_ID_FAILURE:
      return {
        ...state,
        isGetCategoryByIdLoading: false,
      };
    case constants.GET_TAB_KEY:
      return {
        ...state,
        tabKey: action.payload,
      };
    default:
      return state;
  }
};
export default categoriesReducer;
