import React from "react";

import { Typography, Row, Col, Button, Modal } from "antd";

import "../App.css";

import { useHistory ,useLocation,useParams} from "react-router-dom";

export const SellerCancelModal = (props) => {
  const { showcancelDialog, setShowcancelDialog } = props;
  const history = useHistory();
  const location=useLocation();
  const handleCanceldialog = () => {
    setShowcancelDialog(false);
  };
  const redirectToedit = () => {
    history.go(-1);
  };
  const redirectToaddProduct = () => {
    history.push(`/seller/addProduct`);
    localStorage.removeItem("productDescription")
    localStorage.removeItem("negotiablePrice")
    localStorage.removeItem("retailPrice")
    localStorage.removeItem("age")
    localStorage.removeItem("activeManufacturedYear")
    localStorage.removeItem("activeWeight")
    localStorage.removeItem("size")
    localStorage.removeItem("activepurchasedYear")
    localStorage.removeItem("productTitle")
    localStorage.removeItem("activeCategory")
    localStorage.removeItem("Pay")
    localStorage.removeItem("deliveryMethod")
    localStorage.removeItem("sellingPrice")
    localStorage.removeItem("activeGender")
    localStorage.removeItem("activeHeight")
    localStorage.removeItem("activeColor")
    localStorage.removeItem("imagesUploaded")
    localStorage.removeItem("activeLength")
    localStorage.removeItem("currentAddressID")
    localStorage.removeItem("activeCondition")
    localStorage.removeItem("estimatedCharges")
    localStorage.removeItem("activeBrand")
    localStorage.removeItem("activeWidth")
    localStorage.removeItem("brandlogo")
    localStorage.removeItem("ImageLinks")
    localStorage.removeItem("descriptionCount")
  };
  const params = useParams();
  const { title } = params;
 
  return (
    <>
      <Modal
        footer={null}
        width={813}
        visible={showcancelDialog}
        onCancel={() => {
          handleCanceldialog();
        }}
      >
        <form>
          <div className="cancelwrapper">
            <Row gutter={[0, { xs: 50, sm: 50, md: 76, lg: 76, xl: 76 }]}>
              <div className="cancelCard">
                <Row justify="center">
                  <Col span={24}></Col>
                  <Col>
                    <Typography
                      className="cancelText"
                      style={{ color: "black" }}
                    >
                      Are you sure you want to cancel
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[0, 61]}>
                  <Col span={24}></Col>
                  <Col className="cancelbutton">
                    <Row gutter={16}>
                      <Col className="gutter-row">
                        <div>
                          {history.location.pathname ==
                          `/seller/product-details/${title}` ? (
                            <Button
                              justify="center"
                              className={"button button-primary  pLCartButton"}
                              onClick={(event) => {
                                redirectToaddProduct(event);
                              }}
                            >
                              Yes
                            </Button>
                          ) : (
                            <Button
                              justify="center"
                              className={"button button-primary  pLCartButton"}
                              onClick={(event) => {
                                redirectToedit(event);
                              }}
                            >
                              Yes
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Button
                            justify="center"
                            className={"button button-primary  pLCartButton"}
                            onClick={handleCanceldialog}
                          >
                            No
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </form>
      </Modal>
    </>
  );
};
