import { Layout, Row, Col, Typography, Card } from "antd";
import {
  FacebookFilled,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import logo from "../assets/logo.svg";
// import MobileStoreButton from 'react-mobile-store-button';
// import AppStoreButton from "@fenderdigital/react-app-store-button";
import ReactStoreBadges from 'react-store-badges'
import "../App.css";
import { Link } from "react-router-dom";
import "../App.css";
const { Title, Text } = Typography;
const { Footer } = Layout;

const MarkidFooter = () => {
  return (
    <Row gutter={[2,0]} align='middle'>
    <Layout>
      <Footer>
        <Row gutter={[24,8]}>
          <Col xl={6} lg={8} md={12} sm={12}>
            <Row>
              <Title level={5} strong>
                Inside markid
              </Title>
            </Row>
            <Row gutter={[0,8]}>
                <Col span={24}></Col>
                <Col>
            <a href={process.env.REACT_APP_STATIC_DOMAIN+'contact'} target="_" style={{color: 'black'}}>
              Contact Us
              </a>
              </Col>
            </Row>
            <Row gutter={[0,8]}>
            <Col span={24}></Col>
                <Col>
                <a href={process.env.REACT_APP_STATIC_DOMAIN+'about-us'} target="_" style={{color: 'black'}}>
                About Us
            </a>
            </Col>
            </Row>
            <Row gutter={[0,8]}>
            <Col span={24}></Col>
                <Col>
                <a href={process.env.REACT_APP_STATIC_DOMAIN+'contact'} target="_" style={{color: 'black'}}>
                Investors
                </a>
                </Col>
            </Row>
          </Col>
          <Col xl={6} lg={8} md={12} sm={12}>
            <Row>
              <Title level={5} strong>
                Follow Us
              </Title>
            </Row>
            <a href="https://www.facebook.com/hellomarkid" target="_" style={{color: 'black'}}>
            <Row gutter={[8,8]}>
                <Col span={24}></Col>
                <Col>
                <FacebookFilled />
                </Col>
                <Col>
                <Typography>
                Facebook
              </Typography>
              </Col>
            </Row>
            </a>
            <a href="https://twitter.com/hellomarkid" target="_" style={{color: 'black'}}>
            <Row gutter={[8,8]}>
                <Col span={24}></Col>
                <Col>
                <TwitterOutlined />
                </Col>
                <Col>
                <Typography>
                Twitter
                </Typography>
              </Col>
            </Row>
            </a>
            <a href="https://www.instagram.com/hellomarkid/?utm_source=ig_profile_share&igshid=1wqjpnlez4y64" target="_" style={{color: 'black'}}>
            <Row gutter={[8,8]}>
                <Col span={24}></Col>
                <Col>
                <InstagramOutlined />
                </Col>
                <Col>
                <Typography>
                Instagram
              </Typography>
              </Col>
            </Row>
            </a>

          </Col>
          <Col xl={6} lg={8} md={12} sm={12}>
            <Row>
              <Title level={5} strong>
                FAQ
              </Title>
            </Row>
            <Row gutter={[0,8]}>
            <Col span={24}></Col>
                <Col>
                <a href={process.env.REACT_APP_STATIC_DOMAIN+'general-questions'} target="_" style={{color: 'black'}}>
              General Questions
              </a>
              </Col>
            </Row>
            <Row gutter={[0,8]}>
            <Col span={24}></Col>
                <Col>
                <a href={process.env.REACT_APP_STATIC_DOMAIN+'buyer-hub'} target="_" style={{color: 'black'}}>
              Buyer Hub
              </a>
              </Col>
            </Row>
            <Row gutter={[0,8]}>
            <Col span={24}></Col>
                <Col>
                <a href={process.env.REACT_APP_STATIC_DOMAIN+'seller-hub'} target="_" style={{color: 'black'}}>
              Seller Hub
              </a>
              </Col>
            </Row>
            <Row gutter={[0,8]}>
            <Col span={24}></Col>
                <Col>
                <a href={process.env.REACT_APP_STATIC_DOMAIN+'community-guidelines'} target="_" style={{color: 'black'}}>
              Community Guide
              </a>
              </Col>
            </Row>
          </Col>
          <Col xl={6} lg={8} md={12}>
            <Row justify='center' gutter={[8,16]}>
            <Col span={24}></Col>
            <Link to="/">
              <img alt="logo" src={logo} />
              </Link>
            </Row>
            <Row justify='center' gutter={[8,16]}>
            <Col span={24}></Col>
            <Text type="secondary">Download the app</Text>
            </Row>
            <Row gutter={[8, 16]} justify='center'>
              <Col span={24}></Col>
              {/* <Col>
              {/ * <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                /> * /}
                {/ * <AppStoreButton
                  className="mobile-store-button"
                  variant="google"
                  href="https://play.google.com/store/apps/details?id=com.markid"
                /> * /}
              </Col> */}
              <Col>
              <ReactStoreBadges
                platform={'ios'}
                url={'https://apps.apple.com/us/app/markid/id1436309782'}
                target="_"
                locale={'en-us'}
                />
                {/* <AppStoreButton
                  className="mobile-store-button"
                  variant="apple"
                  href=" https://apps.apple.com/us/app/markid/id1436309782"
                /> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>
      <Row align="middle">
        <Col span={24}>
          <Card style={{ backgroundColor: "#ff533f" }}>
            <Row justify="space-between">
              <Col>
                <Row gutter={[8,0]}>
                  <Col>
                    {/* <a style={{color: '#ffffff'}} href={process.env.REACT_APP_STATIC_DOMAIN+'privacy-policy'} target="_">Privacy Policy</a> */}
                    <Link className='pITC' to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </Col>
                  <Col>
                    <a href={process.env.REACT_APP_STATIC_DOMAIN+'contact'} target="_" style={{color: '#ffffff'}}>Investors</a>
                  </Col>
                  <Col>
                    {/* <a href={process.env.REACT_APP_STATIC_DOMAIN+'terms-and-conditions'} target="_" style={{color: '#ffffff'}}>Terms & Condition</a> */}
                    <Link className='pITC' to="/terms-and-conditions">
                      Terms and Condition
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Title level={5} style={{color: '#ffffff'}}>2021 markid Inc. All rights reserved</Title>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
    </Row>
  );
};
export default MarkidFooter;
