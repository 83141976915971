/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Checkbox,
  Spin,
  Alert,
  Typography,
  Layout
} from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ProductTop from "../components/ProductTop";
import PMenu from "../components/PMenu";
import { OfferMadeAddresses } from "../components/OfferMadeAddresses";
import OfferMadePayment from "../components/OfferMadePayment";
import OrderSummary from "../components/OrderSummary";
import OfferMadePriceDetails from "../components/OfferMadePriceDetails";
import "../App.css";
import _ from "lodash";
import { createCart, getCurrentUserWallet } from "../Actions/cartAction";
import SignIn from "./SignIn";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { createMakeOffer } from "../Actions/offerAction";
import { loadStripe } from '@stripe/stripe-js';
import { useBreakpoints } from './../react-responsive-hooks/index';
import { updateOfferByBuyer } from "../Actions/offerAction";
import { Helmet } from "react-helmet";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const { Header, Content, Footer } = Layout;
const OfferMade = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { isXS, isSM } = useBreakpoints();
  const dispatch = useDispatch();
  const history = useHistory();
  // const stripe = useStripe();
  // const elements = useElements();
  const { nextMethod } = props;
  const { currentCart, currentAddressId, currentUser, currentUserWallet, isPostCartLoading, isPostOrderLoading, isAuthenticated, currentCartId, isPostOfferLoading, errorMessage, madeOfferData, createdOffer, offerPaymentLoader } = useSelector((state) => {
    const { currentCart, currentAddressId, currentUserWallet, isPostCartLoading, currentCartId } = state.cart;
    const { currentUser } = state.user;
    const { isAuthenticated } = state.auth;
    const { isPostOfferLoading, errorMessage, madeOfferData, createdOffer, offerPaymentLoader } = state.offer
    const { isPostOrderLoading } = state.order;
    // const [shippingCharges, setShippingCharges] = useState(0)
    let tempPrice = 0;
    console.log(currentCart);
    // console.log(shippingCharges);

    // useEffect(() => {

    //   if(currentCart && currentCart.length > 0){
    //     currentCart.map((cartItems) => {
    //       if(cartItems.isFree == false){
    //         tempPrice = parseInt(cartItems.shippingPrice) + tempPrice
    //         setShippingCharges(tempPrice)
    //       }
    //     })
    //   }
    // }, [currentCart])
    return {
      currentCart,
      currentAddressId,
      currentUserWallet,
      currentUser,
      isPostCartLoading,
      isAuthenticated,
      currentCartId,
      isPostOfferLoading,
      isPostOrderLoading,
      errorMessage,
      madeOfferData,
      createdOffer,
      offerPaymentLoader
    };
  });
  const [shippingCharges, setShippingCharges] = useState(0);
  let tempPrice = 0;
  useEffect(() => {
    if (currentCart && currentCart.length > 0) {
      currentCart.map((cartItems) => {
        if (cartItems.isFree == false) {
          tempPrice = parseInt(cartItems.shippingPrice) + tempPrice
          setShippingCharges(tempPrice)
        }
      })
    }
  }, [currentCart]);
  const { offerId } = createdOffer || {}
  const { offerData } = madeOfferData[0]
  const { lotId, price, deliveryNeeded, shippingPrice } = offerData || {}
  const [addressError, setAddressError] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [validate, setValidate] = useState({});
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [usePaymentMethod, setUsePaymentMethod] = useState(true)
  const [callStripeApi, setCallStripeApi] = useState(false)
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCurrentUserWallet());
  }, []);
  const { handleSubmit } = useForm({
  });
  const handleOfferUpdateByBuyer = (data) => {
    data.addressId = currentAddressId
    dispatch(updateOfferByBuyer(offerId, data));
  }
  const handlemakeOfferClick = () => {
    dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: true } })
    // console.log({data})
    // const newData = data
    const newData = {};
    newData.addressId = currentAddressId;
    newData.lotId = lotId;
    newData.price = price;
    newData.shippingPrice = shippingPrice;
    // newData.deliveryNeeded = deliveryNeeded
    dispatch(createMakeOffer(newData, successCallBack, failureCallBack));
  }
  const successCallBack = () => {
    // console.log("Success")
    // ref.current.click()
    history.push("/chat")
    dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
  };
  const failureCallBack = () => {
    dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
  };
  // useEffect(() => {
  //   const newData = {
  //     addressId: currentAddressId,
  //     lotId: lotId,
  //     price: price
  //   }
  //   dispatch(createMakeOffer(newData));
  // }, []);
  // const ref = useRef(null)
  return (
    <>
      <Helmet>
        <title>Markid - Make Offer</title>
      </Helmet>
      <Layout>
        <Header className="makeOfferHeader" style={{ position: 'fixed', zIndex: 1, width: '100%', height: '100px', backgroundColor: '#FFFFFF' }}>
          <Row gutter={[0, 0]}>
            <Col span={24}></Col>
            <Col offset={0}>
              {/* <Link to="/homepage"> */}
              <Link to="/">
                <img alt="logo" src={Logo} />
              </Link>
            </Col>
          </Row>
        </Header>

        <Spin style={{ top: 200, color: '#FF533F' }} indicator={antIcon} spinning={offerPaymentLoader}>
          <Content className="checkoutBG">
            <div className="app-wrapper">
              <Row gutter={[0, 25]}>
                <Col span={24}></Col>
                <Col span={24}></Col>
                <Col span={24}></Col>
                <Col offset={0}>
                  <Row gutter={[28, 0]}>
                    <Col>
                      <Typography className='afterBackIconTitle'>
                        Offer made
                      </Typography>
                      <Row gutter={[0, 8]}>
                        <Col span={24}></Col>
                        <Typography className='totalCartItemText'>
                          You have made offer for {madeOfferData ? madeOfferData.length : 0} item
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={[0, 35]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Row gutter={[41, 20]} justify='center' style={{ marginRight: 0 }}>
                    <Col span={24}></Col>
                    {/* xl={{ span: 13, offset: 1 }} lg={{ span: 14, offset: 1 }} md={{ span: 11, offset: 1 }} sm={22} xs={22} */}
                    <Col >
                      <OfferMadeAddresses madeOfferData={madeOfferData} setAddressError={setAddressError} />
                      {/* <Row gutter={[0, 50]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className='dAddressText'>
                          Payment Method
                        </Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 14]}>
                      <Col span={24}></Col>
                      <Col span={24}>
                        <Elements stripe={stripePromise}>
                          <OfferMadePayment 
                          validate={validate} 
                          setValidate={setValidate} 
                          paymentLoader={paymentLoader} 
                          setPaymentLoader={setPaymentLoader} 
                          madeOfferData={madeOfferData}
                          handleOfferUpdateByBuyer={handleOfferUpdateByBuyer}
                          setUsePaymentMethod={setUsePaymentMethod} 
                          callStripeApi={callStripeApi}
                          />
                        </Elements>
                      </Col>
                    </Row> */}
                    </Col>
                    <Col xl={8} lg={8} md={11} sm={22} xs={22}>
                      {/* <form onSubmit={handleSubmit(handlemakeOfferClick)}> */}
                      <Card className='priceDetailsCard'>
                        <OfferMadePriceDetails
                          heading="Price details"
                          shipping="Shipping"
                          shippingVal={(shippingCharges / 100).toFixed(2)} // {(shippingCharges / 100).toFixed(2)}
                          cartTotal="Cart Total"
                          cartTotalVal={price / 100}
                          button={
                            usePaymentMethod ?
                              <Button
                                disabled={currentAddressId ? false : true}
                                block
                                className={'squarebutton'}
                                // onClick={() => setCallStripeApi(true)}
                                onClick={() => handlemakeOfferClick()}
                              >
                                SEND OFFER
                              </Button>
                              :
                              <Button
                                disabled={currentAddressId ? false : true}
                                block
                                className={'squarebutton'}
                                htmlType='submit'
                                form="offermadestripeForm"
                              >
                                SEND OFFER
                              </Button>
                          }
                        />
                        {!currentAddressId &&
                          <Row justify='center' gutter={[0, 8]}>
                            <Col span={24}></Col>
                            <Alert
                              type="error"
                              onClose={handleClose}
                              open={open}
                              message="Please create a address"
                            />
                          </Row>
                        }
                        {validate.message &&
                          <Row justify='center' gutter={[0, 8]}>
                            <Col span={24}></Col>
                            <Alert
                              type="error"
                              open={open}
                              message={validate.message}
                            />
                          </Row>
                        }
                        {errorMessage &&
                          <Row justify='center' gutter={[0, 8]}>
                            <Col span={24}></Col>
                            <Alert
                              type="error"
                              onClose={handleClose}
                              open={open}
                              message={errorMessage}
                            />
                          </Row>
                        }
                      </Card>
                      {/* <Button
                        style={{ display: 'none' }}
                        htmlType='submit'
                        form="offermadestripeForm"
                        ref={ref}
                      >

                      </Button> */}
                      {/* </form> */}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}></Col>
              </Row>
            </div>
          </Content>
        </Spin>
      </Layout>
    </>
  );
};
export default OfferMade;
