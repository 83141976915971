/*eslint-disable*/
import React, { useState } from "react";
import { Row, Col, Card, AutoComplete, Input, Typography, Button } from "antd";
import "../App.css";
import swoondle2 from "../assets/new-assets/swandool_2.png";
import swoondle_mobile from "../assets/new-assets/swandool_2_mobile.png";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { useHistory } from "react-router";
const Partnerselection = (props) => {
	const { categoryRef } = props
	const { isXXL, isXL, isLG, isMD, isXS, isSM } = useBreakpoints();
	const history = useHistory()
	const handleRenewedHandPickedItem = () => {
		// if(window.location.pathname === '/homepage'){
		if (window.location.pathname === '/') {
			//   history.push(`/handpicked/handpicked/markid ReNEWED/5fb580cc5f1dfa0e9e75720e`);
			//   history.push(`/handpicked/handpicked/markid ReNEWED/${process.env.REACT_APP_STATIC_USER_ID}`);
			history.push(`partnerproduct-list/swoondle`);
		} else {
			// history.push('/homepage');
		}
	}
	return (
		<>
			{(isXXL || isXL || isLG || isMD) &&
				// <Row gutter={[0, { xs: 70, sm: 70, md: 138, lg: 138, xl:138, xxl:138 }]}>
				<div className='PRSBackground'>
					<Row className="app-wrapper" align="middle" justify="space-between">
						<Col span={24}></Col>

						{/* <div className='PRSBackground'> */}
						{/* <Row gutter={[0, { xs: 49, sm: 49, md: 98, lg: 98, xl:98, xxl:98 }]} > */}
						{/* <Col span={24}></Col> */}
						{/* <Col span={24}></Col> */}
						<Col span={12} className="discover-lt">
							<div className="partnerBannerTextWrapper">
								<Row gutter={[0, 0]}>
									<Typography className="PRSHeading">
										Discover more, with Markid-
										certified Partners
									</Typography>
								</Row>
								<Row gutter={[0, 0]}>
									<Col span={24}></Col>
									<Col>
										<Typography className='PRSDes'>
											Explore certified brands and stores for kids’ gear,
											clothing, toys, and more! All in one place !!!!!.
										</Typography>
									</Col>
								</Row>
								<Row gutter={[0, 0]}>
									<Col span={24}></Col>
									<Col>
										<Typography className="PRText">
											Just the way you’d like them - for your kids.
										</Typography>
									</Col>
								</Row>
								<Row gutter={[0, 0]}>
									<Col span={24}></Col>
									<Col>
										<Button className='button button-primary btn-shop' onClick={() => handleRenewedHandPickedItem()}>
											Shop Now 
										</Button>
									</Col>
								</Row>
							</div>
						</Col>

						<Col span={12} className="discover-rt">
							<img src={swoondle2} alt="" className='PRSCap' />
						</Col>

						{/* <Row>
						<img src={BgShape} style={{ marginTop: -150 }} alt="" />
					</Row> */}
						{/* </Row> */}
						{/* </div> */}
					</Row>
				</div>

			}
			{(isXS || isSM) &&
				<Row gutter={[0, { xs: 79, sm: 79 }]}>
					{/* <Col span={24}></Col> */}
					{/* <Col span={24}></Col> */}
					<div className='PRSBackgroundMob'>
						<Row gutter={[0, 26.33]} justify='center'>
							<Col xs={14} sm={14}>
								<Row>
									<Typography className="PRSHeading">
										Discover more, with Markid-certified Partners
									</Typography>
								</Row>
								<Row gutter={[0, 30]} justify='left'>
									<Col span={24}></Col>
									<Col>
										<Button className='button button-primary shopNowButton for-mob' onClick={() => handleRenewedHandPickedItem()}>
											Shop Now
										</Button>
									</Col>
								</Row>
							</Col>
							<Col xs={10} sm={10}>
								<img src={swoondle_mobile} alt="" className='PRSMobImg' />
							</Col>
						</Row>
					</div>
				</Row>
			}
		</>
	);
};
export default Partnerselection;
