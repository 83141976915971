import constants from "../Utils/constants";
const initialState = {
  isGetPartnerDetailsLoading: false,
  partnerDetails: [],
};

const partnerDetailsReducer = (state = initialState, action) => {
  switch (action.type) {

      case constants.GET_PARTNER_BY_ID_REQUEST:
      return {
        ...state,
        isGetPartnerDetailsLoading: true,
      };
    case constants.GET_PARTNER_BY_ID_SUCCESS:
      const partnerDetails = action.payload
    //   partnerProductData.seller = action.payload.seller
      return {
        ...state,
        isGetPartnerDetailsLoading: false,
        partnerDetails: partnerDetails,
      }; 
    case constants.GET_PARTNER_BY_ID_FAILURE:
      return {
        ...state,
        isGetPartnerDetailsLoading: false,
      };
    default:
      return state;
  }
};
export default partnerDetailsReducer;