import React from "react";
import { Button, Row, Col, Card, Typography,  } from "antd";
import logo from "../assets/logo.svg";
import CommunityCard from "../components/CommunityCard";
import Avatar from "antd/lib/avatar/avatar";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;

const Community = (props) => {
  return (
    <>
      <Helmet>
        <title>Markid - Community</title>
      </Helmet>
      <Row justify="center">
        <Col xl={{span:14, offset: 2 }} lg={{span:14, offset: 2 }} md={{span:16, offset: 2 }} sm={22} xs={22}>
          <Card>
            <Row gutter={[48, 16]}>
              <Col span={24}></Col>
              <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                <img
                  alt="logo"
                  src={logo}
                  // preview={false}
                />
              </Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
            </Row>

            <Row justify="center" gutter={(0, 48)}>
              <Col span={24}></Col>
              <Col>
                <Title level={2}>Join a community</Title>
              </Col>
            </Row>
            <Row justify="center" gutter={(0, 0)}>
              <Col span={24}></Col>
              <Col span={24}>
                <Text>Meet other parents and interact on common</Text>
              </Col>
              <Col>
                <Text>topics, listings and more</Text>
              </Col>
            </Row>

            <Row gutter={[8,32]}>
                <Col span={24}></Col>
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <CommunityCard 
                    cover={<Avatar shape='square' size='large' />}
                    title='Sellers community' 
                    descriptions='Join other sellers to share tips, ideas and more'
                    members='634 members'
                    button={<Button type="link" style={{color: 'black'}}>Join</Button>}
                    />
                </Col>
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <CommunityCard 
                    cover={<Avatar shape='square' size='large' />}
                    title='Expecting community' 
                    descriptions='Interact with other moms who are expecting'
                    members='634 members'
                    button={<Button type="link" style={{color: 'black'}}>Join</Button>}
                    />
                </Col>
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <CommunityCard 
                    cover={<Avatar shape='square' size='large' />}
                    title='Latest community' 
                    descriptions='Discuss latest trends, tips, photos and more'
                    members='634 members'
                    button={<Button type="link" style={{color: 'black'}}>Join</Button>}
                    />
                </Col>
               
            </Row>
            <Row justify="center" gutter={[8, 24]}>
              <Col span={24}></Col>
              <Col>
                <Button
                 className={'squarebutton'}
                >
                  CONTINUE
                </Button>
              </Col>
              <Col span={24}></Col>
            </Row>
          </Card>
        </Col>
        <Col xl={8} lg={8} md={8} sm={22} xs={22}>
          <Card style={{ backgroundColor: "#d8d8d8", height: 331 }} />
        </Col>
      </Row>
    </>
  );
};
export default Community;
