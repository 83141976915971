import { React, useEffect } from "react";
import { Helmet } from "react-helmet";

const TermsConditions = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div class="container" id="i-container" className="content_wrapper"  >
      <Helmet>
        <title>Markid - Terms and Conditions</title>
      </Helmet>
      <div className="headerlines"> Terms and Conditions</div>

      <p>
        Markid is a marketplace for parents to buy and sell kids preowned and
        new products from each other as well as from businesses, as offered on
        the “Markid” mobile app (together with any successor app(s), the “App”),
        and on our “Markid” website currently located at markid.io (together
        with any successor site(s), the “Site”). This Agreement refers to the
        App and Site (together with any materials and services available
        therein) as the “Service”. Please read these Terms and Conditions (the
        “Agreement”) carefully. Your use of the Service (as defined below)
        constitutes your consent to this Agreement. This Agreement is between
        you and Markid Inc. (“Markid”, or “Company” or “we” or “us”) concerning
        your use of the “Service”. Please note that this Agreement hereby
        incorporates by reference any additional terms and conditions posted by
        Company through the Service, or otherwise made available to you by
        Company. BY USING THE SERVICE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO
        ENTER INTO THIS AGREEMENT, OR, IF YOU ARE NOT, THAT YOU HAVE OBTAINED
        PARENTAL OR GUARDIAN CONSENT TO ENTER INTO THIS AGREEMENT, AND THAT YOUR
        PARENT OR GUARDIAN HAS AGREED TO BE LIABLE FOR YOUR ACTS AND OMISSIONS.
      </p>
      <h4 className="header2">Changes:</h4>
      <p>
        We reserve the right to change this Agreement from time to time by
        notifying you of such changes by any reasonable means, which includes
        posting a revised Agreement accessible through the Service. Any such
        changes will not apply to any dispute between you and us arising prior
        to the date on which we posted the revised Agreement incorporating such
        changes, or otherwise notified you of such changes. Your use of the
        Service following any changes to this Agreement will constitute your
        acceptance of such changes. The “Last Updated” legend above indicates
        when this Agreement was last changed. We may, at any time and without
        liability, modify or discontinue all or part of the Service; charge,
        modify or waive any fees required to use the Service; or offer
        opportunities to some or all Service users.
      </p>
      <div className="header2">Information Submitted Through the Service:</div>
      <p>
        Your submission of information through the Service is governed by
        Company’s Privacy Policy (the “Privacy Policy”). You represent and
        warrant that any information you provide in connection with the Service,
        including Product listings and other Submissions (as defined below), is
        and will remain accurate and complete, and that you will maintain and
        update such information as needed.
      </p>
      <div className="header2">Eligibility:</div>
      <p>
        ONLY USERS WHO ARE THIRTEEN (13) YEARS OF AGE OR OLDER MAY REGISTER FOR
        OR USE THE SERVICE. The Service is not targeted towards, nor intended
        for use by, anyone under the age of thirteen (13). If you are between
        the ages of thirteen (13) and eighteen (18), you may use the Service
        only under the supervision of a parent or legal guardian who agrees to
        be bound by this Agreement and any applicable additional terms. You
        further represent and warrant that you: (a) have not previously been
        suspended or removed from using the Service; (b) are legally permitted
        to, and do, live in the United States or one of its territories, and (c)
        may enter into this agreement without violating any other agreement to
        which you are a party. If you are registering to use the Service on
        behalf of a legal entity, you further represent and warrant that (i)
        such legal entity is duly organized and validly existing under the
        applicable laws of the jurisdiction of its organization, and (ii) you
        are duly authorized by such legal entity to act on its behalf.
      </p>
      <div className="header2">Jurisdictional Issues:</div>
      <p>
        The Service is controlled or operated (or both) from the United States,
        and is not intended to be subject Company to any non-U.S. jurisdiction
        or law. The Service may not be appropriate or available for use in some
        non-U.S. jurisdictions. Any use of the Service is at your own risk, and
        you must comply with all applicable laws, rules and regulations in doing
        so. We may limit the Service’s availability at any time, in whole or in
        part, to any person, geographic area or jurisdiction that we choose.
      </p>
      <div className="header2">Community Guidelines:</div>
      <p>
        You agree that you will comply with all of our Community Guidelines, as
        updated by us from time to time, located here. The Community Guidelines
        contain, among other things, our list of prohibited products and our
        rules of conduct.
      </p>
      <div className="header2">Registration; Usernames and Passwords:</div>
      <p>
        You may need to register to use all or part of the Service. We reserve
        the right to reject, or require that you change, any username, password
        or other information that you provide to us in registering. Your
        username and password are for your personal use only and should be kept
        confidential; you are responsible for any use or misuse of your username
        or password, and you must promptly notify us of any confidentiality
        breach or unauthorized use of your username or password, or your Service
        account. If you register using a third party social network account such
        as a Facebook or Amazon account, you authorize us to access, use,
        disclose and retain, in accordance with our Privacy Policy, the
        information that we receive from the social media network in connection
        with your registration.
      </p>
      <div className="header2">Product Listings:</div>
      <p>
        The Service includes a forum where users may post and browse listings
        for goods, including second-hand goods (“Products”). Such listings and
        Products are provided by users, and not by us. WE DO NOT SELL OR
        OTHERWISE MAKE AVAILABLE ANY PRODUCTS, WE DO NOT BUY OR OTHERWISE
        ACQUIRE ANY PRODUCTS, AND WE DO NOT FACILITATE THE SALE, PURCHASE OR
        TRANSFER OF ANY PRODUCTS. WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY SUCH
        LISTINGS OR PRODUCTS, FOR ANY TRANSACTIONS OR TRANSFERS INVOLVING
        PRODUCTS, INCLUDING THE SHIPPING OF ANY PRODUCTS, OR FOR ANY DISPUTE
        BETWEEN USERS RELATING TO ANY SUCH LISTINGS OR PRODUCTS. TRANSACTIONS
        AND TRANSFERS ARE AT YOUR OWN RISK. PLEASE USE CAUTION AND COMMON SENSE
        WHEN BUYING, SELLING OR OTHERWISE TRANSFERRING ANY PRODUCT, OR MAKING OR
        RECEIVING PAYMENT FOR ANY PRODUCT. Neither the availability through the
        Service of any such listing, nor any promotional services that we may
        make available to the user who posted such listing, implies our
        endorsement of the listed Product or the provider of such Product, or
        any affiliation with such provider. We make no representations as to any
        Products or listings, or any providers of Products. Such information and
        the availability of any Product are subject to change at any time
        without notice. You must ascertain and obey all applicable laws
        regarding the marketing, promotion, sale, purchase, delivery, receipt,
        possession and use of any Product. We reserve the right to remove any
        Product listing for any reason. You must ensure that you have the
        necessary authority and permissions to post any Product listing, and to
        enter into any transaction or make any transfer relating to a Product,
        including, if you are not of legal age to do so, by obtaining proper
        parental or guardian consent.
      </p>
      <div className="header2">Submissions:</div>
      <p>
        Service visitors may make available certain materials (each, a
        “Submission”) through or in connection with the Service, including
        listings for Products, and on profile pages or on the Service’s
        interactive services, such as message boards and other forums, and
        chatting, commenting and other messaging functionality. Company has no
        control over and is not responsible for any use or misuse (including any
        distribution) by any third party of Submissions. IF YOU CHOOSE TO MAKE
        ANY OF YOUR PERSONALLY IDENTIFIABLE OR OTHER INFORMATION PUBLICLY
        AVAILABLE THROUGH THE SERVICE, YOU DO SO AT YOUR OWN RISK.
      </p>
      <div className="header2">Fees:</div>
      <p>
        We will charge a seller fee for all transactions unless a promotion
        expressly waives part of all of the fees. The fee will include credit
        card transaction fees. The fee will be deducted from the total price
        of the sale of the Product. The price of the Product may be reduced at
        the seller’s discretion. Once the buyer receives the Product, Markid
        will hold the buyer’s payment for two days after the delivery, and
        remit to the seller the fee, less (i) donations (if any) and (ii)
        Markid;s fee / commission

      </p>
      <div className="header2">Delivery</div>
      <p>
        Markid will use a third-party delivery company to pick up Products
        pursuant to a transaction from seller and deliver to them the buyer.
        Prior to pick up and delivery, both seller and buyer will be given a
        4-hour window for the third-party delivery company to pick up or deliver
        Products sold. If the Product is not ready for pick up, or if the buyer
        is not available for delivery, an additional fee of $20 will be applied
        to the unavailable party.
      </p>
      <div className="header2">Returns:</div>
      <p>
        We are not responsible for any exchange of Products through local pick up. For
        Products that are delivered by us, the buyer has 2 days to make a claim
        based on Products that do not match the description and or condition. If
        this happens, the seller will be responsible for taking the Product
        back. we will reverse the payment to the buyer. The seller will be
        responsible for paying the delivery fee to us. The return process will
        be coordinated via the messaging feature in the App. For returns other
        than the instance referenced in the above paragraph, it is at the
        discretion of the seller, or in some cases Markid, to accept returns. No
        returns of any kind will be accepted after the 2 days have lapsed.
      </p>
      <div className="header2">Disputes:</div>
      <p>
        We will make every effort to resolve disputes between the buyer and the
        seller. To avoid disputes in local pick ups, we encourage the seller to take a
        snapshot of the signature of the buyer, and send it to us along with the
        name, address and phone number of the buyer. This information will
        expedite the payment process and reduce the risk of disputes. In the
        absence of this, we will be required to refund the money to the buyer
        for any qualifying dispute.
      </p>
      <div className="header2">Donations:</div>
      <p>
        While Markid is a for profit organization, we will act as an
        intermediary to pass along any donation chosen by the seller via the
        Service. Donations can only be made to a registered 501(3)(c)
        organization. We will not be responsible for providing information
        related to tax deductible donations, nor will we collect any charitable
        donation receipts or other documentation related to tax deductible
        donations. License. You retain ownership of your Submissions. For each
        Submission, you hereby grant to us a worldwide, royalty-free, fully
        paid-up, non-exclusive, perpetual, irrevocable, transferable and fully
        sublicensable (through multiple tiers) license, without additional
        consideration to you or any third party, to reproduce, distribute,
        perform and display (publicly or otherwise), create derivative works of,
        adapt, modify and otherwise use, analyze and exploit such Submission, in
        any format or media now known or hereafter developed, and for any
        purpose (including promotional purposes, such as testimonials). In
        addition, if you provide to us any ideas, proposals, suggestions or
        other materials (“Feedback”), whether related to the Service or
        otherwise, such Feedback will be deemed a Submission, and you hereby
        acknowledge and agree that such Feedback is not confidential, and that
        your provision of such Feedback is gratuitous, unsolicited and without
        restriction, and does not place Company under any fiduciary or other
        obligation. You represent and warrant that you have all rights necessary
        to grant the licenses granted in this section, and that your
        Submissions, and your provision thereof through and in connection with
        the Service, are complete and accurate, and are not fraudulent, tortious
        or otherwise in violation of any applicable law or any right of any
        third party. You further irrevocably waive any “moral rights” or other
        rights with respect to attribution of authorship or integrity of
        materials regarding each Submission that you may have under any
        applicable law under any legal theory.
      </p>
      <div className="header2">Monitoring:</div>
      <p>
        We may (but have no obligation to) monitor, evaluate, alter or remove
        Submissions before or after they appear on the Service, or analyze your
        access to or use of the Service. We may disclose information regarding
        your access to and use of the Service, and the circumstances surrounding
        such access and use, to anyone for any reason or purpose.
      </p>
      <div className="header2">Your Limited Rights:</div>
      <p>
        The App is licensed (not sold) to end users. Subject to your compliance
        with this Agreement, and solely for so long as you are permitted by
        Company to use the App, we hereby permit you, on a limited,
        non-exclusive, revocable, non-transferable, non-sublicensable basis, to
        install and use the App on a mobile device that you own or control,
        solely for your personal, non-commercial use. If you fail to comply with
        any of the terms or conditions of this Agreement, (i) you must
        immediately cease using the App, (ii) you must remove (that is,
        uninstall and delete) the App from your mobile device and (iii) you are
        prohibited from using the App on any mobile device until such time as we
        grant you explicit permission to use the App again. Please note that if
        you do not accept the system permissions that the App requires from time
        to time, you may not be able to use the App or certain of its
        functionalities. You are responsible for obtaining, maintaining and
        paying for all hardware and all telecommunications and other services
        needed to use the Service. To assist in maintaining high standards of
        service, we reserve the right to suspend all transactions from sellers
        with more than 3 returns from 3 different buyers in a 12 month period.
        In addition, subject to your compliance with this Agreement, and solely
        for so long as you are permitted by Company to use the Site, you may
        view 1 copy of any portion of the Site to which we provide you access
        under this Agreement, on any single device, solely for your personal,
        non-commercial use.
      </p>
      <div className="header2">Company’s Proprietary Rights:</div>
      <p>
        We and our suppliers own the Service, which is protected by proprietary
        rights and laws. Our trade names, trademarks and service marks include
        “Markid” and any associated logos. All trade names, trademarks, service
        marks and logos on the Service not owned by us are the property of their
        respective owners. You may not use our trade names, trademarks, service
        marks or logos in connection with any product or service that is not
        ours, or in any manner that is likely to cause confusion. Nothing
        contained within the scope of the Service should be construed as
        granting any right to use any trade names, trademarks, service marks or
        logos without the express prior written consent of the owner
      </p>
      <div className="header2">Third Party Materials; Links</div>
      <p>
        Certain Service functionality may make available access to information,
        products, services and other materials made available by third parties,
        including listings for Products and other Submissions (“Third Party
        Materials”), or allow for the routing or transmission of such Third
        Party Materials, including via links. We neither control nor endorse,
        nor are we responsible for, any Third Party Materials. Nothing in this
        Agreement shall be deemed to be a representation or warranty by Company
        with respect to any Third Party Materials. We have no obligation to
        monitor Third Party Materials, and we may block or disable access to any
        Third Party Materials through the Service at any time. In addition, the
        availability of any Third Party Materials through the Service does not
        imply our endorsement of, or our affiliation with, any provider of such
        Third Party Materials. YOUR USE OF THIRD PARTY MATERIALS IS AT YOUR OWN
        RISK AND IS SUBJECT TO ANY ADDITIONAL TERMS AND POLICIES APPLICABLE TO
        SUCH THIRD PARTY MATERIALS (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES
        OF THE PROVIDERS OF SUCH THIRD PARTY MATERIALS).
      </p>
      <div className="header2">Disclaimer of Warranties:</div>
      <p>
        TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) THE SERVICE
        AND ANY THIRD PARTY MATERIALS ARE MADE AVAILABLE TO YOU BY US ON AN “AS
        IS,” “WHERE IS” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES BY
        COMPANY OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY; AND (B)
        COMPANY DISCLAIMS ALL WARRANTIES WITH RESPECT TO THE SERVICE AND ANY
        PRODUCTS AND THIRD PARTY MATERIALS, INCLUDING THE WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND
        TITLE. ALL DISCLAIMERS OF ANY KIND (INCLUDING IN THIS SECTION AND
        ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR THE BENEFIT OF BOTH COMPANY
        AND ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, DIRECTORS,
        OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, LICENSORS,
        SUPPLIERS AND SERVICE PROVIDERS (COLLECTIVELY, THE “AFFILIATED
        ENTITIES”), AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS. While we try to
        maintain the timeliness, integrity and security of the Service, we do
        not guarantee that the Service is or will remain updated, complete,
        correct or secure, or that access to the Service will be uninterrupted.
        The Service may include inaccuracies, errors and materials that violate
        or conflict with this Agreement. Additionally, third parties may make
        unauthorized alterations to the Service. If you become aware of any such
        alteration, contact us at <a className="link" href="mailto: abc@example.com">info@markid.io</a> with a description of such
        alteration and its location on the Service.
      </p>
      <div className="header2">Limitation of Liability:</div>
      <p>
        TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) COMPANY WILL
        NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
        EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND, UNDER ANY CONTRACT, TORT
        (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY, INCLUDING
        DAMAGES FOR LOSS OF PROFITS, USE OR DATA, LOSS OF OTHER INTANGIBLES,
        LOSS OF SECURITY OF SUBMISSIONS (INCLUDING UNAUTHORIZED INTERCEPTION BY
        THIRD PARTIES OF ANY SUBMISSIONS), EVEN IF ADVISED IN ADVANCE OF THE
        POSSIBILITY OF SUCH DAMAGES OR LOSSES; (B) WITHOUT LIMITING THE
        FOREGOING, COMPANY WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND RESULTING
        FROM YOUR USE OF OR INABILITY TO USE THE SERVICE OR FROM ANY PRODUCTS OR
        TRANSACTIONS OR TRANSFERS RELATING TO PRODUCTS, OR FROM ANY THIRD PARTY
        MATERIALS, INCLUDING FROM ANY VIRUS THAT MAY BE TRANSMITTED IN
        CONNECTION THEREWITH, AND INCLUDING FROM ANY DISPUTE WITH ANY OTHER USER
        OF THE SERVICE; (C) YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION
        WITH THE SERVICE OR ANY THIRD PARTY MATERIALS IS TO STOP USING THE
        SERVICE; AND (D) THE MAXIMUM AGGREGATE LIABILITY OF COMPANY FOR ALL
        DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT
        (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE THE GREATER OF (I) THE
        TOTAL AMOUNT, IF ANY, PAID BY YOU TO COMPANY TO USE THE SERVICE, AND
        (II) ONE HUNDRED U.S. DOLLARS ($100.00). ALL LIMITATIONS OF LIABILITY OF
        ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE
        MADE FOR THE BENEFIT OF BOTH COMPANY AND THE AFFILIATED ENTITIES, AND
        THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
      </p>
      <div className="header2">Indemnity:</div>
      <p>
        To the fullest extent permitted under applicable law, you agree to
        defend, indemnify and hold harmless Company and the Affiliated Entities,
        and their respective successors and assigns, from and against all
        claims, liabilities, damages, judgments, awards, losses, costs, expenses
        and fees (including attorneys’ fees) arising out of or relating to (a)
        your use of, or activities in connection with, the Service (including
        all Submissions); (b) any Products, listings for Products, transactions
        or transfers relating to Products, or disputes with other users of the
        Service; and (c) any violation or alleged violation of this Agreement by
        you.
      </p>
      <div className="header2">Termination:</div>
      <p>
        This Agreement is effective until terminated. Company may terminate or
        suspend your use of the Service at any time and without prior notice,
        for any or no reason, including if Company believes that you have
        violated or acted inconsistently with the letter or spirit of this
        Agreement. Upon any such termination or suspension, your right to use
        the Service will immediately cease, and Company may, without liability
        to you or any third party, immediately block your associated device(s)
        from accessing or using the Service, and deactivate or delete your user
        name, password and account, and all associated materials, without any
        obligation to provide any further access to such materials. Sections 2,
        4, 9, 13 and 15–20 shall survive any expiration or termination of this
        Agreement.
      </p>
      <div className="header2">Copyright Infringement Claims:</div>
      <p>
        The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
        recourse for copyright owners who believe that material appearing on the
        Internet infringes their rights under U.S. copyright law. If you believe
        that anything on the Service infringes upon any copyright that you own
        or control, you may file a notification with Markid’s Copyright Agent as
        set forth below: By e-mail to <a className="link" href="mailto: abc@example.com">info@markid.io</a>; Please see 17 U.S.C. §
        512(c)(3) for the requirements of a proper notification. If you
        knowingly misrepresent in your notification that the material or
        activity is infringing, you may be liable for any damages, including
        costs and attorneys’ fees, incurred by Markid or the alleged infringer
        as the result of Markid’s reliance upon such misrepresentation in
        removing or disabling access to the material or activity claimed to be
        infringing.
      </p>
      <div className="header2">Governing Law; Venue.</div>
      <p>
        This Agreement and your access to and use of the Service shall be
        governed by and construed and enforced in accordance with the laws of
        the State of New York without regard to conflict of law rules or
        principles (whether of the State of New York or any other jurisdiction)
        that would cause the application of the laws of any other jurisdiction.
        Any Dispute between the parties that is not subject to arbitration or
        cannot be heard in small claims court, shall be resolved in the state or
        federal courts of the State of New York and the United States,
        respectively, sitting in New York County, New York.
      </p>
      <div className="header2">Miscellaneous:</div>
      <p>
        This Agreement does not, and shall not be construed to, create any
        partnership, joint venture, employer-employee, agency or
        franchisor-franchisee relationship between you and Company. If any
        provision of this Agreement is found to be unlawful, void or for any
        reason unenforceable, that provision will be deemed severable from this
        Agreement and will not affect the validity and enforceability of any
        remaining provision. You may not assign, transfer or sublicense any or
        all of your rights or obligations under this Agreement without our
        express prior written consent. We may assign, transfer or sublicense any
        or all of our rights or obligations under this Agreement without
        restriction. No waiver by either party of any breach or default under
        this Agreement will be deemed to be a waiver of any preceding or
        subsequent breach or default. If any provision of this Agreement is held
        to be unenforceable for any reason, such provision will be reformed only
        to the extent necessary to make it enforceable, and such decision will
        not affect the enforceability of such provision under other
        circumstances, or of the remaining provisions hereof under all
        circumstances. Any heading, caption or section title contained herein is
        for convenience only, and in no way defines or explains any section or
        provision. All terms defined in the singular shall have the same
        meanings when used in the plural, where appropriate and unless otherwise
        specified. Any use of the term “including” or variations thereof in this
        Agreement shall be construed as if followed by the phrase “without
        limitation.” This Agreement, including any terms and conditions
        incorporated herein, is the entire agreement between you and Company
        relating to the subject matter hereof, and supersedes any and all prior
        or contemporaneous written or oral agreements or understandings between
        you and Company relating to such subject matter. Notices to you
        (including notices of changes to this Agreement) may be made via posting
        to the Service or by e-mail (including in each case via links), or by
        regular mail. Without limitation, a printed version of this Agreement
        and of any notice given in electronic form shall be admissible in
        judicial or administrative proceedings based upon or relating to this
        Agreement to the same extent and subject to the same conditions as other
        business documents and records originally generated and maintained in
        printed form. Company will not be responsible for any failure to fulfill
        any obligation due to any cause beyond its control.
      </p>
      <div className="header2">Contact Us:</div>
      <p>
        If you have a question or complaint regarding the Service, please send
        an email to <a className="link" href="mailto: abc@example.com">support@markid.io.</a>
      </p>
    </div>
  );
};

export default TermsConditions;
