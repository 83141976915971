import API from '../Utils/API';

export const getRecentSearches = () => dispatch => {
  dispatch({ type: "PATCH_RECENT_SEARCHES_REQUEST" })
  API.get(`/api/v2/searchLotHistory/recentSearches`)
      .then(response => {
          dispatch({ type: "PATCH_RECENT_SEARCHES_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "PATCH_RECENT_SEARCHES_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}

export const getPopularSearches = () => dispatch => {
  dispatch({ type: "PATCH_POPULAR_SEARCHES_REQUEST" })
  API.get(`/api/v2/searchLotHistory/popularSearches`)
      .then(response => {
          dispatch({ type: "PATCH_POPULAR_SEARCHES_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "PATCH_POPULAR_SEARCHES_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}
