import React, { useEffect, useState } from "react";
import { Button} from "antd";
import bookmark1 from "../assets/new-assets/bookmark1.png";
import bookmark2 from "../assets/new-assets/bookmark2.png";
import ReadMoreVector from "../assets/new-assets/ReadMoreVector.png";
import shareicon from "../assets/new-assets/shareBlog.png";
import { useDispatch, useSelector } from "react-redux";
import {
  BlogAddBookmark,
  BlogRemoveBookmark,
} from "../Actions/BlogsAction";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShareBlogModal } from "./ShareBlogModal";
const Allblogposts = (props) => {
  const { Blogs, modifiedBlogs, Blogimages, Blogbookmark } = props;
  const { Blogtags } = useSelector((state) => {
    const { Blogtags } = state.Blogs;
    return {
      Blogtags,
    };
  });

  const [isBackgroundRed, setisBackgroundRed] = useState(false);
  const dispatch = useDispatch();
  const handleAddBookmark = () => {
    if (localStorage.getItem("access_token") != null) {
      if (!Blogbookmark?.bookmarks?.includes(Blogs?.id)) {
        setisBackgroundRed(true);
        dispatch(BlogAddBookmark(Blogs?.id));
        toast("ADDED TO FAVORITES")
      } else {
        setisBackgroundRed(false);
        dispatch(BlogRemoveBookmark(Blogs?.id));
        toast("REMOVED FROM FAVORITES")
      }
    }
  };

  const history = useHistory();
  const [color, setColor] = useState("#fff");
  useEffect(() => {
    if (Blogs?.array[0]?.name === "sleeping") {
      setColor("#FFE8B6");
    } else if (Blogs?.array[0]?.name === "Feeding") {
      setColor("#FFDBDB");
    } else if (Blogs?.array[0]?.name === "Newborns") {
      setColor("#FFE8B6");
    } else if (Blogs?.array[0]?.name === "Toddler Care") {
      setColor("#E3E6FFE5");
    } else if (Blogs?.array[0]?.name === "pregnancy and postpartum") {
      setColor("#FECCFFE5");
    } else if (Blogs?.array[0]?.name === "Sleep Time") {
      setColor("#CDF3FF");
    } else if (Blogs?.array[0]?.name === "Childcare") {
      setColor("#FFBEA3E5");
    } else if (Blogs?.array[0]?.name === "Parent Corner") {
      setColor("#D3FFCCE5");
    }
  }, [Blogs]);

  const [showBlogDialog, setshowBlogDialog] = useState(false);
  const handleOpenBlog = () => {
    setshowBlogDialog(true);
  };
  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      if (Blogbookmark?.bookmarks?.includes(Blogs?.id)) {
        setisBackgroundRed(true);
      }
    }
  }, []);

  const notify = () => {
    if (localStorage.getItem("access_token")=== null){
    toast("LOGIN TO BOOKMARK!");
    }}

  return (
    <>
      <div class="AllBlogPost">
        <img
          src={Blogimages?.source_url}
          className="AllBlogimageHeight"
          style={{ width: "100%" }}
        />
        {Blogs && Blogs?.array[0]?.name &&(<span className="BlogtagsIcon">
          {" "}
          <div className="Blogtags" style={{ background: color }}>
            <p>{Blogs?.array[0]?.name}</p>
          </div>
        </span>)}
        

        <span className="BlogshareIcon">
          <Button onClick={() => handleOpenBlog()}>
            <img className="BlogImageIcons" src={shareicon} />
          </Button>
          <Button
            onClick={() => {
              handleAddBookmark();notify()
            }}
          >
            {!isBackgroundRed ? (
              <img src={bookmark1} className="BlogImageIcons" />
            ) : (
              <img src={bookmark2} className="BlogImageIcons" />
            )}
          </Button><ToastContainer />
        </span>
        <div className="BlogInfo">
          <div class="AllBlogContainer">
            <div className="BlogTimeReading">{/* <b>x mins read</b> */}</div>
            <h2>
              <b
                className="AllBlogHeading"
                dangerouslySetInnerHTML={{ __html: Blogs?.title.rendered }}
              />
            </h2>
            <p
              className="BlogParagraph"
              dangerouslySetInnerHTML={{ __html: Blogs?.excerpt.rendered }}
            />
          </div>
          <div class="AllBlogContainer">
            <div>
              <div className="Blogdatetime">
                {moment(new Date(Blogs?.date)).format("MMMM do, yyyy")}
                <Button
                  onClick={() => {
                    history.push(`/learn/blogdetail/${Blogs?.id}`);
                  }}
                >
                  <div class="BlogReadMore">
                    Read More
                    <img src={ReadMoreVector} />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareBlogModal
        Blogsid={Blogs?.id}
        Blogs={Blogs}
        Blogimages={Blogimages}
        showBlogDialog={showBlogDialog}
        onCancelBlogDialog={() => setshowBlogDialog(false)}
      />
    </>
  );
};
export default Allblogposts;
