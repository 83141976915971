import React, { useEffect } from "react";
import { Card, Typography, Row, Col, Button, Spin } from "antd";
import {
  CloseOutlined, LoadingOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";
import { OBCommunityCard } from "../components/OBCommunityCard";
import { getAllCommunities } from "../Actions/communitiesAction";
const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#393939' }} spin />;
export const OBJoinCommunity = (props) => {
  const { current, setCurrent } = props
  const dispatch = useDispatch();
  const history = useHistory()
  const { communities, isJoinCommunityLoading } = useSelector((state) => {
    const { communities } = state.communities;
    const { isJoinCommunityLoading } = state.user;
    return {
      communities,
      isJoinCommunityLoading
    };
  });
  useEffect(() => {
    dispatch(getAllCommunities());
  }, []);
  return (
    <div>
      <Card bordered={false}>
        <div className='onboardingWeb'>
          <div className="closeicon-wrap">
            <CloseOutlined className='oBCloseIcon' />
          </div>
          <Row gutter={[0, 21]} justify='space-between'>
            <Col span={24}></Col>
            <Col>
              {/* <Link to="/homepage"> */}
              <Link to="/">
                <img alt="logo" src={Logo} className="logoStyle weblogo" />
              </Link>
            </Col>
            <Col>
              <Row gutter={[0, 12]}>
                <Col span={24}></Col>
                {/* <Button type='text' className='onboardingSigninButton' onClick={() =>(props.match.params.isCheckout)? history.push('/checkout') : history.push("./homepage")}> */}
                <Button type='text' className='skip-btn' onClick={() => (props.match.params.isCheckout) ? history.push('/cart') : history.push("./")}>
                  Skip
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='onboardingMob'>
          <div className="closeicon-wrap">
            <CloseOutlined className='oBCloseIcon' />
          </div>
          <Row gutter={[0, 0]} justify='space-between'>
            <Col span={24}></Col>
            <Col>
              {/* <Link to="/homepage"> */}
              <Link to="/">
                <img alt="logo" src={ShippingLogo} className='markidLogoMob' />
              </Link>
            </Col>
            <Col>
              <Row gutter={[30, 0]}>
                <Col>
                  {/* <Button type='text' className='onboardingSigninButton' onClick={() =>(props.match.params.isCheckout)? history.push('/checkout') : history.push("./homepage")}> */}
                  <Button type='text' className='skip-btn' onClick={() => (props.match.params.isCheckout) ? history.push('/cart') : history.push("./")}>
                    Skip
                  </Button>
                </Col>
                {/* <Col>
                  <Row gutter={[0, 10]}>
                    <Col span={24}></Col>
                    <CloseOutlined className='oBCloseIcon' />
                  </Row>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>

        <Row gutter={[0, { xs: 22.12, sm: 22.12, md: 32.50, lg: 32.50, xl: 32.50, xxl: 32.50 }]}>
          <Col span={24}></Col>
          {/* <Col span={24}></Col> */}
          <Col>
            <Typography className='createAccountText'>
              Join a community
            </Typography>
            <Typography className='heading-desc style2'>
              Meet other parents and interact on common topics, listing and more.
            </Typography>
          </Col>
        </Row>
        {/* <Row gutter={[0, { xs: 2, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }]}>
          <Col span={24}></Col>
          <Col>
            <Typography className='oBCommunitySubTitle'>
            
            </Typography>
          </Col>
        </Row> */}

        <Row justify='center'>
          <Spin indicator={antIcon} spinning={isJoinCommunityLoading}>
            <Col>
              <Row gutter={[33, { xs: 27, sm: 27, md: 37, lg: 37, xl: 37, xxl: 37 }]} justify='center'>
                <Col span={24}></Col>
                {communities && communities.map((val, i) => {
                  return (
                    <Col key={i} xxl={6} xl={8} lg={8} md={8} sm={24} xs={24}>
                      <OBCommunityCard
                        cover={
                          <Row justify='center'>
                            <img alt="" src={val.imageUrls} className='oBComImg' />
                          </Row>
                        }
                        communityName={val.title}
                        communityDes={val.description}
                        comButton={val.comButton}
                        communityData={val}
                        current={current}
                        setCurrent={setCurrent}
                      />
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Spin>
        </Row>


        <Row justify="center" gutter={[0, { xs: 10, sm: 10, md: 47.5, lg: 47.5, xl: 47.5, xxl: 47.5 }]}>
          <Col span={24}></Col>
          <Col span={24}></Col>
          {/* <Col span={24}></Col>
            <Col span={24}></Col> */}
          <Col>
            {/* <Button className="oBenabledSignupButton" onClick={() =>(props.match.params.isCheckout)? history.push('/checkout') : history.push("./homepage")}> */}
            <Button className="oBenabledSignupButton" onClick={() => (props.match.params.isCheckout) ? history.push('/cart') : history.push("./")}>
              Continue
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};