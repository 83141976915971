import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import "../App.css";
import EmptyHeart from "../assets/UnFavorite.svg";
import FullHeart from "../assets/Favorite.svg";
import {
  addToFavorites,
  getAllLots,
  removeFromFavorites,
} from "../Actions/lotsAction";
import SignIn from "../pages/SignIn";

const { Text } = Typography;
export const CategoryCard = (props) => {
  // const { setShowSignInDialog, showSignInDialog } = props;
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const dispatch = useDispatch();
  // const [Favorites, setFavorites] = useState(false);
  const history = useHistory();
  const { isAuthenticated } = useSelector((state) => {
    const { isAuthenticated } = state.auth;
    return {
      isAuthenticated,
    };
  });
  const {
    imageUrls,
    brand,
    // category,
    inSoldPending,
    inUserFavorites,
    price,
    costPrice,
    // chips,
    // shipping,
    deliveryType,
    name,
    // cover,
    // bordered,
    // sold,
    // heartIcon,
    // deletePrice,
    _id,
    rating,
    toatalReviews,
  } = props.lots;

  const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const handleAddToFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(addToFavorites({ id: _id }, successCallBack, failureCallBack));
    } else {
      setShowSignInDialog(true)
    }
  };
  const handleRemoveFromFavorite = () => {
    if (isAuthenticated === true) {
      dispatch(removeFromFavorites({ id: _id }, successCallBack, failureCallBack));
    } else {
      setShowSignInDialog(true)
    }
  };
  const successCallBack = () => {
    dispatch(getAllLots());
  };
  const failureCallBack = () => { };
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  return (
    <>
      <div className="category-card" >



        <Card

          bordered={false}

        >

          <img src={images} alt="sample" className="item-image" onClick={() => history.push(`/products/${name}/${_id}`)} />


          <div className="itemButtonSold">
            {inSoldPending ? (
              <div className="sold-button-div">
                <Button type="text" className="sold-button">
                  sold
                </Button>
              </div>
            ) :
              <div className="sold-button-div no-padding"></div>}
          </div>

          <div className="itemButtonLike">
            <div className="fev-icon-div">
              {inUserFavorites ? (
                <img
                  src={FullHeart}
                  alt=""
                  onClick={() => handleRemoveFromFavorite()}
                />
              ) : (
                <img
                  src={EmptyHeart}
                  alt=""
                  onClick={() => handleAddToFavorite()}
                />
              )}
            </div>
          </div>


          <div onClick={() => history.push(`/products/${name}/${_id}`)} className="category-details">
            <Typography
              className="brand-name"
              style={{ paddingBottom: brand ? 0 : 20 }}
            >
              {" "}
              {capitalize(name)}
            </Typography>
            <Typography className="brand-name"> {capitalize(brand)}</Typography>
            {/* <Typography className="brand-name">{category}</Typography> */}
            <Row justify="space-between" gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col>
                <Row gutter={[8, 0]}>
                  <Col>
                    <Typography className="main-price">${price / 100}</Typography>
                  </Col>
                  <Col>
                    <Text className="cut-price">${costPrice / 100}</Text>
                  </Col>
                </Row>
              </Col>
              <Col>
                {rating}
                <Row justify="end">
                  <Typography>{toatalReviews}</Typography>
                </Row>
              </Col>
            </Row>
            <Row gutter={[0, 8]} style={{ paddingTop: "10px" }}>
              <Col span={24}></Col>
              <Col span={12}>
                <Typography className="renewed-text">reNEWED</Typography>
              </Col>
              <Col span={12}>
                <Typography className="shipping">
                  {deliveryType === "both" ? (
                    <Typography>Shipping </Typography>
                  ) : (
                    capitalize(deliveryType)
                  )}
                </Typography>
              </Col>
            </Row>

          </div>

          {/* <div className="categoryFooterLeft">
            <Typography className="renewed-text">reNEWED</Typography>
          </div>
          <div className="categoryFooterRight">
            <Typography className="shipping">
              Free Shipping
            </Typography>
          </div> */}

        </Card>
      </div>
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
