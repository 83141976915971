import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { useGaEvent } from "../hooks/useGaEvent";
import { useDispatch, useSelector } from "react-redux";
//import { setBrandName } from "../Actions/brandsAction";
import { setFilterCondition } from "../Actions/brandsAction";
import debounce from "lodash/debounce";
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Alert,
  Modal,
  Menu,
  Dropdown,
  Input,
  Badge,
  AutoComplete,
  Divider,
} from "antd";
import _ from "lodash";
import ShippingLogo from "../assets/Slice.svg";
import Logo from "../assets/logo.svg";
import LocationPin from "../assets/LocationPin.svg";
import UsertIcon from "../assets/UsertIcon.svg";
import CartIcon from "../assets/new-assets/cart-icon.svg";
import MessageIcon from "../assets/MessageIcon.svg";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Icon, {
  SearchOutlined,
  DownOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import "../styles/landing.css";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import CPMenu from "../components/PMenu";

const NavBottomRow = (props) => {
  const { navlist, colorNav, currentNav, setCurrentNav } = props;

  const dispatch = useDispatch();
  const params = useParams();
  const { search: urlSearch } = params;
  const location = useLocation();
  const refreshParams = new URLSearchParams(location.search);

  const [selectedTab, setSelectedTab] = useState(null);

  const history = useHistory();

  const { gaPageView, gaEventClick } = useGaEvent();
  const switchRouteBrand = useCallback(
    (brand, id) => {
      history.push(`/search-brand/${brand}`);
      dispatch(setFilterCondition("brand"));
      setCurrentNav("brands");
      // dispatch(setBrandName(brand));

      // if (location.pathname !== "/search-new/") {
      //   history.push({
      //     pathname: `/search-new/`,
      //   });
      // }
    },
    [history]
  );

  const switchRouteCategories = useCallback(
    (category, id) => {
      history.push(`/product-listing/category/${category}/${id}`);
      document.location.reload(false);
      setCurrentNav("shop");
    },
    [history]
  );

  const changetoAnd = (data) => {
    return data.replace("and", "&");
  };

  return (
    <Row className="secondNavRow app-wrapper">
       {currentNav == "shop" && (
        <Col key={"allButton"} className="secondRowNavItems">
          <div
            className="defaultTab"
            onClick={() => {
              gaEventClick("Navigation", "shop", "shopAll");
              history.push("/search-new/?refresh=true&zipCode=10100");
            }}
          >
            <span >All</span>
          </div>
          {selectedTab === "all" && (
            <div
              className="selected"
              style={{
                marginTop: "10px",
                height: "4px",
                borderRadius: "3px",
                backgroundColor: colorNav.text,
              }}
            ></div>
          )}
        </Col>
      )}
      {currentNav == "shop" && (
        <Col key={"allButton"} className="secondRowNavItems">
          <div
            className="defaultTab"
            onClick={() => {
              dispatch(setFilterCondition("Holiday"));
              gaEventClick("Navigation", "shop", "winterwear");
              history.push("/products/winterwear");
            }}
          >
            <span style={{ color: "#058058" }}>Winter wear</span>
          </div>
          {selectedTab === "all" && (
            <div
              className="selected"
              style={{
                marginTop: "10px",
                height: "4px",
                borderRadius: "3px",
                backgroundColor: colorNav.text,
              }}
            ></div>
          )}
        </Col>
      )}
     
      {navlist &&
        navlist.map((obj) => {
          return (
            <Col
              key={obj.id}
              className="secondRowNavItems"
              onClick={() => {
                if (currentNav === "brands") {
                  gaEventClick("Navigation", "Brand", obj.value);
                  switchRouteBrand(obj.value, obj.id);
                  // history.push({
                  //   pathname: "/search-new/",
                  //   search: `?refresh=true&zipCode=10100`,
                  //   state: { brand: obj.value },
                  // });
                } else if (currentNav === "shop") {
                  gaEventClick("Navigation", "shop", obj.value);
                  switchRouteCategories(obj.value, obj.id);
                  // history.push(
                  //   `/product-listing/category/${obj.value}/${obj.id}`
                  // );
                } else {
                  if (obj.value === "Blog") history.push(`/learn/blog`);
                }

                //setSelectedTab(obj.display);
                setCurrentNav(null);
              }}
            >
              <div
                className="defaultTab"
                onMouseOver={() => {
                  setSelectedTab(obj.display);
                }}
              >
                {changetoAnd(obj.display)}
              </div>

              {obj.display === selectedTab && (
                <div
                  className="selected"
                  style={{
                    marginTop: "10px",
                    height: "4px",
                    borderRadius: "3px",
                    backgroundColor: colorNav.text,
                  }}
                ></div>
              )}
            </Col>
          );
        })}

      {currentNav !== "learn" && currentNav !== "shop" && (
        <Col key={"allButton"} className="secondRowNavItems">
          <div
            className="defaultTab"
            onClick={() => {
              history.push(`/Allbrands`);
            }}
          >
            <span>All</span>
          </div>
          {selectedTab === "all" && (
            <div
              className="selected"
              style={{
                marginTop: "10px",
                height: "4px",
                borderRadius: "3px",
                backgroundColor: colorNav.text,
              }}
            ></div>
          )}
        </Col>
      )}
    </Row>
  );
};

export default NavBottomRow;
