/*eslint-disable*/
import React, { useState, useRef , useEffect} from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";

import { Menu, Row, Col, Avatar, Button, Dropdown, Typography } from "antd";
import * as Scroll from 'react-scroll';
import {
  DownOutlined,
} from "@ant-design/icons";
import "../styles/landing.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import MenuBar from "../assets/MenuBar.svg";
import CloseIcon from "../assets/new-assets/close-icon.png";
import UsertIcon from "../assets/UsertIcon.svg";
import ShippingLogo from "../assets/Slice.svg";

import SignIn from "../pages/SignIn";
import { useBreakpoints } from './../react-responsive-hooks/index';
import ContactUs from "../pages/ContactUs";
import MainHomePage from "../pages/MainHomePage";
const PMenu = (props) => {
  const { categoryRef } = props;
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  const dispatch = useDispatch()
  const ref = useRef();
  const history = useHistory()
  const { currentUser, providerToken, currentCart, allLots, searchedLots, tabKey } = useSelector(
    (state) => {
      const { allLots, searchedLots } = state.lots;
      const { providerToken } = state.auth;
      const { currentUser } = state.user;
      const { currentCart } = state.cart;
      const { tabKey } = state.categories
      return {
        currentUser,
        providerToken,
        currentCart,
        allLots,
        searchedLots,
        tabKey
      };
    }
  );
  const { fullName, photo } = currentUser || {};
  const userImgName = fullName ? fullName.charAt(0) : null;
  const [token, setToken] = useState(providerToken);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [categoryRefn, setCategoryRefn] = useState(null);

  const [current, setCurrent] = useState(tabKey ? tabKey : 'item1');
  const handleClick = e => {
    setCurrent(e.key);
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setToken("");
    dispatch({ type: "REMOVE_TOKEN" });
    dispatch({ type: "RESET_APP" });
    // history.push("/homepage");
    history.push("/");
  };

  

  // useEffect(() => {
  //   var node = document.getElementById("Categories");
  //   var a = ReactDOM.findDOMNode(node);   
  //   setCategoryRefn(a);
    
  // }, []);

  const handleCategories = () => {
    //  if(window.location.pathname === '/homepage'){
      if(window.innerWidth <= 800){
        if (window.location.pathname === '/') {
          document.body.scrollTo({top: 700, behavior: 'smooth'});
        } else {
          history.push('/');
        }
      }
      else{
        if (window.location.pathname === '/' && categoryRef) {
          categoryRef.current.scrollIntoView();
        } else {
          history.push('/');
        }
      }
  }
  const handleProfile = (id) => {
    if(id != undefined){
      history.push(`/profile/${id}`);

      document.body.scrollTo({ top: 700, behavior: "smooth" });
    }
    else{
      history.push(`/profile`);
    }
  };
  const handleLogin = () => {
    history.push('/onboarding')
  }
  let location = useLocation().pathname
  const handleMenu = (page, key) => {
    history.push(page);
    dispatch({ type: 'GET_TAB_KEY', payload: key });
  }
  const sellerRedirectionCheck = () => {
    if (localStorage.getItem("access_token") != null) {
      history.push("/seller/addproduct")
    }
    else {
      history.push('/onboarding')
    }
  };
  return (
    <div className="m-overflow">
      <Row align="middle" justify="space-between" className="app-wrapper" gutter={[0, { lg: 8, md: 16, sm: 0, xl: 8, xxl: 8, xs: 0 }]}>

        <Col
          xl={{ span: 16, offset: 0 }}
          lg={{ span: 16, offset: 0 }}
          md={{ span: 16, offset: 0 }}
          sm={{ span: 16, offset: 0 }}
          xs={{ span: 2, offset: 0 }}
        >

          <Menu
            mode="horizontal"
            expandIcon
            onClick={handleClick}
            className="ParentMenu"
            selectedKeys={[current]}
            defaultSelectedKeys={['item1']}
            overflowedIndicator={<img alt="" src={MenuBar}  onClick={handleClick}/>}
          >
            {isXS && <Menu.Item key="empty">&nbsp;</Menu.Item>}
            {isXS && <Menu.Item key="item0" className="close">
              <img
                src={CloseIcon} onClick={() => handleMenu(location, 'item0')} />
            </Menu.Item>}

            <Menu.Item key="item1"
              className={current === " item1" ? 'selectedPMenu home-icon' : 'pMenu home-icon'}
              // onClick={() => history.push('/homepage')}
              // onClick={() => handleMenu('/homepage', 'item1')}
              onClick={() => handleMenu('/', 'item1')}>
              HOME
            </Menu.Item>

            <Menu.Item key="item2"
              className={current === "item2" ? 'selectedPMenu' : 'pMenu'}
              onClick={handleCategories}>
              CATEGORIES
            </Menu.Item>

            {/* <Menu.Item key="item3"
              className={current === "item3" ? 'selectedPMenu' : 'pMenu'}
              // onClick={() => history.push('/renewed-page')}
              onClick={() => handleMenu('/renewed-page', 'item3')}
            // onClick={() => handleMenu('/search-new/?refresh=true&renewed=true', 'item3')} 
            >
              reNEWED
            </Menu.Item> */}

            <Menu.Item key="item4"
              className={current === "item4" ? 'selectedPMenu' : 'pMenu'}
              // onClick={() => history.push('/community')}
              onClick={() => handleMenu('/community', 'item4')}>
              COMMUNITY
            </Menu.Item>

            <Menu.Item key="item5"
              className={current === "item5" ? 'selectedPMenu' : 'pMenu'}
              // onClick={() => history.push('/blog')}
              onClick={() => handleMenu('/blog', 'item5')}>
              BLOG
            </Menu.Item>

            <Menu.Item key="item6"
              className={current === "item6" ? 'selectedPMenu contact' : 'pMenu contact'}
              // onClick={() => history.push('/contact-us')}
              onClick={() => handleMenu('/contact-us', 'item6')}>
              {/* <a href={process.env.REACT_APP_STATIC_DOMAIN+'contact'} target="_blank"> */}
              CONTACT US
              {/* </a> */}
            </Menu.Item>

            {/* For Mobile only Visible */}
            {isXS &&
              <Menu.Item className="mob-signin-option">
                <Button className="button menu-items sellButton button-primary" size='large'
                  onClick={() => sellerRedirectionCheck()}
                >Sell</Button>
              </Menu.Item>}

            {isXS && !providerToken &&
              <Menu.Item>
                <Button type='text' className='menu-items signupLoginButton' onClick={() => handleLogin()}>
                  Sign up / Login
                </Button>
              </Menu.Item>}



            {/* For Mobile only Visible */}


          </Menu>

        </Col>


        {/* {current === "item6" && <ContactUs />} */}
        {/* <div className='userLoginButtonWeb'> */}
        {!isXS && !isSM && <Col
          span={4}
        // lg={{ span: 4}}
        // md={{ span: 4}}
        // sm={{ span: 4}}
        >
          {providerToken && providerToken ? (
            <Row gutter={[5, 0]} align='middle' justify="end">
              <Col>
                {photo ?
                  <Avatar
                    //  style={{ backgroundColor: "#93CFCB" }}
                    // size={30}
                    src={photo}
                  />
                  :
                  <Avatar className="text-capitalize"
                    style={{ backgroundColor: "#FD533F", fontWeight: 600 }}
                  // size={30}
                  >
                    {userImgName}
                  </Avatar>
                }
              </Col>
              <Col>
                <Dropdown
                  placement='bottomRight'
                  overlay={
                    <Menu className="myAccountMenu">
                      <Menu.Item className="user">
                        Hello <span className="text-capitalize"> {fullName}</span>
                      </Menu.Item>
                      <Menu.Item onClick={() => handleProfile(1)} >
                        View Profile
                      </Menu.Item>
                      <Menu.Item onClick={() => handleProfile(4)} className="menu-divider">
                        Favorite Items
                      </Menu.Item>
                      <Menu.Item onClick={() => handleProfile(5)}>
                        Purchases History
                      </Menu.Item>
                      <Menu.Item onClick={() => handleProfile(5)}>
                        Sales History
                      </Menu.Item>
                      {/* <Menu.Item onClick={() => handleProfile()}>
                        ReNewed
                      </Menu.Item> */}
                      <Menu.Item onClick={() => handleProfile(3)} className="menu-divider">
                        Payments
                      </Menu.Item>
                      <Menu.Item onClick={() => handleProfile(3)}>
                        Wallets
                      </Menu.Item>
                      <Menu.Item className="logout" onClick={() => handleLogout()}>
                        Log Out
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Row align='middle' gutter={[8, 0]}>
                    <Col className="flex">
                      <Typography className="userName text-capitalize">{fullName}</Typography>
                    </Col>
                    <Col >
                      <DownOutlined />
                    </Col>
                  </Row>
                </Dropdown>
              </Col>
            </Row>
          ) : (
            <Row align='start' justify="end" style={{ padding: "0px 0px 0px 0px", margin: "0px 0px 0px -10px" }}>
              {/* <Col span={7} /> */}
              <Col span={2}>
                <img
                  src={UsertIcon}
                  //   width="75%"
                  // style={{ marginRight: 19 }}
                  onClick={() => handleLogin()}
                />
              </Col>
              <Col span={15}>
                {!providerToken &&
                <Button type='text' className='signupLoginButton' onClick={() => handleLogin()}>
                  Sign up / Login
                </Button>}
              </Col>
            </Row>
            // <img
            //   onClick={() => setShowSignInDialog(true)}
            //   src={UsertIcon}
            //   width="75%"
            // />
          )}
        </Col>
        }
        {/* </div> */}
        <Col span={24}></Col>
      </Row>

      {/* <div>
{      current === "item1" && <MainHomePage />}
{      current === "item6" && <ContactUs />}
      </div> */}
    </div >
  );
};
export default PMenu;
