/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Typography, Breadcrumb, Card, Tag, Button } from "antd";
import FeedTopbar from "../components/FeedTopbar";
import ProductCart from "../components/ProductCart";
import ProductDescription from "../components/ProductDescription";
import { ShareAltOutlined, HeartOutlined } from "@ant-design/icons";
import { ReNewedWorkCard } from "../components/RenewedWork";
import { CategoryCard } from "../components/CategoryCard";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import ListThree from "../assets/list3.svg";
import PickUpImg from "../assets/pickup.svg";
import Rest from "../assets/rest.svg";
import PaidOne from "../assets/paid1.svg";
import MarkidFooter from "../components/Footer";
import { getLotById } from "../Actions/lotsAction";
import { useGaEvent } from "../hooks/useGaEvent";
import "../App.css";
import { useHistory, useParams } from "react-router";
import {ProductBreadcrumb} from "../components/ProductBreadcrumb";
const { Title } = Typography;
const ProductDetails = () => {
  const dispatch = useDispatch();
  const params = useParams()

  const { gaPageView, gaEventClick } = useGaEvent();

  useEffect(() => {
    window.scrollTo(0,0);
    const {productId} = params;
    dispatch(getLotById(productId));
  }, [params]);  

  useEffect(() => gaPageView());
  const { lot, isGetLotByIdLoading } = useSelector((state) => {
    const { lot, isGetLotByIdLoading } = state.lots;
    return {
      lot,
      isGetLotByIdLoading,
    };
  });
  // const { lots } = lot || {lots: []};
  return (
    <div>
      <FeedTopbar />

      {/* <CategoryHeader /> */}
      {/* <Divider/> */}
       <Row gutter={[0, 32]} justify="space-between">
        <Col span={24}></Col>
        <Col 
          offset={2}
          // xl={{span:11, offset: 2}} lg={{span:11, offset: 2}} md={{span:11, offset: 2}} sm={18} xs={18}
        >
         <ProductBreadcrumb 
         markid="Home"
         category="reNEWED"
        //  brand="Bloom"
        //  name="Bloom Coco Lounger"
         />
        </Col>
        {/* <Col span={3}
        // xl={7} lg={7} md={7} sm={6} xs={6}
        >
          <Button
            type="text"
            icon={<ShareAltOutlined />}
            style={{ color: "#93CFCB" }}
          >
            Share
          </Button>
        </Col> */}
      </Row>

      <Row gutter={[0, 48]} justify="center">
        <Col span={24}></Col>
        <Col xl={14} lg={14} md={15} sm={24} xs={24}>
          <Card bordered={false}>
            <ProductDescription productData={lot} productLoader={isGetLotByIdLoading} />
          </Card>
        </Col>
        <Col xl={7} lg={7} md={9} sm={24} xs={24}>
          <Card bordered={false}>
            <ProductCart productData={lot} productLoader={isGetLotByIdLoading} />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={[0, 32]} justify='space-between'>
        <Col span={24}></Col>
        <Col xl={{ span: 6, offset:2}} lg={{ span: 6, offset:2}} md={{ span: 6, offset:2}} sm={11} xs={18}>
          <Title level={3} className="renewed-work-heading">
            More From This Seller
          </Title>
        </Col>
        <Col xl={3} lg={3} md={3} sm={4} xs={6}>
          <Button type="text" className="see-all-button">
            See all
          </Button>
        </Col>
      </Row>
      <Row
        gutter={[16, 48]}
        justify="center"
        wrap={true}
      >
        <Col span={24}></Col>
        {products.map((val, i) => (
          <Col xl={5} lg={5} md={11} sm={22} xs={22} key={i}>
            <CategoryCard product={val} />
          </Col>
        ))}
        <Col span={24}></Col>
      </Row>

      <Row justify="space-between" gutter={[0, 32]}>
        <Col span={24}></Col>
        <Col xl={{ span: 6, offset:2}} lg={{ span: 6, offset:2}} md={{ span: 6, offset:2}} sm={8} xs={12}>
          <Title level={3} className="renewed-work-heading">
            Similar items
          </Title>
        </Col>
        <Col xl={3} lg={3} md={3} sm={4} xs={6}>
          <Button type="text" className="see-all-button">
            See all
          </Button>
        </Col>
      </Row>
      <Row
        // style={{ paddingBottom: 5 }}
        gutter={[16, 48]}
        justify="center"
        wrap={true}
      >
        <Col span={24}></Col>
        {products.map((val, i) => (
          <Col xl={5} lg={5} md={11} sm={22} xs={22} key={i}>
            <CategoryCard product={val} />
          </Col>
        ))}
        <Col span={24}></Col>
      </Row>

      <Row justify="space-between" gutter={[0, 32]}>
        <Col span={24}></Col>
        <Col xl={{ span: 6, offset:2}} lg={{ span: 6, offset:2}} md={{ span: 6, offset:2}} sm={11} xs={18}>
          <Title level={3} className="renewed-work-heading">
            Recommended For You
          </Title>
        </Col>
        <Col xl={3} lg={3} md={3} sm={4} xs={6}>
          <Button type="text" className="see-all-button">
            See all
          </Button>
        </Col>
      </Row>
      <Row
        // style={{ paddingBottom: 5 }}
        gutter={[16, 48]}
        justify="center"
        wrap={true}
      >
        <Col span={24}></Col>
        {products.map((val, i) => (
          <Col xl={5} lg={5} md={11} sm={22} xs={22} key={i}>
            <CategoryCard product={val} />
          </Col>
        ))}
        <Col span={24}></Col>
      </Row> */}

      {/* <Row justify="space-between" gutter={[0, 32]}>
        <Col span={24}></Col>
        <Col xl={{ span: 6, offset:2}} lg={{ span: 6, offset:2}} md={{ span: 6, offset:2}} sm={13} xs={18}>
          <Title level={3} className="renewed-work-heading">
            How does reNEWED work?
          </Title>
        </Col>
        <Col xl={3} lg={3} md={3} sm={4} xs={6}>
          <Button type="text" className="see-all-button">
            See all
          </Button>
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 48]}>
        <Col span={24}></Col>
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={ListThree} className="list-3" />}
            title="1. SUBMIT YOUR ITEM"
            description="Submit a description of your item and a member of our team will
          review and confirm your submission within one bussiness day. (We 
          only accept select items that meet our criteria for gently used,
          high-quality, high-demand kids gear)"
          />
        </Col>
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={PickUpImg} className="list-3" />}
            title="2. SCHEDULE PICK-UP"
            description="We'll handle the heavy lifting,just pick a convenient time and meet us 
          at the door. We'll pick up your item and deliver it to the buyer at no charge."
          />
        </Col>
        <Col span={24}></Col>
      </Row>

      <Row justify="center" gutter={[16, 0]}>
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={Rest} className="list-3" />}
            title="3. LET US DO THE REST"
            description="Our team of experts will professionally inspect,clean,sanitize, and 
          restore the gear to bring it to a lovingly-renewed state."
          />
        </Col>
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={PaidOne} className="list-3" />}
            title="4. GET PAID"
            description="Sit back and relax. We photograph,list,promote,and sell your item so
          that you can get the best price for it. When your item sells,we pay 
          you based on the final sale price,up to 80%"
          />
        </Col>
        <Col span={24}></Col>
      </Row>
       */}
      <MarkidFooter />
      
    </div>
  );
};
export default ProductDetails;
