import React, { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import { Helmet } from "react-helmet";
import Vivian from "../assets/new-assets/vivian.png";
import gill from "../assets/new-assets/gill.png";
import alya from "../assets/new-assets/alya.png";
import saiba from "../assets/new-assets/saiba.png";
import alyssa from "../assets/new-assets/alyssa.png";
import ushma from "../assets/new-assets/ushma.png";
import tom from "../assets/new-assets/tomdaly.png";
const KeyTeam = (props) => {
  return (
    <div class="keywrapper">
      <header>
        <h2>Key Team Members</h2>
        <Divider />
      </header>

      <section class="keycolumns">
        <div class="keycolumn">
          <img src={Vivian} className="keyimg"></img>
          <h1>Vivian Abraham</h1>
          <p>VP, Engineering</p>
        </div>
        <div class="keycolumn">
          <img src={gill} className="keyimg"></img>
          <h1>Gillian Jacome</h1>
          <p>Acquisition & Growth</p>
        </div>
        <div class="keycolumn">
          <img src={alya} className="keyimg"></img>
          <h1>Aalya Dhawan</h1>
          <p>Product & Data</p>
        </div>
      </section>
      <header>
        <h2>Notable Advisors & Investors</h2>
        <Divider />
      </header>

      <section class="keycolumns">
        <div class="keycolumn">
          <img src={saiba} className="keyimg"></img>
          <h1>Saiba Saxena</h1>
          <p>Advisor, Ops + Strategy Advisor</p>
        </div>
        <div class="keycolumn">
          <img src={alyssa} className="keyimg"></img>
          <h1>Alyssa Arnold</h1>
          <p>Advisor, Venture Advisor</p>
        </div>
        <div class="keycolumn">
          <img src={ushma} className="keyimg" style={{minHeight:"68%"}}></img>
          <h1>Ushma Pandya</h1>
          <p>Advisor, Partnerships Advisor</p>
        </div>
        <div class="keycolumn">
          <img src={tom} className="keyimg"></img>
          <h1>Tom Daly</h1>
          <p>Advisor, Mentor</p>
        </div>
      </section>
    </div>
  );
};

export default KeyTeam;
