/*eslint-disable*/
import React, { useState } from 'react';
import { Row, Col, Card, AutoComplete, Input, Typography, Button } from 'antd';
import '../App.css';
import WMImgWeb from '../assets/WMImgWeb.png';
import wmMob from '../assets/wmMob.png';
import DesignForParents from '../assets/DesignForParents.svg';
import ShopKidsImg from '../assets/ShopKidsImg.svg';
import OurTeamImg from '../assets/OurTeamImg.svg';
import PrelovedImg from '../assets/PrelovedImg.svg';
import WMBgshape from '../assets/WMBgshape.svg';
const WhyMarkid = (props) => {
  return (
    <>
      <div className="whyMarkidWeb">
        {/* <div className='wMBackgroundWeb'> */}

        <Card className="wMBgCard" bordered={false}>
          <div className="app-wrapper">
            <Row gutter={[0, 0]}>
              <Col span={24}></Col>
              <Col xxl={{ span: 16 }}>
                <Typography className="featuredSeller w title">Why markid?</Typography>
              </Col>
            </Row>

            <Row gutter={[0, { xs: 40, sm: 40, md: 80, lg: 80, xl: 80, xxl: 80 }]} className="row">
              <Col span={24}></Col>
              {/* <Col span={24}></Col> */}
              <Col lg={{ span: 15 }}>
                <Row gutter={[63, 0]}>
                  <Col>
                    <img alt="" src={DesignForParents} />
                    <Row gutter={[0, 21]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="wMTitle">Designed for parents</Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 6]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="wMDes">We make selling your kids’ preloved items easy and seamless so you can turn to what matters most - being a parent.</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <img alt="" src={ShopKidsImg} />
                    <Row gutter={[0, 21]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="wMTitle">Shop kids’ resale favorites</Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 6]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="wMDes">From Strollers to cribs, books to toys and clothing.</Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[63, 50]}>
                  <Col span={24}></Col>
                  <Col>
                    <img alt="" src={OurTeamImg} />
                    <Row gutter={[0, 21]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="wMTitle">Our team has your back</Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 6]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className="wMDes">Buy kids preloved items with confidence. Our team is here to guide you if you need help.</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <img className="worldImage" alt="" src={PrelovedImg} />
                    <Row gutter={[0, 21]}>
                      <Col span={24}></Col>
                      <Col span={15}>
                        <Typography className="wMTitle">Reshaping buy & sell kids’ preloved</Typography>
                      </Col>
                    </Row>
                    <Row gutter={[0, 6]}>
                      <Col span={24}></Col>
                      <Col span={15}>
                        <Typography className="wMDes">Join a growing community of parents,connected in their mission to pass on a greener planet to our kids.</Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col lg={{ span: 9 }}>
                <div style={{ marginTop: '11%' }}>
                  <img alt="" src={WMImgWeb} style={{ width: '100%' }} />
                </div>
              </Col>
            </Row>
          </div>

        </Card>
        <img alt="" src={WMBgshape} style={{ width: '100%', marginTop: '-15%' }} />
        {/* </div>  */}
      </div>

      <div className="whyMarkidMob">
        <div className="wMBackgroundMob">
          <Row gutter={[0, 40]} justify="center">
            <Col span={24}></Col>
            <Col>
              <Typography className="featuredSeller w title">Why markid?</Typography>
            </Col>
          </Row>
          <Row justify="center" gutter={[0, 20]}>
            <Col span={24}></Col>
            <Col span={22}>
              <img className="image" alt="" src={wmMob} style={{ width: '100%' }} />
            </Col>
          </Row>

          <Row justify="center" className="whymark-id-info">
            <Col>
              <Row justify="center" gutter={[0.2, 20]}>
                <Col span={24}></Col>
                <Col>
                  <img alt="" src={DesignForParents} />
                  <Row gutter={[0, 21]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMTitle">Designed for parents</Typography>
                    </Col>
                  </Row>
                  <Row gutter={[0, 6]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMDes">We make selling your kids’ preloved items easy and seamless so you can turn to what matters most - being a parent.</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="center" gutter={[0.17, 20]}>
                <Col span={24}></Col>
                <Col>
                  <img alt="" src={ShopKidsImg} />
                  <Row gutter={[0, 21]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMTitle">Shop kids’ resale favorites</Typography>
                    </Col>
                  </Row>
                  <Row gutter={[0, 6]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMDes">From Strollers to cribs, books to toys and clothing.</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="center" gutter={[0.17, 20]}>
                <Col span={24}></Col>
                <Col>
                  <img alt="" src={OurTeamImg} />
                  <Row gutter={[0, 21]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMTitle">Our team has your back</Typography>
                    </Col>
                  </Row>
                  <Row gutter={[0, 6]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMDes">Buy kids preloved items with confidence. Our team is here to guide you if you need help.</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="center" gutter={[0.17, 20]}>
                <Col span={24}></Col>
                <Col>
                  <img alt="" src={PrelovedImg} />
                  <Row gutter={[0, 21]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMTitle">Reshaping buy & sell kids’ preloved</Typography>
                    </Col>
                  </Row>
                  <Row gutter={[0, 6]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="wMDes">Join a growing community of parents,connected in their mission to pass on a greener planet to our kids.</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default WhyMarkid;
