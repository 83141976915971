import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useGaEvent } from "../hooks/useGaEvent";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";

import { useBreakpoints } from "./../react-responsive-hooks/index";


export const ConditionFilter = (props) => {
  const {
    selectedCondition,
    dropdownCallback,
    checkdonConditionChange,
    currentPage,
    totalcount
  } = props;
  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { isXS, isSM} = useBreakpoints();
  const [key, setkey] = useState(0);
  useEffect(() => {
    if(!(isXS || isSM)){
    if (currentPage[3] === "shoes") {
      setkey(11);
    }
    if (currentPage[3] === "gear") {
      setkey(11);
    }
    if (currentPage[3] === "electronics") {
      setkey(11);
    }
    if (currentPage[3] === "safety_equipment") {
      setkey(11);
    }
    if (currentPage[3] === "strollers") {
      setkey(11);
    }
    if (currentPage[3] === "toys_and_costumes") {
      setkey(11);
    }
    if (currentPage[3] === "cribs_and_furniture") {
      setkey(11);
    }
    if (currentPage[3] === "bathing") {
      setkey(11);
    }
    if (currentPage[3] === "sports_bicycles") {
      setkey(11);
    }
    if (currentPage[3] === "books") {
      setkey(11);
    }
    if (currentPage[3] === "diapering") {
      setkey(11);
    }}
  }, [currentPage]);
  const { gaPageView, gaEventClick } = useGaEvent();

  return (
    <>
      <Collapse
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        onChange={()=>{gaEventClick("Filter by", "Condition","open/close")}}
        bordered={false}
        defaultActiveKey={["11"]}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        <Panel
          className="collapseContent"
          style={{
            padingTop: 15,
            paddingBottom: 19,
            marginTop: 15,
            marginBottom: 19,
          }}
          header={
            selectedCondition && selectedCondition.length > 0 ? (
              <Badge color="blue">
                <Typography className="pFCollapseTitle">
                  Condition&nbsp;
                </Typography>
              </Badge>
            ) : (
              <Typography className="pFCollapseTitle">Condition</Typography>
            )
          }
          key={key}
        >
          {/* <div>* /}
                    {/* <Checkbox.Group  onChange={checkdonChange} className='collapseContent'> */}
          {totalcount&& Object.entries(totalcount.condition ||{}).map((val, i) => (
              <Row gutter={[12, 16]} key={i}>
                
                <Col>
                  <Checkbox
                    onChange={() => {checkdonConditionChange(val[0]); gaEventClick("Filter by", "Condition",val[0])}}
                    checked={selectedCondition.indexOf(val[0]) !== -1}
                  />
                </Col>
                <Col>
                  <Typography className="conditionName">
                    {(val[0]!="new") && capitalize(val[0])} {val[0] == "new" && <span>New with tag</span>} {val[1]>0 ?(<span  className="ProductCount">({val[1]})</span>):(<span>(0)</span>)}
                  </Typography>
                </Col>
              </Row>
            ))}
        </Panel>
      </Collapse>
    </>
  );
};
