import React from "react";
import { Card, Typography, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import "../App.css";
import { useBreakpoints } from './../react-responsive-hooks/index';
import ArrowRight from "../assets/ArrowRight.svg";
export const StyleCard = (props) => {
  const { isXS, isSM } = useBreakpoints();
  const history = useHistory();
  const { stylesData } = props;
  // const { cover, styleName, forwardIcon } = props
  const { imageUrls, name, _id } = stylesData;
  // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const images = imageUrls && imageUrls.length > 0 ? `https://images.markid.io/resize?width=600&height=600&quality=50&type=webp&url=${imageUrls[0]}` : null;
  return (
    <div className="sTCardParent">
      <Card
        className="sTCard"
        onClick={() => history.push(`/product-list/${_id}`)}
        cover={
          <img
            alt=""
            src={images}
            className="styleImg"
            style={{ height: isSM || isXS ? 234 : 330, borderRadius: 15 }}
          />
        }
        bordered={false}
      >
        <Row justify="space-between" align="middle" className="stylecard-info">
          <Col xs={20} sm={20} md={20} lg={23} xl={23} xxl={23}>
            <Typography className="cMName"> {name}</Typography>
          </Col>
          <Col xs={2} sm={2} md={2} lg={0} xl={0} xxl={0} />
          <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1}>
            <img alt="" src={ArrowRight} className="arrow-rt" />
          </Col>
          <Col />
        </Row>
      </Card>
    </div>
  );
};
