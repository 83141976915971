import { Button, Typography } from "antd";
import React, { useState, useEffect, useSelector } from "react";
import { useHistory } from "react-router-dom";
import { useGaEvent } from "../hooks/useGaEvent";
import { useCallback } from "react";
import Emailpopup from "./Emailpopup";
const RibbonTop = () => {
  const [close1, setClose1] = useState(
    sessionStorage.getItem("ribbon") || "true"
  );
  const history = useHistory();
  const { gaPageView, gaEventClick } = useGaEvent();
  const onClose = () => {
    gaEventClick(
      "Browse",
      "Shop from Swoondle",
      "20% off top banner close action"
    );
    sessionStorage.setItem("ribbon", false);
    setClose1("false");
  };

  const handleRedirect = () => {
    gaEventClick("Browse", "Shop from Swoondle", "20% off top banner");
      history.push("/search-new/?refresh=true&zipCode=10100");
  };

  const names = [
    "Subscribe to our newsletter",
    "20 % off | $50+ on Verified Shops*",
    "10 % off your First Purchase | SIGN UP"
  ];

  const [newName, setnewName] = useState(
    "20 % off | $50+ on Verified Shops*"
  );

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * names.length);
    setnewName(names[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  const handleRedirect2 = () => {
    gaEventClick(
      "Browse",
      "subscribe to newsletter",
      "subscribe our newsletter"
    );
    sessionStorage.setItem("emailpop", "true");
    setClose("true");
  };
  const [close, setClose] = useState(
    sessionStorage.getItem("emailpop") || "true"
  );
  return (
    <>
      {close === "true" && <Emailpopup close={close} setClose={setClose} />}
      {close1 == "true" && (
        <div className="ribbonTop">
          <ul>
            <li>
              <span
                onClick={() => {
                  (newName == "Subscribe to our newsletter")||(newName == "10 % off your First Purchase | SIGN UP")
                    ? handleRedirect2()
                    : handleRedirect();
                }}
              >
                {newName}
              </span>
              <span class="close" onClick={onClose}>
                x
              </span>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
export default RibbonTop;
