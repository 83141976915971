import API from "../Utils/API";

export const getPartnerProductsShippingPrice = (data) => (dispatch) => {
    dispatch({ type: "PARTNER_PRODUCT_SHIIPING_CHARGE_REQUEST" });
    API.post(`api/v5/markid-orders/shipmentCost`, data)
      .then((response) => {
        dispatch({ type: "PARTNER_PRODUCT_SHIIPING_CHARGE_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "PARTNER_PRODUCT_SHIIPING_CHARGE_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };