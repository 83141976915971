import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Button, Alert, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useBreakpoints } from './../../react-responsive-hooks/index';
import { useForm, Controller } from "react-hook-form";
import MSImage from "../../assets/new-assets/sell-comingsoon-bg.png";
import ReactStoreBadges from 'react-store-badges'
import "./MarkidSell.css"
import { Helmet } from "react-helmet";

const MarkidSell = () => {
  const { isXS, isSM } = useBreakpoints();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
    },
  });
  return (
    <>
      <Helmet>
        <title>Markid - Sell</title>
      </Helmet>
      <Card bordered={false} className="mBCardBg">
        <Row justify='center' gutter={[0, { xs: 15, sm: 15, md: 37, lg: 37, xl: 37, xxl: 37 }]}>
          <Col span={24}></Col>
          <Col xxl={24} xl={24} sm={24} xs={24}>
            <div className="app-wrapper">
              <Card bordered={false} className='mBCard'>
                <Row justify='center' >
                  <div className='mBImgBg'>
                    <Row justify='center' >
                      <img alt="" src={MSImage} className='mBImage' />
                    </Row>
                  </div>
                  {/* <img alt="" src={MarkidCommunityImg} /> */}
                </Row>
                <Row justify='center' gutter={[0, 0]}>
                  <Col span={24}></Col>
                  <Col sm={15} xs={15}>
                    <Typography className='mBTitle'>
                      Coming soon
                    </Typography>
                  </Col>
                </Row>
                <Row justify='center' gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col xl={12} lg={13} md={14} sm={18} xs={18}>
                    <Typography className='mBDes'>
                      Get the App to start your listing
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[21, 62.29]} justify='center'>
                  <Col span={24}></Col>
                  {/* <Col>
              <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                />
              </Col> */}
                  <Col>
                    <ReactStoreBadges
                      platform={'ios'}
                      url={'https://apps.apple.com/us/app/markid/id1436309782'}
                      target="_"
                      locale={'en-us'}
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default MarkidSell;
