import { useState } from "react";

import { Button } from "antd";

import { SellerConfirmation } from "../components/SellerConfirmation";
import { SelectBrand } from "../components/SelectBrand";
import SelectColourSeller from "../components/SelectColourSeller";
export const ModalsPage = () => {
  const [showConfirmDialog, setshowConfirmDialog] = useState(false);
  const [showBrandDialog, setshowBrandDialog] = useState(false);
  const [showColorDialog, setshowColorDialog] = useState(false);

  const handleOpenConfirmation = () => {
    setshowConfirmDialog(true);
  };
  const handleOpenBrand = () => {
    setshowBrandDialog(true);
  };
  
const handleOpenColor=()=>
{
  setshowColorDialog(true)
}
  return (
    <>
      <Button
      
        onClick={() => handleOpenConfirmation()}
        className={"button button-primary  pLCartButton"}
      >
        confirm
      </Button>
      <Button
       
        onClick={() => handleOpenBrand()}
        className={"button button-primary  pLCartButton"}
      >
        Brand
      </Button>
      <div>
      <Button
      
        onClick={() => handleOpenColor()}
        className={"button button-primary  pLCartButton"}
      >
        color
      </Button>
      </div>
      <SellerConfirmation showConfirmDialog={showConfirmDialog}onCancelConfirmDialog={() => setshowConfirmDialog(false)} />
      <SelectBrand showBrandDialog={showBrandDialog}onCancelBrandDialog={() => setshowBrandDialog(false)} />
      <SelectColourSeller showColorDialog={showColorDialog}  onCancelColorDialog={() => setshowColorDialog(false)}/>
    </>
  );
};
