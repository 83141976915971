import constants from '../Utils/constants';
const initialState = {
    isestimatedShippingChargesLoading: false,rates:[], parcelID: ''
}

const shippingReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.POST_ESTIMATED_CHARGES_REQUEST:
            return {
                ...state, isestimatedShippingChargesLoading: true
            }
        case constants.POST_ESTIMATED_CHARGES_SUCCESS:
            return {
                ...state, isestimatedShippingChargesLoading: false, rates:action.payload.data.rates, parcelID:action.payload.data.parcelId
            }
        case constants.POST_ESTIMATED_CHARGES_FAILURE:
            return {
                ...state, isestimatedShippingChargesLoading: false
            }
        default:
            return state
    }
}
export default shippingReducer;