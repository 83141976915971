import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  Typography,
  Row,
  Col,
  Button,
  Spin,
  Input,
  Divider,
  Modal,
  Radio,
} from "antd";
import { addBrands } from "../Actions/userAction";
import { getAllBrands } from "../Actions/brandsAction";
import "../App.css";

export const SelectBrand = (props) => {
  const {
    showBrandDialog,
    onCancelBrandDialog,
    selectedBrand,
    setbrandValidation,
    setSelectedBrand,
    setSelectedBrandImage,
    selectedBrandImage,
  } = props;

  const handleCancel = () => {
    onCancelBrandDialog();
  };

  const dispatch = useDispatch();
  const { allBrands } = useSelector((state) => {
    const { allBrands } = state.brands;
    return {
      allBrands,
    };
  });

 
  const [activeBrand, setActiveBrand] = useState("");
  useEffect(() => {
    if (allBrands && allBrands.length == 0) {
      dispatch(getAllBrands());
    }
  }, [allBrands]);
  const [query, setQuery] = useState("");

  return (
    <>
      <Modal
        footer={null}
        width={442}
        visible={showBrandDialog}
        onCancel={() => handleCancel()}
      >
        <form>
          <div>
            <div>
              <Row
                gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
                justify="center"
                style={{ width: "100%" }}
              >
                <Col>
                  <Typography className="selectcolourcontainer">
                    Select the Brand
                  </Typography>
                </Col>
              </Row>

              <Row
                gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
                justify="center"
                style={{ marginTop: "12px" }}
              >
                <Col className="searchOption">
                  <Input
                    className="searchBrand searchInput"
                    placeholder="Search for Brands"
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </Col>
              </Row>

              <div className="brandWrapper" class="scroll">
                <Row
                  style={{
                    marginTop: "12px",
                  }}
                >
                  {allBrands &&
                    allBrands
                      .filter((val) => {
                        if (query == "") {
                          return val;
                        } else if (
                          val.brand.toLowerCase().includes(query.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((val, i) => (
                        <>
                          <Row gutter={[12, 16]} className="brandalign" key={i}>
                            <Col>
                           
                              <Radio
                                
                                onClick={() => {setSelectedBrand(val.brand)}}
                                value={val.brand}
                                checked={selectedBrand === val.brand}
                              >
                                {val.brand}
                              </Radio>
                            
                            </Col>
                          </Row>
                          <Divider />
                        </>
                      ))}
                  {query != allBrands.brand && (
                    <>
                      <Row>
                        <Col>
                          <Radio onClick={() => setSelectedBrand(query)} value={query} checked={selectedBrand === query}/>
                        </Col>
                        <Col>
                          <Typography className="brandName">{query}</Typography>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  )}
                </Row>
              </div>

              <Row
                gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
                justify="center"
              >
                <Button
                  className={"button button-primary  pLCartButton"}
                  onClick={() => {
                    onCancelBrandDialog();
                    setActiveBrand(selectedBrand);
                    setbrandValidation("")
                    setSelectedBrandImage({});
                    localStorage.setItem(
                      "activeBrand",(selectedBrand)
                    );
                  }}
                >
                  select
                </Button>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
