import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import plusicon from "../assets/plusign.jpg";
import { useGaEvent } from "../hooks/useGaEvent";
import minussign from "../assets/new-assets/minussign.jpg";

import { useBreakpoints } from "./../react-responsive-hooks/index";

export const GenderFilter = (props) => {
  const {
    selectedGender,
    dropdownCallback,
    checkdonGenderChange,
    totalcount,
    currentPage,
  } = props;
  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const [key, setkey] = useState();
  const { isXS, isSM } = useBreakpoints();
  useEffect(() => {
    if (!(isXS || isSM)) {
      if (currentPage[3] === "clothing_and_accessories") {
        setkey(2);
      }
      if (currentPage[3] === "shoes") {
        setkey(2);
      }
    }
  }, [currentPage]);
  const { gaPageView, gaEventClick } = useGaEvent();
  return (
    <>
      <Collapse
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        defaultActiveKey={["2"]}
        onChange={()=>{gaEventClick("Filter by", "Gender","open/close")}}
        bordered={false}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        <Panel
          className="collapseContent"
          style={{
            padingTop: 15,
            paddingBottom: 19,
            marginTop: 15,
            marginBottom: 19,
          }}
          header={
            selectedGender && Object.keys(selectedGender).length > 0 ? (
              <Badge color="blue">
                <Typography className="pFCollapseTitle">
                  Gender&nbsp;
                </Typography>
              </Badge>
            ) : (
              <Typography className="pFCollapseTitle">Gender</Typography>
            )
          }
          key={key}
        >
          {totalcount &&
            Object.entries(totalcount.gender || {}).map((val, i) => (
              <Row gutter={[12, 16]} key={i}>
                <Col>
                  <Checkbox
                    onChange={() => {checkdonGenderChange(val[0]);gaEventClick("Filter by", "Gender",val[0])}}
                    checked={selectedGender.indexOf(val[0]) !== -1}
                  />
                </Col>
                <Col>
                  <Typography className="conditionName">
                    {val[0] == "unisex" && <span>Unisex</span>}
                    {val[0] == "neutral" && <span>Neutral</span>}
                    {val[0] == "male" && <span>Boy</span>}
                    {val[0] == "female" && <span>Girl</span>}
                    {val[1] > 0 && (val[0] == "unisex"||val[0] == "neutral"||val[0] == "male"||val[0] == "female")? (
                      <span className="ProductCount">({val[1]})</span>
                    ) : (
                      <span>(0)</span>
                    )}
                  </Typography>
                </Col>
              </Row>
            ))}
        </Panel>
      </Collapse>
    </>
  );
};
