import constants from "../Utils/constants";
const initialState = {
  currentUserOrders: [], orderDetails: {}, partnerOrderDetails: {},
  isGetUserOrdersLoading: false, isPostOrderLoading: false, isPostPartnerOrderLoading: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USER_ORDERS_REQUEST:
      return {
        ...state, isGetUserOrdersLoading: true
      }
    case constants.GET_USER_ORDERS_SUCCESS:
      return {
        ...state, isGetUserOrdersLoading: false, currentUserOrders: action.payload.orders
      }
    case constants.GET_USER_ORDERS_FAILURE:
      return {
        ...state, isGetUserOrdersLoading: false
      }

    case constants.POST_ORDER_REQUEST:
      return {
        ...state,
        isPostOrderLoading: true,
      };
    case constants.POST_ORDER_SUCCESS:
      let orderDetails = action.payload.order
      orderDetails.lots = action.payload.lots
      orderDetails.actualAddress = action.payload.actualAddress
      orderDetails.offerId = action.payload.offerId
      return {
        ...state,
        isPostOrderLoading: false, orderDetails
      };
    case constants.POST_ORDER_FAILURE:
      return {
        ...state,
        isPostOrderLoading: false,
      };

    case constants.CREATE_PARTNER_ORDER_REQUEST:
      return {
        ...state,
        isPostPartnerOrderLoading: true,
      };

    case constants.CREATE_PARTNER_ORDER_SUCCESS:
      let partnerOrderDetails = action.payload
      return {
        ...state,
        isPostPartnerOrderLoading: false, partnerOrderDetails
      };
    case constants.CREATE_PARTNER_ORDER_FAILURE:
      return {
        ...state,
        isPostPartnerOrderLoading: false,
      };
      case constants.RESET_ORDER_STATUS: 
        return {
          ...initialState, orderDetails: [], partnerOrderDetails: [],
        };

    case "RESET_APP":
      return { ...initialState };
    default:
      return state;
  }
};
export default orderReducer;
