import constants from '../Utils/constants';
const initialState = {
    isGetCategoriesGroupLoading: false, isGetfeaturedSellersGroupLoading: false, isGetCuratedesGroupLoading: false,
    isGetExploresGroupLoading: false, isGetBrandsGroupLoading: false, isGetStylesGroupLoading: false, isGetCollectionsGroupLoading: false,
    isGetCommunitiesGroupLoading: false, isGetTestimonialsGroupLoading: false,
    categoriesGroup: {group: {}, groupType: []}, featuredSellersGroup: {group: {}, groupType: []}, curatedesGroup: {group: {}, groupType: []}, exploresGroup: {group: {}, groupType: []}, brandsGroup: {group: {}, groupType: []}, stylesGroup: {group: {}, groupType: []},
    collectionsGroup: {group: {}, groupType: []}, communitiesGroup: {group: {}, groupType: []}, testimonialsGroup: {group: {}, groupType: []}
}

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_CATEGORIES_GROUP_REQUEST:
            return {
                ...state, isGetCategoriesGroupLoading: true
            }
        case constants.GET_CATEGORIES_GROUP_SUCCESS:
            const newPayload = action.payload;
            const order = newPayload.group.groupItem
            const newGroupType = order.map(i => action.payload.groupType.find(j => j._id === i))
            newPayload.groupType = newGroupType
            return {
                ...state, isGetCategoriesGroupLoading: false, categoriesGroup: newPayload
            }
        case constants.GET_CATEGORIES_GROUP_FAILURE:
            return {
                ...state, isGetCategoriesGroupLoading: false
            }

        case constants.GET_FEATURED_SELLER_GROUP_REQUEST:
            return {
                ...state, isGetfeaturedSellersGroupLoading: true
            }
        case constants.GET_FEATURED_SELLER_GROUP_SUCCESS:
            const newFSPayload = action.payload;
            const fSOrder = newFSPayload.group.groupItem
            const newFSGroupType = fSOrder.map(i => action.payload.groupType.find(j => j._id === i))
            newFSPayload.groupType = newFSGroupType
            return {
                ...state, isGetfeaturedSellersGroupLoading: false, featuredSellersGroup: newFSPayload
            }
        case constants.GET_FEATURED_SELLER_GROUP_FAILURE:
            return {
                ...state, isGetfeaturedSellersGroupLoading: false
            }

        case constants.GET_CURATED_BY_MARKID_GROUP_REQUEST:
            return {
                ...state, isGetCuratedesGroupLoading: true
            }
        case constants.GET_CURATED_BY_MARKID_GROUP_SUCCESS:
            return {
                ...state, isGetCuratedesGroupLoading: false, curatedesGroup: action.payload
            }
        case constants.GET_CURATED_BY_MARKID_GROUP_FAILURE:
            return {
                ...state, isGetCuratedesGroupLoading: false
            }

        case constants.GET_EXPLORE_TOP_DEALS_GROUP_REQUEST:
            return {
                ...state, isGetExploresGroupLoading: true
            }
        case constants.GET_EXPLORE_TOP_DEALS_GROUP_SUCCESS:
            return {
                ...state, isGetExploresGroupLoading: false, exploresGroup: action.payload
            }
        case constants.GET_EXPLORE_TOP_DEALS_GROUP_FAILURE:
            return {
                ...state, isGetExploresGroupLoading: false
            }

        case constants.GET_BRANDS_GROUP_REQUEST:
            return {
                ...state, isGetBrandsGroupLoading: true
            }
        case constants.GET_BRANDS_GROUP_SUCCESS:
            return {
                ...state, isGetBrandsGroupLoading: false, brandsGroup: action.payload
            }
        case constants.GET_BRANDS_GROUP_FAILURE:
            return {
                ...state, isGetBrandsGroupLoading: false
            }

        case constants.GET_STYLES_GROUP_REQUEST:
            return {
                ...state, isGetStylesGroupLoading: true
            }
        case constants.GET_STYLES_GROUP_SUCCESS:
            return {
                ...state, isGetStylesGroupLoading: false, stylesGroup: action.payload
            }
        case constants.GET_STYLES_GROUP_FAILURE:
            return {
                ...state, isGetStylesGroupLoading: false
            }

        case constants.GET_COLLECTIONS_GROUP_REQUEST:
            return {
                ...state, isGetCollectionsGroupLoading: true
            }
        case constants.GET_COLLECTIONS_GROUP_SUCCESS:
            return {
                ...state, isGetCollectionsGroupLoading: false, collectionsGroup: action.payload
            }
        case constants.GET_COLLECTIONS_GROUP_FAILURE:
            return {
                ...state, isGetCollectionsGroupLoading: false
            }

        case constants.GET_COMMUNITY_GROUP_REQUEST:
            return {
                ...state, isGetCommunitiesGroupLoading: true
            }
        case constants.GET_COMMUNITY_GROUP_SUCCESS:
            return {
                ...state, isGetCommunitiesGroupLoading: false, communitiesGroup: action.payload
            }
        case constants.GET_COMMUNITY_GROUP_FAILURE:
            return {
                ...state, isGetCommunitiesGroupLoading: false
            }

        case constants.GET_TESTIMONIALS_GROUP_REQUEST:
            return {
                ...state, isGetTestimonialsGroupLoading: true
            }
        case constants.GET_TESTIMONIALS_GROUP_SUCCESS:
            return {
                ...state, isGetTestimonialsGroupLoading: false, testimonialsGroup: action.payload
            }
        case constants.GET_TESTIMONIALS_GROUP_FAILURE:
            return {
                ...state, isGetTestimonialsGroupLoading: false
            }
        default:
            return state
    }
}
export default groupsReducer;