import React, { useEffect, useState, useRef } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGaEvent } from "../hooks/useGaEvent";
import { setLoginSignup } from "../Actions/authAction";
import { setFilterCondition } from "../Actions/brandsAction";
//import { setBrandName } from "../Actions/brandsAction";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Alert,
  Modal,
  Dropdown,
  Input,
  Badge,
  AutoComplete,
  Divider,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";

const NavMenuMob = (props) => {
  const {
    categoriesGroup,
    brandsGroup,
    photo,
    collapsed,
    userImgName,
    fullName,
    setCollapsed,
    providerToken,
    setCurrentNav,
    toggleCollapsed,
  } = props;
  const { Text } = Typography;
  const location = useLocation();
  const history = useHistory();
  const { gaPageView, gaEventClick } = useGaEvent();
  const switchRoute = useCallback(
    (brand, id) => {
      history.push(`/product-listing/brand/${brand}/${id}`);

      window.location.reload(false);
      // dispatch(setBrandName(brand));

      // if (location.pathname !== "/search-new/") {
      //   history.push({
      //     pathname: `/search-new/`,
      //   });
      // }
    },
    [history]
  );

  const switchRouteCategories = useCallback(
    (category, id) => {
      history.push(`/product-listing/category/${category}/${id}`);
      window.location.reload(false);
    },
    [history]
  );

  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem("access_token");

    dispatch({ type: "REMOVE_TOKEN" });
    dispatch({ type: "RESET_APP" });
    // history.push("/homepage");
    history.push("/");
  };
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleCollapsed && toggleCollapsed();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [toggleCollapsed]);

  return (
    <>
      {/* <div
     
        style={{
          position: "absolute",
          zIndex: "3",
          height: "100vh",
          width: "100vw",
          opacity: "0.2",
          backgroundColor: "grey",
          filter: "grayscale(50%)",
        }}
        onClick={() => {
          setCollapsed(false);
        }}
      ></div> */}

      <div
        ref={ref}
        style={{ height: "70vh", position: "absolute", zIndex: "6" }}
      >
        <ProSidebar
          className="focusclass"
          style={{
            // height: sessionStorage.getItem("ribbon")==="false"?"calc(100vh - 53px)":"calc(100vh - 130px)",

            backgroundColor: "#fff",
            overflow: "auto",
          }}
        >
          {providerToken && (
            <SidebarHeader key={"sidebarHeader"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/profile/1`);
                }}
              >
                {photo ? (
                  <Avatar
                    //  style={{ backgroundColor: "#93CFCB" }}
                    // size={30}
                    src={photo}
                  />
                ) : (
                  <Avatar
                    className="text-capitalize"
                    style={{
                      backgroundColor: "#FD533F",
                      fontWeight: 600,
                    }}
                    // size={30}
                  >
                    {userImgName}
                  </Avatar>
                )}{" "}
                &nbsp;
                <Text className="userName text-capitalize">{fullName}</Text>
              </div>
            </SidebarHeader>
          )}
          <SidebarContent key={"sidebarContent"}>
            <Menu iconShape="square">
              <SubMenu title="Categories" onClick={() => setCurrentNav("shop")}>
                <MenuItem
                  key={"ShopAll"}
                  onClick={() => {
                    setCollapsed(false);
                    dispatch(setFilterCondition("Christmas"));
                    gaEventClick("Navigation", "shop", "shopAll");
                    history.push("/search-new/?refresh=true&zipCode=10100");
                  }}
                >
                  <span> All</span>
                </MenuItem>
                <Divider style={{ margin: "1px" }} />
                <MenuItem
                  key={"Holiday"}
                  onClick={() => {
                    setCollapsed(false);
                    dispatch(setFilterCondition("Holiday"));
                    gaEventClick("Navigation", "shop", "winterwear");
                    history.push("/products/winterwear");
                  }}
                >
                  <span style={{ color: "#058058" }}>Winter wear</span>
                </MenuItem>
                <Divider style={{ margin: "0px" }} />
                {categoriesGroup.groupType?.map((obj) => {
                  return (
                    <>
                      <MenuItem
                        key={obj._id + "_lmao"}
                        onClick={() => {
                          setCollapsed(false);
                          switchRouteCategories(obj.category, obj._id);
                        }}
                      >
                        {obj.displayValue.replace("and", "&")}
                      </MenuItem>

                      <Divider style={{ margin: "0px" }} />
                    </>
                  );
                })}

                {/* <MenuItem
                key={"allcategory"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/search-new/?refresh=true&zipCode=10100`);
                }}
              >
                All
              </MenuItem> */}
              </SubMenu>

              <SubMenu
                title="Brands"
                key={"brandsSubmenu"}
                onClick={() => setCurrentNav("brands")}
              >
                {brandsGroup.groupType?.map((obj) => {
                  return (
                    <>
                      <MenuItem
                        onClick={() => {
                          setCollapsed(false);
                          switchRoute(obj.brand, obj._id);
                        }}
                        key={obj._id}
                      >
                        {obj.brand}
                      </MenuItem>
                      <Divider style={{ margin: "0px" }} />
                    </>
                  );
                })}

                <MenuItem
                  key={"allbrands"}
                  onClick={() => {
                    setCollapsed(false);

                    // switchRoute("");

                    history.push(`/Allbrands`);
                  }}
                >
                  All
                </MenuItem>
              </SubMenu>
              <MenuItem
                key={"blog"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/learn/blog`);
                  sessionStorage.setItem("emailpop",true)
                }}
              >
                Blog
              </MenuItem>
              <MenuItem
                key={"sell"}
                onClick={() => {
                  setCollapsed(false);
                  if (providerToken) {
                    history.push("/seller/addproduct");
                    setCurrentNav("sell");
                  } else {
                    history.push("/onboarding");
                  }
                }}
              >
                Sell
              </MenuItem>
              {providerToken && (
                <MenuItem
                  key={"chat"}
                  onClick={() => {
                    setCollapsed(false);
                    history.push(`/chat`);
                  }}
                >
                  Messages
                </MenuItem>
              )}
              <Divider />
              <MenuItem
                key={"faq"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/general-questions`);
                }}
              >
                FAQ
              </MenuItem>
              <MenuItem
                key={"aboutus"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/about-us`);
                }}
              >
                About Us
              </MenuItem>
              <MenuItem
                key={"contactus"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/contact-us`);
                }}
              >
                Contact Us
              </MenuItem>
              <Divider />
              <MenuItem
                key={"privacypolicy"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/privacy-policy`);
                }}
              >
                Privacy Policy
              </MenuItem>
              <MenuItem
                key={"investors"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/about-us`);
                }}
              >
                Investors
              </MenuItem>
              <MenuItem
                key={"termsandconditions"}
                onClick={() => {
                  setCollapsed(false);
                  history.push(`/terms-and-conditions`);
                }}
              >
                Terms and Conditions
              </MenuItem>
            </Menu>
          </SidebarContent>

          <SidebarFooter
            key={"sidebarFooter"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              backgroundColor: "white",
            }}
          >
            {!providerToken ? (
              <>
                <Button
                  className="button button-primary ant-btn-block"
                  size="large"
                  style={{ margin: "auto 10px" }}
                  onClick={() => {
                    dispatch(setLoginSignup("signup"));
                    history.push("/onboarding");
                  }}
                >
                  SignUp
                </Button>
                <br />
                <Button
                  className="button button-primary-extended ant-btn-block"
                  size="large"
                  style={{
                    margin: "auto 10px",
                    backgroundColor: "#fff !important",
                    color: "#FD533F !important",
                    border: "2px solid #FD533F !important",
                  }}
                  onClick={() => {
                    dispatch(setLoginSignup("login"));
                    history.push("/onboarding");
                  }}
                >
                  Login
                </Button>
              </>
            ) : (
              <Button
                className="button button-primary-extended ant-btn-block"
                size="large"
                style={{
                  margin: "auto 10px",
                  backgroundColor: "#fff !important",
                  color: "#FD533F !important",
                  border: "2px solid #FD533F !important",
                }}
                onClick={() => {
                  handleLogout();
                }}
              >
                Logout
              </Button>
            )}
          </SidebarFooter>
        </ProSidebar>{" "}
      </div>
    </>
  );
};

export default NavMenuMob;

// background-color: #fff!important;
//     color: #FD533F !important;
//     border: 2px solid #FD533F !important;
