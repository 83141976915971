import API from "../Utils/API";

export const getPartnerDetails = (Partner_Id) => (dispatch) => {
    dispatch({ type: "GET_PARTNER_BY_ID_REQUEST" });
    API.get(`/api/v5/partner-details/${Partner_Id}`)
      .then((response) => {
        dispatch({ type: "GET_PARTNER_BY_ID_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_PARTNER_BY_ID_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };