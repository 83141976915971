import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import plusicon from "../assets/plusign.jpg";
import { useGaEvent } from "../hooks/useGaEvent";
import minussign from "../assets/new-assets/minussign.jpg";
import { useBreakpoints } from "./../react-responsive-hooks/index";

export const YearOfPurchaseFilter = (props) => {
  const {
    selectedPurchaseYear,
    dropdownCallback,
    checkdonPurchaseYearChange,
    years,
    currentPage
  } = props;
  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const [key, setkey] = useState();
  const { isXS, isSM } = useBreakpoints();
    useEffect(() => {
      if (!(isXS || isSM)) {
        if (currentPage[3] === "strollers") {
          setkey(2);
        }
        if (currentPage[3] === "electronics") {
          setkey(2);
        }
      }
    }, [currentPage]);
    const { gaPageView, gaEventClick } = useGaEvent();
  return (
    <>
      <Collapse
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        defaultActiveKey={["2"]}
        onChange={()=>{gaEventClick("Filter by", "year of purchase","open/close")}}
        bordered={false}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
      <Panel
          className="collapseContent"
          style={{
            padingTop: 15,
            paddingBottom: 19,
            marginTop: 15,
            marginBottom: 19,
          }}
          header={
            selectedPurchaseYear && Object.keys(selectedPurchaseYear).length > 0 ? (
              <Badge color="blue">
                <Typography className="pFCollapseTitle">
                  Year of purchase&nbsp;
                </Typography>
              </Badge>
            ) : (
              <Typography className="pFCollapseTitle">Year of purchase</Typography>
            )
          }
          key={key}
        >
          {years &&
            years.map((val, i) => (
              <Row gutter={[12, 16]} key={i}>
                <Col>
                  <Checkbox
                    onChange={() => {checkdonPurchaseYearChange(val.year);gaEventClick("Filter by", "Year of Purchase",val.year)}}
                    checked={selectedPurchaseYear.indexOf(val.year)!== -1}
                  />
                </Col>
                <Col>
                  <Typography className="conditionName">
                    {capitalize(val.year)}
                  </Typography>
                </Col>
              </Row>
            ))}
        </Panel>
      </Collapse>
    </>
  );
};
