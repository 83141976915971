import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useGaEvent } from "../hooks/useGaEvent";
import { Scrollbars } from "react-custom-scrollbars";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { object } from "yup";
import { useSelector } from "react-redux";

export const Subsizefilter = (props) => {
  const {
    dropdownCallback,
    checkdonSizeChange,
    Sizes,
    itemType,
    selectedCategory,
    filterToDisplay,
    totalcount,
    allsubsizes,
    currentPage,
    selectedSize,
    allsizelist,
  } = props;

  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { gaPageView, gaEventClick } = useGaEvent();
  const [key, setkey] = useState();
  const { isXS, isSM } = useBreakpoints();

  useEffect(() => {}, []);
  return (
    <>
      {itemType == "category" || selectedCategory != "" ? (
        <Collapse
          className="pFCollaps"
          // defaultActiveKey={currentOpenPanelKey}
          expandIconPosition="right"
          onChange={() => {
            dropdownCallback();
            gaEventClick("Filter by", "Size", "open/close");
          }}
          bordered={false}
          defaultActiveKey={["7"]}
          expandIcon={({ isActive }) => {
            if (!isXS) {
              return !isActive ? (
                <DownOutlined className="collapsIcon" />
              ) : (
                <UpOutlined className="collapsIcon" />
              );
            } else {
              return !isActive ? (
                <img src={plusicon} className="collapsIcon" />
              ) : (
                <img src={minussign} className="collapsIcon" />
              );
            }
          }}
        >
          {allsubsizes && (
            <Panel
              className="collapseContent"
              style={{
                padingTop: 15,
                paddingBottom: 19,
                marginTop: 15,
                marginBottom: 19,
              }}
              header={
                selectedSize && Object.keys(selectedSize).length > 0 ? (
                  <Badge color="blue">
                    <Typography className="pFCollapseTitle">
                      Size&nbsp;
                    </Typography>
                  </Badge>
                ) : (
                  <>
                    <Typography className="pFCollapseTitle">Size</Typography>
                  </>
                )
              }
              key={key}
            >
              {/* <Scrollbars style={{ height: 300 }}> */}
              <Row className={"scroll-container"} gutter={[0, 0]}>
                <Col span={22}>
                  {allsubsizes &&
                    Object.entries(allsubsizes || {}).map((val, i) => {
                      if (val[0] != "5")
                        return (
                          <div className="section">
                            <Row gutter={[12, 16]}>
                              {val[0] == "1" && (
                                <Col className="pFCollapseTitle1">Baby</Col>
                              )}
                              {val[0] == "2" && (
                                <Col className="pFCollapseTitle1">
                                  Toddler & Preschooler
                                </Col>
                              )}
                              {val[0] == "3" && (
                                <Col className="pFCollapseTitle1">
                                  Little Kid
                                </Col>
                              )}
                              {val[0] == "4" && (
                                <Col className="pFCollapseTitle1">
                                  Big Kid & Teens
                                </Col>
                              )}
                            </Row>
                            {val[1].map((item) => {
                              if (val[0] != "5")
                                return (
                                  <>
                                    {totalcount &&
                                      Object.keys(
                                        totalcount.size || {}
                                      )?.includes(item?.size) && (
                                        <Col
                                          span={24}
                                          className="sizefiltervalues"
                                        >
                                          <Row gutter={[12, 16]} key={i}>
                                            <Col>
                                              <Checkbox
                                                onChange={() => {
                                                  checkdonSizeChange(
                                                    item?.size
                                                  );
                                                  gaEventClick(
                                                    "Filter by",
                                                    "Size",
                                                    item?.size
                                                  );
                                                }}
                                                checked={
                                                  selectedSize.indexOf(
                                                    item?.size
                                                  ) !== -1
                                                }
                                              />
                                            </Col>
                                            <Row gutter={[0, 16]} key={i}>
                                              <Col>
                                                <Typography className="filtervaluestext">
                                                  {capitalize(item.size)}
                                                </Typography>
                                              </Col>
                                              {totalcount &&
                                                Object.entries(
                                                  totalcount.size || {}
                                                ).map((val, i) => {
                                                  if (item.size == val[0])
                                                    return (
                                                      <Col>
                                                        <Typography className="conditionName">
                                                          {val[1] > 0 ? (
                                                            <span className="ProductCount">
                                                              ({val[1]})
                                                            </span>
                                                          ) : (
                                                            <span>(0)</span>
                                                          )}
                                                        </Typography>
                                                      </Col>
                                                    );
                                                })}
                                            </Row>
                                          </Row>
                                        </Col>
                                      )}
                                  </>
                                );
                            })}
                          </div>
                        );
                    })}
                  <Row gutter={[12, 16]}>
                    {<Col className="pFCollapseTitle1">Others</Col>}
                  </Row>
                  {totalcount &&
                    Object.entries(totalcount.size || {}).map((val, i) => {
                      if (!allsizelist.includes(val[0])) {
                        return (
                          <>
                            <Row gutter={[12, 16]} key={i}>
                              <Col>
                                <Checkbox
                                  onChange={() => {
                                    checkdonSizeChange(val[0]);
                                    gaEventClick("Filter by", "Size", val[0]);
                                  }}
                                  checked={selectedSize.indexOf(val[0]) !== -1}
                                />
                              </Col>
                              <Col>
                                <Typography className="conditionName">
                                  {capitalize(val[0])}
                                  {val[1] > 0 ? (
                                    <span className="ProductCount">
                                      ({val[1]})
                                    </span>
                                  ) : (
                                    <span>(0)</span>
                                  )}
                                </Typography>
                              </Col>
                            </Row>
                          </>
                        );
                      }
                    })}
                </Col>
              </Row>
              {/* </Scrollbars> */}
            </Panel>
          )}
        </Collapse>
      ) : (
        <>
          {" "}
          <Collapse
            className="pFCollaps"
            // defaultActiveKey={currentOpenPanelKey}
            expandIconPosition="right"
            onChange={() => {
              dropdownCallback();
              gaEventClick("Filter by", "Size", "open/close");
            }}
            bordered={false}
            defaultActiveKey={["7"]}
            expandIcon={({ isActive }) => {
              if (!isXS) {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              } else {
                return !isActive ? (
                  <img src={plusicon} className="collapsIcon" />
                ) : (
                  <img src={minussign} className="collapsIcon" />
                );
              }
            }}
          >
            {totalcount && totalcount.size && (
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  selectedSize && Object.keys(selectedSize).length > 0 ? (
                    <Badge color="blue">
                      <Typography className="pFCollapseTitle">
                        Size&nbsp;
                      </Typography>
                    </Badge>
                  ) : (
                    <Typography className="pFCollapseTitle">Size</Typography>
                  )
                }
                key={key}
              >
                <Row  gutter={[0, 0]}>
                  <Col span={22} className="selectcategory">Select categories to select a size</Col>
                </Row>
              </Panel>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
