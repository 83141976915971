import constants from "../Utils/constants";
const initialState = {
  isBlogsLoading: false,
  Blogs: [],
  isBlogtagsLoading: false,
  Blogtags: [],
  Blogdetails: [],
  isBlogdetailsLoading: false,
  Blogsearchedtags:[],
  isBlogsearchtagsloading:false,
  isBlogimagesloading:false,
  Blogimages:[],
  Blogimageid:[],
  isBlogimageidloading:false,
  isAddbookmarkloading:false,
  isRemovebookmarkloading:false,
  isAllbookmarkloading:false,
  Blogbookmarks:[]
};

const BlogReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_BLOGS_REQUEST:
      return {
        ...state,
        isBlogsLoading: true,
      };
    case constants.GET_BLOGS_SUCCESS:
      return {
        ...state,
        isBlogsLoading: false,
        Blogs: action.payload.data,
      };
    case constants.GET_BLOGS_FAILURE:
      return {
        ...state,
        isBlogsLoading: false,
      };
    case constants.GET_BLOGS_TAGS_REQUEST:
      return {
        ...state,
        isBlogtagsLoading: true,
      };
    case constants.GET_BLOGS_TAGS_SUCCESS:
      return {
        ...state,
        isBlogtagsLoading: false,
        Blogtags: action.payload,
      };
    case constants.GET_BLOGS_TAGS_FAILURE:
      return {
        ...state,
        isBlogtagsLoading: false,
      };
    case constants.GET_BLOGS_BY_ID_REQUEST:
      return {
        ...state,
        isBlogdetailsLoading: true,
      };
    case constants.GET_BLOGS_BY_ID_SUCCESS:
      return {
        ...state,
        isBlogdetailsLoading: false,
        Blogdetails: action.payload,
      };
    case constants.GET_BLOGS_BY_ID_FAILURE:
      return {
        ...state,
        isBlogdetailsLoading: false,
      };
      case constants.GET_BLOGSTAGS_REQUEST:
      return {
        ...state,
        isBlogsearchtagsloading: true,
      };
    case constants.GET_BLOGSTAGS_SUCCESS:
      return {
        ...state,
        isBlogsearchtagsloading: false,
        Blogsearchedtags: action.payload,
      };
    case constants.GET_BLOGSTAGS_FAILURE:
      return {
        ...state,
        isBlogsearchtagsloading: false,
      };
      case constants.GET_BLOGS_IMAGE_REQUEST:
      return {
        ...state,
        isBlogimagesloading: true,
      };
    case constants.GET_BLOGS_IMAGE_SUCCESS:
      return {
        ...state,
        isBlogimagesloading: false,
        Blogimages: action.payload,
      };
    case constants.GET_BLOGS_IMAGE_FAILURE:
      return {
        ...state,
        isBlogimagesloading: false,
      };
      case constants.GET_BLOG_IMAGE_ID_REQUEST:
        return {
          ...state,
          isBlogimageidloading: true,
        };
      case constants.GET_BLOG_IMAGE_ID_SUCCESS:
        return {
          ...state,
          isBlogimageidloading: false,
          Blogimageid: action.payload,
        };
      case constants.GET_BLOG_IMAGE_ID_FAILURE:
        return {
          ...state,
          isBlogimageidloading: false,
        };
        case constants.GET_BLOGBOOKMARK_REQUEST:
        return {
          ...state,
          isAllbookmarkloading: true,
        };
      case constants.GET_BLOGBOOKMARK_SUCCESS:
        return {
          ...state,
          isAllbookmarkloading: false,
          Blogbookmarks: action.payload,
        };
      case constants.GET_BLOGBOOKMARK_FAILURE:
        return {
          ...state,
        isAllbookmarkloading: false,
        };
        case constants.ADD_BOOKMARK_REQUEST:
        return {
          ...state,
          isAddbookmarkloading: true,
        };
      case constants.ADD_BOOKMARK_SUCCESS:
        return {
          ...state,
          isAddbookmarkloading: false,
          Blogbookmarks: action.payload,
        };
      case constants.ADD_BOOKMARK_FAILURE:
        return {
          ...state,
          isAddbookmarkloading: false,
        };
        case constants.REMOVE_BOOKMARK_REQUEST:
        return {
          ...state,
          isRemovebookmarkloading: true,
        };
      case constants.REMOVE_BOOKMARK_SUCCESS:
        return {
          ...state,
          isRemovebookmarkloading: false,
          Blogbookmarks: action.payload,
        };
      case constants.REMOVE_BOOKMARK_FAILURE:
        return {
          ...state,
        isRemovebookmarkloading: false,
        };
        
    default:
      return state;
  }
};
export default BlogReducer;
