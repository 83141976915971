/*eslint-disable*/
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Card,
  Avatar,
  Divider,
  Button,
  Select,
  Input,
  Spin,
  Checkbox,
  Alert,
} from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import "../App.css";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { addAddress } from "../Actions/userAction";
const { Text, Title, Paragraph } = Typography;
const US_STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];
const CheckoutFrom = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const { cartCard, setAddressError } = props;
  const history = useHistory();
  const { currentUser, isAddAddressLoading, addresserror, currentAddressId, isAuthenticated } = useSelector((state) => {
    const { currentUser, isAddAddressLoading, addresserror } = state.user;
    const { currentAddressId } = state.cart;
    const { isAuthenticated } = state.auth;
    return {
      currentUser,
      isAddAddressLoading,
      addresserror,
      currentAddressId,
      isAuthenticated,
    };
  });
  const { addresses } = currentUser || {};
  // const { addresses } = userAddresses.user || {};
  const [addressForm, setAddressForm] = useState(false);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
  }, [addresserror]);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleAddressChange = (addressId) => {
    dispatch({type: "SELECT_ADDRESS_ID", payload: {addressId}})
    setAddressError(false)
  }
  // const MyAddresses =  addresses && addresses.length > 0 ? addresses[0] : null;
  // const ItemDescription = [
  //   {
  //     itemImg: <Avatar shape="square" size={100} src={File1} />,
  //     itemName: "Bloom Coco Lounger",
  //   },
  //   {
  //     itemImg: <Avatar shape="square" size={100} src={File2} />,
  //     itemName: "Gymboree Babygirl Dress",
  //   },
  // ]
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
      address: "",
      secondAddress: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
    },
  });
  const handleAddAddress = (data) => {
    dispatch(addAddress(data, successCallBackAddress, failureCallBackAddress));
  };
  const successCallBackAddress = (addressId) => {
    setAddressForm(false);
    dispatch({type: "SELECT_ADDRESS_ID", payload: {addressId}})
    reset();
  };
  const failureCallBackAddress = () => {};
  return (
    <>
      <Row gutter={[0, 0]} justify="space-between" align="middle">
        <Col span={24}></Col>
        <Col>
          <Row gutter={[8, 0]} align="middle">
            <Col>
              <ArrowLeftOutlined
                className="back-icon"
                onClick={() => history.goBack()}
              />
            </Col>
            <Col>
              <Row gutter={[0, 8]}>
                <Col span={24}></Col>
                <Col>
                  <Title level={4} className="your-cart-title">
                    Checkout
                  </Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            className="dit-cart-button"
            onClick={() => history.goBack()}
            type="text"
            icon={<PlusOutlined />}
          >
            EDIT CART
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}></Col>
        {cartCard &&
          cartCard.map((val, i) => (
            <Col xl={12} lg={24} md={24} sm={24} xs={24} key={i}>
              <Card key={i} className="shipping-item-card">
                <Row gutter={[8, 0]}>
                  <Col>
                    <Avatar shape="square" size={70} src={val.imageUrls[0]} />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Title className="checkout-brand-name" level={5}>
                      {val.name}
                    </Title>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
      </Row>
      <Divider />
      <Row gutter={[0, 8]} justify="space-between">
        <Col span={24}></Col>
        <Col>
          <Title className="notify-me" level={4}>
            Addresses
          </Title>
        </Col>
        {isAuthenticated && 
        <Col>
          <Button
            className="dit-cart-button"
            onClick={() => setAddressForm(true)}
            type="text"
            icon={<PlusOutlined />}
          >
            Add Address
          </Button>
        </Col>
}
      </Row>
      {addresses &&
        addresses.map((val, i) => (
          <Row key={i} gutter={[8, 8]}>
            <Col span={24}></Col>
            <Col>
              <Checkbox key={i} onChange={() => handleAddressChange(val._id)} checked={val._id === currentAddressId} />
              </Col>
              <Col>
              <Text className="brand-name">
                {val.address}, {val.secondAddress}{val.secondAddress ? "," : ""} {val.city}, {val.state}, {val.zip}
              </Text>
            </Col>
          </Row>
        ))}
        <Divider />
      {addressForm && (
        <Spin indicator={antIcon} spinning={isAddAddressLoading}>
          <form onSubmit={handleSubmit(handleAddAddress)}>
            <Row gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col>
                <Title className="notify-me" level={4}>
                  New Delivery address
                </Title>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Row>
                  <Text className="brand-name">Name</Text>
                </Row>
                <Controller as={Input} name="name" control={control} />
              </Col>
            </Row>
            {addresserror && (
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Alert
                      type="error"
                      onClose={handleClose}
                      open={open}
                      message={addresserror.error.message}
                    ></Alert>
                  </Col>
                </Row>
              )}
            <Row gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Row>
                  <Text className="brand-name">Address</Text>
                </Row>
                <Controller as={Input} name="address" control={control} />
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Controller as={Input} name="secondAddress" control={control} />
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24}></Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Row>
                  <Text className="brand-name">City</Text>
                </Row>
                <Controller as={Input} name="city" control={control} />
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Row>
                  <Text className="brand-name">State</Text>
                </Row>
                <Controller
                  as={Select}
                  control={control}
                  options={US_STATES}
                  name="state"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Row>
                  <Text className="brand-name">Zipcode</Text>
                </Row>
                <Controller as={Input} name="zip" control={control} />
              </Col>
              <Col span={24}>
                <Row>
                  <Text className="brand-name">Phone number</Text>
                </Row>
                <Controller as={Input} name="phone" control={control} />
              </Col>
            </Row>

            <Row gutter={[8, 8]} justify="end">
              <Col span={24}></Col>
              <Col>
                <Button onClick={() => setAddressForm(false)}>Cancel</Button>
              </Col>
              <Col>
                <Button htmlType="submit">Save</Button>
              </Col>
            </Row>
          </form>
        </Spin>
      )}
    </>
  );
};
export default CheckoutFrom;
