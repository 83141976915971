/*eslint-disable*/
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Spin,
  Typography,
  Empty
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import FeedTopbar from "../components/FeedTopbar";
import { CategoryCard } from "../components/CategoryCard";
import MarkidFooter from "../components/Footer";
import "../App.css";
import { getAllLots } from "../Actions/lotsAction";
import { useParams } from "react-router";

const SearchPage = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const { providerToken } = useSelector((state) => {
    const { providerToken } = state.auth;
    return {
      providerToken,
    };
  });

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  useEffect(() => {
      const {q} = params
    dispatch(getAllLots({search: q}));
  }, []);
  const { searchPageLots, isGetAllLotsLoading } = useSelector((state) => {
    const { searchPageLots, isGetAllLotsLoading } = state.lots;
    return {
        searchPageLots,
      isGetAllLotsLoading,
    };
  });
  const { lots } = searchPageLots || { lots: [] };
  return (
		<>
	<FeedTopbar providerToken={providerToken} />
      <Spin indicator={antIcon} spinning={isGetAllLotsLoading}>
      {searchPageLots.length > 0 ?
        <Row
          gutter={[16, 75]}
          justify="center"
          wrap={true}
        >
          <Col span={24}></Col>
          {searchPageLots &&
            searchPageLots.map((val, i) => (
              <Col xl={5} lg={7} md={7} sm={10} xs={11} key={i} offset={1}>
                <CategoryCard lots={val} />
            </Col>
            ))}
          <Col span={24}></Col>
        </Row>
        : 
        <Row
        gutter={[0,110]}
        justify="center"
      >
        <Col span={24}></Col>
        <Col>
        <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
        height: 60,
        }}
        description={
        <span>
           Search item not found
        </span>
        }
    >
    </Empty>
</Col>
<Col span={24}></Col>
<Col span={24}></Col>
<Col span={24}></Col>
<Col span={24}></Col>
</Row>
    }
      </Spin>
<MarkidFooter />
		</>
	);
};
export default SearchPage;
