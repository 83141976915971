import API from "../Utils/API";

export const getProductType = (Lot_id, successRedirectCallBack, failureRedirectCallBack) => (dispatch) => {
    dispatch({ type: "PRODUCT_TYPE_REQUEST" });
    API.get(`/api/v5/markid-products/${Lot_id}`) 
      .then((response) => {
        dispatch({ type: "PRODUCT_TYPE_SUCCESS", payload: response.data });
        successRedirectCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "PRODUCT_TYPE_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureRedirectCallBack();
        } catch {
          // console.log(error);
        }
      });
  };