import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useGaEvent } from "../hooks/useGaEvent";
import { Scrollbars } from "react-custom-scrollbars";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";
import { useBreakpoints } from "./../react-responsive-hooks/index";

export const SizeFilter = (props) => {
  const {
    dropdownCallback,
    checkdonSizeChange,
    Sizes,
    filterToDisplay,
    totalcount,
    itemType,
    selectedCategory,
    currentPage,
    selectedSize,
  } = props;
  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { gaPageView, gaEventClick } = useGaEvent();
  const [key, setkey] = useState();
  const { isXS, isSM } = useBreakpoints();
  useEffect(() => {
    if (!(isXS || isSM)) {
      if (currentPage[3] === "clothing_and_accessories") {
        setkey(7);
      }
      if (currentPage[3] === "maternity") {
        setkey(7);
      }
      if (currentPage[3] === "toys_and_costumes") {
        setkey(7);
      }
      if (currentPage[3] === "shoes") {
        setkey(7);
      }
    }
  }, [currentPage]);

  return (
    <>
    {itemType == "category" || selectedCategory != "" ?( <Collapse
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        onChange={() => {
          dropdownCallback();
          gaEventClick("Filter by", "Size", "open/close");
        }}
        bordered={false}
        defaultActiveKey={["7"]}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        {totalcount &&
          totalcount.size 
          && (
            <Panel
              className="collapseContent"
              style={{
                padingTop: 15,
                paddingBottom: 19,
                marginTop: 15,
                marginBottom: 19,
              }}
              header={
                selectedSize && Object.keys(selectedSize).length > 0 ? (
                  <Badge color="blue">
                    <Typography className="pFCollapseTitle">
                      Size&nbsp;
                    </Typography>
                  </Badge>
                ) : (
                  <Typography className="pFCollapseTitle">Size</Typography>
                )
              }
              key={key}
            >
              {/* <Scrollbars style={{ height: 300 }}> */}
              <Row
                className={"scroll-container"}
                // className={
                //  totalcount && (Object.keys(totalcount.size||{}).length>5)
                //      ? "filter-scroll "
                //      : ""
                //  }
                // style={{
                //   height: 220,
                //   overflowY: 'scroll',
                // }}
                gutter={[0, 0]}
              >
                <Col span={22}>
                  {totalcount &&
                    Object.entries(totalcount.size || {}).map((val, i) => (
                      <Row gutter={[12, 16]} key={i}>
                        <Col>
                          <Checkbox
                            onChange={() => {
                              checkdonSizeChange(val[0]);
                              gaEventClick("Filter by", "Size", val[0]);
                            }}
                            //onClick={() => addQuery("size", val.size)}
                            checked={selectedSize.indexOf(val[0]) !== -1}

                            // className={checkedValues ? "checkedCondition" : "unCheckedCondition"}
                          />
                        </Col>
                        <Col>
                          <Typography className="conditionName">
                            {capitalize(val[0])}
                            {val[1] > 0 ? (
                              <span className="ProductCount">({val[1]})</span>
                            ) : (
                              <span>(0)</span>
                            )}
                          </Typography>
                        </Col>
                      </Row>
                    ))}
                </Col>
              </Row>
              {/* </Scrollbars> */}
            </Panel>
          )}
      </Collapse>): (
        <>
          {" "}
          <Collapse
            className="pFCollaps"
            // defaultActiveKey={currentOpenPanelKey}
            expandIconPosition="right"
            onChange={() => {
              dropdownCallback();
              gaEventClick("Filter by", "Size", "open/close");
            }}
            bordered={false}
            defaultActiveKey={["7"]}
            expandIcon={({ isActive }) => {
              if (!isXS) {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              } else {
                return !isActive ? (
                  <img src={plusicon} className="collapsIcon" />
                ) : (
                  <img src={minussign} className="collapsIcon" />
                );
              }
            }}
          >
            {totalcount && totalcount.size && (
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  selectedSize && Object.keys(selectedSize).length > 0 ? (
                    <Badge color="blue">
                      <Typography className="pFCollapseTitle">
                        Size&nbsp;
                      </Typography>
                    </Badge>
                  ) : (
                    <Typography className="pFCollapseTitle">Size</Typography>
                  )
                }
                key={key}
              >
                <Row  gutter={[0, 0]}>
                  <Col span={22} className="selectcategory">Select categories to select a size</Col>
                </Row>
              </Panel>
            )}
          </Collapse>
        </>
      )}
     
    </>
  );
};
