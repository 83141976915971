/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash/debounce';
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Alert,
  Modal,
  Menu,
  Dropdown,
  Input,
  Badge,
  AutoComplete,
  Divider,
} from "antd";
import _ from "lodash";
import ShippingLogo from "../assets/Slice.svg";
import Logo from "../assets/logo.svg";
import LocationPin from "../assets/LocationPin.svg";
import UsertIcon from "../assets/UsertIcon.svg";
import CartIcon from "../assets/new-assets/cart-icon.svg";
import MessageIcon from "../assets/MessageIcon.svg";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Icon, {
  SearchOutlined,
  DownOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import "../styles/landing.css";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import CPMenu from "../components/PMenu";
import "../App.css";
import '../responsive.css';
import { getAllLots, getSearchLot } from "../Actions/lotsAction";
import ClockIcon from "../assets/clock.svg";
import TrendingUpIcon from "../assets/trending-up.svg";
import { getRecentSearches, getPopularSearches } from "../Actions/searchesAction";
import { updateUser } from "../Actions/userAction";
import { string } from "yup/lib/locale";
import { getAllpartnerproducts } from "../Actions/partnerProductsAction";
import ResultNotFound from "./SearchResultNotFound/partnerResultnotfound";
// import CategoryHeader from "../components/CategoryHeader";
const { Text } = Typography;
const { Search } = Input;
const ProductTop = (props) => {
  const history = useHistory();
  const { currentUser, providerToken, currentCart, allproducts, searchedproducts, recentSearches, messages, popularSearches, isAuthenticated, allChats, currentLocationZip } = useSelector(
    (state) => {
      const { allproducts, searchedproducts} = state.products;
      const { providerToken, isAuthenticated } = state.auth;
      const { currentUser, currentLocationZip } = state.user;
      const { currentCart } = state.cart;
      const { allChats } = state.chats;
      const { messagesByChatId } = state.chats;
      const { messages } = messagesByChatId;
      const { recentSearches, popularSearches } = state.searches
      return {
        currentUser,
        providerToken,
        currentCart,
        allproducts,
        searchedproducts,
        recentSearches,
        popularSearches,
        isAuthenticated,
        messages,
        allChats,
        currentLocationZip,
      };
    }
  );

  //const { partnerproducts } = allproducts || { partnerproducts: [] };
  const { fullName, photo, zipCode: UserZipCode } = currentUser || {};
  // const { providerToken } = props;
  const dispatch = useDispatch();
  const params = useParams()
  const { search: urlSearch } = params
  const location = useLocation();
  const refreshParams = new URLSearchParams(location.search)
  const refresh = refreshParams.get('refresh')
  const userImgName = fullName ? fullName.charAt(0) : null;
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [token, setToken] = useState(providerToken);
  
  const [search, setSearch] = useState(urlSearch && urlSearch.length > 0 ? urlSearch : "");
  const [zipCode, setZipCode] = useState(currentLocationZip);
  const [zipError, setZipError] = useState('');
  const [newZip, setNewZip] = useState("");
  const [visibleInput, setVisibleInput] = useState(false);
  const [modal, setModal] = useState(false);

  // const handlevalidzipcode = (e)=>{
  //   if(zipCode.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)!=null){
  //     setZipCode(zipCode)
  //   }
  //     else{
  //       alert("Invalid Zipcode!");
  //     }
  //   }


 
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setToken("");
    dispatch({ type: "REMOVE_TOKEN" });
    dispatch({ type: "RESET_APP" });
    // history.push("/homepage");
    history.push("/");
  };
  // const handleProfile = () => {
  //   history.push("/profile");
  // };
  const handleProfile = (id) => {
    if (id != undefined) {
      history.push(`/profile/${id}`);
    }
    else {
      history.push(`/profile`);
    }
  };
  const handleLogin = () => {
    history.push('/onboarding')
  }
  const handleVisibleChange = (flag) => {
    setVisibleInput(true);
    setNewZip(newZip)
  };
  // const handleChangeZipcode = (e, data) => {
  //   dispatch(
  //     updateUser(data, successCallBackUpdateZip, failureCallUpdateZip)
  //   );
  //   setVisibleInput(!visibleInput)
  // }

  const handleSearchModal = () => {
    setModal(false);
  }
  // const successCallBackUpdateZip = () => {
  //   setVisibleInput(false)
  //   setZipCode(newZip)
  // } 
  // const failureCallUpdateZip = () => {}
  useEffect(() => {
    dispatch(getRecentSearches());
    dispatch(getPopularSearches());
  }, []);
  // if((/\b\d{5}-\d{4}\b/).test(zipCode)){
  //   console.log("num")
  // }else{
  //   console.log("Not")
  // }
  // const locationMenu = (
  //   <Menu>
  //     <Menu.Item>
  //       <Input
  //       className='zipInput'
  //         maxLength={5}
  //         // type='number'
  //         defaultValue={zipCode}
  //         name={newZip}
  //         onChange={(e) => setNewZip(e.target.value)}
  //         suffix={<Button type='text' onClick={(e) => handleChangeZipcode(e)}>Save</Button>}
  //         allowClear
  //       />
  //     </Menu.Item>
  //   </Menu>
  // );
  useEffect(() => {
    handleCurrentLocation()
    // dispatch(getSearchLot());
  }, []);
  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleGeoLocationSuccess, handleGeoLocationFaliure, {});
    } else {
      alert("Sorry Not available!");
    }
  };
  const handleGeoLocationSuccess = async (s) => {
    const { coords } = s;
    const { latitude, longitude } = coords;

    const zipCode = await getZipcode({ latitude, longitude })
    setZipCode(zipCode)
    dispatch({ type: "SET_CURRENT_LOCATION_ZIP", payload: zipCode });
  }

  const getZipcode = async ({ latitude, longitude }) => {
    const apiKey = "AIzaSyC5n11G2cpe5WL1PN6l2RocIkUSNTofCe8"
    let zipCode = currentLocationZip || "10100";
    const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    const getResponse = await fetch(googleUrl, { method: 'GET', redirect: 'follow', 'Content-Type': 'application/json', }).then((response) => response.json()).then((data) => {
      if (data.results && data.results.length > 0) {

        const pcs = _.filter(data.results, { types: ["postal_code"] })
        const addressCompnents = _.forEach(pcs, function (value) {
          const { address_components } = value;
          const codes = _.filter(address_components, { types: ["postal_code"] })
          if (codes && codes.length > 0) {
            zipCode = codes[0].long_name
          }
        })
      }

    }).catch((err) => console.log({ err }));

    return zipCode;
  }

  const handleChangeZipcode = (newZipCodeInput) => {
    if (newZip.toString().match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) {
      setZipCode(newZip);
      dispatch({ type: "SET_CURRENT_LOCATION_ZIP", payload: newZip });
      setVisibleInput(!visibleInput);
      setZipError('');
    } else {
      setZipError('Valid Zipcode is required.');
    }
  }
  let HandleZipcodeInput = (e) => {
    let zipRegex = new RegExp('[0-9]{6}')
    let current = e.target;
    if (e.target.value.length > 6) {
      let value = e.target.value;
      e.target.value = value.slice(0, -1)
    }
    if (zipRegex.test(current.value) == true) {
      setZipError('')
      setCheckError(0)
    }
    else {
      setZipError(lang.validZip)
      setCheckError(1)
    }
    if (e.target.value.length == 0) {
      setCheckError(1)
      setZipError('')
    }
  }
  const locationMenu = (
    <Menu className="location-modal">
      <Menu.Item>
        <Input
          maxLength={5}
          onChange={(e) => setNewZip(e.target.value)}
          type='text'
          defaultValue={zipCode}
          name={newZip}
          // suffix={<Button type='text' onClick={(e) => handleChangeZipcode(e)}>Save</Button>}
          allowClear

        />

        {zipError &&
          <Alert message="Valid Zipcode is required." type="error" />}
        <div>
          <Button className="zipcodesavebutton" type='submit' onClick={(e) => { handleChangeZipcode(e) }}>Save</Button>
          <Button className="zipcodecancelbutton" type="submit" onClick={(e) => { setNewZip(''); setVisibleInput(false) }}>Cancel</Button>
          {/* <Button className="zipcodecancelbutton" type='submit' onClick={(e) => {handleCurrentLocation(e)}}>auto detect</Button> */}
        </div>
      </Menu.Item>
    </Menu>
  );
  const handleGeoLocationFaliure = (e) => {
    setZipCode(currentLocationZip || "10100")
  }
  const handleSearch = (data) => {
    dispatch(getAllpartnerproducts ({ search: data }))
  };

  const handleOnSelect = (value, option) => {
    setSearch(option.label)
    if (history.location.pathname.includes("/product/")) {
      const path = { pathname: `/partnerproduct-list/${option.label}/${value}?refresh=true`, state: { pId: value } }
      history.push(path)
    }
    else {
      history.push(`/product-list/${option.name}/${value}?refresh=true`)
      dispatch({ type: "RESET_SEARCH" })
    }
  }
  const handleReacentPopular = (searchQuery) => {
    history.push(`/search-new/${searchQuery}?refresh=true&zipCode=${zipCode}`)
    setModal(false);
  }
  const handleSearchSubmit = () => {
    if (search) {
      history.push(`/search/${search}`)
    }
  }
  const renderTitle = (icon, title, showClear) => (
    <Row justify='space-between'>
      <Col>
        <Row gutter={[12, 0]}>
          <Col>
            {icon}
          </Col>
          <Col>
            {title}
          </Col>
        </Row>
      </Col>
      {/* <Col>
        {showClear &&
          <a
            className='clearAll'
            //  href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clear all
          </a>
        }
      </Col> */}
    </Row>
  );
  const renderItem = (title, key) => {
    return ({
      value: key,
      label: title
    })
  };

  const options = recentSearches && recentSearches.length > 0 ? [
    // {
    //   label: renderTitle(<img alt="" src={ClockIcon} />, <Typography className='reviewedSellerName'>Recent Searches</Typography>, true),
    //   options: [renderItem(
    //     <Row gutter={[15, 15]} key='1'>
    //       {recentSearches.map((val, i) => {
    //         return (
    //           <Col key={i}>
    //             <Button size='small' className='searchesTabsButton' onClick={() => handleReacentPopular(val.searchQuery)}>
    //               {val.searchQuery}
    //             </Button>
    //           </Col>
    //         )
    //       })}
    //     </Row>, '888'
    //   )],
   // },
    // <Divider />,
    // {
    //   label: renderTitle(<img alt="" src={TrendingUpIcon} />, <Typography className='reviewedSellerName'>Popular Searches</Typography>, false),
    //   options: [renderItem(
    //     <Row gutter={[15, 15]} key='2'>
    //       {popularSearches.map((val, i) => {
    //         return (
    //           <Col key={i}>
    //             <Button size='small' className='searchesTabsButton' onClick={() => handleReacentPopular(val.searchQuery)}>
    //               {val.searchQuery}
    //             </Button>
    //           </Col>
    //         )
    //       })}
    //     </Row>, '999'
    //   )],
    // },
  ] : [];
  const updatedDesing = (items) => {
    return items.map((item) => {
      return { ...item, label: <Row gutter={[6, 8]}><Col>{item.brand} </Col><Col style={{ fontWeight: 'bold' }}>{item.label}</Col></Row> }
    })

  }
  const handleOnChangeInAutoComplete = (e, d) => {
    
    setModal(false);
    if (e !== '888' && e !== '999') {
      if (d.name) {
        setSearch(d.name)
        history.push(`/partnerproduct-list/swoondle/${d.name}/${d._id}`)
      }
      else {
        setSearch(e)
      }
    }
  }
  const handleOnChangeInput = (e) => {
    setModal(false);
    if (e.target.value) {
      setSearch(e.target.value)
    }
  }
  const onDropdownVisibleChange = (test) => {
    // if (!test && search.length > 0) {
    //   history.push(`/search-new/${search}?refresh=true`)
    // }
  }
  const onKeyPressVisibleChange = (test) => {
   
    setModal(false);
    
    if ((test.code === 'Enter' || test.key === 'Enter') && modal === true && search.length > 0) {
      history.push(`/partnerproduct-list/swoondle/${search}`)
      setModal(false)
    } else if ((test.code === 'Enter' || test.key === 'Enter') && modal === false) {
      history.push(`/partnerproduct-list/swoondle/${search}`)
    }
  }
  const handleOnClickMessage = () => {
    if (isAuthenticated) {
      history.push(`/chat`)
      // history.push(`/messages`)
      dispatch({ type: 'GET_TAB_KEY', payload: '' });
    }
    else {
      history.push('/onboarding')
    }
  }
  return (
    <>
  
      <div className='userLoginButtonWeb'>
        <Row align="middle" className="app-wrapper header-top" gutter={[0, 0]} justify="space-between">
       
          {/* <Col span={24}></Col> */}
            
              {/* <Col className="pin">
                <Row gutter={[10, 0]}  align="middle">
                  <Col lg={24}>
                    
                    
                  </Col>
                </Row>
              </Col> */}
              <Col className="topsearchbarvariation">
                <Row align="middle" justify="space-around">
                  
              
                    <AutoComplete
                      className='inputBorder'
                      onSearch={debounce(handleSearch, 500)}
                      //onSelect={(v) => search.length > 0 ? handleOnSelect : { v }}
                      // onChange={(e, d) => handleOnChangeInAutoComplete(e, d)}
                      onDropdownVisibleChange={onDropdownVisibleChange}
                      value={search}
                      options={search.length > 0 ? updatedDesing(searchedproducts) : options}
                      // size='large'
                      style={{ width: "100%", position: 'relative', }}
                    >
                      {/* <Input.Search size='large' className='searchPlaceHolder' 
                  placeholder="Search for cribs..."  onClick={() => console.log("Input")} 
                  onKeyPress={(x) => onKeyPressVisibleChange(x)} 
                  /> */}
                      <div className="inputParent">
                        {/* <input id="inputEx" type="search" onKeyDown={(x) => onKeyPressVisibleChange(x)} placeholder="Search Markid..." className='searchHtmlInput' autoComplete="off" autoFocus /> */}
                        <input id="inputEx" type="search"onInput={(x) => onKeyPressVisibleChange(x)} placeholder="Search Swoondle..." className='searchInput' autoComplete="off" autoFocus />
                        <SearchOutlined className="searchimage" />
                      </div>
                    </AutoComplete>
                  
                </Row>
              </Col>
              {/* <Col> */}
                {/* <Button htmlType='submit' onClick={() => handleSearchSubmit()} onKeyPress={(x) => onKeyPressVisibleChange(x)} type='text' className='pTSearchButton' icon={<SearchOutlined style={{color: '#393939'}}/>}></Button> */}
              {/* </Col>
              <Col>
              </Col> */}
            </Row>
      </div>

      {/* MOBILE HEADER START */}
      <div>
        <div className='userLoginButtonMob'>
          <Row align="middle" gutter={[0, 0]} justify="space-between" className="header-top-block">
            {/* <Col span={24}></Col> */}
          
            <Col
              sm={22}
              xs={22}
            >
              <Row align="middle" gutter={[0, 8]} justify='center'>
                
                <Col xs={8} sm={8} >
                  <Row align="middle" justify="end">
                    {/* <Col sm={8} style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "#f00" }}> </Col> */}
                    {/* <Col sm={8} className="menu" style={{ backgroundColor: "#fff" }} > </Col> */}
                  </Row>
                </Col>

              </Row>
            </Col>
          </Row>

          <Row  justify="space-between" className="header-search-block">
            <Col xs={9} span={8}>
              <Row gutter={[10, 0]} style={{ margin: 0 }} align="middle" >
                {/* <Col></Col> */}
              
              </Row>
            </Col>
            <Col xs={15} span={16} className="topsearchbarvariationmob">
              {!modal ?
                <div className="inputParent" onClick={handleSearchModal}>
                  {/* <input id="inputEx" type="search" onChange={(e) => handleOnChangeInput(e)} placeholder="Search Markid..." onKeyDown={(x) => onKeyPressVisibleChange(x)} className='searchHtmlInput' autoComplete="off" autoFocus /> */}
                  <input id="inputEx" type="search" onChange={(e) => handleOnChangeInput(e)} placeholder="Search Swoondle..." className='searchHtmlmobInput' autoComplete="off" autoFocus />
                  <SearchOutlined className="searchOutlined" />
                </div>
                :
                <Modal
                  style={{ top: 5 }}
                  maskClosable
                  bodyStyle={{ background: 'rgba(0, 0, 0, 0.45)', padding: '0px' }}
                  visible={modal}
                  footer={false}
                  closable={false}
                  onCancel={() => setModal(false)}
                >
                  <AutoComplete
                    onSearch={debounce(handleSearch, 500)}
                    onSelect={(v) => search.length > 0 ? handleOnSelect : { v }}
                    // onSelect={handleOnSelect}
                    onChange={(e, d) => handleOnChangeInAutoComplete(e, d)}
                    value={search}
                    defaultOpen={true}
                    clearIcon={<CloseOutlined style={{ color: 'gray', background: '#EFEFEF', fontSize: 50 }} />}
                    options={search.length > 0 ? updatedDesing(searchedproducts) : options}
                    style={{ width: "100%" }}
                  >
                    {/* <Input suffix={<SearchOutlined color="red"/>} size="small" style={{height:30, border:'2px solid #EFEFEF', boxShadow:'unset'}} enterButton={false} className="headerSearchBox"/> */}
                    <div className="inputParent">
                      {/* <input id="inputEx" type="search" placeholder="Search for cribs..." onKeyDown={(x) => onKeyPressVisibleChange(x)} className='searchHtmlInput' autoComplete="off" autoFocus /> */}
                      {/* <Input.Search id="inputEx" ype="search" placeholder="Search for cribs..." className='searchHtmlInput' autoComplete="off" autoFocus enterButton/> */}
                      <input id="inputEx" type="search" placeholder="Search for cribs..." className='searchInput' autoComplete="off" autoFocus />
                      <SearchOutlined className="searchOutlined" />
                    </div>
                  </AutoComplete>
                </Modal>
              }
            </Col>
          </Row>
        </div>
      </div >

      {/* <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      /> */}
      {/* <CategoryHeader /> */}
    </>
  );
};
export default ProductTop;
