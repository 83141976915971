import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBrandName } from "../Actions/brandsAction";
import debounce from "lodash/debounce";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Alert,
  Modal,
  Menu,
  Dropdown,
  Input,
  Badge,
  AutoComplete,
  Divider,
} from "antd";
import _ from "lodash";
import ShippingLogo from "../assets/Slice.svg";
import arrowRight from "../assets/new-assets/ChevronRIght.png";
import Logo from "../assets/logo.svg";
import LocationPin from "../assets/LocationPin.svg";
import ClockIcon from "../assets/clock.svg";
import UsertIcon from "../assets/UsertIcon.svg";
import CartIcon from "../assets/new-assets/cart-icon.svg";
import searchIcon from "../assets/searchIcon.svg";
import hamburgerIcon from "../assets/hamburgerNew.png";
import MessageIcon from "../assets/MessageIcon.svg";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PMenu from "../components/PMenu";
import HomepageProductCard from "../components/HomepageProductCard";

import {
  getAllpartnerproducts,
  getFilterpartnerProducts,
} from "../Actions/partnerProductsAction";

const RecentlyAddedProducts = ({ recentlyAdded, link, partnerproducts }) => {
  // const { partnerproducts } = useSelector((state) => {
  //   const { partnerproducts } = state.products;

  //   return {
  //     partnerproducts,
  //   };
  // });


  return (
    <div className="app-wrapper">
      <Typography className="sectionTitles">
        Recently added <a onClick={link}>see all</a>
      </Typography>
      {/* <Row className="container">          
        <Col>Recently added</Col>
        <Col>see all</Col>
        </Row> */}
      <Row className="container">
        {recentlyAdded &&
          recentlyAdded?.hits?.map((obj) => (
            <Col
              xl={4}
              lg={{ span: 4 }}
              md={24}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <HomepageProductCard
                obj={obj}
                isPartner={obj.productType === "partnerToPerson" ? true : false}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default RecentlyAddedProducts;
