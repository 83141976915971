import React, { useState, useEffect } from "react";
import { Card, Typography, Row, Col, Avatar, Divider, Button, Rate } from "antd";
import "../App.css";
import { useBreakpoints } from './../react-responsive-hooks/index';
import SaqureBlankImg from "../assets/SaqureBlankImg.jpeg";
import Badge1 from "../assets/Badge1.svg";
import Badge2 from "../assets/Badge2.svg";
import Badge3 from "../assets/Badge3.svg";
import Badge4 from "../assets/Badge4.svg";
import moment from 'moment';
export const SellerInfoCard = (props) => {
  const { isXS, isSM } = useBreakpoints();
  const { userById, sellerBadges } = props
  const { photo, fullName, activeLotsCount, soldLotsCount, bio, createdAt } = userById
  const { fastResponder, fastShipper, top10 } = sellerBadges || {}
  const truncate = bio && bio.substring(0, 92) + '...';
  const [sellerBio, setSellerBio] = useState("");
  useEffect(() => {
    setSellerBio(truncate)
  }, [truncate]);

  const [isMore, setIsMore] = useState(false);
  const handleMore = () => {
    setSellerBio(bio);
    setIsMore(true);
  }
  const handleLess = () => {
    setSellerBio(truncate);
    setIsMore(false);
  }
  return (
    <div className="pCategoryParent sellerCard">
      <Card bordered={false} className="pCategoryCard">
        <div className="app-wrapper">
          <Row justify='space-between' gutter={[0, { xs: 0, sm: 0, md: 50, lg: 50, xl: 50, xxl: 50 }]}>
            <Col span={24}></Col>
            <Col>
              <Row gutter={[30, 0]}>
                <Col className="sellerImg">
                  {/* size={(isXS || isSM) ? 160 : 100} */}
                  {photo ?
                    <Avatar className="sellerAvatar" shape='square' src={photo} style={{ borderRadius: 10 }} />
                    :
                    <Avatar className="sellerAvatar" shape='square' src={SaqureBlankImg} style={{ borderRadius: 10 }} />
                  }
                  <div className="ratingWrapper">
                    <Rate
                      defaultValue={4}
                      className="smallStart"
                      disabled={true}
                    />
                  </div>
                  {/* <Row gutter={[0, 10]} justify='center'>
                    <Col span={24}></Col>
                    <Col span={24}>

                    </Col>
                  </Row> */}
                  {/* 20211228 Varun: follower count functionality is not there value was hardcoded. */}
                  {/* <Row gutter={[0, 8]} justify='center'>
                  <Col span={24}></Col>
                  <Col>
                    <Typography className='sellerRatingsCount'>
                      (587)
                    </Typography>
                  </Col>
                </Row> */}
                </Col>
                <Col className="sellerInfo">
                  <Row>
                    <Typography className='name'>{fullName}</Typography>
                  </Row>
                  <Row gutter={[0, 11]}>
                    <Col span={24}></Col>
                    <Col style={{ paddingRight: 20 }}>
                      <Typography className='detail'>
                        Items listed: <span style={{ fontWeight: 'bold' }}>{activeLotsCount + soldLotsCount}</span>
                      </Typography>
                    </Col>
                    <Divider type="vertical" style={{ height: 22, color: '#A5A5A5' }} />
                    <Col style={{ paddingLeft: 20 }}>
                      <Typography className='detail'>
                        Sales: <span style={{ fontWeight: 'bold' }}>{soldLotsCount}</span>
                      </Typography>
                    </Col>
                  </Row>
                  <Row gutter={[0, 19]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className='sellerUserBio' style={{ width: 578 }}>
                        {sellerBio && sellerBio.length < 50 ? bio : sellerBio}
                      </Typography>
                    </Col>
                  </Row>
                  {bio && (bio.length > 150) &&
                    <Row gutter={[0, 6]}>
                      <Col span={24}></Col>
                      <Col>
                        <Button onClick={isMore ? handleLess : handleMore}
                          type='text' className="readMoreButton">
                          {isMore ? "Read less" : "Read more"}
                        </Button>
                      </Col>
                    </Row>
                  }
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[0, 13]}>
                <Col span={24}></Col>
                <Col
                  xl={{ offset: 1 }}
                  lg={{ offset: 1 }}
                  md={{ offset: 1 }}
                  sm={{ offset: 0 }}
                  xs={{ offset: 0 }}
                >
                  <Typography className="pLSellerBadge">
                    Seller badges
                  </Typography>
                </Col>
              </Row>
              <Row gutter={[0, 15]}>
                <Col span={24}></Col>
                {top10 &&
                  <Col>
                    <Row justify="center" gutter={[0, 2]}>
                      <Avatar className="badgeImg" size={40} src={Badge1} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col xxl={18} xl={18} lg={18} md={18}>
                        <Typography className="badgeTitle">
                          Top 10% seller
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
                {createdAt &&
                  <Col>
                    <Row justify="center">
                      <Avatar className="badgeImg" size={40} src={Badge2} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col xxl={18} xl={18} lg={18} md={18}>
                        <Typography className="badgeTitle">
                          Member since {moment(createdAt).format("YYYY")}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
                {fastResponder &&
                  <Col>
                    <Row justify="center">
                      <Avatar className="badgeImg" size={40} src={Badge3} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col xxl={18} xl={18} lg={18} md={18}>
                        <Typography className="badgeTitle">
                          Fast Responder
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
                {fastShipper &&
                  <Col>
                    <Row justify="center">
                      <Avatar className="badgeImg" size={40} src={Badge4} />
                    </Row>
                    <Row gutter={[0, 6]} justify="center">
                      <Col span={24}></Col>
                      <Col xxl={18} xl={18} lg={18} md={18}>
                        <Typography className="badgeTitle">
                          Fast Shipper
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                }
              </Row>
            </Col>
            <Col span={24}></Col>
          </Row>
        </div>

      </Card>
    </div>
  );
};
