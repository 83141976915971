import React from "react";
import { Row, Col, Typography, Card, Button, Tabs, Spin } from "antd";
import SwoondleImg from "../assets/new-assets/swoondleimagenew.png";

import SwoondleBabyImg from "../assets/new-assets/Swoondlebabyimgnew.png";
import SwoondleTodlerImg from "../assets/new-assets/Swoondletoddlerimgnew.png";
import SwoondleBigBabyImg from "../assets/new-assets/Swoondlebigkidnew.png";
import SwoondleExploreImg from "../assets/new-assets/swoondleexplorenew.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFilterCondition } from "../Actions/brandsAction";
import { useGaEvent } from "../hooks/useGaEvent";

const ShopfromSwoondle = () => {
  const history = useHistory();
  const { gaPageView, gaEventClick } = useGaEvent();
  const dispatch=useDispatch()
  return (
    <div className="swoondleWrapper">
      <Typography className="sectionTitles swoondleTitle" align="center">
      Shop styles for every stage
      </Typography>
      {/* <img src={SwoondleImg} alt="" className="SwoondleImg" /> */}

      <div className="shopFromSwoondle app-wrapper" align="center">
        <Row className="topBlock">
          <Col xs={8} sm={8} md={8} lg={8} xl={8} onClick={()=>{history.push("/search-new/?refresh=true&zipCode=10100");
            gaEventClick('Browse', 'Shop from Swoondle', 'Baby');
            dispatch(setFilterCondition("baby"))}}>
            <img src={SwoondleBabyImg} alt="" className="babyshotshome"/>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} onClick={()=>{history.push("/search-new/?refresh=true&zipCode=10100");
            gaEventClick('Browse', 'Shop from Swoondle', 'Toddler');
            dispatch(setFilterCondition("toddler"))}}>
            <img src={SwoondleTodlerImg} alt="" className="babyshotshome"  />
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} onClick={()=>{history.push("/search-new/?refresh=true&zipCode=10100");
            gaEventClick('Browse', 'Shop from Swoondle', 'Big Kid');
            dispatch(setFilterCondition("bigKid"))}}>
            <img src={SwoondleBigBabyImg} alt="" className="babyshotshome" />
          </Col>
        </Row>

        <Row className="bottomBlock">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <img
              src={SwoondleExploreImg}
              alt=""
              className="explorenew"
              onClick={() => {
                gaEventClick('Browse', 'Shop from Swoondle', 'Browse Collection');
                history.push("/verifiedshops")
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ShopfromSwoondle;