import React, {useEffect}from "react";
import BlogDetailComponent from "../components/BlogDetailComponent";
import Blogimg from "../assets/new-assets/Blogimg.png";
import Blogimgs from "../assets/new-assets/Blogdetailimgweb.png";
import BlogimgMob from "../assets/new-assets/Blogdetailimgmob.png";
import { Spin } from "antd";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector,useDispatch } from "react-redux";
import { getBlogimageById } from "../Actions/BlogsAction";
import { useParams } from "react-router-dom";
const BlogDetailPage = () => {
  const { isBlogdetailsLoading} = useSelector((state) => {
    const {isBlogdetailsLoading } = state.Blogs;
    return {
      // Blogimageid,
      isBlogdetailsLoading
    };
  });
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  const params=useParams();
  const {id}=params
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getBlogimageById(id));
  // }, []);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <div className="BlogDetailPageContainer">
      <Spin
            style={{ top: 200, color: "#FF533F" }}
            indicator={antIcon}
            spinning={isBlogdetailsLoading}
          >
        <div className="BlogTopDetail">
        {(isXL || isLG || isXXL || isMD) && (
            <img src={Blogimgs} className="Blogdetailimageheight" style={{ width: "100%" }} />
        )}

        {(isXS || isSM) && (
          <img src={BlogimgMob} className="Blogdetailimageheight" style={{ width: "100%" }} />
        )}
        
        </div>
        <div><BlogDetailComponent/></div>
        <div className="Blogdetaildown"></div>
        </Spin>
      </div>
    </>
  );
};
export default BlogDetailPage;
