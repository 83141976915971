/*eslint-disable*/
import React from "react";
import {
  Tabs,
  Row,
  Col,
} from "antd";
import "../styles/landing.css";

const { TabPane } = Tabs;

const CategoryHeader = (props) => {
  const { itemKeyValue } = props;
  function callback(key) {
  }
  return (
    <>
   
      <Tabs defaultActiveKey="1" onChange={callback} size='large' centered>
        {/* <TabPane tab="Home" key="1"></TabPane> */}
        {/* <TabPane tab="Categories" key="2"></TabPane> */}
        <TabPane tab="ReNewed" key="3"></TabPane>
        {/* <TabPane tab="Community" key="4"></TabPane>
        <TabPane tab="Blog" key="5"></TabPane>
        <TabPane tab="Contact Us" key="6"></TabPane> */}
      </Tabs>
    </>
  );
};
export default CategoryHeader;
