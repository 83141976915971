/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useGaEvent } from '../hooks/useGaEvent';
import { Row, Col, Typography, Divider, Avatar } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import RemoveIcon from '../assets/RemoveIcon.svg';
import '../App.css';
import { useBreakpoints } from './../react-responsive-hooks/index';
import UserBlanckImg from '../assets/UserBlanckImg.svg';
import { addToCart, getCurrentUserWallet , getLastCart} from "../Actions/cartAction";
const { Text, Title } = Typography;
const CartItemList = (props) => {
  const { gaEventClick } = useGaEvent();
  const dispatch = useDispatch();
  const { isXS, isSM } = useBreakpoints();
  const { imageUrls, name, price, recommendedShippingPrice, deliveryType,isActive, closeIcon, itemImg, itemName, itemShippingPrice, shippingPrice, totalPrice, mode, seller } = props.currentCart;
  const { fullName: sellerName, photo: sellerPhoto } = seller || {};
  const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

const [showcancelDialog,setShowcancelDialog]=useState(false)
  const { currentCart, isAuthenticated, lastCart, currentCartId ,currentUser, currentCartIds} = useSelector((state) => {
    const { currentCart, currentCartId, lastCart, currentCartIds } = state.cart;
    const { isAuthenticated } = state.auth;
    const {
      currentUser,
     
    } = state.user;
    return {
      lastCart,
      currentCart,
      currentCartId,
      isAuthenticated,
      currentUser,
      currentCartIds,    
    };
  });

  const manageCartItemAddition = () => {
    const lotsArr = JSON.parse(localStorage.getItem("userCart"))
      ? JSON.parse(localStorage.getItem("userCart"))
      : [];
    const data = {};
    data.cartId = currentUser ? currentUser.cartId : "";
    data.lots = lotsArr;
    data.addressId = null; // isAuthenticated? currentUser.addresses[0]._id: null
    data.email = currentUser ? currentUser.email : "";

    dispatch(
      addToCart(
        data,
        isAuthenticated,
        successMarkidCallBack,
        failureMarkidCallBack
      )
    );
  };


  const successMarkidCallBack = () => {
    dispatch(getLastCart(currentUser._id));
  };
  const failureMarkidCallBack = () => {};


  useEffect(() => {
    if (currentUser?.cartId) {
      if (JSON.parse(localStorage.getItem("userCart"))?.length > 0) {
        manageCartItemAddition();
      } else {
        dispatch(getLastCart(currentUser._id));
      }
    }
  }, [currentUser?.cartId]);
  

  const deleteFromLocalStorage = (item) => {
    const currCartIds = [...currentCartIds]
    //console.log(currCartIds);
    //console.log(item)
    const lotsArr = JSON.parse(localStorage.getItem("userCart"))?  JSON.parse(localStorage.getItem("userCart")) : []
    //currCartIds.splice(currCartIds.indexOf(item.markidProductId),1);

    
    //
    if(item.markidProductId)
    {
      lotsArr.splice(lotsArr.indexOf(item.markidProductId),1);
      dispatch({ type: "REMOVE_ITEM_CART", payload: { item: item } });
      if(currCartIds.length>0)
      dispatch({ type: "DELETE_CURRENT_CART", payload: { id: item.markidProductId } });
      localStorage.setItem("userCart", JSON.stringify(lotsArr));
    }
    else if(item.objectID){
      lotsArr.splice(lotsArr.indexOf(item.objectID),1);
      dispatch({ type: "REMOVE_ITEM_CART", payload: { item: item } });
      if(currCartIds.length>0)
      dispatch({ type: "DELETE_CURRENT_CART", payload: { id: item.markidProductId } });
      localStorage.setItem("userCart", JSON.stringify(lotsArr));
    }
    else{
      lotsArr.splice(lotsArr.indexOf(item._id),1);
      dispatch({ type: "REMOVE_ITEM_CART", payload: { item: item } });
      if(currCartIds.length>0)
      dispatch({ type: "DELETE_CURRENT_CART", payload: { id: item._id } });
      localStorage.setItem("userCart", JSON.stringify(lotsArr));
    }
  }


  const handleRemoveItemCart = (item) => {
    gaEventClick('Cart', 'Delete product', item?.name);
    //console.log(item.markidProductId);
    //console.log(currentCartIds);
    if(isAuthenticated){
      const currCartIds = [...currentCartIds]
   
      currCartIds.splice(currCartIds.indexOf(item.markidProductId),1);
    
      handleCreateCart(currCartIds);
    }
    else{
      deleteFromLocalStorage(item);
    }
   
  };

  const handleCreateCart = (lotsArr) => {
   
    const data = {}
    data.cartId = isAuthenticated? currentUser.cartId: ""
    data.lots = lotsArr
    //data.total = (_.sumBy(currentCart, function (o) { return o.price; }) / 100).toFixed(2)
    data.addressId = null //isAuthenticated? currentUser.addresses[0]._id: null
    data.email = isAuthenticated?currentUser.email: null
    if (isAuthenticated) {
        dispatch(addToCart(data,true, successCallBackCart, failureCallBack));}
  };

  const successCallBackCart = () => {
    dispatch(getLastCart(currentUser._id));
};
const failureCallBack = () => {};

  return (
    <>
      <div className="cartItems">
        {isXS || isSM ? (
          <>
            <Row gutter={[15, 0]}>
              <Col span={7}>
                <Row gutter={[0, 0]}>
                  <Col span={24} />
                  <Avatar shape="square" size={70} src={images} className="cartImg" />
                </Row>
              </Col>
              <Col span={17}>
                <Row justify="space-between" gutter={[0, 0]}>
                  <Col span={24} />
                  <Col xs={2}>
                    <Typography className="cartItemName">{capitalize(name)}</Typography>
                  </Col>
                </Row>
                <Row gutter={[0, { xs: 10, sm: 10, md: 32, lg: 32, xl: 32, xxl: 32 }]} align="bottom" justify="space-between">
                  <Col span={24}></Col>
                  <Col>
                    <Row>
                      <Col span={24} className="priceText">
                        Price
                      </Col>
                      <Col className='priceVal'>
                      {!(Number.isInteger(price/100))?(
                    <Typography className="priceVal">${(price / 100).toFixed(2)}</Typography>):(
                      <Typography className="priceVal">${Math.trunc((price/100))}</Typography>)
                    }
                      </Col>
                    </Row>
                   {isActive===false &&( <Row>
                      <Col span={24}  style={{color:"red", fontWeight:"600"}}>
                      * Item not active
                      </Col>
                    </Row>)}
                  </Col>
                  <Col>
                    <img alt="" src={RemoveIcon} onClick={() => handleRemoveItemCart(props.currentCart)} style={{ cursor: 'pointer', paddingBottom: 10 }} />
                  </Col>
                </Row>
              </Col>
            </Row>
            {props.currentCart.shippingPrice != 0 && props.currentCart.isFree == false ? (
              <Row gutter={[0, 18]}>
                <Col span={24} />
                <Typography className="shippingCharges">Includes shipping charge - $ {props.currentCart.shippingPrice / 100}</Typography>
              </Row>
            ) : (
              ''
            )}
              
          </>
        ) : (
          <Row gutter={[0, 0]} justify="space-between">
            <Col span={24}>
              <Row gutter={[{ xs: 22, sm: 22, md: 29, lg: 29, xl: 29, xxl: 29 }, 0]} justify="space-between">
                <Col xs={3} sm={5} md={5} lg={3} xl={3} xxl={3}>
                  <Avatar shape="square" size={isXS || isSM ? 70 : 120} src={images} className="cartImg" />
                </Col>
                {/* <Col span={2} /> */}
                <Col xs={19} sm={17} md={17} lg={19} xl={19} xxl={19}>
                  <Row justify="space-between">
                    <Col xs={2}>
                      <Typography className="cartItemName">{capitalize(name)}</Typography>
                    </Col>
                    <Col>
                      <img alt="" src={RemoveIcon} onClick={() => handleRemoveItemCart(props.currentCart)} style={{ cursor: 'pointer' }} />
                    </Col>
                  </Row>
                  <Row gutter={[0, { xs: 10, sm: 10, md: 32, lg: 32, xl: 32, xxl: 32 }]} align="bottom" justify="space-between">
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="priceText">Price</Typography>
                      <Row gutter={[0, 5]}>
                        <Col>
                         
                          {!(Number.isInteger(price/100))?(
                    <Typography className="priceVal">${(price / 100).toFixed(2)}</Typography>):(
                      <Typography className="priceVal">${Math.trunc((price/100))}</Typography>)
                    }
                        </Col>
                      </Row>
                    </Col>
                   
                    {isAuthenticated && props.currentCart.shippingPrice != 0 && props.currentCart.isFree == false ? (
                      <Col>
                        <Typography className="shippingCharges">Includes shipping charge - $ {props.currentCart.shippingPrice / 100}</Typography>
                      </Col>
                    ) : (
                      ''
                    )}
                     {isActive===false &&(<Col style={{color:"red", fontWeight:"600"}}> * ITEM NOT ACTIVE</Col>)}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* <Divider style={{ margin: 0, marginTop: 24 }} /> */}
      {isAuthenticated && <div className="seller-info">
        <Row gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
          <Col span={24}></Col>
          <Col>
            <Typography className="cESellerText">Seller</Typography>
          </Col>
        </Row>
        <Row gutter={[0, 11]} justify="space-between">
          <Col span={24}></Col>
          <Col>
            <Row gutter={[15, 0]}>
              <Col>{sellerPhoto ? <Avatar shape="circle" size={50} src={sellerPhoto} /> : <Avatar shape="circle" size={50} src={UserBlanckImg} />}</Col>
              <Col>
                <Row gutter={[0, 14]}>
                  <Col span={24}></Col>
                  <Col>
                    <Typography className="cEsellerName">{sellerName}</Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>}

        <Divider style={{ margin: 0, marginTop: 25 }} />
      </div>
    
    </>
   
  );
};
export default CartItemList;
