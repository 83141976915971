import { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

const ScrollToTop = ({ history }) => {
    const History=useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => {
            // window.scrollTo(0, 485);
            // document.body.scrollTo({top: 0, behavior: 'smooth'});
            document.body.scroll(0,0);
        });
        return () => {
            unlisten();
        }
    }, [History]);

    return (null);
}

export default withRouter(ScrollToTop);