import API from '../Utils/API';
import constants from '../Utils/constants';
export const getEstimatedCharges = (data) => (dispatch) => {
    dispatch({ type: constants.POST_ESTIMATED_CHARGES_REQUEST, payload: data });
    API.post("/api/v1/shipping/get-rates", data)
      .then((response) => {
        dispatch({ type: constants.POST_ESTIMATED_CHARGES_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({
          type:constants.POST_ESTIMATED_CHARGES_FAILURE,
          payload: error.response.data,
        });
      });
  };