/*eslint-disable*/
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Layout, Upload } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Header, Footer, Sider, Content } = Layout;

import {
  Row,
  Col,
  Typography,
  Breadcrumb,
  Input,
  Card,
  Tag,
  Button,
  Collapse,
  Avatar,
  Spin,
  Rate,
  Divider,
  Tabs,
  Skeleton,
  Space,
} from "antd";
import ProductTop from "../components/ProductTop";
import PLCart from "../components/PLCart";
import PPLCart from "../components/PPLCart";
import PLDescription from "../components/PLDescription";
import PLDpage from "../components/PLDpage";
import { MessageSeller } from "../components/MessageSeller";
import {
  ShareAltOutlined,
  HeartOutlined,
  LoadingOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Edit from "../assets/edit-Icon.svg";

import { ReNewedWorkCard } from "../components/RenewedWork";
import { CategoryCard } from "../components/CategoryCard";
import HeadingHearts from "../assets/HeadingHearts.svg";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import ListThree from "../assets/list3.svg";
import PickUpImg from "../assets/pickup.svg";
import Rest from "../assets/rest.svg";
import PaidOne from "../assets/paid1.svg";
import MainHomePageFooter from "../components/MainHomePageFooter";
import { getPartnerProductById } from "../Actions/partnerProductAction";
import "../App.css";
import { useHistory, useParams } from "react-router";
import { PLBreadCrumb } from "../components/PLBreadCrumb";
import PMenu from "../components/PMenu";
import moment from "moment";
import SellerImg from "../assets/SellerImg.svg";
import SellerImgOne from "../assets/SellerImgOne.svg";
import SellerImgTwo from "../assets/SellerImgTwo.svg";
import TrueVector from "../assets/TrueVector.svg";
import Badge1 from "../assets/Badge1.svg";
import swoondlesociety from "../assets/swoondlesociety.png";
import Badge2 from "../assets/Badge2.svg";
import Badge3 from "../assets/Badge3.svg";
import Badge4 from "../assets/Badge4.svg";
import { PLSimilarCard } from "../components/PLSimilarCard";
import { MoreItemsCard } from "../components/PLMoreItemsCard";
import { RecommendedCard } from "../components/RecommendedCard";
import SignIn from "../pages/SignIn";
import {
  followUser,
  unFollowUser,
  moreLotsFromThisSeller,
  similarItems,
  getUserRatings,
  uploadUserPhoto,
} from "../Actions/userAction";
import PL1 from "../assets/PL1.svg";
// import sellerswoondle from "../assets/sellerswoondle.png";
import swsociety from "../assets/swsociety.png";
import swbadge from "../assets/swbadge.png";
import PL2 from "../assets/PL2.svg";
import PL3 from "../assets/PL3.svg";
import PL4 from "../assets/PL4.svg";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel, { consts } from "react-elastic-carousel";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import WMBgshape from "../assets/WMBgshape.svg";
import ShopKidsImg from "../assets/ShopKidsImg.svg";
import OurTeamImg from "../assets/OurTeamImg.svg";
import PrelovedImg from "../assets/PrelovedImg.svg";
import RemoveIcon from "../assets/RemoveIcon.svg";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { humanize } from "../Utils/util";
import { getChatsWithSeller } from "../Actions/chatsAction";
import { getSellerBadges } from "../Actions/userAction";
import { getPartnerDetails } from "../Actions/partnerDetailsAction";
import { moreLotsFromThisPartner } from "../Actions/partnerProductsAction";
import { createLot } from "../Actions/lotsAction";
import { SellerConfirmation } from "../components/SellerConfirmation";
import { SellerCancelModal } from "../components/sellerCancelModal";
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const PreviewPublishPage = () => {
  const { isXS, isXL, isSM } = useBreakpoints();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const {
    lot,
    allLots,
    isGetLotByIdLoading,
    currentUser,
    isFollowUserLoading,
    isUnFollowUserLoading,
    isNotifyItemLoading,
    isMoreFromThisSellerLoading,
    isSimilarItemsLoading,
    isUnNotifyItemLoading,
    currentCart,
    isAuthenticated,
    moreLots,
    similarLots,
    sellerBadges,
    isGetChatsWithSellerLoading,
    partnerProduct,
    partnerDetails,
    isMoreFromThisPartnerLoading,
    morePartnerProducts,
    isGetPartnerProductByIdLoading,
    parcelID,
  } = useSelector((state) => {
    const { lot, isGetLotByIdLoading, allLots } = state.lots;
    const { partnerProduct, isGetPartnerProductByIdLoading } =
      state.partnerProducts;
    const { partnerDetails, isGetPartnerDetailsLoading } = state.partnerDetails;
    const { isMoreFromThisPartnerLoading, morePartnerProducts } =
      state.products;
    const { currentCart } = state.cart;
    const { isAuthenticated } = state.auth;
    const { parcelID } = state.shipping;
    const { isGetChatsWithSellerLoading } = state.chats;
    const {
      currentUser,
      sellerBadges,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      moreLots,
      similarLots,
      isMoreFromThisSellerLoading,
      isSimilarItemsLoading,
    } = state.user;
    return {
      lot,
      allLots,
      moreLots,
      similarLots,
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      currentCart,
      isAuthenticated,
      isGetLotByIdLoading,
      isMoreFromThisSellerLoading,
      isSimilarItemsLoading,
      isGetChatsWithSellerLoading,
      sellerBadges,
      partnerProduct,
      partnerDetails,
      isGetPartnerProductByIdLoading,
      isMoreFromThisPartnerLoading,
      morePartnerProducts,
      parcelID,
    };
  });

  const { fastResponder, fastShipper, top10 } = sellerBadges || {};
  const { _id: currentUserId, following } = currentUser || {};
  // const {
  //   imageUrls,
  //   description,
  //   brand,
  //   name,
  //   gender,
  //   createdAt,
  //   color,
  //   inSoldPending,
  //   inUserFavorites,
  //   _id,
  //   price,
  //   condition,
  //   category,
  //   deliveryType,
  //   yearOfPurchase,
  //   negotiablePrice,
  //   location,
  //   sellerAddress,
  //   createdBy,
  //   seller,
  //   isRenewed,
  // } = lot || {};
  const {
    additionalImageLinks,
    description,
    brand,
    title,
    gender,
    createdAt,
    color,
    inSoldPending,
    inUserFavorites,
    _id,
    price,
    condition,
    category,
    deliveryType,
    yearOfPurchase,
    negotiablePrice,
    location,
    sellerAddress,
    createdBy,
    seller,
    isRenewed,
  } = partnerProduct || {};
  const { lots } = allLots || { lots: [] };
  const {
    _id: sellerId,
    fullName,
    photo,
    activeLotsCount,
    soldLotsCount,
    createdAt: SellerCreatedAt,
  } = seller || {};
  const { name: sellerName } = sellerAddress || { sellerName: "" };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const messageSellerFailure = () => {};
  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  const [shippingAddress, setShippingAddress] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");

  useEffect(() => {
    {
      if (
        currentUser &&
        currentUser.addresses &&
        currentUser.addresses.length &&
        localStorage.getItem("currentAddressID")
      ) {
        currentUser.addresses.map((deliveryAddress) => {
          if (deliveryAddress._id == localStorage.getItem("currentAddressID")) {
            setShippingAddress(deliveryAddress);
          }
        });
      }
    }
  }, [localStorage.getItem("currentAddressID")]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("deliveryMethod")).length == 2) {
      setDeliveryMethod("both");
    } else {
      if (
        JSON.parse(localStorage.getItem("deliveryMethod"))[0] ==
        "Nationwide Shipping"
      ) {
        setDeliveryMethod("shipping");
      } else if (
        JSON.parse(localStorage.getItem("deliveryMethod"))[0] == "Local Pick Up"
      ) {
        setDeliveryMethod("meetup");
      }
    }
  }, []);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const editRedirect = () => {
    history.go(-1);
  };
  const [showcancelDialog, setShowcancelDialog] = useState(false);
  const uploadProduct = () => {
    var data = {
      address: null,
      age: localStorage.getItem("age"),
      brand: localStorage.getItem("activeBrand"),
      color: localStorage.getItem("activeColor"),
      // carrier:
      // carrierAccountId:
      category: localStorage.getItem("activeCategory"),
      condition: localStorage.getItem("activeCondition"),
      costPrice: parseInt(localStorage.getItem("retailPrice") * 100 || 0),
      deliveryType: deliveryMethod,
      description: localStorage.getItem("productDescription"),
      gender: localStorage.getItem("activeGender"),
      imageUrls: JSON.parse(localStorage.getItem("ImageLinks")),
      isFree: localStorage.getItem("Pay") == "yes" ? true : false,
      // isMarkidFee:
      // location:
      // locationPoint:
      price: parseInt(localStorage.getItem("sellingPrice") * 100),
      name: localStorage.getItem("productTitle"),
      negotiablePrice:
        localStorage.getItem("negotiablePrice") == "yes" ? true : false,
      parcelId: parcelID || "",
      sellerAddress: shippingAddress,
      shippingPrice: parseInt(
        localStorage.getItem("estimatedCharges") * 100 || 0
      ),
      shippingWeight: localStorage.getItem("activeWeight"),
      size: localStorage.getItem("size"),
      // userBeamDonation:
      yearOfPurchase: localStorage.getItem("activepurchasedYear"),
      zipCode: shippingAddress.zip,
    };
    dispatch(createLot(data, successCallBack, failureCallBack));
  };
  const successCallBack = () => {
    setShowConfirmDialog(true);
  };
  const failureCallBack = () => {};
  const handleCancelDialog = () => {
    setShowcancelDialog(true);
  };
  const forSellerUser = createdBy === currentUserId;
  return (
    <>
      <div>
        <div className="pDetailBackground">
          <Row gutter={[0, { xs: 18, sm: 18, md: 29, lg: 29, xl: 29 }]}>
            <Col span={24}></Col>
            <Col
              xs={{ span: 20, offset: 1 }}
              sm={{ span: 20, offset: 1 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
            ></Col>
          </Row>
          <div className="websellerProductDetailsWrapper">
            <div className="app-wrapper">
              <Row
                gutter={[0, { xs: 50, sm: 50, md: 76, lg: 76, xl: 76 }]}
                justify="start"
              >
                <Col span={24}></Col>
                <Col xl={{ span: 13, offset: 2 }} xs={{ span: 22, offset: 1 }}>
                  <Row>
                    <Col span={24}></Col>
                    <Col offset={0}>
                      <Row gutter={[28, 0]} style={{ marginBottom: "30px" }}>
                        <Col>
                          <Row
                            gutter={[
                              0,
                              { xs: 5, sm: 5, md: 8, lg: 8, xl: 8, xxl: 8 },
                            ]}
                          >
                            <Col span={24}></Col>
                            <ArrowLeftOutlined
                              className="productDetailsUploadBackIcon"
                              onClick={() => history.goBack()}
                            />
                          </Row>
                        </Col>
                        <Col className="productDetailsUploadTitle">
                          <h1 className="afterBackIconTitle web">
                            Preview & Publish
                            {/* <span className="saveasdraftbutton">SAVE AS DRAFT</span> */}
                          </h1>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Card className="productImageUploadCard">
                    <h2>
                      Product Photos
                      <span
                        className="editWrapper"
                        onClick={(event) => editRedirect(event)}
                      >
                        <img className="editbutton" alt="" src={Edit} />
                        <span className="editbuttontitle">Edit</span>
                      </span>
                    </h2>
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography>
                        The images of the product that you want to sell
                      </Typography>
                    </Row>
                    <Upload
                      action={process.env.REACT_APP_DOMAIN}
                      listType="picture-card"
                      fileList={JSON.parse(
                        localStorage.getItem("imagesUploaded")
                      )}
                      className="uploadImage"
                      showUploadList={{
                        showPreviewIcon: false,
                        showRemoveIcon: false,
                      }}
                    ></Upload>
                  </Card>
                </Col>
                <Col xl={{ span: 13, offset: 2 }} xs={{ span: 22, offset: 1 }}>
                  <Card className="pRCollapseCard">
                    <Row
                      gutter={[
                        0,
                        { xs: 20, sm: 20, md: 25, lg: 25, xl: 25, xxl: 25 },
                      ]}
                    >
                      {/* <Col span={24}></Col> */}
                      <Col span={24}>
                        <Title level={3} className="pLDesTitle">
                          Product details
                          <span
                            span
                            className="editWrapper"
                            onClick={(event) => editRedirect(event)}
                          >
                            <img className="editbutton" alt="" src={Edit} />
                            <span className="editbuttontitle">Edit</span>
                          </span>
                        </Title>
                      </Col>

                      <Col>
                        {/* {" "} */}
                        <Row
                          gutter={[
                            0,
                            { xs: 20, sm: 20, md: 0, lg: 0, xl: 0, xxl: 0 },
                          ]}
                        >
                          <Typography className="pLDes">
                            {capitalize(
                              "The details of items that you want to sell"
                            )}
                          </Typography>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        color: "#707070",
                        margin: isXS ? "20px 0px" : "40px 0px",
                      }}
                    />
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div header={<Text className="pLPanelTitle"> </Text>}>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev"> Listing Title</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {localStorage.getItem("productTitle")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row">
                              <div className="preprev">
                                Description of the product
                              </div>
                            </Col>
                            <Col className="gutter-row">
                              <div className="predesc">
                                {localStorage.getItem("productDescription")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev"> Category</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {humanize(
                                  localStorage.getItem("activeCategory")
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">Brand</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {localStorage.getItem("activeBrand")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">Year of Manufacture</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {localStorage.getItem("activeManufacturedYear")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">Color of the product</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {localStorage.getItem("activeColor")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">
                                Who can use this item(Gender)
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {localStorage.getItem("activeGender")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">
                                Conditon of the product
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {localStorage.getItem("activeCondition")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">Purchased Year</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {localStorage.getItem("activepurchasedYear")}
                              </div>
                            </Col>
                          </Row>
                          {localStorage.getItem("age") != "" ? (
                            <Row gutter={16}>
                              <Col className="gutter-row" span={8}>
                                <div className="rprev">Age Group</div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div className="rdesc">
                                  {localStorage.getItem("age")}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <></>
                          )}
                          {localStorage.getItem("retailPrice") && (
                            <Row gutter={16}>
                              <Col className="gutter-row" span={8}>
                                <div className="rprev">Retail Price</div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div className="rdesc">
                                  ${localStorage.getItem("retailPrice")}
                                </div>
                              </Col>
                            </Row>
                          )}

                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">Selling Price</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                ${localStorage.getItem("sellingPrice")}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">
                                Is this price negotiable
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div
                                className="rdesc"
                                style={{ textTransform: "capitalize" }}
                              >
                                {localStorage.getItem("negotiablePrice")}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xl={{ span: 13, offset: 2 }} xs={{ span: 22, offset: 1 }}>
                  <Col xl={{ span: 6, offset: 1 }}></Col>

                  <div
                    style={{
                      color: "#707070",
                      margin: isXS ? "20px 0px" : "40px 0px",
                    }}
                  />
                  <Card>
                    <Row
                      gutter={[
                        0,
                        { xs: 20, sm: 20, md: 25, lg: 25, xl: 25, xxl: 25 },
                      ]}
                    >
                      {/* <Col span={24}></Col> */}
                      <Col span={24}>
                        <Title level={3} className="pLDesTitle">
                          Shipping details
                          <span
                            span
                            className="editWrapper"
                            onClick={(event) => editRedirect(event)}
                          >
                            <img className="editbutton" alt="" src={Edit} />
                            <span className="editbuttontitle">Edit</span>
                          </span>
                        </Title>
                      </Col>

                      <Col>
                        {/* {" "} */}
                        <Row
                          gutter={[
                            0,
                            { xs: 20, sm: 20, md: 0, lg: 0, xl: 0, xxl: 0 },
                          ]}
                        >
                          {isGetPartnerProductByIdLoading ? (
                            <Skeleton.Button
                              active={isGetPartnerProductByIdLoading}
                              size="small"
                            />
                          ) : (
                            <Typography className="pLDes">
                              {capitalize(
                                "The address where you product is located"
                              )}
                            </Typography>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div header={<Text className="pLPanelTitle"> </Text>}>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev"> Delivery Method</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {
                                  JSON.parse(
                                    localStorage.getItem("deliveryMethod")
                                  )[0]
                                }
                                {JSON.parse(
                                  localStorage.getItem("deliveryMethod")
                                )[1]
                                  ? `, ${
                                      JSON.parse(
                                        localStorage.getItem("deliveryMethod")
                                      )[1]
                                    }`
                                  : ""}
                              </div>
                            </Col>
                          </Row>
                          {JSON.parse(localStorage.getItem("deliveryMethod"))
                            .length > 1 ||
                          JSON.parse(
                            localStorage.getItem("deliveryMethod")
                          )[0] == ["Nationwide Shipping"] ? (
                            <>
                              <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                  <div className="rprev">
                                    {" "}
                                    Size of the package in inch
                                  </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                  <div className="rdesc">
                                    {`${localStorage.getItem(
                                      "activeLength"
                                    )}" (L) x ${localStorage.getItem(
                                      "activeWidth"
                                    )}" (B) x ${localStorage.getItem(
                                      "activeHeight"
                                    )}" (H)`}
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                  <div className="rprev">Weight of product</div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                  <div className="rdesc">
                                    {localStorage.getItem("activeWeight")}{" "}
                                    <span
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      lbs
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                  <div className="rprev">
                                    Estimated Shipping Charges
                                  </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                  <div className="rdesc">
                                    ${localStorage.getItem("estimatedCharges")}
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                  <div className="rprev">
                                    Will you offer free shipping?
                                  </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                  <div className="rdesc">
                                    {localStorage.getItem("Pay")}
                                  </div>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">Address</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {shippingAddress.address
                                  ? `${shippingAddress.address}, `
                                  : ""}
                                {shippingAddress.secondAddress
                                  ? `${shippingAddress.secondAddress}, `
                                  : ""}
                                {shippingAddress.city
                                  ? `${shippingAddress.city}, `
                                  : ""}
                                {shippingAddress.state
                                  ? `${shippingAddress.state}, `
                                  : ""}
                                {shippingAddress.zip
                                  ? `${shippingAddress.zip}`
                                  : ""}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                              <div className="rprev">Phone number</div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div className="rdesc">
                                {shippingAddress.phone
                                  ? shippingAddress.phone
                                  : ""}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Col xl={{ span: 6, offset: 1 }}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Row justify="left" gutter={[0, 0]}>
                    <Col span={24}></Col>
                    <Col>
                      <Button
                        className="previewCancelButton"
                        onClick={handleCancelDialog}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className={"button button-primary previewSaveButton"}
                        onClick={(event) => {
                          uploadProduct(event);
                        }}
                      >
                        Save and continue
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col xl={{ span: 6, offset: 1 }}></Col>
                <Col span={24}></Col>
              </Row>
            </div>
          </div>
        </div>
        <SellerConfirmation showConfirmDialog={showConfirmDialog}currentUser={currentUser} />
        <SellerCancelModal
          showcancelDialog={showcancelDialog}
          setShowcancelDialog={setShowcancelDialog}
        />
      </div>
    </>
  );
};
export default PreviewPublishPage;
