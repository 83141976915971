import React from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "antd";
import "../App.css";

export const PLBreadCrumb = (props) => {
const history = useHistory();
const { markid, category, brand, title } = props
  return (
       <Breadcrumb>

            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            // onClick={() => history.push('/homepage')}
            onClick={() => history.push('/')}
            >
              {markid}
              </Breadcrumb.Item>
    
            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            >
              {category}
              </Breadcrumb.Item>
    
            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            >
              {brand}
              </Breadcrumb.Item>
    
            <Breadcrumb.Item 
            className='pDetailBreadCrumb'
            >
              {title}
            </Breadcrumb.Item>
          </Breadcrumb>
  );
};
