import constants from '../Utils/constants';
const initialState = {
    isGetAllComunitiesLoading: false, communities: []
}

const communitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_COMMUNITIES_REQUEST:
            return {
                ...state, isGetAllComunitiesLoading: true
            }
        case constants.GET_COMMUNITIES_SUCCESS:
            const { community } = action.payload
            return {
                ...state, isGetAllComunitiesLoading: false, communities: community
            }
        case constants.GET_COMMUNITIES_FAILURE:
            return {
                ...state, isGetAllComunitiesLoading: false
            }

        default:
            return state
    }
}
export default communitiesReducer;