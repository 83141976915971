import React from "react";
import { Card, Typography, Row } from "antd";
import "../App.css"
export const ReNewedWorkCard = (props) => {
	const { title, description, cover } = props;
	return (
		<>
		<Card
			cover={cover}
			className="howWorkBlogCard"
		>
			<Row justify='start'>
			<Typography className='hDRWTitle'>{title}</Typography>
			</Row>
            <Typography className='hDRWDec'>{description}</Typography>
		</Card>
		</>
	);
};
