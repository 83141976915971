import React from "react";
import { Modal, Row, Col, Typography, } from "antd";
import NotifySoonImg from "../assets/NotifySoonImg.svg";
export const NotifySoon = (props) => {
  const { showNotifySoonDialog, onCancelNotifySoonMethod, notifyData } = props;
  const { name } = notifyData
  const handleCancel = () => {
    onCancelNotifySoonMethod();
  };
  return (
    <>
      <Modal
        className="notifyModal"
        visible={showNotifySoonDialog}
        onCancel={() => handleCancel()}
        centered
        footer={null}
        width={600}
      >
        <Row justify='center' gutter={[0, 48]}>
          <Col span={24}></Col>
          <Col span={24}></Col>
          <Col>
            <img alt="" src={NotifySoonImg} />
          </Col>
        </Row>

        <Row justify='center' gutter={[0, 29.5]}>
          <Col span={24}></Col>
          <Col>
            <Typography className='nsItemName'>
              Thank you for your inetrest in <span className="productName">{name}</span>.
            </Typography>

            <Row justify='center' gutter={[0, 2]}>
              <Col span={24}></Col>
              <Typography className='nsItemDec'>
                We will notity you soon.
              </Typography>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
