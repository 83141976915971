
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { addCard } from "../Actions/paymentAction";
import {
	Row,
	Col,
	Typography,
	Divider,
	Button,
	Select,
	Input,
	Radio,
	Card,
	Spin,
	Modal,
} from "antd";
import { AppleOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import "../App.css";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { createOrder } from "../Actions/orderAction";
import Vinemo from "../assets/Vinemo.svg";
import GooglePayLogo from "../assets/GooglePayLogo.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
import _ from "lodash";
import { getCards, getPaymentMethod } from "../Actions/paymentAction";
import { postMessageByChatId } from "../Actions/chatsAction";
import profileVisa from "../assets/new-assets/icon-visacard.png";
import MasterCard from "../assets/MasterCard.svg";
import DiscoverCard from "../assets/DiscoverCard.png";
import AmericanExpress from "../assets/AmericanExpress.png";
import { createPartnerOrder } from "../Actions/partnerOrderAction";
import { createStripePayment } from "../Actions/cartAction";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Text, Title } = Typography;
const schema = yup.object().shape({
	// name_on_card: yup.string("Name on card require"),
});
const CheckoutPaymentForm = (props) => {
	const dispatch = useDispatch();
	const { prevMethod, nextMethod, setUsePaymentMethod, callStripeApi, paymentLoader, setPaymentLoader } = props
	const history = useHistory();
	const { isXS } = useBreakpoints();
	const { innerWidth } = window;
	const { latestClientSecret, currentAddressId, finalshippingprice, currentUser, currentCart, lastCart, currentCartId, useCredits, balance, cards, isGetCardsLoading, orderDetails, partnerProductShippingPrice, p2pdiscountAmount, discountAmount, availPromotionsCheck } = useSelector((state) => {
		const { latestClientSecret, currentAddressId, currentCart, lastCart, currentCartId, useCredits, currentUserWallet, partnerProductShippingPrice, discountAmount, finalshippingprice, availPromotionsCheck, p2pdiscountAmount } = state.cart;
		const { currentUser } = state.user;
		const { cards, isGetCardsLoading } = state.payment;
		const { balance } = currentUserWallet;
		const { orderDetails } = state.order;
		return {
			latestClientSecret,
			currentAddressId,
			currentUser,
			p2pdiscountAmount,
			currentCart,
			currentCartId,
			currentUserWallet,
			useCredits,
			balance,
			cards,
			finalshippingprice,
			isGetCardsLoading,
			orderDetails,
			partnerProductShippingPrice,
			discountAmount,
			availPromotionsCheck,
			lastCart
		};
	});


	const [stripePaymentValue, setStripePaymentValue] = useState(0);
	const [cartTotalWithShipping, setCartTotalWithShipping] = useState(0);


	const grouped = groupBy(currentCart, currentCart => currentCart.productType);

	let groupP2P = grouped.get("P2P")
	let groupPartnerProducts = grouped.get("Partner");
	let valueAllItemTotalWithShipping = 0;
	useEffect(() => {
		if (groupPartnerProducts) {

			valueAllItemTotalWithShipping = parseFloat(parseFloat(itemTotalVal)
				+ parseFloat(shippingVal)
				+ parseFloat((partnerProductShippingPrice.shipmentCost) / 100 || 0)
				- parseFloat(((availPromotionsCheck == "both" || availPromotionsCheck == "partner") && discountAmount > 0) ? (discountAmount / 100).toFixed(2) : 0));
		}
		else {
			valueAllItemTotalWithShipping = parseFloat(parseFloat(itemTotalVal)
				+ parseFloat(shippingVal)
				+ parseFloat((partnerProductShippingPrice.shipmentCost) / 100 || 0));

		}

	}, [groupPartnerProducts])

	let itemTotalVal = (_.sumBy(currentCart, function (o) { return o.price; }) / 100).toFixed(2)
	let shippingVal = (_.sumBy(currentCart, function (o) { return o.isFree === false ? (parseFloat(o.shippingPrice) || 0) : 0; }) / 100).toFixed(2)


	useEffect(() => {
		// const value = useCredits ? (balance > numberItemTotalValue ? 0.0 : itemTotalVal - balance): itemTotalVal
		if (availPromotionsCheck == "partner") {

			valueAllItemTotalWithShipping = parseFloat(parseFloat(itemTotalVal)
				+ parseFloat(shippingVal)
				+ parseFloat((partnerProductShippingPrice.shipmentCost) / 100 || 0)
				- parseFloat(((availPromotionsCheck == "partner") && discountAmount > 0) ? (discountAmount / 100).toFixed(2) : 0));
		}
		else if (availPromotionsCheck == "p2p") {
			valueAllItemTotalWithShipping = parseFloat(parseFloat(itemTotalVal)
				+ parseFloat(shippingVal)
				+ parseFloat((partnerProductShippingPrice.shipmentCost) / 100 || 0)
				- parseFloat(((availPromotionsCheck == "p2p") && p2pdiscountAmount > 0) ? (p2pdiscountAmount / 100).toFixed(2) : 0))
		}
		else if (availPromotionsCheck == "both") {
			valueAllItemTotalWithShipping = parseFloat(parseFloat(itemTotalVal)
				+ parseFloat(shippingVal)
				+ parseFloat((partnerProductShippingPrice.shipmentCost) / 100 || 0)
				- parseFloat(((availPromotionsCheck == "both") && p2pdiscountAmount > 0) ? (p2pdiscountAmount / 100).toFixed(2) : 0)
				- parseFloat(((availPromotionsCheck == "both") && discountAmount > 0) ? (discountAmount / 100).toFixed(2) : 0));
		}
		else {
			valueAllItemTotalWithShipping = parseFloat(parseFloat(itemTotalVal)
				+ parseFloat(shippingVal)
				+ parseFloat((partnerProductShippingPrice.shipmentCost) / 100 || 0))
		}
		setCartTotalWithShipping(valueAllItemTotalWithShipping);
		const value = useCredits ? (balance > valueAllItemTotalWithShipping ? 0.0 : valueAllItemTotalWithShipping - balance) : valueAllItemTotalWithShipping;
		setStripePaymentValue(value);
		if (value > 0.0) {
			const valueToCent = value * 100;
			dispatch(createStripePayment({ amount: parseInt(valueToCent) }))
		}
	}, [valueAllItemTotalWithShipping]);
	const [partherShippingPrice, setPartherShippingPrice] = useState(0)
	const [shipToAddress, setShipToAddress] = useState()

	useEffect(() => {
		dispatch(getCards())
		dispatch(getPaymentMethod())
		setPartherShippingPrice(partnerProductShippingPrice)
	}, []);

	useEffect(() => {
		if (currentUser && currentUser.addresses && currentUser.addresses.length && currentAddressId) {
			const streetAdressArray = []
			currentUser.addresses.map((deliveryAddress) => {
				if (deliveryAddress._id == currentAddressId) {
					streetAdressArray.push(deliveryAddress.address)
					deliveryAddress.secondAddress ? streetAdressArray.push(deliveryAddress.secondAddress) : ''
					setShipToAddress({ recipientName: deliveryAddress.name, streetAddress: streetAdressArray, locality: deliveryAddress.city, region: deliveryAddress.state, country: "USA", postalCode: deliveryAddress.zip })

				}
			})
		}
	}, [currentUser.addresses, currentAddressId])

	function groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}


	const [callPartnerOrderAPI, setCallPartnerOrderAPI] = useState(false)
	const [stripeChargeID, setStripeChargeID] = useState()
	const [p2pPrice, setp2pPrice] = useState()

	const productData = {}
	const [groupPrice, setGroupPrice] = useState()
	const [netPrice, setnetPrice] = useState()
	let cartList = []

	let group = currentCart.reduce((r, a) => { r[a.createdBy] = [...r[a.createdBy] || [], a]; return r; }, {});

	useEffect(() => {
		if (group) {
			Object.keys(group).forEach(element => {
				setGroupPrice(group[element].reduce((a, b) => { return a + b.price }, 0))
			})
		}
	}, [group])

	useEffect(() => {
		if ((discountAmount > 0) && (p2pdiscountAmount > 0)) {
			dispatch({ type: 'FIRST_PURCHASE', payload: true })

		}
	}, [])

	useEffect(() => {
		if (callPartnerOrderAPI && orderDetails) {
			if (groupP2P && groupPartnerProducts && orderDetails) {
				Object.keys(group).forEach(element => {
					var item = {
						merchantId: element,
						markidOrderId: (group[element][0].productType == "P2P" && orderDetails._id) ? orderDetails._id : " ",
						buyerId: currentUser._id,
						currency: "usd",
						lineItems: group[element],
						shippingCost: (group[element][0].productType == "P2P") ? parseInt(group[element][0].shippingPrice) : (partherShippingPrice.shipmentCost || 0),
						stripeChargeId: stripeChargeID,
						phoneNumber: currentUser.phone,
						customer: { fullName: currentUser.fullName, invoiceReceivingEmail: currentUser.email },
						deliveryDetails: shipToAddress,
						netPrice: parseInt(group[element].reduce((a, b) => { return a + b.price }, 0)) + parseInt((group[element][0].productType == "P2P") ? parseInt(group[element][0].shippingPrice) : (partherShippingPrice.shipmentCost || 0)) - parseInt((group[element][0].productType == "P2P") ? parseInt((p2pdiscountAmount > 0) ? p2pdiscountAmount : 0) : parseInt((discountAmount > 0) ? discountAmount : 0)),

					}
					cartList.push(item)
				})
				let cartItems = { entries: cartList }

				dispatch(createPartnerOrder(cartItems,(p2pdiscountAmount>0&&discountAmount>0)? successCallBackPartnerwithP2Pfirst:successCallBackPartnerwithP2P, failureCallBack));
				dispatch({ type: "RESET_CART" })
				//   nextMethod()
			}
			else if (groupP2P && orderDetails) {
				Object.keys(group).forEach(element => {
					var item = {
						merchantId: element,
						markidOrderId: (group[element][0].productType == "P2P" && orderDetails._id) ? orderDetails._id : " ",
						buyerId: currentUser._id,
						currency: "usd",
						lineItems: group[element],
						shippingCost: parseInt(group[element][0].shippingPrice) || 0,
						stripeChargeId: stripeChargeID,
						phoneNumber: currentUser.phone,
						customer: { fullName: currentUser.fullName, invoiceReceivingEmail: currentUser.email },
						deliveryDetails: shipToAddress,
						netPrice:cartTotalWithShipping*100,
					}
					cartList.push(item)
				})
				let cartItems = { entries: cartList }
				dispatch(createPartnerOrder(cartItems,(p2pdiscountAmount>0)? successCallBackfirst:successCallBack, failureCallBack));
				dispatch({ type: "RESET_CART" })
				// nextMethod()
			}
		}
	}, [callPartnerOrderAPI, orderDetails])


	useEffect(() => {
		_.forEach(cards, function (value) {
			if (value.isDefault) {
				setSelectedCard(value.id)
				setCDCForm(false)
			}
		})
		if (cards.length === 0) {
			setUsePaymentMethod(false)
		}
	}, [cards]);

	useEffect(() => {
		if (callStripeApi === true) {
			payWithCard(stripe)
		}
	}, [callStripeApi]);
	const [error, setError] = useState({ isVisible: false });
	const [showError, setShowError] = useState("")
	const [cdcForm, setCDCForm] = useState(
		cards && cards.length <= 0 ? true : false
	);
	const [value, setValue] = useState(1);
	const [selectedCard, setSelectedCard] = useState("");
	const { handleSubmit, control, errors } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name_on_card: '', card_number: '', mm: '', yy: '', cvv: '' }
	});
	const stripe = useStripe();
	const elements = useElements();

	const closeModal = () => {
		setError({ isVisible: false })
		setPaymentLoader(false)
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
	}

	const handleAddCard = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make  sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card, {
			name: name, address_line1: 'Address 1', address_line2: 'Address 2',
			address_city: 'San Fransisco', address_state: 'Calefonia', address_zip: '94103', address_country: 'US'
		});

		if (result.error) {
			// Show error to your customer.
			setStripeErrorMessage(result.error.message)
		} else {
			// Send the token to your server.
			// This function does not exist yet; we will define it in the next step.
			//   stripeTokenHandler(result.token);
			dispatch(addCard({ cardToken: result.token.id }, successAddCard(event), faliureAddCard));
		}
	};
	const successAddCard = (event) => {
		handleStripeSubmit(event)
		//setCDCForm(false)
		//dispatch(getCards())
	}
	const faliureAddCard = () => { }
	const handleStripeSubmit = async (event) => {

		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: true } })
		setPaymentLoader(true)
		// Block native form submission.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);
		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		if (error) {
			setPaymentLoader(false)
			// { error }
			// console.log('[error]', error);
			setError({ ...error, isVisible: true });
			dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		} else {
			setPaymentLoader(false)
			// { paymentMethod }
			// console.log('[PaymentMethod]', paymentMethod);
			dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		}
		payWithCard(stripe, paymentMethod, cardElement)
	};

	const payWithCard = (stripe, paymentMethod, cardElement) => {
		if (stripePaymentValue > 0) {
			try {
				dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: true } })
				stripe.confirmCardPayment(latestClientSecret, { payment_method: paymentMethod ? { card: cardElement } : selectedCard }).then((result) => {
					if (result.error) {
						setPaymentLoader(false)
						setError({ ...error, isVisible: true });
						dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
					}
					else {
						// { result }
						// console.log({result})
						const data = {}
						data.cartId = currentUser.cartId
						data.stripePiId = latestClientSecret
						data.addressId = currentAddressId
						data.buyerUserId = currentUser._id
						data.buyerEmail = currentUser.email
						data.buyerPhone = currentUser.phone
						data.orderTotalInCents = result.paymentIntent.amount
						//data.orderTotalInCents = useCredits ? (balance > valueAllItemTotalWithShipping ? 0.0 : valueAllItemTotalWithShipping - balance) *100: valueAllItemTotalWithShipping*100
						// data.orderNumber = generateRandomString
						// data.usedMarkidCreditInCents = useCredits ? balance - orderTotalInCents : 0.0
						data.usedMarkidCredit = useCredits
						data.lotItems = _.map(groupP2P, '_id');
						data.paymentMethodType = result.paymentIntent.payment_method_types[0]
						data.stripeChargeId = result.paymentIntent.id
						if (currentCart && currentCart.length > 0 && currentCart[0].updateLotInChatConfig && currentCart[0].updateLotInChatConfig.offerId) {
							data.offerId = currentCart[0].updateLotInChatConfig.offerId;
						}
						if (groupPartnerProducts && groupP2P) {
							dispatch(createOrder(data, successCallBackPartner, failureCallBack));
							setStripeChargeID(result.paymentIntent.id)
							// setCallPartnerOrderAPI(true)
						}
						else if (groupPartnerProducts) {
							Object.keys(group).forEach(element => {
								var item = {
									merchantId: element,
									markidOrderId: ' ',
									buyerId: currentUser._id,
									currency: "usd",
									lineItems: group[element],
									shippingCost: parseInt(partherShippingPrice.shipmentCost) || 0,
									stripeChargeId: result.paymentIntent.id,
									phoneNumber: currentUser.phone,
									customer: { fullName: currentUser.fullName, invoiceReceivingEmail: currentUser.email },
									deliveryDetails: shipToAddress,
									netPrice: parseInt(group[element].reduce((a, b) => { return a + b.price }, 0)) + parseInt(partherShippingPrice.shipmentCost) - parseInt((discountAmount > 0) ? discountAmount : 0),
								}
								cartList.push(item)
							})
							let cartItems = { entries: cartList }
							dispatch(createPartnerOrder(cartItems, successCallBack, failureCallBack));
						}
						else {
							dispatch(createOrder(data, successCallBackPartner, failureCallBack));
							setStripeChargeID(result.paymentIntent.id)
							setp2pPrice(result.paymentIntent.amount)
						}
						// nextMethod()
					}
				}).catch(error => {
					// console.log('check 157', error);
					// Figure out how general errors and handled, how the user is notified and handle error
					setError({ isVisible: true, message: 'Make sure you\'ve entered the correct details and try again later.' });
					setPaymentLoader(false)
				});

			} catch (error) {
				// Figure out how general errors and handled, how the user is notified and handle error
				// history.goBack();
				setError({ isVisible: true, message: 'Make sure you\'ve entered the correct details and try again later.' });
				setPaymentLoader(false)
			}
		}
		else {
			// { result }
			// console.log({result})
			const data = {}
			data.cartId = currentUser.cartId
			data.stripePiId = latestClientSecret
			data.addressId = currentAddressId
			data.buyerUserId = currentUser._id
			data.buyerEmail = currentUser.email
			data.buyerPhone = currentUser.phone
			data.orderTotalInCents = cartTotalWithShipping * 100
			//data.orderTotalInCents = useCredits ? (balance > valueAllItemTotalWithShipping ? 0.0 : valueAllItemTotalWithShipping - balance) *100: valueAllItemTotalWithShipping*100
			// data.orderNumber = generateRandomString
			// data.usedMarkidCreditInCents = useCredits ? balance - orderTotalInCents : 0.0
			data.usedMarkidCredit = useCredits
			data.lotItems = _.map(groupP2P, '_id');
			data.paymentMethodType = "credits"
			data.stripeChargeId = null
			if (currentCart && currentCart.length > 0 && currentCart[0].updateLotInChatConfig && currentCart[0].updateLotInChatConfig.offerId) {
				data.offerId = currentCart[0].updateLotInChatConfig.offerId;
			}
			if (groupPartnerProducts && groupP2P) {
				dispatch(createOrder(data, successCallBackPartner, failureCallBack));
				setStripeChargeID(null)
				// setCallPartnerOrderAPI(true)
			}
			else if (groupPartnerProducts) {
				Object.keys(group).forEach(element => {
					var item = {
						merchantId: element,
						markidOrderId: ' ',
						buyerId: currentUser._id,
						currency: "usd",
						lineItems: group[element],
						shippingCost: parseInt(partherShippingPrice.shipmentCost) || 0,
						stripeChargeId: null,
						phoneNumber: currentUser.phone,
						customer: { fullName: currentUser.fullName, invoiceReceivingEmail: currentUser.email },
						deliveryDetails: shipToAddress,
						netPrice: cartTotalWithShipping * 100,
					}
					cartList.push(item)
				})
				let cartItems = { entries: cartList }
				dispatch(createPartnerOrder(cartItems, successCallBack, failureCallBack));
			}
			else {
				dispatch(createOrder(data, successCallBackPartner, failureCallBack));
				setStripeChargeID(null)
				setp2pPrice(result.paymentIntent.amount)
			}
			// nextMethod()
		}

	}
	const successCallBackfirst = () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		updateLotsChatWithPaymentDoneMessage();
		history.push('/success/checkout/firstpurchase');
	};
	const successCallBack = () => {

		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		updateLotsChatWithPaymentDoneMessage();
		dispatch({ type: "RESET_CART" })
		history.push('/success/checkout');



		//window.location.reload(false);
	};
	const failureCallBack = () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
	};
	const successCallBackPartner = () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		updateLotsChatWithPaymentDoneMessage();
		setCallPartnerOrderAPI(true)
		dispatch({ type: "RESET_CART" })
	};
	const successCallBackPartnerwithP2P = () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		updateLotsChatWithPaymentDoneMessage();
		//dispatch({ type: "RESET_CART" })
		history.push('/success/checkout');
		//window.location.reload(false);
	};
	const successCallBackPartnerwithP2Pfirst= () => {
		dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
		updateLotsChatWithPaymentDoneMessage();
		//dispatch({ type: "RESET_CART" })
		history.push('/success/checkout/firstpurchase');
		//window.location.reload(false);
	};
	const failureCallBackPartnerwithP2P = () => {

	};
	const updateLotsChatWithPaymentDoneMessage = () => {
		if (currentCart && currentCart.length > 0) {
			currentCart.forEach((cartItem) => {
				if (cartItem.updateLotInChatConfig) {
					//Make update chat api call
					dispatch(postMessageByChatId(cartItem.updateLotInChatConfig.chatId, {
						message: 'Payment made successfully',
						lastMessageId: cartItem.updateLotInChatConfig.chatLastAcceptedOfferMessageId,
						type: 10,
					}))
				}
			})
		}
	}
	const CARD_OPTIONS = {
		iconStyle: "solid",
		style: {
			base: {
				fontWeight: 500,
				fontFamily: "Montserrat, Open Sans, Segoe UI, sans-serif",
				fontSize: "16px"
			},
		}
	};
	const defaultCardId = _.forEach(cards, function (value) {

		// const defaultCard = _.filter(value, )
	})
	const onChangeCard = (cardId) => {
		setSelectedCard(cardId)
		setCDCForm(false)
		setUsePaymentMethod(true)
	}
	const handleOpenCardForm = () => {
		setSelectedCard("")
		setCDCForm(true)
		setUsePaymentMethod(false)
	}
	useEffect(() => {
		if (error.isVisible && error.message) {
			setShowError(error.message)
		}
	}, [error])

	return (
		<>
			<Row gutter={[0, 0]} className="paymentCard">
				<Col span={24}></Col>
				{cards && cards.map((val, i) => {
					return (
						<Col span={24} key={i} className="savedCardss">
							<Card className='shoppingCartCard '>
								<Row justify='space-between'>
									<Col>
										<Row gutter={[{ xl: 20, lg: 20, md: 20, xs: 0, sm: 0 }, 0]}>
											<Col className="payments">
												<Radio.Group onChange={() => onChangeCard(val.id)} value={selectedCard}>
													<Radio className='payRedioText' value={val.id}>
														Credit card ending in {val.last4}
													</Radio>
												</Radio.Group>
												{val.isDefault &&
													<Button className="btn-default">Default</Button>
												}
											</Col>

										</Row>
									</Col>
									<Col className="cardImgs">
										{val.brand === "Visa" &&
											<img alt="" src={profileVisa} />
										}
										{val.brand === "MasterCard" &&
											<img alt="" src={MasterCard} style={{ width: 64, height: 22 }} />
										}
										{val.brand === "Discover" &&
											<img alt="" src={DiscoverCard} style={{ width: 64, height: 22 }} />
										}
										{val.brand === "American Express" &&
											<img alt="" src={AmericanExpress} style={{ width: 64, height: 22 }} />
										}
									</Col>
								</Row>
							</Card>
						</Col>
					);
				})}
			</Row>

			{/* <Row gutter={[0, 25]}>
                <Col span={24}></Col>
                <Col span={24}>
                    <Card className='shoppingCartCard'>
                        <Row justify='space-between'>
                            <Col>
                                <Radio className='payRedioText'>
                                    Google pay
                                </Radio>
                            </Col>
                            <Col>
                                <img alt="" src={GooglePayLogo} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row> */}
			{!cdcForm &&
				<Row gutter={[0, 0]}>
					<Col span={24}></Col>
					<Col>
						<Button className="buttonEdit"
							onClick={() => handleOpenCardForm()}
							icon={<PlusOutlined />}
						>Add new payment method</Button>
					</Col>
				</Row>
			}
			{cdcForm && (
				<Row gutter={[0, { xs: 14, sm: 14, md: 5, lg: 5, xl: 5, xxl: 5 }]} className="paymentCard">
					{/* <Col span={24}></Col> */}
					<Col span={24} className="checkoutPaymentParent">
						<Card className='shoppingCartCard'>
							<Row justify='space-between' className="paymentsTypeTitle">
								<Col>
									<Radio className='paymentsRadio' defaultChecked={1}>
										<span className="paymentType">Debit/Credit card</span>
									</Radio>
								</Col>
								{/* <Col>
                    <img alt="" src={GooglePayLogo}/>
                    </Col> */}
							</Row>
							{/* <Divider /> */}
							<form onSubmit={(e) => handleSubmit(handleAddCard(e))} id="checkoutstripeForm" className="checkoutstripeForm">
								<Row gutter={[0, 0]}>
									<Col span={24}></Col>

									<Col span={24} className="form-elements">
										<Row>
											<Text className='labels card-name'>Name on card</Text>
										</Row>
										<Controller as={Input} name="name" control={control} require='true' />
										{errors.name_on_card && (
											<span style={{ color: "red" }}>
												Name is required
											</span>
										)}
									</Col>
									<Col style={{ width: isXS ? innerWidth - 85 : "30em" }}>
										<CardElement
											options={CARD_OPTIONS}
										// onChange={(e) => {
										// 	setError(e.error);
										// 	setCardComplete(e.complete);
										// }}
										/>
									</Col>
								</Row>
							</form>
						</Card>
						{/* <Button size='large' className='pay-button' onClick={() => setCDCForm(true)}>Credit or Debit card</Button> */}
					</Col>
				</Row>
			)}
			<Modal
				title="Error"
				visible={error.isVisible}
				onOk={() => closeModal()}
				onCancel={() => closeModal()}
				footer={[
					<Button className="squarebutton" key="submit" type="primary" onClick={() => closeModal()}>
						Ok
					</Button>
				]}
			>
				<p>{showError}</p>
			</Modal>
		</>
	);
};
export default CheckoutPaymentForm;