/*eslint-disable*/
import {
  Button,
  Input,
  Card,
  Typography,
  Layout,
  Row,
  Col,
  Space,
  Form,
  icon,
  Steps,
} from "antd";
import { ArrowLeftOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Image } from "antd";
import step1 from "../assets/step1.png";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getOtp } from "../Actions/authAction";
// const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is a required field"),
  // phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
});
const { TextArea } = Input;
const { Text, Title, Paragraph } = Typography;
const { Header, Content, Footer } = Layout;
const Verification = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = React.useState(0);
  const next = () => {
    trigger("email");
    if (email && current === 0) {
      setCurrent(current + 1);
    }
    if (phone && current === 1) {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const methods = useForm({
    resolver: yupResolver(schema),
    validationSchema: schema,
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    watch,
    trigger,
  } = methods;

  const watching = watch();
  const { email, phone } = watching;
  const handleOtp = (data) => {
    dispatch(getOtp(data));
    if (phone && current === 1) {
      setCurrent(current + 1);
    }
  };
  return (
    <>
      <Card>
        <Row gutter={[32, 48]}>
          <Col>
            <ArrowLeftOutlined onClick={() => prev()} />
          </Col>
        </Row>
      </Card>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOtp)}>
          <Row justify="center" gutter={[32, 48]}>
            <Col style={{ display: current === 0 ? "block" : "none" }}>
              <VerifyEmail nextMethod={next} />
            </Col>
          </Row>
          <Row justify="center" gutter={[32, 48]}>
            <Col style={{ display: current === 1 ? "block" : "none" }}>
              <VerifyPhoneNumber nextMethod={next} />
            </Col>
          </Row>
          <Col style={{ display: current === 2 ? "block" : "none" }}>
            <Verified nextMethod={next} />
          </Col>
        </form>
      </FormProvider>
    </>
  );
};
export default Verification;

export const VerifyEmail = (props) => {
  const data = useFormContext();
  const { control, errors } = data;
  const { nextMethod } = props;
  return (
    <>
      <Card bordered={false}>
        <Row justify="center" gutter={[4, 0]}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Text strong style={{ fontSize: '4.2vh' }}>
              Great! We serve at 10001
            </Text>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Text>Lets get your email to list your item</Text>
          </Col>
          </Row>
          <Row justify='center' gutter={[8, 20]}>
            <Col span={24}></Col>
          <Col
            justify="center"
            align="middle"
            display="contents"
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <img
              alt="step1"
              src={step1}
              style={{ maxWidth: "40%", paddingBottom: 12 }}
            />
          </Col>
          <Col span={24}></Col>
        </Row>

        <Row justify='center' gutter={[32, 8]}>
          <Col xl={16} lg={16} md={16} sm={16} xs={16}>
            <Controller
              as={Input}
              style={{ backgroundColor: "#f5f5f5" }}
              name="email"
              control={control}
              placeholder="Email"
            />
            {errors.email && (
              <span style={{ color: "red" }}>Email is required</span>
            )}
          </Col>
        <Col style={{ paddingTop: 5 }}  xl={16} lg={16} md={16} sm={16} xs={16}>
            <Button
              onClick={() => nextMethod()}
              block
              className={'squarebutton'}
            >
              Let's Start
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export const VerifyPhoneNumber = (props) => {
  const data = useFormContext();
  const { control, errors } = data;

  const { nextMethod } = props;
  return (
    <>
      <Card bordered={false}>
        <Row justify="center" gutter={[4, 0]}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Text strong style={{ fontSize: '4.2vh' }}>
              Great! We serve at 10001
            </Text>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Text style={{ fontSize: '2.2vh' }}>Lets get your phone number to list your item</Text>
          </Col>
          </Row>
          <Row justify='center' gutter={[8, 20]}>
            <Col span={24}></Col>
          <Col
            justify="center"
            align="middle"
            display="contents"
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <img
              alt="step1"
              src={step1}
              style={{ maxWidth: "40%", paddingBottom: 12 }}
            />
          </Col>
          <Col span={24}></Col>
        </Row>
        <Row justify='center' gutter={[32, 8]}>
          <Col xl={16} lg={16} md={16} sm={16} xs={16}>
            <Controller
              as={Input}
              style={{ backgroundColor: "#f5f5f5" }}
              name="phone"
              control={control}
              placeholder="Phone Number"
            />
            {errors.phone && (
              <span style={{ color: "red" }}>
                Valid phone number is required
              </span>
            )}
          </Col>
          <Col style={{ paddingTop: 5 }} xl={16} lg={16} md={16} sm={16} xs={16}>
            <Button
            htmlType='submit'
              block
              className={'squarebutton'}
            >
              GET OTP
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export const Verified = (props) => {
  const { nextMethod } = props;
  return (
    <>
      <Card bordered={false}>
      <Row justify="center" gutter={[4,0]}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Text style={{ fontSize: '4.2vh' }} strong>
                  Verify it's you
                </Text>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Text style={{fontSize: '2.2vh'}}>We have sent a six digit code to verify your number</Text>
                </Col>
                <Col span={24}>
                  <Text>+17147073260</Text>
                </Col>
                </Row>
                
                <Row justify='center' gutter={[8, 20]}>
                  <Col span={24}></Col>
              <Col justify="center" align="middle" display="contents" xl={24} lg={24} md={24} sm={24} xs={24}>
                <img
                  alt="step1"
                  src={step1}
                  style={{ maxWidth: "40%", paddingBottom: 12 }}
                />
                {/* <CheckCircleOutlined  style={{maxWidth: '100%'}}/> */}
              </Col>
              <Col span={24}></Col>
              </Row>


                  <Row gutter={[8, 8]} justify="center">
                    <Col xl={1} lg={1} md={1} sm={4} xs={4}>
                      <Input
                        style={{ backgroundColor: "#f5f5f5", textAlign: 'center'  }}
                        defaultValue="5"
                      />
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={4} xs={4}>
                      <Input
                        style={{ backgroundColor: "#f5f5f5", textAlign: 'center'  }}
                        defaultValue="5"
                      />
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={4} xs={4}>
                      <Input
                        style={{ backgroundColor: "#f5f5f5" , textAlign: 'center' }}
                        defaultValue="1"
                      />
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={4} xs={4}>
                      <Input
                        style={{ backgroundColor: "#f5f5f5", textAlign: 'center' }}
                        defaultValue="3"
                      />
                    </Col>
                  </Row>
                <Row justify='center'>
                <Col>
                  <Button type="link" style={{ color: "black" }}>
                    Resend (1:20)
                  </Button>
                </Col>
                </Row>
      </Card>
    </>
  );
};
