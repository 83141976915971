import constants from '../Utils/constants';
const initialState = {
    isGetChatsByLotLoading: false, isGetChatsLoading: false, isGetChatsWithSellerLoading: false, isGetChatByIdLoading: false,
    isGetMessagesByChatIdLoading: false, isPostMessageByChatIdLoading: false, isPostMarkAsSeenByChatIdLoading: false,
    isPostMarkAsRatedByChatIdLoading: false, isDeleteChatByIdLoading: false,
    allChats: [], chatsByLot: [], chatsWithSeller: [], chatById: {}, messagesByChatId: [], currentChat: {}
}

const chatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_CHATS_BY_LOT_ID_REQUEST:
            return {
                ...state, isGetChatsByLotLoading: true
            }
        case constants.GET_CHATS_BY_LOT_ID_SUCCESS:
            return {
                ...state, isGetChatsByLotLoading: false, chatsByLot: action.payload
            }
        case constants.GET_CHATS_BY_LOT_ID_FAILURE:
            return {
                ...state, isGetChatsByLotLoading: false
            }

        case constants.GET_CHATS_REQUEST:
            return {
                ...state, isGetChatsLoading: true
            }
        case constants.GET_CHATS_SUCCESS:
            const chats = action.payload.chats
            return {
                ...state, isGetChatsLoading: false, allChats: action.payload, currentChat: chats.length > 0 ? chats[0] : {}
            }
        case constants.GET_CHATS_FAILURE:
            return {
                ...state, isGetChatsLoading: false
            }

        case constants.GET_CHAT_BY_ID_REQUEST:
            return {
                ...state, isGetChatByIdLoading: true
            }
        case constants.GET_CHAT_BY_ID_SUCCESS:
            return {
                ...state, isGetChatByIdLoading: false, chatById: action.payload
            }
        case constants.GET_CHAT_BY_ID_FAILURE:
            return {
                ...state, isGetChatByIdLoading: false
            }

        case constants.GET_MESSAGES_BY_CHAT_ID_REQUEST:
            return {
                ...state, isGetMessagesByChatIdLoading: true
            }
        case constants.GET_MESSAGES_BY_CHAT_ID_SUCCESS:
            return {
                ...state, isGetMessagesByChatIdLoading: false, messagesByChatId: action.payload
            }
        case constants.GET_MESSAGES_BY_CHAT_ID_FAILURE:
            return {
                ...state, isGetMessagesByChatIdLoading: false
            }

        case constants.GET_CHATS_WITH_SELLER_REQUEST:
            return {
                ...state, isGetChatsWithSellerLoading: true
            }
        case constants.GET_CHATS_WITH_SELLER_SUCCESS:
            return {
                ...state, isGetChatsWithSellerLoading: false, chatsWithSeller: action.payload
            }
        case constants.GET_CHATS_WITH_SELLER_FAILURE:
            return {
                ...state, isGetChatsWithSellerLoading: false
            }

        case constants.DELETE_CHAT_BY_ID_REQUEST:
            return {
                ...state, isDeleteChatByIdLoading: true
            }
        case constants.DELETE_CHAT_BY_ID_SUCCESS:
            return {
                ...state, isDeleteChatByIdLoading: false,
            }
        case constants.DELETE_CHAT_BY_ID_FAILURE:
            return {
                ...state, isDeleteChatByIdLoading: false
            }

        case constants.POST_MESSAGE_BY_CHAT_ID_REQUEST:
            return {
                ...state, isPostMessageByChatIdLoading: true
            }
        case constants.POST_MESSAGE_BY_CHAT_ID_SUCCESS:
            return {
                ...state, isPostMessageByChatIdLoading: false
            }
        case constants.POST_MESSAGE_BY_CHAT_ID_FAILURE:
            return {
                ...state, isPostMessageByChatIdLoading: false
            }

        case constants.POST_MARK_AS_SEEN_BY_CHAT_ID_REQUEST:
            return {
                ...state, isPostMarkAsSeenByChatIdLoading: true
            }
        case constants.POST_MARK_AS_SEEN_BY_CHAT_ID_SUCCESS:
            return {
                ...state, isPostMarkAsSeenByChatIdLoading: false
            }
        case constants.POST_MARK_AS_SEEN_BY_CHAT_ID_FAILURE:
            return {
                ...state, isPostMarkAsSeenByChatIdLoading: false
            }

        case constants.POST_MARK_AS_RATED_BY_CHAT_ID_REQUEST:
            return {
                ...state, isPostMarkAsRatedByChatIdLoading: true
            }
        case constants.POST_MARK_AS_RATED_BY_CHAT_ID_SUCCESS:
            return {
                ...state, isPostMarkAsRatedByChatIdLoading: false
            }
        case constants.POST_MARK_AS_RATED_BY_CHAT_ID_FAILURE:
            return {
                ...state, isPostMarkAsRatedByChatIdLoading: false
            }
            case "SET_CURRENT_CHAT":
                return {
                    ...state, currentChat: action.payload.chat
                }
        default:
            return state
    }
}
export default chatsReducer;