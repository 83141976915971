import {
  Button,
  Typography,
  Row,
  Col,
  Avatar,
  Divider,
  Card,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import React from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import PageHeader from "../components/PageHeader";
import { ConsignmentItem } from "../components/ConsignmentItem";

const { Text } = Typography;
const ConsignmentDetail = () => {
  // const schema = yup.object().shape({
  //   email: yup
  //     .string()
  //     .email("Invalid email address")
  //     .required("Email is a required field"),
  // });

  // const { handleSubmit } = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onSubmit",
  // });

  return (
    <>
      <PageHeader />
      <Card>
      <Row gutter={[8, 24]}>
        <Col span={24}></Col>
        <Col span={24}>
          <Text strong style={{ fontSize: "4.2vh" }}>
            Consignment details
          </Text>
        </Col>
        <Col span={24}></Col>
      </Row>

      <Row justify="center" gutter={[16, 16]}>
        <Col xl={8} lg={8} md={8} sm={20} xs={20}>
          <Card style={{ backgroundColor: "#f6f6f6" }}>
            <Row gutter={[16, 16]}>
              <Col>
                <Text>Items</Text>
              </Col>
              <Col>
                    <EditOutlined />
                  </Col>
                  <Col span={24}></Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card>
                  <ConsignmentItem
                    category="Strollers"
                    brand="UppaBaby"
                    model="Cruz"
                    year="2017"
                  ></ConsignmentItem>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <ConsignmentItem
                    category="Bussinnets"
                    brand="Stokee"
                    model="Snoo"
                    year="2019"
                  ></ConsignmentItem>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xl={8} lg={8} md={8} sm={20} xs={20}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card style={{ backgroundColor: "#f6f6f6" }}>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Text>Collection</Text>
                  </Col>
                  <Col>
                    <EditOutlined />
                  </Col>
                  <Col span={24}></Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card>
                      <Row gutter={[16, 0]}>
                        <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                          <Row>
                          <Avatar shape="square" size={50} />
                          </Row>
                        </Col>
                        <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                          <Row>
                          <Text>
                            Pick up
                          </Text>
                          </Row>
                          <Row>
                            <Col>
                            <Text>
                              7147 Valley Farms Street 
                            </Text>
                            </Col><Col>
                            <Text>
                            Brooklyn, NY 11214
                            </Text>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col> */}
                      </Row>
                      <Divider />

                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Text>Date</Text>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Text>Time</Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Text>12/12/2021</Text>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Text>5:00 PM IST</Text>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card style={{ backgroundColor: "#f6f6f6" }}>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Text>Payout</Text>
                  </Col>
                  <Col>
                    <EditOutlined />
                  </Col>
                  <Col span={24}></Col>
                </Row>
                <Card>
                  <Row align='middle' justify='start' gutter={[16,8]}>
                    <Col >
                      <Avatar shape="square" size={50}>
                        <Text>Venmo</Text>
                      </Avatar>
                    </Col>
                    <Col>
                      <Text>@AliciaJohn</Text>
                    </Col>
                  </Row>
                </Card>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      </Card>
      <Row gutter={[16, 32]} justify="center">
        <Col span={24}></Col>
        <Col>
          <Button
            className={'squarebutton'}
          >
            CONFIRM
          </Button>
        </Col>
        <Col span={24}></Col>
      </Row>
    </>
  );
};
export default ConsignmentDetail;
