/*eslint-disable*/
import React ,{useEffect} from "react";
import { useSelector } from "react-redux";
import {
    Row,
    Col,
    Card,
    Typography
} from "antd";
import CheckoutOrderConfirmation from "../components/CheckoutOrderConfirmation";
import CheckoutOrderDetails from "../components/CheckoutOrderDetails";
import { useHistory } from "react-router-dom";
import CheckoutTopBar from "../components/CheckoutTopBar";

import "../App.css";

const CheckoutConfirmation = () => {
  const history = useHistory();

  useEffect(() => {
      return () => {
  
        if (history.action === "POP") {
           history.replace("/cart");
  
        }
      };
    }, [history])
  return (
    <>
     <CheckoutTopBar current={2}/>
      <div className="checkoutBG">
        <div className="successPage">
          <div className="app-wrapper">
            <Row gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
              <Col span={24}></Col>
              <Col>
                <h1 className="afterBackIconTitle">Order confirmed</h1>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <Row justify="space-between" className="successPageWrap">
                  <Col className="shoppingCart-leftpane">
                    <CheckoutOrderConfirmation />
                  </Col>
                  <Col className="shoppingCart-rightpane">
                    <CheckoutOrderDetails />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default CheckoutConfirmation;
