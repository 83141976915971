import React, { useState } from "react";
import { Row, Col } from "antd";
import "../App.css";
import Carousel from "react-elastic-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import OnboardingImgOne from "../assets/OnboardingImgOne.svg";
import OBImgTwo from "../assets/OBImgTwo.svg";
import { useLocation } from "react-router-dom";
import { VerifyOtpForm } from "./VerifyOtpForm";
import { SendOtpForm } from "./SendOtpForm";
import {SendOtpFormLogin} from "./SendOtpFormLogin";
import { ZipCodeForm } from "./ZipCodeForm";
import { NameAndEmailForm } from "./NameAndEmailForm";
import { ChildrenForm } from "./ChildrenForm";
import { CustomiseExperienceForm } from "./CustomiseExperienceForm";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { OBJoinCommunity } from "./OBJoinCommunity";
import { useDispatch, useSelector } from "react-redux";
import { SendOtpFormLoginMob } from "./SendOtpFormLoginMob";
import { SendOtpFormMob } from "./SendOtpFormMob";
import { Signinmodal } from "./Signinmodal";
const onboardImage = [
  { image: OnboardingImgOne },
  { image: OBImgTwo },
  { image: OnboardingImgOne },
];
export const Onbordinghome = ({ props, match }) => {
  // const {innerHeight, innerWidth} = window;
  const [current, setCurrent] = useState(0);
  const [otp, setOtp] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nameAndEmail, setNameAndEmail] = useState("");
  const handleOtpChange = (otp) => {
    setOtp(otp);
  };
  const { isXS, isSM} = useBreakpoints();
  const { providerToken, loginSignup } = useSelector((state) => {
    const { providerToken, loginSignup } = state.auth;
    return {
      providerToken,
      loginSignup,
    };
  });
  let currentPage = useLocation().pathname.split("/");
  return (
    <Row>
      <Col xl={12} lg={12} md={12} className="onboardingWeb">
        <OnbordingImage />
      </Col>
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={24}
        xs={24}
        className={currentPage[1]===""? "onboardingWeb-rightnew":"onboardingWeb-right"}
      >

        {current === 0 && loginSignup==="signup" && !isXS && (
          <SendOtpForm
            current={current}
            setCurrent={setCurrent}
            otp={otp}
            setOtp={setOtp}
            changeOtp={handleOtpChange}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        )}
         {/* {current === 0 && loginSignup==="signup" && isXS &&(
          <SendOtpFormMob
            current={current}
            setCurrent={setCurrent}
            otp={otp}
            setOtp={setOtp}
            changeOtp={handleOtpChange}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        )} */}
         {current === 0  && isXS &&(
          <Signinmodal
            current={current}
            setCurrent={setCurrent}
            otp={otp}
            setOtp={setOtp}
            changeOtp={handleOtpChange}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        )}

        {/* {current === 0 && loginSignup==="login" && !isXS&&  (
          <SendOtpFormLogin
            current={current}
            setCurrent={setCurrent}
            otp={otp}
            setOtp={setOtp}
            changeOtp={handleOtpChange}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        )} */}
        {/* {current === 0 && loginSignup==="login" && isXS&&  (
          <SendOtpFormLoginMob
            current={current}
            setCurrent={setCurrent}
            otp={otp}
            setOtp={setOtp}
            changeOtp={handleOtpChange}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        )} */}
        {current === 1 && (
          <VerifyOtpForm
            current={current}
            setCurrent={setCurrent}
            otp={otp}
            setOtp={setOtp}
            changeOtp={handleOtpChange}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            match={match}
          />
        )}
        {current === 2 && (
          <NameAndEmailForm
            current={current}
            setCurrent={setCurrent}
            nameAndEmail={nameAndEmail}
            setNameAndEmail={setNameAndEmail}
          />
        )}
        {current === 3 && (
          <ZipCodeForm
            current={current}
            setCurrent={setCurrent}
            zipcode={zipcode}
            setZipcode={setZipcode}
          />
        )}
        {current === 4 && (
          <ChildrenForm current={current} setCurrent={setCurrent} />
        )}

        {current === 5 && (
          <CustomiseExperienceForm current={current} setCurrent={setCurrent} />
        )}
        {current === 6 && (
          <OBJoinCommunity
            current={current}
            setCurrent={setCurrent}
            match={match}
          />
        )}
      </Col>
    </Row>
  );
};

export const OnbordingImage = (props) => {
  const { isXS } = useBreakpoints();
  const { innerHeight, innerWidth } = window;

  const myDots = ({ pages, activePage, onClick }) => {
    return (
      <Row style={{ zIndex: 9 }} className="pager">
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              style={{
                borderRadius: "50%",
                background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                width: 15,
                height: 15,
                margin: isXS ? "40px 5px 0px" : "-20px 5px 10px",
                cursor: "pointer",
              }}
              onClick={() => onClick(page)}
              // active={isActivePage}
            />
          );
        })}
      </Row>
    );
  };
  return (
    // <Row>
    //     <Col span={24}>
    //         <Row gutter={[0, 0]}>
    <Carousel
      itemPadding={[0, 0, 0, 0]}
      itemsToShow={1}
      itemsToScroll={1}
      showArrows={false}
      outerSpacing={0}
      renderPagination={myDots}
      enableAutoPlay={true}
    >
      {onboardImage.map((item, i) => {
        return (
          <div style={{ width: "100%" }}>
            <LazyLoadImage
              effec="blur"
              alt=""
              src={item.image}
              style={{
                objectFit: "cover",
                width: "100%",
                height: innerHeight,
                display: "block",
              }}
            />
          </div>
        );
      })}
      {/* <div style={{backgroundImage: `url(${OnboardingImgOne})`, width: "100%"}} />
                    <LazyLoadImage effec="blur" alt="" src={OnboardingImgOne} 
                      style={{objectFit: 'cover',width:'100%',height:innerHeight, display: "block"}}
                    />
                    <LazyLoadImage effec="blur" alt="" src={OBImgTwo} 
                      style={{objectFit: 'cover',width:'100%',height:innerHeight,  display: "block"}}
                    />
                    <LazyLoadImage effec="blur" alt="" src={OnboardingImgOne}
                      style={{ objectFit: 'cover',width:'100%', height:innerHeight,  display: "block"}}
                    /> */}
    </Carousel>
    //          </Row>
    //       </Col>
    // </Row>
  );
};
