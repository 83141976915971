/*eslint-disable*/
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useGaEvent } from "../hooks/useGaEvent"; 
import {
  Row,
  Col,
  Typography,
  Card,
  Avatar,
  Divider,
  Button,
  Select,
  Input,
  Spin,
  Radio,
  Alert,
} from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import "../App.css";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import {
  addAddress,
  removeAddress,
  updateAddress,
} from "../Actions/userAction";
import EditIcon from "../assets/EditIcon.svg";
import RemoveIcon from "../assets/RemoveIcon.svg";
const { Text, Title, Paragraph } = Typography;
const US_STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export const CheckoutAddress = (props) => {
  const { gaEventClick } = useGaEvent();
  const dispatch = useDispatch();
  const { isXS } = useBreakpoints();
  const { cartCard, setAddressError } = props;
  const history = useHistory();
  const {
    currentUser,
    isAddAddressLoading,
    addresserror,
    currentAddressId,
    isAuthenticated,
  } = useSelector((state) => {
    const { currentUser, isAddAddressLoading, addresserror } = state.user;
    const { currentAddressId } = state.cart;
    const { isAuthenticated } = state.auth;
    return {
      currentUser,
      isAddAddressLoading,
      addresserror,
      currentAddressId,
      isAuthenticated,
    };
  });
  const { addresses } = currentUser || {};
  // const { addresses } = userAddresses.user || {};
  const [addressForm, setAddressForm] = useState(false);
  const [updateAddressForm, setUpdateAddressForm] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {}, [addresserror]);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleAddressChange = (addressId) => {
    dispatch({ type: "SELECT_ADDRESS_ID", payload: { addressId } });
    setAddressError(false);
  };
  // const MyAddresses =  addresses && addresses.length > 0 ? addresses[0] : null;
  // const ItemDescription = [
  //   {
  //     itemImg: <Avatar shape="square" size={100} src={File1} />,
  //     itemName: "Bloom Coco Lounger",
  //   },
  //   {
  //     itemImg: <Avatar shape="square" size={100} src={File2} />,
  //     itemName: "Gymboree Babygirl Dress",
  //   },
  // ]
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      secondAddress: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
    },
  });
  const handleAddAddress = (data) => {
    dispatch(addAddress(data, successCallBackAddress, failureCallBackAddress));
  };
  const handleNewAddressForm = () => {
    setUpdateAddressForm(false);
    setAddressForm(true);
  };
  const handleUpdateAddressForm = (addressId) => {
    setAddressForm(false);
    setUpdateAddressForm(true);
    setSelectedAddressId(addressId);
  };
  const handleRemoveAddress = (addressId) => {
    dispatch(removeAddress(addressId));
  };
  const successCallBackAddress = (addressId) => {
    setAddressForm(false);
    dispatch({ type: "SELECT_ADDRESS_ID", payload: { addressId } });
    reset();
  };
  const failureCallBackAddress = () => {};
  return (
    <>
      {/* <div className="shoppingCartParent test"> */}
      <Card className="shoppingCartCard">
        <Row gutter={[22.64, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
          <Col span={24}></Col>

          {cartCard &&
            cartCard.map((val, i) => (
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                key={i}
                className="checkout-cart-item"
              >
                <Row
                  gutter={[
                    { xs: 8, sm: 8, md: 18, lg: 18, xl: 18, xxl: 18 },
                    0,
                  ]}
                >
                  <Col className="img-wrap">
                    <Avatar
                      shape="square"
                      size={70}
                      src={val.imageUrls?.[0]}
                      className="cartImg"
                    />
                  </Col>
                  <Col className="details-wrap">
                    <Typography className="cEAItemName">{val.name}</Typography>
                    <Row>
                      <Col>
                        {!Number.isInteger(val.price / 100) ? (
                          <Typography className="cEAPrice">
                            ${(val.price / 100).toFixed(2)}
                          </Typography>
                        ) : (
                          <Typography className="cEAPrice">
                            ${Math.trunc(val.price / 100)}
                          </Typography>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {i < cartCard.length - 1 && <Divider className="c-divider" />}
              </Col>
            ))}
        </Row>
      </Card>
      {/* </div> */}
      <Row gutter={[0, { xs: 50, sm: 50, md: 40, lg: 40, xl: 40, xxl: 40 }]}>
        <Col span={24}></Col>
        <Col>
          <Typography className="dAddressText">Delivery Address</Typography>
        </Col>
      </Row>

      {addresses &&
        addresses.map((val, i) => (
          <Row gutter={[0, 14]} key={i}>
            <Col span={24}></Col>
            <Col span={24}>
              <Card className="shoppingCartCard">
                <Row key={i} justify="space-between">
                  <Col span={24}></Col>
                  <Col>
                    <Row gutter={[19, 0]}>
                      <Col span={2} style={{ paddingTop: 5, paddingRight: 20 }}>
                        <Radio
                          defaultValue={val._id === currentAddressId}
                          key={i}
                          onChange={() => handleAddressChange(val._id)}
                          checked={val._id === currentAddressId}
                          className="addressRadio"
                        />
                      </Col>
                      <Col span={20}>
                        <Row gutter={[17, 0]}>
                          <Col>
                            <Typography className="cEAUserName">
                              {val.name}
                            </Typography>
                          </Col>
                          {/* {val._id === currentAddressId &&
                            <Col>
                              <Button className='defaultAddressButton'>
                                Default
                              </Button>
                            </Col>
                          } */}
                        </Row>
                        <Row
                          gutter={[
                            0,
                            { xs: 18, sm: 18, md: 0, lg: 0, xl: 0, xxl: 0 },
                          ]}
                        >
                          <Col span={24} />
                          <Typography className="cEAddress">
                            {val.address}, {val.secondAddress}
                            {val.secondAddress ? "," : ""} {val.city},{" "}
                            {val.state}, {val.zip}
                          </Typography>
                        </Row>
                        <Row
                          gutter={[
                            0,
                            { xs: 6, sm: 6, md: 0, lg: 0, xl: 0, xxl: 0 },
                          ]}
                        >
                          <Col span={24} />
                          <Typography className="cEAddress">
                            Mobile -{" "}
                            <span className="ceAMobileVal">{val.phone}</span>
                          </Typography>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row
                      gutter={[
                        0,
                        { xs: 33, sm: 33, md: 0, lg: 0, xl: 0, xxl: 0 },
                      ]}
                    >
                      <Col span={24} />
                      <Col style={{ paddingLeft: 10 }}>
                        <Button
                          type="text"
                          icon={
                            <img
                              alt=""
                              src={EditIcon}
                              style={{ marginRight: 10 }}
                            />
                          }
                          className="cEAActionText"
                          onClick={() => {
                            gaEventClick('Checkout', 'Edit address'); 
                            handleUpdateAddressForm(val._id)
                          }}
                        >
                          Edit
                        </Button>

                        <Divider type="vertical" />
                        <Button
                          type="text"
                          icon={
                            <img
                              alt=""
                              src={RemoveIcon}
                              style={{ marginRight: 10 }}
                            />
                          }
                          className="cEAActionText"
                          onClick={() => {
                            gaEventClick('Checkout', 'Remove address'); 
                            handleRemoveAddress(val._id)
                          }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ))}

      {!addressForm && !updateAddressForm && (
        <Row gutter={[0, { xs: 25, sm: 25, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
          <Col span={24}></Col>
          <Col>
            <Button
              className="addAddressButton"
              onClick={() => {
                gaEventClick('Checkout', 'Add address'); 
                handleNewAddressForm()
              }}
              type="text"
              icon={<PlusOutlined />}
            >
              Add address
            </Button>
          </Col>
        </Row>
      )}
      {addressForm && (
        <Spin
          indicator={antIcon}
          spinning={isAddAddressLoading}
          style={{ color: "#FF533F" }}
        >
          <Row
            gutter={[0, { xs: 50, sm: 50, md: 40, lg: 40, xl: 40, xxl: 40 }]}
          >
            <Col span={24}></Col>
            <Col>
              <Typography className="addnewAddressText">
                Add new Address
              </Typography>
            </Col>
          </Row>

          <Row gutter={[0, 14]}>
            <Col span={24}></Col>
            <Col span={24} className="shoppingCartParent2">
              <Card className="shoppingCartCard">
                <form onSubmit={handleSubmit(handleAddAddress)}>
                  <Row gutter={[30, 0]}>
                    <Col span={24}></Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row
                        gutter={[
                          0,
                          { xs: 25, sm: 25, md: 30, lg: 30, xl: 30, xxl: 30 },
                        ]}
                      >
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">
                          Name
                        </Typography>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Controller
                          as={Input}
                          name="name"
                          placeholder="Type your name"
                          control={control}
                          size="large"
                          className="addAddressInput"
                          rules={{ required: true }}
                        />
                        {errors.name && errors.name.type === "required" && (
                          <span role="alert" className="error">
                            Name is required
                          </span>
                        )}
                      </Row>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row gutter={[0, 30]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">
                          Phone number
                        </Typography>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Controller
                          as={Input}
                          name="phone"
                          placeholder="Type your phone number"
                          control={control}
                          size="large"
                          className="addAddressInput"
                          rules={{ required: true }}
                        />
                        {errors.phone && errors.phone.type === "required" && (
                          <span role="alert" className="error">
                            Phone numebr is required
                          </span>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  {addresserror && (
                    <Row justify="center" gutter={[0, 8]}>
                      <Col span={24}></Col>
                      <Col>
                        <Alert
                          type="error"
                          onClose={handleClose}
                          open={open}
                          message={addresserror.error.message}
                        ></Alert>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={[30, 8]}>
                    <Col span={24}></Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row gutter={[0, 20]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">
                          Address
                        </Typography>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Controller
                          as={Input}
                          name="address"
                          placeholder="Address line 1"
                          control={control}
                          size="large"
                          className="addAddressInput"
                          rules={{ required: true }}
                        />
                        {errors.address && errors.address.type === "required" && (
                          <span role="alert" className="error">
                            Address is required
                          </span>
                        )}
                      </Row>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row
                        gutter={[
                          0,
                          { xs: 15, sm: 15, md: 52, lg: 52, xl: 52, xxl: 52 },
                        ]}
                      >
                        <Col span={24}></Col>
                        <Controller
                          as={Input}
                          name="secondAddress"
                          placeholder="Address line 2"
                          control={control}
                          size="large"
                          className="addAddressInput"
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    gutter={[8, { xs: 0, sm: 0, md: 8, lg: 8, xl: 8, xxl: 8 }]}
                  >
                    <Col span={24}></Col>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      className="city-block"
                    >
                      <Row gutter={[0, 20]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">
                          City
                        </Typography>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Controller
                          as={Input}
                          name="city"
                          placeholder="Type your city name"
                          control={control}
                          size="large"
                          className="addAddressInput"
                          rules={{ required: true }}
                        />
                        {errors.city && errors.city.type === "required" && (
                          <span role="alert" className="error">
                            City is required
                          </span>
                        )}
                      </Row>
                    </Col>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      className="state-block"
                    >
                      <Row gutter={[0, 20]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">
                          State
                        </Typography>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Controller
                          as={Select}
                          control={control}
                          options={US_STATES}
                          name="state"
                          placeholder="Select your state name"
                          className="stateSelector"
                          style={{ width: "100%" }}
                          size="large"
                          rules={{ required: true }}
                        />
                        {errors.state && errors.state.type === "required" && (
                          <span role="alert" className="error">
                            State is required
                          </span>
                        )}
                      </Row>
                    </Col>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      className="zipcode-block"
                    >
                      <Row gutter={[0, 20]}>
                        <Col span={24}></Col>
                        <Typography className="addAddressInputTitle">
                          Zipcode
                        </Typography>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Controller
                          as={Input}
                          name="zip"
                          placeholder="Type your Zipcode"
                          control={control}
                          size="large"
                          className="addAddressInput"
                          rules={{ required: true }}
                        />
                        {errors.zip && errors.zip.type === "required" && (
                          <span role="alert" className="error">
                            Zipcode is required
                          </span>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <Row
                    gutter={[
                      8,
                      { xs: 41, sm: 41, md: 50, lg: 50, xl: 50, xxl: 50 },
                    ]}
                    justify={isXS ? "center" : "end"}
                  >
                    <Col span={24}></Col>
                    <Col>
                      <Button
                        className="btn-cancel"
                        onClick={() => setAddressForm(false)}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button htmlType="submit" className="createAddressButton">
                        Add Address
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Card>
            </Col>
          </Row>
        </Spin>
      )}
      <UpdateAddress
        updateAddressForm={updateAddressForm}
        setUpdateAddressForm={setUpdateAddressForm}
        selectedAddressId={selectedAddressId}
      />
    </>
  );
};
export const UpdateAddress = (props) => {
  const { updateAddressForm, setUpdateAddressForm, selectedAddressId } = props;
  const dispatch = useDispatch();
  const { isXS } = useBreakpoints();
  const { currentUser, isUpdateAddressLoading } = useSelector(
    (state) => state.user
  );
  const { addresses } = currentUser || {};
  const the_address = addresses.filter(
    (address) => address._id === selectedAddressId
  );
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const handleUpdateAddress = (data) => {
    dispatch(
      updateAddress(
        selectedAddressId,
        data,
        successCallBackUpdateAddress,
        failureCallBackUpdateAddress
      )
    );
  };
  const successCallBackUpdateAddress = () => {
    setUpdateAddressForm(false);
    reset();
  };
  const failureCallBackUpdateAddress = () => {};
  return (
    <>
      {updateAddressForm && (
        <>
          <Spin
            indicator={antIcon}
            spinning={isUpdateAddressLoading}
            style={{ color: "#FF533F" }}
          >
            <Row gutter={[0, 40]}>
              <Col span={24}></Col>
              <Col>
                <Typography className="addnewAddressText">
                  Update Address
                </Typography>
              </Col>
            </Row>

            <Row gutter={[0, 14]}>
              <Col span={24}></Col>
              <Col span={24} className="shoppingCartParent2">
                <Card className="shoppingCartCard">
                  <form onSubmit={handleSubmit(handleUpdateAddress)}>
                    <Row gutter={[30, 0]}>
                      <Col span={24}></Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">
                            Name
                          </Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller
                            as={Input}
                            defaultValue={the_address[0]?.name}
                            name="name"
                            placeholder="Type your name"
                            control={control}
                            size="large"
                            className="addAddressInput"
                            rules={{ required: true }}
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span role="alert" className="error">
                              Name is required
                            </span>
                          )}
                        </Row>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">
                            Phone number
                          </Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller
                            as={Input}
                            type="number"
                            pattern="[0-9]*"
                            defaultValue={the_address[0]?.phone}
                            name="phone"
                            placeholder="Type your phone number"
                            control={control}
                            size="large"
                            className="addAddressInput"
                            rules={{ required: true }}
                          />
                          {errors.phone && errors.phone.type === "required" && (
                            <span role="alert" className="error">
                              Phone number is required
                            </span>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    {/* {addresserror && (
                    <Row justify="center" gutter={[0, 8]}>
                      <Col span={24}></Col>
                      <Col>
                        <Alert
                          type="error"
                          onClose={handleClose}
                          open={open}
                          message={addresserror.error.message}
                        ></Alert>
                      </Col>
                    </Row>
                  )} */}
                    <Row gutter={[30, 8]}>
                      <Col span={24}></Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Row gutter={[0, 0]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">
                            Address
                          </Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller
                            as={Input}
                            defaultValue={the_address[0]?.address}
                            name="address"
                            placeholder="Address line 1"
                            control={control}
                            size="large"
                            className="addAddressInput"
                            rules={{ required: true }}
                          />
                          {errors.address &&
                            errors.address.type === "required" && (
                              <span role="alert" className="error">
                                Address is required
                              </span>
                            )}
                        </Row>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Row
                          gutter={[
                            0,
                            { xs: 15, sm: 15, md: 65, lg: 65, xl: 33, xxl: 65 },
                          ]}
                        >
                          <Col span={24}></Col>
                          <Controller
                            as={Input}
                            defaultValue={the_address[0]?.secondAddress}
                            name="secondAddress"
                            placeholder="Address line 2"
                            control={control}
                            size="large"
                            className="addAddressInput"
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      gutter={[
                        8,
                        { xs: 0, sm: 0, md: 8, lg: 8, xl: 8, xxl: 8 },
                      ]}
                    >
                      <Col span={24}></Col>
                      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">
                            City
                          </Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller
                            as={Input}
                            defaultValue={the_address[0]?.city}
                            name="city"
                            placeholder="Type your city name"
                            control={control}
                            size="large"
                            className="addAddressInput"
                            rules={{ required: true }}
                          />
                          {errors.city && errors.city.type === "required" && (
                            <span role="alert" className="error">
                              City is required
                            </span>
                          )}
                        </Row>
                      </Col>
                      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">
                            State
                          </Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller
                            as={Select}
                            control={control}
                            options={US_STATES}
                            defaultValue={the_address[0]?.state}
                            name="state"
                            placeholder="Select your state name"
                            className="stateSelector"
                            style={{ width: "100%" }}
                            size="large"
                            rules={{ required: true }}
                          />
                          {errors.state && errors.state.type === "required" && (
                            <span role="alert" className="error">
                              State is required
                            </span>
                          )}
                        </Row>
                      </Col>
                      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Row gutter={[0, 30]}>
                          <Col span={24}></Col>
                          <Typography className="addAddressInputTitle">
                            Zipcode
                          </Typography>
                        </Row>
                        <Row gutter={[0, 15]}>
                          <Col span={24}></Col>
                          <Controller
                            as={Input}
                            type="number"
                            pattern="[0-9]*"
                            defaultValue={the_address[0]?.zip}
                            name="zip"
                            placeholder="Type your Zipcode"
                            control={control}
                            size="large"
                            className="addAddressInput"
                            rules={{ required: true }}
                          />
                          {errors.zip && errors.zip.type === "required" && (
                            <span role="alert" className="error">
                              Zipcode is required
                            </span>
                          )}
                        </Row>
                      </Col>
                    </Row>

                    <Row
                      gutter={[
                        8,
                        { xs: 41, sm: 41, md: 50, lg: 50, xl: 50, xxl: 50 },
                      ]}
                      justify={isXS ? "center" : "end"}
                    >
                      <Col span={24}></Col>
                      <Col>
                        <Button
                          className="btn-cancel"
                          onClick={() => setUpdateAddressForm(false)}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          htmlType="submit"
                          // onClick={() => handleUpdateAddress(the_address[0]._id)}
                          className="createAddressButton"
                        >
                          Update Address
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Card>
              </Col>
            </Row>
          </Spin>
        </>
      )}
    </>
  );
};
