import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Row, Col, Button, Card, Typography, Empty,
	// Divider, Image,
	Pagination,
	Avatar, Spin
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../App.css";
// import ProfileAddressEditIcon from "../assets/ProfileAddressEditIcon.svg";
import ProfileRemoveIcon from "../assets/ProfileRemoveIcon.svg";
// import ProfileSetasDefaultIcon from "../assets/ProfileSetasDefaultIcon.svg";
// import ProfileStrollers from "../assets/ProfileStrollers.svg";
import { getUserFavirotesLots } from "../Actions/userAction";
// import _ from "lodash";
// import moment from 'moment';
// import Pagination from '../components/Pagination';
import { useBreakpoints } from './../react-responsive-hooks/index';
import { removeFromFavorites } from "../Actions/lotsAction";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const ProfileFavorites = (props) => {
	const dispatch = useDispatch();
	const { isXS } = useBreakpoints();
	useEffect(() => {
		dispatch(getUserFavirotesLots())
	}, []);
	const { favirotesLots, isGetUserFavoritesLotsLoading } = useSelector((state) => {
		const { favirotesLots, isGetUserFavoritesLotsLoading } = state.user
		return {
			favirotesLots,
			isGetUserFavoritesLotsLoading
		};
	});
	const { lots } = favirotesLots || {}

	// const [nextPage, setNextPage] = useState(0);
	// const [basePages, setBasePages] = useState(5);
	// const [currentPage, setCurrentPage] = useState(1);

	// function itemRender(current, type, originalElement) {
	// 	if (type === "prev") {
	// 		return null;
	// 	}
	// 	if (type === "next") {
	// 		return <a className="pagyNext">Next</a>;
	// 	} else {
	// 		return originalElement;
	// 	}
	// }
	const capitalize = (s) => {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}
	const handleRemoveFromFavorite = (id) => {
		dispatch(removeFromFavorites({ id }, successCallBack, failureCallBack));
	};
	const successCallBack = () => {
		dispatch(getUserFavirotesLots())
	};

	//   const handlePage = (data) => {
	// 	  setCurrentPage();
	// 	  setNextPage(nextPage + 5)
	// 	  setBasePages(basePages + 5)
	//   }
	const perPage = 3;
	const [pageValue, setPageValue] = useState({
		min: 0,
		max: 3
	})
	const handlePagination = (val) => {
		setPageValue({
			min: (val - 1) * perPage,
			max: val * perPage
		});
	}

	const failureCallBack = () => { };
	return (
		<Spin
			style={{ top: 200, color: "#FF533F" }}
			indicator={antIcon}
			spinning={isGetUserFavoritesLotsLoading}
		>
			<Card bordered={false} className="profileFavoriteCard">
				<Row justify="space-between" gutter={[0, 30]}>
					<Col span={10} className="profileDetail">
						Favorites
					</Col>
					<Col span={24} />
				</Row>
				{/* <Row gutter={[0, 30]}>
				
			</Row> */}
				<Row gutter={[32, { xs: 20, sm: 20, md: 25, lg: 25, xl: 25, xxl: 25 }]} justify="space-between">
					{lots && lots.slice(pageValue.min, pageValue.max).map((val, i) => {
						const { imageUrls, name, condition, price, deliveryType } = val || {}
						const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
						// const cur = i + nextPage;
						return (
							// (cur < basePages) &&
							<Col span={24} key={i}>
								<Card className="favoritesCard">
									{lots && lots.length > 0 &&
										<Row gutter={[0, 16]} justify='space-between'>
											<Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
												<Row gutter={[0, 0]}>
													<Col className="favImg-wrap">
														{/* size={isXS ? 100 : 123} */}
														<Avatar shape='square' src={images} className="favImg" />
													</Col>
													<Col className="favInfo-wrap">
														<Row justify="space-between">
															<Col span={24}>
																<Typography className="favoriteName">{name}</Typography>
															</Col>
															{!isXS &&
																<Col span={24}>
																	<Row gutter={[30, 5]}>
																		<Col span={24}></Col>
																		<Col>
																			<Typography className="favoriteDetail">Condition:</Typography>
																		</Col>
																		<Col>
																			<Typography className="favoriteDetail2">{capitalize(condition)}</Typography>
																		</Col>
																	</Row>
																</Col>
															}
															<Col span={24}>
																<Row gutter={[0, 9]}>
																	<Col span={24}></Col>
																	<Col>
																		<Typography className="favItemPrice">${price / 100}</Typography>
																	</Col>
																</Row>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
											{isXS && <Col span={24}>
												<Row gutter={[70, 5]} className="conditionsRow">
													<Col span={24}></Col>
													<Col>
														<Typography className="favoriteDetail">Condition:</Typography>
														<Typography className="favoriteDetail2">{capitalize(condition)}</Typography>
													</Col>
													{/* <Col>
														<Typography className="favoriteDetail2">{capitalize(condition)}</Typography>
													</Col> */}
												</Row>
											</Col>
											}
											<Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
												<Row align="middle" justify="end" className="shippingInfo">
													<Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24} className="deliveryType" >
														<Typography className='pFDeliveryType'>{capitalize(deliveryType)}</Typography>
													</Col>
													<Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24} className="actions">
														<Row gutter={[0, { xs: 0, sm: 0, md: 94, lg: 94, xl: 94, xxl: 94 }]}>
															<Col span={24}></Col>
															<Col>

																<Button className="profile-button btn-remove"
																	onClick={() => handleRemoveFromFavorite(val._id)}><img alt="" src={ProfileRemoveIcon} /><span className="btn-text">Remove</span>
																</Button>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
									}
								</Card>
							</Col>
						)
					})}
					{lots && lots.length > 3 &&
						<Col span={24} className="profile-pagination">
							<Row justify="end">
								<Pagination
									total={lots && lots.length}
									onChange={handlePagination}
									defaultCurrent={1}
									defaultPageSize={perPage}
								/>
							</Row>
						</Col>
					}
					<Col span={24}></Col>
					{/* <Pagination perPage={perPage} total={lots && lots.length} Array={lots} setPage={handlePagination}/> */}
				</Row>
				{lots && lots.length <= 0 &&
					<Row justify='center'>
						<Col span={24}></Col>
						<Col>
							<Empty description="You don't have any favorite items right now." />
						</Col>
					</Row>
				}
			</Card>
		</Spin>
	);
};
export default ProfileFavorites;
