import React from "react";
import { Card, Typography, Row, Col } from "antd";
import "../App.css";
export const PCategoryCard = (props) => {
  const { currentCategory, currentBrand, itemType, resultValue, handPickedData } = props
  const {
    // imageUrl,
    v2ImageUrl,
    displayValue
  } = currentCategory || {}
  const {
    brand,
    imageUrl
  } = currentBrand || {}
  const { handpicked } = handPickedData || {}
  const { name: handPickedName, imageUrl: handPickedImage } = handpicked || {}
  return (
    <div className="pCategoryParent categoryPage">
      <Card className="pCategoryCard ">
        <div className="app-wrapper ">
          <Row gutter={[32, 0]} className="plp-top-block">
            {/* <Col span={24}></Col> */}
            {/* <Col className="no-padding icons-holder"  >
              {itemType === "category" &&
                <>
                  <img alt="" src={v2ImageUrl} className='icon categoryImgWeb' />
                  <img alt="" src={v2ImageUrl} className='icon categoryImgMob' />
            
                </>
              }
              {itemType === "brand" &&
                <><img alt="" src={imageUrl} className='icon categoryImgWeb' />
                  
                  <img alt="" src={imageUrl} className='icon categoryImgMob' />
              
                </>
              }
              {itemType === "handpicked" &&
                <>
                  <img alt="" src={handPickedImage} className='icon categoryImgWeb' />
                  <img alt="" src={handPickedImage} className='icon categoryImgMob' />
                </>
              }
            </Col> */}
            <Col className="no-padding">
              <div className='categoryBlogWeb'>
                <Row gutter={[0, 38]}>
                  {/* <Col span={24}></Col> */}
                  <Col>
                    <h1 className='pCName'>
                      {itemType === "category" &&
                        displayValue
                      }
                      {itemType === "brand" &&
                        brand
                      }
                      {itemType === "handpicked" &&
                        handPickedName
                      }

{resultValue>0 ?(<Typography className="pCResult"> ({resultValue } RESULTS)</Typography>):(<Typography className="pCResult"> Showing RESULTS</Typography>)}
                    </h1>
                  </Col>
                </Row>
              </div>
              <div className="categoryBlogMob">
                <Row gutter={[0, 0]}>
                  {/* <Col span={24}></Col> */}
                  <Col>
                    <h1 className="pCName">
                      {itemType === "category" && displayValue}
                      {itemType === "brand" && brand}
                      {itemType === "handpicked" && handPickedName}
                      {resultValue>0 ?(<Typography className="pCResult"> ({resultValue } RESULTS)</Typography>):(<Typography className="pCResult"> Showing RESULTS</Typography>)}
                      
                    </h1>
                  </Col>
                </Row>
              </div>
              {/* <Row gutter={[0, 2]}>
                <Col>
                  <Typography className="pCResult">
                    {resultValue ? resultValue : 0} RESULTS
                  </Typography>
                </Col>
              </Row> */}
            </Col>
            <Col span={24}></Col>
          </Row>
        </div>

      </Card>
    </div>
  );
};
