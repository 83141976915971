import React from "react";
import { Modal, Button, Row, Col, Typography } from "antd";
const { Title, Text } = Typography;

export const ItemNotFound = (props) => {
  const { createDialog, setCreateDialog } = props;
  return (
    <>
      <Modal
        visible={createDialog}
        onCancel={() => setCreateDialog(false)}
        footer={null}
        centered
        width={400}
      >
        <Row justify="center" gutter={[8, 48]}>
          <Col span={24}></Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title
              level={4}
              strong
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "auto",
              }}
            >
              Can't Find Your Item Here?
            </Title>

            <Text
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              List your item on markid and
            </Text>

            <Text
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              join our growing parent community
            </Text>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 16]}>
          <Col span={24}></Col>
          <Col>
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
              }}
            >
              TAKE ME TO THE APP
            </Button>
          </Col>
          <Col span={24}></Col>
          <Col span={24}></Col>
        </Row>
      </Modal>
    </>
  );
};
