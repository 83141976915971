import constants from '../Utils/constants';
const initialState = {
    isFiltersDataLoading: false,
    filterToDisplay: [],
    isSizeFilterLoading: false,
    sizeFilters: [],
    isAgeFilterLoading: false,
    ageFilters: []
}

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_FILTER_TO_DISPLAY_REQUEST:
            return {
                ...state, isFiltersDataLoading: true
            }
        case constants.GET_FILTER_TO_DISPLAY_SUCCESS:
            return {
                ...state, isFiltersDataLoading: false, filterToDisplay: action.payload
            }
        case constants.GET_FILTER_TO_DISPLAY_FAILURE:
            return {
                ...state, isFiltersDataLoading: false
            }

        case constants.GET_SIZE_FILTER_REQUEST:
            return {
                ...state, isSizeFilterLoading: true
            }
        case constants.GET_SIZE_FILTER_SUCCESS:
            return {
                ...state, isSizeFilterLoading: false, sizeFilters: action.payload
            }
        case constants.GET_SIZE_FILTER_FAILURE:
            return {
                ...state, isSizeFilterLoading: false
            }

        case constants.GET_AGE_FILTER_REQUEST:
            return {
                ...state, isAgeFilterLoading: true
            }
        case constants.GET_AGE_FILTER_SUCCESS:
            return {
                ...state, isAgeFilterLoading: false, ageFilters: action.payload
            }
        case constants.GET_AGE_FILTER_FAILURE:
            return {
                ...state, isAgeFilterLoading: false
            }

        default:
            return state
    }
}
export default filtersReducer;