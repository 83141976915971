/*eslint-disable*/
import React, { useEffect, useLayoutEffect, useState } from "react";

import "react-lazy-load-image-component/src/effects/blur.css";
import { Layout, Modal } from "antd";

import { Row, Col, Typography, Card, Button, Input } from "antd";

import "../App.css";

import RoundColorComponent from "./RoundColorComponent";
const SelectColourSeller = (props) => {
  const {
    showColorDialog,
    onCancelColorDialog,
    setSelectedColors,
    selectedColors,
    setcolorValidation,
    selectedColor,
    setSelectedColor,
  } = props;
  const setOfColors = [
    { colorCode: "#FFFFFF", colorName: "White" },
    { colorCode: "#D4D8E3", colorName: "Grey" },
    { colorCode: " #393939", colorName: "black" },
    { colorCode: "#2A853D", colorName: "green" },
    { colorCode: "#8351B4", colorName: "purple" },
    { colorCode: "#763D23", colorName: "brown" },
    { colorCode: "#2F80ED", colorName: "blue" },
    { colorCode: "#9F73A2", colorName: "lavender" },
    { colorCode: "#FF0000", colorName: "red" },
    { colorCode: "#F335CF", colorName: "pink" },
    { colorCode: "#00FFFF", colorName: "cyan" },
    { colorCode: "#FFD700", colorName: "gold" },
    { colorCode: "#FFFF00", colorName: "yellow" },
    { colorCode: "#8F00FF", colorName: "violet" },
    { colorCode: "#FFFDD0", colorName: "cream" },
    { colorCode: "#C0C0C0", colorName: "silver" },
    { colorCode: "#C0FF00", colorName: "lime" },
    { colorCode: "#990100", colorName: "maroon" },
    { colorCode: "#A7FFC2", colorName: "mint" },
    { colorCode: "#010080", colorName: "navy" },
  ];

 
  function onColorSelectFunction(color) {
    setSelectedColor([]);
    setSelectedColors([color]);
  }
  const handleCancel = () => {
    onCancelColorDialog();
  };

  return (
    <Modal
      footer={null}
      width={442}
      visible={showColorDialog}
      onCancel={() => handleCancel()}
    >
      <div className="colourContainer">
        <Row gutter={[0, { xs: 50, sm: 50, md: 28, lg: 28, xl: 28 }]}>
          <Row
            gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
            justify="center"
            style={{ width: "100%" }}
          >
            <Col>
              <Typography className="selectcolourcontainer">
                Select the color
              </Typography>
            </Col>
          </Row>

          <section class="about_us_section">
            <div class="row">
              <div class="container">
                <div className="colourContainersmall">
                  {setOfColors.map((color, index) => {
                    return (
                      <React.Fragment key={index}>
                        <RoundColorComponent
                          value={
                            color.colorName === localStorage.getItem("activeColor")
                          }
                          
                          onClick={() => {
                            onColorSelectFunction(color);
                            localStorage.setItem(
                              "activeColor",
                              color.colorName
                            );
                          }}
                          color={color}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>

                <Col className="selectbutton">
                  <Button
                    className={"button button-primary  pLselectButton"}
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                      handleCancel();
                      setSelectedColors(selectedColors);
                      setcolorValidation("")
                    }}
                  >
                    select
                  </Button>
                </Col>
              </div>
            </div>
          </section>
        </Row>
      </div>
    </Modal>
  );
};
export default SelectColourSeller;
