import moment from 'moment';
import * as R from 'ramda';

const humanize = (str) => {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  export {humanize};

export const getRemainingTime = createdAt => {
  const offerTime = moment(createdAt).add('1', 'day');
  let remainingTime = moment.duration(offerTime.diff(moment()));
  const hours = remainingTime.hours() < 10
    ? `0${remainingTime.hours()}`
    : remainingTime.hours();
  const minutes = remainingTime.minutes() < 10
    ? `0${remainingTime.minutes()}`
    : remainingTime.minutes();
  remainingTime = `${hours} : ${minutes}`;
  return [remainingTime, hours > 0 || minutes > 0];
};

export const getAddressStr = (address) => {
  const name = R.pathOr('', ['name'], address);
  const firstAddress = R.pathOr('', ['address'], address);
  const secondAddress = R.pathOr('', ['secondAddress'], address);
  const city = R.pathOr('', ['city'], address);
  const state = R.pathOr('', ['state'], address);
  const country = R.pathOr('', ['country'], address);
  const zip = R.pathOr('', ['zip'], address);
  return `${name} ${firstAddress} ${secondAddress} ${city} ${state} ${country} ${zip}`;
};