import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Avatar } from "antd";
import { useBreakpoints } from './../react-responsive-hooks/index';
// import { useHistory } from "react-router-dom";
import "../App.css";
export const TestimonialsCard = (props) => {
  const { testimonialsData, testimonialsIndex } = props;
  const {isXS} = useBreakpoints();
    // const { UserImg, quotation, tSHeading, tSDes, userName, userDesignation} = props
  const { userImage, userTitle, userComment, userName, userDesignation } =
    testimonialsData;
  return (
    <div className="testparent">
      <Card className="testimonialsCard">
        <Row 
        // gutter={[30, 0]}
        gutter={[{xs:10 , sm:10, md:10, lg:20,xl:20, xxl:20},0]}
        // justify="space-between"
        >
          <Col>
            <Avatar src={userImage} className="tsImg"  shape="square" />
            {/* <img alt="" src={userImage} className='tsImg'/> */}
          </Col>
          <Col>
            <Row gutter={[0, { xs: 41.89, sm: 41.89, md: 58, lg: 58 }]} justify="start">
              <Col span={24}></Col>
              <Col style={{ marginBottom: -50 }}>
                <Typography
                  className={
                    testimonialsIndex === 0 ? "quotationOne" : "quotationTwo"
                  }
                >
                  “
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 7.39, sm: 7.39, md: 12, lg: 12 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography className="tSHeading">{userTitle}</Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 5.64, sm: 5.64, md: 10, lg: 10 }]}>
              <Col span={24}></Col>
              <Col style={{
                height:isXS ? 50 : 70,
                padding:"0px 0px 10px",
                overflow:userComment.length > 200 ? "hidden" :'unset',
                overflowY:userComment.length > 200 ?'scroll':'unset'
              }}>
                <Typography className="tSDes">{userComment}</Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 32.64, sm: 32.64, md: 53.5, lg: 53.5 }]}>
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col>
                <Typography className="tSUserName">{userName}</Typography>
              </Col>
            </Row>
            <Row gutter={[0, { xs: 2.87, sm: 2.87, md: 5, lg: 5 }]}>
              <Col span={24}></Col>
              <Col>
                <Typography className="tSUserDesgnation">
                  {userDesignation}
                </Typography>
              </Col>
            </Row>
            <br/>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
