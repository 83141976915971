import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal, Button, Row, Col, Space, Typography, Input } from "antd";
const { Title ,Text } = Typography;

export const VenmoAccount = (props) => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is a required field"),
  });
  const { createDialog, setCreateDialog } = props;
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  return (
    <>
      <Modal
        visible={createDialog}
        onCancel={() => setCreateDialog(false)}
        closable={false}
        footer={null}
        centered
        width={400}
      >
        <form onSubmit={handleSubmit()}>
          <Row justify="center" gutter={[0,48]}>
          <Col span={24}></Col>
          <Col span={24}></Col>
            <Col xl={16} lg={16} md={16} sm={18} xs={18}>
              <Space direction="vertical">
                <Col>
                  <Text strong style={{fontSize: 12}}>
                    Enter your email associated with your
                  </Text>
                  <Title
                    level={4}
                    strong
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "auto",
                    }}
                  >
                    Venmo Account
                  </Title>
                  <Controller
                    as={Input}
                    style={{ backgroundColor: "#f5f5f5" }}
                    name="email"
                    control={control}
                    placeholder="Venmo email address"
                  />
                  {errors.email && (
                    <span style={{ color: "red" }}>Email is required</span>
                  )}
                </Col>
                <Col>
                  <Button
                    block
                    className={'squarebutton'}
                  >
                    VERIFY
                  </Button>
                </Col>
              </Space>
            </Col>
            <Col span={24}></Col>
            <Col span={24}></Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};
