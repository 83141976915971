import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useGaEvent } from "../hooks/useGaEvent";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";
import { useBreakpoints } from "./../react-responsive-hooks/index";

export const DeliveryFilter = (props) => {
  const { selectedShipping, updateSelectedShipping, currentPage, totalcount } =
    props;
  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
 
  const { gaPageView, gaEventClick } = useGaEvent();
  const checkdonShippingChange = (checkedValues) => {
    const findIndex = selectedShipping.findIndex(
      (item) => item.shipping === checkedValues.shipping
    );
    const matchObject = selectedShipping[findIndex];
    matchObject.value =
      matchObject.value === checkedValues.value
        ? !matchObject.value
        : matchObject.value;
    updateSelectedShipping(matchObject, findIndex);
  };
  const [currentOpenPanelKey, setCurrentOpenPanelKey] = useState("");
  function dropdownCallback(key) {
    if (key.length === 0) {
      setCurrentOpenPanelKey("");
      localStorage.removeItem("Active-Filter");
    } else if (key.length === 1) {
      setCurrentOpenPanelKey(key[0]);
      localStorage.setItem("Active-Filter", key[0]);
    } else if (key.length === 2) {
      setCurrentOpenPanelKey(key[1]);
      localStorage.setItem("Active-Filter", key[1]);
    } else {
      setCurrentOpenPanelKey(key[key.length - 1]);
      localStorage.setItem("Active-Filter", key[key.length - 1]);
    }
  }
  const [key, setkey] = useState();
  const { isXS, isSM } = useBreakpoints();
  useEffect(() => {
    if (!(isXS || isSM)) {
      if (currentPage[3] === "clothing_and_accessories") {
        setkey(6);
      }
      if (currentPage[3] === "maternity") {
        setkey(6);
      }
      if (currentPage[3] === "shoes") {
        setkey(6);
      }
      if (currentPage[3] === "gear") {
        setkey(6);
      }
      if (currentPage[3] === "feeding") {
        setkey(6);
      }
      if (currentPage[3] === "electronics") {
        setkey(6);
      }
      if (currentPage[3] === "safety_equipment") {
        setkey(6);
      }
      if (currentPage[3] === "diapering") {
        setkey(6);
      }
      if (currentPage[3] === "toys_and_costumes") {
        setkey(6);
      }
      if (currentPage[3] === "cribs_and_furniture") {
        setkey(6);
      }
      if (currentPage[3] === "bathing") {
        setkey(6);
      }
      if (currentPage[3] === "sports_bicycles") {
        setkey(6);
      }
      if (currentPage[3] === "books") {
        setkey(6);
      }
      if (currentPage[3] === "strollers") {
        setkey(6);
      }
    }
  }, [currentPage]);
  const shippingdetails = totalcount&& totalcount?.deliveryType || {};
  const shippingfree= totalcount&& totalcount?.isFree || {};
  const shipping1 =
    totalcount &&
    "shipping" in shippingdetails &&
    totalcount.deliveryType.shipping > 0
      ? totalcount.deliveryType.shipping
      : 0;
  const local =
    totalcount &&
    "Local Pick-up" in shippingdetails &&
    totalcount.deliveryType["Local Pick-up"] > 0
      ? totalcount.deliveryType["Local Pick-up"]
      : 0;
      const freeshipping =
      totalcount &&
     "true" in shippingfree &&
      totalcount.isFree.true > 0
        ? totalcount.isFree.true
        : 0;
  const meetup =
    totalcount &&
    "meetup" in shippingdetails &&
    totalcount.deliveryType.meetup > 0
      ? totalcount.deliveryType.meetup
      : 0;
  const both =
    totalcount && "both" in shippingdetails && totalcount.deliveryType.both > 0
      ? totalcount.deliveryType.both
      : 0;
  const localpickup =
    totalcount && Object.keys(totalcount.deliveryType || {}).length> 0
      ? local + meetup + both
      : 0;
  const shipping =
    totalcount && Object.keys(totalcount.deliveryType || {}).length > 0
      ? shipping1 + both
      : 0;

  return (
    <>
      <Collapse
        defaultActiveKey={["6"]}
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        onChange={()=>{gaEventClick("Filter by", "Delivery","open/close")}}
        bordered={false}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        <Panel
          className="collapseContent"
          style={{
            padingTop: 15,
            paddingBottom: 19,
            marginTop: 15,
            marginBottom: 19,
          }}
          header={
            selectedShipping &&
            (selectedShipping[0].value === true ||
              selectedShipping[1].value === true 
              ) ? (
              <Badge color="blue">
                <Typography className="pFCollapseTitle">
                  Delivery&nbsp;
                </Typography>
              </Badge>
            ) : (
              <Typography className="pFCollapseTitle">Delivery</Typography>
            )
          }
          key={key}
        >
          {selectedShipping &&
            selectedShipping.map((val, i) => (
              <Row gutter={[12, 16]} key={i}>
                <Col>
                  <Checkbox
                    onChange={() => {checkdonShippingChange(val); gaEventClick("Filter by", "Delivery",val.shipping)}}
                    checked={val.value}
                  />
                </Col>
                <Col>
                  <Typography className="conditionName">
                    {capitalize(val.shipping)}
                    {val.shipping == "Shipping" && (
                      <span  className="ProductCount">({shipping})</span>
                    )}
                    {/* {val.shipping == "Local Pick Up"  && (
                      <span  className="ProductCount">({localpickup})</span>
                    )} */}
                    {val.shipping == "Free Shipping"&& (
                      <span  className="ProductCount">({freeshipping})</span>
                    )}
                  </Typography>
                </Col>
              </Row>
            ))}
        </Panel>
      </Collapse>
    </>
  );
};
