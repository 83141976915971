/*eslint-disable*/
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Row, Col, Button, Typography, Input } from "antd";
import _ from "lodash";

import { useHistory } from "react-router-dom";

import "../styles/landing.css";

import "../App.css";
import "../responsive.css";

import { getAllpartnerproducts } from "../Actions/partnerProductsAction";

const { Search } = Input;
const ProductTop = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const handleSearch = (data) => {
    dispatch(getAllpartnerproducts({ search: data }));
    history.push(`/partnerproduct-list/swoondle/${data}`);
    localStorage.setItem("search", data);
  };
  localStorage.getItem("search");
  const { search } = params;
  return (
    <>
      <div className="userLoginButtonWeb">
        <Row>
          <Col className="topsearchvariation partner_search">
            <Row justify="space-around" align="middle">
              <Col span="24" className="searchOption">
                {/* <Search
                  className="partnersearch searchInput"
                  placeholder="Search for Clothing..."
                  onSearch={handleSearch}
                  defaultValue={search}
                /> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* MOBILE HEADER START */}
      <div className="mobilePartnersSearch">
        <div className="userLoginButtonMob partner_search">
          <Row align="middle" justify="space-around">
            <Col span="24" className="searchOption">
              {/* <Input.Search
                placeholder="Search for clothing..."
                className="partnersearchmob searchInput"
                onSearch={handleSearch}
                defaultValue={search}
              /> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default ProductTop;
