import React from "react";
import { Row, Col, Button, Card, Typography, Input } from "antd";
import "../styles/landing.css";
const { Text, Title } = Typography;
const ReNewedBlog = () => {
  return (
    <>
    <Row  justify='center' gutter={[48, 32]}>
    <Col span={24}></Col>
				<Col xl={20} lg={20} md={20} sm={24} xs={24}>
          <Card style={{ backgroundColor: " #f6f6f6" }}>
            <Row justify='center'>
              <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                <Row justify="center" gutter={[32,32]}>
                  <Title level={3} strong>
                    Meet Markid reNEWED
                  </Title>
                </Row>
                <Row justify="center">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Text>
                    The simplest way to sell kids' pre-owned high-quality
                    products - effortlessly, sustainably & purposefully
                  </Text>
                  </Col>
                </Row>
                <Row justify="center"gutter={[0,12]}>
                  <Col span={24}></Col>
                  <Text style={{ fontSize: 10 }}>
                    We are currently in New York only
                  </Text>
                </Row>
                <Row justify="center" gutter={[0,12]}>
                  <Col span={24}></Col>
                  <Text style={{ fontSize: 10 }} strong>
                    {" "}
                    Check if reNEWED is available at your location{" "}
                  </Text>
                </Row>
              </Col>
            </Row>

            <Row justify="center" gutter={[12,12]}>
              <Col span={24}></Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                <Input
                size='large'
                  placeholder="Enter Your Zip Code"
                  style={{ backgroundColor: "#ececec" }}
                />
              </Col>
              <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                <Button size='large' className={'squarebutton'}>
                  START SELLING
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify='center' gutter={[0, 32]}>
        <Col span={24}></Col>
        <Col xl={24} lg={24} md={24} sm={10} xs={10}>
          <Text style={{ fontSize: 20 }} strong>
            What is Markid reNEWED?
          </Text>
        </Col>
      </Row>

      <Row justify='center'>
        <Col xl={24} lg={24} md={24} sm={20} xs={20}>
          <Row justify="center">
            <Text style={{ fontSize: 10 }}>
              Markid reNewed is a resale marketplacedesigned for busy parents
              who need tounload their kids' gently-used
            </Text>
          </Row>
          <Row justify="center">
            <Text style={{ fontSize: 10 }}>
              preowned items but lack the time or commitment to pursue a sale
            </Text>
          </Row>
          <Row justify="center">
            <Text style={{ fontSize: 10 }}>
              As parents, we recognize your needsand created a solution to fit
              your needs.
            </Text>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ReNewedBlog;
