import React, { createElement,useState } from 'react';

export function ProductItem({ hit, components,onclick }) {

  return (
    
    <div  onClick={onclick} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemTitle">
          {hit.name}
          {/* <components.Highlight hit={hit} attribute="name" /> */}
        </div>
      </div>
    </div>
  );
}