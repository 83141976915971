/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useHistory, Switch } from "react-router-dom";
import { Row, Col, Typography, Card, Divider } from "antd";
import ProductTop from "../components/ProductTop";
import PMenu from "../components/PMenu";
import { UserChatList } from "../components/UserChatList";
import { UserChatListMob } from "../components/UserChatListMob";
import { ChatingSection } from "../components/ChatingSection";
import { ChatingSectionMob } from "../components/ChatingSectionMob";
import "../App.css";
import moment from 'moment';
import { useBreakpoints } from './../react-responsive-hooks/index';
import MainHomePageFooter from "../components/MainHomePageFooter";
import SellerImg from "../assets/SellerImg.svg";
import SellerImgOne from "../assets/SellerImgOne.svg";
import SellerImgTwo from "../assets/SellerImgTwo.svg";
import { getChats } from "../Actions/chatsAction";
import { Helmet } from "react-helmet";

const Message = () => {
  const dispatch = useDispatch()
  const { isXXL, isXL, isLG, isMD, isSM, isXS } = useBreakpoints()
  const { lot, allChats, currentChat } = useSelector((state) => {
    const { lot } = state.lots;
    const { isAuthenticated } = state.auth;
    const { allChats, currentChat } = state.chats
    return {
      lot,
      allChats,
      currentChat
    };
  });
  const { chats } = allChats
  const [userChating, setUserChating] = useState({})
  const [userChatListMobile, setUserChatListMobile] = useState(true)
  const [userChatMobile, setUserChatMobile] = useState(false);

  // const [currentChat, setCurrentChat] = useState(chats && chats.length > 0 ? chats[0] : {})

  useEffect(() => {
    dispatch(getChats())
  }, []);
  return (
    <div>
      <Helmet>
        <title>Markid - Messages</title>
      </Helmet>
      {(isXXL || isXL || isLG || isMD) &&
        <Card bordered={false} className='chatBg'
          style={{ height: innerHeight - 150, overflow: 'hidden', padding: '0px' , marginTop: "52px"}}>
          <div className="chattingWrapper app-wrapper">
            <Row gutter={[0, 0]} justify='center' align="middle">
              <Col span={24}></Col>
              {/* <Col span={2}></Col> */}
              <Col className="chatLeft-panel">
                <UserChatList
                  setUserChating={setUserChating}
                  ChatListData={chats}
                  lotData={lot}
                />
              </Col>
              {/* <Col style={{ background: '#E2F1F1', width: 3 }} /> */}
              <Col className="chatRight-panel">
                <Row>
                  <Col span={24}></Col>
                  <Col span={24}>
                    <ChatingSection
                      ChatListData={chats}
                      userChating={userChating}
                      lotData={lot}
                      currentChat={currentChat}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

        </Card>
      }
      {(isSM || isXS) &&
        <div>
          <Divider className="chatDivider" />

          <Row gutter={[0, 5]} justify='center'>
            <Col span={24}></Col>
            <Col span={24}>
              {userChatListMobile &&
                <UserChatListMob
                  setUserChating={setUserChating}
                  ChatListData={chats}
                  lotData={lot}
                  setUserChatListMobile={setUserChatListMobile}
                  setUserChatMobile={setUserChatMobile}
                />
              }
              {userChatMobile &&
                <ChatingSectionMob
                  userChating={userChating}
                  lotData={lot}
                  currentChat={currentChat}
                  setUserChatListMobile={setUserChatListMobile}
                  setUserChatMobile={setUserChatMobile}
                />
              }
            </Col>
          </Row>
        </div>
      }
    </div>
  );
};
export default Message;
