import API from "../Utils/API";
import swwondlelogo from "../assets/swwondlelogo.png"

export const getCurrentUserWallet = () => (dispatch) => {
  dispatch({ type: "GET_WALLET_REQUEST" });
  API.get("/api/v2/wallet")
    .then((response) => {
      dispatch({ type: "GET_WALLET_SUCCESS", payload: response.data.wallet });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_WALLET_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
export const getLastCart = (userID) => (dispatch) => {
  dispatch({ type: "GET_LAST_CART_REQUEST" });
  API.get("/api/v2/carts/last-cart")
    .then((response) => {

      const addedCartItems = [...response.data.cart[0].lots];
      const lastCartData =[];
      const cartItems=[];
      response.data.lots.forEach((item)=>{
        item.productType = "P2P";
        if(item.seller._id!==userID)
        {
          cartItems.push(item);
          lastCartData.push(item._id);
        }        
      })

      response.data.partnerProducts.forEach((item)=>{
        item.productType = "Partner";
        let imgArray = [];
        imgArray.push(item.imageLink);
        item.name = item.title;
        item.price = parseInt(item.salePrice.value);
        item.imageUrls = imgArray;
        item.shippingPrice = "0";
        item.productType = "Partner";
        item.seller = {
          fullName: "Swoondle Society",
          photo: swwondlelogo,
        };
        cartItems.push(item);
        lastCartData.push(item._id);
      })
      dispatch({
        type: "GET_LAST_CART_SUCCESS",
        payload: {lastCartIds: lastCartData, currCartItems: cartItems, addedCartItems: addedCartItems},
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_LAST_CART_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const setCurentCart = (markidProductId) => dispatch => {
  dispatch({ type: "SET_CURRENT_CART", payload: markidProductId });
}

export const deleteCurrentcart = (markidProductId) => dispatch => {
  dispatch({ type: "DELETE_CURRENT_CART", payload: markidProductId });
}

export const emptyCurrentCart = () => dispatch => {
  dispatch({ type: "EMPTY_CURRENT_CART"});
}




export const addToCart =
  (data ,loggedIn, successCallBack, failureCallBack) => (dispatch) => {
    if(loggedIn){
    dispatch({ type: "POST_CART_REQUEST" });
    API.post("/api/v2/carts", data) // data should be an array of markidProductIds
      .then((response) => {
      localStorage.setItem("userCart", JSON.stringify([]))
      dispatch({ type: "POST_CART_SUCCESS", payload: {cartData: response.data} });
      successCallBack();
        
        // response will be markidProducts
      })
      .catch((error) => {
        try {
          dispatch({
            type: "POST_CART_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
    }
    
};

export const saveToLocalStorage = (item) => (dispatch) => {
   const cartIds =  JSON.parse(localStorage.getItem("userCart"))?  JSON.parse(localStorage.getItem("userCart")) : [];
   //console.log(cartIds);

    if(item.objectID)
    {
     if(!cartIds.includes(item.objectID))
     {
       cartIds.push(item.objectID);
     }
     dispatch({ type: "SET_CURRENT_CART", payload: {id: item.objectID} });
    }
    else if(item._id)
    {
     if(!cartIds.includes(item._id))
     {
       cartIds.push(item._id);
     }
     dispatch({ type: "SET_CURRENT_CART", payload: {id: item._id} });
    }
    else{
      if(!cartIds.includes(item.markidProductId))
     {
       cartIds.push(item.markidProductId);
     }
     dispatch({ type: "SET_CURRENT_CART", payload: {id: item.markidProductId} });
    }
     
     
    localStorage.setItem("userCart", JSON.stringify(cartIds));
    
    dispatch({ type: "ADD_ITEM_CART_OVER_CURRENT_CART", payload: {item : item} })
    
  //  localStorage.setItem("userCart", JSON.stringify(cartIds));
  //  dispatch({ type: "SET_CURRENT_CART", payload: {id: item.objectID} });
  //  dispatch({ type: "ADD_ITEM_CART_OVER_CURRENT_CART", payload: {item : item} })
   //localStorage.setItem("userCart", JSON.stringify([]));
   //const cartData = [];
  
}


export const createCart =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "POST_CART_REQUEST" });
    API.post("/api/v2/carts", data)
      .then((response) => {
        dispatch({ type: "POST_CART_SUCCESS", payload: response.data });
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "POST_CART_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

export const createStripePayment = (data) => (dispatch) => {
  dispatch({ type: "POST_STRIPE_PAYMENT_REQUEST", data });
  API.post("/api/v2/create-payment-intent", data)
    .then((response) => {
      dispatch({ type: "POST_STRIPE_PAYMENT_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "POST_STRIPE_PAYMENT_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const updateCart = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_CART_REQUEST" });
  API.post(`/api/v2//cart${data.id}`)
    .then((response) => {
      dispatch({ type: "UPDATE_CART_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "UPDATE_CART_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const verifyPromoCode = () => (dispatch) => {
  dispatch({ type: "VERIFY_PROMO_CODE_REQUEST" });
  API.post("/api/v2//verify")
    .then((response) => {
      dispatch({ type: "VERIFY_PROMO_CODE_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "VERIFY_PROMO_CODE_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const applyPromoCode = () => (dispatch) => {
  dispatch({ type: "APPLY_PROMO_CODE_REQUEST" });
  API.post("/api/v2//apply")
    .then((response) => {
      dispatch({ type: "APPLY_PROMO_CODE_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "APPLY_PROMO_CODE_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const removePromoCode = () => (dispatch) => {
  dispatch({ type: "REMOVE_PROMO_CODE_REQUEST" });
  API.post("/api/v2//remove")
    .then((response) => {
      dispatch({ type: "REMOVE_PROMO_CODE_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "REMOVE_PROMO_CODE_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const availPromotions = () => (dispatch) => {
  dispatch({ type: "AVAIL_PROMOTIONS_CHECK_REQUEST" });
  API.get("/api/V5/partner-promotions/eligibility")
    .then((response) => {
      dispatch({
        type: "AVAIL_PROMOTIONS_CHECK_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "AVAIL_PROMOTIONS_CHECK_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
