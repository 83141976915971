import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Spin, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "../App.css";
import { Carousel } from "antd";
import sellerhub from "../assets/new-assets/How1.png";
import shape from "../assets/new-assets/shape.png";
import How2 from "../assets/new-assets/How2.png";
import How3 from "../assets/new-assets/How3.png";
import How4 from "../assets/new-assets/How4.png";
import SignIn from "../pages/SignIn";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { joinCommunity } from "../Actions/userAction";
import { TypeOneReciver } from "./TypeOfMessages";
export const SellerHowitWorks = (props) => {
  const history = useHistory();
  const carouselRef = useRef(null);
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  const calculateTop = () => {
    let width = 0;
    if (isXS) {
      width = 302;
    } else if (isLG) {
      width = 335;
    } else if (isSM) {
      width = 200;
    } else if (isMD) {
      width = 200;
    } else if (isXL) {
      width = 320;
    } else if (isXXL) {
      width = 500;
    }
    return width;
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  return (
    <Row>
      <Col span={24} className="heroSlider">
        {(isXL || isLG || isXXL || isMD) && (
          <section class="about_us_section">
            <div
              class="row"
              className="Howbackground"
              style={{ marginTop: "50px" }}
            >
              <div class="container">
                <Row
                  gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
                  justify="center"
                >
                  <Col span={24}></Col>
                  {/* <Col span={24}></Col> */}
                  {/* <Col span={24}></Col> */}
                  <Col>
                    <Typography
                      className="featuredSeller p title"
                      style={{
                        marginTop: "25px",
                      }}
                    >
                      How it works
                    </Typography>
                  </Col>
                  <Col></Col>
                </Row>
                <div className="Howcontainer">
                  <Row
                    gutter={{ xs: 15, sm: 16, md: 24, lg: 32 }}
                    className="imagecenter display-flex-columns"
                  >
                    <Card className="Howcard">
                      <div class="card_img">
                        <a>1</a>
                      </div>
                      <Col
                        className="gutter-row min-width-100-percent"
                        id="n-imagecontainer"
                        span={6}
                      >
                        <span>
                          <Typography
                            className="Howtexttitle"
                            style={{ marginLeft: "13px" }}
                          >
                            Create a listing
                          </Typography>
                          <img
                            className="HowitworksImages"
                            src={sellerhub}
                            alt="advisors-img"
                            width="54px"
                            height="79px"
                          />
                          <p
                            className="describeHow"
                            style={{ marginTop: "12px" }}
                          >
                            Click and upload your kids’ outgrown items in a
                            snap.
                          </p>
                        </span>
                      </Col>
                    </Card>
                    <Col span={1}></Col>
                    <Card className="Howcard">
                      <div class="card_img">
                        <a>2</a>
                      </div>
                      <Col
                        className="gutter-row min-width-100-percent"
                        // class="team_member_advisors"
                        id="n-imagecontainer"
                        span={6}
                      >
                        <Typography
                          className="Howtexttitle"
                          style={{ marginLeft: "18px" }}
                        >
                          Make the sale
                        </Typography>
                        <a>
                          <img
                            className="HowitworksImages"
                            src={How2}
                            alt="advisors-img"
                            width="54px"
                            height="79px"
                          />
                        </a>
                        <span>
                          <p
                            className="describeHow"
                            style={{ marginTop: "12px" }}
                          >
                            Chat securely with buyers to answer their questions
                            or negotiate a price.
                          </p>
                        </span>
                      </Col>
                    </Card>
                    <Col span={1}></Col>
                    <Card className="Howcard">
                      <div class="card_img">
                        <a>3</a>
                      </div>
                      <Col
                        className="gutter-row min-width-100-percent"
                        // class="team_member_advisors"

                        span={6}
                      >
                        <a>
                          <Typography
                            className="Howtexttitle"
                            style={{ marginLeft: "14px" }}
                          >
                            Meet-up or ship
                          </Typography>
                          <img
                            className="HowitworksImages"
                            src={How4}
                            alt="advisors-img"
                            width="54px"
                            height="79px"
                          />
                        </a>
                        <span>
                          <p
                            className="describeHow"
                            style={{ marginTop: "12px" }}
                          >
                            Arrange a local meetup or have items shipped
                            directly to the buyer.
                          </p>
                        </span>
                      </Col>
                    </Card>
                    <Col span={1}></Col>
                    <Card className="Howcard">
                      <div class="card_img">
                        <a>4</a>
                      </div>
                      <Col
                        className="gutter-row min-width-100-percent"
                        // class="team_member_advisors"

                        span={6}
                      >
                        <a>
                          <Typography
                            className="Howtexttitle"
                            style={{ marginLeft: "42px" }}
                          >
                            Get paid
                          </Typography>
                          <img
                            className="HowitworksImages"
                            src={How3}
                            alt="advisors-img"
                            width="54px"
                            height="79px"
                          />
                        </a>
                        <span>
                          <p
                            className="describeHow"
                            style={{ marginTop: "12px" }}
                          >
                            Accept payment directly through our secure app.
                          </p>
                        </span>
                      </Col>
                    </Card>
                  </Row>
                </div>
              </div>
            </div>
            <img src={shape} alt="advisors-img" width="100%" height="160px" />
          </section>
        )}
        {(isXS || isSM) && (
          <section class="about_us_section">
            <div class="row" className="Howbackground">
              <div class="container">
                <div>
                  <Row
                    gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]}
                    justify="center"
                  >
                    <Col>
                      <Typography
                        className="featuredSeller p title"
                        style={{ marginTop: "50px" }}
                      >
                        How it works
                      </Typography>
                    </Col>
                    <Col></Col>
                  </Row>
                  <div>
                    <Row gutter={16}>
                      <Col span={1}></Col>
                      <Card className="Howcard">
                        <Col className="gutter-row" span={6}>
                          <div>
                            <Col span={6}>
                              <Typography className="Howtexttitle" style={{marginBottom:"8px"}}>
                                1. Create a listing
                              </Typography>
                              <img
                                src={sellerhub}
                                alt="advisors-img"
                                width="54px"
                                height="54px"
                                style={{marginBottom:"8px"}}
                              />

                              <span>
                                <p className="describeHow">
                                  Click and upload your kids’ outgrown items in
                                  a snap.
                                </p>
                              </span>
                            </Col>
                          </div>
                        </Col>
                      </Card>
                      <Col span={1}></Col>
                      <Card className="Howcard">
                        <Col className="gutter-row" span={6}>
                          <div>
                            {" "}
                            <Col span={6}>
                              <a>
                                <Typography className="Howtexttitle"  style={{marginBottom:"8px"}}>
                                  2. Make the sale
                                </Typography>
                                <img
                                  src={How2}
                                  alt="advisors-img"
                                  width="54px"
                                  height="54px"
                                  style={{marginBottom:"8px"}}
                                />
                              </a>
                              <span>
                                <p className="describeHow">
                                  Chat securely with buyers to answer their
                                  questions or negotiate a price.
                                </p>
                              </span>
                            </Col>
                          </div>
                        </Col>
                      </Card>
                    </Row>
                    <Row gutter={16} style={{ marginTop: "12px" }}>
                      <Col span={1}></Col>
                      <Card className="Howcard">
                        <Col className="gutter-row" span={6}>
                          <div>
                            <Col span={6}>
                              <Typography className="Howtexttitle"  style={{marginBottom:"7px"}}>
                                3. Meet-up or ship
                              </Typography>
                              <img
                                src={How4}
                                alt="advisors-img"
                                width="54px"
                                height="54px"
                                style={{marginBottom:"5px"}}
                              />

                              <span>
                                <p className="describeHow">
                                  Arrange a local meetup or have items shipped
                                  directly to the buyer.
                                </p>
                              </span>
                            </Col>
                          </div>
                        </Col>
                      </Card>
                      <Col span={1}></Col>
                      <Card className="Howcard">
                        <Col className="gutter-row" span={6}>
                          <div>
                            {" "}
                            <Col span={6}>
                              <Typography className="Howtexttitle"  style={{marginBottom:"8px"}}>
                                4. Get paid
                              </Typography>
                              <a>
                                <img
                                  src={How3}
                                  alt="advisors-img"
                                  width="54px"
                                  height="54px"
                                  style={{marginBottom:"8px"}}
                                />
                              </a>
                              <span>
                                <p className="describeHow">
                                  Accept payment directly through our secure
                                  app.
                                </p>
                              </span>
                            </Col>
                          </div>
                        </Col>
                      </Card>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <img src={shape} alt="advisors-img" width="100%" height="100px" />
          </section>
        )}
      </Col>

      {/* For Mobile  */}
    </Row>
  );
};
