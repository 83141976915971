/*eslint-disable*/
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import securePay from "../assets/securepay.png";
import stripe from "../assets/stripe.png";
import protect from "../assets/protect.png";
import visa from "../assets/visa.png";
import swwondlelogo from "../assets/swwondlelogo.png"
import { getLotById } from "../Actions/lotsAction";
import { getCategoriesGroup } from "../Actions/groupAction";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  Row,
  Col,
  Typography,
  Breadcrumb,
  Input,
  Card,
  Tag,
  Button,
  Collapse,
  Avatar,
  Spin,
  Rate,
  Divider,
  Tabs,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import ProductTop from "../components/ProductTop";
import PLCart from "../components/PLCart";
import MarkNewsLetter from "../components/MarkNewsLetter";
import PPLCart from "../components/PPLCart";
import PLDescription from "../components/PLDescription";
import PLDpage from "../components/PLDpage";
import { MessageSeller } from "../components/MessageSeller";
import {
  ShareAltOutlined,
  HeartOutlined,
  LoadingOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { ReNewedWorkCard } from "../components/RenewedWork";
import { CategoryCard } from "../components/CategoryCard";
import HeadingHearts from "../assets/HeadingHearts.svg";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import ListThree from "../assets/list3.svg";
import PickUpImg from "../assets/pickup.svg";
import Rest from "../assets/rest.svg";
import PaidOne from "../assets/paid1.svg";
import MainHomePageFooter from "../components/MainHomePageFooter";
import { getPartnerProductById } from "../Actions/partnerProductAction";
import "../App.css";
import { useHistory, useParams } from "react-router";
import { PLBreadCrumb } from "../components/PLBreadCrumb";
import PMenu from "../components/PMenu";
import moment from "moment";
import SellerImg from "../assets/SellerImg.svg";
import SellerImgOne from "../assets/SellerImgOne.svg";
import SellerImgTwo from "../assets/SellerImgTwo.svg";
import TrueVector from "../assets/verifiednew.png";
import Badge1 from "../assets/Badge1.svg";
import swoondlesociety from "../assets/swoondlesociety.png";
import Badge2 from "../assets/Badge2.svg";
import Badge3 from "../assets/Badge3.svg";
import Badge4 from "../assets/Badge4.svg";
import { PLSimilarCard } from "../components/PLSimilarCard";
import { MoreItemsCard } from "../components/PLMoreItemsCard";
import { RecommendedCard } from "../components/RecommendedCard";
import SignIn from "../pages/SignIn";
import {
  followUser,
  unFollowUser,
  moreLotsFromThisSeller,
  similarItems,
  getUserRatings,
} from "../Actions/userAction";
import PL1 from "../assets/PL1.svg";
// import sellerswoondle from "../assets/sellerswoondle.png";
import swsociety from "../assets/swsociety.png";
import swbadge from "../assets/swbadge.png";
import PL2 from "../assets/PL2.svg";
import PL3 from "../assets/PL3.svg";
import PL4 from "../assets/PL4.svg";
import SubmitItemImg from "../assets/SubmitItemImg.svg";
import ScheduleImg from "../assets/ScheduleImg.svg";
import RestImg from "../assets/RestImg.svg";
import PaidImg from "../assets/PaidImg.svg";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel, { consts } from "react-elastic-carousel";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import WMBgshape from "../assets/WMBgshape.svg";
import ShopKidsImg from "../assets/ShopKidsImg.svg";
import OurTeamImg from "../assets/OurTeamImg.svg";
import PrelovedImg from "../assets/PrelovedImg.svg";
import RemoveIcon from "../assets/RemoveIcon.svg";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { humanize } from "../Utils/util";
import { getChatsWithSeller } from "../Actions/chatsAction";
import { getSellerBadges } from "../Actions/userAction";
import { getPartnerDetails } from "../Actions/partnerDetailsAction";
import { getFilterpartnerProducts } from "../Actions/partnerProductsAction";
import toolTip from "../assets/new-assets/icon-tooltip.png";
// import { moreLotsFromThisPartner } from "../Actions/partnerProductsAction";
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const partnerpdp = () => {
  const { isXS, isXL, isSM } = useBreakpoints();

  const PlSimilarCardData = [
    {
      pLImage: PL1,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL2,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL3,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL4,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL2,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL3,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL1,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL4,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL3,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL1,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL4,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL2,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
  ];
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [tabKey, setTabkey] = useState("2");
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  function callback(key) {
    // console.log(key);
  }
  // function tabsCallback(key) {
  //   console.log(key);
  // }

  const {
    lot,
    allLots,
    isGetLotByIdLoading,
    currentUser,
    isFollowUserLoading,
    isUnFollowUserLoading,
    isNotifyItemLoading,
    isMoreFromThisSellerLoading,
    isSimilarItemsLoading,
    isUnNotifyItemLoading,
    currentCart,
    isAuthenticated,
    moreLots,
    similarLots,
    sellerBadges,
    isGetChatsWithSellerLoading,
    partnerProduct,
    partnerDetails,
    isMoreFromThisPartnerLoading,
    morePartnerProducts,
    filterpartnerproducts,
    providerToken,
    isGetPartnerProductByIdLoading,
  } = useSelector((state) => {
    const { lot, isGetLotByIdLoading, allLots } = state.lots;
    const { partnerProduct, isGetPartnerProductByIdLoading } =
      state.partnerProducts;
    const { partnerDetails, isGetPartnerDetailsLoading } = state.partnerDetails;
    const {
      isMoreFromThisPartnerLoading,
      morePartnerProducts,
      filterpartnerproducts,
    } = state.products;
    const { currentCart } = state.cart;
    const { isAuthenticated, providerToken } = state.auth;
    const { isGetChatsWithSellerLoading } = state.chats;
    const {
      currentUser,
      sellerBadges,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      moreLots,
      similarLots,
      isMoreFromThisSellerLoading,
      isSimilarItemsLoading,
    } = state.user;
    return {
      lot,
      allLots,
      moreLots,
      similarLots,
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      currentCart,
      isAuthenticated,
      isGetLotByIdLoading,
      isMoreFromThisSellerLoading,
      isSimilarItemsLoading,
      isGetChatsWithSellerLoading,
      sellerBadges,
      partnerProduct,
      partnerDetails,
      isGetPartnerProductByIdLoading,
      isMoreFromThisPartnerLoading,
      morePartnerProducts,
      filterpartnerproducts,
      providerToken
    };
  });
 
  const { fastResponder, fastShipper, top10 } = sellerBadges || {};
  const { _id: currentUserId, following } = currentUser || {};
  // const {
  //   imageUrls,
  //   description,
  //   brand,
  //   name,
  //   gender,
  //   createdAt,
  //   color,
  //   inSoldPending,
  //   inUserFavorites,
  //   _id,
  //   price,
  //   condition,
  //   category,
  //   deliveryType,
  //   yearOfPurchase,
  //   negotiablePrice,
  //   location,
  //   sellerAddress,
  //   createdBy,
  //   seller,
  //   isRenewed,
  // } = lot || {};

  const {
    additionalImageLinks,
    description,
    brand,
    title,
    gender,
    createdAt,
    color,
    inSoldPending,
    inUserFavorites,
    _id,
    price,
    condition,
    category,
    deliveryType,
    yearOfPurchase,
    negotiablePrice,
    location,
    sellerAddress,
    createdBy,
    seller,
    isRenewed,
  } = partnerProduct || {};
  const { lots } = allLots || { lots: [] };
  const {
    _id: sellerId,
    fullName,
    photo,
    activeLotsCount,
    soldLotsCount,
    createdAt: SellerCreatedAt,
  } = seller || {};
  const { name: sellerName } = sellerAddress || { sellerName: "" };
  const { productId } = params;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPartnerProductById(productId));
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getLotById(productId));
    dispatch(getSellerBadges(sellerId));
   // dispatch(moreLotsFromThisSeller({ id: createdBy, limit: 10 }));
    //dispatch(similarItems({ id: productId, limit: 10 }));
    dispatch(getUserRatings(createdBy));
    dispatch(getCategoriesGroup("categories"));
  }, [params, createdBy]);
  useEffect(() => {
    if (partnerProduct) {
      if (partnerProduct.createdBy) {
        dispatch(getPartnerDetails(partnerProduct.createdBy));
      }
    }
  }, [partnerProduct]);
  useEffect(() => {
    if (filterpartnerproducts && filterpartnerproducts.length == 0) {
      let url = `/api/v5/partner-products/search?category=`;
      dispatch(getFilterpartnerProducts(null, url));
    }
  }, [filterpartnerproducts]);
  const Listed = moment(createdAt).format("MMMM YYYY");
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleFollowUser = () => {
    if (isAuthenticated) {
      dispatch(followUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleUnFollowUser = () => {
    if (isAuthenticated) {
      dispatch(unFollowUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleMessageSeller = () => {
    if (isAuthenticated) {
      dispatch(
        getChatsWithSeller(
          _id,
          sellerId,
          messageSellerSuccess,
          messageSellerFailure
        )
      );
      // history.push(`/messages`)
    } else {
      history.push("/onboarding");
    }
  };
  const messageSellerSuccess = () => {
    history.push("/chat");
  };
  const messageSellerFailure = () => {};
  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  const onTabClick = (key) => {
    setTabkey(key);
  };
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <LeftOutlined /> : <RightOutlined />;
    return (
      <button
        style={{
          background: "transparent",
          border: "none",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          fontSize: 25,
          fontWeight: "bolder",
          color: isEdge ? "#E4E4E4" : "#393939",
          cursor: "pointer",
        }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };
  const myDots = ({ pages, activePage, onClick }) => {
    return (
      <Row
        justify="center"
        style={{
          width: "100%",
          padding: "0px 15px",
          zIndex: 9,
        }}
      >
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              style={{
                borderRadius: "50%",
                background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                width: 15,
                height: 15,
                margin: isXS ? "23px 5px 0px" : "70px 5px 0px",
                cursor: "pointer",
              }}
              onClick={() => onClick(page)}
              // active={isActivePage}
            />
          );
        })}
      </Row>
    );
  };


  const forSellerUser = createdBy === currentUserId;
  return (
    <div>
      <div className="pDetailBackground">
        <div className="app-wrapper pdp">
          <Row gutter={[0, { xs: 0, sm: 0, md: 30, lg: 40, xl: 40 }]}>
            <Col span={24}></Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
            >
              <Row justify="space-between" gutter={[0, 0]}>
                <Col>
                  <PLBreadCrumb
                    className="pDetailBreadCrumb"
                    markid="Home"
                    category={category && humanize(category)}
                    brand={capitalize(brand)}
                    name={capitalize(title)}
                  />
                </Col>
                {/* <Col>
              <ShareAltOutlined className="sharedIcon" color="#393939"/>
            </Col> */}
              </Row>
            </Col>
          </Row>

          <Row gutter={[0, { xs: 0, sm: 0, md: 23, lg: 23, xl: 23 }]}>
            <Col span={24}></Col>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="pdp-left"
            >
              {/* <Card bordered={false}> */}
              <PLDpage
                productData={partnerProduct}
                sellerInfo={seller}
                productLoader={isGetPartnerProductByIdLoading}
              />

              <div className="addtocartweb">
                <div className="plsimilarcenter">
                  {/* <Divider style={{ color: "#707070", margin: "30px 0px" }} /> */}
                  <Typography
                    className="plsimilarpdp"
                    onClick={() => {
                      gaEventClick("PDP", "Sell on markid");
                      providerToken? 
                      history.push(`/seller/addproduct`) :  history.push(`/onboarding`);
                    }}
                  >
                    Have a similar item?
                    <span className="plsimilarpdp1">
                      Sell it on Markid
                    </span>{" "}
                  </Typography>
                  {/* <Divider style={{ color: "#707070", margin: "30px 0px" }} /> */}
                </div>
                <div className="addtocartweb">
                  <Row gutter={[0, 24]}>
                    <Col>
                      {isGetLotByIdLoading ? (
                        <Skeleton active={isGetLotByIdLoading} size="small" />
                      ) : (
                        <Typography className="sellertitlepdp">
                          Meet the seller
                        </Typography>
                      )}
                    </Col>
                    <Col span={24} />
                  </Row>
                  <Row gutter={[0, 0]}>
                    <Col xl={4}>
                      {isGetLotByIdLoading ? (
                        <Skeleton.Image
                          active={isGetLotByIdLoading}
                          size="small"
                        />
                      ) : photo && photo ? (
                        <LazyLoadImage
                          effect="blur"
                          shape="square"
                          style={{
                            height: 120,
                            width: 109,
                            cursor: "pointer",
                            borderRadius: "50%",
                          }}
                          src={photo}
                          onClick={() =>
                            history.push(`/seller-profile/${sellerId}`)
                          }
                        />
                      ) : (
                        <img
                          alt=""
                          src={swwondlelogo}
                          style={{ cursor: "pointer" }}
                          
                        />
                      )}
                      <Row gutter={[8, 9]}>
                        <Col span={24}></Col>
                        {isGetLotByIdLoading ? (
                          <Skeleton.Button
                            active={isGetLotByIdLoading}
                            size="small"
                          />
                        ) : (
                          <Text className="pLVerifiedpdp style2">
                            <img src={TrueVector} width="13px" />
                            &nbsp;Verified
                          </Text>
                        )}
                      </Row>
                    </Col>
                    <Col xl={20}>
                      <Row gutter={[0, 3]}>
                        {isGetLotByIdLoading ? (
                          <Skeleton.Input
                            active={isGetLotByIdLoading}
                            size="small"
                          />
                        ) : (
                          
                            <Text
                              className="Plsellernamepdp"
                              style={{ cursor: "pointer" }}
                              
                            >
                              {capitalize("Swoondle Society")}
                            </Text>
                           
                        )}
                        <Col span={3} />
                      </Row>
                      <Row
                        gutter={[0, 15]}
                        align="middle"
                        className="align-center"
                      >
                        {isGetLotByIdLoading ? (
                          <Skeleton.Button
                            active={isGetLotByIdLoading}
                            size="small"
                          />
                        ) : (
                          <Rate
                            defaultValue={5}
                            className="pLRatepdp"
                            disabled={true}
                          />
                        )}
                        {/* {isGetLotByIdLoading ? (
                          <Skeleton active={isGetLotByIdLoading} size="small" />
                        ) : (
                          <Text className="pLListedItempdp">
                            <span className="ItemCount">
                              {"65"}
                            </span>{" "}
                            items listed
                          </Text>
                        )} */}
                        {/* <Text className="pLListedItempdp1">|</Text>
                        {isGetLotByIdLoading ? (
                          <Skeleton active={isGetLotByIdLoading} size="small" />
                        ) : (
                          <Text className="pLListedItempdp">
                            <span className="ItemCount">{"21"}</span>{" "}
                            sold
                          </Text>
                        )} */}

                        <Typography className="pLListedItemdesc">
                        This product is screened, photographed & verified by our brand partner Swoondle Society. <br/>
                        Free Shipping nationwide over $50. This item is eligible for Free Returns
                        </Typography>

                        <Col span={15} />
                      </Row>
                     
                     
                    </Col>
                  </Row>
                  <Divider style={{ color: "#707070", margin: "30px 0px" }} />

                  {/* <Divider style={{ color: '#707070', margin: "30px 0px" }} /> */}
                </div>
              </div>
            </Col>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="pdp-right"
            >
              {/* <Card bordered={false}> */}
              <PLCart
                productData={partnerProduct}
                sellerInfo={seller}
                productLoader={isGetPartnerProductByIdLoading}
              />

              <div className="addtocartweb">
                <Divider style={{ color: "#707070", margin: "30px 0px" }} />{" "}
                <Row gutter={[0, 0]}>
                  <Col>
                    <img alt="" src={securePay} style={{ cursor: "pointer" }} />
                  </Col>
                  <Col
                    xl={{ span: 18, offset: 1 }}
                    sm={{ span: 18, offset: 1 }}
                    xs={{ span: 18, offset: 1 }}
                  >
                    <Row gutter={[0, 3]}>
                      <Text
                        className="Plsecurepdp"
                        style={{ cursor: "pointer" }}
                      >
                        Secure payments
                      </Text>
                      <Col span={3} />
                    </Row>
                    <Row>
                      <Typography className="pLListedItemdesc">
                        We keep your payment information secure using stripe.
                        Markid sellers will never receive your credit card
                        information.
                      </Typography>

                      <Col span={15} />
                    </Row>
                    <Row>
                      <Col span={4}>
                        <img src={stripe} />
                      </Col>
                      <Col span={16}>
                        <img src={visa} />
                      </Col>
                    </Row>
                  </Col>
                  <Divider style={{ color: "#707070", margin: "30px 0px" }} />
                  <Col>
                    <img alt="" src={protect} style={{ cursor: "pointer" }} />
                  </Col>
                  <Col xl={{ span: 18, offset: 1 }}>
                    <Row gutter={[0, 3]}>
                      <Text
                        className="Plsecurepdp"
                        style={{ cursor: "pointer" }}
                      >
                        Markid’s buyer protection guarantee
                      </Text>
                      <Col span={3} />
                    </Row>
                    <Row>
                      <Typography className="pLListedItemdesc">
                        Your transactions are protected. All payments are held
                        until you receive your item, or 100% refund guaranteed.
                      </Typography>

                      <Col span={15} />
                    </Row>
                  </Col>

                  {/* <Row gutter={[0, 3]}>
                    
                   </Row> */}
                </Row>
              </div>

             
            </Col>
            <Col
              xl={{ span: 14, offset: 0 }}
              lg={14}
              md={14}
              sm={24}
              xs={24}
              className="pdp-left"
            ></Col>
          </Row>
          <Row
            gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }]}
            justify="start"
          >
            <Col span={24}></Col>
            <Col xl={{ span: 16, offset: 0 }} xs={{ span: 24, offset: 0 }}>
              {isRenewed && (
                <>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Collapse
                        style={{ backgroundColor: "white" }}
                        defaultActiveKey={["0"]}
                        onChange={callback}
                        expandIconPosition="right"
                        bordered={false}
                        ghost
                        expandIcon={({ isActive }) => {
                          return !isActive ? (
                            <DownOutlined className="collapsIcon" />
                          ) : (
                            <UpOutlined className="collapsIcon" />
                          );
                        }}
                      >
                        <Panel
                          header={
                            <Text className="pLPanelTitle">
                              What is markid reNEWED?
                            </Text>
                          }
                          key="1"
                        >
                          <Row
                            gutter={[
                              0,
                              {
                                xs: 20,
                                sm: 20,
                                md: 25,
                                lg: 25,
                                xl: 25,
                                xxl: 25,
                              },
                            ]}
                          >
                            <Col span={24}></Col>
                            <Col>
                              <Typography className="pLDes">
                                ReNEWED is our hand-picked selection of
                                pre-loved items that are inspected,
                                professionally cleaned, sanitized, and restored
                                to a lovingly reNEWED state. Just the way you’d
                                like them - for your kids.
                              </Typography>
                            </Col>
                          </Row>

                          <Row
                            gutter={[
                              48,
                              {
                                xs: 30,
                                sm: 30,
                                md: 33,
                                lg: 33,
                                xl: 33,
                                xxl: 33,
                              },
                            ]}
                          >
                            <Col span={24}></Col>
                            <Col xl={8} lg={8} md={12} sm={22} xs={22}>
                              <Row>
                                <img alt="" src={ShopKidsImg} />
                              </Row>
                              <Row gutter={[0, 10]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRTitle">
                                  Hand-picked for you
                                </Typography>
                              </Row>
                              <Row gutter={[0, 7]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRDec">
                                  Markid reNEWED is a collection of top brands
                                  and items that you want for your family. From
                                  strollers to high chairs, we sell the best
                                  items for your kids, all in one place.
                                </Typography>
                              </Row>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={22} xs={22}>
                              <Row>
                                <img alt="" src={OurTeamImg} />
                              </Row>
                              <Row gutter={[0, 10]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRTitle">
                                  Quality Assured
                                </Typography>
                              </Row>
                              <Row gutter={[0, 7]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRDec">
                                  All reNEWED products are professionally
                                  sanitized, cleaned, restored, and tested for
                                  functionality by our team. We bring all items
                                  back to a reNEWED state so you get the most
                                  out of your pre-loved kids’ gear.
                                </Typography>
                              </Row>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={22} xs={22}>
                              <Row>
                                <img alt="" src={PrelovedImg} />
                              </Row>
                              <Row gutter={[0, 10]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRTitle">
                                  The Sustainable, affordable choice
                                </Typography>
                              </Row>
                              <Row gutter={[0, 7]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRDec">
                                  ReNEWED gives you top items at a discounted
                                  price, so you don’t have to sacrifice your
                                  wallet for items you love. By purchasing
                                  pre-loved, you and your family help extend the
                                  life of kids’ gently-used items - and make a
                                  better choice for you and for the planet.
                                </Typography>
                              </Row>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </>
              )}
            </Col>

            <Col xl={{ span: 8, offset: 0 }}>
              {isRenewed && (
                <div className="reNewedInfoweb">
                  <Row gutter={[0, 0]}>
                    <Col span={24}>
                      <Card className="rIBackground">
                        <Row>
                          <Col>
                            <Typography className="rIText">
                              reNEWED Information
                            </Typography>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="rIDesBackground">
                        <Row justify="start">
                          <Col>
                            <Typography className="riDes">
                              The simplest way to sell kid's gently used,
                              high-quality furniture and gear-effortlessly,
                              sustainably and purposefully.
                            </Typography>
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                          <Col span={24}></Col>
                          <Col span={24}>
                            <Button
                              block
                              className={"button button-bordered pLMakeOffer"}
                            >
                              KNOW MORE
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
            <Col span={24}></Col>
          </Row>
        </div>
      </div>
      <div className="">
        <Spin
          style={{ top: 200, color: "#FF533F" }}
          indicator={antIcon}
          spinning={isMoreFromThisSellerLoading}
        >
          <Row
            gutter={[
              0,
              { xs: 70, sm: 70, md: 120, lg: 120, xl: 120, xxl: 120 },
            ]}
            justify="center"
          >
            <Col span={24}></Col>
          </Row>
        </Spin>
        <Spin
          style={{ top: 200, color: "#FF533F" }}
          indicator={antIcon}
          spinning={isSimilarItemsLoading}
        >
          <Row
            gutter={[
              0,
              { xs: 70, sm: 70, md: 120, lg: 120, xl: 120, xxl: 120 },
            ]}
            justify="center"
          >
            <Col span={24}></Col>
          </Row>

          <div className="commonShowcase ProductsFromThisSeller">
            <div className="app-wrapper">
              <Typography className="sectionTitles">
                More From This Seller{" "}
                {/* <a onClick={() => history.push(`/seller-profile/${sellerId}`)}>
                  see all
                </a> */}
              </Typography>
              <Row className="container">
                {filterpartnerproducts &&
                  filterpartnerproducts.map((val, i) => {
                    if (i <= 4) {
                      return (
                        <Col
                          xl={4}
                          lg={{ span: 4 }}
                          md={24}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <PLSimilarCard similarItems={val} />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </div>
          </div>
          <div className="commonShowcase ProductsSimilar">
            <div className="app-wrapper">
              <Typography className="sectionTitles">
                Similar items{" "}
                {/* <a
                  onClick={() => history.push(`/simillar-product/${productId}`)}
                >
                  see all
                </a> */}
              </Typography>
              <Row className="container">
                {filterpartnerproducts &&
                  filterpartnerproducts.map((val, i) => {
                    if (i >4 && i<=9) {
                      return (
                        <Col
                          xl={4}
                          lg={{ span: 4 }}
                          md={24}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <PLSimilarCard similarItems={val} />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </div>
          </div>
        </Spin>
      </div>
    
      <MarkNewsLetter />

{/*  morePartnerProducts,
      filterpartnerproducts, */}
      <MessageSeller
        onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)}
        showMessageSellerDialog={showMessageSellerDialog}
        lotDetail={lot}
      />
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </div>
  );
};
export default partnerpdp;
