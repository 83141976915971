import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Checkbox,
  Spin,
  Alert,
  Typography
} from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ProductTop from "../components/ProductTop";
import PMenu from "../components/PMenu";
import { CheckoutAddress } from "../components/CheckoutAddress";
import OrderSummary from "../components/OrderSummary";
import PriceDetails from "../components/PriceDetails";
import "../App.css";
import _ from "lodash";
import CheckoutTopBar from "../components/CheckoutTopBar";
import { createCart, getCurrentUserWallet, getLastCart } from "../Actions/cartAction";
import SignIn from "./SignIn";
import { useGaEvent } from "../hooks/useGaEvent";

const CheckoutProcess = (props) => {
  const { gaEventClick } = useGaEvent();
  // const {innerHeight} = window;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const history = useHistory();
  const [current,setCurrent]=useState(0)
  const { nextMethod } = props;
  const { currentCart, useCredits,lastCart, currentAddressId, currentUser, currentUserWallet, isPostCartLoading, isAuthenticated, currentCartId } = useSelector((state) => {
    const { currentCart,lastCart, useCredits, currentAddressId, currentUserWallet, isPostCartLoading, currentCartId } = state.cart;
    const { currentUser } = state.user;
    const { isAuthenticated } = state.auth;
    return {
      currentCart,
      currentAddressId,
      currentUserWallet,
      currentUser,
      isPostCartLoading,
      isAuthenticated,
      currentCartId,
      useCredits,
      lastCart
    };
  });
  const [addressError, setAddressError] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [shippingCharges, setShippingCharges] = useState(0)
  const [open, setOpen] = React.useState(false);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCreateCart = () => {
    const data = {}
    data.cartId = currentCartId
    data.lots = _.map(currentCart, '_id');
    //data.total = (_.sumBy(currentCart, function (o) { return o.price; }) / 100).toFixed(2)
    data.addressId = currentAddressId
    data.email = currentUser.email
    if (isAuthenticated) {
      gaEventClick('Checkout', 'Proceed'); 
      if (currentAddressId) {
        //dispatch(createCart(data, successCallBack, failureCallBack));
        successCallBack();
      }

      else {
        setAddressError(true)
      }
    }
    else {
      setShowSignInDialog(true)
    }

  };
  const successCallBack = () => {
      setCurrent(1);
      history.push({pathname:'/payment-info'})
   
  };
  const failureCallBack = () => { };
  let tempPrice = 0;
  useEffect(() => {
    if (currentCart && currentCart.length > 0) {
      currentCart.map((cartItems) => {
        if (cartItems.isFree == false) {
          tempPrice = parseInt(cartItems.shippingPrice) + tempPrice
          setShippingCharges(tempPrice)
        }
      })
    }
  }, [currentCart])
  return (
    <>
     <CheckoutTopBar current={current}/>
      <Spin indicator={antIcon} spinning={isPostCartLoading}>
        <div className='checkoutBG'>
          <div className="app-wrapper">
            <Row gutter={[0, { xs: 30, sm: 30, md: 38, lg: 38, xl: 38, xxl: 38 }]} className="checkoutHead">
              {/* <Col span={24}></Col> */}
              <Col offset={0}>
                <Row gutter={[28, 0]}>
                  <Col>
                    <Row gutter={[0, { xs: 5, sm: 5, md: 8, lg: 8, xl: 8, xxl: 8 }]}>
                      <Col span={24}></Col>
                      <ArrowLeftOutlined className='checkoutBackIcon' onClick={() => history.goBack()} />
                    </Row>
                  </Col>
                  <Col className="checkoutTitle">
                    <h1 className='afterBackIconTitle'>
                      Checkout
                    </h1>
                    <Row gutter={[0, 8]}>
                      <Col span={24}></Col>
                      <Typography className='totalCartItemText'>
                        You have {currentCart.length} items in your cart
                        {/* {currentCart.length > 1 ? 'items' : 'item'}) items in your cart*/}
                      </Typography>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="center" className="checkoutProcess" gutter={[0, { xs: 20, sm: 20, md: 35, lg: 35, xl: 35, xxl: 35 }]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Row gutter={[0, 0]} justify='space-between' className="shoppingCartParent page-checkout-process" >
                  {/* <Col span={24}></Col> */}
                  <Col className="shoppingCart-leftpane">
                    <CheckoutAddress cartCard={currentCart} setAddressError={setAddressError} />
                  </Col>
                  <Col className="shoppingCart-rightpane">
                    <Row gutter={[0, { xs: 50, sm: 50, md: 0, lg: 0, xl: 0, xxl: 0 }]} className="priceDetailParent">
                      <Col span={24} />
                      <Card className='priceDetailsCard'>
                        <PriceDetails
                          heading="Price details"
                          itemTotal={`Items total (${currentCart.length})`}
                          itemTotalVal={(_.sumBy(currentCart, function (o) { return o.price; }) / 100).toFixed(2)}
                          shipping="Shipping"
                          //shippingVal="Free"
                          //  shippingVal={(shippingCharges / 100).toFixed(2)}
                           shippingVal={(_.sumBy(currentCart, function (o) { return o.isFree === false ? (parseFloat(o.shippingPrice) || 0) : 0 ; }) / 100).toFixed(2)}
                          salesTax="Sales Tax"
                          salesTaxVal={(0).toFixed(2)}
                          cartTotal="Cart Total"
                          cartTotalVal="199.61"
                          button={<Button
                            block
                            disabled={currentCart.length === 0}
                            className={'button button-primary proceed-btn'}
                            onClick={() => handleCreateCart()}
                          >
                            PROCEED
                          </Button>}
                        />
                        {!currentAddressId && (
                          <Row justify="center" gutter={[0, 8]}>
                            <Col span={24}></Col>
                            <Col>
                              <Alert
                                type="error"
                                onClose={handleClose}
                                open={open}
                                message='Please Select address'
                              // closable
                              // onClose={onClose}
                              ></Alert>
                            </Col>
                          </Row>
                        )}
                      </Card>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default CheckoutProcess;