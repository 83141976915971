import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Card, Typography, Row, Col, Layout, Avatar, Button, Divider, Input, Spin, Affix } from "antd";
import { MoreOutlined, CloseOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getMessagesByChatId, postMessageByChatId } from "../Actions/chatsAction";
import { getOffers } from "../Actions/offerAction";
import { Type0, Type1 } from "../components/TypeOfMessages"
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { useHistory } from "react-router-dom";
import { MakeOffer } from "../components/MakeOffer";
const { Header, Content } = Layout
export const ChatingSection = (props) => {
  const dispatch = useDispatch()
  const { messagesByChatId, currentUser, isGetMessagesByChatIdLoading, offers, isAuthenticated } = useSelector((state) => {
    const { messagesByChatId, isGetMessagesByChatIdLoading } = state.chats
    const { currentUser } = state.user
    const { offers } = state.offer
    const { isAuthenticated } = state.auth
    return {
      messagesByChatId,
      currentUser,
      isGetMessagesByChatIdLoading,
      offers,
      isAuthenticated
    };
  });

  const [showMakeOfferDialog, setShowMakeOfferDialog] = useState(false);
  const history = useHistory();
  const { status, negotiablePrice } = offers || {}
  const { messages } = messagesByChatId || {}
  const { _id: currentUserId, blockedUsers } = currentUser
  const [questions, setQuestions] = useState(false)
  const [makeOffer, setMakeOffer] = useState(false)
  const [priceOffVal, setPriceOffVal] = useState("");
  const [lastType1MessageId, setLastType1MessageId] = useState('');
  const [chatLastAcceptedOfferMessageId, setchatLastAcceptedOfferMessageId] = useState('');
  const [chatCounterOfferMessageId, setChatCounterOfferMessageId] = useState('');
  const { userChating, currentChat, ChatListData } = props
  const { chat, lot, interlocutor } = currentChat || {}
  const { photo, fullName } = interlocutor || {}
  const { name, price, deliveryType, createdBy: LotCreatedBy, imageUrls, seller } = lot || {}
  const { _id, offerId, participants } = chat || {}
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: { message: '' }
  });
  const { handleSubmit: mOHandleSubmit, control: mOControl, reset: mOReset, errors: moErrors, setValue: setFormValues } = useForm({
    defaultValues: { price: '' }
  });
  const watching = watch()
  const { message } = watching;

  let isBlocked;
  let participantsId = participants && participants[1];
  blockedUsers && blockedUsers.map((user) => {
    return (user === participantsId) && (isBlocked = user)
  });

  const handleQuestionsClick = () => {
    setQuestions(true)
    setMakeOffer(false)
  }

  const handleMakeOfferClick = () => {
    setQuestions(false)
    setMakeOffer(true)
  }
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  useEffect(() => {
    dispatch(getMessagesByChatId(_id));
    /* 20220118 Varun: Commented dispatch(getOffers(offerId, _id)) as it's getting called in [messages] use effect and messages[] gets updated by dispatch(getMessagesByChatId(_id))*/
    // dispatch(getOffers(offerId, _id))
  }, [_id]);
  const handleSendMessage = (data) => {
    data.type = 0
    dispatch(postMessageByChatId(_id, data))
    reset()
  }
  const handleSendOffer = (data) => {
    data.type = 0
    dispatch(postMessageByChatId(_id, data))
    reset()
  }
  const handlePriceOff = (offPriceInputVal) => {
    setFormValues("price", offPriceInputVal)
    setPriceOffVal(offPriceInputVal)
  }
  const handleOpenMakeAnOffer = () => {
    if (isAuthenticated) {
      setShowMakeOfferDialog(true);
    } else {
      history.push("/onboarding");
    }
  };


  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => {
    scrollToBottom()
    if (messages) {
      let newLastType1MessageId = '';
      let newchatLastAcceptedOfferMessageId = '';
      let newchatCounterOfferMessageId = '';
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].type !== 0 && newLastType1MessageId === '') {
          newLastType1MessageId = messages[i]._id;
        }
        if ((messages[i].type === 3 || messages[i].type === 4) && newchatLastAcceptedOfferMessageId === '') {
          newchatLastAcceptedOfferMessageId = messages[i]._id;
        }
        if ((messages[i].type === 1 || messages[i].type === 2) && newchatCounterOfferMessageId === '') {
          newchatCounterOfferMessageId = messages[i]._id;
        }
        if (newchatLastAcceptedOfferMessageId !== '' && newLastType1MessageId !== '' && newchatCounterOfferMessageId !== '') {
          break;
        }
      }
      setLastType1MessageId(newLastType1MessageId);
      setchatLastAcceptedOfferMessageId(newchatLastAcceptedOfferMessageId);
      setChatCounterOfferMessageId(newchatCounterOfferMessageId);
    }
    dispatch(getOffers(offerId, _id))
  }, [messages]);


  const tenPercent = price * 10 / 100
  const tenPercentVal = price - tenPercent

  const sixPercent = price * 6 / 100
  const sixPercentVal = price - sixPercent
  const { innerHeight } = window;

  const threePercent = price * 3 / 100
  const threePercentVal = price - threePercent
  if (ChatListData && ChatListData.length > 0) {
    return (
      <div>
        <Layout style={{ height: innerHeight - 214, overflow: 'hidden' }}>
          <Header className='chatHeader'>
            <Row justify='space-between' className="w-100">
              <Col>
                <Row gutter={[8.56, 0]}>
                  <Col>
                    {photo ?
                      <Avatar shape='circle' size={50} src={photo} />
                      :
                      <Avatar shape='circle' size={50} src={UserBlanckImg} />
                    }
                  </Col>
                  <Col>
                    <Row gutter={[0, 25]}>
                      <Col span={24}></Col>
                      <Typography className='pMenu'>
                        {fullName}
                      </Typography>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={[20, 0]} align="middle">
                  <Col className="no-padding">
                    <Row gutter={[0, 0]}>
                      <Col span={24}></Col>
                      <Typography className='moniterdByMarkidText'>
                        All messages are monitered by markid
                      </Typography>
                    </Row>
                  </Col>
                  <Col className="no-padding moreIcon-wrap">
                    <MoreOutlined className='moreIcon' />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>

          <Row className="chatHeaderNext">
            <Col span={24}>
              <Card className='chatItemNameCard'>
                <Row align='top'>
                  <Col md={4} lg={2} xl={2} xxl={2}>
                    <Avatar shape='square' style={{ borderRadius: '5' }} size={45} src={imageUrls && imageUrls[0]} onClick={() => history.push(`/product-list/${name}/${props.currentChat.lot._id}`)} />
                  </Col>
                  <Col md={20} lg={22} xl={22} xxl={22}>
                    <Row>
                      <Col>
                        <Typography className='chatItemname'>
                          {name &&
                            capitalize(name)
                          }
                        </Typography>
                      </Col>
                    </Row>
                    <Row justify='space-between' gutter={[0, 3]}>
                      <Col span={24}></Col>
                      <Col>
                        <Typography className='chatPriceVal'>
                          Price - ${price ? price / 100 : null}
                        </Typography>
                      </Col>
                      <Col>
                        <Row justify='end' gutter={[0, 10]}>
                          <Col span={24}></Col>
                          <Typography className='chatShippingVal'>
                            {deliveryType &&
                              capitalize(deliveryType)
                            }
                          </Typography>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div className='chatContent' style={{ height: innerHeight - 300, overflow: 'hidden', padding: '10px 0px' }}>
            <Content>
              {/* <Row justify='center' align='bottom' style={{ height: makeOffer || questions ? 195 : 369, overflow: 'hidden', overflowY: 'scroll' }}> */}
              <Row justify='center' align='bottom' className="chatScroll" style={{ height: makeOffer || questions ? 195 : innerHeight - 460, overflow: 'hidden', overflowY: 'auto' }}>
                <Col span={24}>
                  {isGetMessagesByChatIdLoading &&
                    <Row justify='center'>
                      <Spin spinning={isGetMessagesByChatIdLoading} size='large' />
                    </Row>
                  }
                  {/* <Content className='chatWindow'> */}
                  {/* {messages && messages.slice(0).reverse().map((val, i) => { */}
                  {messages && messages.slice(0).map((val, i) => {
                    if (val.createdBy !== currentUserId) {
                      return (
                        <Row gutter={[7.45, 48]} key={i}>
                          <Col>
                            {photo ?
                              <Avatar shape='circle' size={36.8} src={photo} />
                              :
                              <Avatar shape='circle' size={36.8} src={UserBlanckImg} />
                            }
                          </Col>
                          <Col>
                            <Row>
                              {val.type === 0 ?
                                <Type0 cName="reciverMessageCard"
                                  messageData={val}
                                  type='reciver'
                                />
                                :
                                <Type1 cName="reciverMessageCard"
                                  messageData={val}
                                  offerId={offerId}
                                  chatId={_id}
                                  type='reciver'
                                  messageType={val.type}
                                  deliveryType={deliveryType}
                                  LotCreatedBy={LotCreatedBy}
                                  currentUserId={currentUserId}
                                  lastType1MessageId={lastType1MessageId}
                                  chatLastAcceptedOfferMessageId={chatLastAcceptedOfferMessageId}
                                  chatCounterOfferMessageId={chatCounterOfferMessageId}
                                  messageId={val._id}
                                  productData={lot}
                                />
                              }
                            </Row>
                          </Col>
                          <Col span={24}></Col>
                        </Row>
                      )
                    }
                    else {
                      // senderInformation
                      return (
                        <>
                          <Row gutter={[0, 30]} justify='end' key={i}>
                            <Col>
                              <Row>
                                {val.type === 0 ?
                                  <Type0 cName="senderMessageCard"
                                    messageData={val}
                                    type='sender'
                                  />
                                  :
                                  <Type1 cName="senderMessageCard"
                                    messageData={val}
                                    offerId={offerId}
                                    chatId={_id}
                                    type='sender'
                                    messageType={val.type}
                                    // productData={props.lotData}
                                    productData={lot}
                                    lastType1MessageId={lastType1MessageId}
                                    chatLastAcceptedOfferMessageId={chatLastAcceptedOfferMessageId}
                                    chatCounterOfferMessageId={chatCounterOfferMessageId}
                                    messageId={val._id}
                                    deliveryType={deliveryType}
                                  />
                                }
                              </Row>
                            </Col>
                            <Col span={24}></Col>
                          </Row>
                        </>
                      )
                    }
                  })}
                  <div ref={messagesEndRef} />
                </Col>
              </Row>
              {isBlocked === participantsId ?
                <Row></Row>
                :
                <Affix offsetBottom={20} style={{ position: "sticky", zIndex: 10 }}>
                  <div>
                    <Row justify='center'>
                      <Col span={22}>
                        {questions &&
                          <>
                            <CloseOutlined onClick={() => setQuestions(false)} className='closeBg' />
                            <Row justify='center'>
                              <Col span={24}>
                                <Card className='makeOfferCard'>
                                  <Row gutter={[10, 10]} style={{ overflowX: 'scroll' }}>
                                    <Col span={24}></Col>
                                    <Col>
                                      <Button className='questionsTextButton'>
                                        What is the condition of the stroller?
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button className='questionsTextButton'>
                                        How many years old is the stroller?
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button className='questionsTextButton'>
                                        Is it foldable?
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button className='questionsTextButton'>
                                        More
                                      </Button>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </>
                        }
                        {makeOffer &&
                          <>
                            <CloseOutlined onClick={() => setMakeOffer(false)} className='closeBg' />
                            <form onSubmit={mOHandleSubmit()}>
                              <Row justify='center'>
                                <Col span={24}>
                                  <Card className='makeOfferCard'>
                                    <Row>
                                      <Col span={6}>
                                        <Typography className='mOCurrentPrice'>
                                          Current price - $ {price ? price / 100 : null}
                                        </Typography>
                                      </Col>
                                      <Divider type='vertical' className='mODivider' />
                                      <Col span={9}>
                                        <Row gutter={[10, 0]} justify='center'>
                                          <Col>
                                            <Button className='questionsTextButton'
                                              onClick={() => handlePriceOff(tenPercentVal / 100)}
                                            >
                                              $ {tenPercentVal / 100}
                                            </Button>
                                            <Row justify='center' gutter={[0, 5]}>
                                              <Col span={24}></Col>
                                              <Typography className='offPercentage'>
                                                10% off
                                              </Typography>
                                            </Row>
                                          </Col>
                                          <Col>
                                            <Button className='questionsTextButton'
                                              onClick={() => handlePriceOff(sixPercentVal / 100)}
                                            >
                                              $ {sixPercentVal / 100}
                                            </Button>
                                            <Row justify='center' gutter={[0, 5]}>
                                              <Col span={24}></Col>
                                              <Typography className='offPercentage'>
                                                6% off
                                              </Typography>
                                            </Row>
                                          </Col>
                                          <Col>
                                            <Button className='questionsTextButton'
                                              onClick={() => handlePriceOff(threePercentVal / 100)}
                                            >
                                              $ {threePercentVal / 100}
                                            </Button>
                                            <Row justify='center' gutter={[0, 5]}>
                                              <Col span={24}></Col>
                                              <Typography className='offPercentage'>
                                                3% off
                                              </Typography>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Divider type='vertical' className='mODivider' />
                                      <Col span={8}>
                                        <Row gutter={[21.45, 0]} justify='end'>
                                          <Col span={22}>
                                            <Controller
                                              as={<Input />}
                                              placeholder='$ Offer price'
                                              className="phoneInput"
                                              size='large'
                                              addonBefore="$"
                                              name="price"
                                              addonAfter={
                                                <Button
                                                  bordered='false' htmlType='submit' className="moSendButton"
                                                >Send
                                                </Button>
                                              }
                                              // name="message"
                                              control={mOControl}
                                            />
                                          </Col>
                                          {/* <Col>
                              </Col> */}
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </form>
                          </>
                        }
                      </Col>
                    </Row>

                    <Row gutter={[0, 10]} justify='center'>
                      <Col span={24}>
                        <Card style={{ borderRadius: makeOffer ? "unset" : '10px' }} bordered={false} className='chatboxCard'>
                          <Row justify='space-between' className="offerOptions">
                            {(status === "CANCELED_BY_BUYER" || status === "CANCELED_BY_SELLER") && LotCreatedBy !== currentUserId &&
                              <Col>
                                <Row gutter={[25.25, 0]}>
                                  {/* <Col>
                        <Button
                          type='text'
                          className={questions === true ? 'selectedquestionsButton' : 'questionsButton'}
                          onClick={() => handleQuestionsClick()}
                        >
                          Questions
                        </Button>
                      </Col> */}
                                  {(negotiablePrice && status === 'CANCELED_BY_BUYER') ? <Col>
                                    <Button
                                      type='text'
                                      className={makeOffer === true ? 'selectedquestionsButton' : 'questionsButton'}
                                      onClick={() => handleOpenMakeAnOffer()}
                                    // onClick={() => handleMakeOfferClick()}
                                    >
                                      Make Offer
                                    </Button>
                                  </Col> : <></>}
                                </Row>
                              </Col>
                            }
                            {/* {
                  (status === "ACCEPT_BY_SELLER") ? 
                  <Col>
                    <Button
                      type='text'
                      className={status === "ACCEPT_BY_SELLER"}
                      // onClick={() => handleMakeOfferClick()}
                    >
                      Send Meet Up Location
                    </Button>
                  </Col>
                  : ''
                  } */}
                            <Col

                              // xl={
                              //   (status === "CANCELED_BY_BUYER" || status === "CANCELED_BY_SELLER")? 14 : 24
                              // }
                              span={
                                (status === "CANCELED_BY_BUYER" || status === "CANCELED_BY_SELLER") ? 20 : 24
                              }
                            >
                              <form onSubmit={handleSubmit(handleSendMessage)}>
                                <Controller
                                  as={<Input />}
                                  placeholder='Type your message'
                                  className="messageInput"
                                  addonAfter={
                                    <Button
                                      disabled={message && message.length > 0 ? false : true}
                                      bordered='false' htmlType='submit' shape='circle' className={message && message.length > 0 ? 'activeSnedIconBg' : 'snedIconBg'}
                                      icon={<SendOutlined className='snedIcon' />}>
                                    </Button>
                                  }
                                  // size='large'
                                  name="message"
                                  control={control}
                                />
                              </form>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* <Col span={24}></Col>
                      <Col span={24}></Col> */}
                    </Row>
                  </div>
                </Affix>
              }
            </Content>
          </div>
        </Layout>
        <MakeOffer
          onCancelMakeOfferDialog={() => setShowMakeOfferDialog(false)}
          showMakeOfferDialog={showMakeOfferDialog}
          makeOfferData={lot}
          sellerInfo={seller}
        />
      </div>
    )
  }
  else {
    return (<Layout><Row justify='center' gutter={[0, 0]} align="bottom" className='blankchatContent'
      style={{ height: innerHeight - 214 }}>
    </Row></Layout>);
  }
};
