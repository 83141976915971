import React from "react";
import {    
    Card,
  } from "antd";
  export const ItemCard = (props) => {
    const { text, selected, id, onCardClick } = props;
    const {type, i} = id
    const {type: selectedType, i:selectedId} = selected
    return (
        <Card  onClick={() => onCardClick(id)}
         style={{
           height: 100.22, textAlign: 'center',
                        boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19",
                        borderStyle: (type === selectedType && i === selectedId) ? "dotted solid  " : "none",
                        backgroundColor: (type === selectedType && i === selectedId) ? "red" : "white",}}>{text} 

        </Card>
    );
};
