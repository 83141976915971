/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useGaEvent } from "../hooks/useGaEvent";
import * as yup from "yup";
import {
  Link,
  BrowserRouter as Router,
  Route,
  useHistory,
} from "react-router-dom";
// import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Card,
  Skeleton,
  Avatar,
  Divider,
  Button,
  Switch,
  Rate,
  Spin,
  Alert
} from "antd";
import {
  CheckOutlined,
  BulbOutlined,
  BellOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { MessageSeller } from "../components/MessageSeller";
import { MakeOffer } from "../components/MakeOffer";
import { NotifySoon } from "../components/NotifySoon";
import { EnlargedImages } from "../components/EnlargedImages";
import PLNotify from "../assets/PLNotify.svg";
import NotifiedIcon from "../assets/NotifiedIcon.svg";
import QuestionIcon from "../assets/NeedHelpIcon.png";
import TrueIcon from "../assets/trueicon.svg";
import {
  followUser,
  unFollowUser,
  getUser,
  notifyItem,
  unNotifyItem,
} from "../Actions/userAction";
import ShippingLogo from "../assets/Slice.svg";
import SignIn from "../pages/SignIn";
import { humanize } from "../Utils/util";
import { createMakeOffer } from "../Actions/offerAction";
const schema = yup.object().shape({
  price: yup.string("Offer Price is require"),
});
const { Text, Title } = Typography;
const PPLCart = (props) => { 
  const dispatch = useDispatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { productData, productLoader, sellerInfo } = props;
  const {
    condition,
    markidProductCategory,
    deliveryType,
    yearOfPurchase,
    location,
    size,
    negotiablePrice,
    title,
    isSold,
    brand,
    salePrice,
    sellerAddress,
    inSoldPending,
    createdBy,
    _id,
  } = productData;

  const { name: sellerName } = sellerAddress || { sellerName: "" };
  const ProductId = _id;
  const history = useHistory();
  const {
    currentUser,
    following,
    isFollowUserLoading,
    isUnFollowUserLoading,
    isNotifyItemLoading,
    isUnNotifyItemLoading,
    currentCart,
    allLots,
    isAuthenticated,
    partnerDetails,
  } = useSelector((state) => {
    const {
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
    } = state.user;
    const { partnerDetails, isGetPartnerDetailsLoading } = state.partnerDetails
    const { allLots } = state.lots;
    const { currentCart } = state.cart;
    const { isAuthenticated } = state.auth;
    const { following } = currentUser || {};
    return {
      following,
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      currentCart,
      allLots,
      isAuthenticated,
      partnerDetails,
    };
  });
  const { isPostOfferLoading, errorMessage } = useSelector((state) => {
    const { isPostOfferLoading, errorMessage } = state.offer
    return {
      isPostOfferLoading,
      errorMessage
    };
  });
  const { handleSubmit, control, reset, errors, setValue: setFormValues } = useForm({
    resolver: yupResolver(schema),
  });
  const { gaPageView, gaEventClick } = useGaEvent();
  useEffect(() => gaPageView());
  useEffect(() => {
  }, [errorMessage]);
  const meetupType = deliveryType === "meetup"
  const { notifyItems, _id: currentUserId } = currentUser;
  const { lots } = allLots || { lots: [] };
  let CartId = "";
  {
    currentCart &&
      currentCart.map((current) => {
        current._id === ProductId ? (CartId = current._id) : null;
      });
  }
  let NotifyItemId = "";
  {
    notifyItems &&
      notifyItems.map((current) => {
        current === ProductId ? (NotifyItemId = current) : null;
      });
  }

  //   const CartID = currentCart && currentCart.length > 0 ? currentCart[0]: null;
  //   const CartID = ccID
  //   console.log({CartID})
  //   function onChange(checked) {
  //       console.log(`switch to ${checked}`);
  //     }
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  const [showMakeOfferDialog, setShowMakeOfferDialog] = useState(false);
  const [showNotifySoonDialog, setShowNotifySoonDialog] = useState(false);
  const [showEnlargedImagesDialog, setShowEnlargedImagesDialog] =
    useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const ManufactureVal = yearOfPurchase ? yearOfPurchase.substring(0, 4) : null;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleAddItemCart = (item) => {
    let imgArray = []
    imgArray.push(item.imageLink) 
    item.name = item.title
    item.price = parseInt(item.salePrice.value)
    item.imageUrls = imgArray
    item.shippingPrice = "0"
    item.productType = "Partner"
    item.seller = {fullName: partnerDetails.name,
                   photo: partnerDetails.image }
    gaEventClick("PDP", "Add to Cart", item.name);
    dispatch({ type: "ADD_ITEM_CART", payload: { item: item } });
    // history.push("/markid-cart")
    history.push("/cart");
  };
  const handleNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(
        notifyItem(
          { id: ProductId },
          successCallBackNotifyMe,
          failureCallBackNotifyMe
        )
      );
    } else {
      gaEventClick("Navigation", "Add to Cart", "signup");
      history.push("/onboarding");
      // setShowSignInDialog(true)
    }
  };
  const successCallBackNotifyMe = () => {
    setShowNotifySoonDialog(true);
  };
  const failureCallBackNotifyMe = () => {};
  const handleUnNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(unNotifyItem({ id: ProductId }));
    } else {
      history.push("/onboarding");
      // setShowSignInDialog(true)
    }
  };
  const handleFollowUser = () => {
    if (isAuthenticated) {
      dispatch(followUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleUnFollowUser = () => {
    if (isAuthenticated) {
      dispatch(unFollowUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      history.push("/onboarding");
    }
  };
  const handleOpenMakeAnOffer = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Make an offer");
      setShowMakeOfferDialog(true);
      // history.push(`/messages/`+ProductId)
    } else {
      gaEventClick("Navigation", "Make an offer", "signup");
      history.push("/onboarding");
    }
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const timeId = setTimeout(() => {
      setOpen(false)
    }, 4000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);
  const handlemakeOfferClick = (data) => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Make an offer");
      data.lotId = productData._id
      const p = parseFloat(productData.salePrice.value)
      data.salePrice.value = p
      dispatch(createMakeOffer(data, successCallBack, failureCallBack));
    }
    else {
      gaEventClick("Navigation", "Make an offer", "signup");
      history.push('/onboarding')
    }
  }
  const successCallBack = () => {
    reset()
    history.push("/chat")
  };
  const failureCallBack = () => { };
  const forSellerUser = createdBy === currentUserId
  return (
    <>
     <div className="addtocartweb">
        {/* <Card className="pLCartCard"> */}
        <Row
          justify="space-between"
          gutter={[0, 2]}
          className="description-heading"
        >
          {/* <Col span={24}></Col> */}
          <Col className="w-100">
            {/* <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
            {productLoader ? (
              <Skeleton.Button active={productLoader} size="default" />
            ) : (
              brand && <Text className="pLCartHeading">{brand}</Text>
            )}
          </Row> */}
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col span={24}></Col>
              <Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="small" />
                ) : (
                  <h1 className="pLtitlepdp">{name?capitalize(name): capitalize(title)}</h1>
                )}
              </Col>
            </Row>
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col className="plcartpdp">
                {deliveryType === "shipping" && (
                  <div className="shippingMethod toship">Shipping</div>
                )}
                {/* {deliveryType === "meetup" && (
                  <div className="shippingMethod local">Local Pickup</div>
                )} */}
                {/* {deliveryType === "both" && (
                  <div className="shippingMethod toship both">
                    Shipping or Local Pick Up
                  </div>
                )} */}
                {!name && <div className="shippingMethod toship">Shipping</div>}
              </Col>

              {location && (
                <span className="availablepdp">
                  available at
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="default" />
                  ) : (
                    <span class="bold-txt">{capitalize(location)}</span>
                  )}{" "}
                </span>
              )}
            </Row>
            <Row
              gutter={[0, { xs: 8, sm: 8, md: 0, lg: 0, xl: 0 }]}
              className="priceAlign"
            >
              <Col span={24}></Col>
              <Col>
                {productLoader ? (
                  <Skeleton.Button active={productLoader} size="default" />
                ) : (
                  price &&
                  (!Number.isInteger(price / 100) ? (
                    <Typography className="pLPrice">
                      ${(price / 100).toFixed(2)}
                    </Typography>
                  ) : (
                    <Typography className="pLPrice">
                      ${Math.trunc(price / 100)}
                    </Typography>
                  ))
                )}
              </Col>

              <Tooltip
                title="For shipping delivery options. Shipping price calculated based on location, shown at checkout."
                placement="rightTop"
              >
                {deliveryType === "shipping" && isFree === false && (
                  <span className="shippingfeepdp tooltip">
                    +Shipping fee <img src={toolTip} />
                  </span>
                )}
                {deliveryType === "both" && isFree === false && (
                  <span className="shippingfeepdp tooltip">
                    +Shipping fee <img src={toolTip} />
                  </span>
                )}
              </Tooltip>
            </Row>
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              {costPrice > 0 && (
                <Col className="retailpricepdp">
                  Retail price{" "}
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="default" />
                  ) : (
                    price &&
                    (!Number.isInteger(costPrice / 100) ? (
                      <span className="strikePrice">
                        ${(costPrice / 100).toFixed(2)}
                      </span>
                    ) : (
                      <span className="strikePrice">
                        ${Math.trunc(costPrice / 100)}
                      </span>
                    ))
                  )}
                </Col>
              )}
            </Row>
            <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
              <Col className="paysecuretxt">
                <span>
                  <img src={securepayvector} />
                </span>{" "}
                Your payment is secured with Stripe
              </Col>
            </Row>

            <Row
              className="buttonsWrapper"
              gutter={[0, { xs: 8, sm: 8, md: 15, lg: 15, xl: 15 }]}
            >
              <Col span={24}></Col>

              {inSoldPending ? (
                <Button
                  block
                  className={"itemSoldButton"}
                  //  onClick={() => setShowNotifySoonDialog(true)}
                >
                  Item sold
                </Button>
              ) : (
                <>
                  {ProductId === CartId ? (
                    productLoader ? (
                      <Skeleton.Button active={productLoader} width={500} />
                    ) : (
                      !meetupType &&
                      !forSellerUser && (
                        <Col span={12}>
                          <Button
                            block
                            className={"button button-primary pLCartButton"}
                            onClick={() => {
                              gaEventClick("PDP", "Go To Cart");
                              history.push("/cart");
                            }}
                          >
                            GO TO CART{" "}
                            {deliveryType === "both" ? "(SHIPPING)" : null}
                          </Button>
                        </Col>
                      )
                    )
                  ) : productLoader ? (
                    <Skeleton.Button active={productLoader} />
                  ) : (
                    !meetupType &&
                    !forSellerUser && (
                      <Col span={12}>
                        <Button
                          block
                          className={"button button-primary  pLCartButton"}
                          onClick={() => handleAddItemCart(productData)}
                        >
                          ADD TO CART{" "}
                          <span className="small">
                            {deliveryType === "both" ? "(SHIPPING)" : null}
                          </span>
                        </Button>
                      </Col>
                    )
                  )}
                </>
              )}
              {(negotiablePrice === false && deliveryType === "shipping") ||
              inSoldPending ||
              forSellerUser ? null : (
                <>
                  {productLoader ? (
                    <Skeleton.Button active={productLoader} size="small" />
                  ) : negotiablePrice ? (
                    <Col span={8}>
                      <Button
                        block
                        onClick={() => handleOpenMakeAnOffer()}
                        className={"plfollowpdp"}
                      >
                        MAKE AN OFFER{" "}
                        <span className="small">
                          {deliveryType === "both" ? "(LOCAL PICK UP )" : null}
                        </span>
                        {deliveryType === "shipping" && (
                          <Tooltip
                            placement="bottomRight"
                            title="Send an offer to the seller for this item. All offers made with shipping are binding and require a form of payment to send."
                          >
                            <img src={toolTip} className="tooltip" />{" "}
                          </Tooltip>
                        )}
                        {deliveryType === "meetup" && (
                          <Tooltip
                            placement="bottomRight"
                            title="Make an offer for local pick up of this item. Seller can accept, reject, or counter your offer. 

                      If your offer is accepted this item will be marked as sold, and you can begin to coordinate with the seller to pick up this item. "
                          >
                            <img src={toolTip} className="tooltip" />{" "}
                          </Tooltip>
                        )}
                      </Button>
                    </Col>
                  ) : (
                    <form onSubmit={handleSubmit(handlemakeOfferClick)}>
                      {errorMessage && (
                        <Row justify="center" gutter={[20]}>
                          <Col span={24}></Col>
                          <Alert
                            type="error"
                            open={open}
                            message={errorMessage}
                          />
                        </Row>
                      )}
                      {productData?.name && 
                     
                        <Button
                          className={"button button-secondary"}
                          block
                          htmlType="submit"
                        >
                          MAKE AN OFFER hh
                          <span className="small">
                            {deliveryType === "both" ? "(LOCAL PICK UP)" : null}
                          </span>{" "}
                          {deliveryType === "shipping" && (
                          <Tooltip
                            placement="bottomRight"
                            title="Send an offer to the seller for this item. All offers made with shipping are binding and require a form of payment to send."
                          >
                            <img src={toolTip} className="tooltip" />{" "}
                          </Tooltip>
                        )}
                          
                        {deliveryType === "meetup" && (
                          <Tooltip
                            placement="bottomRight"
                            title="Make an offer for local pick up of this item. Seller can accept, reject, or counter your offer. 

                      If your offer is accepted this item will be marked as sold, and you can begin to coordinate with the seller to pick up this item. "
                          >
                            <img src={toolTip} className="tooltip" />{" "}
                          </Tooltip>
                        )}
                        </Button>}
                    
                    </form>
                  )}
                </>
              )}
            </Row>
          </Col>
        </Row>
        {/* <Divider
          style={{ background: "#707070", margin: "18px 0px", opacity: 0.3 }}
        /> */}

        <div className="desctrition-details">
          <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
            <Col span={24}></Col>
            <Col>
              <h1 className="pLcartpdp">Overview</h1>
            </Col>
          </Row>
          {condition && (
            <Row gutter={[0, 4]} className="pb-1">
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Condition</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  {condition === "new" && (
                    <Tooltip
                      title="New. Unopened packaging. Unused."
                      placement="right"
                    >
                      <Text className="pLvalue">
                        {capitalize(condition)}
                        {""}
                        <img src={toolTip} className="tooltip" />
                      </Text>
                    </Tooltip>
                  )}
                  {condition === "Good" ||
                    (condition === "good" && (
                      <Tooltip
                        title="Gently used. One / few minor flaws. Functional."
                        placement="right"
                      >
                        <Text className="pLvalue">
                          {capitalize(condition)}
                          {""}
                          <img src={toolTip} className="tooltip" />
                        </Text>
                      </Tooltip>
                    ))}
                  {condition === "Great" ||
                    (condition === "great" && (
                      <Tooltip
                        title="Like-new condition. No signs of wear. Fully functional."
                        placement="right"
                      >
                        <Text className="pLvalue">
                          {capitalize(condition)}
                          {""}
                          <img src={toolTip} className="tooltip" />
                        </Text>
                      </Tooltip>
                    ))}
                  {condition === "fair" ||
                    (condition === "Fair" && (
                      <Tooltip
                        title="Used, functional, multiple flaws / defects. Signs of wear and use."
                        placement="right"
                      >
                        <Text className="pLvalue">
                          {capitalize(condition)}
                          {""}
                          <img src={toolTip} className="tooltip" />
                        </Text>
                      </Tooltip>
                    ))}
                </Col>
              )}
            </Row>
          )}
          {brand && (
            <Row gutter={[0, 4]} className="pb-1">
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Brand</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="default" />
              ) : (
                brand && <Text className="pLCartHeading">{brand}</Text>
              )}
            </Row>
          )}
          {deliveryType && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Delivery</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">
                    {/* {deliveryType === 'both' ? 'Shipping or Meetup' : capitalize(deliveryType)} */}
                    {deliveryType === "both" 
                      ? "Shipping or Local Pick Up"
                      : deliveryType === "meetup"
                      ? "Local Pick Up"
                      : capitalize(deliveryType)}
                  </Text>
                </Col>
              )}
            </Row>
          )}
          <Row
            gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
            className="pb-1"
          >
            <Col span={24}></Col>
            <Col xl={10} lg={10} md={10} sm={13} xs={13}>
              <Text className="pLKey">Price</Text>
            </Col>
            {productLoader ? (
              <Skeleton.Button active={productLoader} size="default" />
            ) : (
              <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                {negotiablePrice === true ? (
                  <Text className="pLvalue">Negotiable</Text>
                ) : (
                  <Text className="product-cart-value">Firm</Text>
                )}
              </Col>
            )}
          </Row>
          <Row gutter={[0, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}>
            <Col span={24}></Col>
            <Col>
              <h1 className="pLcartpdp">Item details</h1>
            </Col>
          </Row>

          {yearOfPurchase && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Purchased</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{ManufactureVal}</Text>
                </Col>
              )}
            </Row>
          )}
          {/* {location && (
          <Row
            gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
            className="pb-1"
          >
            <Col span={24}></Col>
            <Col xl={10} lg={10} md={10} sm={13} xs={13}>
              <Text className="pLKey">Location</Text>
            </Col>
            {productLoader ? (
              <Skeleton.Button active={productLoader} size="default" />
            ) : (
              <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                <Text className="pLvalue">{capitalize(location)}</Text>
              </Col>
            )}
          </Row>
        )} */}
          {age && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Age</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{capitalize(age)}</Text>
                </Col>
              )}
            </Row>
          )}
          {size && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Size</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{capitalize(size)}</Text>
                </Col>
              )}
            </Row>
          )}
          {color && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Color</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{capitalize(color)}</Text>
                </Col>
              )}
            </Row>
          )}
          {category && (
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0 }]}
              className="pb-1"
            >
              <Col span={24}></Col>
              <Col xl={10} lg={10} md={10} sm={13} xs={13}>
                <Text className="pLKey">Category</Text>
              </Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="default" />
              ) : (
                <Col xl={14} lg={14} md={14} sm={11} xs={11}>
                  <Text className="pLvalue">{humanize(category)}</Text>
                </Col>
              )}
            </Row>
          )}
          {/* {inSoldPending || forSellerUser ? null : (
          <Row gutter={[0, 21]}>
            <Col span={24}></Col>
            <Col>
              {productLoader ? (
                <Skeleton.Button active={productLoader} size="small" />
              ) : ProductId === NotifyItemId ? (
                <Spin indicator={antIcon} spinning={isUnNotifyItemLoading}>
                  <Button
                    className="notifyButton"
                    onClick={() => handleUnNotifyItem(ProductId)}
                  >
                    UnNotify Me
                    {
                      <img
                        alt=""
                        src={NotifiedIcon}
                        style={{ paddingLeft: "10px" }}
                      />
                    }
                  </Button>
                </Spin>
              ) : (
                <Spin indicator={antIcon} spinning={isNotifyItemLoading}>
                  <Button
                    className="notifyButton"
                    onClick={() => handleNotifyItem(ProductId)}
                  >
                    Notify Me
                    {
                      <img
                        alt=""
                        src={PLNotify}
                        style={{ paddingLeft: "10px" }}
                      />
                    }
                  </Button>
                </Spin>
              )}
            </Col>
          </Row> */}
          {/* )} */}
          {inSoldPending || forSellerUser ? null : (
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col>
                {productLoader ? (
                  <Skeleton active={productLoader} size="small" />
                ) : (
                  <Typography className="pLNotifyDes">{description}</Typography>
                )}
              </Col>
              <Col></Col>
            </Row>
          )}
        </div>

        {/* </Card> */}
      </div>

      <MessageSeller
        onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)}
        showMessageSellerDialog={showMessageSellerDialog}
      />
      <MakeOffer
        onCancelMakeOfferDialog={() => setShowMakeOfferDialog(false)}
        showMakeOfferDialog={showMakeOfferDialog}
        makeOfferData={productData}
        sellerInfo={sellerInfo}
        negotiablePrice={negotiablePrice}
      />
      <NotifySoon
        onCancelNotifySoonMethod={() => setShowNotifySoonDialog(false)}
        showNotifySoonDialog={showNotifySoonDialog}
        notifyData={productData}
      />
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default PPLCart;
