/*eslint-disable*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../App.css";

const ExposedCategories = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="LogoWrapper" style={{ marginTop: "10px" }}>
        <ul>
          <li
            onClick={() => history.push("/search-new/?refresh=true&zipCode=10100")}
          >
            All
          </li>
          <li
            onClick={() => history.push("/products/winterwear")}
            style={{ color: "#058058" }}
          >
            Winter wear
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/strollers/5f8ab7d436a0cd0ea2329a97"
              )
            }
          >
            Strollers
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/clothing_and_accessories/5f8ab78836a0cd0ea2329a45"
              )
            }
          >
            Clothing
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/shoes/5f8ab7a036a0cd0ea2329a5a"
              )
            }
          >
            Shoes
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/toys_and_costumes/5f8ab7c336a0cd0ea2329a7d"
              )
            }
          >
            Toys & Costumes
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/cribs_and_furniture/5f8ab81836a0cd0ea2329ac0"
              )
            }
          >
            Cribs & Furniture
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/gear/5f8ab7e436a0cd0ea2329aa1"
              )
            }
          >
            Gear
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/feeding/5f8ab83e36a0cd0ea2329ad3"
              )
            }
          >
            Feeding
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/maternity/5f8ab80536a0cd0ea2329ab9"
              )
            }
          >
            Maternity
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/diapering/5f8ab86c36a0cd0ea2329aec"
              )
            }
          >
            Diapering
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/electronics/5f8ab82d36a0cd0ea2329ac7"
              )
            }
          >
            Electronics
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/bathing/5f8ab7f436a0cd0ea2329aacs"
              )
            }
          >
            Bathing
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/books/5f8ab7ae36a0cd0ea2329a5b"
              )
            }
          >
            Books
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/sports_bicycles/5f8ab84f36a0cd0ea2329ad4"
              )
            }
          >
            Sports & Bicycles
          </li>
          <li
            onClick={() =>
              history.push(
                "/product-listing/category/safety_equipment/5f8ab85e36a0cd0ea2329ae2"
              )
            }
          >
            Safety & Health
          </li>
        </ul>
      </div>

      {/* <div className='brandBlogMob'> </div> */}
    </>
  );
};
export default ExposedCategories;
