import API from "../Utils/API";

export const getAllpartnerproducts = (data) => (dispatch) => {

  dispatch({ type: "GET_ALL_PARTNERPRODUCTS_REQUEST" });
  let url = `/api/v5/partner-products/search?category=${data}`
  if(data && data.search){
    url = `/api/v5/partner-products/search?search=${data.search}`
  }
  // else if (data && data.category) {
  //   url = `/api/v2/lots?category=${data.category}`
  // }
  // else if (data && data.brand) {
  //   url = `/api/v2/lots?brands=${data.brand}`
  // }
  API.get(url)
  
    .then((response) => {
     
      dispatch({ type: "GET_ALL_PARTNERPRODUCTS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_ALL_PARTNERPRODUCTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
    
};
export const getFilterpartnerProducts = (data, url) => (dispatch) => {

  dispatch({ type: "GET_FILTER_PARTNERPRODUCTS_REQUEST" });
  API.get(url)
    .then((response) => {
     
      dispatch({ type: "GET_FILTER_PARTNERPRODUCTS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_FILTER_PARTNERPRODUCTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const moreLotsFromThisPartner =
  (Partner_id) => (dispatch) => {

    dispatch({ type: "MORE_FROM_THIS_PARTNER_REQUEST" });
  let url = `api/v4/partner-products/${Partner_id}`
  API.get(url)
    .then((response) => {
      dispatch({ type: "MORE_FROM_THIS_PARTNER_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "MORE_FROM_THIS_PARTNER_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
  };