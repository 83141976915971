import React, { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import { Helmet } from "react-helmet";
import Founders from "../components/Founders";
import KeyTeam from "../components/KeyTeam";
import logo from "../assets/new-assets/weblogomarkid.png";
const About = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Markid - About Us</title>
      </Helmet>
      <div className="pageWrapper" id="d-container">
        <h1>About us</h1>
        <h4>
          {" "}
          <img src={logo} height="60px" width="60px" alt="about-us-image" />
          Here's our story
        </h4>
        <div class="responsive-iframe">
        <iframe
         width="1060" height="450"
          src="https://www.youtube.com/embed/3d0_DEzKMTw?autoplay=1&mute=1"
          title="YouTube video player"
          allowFullScreen="true"
        ></iframe></div>
        {/* <p>
          We believe the future of kids’ shopping is circular. Markid is a
          one-stop solution that integrates peer-to-peer with branded resale –
          giving parents choice & flexibility and brands the opportunity to
          create a long-term purchase cycle with customers.
        </p>
        <p>
          Parents spend thousands of dollars on kids’ products that are quickly
          outgrown. There is no single destination that enables parents with the
          choice to buy new, refurbished, or used kids' products on a single
          platform. This leads parents to overwhelmingly buy new rather than a
          combination of new and used based on their needs. (Parents spend on
          average $5K per household during ages 0-3.) Additionally, the
          secondary marketplaces that exist are time-consuming and plagued with
          fraudulent transactions leaving parents questioning the safety, value,
          and authenticity of the products with no official certification.
        </p>
        <p>
          Brand retail and second-hand markets are disconnected, as second-hand
          transactions are fragmented across multiple third-party platforms. The
          resale market is growing at 7.5% and retailers recognize the value of
          engaging customers throughout their customer journey but they are
          unable to capture value from the second-hand market for their
          products, given the barriers for upfront investment, risks of trying a
          new model and lack of access to an engaged customer base.
        </p>
        <p>
          Markid is a one-stop solution, designed to address parents’ unique
          needs. We empower parents to buy (new or used) and sell kids’
          outgrown/unused items seamlessly through our integrated peer-to-peer
          and brand-resale channels, on one platform
        </p>
        <p>
          We are reimagining how parents shop for their kids by enabling a
          shopping experience that offers them a choice to buy new, refurbished,
          and used, all on one platform, and make the process of selling back
          simple. We enable brands to reach a wider market, further their
          commitment to sustainability and cultivate a deeper relationship with
          their customers that goes beyond the first buyer.
        </p>
        <p>
          Markid is one of the first circular marketplaces in the kid's space
          that connects brands to an engaged peer-to-peer community of parents.
        </p> */}

        <h4 style={{ marginTop: "55px" }}>The Team</h4>
        <h1>Founders and Officers</h1>
        <Divider />
      </div>

      <Founders />
      <KeyTeam />
    </>
  );
};

export default About;
