import {
  Button,
  Typography,
  Row,
  Col,
  Collapse,
  Input,
  Card,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PageHeader from "../components/PageHeader";
import { VenmoAccount } from "../pages/VenmoAccount";

const { Text, Title } = Typography;
const LinkVenmoAccount = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is a required field"),
  });
  const { Panel } = Collapse;
  function callback(key) {
    // console.log(key);
  }

  const [createDialog, setCreateDialog] = useState(false);
  // console.log("model", setCreateDialog)
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  return (
    <>
      <PageHeader />
      <Card>
      <Row justify="center" gutter={[48, 48]}>
        <Col span={24}></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Title level={3} strong style={{ marginBottom: 0 }}>
            How do you want to get paid?
          </Title>
        </Col>
      </Row>

      <Row justify='center' gutter={[16, 8]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col span={24}></Col>
        {/* <Col xl={9} lg={9} md={9} sm={24} xs={24}></Col> */}
        <Col xl={10} lg={10} md={10} sm={24} xs={24}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            collapsible='header'
            // onChange={() => console.log("Nothing")}
            expandIconPosition="left"
            expandIcon={({ isActive }) => {
              return !isActive ? (
                <Row gutter={[8,8]}>
                <PlusCircleOutlined />
                </Row>
              ) : (
                <MinusCircleOutlined />
              );
            }}
          >
            <Panel
              showArrow={true}
              collapsible='disabled'
              header={
                <Row justify="space-between" align='middle'>
                  
                  <Text>Link Your Venmo Account</Text>
                  <Button onClick={() => setCreateDialog(true)} type="link">
                    Venmo
                  </Button>
                  
                </Row>
              }
              key="1"
            ></Panel>
          </Collapse>
        </Col>
      </Row>

      <Row gutter={[16, 8]} justify='center'>
        <Col span={24}></Col>
        {/* <Col xl={9} lg={9} md={9} sm={24} xs={24}></Col> */}
        <Col xl={10} lg={10} md={10} sm={24} xs={24}>
          <Collapse
            style={{ backgroundColor: "white" }}
            defaultActiveKey={["0"]}
            onChange={callback}
            expandIconPosition="left"
            expandIcon={({ isActive }) => {
              return !isActive ? (
                <PlusCircleOutlined style={{ color: "black" }} />
              ) : (
                <MinusCircleOutlined />
              );
            }}
          >
            <Panel
              showArrow={true}
              header={
                <Row justify="space-between" align='middle'>
                  
                <Text>Link Debit Card with Stripe</Text>
                <Button type="link">
                Stripe
                </Button>
                
              </Row>
              }
              key="1"
            >
              <form onSubmit={handleSubmit()}>
                <Row justify="start" gutter={[32, 32]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Controller
                      as={Input}
                      name="card_Number"
                      control={control}
                      placeholder="Enter your card Number"
                    />
                    {errors.card_Number && (
                      <span style={{ color: "red" }}>
                        Valid Card number is required
                      </span>
                    )}
                  </Col>
                </Row>

                <Row justify="start" gutter={[32, 32]}>
                  <Col span={24}></Col>
                  <Col>
                    <Text>Valid upto</Text>
                  </Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col span={24}></Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Controller
                      as={Input}
                      name="mm"
                      control={control}
                      placeholder="MM"
                    />
                    {errors.phoneNumber && (
                      <span style={{ color: "red" }}>Valid MM is required</span>
                    )}
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Controller
                      as={Input}
                      name="yy"
                      control={control}
                      placeholder="YY"
                    />
                    {errors.phoneNumber && (
                      <span style={{ color: "red" }}>Valid YY is required</span>
                    )}
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={4} xs={4}></Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Controller
                      as={Input}
                      name="cvv"
                      control={control}
                      placeholder="CVV"
                    />
                    {errors.phoneNumber && (
                      <span style={{ color: "red" }}>Valid CVVis required</span>
                    )}
                  </Col>
                </Row>
                <Row justify="start" gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Col>
                    <Text>
                      This card will be securely saved for a faster payment
                      experience CVV number will not be saved
                    </Text>
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 48]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col span={24}></Col>

        <Col>
          <Text>We do not store your bank information with us</Text>
        </Col>
      </Row>
</Card>

      <Row justify="center" gutter={[16, 8]}>
        <Col span={24}></Col>
        <Col>
          <Button
            className={'squarebutton'}
          >
            SAVE
          </Button>
        </Col>
        <Col span={24}></Col>
      </Row>
      {createDialog && (
        <VenmoAccount
          createDialog={createDialog}
          setCreateDialog={setCreateDialog}
          // handleDialog={() => setCreateDialog(true)}
        />
      )}
    </>
  );
};
export default LinkVenmoAccount;
