import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography, Input, Button, Tooltip } from "antd";
import { SearchOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { getBrandlisting } from "../Actions/brandsAction";
import { useHistory } from "react-router-dom";
import { setFilterCondition } from "../Actions/brandsAction";
import { number } from "yup";
import { getEmailUpdates } from "../Actions/authAction";
const AllBrandsPage = () => {
  const XY = useRef();
  const A = useRef();
  const B = useRef();
  const C = useRef();
  const D = useRef();
  const E = useRef();
  const F = useRef();
  const G = useRef();
  const H = useRef();
  const I = useRef();
  const J = useRef();
  const K = useRef();
  const L = useRef();
  const M = useRef();
  const N = useRef();
  const O = useRef();
  const P = useRef();
  const Q = useRef();
  const R = useRef();
  const S = useRef();
  const T = useRef();
  const U = useRef();
  const V = useRef();
  const W = useRef();
  const X = useRef();
  const Y = useRef();
  const Z = useRef();

  const handleClick = (ref) => {
    if (ref === "A") {
      A.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "B") {
      B.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "C") {
      C.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "D") {
      D.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "E") {
      E.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "F") {
      F.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "G") {
      G.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "H") {
      H.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "I") {
      I.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "J") {
      J.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "K") {
      K.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "L") {
      L.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "M") {
      M.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "N") {
      N.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "O") {
      O.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "P") {
      P.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "Q") {
      Q.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "R") {
      R.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "S") {
      S.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "T") {
      T.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "U") {
      U.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "V") {
      V.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "W") {
      W.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "X") {
      X.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "Y") {
      Y.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "Z") {
      Z.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (ref === "0-9") {
      XY.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const history = useHistory();

  let getAlphabets = () => {
    const alphabet = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "0-9",
    ];
    return alphabet.map((val) => {
      return (
        <li
          className="category"
          onClick={() => {
            handleClick(val);
          }}
        >
          {val}
        </li>
      );
    });
  };

  const dispatch = useDispatch();
  const { brandlist } = useSelector((state) => {
    const { brandlist } = state.brands;
    return {
      brandlist,
    };
  });
  const [emailInput, setemailInput] = useState("");
  const handleUpdates = () => {
    const dataInput = {
      email: emailInput,
    };
    if (dataInput != null) {
      dispatch(getEmailUpdates(dataInput));
    }
  };
  useEffect(() => {
    dispatch(getBrandlisting());
  }, []);
  const [query, setQuery] = useState("");

  const sorteddata = brandlist?.sort();
  const datalisted = useMemo(() => {
    let array = [];
    let test =
      sorteddata &&
      sorteddata
        ?.filter((val) => {
          if (query == "") {
            return val;
          } else if (val?.toLowerCase()?.includes(query?.toLowerCase())) {
            return val;
          }
        })
        .map((val) => {
          array.push({ name: val });
          return val;
        });
    return test;
  });

  const AlphabetObject = {
    A: [],
    B: [],
    C: [],
    D: [],
    E: [],
    F: [],
    G: [],
    H: [],
    I: [],
    J: [],
    K: [],
    L: [],
    M: [],
    N: [],
    O: [],
    P: [],
    Q: [],
    R: [],
    S: [],
    T: [],
    U: [],
    V: [],
    W: [],
    X: [],
    Y: [],
    Z: [],
    XY: [],
  };
  datalisted?.forEach((element) => {
    if (element.charAt(0) === "A") {
      AlphabetObject["A"]?.push(element);
    }
    if (element.charAt(0) === "B") {
      AlphabetObject["B"]?.push(element);
    }
    if (element.charAt(0) === "C") {
      AlphabetObject["C"]?.push(element);
    }
    if (element.charAt(0) === "D") {
      AlphabetObject["D"]?.push(element);
    }
    if (element.charAt(0) === "E") {
      AlphabetObject["E"]?.push(element);
    }
    if (element.charAt(0) === "F") {
      AlphabetObject["F"]?.push(element);
    }
    if (element.charAt(0) === "G") {
      AlphabetObject["G"]?.push(element);
    }
    if (element.charAt(0) === "H") {
      AlphabetObject["H"]?.push(element);
    }
    if (element.charAt(0) === "I") {
      AlphabetObject["I"]?.push(element);
    }
    if (element.charAt(0) === "J") {
      AlphabetObject["J"]?.push(element);
    }
    if (element.charAt(0) === "K") {
      AlphabetObject["K"]?.push(element);
    }
    if (element.charAt(0) === "L") {
      AlphabetObject["L"]?.push(element);
    }
    if (element.charAt(0) === "M") {
      AlphabetObject["M"]?.push(element);
    }
    if (element.charAt(0) === "N") {
      AlphabetObject["N"]?.push(element);
    }
    if (element.charAt(0) === "O") {
      AlphabetObject["O"]?.push(element);
    }
    if (element.charAt(0) === "P") {
      AlphabetObject["P"]?.push(element);
    }
    if (element.charAt(0) === "Q") {
      AlphabetObject["Q"]?.push(element);
    }
    if (element.charAt(0) === "R") {
      AlphabetObject["R"]?.push(element);
    }
    if (element.charAt(0) === "S") {
      AlphabetObject["S"]?.push(element);
    }
    if (element.charAt(0) === "T") {
      AlphabetObject["T"]?.push(element);
    }
    if (element.charAt(0) === "U") {
      AlphabetObject["U"]?.push(element);
    }
    if (element.charAt(0) === "V") {
      AlphabetObject["V"]?.push(element);
    }
    if (element.charAt(0) === "W") {
      AlphabetObject["W"]?.push(element);
    }
    if (element.charAt(0) === "X") {
      AlphabetObject["X"]?.push(element);
    }
    if (element.charAt(0) === "Y") {
      AlphabetObject["Y"]?.push(element);
    }
    if (element.charAt(0) === "Z") {
      AlphabetObject["Z"]?.push(element);
    }
    if (element.charAt(0) >= "0" && element.charAt(0) <= "9") {
      AlphabetObject["XY"]?.push(element);
    }
  });

  const { Search } = Input;
  return (
    <>
      <div className="AllBrandsTop">
        <h2>Brands</h2>
        <div className="searchOption">
          <Search
            className="allBrandsSearch searchInput"
            placeholder="Search..."
            onChange={(event) => setQuery(event.target.value)}
          />
          {/* <SearchOutlined className="searchOutlined" /> */}
        </div>
      </div>
      <div className="brandlistBackground">
        <div className="Alphabetlistalign">
          <p className="AlphabetList">{getAlphabets()} </p>
        </div>
        <div className="brandWrapper">
          <div>
            {AlphabetObject["A"]?.length > 0 && (
              <div className="ALphaBetHead" ref={A}>
                A
              </div>
            )}
            <div className="brandlistingAll">
              {AlphabetObject["A"].map((val) => {
                return (
                  <li
                    className="brandlistalpha"
                    onClick={() => {
                      history.push(`/search-brand/${val}`);
                      dispatch(setFilterCondition("brand"));
                    }}
                  >
                    {val}
                  </li>
                );
              })}
            </div>
          </div>
          {AlphabetObject["B"]?.length > 0 && (
            <div className="ALphaBetHead" ref={B}>
              B
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["B"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["C"]?.length > 0 && (
            <div className="ALphaBetHead" ref={C}>
              C
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["C"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["D"]?.length > 0 && (
            <div className="ALphaBetHead" ref={D}>
              D
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["D"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["E"]?.length > 0 && (
            <div className="ALphaBetHead" ref={E}>
              E
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["E"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["F"]?.length > 0 && (
            <div className="ALphaBetHead" ref={F}>
              F
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["F"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["G"]?.length > 0 && (
            <div className="ALphaBetHead" ref={G}>
              G
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["G"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["H"]?.length > 0 && (
            <div className="ALphaBetHead" ref={H}>
              H
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["H"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["I"]?.length > 0 && (
            <div className="ALphaBetHead" ref={I}>
              I
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["I"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["J"]?.length > 0 && (
            <div className="ALphaBetHead" ref={J}>
              J
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["J"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["K"]?.length > 0 && (
            <div className="ALphaBetHead" ref={K}>
              K
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["K"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["L"]?.length > 0 && (
            <div className="ALphaBetHead" ref={L}>
              L
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["L"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["M"]?.length > 0 && (
            <div className="ALphaBetHead" ref={M}>
              M
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["M"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["N"]?.length > 0 && (
            <div className="ALphaBetHead" ref={N}>
              N
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["N"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["O"]?.length > 0 && (
            <div className="ALphaBetHead" ref={O}>
              O
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["O"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["P"]?.length > 0 && (
            <div className="ALphaBetHead" ref={P}>
              P
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["P"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["Q"]?.length > 0 && (
            <div className="ALphaBetHead" ref={Q}>
              Q
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["Q"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["R"]?.length > 0 && (
            <div className="ALphaBetHead" ref={R}>
              R
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["R"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["S"]?.length > 0 && (
            <div className="ALphaBetHead" ref={S}>
              S
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["S"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["T"]?.length > 0 && (
            <div className="ALphaBetHead" ref={T}>
              T
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["T"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["U"]?.length > 0 && (
            <div className="ALphaBetHead" ref={U}>
              U
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["U"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["V"]?.length > 0 && (
            <div className="ALphaBetHead" Ref={V}>
              V
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["V"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["W"]?.length > 0 && (
            <div className="ALphaBetHead" ref={W}>
              W
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["W"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["X"]?.length > 0 && (
            <div className="ALphaBetHead" ref={X}>
              X
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["X"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["Y"]?.length > 0 && (
            <div className="ALphaBetHead" ref={Y}>
              Y
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["Y"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["Z"]?.length > 0 && (
            <div className="ALphaBetHead" ref={Z}>
              Z
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["Z"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
          {AlphabetObject["XY"]?.length > 0 && (
            <div className="ALphaBetHead" ref={XY}>
              0-9
            </div>
          )}
          <div className="brandlistingAll">
            {AlphabetObject["XY"].map((val) => {
              return (
                <li
                  className="brandlistalpha"
                  onClick={() => {
                    history.push(`/search-brand/${val}`);
                    dispatch(setFilterCondition("brand"));
                  }}
                >
                  {val}
                </li>
              );
            })}
          </div>
        </div>
        <div className="MarkidNewsletterWrapper">
          <div className="MarkidNewsletter app-wrapper">
            <Typography className="sectionTitles title">
              Don’t miss out!
            </Typography>
            <Typography className="description">
              Sign up for our newsletter to keep updated about new products,
              tips & exclusive offers. We promise not to spam :)
            </Typography>

            <div className="inputWrapper">
              <Input.Group compact>
                <Input
                  className="input"
                  placeholder="Email"
                  onInput={(e) => {
                    setemailInput(e.target.value);
                  }}
                  onKeyDown={(e) => e.key === "Enter" && handleUpdates()}
                />
                <Tooltip title="Receive Updates">
                  <Button
                    className="btnSubmit"
                    type="dashed"
                    icon={
                      <ArrowRightOutlined
                        style={{ fontSize: "16px", color: "#393939" }}
                      />
                    }
                    onClick={() => {
                      handleUpdates();
                    }}
                  />
                </Tooltip>
                {/* <Button type="primary" className='btn-submit'>Submit</Button> */}
              </Input.Group>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllBrandsPage;
