import API from '../Utils/API';

export const getAllCommunities = () => dispatch => {
  dispatch({ type: "GET_COMMUNITIES_REQUEST" })
  API.get('/api/v2/communities')
      .then(response => {
          dispatch({ type: "GET_COMMUNITIES_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "GET_COMMUNITIES_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}
