import API from '../Utils/API';

export const getCategoriesGroup = (data) => dispatch => {
  dispatch({ type: "GET_CATEGORIES_GROUP_REQUEST" })
  API.get(`/api/v2/groups?groupCallName=${data}`)
      .then(response => {
          dispatch({ type: "GET_CATEGORIES_GROUP_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "GET_CATEGORIES_GROUP_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}

export const getFeaturedSellerGroup = (data) => dispatch => {
    dispatch({ type: "GET_FEATURED_SELLER_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_FEATURED_SELLER_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_FEATURED_SELLER_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }

  export const getCuratedesGroup = (data) => dispatch => {
    dispatch({ type: "GET_CURATED_BY_MARKID_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_CURATED_BY_MARKID_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_CURATED_BY_MARKID_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }

  export const getExploresGroup = (data) => dispatch => {
    dispatch({ type: "GET_EXPLORE_TOP_DEALS_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_EXPLORE_TOP_DEALS_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_EXPLORE_TOP_DEALS_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }

  export const getBrandsGroup = (data) => dispatch => {
    dispatch({ type: "GET_BRANDS_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_BRANDS_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_BRANDS_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }

  export const getStylesGroup = (data) => dispatch => {
    dispatch({ type: "GET_STYLES_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_STYLES_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_STYLES_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }

  export const getCollectionsGroup = (data) => dispatch => {
    dispatch({ type: "GET_COLLECTIONS_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_COLLECTIONS_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_COLLECTIONS_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }

  export const getCommunitiesGroup = (data) => dispatch => {
    dispatch({ type: "GET_COMMUNITY_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_COMMUNITY_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_COMMUNITY_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }

  export const getTestimonialsGroup = (data) => dispatch => {
    dispatch({ type: "GET_TESTIMONIALS_GROUP_REQUEST" })
    API.get(`/api/v2/groups?groupCallName=${data}`)
        .then(response => {
            dispatch({ type: "GET_TESTIMONIALS_GROUP_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_TESTIMONIALS_GROUP_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }