import React, { useEffect,useState } from "react";
import { Button, Card, Divider, Spin } from "antd";
import pin from "../assets/new-assets/pin.png";
import insta from "../assets/new-assets/instagram.png";
import facebook from "../assets/new-assets/facebook.png";
import twitter from "../assets/new-assets/twitter.png";
import linkedin from "../assets/new-assets/linkedin.png";
import { useSelector, useDispatch } from "react-redux";
import { getBlogsById } from "../Actions/BlogsAction";
import { useParams } from "react-router-dom";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { BlogAddBookmark,BlogRemoveBookmark,getBlogBookmarks} from "../Actions/BlogsAction";
import { LoadingOutlined } from "@ant-design/icons";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bookmark1 from "../assets/new-assets/bookmark1.png";
import bookmark2 from "../assets/new-assets/bookmark2.png";
const BlogDetailComponent = () => {
  const { Blogdetails, isBlogdetailsLoading, Blogbookmarks  } = useSelector((state) => {
    const { Blogdetails, isBlogdetailsLoading, Blogbookmarks } = state.Blogs;
    return {
      Blogdetails,
      isBlogdetailsLoading,
      Blogbookmarks 
    };
  });
  const notify = () => toast("copied to clipboard!");
  const Alertlogin = () => {
    if (localStorage.getItem("access_token")=== null) {
    toast("LOGIN TO BOOKMARK!");}}
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogsById(id));
  }, []);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(window.location.toString()); 
  };
  const [isBackgroundRed, setisBackgroundRed] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      dispatch(getBlogBookmarks());
    }
  }, []);
  const handleAddBookmark = () => {
    if (localStorage.getItem("access_token") != null) {
      if (!Blogbookmarks?.bookmarks?.includes(Blogdetails[0]?.id)) {
        setisBackgroundRed(true);
        dispatch(BlogAddBookmark(Blogdetails[0]?.id));
        toast("ADDED TO FAVORITES")
      } else {
        dispatch(BlogRemoveBookmark(Blogdetails[0]?.id));
        setisBackgroundRed(false);
        toast("REMOVED FROM FAVORITES")
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      if (Blogbookmarks?.bookmarks?.includes(Blogdetails[0]?.id)) {
        setisBackgroundRed(true);
      }
    }
  }, []);
  return (
    <>
      <div className="BlogPostContainer">
        <Card>
          <div>
            <div className="BlogTimespan">
              {moment(new Date(Blogdetails[0]?.date)).format("MMMM do, yyyy")}
              <span>
              {/* <CopyToClipboard text={`https://markid.io/learn/blogdetail/${Blogdetails[0]?.id}`}> */}
                <Button onClick={()=>{notify();copyToClipboard()}} >
                  <img src={pin} />
                </Button>
                {/* </CopyToClipboard> */}
                <Button
            onClick={() => {
              handleAddBookmark();Alertlogin()
            }}
          >
            {!isBackgroundRed ? (
              <img src={bookmark1} className="BlogImageIcons" />
            ) : (
              <img src={bookmark2} className="BlogImageIcons" />
            )}
          </Button><ToastContainer />
              </span>
            </div>
            <div>
              <h2>
                <b
                  className="BlogDetailHeading"
                  dangerouslySetInnerHTML={{
                    __html: Blogdetails[0]?.title.rendered,
                  }}
                />
              </h2>
              <b className="BlogDetailTimeread">
                {/* x mins read */}
                <span>
                  {/* <InstapaperShareButton
                    url={`https://markid.io/learn/blogdetail/${Blogdetails[0]?.id}`}
                  >
                    <img src={insta} />
                  </InstapaperShareButton> */}
                  <LinkedinShareButton
                    url={`https://markid.io/learn/blogdetail/${Blogdetails[0]?.id}`}
                  >
                    <img src={linkedin} />
                  </LinkedinShareButton>
                  <FacebookShareButton
                    url={`https://markid.io/learn/blogdetail/${Blogdetails[0]?.id}`}
                  >
                    <img src={facebook} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`https://markid.io/learn/blogdetail/${Blogdetails[0]?.id}`}
                  >
                    <img src={twitter} />
                  </TwitterShareButton>
                </span>
              </b>
            </div>
            <Divider />

            <h2>
              <b className="BlogDetailparaheading">
                {/* {Blogdetails?.[0].slug} */}
              </b>
            </h2>
            <p
              className="BlogDetailPara"
              dangerouslySetInnerHTML={{
                __html: Blogdetails[0]?.content.rendered,
              }}
            />
          </div>
        </Card>
      </div>
    </>
  );
};
export default BlogDetailComponent;
