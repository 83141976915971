/*eslint-disable*/
import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Checkbox, 
  Spin,
  Alert
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CheckoutFrom from "../components/CheckoutForm";
import OrderSummary from "../components/OrderSummary";
import "../App.css";
import _ from "lodash";
import { createCart, getCurrentUserWallet } from "../Actions/cartAction";
import SignIn from "../pages/SignIn";
const MarkidShipping = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const { nextMethod } = props;
  const { currentCart, currentAddressId, currentUser, currentUserWallet, isPostCartLoading, isAuthenticated, currentCartId } = useSelector((state) => {
    const { currentCart, currentAddressId, currentUserWallet, isPostCartLoading,currentCartId } = state.cart;
    const { currentUser } = state.user;
    const { isAuthenticated } = state.auth;
    return {
      currentCart,
      currentAddressId,
      currentUserWallet,
      currentUser,
      isPostCartLoading, 
      isAuthenticated,
      currentCartId
    };
  });
  const [addressError, setAddressError] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  useEffect(() => {
      dispatch(getCurrentUserWallet());
    },[]);
  const handleCreateCart = () => {
    const data = {}
    data.cartId = currentCartId
    data.lots = _.map(currentCart, '_id');
    data.total = (_.sumBy(currentCart, function(o) { return o.price; }) / 100).toFixed(2)
    data.addressId = currentAddressId
    data.email = currentUser.email
    if (isAuthenticated){
    if (currentAddressId) {
    dispatch(createCart(data, successCallBack, failureCallBack));
    }
  
    else {
      setAddressError(true)
    }
  }
    else {
      setShowSignInDialog(true)
    }
    
  };
  const successCallBack = () => {
    nextMethod()
  };
  const failureCallBack = () => {};
  return (
    <>
    <Spin indicator={antIcon} spinning={isPostCartLoading}>
      <Row justify="center">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card bordered={false}>
              <CheckoutFrom cartCard={currentCart} setAddressError={setAddressError}/>
          </Card>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Card className='total-item-chekout-card' bordered={false}>
           <OrderSummary 
           heading="ORDER SUMMARY"
          //  codeInput={<Input suffix={<Button type='text'>CHECK</Button>} placeholder='Promo Code' />}
          //  markidCredit={<Checkbox>Use my Markid credits</Checkbox>}
           itemTotal="Items total"
           itemTotalVal={(_.sumBy(currentCart, function(o) { return o.price; }) / 100).toFixed(2)}
           shipping="Shipping"
           shippingVal="Free"
           salesTax="Sales tax"
           salesTaxVal={(0).toFixed(2)}
           cartTotal="Cart Total"
           cartTotalVal="199.61"
           addressError={addressError}
           button={
           <Button
            block
            className={'squarebutton'}
            onClick={() => handleCreateCart()}
          >
            PROCEED
          </Button>}
           />
          </Card>
        </Col>
      </Row>
      </Spin>
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default MarkidShipping;
