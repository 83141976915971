import React from "react";
import { Card, Typography, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import "../App.css";
import { useBreakpoints } from './../react-responsive-hooks/index';
import ArrowRight from "../assets/ArrowRight.svg";
export const ExploreDealCard = (props) => {
  const { isXS } = useBreakpoints();
  const history = useHistory();
  const { exploreTopDeals } = props
  const {
    imageUrls,
    name,
    price,
    _id
  } = exploreTopDeals
  // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const images = imageUrls && imageUrls.length > 0 ? `https://images.markid.io/crop?width=600&height=600&quality=50&type=webp&url=${imageUrls[0]}` : null;

  return (
    <div className="exploreParent">
      <Card className='cMCard' onClick={() => history.push(`/product-list/${name}/${_id}`)}
        cover={<img alt="" src={images} className='exploreDealImg' style={{ height: isXS ? 175 : 295, objectFit: 'cover' }} />}
      >
        <Typography
          className="cMName"
        >
          {" "}
          {name}
        </Typography>
        <Row justify='space-between'>
          <Col gutter={[0, 5]}>
            <Typography
              className="cMPrice"
            >
              {" "}
              ${price / 100}
            </Typography>
          </Col>
          <Col>
            <img alt="" src={ArrowRight} />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
