import React from "react";
import { Row, Col, Button, Card, Typography, Input } from "antd";
import "../styles/landing.css";
const { Text, Title, Paragraph } = Typography;
const AreaNotFound = () => {
  return (
    <>
    <Row  justify='center' align='middle' gutter={[48, 48]}>
    <Col span={24}></Col>
				<Col xl={20} lg={20} md={20} sm={24} xs={24}>
      <Card style={{  backgroundColor: " #f6f6f6" }}>
        <Row justify='center' gutter={[32,32]}>
          <Col span={24}></Col>
          <Col xl={24} lg={24} md={24} sm={20} xs={20}>
            <Title level={3} strong>
              Sorry, We are currently not serving your area
            </Title>
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Paragraph>
              Not to worry-Download the markid app and list your items. Listing a free. You can reach our growing community of thousands of parents.
            </Paragraph>
          </Col>
        </Row>

        <Row justify='center'gutter={[0,16]}>
        <Col span={24}></Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Button size='large' style={{ backgroundColor: "black", color: "white" }}>
              TAKE ME TO THE APP
            </Button>
          </Col>
          <Col span={24}></Col>
        </Row>
      </Card>
      </Col>
</Row>

      <Row justify='center' gutter={[0,32]}>
        <Col span={24}></Col>
          <Col xl={24} lg={24} md={24} sm={20} xs={20}>
            <Title level={2} strong>
              Stay tuned and sign up for updates
            </Title>
            <Paragraph>
                We will connect with you once we are expand our service to your neighborhood
            </Paragraph>
          </Col>
        </Row>

        <Row justify='center' gutter={[2, 8]}>
          <Col span={24}></Col>
          <Col xl={6} lg={6} md={6} sm={18} xs={18}>
            <Input
            size='large'
              placeholder="Enter Your Zip Code"
              style={{ backgroundColor: "#ececec" }}
            />
          </Col>
          <Col xl={2} lg={2} md={2} sm={24} xs={24}>
            <Button size='large' style={{ backgroundColor: "black", color: "white" }}>
                SUBMIT
            </Button>
          </Col>
        </Row>

        <Row justify='center' gutter={[0, 48]}>
          <Col span={24}></Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Text underline style={{color: '#c0c0c0'}}>
                Cancel
            </Text>
            </Col>
        </Row>
    </>
  );
};
export default AreaNotFound;
