import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginSignup } from "../Actions/authAction";
import {
  setNavShow,
  setNavList,
  setNavColors,
  setCurrentNavSelected,
} from "../Actions/brandsAction";
import debounce from "lodash/debounce";
import { getProductType } from "../Actions/productType";
import { useGaEvent } from "../hooks/useGaEvent";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import algoliasearch from "algoliasearch";
import { Autocomplete } from "./auto-complete";
import { ProductItem } from "./product-item";
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Alert,
  Modal,
  Menu,
  Dropdown,
  Input,
  Badge,
  AutoComplete,
  Divider,
} from "antd";
import _ from "lodash";
import ShippingLogo from "../assets/Slice.svg";
import Logo from "../assets/logo.svg";
import LocationPin from "../assets/LocationPin.svg";
import ClockIcon from "../assets/clock.svg";
import UsertIcon from "../assets/UsertIcon.svg";
import CartIcon from "../assets/new-assets/cart-icon.svg";
import searchIcon from "../assets/searchIcon.svg";
import hamburgerIcon from "../assets/hamburgerNew.png";
import MessageIcon from "../assets/MessageIcon.svg";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PMenu from "../components/PMenu";
import * as yup from "yup";
import Icon, {
  SearchOutlined,
  DownOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import "../styles/landing.css";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import NavBottomRow from "../components/NavBottomRow";
import NavProfileMenu from "../components/NavProfileMenu";
import NavMenuMob from "../components/NavMenuMob";
import {
  getAllLots,
  getAllmarkidProducts,
  getSearchLot,
} from "../Actions/lotsAction";

const Searchbar = ({ categoryRef, markidlogo }) => {
  const {
    currentUser,
    providerToken,
    currentCart,
    allLots,
    searchedLots,
    recentSearches,
    messages,
    popularSearches,
    isAuthenticated,
    allChats,
    categoriesGroup,
    brandsGroup,
    currentLocationZip,
    navShow,
    navList,
    navcolors,
    currnav,
    productTypeDetails
  } = useSelector((state) => {
    const { allLots, searchedLots } = state.lots;
    const { productTypeDetails } = state.productTypeDetails;
    const { providerToken, isAuthenticated } = state.auth;
    const { currentUser, currentLocationZip } = state.user;
    const { currentCart } = state.cart;
    const { allChats } = state.chats;
    const { navShow, navList, navcolors, currnav } = state.brands;
    const { messagesByChatId } = state.chats;
    const { messages } = messagesByChatId;
    const { recentSearches, popularSearches } = state.searches;
    const { categoriesGroup, brandsGroup } = state.groups;
    return {
      currentUser,
      providerToken,
      currentCart,
      allLots,
      searchedLots,
      recentSearches,
      popularSearches,
      isAuthenticated,
      messages,
      allChats,
      currentLocationZip,
      categoriesGroup,
      brandsGroup,
      navShow,
      navList,
      navcolors,
      currnav,
      productTypeDetails
    };
  });

  const dispatch = useDispatch();
  const { gaPageView, gaEventClick } = useGaEvent();
  const params = useParams();
  const { search: urlSearch } = params;
  const location = useLocation();
  const refreshParams = new URLSearchParams(location.search);

  const [search, setSearch] = useState(
    urlSearch && urlSearch.length > 0 ? urlSearch : ""
  );
  const [modal, setModal] = useState(false);
  const [zipCode, setZipCode] = useState(
    currentLocationZip ? currentLocationZip : "10100"
  );
  const [openSearch, setOpenSearch] = useState(false);
  const { Search } = Input;
  const [collapsed, setCollapsed] = useState(false);
  const { Text } = Typography;
  const toggleCollapsed = () => {
    setOpenSearch(false);
    setCollapsed(!collapsed);
  };
  // if(!localStorage.getItem('markidlogo'))
  // {
  //   localStorage.setItem("markidlogo", Logo);
  // }

  const appId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
  const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
  const searchClient = algoliasearch(appId, apiKey);

  const { fullName, photo, zipCode: UserZipCode } = currentUser || {};
  const userImgName = fullName ? fullName.charAt(0) : null;

  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();

  const [navlist, setNavlist] = useState(navList);
  const [currentNav, setCurrentNav] = useState(currnav);
  const [colorNav, setColorNav] = useState({ bg: "#FDF5F4", text: "#21cad3" });

  const history = useHistory();

  const handleOnChangeInAutoComplete = (e, d) => {
    setModal(false);

    if (e !== "888" && e !== "999") {
      if (d.name) {
        setSearch(d.name);
        history.push(`/product-list/${d.name}/${d._id}`);
      } else {
        setSearch(e);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    dispatch({ type: "REMOVE_TOKEN" });
    dispatch({ type: "RESET_APP" });
    // history.push("/homepage");
    history.push("/");
  };

  const updatedDesing = (items) => {
    //Search Suggestion function
    // return items.map((item) => {
    //   return { ...item, label: <Row gutter={[6, 8]}><Col>{item.brand} </Col><Col style={{ fontWeight: 'bold' }}>{item.label}</Col></Row> }
    // })
  };

  const onDropdownVisibleChange = (test) => {
    // if (!test && search.length > 0) {
    //   history.push(`/search-new/${search}?refresh=true`)
    // }
  };

  const renderItem = (title, key) => {
    return {
      value: key,
      label: title,
    };
  };

  const renderTitle = (icon, title, showClear) => (
    <Row justify="space-between">
      <Col>
        <Row gutter={[12, 0]}>
          <Col>{icon}</Col>
          <Col>{title}</Col>
        </Row>
      </Col>
      {/* <Col>
        {showClear &&
          <a
            className='clearAll'
            //  href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clear all
          </a>
        }
      </Col> */}
    </Row>
  );

  const options =
    recentSearches && recentSearches.length > 0
      ? [
          {
            label: renderTitle(
              <img alt="" src={ClockIcon} />,
              <Typography className="reviewedSellerName">
                Recent Searches
              </Typography>,
              true
            ),
            options: [
              renderItem(
                <Row gutter={[15, 15]} key="1">
                  {recentSearches.map((val, i) => {
                    return (
                      <Col key={i}>
                        <Button
                          size="small"
                          className="searchesTabsButton"
                          onClick={() => handleReacentPopular(val.searchQuery)}
                        >
                          {val.searchQuery}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>,
                "888"
              ),
            ],
          },
          // <Divider />,
          // {
          //   label: renderTitle(<img alt="" src={TrendingUpIcon} />, <Typography className='reviewedSellerName'>Popular Searches</Typography>, false),
          //   options: [renderItem(
          //     <Row gutter={[15, 15]} key='2'>
          //       {popularSearches.map((val, i) => {
          //         return (
          //           <Col key={i}>
          //             <Button size='small' className='searchesTabsButton' onClick={() => handleReacentPopular(val.searchQuery)}>
          //               {val.searchQuery}
          //             </Button>
          //           </Col>
          //         )
          //       })}
          //     </Row>, '999'
          //   )],
          // },
        ]
      : [];

  const handleProfile = (id) => {
    gaEventClick("Navigation", "Profile");
    if (id != undefined) {
      history.push(`/profile/${id}`);

      document.body.scrollTo({ top: 700, behavior: "smooth" });
    } else {
      history.push(`/profile`);
    }
  };

  const handleSearch = (data) => {
    //dispatch(getAllmarkidProducts({ search: data }));
    //dispatch(getAllLots({ search: data }))
  };

  const handleOnSelect = (value, option) => {
    setSearch(option.label);
    if (history.location.pathname.includes("/product/")) {
      const path = {
        pathname: `/products/${option.label}/${value}?refresh=true`,
        state: { pId: value },
      };
      history.push(path);
    } else {
      history.push(`/product-list/${option.name}/${value}?refresh=true`);
      dispatch({ type: "RESET_SEARCH" });
    }
  };
  const handleReacentPopular = (searchQuery) => {
    history.push(`/search-new/${searchQuery}?refresh=true&zipCode=${zipCode}`);
    setModal(false);
  };

  const onKeyPressVisibleChange = (query) => {
    // dispatch(setNavShow(false));
    // setModal(false);
    // if (
    //   (test.code === "Enter" || test.key === "Enter") &&
    //   modal === true &&
    //   search.length > 0
    // ) {
    //   gaEventClick("Navigation", "Search", search);
    //   setOpenSearch(false);
    //   history.push(`/search-new/${search}?refresh=true&zipCode=${zipCode}`);
    //   localStorage.removeItem("Sizes");
    //   localStorage.removeItem("Brands");
    //   localStorage.removeItem("Selected-Brands");
    //   localStorage.removeItem("Selected-Genders");
    //   localStorage.removeItem("Selected-YearofPurchase");
    //   localStorage.removeItem("Selected-Condition");
    //   localStorage.removeItem("Selected-Shipping");
    //   localStorage.removeItem("Selected-Size");
    //   localStorage.removeItem("Selected-Max-Price");
    //   localStorage.removeItem("Active-Filter");
    //   localStorage.removeItem("Selected-Price-Range");
    //   setModal(false);
    // } else if (
    //   (test.code === "Enter" || test.key === "Enter") &&
    //   modal === false
    // )
    gaEventClick("Navigation", "Search", query.state.query);
    setOpenSearch(false);
    history.push(
      `/search-new/${query.state.query}?refresh=true&zipCode=${zipCode}`
    );
    window.location.reload(false)
    localStorage.removeItem("Sizes");
    localStorage.removeItem("Sizes");
    localStorage.removeItem("Brands");
    localStorage.removeItem("Selected-Brands");
    localStorage.removeItem("Selected-Genders");
    localStorage.removeItem("Selected-YearofPurchase");
    localStorage.removeItem("Selected-Condition");
    localStorage.removeItem("Selected-Shipping");
    localStorage.removeItem("Selected-Size");
    localStorage.removeItem("Selected-Max-Price");
    localStorage.removeItem("Active-Filter");
    localStorage.removeItem("Selected-Price-Range");
  };

  useEffect(() => {
    if (currentNav === "learn") {
      // const tempStore = [
      //   { display: "How We Work", value: "How We Work", id: 1 },
      //   { display: "Blog", value: "Blog", id: 2 },
      // ];

      setNavlist([]);
      dispatch(setNavList([]));
      setColorNav({ bg: "#FFFDF8", text: "#F2A000" });
      dispatch(setNavColors({ bg: "#FFFDF8", text: "#F2A000" }));
    }
    if (currentNav === "shop") {
      const tempStore = categoriesGroup.groupType.map((obj) => {
        return {
          display: obj.displayValue.replace("and", "&"),
          value: obj.category,
          id: obj._id,
        };
      });

      setNavlist(tempStore);
      dispatch(setNavList(tempStore));
      setColorNav({ bg: "#ECF8F8", text: "#21CAD3" });
      dispatch(setNavColors({ bg: "#ECF8F8", text: "#21CAD3" }));
    }
    if (currentNav === "brands") {
      const tempStore = brandsGroup.groupType.map((obj) => {
        return { display: obj.brand, value: obj.brand, id: obj._id };
      });

      setNavlist(tempStore);
      dispatch(setNavList(tempStore));
      setColorNav({ bg: "#ECF8F8", text: "#21CAD3" });
      dispatch(setNavColors({ bg: "#ECF8F8", text: "#21CAD3" }));
    }
    if (currentNav === "sell") {
      setColorNav({ bg: "#FEFAED", text: "#15A8FB" });
      dispatch(setNavColors({ bg: "#FEFAED", text: "#15A8FB" }));
    }
  }, [currentNav]);

  const [redirect, setRedirect] = useState(false);
  const redirectCheck = (id, obj) => {
    dispatch({ type: "CURRENT_PLP_ITEM", payload: { item: obj } });
    dispatch(
      getProductType(id, successRedirectCallBack, failureRedirectCallBack)
    );
  };
  const successRedirectCallBack = () => {
    setRedirect(true);
  };
  const failureRedirectCallBack = () => {
    setRedirect(false);
  };
  useEffect(() => {
    if (redirect) {
      {
       
          if( productTypeDetails.productType != "partnerToPerson"){
            history.push(`/product-list/${productTypeDetails.offerId}`)
            window.location.reload(false)
          }
          else{
            history.push(
              `/partner-product/${productTypeDetails.title}/${productTypeDetails.offerId}`
            );
            window.location.reload(false)
          }
      }
    }
   
  }, [productTypeDetails, redirect]);
  return (
    <>
      {(isXS || isSM || isMD) && (
        <div style={{ margin: "10px 10px 0px 10px" ,paddingBottom:"10px"}}>
          <Autocomplete
            onSubmit={(query) => {
              onKeyPressVisibleChange(query);
            }}
            placeholder="search for brands or items..."
            openOnFocus={true}
            getSources={({ query }) => [
              {
                sourceId: "products",

                getItems() {
                  return getAlgoliaResults({
                    searchClient,
                    queries: [
                      {
                        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
                        query,
                        params: {
                          filters: 'isActive:true AND isSold:false AND (deliveryType:shipping OR deliveryType:both)'
                        }
                      },
                    ],
                  });
                },
                templates: {
                  item({ item, components }) {
                    return <ProductItem hit={item} onclick={()=>{redirectCheck(item.objectID, item)}}  components={components} />;
                  },
                },
              },
            ]}
          />
        </div>
      )}
    </>
  );
};

export default Searchbar;