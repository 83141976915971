import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Spin, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "../App.css";
import { Carousel } from "antd";
import learnmore1 from "../assets/new-assets/learnmore1.png";
import learnmore2 from "../assets/new-assets/learnmore2.png";
import learnmore3 from "../assets/new-assets/learnmore3.png";
import learnmore4 from "../assets/new-assets/learnmore4.png";
import SignIn from "../pages/SignIn";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { joinCommunity } from "../Actions/userAction";
import { TypeOneReciver } from "./TypeOfMessages";
export const HomeLearnMore = (props) => {
  const history = useHistory();
  const carouselRef = useRef(null);
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  const calculateTop = () => {
    let width = 0;
    if (isXS) {
      width = 302;
    } else if (isLG) {
      width = 335;
    } else if (isSM) {
      width = 200;
    } else if (isMD) {
      width = 200;
    } else if (isXL) {
      width = 320;
    } else if (isXXL) {
      width = 500;
    }
    return width;
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  return (
    <Row>
      <Col span={24} className="heroSlider">
        {(isXL || isLG || isXXL || isMD) && (
          
            <section class="about_us_section">
              <div class="row">
                <div class="container">
                  <Row
                  
                    gutter={{ xs: 15, sm: 16, md: 24, lg: 32 }}
                    align="middle"
                    className="imagecenter display-flex-columns"
                  >
                    <Col
                      //className="gutter-row min-width-100-percent"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <img
                        className="learnmoreimg"
                        src={learnmore1}
                        alt="advisors-img"
                        width="100%"
                        height="100%"
                      />

                      <span>
                        <Typography class=" image_text_seller">
                          seller hub
                        </Typography>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                      class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="learnmoreimg"
                          src={learnmore2}
                          alt="advisors-img"
                          width="100%"
                          height="100%"
                        />
                      </a>
                      <span>
                        <Typography class="image_text_seller">
                          How to sell on markid
                        </Typography>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                      class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="learnmoreimg"
                          src={learnmore3}
                          alt="advisors-img"
                          width="100%"
                          height="100%"
                        />
                      </a>
                      <span>
                        <Typography class="image_text_seller">
                          pricing
                        </Typography>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </p>
                      </span>
                    </Col>
                    <Col
                      className="gutter-row min-width-100-percent"
                      class="team_member_advisors"
                      id="n-imagecontainer"
                      span={6}
                    >
                      <a>
                        <img
                          className="learnmoreimg"
                          src={learnmore4}
                          alt="advisors-img"
                          width="100%"
                          height="100%"
                        />
                      </a>
                      <span>
                        <Typography className="image_text_seller">
                          Seller help center
                        </Typography>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </p>
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
        
        )}
        {(isXS || isSM) && (
          <section class="about_us_section">
            <div class="row">
              <div class="container">
                <Col>
                  <Typography
                    justify="center"
                    className="Seller p title"
                  >
                    Learn more about selling on markid
                  </Typography>
                </Col>
                <Row
                  gutter={{ xs: 15, sm: 16, md: 24, lg: 32 }}
                  align="middle"
                  className="imagecenter display-flex-columns"
                >
                  <Col
                    className="gutter-row min-width-100-percent"
                    class="team_member_advisors"
                    span={6}
                  >
                    <a>
                      <img
                        src={learnmore1}
                        alt="advisors-img"
                        width="100%"
                        height="100%"
                      />
                    </a>
                    <span >
                      <p class=" image_text_learn"> seller hub</p>
                      <p> Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.</p>
                    </span>
                  </Col>
                  <Col
                    className="gutter-row min-width-100-percent"
                    class="team_member_advisors"
                    
                    span={6}
                  >
                    <a
                      
                    >
                      <img
                        src={learnmore2}
                        alt="advisors-img"
                        width="100%"
                        height="100%"
                      />
                    </a>
                    <span >
                      <p class=" image_text_learn">How to sell on markid</p>
                      <p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit.</p>
                    </span>
                  </Col>
                  <Col
                    className="gutter-row min-width-100-percent"
                    class="team_member_advisors"
                    
                    span={6}
                  >
                    <a
                     
                    >
                      <img
                        src={learnmore3}
                        alt="advisors-img"
                        width="100%"
                        height="100%"
                      />
                    </a>
                    <span class="image_text_advisors">
                      <p class=" image_text_learn">Pricing</p>
                      <p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit.</p>
                    </span>
                  </Col>
                  <Col
                    className="gutter-row min-width-100-percent"
                    class="team_member_advisors"
                    
                    span={6}
                  >
                    <a
                     
                    >
                      <img
                        src={learnmore4}
                        alt="advisors-img"
                        width="100%"
                        height="100%"
                      />
                    </a>
                    <span class="image_text_advisors">
                      <p class=" image_text_learn">Seller help center</p>
                      <p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit.</p>
                    </span>
                  </Col>

                  
                </Row>
              </div>
            </div>
          </section>
        )}
      </Col>

      {/* For Mobile  */}
    </Row>
  );
};
