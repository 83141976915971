import API from '../Utils/API';
export const getOffers = (offerId, chatId) => (dispatch) => {
  dispatch({ type: "GET_OFFERS_REQUEST" })
  API.get(`/api/v2/offers?offerId=${offerId}&chatId=${chatId}`)
    .then(response => {
      dispatch({ type: "GET_OFFERS_SUCCESS", payload: response.data })
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_OFFERS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const createMakeOffer = (data, successCallBackMakeAnOffer, failureCallBackMakeAnOffer) => dispatch => {
    dispatch({ type: "POST_OFFER_REQUEST"})
    API.post('/api/v2/offers/create/v2', data)
        .then(response => {
            dispatch({ type: "POST_OFFER_SUCCESS", payload: response.data })
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            const s = successCallBackMakeAnOffer ? successCallBackMakeAnOffer() : null;
        })
        .catch((error) => {
            try {
              dispatch({
                type: "POST_OFFER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
             const f =  failureCallBackMakeAnOffer ? failureCallBackMakeAnOffer() : null;
            } catch {
              // console.log(error);
            }
          });
  }

  export const getOfferDeclineReasons = () => dispatch => {
    dispatch({ type: "GET_OFFER_DECLINE_REASONS_REQUEST" })
    API.get(`/api/v2/offers/decline/reasons`)
      .then(response => {
        dispatch({ type: "GET_OFFER_DECLINE_REASONS_SUCCESS", payload: response.data })
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_OFFER_DECLINE_REASONS_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  }

  export const offerCancelByBuyer = (offerId, data, successCallBackCancelByBuyer, failureCallBackCancelByBuyer) => dispatch => {
    dispatch({ type: "OFFER_CANCEL_BY_BUYER_REQUEST" })
    API.patch(`/api/v2/offers/${offerId}/cancel-by-buyer`, data)
        .then(response => {
            dispatch({ type: "OFFER_CANCEL_BY_BUYER_SUCCESS", payload: response.data })
            successCallBackCancelByBuyer();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "OFFER_CANCEL_BY_BUYER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBackCancelByBuyer();
            } catch {
              // console.log(error);
            }
          });
  }

  export const offerCancelBySeller = (offerId, data, successCallBackCancelBySeller, failureCallBackCancelBySeller) => dispatch => {
    dispatch({ type: "OFFER_CANCEL_BY_SELLER_REQUEST" })
    API.patch(`/api/v2/offers/${offerId}/cancel-by-seller`, data)
        .then(response => {
            dispatch({ type: "OFFER_CANCEL_BY_SELLER_SUCCESS", payload: response.data })
            successCallBackCancelBySeller();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "OFFER_CANCEL_BY_SELLER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBackCancelBySeller();
            } catch {
              // console.log(error);
            }
          });
  }

  export const acceptOffer = (offerId, data, successCallBackAccept, failureCallBackAccept) => dispatch => {
    dispatch({ type: "ACCEPT_OFFER_REQUEST" })
    API.patch(`/api/v2/offers/${offerId}/accept-offer`, data)
        .then(response => {
            dispatch({ type: "ACCEPT_OFFER_SUCCESS", payload: response.data })
            successCallBackAccept();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "ACCEPT_OFFER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBackAccept();
            } catch {
              // console.log(error);
            }
          });
  }

  export const acceptSellersOfferByBuyer = (offerId, data, successCallBackAccept, failureCallBackAccept) => dispatch => {
    dispatch({ type: "ACCEPT_OFFER_REQUEST" })
    API.patch(`/api/v2/offers/${offerId}/accept-sellers-offer-by-buyer`, data)
        .then(response => {
            dispatch({ type: "ACCEPT_OFFER_SUCCESS", payload: response.data })
            successCallBackAccept();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "ACCEPT_OFFER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBackAccept();
            } catch {
              // console.log(error);
            }
          });
  }

  export const offerLotSold = (offerId, data, successCallBack, failureCallBack) => dispatch => {
    dispatch({ type: "OFFER_LOT_SOLD_REQUEST" })
    API.patch(`/api/v2/offers/${offerId}/lot-sold`, data)
        .then(response => {
            dispatch({ type: "OFFER_LOT_SOLD_SUCCESS", payload: response.data })
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            successCallBack();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "OFFER_LOT_SOLD_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBack();
            } catch {
              // console.log(error);
            }
          });
  }

  export const deliveredLot = (data, successCallBack, failureCallBack) => dispatch => {
    dispatch({ type: "LOT_DELIVERED_REQUEST" })
    API.post('/api/v2/offers/lot-delivered', data)
        .then(response => {
            dispatch({ type: "LOT_DELIVERED_SUCCESS", payload: response.data })
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            successCallBack();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "LOT_DELIVERED_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBack();
            } catch {
              // console.log(error);
            }
          });
  }

  export const offerApplyPin = (data, successCallBack, failureCallBack) => dispatch => {
    dispatch({ type: "OFFER_APPLY_PIN_REQUEST" })
    API.post('/api/v2/offers/apply-pin', data)
        .then(response => {
            dispatch({ type: "OFFER_APPLY_PIN_SUCCESS", payload: response.data })
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            successCallBack();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "OFFER_APPLY_PIN_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBack();
            } catch {
              // console.log(error);
            }
          });
  }

  export const offerDispute = (offerId, data, successCallBack, failureCallBack) => dispatch => {
    dispatch({ type: "OFFER_DISPUTE_REQUEST" })
    API.post(`/api/v2/offers/dispute/${offerId}`, data)
        .then(response => {
            dispatch({ type: "OFFER_DISPUTE_SUCCESS", payload: response.data })
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            successCallBack();
        })
        .catch((error) => {
            try {
              dispatch({
                type: "OFFER_DISPUTE_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              failureCallBack();
            } catch {
              // console.log(error);
            }
          });
  }


  export const updateOfferByBuyer = (offerId, data, successCallBackUpdateByBuyer, failureCallBackUpdateByBuyer) => dispatch => {
    dispatch({ type: "UPDATE_OFFER_REQUEST" })
    API.patch(`/api/v2/offers/${offerId}/update-offer-by-buyer`, data)
        .then(response => {
            dispatch({ type: "UPDATE_OFFER_SUCCESS", payload: response.data })
            const sCBUB = successCallBackUpdateByBuyer ? successCallBackUpdateByBuyer() : null;
        })
        .catch((error) => {
            try {
              dispatch({
                type: "UPDATE_OFFER_FAILURE",
                payload: error.response.data,
              });
              setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 5000);
              const fCBUB = failureCallBackUpdateByBuyer? failureCallBackUpdateByBuyer() : null;
            } catch {
              // console.log(error);
            }
          });
  }