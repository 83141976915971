/*eslint-disable*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, AutoComplete, Input, Typography, Button } from "antd";
import "../App.css";
import HeadingHearts from "../assets/HeadingHearts.svg";
import HeartMelonIcon from "../assets/HeartMelonIcon1.png";
import HeartPeachIcon from "../assets/HeartPeachIcon1.png";
import HeartBlueIcon from "../assets/HeartBlueIcon1.png";
import { WayToSellCard } from "./WayToSellCard";
import { useGaEvent } from "../hooks/useGaEvent";
import PreLovedBgshape from "../assets/PreLovedBgshape.svg";
const PrelovedKidsItem = (props) => {
    const { gaEventClick } = useGaEvent();
    const history = useHistory();
    const sellerRedirectionCheck = () => {
      if (localStorage.getItem("access_token") != null) {
        history.push("/seller/addproduct")
      }
      else {
        history.push('/onboarding')
      }
    }
    return (
        <>
            <Row className="listYourItemWrapper" gutter={[0, { xs: 0, sm: 0, md: 0, lg: 40, xxl: 40, xl: 40 }]}>
                <Col span={24}></Col>
                {/* <Col span={24}></Col> */}
                <Col span={24}>
                    {/* <div className='preLovedBlogWeb'>       */}
                    <div className='preLovedBlogWeb'>
                        {/* <div className="bg-shape"></div> */}
                        <Card bordered={false} className='prelovedCard'>
                            {/* <Row gutter={[0,36]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col span={24}></Col>
    <Col xl={24} lg={24} md={24}> */}
                            <Row gutter={[20, { xs: 32, sm: 32, md: 0, lg: 0, xl: 0 }]} justify='center'>
                                <Col span={24}></Col>
                                {/* <Col span={24}></Col> */}
                                {/* <Col span={24}></Col> */}
                                <Col>
                                    <Typography className='featuredSeller p title'>Sell preloved kids’ items</Typography>
                                </Col>
                                <Col>
                                    <img alt="" src={HeadingHearts} className='headingHearts' />
                                </Col>
                            </Row>
                            {/* </Col>
     </Row> */}
                            <Row justify='center' gutter={[0, { xs: 26, sm: 26, md: 26, lg: 40, xl: 40 }]}>
                                <Col span={24}></Col>
                                <Col>
                                    <Typography className='pKIDes'>
                                        Give your outgrown kids’ items a second life and join us in our mission to create a better world for our kids.
                                    </Typography>
                                </Col>

                              
                            </Row>
                            <Row justify="center" gutter={[0, { xs: 26, sm: 26, md: 26, lg: 40, xl: 40 }]}>
                            <Col span={24}></Col>
                            <Col><Button block className='startButton button button-primary' size='large'
                                                        href
                                                        onClick={() => {
                                                            gaEventClick("Sell", "Sell");
                                                            sellerRedirectionCheck();
                                                        }}
                                                    >
                                                        <span className='startButtontext'> Start Listing</span>
                                                    </Button>
                                                    </Col>
                            </Row>

                            <Row justify='center' style={{ marginTop: "55px", padding: "0px" }} className="app-wrapper">
                                <Col xxl={24} xl={24} lg={24} md={24}>
                                    <Row gutter={[60, 50]} justify='center'>
                                        {/* <Col span={24}></Col> */}
                                        {/* <Col span={24}></Col> */}
                                        <Col xxl={24} md={24}>
                                            <WayToSellCard
                                                // wTSTitle="LIST YOUR ITEM"
                                                // subTitle="Listing is fast and easy!"
                                                // startButton={
                                                //     <Button block className='startButton button button-primary' size='large'
                                                //         href
                                                //         onClick={() => sellerRedirectionCheck()}
                                                //     >
                                                //         <span className='startButtontext'> Start Listing</span>
                                                //     </Button>
                                                // }
                                                heartImgOne={<img alt="" src={HeartMelonIcon} width="32.46px"/>}
                                                titleOne="Create a Listing"
                                                descriptionsOne="Click and upload your kids’ outgrown items in a snap."
                                                heartImgTwo={<img alt="" src={HeartPeachIcon}width="32.46px" />}
                                                titleTwo="Make the Sale"
                                                descriptionsTwo="Chat securely with buyers to answer their questions or negotiate a price."
                                                heartImgThree={<img alt="" src={HeartBlueIcon}width="32.46px"/>}
                                                // titleThree="Meet-up or Ship"
                                                titleThree="Local Pick Up or Ship"
                                                descriptionsThree="Arrange a local pick up or have items shipped directly to the buyer."
                                                heartImgFour={<img alt="" src={HeartMelonIcon}width="32.46px" />}
                                                titleFour="Get Paid"
                                                descriptionsFour="Accept payment directly through our secure app."
                                                // availableCity=""
                                                //  startButton={
                                                //      <Button block className='startButton' size='large'
                                                //      href="https://sxh54.app.link/gXqxuVnvikb"
                                                //      target='_blank'
                                                //      >
                                                //          <span className='startButtontext'> Start Listing</span>
                                                //      </Button>
                                                //  }
                                                
                                            />
                                        </Col>

                                        {/* <Col xxl={12} md={12}>
                                            <WayToSellCard
                                                wTSTitle="NO TIME TO SELL?"
                                                subTitle="Leave it to us, literally!"
                                                heartImgOne={<img alt="" src={HeartMelonIcon} />}
                                                descriptionsOne="When you use our consignment service, markid renewed*. Our team will make the sale for you."
                                                heartImgTwo={<img alt="" src={HeartPeachIcon} />}
                                                descriptionsTwo="From pick up to storage, cleaning and making the sale, our team of experts do all the work."
                                                heartImgThree={<img alt="" src={HeartBlueIcon} />}
                                                descriptionsThree="You get paid once the item sells."
                                                heartImgFour={<img alt="" src={HeartMelonIcon} />}
                                                descriptionsFour="Fourth point to be added here in order for the layout to look more balanced."
                                                availableCity="*Markid reNEWED is only available in New York City."
                                                startButton={
                                                    <Button block className='startButton button button-primary' size='large'
                                                        href="https://markid.typeform.com/to/Wj73oCrA?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx"
                                                        target='_blank'
                                                    >
                                                        <span className='startButtontext'> Sell with reNEWED </span>
                                                    </Button>
                                                }
                                            />
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                        {/* <img alt="" src={PreLovedBgshape} style={{ width: "100%", marginTop: -2 }} /> */}


                    </div>
                    {/* </div> */}
                </Col>
            </Row>
            <div className='preLovedBlogMob'>
                <div className='kidsBgMob'>
                    <Row justify='center'>
                        <Col xl={23} lg={23} md={23}>
                            <Row gutter={[10, 30]} justify='center'>
                                {/* <Col span={24}></Col> */}
                                {/* <Col span={24}></Col> */}
                                <Col>
                                    <Typography className='featuredSeller p title'>Sell preloved kids’ items</Typography>
                                </Col>
                                <Col>
                                    <img alt="" src={HeadingHearts} className='headingHearts' />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={[0, 0]}>
                        <Col span={24}></Col>
                        <Col>
                            <Typography className='pKIDes'>
                                Give your outgrown kids’ items a second life and join us in our mission to create a better world for our kids.
                            </Typography>
                        </Col>
                    </Row>

                    <Row  justify='center' gutter={[0, 0]}>
                    <Col span={24}></Col>
                        <Col>
                        <Button block className='startButton' size='large'
                                                href
                                                onClick={() => {
                                                    gaEventClick("Sell", "Sell");
                                                    sellerRedirectionCheck();
                                                }}
                                            >
                                                <span className='startButtontext'> Start Listing </span>
                                            </Button>
                                            </Col>
                    </Row>
                    {/* <Row justify='center' gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Col>
                            <Typography className='attentionText'>
                                Choose from two ways to sell on markid.
                            </Typography>
                        </Col>
                    </Row> */}

                    <Row justify='center'>
                        <Col>
                            <Row gutter={[0, 30]} justify='center'>
                                <Col span={24}></Col>
                                <Col span={22} className="">
                                    <WayToSellCard
                                        // wTSTitle="LIST YOUR ITEM"
                                        // subTitle="Listing is fast and easy!"
                                        heartImgOne={<img alt="" src={HeartMelonIcon} width="32.46px" />}
                                        titleOne="Create a Listing"
                                        descriptionsOne="Click and upload your kids’ outgrown items in a snap."
                                        heartImgTwo={<img alt="" src={HeartPeachIcon} width="32.46px"/>}
                                        titleTwo="Make the Sale"
                                        descriptionsTwo="Chat securely with buyers to answer their questions or negotiate a price."
                                        heartImgThree={<img alt="" src={HeartBlueIcon} width="32.46px" />}
                                        // titleThree="Meet-up or Ship"
                                        titleThree="Local Pick Up or Ship"
                                        descriptionsThree="Arrange a local pick up or have items shipped directly to the buyer."
                                        heartImgFour={<img alt="" src={HeartMelonIcon} width="32.46px"/>}
                                        titleFour="Get Paid"
                                        descriptionsFour="Accept payment directly through our secure app."
                                        availableCity=""
                                        mob="true"
                                        //  startButton={
                                        //      <Button block className='startButton' size='large'
                                        //      href="https://sxh54.app.link/gXqxuVnvikb"
                                        //      target='_blank'
                                        //      >
                                        //         <span className='startButtontext'> Start Listing </span>
                                        //      </Button>
                                        //  }
                                        // startButton={
                                        //     <Button block className='startButton' size='large'
                                        //         href
                                        //         onClick={() => sellerRedirectionCheck()}
                                        //     >
                                        //         <span className='startButtontext'> Start Listing </span>
                                        //     </Button>
                                        // }
                                    />
                                </Col>
                                {/* <Col span={22}>
                                    <WayToSellCard
                                        wTSTitle="NO TIME TO SELL?"
                                        subTitle="Leave it to us, literally!"
                                        heartImgOne={<img alt="" src={HeartMelonIcon} />}
                                        descriptionsOne="When you use our consignment service, markid renewed*. Our team will make the sale for you."
                                        heartImgTwo={<img alt="" src={HeartPeachIcon} />}
                                        descriptionsTwo="From pick up to storage, cleaning and making the sale, our team of experts do all the work."
                                        heartImgThree={<img alt="" src={HeartBlueIcon} />}
                                        descriptionsThree="You get paid once the item sells."
                                        heartImgFour={<img alt="" src={HeartMelonIcon} />}
                                        descriptionsFour="Fourth point to be added here in order for the layout to look more balanced."
                                        availableCity="*Markid reNEWED is only available in New York City."
                                        mob="true"
                                        startButton={
                                            <Button block className='startButton' size='large'
                                                href="https://markid.typeform.com/to/Wj73oCrA?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx"
                                                target='_blank'
                                            >
                                                <span className='startButtontext'> Sell with reNEWED </span>
                                            </Button>
                                        }
                                    />
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default PrelovedKidsItem;
