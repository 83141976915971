import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Spin, Alert } from "antd";
import {
  LoadingOutlined,
  CloseOutlined
} from "@ant-design/icons";
import arrow from "../assets/new-assets/arrow-right.svg";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import { useForm } from "react-hook-form";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";

import OtpInput from "react-otp-input";
import {
  createVerifyOtp,
  getOtp
} from "../Actions/authAction";
import OBIllustration from "../assets/OBIllustration.png";

export const VerifyOtpForm = (props) => {
  const { current, setCurrent, otp, setOtp, changeOtp, phoneNumber } = props
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [open, setOpen] = useState(false);
  const { innerHeight, innerWidth } = window;

  const [counter, setCounter] = React.useState(105);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);


  const {
    isCreateVerifyOtpLoading,
    verifyOtperror,
  } = useSelector((state) => {
    const {
      isCreateVerifyOtpLoading,
      verifyOtperror,
    } = state.auth;
    // const { isUpdateUserLoading } = state.user;
    return {
      isCreateVerifyOtpLoading,
      verifyOtperror,
    };
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, reset } = useForm({
    // resolver: yupResolver(schema),
    mode: 'onChange',
    // defaultValues: { phone: "" },
  });
  const ol = otp.length
  const handleVerifyOtp = () => {
    dispatch(
      createVerifyOtp(
        { code: otp, phone: `1${phoneNumber}` },
        successCallBackVerifyOtp,
        failureCallBackVerifyOtp,
        reset()
      )
    );
  };
  // useEffect(() => {
  //   if (ol === 6) {
  //     handleSubmit(handleVerifyOtp())
  //   }
  // }, [ol,handleSubmit])
  const handleotp=()=>{
    if (ol === 6) {
         handleSubmit(handleVerifyOtp())
       }
  }
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const successCallBackVerifyOtp = (retunNewUser) => {
    // onCancelSignInMethod();
    if (retunNewUser === true) {
      setCurrent(current + 1);
      setOtp("");
    } else {
      //   onCancelSignInMethod();
      if (props.match.params.isCheckout) {
        history.push('/cart')
      }
      else {
        // history.push('./homepage')
        history.push('./')
      }
    }
  };
  const failureCallBackVerifyOtp = () => { };
  useEffect(() => {
    handleClick();
  }, [verifyOtperror]);
  const handleResetOpt = () => {
    dispatch(getOtp({ phone: phoneNumber }, handleResetOtpSuccessCallback));
  };
  const handleResetOtpSuccessCallback = () => {
    setCounter(59)
  }
  const handleBack=()=>{
    setCurrent(0)
  }
  return (
    <div>
      <Spin style={{ top: 200, color: '#FF533F' }} indicator={antIcon} spinning={isCreateVerifyOtpLoading}>
        <div className="sendOtpParent">
          <Card bordered={false} style={{ height: innerHeight }}>
            <form>
              <div className='onboardingWeb'>
                <div className="closeicon-wrap">
                  <CloseOutlined className='oBCloseIcon' onClick={() => handleBack()} />
                </div>
                <Row gutter={[0, 0]}>
                  <Col span={24}></Col>
                  <Col className="backarrowtext" onClick={() => handleBack()} >
                    {/* <Link to="/homepage"> */}
                    {/* <Link to="/">
                      <img alt="logo" src={Logo} className="logoStyle weblogo" />
                    </Link> */}
                     <img alt="logo" src={arrow} className="backarrowVerify" />
                    Back
                  </Col>
                </Row>
              </div>
              <div className='onboardingMob'>
                <div className="closeicon-wrapnew">
                  <CloseOutlined className='oBCloseIcon' onClick={() => history.goBack()} />
                </div>
                <div className='onboardingMob'>
                <Row gutter={[0, { xs: 43, sm: 43, md: 43, lg: 43, xl: 43, xxl: 43 }]} justify='space-between'>
                  <Col span={24}></Col>
                  <Col>
                    {/* <Link to="/homepage"> */}
                    {/* <Link to="/">
                      <img alt="logo" src={ShippingLogo} className='markidLogoMob' />
                    </Link> */}
                     <Col className="backarrowtext" onClick={() => history.goBack()} >
                     <img alt="logo" src={arrow} className="backarrowVerify" />
                    Back
                  </Col>
                  </Col>
                  {/* <Col>
                    <CloseOutlined className='oBCloseIcon' />
                  </Col> */}
                </Row>
              </div></div>
              <Row  gutter={[0, { xs: 25, sm: 25, md: 43, lg: 43, xl: 43, xxl: 43 }]}>
                <Col span={24}></Col>
                <Col>
                  <Typography className='createAccountText '>
                    Verify it’s you
                  </Typography>
                  <Typography className='heading-desctext style2'>
                  We have sent a six digit code to verify your <span class="sub-title">number +1{phoneNumber}</span>
                  </Typography>
                </Col>
              </Row>


              <Row gutter={[0, { xs: 27, sm: 27, md: 31, lg: 31, xl: 31, xxl: 31 }]} justify='center'>
                <Col span={24}></Col>
                {/* <Col>
                  <img alt="" src={OBIllustration} className='oBilustration' />
                </Col> */}
              </Row>

              <Row gutter={[0, { xs: 25, sm: 25, md: 20, lg: 20, xl: 20, xxl: 20 }]} >
                <Col span={24}></Col>
                <Col span={24}></Col>
                <Col>
                  <OtpInput
                    inputStyle="oBinputStyle style2"
                    value={otp}
                    isInputNum={true}
                    onChange={changeOtp}
                    numInputs={6}
                    shouldAutoFocus={true}
                  />
                </Col>
              </Row>
              
              <Row  gutter={[2, 5]} style={{marginTop:"65px"}} className="resend-otpblocknew">
              <Col span={16}>
              <Button className="button menu-items verifyButton button-primary" size='large'
                onClick={handleotp}
                >Verify</Button>
                </Col>
                
                <Col span={8}>
                  <Button
                    type='text'
                    disabled={counter > 0}
                    // loading={isCreateSendOtpLoading}
                    htmlType='submit'
                    form="getOtp"
                    className="oBresendLink"
                    onClick={() => handleResetOpt()}
                  >
                    Resend <div class="resend-counter">
                    {counter > 0 && <Typography>(00:{counter})</Typography>}
                  </div>
                  </Button>
                  
                </Col>
              </Row>

              {verifyOtperror && (
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Alert
                      type="error"
                      onClose={handleClose}
                      open={open}
                      message={verifyOtperror.error.message}
                    ></Alert>
                  </Col>
                </Row>
              )}
              {/* <Row justify="center" gutter={[0, { xs: 20, sm: 20, md: 30, lg: 30, xl: 30, xxl: 30 }]}>
            <Col span={24}></Col>
            <Col>
              <Button htmlType="submit" 
              disabled={otp && otp.length ? false : true}
              className={otp ? "oBenabledSignupButton": "oBdisabledSignupButton"}>
                Verify
              </Button>
            </Col>
          </Row> */}
            </form>
          </Card>
        </div>
      </Spin>
    </div>
  );
};
