import React from "react";
import { Card, Typography, Row, Col, Button, Image } from "antd";
import "../App.css";
import test1 from "../assets/test1.jpg";
import girl from "../assets/psgirl.svg";
import profileImg from "../assets/SwoondlePLPBanner.png";
import profileImgMob from "../assets/SwoondlePLPBannermweb.png";
import { useBreakpoints } from "./../react-responsive-hooks/index";

const PartnerListingBanner = () => {
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  return (
    <div className="pCategoryParent">
       {(isXL || isLG || isXXL || isMD) && (
      <Card className="pSCategoryCard">

        <Row gutter={[32, 0]} align="middle" style={{ width: "100%" }}>
          <Col style={{ width: "100%" }}>
            {/* <img alt="" src={featuredbrands} className='categoryImgWeb' /> */}
            {(isXL || isLG || isXXL || isMD) && (
              <img alt="" src={profileImg} className="pshcategoryImgWeb" />
            )}
           
            {/* <img alt="" src={imageUrl} className='categoryImgWeb' />
                      <img alt="" src={imageUrl} className='categoryImgMob' /> */}
          </Col>
          {/* <Col>
                <div className='categoryBlogWeb'>
                      <h1 className='pSName'>
                      The Swoondle Society
                      </h1>
                </div>
                <div className="categoryBlogMob">
                      <h1 className="pSName">
                      The Swoondle Society
                      </h1>
                </div>
                    <Typography className="pSResult">
                  
                    The Swoondle Society provides a seamless experience in buying new and used kids' clothing from top brands you love - at a fraction of the cost!
                    </Typography>
              </Col> */}
          {/* <Col>
              <>
                    <img alt="" src={girl}  className='pscategoryImgWeb' />
                   
                    //  <img alt="" src={imageUrl} className='categoryImgWeb' />
                    //   <img alt="" src={imageUrl} className='categoryImgMob' /> 
                  </>
                
               
              </Col> */}
        </Row>
      </Card>
       )}
        {(isXS || isSM) && (
              <img alt="" src={profileImgMob} className="pshcategoryImgMob" />
            )}
    </div>
  );
};

export default PartnerListingBanner;
