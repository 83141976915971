import API from '../Utils/API';

export const getCurrentUserOrders = (User_id) => dispatch => {
  dispatch({ type: "GET_USER_ORDERS_REQUEST" })
  API.get(`/api/V5/markid-orders/${User_id}`)
      .then(response => {
          dispatch({ type: "GET_USER_ORDERS_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "GET_USER_ORDERS_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}

export const createOrder = (data, successCallBack, failureCallBack) => dispatch => {
  dispatch({ type: "POST_ORDER_REQUEST" })
  API.post('/api/v2/orders', data)
      .then(response => {
          dispatch({ type: "POST_ORDER_SUCCESS", payload: response.data })
          successCallBack();
      })
      .catch((error) => {
          try {
            dispatch({
              type: "POST_ORDER_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            failureCallBack();
          } catch {
            // console.log(error);
          }
        });
}