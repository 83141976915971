import { Button, Row, Col, Alert, Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import promoWeb from "../assets/new-assets/emailnewsletter.svg";
import { useDispatch } from "react-redux";
import { getEmailUpdates } from "../Actions/authAction";
import { useGaEvent } from "../hooks/useGaEvent";
import signup from "../assets/new-assets/signup.svg";
import promoClose from "../assets/new-assets/crossvector.svg";
const Emailpopup = ({ close, setClose}) => {
  const { emailmessage } = useSelector((state) => {
    const { emailmessage } = state.auth;
    return {
      emailmessage,
    };
  });
  const dispatch = useDispatch();
  const { gaEventClick } = useGaEvent();
  const [emailInput, setemailInput] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState();
  const handleUpdates = () => {
    const dataInput = {
      email: emailInput,
    };
    if (dataInput != null) {
      dispatch(getEmailUpdates(dataInput));
    }

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 6000);
  };
  useEffect(()=>{
if(emailmessage=="email added"){
    setClose("false")
}
  },[emailmessage])

  const onClose = () => {
    sessionStorage.setItem("emailpop", "false");
    setClose("false");
  };

  return (
    <>
      {close == "true" && (
        <div class="fixed-contenthome">
          <div class="EmailPromocontainer">
            <img src={promoWeb} alt="Snow" className="partnerpopupimg" />

            <div className="EmailPromocentered">
              <Button className="promoClose" onClick={()=>{onClose();gaEventClick(
                        "Emailpopup",
                        "Newsletter",
                        "cross mark"
                      )}}>
                <img src={promoClose} width="18px"  />
              </Button>
            </div>
            <div class="EmailpromoText1">
              <Row>
                <Col span={20}>
                  {" "}
                  <Input.Group compact>
                    <Input
                      size="large"
                      className="input"
                      placeholder="Your Email"
                      onInput={(e) => {
                        setemailInput(e.target.value);
                      }}
                      onKeyDown={(e) => e.key === "Enter" && handleUpdates()}
                    />
                  </Input.Group>
                </Col>
                <Col span={4}>
                  <img
                    src={signup}
                    onClick={() => {
                      handleUpdates();
                      setIsAlertVisible(true);
                      gaEventClick(
                        "Emailpopup",
                        "Newsletter",
                        "sign up"
                      );
                    }}
                  />
                </Col>
              </Row>
              {isAlertVisible && (
                <Row>
                  <Col className="emailerrormsg">{emailmessage}</Col>{" "}
                </Row>
              )}
            </div>

            <div class="EmailpromoText2" onClick={()=>{onClose(); gaEventClick(
                        "Emailpopup",
                        "Newsletter",
                        "Not right now thanks"
                      );}}>
              NOT RIGHT NOW THANKS
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Emailpopup;
