import React from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "antd";
import "../App.css";

export const ProductBreadcrumb = (props) => {
const history = useHistory();
const { markid, category, brand, name } = props
  return (
    <>
       <Breadcrumb>
            <Breadcrumb.Item className="breadcrumb" onClick={() => history.push("/")}>{markid}</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb" onClick={() => history.push("/")}>{category}</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb">{brand}</Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb">
              {name}
            </Breadcrumb.Item>
          </Breadcrumb>
    </>
  );
};
