/*eslint-disable*/
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Typography,
    Card,
    Avatar,
    Divider,
    Button,
    Switch,
    Rate,
  } from "antd";
import { CheckOutlined, BulbOutlined, BellOutlined} from "@ant-design/icons";
import {MessageSeller} from "../components/MessageSeller";
import {MakeOffer} from "../components/MakeOffer";
import {EnlargedImages} from "../components/EnlargedImages";
import { createStripePayment } from "../Actions/cartAction";
const { Text, Title } = Typography;
const MakePayment = (props) => {
  const dispatch = useDispatch();
  const { heading, itemTotal, itemTotalVal, shipping, shippingVal,
    salesTax, salesTaxVal, cartTotal, cartTotalVal, button} = props;
    const { currentUserWallet, useCredits } = useSelector((state) => {
      const { currentUserWallet, useCredits } = state.cart;
      return {
        currentUserWallet,
        useCredits
      };
    });
    const { balance } = currentUserWallet
    const numberItemTotalValue = parseFloat(itemTotalVal)
    useEffect(() => {
      const value = useCredits ? (balance > numberItemTotalValue ? 0.0 : itemTotalVal - balance): itemTotalVal
      if(value > 0.0) {
        const valueToCent = value * 100;
        dispatch(createStripePayment({amount: valueToCent}))
      }
    }, []);
  return (
    <>
       <Row gutter={[0,48]}>
           <Col span={24}></Col>
              <Col>
                <Title level={4} className='notify-me'>
                  {heading}
                </Title>
              </Col>    
            </Row>

            <Row justify="space-between" gutter={[0,32]}>
                <Col span={24}></Col>
              <Col>
                <Text className='items-total'>{itemTotal}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${itemTotalVal}</Text>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{shipping}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${shippingVal}</Text>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{salesTax}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${salesTaxVal}</Text>
              </Col>
            </Row>
            {useCredits &&
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>Markid Credits</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>- ${ balance > numberItemTotalValue ? numberItemTotalValue : balance.toFixed(2)}</Text>
              </Col>
            </Row>
}
            <Divider />
            <Row justify="space-between" gutter={[0,16]}>
                <Col span={24}></Col>
              <Col>
                <Text className='items-total'>{cartTotal}</Text>
              </Col>
              <Col>
              <Text className='product-cart-value'>${useCredits ? ( balance > numberItemTotalValue ? 0.0 : itemTotalVal - balance): itemTotalVal}</Text>
              </Col>
            </Row>           
            <Row gutter={[0,32]}>
              <Col span={24}></Col>
              <Col span={24}>
               {button}
              </Col>
            </Row>
    </>
  );
};
export default MakePayment;
