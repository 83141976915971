import React from "react";
// import { Children } from "react";
import { Affix } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import MainHomePageFooter from "./MainHomePageFooter";
import RibbonTop from "./ribbonTop";
import ProductTop from "./ProductTop";
import Navbar from "./Navbar";
import Logo from "../assets/logo.svg";
import PMenu from "../components/PMenu";
import Searchbar from "./Searchbar";
const TopBottomCompo = (props) => {
  const {
    component: Component,
    categoryRef,
    showFooter,
    type,
    ...rest
  } = props;
  const history = useHistory();
  let scrollClass = "Feed";
  function handleScroll(e) {
    scrollClass = "Feed scrolling";
  }
  return (
    <>
      <Route
        {...rest}
        render={({ location }) => {
          return (
            <>
              <Affix
                offsetTop={0}
                style={{ position: "sticky", top: 0, zIndex: 10 }}
              >
                <div style={{ backgroundColor: "#fff", marginTop: 0 }} >
                  {(history.location.pathname === `/` ||
                    history.location.pathname ===
                      `/partnerproduct-list/swoondle` ||
                    history.location.pathname === `/search-new` ||
                    history.location.pathname ===
                      `/product-listing/category/clothing_and_accessories/5f8ab78836a0cd0ea2329a45`) && (
                    <RibbonTop categoryRef={categoryRef} />
                  )}

                  {/* <ProductTop categoryRef={categoryRef} />
                  
                  <div className="menuinweb">
                    <PMenu categoryRef={categoryRef} />
                  </div> */}
                  <Navbar categoryRef={categoryRef} markidlogo={Logo}/>
                  <Searchbar/>
                </div>
              </Affix>
              <Component type={type} />
              {showFooter && <MainHomePageFooter categoryRef={categoryRef} />}
            </>
          );
        }}
      />
    </>
  );
};
export default TopBottomCompo;
