import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Upload,
  Typography,
  Row,
  Col,
  Button,
  Input,
  Spin,
  Avatar,
} from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import arrow from "../assets/new-assets/arrow-right.svg";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";
import { updateUser, uploadUserPhoto } from "../Actions/userAction";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
export const NameAndEmailForm = (props) => {
  const history = useHistory();
  const { current, setCurrent } = props;
  const { innerHeight } = window;
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    fullName: yup.string().required(),
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { isUpdateUserLoading, currentUser } = useSelector((state) => {
    const { isUpdateUserLoading, currentUser } = state.user;
    return {
      isUpdateUserLoading,
      currentUser,
    };
  });
  const dispatch = useDispatch();
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: { fullName: "", email: "" },
  });
  // const [files, setFiles] = useState([]);
  // const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [fileList, setFileList] = useState("");

  // const handleImageChange = (e) => {
  //   const { value, files } = e.target;
  //   setName(value);
  //   setFiles(files);
  // };
  const handleUpdateUserNameAndEmail = (data) => {
    data.photo = currentUser.photo;
    dispatch(
      updateUser(data, successCallBackUpdateUser, failureCallUpdateUser)
    );
    dispatch(
      uploadUserPhoto(
        { file: fileList, fileName: fileList.name },
        successCallBackUpdateImage,
        failureCallUpdateUser
      )
    );

    history.push("/");
  };
  const successCallBackUpdateImage = (data) => {
    const { url } = data;
    setPhoto(url);
  };

  const successCallBackUpdateUser = (data) => {
    setCurrent(current + 1);
  };
  const failureCallUpdateUser = () => {};

  const handleUpload = () => {
    dispatch(uploadUserPhoto({ file: fileList, fileName: fileList.name }));
  };
  const propsForUpload = {
    action: handleUpload,
    // onChange: failureCallUpdateUser,
    onRemove: (file) => {
      setFileList("");
    },
    beforeUpload: (file) => {
      setFileList(file);
      return false;
    },
    multiple: false,
  };
  const { isValid } = formState;
  return (
    <div>
      <Spin
        className="onboard-2"
        style={{ top: 200, color: "#FF533F" }}
        indicator={antIcon}
        spinning={isUpdateUserLoading}
      >
        <Card bordered={false} style={{ height: innerHeight }}>
          <form onSubmit={handleSubmit(handleUpdateUserNameAndEmail)}>
            <div className="onboardingWeb">
              <div className="closeicon-wrapnew">
                <CloseOutlined className="oBCloseIcon" />
              </div>
              <Row gutter={[0, 0]}>
                <Col span={24}></Col>
                <Col>
                  {/* <Link to="/homepage"> */}
                  {/* <Link to="/">
                    <img alt="logo" src={Logo} className="logoStyle weblogo" />
                  </Link> */}
                  <Col
                    className="backarrowtext"
                    onClick={() => history.goBack()}
                  >
                    <img alt="logo" src={arrow} className="backarrowVerify" />
                    Back
                  </Col>
                </Col>
              </Row>
            </div>
            <div className='onboardingMob'>
                <div className="closeicon-wrapnew">
                  <CloseOutlined className='oBCloseIcon' onClick={() => history.goBack()} />
                </div>
                <div className='onboardingMob'>
                <Row gutter={[0, { xs: 43, sm: 43, md: 43, lg: 43, xl: 43, xxl: 43 }]} justify='space-between'>
                  <Col span={24}></Col>
                  <Col>
                    {/* <Link to="/homepage"> */}
                    {/* <Link to="/">
                      <img alt="logo" src={ShippingLogo} className='markidLogoMob' />
                    </Link> */}
                     <Col className="backarrowtext" onClick={() => history.goBack()} >
                     <img alt="logo" src={arrow} className="backarrowVerify" />
                    Back
                  </Col>
                  </Col>
                  {/* <Col>
                    <CloseOutlined className='oBCloseIcon' />
                  </Col> */}
                </Row>
              </div></div>
            <Row
              gutter={[
                0,
                { xs: 22.12, sm: 22.12, md: 50, lg: 32.5, xl: 32.5, xxl: 32.5 },
              ]}
            >
              <Col span={24}></Col>
              {/* <Col span={24}></Col> */}
              {/* <Col span={1}></Col> */}
              <Col sm={24} xs={24}>
                <Typography className="createAccountText text-left">
                  Setup your Profile
                </Typography>
                <Typography
                  className="heading-desc style2"
                  style={{ textAlign: "left" }}
                >
                  Tell us about yourself. Help us customise your experience{" "}
                  {/* <span class="sub-title">meet up or have it shipped!</span> */}
                </Typography>
              </Col>
            </Row>

            {/* <Row
              gutter={[15, { xs: 27, sm: 27, md: 37, lg: 37, xl: 37, xxl: 37 }]}
              align="middle"
            >
              <Col span={24}></Col> */}
              {/* <Col span={1}></Col> */}
              {/* Avatar section */}
              {/* <Col xl={3} lg={3} md={3} sm={5} xs={5}>
                {fileList ? (
                  <Avatar
                    size={70}
                    shape="circle"
                    // style={{height: 70, width: 70}}
                    src={photo ? photo : URL.createObjectURL(fileList)}
                  />
                ) : (
                  <img alt="" src={UserBlanckImg} />
                )}
              </Col> */}
              {/* <Col xl={15} lg={15} md={15} sm={19} xs={19} className="uImage"> */}
                {/* <label htmlFor="userImg" className='userImg'>Upload profile pic</label>
                   <input
                   id="userImg"
            name="photo"
            // value={name}
            onChange={(e) => handleImageChange(e)}
            type="file"
          ></input> */}
                {/* <Upload {...propsForUpload} className="photoUpload">
                  {!fileList && (
                    <Button style={{ color: "#FF533F" }} type="link">
                      Select Profile Picture
                    </Button>
                  )}
                </Upload> */}
                {/* {fileList && (
                  <Button
                    onClick={() => handleUpload()}
                    className="fileUpload-btn"
                    type="link"
                  >
                    Upload{" "}
                  </Button>
                )}
              </Col>
            </Row> */}
            <Row
              gutter={[0, { xs: 35, sm: 35, md: 35, lg: 35, xl: 35, xxl: 35 }]}
            >
              <Col span={24}></Col>
              {/* <Col span={1}></Col> */}
              <Col>
                <Typography className="enterMobNumText">Your name</Typography>
              </Col>
            </Row>

            <Row gutter={[0, 15]}>
              <Col span={24}></Col>
              {/* <Col span={1}></Col> */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Controller
                  as={
                    <Input
                      bordered={false}
                      className="profileInput style2"
                      size="large"
                    />
                  }
                  // addonBefore="+1"
                  // className="signinPhoneInput"
                  name="fullName"
                  control={control}
                  placeholder="Type your name"
                />
                {/* {errors.phone && phone.length === 0 && (
                <p style={{ color: "red" }}>
                  Phone number is required
                </p>
              )}
              {formState.isSubmitted && errors.phone && errors.phone?.message && (
                <p style={{ color: "red" }}>
                    {errors.phone.message}
                  </p>
              )} */}
              </Col>
            </Row>

            <Row gutter={[0, 35]}>
              <Col span={24}></Col>
              {/* <Col span={1}></Col> */}
              <Col>
                <Typography className="enterMobNumText">Your email</Typography>
              </Col>
            </Row>

            <Row gutter={[0, 15]}>
              <Col span={24}></Col>
              {/* <Col span={1}></Col> */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Controller
                  as={
                    <Input
                      bordered={false}
                      className="profileInput style2"
                      size="large"
                    />
                  }
                  // addonBefore="+1"
                  // className="signinPhoneInput"
                  name="email"
                  control={control}
                  placeholder="Type your email"
                />
                {/* {errors.phone && phone.length === 0 && (
                <p style={{ color: "red" }}>
                  Phone number is required
                </p>
              )}
              {formState.isSubmitted && errors.phone && errors.phone?.message && (
                <p style={{ color: "red" }}>
                    {errors.phone.message}
                  </p>
              )} */}
              </Col>
            </Row>
            <Row
              gutter={[
                0,
                { xs: 35.4, sm: 35.4, md: 40, lg: 200, xl: 200, xxl: 200 },
              ]}
            >
              <Col span={24}></Col>
              {/* <Col span={24}></Col> */}
              <Col style={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                  htmlType="submit"
                  disabled={!isValid}
                  className={
                    isValid
                      ? "button button-primary oBenabledSignupButton"
                      : "oBdisabledSignupButton btnFinish"
                  }
                >
                  Finish
                </Button>
              </Col>
            </Row>
          </form>
        </Card>
      </Spin>
    </div>
  );
};
