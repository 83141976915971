import React from "react";
import "../App.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
// import LazyLoad from "react-lazyload";

export const ProductImage = (props) => {
const { productimg, className } = props
  return (
    
    // <LazyLoad>
    <LazyLoadImage className={className} alt="" src={productimg} {...props}/>
    // </LazyLoad>
  );
};
