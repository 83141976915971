import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import "../App.css";
import { useBreakpoints } from './../react-responsive-hooks/index';
export const SmallCard = (props) => {
  const { isXS } = useBreakpoints();
  const history = useHistory()
  const { curatedData } = props
  const {
    imageUrls,
    name,
    price,
    _id
  } = curatedData || {}
  // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const images = imageUrls && imageUrls.length > 0 ? `https://images.markid.io/resize?width=600&height=600&quality=50&type=webp&url=${imageUrls[0]}` : null;
  return (
    <div className="CardParent">
      <Card className='smallcMCard' onClick={() => history.push(`/product-list/${_id}`)}
        cover={
          <img alt="" src={images} className='smallcMImg' style={{ height: isXS ? 82 : 146 }} />
        }
      >
        <Typography
          className="cMName"
        >
          {" "}
          {name}
        </Typography>
        <Row gutter={[0, 5]}>
          <Col span={24}></Col>
          <Typography
            className="cMPrice"
          >
            {" "}
            ${price / 100}
          </Typography>
        </Row>
      </Card>
    </div>
  );
};


export const MediumCard = (props) => {
  const { isXS } = useBreakpoints();
  const history = useHistory()
  const { curatedData } = props
  const {
    imageUrls,
    name,
    price,
    _id
  } = curatedData || {}
  // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const images = imageUrls && imageUrls.length > 0 ? `https://images.markid.io/resize?width=600&height=600&quality=50&type=webp&url=${imageUrls[0]}` : null;

  return (
    <div className="CardParent">
      <Card className='mediumcMCard' onClick={() => history.push(`/product-list/${_id}`)}
        cover={
          <img alt="" src={images} className='mediumcMImg' style={{ height: isXS ? 147 : 366 }} />
        }
      >
        <Typography
          className="cMName"
        >
          {" "}
          {name}
        </Typography>
        <Row gutter={[0, 5]}>
          <Col span={24}></Col>
          <Typography
            className="cMPrice"
          >
            {" "}
            ${price / 100}
          </Typography>
        </Row>
      </Card>
    </div>
  );
};

export const LargeCard = (props) => {
  const { isXS } = useBreakpoints();
  const history = useHistory()
  const { curatedData } = props
  const {
    imageUrls,
    name,
    price,
    _id
  } = curatedData || {}
  // const images = imageUrls && imageUrls.length > 0 ? imageUrls[0] : null;
  const images = imageUrls && imageUrls.length > 0 ? `https://images.markid.io/resize?width=600&height=600&quality=50&type=webp&url=${imageUrls[0]}` : null;

  return (
    <div className="CardParent">
      <Card className='largecMCard' onClick={() => history.push(`/product-list/${_id}`)}
        cover={
          <img alt="" src={images} className='largecMImg' style={{ height: isXS ? 308 : 636 }} />
        }>
        <Typography
          className="cMName"
        >
          {" "}
          {name}
        </Typography>
        <Row gutter={[0, 5]}>
          <Col span={24}></Col>
          <Typography
            className="cMPrice"
          >
            {" "}
            ${price / 100}
          </Typography>
        </Row>
      </Card>
    </div>
  );
};