/*eslint-disable*/
import React, { useRef, useState } from "react";
import { Row, Col, Input, Typography, Button } from "antd";
import "../App.css";
import addProductBanner from "../assets/new-assets/addProductBanner.png";
import mobileseller from "../assets/new-assets/mobileseller.png";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { useHistory } from "react-router-dom";

const MainSellerBanner = (props) => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [focus, setFocusText] = useState("Enter product title");
  const [count, setCount] = useState(0);
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();

  return (
    <Row>
      <Col span={24} className="heroSlider">
        {(isXL || isLG || isXXL || isMD) && (
          <img
            alt=""
            src={addProductBanner}
            height="270px"
            className="bannerImgWeb"
          />
        )}

        {(isXS || isSM) && (
          <img alt="" src={mobileseller} className="bannerImgMob" />
        )}
        {(isXS || isSM) && (
          <div className="mobilesellerBannerContent">
            <h1>
              <br />
              {/* that you want to sell */}What do you want to sell ?
            </h1>
            <Row
              align="middle"
              justify="space-around"
              className="addProductInput"
            >
              <Col span="24">
                <Input
                  placeholder={focus}
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    setCount(event.target.value.length);
                  }}
                  onFocus={() => setFocusText("e.g. Uppababy Vista 2020")}
                  onBlur={() => setFocusText("Enter product title")}
                  maxLength={40}
                  className="sellerInput searchInput"
                />{" "}
                <span style={{ color: "white", fontSize: "13px" }}>
                  {count}/40
                </span>
              </Col>
              <Col className={"pLsellerbutton"}>
                <Button
                  className="pLCartButton"
                  disabled={search.trim().length === 0 ? true : false}
                  onClick={() => {
                    const searchText = encodeURI(search.trim());
                    history.push(`/seller/product-details/${searchText}`);
                  }}
                >
                  list your product
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Col>

      {(isLG || isXL || isXXL) && (
        <Col span={24} style={{ position: "absolute", width: "100%" }}>
          <div className="addproductwrapper">
            <Row style={{ marginBottom: "5px" }}>
              <Col className="sellerTitle">
                <div className="mSBTitle">What do you want to sell ?</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="productNameInput">
                  <Input
                    className="partnersearch searchInput"
                    placeholder={focus}
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value);
                      setCount(event.target.value.length);
                    }}
                    onFocus={() => setFocusText("e.g. Uppababy Vista 2020")}
                    onBlur={() => setFocusText("Enter product title")}
                    maxLength={40}
                  />
                  <div style={{ color: "white", fontSize: "18px" }}>
                    {count}/40
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pLlist">
              <Col style={{ marginTop: "6px" }}>
                <Button
                  className={"pLCartButtonsell"}
                  disabled={search.trim().length === 0 ? true : false}
                  onClick={() => {
                    const searchText = encodeURI(search.trim());
                    history.push(`/seller/product-details/${searchText}`);
                  }}
                >
                  list your PRODUCT
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      )}
    </Row>
  );
};
export default MainSellerBanner;
