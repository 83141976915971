import constants from '../Utils/constants';
const initialState = {
    isCreateAccountLoading: false, isCreateLoginLinkLoading: false, isGetBalanceLoading: false,
    isCheckPayoutStatusLoading: false, isGetCardsLoading: false, isAddCardLoading: false, isDeleteCardLoading: false,
    isGetDefaultCardLoading: false, isSetDefaultCardLoading: false, isCheckMarkidFeeLoading: false, isProcessPaymentLoading: false, isGetPaymentMethodLoading: false,
    loginLink: {}, paymentBalance: {}, cards: [], defaultCard: {}, markidFee: {}, paymentMethod: {}
}

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.CREATE_ACCOUNT_REQUEST:
            return {
                ...state, isCreateAccountLoading: true
            }
        case constants.CREATE_ACCOUNT_SUCCESS:
            return {
                ...state, isCreateAccountLoading: false
            }
        case constants.CREATE_ACCOUNT_FAILURE:
            return {
                ...state, isCreateAccountLoading: false
            }

        case constants.CREATE_LOGIN_LINK_REQUEST:
            return {
                ...state, isCreateLoginLinkLoading: true
            }
        case constants.CREATE_LOGIN_LINK_SUCCESS:
            return {
                ...state, isCreateLoginLinkLoading: false, loginLink: action.payload
            }
        case constants.CREATE_LOGIN_LINK_FAILURE:
            return {
                ...state, isCreateLoginLinkLoading: false
            }

        case constants.GET_BALANCE_REQUEST:
            return {
                ...state, isGetBalanceLoading: true
            }
        case constants.GET_BALANCE_SUCCESS:
            return {
                ...state, isGetBalanceLoading: false, paymentBalance: action.payload
            }
        case constants.GET_BALANCE_FAILURE:
            return {
                ...state, isGetBalanceLoading: false
            }

        case constants.CHECK_PAYOUT_STATUS_REQUEST:
            return {
                ...state, isCheckPayoutStatusLoading: true
            }
        case constants.CHECK_PAYOUT_STATUS_SUCCESS:
            return {
                ...state, isCheckPayoutStatusLoading: false
            }
        case constants.CHECK_PAYOUT_STATUS_FAILURE:
            return {
                ...state, isCheckPayoutStatusLoading: false
            }

        case constants.GET_CARDS_REQUEST:
            return {
                ...state, isGetCardsLoading: true
            }
        case constants.GET_CARDS_SUCCESS:
            return {
                ...state, isGetCardsLoading: false, cards: action.payload
            }
        case constants.GET_CARDS_FAILURE:
            return {
                ...state, isGetCardsLoading: false
            }

        case constants.ADD_CARD_REQUEST:
            return {
                ...state, isAddCardLoading: true
            }
        case constants.ADD_CARD_SUCCESS:
            return {
                ...state, isAddCardLoading: false
            }
        case constants.ADD_CARD_FAILURE:
            return {
                ...state, isAddCardLoading: false
            }

        case constants.DELETE_CARD_REQUEST:
            return {
                ...state, isDeleteCardLoading: true
            }
        case constants.DELETE_CARD_SUCCESS:
            return {
                ...state, isDeleteCardLoading: false
            }
        case constants.DELETE_CARD_FAILURE:
            return {
                ...state, isDeleteCardLoading: false
            }

        case constants.GET_DEFAULT_CARD_REQUEST:
            return {
                ...state, isGetDefaultCardLoading: true
            }
        case constants.GET_DEFAULT_CARD_SUCCESS:
            return {
                ...state, isGetDefaultCardLoading: false, defaultCard: action.payload
            }
        case constants.GET_DEFAULT_CARD_FAILURE:
            return {
                ...state, isGetDefaultCardLoading: false
            }

        case constants.SET_DEFAULT_CARD_REQUEST:
            return {
                ...state, isSetDefaultCardLoading: true
            }
        case constants.SET_DEFAULT_CARD_SUCCESS:
            return {
                ...state, isSetDefaultCardLoading: false
            }
        case constants.SET_DEFAULT_CARD_FAILURE:
            return {
                ...state, isSetDefaultCardLoading: false
            }

        case constants.CHECK_MARKID_FEE_REQUEST:
            return {
                ...state, isCheckMarkidFeeLoading: true
            }
        case constants.CHECK_MARKID_FEE_SUCCESS:
            return {
                ...state, isCheckMarkidFeeLoading: false, markidFee: action.payload
            }
        case constants.CHECK_MARKID_FEE_FAILURE:
            return {
                ...state, isCheckMarkidFeeLoading: false
            }

        case constants.PROCESS_PAYMENT_REQUEST:
            return {
                ...state, isProcessPaymentLoading: true
            }
        case constants.PROCESS_PAYMENT_SUCCESS:
            return {
                ...state, isProcessPaymentLoading: false
            }
        case constants.PROCESS_PAYMENT_FAILURE:
            return {
                ...state, isProcessPaymentLoading: false
            }

            case constants.GET_PAYMENT_METHOD_REQUEST:
            return {
                ...state, isGetPaymentMethodLoading: true
            }
        case constants.GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state, isGetPaymentMethodLoading: false, paymentMethod: action.payload
            }
        case constants.GET_PAYMENT_METHOD_FAILURE:
            return {
                ...state, isGetPaymentMethodLoading: false
            }

        default:
            return state
    }
}
export default paymentReducer;