// import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import '../App.css';
import { Row, Col } from 'antd';
import ProfileWallet from "./ProfileWallet";
import ProfileInformation from './ProfileInformation';
const ProfileStatement = (props) => {
    const { currentUser, currentUserWallet } = props
    const { photo, fullName, phone, email, zipCode } = currentUser || {}
    const { balance } = currentUserWallet || {}
    return (
        <>
            <div className="profile-background">
                <div className="app-wrapper">
                    <Row className="profile-top" gutter={[16, { xs: 25, sm: 25, md: 64, lg: 64, xl: 64, xxl: 64 }]}>

                        <Col className="profile-lt"><ProfileInformation UserPhoto={photo} Usermobile={phone} UserfullName={fullName} location={zipCode} Useremail={email} /></Col>
                        <Col className="profile-rt"><ProfileWallet cashValue="283.54" creditValue={balance && balance.toFixed(2)} includecredit="Include Credit" creditupto="Add up to $576.32" /></Col>
                        {/* <Col xl={12} lg={12} md={12} sm={22} xs={22}>
                        <Row gutter={[0, 0]}>
                            <Col
                                xs={{ offset: 0 }}
                                sm={{ offset: 0 }}
                                md={{ offset: 0 }}
                                lg={{ offset: 0 }}
                                xl={{ offset: 0 }}
                                xxl={{ offset: 0 }}
                            >
                                <ProfileInformation UserPhoto={photo} Usermobile={phone} UserfullName={fullName} location={zipCode} Useremail={email} />
                            </Col>
                        </Row>
                    </Col> */}
                        {/* <Col xl={{ span: 7, offset: 3 }} lg={{ span: 7, offset: 3 }} md={{ span: 7, offset: 3 }} sm={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                        <Row gutter={[0, { xs: 15, sm: 15, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
                            <Col span={24}></Col>
                            <Col>
                                <ProfileWallet cashValue="283.54" creditValue={balance && balance.toFixed(2)} includecredit="Include Credit" creditupto="Add up to $576.32" />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}></Col> */}
                    </Row>
                </div>

            </div>
        </>
    );
};
export default ProfileStatement;


