import React from "react";
import ReactGA from 'react-ga';

export const useGaEvent = () => {
    const gaPageView = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    const gaEventClick = (category, action, label) => {
        ReactGA.event({ category, action, label });
    };
    return { gaPageView, gaEventClick };
}

