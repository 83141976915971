/*eslint-disable*/
import React from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
} from "antd";
import OrderConfirmation from "../components/OrderConfirmation";
import OrderDetails from "../components/OrderDetails";
import "../App.css";
const MarkidOrderConfirmation = () => {
  return (
    <>
      {/* <ShippingTopbar /> */}
      <Row justify="center">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card bordered={false}>
              <OrderConfirmation />
          </Card>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Card className='total-item-chekout-card'>
           <OrderDetails />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default MarkidOrderConfirmation;
