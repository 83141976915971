import React from "react";
import { Card, Typography, Row, Col, Button, Spin, Input } from "antd";
import whyShopMarkidImg from "../assets/new-assets/Asset8.png";
import WhyShoponMarkidImgMob from "../assets/new-assets/WhyShoponMarkidImgMob1.webp";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { useHistory, useLocation } from "react-router-dom";
import { useGaEvent } from "../hooks/useGaEvent";

const WhyShopOnMarkid = () => {
  const { isXS, isMD } = useBreakpoints();
  const { gaEventClick } = useGaEvent();
  const history = useHistory();
  return (
    <div className="whyShoponMarkidWrapper">
      <Row className="whyShopMarkid app-wrapper">
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="left">
          <Typography className="sectionTitles title">
            Why shop on markid?
          </Typography>
          <Typography className="description">
            Renewed is our hand-picked selection of preowned items that are
            inspected, professionally cleaned, sanitized and restored to a
            lovingly renewed state.
          </Typography>
          <Typography className="highlightText">
            Just the way you’d like them - for your kids.
          </Typography>

          <Button className="button button-primary readmoreButton">
            <span
              className="readmore text-uppercase"
              onClick={() => {
                gaEventClick("Content", "Why shop on markid");
                history.push("/general-questions");
              }}
            >
              read more
            </span>
          </Button>
        </Col>

        {isXS || isMD ? (
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="right">
            <img src={WhyShoponMarkidImgMob} alt="WhyShoponMarkidImgMob" />
          </Col>
        ) : (
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="right">
            <img src={whyShopMarkidImg} alt="WhyShoponMarkidImg" width="683px" height="460px"  />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default WhyShopOnMarkid;
