// import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import '../App.css';
import { Row, Col, Image, Card, Typography, Divider } from 'antd';
// import MarkidCash from "../assets/MarkidCash.svg";
import MarkidCredit from "../assets/MarkidCredit.svg";

const ProfileWallet = (props) => {
    const {
        // cashValue, 
        creditValue,
        //  includecredit, 
        creditupto } = props;
    return (
        <Card className="yourWallet walletInformation">
            <Row>
                <Col span={24}>
                    {/* <Row>
                        <Col span={16}>
                            <Row gutter={[0, 8]}>
                                <Col span={4}>
                                    <Image src={MarkidCash} />
                                </Col>
                                <Col span={20}>
                                    <Typography className="markidCash">Markid cash</Typography>
                                </Col>
                                <Col span={4}></Col>
                                <Col span={20}>
                                    <Typography className="includeCredit" >{includecredit}</Typography>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Typography className="cashvalue">${cashValue} </Typography>
                        </Col>
                    </Row> */}
                    {/* Wallet information  */}
                    <h2 className="yourWalletTitle">Your wallet</h2>
                    <Row gutter={[16, 23]}>
                        <Col span={24}></Col>
                        <Col span={24}>
                            <Row gutter={[10, 8]}>
                                <Col span={2} className="no-padding">
                                    <img alt="" src={MarkidCredit} />
                                </Col>
                                <Col span={15} className="markidPayoption">
                                    <Typography className="markidCash">Markid credit</Typography>
                                </Col>
                                <Col span={6} className="no-padding">
                                    <Typography className="cashvalue">${creditValue} </Typography>
                                </Col>

                            </Row>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </Card>

    );
};
export default ProfileWallet;


