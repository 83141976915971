import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";

const SellerHub = (props) => {
  const { Panel } = Collapse;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Markid - Seller Hub</title>
      </Helmet>
      <div class="container" id="f-container" className="contentwrap">
        <div class="Faq_content_wrapper__2zvae  col-lg-8 col-sm-12">
          <span class="">
            <h1 id="seller-hub">SELLER HUB</h1>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Selling is effortless!
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <ul>
                      <li>
                        <p>
                          Once you are logged in, tap on the “Sell” icon on the
                          bottom of the screen. Once you enter the name of the
                          item you wish to sell, click, and upload photos of it.
                          You’ll find a graphical guide in the app that’ll help
                          you shoot the perfect snaps. Remember, the best photo
                          should go first (first impressions matter!). You can
                          add up to 7 photos to the listing from your phone
                          gallery. 📷
                        </p>
                      </li>
                      <li>
                        <p>
                          Add all the fun and important details (including
                          flaws) about the item that a buyer would want to know.
                          📑
                        </p>
                      </li>
                      <li>
                        <p>
                          Select an appropriate category for your item.
                          Sometimes it may seem tricky to pick the right age. We
                          recommend you pick the most relevant (or closest) age
                          range and include any additional age groups that
                          apply, in the item description. 🔢
                        </p>
                      </li>
                      <li>
                        <p>
                          Brands grab attention, so add the brand name to your
                          listing. ✨
                        </p>
                      </li>
                      <li>
                        <p>
                          Set a price. Do your best to set a fair price after
                          considering the item’s brand, its utility, condition,
                          and so on. Don’t forget to factor in the cost of
                          shipping (if applicable) and selling fees. 💲
                        </p>
                      </li>
                      <li>
                        <p>
                          Aaand that’s all. You did it! Review everything and
                          when you’re ready, post your item for sale! 📢
                        </p>
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                     How do I edit my listing or delete it?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      Tap on the edit icon at the bottom left corner of your
                      listing to edit or remove your listing.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                     How do I receive my money?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      Once a buyer has made a payment, your money can be found
                      in your cash balance. To transfer your balance to your
                      bank account, click on “Profile” and then “My Balance”.
                      Then, tap on “Connect” in order to set up secure Stripe
                      payment.
                    </p>
                    <ol>
                      <li>
                        <p>
                          Tap on your <strong>“Profile”</strong> from the
                          navigation bar at the bottom of the screen.
                        </p>
                      </li>
                      <li>
                        <p>
                          Go to <strong>“My Balance”</strong>.
                        </p>
                      </li>
                      <li>
                        <p>
                          Click <strong>“Connect”</strong> on the{" "}
                          <strong>“My Balance”</strong> screen to add your
                          payout information. You’ll be redirected to our
                          trusted payment partner Stripe’s secure platform to
                          enter your bank account information.
                        </p>
                      </li>
                      <li>
                        <p>
                          Fill in the required information and remember to save
                          it before leaving the page.
                        </p>
                      </li>
                    </ol>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Tips for fast, successful sales!
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <ul>
                      <li>
                        <p>
                          Update your profile with relevant details and add a
                          profile picture. Profiles with profile pictures are
                          more trusted! 😎
                        </p>
                      </li>
                      <li>
                        <p>
                          Verify yourself by adding your payout details. The
                          “Verified badge” is visible on your profile as well as
                          your listing. Buyers naturally feel more reassured
                          when buying from a verified seller. ☑️
                        </p>
                      </li>
                      <li>
                        <p>
                          Be prompt and polite in your communication. Try to
                          respond to buyers’ messages and offers as soon as you
                          can. Providing a smooth and seamless experience for
                          your buyers increases your chances of a sale. If you
                          ever receive inappropriate behavior from a buyer’s
                          end, please report it to us! 💬
                        </p>
                      </li>
                      <li>
                        <p>
                          When creating your listing, be sure to add clear
                          photos and provide ample information about the item.
                        </p>
                      </li>
                      <li>
                        <p>
                          Be a reliable shipper! Sellers are expected to ship an
                          item within 3-business days from the purchase date.
                          Buyers are eager to receive their delivery so the
                          sooner you ship it the better the experience for
                          everyone. 🚚
                        </p>
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    A buyer has made an offer on an item. What should I do?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      If you’ve listed an item as <strong>“Negotiable”</strong>,
                      buyers can make offers lower than the price you’ve set.
                      There are three things you can do:{" "}
                    </p>
                    <p>
                      <strong>Accept</strong> - If you’re happy with the offer
                      made by a buyer, you can go ahead and accept the offer
                      (you’ll have 24 hours to accept an offer). Once you’ve
                      accepted the offer, the buyer will have 24 hours to
                      complete the payment.
                    </p>
                    <p>
                      <strong>Counter Offer</strong> - If you’re not happy with
                      the offer, you can counter-offer the buyer’s offer.
                    </p>
                    <p>
                      <strong>Decline</strong> - You can also choose to decline
                      an offer. When declining an offer, select a valid reason
                      from the dropdown list that appears after you click
                      “Decline”.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                   I sold an item. What happens next?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>You have two ways to deliver an item to the buyer:</p>
                    <p>
                      <strong>MEET UP</strong> 🤝 - If the buyer opts to pick up
                      the item through a local pick up, you’ll have to
                      coordinate with him/her to decide a time and location to
                      meet. Once you’ve handed off the item, don’t forget to ask
                      the buyer for the 4-digit PIN so that you can receive your
                      payment in your account.
                    </p>
                    <p>
                      <strong>SHIP</strong> 🚚 - If you selected shipping, we
                      will email you a shipping label that you can print and
                      attach to the package before shipping it. Please ship the
                      item within 3 business days.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
          </span>
        </div>
      </div>
    </>
  );
};

export default SellerHub;
