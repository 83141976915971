import React from "react";

import { Typography, Row, Col, Button, Modal } from "antd";
import { useBreakpoints } from './../react-responsive-hooks/index';
import "../App.css";

import { useHistory, useLocation, useParams } from "react-router-dom";

export const Inactivemodal = (props) => {
  const { showcancelDialog, setShowcancelDialog } = props;

  const handleCanceldialog = () => {
    setShowcancelDialog(false);
  };

  const params = useParams();
  const { title } = params;
  const { isXS } = useBreakpoints();
  return (
    <>
      <Modal
        footer={null}
        width={isXS?(300):(600)}
        visible={showcancelDialog}
        onCancel={() => {
          handleCanceldialog();
        }}
      >
        <Row justify="center">
          <Col span={24}></Col>
          <Col>
            <Typography className="cancelTextmodal" style={{margin:"20px 0px 20px 0px"}}>
              * Please remove the inactive item from the cart
            </Typography>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
