import React, { useState } from "react";
import { Card, Typography, Row, Col, Tabs, Button, Avatar, Badge, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import moment from 'moment';
import moreIcon from '../assets/new-assets/more-icon.png';
import { useDispatch, useSelector } from "react-redux";
import BlanckImportantImg from "../assets/BlanckImportantImg.svg";
import Star from "../assets/Star.svg";
import Trash from "../assets/Trash.svg";
import Info from "../assets/Info.svg";
import Block from "../assets/Block.svg";
import UnblockIcon from "../assets/UnblockIcon.svg";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { getChats, getMessagesByChatId, deleteChatById, postMarkAsSeenByChatId } from "../Actions/chatsAction";
import { blockUser, unBlockUser, reportUser } from "../Actions/userAction";
import _ from "lodash";
const { TabPane } = Tabs;

moment.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'a few seconds',
		ss: '%d seconds',
		m: '1 min',
		mm: '%d mins',
		h: '1 hour',
		hh: '%d hours',
		d: '1 day',
		dd: '%d days',
		M: '1 month',
		MM: '%d months',
		y: '1 y',
		yy: '%d years'
	}
});

export const ChatListCard = (props) => {
	const dispatch = useDispatch()
	const { userImage, userName, lastMessage, lastMessageTime, chatId, userId, participantsId, isSeenVal } = props
	const { currentUser, messagesByChatId } = useSelector((state) => {
		const { currentUser } = state.user
		const { messagesByChatId } = state.chats
		return {
			currentUser,
			messagesByChatId
		};
	});
	//   const { messages } = chats || {}
	// const isSeenMessages =  _.map(messages, {isSeen: false});
	// const isSeenCount = isSeenMessages
	// const badgesVal = isSeenCount && isSeenCount.length
	// console.log({badgesVal})
	const { blockedUsers } = currentUser || {}
	let blockedUserId = ""
	{
		blockedUsers &&
			blockedUsers.map((current) => {
				return (
					current === participantsId ? blockedUserId = current : null
				)
			})
	}
	// const [chatListMenu, setChatListMenu] = useState(false)
	// const [chatColor, setChatColor] = useState("");
	const [pop, setPop] = useState(true);

	// const handleCardColor = (data) => {
	//     data === userName ? setChatColor(data) : setChatColor("")
	// }
	const handleDeleteChat = (chatId) => {
		dispatch(deleteChatById(chatId, successDeleteChat, failiureDeleteChat))
	}
	const successDeleteChat = () => {
		dispatch(getChats())
		dispatch(getMessagesByChatId(chatId))
	}
	const failiureDeleteChat = () => { }

	const handleBlockUser = (userId) => {
		dispatch(blockUser(userId, successCallBlockUser, failureCallBlockUser));
		setPop(false);
	}
	const successCallBlockUser = () => {
		dispatch(getChats())
		dispatch(getMessagesByChatId(chatId))
	}
	const failureCallBlockUser = () => { }

	const handleUnBlockUser = (userId) => {
		dispatch(unBlockUser(userId, successCallUnBlockUser, failureCallUnBlockUser));
		setPop(false);
	}
	const successCallUnBlockUser = () => {
		dispatch(getChats())
		dispatch(getMessagesByChatId(chatId))
	}
	const failureCallUnBlockUser = () => { }

	const handleReportUser = (userId) => {
		dispatch(reportUser(userId, successCallReportUser, failureCallReportUser))
	}
	const successCallReportUser = () => {
		dispatch(getChats())
		dispatch(getMessagesByChatId(chatId))
	}
	const failureCallReportUser = () => { }
	const content = (
		<div>
			{participantsId === blockedUserId &&
				<>
					<Row justify="end">
						<Col>
							<img src={moreIcon} alt="" style={{ marginRight: -10 }} onClick={() => setPop(false)} className="moreIcon" />
						</Col>
					</Row>
					<Row>
						<Col>
							<Button type='text' icon={<img alt="" src={UnblockIcon} style={{ paddingRight: 9 }} />} className='chatListMenu'
								onClick={() => handleUnBlockUser(userId)}
							>
								UnBlock User
							</Button>
						</Col>
					</Row>
				</>
			}
			{participantsId !== blockedUserId &&
				<>
					<Row justify="end">
						<Col>
							<img src={moreIcon} alt="" style={{ marginRight: -10 }} onClick={() => setPop(false)} className="moreIcon" />
						</Col>
					</Row>
					{/* <Row>
                <Col>
                    <Button type='text' icon={<img alt="" src={Star} style={{ paddingRight: 9 }} />} className='chatListMenu'>
                        Mark as important
                    </Button>
                </Col>
            </Row> */}
					<Row>
						<Col>
							<Button type='text' icon={<img alt="" src={Trash} style={{ paddingRight: 9 }} />} className='chatListMenu'
								onClick={() => handleDeleteChat(chatId)}
							>
								Delete Chat
							</Button>
						</Col>
					</Row>
					{/* <Row>
                <Col>
                    <Button type='text' icon={<img alt="" src={Info} style={{ paddingRight: 9 }} />} className='chatListMenu'
						onClick={() => handleReportUser(userId)}
					>
                        Report User
                    </Button>
                </Col>
            </Row> */}
					<Row>
						<Col>
							<Button type='text' icon={<img alt="" src={Block} style={{ paddingRight: 9 }} />} className='chatListMenu'
								onClick={() => handleBlockUser(userId)}
							>
								Block User
							</Button>
						</Col>
					</Row>
				</>
			}
		</div>
	);
	return (

		<Row justify='space-between'>
			<Col className="chatCard-left">
				<Row gutter={[9, 0]}>
					<Col className="avatarWrap">
						<Badge count={0} offset={[-10, 40]}>
							{userImage ?
								<Avatar shape='circle' src={userImage} />
								:
								<Avatar shape='circle' src={UserBlanckImg} />
							}
						</Badge>
					</Col>
					<Col className="detailsWrap">
						<Typography
							// className={participantsId === blockedUserId? 'chatListUserName blockCol':'chatListUserName'}
							className="chatListUserName">
							{userName}
						</Typography>
						<Row gutter={[0, 10]}>
							<Col span={24}></Col>
							<Col>
								<Typography
									// className={participantsId === blockedUserId? 'chatListLastMessage blockCol': 'chatListLastMessage'}
									className="chatListLastMessage">
									{lastMessage && lastMessage.length > 10 ? lastMessage.substring(0, 150) + "..." : lastMessage}
								</Typography>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>

			<Col className="chatCard-right">
				<Typography
					className="chatListMessageTime"
				// className={participantsId === blockedUserId ?'chatListMessageTime blockCol':'chatListMessageTime'}
				>
					{moment(lastMessageTime).startOf('seconds').fromNow()}
				</Typography>
				<Row gutter={[0, 10]} justify='end' style={{ position: 'relative' }}>
					<Col span={24}></Col>
					<Col>
						{pop ?
							<Col>
								<Popover overlayInnerStyle={{
									marginRight: "-30px",
									marginTop: '-15px'
								}} className="popover" placement="leftTop" content={content} trigger="click">
									{props.children}
								</Popover>
							</Col>
							:
							<Col>
								<img src={moreIcon} alt="" className="moreIcon" onClick={() => setPop(true)} />
							</Col>
						}
						{/* <Popover overlayInnerStyle={{
                                marginRight:"-50px"
                            }} className="popover" placement="leftTop" content={content}  trigger="click">
                                <MoreOutlined  className='moreIcon' />
                            </Popover> */}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export const UserChatList = (props) => {
	const dispatch = useDispatch();
	const { currentUser, chat, currentChat } = useSelector((state) => {
		const { currentUser } = state.user
		const { currentChat } = state.chats
		const { chat } = currentChat
		return {
			currentUser,
			chat,
			currentChat
		};
	});
	const { blockedUsers } = currentUser || {}
	const { participants } = chat || {}
	const { innerHeight } = window;
	let participantsId = participants && participants[1];

	let blockedUserId = ""
	blockedUsers &&
		blockedUsers.map((current) => {
			return (
				current === participantsId ? blockedUserId = current : null
			)
		});

	const { ChatListData } = props
	const [tabKey, setTabkey] = useState("1")
	const [chatColor, setChatColor] = useState("");

	function chatTabPan(key) {
		setTabkey(key)
	}
	const handleChat = (val) => {
		dispatch({ type: "SET_CURRENT_CHAT", payload: { chat: val } })
		dispatch(postMarkAsSeenByChatId(val.chat?._id));
		setChatColor(val.interlocutor?._id);
	}

	const handleMore = (data) => {

	}

	return (
		<div className="chatListParent">
			<div className="chatCardParent" style={{ height: innerHeight - 214, overflow: 'hidden' }}>
				<Card className='chatListBgCard'>
					<Tabs defaultActiveKey="1" onChange={chatTabPan}
						renderTabBar={(props, DefaultTabBar) => (
							<DefaultTabBar {...props} className='chatListTabBar'>
								{node => (
									<div key={node.key} index={node.key}>
										{node}
									</div>
								)}
							</DefaultTabBar>
						)
						}
					>
						<TabPane key="1"
							tab={<Button type='text' className={tabKey === "1" ? 'chatListSelectedTab' : 'chatListUnSelectedTab'} >All messages</Button>}>
							<Row className="chatScroll" style={{ height: innerHeight - 310, overflow: 'hidden', overflowY: 'scroll' }}
								justify={ChatListData && ChatListData.length > 0 ? "" : "center"}
							>
								<Col span={24}>
									{ChatListData && ChatListData.length > 0 ?
										<Row gutter={[0, 20]}>
											<Col span={24}></Col>
											{ChatListData.map((val, i) => {
												return (
													<Col key={i} span={24}
														onClick={() => handleChat(val)}
													>
														<div className="messagecardParent">
															<Card className={chatColor === val.interlocutor?._id
																? (blockedUserId === participantsId)
																	? 'chatListCard blockedColor'
																	: (currentChat?.chat?._id === val.chat?._id
																		? 'chatListCard cardColor activeCard'
																		: 'chatListCard')
																: 'chatListCard'}
															>
																<ChatListCard
																	chatId={val.chat?._id}
																	userId={val.interlocutor?._id}
																	participantsId={val.chat.participants[1]}
																	blockedUsers={val.interlocutor?.blockedUsers}
																	userImage={val.interlocutor?.photo}
																	userName={val.interlocutor?.fullName}
																	lastMessage={val.lastMessage?.message}
																	isSeenVal={val.lastMessage?.isSeen}
																	lastMessageTime={val.lastMessage?.createdAt}
																>
																	<img src={moreIcon} alt="" onClick={() => handleMore(val.interlocutor?.fullName)} className="moreIcon" />
																</ChatListCard>
															</Card>
														</div>
													</Col>
												)
											})}
										</Row>
										:
										<>
											<Row gutter={[0, 46.67]} justify='center'>
												<Col span={24}></Col>
												<Col span={24}></Col>
												<Col span={24}></Col>
												<Col>
													<img alt="" src={BlanckImportantImg} />
												</Col>
											</Row>
											<Row gutter={[0, 33]} justify='center'>
												<Col span={24}></Col>
												<Col>
													<Typography className='blanckImportantText'>
														No messages, yet?
													</Typography>
												</Col>
											</Row>
										</>
									}
								</Col>
							</Row>
						</TabPane>
						<TabPane key="2"
							tab={<Button type='text' id="btn-important" className={tabKey === "2" ? 'chatListSelectedTab ' : 'chatListUnSelectedTab'}>Important</Button>}>
							<Row className="chatScroll" style={{ overflowY: 'scroll', height: 454 }} justify='center'>
								<Col>
									<Row gutter={[0, 46.67]} justify='center'>
										<Col span={24}></Col>
										<Col span={24}></Col>
										<Col span={24}></Col>
										<Col>
											<img alt="" src={BlanckImportantImg} />
										</Col>
									</Row>
									<Row gutter={[0, 33]} justify='center'>
										<Col span={24}></Col>
										<Col>
											<Typography className='blanckImportantText'>
												There is no important message
											</Typography>
										</Col>
									</Row>
								</Col>
							</Row>
						</TabPane>
					</Tabs>
				</Card>
			</div>
		</div>
	);
};
