/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Typography, Card, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import "../App.css";
import MainSellerBanner from "../components/MainSellerBanner";

import { TestimonialsCard } from "../components/TestimonialsCard";

import { getTestimonialsGroup } from "../Actions/groupAction";

import { ToptipsforSelling } from "../components/ToptipsforSelling";
import { SellerHowitWorks } from "../components/SellerHowitWorks";
import { HomeLearnMore } from "../components/HomeLearnMore";

import { WhyMarketPlace } from "../components/WhyMarketPlace";

const MainSellerPage = (props) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: "#FD533F" }} spin />
  );

  
  const dispatch = useDispatch();

  const { testimonialsGroup, isGetTestimonialsGroupLoading } = useSelector(
    (state) => {
      const {
        featuredSellersGroup,

        testimonialsGroup,

        isGetfeaturedSellersGroupLoading,

        isGetTestimonialsGroupLoading,
      } = state.groups;

      return {
        featuredSellersGroup,

        testimonialsGroup,

        isGetfeaturedSellersGroupLoading,

        isGetTestimonialsGroupLoading,
      };
    }
  );

  const { group: testimonialsGroupDetail, groupType: testimonialsGroupType } =
    testimonialsGroup;

  useEffect(() => {
    dispatch({ type: "GET_TAB_KEY", payload: "" });

    dispatch(getTestimonialsGroup("testimonialsUser"));
  }, []);

  return (
    <div className="homeScreen">
      <MainSellerBanner />
      <Row
        className="app-wrapper"
        justify="center"
        gutter={[0, { xs: 20, sm: 20, md: 100, lg: 86, xl: 86, xxl: 86 }]}
      >
        <WhyMarketPlace />
      </Row>
      <SellerHowitWorks />

      {/* <Row
        className="app-wrapper"
        justify="center"
        gutter={[0, { xs: 20, sm: 20, md: 100, lg: 86, xl: 86, xxl: 86 }]}
      >
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col>
          <Typography className="featuredSeller title featuredseller-block">
            Learn more about selling on markid
          </Typography>
        </Col>
        <HomeLearnMore />
      </Row> */}

      <Row justify="center" className="app-wrapper ">
        <Col span={24}></Col>
        <Col xl={23} lg={23} md={23} sm={24} xs={24}>
          <Row
            gutter={[{ xs: 20, sm: 20, md: 20, lg: 80, xl: 80, xxl: 80 }, 0]}
          ></Row>
        </Col>
      </Row>

      <Row
        gutter={[0, { xs: 70, sm: 70, md: 12, lg: 12, xl: 12, xxl: 12 }]}
      >
        <Col span={24} />
        <Col span={24}>
          <ToptipsforSelling />
        </Col>
        {/* <Col span={24} /> */}
      </Row>

      {/* <Row>
        <Col span={24} className="testiParent">
          <Card className="tSBackgroung">
            <Spin indicator={antIcon} spinning={isGetTestimonialsGroupLoading}>
              <Row
                justify="center"
                gutter={[0, { xs: 20, sm: 20, md: 94, lg: 94, xl: 94 }]}
              >
                <Col span={24}></Col>
               
                <Col>
                  <Typography className="featuredSeller title">
                    What the seller says
                  </Typography>
                </Col>
              </Row>

         
              <Col>
                <Row
                  gutter={[
                    { xs: 30, sm: 30, md: 60, lg: 60, xl: 60 },
                    { xs: 25, sm: 25, md: 75, lg: 75, xl: 75 },
                  ]}
                  justify="center"
                  className="testimonials-wrap"
                >
                  <Col span={24}></Col>
                
                  {testimonialsGroupType &&
                    testimonialsGroupType.map((val, i) => {
                      return (
                        <Col className="testimonials-col" key={i}>
                          <TestimonialsCard
                            testimonialsData={val}
                            testimonialsIndex={i}
                          />
                        </Col>
                      );
                    })}
                  <Col span={24}></Col>
                </Row>
              </Col>
            </Spin>
          </Card>
        </Col>
      </Row> */}
    </div>
  );
};
export default MainSellerPage;
