import React, { useState, useRef } from "react";

import { Card, Typography, Row, Col, Button, Spin, Input, Modal } from "antd";

import "../App.css";

import How4 from "../assets/new-assets/confirmation.png";
import { useHistory } from "react-router-dom";

export const SellerConfirmation = (props) => {
  const { showConfirmDialog, onCancelConfirmDialog,currentUser} = props;
  const history = useHistory();
  const { _id } = currentUser || {};
  const redirectToHome = () => {
    history.push("/")
    localStorage.removeItem("productDescription")
    localStorage.removeItem("negotiablePrice")
    localStorage.removeItem("retailPrice")
    localStorage.removeItem("age")
    localStorage.removeItem("activeManufacturedYear")
    localStorage.removeItem("activeWeight")
    localStorage.removeItem("size")
    localStorage.removeItem("activepurchasedYear")
    localStorage.removeItem("productTitle")
    localStorage.removeItem("activeCategory")
    localStorage.removeItem("Pay")
    localStorage.removeItem("deliveryMethod")
    localStorage.removeItem("sellingPrice")
    localStorage.removeItem("activeGender")
    localStorage.removeItem("activeHeight")
    localStorage.removeItem("activeColor")
    localStorage.removeItem("imagesUploaded")
    localStorage.removeItem("activeLength")
    localStorage.removeItem("currentAddressID")
    localStorage.removeItem("activeCondition")
    localStorage.removeItem("estimatedCharges")
    localStorage.removeItem("activeBrand")
    localStorage.removeItem("activeWidth")
    localStorage.removeItem("brandlogo")
    localStorage.removeItem("ImageLinks")
    localStorage.removeItem("descriptionCount")
  };
  return (
    <>
      <Modal
        footer={null}
        width={813}
        visible={showConfirmDialog}
        onCancel={() => redirectToHome()}
      >
        <form>
          <div className="confirmwrapper">
            <Row gutter={[0, { xs: 50, sm: 50, md: 76, lg: 76, xl: 76 }]}>
              <div className="confirmationCard">
                <Row gutter={[0, 77]}>
                  <Col span={24}></Col>
                  <Col className="confirmimg">
                    <img src={How4} alt="advisors-img" />
                  </Col>
                </Row>
                <Row gutter={[0, 39]}>
                  <Col span={24}></Col>
                  <Col>
                    <Typography className="confirmationtext">
                      Congrats! You’ve successfully listed your item. Check here
                      to view your listing.
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[0, 61]}>
                  <Col span={24}></Col>
                  <Col className="confirmbutton">
                    <Button
                      justify="center"
                      className={"button button-primary  pLCartButton"}
                      onClick={() => history.push(`/seller-profile/${_id}`)}
                    >
                      My Listing
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </form>
      </Modal>
    </>
  );
};
