import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Checkbox, Collapse, Badge } from "antd";

const Faq = (props) => {
  const { Panel } = Collapse;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Markid - General Questions</title>
      </Helmet>
      <div class="container" id="f-container" className="contentwrap">
        <div>
          <span class="">
            <h1 id="general-questions">GENERAL QUESTIONS</h1>
            {/* <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    1.How can I get the Markid App?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    Markid is available on iOS and Android. Simply download the
                    app from either the App Store or Google Play.
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    2.How do I get started?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    If you’re new to the experience, we recommend you take a few
                    minutes to complete your profile because details make you!
                    All those tiny details like adding a profile photo, writing
                    a couple of lines to describe yourself, verifying yourself
                    and your payment details will make Markid a warmer and more
                    trusted community for parents to connect on. Make sure to
                    read the{" "}
                    <a href="https://markid.io/community-guidelines">
                      Markid Community Guidelines
                    </a>{" "}
                    which were created keeping you (the parents) in mind, to
                    help make your experience as warm as possible.
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    3.Which locations do you currently serve?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    For local pick up, Markid is primarily available in New York
                    City. However, for shipping, Markid is available in the
                    entirety of the United States.
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    4.Do you deliver to PO Boxes?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    No, currently we do not support delivery to PO Boxes.
                  </Typography>
                </Row>
              </Panel>
            </Collapse> */}
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Meet-up VS Shipping - How do I verify my purchase?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      <strong>MEET UP</strong> 🤝 - The buyer will need to
                      confirm the accuracy of the item at the time of pickup.
                      Make sure to remind the buyer to accept the receipt of the
                      item by going to the “Messages” tab on their app and
                      tapping “Confirm Order Received”.
                    </p>
                    <p>
                      <strong>SHIPPING</strong> 🚚 - The buyer has 48 hours from
                      the time they receive an item to verify that the condition
                      and quality of the item match the description in its
                      listing. In case of any discrepancies reported by the
                      buyer within that time, we will initiate a review. If the
                      item is found to be misrepresented (Wrong item, does not
                      match the listing, missing items) in the listing, we will
                      cancel the sale and refund the buyer. The seller will be
                      responsible for the return shipping of the item.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    {" "}
                    How does the shipping fee work on Markid?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <ul>
                      <li>
                        We offer USPS Priority 1 to 3-day shipping. All items
                        are insured by USPS for up to $100.
                      </li>
                      <li>
                        The seller decides whether to make an item available for
                        shipping.
                      </li>
                      <li>
                        The seller also decides, at the time of listing an item,
                        who pays for shipping (Buyer or Seller).
                      </li>
                      <li>
                        The buyer is responsible for shipping fees unless the
                        seller has offered FREE SHIPPING on the item.
                      </li>
                      <li>
                        Items are marked with FREE SHIPPING when a seller
                        chooses to pay for shipping.
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How do I know that an item is eligible for shipping?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      To be eligible for shipping, items must meet the following
                      criteria:
                    </p>
                    <ul>
                      <li>
                        Items must weigh less than 50lbs and be smaller than 108
                        inches in dimension (length+width).
                      </li>
                      <li>
                        Items must be available for immediate shipping – items
                        must be shipped within 3 business days from the date of
                        purchase.
                      </li>
                      <li>
                        If it complies with USPS guidelines outlined in our
                        shipping menu, it can be shipped! Sellers have to comply
                        with all applicable laws and regulations regarding
                        mailing. For a more comprehensive understanding, you can
                        check the{" "}
                        <a href="https://www.usps.com/ship/shipping-restrictions.htm">
                          US Postal Service shipping restrictions webpage
                        </a>
                        .
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How do payments work on Markid?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      It's important to us that both buyers and sellers feel
                      secure while transacting in the marketplace.
                    </p>
                    <p>
                      <strong>FOR BUYERS</strong> – As a buyer, you can conduct
                      secure and seamless payments using your credit/debit card,
                      Google Play, or Apple Pay.
                    </p>
                    <ul>
                      <li>
                        You will never need to share your personal information –
                        Email, Phone, or Account number with the seller.{" "}
                      </li>
                      <li>
                        Our trusted payment processor Stripe will process your
                        payments on our behalf and ensure that your payment
                        information is stored securely.
                      </li>
                      <li>
                        You can easily make an in-app purchase using your
                        preferred method of payment (once your offer is accepted
                        by the seller).{" "}
                      </li>
                      <li>
                        When you make an offer on an item you're interested in,
                        the seller has 24 hours to accept your offer and confirm
                        the item’s availability. Your payment will be charged
                        only when the seller accepts your offer.
                      </li>
                    </ul>
                    <p>
                      <strong>FOR SELLERS</strong> – We’ve made sure that your
                      experience as a seller is as smooth as that of a buyer.
                    </p>
                    <ul>
                      <li>
                        Thanks to Stripe (our payment processor), you can accept
                        payouts (for items sold) directly to your bank account.
                      </li>
                      <li>
                        When an interested buyer makes an offer on your listed
                        item, you have 24 hours to accept the offer and confirm
                        availability.
                      </li>
                      <li>
                        Before accepting your first offer on Markid, you will be
                        asked to add your bank details to begin accepting
                        payouts securely to your bank account.
                      </li>
                      <li>
                        Payment is processed after the buyer receives your item.
                        It will take 2-3 business days for it to appear in your
                        bank account.
                      </li>
                      <li>
                        In the case of a local meet-up, payment is processed
                        after the buyer confirms that they have received the
                        item by tapping “Confirm Order Received” from their
                        “Notifications”.
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    What details do I need to receive my money?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      We consider your safety our highest priority. Together
                      with our trusted payment provider Stripe, we apply the
                      standard and required{" "}
                      <a href="https://en.wikipedia.org/wiki/Know_your_customer">
                        KYC guidelines
                      </a>{" "}
                      (Know Your Customer). Simply put, we may need you to
                      confirm your identity, to make sure it is really you who
                      is making the payout, which will enable a secure transfer
                      to your bank account.
                    </p>
                    <p>
                      Stripe may require the following information/documents to
                      complete your verification process:
                    </p>
                    <ul>
                      <li>
                        Full name and birthdate of the checking bank account
                        holder.
                      </li>
                      <li>
                        Last 4 numbers of SSN (Social Security Number). There
                        might be rare cases when your full SSN is needed but
                        please note that we don’t store this information; it’s
                        only used to verify your identity and ensure a secure
                        payout.
                      </li>
                      <li>
                        9-digit routing number (make sure you don’t mix this
                        with the account number). Also, make sure you’re using
                        the electronic transfer routing number (ACH) assigned
                        for your bank and area, which isn’t necessarily the
                        routing number you see on your checks (ABA/RTN). Check
                        with your bank if you're not sure.
                      </li>
                      <li>
                        Bank account number (9-12 digits) of the checking
                        account you want to pay out to.
                      </li>
                      <li>Full name.</li>
                      <li>Billing address.</li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                  What is Markid’s Selling Fee?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      Listing is always free on Markid. When you make a sale, we
                      charge a 10% selling fee based on the listed price of your
                      item. The selling fee of 10% includes the payment
                      transaction fee of 2.9% + 30 cents per transaction.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Can I transfer Markid cash to my bank account?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      You are free to use your Markid cash for any in-app
                      transactions but it cannot be transferred to a bank
                      account.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    What should I do if someone asks me to take a transaction
                    offline?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      You should use Markid to complete your buying process from
                      start to finish. Never share your personal information
                      (Email, home address, phone number, or account number) in
                      the message thread. Our secure payment process is set up
                      to keep you both from having a bad experience. We won’t be
                      able to protect a purchase that is taken offline.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How does the Negotiable offer feature work for buyers
                    &amp; sellers?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      In order to make it easy for the buyer and seller to
                      negotiate on the price of an item, we have the Price
                      Negotiation feature in place.
                    </p>
                    <p>
                      The seller decides whether to list an item as
                      “Negotiable”. If you do not want to negotiate on the price
                      of the item you’re selling, simply indicate in your
                      listing that the item is only available at the listed
                      price, by selecting <strong>“NO”</strong> for{" "}
                      <strong>“Negotiable”</strong>. Buyers can{" "}
                      <strong>“Make an offer”</strong> on an item that is listed
                      as Negotiable.
                    </p>
                    <p>
                      <strong>Please note</strong> - All offers are binding,
                      once the seller accepts the offer. The seller has 24 hours
                      to <strong>Accept</strong> or <strong>Reject</strong> your
                      offer. So please make sure you’re absolutely certain that
                      you like the item before you purchase it.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Why can’t I sell/purchase car seats on Markid?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      We do not allow the purchase and sale of used car seats on
                      Markid. Used car seats can pose a risk to child safety
                      since we cannot validate their reliability, or recall the
                      status of their expiration date as set by the
                      manufacturer, or if a car seat has been involved in an
                      accident.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    What is the Markid #PARENTSFORPUPILS program all about?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      For every item you sell on Markid, you can donate a
                      portion directly to a school in need. Our{" "}
                      <strong>#ParentsforPupils</strong> program makes it easy
                      for parents to help classrooms improve their facilities
                      for kids.
                    </p>
                    <p>
                      At Markid, we are committed to bringing focus on the vast
                      economic disparity in public schools, starting with New
                      York City. With your help (the buyer and seller parents)
                      we aim to fund classroom projects for underprivileged
                      schools in the community. We have partnered with{" "}
                      <strong>DonorsChoose</strong> to showcase projects that
                      have the highest economic requirements and provide
                      students with the tools and experiences they need to be
                      successful.
                    </p>
                    <p>
                      Click to learn more about{" "}
                      <a href="https://www.donorschoose.org/about">
                        DonorsChoose
                      </a>
                      .
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How will selling or buying play a role in benefiting a
                    classroom?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      <strong>As a seller</strong>, you can choose to donate a
                      portion or all of the proceeds from your listed item to
                      our spotlight classroom project.
                    </p>
                    <p>
                      Remember, each item your child outgrows has the potential
                      to enhance the life of a student in need. When listing an
                      item, you can consider whether to donate a portion of your
                      sale &amp; specify the percentage you would like to
                      donate. The donor badge will appear on your item listing
                      which informs the buyer that the particular sale benefits
                      our spotlight classroom project. Once your item sells,
                      your specified donation amount will be automatically
                      deducted from the sale price and transferred to{" "}
                      <a href="https://www.donorschoose.org/about">
                        DonorsChoose
                      </a>
                      .
                    </p>
                    <p>
                      As a buyer, when you purchase items marked for the{" "}
                      <strong>#PARENTSFORPUPILS</strong> cause, you directly
                      contribute to creating better educational opportunities
                      for children like yours.
                    </p>
                    <ul>
                      <li>
                        If you see the donor badge on a listed item, you’ll know
                        that this particular sale will benefit our spotlight
                        classroom project.
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How is a school's economic need-level defined at
                    DonorsChoose.org?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      At <strong>DonorsChoose</strong>, calculations of
                      low-income households refer to the percentage of students
                      at a given school who qualify for free and reduced-fee
                      lunch, which in turn is considered as a measure of
                      economic need. To be deemed eligible for free lunch, a
                      student's family income should be within 130% of the
                      poverty line (a maximum of $29,055 for a family of four).
                      For reduced-fee lunch, the family income should be within
                      185% of the poverty level (a maximum of $41,348 for a
                      family of four). Donorschoose.org receives data about
                      students’ economic needs from the National Center for
                      Education Statistics (NCES). As part of donorschoose.org’s
                      commitment to transparency, they display this information
                      on their project page. This helps their community
                      (including corporate and foundation partners) choose
                      projects that matter the most to them.
                    </p>
                    <p>
                      Projects on donorschoose.org will denote the approximate
                      percentage of students in a school who qualify for free or
                      reduced-fee lunch. You’ll see the following demarcations
                      in accordance with our NCES data:
                    </p>
                    <ul>
                      <li>
                        More than a third of students are from low-income
                        households: 30-44%
                      </li>
                      <li>
                        Half of the students are from low-income households:
                        45-50%
                      </li>
                      <li>
                        More than half of the students are from low-income
                        households: 51-75%
                      </li>
                      <li>
                        More than three-quarters of students are from low-income
                        households: 76-89%
                      </li>
                      <li>
                        Nearly all students are from low-income households: 90%+
                      </li>
                    </ul>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    What happens to the donation I make?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      When you make a donation on Markid, the proceeds apply
                      directly to the project through Donorschoose.org
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Can I make a donation to a project/school of my choice?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      We’re currently focusing on one school project at a time
                      to be able to deliver the required resources, as a part of
                      their request. You’re welcome to drop us a note at{" "}
                      <a href="support@markid.io">support@markid.io</a> with any
                      suggestions you may have.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Is my donation tax-deductible?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      Our API with <strong>DonorsChoose</strong> does not allow
                      us to pass on the tax deduction benefit to donors. If you
                      choose to donate directly to <strong>DonorsChoose</strong>
                      , you will receive a donation receipt from them. Believe
                      us when we say that we’re trying to work around this
                      issue.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    Is my information safe with Markid?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      Markid was created for parents, so we are constantly
                      working on improving features that make this a secure,
                      trusted, and wholesome community. Whether you’re buying or
                      selling, we want you to enjoy your experience and feel
                      safe on the platform. We authenticate each user at the
                      time of login, to ensure all the steps needed to validate
                      each user’s true identity are followed. Our payment
                      processing partner (Stripe) ensures that all your
                      information is encrypted and stored safely. Just make sure
                      to keep all purchases in-app since all payments are
                      secured by Stripe.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How can I be sure that I’m not buying a recalled item on
                    Markid?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      We do not have a mechanism to track recalled information
                      on the items listed on Markid. We strongly recommend
                      buyers to check for themselves online before purchasing an
                      item.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How do I report a user, an inappropriate item, or a
                    conversation?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      We want to make sure that fellow parents are comfortable
                      with their interactions and having a fulfilling experience
                      on Markid.
                    </p>
                    <p>
                      <strong>REPORTING A USER OR AN ITEM</strong> - If you come
                      across an improper username, profile description, someone
                      who has multiple accounts, or an inappropriate item posted
                      on Markid, help us out by reporting it and we will address
                      it immediately. Please take a screenshot and email us at{" "}
                      <a href="support@markid.io">support@markid.io</a>.
                    </p>
                    <p>
                      <strong>REPORTING AN INAPPROPRIATE CONVERSATION</strong> -
                      If you receive any kind of inappropriate private message
                      that doesn’t comply with our <strong>Terms of Use</strong>{" "}
                      and <strong>Community Guidelines</strong>, please report
                      it to us at{" "}
                      <a href="support@markid.io">support@markid.io</a> We will
                      address it immediately.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              className="pFCollaps"
              // defaultActiveKey={currentOpenPanelKey}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["7"]}
              expandIcon={({ isActive }) => {
                return !isActive ? (
                  <DownOutlined className="collapsIcon" />
                ) : (
                  <UpOutlined className="collapsIcon" />
                );
              }}
            >
              <Panel
                className="collapseContent"
                style={{
                  padingTop: 15,
                  paddingBottom: 19,
                  marginTop: 15,
                  marginBottom: 19,
                }}
                header={
                  <Typography className="faqheading">
                    How do I delete my account?
                  </Typography>
                }
              >
                <Row gutter={[0, 0]}>
                  <Typography className="faqdescription">
                    <p>
                      As much as we’d hate to see you leave, you can drop us an
                      email to <a href="support@markid.io">support@markid.io</a>{" "}
                      and we will assist you.
                    </p>
                  </Typography>
                </Row>
              </Panel>
            </Collapse>
          </span>
        </div>
      </div>
    </>
  );
};

export default Faq;
