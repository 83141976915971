import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Typography, Row, Col, Button, Select } from "antd";
import {
  CloseOutlined, MinusOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../App.css";
import { useForm } from "react-hook-form";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";
import { addKids } from "../Actions/userAction";

export const ChildrenForm = (props) => {
  const kidsAge = [
    { value: 0, label: "1 month" },
    { value: 1, label: "2 month" },
    { value: 2, label: "3 month" },
    { value: 3, label: "4 month" },
    { value: 4, label: "5 month" },
    { value: 5, label: "6 month" },
    { value: 6, label: "7 month" },
    { value: 7, label: "8 month" },
    { value: 8, label: "9 month" },
    { value: 9, label: "10 month" },
    { value: 10, label: "11 month" },
    { value: 12, label: "1 year" },
    { value: 24, label: "2 year" },
    { value: 36, label: "3 year" },
    { value: 48, label: "4 year" },
    { value: 60, label: "5 year" },
    { value: 72, label: "6 year" },
    { value: 84, label: "7 year" },
    { value: 96, label: "8 year" },
    { value: 108, label: "9 year" },
    { value: 120, label: "10 year" },
    { value: 132, label: "11 year" },
  ];
  const [kids, setKids] = useState([{ gender: '', ageGroup: '' }]);
  const addMoreKids = () => {

    setKids([...kids, { gender: '', ageGroup: '' }])
    // kidsForm.push(form)
  }
  // console.log({ kids })

  const { current, setCurrent } = props
  const dispatch = useDispatch();
  const { innerHeight } = window;
  const { handleSubmit } = useForm({
    //resolver: yupResolver(schema),
    mode: 'onChange',
    // defaultValues: { phone: "" },
  });

  const handleAddKids = (data) => {

    dispatch(addKids(data, successCallBackKids, failureCallBackKids))
  }
  const successCallBackKids = () => {
    setCurrent(current + 1);
  };
  const failureCallBackKids = () => { };

  const handleGenderChange = (value, index) => {
    const kidsList = [...kids]
    kidsList[index].gender = value
    setKids(kidsList)
  }
  const handleAgeChange = (value, index) => {
    const kidsList = [...kids]
    kidsList[index].ageGroup = value
    setKids(kidsList)
  }
  const removeKids = (index) => {
    const list = [...kids];
    list.splice(index, 1);
    setKids(list);
  }

  return (
    <div style={{
      overflow: 'hidden',
      // overflowY: 'scroll', 
      height: innerHeight
    }}>
      <div className="sendOtpParent">
        <Card bordered={false} >
          <form onSubmit={handleSubmit(handleAddKids)}>
            <div className='onboardingWeb'>
              <div className="closeicon-wrap">
                <CloseOutlined className='oBCloseIcon' />
              </div>
              <Row gutter={[0, 21]} justify='space-between'>
                <Col span={24}></Col>
                <Col>
                  {/* <Link to="/homepage"> */}
                  <Link to="/">
                    <img alt="logo" src={Logo} className="logoStyle weblogo" />
                  </Link>
                </Col>
                <Col>
                  <Row gutter={[0, 12]}>
                    <Col span={24}></Col>
                    <Button type='text' className='skip-btn' onClick={() => setCurrent(current + 1)}>
                      Skip
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='onboardingMob'>
              <div className="closeicon-wrap">
                <CloseOutlined className='oBCloseIcon' />
              </div>
              <Row gutter={[0, 0]} justify='space-between'>
                <Col span={24}></Col>
                <Col>
                  {/* <Link to="/homepage"> */}
                  <Link to="/">
                    <img alt="logo" src={ShippingLogo} className='markidLogoMob' />
                  </Link>
                </Col>
                <Col>
                  <Row gutter={[30, 0]}>
                    <Col>
                      <Button type='text' className='skip-btn' onClick={() => setCurrent(current + 1)}>
                        Skip
                      </Button>
                    </Col>
                    {/* <Col>
                      <Row gutter={[0, 10]}>
                        <Col span={24}></Col>
                        <CloseOutlined className='oBCloseIcon' />
                      </Row>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </div>
            <Row gutter={[0, { xs: 22.12, sm: 22.12, md: 16, lg: 16, xl: 16, xxl: 16 }]}>
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col>
                <Typography className='createAccountText'>
                  Let's personalize your experience
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, 37]}>
              <Col span={24} />
              <Col>
                <Typography className="giveus">
                  Give us your child information
                </Typography>
              </Col>
            </Row>
            {kids.map((kid, i) => {
              return (
                <Fragment key={i}>
                  <Row gutter={[15, 15]}>
                    <Col span={24}></Col>
                    <Col offset={0}>
                      <Button className={kids[i].gender === 'male' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleGenderChange('male', i)}>Boy</Button>
                    </Col>
                    <Col>
                      <Button className={kids[i].gender === 'female' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleGenderChange('female', i)}>Girl</Button>
                    </Col>
                    <Col>
                      <Button className={kids[i].gender === 'neutral' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleGenderChange('neutral', i)}>Neutral</Button>
                    </Col>
                  </Row>
                  <Row gutter={[0, 15]}>
                    <Col span={24}></Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row>
                        <Typography>Age</Typography>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24} />
                        <Col span={24}>
                          <div className="ageSelectParent">
                            <Select
                              name="ageGroup"
                              onChange={(value) => handleAgeChange(value, i)}
                              className="ageSelect"
                              style={{ width: "100%" }}
                              options={kidsAge}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {i !== 0 && <Row justify='center' gutter={[0, 15]}>
                    <Col span={24}></Col>
                    <Col>
                      <MinusOutlined onClick={() => removeKids()} />
                    </Col>
                  </Row>}
                </Fragment>
              )
            })}

            <Row justify="space-between" gutter={[0, { xs: 22, sm: 22, md: 32, lg: 32, xl: 32, xxl: 32 }]}>
              <Col span={24}></Col>
              {/* <Col span={24}></Col> */}
              <div className="buttons-wrap">
                <Col><Button className="button button-bordered add-child-btn" onClick={() => addMoreKids()}>Add More Children</Button></Col>
                <Col>
                  <Button className="button button-primary continue-btn" htmlType='submit' disabled={kids[0].ageGroup === "" || !kids[0].gender.length > 0} className={kids[0].ageGroup !== "" && kids[0].gender.length > 0 ? "oBenabledSignupButton" : "oBdisabledSignupButton"}>
                    Continue
                  </Button>
                </Col>
              </div>
              <Col span={24}></Col>
            </Row>
          </form>
        </Card>
      </div>
    </div>
  );
};