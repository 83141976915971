import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Divider,
  Collapse,
  Slider,
  Checkbox,
  Button,
  Badge,
  Input,
} from "antd";
import ColorFilter from "./ColorFilter";
import { Maternitysizefilter } from "./Maternitysizefilter";
import { Subsizefilter } from "./Subsizefilter";
import { Subagefilter } from "./Subagefilter";
import { YearOfPurchaseFilter } from "./YearOfPurchaseFilter";
import { CategoryFilter } from "./CategoryFilter";
import { ConditionFilter } from "./ConditionFilter";
import { PriceFilter } from "./PriceFilter";
import { SizeFilter } from "./SizeFilter";
import { AgeFilter } from "./AgeFilter";
import closeBtn from "../assets/new-assets/close-icon.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import { UpOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import "../App.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { createBrowserHistory } from "history";
import { getAllBrands } from "../Actions/brandsAction";
import { getAllCategories } from "../Actions/categoriesAction";
import { values } from "ramda";
import { BrandFilter } from "./BrandFilter";
import { DeliveryFilter } from "./DeliveryFilter";
import { GenderFilter } from "./GenderFilter";
import { useGaEvent } from "../hooks/useGaEvent";
import {
  getAgeFilter,
  getFiltersToDisplay,
  getSizeFilter,
} from "../Actions/filtersAction";
const { Panel } = Collapse;
const PFilteredItemMob = (props) => {
  const {
    // lots,
    id,
    allsizelist,
    buildQuery,
    itemName,
    itemType,
    total,
    setSelectedBrand,
    selectedBrand,
    allsubsizes,
    updateSelectedBrand,
    selectedGender,
    updateSelectedGender,
    selectedSize,
    updateSelectedSize,
    // updateSelectedFilter,
    selectedAge,
    updateSelectedAge,
    selectedCondition,
    updateSelectedCondition,
    selectedCategory,
    setSelectedCategory,
    updateSelectedCategory,
    selectedShipping,
    updateSelectedShipping,
    selectedPurchaseYear,
    updateSelectedPurchaseYear,
    selectedPriceRange,
    updateSelectedPriceRannge,
    updateResetAllFilter,
    brandsByCategory,
    allBrands,
    showfilterDialog,
    setshowfilterDialog,
    currentPage,
    selectedColor,
    priceValues,
    setPriceValues,
    updateSelectedColor,
    initialPriceValues,
    setdisplaydata,
    totalcount,
    displaydata,
    filtertags,
    resetclose,
    handledisplayAll,
  } = props;

  const { gaEventClick } = useGaEvent();
  const sortBrandsByCategory = _.sortBy(brandsByCategory, "brand");
  const dispatch = useDispatch();
  let {
    yearOfDetails,
    brandsToShowInUI,
    category,
    brandlist,
    alllots,
    filterLots,
    lots,
    filterToDisplay,
    sizeFilters,
    ageFilters,
    currentLocationZip,
  } = useSelector((state) => {
    let { yearOfDetails, filterLots, alllots } = state.lots;
    const { currentLocationZip } = state.user;
    let selectedBrand = [];
    const { category } = state.categories;
    const { allBrands, brandsByCategory, brandlist } = state.brands;
    const { filterToDisplay, sizeFilters, ageFilters } = state.filters;
    // const { allCategories } = state.categories
    return {
      // checkedValues,
      brandsToShowInUI: allBrands !== [] ? allBrands : brandsByCategory,
      // allCategories,
      alllots,
      filterLots,
      yearOfDetails,
      category,
      filterToDisplay,
      sizeFilters,
      ageFilters,
      currentLocationZip,
      brandlist,
    };
  });
  const checkdonColorChange = (checkedValues) => {
    updateSelectedColor(checkedValues);
  };
  const location = useLocation();
  const history = useHistory();

  const [sliderMinVal, setSliderMinVal] = useState(
    selectedPriceRange?.min ? selectedPriceRange?.min : 0
  );
  const [sliderMaxVal, setSliderMaxVal] = useState(
    selectedPriceRange?.max ? selectedPriceRange?.max : 0
  );

  useEffect(() => {
    if (filterToDisplay) {
      if (filterToDisplay.includes("Age")) {
        dispatch(getAgeFilter(itemName));
      }
      if (filterToDisplay.includes("Size")) {
        dispatch(getSizeFilter(itemName));
      }
    }
  }, [filterToDisplay]);

  const [Brands, setBrands] = useState([]);
  const [BrandsList, setBrandsList] = useState([]);

  let brandArray = [];

  const [Sizes, setSizes] = useState([]);
  const [SizesList, setSizesList] = useState([]);
  let sizeArray = [];
  let tempSizeArray = [];

  const [Ages, setAges] = useState([]);
  const [AgeList, setAgeList] = useState([]);
  let ageArray = [];
  let tempAgeArray = [];

  let currentSearchElement = useLocation().search.split("&");
  let currentPathElement = useLocation().pathname.split("/");

  const [brandsvalue, setbrandsvalue] = useState([]);
  const [gendervalue, setgendervalue] = useState([]);
  const [purchaseYearvalue, setpurchaseYearvalue] = useState([]);
  const [conditionvalue, setconditionvalue] = useState([]);
  const [shippingvalue, setshippingvalue] = useState([]);
  const [sizevalue, setsizevalue] = useState([]);
  const handleCancel = () => {
    if (filtertags.length <= 0 && resetclose === true) {
      setdisplaydata(true);
    } else {
      setdisplaydata(false);
    }

    setshowfilterDialog(false);
  };

  // useEffect(()=>{
  //   if( currentPathElement[1]=="search-new" && currentSearchElement.length < 3){
  //     localStorage.removeItem("Brands")
  //     localStorage.removeItem("Sizes")
  //     localStorage.removeItem("Selected-Brands")
  //     localStorage.removeItem("Selected-Genders")
  //     localStorage.removeItem("Selected-YearofPurchase")
  //     localStorage.removeItem("Selected-Condition")
  //     localStorage.removeItem("Selected-Shipping")
  //     localStorage.removeItem("Selected-Size")
  //     localStorage.removeItem("Selected-Price-Range")
  //     selectedBrand.length = 0
  //     selectedGender.length = 0
  //     selectedSize.length = 0
  //     selectedCondition.length = 0
  //     selectedPurchaseYear.length = 0
  //     selectedPriceRange.length = 0
  //   }
  // },[currentSearchElement])

  useEffect(() => {
    if (filterLots.lots) {
      filterLots.lots.map((productList) => {
        if (
          brandArray.indexOf(productList.brand) === -1 &&
          productList.brand != undefined &&
          productList.brand != ""
        ) {
          brandArray.push(productList.brand);
        }
      });
      setBrandsList(brandArray);
    }
  }, []);

  useEffect(() => {
    if (BrandsList.length) {
      BrandsList.map((brandVal) => {
        brandArray.push({ brand: brandVal, value: brandVal });
      });
      setBrands(brandArray);
    }
  }, [BrandsList]);

  // useEffect(() => {
  //   if (filterLots.lots) {
  //     filterLots.lots.map((productList) => {
  //       if (
  //         brandArray.indexOf(productList.brand) === -1 &&
  //         productList.brand != undefined &&
  //         productList.brand != ""
  //       ) {
  //         brandArray.push(productList.brand);
  //       }
  //     });
  //   }
  //   if(localStorage.getItem("Brands") != null && brandArray.length != JSON.parse(localStorage.getItem("Brands")).length ){
  //     setBrandsList(JSON.parse(localStorage.getItem("Brands")))
  //   }
  //   else{
  //     setBrandsList(brandArray);
  //     localStorage.setItem("Brands", JSON.stringify(brandArray))
  //   }
  // }, [filterLots.lots]);

  useEffect(() => {
    if (BrandsList.length) {
      BrandsList.map((brandVal) => {
        brandArray.push({ brand: brandVal, value: brandVal });
      });
      setBrands(brandArray);
    }
  }, [BrandsList]);

  // useEffect(()=>{
  //   if(filterLots.lots){
  //     if(filterLots.lots[0].category == category.category){
  //       filterLots.lots.map((productList)=>{
  //         if(sizeArray.indexOf(productList.size) === -1 && productList.size != undefined && productList.size != ''){
  //           sizeArray.push(productList.size)
  //         }
  //       })
  //     }
  //     setSizesList(sizeArray)
  //   }
  // },[category.category])

  useEffect(() => {
    if (filterLots.lots) {
      filterLots.lots.map((productList) => {
        if (
          sizeArray.indexOf(productList.size) === -1 &&
          productList.size != undefined &&
          productList.size != ""
        ) {
          sizeArray.push(productList.size);
        }
      });
    }
    if (localStorage.getItem("Sizes") != null) {
      if (JSON.parse(localStorage.getItem("Sizes")).length) {
        setSizesList(JSON.parse(localStorage.getItem("Sizes")));
      } else {
        setSizesList(sizeArray);
        localStorage.setItem("Sizes", JSON.stringify(sizeArray));
      }
    } else {
      setSizesList(sizeArray);
      localStorage.setItem("Sizes", JSON.stringify(sizeArray));
    }
  }, [filterLots.lots]);

  // useEffect(() => {
  //   if(SizesList){
  //     if (SizesList.length) {
  //       SizesList.map((sizeVal) => {
  //         sizeArray.push({ size: sizeVal, value: sizeVal });
  //       });
  //     }
  //   }
  //   setSizes(sizeArray);
  // }, [SizesList]);

  useEffect(() => {
    if (sizeFilters) {
      if (sizeFilters.length) {
        sizeFilters.map((sizeVal) => {
          if (tempSizeArray.indexOf(sizeVal.size) === -1) {
            tempSizeArray.push(sizeVal.size);
          }
        });
        tempSizeArray.map((sizeVal) => {
          sizeArray.push({ size: sizeVal, value: sizeVal });
        });
      }
    }
    setSizes(sizeArray);
  }, [sizeFilters]);

  useEffect(() => {
    if (ageFilters) {
      if (ageFilters.length) {
        ageFilters.map((ageVal) => {
          if (tempAgeArray.indexOf(ageVal.age) === -1) {
            tempAgeArray.push(ageVal.age);
          }
        });
        tempAgeArray.map((ageVal) => {
          ageArray.push({ age: ageVal, value: ageVal });
        });
      }
    }
    setAges(ageArray);
  }, [ageFilters]);

  // let selectedBrandFilter = JSON.parse(localStorage.getItem("Selected-Brands"))
  // let selectedGenderFilter = JSON.parse(localStorage.getItem("Selected-Genders"))
  // let selectedPurchaseYearFilter = JSON.parse(localStorage.getItem("Selected-YearofPurchase"))
  // let selectedConditionFilter = JSON.parse(localStorage.getItem("Selected-Condition"))
  // let selectedShippingFilter = JSON.parse(localStorage.getItem("Selected-Shipping"))
  // let selectedSizeFilter = JSON.parse(localStorage.getItem("Selected-Size"))
  // let selectedPriceRangeFilter = JSON.parse(localStorage.getItem("Selected-Price-Range"))

  // useEffect(()=>{
  //   if(selectedBrandFilter && selectedBrandFilter.length > 0){
  //     selectedBrandFilter.map((selectedBrandFilters) => {
  //       if(selectedBrandFilters != ''){
  //         updateSelectedBrand(selectedBrandFilters)
  //         brandsvalue.push(selectedBrandFilters);
  //       }
  //     })
  //   }
  //   if(selectedGenderFilter && selectedGenderFilter.length > 0){
  //     selectedGenderFilter.map((selectedGenderFilters) => {
  //       if(selectedGenderFilters != ''){
  //         updateSelectedGender(selectedGenderFilters)
  //         gendervalue.push(selectedGenderFilters);
  //       }
  //     })
  //   }
  //   if(selectedPurchaseYearFilter && selectedPurchaseYearFilter.length > 0){
  //     selectedPurchaseYearFilter.map((selectedPurchaseYearFilters) => {
  //       if(selectedPurchaseYearFilters != ''){
  //         updateSelectedPurchaseYear(selectedPurchaseYearFilters)
  //         selectedPurchaseYearFilter.push(selectedPurchaseYearFilters);
  //       }
  //     })
  //   }
  //   if(selectedConditionFilter && selectedConditionFilter.length > 0){
  //     selectedConditionFilter.map((selectedConditionFilters) => {
  //       if(selectedConditionFilters != ''){
  //         updateSelectedCondition(selectedConditionFilters)
  //         conditionvalue.push(selectedConditionFilters);
  //       }
  //     })
  //   }
  //   if(selectedShippingFilter && selectedShippingFilter.length > 0){
  //     selectedShippingFilter.map((selectedShippingFilters) => {
  //       if(selectedShippingFilters != ''){
  //         updateSelectedShipping(selectedShippingFilters)
  //         shippingvalue.push(selectedShippingFilters);
  //       }
  //     })
  //   }
  //   if(selectedSizeFilter && selectedSizeFilter.length > 0){
  //     selectedSizeFilter.map((selectedSizeFilters) => {
  //       if(selectedSizeFilters != ''){
  //         updateSelectedSize(selectedSizeFilters)
  //         sizevalue.push(selectedSizeFilters);
  //       }
  //     })
  //   }
  // },[])

  const { lot } = yearOfDetails || {};
  let { uniqueValues, minPrice, maxPrice } =
    lot && lot.length > 0 ? lot[0] : {};
  // const [checkedValues, setCheckedValues] = useState('')

  if (uniqueValues) uniqueValues = uniqueValues.filter((el) => el !== null);

  /* 20211124 Varun: added filter to uniqueValues array to remove null values returned from api */
  // const categories = (_.uniqBy(lots, 'category')).filter(e =>  e.category)
  // const brands = allBrands;
  // const brands = (_.uniqBy(lots, 'brand')).filter(e =>  e.brand)

  // const minimumPrice = _.minBy(lots, 'price');
  // const {price: minimumPriceVal} = minimumPrice || {}
  // const maximumPrice = _.maxBy(lots, 'price');
  // const {price: maximumPriceVal} = maximumPrice || {}
  const purchaseYear = uniqueValues && uniqueValues.sort();

  const checkdonCategoryChange = (clickedCategory) => {
    updateSelectedCategory(clickedCategory);
  };

  const addQuery = (key, value) => {
    let pathname = location.pathname;
    let searchParams = new URLSearchParams(location.search);
    let selectedBrandCheck = brandsvalue.indexOf(value);
    let selectedGenderCheck = gendervalue.indexOf(value);
    let selectedPurchaseYearvalueCheck = purchaseYearvalue.indexOf(value);
    let selectedConditionCheck = conditionvalue.indexOf(value);
    let selectedShippingCheck = shippingvalue.indexOf(value);
    let selectedSizeCheck = sizevalue.indexOf(value);

    if (key === "brand") {
      if (selectedBrandCheck === -1) {
        brandsvalue.push(value);
      } else {
        if (selectedBrandCheck > -1) {
          brandsvalue.splice(selectedBrandCheck, 1);
        }
      }
      setbrandsvalue(brandsvalue);
      searchParams.set(key, brandsvalue);
      // localStorage.setItem("Selected-Brands", JSON.stringify(brandsvalue))
    } else if (key == "gender") {
      if (selectedGenderCheck === -1) {
        gendervalue.push(value);
      } else {
        if (selectedGenderCheck > -1) {
          gendervalue.splice(selectedGenderCheck, 1);
        }
      }
      setgendervalue(gendervalue);
      searchParams.set(key, gendervalue);
      // localStorage.setItem("Selected-Genders", JSON.stringify(gendervalue))
    } else if (key == "purchaseYear") {
      if (selectedPurchaseYearvalueCheck === -1) {
        purchaseYearvalue.push(value);
      } else {
        if (selectedPurchaseYearvalueCheck > -1) {
          purchaseYearvalue.splice(selectedPurchaseYearvalueCheck, 1);
        }
      }
      setpurchaseYearvalue(purchaseYearvalue);
      searchParams.set(key, purchaseYearvalue);
      // localStorage.setItem("Selected-YearofPurchase", JSON.stringify(purchaseYearvalue))
    } else if (key == "condition") {
      if (selectedConditionCheck === -1) {
        conditionvalue.push(value);
      } else {
        if (selectedConditionCheck > -1) {
          conditionvalue.splice(selectedConditionCheck, 1);
        }
      }
      setconditionvalue(conditionvalue);
      searchParams.set(key, conditionvalue);
      // localStorage.setItem("Selected-Condition", JSON.stringify(conditionvalue))
    } else if (key == "shipping") {
      if (selectedShippingCheck === -1) {
        shippingvalue.push(value);
      } else {
        if (selectedShippingCheck > -1) {
          shippingvalue.splice(selectedShippingCheck, 1);
        }
      }
      setshippingvalue(shippingvalue);
      searchParams.set(key, shippingvalue);
      // localStorage.setItem("Selected-Shipping", JSON.stringify(shippingvalue))
    } else if (key == "size") {
      if (selectedSizeCheck === -1) {
        sizevalue.push(value);
      } else {
        if (selectedSizeCheck > -1) {
          sizevalue.splice(selectedSizeCheck, 1);
        }
      }
      setsizevalue(sizevalue);
      searchParams.set(key, sizevalue);
      // localStorage.setItem("Selected-Size", JSON.stringify(sizevalue))
    }
    // history.push({
    //   pathname: pathname,
    //   search: searchParams.toString(),
    // });
  };

  // const shipping = [{shipping:'Free Shipping', value: false},{shipping:'Shipping', value: false}, {shipping:'Meetup', value: false}]
  // const sliderMin = minPrice > 0 ? minPrice / 100 : 0;
  // const sliderMax = maxPrice > 0 ? maxPrice / 100 : 100;

  const sliderMin = 0;
  const sliderMax = 2500;

  const priceFilter = {
    [sliderMin]: (
      <Typography.Text
        style={{ width: "auto" }}
        ellipsis={{ tooltip: sliderMin }}
        className="pFSliderPriceVal minimum"
      >
        ${sliderMin}
      </Typography.Text>
    ),
    [sliderMax]: (
      <Typography.Text
        style={{ width: "auto" }}
        ellipsis={{ tooltip: sliderMax }}
        className="pFSliderPriceVal maximum"
      >
        ${sliderMax}
      </Typography.Text>
    ),
    // [sliderMin]: <Typography className='pFSliderPriceVal'>${sliderMin}</Typography>,
    // [sliderMax]: <Typography className='pFSliderPriceVal'>${sliderMax}</Typography>,
  };
  // let currentOpenPanelKey = '';
  const [currentOpenPanelKey, setCurrentOpenPanelKey] = useState("");
  useEffect(() => {
    if (
      currentPathElement[1] == "search-new" &&
      currentSearchElement.length < 3
    ) {
      localStorage.removeItem("Active-Filter");
    }
  }, []);
  function dropdownCallback(key) {
    // if (key.length === 0) {
    //   setCurrentOpenPanelKey("");
    //   localStorage.removeItem("Active-Filter");
    // } else if (key.length === 1) {
    //   setCurrentOpenPanelKey(key[0]);
    //   localStorage.setItem("Active-Filter", key[0]);
    // } else if (key.length === 2) {
    //   setCurrentOpenPanelKey(key[1]);
    //   localStorage.setItem("Active-Filter", key[1]);
    // } else {
    //   setCurrentOpenPanelKey(key[key.length - 1]);
    //   localStorage.setItem("Active-Filter", key[key.length - 1]);
    // }
  }

  useEffect(() => {
    // if (sortBrandsByCategory && sortBrandsByCategory.length > 0) {
    //   brandsToShowInUI = sortBrandsByCategory;
    // } else {
    //   dispatch(getAllBrands());
    // }
    // dispatch(getAllCategories());
    if (brandsByCategory === []) {
      dispatch(getAllBrands());
    }
  }, []);
  const onChangedPriceRange = (range) => {
    setSliderMinVal(range[0]);
    setSliderMaxVal(range[1]);
    updateSelectedPriceRannge(range);
    localStorage.setItem("Selected-Price-Range", range[1]);
  };
  const checkdonBrandChange = (checkedValues) => {
    updateSelectedBrand(checkedValues);
  };
  const checkdonGenderChange = (checkedValues) => {
    updateSelectedGender(checkedValues);
  };
  const checkdonSizeChange = (checkedValues) => {
    updateSelectedSize(checkedValues);
  };
  const checkdonPurchaseYearChange = (checkedValues) => {
    updateSelectedPurchaseYear(checkedValues);
  };
  const checkdonAgeChange = (checkedValues) => {
    updateSelectedAge(checkedValues);
  };
  // const checkdonAgeChange = (checkedValues) => {
  //   const isExists = _.includes(selectedAge, checkedValues)
  //   const sAge = selectedAge
  //   if(isExists){
  //     const removeIndex = selectedAge.findIndex( item => item === checkedValues );
  //     sAge.splice( removeIndex, 1 );
  //   }
  //   else {
  //     sAge.push(checkedValues)
  //   }
  //   updateSelectedAge(sAge)
  // }
  const checkdonConditionChange = (checkedValues) => {
    updateSelectedCondition(checkedValues);
  };

  const checkdonShippingChange = (checkedValues) => {
    const findIndex = selectedShipping.findIndex(
      (item) => item.shipping === checkedValues.shipping
    );
    const matchObject = selectedShipping[findIndex];
    matchObject.value =
      matchObject.value === checkedValues.value
        ? !matchObject.value
        : matchObject.value;
    updateSelectedShipping(matchObject, findIndex);
  };
  //   const checkdonSoldUnSoldChange = (checkedValues) => {
  //   updateSelectedShipping(checkedValues)
  // }
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  let zipCode = currentLocationZip || "10100";
  const handleResetAll = () => {
    setdisplaydata(true);
    //onChangedPriceRange([0, 0]);
    setSliderMinVal(0);
    setSliderMaxVal(0);
    setPriceValues([
      { title: "$0 to $10", value: false },
      { title: "$10 to $50", value: false },
      { title: "$50 to $100", value: false },
    ]);
    updateResetAllFilter();
    // localStorage.removeItem("Selected-Brands");
    // localStorage.removeItem("Selected-Genders");
    // localStorage.removeItem("Selected-YearofPurchase");
    // localStorage.removeItem("Selected-Condition");
    // localStorage.removeItem("Selected-Shipping");
    // localStorage.removeItem("Selected-Size");
    // localStorage.removeItem("Selected-Price-Range");
    // if (currentPathElement[1] === "search-new") {
    //   history.replace({
    //     search: `refresh=true&zipCode=${zipCode}`,
    //   });
    // } else {
    //   history.replace({
    //     search: "",
    //   });
    // }
  };

  const years = [
    { year: "2012" },
    { year: "2013" },
    { year: "2014" },
    { year: "2015" },
    { year: "2016" },
    { year: "2017" },
    { year: "2018" },
    { year: "2019" },
    { year: "2020" },
    { year: "2021" },
    { year: "2022" },
  ];
  const [query, setQuery] = useState("");
  const sBrand = localStorage.getItem("sBrand");
  return (
    <div>
      <BottomSheet open={showfilterDialog}>
        <Card className="pFilterItemMain">
          <Row justify="space-between" className="pFilterHeader">
            <Col onClick={handleCancel}>
              <Col>
                <Button type="text" className="resetAllButton style2">
                  Cancel
                </Button>
              </Col>
            </Col>
            <Col>
              <Typography className="pFilterTitle">Filter</Typography>
            </Col>

            <Col>
              <Button
                type="text"
                className="resetAllButton style2"
                onClick={() => {
                  handleResetAll();
                  gaEventClick("Filter by", "Clear all");
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Row justify="center" className="justifyresults">
            <Col style={{ margin: "0px 12px" }}>
              <Typography className="applyresults">{total}</Typography>
            </Col>
            <Col>
              <Typography className="applyresults">Results</Typography>
            </Col>
          </Row>
          <Divider
            style={{
              borderTop: "1px solid #D4D4D4",
              marginTop: 12,
              marginBottom: 1,
            }}
          />
          <div className="pFilterItemCard">
            <Card>
              <Row className="removeColumnPadding">
                <Col span={24}>
                  {currentPage[1] === "search-new" && (
                    <>
                      <CategoryFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCategory={selectedCategory}
                        checkdonCategoryChange={checkdonCategoryChange}
                      />
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      {(filtertags?.includes("clothing_and_accessories") ||
                        filtertags?.includes("shoes")) && (
                        <Subsizefilter
                          itemType={itemType}
                          selectedCategory={selectedCategory}
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {!filtertags?.includes("clothing_and_accessories") &&
                        !filtertags?.includes("shoes") &&
                        !filtertags?.includes("maternity") && (
                          <SizeFilter
                            itemType={itemType}
                            selectedCategory={selectedCategory}
                            filtertags={filtertags}
                            allsubsizes={allsubsizes}
                            totalcount={totalcount}
                            currentPage={currentPage}
                            dropdownCallback={dropdownCallback}
                            checkdonSizeChange={checkdonSizeChange}
                            Sizes={Sizes}
                            filterToDisplay={filterToDisplay}
                            selectedSize={selectedSize}
                          />
                        )}
                      {filtertags?.includes("maternity") && (
                        <Maternitysizefilter
                          allsizelist={allsizelist}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filtertags={filtertags}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {(filtertags?.includes("toys_and_costumes") ||
                        filtertags?.includes("books")) && (
                        <Subagefilter
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonAgeChange={checkdonAgeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedAge={selectedAge}
                        />
                      )}
                      <GenderFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedGender={selectedGender}
                        dropdownCallback={dropdownCallback}
                        checkdonGenderChange={checkdonGenderChange}
                      />{" "}
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />{" "}
                      <YearOfPurchaseFilter
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />{" "}
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}
                  {currentPage[2] === "winterwear" && (
                    <>
                      <CategoryFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCategory={selectedCategory}
                        checkdonCategoryChange={checkdonCategoryChange}
                      />
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      {(filtertags?.includes("clothing_and_accessories") ||
                        filtertags?.includes("shoes")) && (
                        <Subsizefilter
                          itemType={itemType}
                          selectedCategory={selectedCategory}
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {!filtertags?.includes("clothing_and_accessories") &&
                        !filtertags?.includes("shoes") &&
                        !filtertags?.includes("maternity") && (
                          <SizeFilter
                            itemType={itemType}
                            selectedCategory={selectedCategory}
                            filtertags={filtertags}
                            allsubsizes={allsubsizes}
                            totalcount={totalcount}
                            currentPage={currentPage}
                            dropdownCallback={dropdownCallback}
                            checkdonSizeChange={checkdonSizeChange}
                            Sizes={Sizes}
                            filterToDisplay={filterToDisplay}
                            selectedSize={selectedSize}
                          />
                        )}
                      {filtertags?.includes("maternity") && (
                        <Maternitysizefilter
                          allsizelist={allsizelist}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filtertags={filtertags}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {(filtertags?.includes("toys_and_costumes") ||
                        filtertags?.includes("books")) && (
                        <Subagefilter
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonAgeChange={checkdonAgeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedAge={selectedAge}
                        />
                      )}
                      <GenderFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedGender={selectedGender}
                        dropdownCallback={dropdownCallback}
                        checkdonGenderChange={checkdonGenderChange}
                      />{" "}
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />{" "}
                      <YearOfPurchaseFilter
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />{" "}
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}
                  {currentPage[2] === "gearandstrollers" && (
                    <>
                      <CategoryFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCategory={selectedCategory}
                        checkdonCategoryChange={checkdonCategoryChange}
                      />
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      {(filtertags?.includes("clothing_and_accessories") ||
                        filtertags?.includes("shoes")) && (
                        <Subsizefilter
                          itemType={itemType}
                          selectedCategory={selectedCategory}
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {!filtertags?.includes("clothing_and_accessories") &&
                        !filtertags?.includes("shoes") &&
                        !filtertags?.includes("maternity") && (
                          <SizeFilter
                            itemType={itemType}
                            selectedCategory={selectedCategory}
                            filtertags={filtertags}
                            allsubsizes={allsubsizes}
                            totalcount={totalcount}
                            currentPage={currentPage}
                            dropdownCallback={dropdownCallback}
                            checkdonSizeChange={checkdonSizeChange}
                            Sizes={Sizes}
                            filterToDisplay={filterToDisplay}
                            selectedSize={selectedSize}
                          />
                        )}
                      {filtertags?.includes("maternity") && (
                        <Maternitysizefilter
                          allsizelist={allsizelist}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filtertags={filtertags}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {(filtertags?.includes("toys_and_costumes") ||
                        filtertags?.includes("books")) && (
                        <Subagefilter
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonAgeChange={checkdonAgeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedAge={selectedAge}
                        />
                      )}
                      <GenderFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedGender={selectedGender}
                        dropdownCallback={dropdownCallback}
                        checkdonGenderChange={checkdonGenderChange}
                      />{" "}
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />{" "}
                      <YearOfPurchaseFilter
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />{" "}
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}
                  {currentPage[1] === "verifiedshops" && (
                    <>
                      <CategoryFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCategory={selectedCategory}
                        checkdonCategoryChange={checkdonCategoryChange}
                      />
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      {/* {(filtertags?.includes("clothing_and_accessories") ||
                      filtertags?.includes("shoes")) && ( */}
                      <Subsizefilter
                        selectedCategory={selectedCategory}
                        itemType={itemType}
                        allsizelist={allsizelist}
                        filtertags={filtertags}
                        allsubsizes={allsubsizes}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        dropdownCallback={dropdownCallback}
                        checkdonSizeChange={checkdonSizeChange}
                        Sizes={Sizes}
                        filterToDisplay={filterToDisplay}
                        selectedSize={selectedSize}
                      />
                      {/* )} */}
                      {/* {(!filtertags?.includes("clothing_and_accessories")&& !filtertags?.includes("shoes") && !filtertags?.includes("maternity") )
                      && (
                      <SizeFilter
                        filtertags={filtertags}
                        allsubsizes={allsubsizes}
                        allsizelist={allsizelist}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        dropdownCallback={dropdownCallback}
                        checkdonSizeChange={checkdonSizeChange}
                        Sizes={Sizes}
                        filterToDisplay={filterToDisplay}
                        selectedSize={selectedSize}
                      />
                    )} */}
                      {filtertags?.includes("maternity") && (
                        <Maternitysizefilter
                          allsizelist={allsizelist}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filtertags={filtertags}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {(filtertags?.includes("toys_and_costumes") ||
                        filtertags?.includes("books")) && (
                        <Subagefilter
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonAgeChange={checkdonAgeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedAge={selectedAge}
                        />
                      )}
                      <GenderFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedGender={selectedGender}
                        dropdownCallback={dropdownCallback}
                        checkdonGenderChange={checkdonGenderChange}
                      />{" "}
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />{" "}
                      <YearOfPurchaseFilter
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />{" "}
                      <DeliveryFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedShipping={selectedShipping}
                        updateSelectedShipping={updateSelectedShipping}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}
                  {currentPage[1] === "search-brand" && (
                    <>
                      <CategoryFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCategory={selectedCategory}
                        checkdonCategoryChange={checkdonCategoryChange}
                      />
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      {(filtertags?.includes("clothing_and_accessories") ||
                        filtertags?.includes("shoes")) && (
                        <Subsizefilter
                          itemType={itemType}
                          selectedCategory={selectedCategory}
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {!filtertags?.includes("clothing_and_accessories") &&
                        !filtertags?.includes("shoes") &&
                        !filtertags?.includes("maternity") && (
                          <SizeFilter
                            itemType={itemType}
                            selectedCategory={selectedCategory}
                            filtertags={filtertags}
                            allsubsizes={allsubsizes}
                            totalcount={totalcount}
                            currentPage={currentPage}
                            dropdownCallback={dropdownCallback}
                            checkdonSizeChange={checkdonSizeChange}
                            Sizes={Sizes}
                            filterToDisplay={filterToDisplay}
                            selectedSize={selectedSize}
                          />
                        )}
                      {filtertags?.includes("maternity") && (
                        <Maternitysizefilter
                          allsizelist={allsizelist}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonSizeChange={checkdonSizeChange}
                          Sizes={Sizes}
                          filtertags={filtertags}
                          filterToDisplay={filterToDisplay}
                          selectedSize={selectedSize}
                        />
                      )}
                      {(filtertags?.includes("toys_and_costumes") ||
                        filtertags?.includes("books")) && (
                        <Subagefilter
                          allsizelist={allsizelist}
                          filtertags={filtertags}
                          allsubsizes={allsubsizes}
                          totalcount={totalcount}
                          currentPage={currentPage}
                          dropdownCallback={dropdownCallback}
                          checkdonAgeChange={checkdonAgeChange}
                          Sizes={Sizes}
                          filterToDisplay={filterToDisplay}
                          selectedAge={selectedAge}
                        />
                      )}
                      <GenderFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedGender={selectedGender}
                        dropdownCallback={dropdownCallback}
                        checkdonGenderChange={checkdonGenderChange}
                      />{" "}
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />{" "}
                      <YearOfPurchaseFilter
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />{" "}
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}
                  {currentPage[3] === "cribs_and_furniture" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "strollers" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <YearOfPurchaseFilter
                        totalcount={totalcount}
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "gear" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <YearOfPurchaseFilter
                        totalcount={totalcount}
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                    </>
                  )}

                  {currentPage[3] === "clothing_and_accessories" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <Subsizefilter
                        itemType={itemType}
                        selectedCategory={selectedCategory}
                        allsizelist={allsizelist}
                        filtertags={filtertags}
                        allsubsizes={allsubsizes}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        dropdownCallback={dropdownCallback}
                        checkdonSizeChange={checkdonSizeChange}
                        Sizes={Sizes}
                        filterToDisplay={filterToDisplay}
                        selectedSize={selectedSize}
                      />
                      <GenderFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedGender={selectedGender}
                        dropdownCallback={dropdownCallback}
                        checkdonGenderChange={checkdonGenderChange}
                      />
                      <BrandFilter
                        allBrands={allBrands}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      <AgeFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedAge={selectedAge}
                        selectedSize={selectedSize}
                        dropdownCallback={dropdownCallback}
                        checkdonAgeChange={checkdonAgeChange}
                        Ages={Ages}
                        filterToDisplay={filterToDisplay}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                    </>
                  )}
                  {currentPage[3] === "shoes" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                      <AgeFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedAge={selectedAge}
                        selectedSize={selectedSize}
                        dropdownCallback={dropdownCallback}
                        checkdonAgeChange={checkdonAgeChange}
                        Ages={Ages}
                        filterToDisplay={filterToDisplay}
                      />
                      <Subsizefilter
                        itemType={itemType}
                        selectedCategory={selectedCategory}
                        allsizelist={allsizelist}
                        allsubsizes={allsubsizes}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        dropdownCallback={dropdownCallback}
                        checkdonSizeChange={checkdonSizeChange}
                        Sizes={Sizes}
                        filtertags={filtertags}
                        filterToDisplay={filterToDisplay}
                        selectedSize={selectedSize}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />

                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "toys_and_costumes" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      <Subagefilter
                        allsizelist={allsizelist}
                        filtertags={filtertags}
                        allsubsizes={allsubsizes}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        dropdownCallback={dropdownCallback}
                        checkdonAgeChange={checkdonAgeChange}
                        Sizes={Sizes}
                        filterToDisplay={filterToDisplay}
                        selectedAge={selectedAge}
                      />

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />

                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "feeding" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}

                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />

                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                      />

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <YearOfPurchaseFilter
                        totalcount={totalcount}
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                    </>
                  )}

                  {currentPage[3] === "diapering" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}

                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />

                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "bathing" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}

                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "sports_bicycles" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}

                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />
                      <AgeFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedAge={selectedAge}
                        selectedSize={selectedSize}
                        dropdownCallback={dropdownCallback}
                        checkdonAgeChange={checkdonAgeChange}
                        Ages={Ages}
                        filterToDisplay={filterToDisplay}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "books" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <Subagefilter
                        allsizelist={allsizelist}
                        filtertags={filtertags}
                        allsubsizes={allsubsizes}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        dropdownCallback={dropdownCallback}
                        checkdonAgeChange={checkdonAgeChange}
                        Sizes={Sizes}
                        filterToDisplay={filterToDisplay}
                        selectedAge={selectedAge}
                      />
                    </>
                  )}

                  {currentPage[3] === "safety_equipment" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}

                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />

                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <YearOfPurchaseFilter
                        totalcount={totalcount}
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "maternity" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}
                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />

                      <Maternitysizefilter
                        allsubsizes={allsubsizes}
                        allsizelist={allsizelist}
                        totalcount={totalcount}
                        currentPage={currentPage}
                        dropdownCallback={dropdownCallback}
                        checkdonSizeChange={checkdonSizeChange}
                        Sizes={Sizes}
                        filtertags={filtertags}
                        filterToDisplay={filterToDisplay}
                        selectedSize={selectedSize}
                      />
                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />
                      <ColorFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedColor={selectedColor}
                        dropdownCallback={dropdownCallback}
                        checkdonColorChange={checkdonColorChange}
                      />
                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                    </>
                  )}

                  {currentPage[3] === "electronics" && (
                    <>
                      {/* <DeliveryFilter
                      totalcount={totalcount}
                      currentPage={currentPage}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                    /> */}

                      <BrandFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        dropdownCallback={dropdownCallback}
                        selectedBrand={selectedBrand}
                        itemType={itemType}
                        checkdonBrandChange={checkdonBrandChange}
                      />

                      <PriceFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedPriceRange={selectedPriceRange}
                        dropdownCallback={dropdownCallback}
                        sliderMin={sliderMin}
                        sliderMax={sliderMax}
                        priceFilter={priceFilter}
                        sliderMaxVal={sliderMaxVal}
                        sliderMinVal={sliderMinVal}
                        setSliderMinVal={setSliderMinVal}
                        setSliderMaxVal={setSliderMaxVal}
                        onChangedPriceRange={onChangedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                      />

                      <ConditionFilter
                        totalcount={totalcount}
                        currentPage={currentPage}
                        selectedCondition={selectedCondition}
                        dropdownCallback={dropdownCallback}
                        checkdonConditionChange={checkdonConditionChange}
                      />
                      <YearOfPurchaseFilter
                        selectedPurchaseYear={selectedPurchaseYear}
                        dropdownCallback={dropdownCallback}
                        checkdonPurchaseYearChange={checkdonPurchaseYearChange}
                        years={years}
                        currentPage={currentPage}
                      />
                    </>
                  )}
                  <Button
                    style={{ marginTop: "20px" }}
                    type="text"
                    className={"button button-primary  pLapplybutton"}
                    onClick={handledisplayAll}
                  >
                    Apply
                  </Button>
                  {/* Color Filter  */}
                </Col>
              </Row>
            </Card>
          </div>
        </Card>
      </BottomSheet>
    </div>
  );
};
export default PFilteredItemMob;
