/*eslint-disable*/
import React, {useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useHistory, Switch } from "react-router-dom";
import { Row, Col, Typography, Button, Spin, Empty, Pagination } from "antd";
import { PCategoryCard } from "../components/PCategoryCard";
import { ProductCard } from "../components/ProductCard";
import { RelatedItem } from "../components/RelatedItem";
import { LoadingOutlined } from "@ant-design/icons";
import Magnifying from "../assets/Magnifying.svg";
import { getUser } from "../Actions/userAction";
import PL1 from "../assets/PL1.svg";
import PL2 from "../assets/PL2.svg";
import PL3 from "../assets/PL3.svg";
import PL4 from "../assets/PL4.svg";
import Carousel from 'react-elastic-carousel';
import { useBreakpoints } from './../react-responsive-hooks/index';
import { getHandPickedItem } from "../Actions/handPickedAction";
import { Helmet } from "react-helmet";

const HandPicked = () => {
  const { isXS } = useBreakpoints();
  const PlSimilarCardData = [
    {
      pLImage: <img alt="" src={PL1} className='relatedImg' />,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL2} className='relatedImg' />,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL3} className='relatedImg' />,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL4} className='relatedImg' />,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL2} className='relatedImg' />,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL3} className='relatedImg' />,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL1} className='relatedImg' />,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL4} className='relatedImg' />,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL3} className='relatedImg' />,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL1} className='relatedImg' />,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL4} className='relatedImg' />,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: <img alt="" src={PL2} className='relatedImg' />,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
  ]
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const params = useParams()
  const location = useLocation();
  const paramsToken = new URLSearchParams(location.search)
  const history = useHistory();
  const token = paramsToken.get("token")
  const { providerToken, isGetHandPickedItemLoading, handPickedItem } = useSelector((state) => {
    const { providerToken } = state.auth;
    const { isGetHandPickedItemLoading, handPickedItem } = state.handPicked
    return {
      providerToken,
      isGetHandPickedItemLoading,
      handPickedItem
    };
  });
  const { lots } = handPickedItem || {}
  if (token) {
    localStorage.setItem("access_token", token);
    if (!providerToken) {
      dispatch({ type: "SET_PROVIDER_TOKEN", payload: { token } })
    }
  }
  useEffect(() => {
    dispatch(getUser());
  }, [token]);
  useEffect(() => {
    window.scroll(0, 0)
    if (id) {
      dispatch(getHandPickedItem(itemType === "handpicked" && id));
    }
  }, []);
  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 4 }
  ];

const perPage = 16;
const [pageValue, setPageValue] = useState({
	min : 0,
	max: 16
})
const handlePagination = (val) => {
	setPageValue({
		min : (val - 1) * perPage,
		max : val * perPage
	});
}

  const { itemType, itemName, id, search } = params;
  return (
    <div>
      <Helmet>
        <title>Markid - Handpicked</title>
      </Helmet>
      {!search &&
        <PCategoryCard
          itemType={itemType}
          resultValue={lots && lots.length}
          handPickedData={handPickedItem}
        />
      }
      <div className='pCardBackgound'>
        <Row gutter={[0,0]} justify='center'>
          <Col>
            <Spin style={{ top: 200, color: '#FF533F' }} indicator={antIcon} spinning={isGetHandPickedItemLoading}>
              <Row gutter={[0,{xs:0, sm:0, md:32, lg:32, xl: 32, xxl:32}]}>
                <Col span={24}>
                  <Row justify='center' gutter={[20,32]}>
                    <Col span={24}></Col>
                    {lots &&
                      lots.slice(pageValue.min, pageValue.max).map((val, i) => {
                          return (
                            <Col xl={5} lg={5} md={12} sm={11} xs={11} key={i}>
                              <ProductCard lots={val} />
                            </Col>
                          )
                      })}
                    {/* <Col span={24}></Col> */}
                  </Row>
                  {handPickedItem && handPickedItem.length <= 0 &&
                    <Empty />
                  }
                </Col>
                <Col span={isXS ? 24 : 22} className="profile-pagination handpicked-pagination">
                    <Row justify="end" align="middle">
                    <Pagination 
                      size={isXS ? 'small' : 'default'}
                      total={lots && lots.length}
                      defaultCurrent={1}
                      defaultPageSize={perPage}
                      showSizeChanger={false}
                      onChange={handlePagination} 
                    />
                  </Row>
                </Col>
                <Col span={24}/>
              </Row>
            </Spin>
          </Col>
        </Row>

        {/* <div className='relatedBack'>
          <Row justify='center' gutter={[0, { xs: 30, sm: 30, md: 48, lg: 48 }]}>
            <Col span={24}></Col>
            <Col span={24}></Col>
            <Col span={24}></Col>
            <Col>
              <Typography className='relatedItemText'>
                Related items
              </Typography>
            </Col>
          </Row>
          <Row gutter={[0, { xs: 20, sm: 20, md: 76, lg: 76 }]} justify='center'>
            <Col span={24}></Col>
            <Col xl={20} lg={20} md={24} sm={22} xs={22}>
              <Row gutter={[11, 0]}>
                <Carousel itemsToShow={4} itemsToScroll={4} breakPoints={breakPoints} showArrows={isXS ? false : true} >
                  {PlSimilarCardData.map((val, i) => {
                    return (
                      <Col key={i}>
                        <RelatedItem
                          cover={val.pLImage}
                          itemName={val.pLName}
                          price={val.pLPrice}
                          shipping={val.pLShipping}
                        />
                      </Col>
                    )
                  })}
                </Carousel>
              </Row>
            </Col>
          </Row>
          <Row justify='center' gutter={[0, { xs: 38, sm: 38, md: 48, lg: 48 }]}>
            <Col span={24}></Col>
            <Col>
              <Button className='cSeeAllButton'>
                See all
              </Button>
            </Col>
            <Col span={24}></Col>
          </Row>
        </div> */}
      </div>

      {/* <Row justify='center' gutter={[0, { xs: 53, sm: 53, md: 48, lg: 48 }]}>
        <Col span={24}></Col>
        <Col>
          <Typography className='searchedCategoryText'>
            Customers who searched for strollers also searched for
          </Typography>
        </Col>
        <Col>
          <img alt="" src={Magnifying} className='magnifyingWeb' />
          <img alt="" src={Magnifying} className='magnifyingmob' />
        </Col>
      </Row>

      <Row gutter={[0, { xs: 20, sm: 20, md: 82, lg: 82 }]} justify='center'>
        <Col span={24}></Col>
        <Col xl={20} lg={20} md={24} sm={22} xs={22}>
          <Row gutter={[11, 0]}>
            <Carousel itemsToShow={4} itemsToScroll={4} breakPoints={breakPoints} showArrows={isXS ? false : true} >
              {PlSimilarCardData.map((val, i) => {
                return (
                  <Col key={i}>
                    <RelatedItem
                      cover={val.pLImage}
                      itemName={val.pLName}
                      price={val.pLPrice}
                      shipping={val.pLShipping}
                    />
                  </Col>
                )
              })}
            </Carousel>
          </Row>
        </Col>
      </Row>

      <Row justify='center' gutter={[0, { xs: 38, sm: 38, md: 48, lg: 48 }]}>
        <Col span={24}></Col>
        <Col>
          <Button className='cSeeAllButton'>
            See all
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};
export default HandPicked;
