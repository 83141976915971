import { Row, Col, Button, Card, Divider } from "antd";
import "../App.css";
// import ProfileTab from "../components/ProfileTab";
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useBreakpoints } from './../react-responsive-hooks/index';
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import editIcon from "../assets/edit-Icon.svg";
// import ProfileImage from "../assets/ProfileImage.svg";
import ProfileForm from "../components/ProfileForm";
import { ProfileAddresses } from "../components/ProfileAddresses";
import ProfilePaymentWallet from "../components/ProfilePaymentWallet";
import ProfileFavorites from "../components/ProfileFavorites";
import ProfileAllOrders from "../components/ProfileAllOrders";
import ProfileSalesHistory from "../components/ProfileSalesHistory";
import ProfileMarkidReNewed from "../components/ProfileMarkidReNewed";
import ProfilePurchasesHistory from "../components/ProfilePurchasesHistory";
import { useHistory, useLocation } from 'react-router-dom';

// import * as yup from "yup";
// const schema = yup.object().shape({
// });
const ProfileCardDetails = (props) => {
	const { currentUser, currentUserWallet } = props
	const { addresses } = currentUser || {}
	const creditValue = (currentUserWallet && currentUserWallet.balance !== null && currentUserWallet.balance !== undefined) ? currentUserWallet.balance : {}
	let location = useLocation().pathname.split("/");
	const [tabKey, setTabkey] = useState("1")
	const [leftTabKey, setleftTabkey] = useState("1"
		// (tabKey === "1" && "1") || (tabKey === "2" && "4") || (tabKey === "3" && "8")
	)
	useEffect(() => {
		if(location){
			if(location[2] == "5"){
				setTabkey("2")
			}
			setleftTabkey(location[2])
		}
	},[])
	const handleTabKey = (selectedTabKey) => {
		if (selectedTabKey === "1") {
			setleftTabkey("1")
		}
		else if (selectedTabKey === "2") {
			setleftTabkey("5")
		}
		else if (selectedTabKey === "3") {
			setleftTabkey("9")
		}
	}
	const { isXS } = useBreakpoints();
	const onTabClick = (key) => {
		setTabkey(key)
	}
	const { _id } = currentUser || {};
	const history=useHistory()
	// const { register, handleSubmit, errors, control, reset, watch } = useForm({
	// 	resolver: yupResolver(schema),
	// 	mode: 'onSubmit',
	// });
	const { TabPane } = Tabs;
	return (
		<div style={{ backgroundColor: ' #F7F7F7' }}>
			<Row justify='center' gutter={[0, { xs: 30, sm: 30, md: 50, lg: 50, xl: 50, xxl: 50 }]}>
				<Col span={24}></Col>
				<Col className="profileTabs">
					<Tabs defaultActiveKey="1" onTabClick={onTabClick} tabBarGutter={5} tabBarStyle={{ border: '0px' }} animated={false}
						renderTabBar={(props, DefaultTabBar) => (
							<DefaultTabBar {...props} className='cMTabsBckground'>
								{node => (
									// <div  style={{overflow:'hidden',overflowX:'scroll'}} >
									<div className="profileTab" key={node.key} index={node.key}>
										{node}
									</div>
									// </div>
								)}
							</DefaultTabBar>
						)
						}
					>
						{/* {!isXS ? */}
						<TabPane tab={<Button shape='round' className={tabKey === "1" ? 'selectedProfileTabsButton' : 'unSelectedProfileTabsButton'} onClick={() => handleTabKey("1")}>Profile details</Button>} key="1">
						</TabPane>
						{/* :
							null
						} */}
						<TabPane tab={<Button shape='round' className={tabKey === "2" ? 'selectedProfileTabsButton' : 'unSelectedProfileTabsButton'} onClick={() => handleTabKey("2")}>Order history</Button>} key="2">
						</TabPane>
						{/* <TabPane tab={<Button shape='round' className={tabKey === "3" ? 'selectedProfileTabsButton' : 'unSelectedProfileTabsButton'} onClick={() => handleTabKey("3")}>Offer made/Received</Button>} key="3">
						</TabPane> */}
					</Tabs>
				</Col>
			</Row>

			<div className="app-wrapper">
				<Row justify="space-around" className="tabsBottom-block" gutter={[0, { xs: 30, sm: 30, md: 50, lg: 50, xl: 50, xxl: 50 }]}>
					{/* <Col span={24}></Col> */}
					{/* <Col xl={1} lg={1} md={1}></Col> */}
					<Col className="tabNames">
						{tabKey === "1" && <Card className="profileCard">
							<Button className={leftTabKey === "1" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("1")} >Profile</Button>
							<Button className={leftTabKey === "2" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("2")}>Addresses</Button>
							<Button className={leftTabKey === "3" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("3")}>Payment and wallet</Button>
							<Button className={leftTabKey === "4" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("4")}>Favorites</Button>
							<Button className={leftTabKey === "12" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => history.push(`/seller-profile/${_id}`)}>My listings</Button>
						</Card>}
						{tabKey === "2" && <Card className="profileCard">
							<Button className={leftTabKey === "5" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("5")} >All orders</Button>
							{/* <Button className={leftTabKey === "6" ? 'profileCard' : 'profileAddress'} onClick={() => setleftTabkey("5")}>Purchases history</Button>
						<Divider className="divider" />
						<Button className={leftTabKey === "7" ? 'profileCard' : 'profileAddress'} onClick={() => setleftTabkey("6")}>Sales history</Button>
						<Divider className="divider" />
						<Button className={leftTabKey === "8" ? 'profileCard' : 'profileAddress'} onClick={() => setleftTabkey("7")}>Markid reNewed</Button>
						<Divider className="divider" /> */}
						</Card>}
						{tabKey === "3" && <Card className="profileCard">
							<Button className={leftTabKey === "9" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("8")} >Profile 2</Button>
							<Button className={leftTabKey === "10" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("9")}>Addresses 2</Button>
							<Button className={leftTabKey === "11" ? 'tab-selected' : 'tab-button'} type="link" onClick={() => setleftTabkey("10")}>Payment and wallet 2</Button>
						</Card>}
					</Col>
					<Col className="tabContent">
						{leftTabKey === "1" && <ProfileForm UserDetail={currentUser} />}
						{leftTabKey === "2" && <ProfileAddresses UserAddresses={addresses} />}
						{leftTabKey === "3" && <ProfilePaymentWallet cashValue="0" creditValue={creditValue} />}
						{leftTabKey === "4" && <ProfileFavorites />}
						{leftTabKey === "5" && <ProfileAllOrders />}
						{leftTabKey === "6" && <ProfilePurchasesHistory />}
						{leftTabKey === "7" && <ProfileSalesHistory />}
						{leftTabKey === "8" && <ProfileMarkidReNewed />}
					</Col>
					{/* <Col xl={1} lg={1} md={1} sm={0} xs={0}></Col> */}
					{/* <Col span={24}></Col>
					<Col span={24}></Col>
					<Col span={24}></Col>
					<Col span={24}></Col>
					<Col span={24}></Col> */}
				</Row>
			</div>
		</div>
	);
};
export default ProfileCardDetails;
