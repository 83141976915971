import API from "../Utils/BlogApi";
import v5API from "../Utils/API";
import constants from "../Utils/constants";
export const getAllBlogs = (data) => (dispatch) => {
  let url = `/index.php/wp-json/wp/v2/posts`;
  if (data && data.search) {
    if (isNaN(data.search)) {
      url = `/index.php/wp-json/wp/v2/posts?search=${data.search}`;
    } else {
      url = `/index.php/wp-json/wp/v2/posts?tags=${data.search}`;
    }
  }
  dispatch({ type: constants.GET_BLOGS_REQUEST, payload: data });
  API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BLOGS_SUCCESS,
        payload: response,
        search: data && data.search,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BLOGS_FAILURE,
        payload: error.response.data,
      });
    });
};

export const getAllTags = (data) => (dispatch) => {
  let url = `/index.php/wp-json/wp/v2/tags`;
  dispatch({ type: constants.GET_BLOGS_TAGS_REQUEST, payload: data });
  API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BLOGS_TAGS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BLOGS_TAGS_FAILURE,
        payload: error.response.data,
      });
    });
};
export const getBlogsById = (data) => (dispatch) => {
  let url = `/index.php/wp-json/wp/v2/posts?include[]=${data}`;
  dispatch({ type: constants.GET_BLOGS_BY_ID_REQUEST, payload: data });
  API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BLOGS_BY_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BLOGS_BY_ID_FAILURE,
        payload: error.response.data,
      });
    });
};
export const getSearchedtags = (data) => (dispatch) => {
  let url = `/index.php/wp-json/wp/v2/posts?tags=${data}`;
  dispatch({ type: constants.GET_BLOGSTAGS_REQUEST, payload: data });
  API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BLOGSTAGS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BLOGSTAGS_FAILURE,
        payload: error.response.data,
      });
    });
};
export const getBlogsimages = (data) => (dispatch) => {
  let url = `/index.php/wp-json/wp/v2/media?parent=${data}`;
  dispatch({ type: constants.GET_BLOGS_IMAGE_REQUEST, payload: data });
  API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BLOGS_IMAGE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BLOGS_IMAGE_FAILURE,
        payload: error.response.data,
      });
    });
};

export const getBlogimageById = (data) => (dispatch) => {
  let url = `/index.php/wp-json/wp/v2/media?parent=${data}`;
  dispatch({ type: constants.GET_BLOG_IMAGE_ID_REQUEST, payload: data });
  API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BLOG_IMAGE_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BLOG_IMAGE_ID_FAILURE,
        payload: error.response.data,
      });
    });
};

export const BlogAddBookmark = (data) => (dispatch) => {
  let url = `/api/v5/blogs/bookmarks/add/${data}`;
  dispatch({ type: constants.ADD_BOOKMARK_REQUEST, payload: data });
  v5API.patch(url)
    .then((response) => {
      dispatch({
        type: constants.ADD_BOOKMARK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.ADD_BOOKMARK_FAILURE,
        payload: error.response.data,
      });
    });
};

export const BlogRemoveBookmark = (data) => (dispatch) => {
  let url = `/api/v5/blogs/bookmarks/remove/${data}`;
  dispatch({ type: constants.REMOVE_BOOKMARK_REQUEST, payload: data });
  v5API.patch(url)
    .then((response) => {
      dispatch({
        type: constants.REMOVE_BOOKMARK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.REMOVE_BOOKMARK_FAILURE,
        payload: error.response.data,
      });
    });
};
export const getBlogBookmarks = (data) => (dispatch) => {
  let url = `/api/v5/blogs/bookmarks`;
  dispatch({ type: constants.GET_BLOGBOOKMARK_REQUEST, payload: data });
  v5API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BLOGBOOKMARK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BLOGBOOKMARK_FAILURE,
        payload: error.response.data,
      });
    });
};