import React from "react";
import { Card, Typography, Row, Col } from "antd";
import "../App.css";
export const PCategoryCard = (props) => {
  const { currentCategory, currentBrand, itemType, resultValue, handPickedData } = props
  const {
    // imageUrl,
    v2ImageUrl,
    displayValue
  } = currentCategory || {}
  const {
    brand,
    imageLink
  } = currentBrand || {}
  const { handpicked } = handPickedData || {}
  const { title: handPickedName, imageLink: handPickedImage } = handpicked || {}
  return (
    <div className="pCategoryParent">
      <Card className="pCategoryCard">
        <Row gutter={[32, 0]}>
          <Col span={24}></Col>
          <Col>
            {itemType === "category" && 
              <>
                <img alt="" src={v2ImageUrl} className='icon categoryImgWeb' />
                <img alt="" src={v2ImageUrl} className='icon categoryImgMob' />
                {/* <img alt="" src={imageUrl} className='categoryImgWeb' />
                  <img alt="" src={imageUrl} className='categoryImgMob' /> */}
              </>
            }
            {itemType === "brand" &&
              <>
                <img alt="" src={imageLink} className='icon categoryImgWeb' />
                <img alt="" src={imageLink} className='icon categoryImgMob' />
                {/* <img alt="" src={imageUrl} className='categoryImgWeb' />
                  <img alt="" src={imageUrl} className='categoryImgMob' /> */}
              </>
            }
            {itemType === "handpicked" &&
              <>
                <img alt="" src={handPickedImage} className='icon categoryImgWeb' />
                <img alt="" src={handPickedImage} className='icon categoryImgMob' />
              </>
            }
          </Col>
          <Col>
            <div className='categoryBlogWeb'>
              <Row gutter={[0, 38]}>
                <Col span={24}></Col>
                <Col>
                  <Typography className='pCName'>
                    {itemType === "category" &&
                      displayValue
                    }
                    {itemType === "brand" &&
                      brand
                    }
                    {itemType === "handpicked" &&
                      handPickedName
                    }
                  </Typography>
                </Col>
              </Row>
            </div>
            <div className="categoryBlogMob">
              <Row gutter={[0, 15.33]}>
                <Col span={24}></Col>
                <Col>
                  <Typography className="pCName">
                    {itemType === "category" && displayValue}  
                    {itemType === "brand" && brand}
                    {itemType === "handpicked" && handPickedName}
                  </Typography>
                </Col>
              </Row>
            </div>
            <Row gutter={[0, 2]}>
              <Col span={24}></Col>
              <Col>
                <Typography className="pCResult">
                  {resultValue ? resultValue : 0} RESULTS
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col span={24}></Col>
        </Row>
      </Card>
    </div>
  );
};
