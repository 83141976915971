
import { useHistory } from "react-router";
import { Layout, Row, Col, Typography } from "antd";
import MHFIcon from "../assets/MHFIcon.svg";
import MHIIcon from "../assets/MHIIcon.svg";
import logo from "../assets/logo.svg";
// import MarkidLogoFooter from "../assets/MarkidLogoFooter.svg";
import ReactStoreBadges from 'react-store-badges'
import "../App.css";
import { Link } from "react-router-dom";
import { useGaEvent } from "../hooks/useGaEvent";
import { useDispatch } from "react-redux";
import { useBreakpoints } from './../react-responsive-hooks/index';
const { Text } = Typography;
const { Footer } = Layout;

const MainHomePageFooter = (props) => {
  const { isXS, isSM } = useBreakpoints();
  const { gaEventClick } = useGaEvent();
  const { categoryRef } = props;
  const history = useHistory()
  const handleCategories = () => {
    gaEventClick("Shop", "Categories");
    // if(window.location.pathname === '/homepage'){
    if (window.location.pathname === '/') {
      categoryRef.current.scrollIntoView();
    } else {
      history.push('/#categories')
    }
  }
  return (
    <Row gutter={[0, 48]} align='middle'>
      <Layout className='pageFooter'>
        <Footer>
          <Row gutter={[0, 8]} justify='space-between'>
            {/* <Col offset={1}></Col> */}
            <Col xl={10} lg={10} md={22} sm={22} xs={24}>
              <Row gutter={[8, 0]} align="top">
                <Col span={24}> </Col>
                <Col xs={10} sm={10} md={12} lg={12} xl={13} xxl={12} >
                  {/* <Link to="/homepage"> */}
                  <Link to="/">
                    <img style={{ width: '100%', height: 'fit-content' }} alt="logo" src={logo} className="footerLogo" />
                  </Link>
                </Col>
              </Row>

              {/* <div className='fotterWeb'>
                <Row gutter={[8, { xs: 0, sm: 0, md: 23, lg: 82 }]} className="downloadApps">
                  <Col span={24}></Col>
                  <Typography className='dtApp'>Download the app</Typography>
                </Row>
                <Row gutter={[16, 16]} className="downloadApps">
                  <Col span={24}></Col>
                  {/* <Col>
              <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                />
              </Col> 
                  <Col>
                    <ReactStoreBadges
                      platform={'ios'}
                      url={'https://apps.apple.com/us/app/markid/id1436309782'}
                      target="_"
                      locale={'en-us'}
                    />
                  </Col>
                </Row>
              </div> */}
            </Col>

            <Col xl={4} lg={4} md={8} sm={12} xs={12}>
              <Row gutter={[0, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text className="fHeading">Shop</Text>
                </Col>
              </Row>
              <Row gutter={[0, { xs: 0, sm: 0, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("Shop", "Home")} className='tSUserDesgnation' to="/">
                    Home
                  </Link>

                </Col>
              </Row>
              <Row gutter={[0, { xs: 0, sm: 0, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <a onClick={handleCategories} className='tSUserDesgnation'>
                    Categories
                  </a>
                </Col>
              </Row>
              {/* <Row gutter={[0, { xs: 10, sm: 10, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link className='tSUserDesgnation' to="/renewed-page">

                    {/* <a href="/search-new/?refresh=true&renewed=true" className='tSUserDesgnation'> 
                    ReNewed
                  </Link>

                </Col>
              </Row> */}
              <Row gutter={[0, { xs: 0, sm: 0, md:24, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("Shop", "Contact Us")} className='tSUserDesgnation' to="/contact-us">
                    Contact Us
                  </Link>


                </Col>
              </Row>

            </Col>
            <Col xl={4} lg={4} md={8} sm={12} xs={12}>
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text className="fHeading">Inside markid</Text>
                </Col>
              </Row>
              {/* <Row gutter={[0,{ xs: 10, sm: 10, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                <a href={process.env.REACT_APP_STATIC_DOMAIN+'contact'} target="_" className='tSUserDesgnation'>
                Contact Us
              </a>
              </Col>
            </Row> */}
              <Row gutter={[0, { xs: 0, sm: 0, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("Inside Markid", "About Us")} className='tSUserDesgnation' to="/about-us">
                    About Us
                  </Link>

                </Col>
              </Row>
              <Row gutter={[0, { xs: 0, sm: 0, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("Inside Markid", "Investors")} className='tSUserDesgnation' to="/about-us">
                    Investors
                  </Link>


                </Col>
              </Row>
            </Col>

            <Col xl={4} lg={4} md={8} sm={12} xs={12}>
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text className="fHeading">FAQ</Text>
                </Col>
              </Row>
              <Row gutter={[0, { xs: 10, sm: 10, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("FAQ", "General Questions")} className='tSUserDesgnation' to="/general-questions">
                    General Questions
                  </Link>
                </Col>
              </Row>
              <Row gutter={[0, { xs: 10, sm: 10, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("FAQ", "Buyer Hub")} className='tSUserDesgnation' to="/buyer-hub">
                    Buyer Hub</Link>
                </Col>
              </Row>
              <Row gutter={[0, { xs: 10, sm: 10, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("FAQ", "Seller Hub")} className='tSUserDesgnation' to="/seller-hub">
                    Seller Hub</Link>
                </Col>
              </Row>
              <Row gutter={[0, { xs: 10, sm: 10, md: 23, lg: 23 }]}>
                <Col span={24}></Col>
                <Col>
                  <Link onClick={() => gaEventClick("FAQ", "Community guide")} className='tSUserDesgnation' to="/community-guide">
                    Community guide</Link>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <div className='fotterMob'>
            <Row justify='center' gutter={[0, 50]}>
              <Col span={24}></Col>
              <Typography className='dtApp'>Download the app</Typography>
            </Row>
            <Row gutter={[20.24, 16]} justify='center' style={{ marginRight: isXS || isSM && "0px" }}>
              <Col span={24}></Col>
              {/* <Col>
              <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                />
              </Col> */}
               {/*<Col>
                <ReactStoreBadges
                  platform={'ios'}
                  url={'https://apps.apple.com/us/app/markid/id1436309782'}
                  target="_"
                  locale={'en-us'}
                />
              </Col>
            </Row>
          </div> */}

          <div className='fotterWeb'>
            <Row justify="space-between" gutter={[0, { xs: 0, sm: 0, md: 30, lg: 100, xxl: 100, xl: 100 }]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Row gutter={[46, 0]} className="markidPolicies">
                  <Col xxl={9} xl={9} lg={9} md={24} sm={9} offset={0}>
                    <Row gutter={[36, 0]} style={{ padding: "0px 1px" }} className="privacyAlign">
                      <Col>
                        <Link className='pITC' to="/privacy-policy">Privacy Policy</Link>
                      </Col>
                      <Col>
                        <Link className='pITC' to="/about-us">Investors</Link>
                      </Col>
                      <Col>
                        <Link className='pITC' to="/terms-and-conditions">Terms and Condition</Link>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                  md={{ span: 24, offset: 0 }}
                  xl={{ span: 15, offset: 0 }}
                  className="footerBottom">
                    <Row gutter={[25, 0]} justify="space-between">
                      <Col 
                       lg={{ span: 12, offset: 0 }}
                       md={{ span: 24, offset: 0 }}
                       >
                        <Row gutter={[36, 16]} className="socialIcons" align="center">
                          <Col>
                            <a href="https://www.facebook.com/hellomarkid" target="_">
                              <img alt="" src={MHFIcon} />
                            </a>
                          </Col>
                          <Col>
                            <a href="https://www.instagram.com/hellomarkid/?utm_source=ig_profile_share&igshid=1wqjpnlez4y64" target="_">
                              <img alt="" src={MHIIcon} />
                            </a>
                          </Col>
                        </Row>
                      </Col>
                      <Col 
                         lg={{ span: 12, offset: 0, align:"end" }}
                         md={{ span: 24, offset: 0 }}
                          className="copyRights">
                        <Text className='pITC'>2021 markid Inc. All rights reserved</Text>
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </Col>
            </Row>
          </div>

          <div className='fotterMob'>
            <Row gutter={[0, 21]} align="middle" justify="center">
              <Col span={24}></Col>
              <Col>
                <Row gutter={[26, 0]} align='middle' justify="space-between">
                  <Col>
                    {/* <a className='pITC' href={process.env.REACT_APP_STATIC_DOMAIN+'privacy-policy'} target="_">Privacy Policy</a> */}
                    <Link className='pITC' to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </Col>
                  <Col>
                    <Link className='pITC' to="/about-us">
                      Investors
                    </Link>
                  </Col>
                  <Col>
                    {/* <a href={process.env.REACT_APP_STATIC_DOMAIN+'terms-and-conditions'} target="_" className='pITC'>Terms and Condition</a> */}
                    <Link className='pITC' to="/terms-and-conditions">
                      Terms and Condition
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[25, 19]} align="middle" justify='center' style={{ marginRight: isXS || isSM && "0px" }}>
              <Col span={24}></Col>
              <Col>
                <a href="https://www.facebook.com/hellomarkid" target="_">
                  <img alt="" src={MHFIcon} />
                </a>
              </Col>
              <Col>
                <a href="https://www.instagram.com/hellomarkid/?utm_source=ig_profile_share&igshid=1wqjpnlez4y64" target="_">
                  <img alt="" src={MHIIcon} />
                </a>
              </Col>
            </Row>
            <Row gutter={[0, 19]} justify='center'>
              <Col span={24}></Col>
              <Col>
                <Text className='pITC'>2021 markid Inc. All rights reserved</Text>
              </Col>
            </Row>
          </div>
        </Footer>
      </Layout>
      {/* <Col span={24}></Col> */}
    </Row>
  );
};
export default MainHomePageFooter;

