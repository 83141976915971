import constants from "../Utils/constants";
const initialState = {
    isProductTypeLoading: false,
    productTypeData: [],
};

const productDetailsReducer = (state = initialState, action) => {
    switch (action.type) {

        case constants.PRODUCT_TYPE_REQUEST:
            return {
                ...state,
                isProductTypeLoading: true,
            };
        case constants.PRODUCT_TYPE_SUCCESS:
            const productTypeData = action.payload
            return {
                ...state,
                isProductTypeLoading: false,
                productTypeDetails: productTypeData,
            };
        case constants.PRODUCT_TYPE_FAILURE:
            return {
                ...state,
                isProductTypeLoading: false,
            };
        default:
            return state;
    }
};
export default productDetailsReducer;