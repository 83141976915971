import React, { useEffect } from 'react'

const ProductDetailsUploadInformationSection = ({ title, list }) => {

    let getListItems = () => {
        if (list && list.length) {
            return(
                list.map((listItem) => {
                    return <li><span>{listItem}</span></li>
                })
            ) 
        }
    }

    return (
        <>
            <h3>{title ? title : ""}</h3>
            <ul>
                {getListItems()}
            </ul>
        </>
    )
}

export default ProductDetailsUploadInformationSection