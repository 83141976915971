/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Typography,
} from "antd";
import "../App.css";
import {Onbording} from "../components/Onbording";
const { Text } = Typography;
const { Header, Content, Footer } = Layout;
const MarkidOnboarding = ({match}) => {
  return (
      <>
      <Onbording match={match}/>
    </>
  );
};
export default MarkidOnboarding;
