import {
  Button,
  Input,
  Typography,
  Row,
  Col,
  Collapse,
  Radio,
  Checkbox,
  DatePicker,
  Divider,
  Card,
} from "antd";
import { EditOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import {
  useForm,
  Controller,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PageHeader from "../components/PageHeader";

const { TextArea } = Input;

const { Text } = Typography;
const CollectItem = () => {
  const customFormat = (value) => {
    const m = value.format("LLLL");
    // debugger;

    return m.slice(0, -8);
  };

  const { Panel } = Collapse;

  const [radioPickValue, setRadioPickValue] = useState(false);
  const [radioDropValue, setRadioDropValue] = useState(false);
  // const [checkedValue, setCheckedValue] = useState(false);
  // const [address, setAddress] = useState(
  //   "137 Hartford Street. New York, NY 10027"
  // );

  function callback(key) {
    

    if(key.length > 0){
      switch (key[0]) {
        case "1":
          setRadioPickValue(true)
          setRadioDropValue(false)
          break;
          case "2":
            setRadioPickValue(false)
            setRadioDropValue(true)
            break;
        default:
          break;
      }
    }
  }
  const { control } = useForm({
    resolver: yupResolver(),defaultValues:{is_fix: false}
  });
  return (
    <>
      <PageHeader />
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={24}></Col>
          <Col span={24}>
            <Text strong style={{ fontSize: "4.2vh" }}>
              How do we Collect your item?
            </Text>
            <Col span={24}>
              <Text>Arrange for a pick up or drop off</Text>
            </Col>
          </Col>
          <Col span={24}></Col>
        </Row>

        <Row justify="center" gutter={[48, 32]}>
          {/* <Col span={3}></Col> */}
          <Col xl={9} lg={9} md={9} sm={20} xs={20}>
            <Collapse
              onChange={callback}
              expandIconPosition="right"
              bordered={false}
            >
              <Panel
                style={{ backgroundColor: "#f6f6f6" }}
                header={
                  <Row>
                  
                  <Radio.Group value={radioPickValue} defaultValue={radioPickValue}>

                        <Radio value={true}>Pick Up</Radio>
                        </Radio.Group>
                   </Row>
                }
                key="1"
              >
                <Row justify="start" gutter={[16, 16]}>
                  <Col span={1}></Col>
                  <Col>
                    <Text>Address</Text>
                  </Col>
                  <Col>
                    <EditOutlined />
                  </Col>
                </Row>

                <Row>
                  <Col span={1}></Col>
                  {/* <Col>
                    <Text>{address}</Text>
                  </Col> */}
                </Row>

                <Row gutter={[8, 16]}>
                  <Col span={1}></Col>
                  <Col span={23}>
                    <Input placeholder="Address" />
                  </Col>
                  <Col span={24}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={1}></Col>
                  <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                    <Input placeholder="City" />
                  </Col>

                  <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                    <Input placeholder="State" />
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={24} xs={24}>
                    <Input placeholder="Zipcode" />
                  </Col>
                </Row>
                <Divider />

                <Collapse
                  // defaultActiveKey={["0"]}
                  onChange={callback}
                  expandIconPosition="left"
                  bordered={false}
                  expandIcon={({ isActive }) => {
                    return !isActive ? <PlusOutlined /> : <MinusOutlined />;
                  }}
                >
                  <Panel
                    showArrow={true}
                    key="3"
                    style={{ backgroundColor: "#f6f6f6" }}
                    header={
                      <Row justify="start">
                        <Text>ADD DRIVER INSTUCTIONS</Text>
                      </Row>
                    }
                  >
                    <TextArea></TextArea>
                  </Panel>
                </Collapse>
              </Panel>
            </Collapse>
            <Row gutter={[8, 8]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Collapse
                  // defaultActiveKey={["0"]}
                  onChange={callback}
                  expandIconPosition="right"
                  bordered={false}
                >
                  <Panel
                    style={{ backgroundColor: "#f6f6f6" }}
                    header={
                      <Row>
                        <Col>
                          <Radio.Group
                            defaultValue={radioDropValue}
                            value={radioDropValue}
                          >
                            <Radio value={true}>Drop Off</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                    }
                    key="2"
                  >
                    <Row justify="start" gutter={[16, 16]}>
                      <Col>
                        <Controller
                          name="is_fix"
                          control={control}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            >
                              Address 1
                            </Checkbox>
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={1}></Col>
                      <Col>
                        <Text
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          137 Hartford Street. New York, NY 10027
                        </Text>
                      </Col>
                    </Row>

                    <Row justify="start">
                      <Col>
                        <Controller
                          name="is_fix"
                          control={control}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            >
                              Address2
                            </Checkbox>
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={1}></Col>
                      <Col>
                        <Text
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          137 Hartford Street. New York, NY 10027
                        </Text>
                      </Col>
                    </Row>
                  </Panel>
                  {/* </Radio.Group> */}
                </Collapse>
              </Col>
            </Row>
          </Col>

          <Col xl={9} lg={9} md={9} sm={20} xs={20}>
            <Row justify="start">
              <Col>
                <Text strong>Date</Text>
              </Col>
            </Row>

            <Row>
              <Col xl={19} lg={19} md={19} sm={24} xs={24}>
                <DatePicker
                  style={{ display: "flex", justifyContent: "flex-start" }}
                  // format={moment('LLLL', dateFormat)}
                  format={(value) => customFormat(value)}
                />
              </Col>
            </Row>

            <Row justify="start" gutter={[8, 16]}>
              <Col span={24}></Col>
              <Col>
                <Text strong>Slot Time</Text>
              </Col>
            </Row>
            <Row gutter={[8, 8]} >
              
              <Col 
              // spna={6}
              // xl={12} lg={12} md={12} sm={12} xs={12}
              >
                <Button 
                style={{maxWidth: 120,fontSize:12}} 
                >8:00-10:00 am</Button>
              </Col>
              <Col 
              // spna={6}
              // xl={12} lg={12} md={12} sm={12} xs={12}
              >
                <Button 
                style={{maxWidth: 120,fontSize:12}}
                >10:00-12:00 pm</Button>
              </Col>
            

            
              
              <Col 
              // spna={6}
              // xl={8} lg={8} md={16} sm={12} xs={12}
              >
                <Button 
                style={{maxWidth: 120,fontSize:12}}
                >12:00-2:00 pm</Button>
              </Col>
              <Col 
              // spna={6}
              // xl={8} lg={8} md={16} sm={12} xs={12}
              >
                <Button 
                style={{maxWidth: 120,fontSize:12}}
                >02:00-4:00 pm</Button>
              </Col>
            
           
              
              <Col 
              // spna={6}
              // xl={8} lg={8} md={16} sm={12} xs={12}
              >
                <Button 
                style={{maxWidth: 120,fontSize:12}}
                >04:00-06:00 am</Button>
              </Col>
              <Col 
              // spna={6}
              // xl={8} lg={8} md={16} sm={12} xs={12}
              >
                <Button 
                style={{maxWidth: 120,fontSize:12}}
                >06:00-08:00 pm</Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}></Col>
          <Col span={24}></Col>
          <Col span={24}></Col>
        </Row>
      </Card>

      <Row justify="center" align='middle' gutter={[16, 8]}>
        <Col span={24}></Col>
        <Col spa={24}>
          <Button
           className={'squarebutton'}
          >
            CONFIRM
          </Button>
        </Col>
        <Col span={24}></Col>
      </Row>
    </>
  );
};
export default CollectItem;
