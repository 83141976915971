import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Row, Col} from 'antd';
import 'antd/dist/antd.css';
import '../App.css';
import ProfileStatement from "../components/ProfileStatement";
import ProfileCardDetails from "../components/ProfileCardDetails";
import { getUser } from "../Actions/userAction";
import { getCurrentUserWallet } from "../Actions/cartAction";
import {Helmet} from "react-helmet";

const ProfileDetails = ({match}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUser());
        dispatch(getCurrentUserWallet())
      }, []);
      const { currentUser, currentUserWallet } = useSelector((state) => {
          const { currentUser } = state.user;
          const { currentUserWallet } = state.cart;
          return {
            currentUser,
            currentUserWallet,
          };
        });
    return (
        <>
            <Helmet>
                <title>Markid - Profile</title>
            </Helmet>
            <ProfileStatement 
            currentUser={currentUser}
            currentUserWallet={currentUserWallet}
            />
            <ProfileCardDetails 
            currentUser={currentUser}
            currentUserWallet={currentUserWallet}
            match={match}
            />
        </>
    );
};
export default ProfileDetails;


