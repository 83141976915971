/*eslint-disable*/
import {
  Button,
  Input,
  Typography,
  Row,
  Col,
  Image,
  Divider,
  Card,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import PageHeader from "../components/PageHeader";
import { ItemCard } from "../components/ItemCard";
import { ItemNotFound } from "../pages/ItemNotFound";
const { Text, Title } = Typography;
const ItemDetails = () => {
  const { TextArea } = Input;

  const [selectedCategory, setselectedCategory] = useState({});
  const [selectedBrand, setselectedBrand] = useState({});
  const [selectedYear, setselectedYear] = useState({});
  const [selectedModel, setselectedModel] = useState({});
  const [selectedCondition, setselectedCondition] = useState({});
  const [createDialog, setCreateDialog] = useState(false);
  const onCardClick = (selected) => {
    const { type, i } = selected;
    switch (type) {
      case "catagory":
        // code block
        setselectedCategory(selected);
        break;
      case "brand":
        setselectedBrand(selected);
        break;
      case "model":
        setselectedModel(selected);
        break;
      case "year":
        setselectedYear(selected);
        break;
      case "condition":
        setselectedCondition(selected);
        break;
      default:
      // code block
    }
  };
  return (
    <>
      <PageHeader />
      {/* <Row justify="center">
        <Col style={{ paddingTop: 30 }}>
          <Button onClick={() => setCreateDialog(true)}>Item Not Found</Button>
          {createDialog && (
            <ItemNotFound
              createDialog={createDialog}
              setCreateDialog={setCreateDialog}
              // handleDialog={() => setCreateDialog(true)}
            />
          )}
        </Col>
      </Row> */}
      <Card>
      <Row gutter={[16, 32]}>
        <Col span={24}></Col>
        <Col xl={24} lg={24} md={22} sm={22} xs={22}>
          <Title strong level={2}>
            Let's add your item
          </Title>
          <Text>Choose product from the list provided</Text>
        </Col>
      </Row>

      <Row justify="center" gutter={[48, 32]}>
        <Col span={24}></Col>
        <Col
          xl={18}
          lg={22}
          md={24}
          sm={22}
          xs={22}

        >
          <Card style={{ backgroundColor: "#f6f6f6" }}>
            <Row justify="start" gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col>
                <Row justify="start" gutter={[8, 0]}>
                  <Col span={24}></Col>
                  <Col>
                    <PlusOutlined />
                  </Col>
                  <Col>
                    <Text>Item 1</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row justify="start" gutter={[0, 24]}>
              <Col span={24}></Col>
              <Col>
                <Text>Select catagory</Text>
              </Col>
            </Row>
            <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 1 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Strollers</Text>
                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 2 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Bussinnets</Text>
                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 3 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Cribs</Text>
                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 4 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>Gear</Text>
                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                <ItemCard
                  id={{ type: "catagory", i: 5 }}
                  selected={selectedCategory}
                  onCardClick={onCardClick}
                ></ItemCard>
                <Row justify="center">
                  <Text>High Chair</Text>
                </Row>
              </Col>
            </Row>
            <Row justify="start" gutter={[0, 24]}>
              <Col span={24}></Col>
              <Col>
                <Text>Select Stroller Brands</Text>
              </Col>
            </Row>

            <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="UppaBaby"
                  id={{ type: "brand", i: 1 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Buggaboo"
                  id={{ type: "brand", i: 2 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Buggaboo"
                  id={{ type: "brand", i: 3 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Bob"
                  id={{ type: "brand", i: 4 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="City Mini"
                  id={{ type: "brand", i: 5 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
          
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Citi Select"
                  id={{ type: "brand", i: 6 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Joovy"
                  id={{ type: "brand", i: 7 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Nuna Mixx Stroller"
                  id={{ type: "brand", i: 8 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Lelo The Daily Stroller"
                  id={{ type: "brand", i: 9 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Babyzen"
                  id={{ type: "brand", i: 10 }}
                  selected={selectedBrand}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
            </Row>

            <Row justify="start" gutter={[0, 24]}>
              <Col span={24}></Col>
              <Col>
                <Text>Select Model</Text>
              </Col>
            </Row>

            <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="Cruz"
                  id={{ type: "model", i: 1 }}
                  selected={selectedModel}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="Vista"
                  id={{ type: "model", i: 2 }}
                  selected={selectedModel}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="Minu"
                  id={{ type: "model", i: 3 }}
                  selected={selectedModel}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col>
            </Row>

            <Row justify="start" gutter={[0, 24]}>
              <Col span={24}></Col>
              <Col>
                <Text>Select Year</Text>
              </Col>
            </Row>

            <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="2017"
                  id={{ type: "year", i: 1 }}
                  selected={selectedYear}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="2018"
                  id={{ type: "year", i: 2 }}
                  selected={selectedYear}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="2019"
                  id={{ type: "year", i: 3 }}
                  selected={selectedYear}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="2020"
                  id={{ type: "year", i: 4 }}
                  selected={selectedYear}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <ItemCard
                  text="2021"
                  id={{ type: "year", i: 5 }}
                  selected={selectedYear}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
            </Row>

            <Row justify="start" gutter={[0, 24]}>
              <Col span={24}></Col>
              <Col>
                <Text>Item Condition</Text>
              </Col>
            </Row>

            <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Good"
                  id={{ type: "condition", i: 1 }}
                  selected={selectedCondition}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Great"
                  id={{ type: "condition", i: 2 }}
                  selected={selectedCondition}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="Like-New"
                  id={{ type: "condition", i: 3 }}
                  selected={selectedCondition}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <ItemCard
                  text="New"
                  id={{ type: "condition", i: 4 }}
                  selected={selectedCondition}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col>
            </Row>

            <Row justify="start" gutter={[0, 24]}>
              <Col span={24}></Col>
              <Col>
                <Text>Note</Text>
              </Col>
            </Row>

            <Row justify="start" gutter={[32, 16]}>
              <Col span={24}></Col>
              <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                <TextArea
                  rows={2}
                  cols={100}
                  placeholder="Write about your item"
                ></TextArea>
              </Col>
              <Col span={24}></Col>
            </Row>
</Card>
        </Col>
      </Row>

      <Row justify="center" gutter={[0, 32]}>
        <Col span={24}></Col>
        <Col>
          <Button
           className={'squarebutton'}
          >
            ADD MORE
          </Button>
        </Col>
      </Row>

      <Row justify="center" gutter={[0, 32]}>
        <Col span={24}></Col>
        <Col>
          <Text
            style={{
              color: "#ababab",
            }}
          >
            Can't find your item here list your item on markid and join our
            growing parent community
          </Text>
        </Col>
      </Row>

      <Row justify="center" gutter={[0, 0]}>
        <Col span={24}></Col>
        <Col>
          <Text
            underline
            style={{
              color: "#ababab",
            }}
          >
            App link
          </Text>
        </Col>
      </Row>
</Card>

      <Row justify="center" gutter={[0, 32]}>
        <Col span={24}></Col>
        <Col>
          <Button
            block
            style={{
              backgroundColor: "#9d9d9d",
              color: "#f6f6f6",
            }}
          >
            CONFIRM
          </Button>
        </Col>
        <Col span={24}></Col>
      </Row>
    </>
  );
};
export default ItemDetails;
