/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash/debounce';

import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  Menu,
  Dropdown,
  Input,
  Badge,
  AutoComplete,
} from "antd";
import _ from "lodash";
// import MarkidreNewedlogo from "../assets/MarkidReNewedLogo.svg";
import MRLogo from "../assets/mrLogo.png";
import Location from "../assets/location.svg";
import UserAvatar from "../assets/userAvatar.svg";
import ShoppingCart from "../assets/shoppingCart.svg";
import { useHistory } from "react-router-dom";
import {
  SearchOutlined,
  DownOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import "../styles/landing.css";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import "../App.css";
import { getAllLots, getSearchLot } from "../Actions/lotsAction";
// import CategoryHeader from "../components/CategoryHeader";
const { Text } = Typography;
const { Search } = Input;
const FeedTopbar = (props) => {
  const history = useHistory();
  const { currentUser, providerToken, currentCart, allLots, searchedLots } = useSelector(
    (state) => {
      const { allLots, searchedLots } = state.lots;
      const { providerToken } = state.auth;
      const { currentUser } = state.user;
      const { currentCart } = state.cart;
      return {
        currentUser,
        providerToken,
        currentCart,
        allLots,
        searchedLots,
      };
    }
  );
  const { lots } = allLots || { lots: [] };
  const { fullName } = currentUser || {};
  // const { providerToken } = props;
  const dispatch = useDispatch();
  const userImgName = fullName ? fullName.charAt(0) : null;
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [token, setToken] = useState(providerToken);
  const [search, setSearch] = useState("");
  // const [zipCode, setZipCode] = useState("");
  // const [newZip, setNewZip] = useState("");
  // const [visibleInput, setVisibleInput] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setToken("");
    dispatch({ type: "REMOVE_TOKEN" });
    dispatch({ type: "RESET_APP" });
    history.push("/");
  };
  // const handleVisibleChange = (flag) => {
  //   setVisibleInput(flag);
  // };
  // const handleChangeZipcode = (e) => {
  //   setZipCode(newZip)
  //   setVisibleInput(!visibleInput)
  // }
  // const locationMenu = (
  //   <Menu>
  //     <Menu.Item>
  //       <Input
  //       defaultValue={zipCode}
  //       name={newZip}
  //       onChange={(e) => setNewZip(e.target.value)}
  //       suffix={<Button type='text' onClick={(e) => handleChangeZipcode(e)}>Save</Button>}
  //       allowClear
  //       />
  //     </Menu.Item>
  //   </Menu>
  // );
  // useEffect(() => {
  //   handleCurrentLocation()
  //   // dispatch(getSearchLot());
  // },[]);
  // const handleCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         if (result.state === "granted") {
  //           //If granted then you can directly call your function here
  //           navigator.geolocation.getCurrentPosition(handleGeoLocationSuccess, handleGeoLocationFaliure, {});
  //         } else if (result.state === "prompt") {
  //           navigator.geolocation.getCurrentPosition(handleGeoLocationSuccess, handleGeoLocationFaliure, {});
  //         } else if (result.state === "denied") {
  //           setZipCode("10100")
  //           //If denied then you have to show instructions to enable location
  //         }
  //         result.onchange = function () {
  //         };
  //       });
  //   } else {
  //     alert("Sorry Not available!");
  //   }
  // };
  // const handleGeoLocationSuccess = async (s) => {
  //   const {coords} = s;
  //   const {latitude, longitude} = coords;
  //   const zipCode =  await getZipcode({latitude,longitude})
  //   setZipCode(zipCode)
  // }

  // const getZipcode = async ({latitude, longitude}) => {
  //   const apiKey = "AIzaSyC5n11G2cpe5WL1PN6l2RocIkUSNTofCe8"
  //   let zipCode = "10100";
  //   const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
  //   const getResponse = await fetch(googleUrl,{method:'GET', redirect:'follow', 'Content-Type': 'application/json',}).then((response) => response.json()).then((data) => {
  //   if (data.results && data.results.length > 0)  {
  //     const pcs =  _.filter(data.results, {types: ["postal_code"]} )
  //     const addressCompnents = _.forEach(pcs, function(value){
  //       const { address_components } = value;
  //       const codes = _.filter(address_components, {types: ["postal_code"]} )
  //       if(codes && codes.length > 0){
  //         zipCode = codes[0].long_name
  //       }
  //     })
  //   }

  // }).catch((err) => console.log({err}));

  //   return zipCode;
  // }
  // const handleGeoLocationFaliure = (e) => {
  //   console.log({e})
  // }
  const handleSearch = (data) => {
    dispatch(getAllLots({ search: data }))
  };

  const handleOnSelect = (value, option) => {
    setSearch(option.label)
    if (history.location.pathname.includes("/product/")) {
      const path = { pathname: `/products/${option.label}/${value}?refresh=true`, state: { pId: value } }
      history.push(path)
    }
    else {
      history.push(`/products/${option.label}/${value}`)
      dispatch({ type: "RESET_SEARCH" })
    }
  }
  const handleSearchSubmit = () => {
    if (search) {
      history.push(`/search/${search}`)
    }
  }
  return (
    <>
      <Row align="middle" gutter={[0, 24]}>
        <Col span={24}></Col>
        <Col
          xl={{ span: 4, offset: 2 }}
          lg={{ span: 4, offset: 2 }}
          md={{ span: 4, offset: 2 }}
          sm={{ span: 22, offset: 2 }}
          xs={{ span: 22, offset: 2 }}
        >
          <Row>
            <Link to="/">
              <img alt="logo" src={MRLogo} className="logoStyle" />
            </Link>
          </Row>
        </Col>
        {/* <Col
          xs={{ span: 7, offset: 1 }}
          lg={{ span: 5, offset: 1 }}
          md={{ span: 5, offset: 1 }}
        ></Col> */}
        <Col
          xl={{ span: 8, offset: 8 }}
          lg={{ span: 8, offset: 8 }}
          md={{ span: 10, offset: 4 }}
          sm={{ span: 22, offset: 2 }}
          xs={{ span: 22, offset: 2 }}
        >
          <Row align="middle" justify="start" gutter={[{ md: 16, lg: 16 }, 8]}>
            {/* <Col>
              <Row gutter={[4, 0]} justify="center" align="middle">
                <Col>
                  <img width="75%" src={Location} />
                </Col>
                <Col>
                  <Dropdown
                    overlay={locationMenu}
                    onVisibleChange={() => handleVisibleChange(!visibleInput)}
                    visible={visibleInput}
                    // trigger={["click"]}
                    placement="bottomCenter"
                    arrow
                  >
                    <Text type="link" className="location-num">
                      {zipCode} <DownOutlined />
                    </Text>
                  </Dropdown>
                </Col>
              </Row>
            </Col> */}
            <Col
              // xl={4} lg={6} md={6} sm={8} xs={8}
              xl={10}
              lg={10}
              md={10}
              sm={8}
              xs={8}
              className="topsearchbar"
            >
              <AutoComplete
                onSearch={debounce(handleSearch, 500)}
                onSelect={handleOnSelect}
                onChange={(e) => setSearch(e)}
                value={search}
                defaultOpen={true}
                options={searchedLots}
                // showSearch={true}
                placeholder={
                  <div style={{ display: "flex", color: "#93CFCB" }}>
                    Search
                  </div>
                }
                // suffixIcon={<SearchOutlined style={{ color: "#93CFCB" }} />}
                style={{
                  width: "100%",
                  borderRadius: "50px",
                }}
              >
              </AutoComplete>
              {/* <Button onClick={() => handleSearchSubmit()}type='text' className='searchButton' icon={<SearchOutlined />}></Button> */}
            </Col>
            <Col
              xl={2} lg={2} md={2}
              sm={2} xs={2}
            >
              <Button onClick={() => handleSearchSubmit()} type='text' className='searchButton' icon={<SearchOutlined />}></Button>
            </Col>
            <Col
              xl={3} lg={3} md={3}
              sm={5} xs={5}
            >
              <Link to="/markid-cart">
                <Badge count={currentCart.length}>
                  <img width="75%" src={ShoppingCart} />
                </Badge>
              </Link>
            </Col>
            <Col
              // xl={5} lg={5} md={5} 
              sm={9} xs={9}
            >
              {providerToken && providerToken ? (
                <>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Avatar
                        style={{ backgroundColor: "#93CFCB" }}
                        size={30}
                        src={userImgName}
                      // width="75%"
                      //   icon={<UserOutlined />}
                      //   style={{
                      //     display: "flex",
                      // 	cursor: 'pointer',
                      //     alignItems: "center",
                      //     justifyContent: "center",
                      //     float: "right",
                      //   }}
                      >
                        {userImgName}
                      </Avatar>
                    </Col>
                    <Row align="middle">
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => handleLogout()}>
                              Logout
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Col>
                          <Text className="userName">{fullName}</Text>{" "}
                          <DownOutlined />
                        </Col>
                      </Dropdown>
                    </Row>
                  </Row>
                </>
              ) : (
                <Avatar
                  // src={user.user.picture}
                  onClick={() => setShowSignInDialog(true)}
                  size={15}
                  src={UserAvatar}
                  width="75%"
                //   icon={<UserOutlined />}
                //   style={{
                //     display: "flex",
                // 	cursor: 'pointer',
                //     alignItems: "center",
                //     justifyContent: "center",
                //     float: "right",
                //   }}
                />
              )}
            </Col>
            {/* <Divider type="vertical" /> */}
            {/* <Col
            // xl={2} lg={2} md={2} sm={2} xs={2}
            >
              <Button className="squarebutton">Sell</Button>
            </Col> */}
          </Row>
        </Col>
      </Row>
      {/* <SignUp
        onCancelSignUpMethod={() => setShowSignUpDialog(false)}
        showSignUpDialog={showSignUpDialog}
      /> */}
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
      {/* <CategoryHeader /> */}
    </>
  );
};
export default FeedTopbar;
