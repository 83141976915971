import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Collapse } from "antd";
import { CloseOutlined, UpOutlined, DownOutlined, } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../App.css";
import { useForm } from "react-hook-form";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";
import { getAllBrands } from "../Actions/brandsAction";
import { getAllCategories } from "../Actions/categoriesAction";
import { addBrands, addCategories } from "../Actions/userAction";
import { humanize } from "../Utils/util";
const { Panel } = Collapse;
export const CustomiseExperienceForm = (props) => {
  const [brandButton, setBrandButton] = useState([])
  const [categoryButton, setCategoryButton] = useState([])
  const [showBrands, setShowBrands] = useState(7)
  const [showCategories, setShowCategories] = useState(7)
  const { current, setCurrent } = props
  const {
    allBrands, allCategories
  } = useSelector((state) => {
    const { allBrands } = state.brands;
    const { allCategories } = state.categories;
    return {
      allBrands,
      allCategories
    };
  });
  const dispatch = useDispatch();
  const { innerHeight } = window;
  const { handleSubmit } = useForm({
    // resolver: yupResolver(schema),
    mode: 'onChange',
    // defaultValues: { phone: "" },
  });
  function callback(key) {
    // console.log(key);
  }
  const handleBrand = (brandVal) => {
    setBrandButton(brandButton => [...brandButton, brandVal])
  }
  const handleCategory = (categoryVal) => {
    setCategoryButton(categoryButton => [...categoryButton, categoryVal])
  }
  useEffect(() => {
    dispatch(getAllBrands());
  }, []);
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);
  const handleShowBrands = () => {
    setShowBrands(allBrands.length)
  }
  const handleShowCategories = () => {
    setShowCategories(allCategories.length)
  }
  const handleUpdateUserBrandsAndCategories = (data) => {
    data.brands = brandButton
    data.categories = categoryButton
    dispatch(
      addBrands(data, successCallBackBrandsAndCategories, failureCallBackBrandsAndCategories)
    );
    dispatch(
      addCategories(data, successCallBackBrandsAndCategories, failureCallBackBrandsAndCategories)
    );
  };
  const successCallBackBrandsAndCategories = () => {
    setCurrent(current + 1);
  };
  const failureCallBackBrandsAndCategories = () => { };
  return (
    <div style={{
      // overflow:'hidden', overflowY:'scroll', height:innerHeight
    }}>
      <Card bordered={false}>
        <form onSubmit={handleSubmit(handleUpdateUserBrandsAndCategories)}>
          <div className='onboardingWeb'>
            <div className="closeicon-wrap">
              <CloseOutlined className='oBCloseIcon' />
            </div>
            <Row gutter={[0, 21]} justify='space-between'>
              <Col span={24}></Col>
              <Col>
                {/* <Link to="/homepage"> */}
                <Link to="/">
                  <img alt="logo" src={Logo} className="logoStyle weblogo" />
                </Link>
              </Col>
              <Col>
                <Row gutter={[0, 12]}>
                  <Col span={24}></Col>
                  <Button type='text' className='skip-btn' onClick={() => setCurrent(current + 1)}>
                    Skip
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
          <div className='onboardingMob'>
            <div className="closeicon-wrap">
              <CloseOutlined className='oBCloseIcon' />
            </div>
            <Row gutter={[0, 0]} justify='space-between'>
              <Col span={24}></Col>
              <Col>
                {/* <Link to="/homepage"> */}
                <Link to="/">
                  <img alt="logo" src={ShippingLogo} className='markidLogoMob' />
                </Link>
              </Col>
              <Col>
                <Row gutter={[30, 0]}>
                  <Col>
                    <Button type='text' className='skip-btn' onClick={() => setCurrent(current + 1)}>
                      Skip
                    </Button>
                  </Col>
                  {/* <Col>
                    <Row gutter={[0, 10]}>
                      <Col span={24}></Col>
                      <CloseOutlined className='oBCloseIcon' />
                    </Row>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </div>
          <Row gutter={[0, { xs: 22.12, sm: 22.12, md: 16, lg: 16, xl: 16, xxl: 16 }]}>
            <Col span={24}></Col>
            <Col span={24}></Col>
            <Col>
              <Typography className='createAccountText'>
                Let us customise your experience
              </Typography>
            </Col>
          </Row>

          {/*<Row gutter={[0, { xs: 35, sm: 35, md: 37, lg: 37, xl: 37, xxl: 37 }]}>
            <Col span={24}></Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Collapse
                style={{ backgroundColor: "white" }}
                // defaultActiveKey={["1"]}
                onChange={callback}
                expandIconPosition="right"
                bordered={false}
                expandIcon={({ isActive }) => {
                  return !isActive ? (

                    <DownOutlined className='collapsIcon' />
                  ) : (
                    <UpOutlined className='collapsIcon' />
                  );
                }}
              >
                <Panel
                  showArrow={true}
                  className='cEPanel'
                  header={
                    <Row justify='start' align='middle'>
                      <Typography className='childrenHeading'>Pick your favourite brands</Typography>

                    </Row>
                  }
                  key="1"
                >

                  <div class="c-scroll">
                    <div className="exp-btns-wrap">
                      {/* <Col span={24}></Col> */}
                      {/* {allBrands &&
                        allBrands.map((val, i) => (
                          i < showBrands &&
                          <div className="in-col" key={i}>
                            <Button className={brandButton.length > 0 ? brandButton.map((_id) => _id === val._id ? 'selectedageButton' : 'unSelectedageButton') : 'unSelectedageButton'} onClick={() => handleBrand(val._id)}>{val.brand}</Button>
                          </div>
                        ))} */}
                      {/* <Col>
              <Button className={brandButton === 'stokkee' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleBrand('stokkee')}>stokkee</Button>
              </Col>
              <Col>
              <Button className={brandButton === 'city-mini' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleBrand('city-mini')}>city mini</Button>
              </Col>
              <Col>
              <Button className={brandButton === 'disney' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleBrand('disney')}>disney</Button>
              </Col>
              <Col>
              <Button className={brandButton === 'Fisherprice' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleBrand('Fisherprice')}>Fisherprice</Button>
              </Col>
              <Col>
              <Button className={brandButton === 'citi-select' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleBrand('citi-select')}>citi select</Button>
              </Col>
              <Col>
              <Button className={brandButton === 'uppababy' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleBrand('uppababy')}>uppababy</Button>
              </Col>
              <Col>
              <Button className={brandButton === 'joovy' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleBrand('joovy')}>joovy</Button>
              </Col> 
                    </div>
                  </div>


                  <Row gutter={[0, 10]}>
                    <Col span={24}></Col>
                    <Col>
                      <Button type='text' className={"oBSeeAll"} onClick={() => handleShowBrands()}>
                        See all
                      </Button>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row> */}

          <Row gutter={[0, 0]}>
            <Col span={24}></Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Collapse
                style={{ backgroundColor: "white" }}
                defaultActiveKey={["0"]}
                onChange={callback}
                expandIconPosition="right"
                bordered={false}
                expandIcon={({ isActive }) => {
                  return !isActive ? (

                    <DownOutlined className='collapsIcon' />
                  ) : (
                    <UpOutlined className='collapsIcon' />
                  );
                }}
              >
                <Panel
                  showArrow={true}
                  className='cEPanel'
                  header={
                    <Row justify='start' align='middle'>
                      <Typography className='childrenHeading'>Pick your preferred categories</Typography>

                    </Row>
                  }
                  key="1"
                >
                  <div class="c-scroll">
                    <div className="exp-btns-wrap">
                      {/* <Col span={24}></Col> */}
                      {allCategories &&
                        allCategories.map((val, i) => (
                          i < showCategories &&
                          <div className="in-col" key={i}>
                            <Button className={categoryButton.length > 0 ? categoryButton.map((_id) => _id === val._id ? 'selectedageButton' : 'unSelectedageButton') : 'unSelectedageButton'} onClick={() => handleCategory(val._id)}>{humanize(val.category)}</Button>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* <Col>
               <Button className={categoryButton === 'Apparel' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleCategory('Apparel')}>Apparel</Button>
               </Col>
               <Col>
               <Button className={categoryButton === 'Bouncers & Playards' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleCategory('Bouncers & Playards')}>Bouncers & Playards</Button>
               </Col>
               <Col>
               <Button className={categoryButton === 'Toys' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleCategory('Toys')}>Toys</Button>
               </Col>
               <Col>
               <Button className={categoryButton === 'Bouncers' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleCategory('Bouncers')}>Bouncers</Button>
               </Col>
               <Col>
               <Button className={categoryButton === 'Room & Nursery' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleCategory('Room & Nursery')}>Room & Nursery</Button>
               </Col>
               <Col>
               <Button className={categoryButton === 'Shoes' ? 'selectedageButton' : 'unSelectedageButton'} onClick={() => handleCategory('Shoes')}>Shoes</Button>
               </Col> */}


                  <Row gutter={[0, 25]}>
                    <Col span={24}></Col>
                    <Col>
                      <Button type='text' className={"oBSeeAll"} onClick={() => handleShowCategories()}>
                        See all
                      </Button>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row justify="center" gutter={[0, 20]}>
            <Col span={24}></Col>
            <Col span={24}></Col>
            <Col>
              <Button htmlType='submit'
                disabled={ categoryButton.length > 0 ? false : true}
                className={ categoryButton.length > 0 ? "oBenabledSignupButton" : "oBdisabledSignupButton"}>
                Continue
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </div>
  );
};