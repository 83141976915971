import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Spin } from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import "../App.css";
import { joinCommunity } from "../Actions/userAction";
export const OBCommunityCard = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#393939' }} spin />;
  const { cover, communityName, communityDes, communityData, setCurrent } = props
  const { _id } = communityData
  const [clickedCommunityId, setClickedCommunityId] = useState('')
  const dispatch = useDispatch()
  const { currentUser, communities, isAuthenticated, isJoinCommunityLoading } = useSelector((state) => {
    const { currentUser, isJoinCommunityLoading } = state.user;
    const { isAuthenticated } = state.auth;
    const { communities } = currentUser || {}
    return {
      currentUser,
      isAuthenticated,
      communities,
      isJoinCommunityLoading
    };
  });
  const CommunityId = _id
  let UserCommunityId = ""
  {
    communities &&
      communities.map((current) => {
        return (
          current === CommunityId ? UserCommunityId = current : null
        )
      })
  }
  const handleJoinCommunity = () => {
    if (isAuthenticated) {
      setClickedCommunityId(CommunityId)
      dispatch(joinCommunity({ id: CommunityId }));
    }
    else {
      setCurrent(0)
    }
  }
  return (
    <div>
      <Card className='oBComCard'
        cover={cover}>
        <Row justify='center'>
          <Col>
            <Typography
              className="oBComName"
            >
              {" "}
              {communityName}
            </Typography>
          </Col>
        </Row>
        <Row justify='center' gutter={[0, 5.61]}>
          <Col span={24}></Col>
          <Col>
            <Typography className='oBComDes'>
              {communityDes}
            </Typography>
          </Col>
        </Row>
        <Row justify='center' gutter={[0, 0]}>
          <Col span={24}></Col>
          <Col>
            {/* {comButton} */}
            {/* <Spin indicator={antIcon} spinning={isJoinCommunityLoading && clickedCommunityId === _id}> */}
            {CommunityId === UserCommunityId ?
              <Button className='button button-bordered oBComJoinButton'>Joined</Button>
              :
              <Button className='button button-bordered oBComJoinButton' onClick={() => handleJoinCommunity()}>Join Now</Button>
            }
            {/* </Spin> */}
          </Col>
        </Row>
      </Card>
    </div>
  );
};
