/*eslint-disable*/
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Avatar,
  Divider,
  Row, Col,
} from "antd";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import CartItemsCard from "./CartItemsCard";
const { Text, Title, Paragraph } = Typography;
const CartItems = () => {
  const dispatch = useDispatch();
  const { currentCart } = useSelector((state) => {
    const { currentCart } = state.cart;
    return {
      currentCart,
    };
  });
  // const ItemDescription = [
  //   {
  //     closeIcon: <CloseOutlined />,
  //     itemImg:   <Avatar shape="square" size={140} src={File1} />,
  //     itemName: "Bloom Coco Lounger",
  //     itemShippingPrice: "$150 + $13.42 shipping",
  //     shippingPrice: "$13.42",
  //     totalPrice: "$163.42",
  //     mode: "Shipping",
  //   },
  //   {
  //     closeIcon: <CloseOutlined />,
  //     itemImg:   <Avatar shape="square" size={140} src={File2} />,
  //     itemName: "Gymboree Babygirl Dress",
  //     itemShippingPrice: "$12 + $2.19 shipping",
  //     shippingPrice: "$2.19",
  //     totalPrice: "$14.19",
  //     mode: "Shipping",
  //   },
  // ]
  return (
    <>
{currentCart && currentCart.map((val, i) => (
      <CartItemsCard currentCart={val} key={i} />
      ))}
      <Row gutter={[0, 16]} justify="space-between">
        <Col span={24}></Col>
        <Col>
        <Row gutter={[8,0]}>
          <Col>
            {/* <Avatar /> */}
            </Col>
            <Col>
            <Title className='seller-title' level={4}>
              Seller 
            </Title>
            </Col>
            <Col>
            <Row gutter={[0,0]}>
              <Col span={24}></Col>
              <Col>
            <Text className='seller-name'>reNEWED</Text>
            </Col>
            </Row>
            </Col>
            </Row>
        </Col>
        {/* <Col>
          <Button block className={'buttonwithborder'}>CHECKOUT FROM THIS SELLER</Button>
        </Col> */}
      </Row>
            <Divider />
    </>
  );
};
export default CartItems;
