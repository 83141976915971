/*eslint-disable*/
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
	Row,
	Col,
	Typography,
	Divider,
	Button,
	Select,
	Input,
	Radio,
	Card,
	Spin,
	Alert,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../App.css";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { createOrder } from "../Actions/orderAction";
import Vinemo from "../assets/Vinemo.svg";
import GooglePayLogo from "../assets/GooglePayLogo.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
import _ from "lodash";
import { addCard, getCards } from "../Actions/paymentAction";
const { Text, Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const schema = yup.object().shape({
	name: yup.string().required("Name is require"),
});
const SavedCardForm = (props) => {
	const dispatch = useDispatch();
	const { setCDCForm } = props
	const history = useHistory();
	const { isXS } = useBreakpoints();
	const { innerWidth } = window;

	const [stripeErrorMessage, setStripeErrorMessage] = useState("")
	const { isAddCardLoading } = useSelector((state) => {
		const { isAddCardLoading } = state.payment;
		return {
			isAddCardLoading
		};
	});
	const { handleSubmit, control, errors, watch } = useForm({
		resolver: yupResolver(schema),
	});
	const watching = watch()
	const { name } = watching
	const stripe = useStripe();
	const elements = useElements();

	const CARD_OPTIONS = {
		iconStyle: "solid",
		style: {
			base: {
				iconColor: "#c4f0ff",
				color: "#fff",
				fontWeight: 500,
				fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
				fontSize: "16px",
				fontSmoothing: "antialiased",
				":-webkit-autofill": {
					color: "#fce883"
				},
				"::placeholder": {
					color: "#87bbfd"
				}
			},
			invalid: {
				iconColor: "#ffc7ee",
				color: "#ffc7ee"
			}
		}
	};
	// const handleAddCard = (data) => {
	//     console.log({data})
	//     dispatch(addCard(data));
	// }
	const handleAddCard = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make  sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card, {
			name: name, address_line1: 'Address 1', address_line2: 'Address 2',
			address_city: 'San Fransisco', address_state: 'Calefonia', address_zip: '94103', address_country: 'US'
		});

		if (result.error) {
			// Show error to your customer.
			setStripeErrorMessage(result.error.message)
		} else {
			// Send the token to your server.
			// This function does not exist yet; we will define it in the next step.
			//   stripeTokenHandler(result.token);
			dispatch(addCard({ cardToken: result.token.id }, successAddCard, faliureAddCard));
		}
	};
	const successAddCard = () => {
		setCDCForm(false)
		dispatch(getCards())
	}
	const faliureAddCard = () => { }
	return (
		<>
			<Row gutter={[0, { xs: 14, sm: 14, md: 5, lg: 5, xl: 5, xxl: 5 }]} className="paymentCard">
				<Col span={24}></Col>
				<Col span={24} className="checkoutPaymentParent">
					<Card className='shoppingCartCard'>
						<Row justify='space-between' className="paymentsTypeTitle">
							<Col>
								<Radio className='paymentsRadio' defaultChecked={1}>
									<span className="paymentType">Debit/Credit card</span>
								</Radio>
							</Col>
						</Row>
						{/* <Divider /> */}
						<form onSubmit={(e) => handleSubmit(handleAddCard(e))} className="checkoutstripeForm">
							<Row gutter={[0, 16]}>
								<Col span={24}></Col>
								<Col span={24}>
									{/* <Row>
                                    <Text className='brand-name'>Name</Text>
                                </Row> */}
									<Controller as={Input} name="name" control={control} placeholder="Name" required />
									{errors.name && (
										<span style={{ color: "red" }}>
											Name is required
										</span>
									)}
								</Col>
								<Col style={{ width: isXS ? innerWidth - 85 : "30em" }}>
									<CardElement />
								</Col>
							</Row>

							{stripeErrorMessage && stripeErrorMessage.length > 0 &&
								<Row justify='center' gutter={[0, 20]}>
									<Col span={24}></Col>
									<Col>
										<Alert
											message={stripeErrorMessage}
											type="error"
										/>
									</Col>
								</Row>
							}
							<Row justify='end' gutter={[5, 15]}>
								<Col span={24}></Col>
								<Col span={24}></Col>
								<Col>
									<Button type='text' onClick={() => setCDCForm(false)}>
										Cancel
									</Button>
								</Col>
								<Col>
									<Spin style={{ color: '#FFFFFF' }} indicator={antIcon} spinning={isAddCardLoading}>
										<Button htmlType='submit' className='saveCardButton'>
											Save Card
										</Button>
									</Spin>
								</Col>
							</Row>
						</form>
					</Card>
				</Col>
			</Row>
		</>
	);
};
export default SavedCardForm;
