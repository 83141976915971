import API from "../Utils/API";

export const getAllCategories = (data) => (dispatch) => {
  dispatch({ type: "GET_CATEGORIES_REQUEST" });
  API.get("/api/v2/categories")
    .then((response) => {
      dispatch({ type: "GET_CATEGORIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CATEGORIES_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
export const getAllSubSizes = (data) => (dispatch) => {
  dispatch({ type: "GET_SUBSIZES_REQUEST" });
  API.get("/api/v5/markid-products/filters/sizes")
    .then((response) => {
      const sizelist = [];
      // const completeData = [...response.data[0], ...response.data[1]]
      const withoutCategory = response.data.map(
        ({ category, age, ...rest }) => {
          return { size: age, ...rest };
        }
      );

      withoutCategory.forEach((item) => {
        sizelist.push(item.size);
      });
      function groupBy(arr, property) {
        return arr.reduce(function (memo, x) {
          if (!memo[x[property]]) {
            memo[x[property]] = [];
          }
          memo[x[property]].push(x);
          return memo;
        }, {});
      }

      const key = "size";
      const unique = [
        ...new Map(withoutCategory.map((item) => [item[key], item])).values(),
      ];

       const result = groupBy(unique, "order");
      // const result1 = groupBy(unique, "subCategory");
      dispatch({
        type: "GET_SUBSIZES_SUCCESS",
        payload: { result: result, sizelist: sizelist },
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_SUBSIZES_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getCategoryById = (data) => (dispatch) => {
  dispatch({ type: "GET_CATEGORY_BY_ID_REQUEST" });
  API.get(`/api/v2/categories/${data}`)
    .then((response) => {
      dispatch({ type: "GET_CATEGORY_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CATEGORY_BY_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getAllRecentlySold = () => (dispatch) => {
  dispatch({ type: "GET_ALL_RECENTLY_SOLD" });
  API.get(`api/v5/markid-products/search?isSold=true`)
    .then((response) => {
      dispatch({ type: "GET_ALL_RECENTLY_SOLD_SUCCESS", payload: response });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_ALL_RECENTLY_SOLD_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getRecentlyAddedProducts = () => (dispatch) => {
  dispatch({ type: "GET_RECENTLY_ADDED_PRODUCTS" });
  API.get(`/api/v5/markid-products/homepage?pageCount=5`)
    .then((response) => {
      dispatch({
        type: "GET_RECENTLY_ADDED_PRODUCTS_SUCCESS",
        payload: response,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_RECENTLY_ADDED_PRODUCTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};

export const getRecentlySoldProducts = () => (dispatch) => {
  dispatch({ type: "GET_RECENTLY_SOLD_PRODUCTS" });
  API.get(`/api/v5/markid-products/homepage?isSold=true&pageCount=5`)
    .then((response) => {
      dispatch({
        type: "GET_RECENTLY_SOLD_PRODUCTS_SUCCESS",
        payload: response,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_RECENTLY_SOLD_PRODUCTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
export const getverifiedProducts = (category) => (dispatch) => {
  if (category === "productType=true") {
    dispatch({ type: "GET_VERIFIED_PRODUCTS_REQUEST" });
  }
  API.get(`/api/v5/markid-products/homepage?${category}&pageCount=5`)
    .then((response) => {
      //dispatch({ type: "GET_CATEGORIES_PRODUCTS_SUCCESS", payload: response.data });
      if (category === "productType=true") {
        dispatch({ type: "GET_VERIFIED_SUCCESS", payload: response });
      }
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CATEGORIES_PRODUCTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
export const getCategoryProducts = (category) => (dispatch) => {
  if (category === "cribs_and_furniture") {
    dispatch({ type: "GET_CRIBS_PRODUCTS_REQUEST" });
  }

  if (category === "strollers") {
    dispatch({ type: "GET_STROLLERS_PRODUCTS_REQUEST" });
  }
  if (category === "gear") {
    dispatch({ type: "GET_GEAR_PRODUCTS_REQUEST" });
  }
  if (category === "clothing_and_accessories") {
    dispatch({ type: "GET_CLOTHING_PRODUCTS_REQUEST" });
  }
  if (category === "shoes") {
    dispatch({ type: "GET_SHOES_PRODUCTS_REQUEST" });
  }
  if (category === "toys_and_costumes") {
    dispatch({ type: "GET_TOYS_PRODUCTS_REQUEST" });
  }
  if (category === "feeding") {
    dispatch({ type: "GET_FEEDING_PRODUCTS_REQUEST" });
  }
  if (category === "diapering") {
    dispatch({ type: "GET_DIAPERING_PRODUCTS_REQUEST" });
  }
  if (category === "bathing") {
    dispatch({ type: "GET_BATHING_PRODUCTS_REQUEST" });
  }
  if (category === "sports_bicycles") {
    dispatch({ type: "GET_SPORTS_PRODUCTS_REQUEST" });
  }
  if (category === "books") {
    dispatch({ type: "GET_BOOKS_PRODUCTS_REQUEST" });
  }
  if (category === "safety_equipment") {
    dispatch({ type: "GET_SAFETY_PRODUCTS_REQUEST" });
  }
  if (category === "maternity") {
    dispatch({ type: "GET_MATERNITY_PRODUCTS_REQUEST" });
  }
  if (category === "electronics") {
    dispatch({ type: "GET_ELECTRONICS_PRODUCTS_REQUEST" });
  }

  API.get(`/api/v5/markid-products/homepage?category=${category}&pageCount=5`)
    .then((response) => {
      //dispatch({ type: "GET_CATEGORIES_PRODUCTS_SUCCESS", payload: response.data });
      if (category === "cribs_and_furniture") {
        dispatch({ type: "GET_CRIBS_SUCCESS", payload: response });
      }
      if (category === "strollers") {
        dispatch({ type: "GET_STROLLERS_SUCCESS", payload: response });
      }
      if (category === "gear") {
        dispatch({ type: "GET_GEAR_SUCCESS", payload: response });
      }
      if (category === "productType=true") {
        dispatch({ type: "GET_VERIFIED_SUCCESS", payload: response });
      }
      if (category === "clothing_and_accessories") {
        dispatch({ type: "GET_CLOTHING_SUCCESS", payload: response });
      }
      if (category === "shoes") {
        dispatch({ type: "GET_SHOES_SUCCESS", payload: response });
      }
      if (category === "toys_and_costumes") {
        dispatch({ type: "GET_TOYS_SUCCESS", payload: response });
      }
      if (category === "feeding") {
        dispatch({ type: "GET_FEEDING_SUCCESS", payload: response });
      }
      if (category === "diapering") {
        dispatch({ type: "GET_DIAPERING_SUCCESS", payload: response });
      }
      if (category === "bathing") {
        dispatch({ type: "GET_BATHING_SUCCESS", payload: response });
      }
      if (category === "sports_bicycles") {
        dispatch({ type: "GET_SPORTS_SUCCESS", payload: response });
      }
      if (category === "books") {
        dispatch({ type: "GET_BOOKS_SUCCESS", payload: response });
      }
      if (category === "safety_equipment") {
        dispatch({ type: "GET_SAFETY_SUCCESS", payload: response });
      }
      if (category === "maternity") {
        dispatch({ type: "GET_MATERNITY_SUCCESS", payload: response });
      }
      if (category === "electronics") {
        dispatch({ type: "GET_ELECTRONICS_SUCCESS", payload: response });
      }
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CATEGORIES_PRODUCTS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
};
