import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Card,
  Typography,
  Input,
  Image,
  Avatar,
  Spin,
  Upload,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../App.css";
import editIcon from "../assets/edit-Icon.svg";
// import ProfileImage from "../assets/ProfileImage.svg";
import { updateUser, uploadUserPhoto } from "../Actions/userAction";
import SaqureBlankImg from "../assets/SaqureBlankImg.jpeg";
import { useBreakpoints } from './../react-responsive-hooks/index';

const ProfileForm = (props) => {
  const { UserDetail } = props;
  const { photo, fullName, phone, email } = UserDetail;
  const dispatch = useDispatch();
  const { isXS } = useBreakpoints();
  const [editUserForm, setEditUserForm] = useState(false);
  const [newphoto, setNewPhoto] = useState({});
  const [fileList, setFileList] = useState("");
  const [updatedData, setupdatedData] = useState({});
  // const { isXS, isSM } = useBreakpoints();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { isUpdateUserLoading, currentUser } = useSelector((state) => {
    const { isUpdateUserLoading, currentUser } = state.user;
    return {
      isUpdateUserLoading,
      currentUser,
    };
  });
  const handleEditUserForm = () => {
    setEditUserForm(true);
  };
  const { handleSubmit, control, formState } = useForm({
    mode: "all",
  });
  const handleUpdateUserDetail = (data) => {
    data.photo = currentUser.photo;
    setupdatedData(data);
    dispatch(
      updateUser(data, successCallBackUpdateUser, failureCallUpdateUser)
    );
    dispatch(
      uploadUserPhoto(
        { file: fileList, fileName: fileList.name },
        successCallBackUpdateImage,
        failureCallUpdateUser
      )
    );
  };
  const successCallBackUpdateImage = (data) => {
    const { url } = data;
    setNewPhoto({ url: url });
    updatedData.photo = url;
    dispatch(
      updateUser(updatedData, successCallBackUpdateUser, failureCallUpdateUser)
    );
    
  };

  const successCallBackUpdateUser = () => {
    setEditUserForm(false);
  };
  const failureCallUpdateUser = () => {};

  const handleUpload = () => {
    
    dispatch(uploadUserPhoto({ file: fileList, fileName: fileList.name }));

  };

  useEffect(()=>{
    
    if(fileList)
    {
      setNewPhoto(URL.createObjectURL(fileList));
    }
      
  },[fileList]);

  useEffect(()=>{
    
    if(photo)
    setNewPhoto(photo);
     
  },[photo]);




  const propsForUpload = {
    action: handleUpload,
    // onChange: failureCallUpdateUser,
    onRemove: (file) => {
      setFileList("");
      setNewPhoto(photo);
    },
    beforeUpload: (file) => {
      setFileList(file);
      photo = null;
     
      return false;
    },
    multiple: false,
  };
  const { isValid } = formState;
  return (
    <Spin
      style={{ top: 200, color: "#FF533F" }}
      indicator={antIcon}
      spinning={isUpdateUserLoading}
    >
      <Row gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 }]}>
        <Col span={24} />
        <Col span={24}>
          <Card bordered={false} className="profileFormCard">
            <Row justify="space-between" align="middle" gutter={[0, 0]}>
              <Col span={24}></Col>
              <Col span={editUserForm ? 10 : 10}>
                {editUserForm ? (
                  <Typography className="profileDetail">
                    Edit Profile
                  </Typography>
                ) : (
                  <Typography className="profileDetail">Profile</Typography>
                )}
              </Col>
              {!editUserForm && (
                <Col className="editOption" style={{ textAlign: "end" }}>
                  <Image preview={false} src={editIcon} className="imgEdit" />
                  <Button
                    className="buttonEdit"
                    onClick={() => handleEditUserForm()}
                  >
                    Edit
                  </Button>
                </Col>
              )}
            </Row>
            <Row
              gutter={[0, { xs: 35, sm: 35, md: 30, lg: 30, xl: 30, xxl: 30 }]}
              justify="space-between"
              className="profileFormBottom"
            >
              <Col span={24} />
              <Col className="profileForm-img">
                <Row>
                  <Col span={24} />
                  <Col xl={24} lg={24} md={24} sm={22} xs={22}>
                    {/* size={120} */}
                    {photo ? (
                      <Avatar
                        shape="square"
                        src={newphoto ? newphoto : URL.createObjectURL(fileList)}
                        className="profile-avatar"
                      />
                    ) : (
                      <Avatar
                        shape="square"
                        src={fileList.name ? fileList.name : SaqureBlankImg}
                        className="profile-avatar"
                      />
                    )}
                    {/* <Image preview={false} src={ProfileImage} className="profile-image2" /> */}
                    {editUserForm && (
                      <Row className="uploadImg" justify="center">
                        <Upload {...propsForUpload}>
                          {!fileList && (
                            <Button type="link" className="selectPhotoButton">
                              Select Profile Picture
                            </Button>
                          )}
                        </Upload>
                        {fileList && (
                          <Button
                            onClick={() => handleUpload()}
                            style={{ color: "#FF533F" }}
                            type="link"
                            className="btn-upload"
                          >
                            Upload
                          </Button>
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className="profileForm-details">
                <form onSubmit={handleSubmit(handleUpdateUserDetail)}>
                  <Row gutter={[16, 0]}>
                    <Col span={22}>
                      <Row>
                        <Col span={24} />
                        <Typography className="profileName">Name</Typography>
                      </Row>
                    </Col>
                    {/* <Space direction="vertical" className="create_client_field"> */}
                    {/* <Controller as={Input} name="client[name]" control={control} placeholder="name"/>
                                                {errors.name && (
                                        <span className="error_style">Client Name is required</span>
                                    )} */}
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Row
                        gutter={[
                          0,
                          { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 },
                        ]}
                      >
                        <Col span={24} />
                        <Col span={24}>
                          {editUserForm ? (
                            <Controller
                              as={Input}
                              defaultValue={fullName}
                              name="fullName"
                              placeholder="Type your name"
                              control={control}
                              size="large"
                              className="profileInput"
                            />
                          ) : (
                            <Typography className="profileNameDetail">
                              {fullName}
                            </Typography>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {/* </Space> */}
                  </Row>
                  {/* <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Typography className="profileName">Gender</Typography>
                            </Col>
                            <Col xl={14} lg={14} md={14} sm={24} xs={24}>

                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={1} className="profileGender">Boy</Radio>
                                    <Radio value={2} className="profileGender">Girl</Radio>
                                    <Radio value={3} className="profileGender">Neutral</Radio>
                                </Radio.Group>
                            </Col></Row> */}

                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      {/* <Space direction="vertical" className="create_client_field"> */}
                      <Row
                        gutter={[
                          0,
                          { xs: 35, sm: 35, md: 25, lg: 25, xl: 25, xxl: 25 },
                        ]}
                      >
                        <Col span={24} />
                        <Typography className="profileName">
                          Phone Number
                        </Typography>
                      </Row>
                    </Col>
                    {/* <Controller
                                        as={Input}
                                        name="client[mobile_number]"
                                        type="number"
                                        control={control}
                                        placeholder="Phone Number"
                                    />
                                    {errors.priority && (
                                        <span className="error_style">Phone Number is required</span>
                                    )} */}
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Row
                        gutter={[
                          0,
                          { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 },
                        ]}
                      >
                        <Col span={24} />
                        <Col span={24}>
                          {/* <Controller
                              as={<Input addonBefore="+1" bordered={false} className="phoneInput" size='large' />}
                              defaultValue={phone.substring(1)}
                              name="phone"
                              control={control}
                              placeholder="Phone number"
                            /> */}

                          <Typography className="profileNameDetail">
                            +{phone}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* </Space> */}
                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      {/* <Space direction="vertical" className="create_client_field"> */}
                      <Row
                        gutter={[
                          0,
                          { xs: 35, sm: 35, md: 25, lg: 25, xl: 25, xxl: 25 },
                        ]}
                      >
                        <Col span={24} />
                        <Typography className="profileName">
                          Email-Id
                        </Typography>
                      </Row>
                    </Col>
                    {/* <Controller
                                        as={Input}
                                        name="client[email]"
                                        control={control}
                                        placeholder="Email-Id"
                                    />
                                    {errors.priority && (
                                        <span className="error_style">Email-Id is required</span>
                                    )} */}
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Row
                        gutter={[
                          0,
                          { xs: 15, sm: 15, md: 12, lg: 12, xl: 12, xxl: 12 },
                        ]}
                      >
                        <Col span={24} />
                        <Col span={24}>
                          {editUserForm ? (
                            <Controller
                              as={Input}
                              defaultValue={email}
                              name="email"
                              placeholder="Type your name"
                              control={control}
                              size="large"
                              className="profileInput"
                            />
                          ) : (
                            <Typography className="profileNameDetail">
                              {email}
                            </Typography>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {editUserForm && (
                    <Row
                      justify={isXS && "center"}
                      gutter={[
                        0,
                        { xs: 40, sm: 40, md: 55, lg: 55, xl: 55, xxl: 55 },
                      ]}
                    >
                      <Col span={24}></Col>
                      <Col>
                        <Button
                          htmlType="submit"
                          className="button button-primary saveChanges"
                          size="large"
                        >
                          Save Changes
                        </Button>
                      </Col>
                    </Row>
                  )}
                </form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
export default ProfileForm;