import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Typography, Row, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  addToCart,
  getLastCart,
  saveToLocalStorage,
} from "../Actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { getMarkidProductById } from "../Actions/partnerProductAction";
import { getProductType } from "../Actions/productType";
import { useGaEvent } from "../hooks/useGaEvent";
import tickCart from "../assets/tickCart.svg";

const { Meta } = Card;

const HomepageProductCard = ({ obj, isPartner }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { isAuthenticated, productTypeDetails, currentCartIds, currentUser } =
    useSelector((state) => {
      const { isAuthenticated } = state.auth;
      const { productTypeDetails } = state.productTypeDetails;
      const { currentUser } = state.user;
      const { currentCartIds } = state.cart;
      return {
        isAuthenticated,
        productTypeDetails,
        currentUser,
        currentCartIds,
      };
    });

  const redirectCheck = (id) => {
    dispatch({ type: "CURRENT_PLP_ITEM", payload: { item: obj } });
    dispatch(
      getProductType(id, successRedirectCallBack, failureRedirectCallBack)
    );
  };
  const successRedirectCallBack = () => {
    setRedirect(true);
  };
  const failureRedirectCallBack = () => {
    setRedirect(false);
  };
  const { gaPageView, gaEventClick } = useGaEvent();

  useEffect(() => {
    if (productTypeDetails && redirect) {
      {
        productTypeDetails.productType != "partnerToPerson"
          ? history.push(`/product-list/${obj.offerId}`)
          : history.push(
              `/partner-product/${obj.name}/${productTypeDetails.offerId}`
            );
      }
    }
  }, [productTypeDetails, redirect]);

  // useEffect(()=>{

  //   if(fetchProduct && Object.keys(productDetails).length>0){   //
  //     //dispatch(getMarkidProductById(obj._id));
  //     history.push(`partner-product/${obj.name}/${productDetails.offerId}`);
  //     setFetchProduct(false);
  //   }

  // }, [productDetails])

  // const productOnclick = ()=>{
  //   if (isPartner) {
  //     setFetchProduct(true);
  //     dispatch(getMarkidProductById(obj._id));

  //   } else {

  //     history.push(`/product-list/${obj.name}/${obj.offerId}`);
  //   }
  // }

  const manageCartItemAddition = (item) => {
    gaEventClick("Homepage", "Add to Cart", item?.name);
    const lotsArr = currentCartIds ? [...currentCartIds] : [];

    lotsArr.push(item._id);
    const data = {};
    data.cartId = currentUser ? currentUser.cartId : "";
    data.lots = lotsArr;
    data.addressId = null; //isAuthenticated? currentUser.addresses[0]._id: null
    data.email = isAuthenticated ? currentUser.email : "";

    if (!currentCartIds?.includes(obj._id)) {
      if (isAuthenticated) {
        dispatch(
          addToCart(
            data,
            isAuthenticated,
            successMarkidCallBack,
            failureMarkidCallBack
          )
        );
      } else {
        dispatch(saveToLocalStorage(item));
      }
    }

    dispatch(
      addToCart(
        data,
        isAuthenticated,
        successMarkidCallBack,
        failureMarkidCallBack
      )
    );
  };
  const successMarkidCallBack = () => {
    dispatch(getLastCart(currentUser._id));
  };
  const failureMarkidCallBack = () => {};

  return (
    <Card
      className="productCard"
      style={{ cursor: "pointer" }}
      cover={
        <img
          className="productImg"
          alt="example"
          //src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          onClick={() => {
            gaEventClick("browse", obj?.category, obj?.name);
            redirectCheck(obj._id);
          }}
          src={
            isPartner
              ? `https://images.markid.io/smartcrop?width=600&height=600&quality=80&type=webp&url=${obj.imageUrls?.[0]}`
              : `https://images.markid.io/smartcrop?width=600&height=600&quality=80&type=webp&url=${obj.imageUrls?.[0]}`
          }
        />
      }
      onMouseOver={() => {
        if (isPartner) {
          dispatch(getMarkidProductById(obj._id));
        }
      }}
      // onClick={() => {
      //   gaEventClick("browse", obj?.category, obj?.name);
      //   redirectCheck(obj._id);
      // }}
    >
      <div
        className="productcardpadding"
        onClick={() => {
          gaEventClick("browse", obj?.category, obj?.name);
          redirectCheck(obj._id);
        }}
      >
        {obj.isSold && <div className="sold-tag text-uppercase">Sold</div>}
        <h4 className="itemTitle">{obj.name}</h4>
        {/* <p className='itemPrice'>${obj.price}</p> */}
        {!Number.isInteger(obj.price / 100) ? (
          <p className="itemPrice">${(obj.price / 100).toFixed(2)}</p>
        ) : (
          <p className="itemPrice">${Math.trunc(obj.price / 100)}</p>
        )}
        {obj.size ? (
          <Typography className="productSize">
            {" "}
            Size: {capitalize(obj.size)}
          </Typography>
        ) : (
          <div className="productSize">Size: N/A</div>
        )}

        {obj.productType == "personToPerson" &&
          (obj.isFree === true ? (
            <Typography className="productSize"> Free shipping</Typography>
          ) : (
            <Typography className="productSize"> + shipping fee</Typography>
          ))}
        {obj.productType == "partnerToPerson" &&
          (obj.price > 5000 ? (
            <Typography className="productSize"> Free shipping</Typography>
          ) : (
            <Typography className="productSize"> + shipping fee</Typography>
          ))}

        {/* {obj.deliveryType === "meetup" && (
        <div className="shippingMethod local">Local Pickup</div>
      )} */}
        {/* {obj.deliveryType === "both" && (
        <div className="shippingMethod toship both">
          Shipping or Local Pick Up
        </div>
      )} */}
        {/* {obj.deliveryType === "both" && (
        <div className="shippingMethodParent">
          <div className="shippingMethod toship">Shipping</div>
        
        </div>
      )}
      {obj.deliveryType !== "both"&&obj.deliveryType !== "meetup"&&obj.deliveryType !== "shipping"&&  (<div className="ship"></div>)} */}
        {/* <div className="shippingMethod local">{obj.deliveryType}</div> */}

        {/* <Meta
      // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
      title="Card title"
      description="This is the description"
      price="150"
    /> */}
      </div>
      {obj.createdBy !== currentUser._id && (
        <Row justify="center">
          <Button
            onClick={() => manageCartItemAddition(obj)}
            className={"pLCartButtonrevised"}
          >
            {currentCartIds?.includes(obj._id) ? (
              <p style={{ color: "#4FAFE4" }}>
                In your cart
                <span>
                  &nbsp;
                  <img src={tickCart} />
                </span>
              </p>
            ) : (
              "Add to cart"
            )}
          </Button>
        </Row>
      )}
    </Card>
  );
};

export default HomepageProductCard;
