import API from '../Utils/API';

export const getFiltersToDisplay = (category) => dispatch => {
    let url = '/api/v5/markid-products/filters/display'
    if(category){
      url = `/api/v5/markid-products/filters/display?category=${category}`
    }
    dispatch({ type: "GET_FILTER_TO_DISPLAY_REQUEST" })
    API.get(url)
        .then(response => {
            dispatch({ type: "GET_FILTER_TO_DISPLAY_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "GET_FILTER_TO_DISPLAY_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const getSizeFilter = (category) => dispatch => {
    dispatch({ type: "GET_SIZE_FILTER_REQUEST" })
    API.get(`/api/v5/markid-products/filters/sizes?category=${category}`)
        .then(response => {
            dispatch({ type: "GET_SIZE_FILTER_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "GET_SIZE_FILTER_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}

export const getAgeFilter = (category) => dispatch => {
    dispatch({ type: "GET_AGE_FILTER_REQUEST" })
    API.get(`/api/v5/markid-products/filters/ages?category=${category}`)
        .then(response => {
            dispatch({ type: "GET_AGE_FILTER_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
                dispatch({
                    type: "GET_AGE_FILTER_FAILURE",
                    payload: error.response.data,
                });
                setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
                // console.log(error);
            }
        });
}