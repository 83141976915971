/*eslint-disable*/
import React, { useState } from "react";
import { Row, Col, Card, AutoComplete, Input, Typography, Button } from "antd";
import { useHistory } from "react-router-dom";
import "../App.css";
import BrandOne from "../assets/BrandOne.svg";
import BrandTwo from "../assets/BrandTwo.svg";
import BrandThree from "../assets/BrandThree.svg";
import BrandFour from "../assets/BrandFour.svg";
import BrandFive from "../assets/BrandFive.svg";
import BrandSix from "../assets/BrandSix.svg";
import BrandSeven from "../assets/BrandSeven.svg";
import BrandEaight from "../assets/BrandEaight.svg";
import BrandNine from "../assets/BrandNine.svg";
import BrandTen from "../assets/BrandTen.svg";
import Carousel, { consts } from "react-elastic-carousel";


import Boden from "../assets/Brand_1.png";
import Patagonia from "../assets/Brand_2.png";
import Hanna from "../assets/Brand_3.png";
import logo2 from "../assets/Brand_4.png";
import Moms from "../assets/Brand_5.png";
import Frug from "../assets/Brand_6.png";
import UppAbaby from "../assets/Brand_7.png";
import BABYBJORN from "../assets/Brand_8.png";
import Kyte from "../assets/Brand_9.png";
import buoabo from "../assets/new-assets/brands/buoabo.png";
import doona from "../assets/new-assets/brands/doona.png";
import STOKKE from "../assets/new-assets/brands/STOKKE.png";


import { setFilterCondition } from "../Actions/brandsAction";
import { useDispatch } from "react-redux";
import { useGaEvent } from "../hooks/useGaEvent";


const { gaPageView, gaEventClick } = useGaEvent();
const BrandComponent = ({ BrandImg, brandVal, brandId }) => {
  const history = useHistory();
  const handleBrandVal = () => {
    history.push(`/product-listing/brand/${brandVal}/${brandId}`)
  }
  return (
    <>
      <li>
        <img alt="" src={BrandImg} onClick={() => handleBrandVal()} style={{ width: 100, height: 100, cursor: 'pointer' }}  />
      </li>
      
      
        {/* <Col onClick={() => handleBrandVal()} style={{ cursor: 'pointer' }}>
          <div className='brandbackground'>
            <Row justify='center'>
              <Col>
                <img alt="" src={BrandImg} style={{ width: 100, height: 100 }} />
              </Col>
            </Row>
          </div>
        </Col> */}
    

      {/* <div className='brandBlogMob'>
        <div onClick={() => handleBrandVal()} style={{ cursor: 'pointer' }}>
          <div className='brandbackground'>
            <img alt="" src={BrandImg} style={{ width: 100, height: 100 }} />
          </div>
        </div>
      </div> */}
    </>
  )
}
const ShopBrand = (props) => {
  const { brandsAll } = props;
  const [seeItems, setSeeItems] = useState(6);
  const [seeAll, setSeeAll] = useState(true);

  const handleShowBrands = () => {
    setSeeAll(false)
    setSeeItems(brandsAll.length)
  }
  const handleLessBrands = () => {
    setSeeAll(true)
    setSeeItems(6)
  }
const History=useHistory()
const dispatch=useDispatch()
  return (
    <>
    {/* brandBlogWeb */}
      <div className='LogoWrapper'>
        <ul>
        {/* {brandsAll && brandsAll.map((val, i) => ( 
                    i < seeItems &&
                    <BrandComponent
                      BrandImg={val.imageUrl}
                      key={i}
                      brandVal={val.brand}
                      brandId={val._id}
                    />
        ))} */}

              <li  onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "Boden");
                History.push(`/search-brand/Boden`);
                    
                  }}><img src={Boden} alt="Boden"/></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "Patagonia");
                    History.push(`/search-brand/Patagonia`);
                    
                  }}><img src={Patagonia} alt="Patagonia" /></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "Hanna Andersson");
                    History.push(`/search-brand/Hanna Andersson`);
                    
                  }}><img src={Hanna} alt="Hanna" /></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "Lilly Pulitzer");
                    History.push(`/search-brand/Lilly Pulitzer`);
                    
                  }}><img src={logo2} alt="logo2" /></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "4moms");
                    History.push(`/search-brand/4moms`);
                    
                  }}><img src={Moms} alt="Moms" /></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "Frugi");
                    History.push(`/search-brand/Frugi`);
                    
                  }}><img src={Frug} alt="Frug" /></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "UPPAbaby");
                    History.push(`/search-brand/UPPAbaby`);
                    
                  }}><img src={UppAbaby} alt="UppAbaby" /></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "BabyBjorn");
                    History.push(`/search-brand/BabyBjorn`);
                    

                  }}><img src={BABYBJORN} alt="BABYBJORN" /></li>
              <li onClick={() => {
                dispatch(setFilterCondition("brand"));
                gaEventClick("Top Brands", "Kyte");
                    History.push(`/search-brand/Kyte`);
                    
                  }}><img src={Kyte} alt="Kyte" /></li>
              {/* <li><a href="#"><img src={buoabo} alt="buoabo" /></a></li>
              <li><a href="#"><img src={doona} alt="doona" /></a></li>
              <li><a href="#"><img src={STOKKE} alt="STOKKE" /></a></li> */}


        </ul>
      </div>

      {/* <div className='brandBlogMob'> </div> */}
    </>
  );
};
export default ShopBrand;
