import API from "../Utils/API";

export const getPartnerProductById = (Product_id) => (dispatch) => {
    dispatch({ type: "GET_PARTNER_PRODUCT_BY_ID_REQUEST" });
    API.get(`/api/v5/partner-products/${Product_id}`)
      .then((response) => {
        dispatch({ type: "GET_PARTNER_PRODUCT_BY_ID_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_PARTNER_PRODUCT_BY_ID_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };


  export const getMarkidProductById = (Product_id, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "GET_MARKID_PRODUCT_BY_ID_REQUEST" });
    API.get(`api/v5/markid-products/${Product_id}`)
      .then((response) => {
        dispatch({ type: "GET_MARKID_PRODUCT_BY_ID_SUCCESS", payload: response.data });
        successCallBack();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "GET_MARKID_PRODUCT_BY_ID_FAILURE",
            payload: error.response.data,
          });
          failureCallBack();
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        } catch {
          // console.log(error);
        }
      });
  };