
import constants from "../Utils/constants";

const initialState = {
  isAllpartnerProductsloading: false,
  isGetFilterpartnerProductsLoading: false,
  isMoreFromThisPartnerLoading: false,
  allproducts: {hits:[]},
  searchedproducts: [],
  partnerproducts: [],
  searchPageproducts: [],
 
  checkedValues: '',
  filterpartnerproducts: [],

  morePartnerProducts: []
};

const partnerProductlistReducer = (state = initialState, action) => {
   
  switch (action.type) {

      case constants.GET_ALL_PARTNERPRODUCTS_REQUEST:
      return {
        ...state,
        isAllpartnerProductsloading
        : true,
      };
    case constants.GET_ALL_PARTNERPRODUCTS_SUCCESS:
        const search = action.search
        let searchedArray = []
        if(search){
            const lotsObjects = action.payload.hits;
            lotsObjects.map((partnerproducts) => searchedArray.push({...partnerproducts, label: partnerproducts.title, value:partnerproducts ._id}))
          }
     
    //   partnerProductData.seller = action.payload.seller
      return {
        ...state,
        isAllpartnerProductsloading: false,
        allproducts: search ? state.allproducts : action.payload, searchedproducts: search ? searchedArray : [],
        partnerproducts: action.payload.hits
    
      }; 
    case constants.GET_ALL_PARTNERPRODUCTS_FAILURE:
      return {
        ...state,
        isAllpartnerProductsloading: false,
      };
      case constants.GET_FILTER_PARTNERPRODUCTS_REQUEST:
        return {
            ...state, isGetFilterpartnerProductsLoading: true
        }
    case constants.GET_FILTER_PARTNERPRODUCTS_SUCCESS:
        return {
            ...state, isGetFilterpartnerProductsLoading: false,totalProd:action.payload.nbHits,totalcount:action.payload.facets, filterpartnerproducts: action.payload.hits,partnerproducts:action.payload.hits,searchedproducts:action.payload.products
        }
    case constants.GET_FILTER_PARTNERPRODUCTS_FAILURE:
        return {
            ...state, isGetFilterpartnerProductsLoading: false
        }

      case constants.FILTER_PARTNERPRODUCTS:
        return {
          ...state, checkedValues: action.payload.hits
        }


        case constants.MORE_FROM_THIS_PARTNER_REQUEST:
			return {
				...state, isMoreFromThisPartnerLoading: true
			}
		case constants.MORE_FROM_THIS_PARTNER_SUCCESS:
			return {
				...state, isMoreFromThisPartnerLoading: false, morePartnerProducts: action.payload.morePartnerProducts
			}
		case constants.MORE_FROM_THIS_PARTNER_FAILURE:
			return {
				...state, isMoreFromThisPartnerLoading: false,
			}

    default:
      return state;
  }
};
export default partnerProductlistReducer;

  