/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Typography, Input, Button, Alert, Spin, Skeleton } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useBreakpoints } from './../../react-responsive-hooks/index';
import { useForm, Controller } from 'react-hook-form';
import MSImage from '../../assets/MSImage.png';
import ReactStoreBadges from 'react-store-badges';
import { getLotById } from './../../Actions/lotsAction';
import './MarkidMessages.css';
const MarkidMessages = (props) => {
  const { type } = props;
  /* props.type
    0: when rrdirected from message icon from homepage
    1: when rrdirected from make offer button from product detail page
  */
  const { isXS, isSM } = useBreakpoints();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    const { productId } = params;
    dispatch(getLotById(productId));
  }, [params]);
  const { lot, isGetLotByIdLoading, currentCart, currentUser } = useSelector((state) => {
    const { lot, isGetLotByIdLoading } = state.lots;
    const { currentCart } = state.cart;
    const { currentUser } = state.user;
    return {
      lot,
      isGetLotByIdLoading,
      currentCart,
      currentUser,
    };
  });
  const ProductId = lot._id;
  const productData = lot;
  const productLoader = isGetLotByIdLoading;
  const handleAddItemCart = (item) => {
    dispatch({ type: 'ADD_ITEM_CART', payload: { item: item } });
    // history.push("/markid-cart")
    history.push('/cart');
  };
  let CartId = '';
  {
    currentCart &&
      currentCart.map((current) => {
        current._id === ProductId ? (CartId = current._id) : null;
      });
  }
  const meetupType = productData?.deliveryType === 'meetup';
  const { notifyItems, _id: currentUserId } = currentUser;
  const forSellerUser = productData?.createdBy === currentUserId;
  return (
    <Card className="mBCardBg">
      <Row justify="center" gutter={[0, 37]}>
        <Col span={24}></Col>
        <Col xxl={18} xl={18} sm={22} xs={22}>
          <Card className="mBCard">
            <Row justify="center">
              <div className="mBImgBg">
                <Row justify="center">
                  <img alt="" src={MSImage} className="mBImage" />
                </Row>
              </div>
              {/* <img alt="" src={MarkidCommunityImg} /> */}
            </Row>
            {type === 0 && (
              <>
                <Row justify="center" gutter={[0, 24]}>
                  <Col span={24}></Col>
                  <Col sm={15} xs={15}>
                    <Typography className="mBTitle">Get the App</Typography>
                  </Col>
                </Row>
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col xl={12} lg={13} md={14} sm={18} xs={18}>
                    <Typography className="mBDes">Download the App to start messaging sellers.</Typography>
                  </Col>
                </Row>
                <Row gutter={[21, 62.29]} justify="center">
                  <Col span={24}></Col>
                  {/* <Col>
              <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                />
              </Col> */}
                  <Col>
                    <ReactStoreBadges platform={'ios'} url={'https://apps.apple.com/us/app/markid/id1436309782'} target="_" locale={'en-us'} />
                  </Col>
                </Row>
              </>
            )}
            {type === 1 && (
              <Row justify="center" gutter={[0, 24]}>
                <Col md={12} sm={24}>
                  <Row justify="center" gutter={[0, 24]}>
                    <Col span={24}></Col>
                    <Col sm={15} xs={15}>
                      <Typography className="mBTitle">Get the App</Typography>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={[0, 8]}>
                    <Col span={24}></Col>
                    <Col xl={12} lg={13} md={14} sm={18} xs={18}>
                      <Typography className="mBDes">Download the App to send and negotiate an offer.</Typography>
                    </Col>
                  </Row>
                  <Row gutter={[21, 62.29]} justify="center">
                    <Col span={24}></Col>
                    {/* <Col>
              <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                />
              </Col> */}
                    <Col>
                      <ReactStoreBadges platform={'ios'} url={'https://apps.apple.com/us/app/markid/id1436309782'} target="_" locale={'en-us'} />
                    </Col>
                  </Row>
                </Col>
                {!meetupType && !forSellerUser && (
                  <Col md={12} sm={24}>
                    <Row justify="center" gutter={[0, 24]}>
                      <Col span={24}></Col>
                      <Col sm={15} xs={15}>
                        <Typography className="mBTitle">Or buy now</Typography>
                      </Col>
                    </Row>
                    <Row justify="center" gutter={[0, 8]}>
                      <Col span={24}></Col>
                      <Col xl={12} lg={13} md={14} sm={18} xs={18}>
                        <Typography className="mBDes">Buy the product at listed price.</Typography>
                      </Col>
                    </Row>
                    <Row gutter={[21, 62.29]} justify="center">
                      <Col span={24}></Col>
                      {/* <Col>
              <ReactStoreBadges
                platform={'android'}
                url={'https://play.google.com/store/apps/details?id=com.markid'}
                target="_"
                locale={'en-us'}
                />
              </Col> */}

                      <Col>
                        {ProductId === CartId ? (
                          productLoader ? (
                            <Skeleton.Button active={productLoader} width={500} />
                          ) : (
                            <Button block className={'button button-primary pLCartButton'} onClick={() => history.push('/cart')}>
                              GO TO CART
                            </Button>
                          )
                        ) : productLoader ? (
                          <Skeleton.Button active={productLoader} />
                        ) : (
                          !meetupType &&
                          !forSellerUser && (
                            <Button block className={'button button-primary pLCartButton'} onClick={() => handleAddItemCart(productData)}>
                              ADD TO CART
                            </Button>
                          )
                        )}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
export default MarkidMessages;
