
import React ,{ useEffect }from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div class="container" id="i-container" className="content_wrapper">
      <Helmet>
        <title>Markid - Privacy Policy</title>
      </Helmet>
      <div className="headerlines">Privacy Policy</div>
      <p>
        Markid is a marketplace for parents to buy and sell kids preowned and
        new products from each other as well as from businesses, as offered on
        the “Markid” mobile app (together with any successor app(s), the “App”),
        and on our “Markid” website currently located at markid.io (together
        with any successor site(s), the “Site”). This Privacy Policy refers to
        the App and Site (together with any materials and services available
        therein) as the “Service”. This Privacy Policy explains how information
        about you is collected, used and disclosed by Markid, Inc. (hereafter
        “Markid”, “our,” “we,” or “us”). This Privacy Policy applies to
        information we collect when you use the Service. We reserve the right to
        change this Privacy Policy from time to time. If we make changes, we
        will notify you by revising the date at the top of the policy and, in
        some cases, we may provide you with more prominent notice (such as
        adding a statement to our homepage or sending you an email
        notification). We encourage you to review the Privacy Policy whenever
        you access the Service to stay informed about our information practices
        and the ways you can help protect your privacy.
      </p>
      <div className="header2">1. Information You Provide to Us.</div>
      <p>
        Markid collects information that you provide directly to us. For
        example, we collect information when you register or update the details
        of your account, post information in order to sell any good through the
        Service, enroll in any third-party operated payment processing services
        offered in connection with the Service, communicate with other users,
        provide reviews or other comments, purchase any services from Markid,
        request customer support or otherwise communicate with us. The types of
        information we may collect include your name, email address, Markid
        account password, telephone number, postal address including city, state
        and zip code, photographs, written descriptions of your posted goods,
        any bids you make on offers through the Service, any reviews or comments
        you make using the Service, all correspondence or communications with
        other users or with us conducted via the Service, government-issued
        identification documents (such as a driver’s license), and any other
        information you choose to provide.
      </p>
      <div className="header2">2. Information We Collect Automatically.</div>
      <p>
        When you access or use the Service, we automatically collect information
        about you, including: (a) Log Information: We log information about your
        use of the Service, including the type of browser you use, access times,
        pages viewed, your IP address and the page you visited before navigating
        to our Service. (b) Device Information: We collect information about the
        computer or mobile device you use to access the Service, including the
        hardware model, operating system and version, unique device identifiers
        and mobile network information. (c) Location Information: If you consent
        to the collection of location information from your computer or mobile
        device, we may collect this information when you access or use the
        MarkidService, or when you otherwise consent to the collection of this
        information. We may also use your IP address to infer an approximate
        geographic location for your computer or device. For more details,
        please see “Your Information Choices” below. (d) Information Collected
        by Tracking Technologies: We use various technologies to collect
        information, and this may include sending cookies to your computer or
        mobile device. Cookies are small data files stored on your hard drive or
        in device memory that helps us to improve the Service and your
        experience, see which areas and features of the Service are popular and
        count visits. We may also collect information using web beacons (also
        known as “gifs,” “pixel tags” and “tracking pixels”). Web beacons are
        electronic images that may be used in the Service or emails and help
        deliver cookies, count visits, understand usage and campaign
        effectiveness and determine whether an email has been opened and acted
        upon. For more information about cookies, and how to disable them,
        please see “Your Information Choices” below.
      </p>
      <div className="header2">3. Information from Third Parties.</div>
      <p>
        Markid may, from time to time, obtain information from other sources and
        combine that with information we collect through the Service in order to
        enhance our ability to serve you, prevent fraudulent transactions,
        tailor our content to you, offer you opportunities to purchase products
        or services that we believe may be of interest to you, and for any other
        purposes described in this Privacy Policy. For example, if you create or
        log into your account through a social media site, we will have access
        to certain information from that site, such as your name, account
        information and friends lists, in accordance with the authorization
        procedures determined by such social media site. We may also receive
        transaction information from Stripe Inc. (“Stripe”) when you purchase or
        sell a good using the Markid payment solution, including the date and
        time of sale and the amount of the sale. You may also access the Service
        by logging in using an authorized third party social network account,
        such as a Facebook or Amazon account. By doing so, you authorize us to
        collect information from that third party social network, such as your
        email address. Please note that if you opt to use a third party social
        network account to login to our App and/or Site, such as a Facebook or
        Amazon account, you are using the account subject to the third party
        social network’s own privacy policy.
      </p>
      <div className="header2">4. Payment Information.</div>
      <p>
        Markid is integrated with Stripe’s services to allow sellers to accept
        credit card and other electronic payment methods for goods they list for
        sale on the Service. Stripe provides these services directly to sellers
        pursuant to its own terms and privacy policy. When signing up to receive
        funds electronically, Stripe, and not Markid, collects some of the
        information provided by sellers via the Service, including debit card
        numbers, Social Security numbers, government IDs, and bank account
        information.
      </p>
      <div className="header2">5. Use of Information.</div>
      <p>
        (a) Enable you to access and use the Service, to personalize the
        Service, and to optimize the type of offers presented to you when you
        use the Service; (b) Create and maintain a trusted and safer environment
        on the Service, including through detection and prevention of fraud,
        unauthorized access, intrusion, and service interruption; (c) Conduct
        investigations and to respond to disputes between users, error
        resolution, and other similar customer support service; (d) Operate,
        protect, improve and optimize the Service, our business, and our users’
        experience; (e) Perform analytics, conduct research, and monitor and
        analyze trends, usage and activities in connection with the Service; (f)
        Communicate with you about products, services, offers, promotions,
        rewards, and events offered by Markid and others, and provide
        advertisements that match user profiles or interests, news and
        information we think will be of interest to you; (g) Send you service,
        support and administrative messages, reminders, technical notices,
        updates, security alerts, and information requested by you; (h) Comply
        with our legal obligations or requests from law enforcement agencies,
        resolve any disputes that we may have with any of our users, and enforce
        our agreements with third parties; (i) Link or combine with information
        we get from others to help understand your needs and provide you with
        better service; (j) Assist third-party payment processors with
        transaction processing, error, chargeback and disputed transaction
        resolution, and similar payment processing functions; and (k) Carry out
        any other purpose for which the information was collected. (l) Markid is
        based in the United States and the information we collect is governed by
        U.S. law. By accessing or using the Service or otherwise providing
        information to us, you consent to the processing and transfer of
        information in and to the U.S. and other countries, where you may not
        have the same rights and protections as you do under local law.
      </p>
      <div className="header2">6. Sharing of Information.</div>
      <p>
        We may share information about you as follows or as otherwise described
        in this Privacy Policy: (a) With the public on the Service. (b) When
        sellers register through the Service to receive electronic payments via
        Stripe’s services, we will share certain information about them with
        Stripe, including name, address, IP address and date of birth. This
        information is in addition to the information collected directly by
        Stripe (as described above in “Payment Information Collected by
        Stripe”). Stripe may use this information for the purposes described in
        its privacy policy, including for fraud prevention. (c) With vendors,
        consultants and other service providers who need access to such
        information to carry out work on our behalf (including, without
        limitation, with third-party service providers that may use the
        information we share to help us and other companies detect inaccurate
        personal information and prevent fraudulent transactions); (d) In
        response to a request for information if we believe disclosure is in
        accordance with any applicable law, regulation or legal process, or as
        otherwise required by any applicable law, rule or regulation; (e) If we
        believe your actions are inconsistent with the spirit or language of our
        user agreements or policies, or to protect the rights, property and
        safety of Markid or others; (f) In connection with, or during
        negotiations of, any merger, sale of company assets, financing or
        acquisition of all or a portion of our business to another company; (g)
        Between and among Markid, and its current and future parents,
        affiliates, subsidiaries, and other companies under common control and
        ownership; and (h) With your consent or at your direction, including if
        we notify you through the Service that the information you provide will
        be shared in a particular manner and you provide such information. We
        may also share aggregated or anonymized information, which cannot be
        used to identify you.
      </p>
      <div className="header2">7. Social Sharing Features. </div>
      <p>
        The Service may offer social sharing features and other integrated tools
        (such as the Facebook “Like” button), which let you share actions you
        take on the Service with other media, and vice versa. The use of such
        features enables the sharing of information with your friends or the
        public, depending on the settings you establish with the entity that
        provides the social sharing feature. For more information about the
        purpose and scope of data collection and processing in connection with
        social sharing features, please visit the privacy policies of the
        entities that provide those features.
      </p>
      <div className="header2">8. Advertising and Analytics Services.</div>
      <p>
        We may allow others to serve advertisements on our behalf across the
        Internet and to provide analytics services. These entities may use
        cookies, web beacons and other technologies to collect information about
        your use of the Service and other websites and online services,
        including your IP address, web browser, pages viewed, time spent on
        pages, links clicked and conversion information. This information may be
        used by Markid and others to, among other things, analyze and track
        data, determine the popularity of certain content, deliver advertising
        and content targeted to your interests on the Service and other websites
        and online services, and better understand your online activity.
      </p>
      <div className="header2">9. Security.</div>
      <p>
        Markid takes reasonable measures to help protect information about you
        from loss, theft, misuse and unauthorized access, disclosure, alteration
        and destruction.
      </p>
      <div className="header2">10. Account Information.</div>
      <p>
        You may update, correct or delete information about you at any time by
        logging in to your account. If you would like to cancel your Markid
        account entirely, please contact us, and enter your request for
        cancellation, but please note that we may retain certain information as
        required by law or for legitimate business purposes. For example, any
        reviews, forum postings and similar materials posted by you may continue
        to be publicly available on the Service in association with your account
        name even after your Markid account is cancelled. We may also retain
        cached or archived copies of information about you for a certain period
        of time.
      </p>
      <div className="header2">11. Location Information.</div>
      <p>
        When you first download or launch any of our mobile applications that
        collect location information or first use any features that use location
        information, you will be asked to consent to the application’s
        collection of this information. If you initially consent to our
        collection of location information, you can subsequently stop the
        collection of this information at any time by changing the preferences
        on your mobile device. You may also stop our collection of location
        information by following the standard uninstall process to remove all of
        our mobile applications form your device. Even if you do not provide us
        with consent to collect precise location information from your mobile
        device or platform, we may use your IP address to infer an approximation
        of your location when using certain features of the Services, such as
        the Markid payments solution.
      </p>
      <div className="header2">12. Cookies.</div>
      <p>
        Most web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove or reject browser
        cookies. Removing or rejecting browser cookies does not necessarily
        affect third party flash cookies used in connection with the Service.
      </p>
      <div className="header2">13. Promotional Communications.</div>
      <p>
        You may opt out of receiving promotional emails, text messages, and
        other communications from Markid by following the instructions in those
        emails, text messages or other communications or by logging on to your
        Markid account and going to Settings.
      </p>
      <div className="header2">14. Questions and Comments.</div>
      <p>
        If you’d like to provide feedback to us about this Privacy Policy, or if
        you have any questions, please contact us at <a  className="link" href = "mailto: abc@example.com">support@markid.io.</a>"
      </p>
    </div>
  );
};

export default PrivacyPolicy;
