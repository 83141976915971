/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Button,
  Pagination,
  Spin,
  Empty,
  Card,
  Modal,
  Affix,
} from "antd";
import { PCategoryCard } from "../components/partnerCategoryCard";
import { PFiltered } from "../components/partnerFiltered";
import { PFilteredItem } from "../components/partnerFilteredItem";
import { ProductCard } from "../components/partnerProductCard";
import { RelatedItem } from "../components/RelatedItem";
import {
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Magnifying from "../assets/Magnifying.svg";
import "../App.css";
import { PLBreadCrumb } from "../components/partnerBreadCrumb";
import { getUser } from "../Actions/userAction";
import { getBrandsByCategory } from "../Actions/brandsAction";
import PL1 from "../assets/PL1.svg";
import PL2 from "../assets/PL2.svg";
import PL3 from "../assets/PL3.svg";
import PL4 from "../assets/PL4.svg";
import { getFilterLots, getYearOfDetails } from "../Actions/lotsAction";
import { getCategoryById } from "../Actions/categoriesAction";
import { getBrandById } from "../Actions/brandsAction";
import { getAllBrands } from "../Actions/brandsAction";
import Carousel, { consts } from "react-elastic-carousel";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import FilterIcon from "../assets/FilterIcon.svg";
import { getHandPickedItem } from "../Actions/handPickedAction";
import PFilteredItemMob from "../components/partnerFilteredItemMob";
import ResultNotFound from "../components/SearchResultNotFound/partnerResultnotfound";
import PartnerListingBanner from "../components/PartnerListingBanner";
import RibbonTop from "../components/promotionPopUp";
import {
  getAllpartnerproducts,
  getFilterpartnerProducts,
} from "../Actions/partnerProductsAction";
import { Helmet } from "react-helmet";
import lang from "../Utils/metatags.json";
import { SellerCancelModal } from "../components/sellerCancelModal";
import { postInsights } from "stream-chat";
const PartnerProductList = () => {
  const { isXS, isSM } = useBreakpoints();
  const history = useHistory();

  const PlSimilarCardData = [
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL1}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL2}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL3}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL4}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL2}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL3}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL1}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL4}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL3}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL1}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL4}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL2}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
  ];
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  // const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  // const [showSignInDialog, setShowSignInDialog] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const paramsToken = new URLSearchParams(location.search);
  const token = paramsToken.get("token");
  const renewedSearch = paramsToken.get("renewed");
  const zipCode = paramsToken.get("zipCode");
  const {
    providerToken,
    showSignIn,
    allLots,
    allCategories,
    allproducts,
    lotsByCategory,
    filterpartnerproducts,
    isAllpartnerProductsloading,
    isGetAllLotsLoading,
    category,
    brand,
    brandsByCategory,
    filterLots,
    isGetFilterpartnerProductsLoading,
    checkedValues,
    totalProd,
    totalcount,
    isGetFilterLotsLoading,
    handPickedItem,
    partnerproducts,
  } = useSelector((state) => {
    const { providerToken } = state.auth;
    const {
      allLots,
      lotsByCategory,
      isGetAllLotsLoading,
      filterLots,
      isGetFilterLotsLoading,
    } = state.lots;
    const {
      allproducts,
      totalcount,
      filterpartnerproducts,
      checkedValues,
      isAllpartnerProductsloading,
      partnerproducts,
      isGetFilterpartnerProductsLoading,
      totalProd,
    } = state.products;

    const { allCategories, category } = state.categories;
    const { brand, brandsByCategory } = state.brands;
    const { handPickedItem } = state.handPicked;
    return {
      totalProd,

      partnerproducts,
      totalcount,
      filterpartnerproducts,
      brand,
      brandsByCategory,

      checkedValues,
      isGetFilterpartnerProductsLoading,

      handPickedItem,
      isAllpartnerProductsloading,
      allproducts,
    };
  });
  const [data, setdata] = useState();
  const [displaydata, setdisplaydata] = useState(false);
  const [showsortfilterDialog, setshowsortfilterDialog] = useState(false);
  const [showfilterDialog, setshowfilterDialog] = useState(false);
  const [resetclose, setresetclose] = useState(false);
  const { brand: brandName } = brand || {};
  const { handpicked } = handPickedItem || {};
  const { title: handPickedName } = handpicked || {};
  if (token) {
    localStorage.setItem("access_token", token);
    if (!providerToken) {
      dispatch({ type: "SET_PROVIDER_TOKEN", payload: { token } });
    }
  }
  const [selectedpricevalues, setSelectedpricevalues] = useState([]);
  const [selectedshippingarr, setSelectedshippingarr] = useState([]);

  useEffect(() => {
    setdisplaydata(true);
    const { innerWidth, innerHeight } = window;
    //const {itemType,itemName, id, renewedSearch} = params;
    //let url = `/api/v5/partner-products-search?category=${itemType === 'category' ? itemName : ''}&brands=${itemType === 'brand' ? itemName : ''}${renewedSearch !== null && renewedSearch !== undefined ? '&renewed='+renewedSearch : ''}${zipCode !== null && zipCode !== undefined ? '&distance=10&zipCode='+zipCode : ''}${search !== null && search !== undefined ? '&search=' + search : ''}`;
    let url = `/api/v5/markid-products/algolia-search?productType=true&category=${
      itemType === "category" ? itemName : ""
    }&brands=${itemType === "brand" ? itemName : ""}${
      renewedSearch !== null && renewedSearch !== undefined
        ? "&renewed=" + renewedSearch
        : ""
    }${
      zipCode !== null && zipCode !== undefined
        ? "&distance=10&zipCode=" + zipCode
        : ""
    }${search !== null && search !== undefined ? "&search=" + search : ""}`;
    if (itemName == "swoondle") {
      // dispatch(getAllpartnerproducts(itemName));
      // dispatch(getYearOfDetails({ category: itemName }));
      // dispatch(getBrandsByCategory(itemName));
      dispatch(getFilterpartnerProducts(null, url));
    } else if (itemName && id && itemType === "brand") {
      dispatch(getBrandById(id));
      //dispatch(getYearOfDetails({ brand: itemName }));
      dispatch(getFilterpartnerProducts(null, url));
    }
    //else if (itemName && id && itemType === 'handpicked') {
    //   // dispatch(getBrandById(id));
    //   // dispatch(getYearOfDetails({ name: Handpicked }));
    //   dispatch(getHandPickedItem(id));
    //}
    else if (search) {
      // dispatch(getAllBrands());
      //dispatch(getYearOfDetails());
      // dispatch(getFilterLots(null, `/api/v2/lots-searchable/?search=${search}`));
      dispatch(getFilterpartnerProducts(null, url));
    } else {
      // dispatch(getFilterLots(null, `/api/v2/lots/`));
      dispatch(getFilterpartnerProducts(null, url));
    }
  }, []);

  // const capitalize = (s) => {
  //   if (typeof s !== "string") return "";
  //   return s.charAt(0).toUpperCase() + s.slice(1);
  // };

 

  // const pages = Math.ceil(total / 18);
  //const showTotal = filterpartnerproducts.length;
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a className="pagyNext">prev</a>;
      //return originalElement;
    }
    if (type === "next") {
      return <a className="pagyNext">Next</a>;
    }
    return originalElement;
  }

  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  const { itemType, itemName, id, search } = params;
  const [lastUpdatedUrl, setLastUpdatedUrl] = useState(
    `/api/v5/markid-products/algolia-search?productType=true&category=${
      itemType === "category" ? itemName : ""
    }&brands=${itemType === "brand" ? itemName : ""}${
      renewedSearch !== null && renewedSearch !== undefined
        ? "&renewed=" + renewedSearch
        : ""
    }`
  );

  const [selectedBrand, setSelectedBrand] = useState([]);
  const [close, setClose] = useState(sessionStorage.getItem("pop") || "true");
  const [selectedGender, setSelectedGender] = useState([]);
  const [totalnew, settotalnew] = useState(totalProd);

  useEffect(() => {
    if (isXS||isSM) {
      if (displaydata === true) {
        setdata(filterpartnerproducts);
        settotalnew(totalProd);
      }
    } else {
      setdata(filterpartnerproducts);
      settotalnew(totalProd);
    }
  }, [filterpartnerproducts, displaydata]);

  const defaultQuery = {
    category: itemType === "category" ? itemName : "",
    brands: itemType === "brand" ? itemName : "",
    ...(search !== null && search !== undefined ? { search: search } : {}),
    ...(renewedSearch !== null ? { renewed: renewedSearch === "true" } : {}),
  };
  const [currentQuery, setCurrentQuery] = useState({ ...defaultQuery });

  const [selectedPurchaseYear, setSelectedPurchaseYear] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState({});
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState([
    { shipping: "Free Shipping", value: false },
    { shipping: "Shipping", value: false },
    { shipping: "Meetup", value: false },
  ]);

  const [sortByLots, setSortByLots] = useState("");
  const [renewed, setRenewed] = useState(
    renewedSearch !== null && renewedSearch !== undefined
      ? renewedSearch === "true"
      : false
  );
  const [filterMob, setFilterMob] = useState(false);
  // const [filterMLots, setFilterLots] = useState(lots)
  const updateSelectedPriceRannge = (range) => {
    const min = range[0];
    const max = range[1];
    const q = buildQuery({ ...currentQuery, price: { min, max } });
    dispatch(getFilterpartnerProducts(null, q));
    setSelectedPriceRange({ min, max });
    setCurrentQuery({ ...currentQuery, price: { min, max } });
  };
  const updateSelectedBrand = (brands) => {
    const isExists = _.includes(selectedBrand, brands);
    const sBrand = selectedBrand;
    if (isExists) {
      const removeIndex = selectedBrand.findIndex((item) => item === brands);
      sBrand.splice(removeIndex, 1);
    } else {
      sBrand.push(brands);
    }
    setSelectedBrand(sBrand);
    const q = buildQuery({ ...currentQuery, brands: selectedBrand });
    dispatch(getFilterpartnerProducts(null, q));
    setCurrentQuery({ ...currentQuery, brands: selectedBrand });
  };
  const updateSelectedGender = (genders) => {
    const isExists = _.includes(selectedGender, genders);
    const sGender = selectedGender;
    if (isExists) {
      const removeIndex = selectedGender.findIndex((item) => item === genders);
      sGender.splice(removeIndex, 1);
    } else {
      sGender.push(genders);
    }
    setSelectedGender(sGender);
    const q = buildQuery({ ...currentQuery, gender: selectedGender });
    dispatch(getFilterpartnerProducts(null, q));
    setCurrentQuery({ ...currentQuery, gender: selectedGender });
  };
  const updateSelectedSize = (Sizes) => {
    const isExists = _.includes(selectedSize, Sizes);
    const sSize = selectedSize;
    if (isExists) {
      const removeIndex = selectedSize.findIndex((item) => item === Sizes);
      sSize.splice(removeIndex, 1);
    } else {
      sSize.push(Sizes);
    }
    setSelectedSize(sSize);
    const q = buildQuery({ ...currentQuery, size: selectedSize });
    dispatch(getFilterpartnerProducts(null, q));
    setCurrentQuery({ ...currentQuery, size: selectedSize });
  };
  const updateSelectedPurchaseYear = (purchaseYear) => {
    const { yearOfPurchase } = currentQuery;
    if (purchaseYear === yearOfPurchase) {
      setSelectedPurchaseYear("");
      const newQuery = _.omit(currentQuery, "yearOfPurchase");
      setCurrentQuery({ ...newQuery });
      const q = buildQuery({ ...newQuery });
      dispatch(getFilterpartnerProducts(null, q));
    } else {
      setSelectedPurchaseYear(purchaseYear);
      setCurrentQuery({ ...currentQuery, yearOfPurchase: purchaseYear });
      const q = buildQuery({
        ...currentQuery,
        yearOfPurchase: purchaseYear,
      });
      dispatch(getFilterpartnerProducts(null, q));
    }
  };
  const handlePagination = (value) => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
    const q = buildQuery({ ...currentQuery, skip: value-1 });
    dispatch(getFilterpartnerProducts(null, q));
  };
  const changesSortByLots = (sortingVal) => {
    setSortByLots(sortingVal);
    setCurrentQuery({ ...currentQuery, sortBy: sortingVal });
    const q = buildQuery({ ...currentQuery, sortBy: sortingVal });
    dispatch(getFilterpartnerProducts(null, q));
  };
  const handleApply = () => {
    setFilterMob(false);
  };
  const handleCancel = () => {
    setFilterMob(false);
  };
  const buildQuery = (queries) => {
    let basicUrl = "/api/v5/markid-products/algolia-search?productType=true";
    for (let key in queries) {
      if (queries.hasOwnProperty(key)) {
        switch (key) {
          case "search":
            basicUrl = `${basicUrl}&search=${queries[key]}`;
            break;
          case "gender":
            basicUrl = `${basicUrl}&gender=${queries[key]}`;
            break;
          case "size":
            basicUrl = `${basicUrl}&size=${queries[key]}`;
            break;
          case "yearOfPurchase":
            basicUrl = `${basicUrl}&yearOfPurchase=${queries[key]}`;
            break;
          case "category":
            basicUrl = `${basicUrl}&category=${queries[key]}`;
            break;
          case "brands":
            // basicUrl = `${basicUrl}&brands=${queries[key] ? queries[key].join() : ''}`
            basicUrl = `${basicUrl}&brands=${queries[key]}`;
            break;
          case "condition":
            basicUrl = `${basicUrl}&condition=${queries[key]}`;
            break;
          case "renewed":
            basicUrl = `${basicUrl}&renewed=${queries[key]}`;
            break;
          case "shipping":
            if (queries[key].isDelivery) {
              basicUrl = `${basicUrl}&isDelivery=true`;
            } else if (queries[key].isFree) {
              basicUrl = `${basicUrl}&isFree=true`;
            } else if (queries[key].isMeetup) {
              basicUrl = `${basicUrl}&isMeetup=true`;
            }
            break;
          case "price":
            basicUrl = `${basicUrl}&priceFrom=${
              queries[key].min * 100
            }&priceTo=${queries[key].max * 100}`;
            break;
          case "skip":
            basicUrl = `${basicUrl}&pageNumber=${queries[key]}`;
            break;
          // case "pageNumber":
          //   basicUrl = `${basicUrl}&pageNumber=${queries[key]}`;
          //   break;
          case "sortBy":
            basicUrl = `${basicUrl}&sortBy=${queries[key]}`;
            break;
        }
      }
    }
    return basicUrl;
  };
  const updateSelectedAge = (ages) => {
    // if(ages.length > 0){
    //   const ageFilterLots = _.filter(lots, function(o) { return _.includes(selectedAge, o.age); });
    // setFilterLots(ageFilterLots)
    // setSelectedAge(ages)
    // }
    // else {
    //   setFilterLots(lots)
    // }
  };

  const updateSelectedCondition = (conditions) => {
    const isExists = _.includes(selectedCondition, conditions);
    const sCondition = selectedCondition;
    if (isExists) {
      const removeIndex = selectedCondition.findIndex(
        (item) => item === conditions
      );
      sCondition.splice(removeIndex, 1);
    } else {
      sCondition.push(conditions);
    }
    setSelectedCondition(sCondition);
    const q = buildQuery({ ...currentQuery, condition: selectedCondition });
    dispatch(getFilterpartnerProducts(null, q));
    setCurrentQuery({ ...currentQuery, condition: selectedCondition });
  };
  const updateSelectedShipping = (shipping, findIndex) => {
    const isFree = shipping.shipping === "Free Shipping";
    const isMeetup = shipping.shipping === "Meetup";
    const isDelivery = shipping.shipping === "Shipping";
    let q;
    if (isFree) {
      q = { shipping: { isFree: shipping.value } };
    } else if (isMeetup) {
      q = { shipping: { isMeetup: shipping.value } };
    } else if (isDelivery) {
      q = { shipping: { isDelivery: shipping.value } };
    }
    const delta = { ...currentQuery, ...q };
    const url = buildQuery({ ...currentQuery, ...q });
    dispatch(getFilterpartnerProducts(null, url));
    setCurrentQuery({ ...currentQuery, ...q });

    const newSelectedShipping = selectedShipping.map((s) => {
      if (s.shipping === shipping.shipping) {
        s.value = shipping.value;
        return s;
      } else {
        s.value = false;
        return s;
      }
      s.shipping;
    });
    setSelectedShipping([...newSelectedShipping]);
  };

  const updateResetAllFilter = () => {
    setSelectedBrand([]);
    setSelectedGender([]);
    setSelectedSize([]);
    setSelectedPriceRange({});
    setSelectedPurchaseYear("");
    setSelectedCondition([]);
    setSortByLots("");
    setSelectedShipping([
      { shipping: "Free Shipping", value: false },
      { shipping: "Shipping", value: false },
      { shipping: "Meetup", value: false },
    ]);
    setCurrentQuery({
      ...currentQuery,
      gender: "",
      size: "",
      condition: "",
      price: "",
      age:"",
    });
    const q = buildQuery({ ...defaultQuery });
    dispatch(getFilterpartnerProducts(null, q));
  };
  const handleRenewedChange = (x) => {
    const { renewedQuery } = currentQuery;
    if (x) {
      const q = buildQuery({ ...currentQuery, renewed: true });
      setCurrentQuery({ ...currentQuery, renewed: true });
      setRenewed(true);
      dispatch(getFilterpartnerProducts(null, q));
    } else {
      const q = buildQuery({ ...currentQuery, renewed: false });
      setCurrentQuery({ ...currentQuery, renewed: false });
      setRenewed(false);
      dispatch(getFilterpartnerProducts(null, q));
    }
  };
  let filtertags = [
    ...selectedBrand,
    ...selectedGender,
    ...selectedshippingarr,
    ...selectedCondition,
    ...selectedPurchaseYear,
    ...selectedAge,
    ...selectedSize,
    ...selectedpricevalues,
  ];
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <LeftOutlined /> : <RightOutlined />;
    return (
      <button
        style={{
          background: "transparent",
          border: "none",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          fontSize: 25,
          fontWeight: "bolder",
          color: isEdge ? "#E4E4E4" : "#393939",
          cursor: "pointer",
        }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };
  const myDots = ({ pages, activePage, onClick }) => {
    return (
      <Row
        justify="center"
        style={{ width: "100%", padding: "5px 15px", zIndex: 9 }}
      >
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              style={{
                borderRadius: "50%",
                background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                width: 15,
                height: 15,
                margin: isXS ? "22px 5px 0px" : "50px 5px 0px",
                cursor: "pointer",
              }}
              onClick={() => onClick(page)}
              // active={isActivePage}
            />
          );
        })}
      </Row>
    );
  };
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);
  const listenToScroll = () => {
    let heightToHideFrom = 700;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);
    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll, true);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  let currentPage = useLocation().pathname.split("/");
  return (
    <>
      <div id="height">
        <b>
          height: {height} - {isVisible ? "show" : "hide"}
        </b>
      </div>
      {!isVisible && !isXS &&(
        <div id="hide">
          <RibbonTop close={close} setClose={setClose} />
        </div>
      )}

      <Helmet>
        <meta charSet="utf-8" />
        <title>{lang.partnerSwoondle.title}</title>
        <meta name="description" content={lang.partnerSwoondle.description} />
        {localStorage.getItem("Selected-Brands")
          ? JSON.parse(localStorage.getItem("Selected-Brands")).length &&
            JSON.parse(localStorage.getItem("Selected-Brands")).map(
              (brands) => {
                return (
                  <meta
                    name="description"
                    content={`Shop ${brands} products for Sale & Used Baby Items | Markid Marketplace`}
                  />
                );
              }
            )
          : ""}
        {JSON.parse(localStorage.getItem("Selected-Genders")) ? (
          JSON.parse(localStorage.getItem("Selected-Genders")).length ? (
            <meta
              name="keywords"
              content={`For ${
                JSON.parse(localStorage.getItem("Selected-Genders")) || " "
              }`}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {JSON.parse(localStorage.getItem("Selected-Condition")) ? (
          JSON.parse(localStorage.getItem("Selected-Condition")).length ? (
            <meta
              name="keywords"
              content={`Condition: ${
                JSON.parse(localStorage.getItem("Selected-Condition")) || ""
              }`}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {localStorage.getItem("Selected-Price-Range") ? (
          localStorage.getItem("Selected-Price-Range").length ? (
            <meta
              name="keywords"
              content={`Under $${
                localStorage.getItem("Selected-Price-Range") || ""
              }`}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Helmet>
      {/* {lots && lots.length > 0 && ( */}
      <div>
        {/* {!search && currentPage[1] != 'partner' && <PCategoryCard itemType={itemType} currentBrand={brand} currentCategory={category} categoryresults={partnerproducts} resultValue={total} handPickedData={handPickedItem} />} */}
        {/* <PartnerListingBanner /> */}

        <div className="pCardBackgound">
          <div className="app-wrapper w-100">
            {/* <div className="pBreadCrumbMob partners_module">
              <Row
                gutter={[
                  0,
                  {
                    xs: 0,
                    sm: 0,
                    md: 15.4,
                    lg: 15.4,
                    xl: 15.4,
                  },
                ]}
              >
                <Col span={24}></Col>
                <Col>
                  <PLBreadCrumb markid="Home" category={(itemType === 'category' && category.displayValue) || (itemType === 'brand' && brandName) || (itemType === 'handpicked' && handPickedName)} /> 
                  <div className="staticBreadcrumb">
                    <ul>
                      <li>Home</li>
                      <li className="active">Partner Profile</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div> */}
            {/* <PFiltered
          itemType={itemType}
          category={category}
          brand={brand}
          handPickedName={handPickedName}
          sortByLots={sortByLots}
          changesSortByLots={changesSortByLots}
          handleRenewedChange={handleRenewedChange}
        /> */}
            <div className="app-wrapper prod-cards-grid">
              <Row gutter={[0, { xs: 15, sm: 15, md: 32, lg: 32, xl: 32 }]}>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 21, offset: 2 }}
                  lg={{ span: 21, offset: 2 }}
                  xl={{ span: 21, offset: 2 }}
                >
                  {/* <PFiltered
                selectedshippingarr={selectedshippingarr}
                filtertags={filtertags}
                id={id}
                setSelectedCondition={setSelectedCondition}
                setSelectedSize={setSelectedSize}
                buildQuery={buildQuery}
                itemType={itemType}
                itemName={itemName || "search"}
                selectedBrand={selectedBrand}
                updateSelectedBrand={updateSelectedBrand}
                selectedGender={selectedGender}
                updateSelectedGender={updateSelectedGender}
                selectedAge={selectedAge}
                setSelectedGender={setSelectedGender}
                updateSelectedAge={updateSelectedAge}
                selectedSize={selectedSize}
                updateSelectedSize={updateSelectedSize}
                selectedCondition={selectedCondition}
                updateSelectedCondition={updateSelectedCondition}
                selectedShipping={selectedShipping}
                updateSelectedShipping={updateSelectedShipping}
                selectedPurchaseYear={selectedPurchaseYear}
                updateSelectedPurchaseYear={updateSelectedPurchaseYear}
                selectedPriceRange={selectedPriceRange}
                updateSelectedPriceRannge={updateSelectedPriceRannge}
                updateResetAllFilter={updateResetAllFilter}
                brandsByCategory={brandsByCategory}
                category={category}
                brand={brand}
                sortByLots={sortByLots}
                changesSortByLots={changesSortByLots}
                renewed={renewed}
                handleRenewedChange={handleRenewedChange}
              /> */}
                </Col>
              </Row>
              {/* <Col span={24}></Col> */}
              {/* <Col span={2}></Col> */}
              <Row
                gutter={[
                  { xs: 10, sm: 10, md: 32, lg: 32, xl: 32 },
                  { xs: 18, sm: 18, md: 32, lg: 32, xl: 32 },
                ]}
              >
                <Col span={24}></Col>
                {/* Default filters for a query are category='' and
                        brands='' Hide filter dropdowns only for default case,
                        with any value for filters dropdown shouldn't be hidden
                        */}
                {((data && data.length > 0) ||
                  (JSON.stringify(currentQuery) !==
                    JSON.stringify({
                      category: "",
                      brands: "",
                    }) &&
                    JSON.stringify(currentQuery) !==
                      JSON.stringify({
                        brands: "",
                        category: "",
                      }))) && (
                  <Col
                    md={{ span: 6, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                    xl={{ span: 6, offset: 0 }}
                    className="pFilterItemCardMob"
                  >
                    <PFilteredItem
                     totalcount={totalcount}
                      itemType={itemType}
                      itemName={itemName}
                      partnerproducts={partnerproducts}
                      selectedBrand={selectedBrand}
                      updateSelectedBrand={updateSelectedBrand}
                      selectedGender={selectedGender}
                      updateSelectedGender={updateSelectedGender}
                      selectedSize={selectedSize}
                      updateSelectedSize={updateSelectedSize}
                      selectedAge={selectedAge}
                      updateSelectedAge={updateSelectedAge}
                      selectedCondition={selectedCondition}
                      updateSelectedCondition={updateSelectedCondition}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                      selectedPurchaseYear={selectedPurchaseYear}
                      updateSelectedPurchaseYear={updateSelectedPurchaseYear}
                      selectedPriceRange={selectedPriceRange}
                      updateSelectedPriceRannge={updateSelectedPriceRannge}
                      updateResetAllFilter={updateResetAllFilter}
                      bransByCategory={brandsByCategory}
                    />
                  </Col>
                )}
                {isXS && (
                  <Row gutter={[10, 0]} className="filterWrap">
                    <Col span={12}>
                      {/* {!showfilterDialog && (<Affix offsetTop={510}>
                        <LazyLoadImage onClick={() => {setshowfilterDialog(true)}} src={FilterIcon} effect="blur" alt="" />
                      </Affix>)} */}
                      {!showfilterDialog && (
                        <Affix offsetTop={20}>
                          {/* <LazyLoadImage  src={FilterIcon} effect="blur" alt="" />  */}
                          <Button
                            type="link"
                            className="filter_text fitlerOption"
                            onClick={() => {
                              setshowfilterDialog(true);
                              setdisplaydata(false);
                            }}
                          >
                            Filter
                          </Button>
                        </Affix>
                      )}
                      {/* {filterMob && (
                    <Modal
                      width={innerWidth}
                      // bodyStyle={{height:innerHeight}}
                      style={{
                        top: 0,
                        height: innerHeight,
                        margin: 0,
                        padding: 0,
                        maxWidth: "unset",
                      }}
                      closable={false}
                      className="modalParent"
                      visible={filterMob}
                      footer={false}
                    > */}
                      <PFilteredItemMob
                      handledisplayAll={() => {
                        setdisplaydata(true);
                        setshowfilterDialog(false)
                        setresetclose(true)
                      }}
                      totalcount={totalcount}
                      filtertags={filtertags}
                      total={totalProd}
                      resetclose={resetclose}
                      displaydata={displaydata}
                      setdisplaydata={setdisplaydata}
                        showfilterDialog={showfilterDialog}
                        setshowfilterDialog={setshowfilterDialog}
                        itemType={itemType}
                        itemName={itemName}
                        partnerproducts={partnerproducts}
                        selectedBrand={selectedBrand}
                        updateSelectedBrand={updateSelectedBrand}
                        selectedGender={selectedGender}
                        updateSelectedGender={updateSelectedGender}
                        selectedSize={selectedSize}
                        updateSelectedSize={updateSelectedSize}
                        selectedAge={selectedAge}
                        updateSelectedAge={updateSelectedAge}
                        selectedCondition={selectedCondition}
                        updateSelectedCondition={updateSelectedCondition}
                        selectedShipping={selectedShipping}
                        updateSelectedShipping={updateSelectedShipping}
                        selectedPurchaseYear={selectedPurchaseYear}
                        updateSelectedPurchaseYear={updateSelectedPurchaseYear}
                        selectedPriceRange={selectedPriceRange}
                        updateSelectedPriceRannge={updateSelectedPriceRannge}
                        updateResetAllFilter={updateResetAllFilter}
                        bransByCategory={brandsByCategory}
                      >
                        {/* <LeftOutlined onClick={() => setFilterMob(false)} />
                        <div className="affixParent">
                          <Affix offsetBottom={0}>
                            <Card
                              style={{
                                width: "100%",
                              }}
                            >
                              <Row gutter={[20, 0]} align="middle">
                                <Col span={10}>
                                  <Button
                                    onClick={() => handleCancel()}
                                    className="pFilterCancelMob"
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col span={14}>
                                  <Button
                                    onClick={() => handleApply()}
                                    className="pFilterApplyMob"
                                  >
                                    Apply
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          </Affix>
                        </div> */}
                      </PFilteredItemMob>
                      {/* </Modal>
                  )} */}
                    </Col>
                    {!showsortfilterDialog && (
                      <Col span={12}>
                        <Button
                          type="link"
                          className="filter_text sortOption"
                          onClick={() => {
                            setshowsortfilterDialog(true);
                          }}
                        >
                          {" "}
                          Sort by{" "}
                        </Button>

                        {/* <Select  bordered={false} showArrow={false} className='custom_select_menu filter_text sortOption' defaultValue="Sort by">
                      <Option value="Popularity">Popularity</Option>
                      <Option value="Latest">Latest</Option>
                      <Option value="Discount">Discount</Option>
                      <Option value="High to low">Price: High to low</Option>
                      <Option value="Low to High">Price: Low to High</Option>
                      </Select> */}
                      </Col>
                    )}
                  </Row>
                )}

                {data &&
                  data.length <= 0 &&
                  (JSON.stringify(currentQuery) ===
                    JSON.stringify({
                      category: "",
                      brands: "",
                    }) ||
                    JSON.stringify(currentQuery) ===
                      JSON.stringify({
                        brands: "",
                        category: "",
                      })) && (
                    <Col span={24}>
                      <ResultNotFound
                        search={search}
                        updateResetAllFilter={updateResetAllFilter}
                      />
                    </Col>
                  )}
                <Col md={18} lg={18} xl={18} xs={24} sm={24}>
                  <Row gutter={[0, { xs: 15, sm: 15, md: 32, lg: 32, xl: 32 }]}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 2 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                    >
                      <PFiltered
                        showsortfilterDialog={showsortfilterDialog}
                        setshowsortfilterDialog={setshowsortfilterDialog}
                        itemType={itemType}
                        category={category}
                        brand={brand}
                        sortByLots={sortByLots}
                        changesSortByLots={changesSortByLots}
                        renewed={renewed}
                        handleRenewedChange={handleRenewedChange}
                      />
                    </Col>
                  </Row>
                  {data &&
                    data.length <= 0 &&
                    JSON.stringify(currentQuery) !==
                      JSON.stringify({
                        category: "",
                        brands: "",
                      }) &&
                    JSON.stringify(currentQuery) !==
                      JSON.stringify({
                        brands: "",
                        category: "",
                      }) && <ResultNotFound />}
                  {data && data.length > 0 && (
                    <Spin
                      style={{ top: 200, color: "#FF533F" }}
                      indicator={antIcon}
                      spinning={isGetFilterpartnerProductsLoading}
                    >
                      <Row>
                        {/* <Col span={24}></Col> */}
                        <Col span={24} style={{ position: "relative" }}>
                          <Row
                            // justify="space-around"
                            className="productListingWrapper"
                            gutter={[
                              {
                                xs: 0,
                                sm: 0,
                                md: 30,
                                lg: 30,
                                xl: 30,
                              },
                              {
                                xs: 15,
                                sm: 15,
                                md: 30,
                                lg: 30,
                                xl: 30,
                              },
                            ]}
                          >
                            {data &&
                              data.map((val, i) => {
                                const q = buildQuery({
                                  ...currentQuery,
                                });
                                return (
                                  <Col
                                    xl={6}
                                    lg={6}
                                    md={12}
                                    sm={11}
                                    xs={11}
                                    key={i}
                                  >
                                    <ProductCard
                                      renewed={renewed}
                                      partnerproducts={val}
                                      url={q}
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                          {data && data.length >= 1 && (
                            <Row
                              gutter={[
                                0,
                                {
                                  xs: 21,
                                  sm: 21,
                                  md: 38,

                                  lg: 38,
                                  xl: 38,
                                },
                              ]}
                              align="middle"
                              justify={isXS ? "center" : "end"}
                            >
                              <Col span={24} />
                              <Col>
                                <Pagination
                                  size="small"
                                  showSizeChanger={false}
                                  total={totalnew}
                                  pageSize={24}
                                  onChange={(value) => handlePagination(value)}
                                  itemRender={itemRender}
                                />
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Spin>
                  )}
                </Col>
                <Col span={24}></Col>
              </Row>
            </div>
            {/* {lots && lots.length <= 0 && (
                        <Col
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 50,
                            }}
                        >
                            <Empty
                                description={
                                    <div
                                        style={{
                                            fontFamily: 'Montserrat',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Sorry, No items found.
                                    </div>
                                }
                            />
                        </Col>
                    )} */}
          </div>
        </div>
        {/* <Row  justify="center" gutter={[0, { xs: 77, sm: 24, md: 60, lg: 60, xl:60 }]}>
          <Col span={24}/>
          <Col span={24}>
        <div className="relatedBack">
          <Row justify="center" gutter={[0, { xs: 23, sm: 23, md: 48, lg: 48, xl:48 }]}>
            <Col span={24}></Col>
            <Col xs={0} sm={0} md={24} lg={24} xl={24}></Col>
            <Col xs={0} sm={0} md={24} lg={24} xl={24}></Col>
            <Col>
              <Typography className="relatedItemText">Related items</Typography>
            </Col>
          </Row>
          <Row
            gutter={[0, { xs: 5, sm: 5, md: 76, lg: 76 }]}
            justify="center"
          >
            <Col span={24}></Col>
            <Col xl={20} lg={20} md={24} sm={22} xs={22}>
              <Row gutter={[{ xs:22, sm: 22, md: 25, lg: 25, xl:25 }, 0]} justify="center">
                <Carousel
                  itemsToShow={isXS ? 1.75 : 4}
                  itemsToScroll={4}
                  breakPoints={isXS ? [] : breakPoints}
                  showArrows={isXS ? false : true}
                  renderArrow={myArrow}
                  renderPagination={myDots}
                >
                  {PlSimilarCardData.map((val, i) => {
                    return (
                      <Col key={i}>
                        <RelatedItem
                          cover={val.pLImage}
                          itemName={val.pLName}
                          price={val.pLPrice}
                          shipping={val.pLShipping}
                        />
                      </Col>
                    );
                  })}
                </Carousel>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[0, { xs: 36, sm: 36, md: 54, lg: 54, xl:54 }]}
          >
            <Col span={24}></Col>
            <Col>
              <Button className="cSeeAllButton">See all</Button>
            </Col>
            <Col span={24}></Col>
          </Row>
        </div>
        </Col>
        </Row>
      </div>
      <Row justify="center" gutter={[0, { xs: 53, sm: 53, md: 48, lg: 48, xl:120 }]}>
        <Col span={24}></Col>
        <Col md={24} lg={24} xl={24}></Col>
        <Col>
          <Typography className="searchedCategoryText">
            Customers who searched for strollers also searched for
          </Typography>
        </Col>
        <Col>
          <img alt="" src={Magnifying} className="magnifyingWeb" />
          <img alt="" src={Magnifying} className="magnifyingmob" />
        </Col>
      </Row>
      <Row gutter={[0, { xs: 20, sm: 20, md: 82, lg: 82 }]} justify="center">
        <Col span={24}></Col>
        <Col xl={20} lg={20} md={24} sm={22} xs={22}>
          <Row gutter={[{ xs: 22, sm: 22, md: 20, lg: 20, xl:20 }, 0]}>
            <Carousel
              itemsToShow={isXS ? 1.75 : 4}
              itemsToScroll={4}
              breakPoints={isXS ? [] : breakPoints}
              showArrows={isXS ? false : true}
              renderArrow={myArrow}
              renderPagination={myDots}
            >
              {PlSimilarCardData.map((val, i) => {
                return (
                  <Col key={i}>
                    <RelatedItem
                      cover={val.pLImage}
                      itemName={val.pLName}
                      price={val.pLPrice}
                      shipping={val.pLShipping}
                    />
                  </Col>
                );
              })}
            </Carousel>
          </Row>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, { xs: 36, sm: 36, md: 54, lg: 54, xl:54 }]}>
        <Col span={24}></Col>
        <Col>
          <Button className="cSeeAllButton">See all</Button>
        </Col>
        <Col xs={24} sm={24} md={0} lg={0}></Col>
      </Row> */}
        {/* {lots && lots.length <= 0 && search &&
        <ResultNotFound />
} */}
      </div>
      {/* )} */}

      {/* {lots && lots.length <= 0 && <ResultNotFound />} */}
    </>
  );
};
export default PartnerProductList;
