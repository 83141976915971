/*eslint-disable*/
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Checkbox,
} from "antd";
import "../App.css";
import { createStripePayment } from "../Actions/cartAction";
const { Text, Title } = Typography;
const OfferMadePriceDetails = (props) => {
  const dispatch = useDispatch()
  const { heading, itemTotal, itemTotalVal, shipping, shippingVal, salesTax, salesTaxVal, cartTotal, cartTotalVal, button } = props
  const { currentUserWallet, useCredits } = useSelector((state) => {
    const { currentUserWallet, useCredits } = state.cart;
    return {
      currentUserWallet,
      useCredits
    };
  });
  const history=useHistory()
  const { balance } = currentUserWallet
  let location = useLocation().pathname.split("/");
  const handleUseCredits = () => {
    dispatch({ type: "USE_MARKID_CREDIT", payload: { useCredits: !useCredits } })
  }
  const numberItemTotalValue = parseFloat(cartTotalVal)
  let valueAllItemTotalWithShipping = (parseFloat(cartTotalVal) + parseFloat(shippingVal)).toFixed(2);
  useEffect(() => {
    valueAllItemTotalWithShipping = parseFloat(cartTotalVal) + parseFloat(shippingVal);
    const value = useCredits ? (balance > valueAllItemTotalWithShipping ? 0.0 : valueAllItemTotalWithShipping - balance): valueAllItemTotalWithShipping;
    if(value > 0.0) {
      const valueToCent = value * 100;
      dispatch(createStripePayment({ amount: valueToCent })) //
    }
  }, []);
  return (
    <>
      <Row gutter={[0, 25]}>
        <Col span={24}></Col>
        <Col>
          <Title level={4} className='pDTitle'>
            {heading}
          </Title>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[0, 16]}>
        <Col span={24}></Col>
        {/* <Col>
              <Input suffix={<Button type='text'>CHECK</Button>} placeholder='Promo Code' />
              </Col> */}
      </Row>
      {balance > 0 &&
        <>
          <Row gutter={[0, 8]}>
            <Col span={24}></Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Text className='pDKey'>Markid Credits</Text>
                </Col>
                <Col>
                  <Text className='pDVal'>${balance.toFixed(2)}</Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Checkbox checked={useCredits} onChange={() => handleUseCredits()}>Use my Markid credits</Checkbox>
            </Col>
          </Row>
        </>
      }
      {(location[1] != "made-offer") ?
        <>
          <Divider />
          <Row justify="space-between" gutter={[0, 25]}>
            <Col span={24}></Col>
            <Col>
              <Text className='pDKey'>{shipping}</Text>
            </Col>
            <Col>
              <Text className='pDVal'>{shippingVal}</Text>
            </Col>
          </Row> </> : <></>}
      {useCredits &&
        <Row justify="space-between" gutter={[0, 25]}>
          <Col span={24}></Col>
          <Col>
            <Text className='pDKey'>Markid Credits</Text>
          </Col>
          <Col>
            <Text className='pDVal'>- ${balance > numberItemTotalValue ? numberItemTotalValue : balance.toFixed(2)}</Text>
          </Col>
        </Row>
      }
      <Divider className='pDDivider' />
      <Row justify="space-between">
        <Col>
          <Text className='cartTotalKeyVal'>{cartTotal}</Text>
        </Col>
        <Col>
          {/* <Text className='cartTotalKeyVal'>${useCredits ? (balance > numberItemTotalValue ? 0.0 : cartTotalVal - balance) : cartTotalVal}</Text> */}
          <Text className='cartTotalKeyVal'>${useCredits ? ( balance > valueAllItemTotalWithShipping ? 0.0 : valueAllItemTotalWithShipping - balance): valueAllItemTotalWithShipping}</Text>
        </Col>
      </Row>

      <Row gutter={[0, 40]}>
        <Col span={24}></Col>
        <Col span={24}>
          {button}
        </Col>
      </Row>

      <Row gutter={[0, 20]}>
        <Col span={24}></Col>
        <Col>
          <Typography className='agreeText'>
            By clicking “Checkout”, you agree to the Markid <a onClick={() => history.push(`/privacy-policy`)} target="_blank">Privacy & Policy</a> and <a onClick={() => history.push(`/terms-and-conditions`)} target="_blank">Terms of Service</a>
          </Typography>
        </Col>
      </Row>
    </>
  );
};
export default OfferMadePriceDetails;
