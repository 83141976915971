import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Row, Col, Card, Typography, Select } from "antd";
import logo from "../assets/logo.svg";
import { PlusOutlined } from "@ant-design/icons";
import { ItemCard } from "../components/ItemCard";
const { Title, Text } = Typography;
const NoOfChildren = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];
const schema = yup.object().shape({});
const Children = (props) => {
  const [selectedChildren, setselectedChildren] = useState({});
  const [selectedChildAge, setselectedChildAge] = useState({});
  const [selectedGender, setselectedGender] = useState({});

  const onCardClick = (selected) => {
    const { type } = selected;
    switch (type) {
      case "children":
        // code block
        setselectedChildren(selected);
        break;
      case "childAge":
        setselectedChildAge(selected);
        break;
      case "gender":
        setselectedGender(selected);
        break;
      default:
      // code block
    }
  };
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  return (
    <>
      <Row justify="center">
        <Col xl={{span:14, offset: 2 }} lg={{span:14, offset: 2 }} md={{span:16, offset: 2 }} sm={22} xs={22}>
          <Card>
            <Row gutter={[48, 16]}>
              <Col span={24}></Col>
              <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                <img
                  alt="logo"
                  src={logo}
                  // preview={false}
                />
              </Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
            </Row>

            <Row justify="center" gutter={(0, 48)}>
              <Col span={24}></Col>
              <Col>
                <Title level={2}>Tell us about your children</Title>
              </Col>
            </Row>
            <form onSubmit={handleSubmit()}>
            <Row gutter={[16, 8]} justify="start">
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col>
                <Text strong>Do you have any children?</Text>
              </Col>
            </Row>
            <Row gutter={[32, 8]} justify="start">
              <Col span={24}></Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Expecting"
                  id={{ type: "children", i: 1 }}
                  selected={selectedChildren}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Have children"
                  id={{ type: "children", i: 2 }}
                  selected={selectedChildren}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="None"
                  id={{ type: "children", i: 3 }}
                  selected={selectedChildren}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
            </Row>

            <Row gutter={[16, 8]} justify="start">
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col>
                <Text strong>How many children do you have?</Text>
              </Col>
            </Row>
            <Row gutter={[32, 8]} justify="start">
              <Col span={24}></Col>
              <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                <Controller
                  as={Select}
                  control={control}
                  options={NoOfChildren}
                  name="children"
                  style={{ width: '100%' }}
                />
                {/* {errors.children && (
                  <span className="error_style">Required</span>
                )} */}
              </Col>
            </Row>

            <Row gutter={[16, 8]} justify="start">
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col>
                <Text strong>How old is your child?</Text>
              </Col>
            </Row>

            <Row gutter={[32, 8]} justify="start">
              <Col span={24}></Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="0-3 M"
                  id={{ type: "childAge", i: 1 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="3-6 M"
                  id={{ type: "childAge", i: 2 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="6-12 M"
                  id={{ type: "childAge", i: 3 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="1-2 Y"
                  id={{ type: "childAge", i: 4 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="2-3 Y"
                  id={{ type: "childAge", i: 5 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="3-5 Y"
                  id={{ type: "childAge", i: 6 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="5-7 Y"
                  id={{ type: "childAge", i: 7 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <ItemCard
                  text="7-9 Y"
                  id={{ type: "childAge", i: 8 }}
                  selected={selectedChildAge}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
            </Row>

            <Row gutter={[16, 8]} justify="start">
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
              <Col>
                <Text strong>What is your child's gender?</Text>
              </Col>
            </Row>

            <Row gutter={[32, 8]} justify="start">
              <Col span={24}></Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Male"
                  id={{ type: "gender", i: 1 }}
                  selected={selectedGender}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Female"
                  id={{ type: "gender", i: 2 }}
                  selected={selectedGender}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ItemCard
                  text="Prefer not to say"
                  id={{ type: "gender", i: 3 }}
                  selected={selectedGender}
                  onCardClick={onCardClick}
                ></ItemCard>
              </Col>
            </Row>

            <Row justify="center" gutter={[8, 24]}>
              <Col span={24}></Col>
              <Col>
                <Button type="text" icon={<PlusOutlined />}>
                  ADD CHILD
                </Button>
              </Col>
              <Col>
                <Button
                  className={'squarebutton'}
                >
                  CONTINUE
                </Button>
              </Col>
              <Col span={24}></Col>
            </Row>
            </form>
          </Card>
        </Col>
        <Col xl={8} lg={8} md={8} sm={22} xs={22}>
          <Card style={{ backgroundColor: "#d8d8d8", height: 331 }} />
        </Col>
      </Row>
    </>
  );
};
export default Children;
