/*eslint-disable*/
import React, { useRef, useState } from "react";
import { Row, Col, Card, AutoComplete, Input, Typography, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import { MenuOutlined } from "@ant-design/icons";
import { setFilterCondition } from "../Actions/brandsAction";
import MenuBar from "../assets/MenuBar.svg";
import MHBanner1 from "../assets/new-assets/slider-1.jpg";
import MHBanner2 from "../assets/Option1.jpg";
import MHBanner3 from "../assets/Option2.jpg";
import MobileBannerNew from "../assets/new-assets/mobile_banner.jpg";
import MobileBanner1 from "../assets/MobileBanner1.jpg";
import MobileBanner2 from "../assets/new-assets/Mobile-Banner.png";
import MobileBanner3 from "../assets/MobileBanner3.jpg";
import Illustration from "../assets/Illustration.svg";
import promotionalBanner1 from "../assets/verifiedshopsweb.png";
import promotionalBanner2 from "../assets/HALLOWEENWeb.png";
import promotionalBanner3 from "../assets/shopgearstrollweb.png";
import { useGaEvent } from "../hooks/useGaEvent";
import promotionalBannerMobile5 from "../assets/winterwearmwebnew.png";
import promotionalBannerMobile6 from "../assets/Christmasmweb.png";
import promotionalBannerMobile1 from "../assets/verifiedshopsmweb.png";
import promotionalBannerMobile4 from "../assets/mwebhall.png";
import promotionalBannerMobile2 from "../assets/shopgearstrollmweb.png";
import promotionalBanner6 from "../assets/Christmasbanner.png";
import promotionalBanner5 from "../assets/Winterbanner.png";

import promotionalBannerMobile3 from "../assets/mwebTextChange.png";

//import Carousel from 'react-elastic-carousel';
import { Carousel } from "antd";
import { FeaturedSeller } from "./FeaturedSeller";
import DotContainer from "./DotComponent";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { useHistory } from "react-router-dom";
const { Title } = Typography;
const MainHomePromotionalBanner = (props) => {
  const history = useHistory();
  const { gaPageView, gaEventClick } = useGaEvent();
  const { currentLocationZip } = useSelector((state) => {
    const { currentLocationZip } = state.user;
    return {
      currentLocationZip,
    };
  });
  let zipCode = currentLocationZip || "10100";
  const carouselRef = useRef(null);
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints();
  const calculateTop = () => {
    let width = 0;
    if (isXS) {
      width = 302;
    } else if (isLG) {
      width = 335;
    } else if (isSM) {
      width = 200;
    } else if (isMD) {
      width = 200;
    } else if (isXL) {
      width = 320;
    } else if (isXXL) {
      width = 500;
    }
    return width;
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  const myDots = ({ pages, activePage, onClick }) => {
    return (
      <Row
        justify="end"
        style={{ width: "100%", padding: "5px 15px", zIndex: 9 }}
      >
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              style={{
                borderRadius: "50%",
                background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                width: 15,
                height: 15,
                margin: "70px 5px 0px",
                cursor: "pointer",
              }}
              onClick={() => onClick(page)}
              //   active={isActivePage}
            />
          );
        })}
      </Row>
    );
  };

  const dispatch = useDispatch();
  // console.log({ isXS, isLG, isSM, isXL, isXXL })
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index && nextItem === 2) {
      // we hit the last item, go to first item
      //   carouselRef.current.goTo(0);
      setTimeout(() => {
        try {
          carouselRef.current.goTo(0);
        } catch (error) {}
      }, 1500); // same tim
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(items.length);
    }
  };
  return (
    <Row className="app-wrapper style2">
      <Col span={24}>
        {(isXL || isLG || isXXL || isMD) && (
          <Carousel
            className="noPaddingCarousel heroSlider"
            breakPoints={breakPoints}
            renderPagination={myDots}
            itemPadding={[0, 0, 0, 0]}
            itemsToShow={1}
            itemsToScroll={1}
            showArrows={false}
            outerSpacing={0}
            autoplaySpeed={9000}
            //enableAutoPlay={true}
            ref={carouselRef}
            //	onPrevStart={onPrevStart}
            //	onChange={onNextStart}
            autoplay
          >
            {/* <img
              alt=""
              src={promotionalBanner2}
              className="bannerImgWeb slider1"
              onClick={() => {
                gaEventClick("Hero Section", "Banner", "halloween");
                history.push(`/product-listing/category/toys_and_costumes/5f8ab7c336a0cd0ea2329a7d`);
              }}
            /> */}
            {/* src={MHBanner1} src={MHBanner2} src={MHBanner3}*/}
            {/* <img
              alt=""
              src={promotionalBanner6}
              className="bannerImgWeb slider1"
              onClick={() => {
                dispatch(setFilterCondition("Christmas"));
                gaEventClick("Hero Section", "Banner", "holiday");
                history.push(`/products/holiday`);
              }}
            /> */}
            <img
              alt=""
              src={promotionalBanner5}
              className="bannerImgWeb slider1"
              onClick={() => {
                dispatch(setFilterCondition("Holiday"));
                gaEventClick("Hero Section", "Banner", "winterwear");
                history.push(`/products/winterwear`);
              }}
            />
            <img
              alt=""
              src={promotionalBanner3}
              className="bannerImgWeb slider1"
              onClick={() => {
                gaEventClick("Hero Section", "Banner", "browse All");
                history.push(`/products/gearandstrollers`);
              }}
            />

            <img
              alt=""
              src={promotionalBanner1}
              className="bannerImgWeb slider2"
              onClick={() => {
                gaEventClick("Hero Section", "Banner", "Swoondle");
                history.push(`/verifiedshops`);
              }}
            />

            {/* <img
              alt=""
              src={promotionalBanner3}
              className="bannerImgWeb slider3"
              onClick={() => {
                gaEventClick("Hero Section", "Banner", "browse All");
                history.push(`/search-new?refresh=true&zipCode=${zipCode}`)
              }
            /> */}
          </Carousel>
        )}
        {(isXS || isSM) && (
          <Carousel
            className="noPaddingCarousel"
            breakPoints={breakPoints}
            renderPagination={({ pages, activePage, onClick }) => {
              return (
                <Row
                  justify="center"
                  style={{
                    width: "100%",
                    paddingTop: "68%",
                    paddingBottom: 0,
                    zIndex: 10,
                  }}
                >
                  {pages.map((page) => {
                    const isActivePage = activePage === page;
                    return (
                      <div
                        style={{
                          borderRadius: "50%",
                          background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                          width: 15,
                          height: 15,
                          margin: "70px 5px 0px",
                          cursor: "pointer",
                        }}
                        key={page}
                        onClick={() => onClick(page)}
                        // active={isActivePage}
                      />
                    );
                  })}
                </Row>
              );
            }}
            itemPadding={[0, 0, 0, 0]}
            itemsToShow={1}
            itemsToScroll={1}
            showArrows={false}
            outerSpacing={0}
            autoplaySpeed={9000}
            //enableAutoPlay={true}
            ref={carouselRef}
            //onPrevStart={onPrevStart}
            //onChange={onNextStart}
            autoplay
          >
            {/* <img
              alt=""
              src={promotionalBannerMobile4}
              onClick={() => {
                gaEventClick("Hero Section", "Banner", "halloween");
                history.push(`/product-listing/category/toys_and_costumes/5f8ab7c336a0cd0ea2329a7d`);
              }}
              className="bannerImgMob"
            /> */}
            {/* <img
              alt=""
              src={promotionalBannerMobile6}
              onClick={() => {
                dispatch(setFilterCondition("Christmas"));
                gaEventClick("Hero Section", "Banner", "holiday");
                history.push(`/products/holiday`);
              }}
              className="bannerImgMob"
            /> */}
            <img
              alt=""
              src={promotionalBannerMobile5}
              onClick={() => {
                dispatch(setFilterCondition("Holiday"));
                gaEventClick("Hero Section", "Banner", "winterwear");
                history.push(`/products/winterwear`);
              }}
              className="bannerImgMob"
            />
            <img
              alt=""
              src={promotionalBannerMobile2}
              onClick={() => {
                gaEventClick("Hero Section", "Banner", "browse all");
                history.push(`/products/gearandstrollers`);
              }}
              className="bannerImgMob"
            />

            <img
              alt=""
              src={promotionalBannerMobile1}
              onClick={() => {
                gaEventClick("Hero Section", "Banner", "swoondle");
                history.push(`/verifiedshops`);
              }}
              className="bannerImgMob"
            />
            {/* <img
              alt=""
              src={promotionalBannerMobile1}
			  onClick={() =>{
               
           history.push(
                  `/product-listing/category/clothing_and_accessories/5f8ab78836a0cd0ea2329a45`
                );
        }
              }
              className="bannerImgMob"
            /> */}
          </Carousel>
        )}
      </Col>
      {/*position: 'absolute', width: "100%", top: calculateTop(), */}
      <Col
        span={24}
        style={{
          position: "absolute",
          width: "100%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "none",
        }}
        className="herobanner"
      >
        <Row className="mHBParent">
          <div className="app-wrapper">
            <Col
              xxl={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              sm={{ span: 22, offset: 0 }}
              xs={{ span: 22, offset: 0 }}
            >
              <div className="promotionalText">
                {/* gutter={[0, { xs: 27.57, sm: 27.57, md: 40, lg: 40 }]} */}
                <Row>
                  <Col>
                    <Typography
                      id="firstRow"
                      level={4}
                      className="mPromotionalBannerTitle"
                    >
                      20% off on you first Swoondle
                    </Typography>
                    <Typography level={4} className="mPromotionalBannerTitle">
                      purchase from Markid
                    </Typography>
                  </Col>
                </Row>
                {/* gutter={[0, { xs: 14.86, sm: 14.86, md: 22, lg: 22, xl: 18, xxl: 18 }]} */}

                {/* gutter={[{ xl: 12.42, xs: 12.42, sm: 12.42, md: 18, lg: 18 }, { xs: 17.81, sm: 17.81, md: 25, lg: 25, xl: 25, xxl: 25 }]} */}
                <Row>
                  <Col span={24}></Col>
                  <Col className="mPromotionalBannerButton">
                    <Button
                      className="button shopNowButton button-primary"
                      onClick={() =>
                        history.push(
                          `/search-new?refresh=true&zipCode=${zipCode}`
                        )
                      }
                    >
                      Shop Now
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}></Col>
                  <Col>
                    <Typography className="mHBDes">
                      Automatically applied at checkout.
                    </Typography>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
        </Row>
      </Col>
    </Row>
  );
};
export default MainHomePromotionalBanner;
