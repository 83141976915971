/*eslint-disable*/
import React, { useState } from "react";
import { Row, Col, Card, AutoComplete, Input, Typography, Button } from "antd";
import "../App.css";
import Cap from "../assets/Cap.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { useHistory } from "react-router";
const MRSelection = (props) => {
	const { categoryRef } = props
	const { isXXL, isXL, isLG, isMD, isXS, isSM } = useBreakpoints();
	const history = useHistory()
	const handleRenewedHandPickedItem = () => {
		// if(window.location.pathname === '/homepage'){
		if (window.location.pathname === '/') {
			//   history.push(`/handpicked/handpicked/markid ReNEWED/5fb580cc5f1dfa0e9e75720e`);
			//   history.push(`/handpicked/handpicked/markid ReNEWED/${process.env.REACT_APP_STATIC_USER_ID}`);
			history.push(`/search-new/?refresh=true&renewed=true`);
			categoryRef.current.scrollIntoView();
		} else {
			// history.push('/homepage');
		}
	}
	return (
		<>
			{(isXXL || isXL || isLG || isMD) &&
				// <Row gutter={[0, { xs: 70, sm: 70, md: 138, lg: 138, xl:138, xxl:138 }]}>
				<Row gutter={[0, { xs: 70, sm: 70, md: 50, lg: 35, xl: 35, xxl: 35 }]}>
					<Col span={24}></Col>
					<div className='MRSBackground'>
						<div className="app-wrapper discover-wrap">
							<Row gutter={[0, { xs: 49, sm: 49, md: 98, lg: 98, xl: 98, xxl: 98 }]}>
								{/* <Col span={24}></Col> */}
								{/* <Col span={24}></Col> */}
								<Col span={14} >
									<Typography className="MRSHeading">
										Discover markid reNEWED selection
									</Typography>
									<Typography className="MRSDes">
										ReNEWED is our hand-picked selection of preowned items that are inspected, professionally cleaned, sanitized and restored to a lovingly reNEWED state.
									</Typography>
									<Typography className="attentionText">
										Just the way you’d like them - for your kids.
									</Typography>
									<Button className='button button-primary shopNowButton' onClick={() => handleRenewedHandPickedItem()}>
										Shop Now
									</Button>

									{/* <Row>
										
									</Row>
									<Row gutter={[0, 10]}>
										<Col span={24}></Col>
										<Col>
										
										</Col>
									</Row>
									<Row gutter={[0, 30]}>
										<Col span={24}></Col>
										<Col span={24}></Col>
										<Col>
										
										</Col>
									</Row>
									<Row gutter={[0, 34]}>
										<Col span={24}></Col>
										<Col>
											
										</Col>
									</Row> */}
								</Col>
								<Col span={10} >
									<img src={Cap} alt="" className='MRSCap' />
								</Col>
								{/* <Row>
						<img src={BgShape} style={{ marginTop: -150 }} alt="" />
					</Row> */}
							</Row>
						</div>

					</div>
				</Row>
			}
			{
				(isXS || isSM) &&
				<Row gutter={[0, { xs: 79, sm: 79 }]}>
					<Col span={24}></Col>
					<Col span={24}></Col>
					<div className='MRSBackgroundMob'>
						<Row justify='center'>
							<Col>
								<img src={Cap} alt="" className='MRSCapMob' />
							</Col>
						</Row>
						<Row gutter={[0, 26.33]} justify='center'>
							<Col span={24}></Col>
							<Col span={22}>
								<Row>
									<Typography className="MRSHeading">
										Discover markid reNEWED selection
									</Typography>
								</Row>
								<Row gutter={[0, 20]} justify='center'>
									<Col span={24}></Col>
									<Col>
										<Typography className="MRSDes">
											ReNEWED is our hand-picked selection of preowned items that are inspected, professionally cleaned, sanitized and restored to a lovingly reNEWED state.
										</Typography>
									</Col>
								</Row>
								<Row gutter={[0, 30]}>
									<Col span={24}></Col>
									<Col span={18}>
										<Typography className="attentionText">
											Just the way you’d like them - for your kids.
										</Typography>
									</Col>
								</Row>
								<Row gutter={[0, 30]} justify='center'>
									<Col span={24}></Col>
									<Col>
										<Button className='button button-primary shopNowButton' onClick={() => handleRenewedHandPickedItem()}>
											Shop Now
										</Button>
									</Col>
									<Col span={24}></Col>
									<Col span={24}></Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Row>
			}
		</>
	);
};
export default MRSelection;
