import React from "react";
import { Button, Row, Col } from "antd";
import AllBlog from "../assets/new-assets/AllBlog.png";
import ReadMoreVector from "../assets/new-assets/ReadMoreVector.png";
import Allblogposts from "./Allblogposts";
import bookmark from "../assets/new-assets/Bookmarkvector.png";
import shareicon from "../assets/new-assets/shareBlog.png";
const Bookmark = () => {
  return (
    <>
    <div className="favoriteContainer">
      <Row className="favoritecenter" gutter={16}>
        <Col className="gutter-row">
        <div class="AllBlogPost">
        <img src={AllBlog} alt="Avatar" style={{ width: "100%" }} />
        <span ><img src={shareicon}/><img src={bookmark}/></span>
        <div class="AllBlogContainer">
          <div className="BlogTimeReading">
            <b>x mins read</b>
          </div>
          <h2>
            <b className="AllBlogHeading">
              Starting your traveling blog with Vasco
            </b>
          </h2>
          <p className="BlogParagraph">
            Lorem ipsum dolor sit amet, et nam pertinax gloriatur. Sea te minim soleat senserit, ex quo luptatum tacimates voluptatum, salutandi delicatissimi eam ea. In sed nullam laboramus appellantur, mei ei omnis dolorem mnesarchum.
          </p>
        </div>
        <div class="AllBlogContainer">
          <p>
            may 13, 2022{" "}
            <Button>
              <div class="BlogReadMore">
                Read More
                <img src={ReadMoreVector} />
              </div>
            </Button>
          </p>
        </div>
      </div>
        </Col>
        <Col className="gutter-row">
        <div class="AllBlogPost">
        <img src={AllBlog} alt="Avatar" style={{ width: "100%" }} />
        <span ><img src={shareicon}/><img src={bookmark}/></span>
        <div class="AllBlogContainer">
          <div className="BlogTimeReading">
            <b>x mins read</b>
          </div>
          <h2>
            <b className="AllBlogHeading">
              Starting your traveling blog with Vasco
            </b>
          </h2>
          <p className="BlogParagraph">
          Lorem ipsum dolor sit amet, et nam pertinax gloriatur. Sea te minim soleat senserit, ex quo luptatum tacimates voluptatum, salutandi delicatissimi eam ea. In sed nullam laboramus appellantur, mei ei omnis dolorem mnesarchum.
          </p>
        </div>
        <div class="AllBlogContainer">
          <p>
            may 13, 2022{" "}
            <Button>
              <div class="BlogReadMore">
                Read More
                <img src={ReadMoreVector} />
              </div>
            </Button>
          </p>
        </div>
      </div> 
        </Col>
        <Col className="gutter-row">
        <div class="AllBlogPost">
        <img src={AllBlog} alt="Avatar" style={{ width: "100%" }} />
        <span ><img src={shareicon}/><img src={bookmark}/></span>
        <div class="AllBlogContainer">
          <div className="BlogTimeReading">
            <b>x mins read</b>
          </div>
          <h2>
            <b className="AllBlogHeading">
              Starting your traveling blog with Vasco
            </b>
          </h2>
          <p className="BlogParagraph">
          Lorem ipsum dolor sit amet, et nam pertinax gloriatur. Sea te minim soleat senserit, ex quo luptatum tacimates voluptatum, salutandi delicatissimi eam ea. In sed nullam laboramus appellantur, mei ei omnis dolorem mnesarchum.
          </p>
        </div>
        <div class="AllBlogContainer">
          <p>
            may 13, 2022{" "}
            <Button>
              <div class="BlogReadMore">
                Read More
                <img src={ReadMoreVector} />
              </div>
            </Button>
          </p>
        </div>
      </div>
        </Col>
      </Row>
      {/* <Row>
                  
                    <Col span={24} style={{ position: "relative" }}>
                      <Row
                        justify="space-around"
                        gutter={[
                          {
                            xs: 0,
                            sm: 0,
                            md: 30,
                            lg: 30,
                            xl: 30,
                          },
                          {
                            xs: 15,
                            sm: 15,
                            md: 45,
                            lg: 45,
                            xl: 45,
                          },
                        ]}
                      >
                        
                              <Col
                                xl={8}
                                lg={8}
                                md={12}
                                sm={11}
                                xs={11}
                               
                              >
                                <Allblogposts
                                  
                                />
                              </Col>
                        
                      </Row>
                     
                    </Col>
                  </Row> */}</div>
    </>
  );
};
export default Bookmark;
