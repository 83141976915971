import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Button, Alert, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useBreakpoints } from './../../react-responsive-hooks/index';
import { useForm, Controller } from "react-hook-form";
import MBImage from "../../assets/new-assets/blog-comingsoon-img.png";
import "./MarkidBlog.css"
import { Helmet } from "react-helmet";

const MarkidBlog = () => {
  const { isXS, isSM } = useBreakpoints();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
    },
  });
  return (
    <>
      <Helmet>
        <title>Markid - Blog</title>
      </Helmet>
      <Card bordered={false} className="mBCardBg">
        <div className="app-wrapper">
          <Row justify='center' gutter={[0, { xs: 15, sm: 15, md: 37, lg: 37, xl: 37, xxl: 37 }]}>
            <Col span={24}></Col>
            <Col xxl={24} xl={24} sm={24} xs={24}>
              <Card bordered={false} className='mBCard'>
                <Row justify='center' >
                  <div className='mBImgBg'>
                    <Row justify='center' >
                      <img alt="" src={MBImage} className='mBImage' />
                    </Row>
                  </div>
                  {/* <img alt="" src={MarkidCommunityImg} /> */}
                </Row>
                <Row justify='center' gutter={[0, 0]}>
                  <Col span={24}></Col>
                  <Col sm={15} xs={15}>
                    <Typography className='mBTitle'>
                      Coming soon
                    </Typography>
                  </Col>
                </Row>
                <Row justify='center' gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col xl={12} lg={13} md={14} sm={18} xs={18}>
                    <Typography className='mBDes'>
                      Our Markid Blog is only growing! Sign up for our newsletter to be alerted when the Markid Blog Platform Launches
                    </Typography>
                  </Col>
                </Row>
                <Row justify='center' gutter={[10, 45.29]}>
                  <Col span={24}></Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="center-col">
                    <Controller as={Input} name="email" placeholder="Enter your email id" control={control} size='large' className='mBEmailInput' /> <Button size='large' className='mBButton' icon={<RightOutlined />} />
                  </Col>

                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};
export default MarkidBlog;
