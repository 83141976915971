/*eslint-disable*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Avatar,
  Divider,
} from "antd";
import "../App.css";
import _ from "lodash";
const { Text, Title } = Typography;
const OrderConfirmation = () => {
  const { orderDetails } = useSelector((state) => {
    const { orderDetails } = state.order;
    return {
      orderDetails,
    };
  });
  const { _id, lots, orderTotalInCents, shippingTotalInCents, usedMarkidCreditInCents } = orderDetails
  const { price } = lots
  return (
    <>
      <Row gutter={[0, 0]}>
        <Col span={24}></Col>
        <Col>
          <Title className='your-cart-title' level={4}>
            Thank you, your order {_id} is confirmed!
          </Title>
        </Col>
      </Row>

      <Row gutter={[0, 8]}>
        <Col span={24}></Col>
        <Col xl={17} lg={17} md={17} sm={24} xs={24}>
          <Typography className='product-des'>
            You will receive an invoice mailed to you shortly, meanwhile you can
            chat with the seller, interact with parents on our communities or
            even try selling on Markid.
          </Typography>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col span={24}></Col>
        <Col>
          <Title className='notify-me' level={4}>
            Order summary
          </Title>
        </Col>
      </Row>
      {lots &&
          lots.map((val, i) => (
      <Row gutter={[0,16]} justify="space-between" key={i}>
        <Col span={24}></Col>
        <Col>
          <Row gutter={[16, 0]}>
            {/* <Col>
              <Avatar shape="square" size={50} />
            </Col> */}
            <Col>
              <Title className='items-total' level={5}>
                {val.name}
              </Title>
            </Col>
          </Row>
        </Col>
        <Col>
          <Col>
            <Typography className='checkout-brand-name'>${(val.price / 100).toFixed(2)}</Typography>
          </Col>
        </Col>
      </Row>
  ))}
      <Divider />
      <Row gutter={[0, 16]} justify="space-between">
        <Col span={24}></Col>
        <Col>
          <Row gutter={[0, 0]}>
            <Col></Col>
            <Col>
              <Typography className='items-total'>Items total</Typography>
            </Col>
          </Row>
        </Col>
        <Col>
          <Typography className='checkout-brand-name'>${(_.sumBy(lots, function(o) { return o.price; }) / 100).toFixed(2)}</Typography>
        </Col>
      </Row>
      <Row gutter={[0, 8]} justify="space-between">
        <Col span={24}></Col>
        <Col>
          <Row gutter={[0, 0]}>
            <Col></Col>
            <Col>
              <Typography className='items-total'>Shipping</Typography>
            </Col>
          </Row>
        </Col>
        <Col>
          <Typography className='checkout-brand-name'>${shippingTotalInCents}</Typography>
        </Col>
      </Row>
      <Row gutter={[0, 8]} justify="space-between">
        <Col span={24}></Col>
        <Col>
          <Row gutter={[0, 0]}>
            <Col></Col>
            <Col>
              <Typography className='items-total'>Markid Credits</Typography>
            </Col>
          </Row>
        </Col>
        <Col>
          <Typography className='checkout-brand-name'>- ${usedMarkidCreditInCents.toFixed(2)}</Typography>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[0, 8]} justify="space-between">
        <Col span={24}></Col>
        <Col>
          <Row gutter={[0, 0]}>
            <Col></Col>
            <Col>
              <Text className='items-total'>Cart total</Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <Text className='checkout-brand-name'>${orderTotalInCents /100}</Text>
        </Col>
      </Row>
      <Divider />
    </>
  );
};
export default OrderConfirmation;
