import API from '../Utils/API';
import constants from "../Utils/constants";
export const getAllBrands = () => dispatch => {
  dispatch({ type: "GET_BRANDS_REQUEST" })
  API.get('/api/v2/brands')
      .then(response => {
          dispatch({ type: "GET_BRANDS_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "GET_BRANDS_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}

export const getBrandById = (data) => dispatch => {
  dispatch({ type: "GET_BRAND_BY_ID_REQUEST" })
  API.get(`/api/v2/brands/${data}`)
      .then(response => {
          dispatch({ type: "GET_BRAND_BY_ID_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "GET_BRAND_BY_ID_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}

export const getBrandsByCategory = (category) => dispatch => {
  dispatch({ type: "GET_BRANDS_BY_CATEGORY_REQUEST" })
  API.get(`/api/v2/brands?category=${category}`)
      .then(response => {
          dispatch({ type: "GET_BRANDS_BY_CATEGORY_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "GET_BRANDS_BY_CATEGORY_FAILURE",
              payload: error.response.data,
            });
            setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}


export const setNavShow = (navVal) => dispatch => {
  dispatch({ type: "SET_NAV_SHOW", payload: navVal });
}

export const setNavList = (navlist) => dispatch => {
  dispatch({ type: "SET_NAV_LIST", payload: navlist });
}
export const setNavColors = (navcolor) => dispatch => {
  dispatch({ type: "SET_NAV_COLORS", payload: navcolor });
}
export const setCurrentNavSelected = (currNav) => dispatch => {
  dispatch({ type: "SET_CURRENT_NAV", payload: currNav });
}
export const getBrandlisting = (data) => (dispatch) => {
  let url = `/api/v5/markid-products/brands`;
  dispatch({ type: constants.GET_BRANDLIST_REQUEST, payload: data });
  API.get(url)
    .then((response) => {
      dispatch({
        type: constants.GET_BRANDLIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_BRANDLIST_FAILURE,
        payload: error.response.data,
      });
    });
};

export const setFilterCondition = (val) => dispatch => {
  dispatch({ type: "SET_FILTER_CONDITION", payload: val });
}
