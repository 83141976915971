import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Switch,
  Dropdown,
  Menu,
  Select,
  Button,
  Tooltip,
  Divider,
} from "antd";
import toolTip from "../assets/new-assets/infoicon.png";
import { useGaEvent } from "../hooks/useGaEvent";
import verified from "../assets/verifiednew.png";
import closeBtn from "../assets/new-assets/close-icon.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import renewedRoatate from "../assets/renewedRoatate.svg";
import { PLBreadCrumb } from "./PLBreadCrumb";
import { getAllmarkidProducts } from "../Actions/lotsAction";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { update } from "ramda";
import { Verified } from "../pages/verification";
const { Text } = Typography;
export const PFiltered = (props) => {
  const {
    selectedCategory,
    setSelectedCategory,
    category,
    itemType,
    brand,
    selectedSize,
    selectedAge,
    changesSortByLots,
    setSelectedAge,
    setSelectedSize,
    updateSelectedPriceRannge,
    renewed,
    selectedColor,
    selectedpricevalues,
    updateSelectedColor,
    setselectedColor,
    setSelectedGender,
    handleRenewedChange,
    handPickedName,
    sortByFeatured,
    setSortByFeaturedValue,
    sortByFeaturedValue,
    setsortByFeatured,
    selectedPriceRange,
    updateResetAllFilter,
    setSelectedGenderarr,
    filterCondition,
    currentPage,
    selectedBrand,
    setSelectedBrand,
    updateSelectedBrand,
    updateSelectedGender,
    setSelectedPurchaseYear,
    updateSelectedAge,
    updateSelectedSize,
    filtertags,
    updateSelectedPurchaseYear,
    selectedGender,
    selectedGenderarr,
    selectedPurchaseYear,
    updateSelectedCondition,
    selectedCondition,
    setSelectedCondition,
    selectedshippingarr,
    setSelectedshippingarr,
    updateSelectedShipping,
    setSelectedpricevalues,
    selectedShipping,
    initialPriceValues,
    priceValues,
    updateSelectedCategory,
    setPriceValues,
    showsortfilterDialog,
    setshowsortfilterDialog,
    hits
  } = props;
  const handleCancel = () => {
    setshowsortfilterDialog(false);
  };
 
  const { gaPageView, gaEventClick } = useGaEvent();
  const { brand: brandName } = brand || "";
  const [switchVal, setSwitchVal] = useState(
    renewed !== null && renewed !== undefined ? renewed : false
  );
  useEffect(()=>{
    if (currentPage[3] === "clothing_and_accessories"||currentPage[3] === "shoes"||currentPage[1]==="verifiedshops"||currentPage[3]=="toys_and_costumes") {
      setSwitchVal(!switchVal)
    }
},[])

  const dispatch = useDispatch();
  function onChange(checked) {
    gaEventClick("PLP", "Shop Top Brands")
    setSwitchVal(checked);
    handleRenewedChange(!switchVal);  }

  const sortingByLots = (sortingVal, name) => {
    setSortByFeaturedValue(name);
    changesSortByLots(sortingVal);
  };
  // const deliveryMenu = (
  //   <Menu>
  //     <Menu.Item></Menu.Item>
  //   </Menu>
  // );
  // const featuredMenu = (
  // <Menu onClick={(e) => setSortByFeatured(console.log(e.key))} selectedKeys={[sortByFeatured]}>
  //     <Menu.Item onClick={() => sortingByLots('priceAsc')} key='1'>
  //       Price low to high
  //     </Menu.Item>
  //     <Menu.Item onClick={() => sortingByLots('priceDesc')} key='2'>
  //       Price high to low
  //     </Menu.Item>
  //     <Menu.Item onClick={() => sortingByLots('createdAtDesc')} key='3'>
  //       Date of Listing
  //     </Menu.Item>
  //   </Menu>
  // )
  const { isXS, isSM } = useBreakpoints();
  const optionsList = [
    { label: "Relevance", name: "", keyName: "Relevance" },
    {
      label: "Price high to low",
      name: "priceDesc",
      keyName: "Price high to low",
    },
    {
      label: "Price low to high",
      name: "priceAsc",
      keyName: "Price low to high",
    },
    {
      label: "Date of Listing",
      name: "createdAtDesc",
      keyName: "Date of Listing",
    },
  ];
  
  const optionsListdesc = [
    {
      label: "Date of Listing",
      name: "createdAtDesc",
      keyName: "Date of Listing",
    },
    { label: "Relevance", name: "", keyName: "Relevance" },
    {
      label: "Price high to low",
      name: "priceDesc",
      keyName: "Price high to low",
    },
    {
      label: "Price low to high",
      name: "priceAsc",
      keyName: "Price low to high",
    },
  ];
  const handleclick = (e) => {
    const target = optionsList.filter((obj) => {
      return obj.name === e.target.value;
    });
    setSortByFeaturedValue(target.keyName);
    changesSortByLots(e.target.value);
  };

  const removefiltertags = (val) => {
    if (selectedBrand?.includes(val)) {
      const newPeople = selectedBrand.filter((person) => person !== val);
      setSelectedBrand(newPeople);
      updateSelectedBrand(val);
    }
    if (selectedGenderarr?.includes(val)) {
      const newPeople = selectedGenderarr.filter((person) => person !== val);
      setSelectedGenderarr(newPeople);
      if (val == "Girl") {
        updateSelectedGender("female");
      }
      if (val == "Boy") {
        updateSelectedGender("male");
      }
      if (val == "Unisex") {
        updateSelectedGender("unisex");
      }
    }
    if (val == "Neutral") {
      updateSelectedGender("neutral");
    }

    if (selectedPurchaseYear.includes(val)) {
      const newPeople = selectedGender.filter((person) => person !== val);
      setSelectedPurchaseYear(newPeople);
      updateSelectedPurchaseYear(val);
    }
    if (selectedCondition?.includes(val)) {
      const newPeople = selectedCondition.filter((person) => person !== val);
      setSelectedCondition(newPeople);
      updateSelectedCondition(val);
    }
    if (selectedColor?.includes(val)) {
      const newPeople = selectedColor.filter((person) => person !== val);
      setselectedColor(newPeople);
      updateSelectedColor(val);
    }

    if (selectedshippingarr?.includes(val)) {
      const newState = { shipping: val, value: false };
      updateSelectedShipping(newState, 0);
    }
    if (selectedAge?.includes(val)) {
      const newPeople = selectedAge.filter((person) => person !== val);
      setSelectedAge(newPeople);
      updateSelectedAge(val);
    }
    if (selectedSize?.includes(val)) {
      const newPeople = selectedSize.filter((person) => person !== val);
      setSelectedSize(newPeople);
      updateSelectedSize(val);
    }
    if (selectedCategory?.includes(val)) {
      const newPeople = selectedCategory.filter((person) => person !== val);
      setSelectedCategory(newPeople);
      updateSelectedCategory(val);
    }
    if (selectedpricevalues?.includes(val)) {
      if (val === "$0 to $10") {
        const newPeople = selectedpricevalues.filter(
          (person) => person !== val
        );

        setSelectedpricevalues(newPeople);
        setPriceValues(initialPriceValues);
        updateSelectedPriceRannge([0, 2500]);
      }
      if (val === "$10 to $50") {
        const newPeople = selectedpricevalues.filter(
          (person) => person !== val
        );
        setSelectedpricevalues(newPeople);
        setPriceValues(initialPriceValues);
        updateSelectedPriceRannge([0, 2500]);
      }
      if (val === "$50 to $100") {
        const newPeople = selectedpricevalues.filter(
          (person) => person !== val
        );
        setSelectedpricevalues(newPeople);
        setPriceValues(initialPriceValues);
        updateSelectedPriceRannge([0, 2500]);
      }
    }
  };

  const sliderMin = 0;
  const sliderMax = 2500;
  const [sliderMinVal, setSliderMinVal] = useState(
    selectedPriceRange?.min ? selectedPriceRange?.min : 0
  );
  const [sliderMaxVal, setSliderMaxVal] = useState(
    selectedPriceRange?.max ? selectedPriceRange?.max : 0
  );

  useEffect(() => {
    if (Object.keys(selectedPriceRange).length > 0) {
      setSliderMinVal(0);
      setSliderMaxVal(0);
    }
  }, [selectedPriceRange]);
  const priceFilter = {
    [sliderMin]: (
      <Typography.Text
        style={{ width: "auto" }}
        ellipsis={{ tooltip: sliderMin }}
        className="pFSliderPriceVal minimum"
      >
        ${sliderMin}
      </Typography.Text>
    ),
    [sliderMax]: (
      <Typography.Text
        style={{ width: "auto" }}
        ellipsis={{ tooltip: sliderMax }}
        className="pFSliderPriceVal maximum"
      >
        ${sliderMax}
      </Typography.Text>
    ),
    // [sliderMin]: <Typography className='pFSliderPriceVal'>${sliderMin}</Typography>,
    // [sliderMax]: <Typography className='pFSliderPriceVal'>${sliderMax}</Typography>,
  };
  const handleResetAll = () => {
    //onChangedPriceRange([0, 0]);
    setSliderMinVal(0);
    setSliderMaxVal(0);
    setPriceValues([
      { title: "$0 to $10", value: false },
      { title: "$10 to $50", value: false },
      { title: "$50 to $100", value: false },
    ]);
    updateResetAllFilter();
    // localStorage.removeItem("Selected-Brands");
    // localStorage.removeItem("Selected-Genders");
    // localStorage.removeItem("Selected-YearofPurchase");
    // localStorage.removeItem("Selected-Condition");
    // localStorage.removeItem("Selected-Shipping");
    // localStorage.removeItem("Selected-Size");
    // localStorage.removeItem("Selected-Price-Range");
    // if (currentPathElement[1] === "search-new") {
    //   history.replace({
    //     search: `refresh=true&zipCode=${zipCode}`,
    //   });
    // } else {
    //   history.replace({
    //     search: "",
    //   });
    // }
  };
  let getTagsList = () => {
    if (filtertags?.length > 0) {
      return filtertags.map((category) => {
        return (
          <Col className="tag">
            {category}{" "}
            <span
              className="removeTag"
              onClick={() => removefiltertags(category)}
            >
              x
            </span>
          </Col>
        );
      });
    }
  };
  return (
    <div className="pFilteredParent test">
      <div className="app-wrapper">
        <Row
          gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }]}
          className="app-breadcrumb"
        >
          {/* <Col span={24}></Col> */}
          <Col span={2}></Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card className="pFiltercard">
              <Row
                justify="space-between"
                gutter={[0, { xs: 0, sm: 0, md: 16, lg: 16, xl: 16 }]}
               
              >
                <Col sm={10} xl={8}>
                  {/* <div className="pBreadCrumbWeb">
                    <PLBreadCrumb
                      markid="Home"
                      category={
                        (itemType === "category" && category?.displayValue) ||
                        (itemType === "brand" && brandName) ||
                        (itemType === "handpicked" && handPickedName)
                      }
                    />
                  </div> */}
                  <Row gutter={[14, 0]} className="pFReNewedBlock">
                    <Col>
                      <Row gutter={[5, 0]}>
                        <Col>
                          <img alt="" src={verified} width="18px"/>
                        </Col>
                        <Col>
                          <Typography className="pFReNewedText">
                            Verified Shops
                          </Typography>
                         
                        </Col> <span>
                          <Tooltip
                          placement="bottomLeft"
                          title="This item is reviewed by our specialists and shipped straight from our verified shop to your home as part of our Verified shop program. The customer service staff at Markid is happy to answer any inquiries. All returns are free and shipping on orders over $50 is also free! "
                        >
                          <img src={toolTip} className="tooltip" width="18px" />{" "}
                        </Tooltip></span>
                      </Row>
                    </Col>
                    <Col>
                      <Switch
                        onChange={onChange}
                        className={switchVal ? "pSwitch" : "pSwitchOff"}
                        checked={switchVal}
                      />
                    </Col>
                  </Row>
                </Col>
                <div className="pFItemMob">
                  <div>
                    <BottomSheet open={showsortfilterDialog}>
                      <Col onClick={handleCancel}>
                        <img className="ShareModalimgClose" src={closeBtn} />
                      </Col>
                      <Row justify="space-between" className="pFilterHeader">
                        <Col>
                          <Typography className="pFilterTitle">
                            Sort by
                          </Typography>
                        </Col>
                      </Row>
                      <Divider
                        style={{
                          borderTop: "1px solid #D4D4D4",
                          marginTop: 12,
                          marginBottom: 1,
                        }}
                      />
                      <Col
                        onClick={() => {
                          sortingByLots("", "Relevance");
                          gaEventClick("Sort by","Relevance")
                          setshowsortfilterDialog(false);
                        }}
                      >
                        <Typography className="pFSortByTitle">
                          Relevance
                        </Typography>
                      </Col>
                      <Col
                        onClick={() => {
                          sortingByLots("priceDesc", "Price high to low");
                          setshowsortfilterDialog(false);
                          gaEventClick("Sort by","Price high to low")
                        }}
                      >
                        <Typography className="pFSortByTitle">
                          Price: High to low
                        </Typography>
                      </Col>
                      <Col
                        onClick={() => {
                          sortingByLots("priceAsc", " Price low to high");
                          setshowsortfilterDialog(false);
                          gaEventClick("Sort by","Price low to high")
                        }}
                      >
                        <Typography className="pFSortByTitle">
                          Price: Low to High
                        </Typography>
                      </Col>
                      <Col
                        onClick={() => {
                          sortingByLots("createdAtDesc", " Date of Listing");
                          setshowsortfilterDialog(false);
                          gaEventClick("Sort by","Date of Listing")
                        }}
                      >
                        <Typography className="pFSortByTitle">
                          Date Of listing
                        </Typography>
                      </Col>
                    </BottomSheet>
                  </div>
                </div>

                <div className="pFItemWeb">
                  <Col span={24}>
                    <Row gutter={[0, 0]}>
                      <Col>
                        <Row gutter={[66, 0]}>
                          {/* <Col>
                          <Dropdown
                            overlay={deliveryMenu}
                            // visible={visibleInput}
                            placement="bottomCenter"
                            arrow
                          >
                            <Text type="link" className="pFDelivery">
                              Delivery{" "}
                              <DownOutlined
                                style={{ marginLeft: "5px", color: "#393939" }}
                              />
                            </Text>
                          </Dropdown>
                        </Col> */}
                          <Col>
                            <Row gutter={[9, 0]}>
                              <Col>
                                <Typography className="pFSort">
                                  Sort by
                                </Typography>
                              </Col>
                              <Col className="sortFilter">
                                <Dropdown
                                  overlayClassName="cardSortFilter"
                                  overlay={
                                    <Menu
                                      onClick={(e) => null}
                                      /* 20211124 Varun: Commneted until sort functionality is added to lots-searchable api */
                                      // setSortByFeatured(
                                      //     e.key
                                      // )

                                      selectedKeys={[sortByFeatured]}
                                    >
                                      <Menu.Item
                                        onClick={() =>{
                                          sortingByLots("", "Relevance");gaEventClick("Sort by","Relevance")}
                                        }
                                        key="0"
                                      >
                                        Relevance
                                      </Menu.Item>
                                      <Menu.Item
                                        onClick={() =>{
                                          sortingByLots(
                                            "priceAsc",
                                            " Price low to high"
                                          );
                                          gaEventClick("Sort by","Price low to high")
                                        }}
                                        key="1"
                                        // disabled={sortByFeatured === sortByFeaturedValue ? true : false}
                                      >
                                        Price low to high
                                      </Menu.Item>
                                      <Menu.Item
                                        onClick={() =>{
                                          sortingByLots(
                                            "priceDesc",
                                            "Price high to low"
                                          )
                                          gaEventClick("Sort by","Price high to low")
                                        }}
                                        key="2"
                                      >
                                        Price high to low
                                      </Menu.Item>
                                      <Menu.Item
                                        onClick={() =>{
                                          sortingByLots(
                                            "createdAtDesc",
                                            " Date of Listing"
                                          );
                                          gaEventClick("Sort by","Date of Listing")
                                        }}
                                        key="3"
                                      >
                                        Date of Listing
                                      </Menu.Item>
                                    </Menu>
                                  }
                                  // visible={visibleInput}
                                  placement="bottomCenter"
                                  arrow
                                >
                                  <Text
                                    type="link"
                                    // className="pFDelivery"
                                    className="pFDeliveryVal"
                                  >
                                    {sortByFeaturedValue}{" "}
                                    <DownOutlined
                                      className="arrow-down"
                                      // style={{
                                      //   marginLeft: '5px',
                                      //   fontSize: 12,
                                      //   color: '#393939',
                                      //   fontWeight: 'bolder',
                                      // }}
                                    />
                                  </Text>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col>
                    
                      <div className="pFItemWeb">
                        <Button className="saveSearchButton">
                          Save this search
                          <HeartOutlined />
                        </Button>
                      </div>
                    </Col> */}
                    </Row>

                    {/* <div className='pFItemMob'>
            <Row justify='space-between' gutter={[0,15]}>
            <Col span={24}></Col>
            <Col>
            <Button className='saveSearchButton'>
            Save this search
            <HeartOutlined />
            </Button>
            </Col>
            </Row>
          </div> */}
                  </Col>
                </div>
              </Row>
            </Card>
          </Col>
          {!isXS && (
            <Row className="fiterTags">
              {getTagsList()}{" "}
              {!isXS && filtertags && filtertags.length > 0 && (
                <span>
                  <Button
                    type="text"
                    className="resetAllButton style2"
                    onClick={() => {handleResetAll();gaEventClick("Filter by", "Clear all")}}
                  >
                    Clear all
                  </Button>
                </span>
              )}
            </Row>
          )}
        </Row>
      </div>
    </div>
  );
};
