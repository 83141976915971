import { Layout, Row, Col, Typography, Button, Card } from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import logo from "../assets/logo.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
// import MobileStoreButton from 'react-mobile-store-button';
import AppStoreButton from "@fenderdigital/react-app-store-button";
import "../App.css";
import { Link } from "react-router-dom";
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});

const { Title, Text } = Typography;
const { Footer } = Layout;


const ReNewedFooter = () => {
  const { isXS } = useBreakpoints();
//   const iOSUrl =
//     "https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8";

  return (
    <Row gutter={[24,{ md:120, lg:120, xl:120, xxl:120}]} align='middle'>
      <Col span={24}/>
      <Layout>
        <div className="footerParent">
      <Footer style={{backgroundColor:'#E9F8F8'}}>
        <Row gutter={[24,8]} justify='start'>
          <Col xl={12} lg={12} md={24}>
            <Row gutter={[0,{md:80, lg:80, xl:80, xxl:80}]}>
              <Col span={24}/>
                <Link to="/">
                <img className="markidLogo" alt="logo" src={logo} />
                </Link>
            </Row>
            <Row  gutter={[8,{md:92, lg:92, xl:92, xxl:92}]}>
            <Col span={24}/>
            <Text type="secondary">Download the app</Text>
            </Row>
            <Row gutter={[8, 16]} >
              <Col span={24}></Col>
              {/* <Col>
                <AppStoreButton
                  className="mobile-store-button"
                  variant="google"
                />
              </Col> */}
              <Col>
                <AppStoreButton
                  className="mobile-store-button"
                  variant="apple"
                />
              </Col>
            </Row>
          </Col>
          <Col xl={4} lg={4} md={8}>
            <Row gutter={[8,{md:103, lg:103, xl:103, xxl:103}]}>
              <Col span={24}/>
              <Text className="footer-header">Shop</Text>
            </Row>
            <Row gutter={[0,21]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/" className="footer-detail">
              Home
              </Link>
              </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/#categories" className="footer-detail">
                Categories
              </Link>
              </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/renewed-page" className="footer-detail">
                ReNewed
              </Link>
              </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/contact-us" className="footer-detail">
                Contact Us
              </Link>
              </Col>
            </Row>

          </Col>
          <Col xl={4} lg={4} md={8}>
            <Row gutter={[8,{md:103, lg:103, xl:103, xxl:103}]}>
              <Col span={24}/>
            <Text className="footer-header">Inside markid</Text>
            </Row>
            <Row gutter={[0,21]}>
                <Col span={24}></Col>
                <Col>
            <Link to="/contact-us" className="footer-detail">
              Contact Us
              </Link>
              </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/about-us" className="footer-detail">
                About Us
            </Link>
            </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/about-us" className="footer-detail">
                Investors
                </Link>
                </Col>
            </Row>
          </Col>

          <Col xl={4} lg={4} md={8}>
            <Row gutter={[8,{md:103, lg:103, xl:103, xxl:103}]}>
              <Col span={24}/>
            <Text className="footer-header">FAQ</Text>
            </Row>
            <Row gutter={[0,21]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/general-questions" className="footer-detail">
              General Questions
              </Link>
              </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/buyer-hub" className="footer-detail">
              Buyer Hub
              </Link>
              </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/seller-hub" className="footer-detail">
              Seller Hub
              </Link>
              </Col>
            </Row>
            <Row gutter={[0,23]}>
            <Col span={24}></Col>
                <Col>
                <Link to="/community-guide" className="footer-detail">
              Community Guide
              </Link>
              </Col>
            </Row>
          </Col>

        </Row>

      {/* <Row align="middle"> */}
        {/* <Col span={24}> */}
          {/* <Card style={{ backgroundColor: "#E9F8F8" }}> */}
          <Row gutter={[0,80]}>
            <Col span={24}>
            <Row gutter={[{ md:0, lg:0, xl:0, xxl:0},{md:80, lg:80, xl:80, xxl:80}]} justify={"space-between"}>
              <Col span={24}/>
              <Col>
                <Row gutter={[{ md:8, lg:8, xl:8, xxl:8},0]}>
                  <Col>
                  <Link className='footer-button' to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </Col>
                  <Col>
                  <Link className='footer-button' to="/about-us">
                      Investors
                    </Link>
                  </Col>
                  <Col>
                  <Link className='footer-button' to="/terms-and-conditions">
                      Terms & Conditions
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col>
              <Row gutter={[20,0]}>
                <Col>
                  <IconFont type="icon-facebook" className="socialIcons"/>
                </Col>
                <Col>
                  <InstagramOutlined className="socialIcons"/>
                </Col>
              </Row>
              </Col>
              <Col>
                <Text className="footer-button">2021 markid Inc. All rights reserved</Text>
              </Col>
            </Row>
            </Col>
            <Col span={24}/>
            </Row>
          {/* </Card> */}
        {/* </Col> */}
      {/* </Row> */}
      </Footer>
      </div>
    </Layout>
    </Row>
  );
};
export default ReNewedFooter;

