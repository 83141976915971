import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import { Modal, Row, Col, Typography, Input, Button, Radio, Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { createMakeOffer } from "../Actions/offerAction";
const schema = yup.object().shape({
  price: yup.string("Offer Price is require"),
});
export const MakeOffer = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const history = useHistory()
  const dispatch = useDispatch()
  const { showMakeOfferDialog, onCancelMakeOfferDialog, makeOfferData, negotiablePrice } = props;
  const {
    price,
    _id,
    deliveryType,
  } = makeOfferData;
  const { currentUser, isAuthenticated, isPostOfferLoading, errorMessage } = useSelector((state) => {
    const { currentUser } = state.user;
    const { isAuthenticated } = state.auth;
    const { isPostOfferLoading, errorMessage } = state.offer
    return {
      currentUser,
      isAuthenticated,
      isPostOfferLoading,
      errorMessage
    };
  });

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("shipping");
  const [priceOffVal, setPriceOffVal] = useState("");
  const { handleSubmit, control, reset, errors, setValue: setFormValues } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
  }, [errorMessage]);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  // const handlemakeOfferClick = () => {
  //   history.push("/chat")
  //   }
  const onChange = e => {
    setValue(e.target.value);
  };
  const handlemakeOfferClick = (data) => {
    // const deliveryNeededVal = value === "shipping" ? data.deliveryNeeded = true : data.deliveryNeeded = false
    if (isAuthenticated) {
      // data.deliveryNeeded = deliveryNeededVal
      data.lotId = _id;
      // data.addressId = value === "meetup" && null
      const p = (negotiablePrice != false) ? parseFloat(data.price) : makeOfferData.price
      data.price = (negotiablePrice != false) ? p * 100 : p
      if (value === 'shipping' && (deliveryType !== 'meetup' && deliveryType !== 'both')) {
        data.shippingPrice = (makeOfferData.shippingPrice ? makeOfferData.shippingPrice : '0')
        dispatch({ type: "OFFER_PAYMENT_STATRTED", payload: { offerPaymentLoader: false } })
        dispatch({ type: 'MADE_OFFER_DATA', payload: [{ makeOfferData, offerData: data }] })
        history.push('/made-offer')
      }
      else {
        dispatch(createMakeOffer(data, successCallBack, failureCallBack));

      }
    }
    else {
      history.push('/onboarding')
    }
  }

  const successCallBack = () => {
    reset()
    history.push("/chat")

    window.location.reload()
  };
  const failureCallBack = () => { };
  const handleCancel = () => {
    reset()
    onCancelMakeOfferDialog()
  }
  const handlePriceOff = (offPriceInputVal) => {
    setFormValues("price", offPriceInputVal)
    setPriceOffVal(offPriceInputVal)
  }
  const tenPercent = price * 10 / 100
  const tenPercentVal = price - tenPercent

  const sixPercent = price * 6 / 100
  const sixPercentVal = price - sixPercent

  const threePercent = price * 3 / 100
  const threePercentVal = price - threePercent
  return (
    <>
      <Modal
        className='makeOfferModal'
        title={
          <Row justify='start'>
            <Typography className='makeOfferTitle'>
              Make an offer
            </Typography>
          </Row>
        }
        visible={showMakeOfferDialog}
        onCancel={() => handleCancel()}
        centered
        footer={null}
        width={362}
      >
        <form onSubmit={handleSubmit(handlemakeOfferClick)}>
          <Spin style={{ color: '#FF533F' }} indicator={antIcon} spinning={(isPostOfferLoading)}>
            {/* {deliveryType === 'both' &&
              <>
                <Row gutter={[0, 38]}>
                  <Col span={24}></Col>
                  <Col>
                    <Typography className='makeOfferContentTitle'>
                      Which delivery method you choose?
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Col>
                    <Radio.Group onChange={onChange} value={value}>
                      <Row gutter={[32, 0]}>
                        <Col>
                          <Radio value={"shipping"}><span className='makeOfferRadioTitle'>Shipping</span></Radio>
                        </Col>
                        <Col>
                          <Radio value={"meetup"}><span className='makeOfferRadioTitle'>Meetup</span></Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Col>
                </Row>
              </>
            } */}
            {/* {negotiablePrice != false ? 
            <> */}
            <Row gutter={[0, 35]}>
              <Col span={24}></Col>
              <Col>
                <Typography className='makeOfferContentTitle'>
                  Choose or enter you offer price
                </Typography>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={24}></Col>
              <Col>
                <Button size='large' className='questionsTextButton'
                  onClick={() => handlePriceOff(tenPercentVal / 100)}
                >
                  $ {tenPercentVal / 100}
                </Button>
                <Row justify='center' gutter={[0, 5]}>
                  <Col span={24}></Col>
                  <Typography className='offPercentage'>
                    10% off
                  </Typography>
                </Row>
              </Col>
              <Col>
                <Button size='large' className='questionsTextButton'
                  onClick={() => handlePriceOff(sixPercentVal / 100)}
                >
                  $ {sixPercentVal / 100}
                </Button>
                <Row justify='center' gutter={[0, 5]}>
                  <Col span={24}></Col>
                  <Typography className='offPercentage'>
                    6% off
                  </Typography>
                </Row>
              </Col>
              <Col>
                <Button size='large' className='questionsTextButton'
                  onClick={() => handlePriceOff(threePercentVal / 100)}
                >
                  $ {threePercentVal / 100}
                </Button>
                <Row justify='center' gutter={[0, 5]}>
                  <Col span={24}></Col>
                  <Typography className='offPercentage'>
                    3% off
                  </Typography>
                </Row>
              </Col>
            </Row>
            <Row gutter={[0, 15]}>
              <Col span={24}></Col>
              <Col>
                <Typography className='makeOfferContentEnterPrice'>
                  Enter offer price
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, 15]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Controller
                  as={Input}
                  // defaultValue={priceOffVal} 
                  required
                  addonBefore="$"
                  className='mAOInput'
                  size='large'
                  name="price"
                  control={control}
                  placeholder="eg. 750"
                />
                {errors.price && (
                  <span style={{ color: "red" }}>
                    Offer Price is required
                  </span>
                )}
              </Col>
            </Row>
            {/* </> : <></>} */}
            {errorMessage &&
              <Row justify='center' gutter={[0, 20]}>
                <Col span={24}></Col>
                <Alert
                  type="error"
                  onClose={handleClose}
                  open={open}
                  message={errorMessage}
                />
              </Row>}
            <Row justify='end' gutter={[0, deliveryType === 'both' ? 40 : 128]}>
              <Col span={24}></Col>
              <Col>
                <Button size='large' className={'button button-primary makeOfferButton'}
                  htmlType='submit'
                // onClick={() => handlemakeOfferClick()}
                // disabled={priceInputVal > 0 ? false : true}
                >
                  MAKE OFFER
                </Button>
              </Col>
              {/* <Col>
      <Button type="text" onClick={() => onCancelMakeOfferDialog()}>CANCEL</Button>
    </Col> */}
            </Row>
          </Spin>
        </form>
      </Modal>
    </>
  );
};
