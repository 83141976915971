import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginSignup } from "../Actions/authAction";
import { Card, Typography, Row, Col, Button, Input, Spin, Alert } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import markidlogo from "../assets/new-assets/markidlogo.png";
import markidlogo2 from "../assets/new-assets/weblogomarkid.png";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";
import AppleSignin from "react-apple-signin-auth";
import FacebookLogin from "react-facebook-login";
import AmazonImg from "../assets/AmazonImg.svg";
import AmazonImg1 from "../assets/amazonsignin.png";
import AppleImg from "../assets/AppleImg.svg";
import AppleImg1 from "../assets/applesignin.png";
import amazon from "../assets/amazonicon.svg";
import apple from "../assets/appleicon.svg";
import facebookiconimg from "../assets/facebookiconlogin.svg";
import FacebookImg from "../assets/FacebookImg.svg";
import FacebookImgBtn from "../assets/facebookimgbtn.png";
import { useGaEvent } from "../hooks/useGaEvent";
import {
  getOtp,
  signupWithFacebook,
  signupWithApple,
} from "../Actions/authAction";
import { getUser } from "../Actions/userAction";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
export const SendOtpFormLoginMob = (props) => {
  const { current, setCurrent, phoneNumber, setPhoneNumber } = props;
  const schema = yup.object().shape({
    phone: yup
      .string()
      .matches(phoneRegExp, "Please enter valid Phone Number."),
  });
  const dispatch = useDispatch();
  const {
    isCreateSendOtpLoading,
    getOtperror,
    providerToken,
    isAuthenticated,
    loginSignup
  } = useSelector((state) => {
    const {
      isCreateSendOtpLoading,
      getOtperror,
      providerToken,
      isAuthenticated,
      loginSignup
    } = state.auth;
    return {
      isCreateSendOtpLoading,
      getOtperror,
      providerToken,
      isAuthenticated,
      loginSignup
    };
  });
  const { gaEventClick } = useGaEvent();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { innerHeight } = window;
  const [FbError, setFbError] = useState("");
  const [AppleError, setAppleError] = useState("");
  // const [seconds, setSeconds] = useState(60);
  // const [resedEnable, setResedEnable] = useState(false);
  const { handleSubmit, control, errors, reset, watch, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: { phone: "" },
  });
  // console.log({formState})
  const watching = watch();
  const { phone } = watching;
  const pl = phone.length;
  const handleAmazonLogin = () => {
    gaEventClick('Registration', 'Amazon');
    history.push("/amazon");
  };
  const handleOtp = (data) => {
    gaEventClick('Registration', 'Login');
    if (data.phone) {
    } else {
      data = {};
      data.phone = phoneNumber;
    }
    dispatch(getOtp(data, successCallBackGetOtp, failureCallBackGetOtp));
    setPhoneNumber(data.phone);
  };
  const successCallBackGetOtp = () => {
    if (current === 0) {
      setCurrent(current + 1);
      reset();
    }
  };
  const failureCallBackGetOtp = () => {};

  const responseFacebook = (response) => {
    gaEventClick('Registration', 'Facebook');
    // const { accessToken, id, name, picture, userID, signedRequest } = response;
    if (response.accessToken) {
      dispatch(signupWithFacebook(response));
    } else {
      setFbError("Something went wrong. Try again.");
    }
  };
  const responseAppleSignin = (response) => {
    gaEventClick('Registration', 'Apple');
    if (response.authorization) {
      let fullName = "";
      let email = response?.user?.email ? response.user.email : "";
      if (response?.user?.name?.firstName) {
        fullName = fullName + response?.user?.name?.firstName;
      }
      if (fullName && response?.user?.name?.lastName) {
        fullName = fullName + " " + response?.user?.name?.lastName;
      } else if (response?.user?.name?.lastName) {
        fullName = fullName + response?.user?.name?.lastName;
      }
      dispatch(
        signupWithApple({
          email,
          fullName,
          identityToken: response?.authorization?.id_token,
          appleUserId: response?.authorization?.code,
        })
      );
    } else {
      setAppleError("Something went wrong. Try again.");
    }
  };
  React.useEffect(() => {
    if (providerToken) {
      localStorage.setItem("access_token", providerToken);
       history.goBack();
      if (!isAuthenticated) {
        dispatch(getUser());
      }
    }
  }, [providerToken]);
  React.useEffect(() => {
    handleClick();
  }, [getOtperror]);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div>
      <Spin
        style={{ top: 200, color: "#FF533F" }}
        indicator={antIcon}
        spinning={isCreateSendOtpLoading}
      >
        <div className="sendOtpParent1">
          <Card bordered={false}>
            <div className="onboardingWeb">
              <div className="closeicon-wrap">
                <CloseOutlined
                  className="oBCloseIcon"
                  onClick={() => history.goBack()}
                />
              </div>
              <Row gutter={[0, 15]} justify="end">
                <Col span={24} />
                <Col
                  className="signupnewtxt">
                  {/* <Link to="/homepage"> */}
                  {/* <Link to="/">
                    <img alt="logo" src={Logo} className="logoStyle weblogo " />
                  </Link> */}
                  Already have an account ?<span className="loginBtn"  onClick={() => {
                    dispatch(setLoginSignup("login"));
                    history.push("/onboarding");
                  }}>Signup</span>
                </Col>
              </Row>
            </div>
            <div className="onboardingMob">
              <div className="closeicon-wrap">
                {/* <CloseOutlined
                  className="oBCloseIcon"
                  onClick={() => history.goBack()}
                /> */}
                 <span className="Skipbutton"  onClick={() => history.goBack()}>
                skip</span>
              </div>
            </div>
            <div className="onboardingMob">
              <Row
                gutter={[
                  0,
                  { xs: 36, sm: 36, md: 32.5, lg: 32.5, xl: 32.5, xxl: 32.5 },
                ]}
                justify="end"
              >
                <Col span={24}></Col>
                <Col className="signuptext">
                  {/* <Link to="/homepage"> */}
                  {/* <Link to="/">
                    <img alt="logo" src={ShippingLogo} className='markidLogoMob' />
                  </Link> */}
                  {/* Don't have an account ? */}
                  {/* <span className="Skipbutton"  onClick={() => {
                    dispatch(setLoginSignup("signup"));
                    history.push("/onboarding");
                  }}>skip</span> */}
                </Col>
              </Row>
            </div>
            <Row justify="centre" style={{marginTop:"10px"}}> <img src={markidlogo2} style={{margin:"0px auto"}}/></Row>
           
            <Row
              gutter={[
                0,
                { xs: 20, sm: 20, md: 32.5, lg: 32.5, xl: 32.5, xxl: 32.5 },
              ]}
              justify="center"
            >
              <Col span={24}></Col>
              {/* <Col span={24}></Col> */}
              <Col>
                <Typography className="createtext">
                Sign into Markid
                  {/* <span>
                    <img
                      alt="logo"
                      src={markidlogo}
                      className="markidweblogo "
                    />
                  </span>{" "} */}
                </Typography>
                {/* <Typography className="heading-descnew style2">
                Login to your account
                </Typography> */}
              </Col>
            </Row>
            <Row
              gutter={[
                0,
                { xs: 5, sm: 5, md: 32.5, lg: 32.5, xl: 32.5, xxl: 32.5 },
              ]}
              justify="center"
            >
              <Col span={24}></Col>
              {/* <Col span={24}></Col> */}
              <Col>
                <Typography className="parentmarket">
                The marketplace for parents
                </Typography>
                {/* <Typography className="heading-descnew style2">
                Login to your account
                </Typography> */}
              
              </Col>
            </Row>
            <Row
              gutter={[
                0,
                { xs: 5, sm: 5, md: 32.5, lg: 32.5, xl: 32.5, xxl: 32.5 },
              ]}
              justify="center"
            >
              <Col span={24}></Col>
              {/* <Col span={24}></Col> */}
              <Col>
                <Typography className="parentmarket1">
                "Get up to 20% off your first order!”
                </Typography>
                
              </Col>
            </Row>

            {/* <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[0, { xs: 2, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }]} justify='center'>
                  <Col span={24}></Col>
                  <Col>
                    <Typography className='alreadyAccountText style2'>
                      Sign into markid with
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row> */}

            <form id="getOtp" onSubmit={handleSubmit(handleOtp)}>
              <Row
                gutter={[
                  0,
                  { xs: 35, sm: 35, md: 42.8, lg: 42.8, xl: 42.8, xxl: 42.8 },
                ]}
              >
                <Col span={24}></Col>
                {/* <Col span={24}></Col> */}
                {/* <Col span={24}></Col>
            <Col span={24}></Col> */}
                {/* <Col span={24}></Col> */}
                <Col>
                  <Typography className="enterMobNumText">
                    Enter your mobile number
                  </Typography>
                </Col>
              </Row>

              <Row gutter={[0, 15]} className="enterOtp-wrapnew">
                <Col span={24}></Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Controller
                    as={
                      <Input
                        addonBefore="+1"
                        bordered={false}
                        className="phoneInputnew"
                        size="large"
                      />
                    }
                    // addonBefore="+1"
                    // className="signinPhoneInput"
                    name="phone"
                    control={control}
                    placeholder="Mobile Number"
                  />
                  {formState.isSubmitted && phone.length === 0 && (
                    <p style={{ color: "red" }}>Phone number is required</p>
                  )}
                  {formState.isSubmitted &&
                    phone.length > 0 &&
                    errors.phone &&
                    errors.phone?.message && (
                      <p style={{ color: "red" }}>{errors.phone.message}</p>
                    )}
                </Col>
              </Row>
              {AppleError && (
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Alert
                      type="error"
                      closable
                      onClose={handleClose}
                      open={open}
                      message={AppleError}
                    ></Alert>
                  </Col>
                </Row>
              )}
              {FbError && (
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Alert
                      type="error"
                      closable
                      onClose={handleClose}
                      open={open}
                      message={FbError}
                    ></Alert>
                  </Col>
                </Row>
              )}
              {getOtperror && (
                <Row justify="center" gutter={[0, 8]}>
                  <Col span={24}></Col>
                  <Col>
                    <Alert
                      type="error"
                      onClose={handleClose}
                      open={open}
                      message={getOtperror.error.message}
                    ></Alert>
                  </Col>
                </Row>
              )}
              <Row
                gutter={[
                  0,
                  { xs: 15, sm: 15, md: 15, lg: 56, xl: 56, xxl: 56 },
                ]}
               
              >
                <Col span={24}></Col>
                {/* <Col span={24}></Col> */}
                {/* <Col span={24} > */}
                  <Button
                    htmlType="submit"
                    size="large"
                    disabled={pl === 10 ? false : true}
                    // disabled={errors.length > 0}
                    className={
                      pl === 10
                        ? "button button-primary oBenabledSignupButtonnew"
                        : "oBenabledSignupButtonnew"
                    }
                  >
                    LOGIN
                  </Button>
                {/* </Col> */}
              </Row>

              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Row
                    gutter={[
                      3,
                      { xs: 30, sm: 30, md: 30, lg: 15, xl: 15, xxl: 15 },
                    ]}
                    justify="center"
                  >
                    <Col span={24}></Col>
                    <Col>
                      <Typography className="orcreateiwithtxt">
                        {/* By logging in you agree to our <a href={process.env.REACT_APP_STATIC_DOMAIN+'terms-and-conditions'} target="_" className='tANdC'>terms and conditions</a> / <a className='tANdC' href={process.env.REACT_APP_STATIC_DOMAIN+'privacy-policy'} target="_">privacy policy</a> */}
                        or continue with
                        {/* <Link className="pITCnew" to="/terms-and-conditions">
                          terms and condition
                        </Link>{" "}
                        and{" "}
                        <Link className="pITCnew" to="/privacy-policy">
                          privacy policy
                        </Link> */}
                      </Typography>
                    </Col>
                  </Row>
                  
                </Col>
                {/* <Col span={24}></Col> */}
              </Row>
              <Row
                gutter={[
                  24,
                  { xs: 43, sm: 43, md: 45, lg: 45, xl: 45, xxl: 45 },
                ]}
              >
                <Col span={24}></Col>
                {/* <Col span={24} className="signupusing">
                  OR login using:
                </Col> */}
              </Row>
              <Col span={24}></Col>
<div style={{marginTop:"10px"}}>
              <Row
             style={{marginLeft:"0px",marginRight:"0px"}}
            //    className="SocialLogin"
                gutter={[
                  24,
                  { xs: 10, sm: 10, md: 10, lg: 45, xl: 45, xxl: 45 },
                ]}
              >
                <Col  style={{margin:"0px auto"}}>
                  <a href="" id="LoginWithAmazon" onClick={handleAmazonLogin}>
                  <Button className="pLfacebookButton"><span><img style={{marginRight:"10px"}}src={amazon}/></span>Continue with Amazon</Button>
                    {/* <AmazonCircleFilled
                    style={{
                      fontSize: 40,
                    }}
                  /> */}
                  </a>
                </Col>
                <Col style={{margin:"0px auto"}} >
                  {/* <AppleLogin 
                clientId="com.markid.auth"
                redirectURI={process.env.REACT_APP_DOMAIN+'apple'}
                scope="email name"
                render={(props) => (
                  <div style={{ cursor: "pointer" }} {...props}>
                    <img src={AppleImg} />
                  </div>
                )}
                callback={(response) => responseAppleSignin(response)}
                usePopup={true} 
                nonce= "nonce"
                onError={(error) => console.error('error 189', error)}
            /> */}
                  <AppleSignin
                    authOptions={{
                      clientId: "com.markid.auth",
                      scope: "email name",
                      redirectURI: process.env.REACT_APP_DOMAIN + "apple",
                      state: "state",
                      nonce: "nonce",
                      usePopup: true,
                    }}
                    uiType="light"
                    className="apple-auth-btn"
                    noDefaultStyle={false}
                    buttonExtraChildren="Continue with Apple"
                    onSuccess={(response) => responseAppleSignin(response)}
                    // onSuccess={(response) => console.log('check188', response)}
                    onError={(error) => console.error("error 189", error)}
                    skipScript={false}
                    // iconProp={{ style: { marginTop: "10px" } }}
                    render={(props) => (
                      <div style={{ cursor: "pointer" }} {...props}>
                      {/* <img src={AppleImg1}  width="295px" /> */}
                      <Button className="pLfacebookButton"><span><img style={{marginRight:"10px"}} src={apple}/></span>Continue with Apple</Button>
                    </div>
                    )}
                  />
                </Col>
                <Col style={{margin:"0px auto"}}  >
                  <FacebookLogin
                    appId="450135898816373"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    textButton=""
                    redirect_uri={process.env.REACT_APP_DOMAIN}
                    disableMobileRedirect={true}
                    icon={
                      <Button className="pLfacebookButton"><span><img style={{marginRight:"10px"}}src={facebookiconimg}/></span>Continue with Facebook</Button>
                    }
                  />
                </Col>
              </Row>
              <Row gutter={[0, 15]} justify="center" style={{marginTop:"40px"}}>
                <Col span={24} />
                <Col
                  className="signuptextnew">
                  {/* <Link to="/homepage"> */}
                  {/* <Link to="/">
                    <img alt="logo" src={Logo} className="logoStyle weblogo " />
                  </Link> */}
                  Dont have an account ? <span className="loginBtn"  onClick={() => {
                    dispatch(setLoginSignup("login"));
                    history.push("/onboarding");
                  }}>Signup</span>
                </Col>
              </Row></div>
            </form>
          </Card>
        </div>
      </Spin>
    </div>
  );
};