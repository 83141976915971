import React from 'react'
import { Row, Col, Typography, Card, Button, Tabs, Spin } from "antd";
import NationWideShippingImg from '../assets/new-assets/Nation-wideShippingImg.png'
import ShoplocalShippingImg from '../assets/new-assets/ShoplocalShippingImg.png'
import { useHistory } from "react-router-dom";
import { setFilterCondition } from "../Actions/brandsAction";
import { useDispatch, useSelector } from "react-redux";
import { useGaEvent } from '../hooks/useGaEvent';

const ShopNationWideShipping = () => {
  const { gaPageView, gaEventClick } = useGaEvent();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className='shopNationalWideWrapper'>
      <Row className="shopNationalWide app-wrapper">
        <Col className='blockItem' onClick={() => {
            gaEventClick("Browse", "Shop Nationwide Shipping");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition("nation"));
          }}>
          <Row className="nationWide">
            <Col xs={24} sm={24} md={18} lg={18} xl={18} ><h2>Shop nation-wide shipping</h2></Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}><img src={NationWideShippingImg} alt=""  /></Col>
          </Row>
        </Col>    
        <Col className='blockItem'  onClick={() => {
            gaEventClick("Browse", "Shop local pickup");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition("local"));
          }}>
          <Row className="localPickup">
            <Col xs={24} sm={24} md={18} lg={18} xl={18}><h2>Shop local<br />pickup</h2></Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}><img src={ShoplocalShippingImg} alt=""  /></Col>
          </Row>
        </Col>   
      </Row>
    </div>
  )
}

export default ShopNationWideShipping
