/*eslint-disable*/
import React, { useEffect, useState, useRef, useMemo } from "react";
import RibbonTop from "../components/promotionPopUp";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Button,
  Pagination,
  Spin,
  Empty,
  Card,
  Modal,
  Affix,
  Result,
  Select,
} from "antd";
import { PCategoryCard } from "../components/PCategoryCard";
import { PFiltered } from "../components/PFiltered";
import { PFilteredItem } from "../components/PFilteredItem";
import { ProductCard } from "../components/ProductCard";
import { RelatedItem } from "../components/RelatedItem";
import { getFilterpartnerProducts } from "../Actions/partnerProductsAction";
import {
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Magnifying from "../assets/Magnifying.svg";
import "../App.css";
import "../responsive.css";
import { PLBreadCrumb } from "../components/PLBreadCrumb";
import { getUser } from "../Actions/userAction";
import { getBrandlisting, getBrandsByCategory } from "../Actions/brandsAction";
import PL1 from "../assets/PL1.svg";
import PL2 from "../assets/PL2.svg";
import PL3 from "../assets/PL3.svg";
import PL4 from "../assets/PL4.svg";
import { getFilterLots, getYearOfDetails } from "../Actions/lotsAction";
import { getAllSubSizes, getCategoryById } from "../Actions/categoriesAction";
import {
  getBrandById,
  setNavShow,
  setNavList,
  setNavColors,
  setCurrentNavSelected,
} from "../Actions/brandsAction";
import { setFilterCondition } from "../Actions/brandsAction";
import { getAllBrands } from "../Actions/brandsAction";
import Carousel, { consts } from "react-elastic-carousel";
import { useBreakpoints } from "./../react-responsive-hooks/index";
import FilterIcon from "../assets/FilterIcon.svg";
import { getHandPickedItem } from "../Actions/handPickedAction";
import PFilteredItemMob from "../components/PFilteredItemMob";
import ResultNotFound from "../components/SearchResultNotFound/ResultNotFound";
import { Helmet } from "react-helmet";
import PartnerListingBanner from "../components/PartnerListingBanner";
import { getFiltersToDisplay } from "../Actions/filtersAction";
import { Productresults } from "../components/Productresults";
import { PFilteredMob } from "../components/PFilteredMob";
const { Option } = Select;
const ProductList = () => {
  const { isXS, isSM } = useBreakpoints();
  const history = useHistory();

  const PlSimilarCardData = [
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL1}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL2}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL3}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL4}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL2}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL3}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL1}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL4}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL3}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL1}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL4}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: (
        <LazyLoadImage
          alt=""
          effect="blur"
          src={PL2}
          width={"100%"}
          className="relatedImg"
        />
      ),
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
  ];

  document.addEventListener("DOMContentLoaded", function () {
    // Store the window width
    var windowWidth = window.innerWidth;

    // Resize Event
    window.addEventListener("resize", function () {
      // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
      if (window.innerWidth != windowWidth) {
        // Update the window width for next time
        windowWidth = window.innerWidth;

        // Do stuff here
      }

      // Otherwise do nothing
    });
  });

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  const helmetRef = useRef(null);
  // window.HTMLElement.prototype.scrollIntoView = function() {
  //   window.scrollTo(0, 0);
  //   window.scrollTo(0, offsetTop)
  // };
  const executeScroll = () => helmetRef.current.scrollIntoView();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  // const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  // const [showSignInDialog, setShowSignInDialog] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const paramsToken = new URLSearchParams(location.search);
  const token = paramsToken.get("token");
  const renewedSearch = paramsToken.get("productType");
  const zipCode = paramsToken.get("zipCode");
  const {
    providerToken,
    showSignIn,
    allLots,
    allCategories,
    total,
    totalcount,
    lotsByCategory,
    brandlist,
    isGetAllLotsLoading,
    category,
    allsubsizes,
    brand,
    brandsByCategory,
    filterLots,
    checkedValues,
    isGetFilterLotsLoading,
    handPickedItem,
    allBrands,
    filterCondition,
    allsizelist,
  } = useSelector((state) => {
    const { providerToken } = state.auth;
    const {
      allLots,
      lotsByCategory,
      isGetAllLotsLoading,
      checkedValues,
      totalcount,
      total,
      filterLots,
      isGetFilterLotsLoading,
    } = state.lots;

    const { allCategories, category, allsubsizes, allsizelist } =
      state.categories;
    const { brand, brandsByCategory, allBrands, filterCondition, brandlist } =
      state.brands;
    const { handPickedItem } = state.handPicked;
    return {
      providerToken,
      allLots,
      filterLots,
      allCategories,
      category,
      brand,
      total,
      totalcount,
      brandsByCategory,
      lotsByCategory,
      isGetAllLotsLoading,
      checkedValues,
      isGetFilterLotsLoading,
      handPickedItem,
      allBrands,
      filterCondition,
      brandlist,
      allsubsizes,
      allsizelist,
    };
  });
  const { brand: brandName } = brand || {};
  const { handpicked } = handPickedItem || {};
  const { name: handPickedName } = handpicked || {};
  if (token) {
    localStorage.setItem("access_token", token);
    if (!providerToken) {
      dispatch({ type: "SET_PROVIDER_TOKEN", payload: { token } });
    }
  }
  useEffect(() => {
    dispatch(getUser());
  }, [token]);

  // const [filtertags,setfiltertag]=useState([])

  const [sortByFeaturedValue, setSortByFeaturedValue] = useState([
    // 'Price low to high',
    "Relevance",
  ]);
  const [renewed, setRenewed] = useState(
    renewedSearch !== null && renewedSearch !== undefined
      ? renewedSearch === "true"
      : false
  );

  const { hits } = filterLots || { hits: [] };
  const [datafirst, setdatafirst] = useState([]);
  const [data, setdata] = useState(hits);
  const [displaydata, setdisplaydata] = useState(false);
  const [resetclose, setresetclose] = useState(false);
  const [showfilterDialog, setshowfilterDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [showsortfilterDialog, setshowsortfilterDialog] = useState(false);
  const { itemType, itemName, id, search, brandfilter } = params;

  useEffect(() => {
    if (filterCondition && filterCondition !== "X") {
      const conditions = ["Good", "fair", "Great"];
      const shipping = { shipping: "" };
      const price = [];
      const ages = [];
      price.push(0);
      if (filterCondition === "new") {
        // conditions.push('new');

        updateSelectedCondition(filterCondition);
      } else if (currentPage[2] === "holiday") {
        updateProducttags(["Christmas"]);
      } else if (currentPage[2] === "winterwear") {
        updateProducttags(["Fall/Winter"]);
      } else if (filterCondition === "used") {
        // conditions.push("good");
        // conditions.push("fair");
        // conditions.push("great");

        updateSelectedCondition(conditions);
      } else if (filterCondition === "local") {
        shipping.shipping = "Local Pick Up";
        shipping.value = "true";
        updateSelectedShipping(shipping, 0);
      } else if (brandfilter && filterCondition === "brand") {
        updateSelectedBrand(brandfilter);
      } else if (filterCondition === "nation") {
        shipping.shipping = "Shipping";
        shipping.value = "true";
        updateSelectedShipping(shipping, 0);
      } else if (filterCondition === "baby") {
        ages.push("infant");
        ages.push("newborn");
        updateSelectedageGroup(ages);
      } else if (filterCondition === "bigKid") {
        ages.push("kids");
        updateSelectedageGroup(ages);
      } else if (filterCondition === "toddler") {
        ages.push("toddler");
        updateSelectedageGroup(ages);
      } else if (filterCondition === "sold") {
        updateSoldProducts(true);
      } else if (
        filterCondition === "desc" &&
        currentPage[0] === "" &&
        currentPage[2] === ""
      ) {
        setSortByFeaturedValue("Date of Listing");
        changesSortByLots("createdAtDesc");
      } else if (
        filterCondition == 10 ||
        filterCondition == 20 ||
        filterCondition == 50 ||
        filterCondition == 100
      ) {
        price.push(filterCondition);
        updateSelectedPriceRannge(price);
      } else {
        let url = `/api/v5/markid-products/algolia-search?isSold=false&category=${
          itemType === "category" ? itemName : ""
        }&brands=${itemType === "brand" ? itemName : ""}${
          renewedSearch !== null && renewedSearch !== undefined
            ? "&productType=" + renewedSearch
            : ""
        }${
          zipCode !== null && zipCode !== undefined
            ? "&distance=10&zipCode=" + zipCode
            : ""
        }${search !== null && search !== undefined ? "&search=" + search : ""}`;
        if (itemName && id && itemType === "category") {
          dispatch(getCategoryById(id));
          dispatch(getYearOfDetails({ category: itemName }));
          dispatch(getBrandsByCategory(itemName));
          dispatch(getFiltersToDisplay(itemName));
          dispatch(getFilterLots(null, url));
        } else if (itemName && id && itemType === "brand") {
          dispatch(getBrandById(id));
          dispatch(getYearOfDetails({ brand: itemName }));
          dispatch(getFilterLots(null, url));
        } else if (search) {
          dispatch(getFilterLots(null, url));
        } else {
          dispatch(getFilterLots(null, url));
        }
        window.dispatchEvent(new Event("resize"));
      }

      //brands.push(selectedBrandName);
      //updateSelectedPriceRannge
      //updateSelectedBrand(brands);
      // const q = buildQuery({...currentQuery, condition: conditions });
      // dispatch(getFilterLots(null, q));
    }
    dispatch(setCurrentNavSelected("shop"));
    dispatch(setNavShow(true));
    const tempCatList = [
      {
        display: "Cribs and Furniture",
        value: "cribs_and_furniture",
        id: "5f8ab81836a0cd0ea2329ac0",
      },
      {
        display: "Strollers",
        value: "strollers",
        id: "5f8ab7d436a0cd0ea2329a97",
      },
      {
        display: "Gear",
        value: "gear",
        id: "5f8ab7e436a0cd0ea2329aa1",
      },
      {
        display: "Clothing",
        value: "clothing_and_accessories",
        id: "5f8ab78836a0cd0ea2329a45",
      },
      {
        display: "Shoes",
        value: "shoes",
        id: "5f8ab7a036a0cd0ea2329a5a",
      },
      {
        display: "Toys and Costumes",
        value: "toys_and_costumes",
        id: "5f8ab7c336a0cd0ea2329a7d",
      },
      {
        display: "Feeding",
        value: "feeding",
        id: "5f8ab83e36a0cd0ea2329ad3",
      },
      {
        display: "Diapering",
        value: "diapering",
        id: "5f8ab86c36a0cd0ea2329aec",
      },
      {
        display: "Bathing",
        value: "bathing",
        id: "5f8ab7f436a0cd0ea2329aac",
      },
      {
        display: "Sports and Bicycles",
        value: "sports_bicycles",
        id: "5f8ab84f36a0cd0ea2329ad4",
      },
      {
        display: "Books",
        value: "books",
        id: "5f8ab7ae36a0cd0ea2329a5b",
      },
      {
        display: "Safety & Health",
        value: "safety_equipment",
        id: "5f8ab85e36a0cd0ea2329ae2",
      },
      {
        display: "Maternity",
        value: "maternity",
        id: "5f8ab80536a0cd0ea2329ab9",
      },
      {
        display: "Electronics",
        value: "electronics",
        id: "5f8ab82d36a0cd0ea2329ac7",
      },
    ];
    dispatch(setNavList(tempCatList));
    dispatch(setNavColors({ bg: "#FDF5F4", text: "#FD533F" }));
  }, [filterCondition]);

  let currentPage = useLocation().pathname.split("/");
  let currentSearchElement = useLocation().search.split("&");
  const [switchVal, setSwitchVal] = useState(
    renewed !== null && renewed !== undefined ? renewed : false
  );

  useEffect(() => {
    setdisplaydata(true);
    window.scrollTo(0, 0);
    const { innerWidth, innerHeight } = window;
    // const {itemType,itemName, id, renewedSearch} = params;
    if (!filterCondition || filterCondition === "X") {
      let url = `/api/v5/markid-products/algolia-search?isSold=false&category=${
        itemType === "category" ? itemName : ""
      }&brands=${itemType === "brand" ? itemName : ""}${
        renewedSearch !== null && renewedSearch !== undefined
          ? "&productType=" + renewedSearch
          : ""
      }${
        zipCode !== null && zipCode !== undefined
          ? "&distance=10&zipCode=" + zipCode
          : ""
      }${search !== null && search !== undefined ? "&search=" + search : ""}`;
      if (itemName && id && itemType === "category") {
        dispatch(getCategoryById(id));
        dispatch(getYearOfDetails({ category: itemName }));
        dispatch(getBrandsByCategory(itemName));
        dispatch(getFiltersToDisplay(itemName));

        if (
          currentPage[3] === "clothing_and_accessories" ||
          currentPage[3] === "shoes" ||
          currentPage[3] === "toys_and_costumes"
        ) {
          setSwitchVal(!switchVal);
          handleRenewedChange(!switchVal);
        } else {
          dispatch(getFilterLots(null, url));
        }

        // dispatch(getAllBrands());
        // dispatch(getBrandlisting());
      } else if (itemName && id && itemType === "brand") {
        dispatch(getBrandById(id));
        dispatch(getYearOfDetails({ brand: itemName }));
        dispatch(getFilterLots(null, url));
        // dispatch(getAllBrands());
        // dispatch(getBrandlisting());
      } else if (itemName && id && itemType === "handpicked") {
        // dispatch(getBrandById(id));
        // dispatch(getYearOfDetails({ name: Handpicked }));
        dispatch(getHandPickedItem(id));
      } else if (search) {
        // dispatch(getFilterLots(null, `/api/v2/lots-searchable/?search=${search}`));
        dispatch(getFilterLots(null, url));
        // dispatch(getAllBrands());
        // dispatch(getYearOfDetails());
        // dispatch(getBrandlisting());
      } else if (currentPage[1] === "verifiedshops") {
        setSwitchVal(!switchVal);
        handleRenewedChange(!switchVal);
      } else if (currentPage[2] === "holiday") {
        updateProducttags(["Christmas"]);
      } else if (currentPage[2] === "winterwear") {
        updateProducttags(["Fall/Winter"]);
      } else if (currentPage[2] === "gearandstrollers") {
        const category = ["strollers", "gear"];
        updateSelectedCategory(category);
        setSelectedCategory(["strollers", "gear"]);
      } else {
        // dispatch(getFilterLots(null, `/api/v2/lots/`));
        dispatch(getFilterLots(null, url));
        // dispatch(getAllBrands());
        // dispatch(getYearOfDetails());
        // dispatch(getFiltersToDisplay());
        // dispatch(getBrandlisting());
      }
    }
  }, []);
  // const capitalize = (s) => {
  //   if (typeof s !== "string") return "";
  //   return s.charAt(0).toUpperCase() + s.slice(1);
  // };

  const pages = Math.ceil(total / 18);
  const showTotal = filterLots.length;
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a className="pagyNext">Prev</a>;
    }
    if (type === "next") {
      return <a className="pagyNext">Next</a>;
    }

    return originalElement;
  }
  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  const handleOpenfilter = () => {
    setshowfilterDialog(true);
  };
  const [lastUpdatedUrl, setLastUpdatedUrl] = useState(
    `/api/v2/lots-searchable?category=${
      itemType === "category" ? itemName : ""
    }&brands=${itemType === "brand" ? itemName : ""}${
      renewedSearch !== null && renewedSearch !== undefined
        ? "&renewed=" + renewedSearch
        : ""
    }`
  );
  const initialPriceValues = [
    { title: "$0 to $10", value: false, filterParams: [0, 10] },
    { title: "$10 to $50", value: false, filterParams: [10, 50] },
    { title: "$50 to $100", value: false, filterParams: [50, 100] },
  ];

  const [priceValues, setPriceValues] = useState([...initialPriceValues]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedpricevalues, setSelectedpricevalues] = useState([]);
  const [selectedshippingarr, setSelectedshippingarr] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedGenderarr, setSelectedGenderarr] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const defaultQuery = {
    category: itemType === "category" ? itemName : "",
    brands: itemType === "brand" ? itemName : "",
    // sortBy: '',
    ...(search !== null && search !== undefined ? { search: search } : {}),
    ...(renewedSearch !== null && renewedSearch !== undefined
      ? { renewed: renewedSearch === "true" }
      : {}),
  };

  const [currentQuery, setCurrentQuery] = useState({ ...defaultQuery });
  const [selectedPurchaseYear, setSelectedPurchaseYear] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState({});
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [selectedColor, setselectedColor] = useState([]);
  const [selectedAgegroup, setSelectedAgegroup] = useState([]);
  const [selectedSold, setSelectedSold] = useState([]);
  const [selectedtag, setSelectedtag] = useState([]);
  const [close, setClose] = useState(sessionStorage.getItem("pop") || "true");
  const [selectedShipping, setSelectedShipping] = useState([
    { shipping: "Free Shipping", value: false },
    { shipping: "Shipping", value: false },
    // { shipping: 'Meetup', value: false },
    // { shipping: "Local Pick Up", value: false },
  ]);
  const [totalnew, settotalnew] = useState(total);
  useEffect(() => {
    if (
      itemType == "category" ||
      currentPage[1] == "search-new" ||
      currentPage[1] == "verifiedshops" ||
      currentPage[1] == "search-brand"
    ) {
      dispatch(getAllSubSizes());
    }
  }, []);
  useEffect(() => {
    if (isXS || isSM) {
      if (displaydata === true) {
        setdata(hits);
        settotalnew(total);
      }
    } else {
      setdata(hits);
      settotalnew(total);
    }
  }, [hits, displaydata, showfilterDialog]);

  const [sortByLots, setSortByLots] = useState("");

  let filtertags = [
    ...selectedCategory,
    ...selectedBrand,
    ...selectedGenderarr,
    ...selectedshippingarr,
    ...selectedCondition,
    ...selectedPurchaseYear,
    ...selectedColor,
    ...selectedAge,
    ...selectedSize,
    ...selectedpricevalues,
  ];
  const [filterMob, setFilterMob] = useState(false);
  // const [filterMLots, setFilterLots] = useState(lots)
  const updateSelectedPriceRannge = (range) => {
    if (range[1] === 10 && filterCondition != 10) {
      setSelectedpricevalues(["$0 to $10"]);
    } else if (range[1] === 50 && filterCondition != 50) {
      setSelectedpricevalues(["$10 to $50"]);
    } else if (range[1] === 100 && filterCondition != 100) {
      setSelectedpricevalues(["$50 to $100"]);
    } else {
      setSelectedpricevalues([]);
    }

    const min = range[0];
    const max = range[1];
    const q = buildQuery({ ...currentQuery, price: { min, max } });
    dispatch(getFilterLots(null, q));
    setSelectedPriceRange({ min, max });
    setCurrentQuery({ ...currentQuery, price: { min, max } });
  };

  const updateSelectedBrand = (brands, viewType) => {
    const isExists = _.includes(selectedBrand, brands);
    const sBrand = selectedBrand;
    if (isExists) {
      const removeIndex = selectedBrand.findIndex((item) => item === brands);
      sBrand.splice(removeIndex, 1);
    } else {
      sBrand.push(brands);
    }
    const q = buildQuery({ ...currentQuery, brands: selectedBrand });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, brands: selectedBrand });
    setSelectedBrand(sBrand);
  };
  const updateSelectedSize = (sizes) => {
    const isExists = _.includes(selectedSize, sizes);
    const sSize = selectedSize;
    if (isExists) {
      const removeIndex = selectedSize.findIndex((item) => item === sizes);
      sSize.splice(removeIndex, 1);
    } else {
      sSize.push(sizes);
    }
    setSelectedSize(sSize);

    const q = buildQuery({ ...currentQuery, size: selectedSize });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, size: selectedSize });
  };
  const updateSelectedGender = (genders) => {
    const isExists = _.includes(selectedGender, genders);
    const sGender = selectedGender;
    if (isExists) {
      const removeIndex = selectedGender.findIndex((item) => item === genders);
      sGender.splice(removeIndex, 1);
    } else {
      sGender.push(genders);
      if (
        selectedGender.includes("female") &&
        selectedGenderarr.includes("Girl") == false
      ) {
        selectedGenderarr.push("Girl");
      }
      if (
        selectedGender.includes("male") &&
        selectedGenderarr.includes("Boy") == false
      ) {
        selectedGenderarr.push("Boy");
      }
      if (
        selectedGender.includes("unisex") &&
        selectedGenderarr.includes("Unisex") == false
      ) {
        selectedGenderarr.push("Unisex");
      }
      if (
        selectedGender.includes("neutral") &&
        selectedGenderarr.includes("Neutral") == false
      ) {
        selectedGenderarr.push("Neutral");
      }
    }
    const q = buildQuery({ ...currentQuery, gender: selectedGender });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, gender: selectedGender });
  };
  const updateSelectedPurchaseYear = (purchaseYear) => {
    const isExists = _.includes(selectedPurchaseYear, purchaseYear);
    const sYear = selectedPurchaseYear;
    if (isExists) {
      const removeIndex = selectedPurchaseYear.findIndex(
        (item) => item === purchaseYear
      );
      sYear.splice(removeIndex, 1);
    } else {
      sYear.push(purchaseYear);
    }
    setSelectedPurchaseYear(sYear);
    const q = buildQuery({
      ...currentQuery,
      yearOfPurchase: selectedPurchaseYear,
    });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, yearOfPurchase: selectedPurchaseYear });
  };

  const updateSelectedAge = (age) => {
    const isExists = _.includes(selectedAge, age);
    const sAge = selectedAge;
    if (isExists) {
      const removeIndex = selectedAge.findIndex((item) => item === age);
      sAge.splice(removeIndex, 1);
    } else {
      sAge.push(age);
    }
    setSelectedAge(sAge);
    const q = buildQuery({ ...currentQuery, age: selectedAge });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, age: selectedAge });
  };
  const updateSelectedColor = (color) => {
    const isExists = _.includes(selectedColor, color);
    const sColor = selectedColor;
    if (isExists) {
      const removeIndex = selectedColor.findIndex((item) => item === color);
      sColor.splice(removeIndex, 1);
    } else {
      sColor.push(color);
    }
    setselectedColor(sColor);
    const q = buildQuery({ ...currentQuery, color: selectedColor });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, color: selectedColor });
  };

  // const updateSelectedPurchaseYear = (purchaseYear) => {
  //   const { yearOfPurchase } = currentQuery;
  //   if (purchaseYear === yearOfPurchase) {
  //     setSelectedPurchaseYear('');
  //     const newQuery = _.omit(currentQuery, 'yearOfPurchase');
  //     setCurrentQuery({ ...newQuery });
  //     const q = buildQuery({ ...newQuery });
  //     dispatch(getFilterLots(null, q));
  //   } else {
  //     setSelectedPurchaseYear(purchaseYear);
  //     setCurrentQuery({ ...currentQuery, yearOfPurchase: purchaseYear });
  //     const q = buildQuery({
  //       ...currentQuery,
  //       yearOfPurchase: purchaseYear,
  //     });
  //     dispatch(getFilterLots(null, q));
  //   }
  // };

  const handlePagination = (value) => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
    const q = buildQuery({ ...currentQuery, skip: value - 1 });
    dispatch(getFilterLots(null, q));
  };
  const changesSortByLots = (sortingVal) => {
    setSortByLots(sortingVal);
    setCurrentQuery({ ...currentQuery, sortBy: sortingVal });
    setCurrentQuery({ ...currentQuery, sortBy: sortingVal });
    const q = buildQuery({ ...currentQuery, sortBy: sortingVal });

    dispatch(getFilterLots(null, q));
  };
  const shippingval = selectedShipping.map((data) => {
    // return {
    //   value: data,
    // };
  });

  const handleApply = () => {
    setFilterMob(false);
    // executeScroll();
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleCancel = () => {
    setFilterMob(false);
    //executeScroll();
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const buildQuery = (queries) => {
    let basicUrl = "/api/v5/markid-products/algolia-search?isSold=false&";
    for (let key in queries) {
      if (queries.hasOwnProperty(key)) {
        switch (key) {
          case "search":
            basicUrl = `${basicUrl}&search=${queries[key]}`;
            break;
          case "gender":
            basicUrl = `${basicUrl}&gender=${queries[key]}`;
            break;
          case "size":
            basicUrl = `${basicUrl}&size=${queries[key]}`;

            break;
          case "color":
            basicUrl = `${basicUrl}&color=${queries[key]}`;

            break;
          case "age":
            basicUrl = `${basicUrl}&age=${queries[key]}`;
            break;
          case "yearOfPurchase":
            basicUrl = `${basicUrl}&yearOfPurchase=${queries[key]}`;
            break;
          case "category":
            basicUrl = `${basicUrl}&category=${queries[key]}`;

            break;
          case "brands":
            // basicUrl = `${basicUrl}&brands=${queries[key] ? queries[key].join() : ''}`
            basicUrl = `${basicUrl}&brands=${queries[key]}`;
            break;
          case "condition":
            basicUrl = `${basicUrl}&condition=${queries[key]}`;
            break;
          case "agegroup":
            basicUrl = `${basicUrl}&productType=true&age=${queries[key]}`;
            break;
          case "isSold":
            basicUrl = `${basicUrl}&isSold=${queries[key]}`;
            break;
          case "tags":
            basicUrl = `${basicUrl}&tags=${queries[key]}`;
            break;
          case "renewed":
            basicUrl = `${basicUrl}${
              queries[key] ? `&productType=${queries[key]}` : ""
            }`;

            break;
          case "shipping":
            if (queries[key].deliveryType) {
              basicUrl = `${basicUrl}&deliveryType=shipping,both`;
            } else if (queries[key].isFree) {
              basicUrl = `${basicUrl}&isFree=true`;
            } else if (queries[key].isMeetup) {
              basicUrl = `${basicUrl}&deliveryType=meetup,both,Local pick-up`;
            }
            break;
          case "price":
            let minQ = 0,
              maxQ = 2500;
            if (queries[key].min) {
              minQ = queries[key].min;
            }
            if (queries[key].max) {
              maxQ = queries[key].max;
            }
            basicUrl = `${basicUrl}&priceFrom=${minQ * 100}&priceTo=${
              maxQ * 100
            }`;
            break;
          case "skip":
            basicUrl = `${basicUrl}&pageNumber=${queries[key]}`;
            break;
          case "sortBy":
            basicUrl = `${basicUrl}&sortBy=${queries[key]}`;
            break;
        }
      }
    }
    return basicUrl;
  };

  // const updateSelectedAge = (ages) => {
  //   // if(ages.length > 0){
  //   //   const ageFilterLots = _.filter(lots, function(o) { return _.includes(selectedAge, o.age); });
  //   // setFilterLots(ageFilterLots)
  //   // setSelectedAge(ages)
  //   // }
  //   // else {
  //   //   setFilterLots(lots)
  //   // }
  // };
  const updateProducttags = (isSold) => {
    const isExists = _.includes(selectedtag, isSold);
    const soldProducts = selectedtag;
    if (isExists) {
      const removeIndex = selectedtag.findIndex((item) => item === isSold);
      soldProducts.splice(removeIndex, 1);
    } else {
      soldProducts.push(isSold);
    }
    setSelectedtag(soldProducts);

    const q = buildQuery({ ...currentQuery, tags: selectedtag });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, tags: selectedtag });
  };
  const updateSoldProducts = (isSold) => {
    const isExists = _.includes(selectedSold, isSold);
    const soldProducts = selectedSold;
    if (isExists) {
      const removeIndex = selectedSold.findIndex((item) => item === isSold);
      soldProducts.splice(removeIndex, 1);
    } else {
      soldProducts.push(isSold);
    }
    setSelectedSold(soldProducts);

    const q = buildQuery({ ...currentQuery, isSold: selectedSold });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, isSold: selectedSold });
  };
  const updateSelectedageGroup = (agegroup) => {
    const isExists = _.includes(selectedAgegroup, agegroup);
    const sAgegroup = selectedAgegroup;
    if (isExists) {
      const removeIndex = selectedAgegroup.findIndex(
        (item) => item === agegroup
      );
      sAgegroup.splice(removeIndex, 1);
    } else {
      sAgegroup.push(agegroup);
    }
    setSelectedAgegroup(sAgegroup);

    const q = buildQuery({ ...currentQuery, agegroup: selectedAgegroup });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, agegroup: selectedAgegroup });
  };

  const updateSelectedCategory = (categoryArr) => {
    const isExists = _.includes(selectedCategory, categoryArr);
    const sCategory = selectedCategory;
    if (isExists) {
      const removeIndex = selectedCategory.findIndex(
        (item) => item === categoryArr
      );
      sCategory.splice(removeIndex, 1);
    } else {
      sCategory.push(categoryArr);
    }

    const q = buildQuery({ ...currentQuery, category: selectedCategory });
    dispatch(getFilterLots(null, q));
    setCurrentQuery({ ...currentQuery, category: selectedCategory });
    setSelectedCategory(sCategory);
  };

  const updateSelectedCondition = (conditions) => {
    const isExists = _.includes(selectedCondition, conditions);
    const sCondition = selectedCondition;
    if (isExists) {
      const removeIndex = selectedCondition.findIndex(
        (item) => item === conditions
      );
      sCondition.splice(removeIndex, 1);
    } else {
      if (filterCondition === "used") {
        sCondition.push(...conditions);
      } else {
        sCondition.push(conditions);
      }
    }
    setSelectedCondition(sCondition);
    setCurrentQuery({ ...currentQuery, condition: selectedCondition });
    const q = buildQuery({ ...currentQuery, condition: selectedCondition });
    dispatch(getFilterLots(null, q));
  };
  const updateSelectedShipping = (shipping, findIndex) => {
    if (shipping.value === true || shipping.value === "true") {
      setSelectedshippingarr([shipping.shipping]);
    } else {
      const newPeople = selectedshippingarr.filter(
        (person) => person !== shipping.shipping
      );
      setSelectedshippingarr(newPeople);
    }

    const isFree = shipping.shipping === "Free Shipping";
    // const isMeetup = shipping.shipping === 'Meetup';
    const isMeetup = shipping.shipping === "Local Pick Up";
    const deliveryType = shipping.shipping === "Shipping";
    let q;
    if (isFree) {
      q = { shipping: { isFree: shipping.value } };
    } else if (isMeetup) {
      q = { shipping: { isMeetup: shipping.value } };
    } else if (deliveryType) {
      q = { shipping: { deliveryType: shipping.value } };
    }
    const delta = { ...currentQuery, ...q };
    const url = buildQuery({ ...currentQuery, ...q });
    dispatch(getFilterLots(null, url));
    setCurrentQuery({ ...currentQuery, ...q });

    const newSelectedShipping = selectedShipping.map((s) => {
      if (s.shipping === shipping.shipping) {
        s.value = shipping.value;
        return s;
      } else {
        s.value = false;
        return s;
      }
      s.shipping;
    });
    setSelectedShipping([...newSelectedShipping]);
  };
  const updateResetAllFilter = () => {
    let url = `/api/v5/markid-products/algolia-search?isSold=false&category=${
      itemType === "category" ? itemName : ""
    }${
      renewedSearch !== null && renewedSearch !== undefined
        ? "&productType=" + renewedSearch
        : ""
    }${
      zipCode !== null && zipCode !== undefined
        ? "&distance=10&zipCode=" + zipCode
        : ""
    }${search !== null && search !== undefined ? "&search=" + search : ""}`;
    setSelectedshippingarr([]);
    setSelectedCategory([]);
    //dispatch(setFilterCondition("X"));
    setSelectedpricevalues([]);
    setSelectedBrand([]);
    setSelectedSize([]);
    setSelectedGender([]);
    setSelectedPriceRange({});
    setSelectedPurchaseYear("");
    setSelectedCondition([]);
    setSortByLots("");
    setSelectedAge([]);
    setSelectedGenderarr([]);
    setselectedColor([]);
    setSelectedShipping([
      { shipping: "Free Shipping", value: false },
      { shipping: "Shipping", value: false },
      // { shipping: 'Meetup', value: false },
      { shipping: "Local Pick Up", value: false },
    ]);
    setCurrentQuery({
      ...currentQuery,
      brands: "",
      gender: "",
      size: "",
      purchaseYear: "",
      condition: "",
      price: "",
      shipping: "",
      color: "",
      age: "",
      category: "",
    }); // for brand reset filter bug
    const q = buildQuery({ ...defaultQuery });
    //executeScroll();
    //   document.body.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
    dispatch(getFilterLots(null, url));
    setdisplaydata(false);
  };
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);
  const listenToScroll = () => {
    let heightToHideFrom = 700;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);
    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll, true);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);
  const handleRenewedChange = (x) => {
    const { renewedQuery } = currentQuery;
    if (x) {
      const q = buildQuery({ ...currentQuery, renewed: true });
      setCurrentQuery({ ...currentQuery, renewed: true });
      setRenewed(true);
      dispatch(getFilterLots(null, q));
    } else {
      const q = buildQuery({ ...currentQuery, renewed: false });
      setCurrentQuery({ ...currentQuery, renewed: false });
      setRenewed(false);
      dispatch(getFilterLots(null, q));
    }
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <LeftOutlined /> : <RightOutlined />;
    return (
      <button
        style={{
          background: "transparent",
          border: "none",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          fontSize: 25,
          fontWeight: "bolder",
          color: isEdge ? "#E4E4E4" : "#393939",
          cursor: "pointer",
        }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };
  const myDots = ({ pages, activePage, onClick }) => {
    return (
      <Row
        justify="center"
        style={{ width: "100%", padding: "5px 15px", zIndex: 9 }}
      >
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              style={{
                borderRadius: "50%",
                background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                width: 15,
                height: 15,
                margin: isXS ? "22px 5px 0px" : "50px 5px 0px",
                cursor: "pointer",
              }}
              onClick={() => onClick(page)}
              // active={isActivePage}
            />
          );
        })}
      </Row>
    );
  };
  return (
    <>
      <div id="height">
        <b>
          height: {height} - {isVisible ? "show" : "hide"}
        </b>
      </div>
      {!isVisible && !isXS && currentPage[1] == "verifiedshops" && (
        <div id="hide">
          <RibbonTop close={close} setClose={setClose} />
        </div>
      )}

      {/* {lots && lots.length > 0 && ( */}
      {/* <div>
        {!search && <PCategoryCard itemType={itemType} currentBrand={brand} currentCategory={category} categoryresults={lots} resultValue={total} handPickedData={handPickedItem} />}
        <div className="pCardBackgound plp">
          <div className="app-wrapper w-100">
            <div className="pBreadCrumbMob">
              <Row
                gutter={[
                  0,
                  {
                    xs: 0,
                    sm: 0,
                    md: 15.4,
                    lg: 15.4,
                    xl: 15.4,
                  },
                ]}
              >
                <Col span={24}></Col>
                <Col offset={0}>
                  <PLBreadCrumb markid="Home" category={(itemType === 'category' && category.displayValue) || (itemType === 'brand' && brandName) || (itemType === 'handpicked' && handPickedName)} />
                </Col>
              </Row>
            </div> */}
      {/* <PFiltered
    {currentPage[1] == 'partner' ? <PartnerListingBanner /> : <></>}
      {/* {lots && lots.length > 0 && ( */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{category?.metaTitle || "Markid - Product Listing"}</title>
        <meta
          name="description"
          content={category?.metaDescription || "Markid - Product Listing"}
        />
        {localStorage.getItem("Selected-Brands")
          ? JSON.parse(localStorage.getItem("Selected-Brands")).length &&
            JSON.parse(localStorage.getItem("Selected-Brands")).map(
              (brands) => {
                return (
                  <meta
                    name="description"
                    content={`Shop ${brands} ${category?.displayValue} for Sale & Used Baby Items | Markid Marketplace`}
                  />
                );
              }
            )
          : ""}
        {JSON.parse(localStorage.getItem("Selected-Genders")) ? (
          JSON.parse(localStorage.getItem("Selected-Genders")).length ? (
            <meta
              name="keywords"
              content={`For ${
                JSON.parse(localStorage.getItem("Selected-Genders")) || " "
              }`}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {JSON.parse(localStorage.getItem("Selected-YearofPurchase")) ? (
          JSON.parse(localStorage.getItem("Selected-YearofPurchase")).length ? (
            <meta
              name="keywords"
              content={`Year of purchase: ${
                JSON.parse(localStorage.getItem("Selected-YearofPurchase")) ||
                ""
              }`}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {JSON.parse(localStorage.getItem("Selected-Condition")) ? (
          JSON.parse(localStorage.getItem("Selected-Condition")).length ? (
            <meta
              name="keywords"
              content={`Condition: ${
                JSON.parse(localStorage.getItem("Selected-Condition")) || ""
              }`}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {JSON.parse(localStorage.getItem("Selected-Shipping")) == false ? (
          <title>
            Shop Local Delivery Used Baby and Kid Items | Markid Marketplace
          </title>
        ) : (
          <title>
            "Shop Used Baby and Kid Items Nationwide Shipping | Markid
            Marketplace"
          </title>
        )}
        {localStorage.getItem("Selected-Price-Range") ? (
          localStorage.getItem("Selected-Price-Range").length ? (
            <meta
              name="keywords"
              content={`Under $${
                localStorage.getItem("Selected-Price-Range") || ""
              }`}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Helmet>
      <div>
        {!search && currentPage[1] != "partner" && !isXS && (
          <PCategoryCard
            itemType={itemType}
            currentBrand={brand}
            currentCategory={category}
            categoryresults={hits}
            resultValue={totalnew}
            handPickedData={handPickedItem}
          />
        )}

        <div className="pCardBackgound plp">
          <div className="app-wrapper w-100">
            {isXS && (
              <Row gutter={[10, 0]} className="filterWrap">
                <Col span={12} style={{ borderRight: " 1px solid #CDCDCD" }}>
                  {/* {!showfilterDialog && (<Affix offsetTop={20} >
                        <LazyLoadImage onClick={() => {setshowfilterDialog(true)}} src={FilterIcon} effect="blur" alt="" /> 
                      </Affix>)} */}

                  {!showfilterDialog && (
                    <Affix offsetTop={20}>
                      <Button
                        type="link"
                        className="filter_text fitlerOption"
                        onClick={() => {
                          setshowfilterDialog(true);
                          setdisplaydata(false);
                        }}
                      >
                        Filter
                      </Button>
                    </Affix>
                  )}

                  {/* {filterMob && (
                        <Modal
                          width={innerWidth}
                          // bodyStyle={{height:innerHeight}}
                          style={{
                            top: 0,
                            height: innerHeight,
                            margin: 0,
                            padding: 0,
                            maxWidth: 'unset',
                          }}
                          closable={false}
                          className="modalParent"
                          visible={filterMob}
                          footer={false}
                        > */}
                  <PFilteredItemMob
                    allsizelist={allsizelist}
                    handledisplayAll={() => {
                      setdisplaydata(true);
                      setshowfilterDialog(false);
                      setresetclose(true);
                    }}
                    allsubsizes={allsubsizes}
                    resetclose={resetclose}
                    filtertags={filtertags}
                    displaydata={displaydata}
                    setdisplaydata={setdisplaydata}
                    total={total}
                    priceValues={priceValues}
                    setPriceValues={setPriceValues}
                    initialPriceValues={initialPriceValues}
                    totalcount={totalcount}
                    currentPage={currentPage}
                    showfilterDialog={showfilterDialog}
                    setshowfilterDialog={setshowfilterDialog}
                    filterCondition={filterCondition}
                    id={id}
                    buildQuery={buildQuery}
                    itemType={itemType}
                    itemName={itemName || "search"}
                    hits={hits}
                    selectedBrand={selectedBrand}
                    updateSelectedBrand={(data) =>
                      updateSelectedBrand(data, "MOB")
                    }
                    selectedGender={selectedGender}
                    updateSelectedGender={updateSelectedGender}
                    selectedAge={selectedAge}
                    updateSelectedAge={updateSelectedAge}
                    selectedSize={selectedSize}
                    updateSelectedSize={updateSelectedSize}
                    selectedCondition={selectedCondition}
                    updateSelectedCondition={updateSelectedCondition}
                    selectedShipping={selectedShipping}
                    updateSelectedShipping={updateSelectedShipping}
                    selectedPurchaseYear={selectedPurchaseYear}
                    updateSelectedPurchaseYear={updateSelectedPurchaseYear}
                    selectedPriceRange={selectedPriceRange}
                    updateSelectedPriceRannge={updateSelectedPriceRannge}
                    updateResetAllFilter={updateResetAllFilter}
                    brandsByCategory={brandsByCategory}
                    allBrands={allBrands}
                    updateSelectedColor={updateSelectedColor}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    updateSelectedCategory={updateSelectedCategory}
                    selectedColor={selectedColor}
                  >
                    {/* <LeftOutlined onClick={() => setFilterMob(false)} />
                            <div className="affixParent">
                              <Affix offsetBottom={0}>
                                <Card
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <Row gutter={[20, 0]} align="middle">
                                    <Col span={10}>
                                      <Button onClick={() => handleCancel()} className="pFilterCancelMob">
                                        Cancel
                                      </Button>
                                    </Col>
                                    <Col span={14}>
                                      <Button onClick={() => handleApply()} className="pFilterApplyMob">
                                        Apply
                                      </Button>
                                    </Col>
                                  </Row>
                                </Card>
                              </Affix>
                            </div> */}
                  </PFilteredItemMob>
                  {/* </Modal> */}
                  {/* )} */}
                </Col>
                {!showsortfilterDialog && (
                  <Col span={12}>
                    <Button
                      className="filter_text sortOption"
                      onClick={() => {
                        setshowsortfilterDialog(true);
                      }}
                    >
                      {" "}
                      Sort by{" "}
                    </Button>

                    {/* <Select  bordered={false} showArrow={false} className='custom_select_menu filter_text sortOption' defaultValue="Sort by">
                      <Option value="Popularity">Popularity</Option>
                      <Option value="Latest">Latest</Option>
                      <Option value="Discount">Discount</Option>
                      <Option value="High to low">Price: High to low</Option>
                      <Option value="Low to High">Price: Low to High</Option>
                      </Select> */}
                  </Col>
                )}
              </Row>
            )}

            <div ref={helmetRef} className="app-wrapper prod-cards-grid">
              {/* <Col span={24}></Col> */}
              {/* <Col span={2}></Col> */}
              <Row
                gutter={[
                  { xs: 10, sm: 10, md: 32, lg: 32, xl: 32 },
                  { xs: 18, sm: 18, md: 32, lg: 32, xl: 32 },
                ]}
              >
                <Col span={24}></Col>

                {((data && data.length > 0) ||
                  (JSON.stringify(currentQuery) !==
                    JSON.stringify({
                      category: "",
                      brands: "",
                    }) &&
                    JSON.stringify(currentQuery) !==
                      JSON.stringify({
                        brands: "",
                        category: "",
                      }))) && (
                  <Col
                    md={{ span: 6, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                    xl={{ span: 6, offset: 0 }}
                    className="pFilterItemCardMob"
                  >
                    <PFilteredItem
                      allsizelist={allsizelist}
                      allsubsizes={allsubsizes}
                      priceValues={priceValues}
                      setPriceValues={setPriceValues}
                      initialPriceValues={initialPriceValues}
                      selectedshippingarr={selectedshippingarr}
                      filtertags={filtertags}
                      totalcount={totalcount}
                      currentPage={currentPage}
                      filterCondition={filterCondition}
                      id={id}
                      buildQuery={buildQuery}
                      itemType={itemType}
                      itemName={itemName || "search"}
                      hits={hits}
                      selectedBrand={selectedBrand}
                      updateSelectedBrand={(data) =>
                        updateSelectedBrand(data, "WEB")
                      }
                      selectedGender={selectedGender}
                      updateSelectedGender={updateSelectedGender}
                      selectedAge={selectedAge}
                      updateSelectedAge={updateSelectedAge}
                      selectedSize={selectedSize}
                      updateSelectedSize={updateSelectedSize}
                      selectedCondition={selectedCondition}
                      updateSelectedCondition={updateSelectedCondition}
                      updateSelectedColor={updateSelectedColor}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      updateSelectedCategory={updateSelectedCategory}
                      selectedColor={selectedColor}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                      selectedPurchaseYear={selectedPurchaseYear}
                      updateSelectedPurchaseYear={updateSelectedPurchaseYear}
                      selectedPriceRange={selectedPriceRange}
                      updateSelectedPriceRannge={updateSelectedPriceRannge}
                      updateResetAllFilter={updateResetAllFilter}
                      brandsByCategory={brandsByCategory}
                      allBrands={allBrands}
                    />
                  </Col>
                )}

                {data &&
                  data.length <= 0 &&
                  (JSON.stringify(currentQuery) ===
                    JSON.stringify({
                      category: "",
                      brands: "",
                    }) ||
                    JSON.stringify(currentQuery) ===
                      JSON.stringify({
                        brands: "",
                        category: "",
                      })) && (
                    <Col span={24}>
                      <ResultNotFound />
                    </Col>
                  )}
                <Col
                  md={18}
                  lg={18}
                  xl={18}
                  xs={24}
                  sm={24}
                  className="productsListing"
                  style={{ marginTop: "50px" }}
                >
                  {isXS && (
                    <>
                      <Productresults resultValue={totalnew} search={search} />
                      <PFilteredMob
                        selectedGenderarr={selectedGenderarr}
                        setSelectedGenderarr={setSelectedGenderarr}
                        selectedPriceRange={selectedPriceRange}
                        priceValues={priceValues}
                        setPriceValues={setPriceValues}
                        initialPriceValues={initialPriceValues}
                        showsortfilterDialog={showsortfilterDialog}
                        setshowsortfilterDialog={setshowsortfilterDialog}
                        selectedpricevalues={selectedpricevalues}
                        setSelectedpricevalues={setSelectedpricevalues}
                        setselectedColor={setselectedColor}
                        setSelectedAge={setSelectedAge}
                        setSelectedSize={setSelectedSize}
                        filtertags={filtertags}
                        currentPage={currentPage}
                        setSortByFeaturedValue={setSortByFeaturedValue}
                        sortByFeaturedValue={sortByFeaturedValue}
                        filterCondition={filterCondition}
                        itemType={itemType}
                        category={category}
                        brand={brand}
                        sortByLots={sortByLots}
                        changesSortByLots={changesSortByLots}
                        renewed={renewed}
                        handleRenewedChange={handleRenewedChange}
                        totalcount={totalcount}
                        showfilterDialog={showfilterDialog}
                        setshowfilterDialog={setshowfilterDialog}
                        id={id}
                        buildQuery={buildQuery}
                        itemName={itemName || "search"}
                        hits={hits}
                        selectedBrand={selectedBrand}
                        setSelectedPurchaseYear={setSelectedPurchaseYear}
                        setSelectedGender={setSelectedGender}
                        setSelectedBrand={setSelectedBrand}
                        updateSelectedBrand={updateSelectedBrand}
                        selectedGender={selectedGender}
                        updateSelectedGender={updateSelectedGender}
                        selectedAge={selectedAge}
                        updateSelectedAge={updateSelectedAge}
                        selectedSize={selectedSize}
                        updateSelectedSize={updateSelectedSize}
                        selectedCondition={selectedCondition}
                        updateSelectedCondition={updateSelectedCondition}
                        selectedShipping={selectedShipping}
                        updateSelectedShipping={updateSelectedShipping}
                        selectedPurchaseYear={selectedPurchaseYear}
                        setSelectedCondition={setSelectedCondition}
                        updateSelectedPurchaseYear={updateSelectedPurchaseYear}
                        updateSelectedPriceRannge={updateSelectedPriceRannge}
                        updateResetAllFilter={updateResetAllFilter}
                        brandsByCategory={brandsByCategory}
                        allBrands={allBrands}
                        updateSelectedColor={updateSelectedColor}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        updateSelectedCategory={updateSelectedCategory}
                        selectedColor={selectedColor}
                        selectedshippingarr={selectedshippingarr}
                        setSelectedshippingarr={setSelectedshippingarr}
                      />
                    </>
                  )}

                  {!isXS && (
                    <PFiltered
                      selectedGenderarr={selectedGenderarr}
                      setSelectedGenderarr={setSelectedGenderarr}
                      selectedPriceRange={selectedPriceRange}
                      priceValues={priceValues}
                      setPriceValues={setPriceValues}
                      initialPriceValues={initialPriceValues}
                      showsortfilterDialog={showsortfilterDialog}
                      setshowsortfilterDialog={setshowsortfilterDialog}
                      selectedpricevalues={selectedpricevalues}
                      setSelectedpricevalues={setSelectedpricevalues}
                      setselectedColor={setselectedColor}
                      setSelectedAge={setSelectedAge}
                      setSelectedSize={setSelectedSize}
                      filtertags={filtertags}
                      currentPage={currentPage}
                      setSortByFeaturedValue={setSortByFeaturedValue}
                      sortByFeaturedValue={sortByFeaturedValue}
                      filterCondition={filterCondition}
                      itemType={itemType}
                      category={category}
                      brand={brand}
                      sortByLots={sortByLots}
                      changesSortByLots={changesSortByLots}
                      renewed={renewed}
                      handleRenewedChange={handleRenewedChange}
                      totalcount={totalcount}
                      showfilterDialog={showfilterDialog}
                      setshowfilterDialog={setshowfilterDialog}
                      id={id}
                      buildQuery={buildQuery}
                      itemName={itemName || "search"}
                      hits={hits}
                      selectedBrand={selectedBrand}
                      setSelectedPurchaseYear={setSelectedPurchaseYear}
                      setSelectedGender={setSelectedGender}
                      setSelectedBrand={setSelectedBrand}
                      updateSelectedBrand={updateSelectedBrand}
                      selectedGender={selectedGender}
                      updateSelectedGender={updateSelectedGender}
                      selectedAge={selectedAge}
                      updateSelectedAge={updateSelectedAge}
                      selectedSize={selectedSize}
                      updateSelectedSize={updateSelectedSize}
                      selectedCondition={selectedCondition}
                      updateSelectedCondition={updateSelectedCondition}
                      selectedShipping={selectedShipping}
                      updateSelectedShipping={updateSelectedShipping}
                      selectedPurchaseYear={selectedPurchaseYear}
                      setSelectedCondition={setSelectedCondition}
                      updateSelectedPurchaseYear={updateSelectedPurchaseYear}
                      updateSelectedPriceRannge={updateSelectedPriceRannge}
                      updateResetAllFilter={updateResetAllFilter}
                      brandsByCategory={brandsByCategory}
                      allBrands={allBrands}
                      updateSelectedColor={updateSelectedColor}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      updateSelectedCategory={updateSelectedCategory}
                      selectedColor={selectedColor}
                      selectedshippingarr={selectedshippingarr}
                      setSelectedshippingarr={setSelectedshippingarr}
                    />
                  )}
                  {data &&
                    data.length <= 0 &&
                    JSON.stringify(currentQuery) !==
                      JSON.stringify({
                        category: "",
                        brands: "",
                      }) &&
                    JSON.stringify(currentQuery) !==
                      JSON.stringify({
                        brands: "",
                        category: "",
                      }) && <ResultNotFound />}
                  {data && data.length > 0 && (
                    <Spin
                      style={{ top: 200, color: "#FF533F" }}
                      indicator={antIcon}
                      spinning={isGetFilterLotsLoading}
                    >
                      <Row>
                        {/* <Col span={24}></Col> */}
                        <Col span={24} style={{ position: "relative" }}>
                          <Row
                            // justify="space-around"
                            className="productListingWrapper"
                            gutter={[
                              {
                                xs: 0,
                                sm: 0,
                                md: 30,
                                lg: 30,
                                xl: 30,
                              },
                              {
                                xs: 15,
                                sm: 15,
                                md: 30,
                                lg: 30,
                                xl: 30,
                              },
                            ]}
                          >
                            {data &&
                              data.map((val, i) => {
                                const q = buildQuery({
                                  ...currentQuery,
                                });
                                return (
                                  <Col
                                    xxl={6}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={11}
                                    xs={11}
                                    key={i}
                                  >
                                    <ProductCard
                                      renewed={renewed}
                                      hits={val}
                                      url={q}
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                          {data && data.length >= 1 && (
                            <Row
                              gutter={[
                                0,
                                {
                                  xs: 21,
                                  sm: 21,
                                  md: 38,
                                  lg: 38,
                                  xl: 38,
                                },
                              ]}
                              align="middle"
                              justify={isXS ? "center" : "end"}
                            >
                              <Col span={24} />
                              <Col>
                                <Pagination
                                  size="small"
                                  showSizeChanger={false}
                                  total={totalnew}
                                  pageSize={24}
                                  onChange={(value) => handlePagination(value)}
                                  itemRender={itemRender}
                                />
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Spin>
                  )}
                </Col>
                <Col span={24}></Col>
              </Row>
            </div>

            {/* {lots && lots.length <= 0 && (
                        <Col
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 50,
                            }}
                        >
                            <Empty
                                description={
                                    <div
                                        style={{
                                            fontFamily: 'Montserrat',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Sorry, No items found.
                                    </div>
                                }
                            />
                        </Col>
                    )} */}
          </div>
        </div>
        {/* <Row  justify="center" gutter={[0, { xs: 77, sm: 24, md: 60, lg: 60, xl:60 }]}>
          <Col span={24}/>
          <Col span={24}>
        <div className="relatedBack">
          <Row justify="center" gutter={[0, { xs: 23, sm: 23, md: 48, lg: 48, xl:48 }]}>
            <Col span={24}></Col>
            <Col xs={0} sm={0} md={24} lg={24} xl={24}></Col>
            <Col xs={0} sm={0} md={24} lg={24} xl={24}></Col>
            <Col>
              <Typography className="relatedItemText">Related items</Typography>
            </Col>
          </Row>
          <Row
            gutter={[0, { xs: 5, sm: 5, md: 76, lg: 76 }]}
            justify="center"
          >
            <Col span={24}></Col>
            <Col xl={20} lg={20} md={24} sm={22} xs={22}>
              <Row gutter={[{ xs:22, sm: 22, md: 25, lg: 25, xl:25 }, 0]} justify="center">
                <Carousel
                  itemsToShow={isXS ? 1.75 : 4}
                  itemsToScroll={4}
                  breakPoints={isXS ? [] : breakPoints}
                  showArrows={isXS ? false : true}
                  renderArrow={myArrow}
                  renderPagination={myDots}
                >
                  {PlSimilarCardData.map((val, i) => {
                    return (
                      <Col key={i}>
                        <RelatedItem
                          cover={val.pLImage}
                          itemName={val.pLName}
                          price={val.pLPrice}
                          shipping={val.pLShipping}
                        />
                      </Col>
                    );
                  })}
                </Carousel>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[0, { xs: 36, sm: 36, md: 54, lg: 54, xl:54 }]}
          >
            <Col span={24}></Col>
            <Col>
              <Button className="cSeeAllButton">See all</Button>
            </Col>
            <Col span={24}></Col>
          </Row>
        </div>
        </Col>
        </Row>
      </div>

      <Row justify="center" gutter={[0, { xs: 53, sm: 53, md: 48, lg: 48, xl:120 }]}>
        <Col span={24}></Col>
        <Col md={24} lg={24} xl={24}></Col>
        <Col>
          <Typography className="searchedCategoryText">
            Customers who searched for strollers also searched for
          </Typography>
        </Col>
        <Col>
          <img alt="" src={Magnifying} className="magnifyingWeb" />
          <img alt="" src={Magnifying} className="magnifyingmob" />
        </Col>
      </Row>

      <Row gutter={[0, { xs: 20, sm: 20, md: 82, lg: 82 }]} justify="center">
        <Col span={24}></Col>
        <Col xl={20} lg={20} md={24} sm={22} xs={22}>
          <Row gutter={[{ xs: 22, sm: 22, md: 20, lg: 20, xl:20 }, 0]}>
            <Carousel
              itemsToShow={isXS ? 1.75 : 4}
              itemsToScroll={4}
              breakPoints={isXS ? [] : breakPoints}
              showArrows={isXS ? false : true}
              renderArrow={myArrow}
              renderPagination={myDots}
            >
              {PlSimilarCardData.map((val, i) => {
                return (
                  <Col key={i}>
                    <RelatedItem
                      cover={val.pLImage}
                      itemName={val.pLName}
                      price={val.pLPrice}
                      shipping={val.pLShipping}
                    />
                  </Col>
                );
              })}
            </Carousel>
          </Row>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, { xs: 36, sm: 36, md: 54, lg: 54, xl:54 }]}>
        <Col span={24}></Col>
        <Col>
          <Button className="cSeeAllButton">See all</Button>
        </Col>
        <Col xs={24} sm={24} md={0} lg={0}></Col>
      </Row> */}
        {/* {lots && lots.length <= 0 && search &&
        <ResultNotFound />
} */}
      </div>

      {/* )} */}
      {/* {lots && lots.length <= 0 && <ResultNotFound />} */}
    </>
  );
};
export default ProductList;
