import React from "react";
import { Row, Col, Typography, Card, Button, Tabs, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { setFilterCondition } from "../Actions/brandsAction";
import { useDispatch, useSelector } from "react-redux";
import { useGaEvent } from "../hooks/useGaEvent";

const ShopByDealss = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { gaPageView, gaEventClick } = useGaEvent();
  return (
    <div className="shopbyDealsWrapper">
      <Typography className="sectionTitles dealsTitle" align="center">
        Shop by deals
      </Typography>

      <Row className="shopbyDeals app-wrapper">
        <Col
          className="shopBy10"
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          onClick={() => {
            gaEventClick("Browse", "Shop by deals", "$10");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition(10));
          }}
        >
          <h2>Shop under</h2>
          <p className="priceOption">$10</p>
        </Col>
        <Col
          className="shopBy20"
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          onClick={() => {
            gaEventClick("Browse", "Shop by deals", "$20");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition(20));
          }}
        >
          <h2>Shop under</h2>
          <p className="priceOption">$20</p>
        </Col>
        <Col
          className="shopBy50"
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          onClick={() => {
            gaEventClick("Browse", "Shop by deals", "$50");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition(50));
          }}
        >
          <h2>Shop under</h2>
          <p className="priceOption">$50</p>
        </Col>
        <Col
          className="shopBy100"
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          onClick={() => {
            gaEventClick("Browse", "Shop by deals", "$100");
            history.push("/search-new/?refresh=true&zipCode=10100");
            dispatch(setFilterCondition(100));
          }}
        >
          <h2>Shop under</h2>
          <p className="priceOption">$100</p>
        </Col>
      </Row>
    </div>
  );
};

export default ShopByDealss;
