import React from 'react'
import { Row, Col, Typography, Card, Button, Tabs, Spin } from "antd";
import StripeImg from "../assets/stripelogo2.png";
import TrustImg from "../assets/new-assets/TrustImg-1.png";
import trustBuyerGuaranteeImg from '../assets/new-assets/trustBuyerGuaranteeImg.png'
import { Anchor } from 'antd';
import { Link } from "react-router-dom";
import { useGaEvent } from '../hooks/useGaEvent';

const MarkidTrust = () => {
  const { gaEventClick } = useGaEvent();
  return (
    <div className='markIdTrustWrapper'>
       <Row className="markIdTrust app-wrapper">
       <Col className='blockItem'>
            <div className='left'>
                <img src={TrustImg} alt="" className='TrustImg' />
            </div>
            <div className='right'>
            <h2>Sit back & relax</h2>
            <p>All shipping nationwide transactions are secured by <img src={StripeImg} alt="" className='StripeImg' /></p>
            {/* <Link onClick={() => gaEventClick("Content", "Sitback and relax")} to="/general-questions" className="links">Learn more</Link> */}
            </div>
         
        </Col>    
        <Col className='blockItem'>
            <div className='left'>
                <img src={trustBuyerGuaranteeImg} alt="" className='TrustImg' />
            </div>
            <div className='right'>
            <h2>Markid’s buyer<br />protection Guarantee</h2>
            <p>&nbsp;</p>
            {/* <Link onClick={()=> gaEventClick("Content", "Buyer protection guarantee")} to="/general-questions" className="links">Learn more</Link> */}

            </div>
         
        </Col>
      </Row>
    </div>
  )
}

export default MarkidTrust
