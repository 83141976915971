/*eslint-disable*/
import React from "react";
import {
    Row,
    Col,
    Typography,
    Divider,
    Button,
  } from "antd";
  import "../App.css";
const { Text, Title } = Typography;
const MarkidCheckout = (props) => {
  const { heading, itemTotal, itemTotalVal, shipping, shippingVal, salesTax, salesTaxVal, cartTotal, cartTotalVal, button } =props
  return (
    <>
       <Row gutter={[0,48]}>
           <Col span={24}></Col>
              <Col>
                <Title level={4} className='notify-me'>
                  {heading}
                </Title>
              </Col>    
            </Row>

            <Row justify="space-between" gutter={[0,32]}>
                <Col span={24}></Col>
              <Col>
                <Text className='items-total'>{itemTotal}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${itemTotalVal}</Text>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{shipping}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>{shippingVal}</Text>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{salesTax}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${salesTaxVal}</Text>
              </Col>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Col>
                <Text className='items-total'>{cartTotal}</Text>
              </Col>
              <Col>
                <Text className='product-cart-value'>${itemTotalVal}</Text>
              </Col>
            </Row>
            <Divider />
           
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
               {button}
              </Col>
            </Row>
    </>
  );
};
export default MarkidCheckout;
