import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Steps, Divider, Button, Affix } from "antd";
// import ShippingLogo from "../assets/ShippingLogo.svg";
// import MarkidreNewedlogo from "../assets/MarkidReNewedLogo.svg";
import Logo from "../assets/logo.svg";
import CTMobLogo from "../assets/CTMobLogo.svg";
import CheckoutProcess from "../pages/CheckoutProcess";
import CheckoutPayment from "../pages/CheckoutPayment";
import CheckoutConfirmation from "../pages/CheckoutConfirmation";
import { useBreakpoints } from './../react-responsive-hooks/index';
import { Helmet } from "react-helmet";

const { Step } = Steps;
const { Text, Title } = Typography;

const CheckoutTopBar = (props) => {
  const { isXS, isSM } = useBreakpoints();
  const {current}=props
  const steps = [
    {
      title: 'SHIPPING',
    },
    {
      title: 'PAYMENT',
    },
    {
      title: 'ORDER PLACED',
    },
  ];
 

  const next = () => {
    // setCurrent(current + 1);
  };

  const prev = () => {
    // setCurrent(current - 1);
  };
  return (
    <>
      <Helmet>
        <title>Markid - Checkout</title>
      </Helmet>
      <Affix offsetTop={1} style={{ background: "#fff", position: "sticky", top: 0, zIndex: 10 }}>
        <div className="app-wrapper">
          <Row gutter={[0, 0]} className="checkoutHeader">
            {/* <Col span={24}></Col> */}
            <Col
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              md={{ span: 4, offset: 0 }}
              sm={{ span: 22, offset: 0 }}
              xs={{ span: 22, offset: 0 }}
              className="checkTopParent"
            >
              {/* <Link to="/homepage"> */}
              <Link to="/">
                {(isXS || isSM) ?
                  <img alt="logo" src={CTMobLogo} />
                  :
                  <img alt="logo" src={Logo} />
                }
              </Link>
              {/* <Row gutter={[48, 0]} align="middle" justify='center'>
            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
            <Link to="/">
            <img alt="logo" src={MarkidreNewedlogo} />
          </Link>
            </Col>
            <Col>
              <Title strong level={4}>
                Shipping
              </Title>
            </Col>
          </Row> */}
            </Col>
            <Col
              xl={{ span: 8, offset: 12 }}
              lg={{ span: 8, offset: 12 }}
              md={{ span: 8, offset: 12 }}
            // sm={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}
            >
              {!isXS && <Stepper current={current} data={steps} className="checkout-steps" />}
            </Col>


            {/* <Col>
          <div>
            {current < steps.length - 1 && (
              <Button onClick={() => next()} className="">
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button onClick={() => message.success('Processing complete!')}>
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </Col> */}
          </Row>
        </div>
      </Affix>
      <Row gutter={[0, 20]} style={{ background: '#F7F7F7' }}>
        {/* <Col span={24} /> */}
        <Col span={24} className="steps">
          {isXS && <Stepper current={current} data={steps} />}
        </Col>
      </Row>
      {/* {
        current === 0 && (
          <div>
            <CheckoutProcess
              nextMethod={next}
            />
          </div>
        )
      }
      {
        current === 1 && (
          <div >
            <CheckoutPayment
              prevMethod={prev}
              nextMethod={next}
            />
          </div>
        )
      }
      {
        current === 2 && (
          <div >
            <CheckoutConfirmation />
          </div>
        )
      } */}
    </>
  );
};

const Stepper = (props) => {
  const { data, current } = props;

  return <Steps current={current} size="small" labelPlacement="vertical" className='checkoutStep'>
    {data.map((item) => (
      <Step key={item.title} title={item.title} className='checkoutStepTitle' />
    ))}
  </Steps>
}
export default CheckoutTopBar;