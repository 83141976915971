/*eslint-disable*/
import React from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Button,
  Spin
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PaymentForm from "../components/PaymentForm";
import MakePayment from "../components/MakePayment";
import "../App.css";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Payment = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { nextMethod, prevMethod } = props
  const { currentCart, currentAddressId, currentUser, currentUserWallet, isPostCartLoading, isPostOrderLoading } = useSelector((state) => {
    const { currentCart, currentAddressId, currentUserWallet, isPostCartLoading } = state.cart;
    const { currentUser } = state.user;
    const { isPostOrderLoading } = state.order;
    return {
      currentCart,
      currentAddressId,
      currentUserWallet,
      currentUser,
      isPostCartLoading,
      isPostOrderLoading
    };
  });
  return (
    <>
      {/* <ShippingTopbar /> */}
      <Spin indicator={antIcon} spinning={isPostOrderLoading}>
      <Row justify="center">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card bordered={false}>
            <Elements stripe={stripePromise}>
              <PaymentForm prevMethod={prevMethod} nextMethod={nextMethod} />
              </Elements>
          </Card>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Card className='total-item-chekout-card' bordered={false}>
           <MakePayment 
           heading="Total"
           itemTotal="Items total"
           itemTotalVal={(_.sumBy(currentCart, function(o) { return o.price; }) / 100).toFixed(2)}
           shipping="Shipping"
           shippingVal="Free"
           salesTax="Sales tax"
           salesTaxVal={(0).toFixed(2)}
           cartTotal="Cart Total"
           cartTotalVal="199.61"
           button={
            <Button
            block
            className={'squarebutton'}
            htmlType='submit'
            form="stripeForm"
            // onClick={() => nextMethod()}
          >
            PAY
          </Button>
           }
           />
          </Card>
        </Col>
      </Row>
      </Spin>
    </>
  );
};
export default Payment;
