import constants from "../Utils/constants";
const initialState = {
  isGetPartnerProductByIdLoading: false,
  
  partnerProduct: [],
  productDetails: {},
};

const partnerProductsReducer = (state = initialState, action) => {
  switch (action.type) {

    case constants.GET_MARKID_PRODUCT_BY_ID_REQUEST:
      return {
        ...state,
        isGetPartnerProductByIdLoading: true,
      };
    case constants.GET_MARKID_PRODUCT_BY_ID_SUCCESS:
     
      return {
        ...state,
        isGetPartnerProductByIdLoading: false,
        productDetails: action.payload,
      };
    case constants.GET_MARKID_PRODUCT_BY_ID_FAILURE:
      return {
        ...state,
        isGetPartnerProductByIdLoading: false,
      };
    case constants.GET_PARTNER_PRODUCT_BY_ID_REQUEST:
      return {
        ...state,
        isGetPartnerProductByIdLoading: true,
      };
    case constants.GET_PARTNER_PRODUCT_BY_ID_SUCCESS:
      const partnerProductData = action.payload;
      //   partnerProductData.seller = action.payload.seller
      return {
        ...state,
        isGetPartnerProductByIdLoading: false,
        partnerProduct: partnerProductData,
      };
    case constants.GET_PARTNER_PRODUCT_BY_ID_FAILURE:
      return {
        ...state,
        isGetPartnerProductByIdLoading: false,
      };
    default:
      return state;
  }
};
export default partnerProductsReducer;
