import React, { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import { Helmet } from "react-helmet";
import ankur from "../assets/new-assets/ankur.png";
import cartin from "../assets/new-assets/cartin.png";
import devyani from "../assets/new-assets/devyani.png";
const Founders = (props) => {
  return (
    <div className="foundersbody">
        
      <img src={ankur} alt="..." height="" width="" className="founderimg" />
      <p><h2>Ankur Dhawan</h2><h3>CEO & Founder</h3>Ankur has held several leadership positions across key businesses at Amazon. His last role was Head of Product for Amazon Prime & Amazon Consumables, driving growth and building scalable solutions for their subscription-based model. Prior to that, he served as Head of Growth & Innovation for Baby & Kid’s eCommerce marketplace Diapers.com and as Head of Product & Analytics at Audible.com. Prior to Amazon, he also led Product Strategy at American Express.</p>

      <img src={cartin} alt="..." height="" width="" className="founderimg"/>
      <p><h2>Stephanie Cartin</h2><h3>Co-Founder, Community, Growth & Partnerships</h3>Stephanie is the Co-founder of Socialfly a leading social-first digital and influencer marketing agency based in New York City and the co-founder of Entreprenista, a leading media company and membership community for women founders. Socialfly has been named to Inc. 5000's fastest-growing private companies for two consecutive years and has worked with over 200 brands including the Girl Scouts, WE tv, Conair, Nest Fragrances, 20th Century Fox, Happy Family Organics, and Univision.</p>

      <img src={devyani} alt="..." height="" width="" className="founderimg"/>
      <p><h2>Devyani Mehta</h2><h3>Co-Founder, Customer Experience</h3>Devyani brings a deep commitment to customer experience and a data-driven mindset. With over 15 years experience in Customer Marketing, Content Strategy, and Personalization across her roles at Citi, Amazon, and American Express, Devyani has delivered contextually relevant and personalized marketing strategies anchored in AI & data-driven methodology.  </p>
    </div>
  );
};

export default Founders;
