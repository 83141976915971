import React from "react";
import {    
     Row, Col, Typography
  } from "antd";
  const {Text} = Typography
  export const ConsignmentItem = (props) => {
    const { category, brand, model, year} = props;
    return (
       <>
                <Row gutter={[16, 16]}>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <Text>{category}</Text>
                  </Col>
                </Row>

                <Row style={{paddingTop:20}}>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <Col>
                      <Text >Brand</Text>
                    </Col>
                    <Col>
                      <Text >{brand}</Text>
                    </Col>
                  </Col>
                  <Col sxl={8} lg={8} md={8} sm={8} xs={8}>
                    <Col>
                      <Text >Model</Text>
                    </Col>
                    <Col>
                      <Text >{model}</Text>
                    </Col>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <Col>
                      <Text >Year</Text>
                    </Col>
                    <Col>
                      <Text >{year}</Text>
                    </Col>
                  </Col>
</Row>
</>
    );
};
