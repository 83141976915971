import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Checkbox, Collapse, Badge, Radio } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { humanize } from "../Utils/util";
import { useGaEvent } from "../hooks/useGaEvent";

import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";

import { useBreakpoints } from "./../react-responsive-hooks/index";

export const CategoryFilter = (props) => {
  const { selectedCategory, checkdonCategoryChange, currentPage, totalcount } =
    props;
  const { Panel } = Collapse;

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { gaPageView, gaEventClick } = useGaEvent();
  const [currentOpenPanelKey, setCurrentOpenPanelKey] = useState("");
  function dropdownCallback(key) {
    
    // if (key.length === 0) {
    //   setCurrentOpenPanelKey("");
    //   localStorage.removeItem("Active-Filter");
    // } else if (key.length === 1) {
    //   setCurrentOpenPanelKey(key[0]);
    //   localStorage.setItem("Active-Filter", key[0]);
    // } else if (key.length === 2) {
    //   setCurrentOpenPanelKey(key[1]);
    //   localStorage.setItem("Active-Filter", key[1]);
    // } else {
    //   setCurrentOpenPanelKey(key[key.length - 1]);
    //   localStorage.setItem("Active-Filter", key[key.length - 1]);
    // }
  }
  const [key, setkey] = useState();
  const { isXS, isSM } = useBreakpoints();
  useEffect(() => {
    if (!(isXS || isSM)) {
      if (currentPage[3] === "clothing_and_accessories") {
        setkey(6);
      }
      if (currentPage[3] === "maternity") {
        setkey(6);
      }
      if (currentPage[3] === "shoes") {
        setkey(6);
      }
      if (currentPage[3] === "gear") {
        setkey(6);
      }
      if (currentPage[3] === "feeding") {
        setkey(6);
      }
      if (currentPage[3] === "electronics") {
        setkey(6);
      }
      if (currentPage[3] === "safety_equipment") {
        setkey(6);
      }
      if (currentPage[3] === "diapering") {
        setkey(6);
      }
      if (currentPage[3] === "toys_and_costumes") {
        setkey(6);
      }
      if (currentPage[3] === "cribs_and_furniture") {
        setkey(6);
      }
      if (currentPage[3] === "bathing") {
        setkey(6);
      }
      if (currentPage[3] === "sports_bicycles") {
        setkey(6);
      }
      if (currentPage[3] === "books") {
        setkey(6);
      }
      if (currentPage[3] === "strollers") {
        setkey(6);
      }
    }
  }, [currentPage]);

  return (
    <>
      <Collapse
        defaultActiveKey={["6"]}
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        onChange={() => {gaEventClick("Filter by", "Category","open/close")}}
        bordered={false}
        expandIcon={({ isActive }) => {
          if(isActive){

          }
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        <Panel
          className="collapseContent"
          style={{
            padingTop: 15,
            paddingBottom: 19,
            marginTop: 15,
            marginBottom: 19,
          }}
          header={<Typography className="pFCollapseTitle">Category</Typography>}
          key={key}
        >
          {totalcount &&
            Object.entries(totalcount.category || {}).map((val, i) => (
              <Row gutter={[12, 16]} key={i}>
                <Col>
                  <Checkbox
                    onChange={() => {checkdonCategoryChange(val[0]);gaEventClick("Filter by", "Category",val[0])}}
                    checked={selectedCategory.indexOf(val[0]) !== -1}
                  />
                </Col>
                <Col>
                  <Typography className="conditionName">
                    {humanize(val[0])}{" "}
                    {val[1] > 0 ? (
                      <span className="ProductCount">({val[1]})</span>
                    ) : (
                      <span>(0)</span>
                    )}
                  </Typography>
                </Col>
              </Row>
            ))}
        </Panel>
      </Collapse>
    </>
  );
};
