/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Steps, Divider, Button } from "antd";
// import ShippingLogo from "../assets/ShippingLogo.svg";
import MarkidreNewedlogo from "../assets/MarkidReNewedLogo.svg";
import MarkidShipping from "../pages/MarkidShipping";
import Payment from "../pages/Payment";
import MarkidOrderConfirmation from "../pages/MarkidOrderConfirmation";
const { Step } = Steps;
const { Text, Title } = Typography;

const ShippingTopbar = () => {
  const steps = [
    {
      title: 'SHIPPING',
    },
    {
      title: 'PAYMENT',
    },
    {
      title: 'CONFIRMATION',
    },
  ];
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}></Col>
        <Col 
        xl={{ span: 4, offset: 2 }} 
        lg={{ span: 4, offset: 2 }} 
        md={{ span: 4, offset: 2 }} sm={{ span: 22, offset: 2 }} xs={{ span: 22, offset: 2 }}>
        <Link to="/">
            <img alt="logo" src={MarkidreNewedlogo} />
          </Link>
          {/* <Row gutter={[48, 0]} align="middle" justify='center'>
            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
            <Link to="/">
            <img alt="logo" src={MarkidreNewedlogo} />
          </Link>
            </Col>
            <Col>
              <Title strong level={4}>
                Shipping
              </Title>
            </Col>
          </Row> */}
        </Col>
        <Col
        xl={{ span: 8, offset: 2 }}
        lg={{ span: 8, offset: 2 }}
        md={{ span: 8, offset: 2 }}
        sm={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}
        >
          <Steps current={current} size="small"  labelPlacement="vertical">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} style={{ fontSize: '0.75vw' }} />
            ))}
          </Steps>
        </Col>
        {/* <Col>
          <div>
            {current < steps.length - 1 && (
              <Button onClick={() => next()} className="">
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button onClick={() => message.success('Processing complete!')}>
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </Col> */}
      </Row>

      <Divider />
      {current === 0 && (
        <div>
          <MarkidShipping
            nextMethod={next}
          />
        </div>
      )}
      {current === 1 && (
        <div >
          <Payment
          prevMethod={prev}
          nextMethod={next}
          />
        </div>
      )}
      {current === 2 && (
        <div >
          <MarkidOrderConfirmation />
        </div>
      )}
    </>
  );
};
export default ShippingTopbar;
