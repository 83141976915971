import constants from '../Utils/constants';
const initialState = {
    isGetOffersLoading: false, isPostOfferLoading: false, isGetOfferDeclineReasonsLoading: false, isOfferCancelByBuyerLoading: false, isOfferCancelBySellerLoading: false, isAcceptOfferLoading: false, 
    isOfferLotSoldLoading: false, isLotDeliveredLoading: false, isOfferApplyPinLoading: false, isOfferDisputeLoading: false, isUpdateOfferLoading: false, offerPaymentLoader: false,
    offers: [], declineReasons: {}, errorMessage: "", madeOfferData: [], createdOffer: {}
}

const offerReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_OFFERS_REQUEST:
            return {
                ...state, isGetOffersLoading: true
            }
        case constants.GET_OFFERS_SUCCESS:
            return {
                ...state, isGetOffersLoading: false, offers: action.payload
            }
        case constants.GET_OFFERS_FAILURE:
            return {
                ...state, isGetOffersLoading: false
            }

        case constants.POST_OFFER_REQUEST:
            return {
                ...state, isPostOfferLoading: true
            }
        case constants.POST_OFFER_SUCCESS:
            return {
                ...state, isPostOfferLoading: false, createdOffer: action.payload
            }
        case constants.POST_OFFER_FAILURE:
            const { message } = action.payload.error
            return {
                ...state, isPostOfferLoading: false, errorMessage: message
            }

            case constants.UPDATE_OFFER_REQUEST:
            return {
                ...state, isUpdateOfferLoading: true
            }
        case constants.UPDATE_OFFER_SUCCESS:
            return {
                ...state, isUpdateOfferLoading: false
            }
        case constants.UPDATE_OFFER_FAILURE:
            return {
                ...state, isUpdateOfferLoading: false
            }

            case constants.GET_OFFER_DECLINE_REASONS_REQUEST:
                return {
                    ...state, isGetOfferDeclineReasonsLoading: true
                }
            case constants.GET_OFFER_DECLINE_REASONS_SUCCESS:
                return {
                    ...state, isGetOfferDeclineReasonsLoading: false, declineReasons: action.payload
                }
            case constants.GET_OFFER_DECLINE_REASONS_FAILURE:
                return {
                    ...state, isGetOfferDeclineReasonsLoading: false
                }

                case constants.OFFER_CANCEL_BY_BUYER_REQUEST:
                    return {
                        ...state, isOfferCancelByBuyerLoading: true
                    }
                case constants.OFFER_CANCEL_BY_BUYER_SUCCESS:
                    return {
                        ...state, isOfferCancelByBuyerLoading: false
                    }
                case constants.OFFER_CANCEL_BY_BUYER_FAILURE:
                    return {
                        ...state, isOfferCancelByBuyerLoading: false
                    }

                    case constants.OFFER_CANCEL_BY_SELLER_REQUEST:
                        return {
                            ...state, isOfferCancelBySellerLoading: true
                        }
                    case constants.OFFER_CANCEL_BY_SELLER_SUCCESS:
                        return {
                            ...state, isOfferCancelBySellerLoading: false
                        }
                    case constants.OFFER_CANCEL_BY_SELLER_FAILURE:
                        return {
                            ...state, isOfferCancelBySellerLoading: false
                        }

                        case constants.ACCEPT_OFFER_REQUEST:
                            return {
                                ...state, isAcceptOfferLoading: true
                            }
                        case constants.ACCEPT_OFFER_SUCCESS:
                            return {
                                ...state, isAcceptOfferLoading: false
                            }
                        case constants.ACCEPT_OFFER_FAILURE:
                            return {
                                ...state, isAcceptOfferLoading: false
                            }

                            case constants.OFFER_LOT_SOLD_REQUEST:
                                return {
                                    ...state, isOfferLotSoldLoading: true
                                }
                            case constants.OFFER_LOT_SOLD_SUCCESS:
                                return {
                                    ...state, isOfferLotSoldLoading: false
                                }
                            case constants.OFFER_LOT_SOLD_FAILURE:
                                return {
                                    ...state, isOfferLotSoldLoading: false
                                }

                                case constants.LOT_DELIVERED_REQUEST:
                                    return {
                                        ...state, isLotDeliveredLoading: true
                                    }
                                case constants.LOT_DELIVERED_SUCCESS:
                                    return {
                                        ...state, isLotDeliveredLoading: false
                                    }
                                case constants.LOT_DELIVERED_FAILURE:
                                    return {
                                        ...state, isLotDeliveredLoading: false
                                    }

                                    case constants.OFFER_APPLY_PIN_REQUEST:
                                        return {
                                            ...state, isOfferApplyPinLoading: true
                                        }
                                    case constants.OFFER_APPLY_PIN_SUCCESS:
                                        return {
                                            ...state, isOfferApplyPinLoading: false
                                        }
                                    case constants.OFFER_APPLY_PIN_FAILURE:
                                        return {
                                            ...state, isOfferApplyPinLoading: false
                                        }

                                        case constants.OFFER_DISPUTE_REQUEST:
                                            return {
                                                ...state, isOfferDisputeLoading: true
                                            }
                                        case constants.OFFER_DISPUTE_SUCCESS:
                                            return {
                                                ...state, isOfferDisputeLoading: false
                                            }
                                        case constants.OFFER_DISPUTE_FAILURE:
                                            return {
                                                ...state, isOfferDisputeLoading: false
                                            }

                                            case constants.MADE_OFFER_DATA:
                                            return {
                                                ...state, madeOfferData: action.payload
                                            }

                                            case constants.OFFER_PAYMENT_STATRTED: 
                                            let newOfferPaymentLoader = action.payload.offerPaymentLoader
                                            return {
                                                ...state, offerPaymentLoader: newOfferPaymentLoader
                                            }

            case "CLEAR ERROR STATE":
                return {
                  ...state,
                  errorMessage: "",
                };
              case "RESET_APP":
                return { ...initialState };
        default:
            return state
    }
}
export default offerReducer;