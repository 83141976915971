import React, { useState ,useEffect} from "react";
import {
  Row,
  Col,
  Card,
  Typography
} from "antd";
import NotFoundSearch from "../../assets/new-assets/Blogresult.png";
import "./ResultNotFound.css";
import { useHistory } from "react-router-dom";

const BlogResultNotFound = (props) => {
    const { Blogtags } = props;
    const [color, setColor] = useState({});
    useEffect(() => {
    const tempobj={};
      Blogtags.forEach((val)=>{
        if (val.name === "sleeping") {
          tempobj[val.name]="#fff"
        } else if (val.name === "Feeding") {
          tempobj[val.name]="#FFDBDB"
        } else if (val.name === "Newborns") {
          tempobj[val.name]="#FFE8B6"
        } else if (val.name === "Toddler Care") {
          tempobj[val.name]="#E3E6FFE5"
        } else if (val.name === "Pregnancy & Postpartum") {
          tempobj[val.name]="#FECCFFE5"
        } else if (val.name === "Sleep Time") {
          tempobj[val.name]="#CDF3FF"
        } else if (val.name === "Childcare") {
          tempobj[val.name]="#FFBEA3E5"
        } else if (val.name === "Parent Corner") {
          tempobj[val.name]="#D3FFCCE5"
        }
        else{
          tempobj[val.name]="#fff"
        }
        setColor(tempobj)
      })
      
    }, [Blogtags]);

const history=useHistory()
  let getTagsList = () => {
    if (Blogtags.length) {
     Blogtags.map((category) => {
        return <li className="category" onClick={()=>{history.push(`/learn/blog/${category.id}`)}} style={color[category.name]?{background:color[category.name]}:{background:"#ccc"}}>{category.name}</li>;
      });
    }
  };
 
  return (
    <Card bordered={false} className="rNFCardBg">
      <div className="BlogWrapper">
        <Row
          justify="center"
          gutter={[0, { xs: 0, sm: 0, md: 37, lg: 37, xl: 37, xxl: 37 }]}
        >
          <Col span={24}></Col>
          <Col xxl={24} xl={24} sm={24} xs={24}>
            <Row justify="center">
              <div className="rNFImgBg">
                <Row justify="center">
                  <img alt="" src={NotFoundSearch} className="rNFImage" />
                </Row>
              </div>
              {/* <img alt="" src={MarkidCommunityImg} /> */}
            </Row>
            <div className="sorryalign">
            <Row justify="center" gutter={[0, 0]}>
              <Col span={24}></Col>
              <Col sm={15} xs={15}>
                <Typography className="rNFTitle">
                  Sorry. No results found
                </Typography>
              </Col>
            </Row>
            <Row justify="center" gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col sm={16} xs={16}>
                <Typography className="rNFDes">
                  Explore from our existing categories of articles...
                </Typography>
              </Col>
            </Row>
            </div>
            <div>
              <ul className="TagList">{getTagsList()} </ul>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default BlogResultNotFound;
