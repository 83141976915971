import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Checkbox,
  Collapse,
  Badge,
  Slider,
  Input,
  Radio,
} from "antd";
import { useGaEvent } from "../hooks/useGaEvent";
import plusicon from "../assets/plusign.jpg";
import minussign from "../assets/new-assets/minussign.jpg";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useBreakpoints } from "./../react-responsive-hooks/index";
export const PriceFilter = (props) => {
  const {
    totalcount,
    selectedPriceRange,
    dropdownCallback,
    sliderMin,
    sliderMax,
    priceFilter,
    sliderMaxVal,
    sliderMinVal,
    currentPage,
    onChangedPriceRange,
    setSliderMinVal,
    setSliderMaxVal,
    priceValues,
    setPriceValues,
    initialPriceValues,
  } = props;
  const { Panel } = Collapse;
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const [key, setkey] = useState();
  const { gaPageView, gaEventClick } = useGaEvent();
  //const priceValues = [{title: "$0 to $10", value: false},{title: "$10 to $50", value: false},{title: "$50 to $100", value: false}];

  const { isXS, isSM } = useBreakpoints();

  const checkdonPriceChange = (val, i, initialPriceValues) => {
   
    if (val[i].value) {
      setPriceValues(initialPriceValues);
      onChangedPriceRange([0, 2500]);
    } else {
      const newValues = [...initialPriceValues];
      newValues[i].value = true;

      setPriceValues(newValues);
      onChangedPriceRange(newValues[i].filterParams);
      gaEventClick("Filter by", "Price",newValues[i].filterParams)
    }

    // if (range === "$0 to $10") {
    //   onChangedPriceRange([0, 10]);

    //   priceValues[0].value = true;
    //   priceValues[1].value = false;
    //   priceValues[2].value = false;

    //   setPriceValues(priceValues);
    // }
    // else{
    //   setPriceLabel("");
    //   priceValues[0].value = false;
    //   setPriceValues(priceValues);
    // }
    // if (range === "$10 to $50") {
    //   onChangedPriceRange([10, 50]);
    //   setPriceLabel("$10 to $50")
    //   priceValues[1].value = true;
    //   priceValues[0].value = false;
    //   priceValues[2].value = false;
    //   setPriceValues(priceValues);
    // }
    // else{
    //   setPriceLabel("");
    //   priceValues[1].value = false;
    //   priceValues[0].value = false;
    //   priceValues[1].value = false;
    //   setPriceValues(priceValues);
    // }
    // if (range === "$50 to $100" ) {
    //   onChangedPriceRange([50, 100]);
    //   setPriceLabel("$50 to $100")
    //   priceValues[2].value = true;
    //   priceValues[0].value = false;
    //   priceValues[1].value = false;
    //   setPriceValues(priceValues);
    // }
    // else{
    //   setPriceLabel("");
    //   priceValues[0].value = false;
    //   priceValues[1].value = false;
    //   priceValues[2].value = false;
    //   setPriceValues(priceValues);
    // }
  };

  useEffect(() => {
    if (!(isXS || isSM)) {
      if (currentPage[3] === "maternity") {
        setkey(3);
      }
      if (currentPage[3] === "gear") {
        setkey(3);
      }
      if (currentPage[3] === "shoes") {
        setkey(3);
      }
      if (currentPage[3] === "feeding") {
        setkey(3);
      }
      if (currentPage[3] === "electronics") {
        setkey(3);
      }
      if (currentPage[3] === "safety_equipment") {
        setkey(3);
      }
      if (currentPage[3] === "strollers") {
        setkey(3);
      }
      if (currentPage[3] === "toys_and_costumes") {
        setkey(3);
      }
      if (currentPage[3] === "cribs_and_furniture") {
        setkey(3);
      }
      if (currentPage[3] === "bathing") {
        setkey(3);
      }
      if (currentPage[3] === "sports_bicycles") {
        setkey(3);
      }
      if (currentPage[3] === "diapering") {
        setkey(3);
      }
    }
  }, [currentPage]);
  return (
    <>
      <Collapse
        className="pFCollaps"
        // defaultActiveKey={currentOpenPanelKey}
        expandIconPosition="right"
        defaultActiveKey={["3"]}
        onChange={()=>{gaEventClick("Filter by", "Price","open/close")}}
        bordered={false}
        expandIcon={({ isActive }) => {
          if (!isXS) {
            return !isActive ? (
              <DownOutlined className="collapsIcon" />
            ) : (
              <UpOutlined className="collapsIcon" />
            );
          } else {
            return !isActive ? (
              <img src={plusicon} className="collapsIcon" />
            ) : (
              <img src={minussign} className="collapsIcon" />
            );
          }
        }}
      >
        <Panel
          className="collapseContent"
          style={{
            padingTop: 15,
            paddingBottom: 19,
            marginTop: 15,
            marginBottom: 19,
          }}
          header={<Typography className="pFCollapseTitle">Price</Typography>}
          // key={key}
        >
          {" "}
          <Row className="priceRowOne">
            <Col>
              <Input
                placeholder="min"
                onChange={(val) => {
                  setSliderMinVal(val);
                  onChangedPriceRange([sliderMinVal, sliderMaxVal]);
                }}
                value={selectedPriceRange?.min}
                defaultValue={sliderMin}
              />
            </Col>
            <Col>
              <Input
                placeholder="max"
                onChange={(val) => {
                  setSliderMaxVal(val);
                  onChangedPriceRange([sliderMinVal, sliderMaxVal]);
                }}
                value={selectedPriceRange?.max}
                defaultValue={sliderMax}
              />
            </Col>
          </Row>
          {priceValues?.map((val, i) => {
            return (
              <Row gutter={[12, 16]}>
                <Col>
                  <Checkbox
                    onClick={() =>
                      {checkdonPriceChange(priceValues, i, initialPriceValues);}
                    }
                    checked={val.value}
                  />
                </Col>
                <Col>
                  <Typography className="conditionName">{val.title}</Typography>
                </Col>
              </Row>
            );
          })}
        </Panel>
      </Collapse>
    </>
  );
};

// <Slider
// range={true}
// tipFormatter={(value) => `$${value}`}
// min={sliderMin}
// max={sliderMax}
// draggableTrack={true}
// onChange={(value) => onChangedPriceRange(value)}
// marks={priceFilter}
// className="pFSlider"
// defaultValue={[sliderMinVal, sliderMaxVal]}
// value={[selectedPriceRange?.min, selectedPriceRange?.max]}
// />

// display: flex;
// justify-content: center;
// align-items: center;
// width: 49%;
