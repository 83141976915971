import constants from "../Utils/constants";
const initialState = {
  isGetAllLotsLoading: false,
  isGetLotByIdLoading: false,
  isCreateLotLoading: false,
  isAddToFavoritesLoading: false,
  isRemoveFromFavoritesLoading: false,
  isGetSearchLotLoading: false,
  isGetFilterLotsLoading: false,
  isGetNonRenewedLotsLoading: false,
  isGetyearOfDetailsLoading: false,
  isUploadProductPhotoLoading: false,
  yearOfDetails: [],
  nonReanewedLots: [],
  filterLots: [],
  SearchLot: [],
  allLots: { hits: [] },
  algoliabrandlist: [],
  searchedLots: [],
  lot: [],
  searchPageLots: [],
  checkedValues: "",
  productImageURL: {},
};

const lotsReducer = (state = initialState, action) => {
  // switch (action.type) {
  //   case constants.GET_ALL_LOTS_REQUEST:
  //     return {
  //       ...state,
  //       isGetAllLotsLoading: true,
  //     };
  //   case constants.GET_ALL_LOTS_SUCCESS:
  //   const search = action.search
  //   let searchedArray = []
  //   if(search){
  //     const lotsObjects = action.payload.lots;
  //     lotsObjects.map((lot) => searchedArray.push({...lot, label: lot.name, value: lot._id}))
  //   }
  //   return {
  //       ...state,
  //       isGetAllLotsLoading: false,
  //       allLots: search ? state.allLots : action.payload, searchedLots: search ? searchedArray : [],
  //       searchPageLots: action.payload.lots
  //     };
  //   case constants.GET_ALL_LOTS_FAILURE:
  //     return {
  //       ...state,
  //       isGetAllLotsLoading: false,
  //     };
  switch (action.type) {
    case constants.GET_ALL_MARKIDPRODUCTS_REQUEST:
      return {
        ...state,
        isGetAllLotsLoading: true,
      };
    case constants.GET_ALL_MARKIDPRODUCTS_SUCCESS:
      const search = action.search;
      let searchedArray = [];
      if (search) {
        const lotsObjects = action.payload.hits;
        lotsObjects.map((lot) =>
          searchedArray.push({ ...lot, label: lot.name, value: lot._id })
        );
      }
      return {
        ...state,
        isGetAllLotsLoading: false,
        allLots: search ? state.allLots : action.payload,
        searchedLots: search ? searchedArray : [],
        searchPageLots: action.payload.hits,
      };
    case constants.GET_ALL_MARKIDPRODUCTS_FAILURE:
      return {
        ...state,
        isGetAllLotsLoading: false,
      };
    case constants.GET_ALGOLIABRANDS_REQUEST:
      return {
        ...state,
        brandlistloading: true,
      };
    case constants.GET_ALGOLIABRANDS_SUCCESS:
      return {
        ...state,
        brandlistloading: false,
        algoliabrandlist: action.payload,
      };
    case constants.GET_ALGOLIABRANDS_FAILURE:
      return {
        ...state,
        brandlistloading: false,
      };
    case constants.GET_NON_RENEWED_LOTS_REQUEST:
      return {
        ...state,
        isGetNonRenewedLotsLoading: true,
      };
    case constants.GET_NON_RENEWED_LOTS_SUCCESS:
      return {
        ...state,
        isGetNonRenewedLotsLoading: false,
        nonReanewedLots: action.payload,
      };
    case constants.GET_NON_RENEWED_LOTS_FAILURE:
      return {
        ...state,
        isGetNonRenewedLotsLoading: false,
      };

    case constants.GET_FILTER_LOTS_REQUEST:
      return {
        ...state,
        isGetFilterLotsLoading: true,
      };
    case constants.GET_FILTER_LOTS_SUCCESS:
      return {
        ...state,
        isGetFilterLotsLoading: false,
        filterLots: action.payload,
        total: action.payload.nbHits,
        totalcount: action.payload.facets,
      };
    case constants.GET_FILTER_LOTS_FAILURE:
      return {
        ...state,
        isGetFilterLotsLoading: false,
      };
    case constants.RESER_FILTER_PRODUCTS:
      return {
        ...initialState,
        filterLots: [],
      };

    case constants.GET_YEAR_OF_DETAILS_REQUEST:
      return {
        ...state,
        isGetyearOfDetailsLoading: true,
      };
    case constants.GET_YEAR_OF_DETAILS_SUCCESS:
      const uniqArray = action.payload;
      let values = [];
      if (uniqArray.length > 0) {
        const { uniqueValues } = uniqArray[0];
        values = uniqueValues.filter((a) => a);
      }
      return {
        ...state,
        isGetyearOfDetailsLoading: false,
        yearOfDetails: action.payload,
      };
    case constants.GET_YEAR_OF_DETAILS_FAILURE:
      return {
        ...state,
        isGetyearOfDetailsLoading: false,
      };

    case constants.FILTER_LOTS:
      return {
        ...state,
        checkedValues: action.payload,
      };

    case constants.GET_LOT_BY_ID_REQUEST:
      return {
        ...state,
        isGetLotByIdLoading: true,
      };
    case constants.GET_LOT_BY_ID_SUCCESS:
      const lotWithSeller = action.payload.lot;
      lotWithSeller.seller = action.payload.seller;
      return {
        ...state,
        isGetLotByIdLoading: false,
        lot: lotWithSeller,
      };
    case constants.GET_LOT_BY_ID_FAILURE:
      return {
        ...state,
        isGetLotByIdLoading: false,
      };

    case constants.CREATE_LOT_REQUEST:
      return {
        ...state,
        isCreateLotLoading: true,
      };
    case constants.CREATE_LOT_SUCCESS:
      return {
        ...state,
        isCreateLotLoading: false,
      };
    case constants.CREATE_LOT_FAILURE:
      return {
        ...state,
        isCreateLotLoading: false,
      };

    case constants.ADD_TO_FAVORITES_REQUEST:
      return {
        ...state,
        isAddToFavoritesLoading: true,
      };
    case constants.ADD_TO_FAVORITES_SUCCESS:
      return {
        ...state,
        isAddToFavoritesLoading: false,
      };
    case constants.ADD_TO_FAVORITES_FAILURE:
      return {
        ...state,
        isAddToFavoritesLoading: false,
      };

    case constants.REMOVE_FROM_FAVORITES_REQUEST:
      return {
        ...state,
        isRemoveFromFavoritesLoading: true,
      };
    case constants.REMOVE_FROM_FAVORITES_SUCCESS:
      return {
        ...state,
        isRemoveFromFavoritesLoading: false,
      };
    case constants.REMOVE_FROM_FAVORITES_FAILURE:
      return {
        ...state,
        isRemoveFromFavoritesLoading: false,
      };

    case constants.UPLOAD_PRODUCT_PHOTO_REQUEST:
      return {
        ...state,
        isUploadProductPhotoLoading: true,
      };
    case constants.UPLOAD_PRODUCT_PHOTO_SUCCESS:
      var { productImageURL } = state;
      productImageURL.photo = action.payload.url;
      return {
        ...state,
        isUploadProductPhotoLoading: false,
        productImageURL,
      };
    case constants.UPLOAD_PRODUCT_PHOTO_FAILURE:
      return {
        ...state,
        isUploadProductPhotoLoading: false,
      };

    case constants.RESET_SEARCH:
      return {
        ...state,
        searchedArray: [],
      };
    case constants.GET_HANDPICKED_ITEM_SUCCESS:
      return {
        ...state,
        filterLots: action.payload,
      };
    default:
      return state;
  }
};
export default lotsReducer;
