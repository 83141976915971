import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Divider, Spin, Modal, Input, Alert } from "antd";
import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { offerCancelByBuyer, offerCancelBySeller, acceptOffer, acceptSellersOfferByBuyer, getOffers, createMakeOffer } from "../Actions/offerAction";
import "../App.css";
import { getRemainingTime, getAddressStr } from './../Utils/util';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	LoadingOutlined,
} from "@ant-design/icons";
import { postMessageByChatId } from "../Actions/chatsAction";

export const Type0 = (props) => {
	const { messageData, cName, type } = props
	const { message, createdAt } = messageData
	return (
		<>
			{type === 'sender' &&
				<>
					<Col span={24}>
						<Card className={cName}>
							<Typography className='senderMessage'>
								{message}
							</Typography>
						</Card>
					</Col>
					<Col span={24}>
						<Row justify='space-between'>
							<Col>

							</Col>

							<Col>
								<Row gutter={[6, 5]}>
									<Col span={24}></Col>
									<Col>
										<Typography className='messageTime'>
											{moment(createdAt).format('LT')}
										</Typography>
									</Col>
									<Col style={{ marginTop: -7 }}>
										<CheckOutlined style={{ fontSize: 7 }} />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</>
			}
			{type === 'reciver' &&
				<Col span={24}>
					<Card className={cName}>
						<Typography className='reciverMessage'>
							{message}
						</Typography>
					</Card>
					<Row justify='space-between'>
						<Col>
							<Row gutter={[0, 5]}>
								<Col span={24}></Col>
								<Typography className='messageTime'>
									{moment(createdAt).format('LT')}
								</Typography>
							</Row>
						</Col>

						<Col>	</Col>
					</Row>
				</Col>

			}
		</>
	);
};


export const Type1 = (props) => {
	const dispatch = useDispatch()
	const { offers, isGetOffersLoading } = useSelector((state) => {
		const { offers, isGetOffersLoading } = state.offer
		return {
			offers,
			isGetOffersLoading
		};
	});
	const { status, trackingNumber, shippingLabel, trackingUrl, address } = offers || {}
	const { messageData, cName, type, deliveryType, LotCreatedBy, currentUserId, chatId, offerId, productData, lastType1MessageId, chatLastAcceptedOfferMessageId, chatCounterOfferMessageId, messageId, messageType } = props
	const buyerAddressStr = getAddressStr(address);
	const { offerPrice, createdAt, message } = messageData
	const successCallBackCancelByBuyer = () => {
		dispatch(getOffers(offerId, chatId))
	}
	const failureCallBackCancelByBuyer = () => { }
	const successCallBackCancelBySeller = () => {
		dispatch(getOffers(offerId, chatId))
	}
	
	const failureCallBackCancelBySeller = () => { }
	// useEffect(() => {
	// 	dispatch(getOffers(offerId, chatId))
	//   }, []); 

	// let remainingTime = '00:00';
	// if (messageType === 1 || messageType === 4 || messageType === 2 || messageType === 3) {
	// 	if (moment(createdAt).diff(moment(), 'days') === 0) {
	// 		const remTime = getRemainingTime(messageData?.createdAt);
	// 		[remainingTime] = remTime;
	// 	}
	// }

	// const [isOfferExpired, setIsOfferExpired] = useState(status === 'EXPIRED' || moment(createdAt).diff(moment(), 'days') !== 0);
	// const [rTime, setRemainingTime] = useState(remainingTime);
	// useEffect(() => {
	//   const timer = setInterval(() => {
	// 	  if (moment(createdAt).diff(moment(), 'days') === 0) {
	// 		const rT = getRemainingTime(messageData?.createdAt);
	// 		setRemainingTime(rT[0]);
	// 	  } else {
	// 		setRemainingTime('00:00');
	// 		setIsOfferExpired(true);
	// 		// clearInterval(timer);
	// 	  }
	//   }, 60000);
	//   return () => clearInterval(timer);
	// }, []);

	return (
		<>
			<Spin spinning={isGetOffersLoading}>
				{type === 'sender' ?
					<TypeOneSender
						{...props} status={status} createdAt={createdAt} offerPrice={offerPrice}
						cName={cName} chatId={chatId} successCallBackCancelByBuyer={successCallBackCancelByBuyer} failureCallBackCancelByBuyer={failureCallBackCancelByBuyer}
						successCallBackCancelBySeller={successCallBackCancelBySeller} failureCallBackCancelBySeller={failureCallBackCancelBySeller} productData={productData}
						lastType1MessageId={lastType1MessageId} chatLastAcceptedOfferMessageId={chatLastAcceptedOfferMessageId} chatCounterOfferMessageId={chatCounterOfferMessageId} messageId={messageId} messageType={messageType}
						message={message} trackingNumber={trackingNumber} shippingLabel={shippingLabel} trackingUrl={trackingUrl} buyerAddressStr={buyerAddressStr} LotCreatedBy={LotCreatedBy} currentUserId={currentUserId}
					/>
					// rTime={rTime} isOfferExpired={isOfferExpired}/>
					:
					<TypeOneReciver
						{...props} status={status} createdAt={createdAt} offerPrice={offerPrice} cName={cName} deliveryType={deliveryType}
						LotCreatedBy={LotCreatedBy} currentUserId={currentUserId} chatId={chatId} successCallBackCancelByBuyer={successCallBackCancelByBuyer} failureCallBackCancelByBuyer={failureCallBackCancelByBuyer}
						successCallBackCancelBySeller={successCallBackCancelBySeller} failureCallBackCancelBySeller={failureCallBackCancelBySeller} lastType1MessageId={lastType1MessageId} chatLastAcceptedOfferMessageId={chatLastAcceptedOfferMessageId} chatCounterOfferMessageId={chatCounterOfferMessageId} messageId={messageId}
						// rTime={rTime} isOfferExpired={isOfferExpired}
						productData={productData} messageType={messageType} shippingLabel={shippingLabel} message={message} trackingUrl={trackingUrl} trackingNumber={trackingNumber} buyerAddressStr={buyerAddressStr} />}
			</Spin>
		</>
	);
}

export const TypeOneSender = (props) => {
	const dispatch = useDispatch()
	const history = useHistory();
	// const { cName, offerId, chatId, status, offerPrice, createdAt, successCallBackCancelByBuyer, failureCallBackCancelByBuyer, productData, lastType1MessageId, messageId, rTime, isOfferExpired } = props
	const { cName, offerId, chatId, status, offerPrice, createdAt, successCallBackCancelBySeller, failureCallBackCancelBySeller, successCallBackCancelByBuyer, failureCallBackCancelByBuyer, productData, lastType1MessageId, chatLastAcceptedOfferMessageId, chatCounterOfferMessageId, messageId, messageType, message, trackingUrl, shippingLabel, trackingNumber, buyerAddressStr, LotCreatedBy, currentUserId } = props
	const {
		// category,
		deliveryType,
		yearOfPurchase,
		location,
		size,
		negotiablePrice,
		name,
		brand,
		price,
		sellerAddress,
		inSoldPending,
		createdBy,
		_id,
	} = productData;
	productData.price = parseInt(offerPrice)
	const handleOfferCancelByBuyer = (data) => {
		dispatch(offerCancelByBuyer(offerId, data, successCallBackCancelByBuyer, failureCallBackCancelByBuyer));
	}
	const handleOfferCancelBySeller = (data) => {
		dispatch(offerCancelBySeller(offerId, data, successCallBackCancelBySeller, failureCallBackCancelBySeller));
	}
	const handleAddItemCart = (item) => {
		item.productType = "P2P"
		dispatch({ type: 'ADD_ITEM_CART', payload: { item: item } })
		// history.push("/markid-cart")
		history.push("/cart")
	}
	let remainingTime = '00:00';
	if (messageType === 1 || messageType === 4 || messageType === 2 || messageType === 3) {
		if (moment(createdAt).diff(moment(), 'days') === 0) {
			const remTime = getRemainingTime(createdAt);
			[remainingTime] = remTime;
		}
	}

	const [isOfferExpired, setIsOfferExpired] = useState(status === 'EXPIRED' || moment(createdAt).diff(moment(), 'days') !== 0);
	const [rTime, setRemainingTime] = useState(remainingTime);
	useEffect(() => {
		
		const timer = setInterval(() => {
			if (moment(createdAt).diff(moment(), 'days') === 0) {
				const rT = getRemainingTime(createdAt);
				setRemainingTime(rT[0]);
			} else {
				setRemainingTime('00:00');
				setIsOfferExpired(true);
				// clearInterval(timer);
			}
		}, 60000);
		return () => clearInterval(timer);
	}, []);
	return (
		<Col>
			<Card className={cName}>
				<Row>
					{(messageType === 1 || messageType === 4) &&
						<Col span={24}>
							<Row>
								<Col>
									<Typography className='yourOfferText'>
										Your offer
									</Typography>
								</Col>
							</Row>
							<Row gutter={[0, 6]}>
								<Col span={24}></Col>
								<Col>
									<Typography className='yourOfferPrice'>
										${offerPrice / 100}
									</Typography>
								</Col>
							</Row>
						</Col>
					}
					<Col span={24}>
						{lastType1MessageId === messageId && (status === "CANCELED_BY_BUYER" || status === "CANCELED_BY_SELLER") &&
							<>
								{/* <Divider /> */}
								<Row>
									<Col>
										<Typography className='cancelOfferText'>
											Offer has been cancelled
										</Typography>
									</Col>
								</Row>
							</>
						}
						{lastType1MessageId === messageId && (productData.deliveryType == "meetup" || productData.deliveryType == "both") && (status === "ACCEPT_BY_SELLER" || status === "ACCEPT_BY_BUYER") &&
							<>
								<Row gutter={[0, 28.4]} justify='end'>
									<Col span={24}></Col>
									<Col>
										<Typography className="cancelOfferText">Offer has been accepted</Typography>
									</Col>
								</Row>
							</>
						}
						{lastType1MessageId === messageId && productData.deliveryType == "shipping" && (status === "ACCEPT_BY_SELLER" || status === "ACCEPT_BY_BUYER") &&
							<>
								<Row gutter={[0, 28.4]} justify='end'>
									<Col span={24}></Col>
									<Col>
										<Typography className="cancelOfferText">Offer has been accepted</Typography>
									</Col>
								</Row>
							</>
						}
						{lastType1MessageId === messageId && !isOfferExpired && LotCreatedBy !== currentUserId && deliveryType === 'shipping' && (status === "ACCEPT_BY_SELLER" || status === "ACCEPT_BY_BUYER") &&
							<>
								<Row gutter={[0, 28.4]} justify='end'>
									<Col span={24}></Col>
									<Col>
										<Button className='offerAcceptButton' onClick={() => handleAddItemCart(productData)}>
											Pay now
										</Button>
									</Col>
								</Row>
							</>
						}
						{lastType1MessageId === messageId && !isOfferExpired && (status === "NEW" || status === "EXPIRED") &&
							<Row gutter={[0, 28.4]} justify='end'>
								<Col span={24}></Col>
								<Col>
									<Button className='offerAcceptButton'
										onClick={() => handleOfferCancelByBuyer()}>
										Cancel 
									</Button>
								</Col>
							</Row>
						}
						{/* {lastType1MessageId === messageId && !isOfferExpired && LotCreatedBy === currentUserId && (status === "NEW" || status === "COUNTER_BY_SELLER") &&
							<Row gutter={[0, 28.4]} justify='end'>
								<Col span={24}></Col>
								<Col>
									<Button className='offerCancelButton'
										onClick={() => handleOfferCancelBySeller()}
									>
										Cancel
									</Button>
								</Col>
							</Row>
						} */}
					</Col>
				</Row>
				<div >
					{messageType === 10 && (productData.deliveryType === 'shipping' || productData.deliveryType === 'both')&&
						< div className="orderdetailscontainer" >
							<Row justify="center">
								<Col>
									<Typography className="orderdetails" style={{ color: "#646FDE" }}>Order Details </Typography>
								</Col>
							</Row>
							<Row></Row>
							<Row justify="center">
								<Col>
									<Typography className="ordertext" style={{ color: "#707070" }}>Below is the tracking number for your product</Typography>
								</Col>
							</Row>
							<Row>
								<Col>
									<Typography className="ordertext1" style={{ color: "#707070" }}>Tracking number:
										<Typography className="Trackingnumber" style={{ color: "#FF533F" }} >{trackingNumber}</Typography></Typography>
								</Col>
							</Row>
							<Row></Row>
							<Row justify="center">
								<Col>
									<Typography className="ordertext1" style={{ color: "#707070" }}>Delivery Address:
										<Typography className="ordertext2" style={{ color: "#707070" }} >{buyerAddressStr}</Typography></Typography>
								</Col>
							</Row>
							<Row justify="center" >
								<Col className="trackbutton">
									<Button className="Trackorderbutton" style={{ color: "#FD533F" }} >

										{/* <a style={{color:"#FD533F"}} href={trackingUrl+'track-order'} target="/track-order" > */}
										<a style={{ color: "#FD533F" }} href={trackingUrl} target="_blank" >
											Track Order
										</a>

									</Button>
								</Col>
							</Row >
							<Row></Row>
							<Row justify="center">
								<Col>
									<Typography className="ordertext3" style={{ color: "#646FDE" }}>We've also sent this order detail info to your mail.</Typography>
								</Col>
							</Row>
						</div>
					}
				</div>
				{lastType1MessageId !== messageId && <>
					<Divider />
					<Row>
						<Col>
							<Typography className="cancelOfferText">{message}</Typography>
						</Col>
					</Row>
				</>}
			</Card>
			{
				lastType1MessageId === messageId && !isOfferExpired && (status === 'NEW' || status === 'COUNTER_BY_SELLER') &&
				<Row gutter={[36, 5]} justify="space-between">
					<Col span={24}></Col>
					<Col>
						<Row gutter={[6.83, 0]}>
							<Col style={{ marginTop: -7 }}>
								<ClockCircleOutlined style={{ fontSize: 7 }} />
							</Col>
							<Col>
								<Typography className="expireOfferText">Expires in {rTime} hours</Typography>
							</Col>
						</Row>
					</Col>
				</Row>
			}
			{lastType1MessageId === messageId && status === "EXPIRED" &&
				<Row gutter={[36, 5]} justify='space-between'>
					<Col span={24}></Col>
					<Col>
						<Row gutter={[6.83, 0]}>
							<Col style={{ marginTop: -7 }}>
								<ClockCircleOutlined style={{ fontSize: 7 }} />
							</Col>
							<Col>
								<Typography className='expireOfferText'>
									Offer is Expired
								</Typography>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row gutter={[6, 0]}>
							<Col>
								<Typography className='messageTime'>
									{moment(createdAt).format('LT')}
								</Typography>
							</Col>
							<Col style={{ marginTop: -7 }}>
								<CheckOutlined style={{ fontSize: 7 }} />
							</Col>
						</Row>
					</Col>
				</Row>
			}
		</Col>
	)
}

export const TypeOneReciver = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { cName, offerId, chatId, status, offerPrice, createdAt, deliveryType, LotCreatedBy, currentUserId,
		successCallBackCancelByBuyer, failureCallBackCancelByBuyer, successCallBackCancelBySeller, failureCallBackCancelBySeller,
		// lastType1MessageId, messageId, rTime, isOfferExpired, productData } = props
		lastType1MessageId, chatLastAcceptedOfferMessageId, chatCounterOfferMessageId, messageId, productData, messageType, message, trackingUrl, shippingLabel, trackingNumber, buyerAddressStr } = props
	// const { addressedTo } = messageData
	// const handleOfferCancelByBuyer = (data) => {
	// 	dispatch(offerCancelByBuyer(offerId, data));
	// }
	const schema = yup.object().shape({
		price: yup.string("Offer Price is required"),
	});
	const {
		isAuthenticated, currentChat
	} = useSelector((state) => {
		const {
			currentUser,
			isFollowUserLoading,
			isUnFollowUserLoading,
			isNotifyItemLoading,
			isUnNotifyItemLoading,
		} = state.user;
		const { isAuthenticated } = state.auth;
		const { currentChat } = state.chats
		return {
			isAuthenticated,
			currentChat
		};
	});
	const { lot, lastMessage } = currentChat
	const handleOfferCancelBySeller = (data) => {
		dispatch(offerCancelBySeller(offerId, data, successCallBackCancelBySeller, failureCallBackCancelBySeller));
	}
	const [showMakeOfferDialog, setShowMakeOfferDialog] = useState(false);
	const handleAcceptOffer = (data) => {
		dispatch(acceptOffer(offerId, data, successCallBackAccept, failureCallBackAccept));
	}
	const handleAcceptOfferBySendingMessage = (data) => {
		dispatch(postMessageByChatId(chatId, {
			message: '💰 You accepted the offer',
			lastMessageId: chatCounterOfferMessageId,
			type: 3,
			offerPrice,
		}))
	}
	const handleAcceptSellersOfferByBuyer = (data) => {
		dispatch(acceptSellersOfferByBuyer(offerId, data, successCallBackAccept, failureCallBackAccept));
	}
	const successCallBackAccept = () => {
		dispatch(getOffers(offerId, chatId))
	}
	const failureCallBackAccept = () => { }

	const handleCounterOfferBySeller = (data) => {
		setShowMakeOfferDialog(true)
	}
	const handleCancel = () => {
		setShowMakeOfferDialog(false)
	}
	const { handleSubmit, control, reset, errors, setValue: setFormValues } = useForm({
		resolver: yupResolver(schema),
	});
	const { isPostOfferLoading, errorMessage } = useSelector((state) => {
		const { isPostOfferLoading, errorMessage } = state.offer
		return {
			isPostOfferLoading,
			errorMessage
		};
	});
	const [open, setOpen] = useState(false);
	const handleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const submitCounterOffer = (data) => {
		if (isAuthenticated) {
			data.lotId = productData._id
			const p = parseFloat(productData.price)
			data.price = control.fieldsRef.current.price.ref.value * 100
			dispatch(postMessageByChatId(chatId, {
				message: 'You sent a counteroffer to',
				lastMessageId: chatCounterOfferMessageId,
				type: 4,
				offerPrice: data.price
			}))
			setShowMakeOfferDialog(false)
		}
		else {
			history.push('/onboarding')
		}
	}
	const successCallBack = () => {
		window.location.reload()
	};
	const failureCallBack = () => { };
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const handleDeclineOfferBySeller = (data) => {
		dispatch(offerCancelBySeller(offerId, data, successCallBackCancelBySeller, failureCallBackCancelBySeller));
	}
	const handleDeclineOfferByBuyer = (data) => {
		dispatch(offerCancelByBuyer(offerId, data, successCallBackCancelByBuyer, failureCallBackCancelByBuyer));
	}
	const handleAddItemCart = (item) => {
		/* 20220112 Varun: To clear cart of existing items left if any as this is an express checkout */
		item.shippingPrice = (parseFloat(lot.shippingPrice) > 0 && lot.isFree != true) ? lot.shippingPrice : '0'
		item.price = parseInt(lastMessage.offerPrice)
		item.productType = "P2P"
		dispatch({ type: "RESET_CART" })
		dispatch({ type: 'ADD_ITEM_CART', payload: { item: { ...item, updateLotInChatConfig: { chatLastAcceptedOfferMessageId, chatId, offerId } } } });
		// history.push("/markid-cart")
		history.push("/cart")
	}
	let remainingTime = '00:00';
	if (messageType === 1 || messageType === 4 || messageType === 2 || messageType === 3) {
		if (moment(createdAt).diff(moment(), 'days') === 0) {
			const remTime = getRemainingTime(createdAt);
			[remainingTime] = remTime;
		}
	}

	const [isOfferExpired, setIsOfferExpired] = useState(status === 'EXPIRED' || moment(createdAt).diff(moment(), 'days') !== 0);
	const [rTime, setRemainingTime] = useState(remainingTime);
	useEffect(() => {
		const timer = setInterval(() => {
			if (moment(createdAt).diff(moment(), 'days') === 0) {
				const rT = getRemainingTime(createdAt);
				setRemainingTime(rT[0]);
			} else {
				setRemainingTime('00:00');
				setIsOfferExpired(true);
				clearInterval(timer);
			}
		}, 60000);
		return () => clearInterval(timer);
	}, []);
	return (
		<>
			<Col>
				<Card className={cName}>
					{messageType === 10 && (deliveryType === 'shipping' || deliveryType === 'both') &&
						<div className="orderdetailscontainer">
							<Row justify="center">
								<Col>
									<Typography className="orderdetails" style={{ color: "#646FDE" }}>Shipping Details</Typography>
								</Col>
							</Row>
							<Row></Row>
							<Row justify="center">
								<Col>
									<Typography className="ordertext" style={{ color: "#707070" }}>Below is the tracking number for your product</Typography>
								</Col>
							</Row>
							<Row>
								<Col>
									<Typography className="ordertext1" style={{ color: "#707070" }}>Tracking number:
										<Typography className="Trackingnumber" style={{ color: "#FF533F" }} >{trackingNumber}</Typography></Typography>
								</Col>
							</Row>
							<Row></Row>
							<Row justify="center">
								<Col>
									<Typography className="ordertext1" style={{ color: "#707070" }}>Delivery Address:
										<Typography className="ordertext2" style={{ color: "#707070" }} >{buyerAddressStr}</Typography></Typography>
								</Col>
							</Row>
							<Row justify="center">
								<Col className="trackbutton">
									<Button className="Trackorderbutton" style={{ color: "#FD533F" }} >
										{/* <Link  to={shippingLabel}>
                        Download Label
                        </Link> */}
										{/* <a className="Trackorderbutton" href={shippingLabel+'shippingLabel'} target="/shippingLabel" style={{color:"#FD533F"}}> */}
										<a className="Trackorderbutton" href={shippingLabel} target="_blank" style={{ color: "#FD533F" }}>
											Download Label
										</a>
									</Button>
								</Col>
							</Row>
							<Row justify="center">
								<Col>
									<Typography className="ordertext3" style={{ color: "#646FDE" }}>We've also sent this order detail info to your mail.</Typography>
								</Col>
							</Row>
						</div>
					}

					<Row gutter={[55, 0]}>
						{(messageType === 1 || messageType === 4) && LotCreatedBy === currentUserId &&
							<Col span={24}>
								<Row>
									<Col>
										<Typography className='yourOfferText'>
											Offer Recieved
										</Typography>
									</Col>
								</Row>
								<Row gutter={[0, 6]}>
									<Col span={24}></Col>
									<Col>
										<Typography className='yourOfferPrice'>
											${offerPrice / 100}
										</Typography>
									</Col>
								</Row>
							</Col>
						}
						<Col>
							{LotCreatedBy !== currentUserId && lastType1MessageId === messageId && status !== 'ACCEPT_BY_SELLER' && <Typography className="yourOfferText">Seller's offer</Typography>}
						</Col>
						{/* {lastType1MessageId === messageId && deliveryType === 'shipping' && LotCreatedBy === currentUserId && status === 'NEW' && (
            <Col>
              <Typography className="offerSellerDeclineButton" style={{ cursor: 'pointer' }} onClick={() => handleDeclineOfferBySeller()}>
                Decline
              </Typography>
            </Col>
          )} */}
					</Row>
					{lastType1MessageId === messageId && LotCreatedBy !== currentUserId && status !== 'ACCEPT_BY_SELLER' &&
						<Row gutter={[0, 6]}>
							<Col span={24}></Col>
							<Col>
								<Typography className="yourOfferPrice">${offerPrice / 100}</Typography>
							</Col>
						</Row>}
					{lastType1MessageId === messageId && (status === 'CANCELED_BY_BUYER' || status === 'CANCELED_BY_SELLER') && (
						<>
							<Divider className="chatDivider" />
							<Row>
								<Col>
									<Typography className="cancelOfferText">Offer has been cancelled</Typography>
								</Col>
							</Row>
						</>
					)}
					{lastType1MessageId === messageId && (status === "ACCEPT_BY_SELLER" || status === "ACCEPT_BY_BUYER") && (
						<>
							{/* <Divider /> */}
							<Row>
								{(deliveryType == "meetup") ?
									<>
										{(status === "ACCEPT_BY_SELLER") ?
											<Col span={24}>
												<Row>
													<Col>
														<Typography className='yourOfferText'>
															Your offer
														</Typography>
													</Col>
												</Row>
												<Row gutter={[0, 6]}>
													<Col span={24}></Col>
													<Col>
														<Typography className='yourOfferPrice'>
															${offerPrice / 100}
														</Typography>
													</Col>
												</Row>
											</Col>
											: <></>}
										<Divider />
									</> : <></>}
								<Col>
									<Typography className="cancelOfferText">Offer has been accepted</Typography>
								</Col>
							</Row>
						</>
					)}
					{lastType1MessageId !== messageId && LotCreatedBy !== currentUserId && (messageType === 1 || messageType === 4) &&
						/* 20220201 Varun: Need above condition for all negotiable flows ie delivery, local meetup and both */
						<>
							<Row>
								<Col span={24}>
									<Row>
										<Col>
											<Typography className='yourOfferText'>
												Seller's Offer
											</Typography>
										</Col>
									</Row>
									<Row gutter={[0, 6]}>
										<Col span={24}></Col>
										<Col>
											<Typography className='yourOfferPrice'>
												${offerPrice / 100}
											</Typography>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					}
					{lastType1MessageId !== messageId && LotCreatedBy !== currentUserId && deliveryType === 'shipping' && (status === 'ACCEPT_BY_SELLER' || messageType === 3) && (
						<>
							{/* <Divider /> */}
							<Row>
								<Col>
									<Typography className="cancelOfferText">Offer has been accepted</Typography>
								</Col>
							</Row>
						</>
					)}
					{lastType1MessageId !== messageId && deliveryType === 'meetup' && (status === 'ACCEPT_BY_SELLER' || messageType === 3) && (
						<>
							{/* <Divider /> */}
							<Row>
								<Col>
									<Typography className="cancelOfferText">Offer has been accepted</Typography>
								</Col>
							</Row>
						</>
					)}
					<Row gutter={[10, 28.4]} justify="space-between">
						<Col span={24}></Col>
						{/* {(deliveryType === 'shipping') && (LotCreatedBy === currentUserId) && (status === "NEW") && */}
						{(lastType1MessageId === messageId && !isOfferExpired)&& LotCreatedBy === currentUserId && status === 'NEW' && parseInt(props.messageData.offerPrice) < props.productData.price && (
							<Col>
								<Button className="offerAcceptButton" onClick={() => handleCounterOfferBySeller()}>
									Counter
								</Button>
							</Col>
						)}
						{/* {(deliveryType === 'shipping') && (LotCreatedBy !== currentUserId) && (status === "NEW") && */}
						{lastType1MessageId === messageId && LotCreatedBy === currentUserId && (status === 'NEW' || status === 'COUNTER_BY_SELLER') && (
							<Col>
								<Button className="offerAcceptButton" onClick={() => handleDeclineOfferBySeller()}>
									Decline
								</Button>
							</Col>
						)}
						{lastType1MessageId === messageId && LotCreatedBy !== currentUserId && (status === 'NEW' || status === 'COUNTER_BY_SELLER') && (
							<Col>
								<Button className="offerAcceptButton" onClick={() => handleDeclineOfferByBuyer()}>
									Decline
								</Button>
							</Col>
						)}
						{lastType1MessageId === messageId && LotCreatedBy === currentUserId && !isOfferExpired && (deliveryType == "meetup" || deliveryType == "both") && (status === 'NEW' || status === 'EXPIRED' || status === 'COUNTER_BY_SELLER') && (
							<Col>
								<Button className="offerAcceptButton" onClick={() => handleAcceptOffer()}>
									Accept
								</Button>
							</Col>
						)}
						{lastType1MessageId === messageId && LotCreatedBy === currentUserId && deliveryType === 'shipping' && (status === 'NEW' || status === 'EXPIRED' || status === 'COUNTER_BY_SELLER') && (
							<Col>
								<Button className="offerAcceptButton" onClick={() => handleAcceptOfferBySendingMessage()}>
									Accept
								</Button>
							</Col>
						)}
						{lastType1MessageId === messageId && (deliveryType == "meetup" || deliveryType == "both") && LotCreatedBy !== currentUserId && !isOfferExpired && (status === 'NEW' || status === 'EXPIRED' || status === 'COUNTER_BY_SELLER') && (
							<Col>
								<Button className="offerAcceptButton" onClick={() => handleAcceptSellersOfferByBuyer()}>
									Accept
								</Button>
							</Col>
						)}


						{lastType1MessageId === messageId && !isOfferExpired && LotCreatedBy !== currentUserId && deliveryType === 'shipping' && (status === "ACCEPT_BY_SELLER" || status === "COUNTER_BY_SELLER") &&
							<>
								<Col>
									<Button className='offerAcceptButton'
										onClick={() => handleAddItemCart(productData)}
									>
										Pay now
									</Button>
								</Col>
							</>
						}
					</Row>
					{/* {lastType1MessageId === messageId && !isOfferExpired && LotCreatedBy !== currentUserId && deliveryType === 'shipping' && (status === "ACCEPT_BY_SELLER" || status === "COUNTER_BY_SELLER") &&
			<>
				<Row gutter={[0, 28.4]} justify='end'>
					<Col span={24}></Col>
					<Col>
						<Button className='offerAcceptButton'
							onClick={() => handleAddItemCart(productData)}
						>
							Pay now 3
						</Button>
					</Col>
				</Row>
			</>
		} */}
					{/* {lastType1MessageId !== messageId && LotCreatedBy !== currentUserId && deliveryType !== 'shipping' && (messageType === 1 || messageType === 4) && <>
            <Row>
              <Col>
                <Typography className="cancelOfferText">{message}</Typography>
              </Col>
            </Row>
          </>} */}
					{/* {lastType1MessageId !== messageId && LotCreatedBy !== currentUserId && deliveryType === 'shipping' && (messageType === 1 || messageType === 4) && <>
            <Row>
              <Col>
                <Typography className="cancelOfferText">{message}</Typography>
              </Col>
            </Row>
          </>} */}
				</Card>
				{lastType1MessageId === messageId && !isOfferExpired && (status === 'NEW' || status === 'COUNTER_BY_SELLER') &&
					<Row gutter={[36, 5]} justify="space-between">
						<Col span={24}></Col>
						<Col>
							<Row gutter={[6.83, 0]}>
								<Col style={{ marginTop: -7 }}>
									<ClockCircleOutlined style={{ fontSize: 7 }} />
								</Col>
								<Col>
									<Typography className="expireOfferText">Expires in {rTime} hours</Typography>
								</Col>
							</Row>
						</Col>
					</Row>
				}
				{lastType1MessageId === messageId && status === 'EXPIRED' && (
					<Row gutter={[36, 5]} justify="space-between">
						<Col span={24}></Col>
						<Col>
							<Typography className="messageTime">{moment(createdAt).format('LT')}</Typography>
						</Col>
						<Col>
							<Row gutter={[6.83, 0]}>
								<Col style={{ marginTop: -7 }}>
									<ClockCircleOutlined style={{ fontSize: 7 }} />
								</Col>
								<Col>
									<Typography className="expireOfferText">Offer is Expired</Typography>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</Col>
			<Modal
				className='makeOfferModal'
				title={
					<Row justify='center'>
						<Typography className='makeOfferTitle'>
							Make a counteroffer
						</Typography>
					</Row>
				}
				visible={showMakeOfferDialog}
				onCancel={() => handleCancel()}
				centered
				footer={null}
				width={362}
			>
				<form onSubmit={handleSubmit(submitCounterOffer)}>
					<Spin style={{ color: '#FF533F' }} indicator={antIcon} spinning={(isPostOfferLoading)}>
						<Row gutter={[0, 15]}>
							<Col span={24}></Col>
							<Col>
								<Typography className='makeOfferContentEnterPrice'>
									Enter counteroffer price
								</Typography>
							</Col>
						</Row>
						<Row gutter={[0, 15]}>
							<Col span={24}></Col>
							<Col>
								<Controller
									as={Input}
									// defaultValue={priceOffVal} 
									required
									addonBefore="$"
									className='mAOInput'
									size='large'
									name="price"
									control={control}
									placeholder="Counter price"
								/>
								{errors.price && (
									<span style={{ color: "red" }}>
										Offer Price is required
									</span>
								)}
							</Col>
						</Row>
						{errorMessage &&
							<Row justify='center' gutter={[0, 20]}>
								<Col span={24}></Col>
								<Alert
									type="error"
									open={open}
									onClose={handleClose}
									message={errorMessage}
								/>
							</Row>
						}
						<Row justify='center' gutter={[0, deliveryType === 'both' ? 40 : 128]}>
							<Col span={24}></Col>
							<Col>
								<Button size='large' className={'makeOfferButton'}
									htmlType='submit'
								// disabled={priceInputVal > 0 ? false : true}
								>
									Submit
								</Button>
							</Col>
						</Row>
					</Spin>
				</form>
			</Modal>
		</>
	);
}