import React from "react";
import { Row, Col, Modal,Button } from "antd";
import "../App.css";
import insta from "../assets/new-assets/instamodel.png";
import facebook from "../assets/new-assets/facebookmodal.png";
import closeBtn from "../assets/new-assets/close-icon.png";
import twitter from "../assets/new-assets/twitter.png";
import linkedin from "../assets/new-assets/linkedinmodal.png";
import pin from "../assets/new-assets/pin.png";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useBreakpoints } from "./../react-responsive-hooks/index";
import { FacebookShareButton, TwitterShareButton,LinkedinShareButton, InstapaperIcon, InstapaperShareButton} from "react-share";
export const ShareBlogModal = (props) => {
  const { showBlogDialog, onCancelBlogDialog, Blogsid,Blogs,Blogimages } = props;
  const handleCancel = () => {
    onCancelBlogDialog();
  };
  
  const { isXS, isLG, isSM, isXL, isXXL, isMD } = useBreakpoints(); 
  return (
    <>
      {(isXS || isSM) && (
     <BottomSheet open={showBlogDialog}><Col onClick={handleCancel}>
     <img className="ShareModalimgClose" src={closeBtn} /></Col>
      <LinkedinShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`} onClick={handleCancel}>
     <Col className="BottomsheetBlog">
       <img className="ShareModalimg" src={linkedin} />
       <span className="BlogModalText">LinkedIn</span>
     </Col></LinkedinShareButton>
      <FacebookShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`} onClick={handleCancel} quote={ Blogs?.title.rendered } media={Blogimages?.guid.rendered}>
       <Col className="BottomsheetBlog">
         <img src={facebook} className="ShareModalimgFacebook" />
         <span className="BlogModalText">Facebook</span>
       </Col>{" "}
     </FacebookShareButton>
     <TwitterShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`} onClick={handleCancel} title={ Blogs?.title.rendered }>
     <Col className="BottomsheetBlog">
       <img src={twitter} className="ShareModalimgTwitter" />
       <span className="BlogModalText">Twitter</span>
     </Col></TwitterShareButton>
     {/* <InstapaperShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`}>
     <Col>
       <img src={insta} className="ShareModalimg" />
       <span className="BlogModalText">Share to Instagram</span>
     </Col></InstapaperShareButton> */}
     <div className="BottomsheetBlog">
     <CopyToClipboard text={`https://markid.io/learn/blogdetail/${Blogsid}`}>
     <Button  onClick={()=>{handleCancel();toast("copied to clipboard!")}}>
     <Col>
       <img src={pin}  className="ShareModalcopy" />
       <span className="BlogModalText">Copy link</span>
     </Col></Button></CopyToClipboard></div></BottomSheet>)}
      {(isXL || isLG || isXXL || isMD) && ( <Modal
        footer={null}
        width={250}
        visible={showBlogDialog}
        onCancel={() => handleCancel()}
      >
        <div>
          {/* <Row> */}
            {/* <Row gutter={[12, 19]}> */}
              <LinkedinShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`} onClick={handleCancel}>
              <Col>
                <img className="ShareModalimg" src={linkedin} />
                <span className="BlogModalText">LinkedIn</span>
              </Col></LinkedinShareButton>
               <FacebookShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`} onClick={handleCancel} quote={ Blogs?.title.rendered } media={Blogimages?.guid.rendered}>
                <Col>
                  <img src={facebook} className="ShareModalimgFacebook" />
                  <span className="BlogModalText">Facebook</span>
                </Col>{" "}
              </FacebookShareButton>
              <TwitterShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`} onClick={handleCancel} title={ Blogs?.title.rendered }>
              <Col>
                <img src={twitter} className="ShareModalimgTwitter" />
                <span className="BlogModalText">Twitter</span>
              </Col></TwitterShareButton>
              {/* <InstapaperShareButton url={`https://markid.io/learn/blogdetail/${Blogsid}`}>
              <Col>
                <img src={insta} className="ShareModalimg" />
                <span className="BlogModalText">Share to Instagram</span>
              </Col></InstapaperShareButton> */}
              <CopyToClipboard text={`https://markid.io/learn/blogdetail/${Blogsid}`}>
              <Button onClick={()=>{handleCancel();toast("copied to clipboard!")}}>
              <Col>
                <img src={pin}  className="ShareModalcopy" />
                <span className="BlogModalText">Copy link</span>
              </Col></Button></CopyToClipboard>
            {/* </Row> */}
          {/* </Row> */}
        </div>
      </Modal>)}
    </>
  );
};