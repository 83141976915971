import { Typography, Row, Col, Avatar } from "antd";
import React from "react";
import PageHeader from "../components/PageHeader";
const { Text, Title } = Typography;
const Success = () => {
  return (
    <>
     
          <PageHeader />
      

      <Row justify='center' gutter={[48, 48]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Avatar
                  size={{
                    xs: 100,
                    sm: 100,
                    md: 100,
                    lg: 100,
                    xl: 100,
                    xxl: 100,
                  }}
                />
                </Col>
                </Row>
              
                <Row gutter={[16, 16]}>
                  <Col span={24}></Col>
                <Col  xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={2} strong>
                  Thank You!
                </Title>
                  </Col>
                </Row>

                <Row justify='center'>
                <Col xl={6} lg={6} md={6} sm={16} xs={16}>
                <Text>You have successfully scheduled your pick up Incase you want to cancel or reschedule your pick up then
                  write to us</Text>
                  </Col>
                </Row>
    </>
  );
};
export default Success;
