/*eslint-disable*/
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGaEvent } from "../hooks/useGaEvent";
import {
  Link,
  BrowserRouter as Router,
  Route,
  useHistory,
} from "react-router-dom";
// import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Card,
  Skeleton,
  Avatar,
  Divider,
  Button,
  Switch,
  Rate,
  Spin,
} from "antd";
import {
  CheckOutlined,
  BulbOutlined,
  BellOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { MessageSeller } from "../components/MessageSeller";
import { MakeOffer } from "../components/MakeOffer";
import { EnlargedImages } from "../components/EnlargedImages";
import NotificationIcon from "../assets/Notification.png";
import NotifiedIcon from "../assets/NotifiedIcon.svg";
import QuestionIcon from "../assets/NeedHelpIcon.png";
import TrueIcon from "../assets/trueicon.svg";
import { followUser, unFollowUser, getUser, notifyItem, unNotifyItem } from "../Actions/userAction";
import ShippingLogo from "../assets/Slice.svg";
import SignIn from "../pages/SignIn";
const { Text, Title } = Typography;
const { gaPageView, gaEventClick } = useGaEvent();

const ProductCart = (props) => {
  const dispatch = useDispatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { productData, productLoader } = props;
  const {
    condition,
    category,
    deliveryType,
    yearOfPurchase,
    location,
    size,
    negotiablePrice,
    name,
    brand,
    price,
    sellerAddress,
    inSoldPending,
    createdBy,
    _id,
    seller
  } = productData;
  const { name: sellerName } = sellerAddress || { sellerName: '' }
  const ProductId = _id
  const history = useHistory();
  const { currentUser, following, isFollowUserLoading, isUnFollowUserLoading, isNotifyItemLoading, isUnNotifyItemLoading, currentCart, allLots, isAuthenticated } = useSelector((state) => {
    const { currentUser, isFollowUserLoading, isUnFollowUserLoading, isNotifyItemLoading, isUnNotifyItemLoading } = state.user;
    const { allLots } = state.lots;
    const { currentCart } = state.cart;
    const { isAuthenticated } = state.auth;
    const { following } = currentUser || {}
    return {
      following,
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      currentCart,
      allLots,
      isAuthenticated
    };
  });
  const { notifyItems } = currentUser
  const { lots } = allLots || { lots: [] };
  let CartId = ""
  {
    currentCart &&
      currentCart.map((current) => {
        current._id === ProductId ? CartId = current._id : null
      })
  }
  let NotifyItemId = ""
  {
    notifyItems &&
      notifyItems.map((current) => {
        current === ProductId ? NotifyItemId = current : null
      })
  }

  // const CartID = currentCart && currentCart.length > 0 ? currentCart[0]: null;
  // const CartID = ccID
  // console.log({CartID})
  // function onChange(checked) {
  //     console.log(`switch to ${checked}`);
  //   }
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  const [showMakeOfferDialog, setShowMakeOfferDialog] = useState(false);
  const [showEnlargedImagesDialog, setShowEnlargedImagesDialog] = useState(false);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const ManufactureVal = yearOfPurchase ? yearOfPurchase.substring(0, 4) : null
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  const handleAddItemCart = (item) => {
    gaEventClick("PDP", "Add to Cart", name);
    dispatch({ type: 'ADD_ITEM_CART', payload: { item: item } })
    history.push("/markid-cart")
  }
  const handleNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(notifyItem({ id: ProductId }));
    }
    else {
      setShowSignInDialog(true)
    }
  }
  const handleUnNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(unNotifyItem({ id: ProductId }));
    }
    else {
      gaEventClick("Navigation", "Add to Cart", "signIn");
      setShowSignInDialog(true)
    }
  }
  const handleFollowUser = () => {
    if (isAuthenticated) {
      dispatch(followUser({ id: createdBy }));
    }
    else {
      setShowSignInDialog(true)
    }
  }
  const handleUnFollowUser = () => {
    if (isAuthenticated) {
      dispatch(unFollowUser({ id: createdBy }));
    }
    else {
      setShowSignInDialog(true)
    }
  }
  return (
    <>
      <div className="addtocartweb">
        <Row justify="start">
          <Text style={{ color: "#FF533F" }}>reNEWED</Text>
        </Row>
        <Row justify="space-between" gutter={[0, 8]}>
          <Col span={24}></Col>
          <Col>
            <Row>
              {productLoader ?
                <Skeleton.Button active={productLoader} size="default" /> :
                name && <Text className="renewed-work-heading">{capitalize(name)}</Text>}
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col>
                {productLoader ?
                  <Skeleton.Button active={productLoader} size="small" /> :
                  brand && <Typography className="benefit">{capitalize(brand)}</Typography>}
              </Col>
            </Row>
          </Col>
          {productLoader ?
            <Skeleton.Button active={productLoader} size="default" /> :
            price && (
              <Col>
                <Text className="main-price">${price / 100}</Text>
              </Col>
            )}
        </Row>
        <Divider />
        {condition && (
          <Row justify="space-between">
            <Col>
              <Text className="product-cart-details">Condition:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="small" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(condition)}</Text>
              </Col>
            }
          </Row>
        )}
        {category && (
          <Row gutter={[0, 4]} justify="space-between">
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Category:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="default" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(category)}</Text>
              </Col>
            }
          </Row>
        )}
        {deliveryType && (
          <Row gutter={[0, 4]} justify="space-between">
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Delivery Type:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="small" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(deliveryType)}</Text>
              </Col>
            }
          </Row>
        )}
        {yearOfPurchase && (
          <Row justify="space-between" gutter={[0, 4]}>
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Manufactured:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="small" /> :
              <Col>
                <Text className="product-cart-value">{ManufactureVal}</Text>
              </Col>
            }
          </Row>
        )}
        {location && (
          <Row justify="space-between" gutter={[0, 4]}>
            <Col span={24}></Col>
            <Col>
              <Text className="product-cart-details">Location:</Text>
            </Col>
            {productLoader ?
              <Skeleton.Button active={productLoader} size="default" /> :
              <Col>
                <Text className="product-cart-value">{capitalize(location)}</Text>
              </Col>
            }
          </Row>
        )}
        {/* {size && (
        <Row justify="space-between" gutter={[0, 4]}>
          <Col span={24}></Col>
          <Col>
            <Text className="product-cart-details">Size:</Text>
          </Col>
          {productLoader ?
        <Skeleton.Button active={productLoader} size="small" /> :
          <Col>
            <Text className="product-cart-value">{capitalize(size)}</Text>
          </Col>
}
        </Row>
      )} */}
        <Row justify="space-between" gutter={[0, 4]}>
          <Col span={24}></Col>
          <Col>
            <Text className="product-cart-details">Price:</Text>
          </Col>
          {productLoader ?
            <Skeleton.Button active={productLoader} size="default" /> :
            <Col>
              {negotiablePrice === true ? (
                <Text className="product-cart-value">Negotiable</Text>
              ) : (
                <Text className="product-cart-value">Firm</Text>
              )}
            </Col>
          }
        </Row>
        {inSoldPending ? null
          :
          <Row gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col span={24}>
              {ProductId === CartId ?
                productLoader ? <Skeleton.Button active={productLoader} width={500} />
                  :
                  <Button
                    block
                    className={"squarebutton"}
                    onClick={() => {
                      gaEventClick("PDP", "Go To Cart");
                      history.push("/markid-cart");
                    }}
                  >
                    GO TO CART
                  </Button>
                :
                productLoader ? <Skeleton.Button active={productLoader} />
                  :
                  <Button
                    block
                    className={"squarebutton"}
                    onClick={() => handleAddItemCart(productData)}
                  >
                    ADD TO CART

                  </Button>
              }
            </Col>
          </Row>
        }
      </div>
      {/* <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Button block onClick={() => setShowMakeOfferDialog(true)} className={'make-offer-button'}>MAKE AN OFFER</Button>
              </Col>
            </Row> */}
      <Divider />
      <Row justify="space-between">
        <Col>
        {ProductId === NotifyItemId ?
          <Title strong level={4} className="notify-me">
            UnNotify Me
          </Title>
          :
          <Title strong level={4} className="notify-me">
            Notify Me
          </Title>
}
        </Col>
        <Col>
            {ProductId === NotifyItemId ?
            <Spin indicator={antIcon} spinning={isUnNotifyItemLoading}>
              <img src={NotifiedIcon} onClick={() => handleUnNotifyItem(ProductId)} />
              </Spin>
              :
              <Spin indicator={antIcon} spinning={isNotifyItemLoading}>
              <img src={NotificationIcon} onClick={() => handleNotifyItem(ProductId)} />
              </Spin>
            }

          {/* <Switch defaultChecked onChange={onChange} /> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography className="notify-des">
            We can notify when a similar product is available or the seller
            reduces the price
          </Typography>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col>
          <Title strong level={4} className="notify-me">
            Seller
          </Title>
        </Col>
        <Col>
          {following && following.length > 0 ?
            <Spin indicator={antIcon} spinning={isUnFollowUserLoading}>
              <Button type='text'
                className="see-all-button"
                onClick={() => handleUnFollowUser(createdBy)}
              >
                UnFollow seller
              </Button>
            </Spin>
            :
            <Spin indicator={antIcon} spinning={isFollowUserLoading}>
              <Button type='text'
                className="see-all-button"
                onClick={() => handleFollowUser(createdBy)}
              >
                Follow seller
              </Button>
            </Spin>
          }
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col>
          {/* <img src={ShippingLogo} /> */}
          <Avatar shape="square" style={{ height: 69, width: 74 }} src={ShippingLogo} />
        </Col>
        <Col>
          {sellerName &&
            <Text className="view-more">
              {/* Amanda John */}
              {capitalize(sellerName)}
            </Text>
          }

          <Row style={{ paddingLeft: 15 }}>
            <Rate defaultValue={5} />
          </Row>
          <Row style={{ paddingLeft: 15 }}>
            <Text className="verifiedSeller">
              <img src={TrueIcon} /> Verified Seller
            </Text>
          </Row>
          <Row style={{ paddingLeft: 15 }}>
            <Text className="itemListed">{lots.length} items listed</Text>
          </Row>
        </Col>
      </Row>
      {/* <Row justify="space-between" gutter={[0, 8]}>
              <Col span={24}></Col>
              <Col>
                <Text>
                  <CheckOutlined /> Verified Seller
                </Text>
              </Col>
              <Col>
                <Text>23 items listed</Text>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col>
                <Title strong level={5}>
                  Seller badges
                </Title>
              </Col>
            </Row>
            <Row gutter={[3,8]}>
              <Col span={24}></Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Avatar />
                <Row gutter={[0, 6]}>
                  <Col span={24}></Col>
                  <Col>
                    <Typography>Top 10% seller</Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Avatar />
                <Row gutter={[0, 6]}>
                  <Col span={24}></Col>
                  <Col>
                    <Typography>Member since 2019</Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Avatar />
                <Row gutter={[0, 6]}>
                  <Col span={24}></Col>
                  <Col>
                    <Typography>Fast Responder</Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Avatar />
                <Row gutter={[0, 6]}>
                  <Col span={24}></Col>
                  <Col>
                    <Typography>Fast Shipper</Typography>
                  </Col>
                </Row>
              </Col>
            </Row> */}
      {isAuthenticated && 
      <Row gutter={[0, 16]}>
        <Col span={24}></Col>
        <Col span={24}>
          <Button
            block
            onClick={() => {
              gaEventClick("PDP", "Message Seller");
              setShowMessageSellerDialog(true)
            }}
            className={"message-seller-button"}
          >
            MESSAGE SELLER
          </Button>
        </Col>
      </Row>
}
      <Divider />
      <Row gutter={[0, 16]}>
        <Col span={24}></Col>
        <Col span={24}>
          <Card className="renewed-info-card">
            <Row justify="space-between">
              <Col>
                <Title level={4} className="renewed-info">
                  reNEWED Information
                </Title>
              </Col>
              {/* <Col>
              <BulbOutlined />
              </Col> */}
            </Row>
            <Row justify="start">
              <Col>
                <Typography className="renewed-info-des">
                  The simplest way to sell kid's gently used, high-quality
                  furniture and gear-effortlessly, sustainably and purposefully.
                </Typography>
              </Col>
            </Row>
            {/* <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Button
                  block
                  onClick={() => setShowEnlargedImagesDialog(true)}
                  className={"know-more-button"}
                >
                  KNOW MORE
                </Button>
              </Col>
            </Row> */}
          </Card>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}></Col>
        <Col span={24}>
          <Card className="need-help-card">
            <Row justify="space-between">
              <Col>
                <Title className="renewed-info" level={4}>
                  Need help?
                </Title>
              </Col>
              <Col>
                <img src={QuestionIcon} />
                {/* <QuestionCircleOutlined /> */}
              </Col>
            </Row>
            <Row justify="start">
              <Col>
                <Typography className="renewed-info-des">
                  Request a call, chat and more.
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
                <Button
                  block
                  href="https://markid.io/contact"
                  target="_"
                  className={"contact-us-button"}
                >
                  CONTACT US
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <MessageSeller
        onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)}
        showMessageSellerDialog={showMessageSellerDialog}
      />
      {/* <MakeOffer
        onCancelMakeOfferDialog={() => setShowMakeOfferDialog(false)}
        showMakeOfferDialog={showMakeOfferDialog}
        makeOfferData={productData}
        sellerInfo={sellerInfo}
      /> */}
      <EnlargedImages
        onCancelEnlargedImagesMethod={() => setShowEnlargedImagesDialog(false)}
        showEnlargedImagesDialog={showEnlargedImagesDialog}
      />
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </>
  );
};
export default ProductCart;
