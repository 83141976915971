import constants from '../Utils/constants';
const initialState = {
	isGetUserLoading: false, isUpdateUserLoading: false,
	isFollowUserLoading: false, isUnFollowUserLoading: false, isAddAddressLoading: false, isRemoveAddressLoading: false, isUpdateAddressLoading: false, isNotifyItemLoading: false,
	isUnNotifyItemLoading: false, isContactUsLoading: false, isAddBrandsLoading: false, isAddCategoriesLoading: false, isAddZipcodeLoading: false,
	isUploadUserPhotoLoading: false, isAddKidsLoading: false, isJoinCommunityLoading: false, isMoreFromThisSellerLoading: false, isSimilarItemsLoading: false,
	isGetRatingsByUserIdLoading: false, isGetUserBYIdLoading: false, isGetSellerUserLotsLoading: false, isGetSellerBadgesLotsLoading: false, isGetUserFavoritesLotsLoading: false, 
	isBlockUserLoading: false, isUnBlockUserLoading: false, isReportUserLoading: false,
	successMessage: '', currentUser: {}, userById: {}, sellerUserLots: [], sellerBadges: {}, favirotesLots: [], userAddresses: [], moreLots: [], similarLots: [], userRatings: {}, currentLocationZip: '',
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {

		case constants.GET_USER_REQUEST:
			return {
				...state, isGetUserLoading: true
			}
		case constants.GET_USER_SUCCESS:
			return {
				...state, isGetUserLoading: false, currentUser: action.payload
			}
		case constants.GET_USER_FAILURE:
			return {
				...state, isGetUserLoading: false
			}

		case constants.GET_USER_BY_ID_REQUEST:
			return {
				...state, isGetUserBYIdLoading: true
			}
		case constants.GET_USER_BY_ID_SUCCESS:
			return {
				...state, isGetUserBYIdLoading: false, userById: action.payload
			}
		case constants.GET_USER_BY_ID_FAILURE:
			return {
				...state, isGetUserBYIdLoading: false
			}

		case constants.GET_SELLER_USER_LOTS_REQUEST:
			return {
				...state, isGetSellerUserLotsLoading: true
			}
		case constants.GET_SELLER_USER_LOTS_SUCCESS:
			return {
				...state, isGetSellerUserLotsLoading: false, sellerUserLots: action.payload
			}
		case constants.GET_SELLER_USER_LOTS_FAILURE:
			return {
				...state, isGetSellerUserLotsLoading: false
			}

		case constants.GET_SELLER_BADGES_REQUEST:
			return {
				...state, isGetSellerBadgesLotsLoading: true
			}
		case constants.GET_SELLER_BADGES_SUCCESS:
			return {
				...state, isGetSellerBadgesLotsLoading: false, sellerBadges: action.payload
			}
		case constants.GET_SELLER_BADGES_FAILURE:
			return {
				...state, isGetSellerBadgesLotsLoading: false
			}

			case constants.GET_USER_FAVORITES_LOTS_REQUEST:
			return {
				...state, isGetUserFavoritesLotsLoading: true
			}
		case constants.GET_USER_FAVORITES_LOTS_SUCCESS:
			return {
				...state, isGetUserFavoritesLotsLoading: false, favirotesLots: action.payload
			}
		case constants.GET_USER_FAVORITES_LOTS_FAILURE:
			return {
				...state, isGetUserFavoritesLotsLoading: false
			}

		case constants.UPDATE_USER_REQUEST:
			return {
				...state, isUpdateUserLoading: true
			}
		case constants.UPDATE_USER_SUCCESS:
			return {
				...state, isUpdateUserLoading: false
			}
		case constants.UPDATE_USER_FAILURE:
			return {
				...state, isUpdateUserLoading: false
			}

			case constants.BLOCK_USER_REQUEST:
			return {
				...state, isBlockUserLoading: true
			}
		case constants.BLOCK_USER_SUCCESS:
			return {
				...state, isBlockUserLoading: false
			}
		case constants.BLOCK_USER_FAILURE:
			return {
				...state, isBlockUserLoading: false
			}

			case constants.UNBLOCK_USER_REQUEST:
			return {
				...state, isUnBlockUserLoading: true
			}
		case constants.UNBLOCK_USER_SUCCESS:
			return {
				...state, isUnBlockUserLoading: false
			}
		case constants.UNBLOCK_USER_FAILURE:
			return {
				...state, isUnBlockUserLoading: false
			}

			case constants.REPORT_USER_REQUEST:
			return {
				...state, isReportUserLoading: true
			}
		case constants.REPORT_USER_SUCCESS:
			return {
				...state, isReportUserLoading: false
			}
		case constants.REPORT_USER_FAILURE:
			return {
				...state, isReportUserLoading: false
			}

		case constants.NOTIFY_ITEM_REQUEST:
			return {
				...state, isNotifyItemLoading: true
			}
		case constants.NOTIFY_ITEM_SUCCESS:
			return {
				...state, isNotifyItemLoading: false
			}
		case constants.NOTIFY_ITEM_FAILURE:
			return {
				...state, isNotifyItemLoading: false
			}
		case constants.UNNOTIFY_ITEM_REQUEST:
			return {
				...state, isUnNotifyItemLoading: true
			}
		case constants.UNNOTIFY_ITEM_SUCCESS:
			return {
				...state, isUnNotifyItemLoading: false
			}
		case constants.UNNOTIFY_ITEM_FAILURE:
			return {
				...state, isUnNotifyItemLoading: false
			}

		case constants.CONTACT_US_REQUEST:
			return {
				...state, isContactUsLoading: true
			}
		case constants.CONTACT_US_SUCCESS:
			return {
				...state, isContactUsLoading: false, successMessage: action.payload.message
			}
		case constants.CONTACT_US_FAILURE:
			return {
				...state, isContactUsLoading: false, failureMessage: action.payload.error.message
			}

		case constants.FOLLOW_USER_REQUEST:
			return {
				...state, isFollowUserLoading: true
			}
		case constants.FOLLOW_USER_SUCCESS:
			return {
				...state, isFollowUserLoading: false
			}
		case constants.FOLLOW_USER_FAILURE:
			return {
				...state, isFollowUserLoading: false
			}

		case constants.UNFOLLOW_USER_REQUEST:
			return {
				...state, isUnFollowUserLoading: true
			}
		case constants.UNFOLLOW_USER_SUCCESS:
			return {
				...state, isUnFollowUserLoading: false
			}
		case constants.UNFOLLOW_USER_FAILURE:
			return {
				...state, isUnFollowUserLoading: false
			}

		case constants.ADD_ADDRESS_REQUEST:
			return {
				...state, isAddAddressLoading: true
			}
		case constants.ADD_ADDRESS_SUCCESS:
			return {
				...state, isAddAddressLoading: false, userAddresses: action.payload
			}
		case constants.ADD_ADDRESS_FAILURE:
			return {
				...state, isAddAddressLoading: false,
				addresserror: action.payload,
			}

		case constants.UPDATE_ADDRESS_REQUEST:
			return {
				...state, isUpdateAddressLoading: true
			}
		case constants.UPDATE_ADDRESS_SUCCESS:
			return {
				...state, isUpdateAddressLoading: false
			}
		case constants.UPDATE_ADDRESS_FAILURE:
			return {
				...state, isUpdateAddressLoading: false,
			}

		case constants.REMOVE_ADDRESS_REQUEST:
			return {
				...state, isRemoveAddressLoading: true
			}
		case constants.REMOVE_ADDRESS_SUCCESS:
			return {
				...state, isRemoveAddressLoading: false
			}
		case constants.REMOVE_ADDRESS_FAILURE:
			return {
				...state, isRemoveAddressLoading: false,
			}

		case constants.SET_AS_DEAFULT_ADDRESS_REQUEST:
			return {
				...state, isRemoveAddressLoading: true
			}
		case constants.SET_AS_DEAFULT_ADDRESS_SUCCESS:
			return {
				...state, isRemoveAddressLoading: false
			}
		case constants.SET_AS_DEAFULT_ADDRESS_FAILURE:
			return {
				...state, isRemoveAddressLoading: false,
			}

		case constants.ADD_ZIPCODE_REQUEST:
			return {
				...state, isAddZipcodeLoading: true
			}
		case constants.ADD_ZIPCODE_SUCCESS:
			return {
				...state, isAddZipcodeLoading: false
			}
		case constants.ADD_ZIPCODE_FAILURE:
			return {
				...state, isAddZipcodeLoading: false,
			}

		case constants.ADD_BRANDS_REQUEST:
			return {
				...state, isAddBrandsLoading: true
			}
		case constants.ADD_BRANDS_SUCCESS:
			return {
				...state, isAddBrandsLoading: false
			}
		case constants.ADD_BRANDS_FAILURE:
			return {
				...state, isAddBrandsLoading: false,
			}

		case constants.ADD_CATEGORIES_REQUEST:
			return {
				...state, isAddCategoriesLoading: true
			}
		case constants.ADD_CATEGORIES_SUCCESS:
			return {
				...state, isAddCategoriesLoading: false
			}
		case constants.ADD_CATEGORIES_FAILURE:
			return {
				...state, isAddCategoriesLoading: false,
			}

		case constants.UPLOAD_USER_PHOTO_REQUEST:
			return {
				...state, isUploadUserPhotoLoading: true
			}
		case constants.UPLOAD_USER_PHOTO_SUCCESS:
			var { currentUser } = state
			currentUser.photo = action.payload.url
			return {
				...state, isUploadUserPhotoLoading: false, currentUser
			}
		case constants.UPLOAD_USER_PHOTO_FAILURE:
			return {
				...state, isUploadUserPhotoLoading: false
			}

		case constants.ADD_KIDS_REQUEST:
			return {
				...state, isAddKidsLoading: true
			}
		case constants.ADD_KIDS_SUCCESS:
			return {
				...state, isAddKidsLoading: false
			}
		case constants.ADD_KIDS_FAILURE:
			return {
				...state, isAddKidsLoading: false,
			}

		case constants.GET_RATINGS_BY_USER_ID_REQUEST:
			return {
				...state, isGetRatingsByUserIdLoading: true
			}
		case constants.GET_RATINGS_BY_USER_ID_SUCCESS:
			return {
				...state, isGetRatingsByUserIdLoading: false, userRatings: action.payload
			}
		case constants.GET_RATINGS_BY_USER_ID_FAILURE:
			return {
				...state, isGetRatingsByUserIdLoading: false,
			}

		case constants.JOIN_COMMUNITY_REQUEST:
			return {
				...state, isJoinCommunityLoading: true
			}
		case constants.JOIN_COMMUNITY_SUCCESS:
			return {
				...state, isJoinCommunityLoading: false
			}
		case constants.JOIN_COMMUNITY_FAILURE:
			return {
				...state, isJoinCommunityLoading: false,
			}

		case constants.MORE_FROM_THIS_SELLER_REQUEST:
			return {
				...state, isMoreFromThisSellerLoading: true
			}
		case constants.MORE_FROM_THIS_SELLER_SUCCESS:
			return {
				...state, isMoreFromThisSellerLoading: false, moreLots: action.payload.data.lots
			}
		case constants.MORE_FROM_THIS_SELLER_FAILURE:
			return {
				...state, isMoreFromThisSellerLoading: false,
			}

		case constants.SIMILAR_ITEMS_REQUEST:
			return {
				...state, isSimilarItemsLoading: true
			}
		case constants.SIMILAR_ITEMS_SUCCESS:
			return {
				...state, isSimilarItemsLoading: false, similarLots: action.payload.data.lots
			}
		case constants.SIMILAR_ITEMS_FAILURE:
			return {
				...state, isSimilarItemsLoading: false,
			}
		case constants.SET_CURRENT_LOCATION_ZIP:
			return {
				...state, currentLocationZip: action.payload,
			}
		case "SIGNUP_WITH_FACEBOOK_SUCCESS":
			return {
				...state, currentUser: action.payload.data.user
			}
		case "CLEAR ERROR STATE":
			return {
				...state,
				addresserror: "",
				successMessage: "",
				failureMessage: ''
			};
		case "RESET_APP":
			return { ...initialState };
		default:
			return state
	}
}
export default userReducer;