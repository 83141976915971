import API from '../Utils/API';

export const getPublishedHandPicked = () => dispatch => {
  dispatch({ type: "GET_PUBLISHED_HANDPICKED_REQUEST" })
  API.get('/api/v2/handpicked')
      .then(response => {
          dispatch({ type: "GET_PUBLISHED_HANDPICKED_SUCCESS", payload: response.data })
      })
      .catch((error) => {
          try {
            dispatch({
              type: "GET_PUBLISHED_HANDPICKED_FAILURE",
              payload: error.response.data,
            });
            // setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          } catch {
            // console.log(error);
          }
        });
}
// export const getHandPickedItem = (data, url) => (dispatch) => {
//     dispatch({ type: "GET_HANDPICKED_ITEM_REQUEST" });
//     API.get(url)
//       .then((response) => {
//         dispatch({ type: "GET_HANDPICKED_ITEM_SUCCESS", payload: response.data });
//       })
//       .catch((error) => {
//         try {
//           dispatch({
//             type: "GET_HANDPICKED_ITEM_FAILURE",
//             payload: error.response.data,
//           });
//           setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
//         } catch {
//           // console.log(error);
//         }
//       });
//   };

export const getHandPickedItem = (handpickedId) => dispatch => {
    dispatch({ type: "GET_HANDPICKED_ITEM_REQUEST" })
    API.get(`/api/v2/handpicked/${handpickedId}`)
        .then(response => {
            dispatch({ type: "GET_HANDPICKED_ITEM_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            try {
              dispatch({
                type: "GET_HANDPICKED_ITEM_FAILURE",
                payload: error.response.data,
              });
              // setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
            } catch {
              // console.log(error);
            }
          });
  }