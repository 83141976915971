import API from "../Utils/API";
import constants from "../Utils/constants";
import setAuthToken from "./../Utils/setAuthToken";
import { getUser } from "./userAction";
export const getOtp =
  (data, successCallBackGetOtp, failureCallBackGetOtp) => (dispatch) => {
    dispatch({ type: "CREATE_SEND_OTP_REQUEST" });
    API.get(`/api/v2/auth/request-sms-code?phone=1${data.phone}`)
      .then((response) => {
        dispatch({ type: "CREATE_SEND_OTP_SUCCESS", payload: response.data });
        successCallBackGetOtp();
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_SEND_OTP_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBackGetOtp();
        } catch {
          // console.log(error);
        }
      });
  };
export const createVerifyOtp =
  (data, successCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: "CREATE_VERIFY_OTP_REQUEST" });
    API.post("/api/v2/auth/authenticate-with-phone", data)
      .then((response) => {
        const token = response.data.token;
        // const data = response.data;
        localStorage.setItem("access_token", token);
        setAuthToken(token);
        dispatch({ type: "CREATE_VERIFY_OTP_SUCCESS", payload: response });
        dispatch(getUser())
        successCallBack(response.data.new);
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_VERIFY_OTP_FAILURE",
            payload: error.response.data,
          });
          setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
          failureCallBack();
        } catch {
          // console.log(error);
        }
      });
  };

export const signupWithFacebook = (data) => (dispatch) => {
  dispatch({ type: "SIGNUP_WITH_FACEBOOK_REQUEST", payload: data });
  API.post("/api/v2/auth/facebook", data)
    .then((response) => {
      dispatch({ type: "SIGNUP_WITH_FACEBOOK_SUCCESS", payload: response });
    })
    .catch((error) => {
      dispatch({
        type: "SIGNUP_WITH_FACEBOOK_FAILURE",
        payload: error.response.data,
      });
    });
};

export const signupWithApple = (data) => (dispatch) => {
  dispatch({ type: "SIGNUP_WITH_APPLE_REQUEST", payload: data });
  API.post("/api/v2/auth/apple", data)
    .then((response) => {
      dispatch({ type: "SIGNUP_WITH_APPLE_SUCCESS", payload: response });
    })
    .catch((error) => {
      dispatch({
        type: "SIGNUP_WITH_APPLE_FAILURE",
        payload: error.response.data,
      });
    });
};

export const signupWithAmazon = (data) => (dispatch) => {
  dispatch({ type: "SIGNUP_WITH_AMAZON_REQUEST", payload: data });
  API.get("/api/v2/auth/amazon", data)
    .then((response) => {
      dispatch({ type: "SIGNUP_WITH_AMAZON_SUCCESS", payload: response });
    })
    .catch((error) => {
      dispatch({
        type: "SIGNUP_WITH_AMAZON_FAILURE",
        payload: error.response.data,
      });
    });
};


export const setLoginSignup = (val) => dispatch => {
  dispatch({ type: "SET_LOGIN_SIGNUP", payload: val });
}


export const getEmailUpdates = (data) => (dispatch) => {
  dispatch({ type: constants.POST_EMAIL_UPDATES_REQUEST, payload: data });
  API.post("/api/v2/users/letter-email", data)
    .then((response) => {
      dispatch({ type: constants.POST_EMAIL_UPDATES_SUCCESS, payload: response });
    })
    .catch((error) => {
      dispatch({
        type:constants.POST_EMAIL_UPDATES_FAILURE,
        payload: error.response.data,
      });
    });
};
