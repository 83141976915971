import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Row, Col, Typography, Input, Modal } from "antd";
import logo from "../assets/logo.svg";
import {
  AmazonCircleFilled,
  AppleFilled,
  FacebookFilled,
} from "@ant-design/icons";
import "../App.css";
import {Helmet} from "react-helmet";

const { Title, Text } = Typography;
const SignUp = (props) => {
  const { showSignUpDialog, onCancelSignUpMethod } = props;
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is a required field"),
    password: yup.string().required("Password is a required field"),
  });
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {phone: '', password: ''},
    mode: "onSubmit",
  });
  return (
    <>
      <Helmet>
        <title>Markid - Sign Up</title>
      </Helmet>
     <Modal
        visible={showSignUpDialog}
        onCancel={() => onCancelSignUpMethod()}
        centered
        footer={null}
        width={400}
      >
            <Row gutter={[0,0]} justify="center">
              <Col span={24}></Col>
              <Col>
                <img
                style={{height: "30px", width: "100px"}}
                  alt="logo"
                  src={logo}
                />
              </Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
            </Row>
            <Row justify="center" gutter={[0,32]}>
              <Col span={24}></Col>
              <Col>
                <Title level={2}>Create Account</Title>
                <Text style={{ color: "#bdbdbd" }}>
                  Already have an account?
                </Text>
                <Button
                  type="link"
                  style={{ color: "#7f7f7f", paddingLeft: "5px" }}
                >
                  Sign in
                </Button>
              </Col>
            </Row>

            <form onSubmit={handleSubmit()}>
              <Row justify="center" gutter={[16,32]}>
                <Col span={24}></Col>
                <Col>
                  <AmazonCircleFilled
                    style={{
                      // border: "1px solid",
                      // borderRadius: "42px",
                      fontSize: 40,
                    }}
                  />
                </Col>
                <Col>
                  <AppleFilled
                    style={{
                      // border: "1px solid",
                      // borderRadius: "100px",
                      fontSize: 40,
                    }}
                  />
                </Col>
                <Col>
                  <FacebookFilled
                    style={{
                      // border: "1px solid",
                      // borderRadius: "42px",
                      fontSize: 40,
                    }}
                  />
                </Col>
              </Row>

              <Row justify="center" gutter={[0,8]}>
                <Col span={24}></Col>
                <Col>
                  <Text style={{ color: "#bbbbbb" }}>
                    Or use mobile number
                  </Text>
                </Col>
              </Row>

              <Row justify="center" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    as={Input}
                    style={{ backgroundColor: "#f6f6f6" }}
                    name="phone"
                    control={control}
                    placeholder="Phone number"
                  />
                  {errors.email && (
                    <span style={{ color: "red" }}>
                     Phone number is required
                    </span>
                  )}
                </Col>
              </Row>

              <Row justify="center" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    as={Input}
                    type="password"
                    name="password"
                    control={control}
                    placeholder="Password"
                  />
                  {errors.password && (
                    <span style={{ color: "red" }}>Password is required</span>
                  )}
                </Col>
              </Row>

              <Row justify="center" gutter={[0,48]}>
                <Col span={24}></Col>
                <Col>
                  <Button
                    htmlType="submit"
                    className={'roundbutton'}
                  >
                    SIGN UP
                  </Button>
                </Col>
              </Row>
            </form>
         
        {/* <Col xl={12} lg={18} md={18} sm={22} xs={22}>
            <Card style={{ backgroundColor: "#d8d8d8", height: 331 }} />
          </Col> */}
      </Modal>
    </>
  );
};
export default SignUp;
