import React, { useRef, useEffect } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./Utils/store";
import setAuthToken from "./Utils/setAuthToken";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { PersistGate } from "redux-persist/integration/react";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import { history } from "./Utils/history";
// import HomePage from "../src/pages/HomePage";
import Verification from "../src/pages/verification";
import ItemDetails from "../src/pages/itemDetails";
import CollectItem from "../src/pages/CollectItem";
import LinkVenmoAccount from "../src/pages/LinkVenmoAccount";
import Success from "../src/pages/Success";
import Landing from "../src/pages/Landing";
import ConsignmentDetail from "../src/pages/ConsignmentDetail";
// import Feed from "../src/pages/Feed";
import AllBrandsPage from "./pages/AllBrandsPage";
import SignUp from "../src/pages/SignUp";
import CustomiseExperience from "../src/pages/CustomiseExperience";
import Children from "../src/pages/Children";
import Community from "../src/pages/Community";
import CategoryPage from "../src/pages/CategoryPageSearch";
import ContactUs from "./pages/ContactUs";
import ProductDetails from "../src/pages/ProductDetails";
import MarkidCart from "../src/pages/MarkidCart";
// import MarkidShipping from "../src/pages/MarkidShipping";
// import Payment from "../src/pages/Payment";
// import MarkidOrderConfirmation from "../src/pages/MarkidOrderConfirmation";
import ShippingTopbar from "./components/ShippingTop";
import PartnerListingBanner from "./components/PartnerListingBanner";
//import  AliceCarouselComponent  from "./components/AliceCarouselComponent";
import SearchPage from "./pages/SearchPage";
import About from "./pages/about-us";
import BuyerHub from "./pages/buyer-hub";
import TermsConditions from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import ProductListDetail from "./pages/ProductListDetail";
import partnerpdp from "./pages/partnerpdp";
import ProductList from "./pages/ProductList";
import MainHomePage from "./pages/MainHomePage";
import MainSellerPage from "./pages/MainSellerPage";
import MarkidOnboarding from "./pages/MarkidOnboarding";
import MarkidOnboardinghome from "./pages/Markidonboardhome";
import CheckoutExperience from "./pages/CheckoutExperience";
import Message from "./pages/Message";
import TopBottomCompo from "./components/TopBottomCompo";
// import ProfileStatement from "./pages/ProfileStatement";
import ProfileDetails from "./pages/ProfileDetails";
import OfferMade from "./pages/OfferMade";
// import { useDispatch } from "react-redux";
import { getUser } from "./Actions/userAction";
import HandPicked from "./pages/HandPicked";
import GeneralQuestions from "./pages/general-questions";
import SellerHub from "./pages/seller-hub";
import ReNewedPage from "../src/pages/ReNewedPage";
import SellerProfile from "../src/pages/SellerProfile";
import MarkidCommunity from "./pages/MarkidCommunity/MarkidCommunity";
import MarkidBlog from "./pages/MarkidBlog/MarkidBlog";
import MarkidSell from "./pages/MarkidSell/MarkidSell";
import SimillarProduct from "./pages/SimillarProduct";
import MarkidMessages from "./pages/MarkidMessages/MarkidMessages";
import "./App.css";
import "antd/dist/antd.css";
import CommunityGuide from "./pages/community-guidelines";
import { Helmet } from "react-helmet";
import PartnerProductList from "../src/pages/PartnerProductList";
import PreviewPublishPage from "./pages/PreviewPublishPage";
import SellerProductDetails from "./pages/SellerProductDetails";
import SelectColourSeller from "./components/SelectColourSeller";
import { SellerConfirmation } from "./components/SellerConfirmation";
import { SelectBrand } from "./components/SelectBrand";
import { ModalsPage } from "./pages/ModalsPage";
import ScrollToTop from "./components/ScrollToTop";
import CheckoutTopBar from "./components/CheckoutTopBar";
import CheckoutProcess from "./pages/CheckoutProcess";
import CheckoutPayment from "./pages/CheckoutPayment";
import CheckoutConfirmation from "./pages/CheckoutConfirmation";
import CheckoutOrderConfirmation from "./components/CheckoutOrderConfirmation";
import MainBlogPage from "./pages/MainBlogPage";
import BlogDetailPage from "./pages/BlogDetailPage";
import Bookmark from "./components/Bookmark";
import ReactGA from "react-ga";

function App() {
  const categoryRef = useRef(null);
  let userId;

  // const {
  //   isAuthenticated,
  //   currentCartIds,
  //   currentUser
  // } = useSelector((state) => {
  //   const { isAuthenticated } = state.auth;
  //   const { currentUser } = state.user;
  //   const { currentCartIds} = state.cart;
  //   return {
  //     isAuthenticated,
  //     currentCartIds,
  //     currentUser
  //   };
  // });

  if (localStorage.access_token) {
    setAuthToken(localStorage.access_token);
    const decoded = jwt_decode(localStorage.access_token);
    userId = decoded?.userId;
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      window.location.href = "/login";
    } else {
      store.dispatch(getUser());
    }
  }

  useEffect(() => {
    // ReactGA.initialize(process.env.REACT_APP_GA_ID, {
    ReactGA.initialize(process.env.REACT_APP_GA_ID, {
      gaOptions: {
        userId: userId ? userId : null,
      },
    });
  }, []);
  // useEffect(() => {
  // const linkElement = document.createElement("link");
  //       linkElement.setAttribute("rel", "stylesheet");
  //       linkElement.setAttribute("type", "text/css");
  //       linkElement.setAttribute("integrity", "sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk");
  //       linkElement.setAttribute("crossorigin", "anonymous");
  //       linkElement.setAttribute(
  //         "href",
  //         "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  //       );
  //       document.head.appendChild(linkElement);

  // }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            {/* <Route
            exact
            path="/"
            component={HomePage}
          /> */}
            <Route
              exact
              path="/landing"
              component={Landing}
              // layout={LoginButton}
            />
            <Route
              exact
              path="/start"
              component={Verification}
              // layout={LoginButton}
            />
            <Route
              exact
              path="/item-details"
              component={ItemDetails}
              // layout={LoginButton}
            />

            <Route
              exact
              path="/collect-item"
              component={CollectItem}
              // layout={LoginButton}
            />

            <Route
              exact
              path="/success"
              component={Success}
              // layout={LoginButton}
            />
            <Route
              exact
              path="/renewed-page"
              // path="/search-new/?refresh=true&renewed=true"
              component={ReNewedPage}
              // layout={LoginButton}
            />
            <Route
              exact
              path="/link-venmo-account"
              component={LinkVenmoAccount}
              // layout={LoginButton}
            />
            {/* <PrivateRoute exact path="/app" component={AppView} /> */}

            <Route
              exact
              path="/consignment-detail"
              component={ConsignmentDetail}
              // layout={LoginButton}
            />
            <Route exact path="/sign-up" component={SignUp} />
            <Route
              exact
              path="/customise-experience"
              component={CustomiseExperience}
            />
            <TopBottomCompo
              exact
              path={`/profile/:id`}
              component={ProfileDetails}
              showFooter={false}
              // layout={LoginButton}
            />
            <Route exact path="/children" component={Children} />
            <Route exact path="/join-community" component={Community} />
            {/* 20211216 Varun: Commented <Route exact path="/" component={CategoryPage} /> to redirect '/' route to '/homepage' */}
            {/* <Route exact path="/renewed" component={CategoryPage} /> */}
            {/* <Route exact path="/"><Redirect to="/homepage" /></Route> */}
            <Route
              exact
              path="/products/:productName/:productId"
              component={ProductDetails}
            />
            <Route exact path="/markid-cart" component={MarkidCart} />
            <Route exact path="/shipping" component={ShippingTopbar} />

            {/* <Route exact path="/carousel" component={AliceCarouselComponent} /> */}
            <Route exact path="/search/:q" component={SearchPage} />
            <Route exact path="/made-offer" component={OfferMade} />
            {/* <Route exact path="/about" component={About} /> */}
            {/* <Route exact path="/termsconditions" component={TermsConditions} /> */}
            {/* <TopBottomCompo exact path="/search-new/:search" render={(props) => <ProductList {...props} key={(Date.now())}/>} /> */}
            {/* <TopBottomCompo exact path="/search-new/" component={(props) => <ProductList {...props} key={(Date.now())}/>} showFooter={true}/> */}
            <TopBottomCompo
              exact
              path="/search-new/:search?"
              component={(props) => <ProductList {...props} key={Date.now()} />}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/search-brand/:brandfilter?"
              component={(props) => <ProductList {...props} key={Date.now()} />}
              showFooter={true}
            />

            <Route
              exact
              path="/amazon"
              component={() => {
                window.location.href = `${process.env.REACT_APP_API_URL}api/v1/auth/amazon`;
                return null;
              }}
            />
            <TopBottomCompo
              exact
              path="/product-list/:productId"
              categoryRef={categoryRef}
              component={ProductListDetail}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/partner-product/:productName/:productId"
              categoryRef={categoryRef}
              component={partnerpdp}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/product-listing/:itemType/:itemName?/:id/:filter?"
              categoryRef={categoryRef}
              component={(t) => (
                <ProductList categoryRef={categoryRef} {...t} />
              )}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/partnerproduct-list/:itemName/:search?/(filter)?/:filter?"
              categoryRef={categoryRef}
              component={(props) => (
                <PartnerProductList {...props} key={Date.now()} />
              )}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/products/:categorytype?"
              categoryRef={categoryRef}
              component={(props) => <ProductList {...props} key={Date.now()} />}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/verifiedshops"
              categoryRef={categoryRef}
              component={(props) => <ProductList {...props} key={Date.now()} />}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/handpicked/:itemType/:itemName?/:id?"
              categoryRef={categoryRef}
              component={HandPicked}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/seller-profile/:id?"
              categoryRef={categoryRef}
              component={SellerProfile}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/simillar-product/:id?"
              categoryRef={categoryRef}
              component={SimillarProduct}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/cart/:id?"
              component={CheckoutExperience}
              showFooter={false}
            />
            <TopBottomCompo
              exact
              path="/"
              categoryRef={categoryRef}
              component={(t) => (
                <MainHomePage categoryRef={categoryRef} {...t} />
              )}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/seller/addProduct"
              categoryRef={categoryRef}
              component={(t) => (
                <MainSellerPage categoryRef={categoryRef} {...t} />
              )}
              showFooter={true}
              s
            />
            <TopBottomCompo
              exact
              path="/learn/blog/:id?"
              categoryRef={categoryRef}
              component={(t) => (
                <MainBlogPage categoryRef={categoryRef} {...t} />
              )}
              showFooter={true}
              s
            />
            <TopBottomCompo
              exact
              path="/learn/blogdetail/:id?"
              categoryRef={categoryRef}
              component={(t) => (
                <BlogDetailPage categoryRef={categoryRef} {...t} />
              )}
              showFooter={true}
              s
            />
            <TopBottomCompo
              exact
              path="/learn/favourites"
              categoryRef={categoryRef}
              component={(t) => <Bookmark categoryRef={categoryRef} {...t} />}
              showFooter={true}
              s
            />
            <TopBottomCompo
              exact
              path="/seller/preview"
              categoryRef={categoryRef}
              component={(t) => (
                <PreviewPublishPage categoryRef={categoryRef} {...t} />
              )}
              showFooter={true}
              s
            />
            <TopBottomCompo
              exact
              path="/chat"
              categoryRef={categoryRef}
              component={Message}
              showFooter={false}
            />
            <TopBottomCompo
              exact
              path="/contact-us"
              categoryRef={categoryRef}
              component={ContactUs}
              showFooter={false}
            />
            <TopBottomCompo
              exact
              path="/terms-and-conditions"
              categoryRef={categoryRef}
              component={TermsConditions}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/privacy-policy"
              categoryRef={categoryRef}
              component={PrivacyPolicy}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/general-questions"
              categoryRef={categoryRef}
              component={GeneralQuestions}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/buyer-hub"
              categoryRef={categoryRef}
              component={BuyerHub}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/seller-hub"
              categoryRef={categoryRef}
              component={SellerHub}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/community-guide"
              categoryRef={categoryRef}
              component={CommunityGuide}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/about-us"
              categoryRef={categoryRef}
              component={About}
              showFooter={true}
            />
            <TopBottomCompo
              exact
              path="/community"
              categoryRef={categoryRef}
              component={MarkidCommunity}
              showFooter={false}
            />
            <TopBottomCompo
              exact
              path="/blog"
              categoryRef={categoryRef}
              component={MarkidBlog}
              showFooter={false}
            />
            <TopBottomCompo
              exact
              path="/sell"
              categoryRef={categoryRef}
              component={MarkidSell}
              showFooter={false}
            />
            <TopBottomCompo
              exact
              path="/messages"
              categoryRef={categoryRef}
              component={MarkidMessages}
              showFooter={false}
              type={0}
            />
            <TopBottomCompo
              exact
              path="/messages/:productId"
              categoryRef={categoryRef}
              component={MarkidMessages}
              showFooter={false}
              type={1}
            />
            <TopBottomCompo
              exact
              path="/Allbrands"
              categoryRef={categoryRef}
              component={(t) => (
                <AllBrandsPage categoryRef={categoryRef} {...t} />
              )}
              showFooter={true}
              s
            />
            <Route
              exact
              path="/onboarding/(isCheckout)?/:isCheckout?"
              component={MarkidOnboarding}
            />
            <TopBottomCompo
              exact
              path="/seller/product-details/:title?"
              component={SellerProductDetails}
              showFooter={false}
            />
            <TopBottomCompo
              exact
              path="/sellermodals"
              categoryRef={categoryRef}
              component={(t) => <ModalsPage categoryRef={categoryRef} {...t} />}
              showFooter={true}
              s
            />
            <Route
              exact
              path="/your-info"
              categoryRef={categoryRef}
              component={CheckoutProcess}
            />
            <Route
              exact
              path="/payment-info"
              categoryRef={categoryRef}
              component={CheckoutPayment}
            />
            <Route
              exact
              path="/onboardinghome/(isCheckout)?/:isCheckout?"
              component={MarkidOnboardinghome}
            />
            <Route
              exact
              path="/success/checkout/:first?"
              categoryRef={categoryRef}
              component={CheckoutConfirmation}
            />
            {/* <Route exact path="/checkout-process" categoryRef={categoryRef}  component={CheckoutTopBar} /> */}
            <Redirect from="*" to="/" />

            {/* <Route
            exact
            path="/payment"
            component={Payment}
          />
          <Route
            exact
            path="/markid-order-confirmation"
            component={MarkidOrderConfirmation}
          /> */}
            {/* <PrivateRoute exact path="/app" component={AppView} /> */}
            {/* <Route
            exact
            path="/feed"
            component={Feed}
          /> */}
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
