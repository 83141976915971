/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Checkbox,
} from "antd";
import "../App.css";
import { useHistory } from "react-router-dom";
const { Text, Title } = Typography;
const PriceDetails = (props) => {
  const dispatch = useDispatch()
  const { heading, itemTotal, itemTotalVal, shipping, shippingVal, salesTax, salesTaxVal, cartTotal, cartTotalVal, button } = props
  const { currentUserWallet, useCredits, currentCart, partnerProductShippingPrice, availPromotionsCheck, isAuthenticated } = useSelector((state) => {
    const { currentUserWallet, useCredits, currentCart, partnerProductShippingPrice, availPromotionsCheck } = state.cart;
    const { isAuthenticated } = state.auth;
    return {
      currentUserWallet,
      useCredits,
      currentCart,
      partnerProductShippingPrice,
      availPromotionsCheck,
      isAuthenticated
    };
  });
  const [shippingChargesFromChat, setShippingChargesFromChat] = useState(0)
  const [partnerShippingCost, setPartnerShippingCost] = useState(0)
  const [partnerShippingCostCheck, setPartnerShippingCostCheck] = useState(false)
  const [freeShipping, setFreeShipping] = useState(false)
  const [finalShipPrice, setFinalShipPrice] = useState(0)
  useEffect(() => {
    if (shippingVal <= 0 && currentCart.length && currentCart[0].isFree != true) {
      setShippingChargesFromChat(currentCart[0].shippingPrice)
    }
  }, [shippingVal])
  useEffect(() => {
    if (currentCart && currentCart.length) {
      if (currentCart[0].isFree) {
        setFreeShipping(true)
      }
    }
  }, [currentCart])
  useEffect(() => {
    if(partnerProductShippingPrice.shipmentCost != "0"){
      // setPartnerShippingCost(parseFloat(partnerProductShippingPrice.shipmentCost / 100).toFixed(2))
      setPartnerShippingCost(parseFloat(partnerProductShippingPrice.shipmentCost / 100))
    }
  },[partnerProductShippingPrice])
  useEffect(()=>{
    setFinalShipPrice(parseFloat((partnerProductShippingPrice.shipmentCost / 100) || 0) + parseFloat(shippingVal))
  },[shippingVal, freeShipping, partnerShippingCost, shippingChargesFromChat, partnerProductShippingPrice])
  const { balance } = currentUserWallet
  const handleUseCredits = () => {
    dispatch({ type: "USE_MARKID_CREDIT", payload: { useCredits: !useCredits } })
  }
  // const numberItemTotalValue = parseFloat(parseFloat(itemTotalVal)+parseFloat(shippingVal))
  // Shashank's code
  // const numberItemTotalValue = (shippingVal > 0 && freeShipping!=true) ? parseFloat(parseFloat(itemTotalVal)+parseFloat(shippingVal)) : (shippingChargesFromChat > 0 && shippingVal<=0 && freeShipping!=true) ? parseFloat(parseFloat(itemTotalVal)+parseFloat(shippingChargesFromChat/100)) : parseFloat(parseFloat(itemTotalVal))
  // useEffect(() => {
  //   const value = useCredits ? (balance > numberItemTotalValue ? 0.0 : itemTotalVal - balance): itemTotalVal
  //   if(value > 0.0) {
  //     numberItemTotalValue.toFixed(2)
  //     const valueToCent = numberItemTotalValue * 100;
  //     dispatch(createStripePayment({amount: parseInt(valueToCent)}))
  //   }
  // }, [numberItemTotalValue]);
  // end of shashank's code
  // const numberItemTotalValue = (shippingVal > 0 && freeShipping!=true) ? parseFloat(parseFloat(itemTotalVal)+parseFloat(shippingVal)) : (shippingChargesFromChat > 0 && shippingVal<=0 && freeShipping!=true) ? parseFloat(parseFloat(itemTotalVal)+parseFloat(shippingChargesFromChat/100)) : parseFloat(parseFloat(itemTotalVal))

  function groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			 const key = keyGetter(item);
			 const collection = map.get(key);
			 if (!collection) {
				 map.set(key, [item]);
			 } else {
				 collection.push(item);
			 }
		});
		return map;
	}
	const grouped = groupBy(currentCart, currentCart => currentCart.productType)
	let groupPartnerProducts = grouped.get("Partner")
  let groupP2pProducts = grouped.get("P2P")

  const [ partnerDiscount, setPartnerDiscount ] = useState(0)
  const [ p2pDiscount, setP2pDiscount ] = useState(0)

  useEffect(()=>{
    if(groupPartnerProducts && (availPromotionsCheck=="both" || availPromotionsCheck=="partner")){
      setPartnerDiscount(groupPartnerProducts.reduce((a, b) => {return a + b.price * 0.2}, 0))
    }
    else{
      setPartnerDiscount(0);
    }
  },[groupPartnerProducts])
  useEffect(()=>{
    if(groupP2pProducts && (availPromotionsCheck=="both" || availPromotionsCheck=="p2p")){
      setP2pDiscount(groupP2pProducts.reduce((a, b) => {return a + b.price * 0.1}, 0))
    }
    else{
      setP2pDiscount(0);
    }
  },[groupP2pProducts])
 

  let valueAllItemTotalWithShipping = parseFloat((parseFloat(itemTotalVal) + parseFloat(shippingVal) + parseFloat((partnerProductShippingPrice.shipmentCost)/100-parseFloat(p2pDiscount/100) - parseFloat(partnerDiscount/100) || 0)).toFixed(2))

  useEffect(() =>{
    if(partnerDiscount && partnerDiscount > 0){
      dispatch({ type: 'PARTNER_DISCOUNT_AMOUNT', payload: partnerDiscount });
    }
  },[partnerDiscount])
  useEffect(() =>{
    if(p2pDiscount && p2pDiscount > 0){
      dispatch({ type: 'P2P_DISCOUNT_AMOUNT', payload: p2pDiscount });
    }
  },[p2pDiscount])
  useEffect(() =>{
    if(finalShipPrice && finalShipPrice > 0){
      dispatch({ type: 'FINAL_SHIPPINGPRICE', payload: finalShipPrice });
    }
  },[shippingVal, freeShipping, partnerShippingCost, shippingChargesFromChat, partnerProductShippingPrice])

  
const history=useHistory()
  return (
    <>
      <Row gutter={[0, 25]}>
        <Col span={24}></Col>
        <Col>
          <Title level={4} className='pDTitle'>
            {heading}
          </Title>
        </Col>
      </Row>
      <Divider className="priceDetailsDivider" />
      <Row gutter={[0, 16]}>
        <Col span={24}></Col>
        {/* <Col>
              <Input suffix={<Button type='text'>CHECK</Button>} placeholder='Promo Code' />
              </Col> */}
      </Row>
      {balance > 0 &&
        <>
          {/* <Row gutter={[0, 8]}>
            <Col span={24}></Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Text className='pDKey'>Markid Credits</Text>
                </Col>
                <Col>
                  <Text className='pDVal'>${balance.toFixed(2)}</Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Checkbox checked={useCredits} onChange={() => handleUseCredits()}>Use my Markid credits</Checkbox>
            </Col>
          </Row>
          <Divider /> */}
        </>
      }
      <Row justify="space-between" gutter={[0, 0]}>
        <Col span={24}></Col>
        <Col>
          <Text className='pDKey'>{itemTotal}</Text>
        </Col>
        <Col>
          <Text className='pDVal'>${itemTotalVal}</Text>
        </Col>
      </Row>
      {isAuthenticated && <Row justify="space-between" gutter={[0, 15]}>
        <Col span={24}></Col>
        <Col>
          <Text className='pDKey'>{shipping}</Text>
        </Col>
        <Col>
          <Text className='pDVal'> {finalShipPrice > 0 ? "$" : ""}{finalShipPrice > 0 ? 
            // (shippingVal && shippingVal != 0 && freeShipping != true) ? ((partnerShippingCost != 0) ? parseFloat(parseFloat(shippingVal) + parseFloat(partnerShippingCost)).toFixed(2)  : shippingVal) : (shippingChargesFromChat != 0) ? shippingChargesFromChat / 100 : (partnerShippingCost != 0) ? <>{(freeShipping != true ? (parseFloat(shippingVal) + parseFloat(partnerShippingCost)).toFixed(2) : shippingVal)} </> : '0'
            finalShipPrice.toFixed(2)
             : "Free Shipping" }</Text>
        </Col>
      </Row>}
      {groupP2pProducts && (availPromotionsCheck=="both"||availPromotionsCheck=="p2p") ? 
        <Row justify="space-between" gutter={[0, 15]}>
          <Col span={24}></Col>
          <Col>
            <Text className='pDKey'>Markid Discount</Text>
          </Col>
          <Col>
            <Text className='pDVal'>- ${(p2pDiscount/100).toFixed(2)}</Text>
          </Col>
        </Row>
        : 
        <></>
      }
      {groupPartnerProducts && (availPromotionsCheck=="both"||availPromotionsCheck=="partner") ? 
        <Row justify="space-between" gutter={[0, 15]}>
          <Col span={24}></Col>
          <Col>
            <Text className='pDKey'>Swoondle Discount</Text>
          </Col>
          <Col>
            <Text className='pDVal'>- ${(partnerDiscount/100).toFixed(2)}</Text>
          </Col>
        </Row>
        : 
        <></>
      }
      <Row justify="space-between" gutter={[0, 15]}>
        <Col span={24}></Col>
        <Col>
          <Text className='pDKey'>{salesTax}</Text>
        </Col>
        <Col>
          <Text className='pDVal'>${salesTaxVal}</Text>
        </Col>
      </Row>
      {useCredits &&
        <Row justify="space-between" gutter={[0, 15]}>
          <Col span={24}></Col>
          <Col>
            <Text className='pDKey'>Markid Credits</Text>
          </Col>
          <Col>
            {/* <Text className='pDVal'>- ${ balance > numberItemTotalValue ? numberItemTotalValue.toFixed(2) : balance.toFixed(2)}</Text> */}
            <Text className='pDVal'> -${ (parseFloat(itemTotalVal) + parseFloat(finalShipPrice))>balance? balance.toFixed(2):(parseFloat(itemTotalVal) + parseFloat(finalShipPrice)).toFixed(2) }</Text>
          </Col>
        </Row>
      }
      <Divider className='pDDivider' />
      <Row justify="space-between">
        <Col>
          <Text className='cartTotalKeyVal'>{cartTotal}</Text>
        </Col>
        <Col>
          {/* <Text className='cartTotalKeyVal'>${useCredits ? ( balance > numberItemTotalValue ? 0.0 : cartTotalVal - balance) : cartTotalVal}</Text> */}
          <Text className='cartTotalKeyVal'>${useCredits ? ( balance> valueAllItemTotalWithShipping ? 0.0 : valueAllItemTotalWithShipping - balance).toFixed(2): valueAllItemTotalWithShipping.toFixed(2)}</Text>
        </Col>
      </Row>

      <Row gutter={[0, 40]}>
        <Col span={24}></Col>
        <Col span={24}>
          {button}
        </Col>
      </Row>

      <Row gutter={[0, 20]}>
        <Col span={24}></Col>
        <Col>
          <Typography className='agreeText'>
            By clicking “Checkout”, you agree to the Markid <a  onClick={() => history.push(`/privacy-policy`)} target="_blank">Privacy & Policy</a> and <a onClick={() => history.push(`/terms-and-conditions`)}  target="_blank">Terms of Service</a>
          </Typography>
        </Col>
      </Row>
    </>
  );
};
export default PriceDetails;
