/*eslint-disable*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    Row,
    Col,
    Typography,
    Button,
  } from "antd";
import { CheckCircleOutlined} from "@ant-design/icons";
import {MessageSeller} from "../components/MessageSeller";
import Avatar from "antd/lib/avatar/avatar";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import TrueIcon from "../assets/trueicon.svg";
import "../App.css";
import moment from 'moment';
import ShippingLogo from "../assets/Slice.svg";
const { Text, Title } = Typography;
const OrderDetails = () => {
  const history = useHistory();
    const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false)
    const { orderDetails } = useSelector((state) => {
      const { orderDetails } = state.order;
      return {
        orderDetails,
      };
    });
    const { createdAt, lots, actualAddress, paymentMethodType } = orderDetails
    const orderCreatedDate = moment(createdAt).format("h:mm: a, Do MMMM YYYY");
    const { sellerAddress } = lots
    const { name: sellerName } = sellerAddress || {}
    const {address, city, name, secondAddress, state,  zip} = actualAddress.length > 0 ? actualAddress[0] : {}
  return (
    <>
       <Row gutter={[0,48]}>
           <Col span={24}></Col>
              <Col>
                <Title level={4} className='notify-me'>
                  Order details
                </Title>
              </Col>    
            </Row>

            <Row gutter={[0,16]}>
           <Col span={24}></Col>
              <Col>
              <Typography className='items-total'>Date and time</Typography>
                  </Col>
                  </Row>
                  <Row gutter={[0,0]}>
              <Col>
              <Text className='checkout-brand-name'>{orderCreatedDate}</Text>
                  </Col>
                  </Row>
            <Row gutter={[0,32]}>
                <Col span={24}></Col>
              <Col>
                <Typography className='items-total'>Payment</Typography>
              </Col>
            </Row>
            <Row gutter={[0,2]}>
            <Col span={24}></Col>
              <Col>
                <Text className='checkout-brand-name'>{paymentMethodType}</Text>
              </Col>
            </Row>
            <Row gutter={[0,32]}>
                <Col span={24}></Col>
              <Col>
                <Typography className='items-total'>Shipping address</Typography>
              </Col>
            </Row>
            <Row gutter={[0,2]}>
            <Col span={24}></Col>
              <Col span={24}>
                <Text className='checkout-brand-name'>{name}</Text>
              </Col>
              <Col>
                <Text className='checkout-brand-name'>{address}, {secondAddress} {secondAddress ? ',' : ''} {city}, {state} {zip}</Text>
              </Col>
            </Row>
            <Row gutter={[0,32]}>
                <Col span={24}></Col>
              <Col>
                <Typography className='items-total'>Shipping method</Typography>
              </Col>
            </Row>
            <Row gutter={[0,2]}>
            <Col span={24}></Col>
              <Col>
                <Text className='checkout-brand-name'>Standerd shipping</Text>
              </Col>
            </Row>
            <Row gutter={[0,32]}>
                <Col span={24}></Col>
              <Col>
                <Typography className='notify-me'>Seller</Typography>
              </Col>
            </Row>
            <Row gutter={[32,2]}>
            <Col span={24}></Col>
              <Col>
              <Avatar shape='square' size={51} src={ShippingLogo} />
              </Col>
              <Col>
                  <Text className='view-more'>{sellerName}</Text>
                  <Row gutter={[4,0]}>
                    <Col>
                    <img src={TrueIcon} />
                    </Col>
                    <Col>
                      <Typography className='verified-seller'>Verified seller</Typography>
                      </Col>
                  </Row>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
              <Button block onClick={() => setShowMessageSellerDialog(true)} className='contact-us-button'>CONTACT SELLER</Button>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={24}></Col>
              <Col span={24}>
              <Button block onClick={() => history.push('/')} className='message-seller-button'>CONTINUE SHOPPING</Button>
              </Col>
            </Row>
            <MessageSeller onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)} showMessageSellerDialog={showMessageSellerDialog}/>
    </>
  );
};
export default OrderDetails;
