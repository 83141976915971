/*eslint-disable*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Button,
  Card,
  Divider,
  Rate
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { MessageSeller } from "../components/MessageSeller";
import Avatar from "antd/lib/avatar/avatar";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import TrueIcon from "../assets/trueicon.svg";
import { useBreakpoints } from './../react-responsive-hooks/index';
import "../App.css";
import moment from 'moment';
import SellerImg from "../assets/SellerImg.svg";
import TrueVector from "../assets/TrueVector.svg";
import ShippingLogo from "../assets/Slice.svg";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { useGaEvent } from "../hooks/useGaEvent";
const { Text, Title } = Typography;
const CheckoutOrderDetails = () => {
  const { gaEventClick } = useGaEvent();
  const history = useHistory();
  const { isXS } = useBreakpoints();
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false)
  const { orderDetails, partnerOrderDetails } = useSelector((state) => {
    const { orderDetails, partnerOrderDetails } = state.order;
    return {
      orderDetails,
      partnerOrderDetails
    };
  });
  const { createdAt, lots, actualAddress, paymentMethodType } = orderDetails
  const orderCreatedDate = moment(createdAt).format("h:mm: a, Do MMMM YYYY");
  const { sellerAddress, lot } = lots || {}
  const { name: sellerName } = sellerAddress || {}
  const { address, city, name, secondAddress, state, zip } = (actualAddress && actualAddress.length) > 0 ? actualAddress[0] : {}
  return (
    <>
      <Row gutter={[0, 30]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col span={24} className="shoppingCartParent2">
          <Card className='shoppingCartCard'>
            <Row gutter={[0, 25]}>
              <Col span={24}></Col>
              <Col>
                <Typography className='pDTitle'>
                  Order details
                </Typography>
              </Col>
            </Row>
            <Divider style={{ margin: '14px 0px 9px' }} />
            <Row gutter={[0, 16]} className="order-lineitems" justify='space-between' align="middle">
              <Col span={24}></Col>
              <Col>
                <Typography className='pDKey'>Date and time</Typography>
              </Col>
              <Col>
                <Text className='PDVal'>{orderCreatedDate}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 15]} className="order-lineitems" justify='space-between' align="middle">
              <Col span={24}></Col>
              <Col>
                <Typography className='PDKey'>Payment</Typography>
              </Col>
              <Col>
                <Text className='PDVal  text-capitalize'>{paymentMethodType || ((partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? partnerOrderDetails.insertedPartnerDocs[0].paymentMethodType : '' )}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 15]} className="order-lineitems" justify='space-between'>
              <Col span={24}></Col>
              <Col>
                <Typography className='PDKey'>Shipping address</Typography>
              </Col>
              {/* <Col span={24}>
                <Text className='PDVal'>{name}</Text>
              </Col> */}
              <Col span={12}>
              {actualAddress ? <Typography className='PDVal' style={{ textAlign: 'end' }}>{address}, {secondAddress} {secondAddress ? ',' : ''} {city}, {state} {zip}</Typography> :
                (partnerOrderDetails && partnerOrderDetails.insertedPartnerDocs) ? <Typography className='PDVal' style={{ textAlign: 'end' }}>{partnerOrderDetails.insertedPartnerDocs[0].billingAddress.locality}, {partnerOrderDetails.insertedPartnerDocs[0].billingAddress.region} , {partnerOrderDetails.insertedPartnerDocs[0].billingAddress.postalCode}</Typography> : <></>}
              </Col>
            </Row>
            <Row gutter={[0, 15]} className="order-lineitems" justify='space-between' align="middle">
              <Col span={24}></Col>
              <Col>
                <Typography className='pDKey'>Shipping method</Typography>
              </Col>
              <Col>
                <Text className='PDVal'>Standard shipping</Text>
              </Col>
            </Row>
            <Divider style={{ margin: '30px 0px 5px' }} />
            {lots && lots.map((val, i) => {
              const { seller } = val
              const { photo: sellerPhoto, fullName: sellerName } = seller || {}
              return (
                <div key={i}>
                  <Row gutter={[0, 25]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className='oCSellerText'>Seller</Typography>
                    </Col>
                  </Row>
                  <Row gutter={[{ xs: 10, sm: 10, md: 21, lg: 21, xl: 21, xxl: 21 }, 20]}>
                    <Col span={24}></Col>
                    <Col>
                      {sellerPhoto ?
                        <Avatar size={isXS ? 75 : 109} className="checkoutImage" shape='square' src={sellerPhoto} />
                        :
                        <Avatar size={isXS ? 75 : 109} className="checkoutImage" shape='square' src={UserBlanckImg} />
                      }
                      <Row gutter={[0, 14.12]}>
                        <Col span={24}></Col>
                        <Text className="pLVerified">
                          <img
                            src={TrueVector}
                          /> Verified
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Typography className='pLSellerName'>
                        {sellerName}
                      </Typography>
                      <Row>
                        <Rate defaultValue={5} className="pLRate" disabled={true} />
                      </Row>
                      <Row gutter={[0, { xs: 10, sm: 10, md: 30.96, lg: 30.96, xl: 30.96, xxl: 30.96 }]}>
                        <Col span={24}></Col>
                        <Col span={24}>
                          <Button block onClick={() => setShowMessageSellerDialog(true)} className='oCContactSellerButton'>CONTACT SELLER</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {i < lots && lots.length - 1 && <Divider style={{ margin: "30px 0px 0px" }} />}
                </div>
              )
            })}
            {partnerOrderDetails.insertedPartnerDocs && partnerOrderDetails.insertedPartnerDocs.map((val, i) => {
              return (
                <div key={i}>
                  <Divider style={{ margin: "30px 0px 0px" }} />
                  <Row gutter={[0, 25]}>
                    <Col span={24}></Col>
                    <Col>
                      <Typography className='oCSellerText'>Seller</Typography>
                    </Col>
                  </Row>
                  <Row gutter={[{ xs: 10, sm: 10, md: 21, lg: 21, xl: 21, xxl: 21 }, 20]}>
                    <Col span={24}></Col>
                    <Col>
                      {val.sellerInfo.image ?
                        <Avatar size={isXS ? 75 : 109} className="checkoutImage" shape='square' src={val.sellerInfo.image} />
                        :
                        <Avatar size={isXS ? 75 : 109} className="checkoutImage" shape='square' src={UserBlanckImg} />
                      }
                      <Row gutter={[0, 14.12]}>
                        <Col span={24}></Col>
                        <Text className="pLVerified">
                          <img
                            src={TrueVector}
                          /> Verified
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Typography className='pLSellerName'>
                        {val.sellerInfo.name}
                      </Typography>
                      <Row>
                        <Rate defaultValue={5} className="pLRate" disabled={true} />
                      </Row>
                      {/* <Row gutter={[0, { xs: 10, sm: 10, md: 30.96, lg: 30.96, xl: 30.96, xxl: 30.96 }]}>
                        <Col span={24}></Col>
                        <Col span={24}>
                          <Button block onClick={() => setShowMessageSellerDialog(true)} className='oCContactSellerButton'>CONTACT SELLER</Button>
                        </Col>
                      </Row> */}
                    </Col>
                  </Row>
                  {/* {i < partnerOrderDetails.insertedPartnerDocs && partnerOrderDetails.insertedPartnerDocs.length - 1 && <Divider style={{ margin: "30px 0px 0px" }} />} */}
                </div>
              )
            })}
            <Row gutter={[0, { xs: 40, sm: 40, md: 16, lg: 16, xl: 16, xxl: 16 }]} className="btn-continue-wrap">
              <Col span={24}></Col>
              <Col span={24}>
                {/* <Button block onClick={() => history.push('/homepage')} className='continueshippingButton'>CONTINUE SHOPPING</Button> */}
                <Button block onClick={() => {gaEventClick('Navigation', 'Continue Shopping');history.push('/')}} className='button button-primary continueshippingButton'>CONTINUE SHOPPING</Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <MessageSeller onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)} showMessageSellerDialog={showMessageSellerDialog} />
    </>
  );
};
export default CheckoutOrderDetails;
