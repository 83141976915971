/*eslint-disable*/
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  Row,
  Col,
  Typography,
  Breadcrumb,
  Input,
  Card,
  Tag,
  Button,
  Collapse,
  Avatar,
  Spin,
  Rate,
  Divider,
  Tabs,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import ProductTop from "../components/ProductTop";
import PLCart from "../components/PLCart";
import PLDescription from "../components/PLDescription";
import { MessageSeller } from "../components/MessageSeller";
import {
  ShareAltOutlined,
  HeartOutlined,
  LoadingOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  followUser,
  unFollowUser,
  getUser,
  notifyItem,
  unNotifyItem,
} from "../Actions/userAction";
import { ReNewedWorkCard } from "../components/RenewedWork";
import { CategoryCard } from "../components/CategoryCard";
import HeadingHearts from "../assets/HeadingHearts.svg";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import File3 from "../assets/File3.JPG";
import File4 from "../assets/File4.JPG";
import ListThree from "../assets/list3.svg";
import PickUpImg from "../assets/pickup.svg";
import Rest from "../assets/rest.svg";
import PaidOne from "../assets/paid1.svg";
import MainHomePageFooter from "../components/MainHomePageFooter";
import { getLotById } from "../Actions/lotsAction";
import "../App.css";
import { useHistory, useParams } from "react-router";
import { PLBreadCrumb } from "../components/PLBreadCrumb";
import PMenu from "../components/PMenu";
import moment from "moment";
import SellerImg from "../assets/SellerImg.svg";
import SellerImgOne from "../assets/SellerImgOne.svg";
import SellerImgTwo from "../assets/SellerImgTwo.svg";
import Badge1 from "../assets/Badge1.svg";
import Badge2 from "../assets/Badge2.svg";
import Badge3 from "../assets/Badge3.svg";
import Badge4 from "../assets/Badge4.svg";
import { PLSimilarCard } from "../components/PLSimilarCard";
import { MoreItemsCard } from "../components/PLMoreItemsCard";
import { RecommendedCard } from "../components/RecommendedCard";
import SignIn from "../pages/SignIn";
import toolTip from "../assets/new-assets/icon-tooltip.png";

import {
  moreLotsFromThisSeller,
  similarItems,
  getUserRatings,
} from "../Actions/userAction";
import protect from "../assets/protection.svg";
import securePay from "../assets/secure_payment.svg";
import MarkNewsLetter from "../components/MarkNewsLetter";
import PL1 from "../assets/PL1.svg";
import { NotifySoon } from "../components/NotifySoon";
import visa from "../assets/Mastercardlogo.png";
import visa1 from "../assets/VisaLogo.png";
import AmericanEx from "../assets/AmericanExpress.png";
import stripe from "../assets/Stripelogo.png";
import TrueVector from "../assets/verifiednew.png";
import PL2 from "../assets/PL2.svg";
import PL3 from "../assets/PL3.svg";
import PL4 from "../assets/PL4.svg";
import SubmitItemImg from "../assets/SubmitItemImg.svg";
import ScheduleImg from "../assets/ScheduleImg.svg";
import RestImg from "../assets/RestImg.svg";
import PaidImg from "../assets/PaidImg.svg";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel, { consts } from "react-elastic-carousel";
import { useBreakpoints } from './../react-responsive-hooks/index';
import WMBgshape from "../assets/WMBgshape.svg";
import ShopKidsImg from "../assets/ShopKidsImg.svg";
import OurTeamImg from "../assets/OurTeamImg.svg";
import PrelovedImg from "../assets/PrelovedImg.svg";
import RemoveIcon from "../assets/RemoveIcon.svg";
import UserBlanckImg from "../assets/UserBlanckImg.svg";
import { humanize } from "../Utils/util";
import { getChatsWithSeller } from "../Actions/chatsAction";
import { getSellerBadges } from "../Actions/userAction";
import { getCategoriesGroup } from "../Actions/groupAction";
import { Helmet } from "react-helmet";
import { useGaEvent } from "../hooks/useGaEvent";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ProductListDetail = () => {
  const { isXS, isXL, isSM } = useBreakpoints();

  const { gaPageView, gaEventClick } = useGaEvent();
  useEffect(() => gaPageView());
  
  const PlSimilarCardData = [
    {
      pLImage: PL1,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL2,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL3,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL4,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL2,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL3,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL1,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL4,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL3,
      pLName: "Buggaboo Donkey2 s...",
      pLPrice: "150",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL1,
      pLName: "Cybex platinum strol...",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL4,
      pLName: "Geo2 stroller",
      pLPrice: "143",
      pLShipping: "Shipping",
    },
    {
      pLImage: PL2,
      pLName: "Buggaboo Donkey2 st...",
      pLPrice: "229",
      pLShipping: "Shipping",
    },
  ];
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [showMessageSellerDialog, setShowMessageSellerDialog] = useState(false);
  const [groupIDMap, setGroupIDMap] = useState({});
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [tabKey, setTabkey] = useState("2");
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  function callback(key) {
    // console.log(key);
  }
  // function tabsCallback(key) {
  //   console.log(key);
  // }

  const {
    lot,
    allLots,
    isGetLotByIdLoading,
    currentUser,
    isFollowUserLoading,
    isUnFollowUserLoading,
    isNotifyItemLoading,
    isMoreFromThisSellerLoading,
    isSimilarItemsLoading,
    isUnNotifyItemLoading,
    currentCart,
    productTypeDetails,
    providerToken,
    isAuthenticated,
    moreLots,
    similarLots,
    sellerBadges,
    isGetChatsWithSellerLoading,
    categoriesGroup,
  } = useSelector((state) => {
    const { lot, isGetLotByIdLoading, allLots } = state.lots;
    const { currentCart } = state.cart;
    const { productTypeDetails } = state.productTypeDetails
    const { providerToken, isAuthenticated } = state.auth;
    const { isGetChatsWithSellerLoading } = state.chats;
    const { categoriesGroup } = state.groups;
    const {
      currentUser,
      sellerBadges,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      moreLots,
      similarLots,
      isMoreFromThisSellerLoading,
      isSimilarItemsLoading,
    } = state.user;
    return {
      lot,
      allLots,
      moreLots,
      similarLots,
      currentUser,
      isFollowUserLoading,
      isUnFollowUserLoading,
      isNotifyItemLoading,
      isUnNotifyItemLoading,
      currentCart,
      isAuthenticated,
      providerToken,
      isGetLotByIdLoading,
      isMoreFromThisSellerLoading,
      isSimilarItemsLoading,
      isGetChatsWithSellerLoading,
      sellerBadges,
      categoriesGroup,
      productTypeDetails
    };
  });
  // const {isActive}=productTypeDetails
  const { fastResponder, fastShipper, top10 } = sellerBadges || {};
  const { _id: currentUserId, notifyItems, following } = currentUser || {};

  const {
    imageUrls,
    description,
    brand,
    name,
    gender,
    createdAt,
    color,
    inSoldPending,
    inUserFavorites,
    _id,
    price,
    condition,
    category,
    isActive,
    deliveryType,
    yearOfPurchase,
    negotiablePrice,
    location,
    sellerAddress,
    createdBy,
    seller,
    isRenewed,
  } = lot || {};
  const { lots } = allLots || { lots: [] };
  const ProductId = _id;
  const {
    _id: sellerId,
    fullName,
    photo,
    activeLotsCount,
    soldLotsCount,
    createdAt: SellerCreatedAt,
  } = seller || {};

  // const { name: sellerName } = sellerAddress || { sellerName: '' }
  useEffect(() => {
    var result = categoriesGroup.groupType.reduce(function (map, obj) {
      // const temp = {
      //   category: obj.category,
      //   _id: obj._id,
      // };
      map[obj.category] = obj._id;
      return map;
    }, {});

    setGroupIDMap(result);
  }, [categoriesGroup]);
  const [showNotifySoonDialog, setShowNotifySoonDialog] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getLotById(productId));
    dispatch(getSellerBadges(sellerId));
    dispatch(moreLotsFromThisSeller({ id: createdBy, limit: 10 }));
    dispatch(similarItems({ id: productId, limit: 10 }));
    dispatch(getUserRatings(createdBy));
    dispatch(getCategoriesGroup("categories"));
  }, [params, createdBy]);
  const { productId } = params;

  const Listed = moment(createdAt).format("MMMM YYYY");
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleFollowUser = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Follow", createdBy);
      dispatch(followUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      gaEventClick("Navigation", "Follow", "signup");
      history.push("/onboarding");
    }
  };
  const handleUnFollowUser = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Unfollow", createdBy);
      dispatch(unFollowUser({ id: createdBy }));
    } else {
      // setShowSignInDialog(true)
      gaEventClick("Navigation", "Unfollow", "signup");
      history.push("/onboarding");
    }
  };
  const handleMessageSeller = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Message Seller");
      dispatch(
        getChatsWithSeller(
          _id,
          sellerId,
          messageSellerSuccess,
          messageSellerFailure
        )
      );
      // history.push(`/messages`)
    } else {
      gaEventClick("Navigation", "Message Seller", "signup");
      history.push("/onboarding");
    }
  };
  const messageSellerSuccess = () => {
    history.push("/chat");
  };
  const messageSellerFailure = () => {};
  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  const onTabClick = (key) => {
    setTabkey(key);
  };
  let NotifyItemId = "";
  {
    notifyItems &&
      notifyItems.map((current) => {
        current === ProductId ? (NotifyItemId = current) : null;
      });
  }
  const handleNotifyItem = () => {
    if (isAuthenticated) {
      gaEventClick("PDP", "Get Notified", productId);
      dispatch(
        notifyItem(
          { id: ProductId },
          successCallBackNotifyMe,
          failureCallBackNotifyMe
        )
      );
    } else {
      gaEventClick("Navigation", "Get Notified", "signup");
      history.push("/onboarding");
      // setShowSignInDialog(true)
    }
  };
  const successCallBackNotifyMe = () => {
    setShowNotifySoonDialog(true);
  };
  const failureCallBackNotifyMe = () => {};
  const handleUnNotifyItem = () => {
    if (isAuthenticated) {
      dispatch(unNotifyItem({ id: ProductId }));
    } else {
      history.push("/onboarding");
      // setShowSignInDialog(true)
    }
  };
  

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <LeftOutlined /> : <RightOutlined />;
    return (
      <button
        style={{
          background: "transparent",
          border: "none",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          fontSize: 25,
          fontWeight: "bolder",
          color: isEdge ? "#E4E4E4" : "#393939",
          cursor: "pointer",
        }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };
  const myDots = ({ pages, activePage, onClick }) => {
    return (
      <Row
        justify="center"
        style={{
          width: "100%",
          padding: "0px 15px",
          zIndex: 9,
        }}
      >
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              style={{
                borderRadius: "50%",
                background: isActivePage ? "#B5B5B5" : "#E4E4E4",
                width: 15,
                height: 15,
                margin: isXS ? "23px 5px 0px" : "70px 5px 0px",
                cursor: "pointer",
              }}
              onClick={() => onClick(page)}
              // active={isActivePage}
            />
          );
        })}
      </Row>
    );
  };

  const forSellerUser = createdBy === currentUserId;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{name || "Markid - Product Details"}</title>
      </Helmet>
      <div className="pDetailBackground">
        <div className="app-wrapper pdp">
          <Row gutter={[0, { xs: 0, sm: 0, md: 30, lg: 40, xl: 40 }]}>
            <Col span={24}></Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
            >
              {/* Breadcrumb  */}
              <Row justify="space-between" gutter={[0, 0]}>
                <Col>
                  <PLBreadCrumb
                    className="pDetailBreadCrumb"
                    markid="Home"
                    category={category && humanize(category)}
                    brand={capitalize(brand)}
                    name={capitalize(name)}
                    routeId={groupIDMap[category]}
                    routeName={category}
                  />
                </Col>
                {/* <Col>
              <ShareAltOutlined className="sharedIcon" color="#393939"/>
            </Col> */}
              </Row>
              {/* Breadcrumb  */}
            </Col>
          </Row>

          <Row gutter={[0, { xs: 0, sm: 0, md: 23, lg: 23, xl: 23 }]}>
            <Col span={24}></Col>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="pdp-left"
            >
              {/* <Card bordered={false}> */}
              <PLDescription
                productData={lot}
                sellerInfo={seller}
                productLoader={isGetLotByIdLoading}
              />
              <div className="addtocartweb">
                <div className="plsimilarcenter">
                  {/* <Divider style={{ color: "#707070", margin: "30px 0px" }} /> */}
                  <Typography
                    className="plsimilarpdp"
                    onClick={() => {
                      if (providerToken) {
                        gaEventClick("PDP", "Sell on markid");
                        history.push("/seller/addproduct");
                      } else {
                        gaEventClick("Navigation", "Sell on markid", "SignUp");
                        history.push("/onboarding");
                      }
                    }}
                  >
                    Have a similar item?
                    <span className="plsimilarpdp1">
                      Sell it on Markid
                    </span>{" "}
                  </Typography>
                  {/* <Divider style={{ color: "#707070", margin: "30px 0px" }} /> */}
                </div>
               {isActive===true &&(<div className="addtocartweb">
                  <Row gutter={[0, 24]}>
                    <Col>
                      {isGetLotByIdLoading ? (
                        <Skeleton active={isGetLotByIdLoading} size="small" />
                      ) : (
                        <Typography className="sellertitlepdp">
                          Meet the seller
                        </Typography>
                      )}
                    </Col>
                    <Col span={24} />
                  </Row>
                  <Row gutter={[0, 0]}>
                    <Col xl={4}>
                      {isGetLotByIdLoading ? (
                        <Skeleton.Image
                          active={isGetLotByIdLoading}
                          size="small"
                        />
                      ) : photo && photo ? (
                        <LazyLoadImage
                          effect="blur"
                          shape="square"
                          style={{
                            height: 120,
                            width: 109,
                            cursor: "pointer",
                            borderRadius: "50%",
                          }}
                          src={photo}
                          onClick={() =>
                            history.push(`/seller-profile/${sellerId}`)
                          }
                        />
                      ) : (
                        <img
                          alt=""
                          src={UserBlanckImg}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(`/seller-profile/${sellerId}`)
                          }
                        />
                      )}
                      <Row gutter={[8, 9]}>
                        <Col span={24}></Col>
                        {isGetLotByIdLoading ? (
                          <Skeleton.Button
                            active={isGetLotByIdLoading}
                            size="small"
                          />
                        ) : (
                          <Text className="pLVerifiedpdp style2">
                            {/* <img src={TrueVector} width="13px" />
                            &nbsp;Verified */}
                          </Text>
                        )}
                      </Row>
                    </Col>
                    <Col xl={20}>
                      <Row gutter={[0, 3]}>
                        {isGetLotByIdLoading ? (
                          <Skeleton.Input
                            active={isGetLotByIdLoading}
                            size="small"
                          />
                        ) : (
                          fullName && (
                            <Text
                              className="Plsellernamepdp"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push(`/seller-profile/${sellerId}`)
                              }
                            >
                              {capitalize(fullName)}
                            </Text>
                          )
                        )}
                        <Col span={3} />
                      </Row>
                      <Row
                        gutter={[0, 15]}
                        align="middle"
                        className="align-center"
                      >
                        {isGetLotByIdLoading ? (
                          <Skeleton.Button
                            active={isGetLotByIdLoading}
                            size="small"
                          />
                        ) : (
                          <Rate
                            defaultValue={5}
                            className="pLRatepdp"
                            disabled={true}
                          />
                        )}
                        {isGetLotByIdLoading ? (
                          <Skeleton active={isGetLotByIdLoading} size="small" />
                        ) : (
                          <Text className="pLListedItempdp">
                            <span className="ItemCount">
                              {activeLotsCount + soldLotsCount}
                            </span>{" "}
                            items listed
                          </Text>
                        )}
                        <Text className="pLListedItempdp1">|</Text>
                        {isGetLotByIdLoading ? (
                          <Skeleton active={isGetLotByIdLoading} size="small" />
                        ) : (
                          <Text className="pLListedItempdp">
                            <span className="ItemCount">{soldLotsCount}</span>{" "}
                            sold
                          </Text>
                        )}

                        <Typography className="pLListedItemdesc">
                          New to gently used kids clothing , shoes , and
                          accessories ship same or next day ✨
                        </Typography>

                        <Col span={15} />
                      </Row>
                     
                      {!inSoldPending && !forSellerUser && (
                        <Row gutter={[0, 16]}>
                          <Col span={24}></Col>
                          {isGetLotByIdLoading ? (
                            <Skeleton.Button
                              active={isGetLotByIdLoading}
                              size="small"
                            />
                          ) : (
                            <Col span={12}>
                              <Spin
                                indicator={antIcon}
                                spinning={isGetChatsWithSellerLoading}
                              >
                                <Button
                                  block
                                  onClick={() => handleMessageSeller()}
                                  className={
                                    "button button-bordered pLMakeOfferpdp"
                                  }
                                >
                                  MESSAGE SELLER
                                </Button>
                              </Spin>
                            </Col>
                          )}
                          {!forSellerUser && (
                            <Col span={12}>
                              {isGetLotByIdLoading ? (
                                <Skeleton.Button
                                  active={isGetLotByIdLoading}
                                  size="small"
                                />
                              ) : following && following.length > 0 ? (
                                <Spin
                                  indicator={antIcon}
                                  spinning={isUnFollowUserLoading}
                                >
                                  <div className="tooltipext  style2">
                                    <Button
                                      type="text"
                                      className="plfollowpdp"
                                      onClick={() =>
                                        handleUnFollowUser(createdBy)
                                      }
                                    >
                                      UnFollow
                                    </Button>

                                    <Tooltip
                                      className="custom"
                                      arrowPointAtRight
                                      placement="bottom"
                                      title="Get notified when this seller publishes a new item to sell"
                                    >
                                      <img src={toolTip} className="tooltip" />
                                    </Tooltip>
                                  </div>
                                </Spin>
                              ) : (
                                <Spin
                                  indicator={antIcon}
                                  spinning={isFollowUserLoading}
                                >
                                  <div className="tooltipext  style2">
                                    <Button
                                      border={false}
                                      type="text"
                                      className="plfollowpdp"
                                      onClick={() =>
                                        handleFollowUser(createdBy)
                                      }
                                    >
                                      Follow
                                    </Button>{" "}
                                    <Tooltip
                                      className="custom"
                                      arrowPointAtRight
                                      placement="bottom"
                                      title="Get notified when this seller publishes a new item to sell"
                                    >
                                      <img src={toolTip} className="tooltip" />{" "}
                                    </Tooltip>
                                  </div>
                                </Spin>
                              )}
                            </Col>
                          )}
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Divider style={{ color: "#707070", margin: "30px 0px" }} />

                
                </div>)} 
              </div>
              {/* </Card> */}
            </Col>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="pdp-right"
            >
              {/* <Card bordered={false}> */}
              <PLCart
                productData={lot}
                sellerInfo={seller}
                productLoader={isGetLotByIdLoading}
              />
              <div className="addtocartweb">
                <Divider style={{ color: "#707070", margin: "30px 0px" }} />{" "}
                <Row gutter={[0, 0]}>
                  <Col>
                    <img alt="" src={securePay} style={{ cursor: "pointer" }} />
                  </Col>
                  <Col
                    xl={{ span: 18, offset: 1 }}
                    sm={{ span: 18, offset: 1 }}
                    xs={{ span: 18, offset: 1 }}
                  >
                    <Row gutter={[0, 3]}>
                      <Text
                        className="Plsecurepdp"
                        style={{ cursor: "pointer" }}
                      >
                        Secure payments
                      </Text>
                      <Col span={3} />
                    </Row>
                    <Row>
                      <Typography className="pLListedItemdesc">
                        We keep your payment information secure using stripe.
                        Markid sellers will never receive your credit card
                        information.
                      </Typography>

                      <Col span={15} />
                    </Row>
                    <Row>
                      <Col span={4}>
                        <img src={stripe} width="60.61px"/>
                      </Col>
                      <Col span={2}>
                        <img src={visa}width="40.61px" />
                      </Col>
                      <Col span={2}>
                        <img src={visa1}width="40.61px" />
                      </Col>
                      <Col span={2}>
                        <img src={AmericanEx} width="40.61px" />
                      </Col>
                    </Row>
                  </Col>
                  <Divider style={{ color: "#707070", margin: "30px 0px" }} />
                  <Col>
                    <img alt="" src={protect} style={{ cursor: "pointer" }} />
                  </Col>
                  <Col xl={{ span: 18, offset: 1 }}>
                    <Row gutter={[0, 3]}>
                      <Text
                        className="Plsecurepdp"
                        style={{ cursor: "pointer" }}
                      >
                        Markid’s buyer protection guarantee
                      </Text>
                      <Col span={3} />
                    </Row>
                    <Row>
                      <Typography className="pLListedItemdesc">
                        Your transactions are protected. All payments are held
                        until you receive your item, or 100% refund guaranteed.
                      </Typography>

                      <Col span={15} />
                    </Row>
                  </Col>

                  {/* <Row gutter={[0, 3]}>
                    
                   </Row> */}
                </Row>
              </div>

              {inSoldPending || forSellerUser ? (
                <></>
              ) : (
                <div className="getNotifiedWrapper lg">
                  <Spin indicator={antIcon} spinning={isNotifyItemLoading}>
                    <Typography
                      className="getNotified"
                      
                    >
                      <span className="getNotified"onClick={() => handleNotifyItem(ProductId)} style={{color:"#fd533f"}}>
                      Get notified{" "}</span>
                      <span className="">
                        when the price drops or when a similar item is posted.
                        <Tooltip
                          title="You'll be notified when the price drops on this item, or when a similar item is posted. "
                          placement="left"
                        >
                          <img src={toolTip} className="tooltip" />
                        </Tooltip>
                      </span>{" "}
                    </Typography>
                  </Spin>
                </div>
              )}
              {/* </Card> */}
            </Col>

            <Col
              xl={{ span: 14, offset: 0 }}
              lg={14}
              md={14}
              sm={24}
              xs={24}
              className="pdp-left"
            ></Col>
          </Row>

          <Row
            gutter={[0, { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }]}
            justify="start"
          >
            <Col span={24}></Col>
            <Col xl={{ span: 16, offset: 0 }} xs={{ span: 24, offset: 0 }}>
              {/* <Card className="pLCollapseCard"> */}

              {/* <Divider style={{ color: "#707070", margin: isXS ? "20px 0px" : "40px 0px" }} /> */}

              {isRenewed && (
                <>
                  {/* <Divider style={{ color: "#707070", margin: isXS ? "20px 0px" : "40px 0px" }} /> */}
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Collapse
                        style={{ backgroundColor: "white" }}
                        defaultActiveKey={["0"]}
                        onChange={callback}
                        expandIconPosition="end"
                        bordered={false}
                        ghost
                        expandIcon={({ isActive }) => {
                          return !isActive ? (
                            <DownOutlined className="collapsIcon" />
                          ) : (
                            <UpOutlined className="collapsIcon" />
                          );
                        }}
                      >
                        <Panel
                          header={
                            <Text className="pLPanelTitle">
                              What is markid reNEWED?
                            </Text>
                          }
                          key="1"
                        >
                          <Row
                            gutter={[
                              0,
                              {
                                xs: 20,
                                sm: 20,
                                md: 25,
                                lg: 25,
                                xl: 25,
                                xxl: 25,
                              },
                            ]}
                          >
                            <Col span={24}></Col>
                            <Col>
                              <Typography className="pLDes">
                                ReNEWED is our hand-picked selection of
                                pre-loved items that are inspected,
                                professionally cleaned, sanitized, and restored
                                to a lovingly reNEWED state. Just the way you’d
                                like them - for your kids.
                              </Typography>
                            </Col>
                          </Row>

                          <Row
                            gutter={[
                              48,
                              {
                                xs: 30,
                                sm: 30,
                                md: 33,
                                lg: 33,
                                xl: 33,
                                xxl: 33,
                              },
                            ]}
                          >
                            <Col span={24}></Col>
                            <Col xl={8} lg={8} md={12} sm={22} xs={22}>
                              <Row>
                                <img alt="" src={ShopKidsImg} />
                              </Row>
                              <Row gutter={[0, 10]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRTitle">
                                  Hand-picked for you
                                </Typography>
                              </Row>
                              <Row gutter={[0, 7]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRDec">
                                  Markid reNEWED is a collection of top brands
                                  and items that you want for your family. From
                                  strollers to high chairs, we sell the best
                                  items for your kids, all in one place.
                                </Typography>
                              </Row>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={22} xs={22}>
                              <Row>
                                <img alt="" src={OurTeamImg} />
                              </Row>
                              <Row gutter={[0, 10]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRTitle">
                                  Quality Assured
                                </Typography>
                              </Row>
                              <Row gutter={[0, 7]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRDec">
                                  All reNEWED products are professionally
                                  sanitized, cleaned, restored, and tested for
                                  functionality by our team. We bring all items
                                  back to a reNEWED state so you get the most
                                  out of your pre-loved kids’ gear.
                                </Typography>
                              </Row>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={22} xs={22}>
                              <Row>
                                <img alt="" src={PrelovedImg} />
                              </Row>
                              <Row gutter={[0, 10]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRTitle">
                                  The Sustainable, affordable choice
                                </Typography>
                              </Row>
                              <Row gutter={[0, 7]}>
                                <Col span={24}></Col>
                                <Typography className="wIMRDec">
                                  ReNEWED gives you top items at a discounted
                                  price, so you don’t have to sacrifice your
                                  wallet for items you love. By purchasing
                                  pre-loved, you and your family help extend the
                                  life of kids’ gently-used items - and make a
                                  better choice for you and for the planet.
                                </Typography>
                              </Row>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </>
              )}
              {/* <Divider style={{ color: "#707070", margin: isXS ? "20px 0px" : "40px 0px" }} /> */}
              {/* <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Collapse
                    style={{ backgroundColor: "white"}}
                    defaultActiveKey={["0"]}
                    onChange={callback}
                    expandIconPosition="end"
                    ghost
                    bordered={false}
                    expandIcon={({ isActive }) => {
                      return !isActive ? (
                        <DownOutlined className="collapsIcon" />
                      ) : (
                        <UpOutlined className="collapsIcon" />
                      );
                    }}
                  >
                    <Panel
                      header={
                          <Text className="pLPanelTitle">
                            Ratings and reviews
                          </Text>
                      }
                      key="1"
                    >
                      <Row justify="start" gutter={[0, {xs:25, sm:25, md:4, lg:4, xl:4, xxl:4}]}>
                        <Col span={24}></Col>
                        <Col>
                          <Typography className="momReviesBlog">
                            Blogger Mom Reviews
                          </Typography>
                        </Col>
                      </Row>
                      <Row justify="start" gutter={[0, 16]}>
                        <Col span={24}></Col>
                        <Col span={24} className="ratingParent">
                          <Card style={{ backgroundColor: "#F7F7F7" }}>
                            <Row>
                              <Col>
                                <Title level={5} className="bMRCardTitle">
                                  My kid loves the lounger
                                </Title>
                              </Col>
                            </Row>
                            <Row gutter={[0, {xs:10, sm:10, md:30, lg:30, xl:30, xxl:30}]}>
                              <Col span={24}/>
                              <Col>
                                <Typography className="bMRCardDec">
                                  The coco stylewood lounger contains the wooden
                                  frame, seat pad + 5 point safety harness.
                                  curved, single-piece baltic birch wood frame
                                  with plush, cotton baby-facing seat pad with
                                  polyester rear lining. formed in pressed wood.
                                  smooth, self-rocking motion. Adjustable
                                  5-point padded safety
                                </Typography>
                              </Col>
                              <Col span={24}/>
                            </Row>
                            <Row gutter={[16, 0]}>
                              <Col span={24}></Col>
                              <Col>
                                <Avatar
                                  shape="square"
                                  size={(57, 62)}
                                  src={SellerImg}
                                />
                              </Col>
                              <Col>
                                <Row gutter={[0, 8]}>
                                  <Col span={24}></Col>
                                  <Col>
                                    <Typography className="reviewedSellerName">
                                      Celiene Adams
                                    </Typography>
                                    <Row>
                                      <Col>
                                        <Typography className="outOfReviewSeller">
                                          (Mom of two aged 3 and 5)
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row gutter={[0, 35]}>
                        <Col span={24}></Col>
                        <Col>
                          <Typography className="momReviesBlog">
                            PRODUCT RATINGS (AMAZON)
                          </Typography>
                        </Col>
                      </Row>
                      <Row gutter={[0, 15]}>
                        <Col span={24}></Col>
                        <Col span={24}>
                          <Card style={{ backgroundColor: "#FFF8EF" }}>
                            <Row justify="center">
                              <Col>
                                <Rate
                                  allowHalf
                                  className="bigStar"
                                  defaultValue={4}
                                  disabled={true}
                                />
                                <Row justify="center" gutter={[0,{xs:12, sm:12, md:10, lg:10, xl:10, xxl:10}]}>
                                  <Col span={24}/>
                                  <Col>
                                    <Title strong level={5}>
                                      <Typography className="totalRatings">
                                        <span className="ratingsVal">4.5</span>{" "}
                                        out of 5
                                      </Typography>
                                    </Title>
                                  </Col>
                                </Row>
                              </Col>
                              <Row justify="center" gutter={[0,{xs:25, sm:25, md:22, lg:22, xl:22, xxl:22}]}>
                                <Col span={24}/>
                                <Col>
                                  <Typography className="pRADec">
                                    Out of 3.711 global ratings with top
                                    features such as thickness, easy assembly.
                                    Good sleep quality and sturdinees
                                  </Typography>
                                </Col>
                              </Row>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row justify="start" gutter={[0,{xs:15, sm:15, md:16, lg:16, xl:16, xxl:16}]}>
                        <Col span={24}></Col>
                        <Col span={24} className="ratingParent">
                          <Card style={{ backgroundColor: "#F7F7F7" }}>
                            <Row>
                              <Col>
                                <Typography className="bMRCardTitle">
                                  Very good quality
                                </Typography>
                              </Col>
                            </Row>
                            <Row gutter={[0, 10]}>
                              <Col span={24}></Col>
                              <Col>
                                <Typography className="bMRCardDec">
                                  The coco stylewood lounger contains the wooden
                                  frame, seat pad + 5 point safety harness.
                                  curved, single-piece baltic birch wood frame
                                  with plush, cotton baby-facing seat pad with
                                  polyester rear lining.
                                </Typography>
                              </Col>
                            </Row>
                            <Row gutter={[0, 16]} align="middle" justify="space-between">
                              <Col span={24}></Col>
                              <Col>
                                <Space direction="horizontal">
                                  <LazyLoadImage
                                    effect="blur"
                                    size={(57, 62)}
                                    src={SellerImgOne}
                                  />
                                  <Row gutter={[0, 8]}>
                                    <Col span={24}></Col>
                                    <Col>
                                      <Typography className="reviewedSellerName">
                                      &nbsp;John Carter
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Space>
                              </Col>
                              <Col>
                                <Rate
                                  defaultValue={4}
                                  className="smallStart"
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row justify="start" gutter={[0, 16]}>
                        <Col span={24}></Col>
                        <Col span={24} className="ratingParent">
                          <Card style={{ backgroundColor: "#F7F7F7" }}>
                            <Row>
                              <Col>
                                <Typography className="bMRCardTitle">
                                  Breathable and nice looking
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Typography className="bMRCardDec">
                                  The coco stylewood lounger contains the wooden
                                  frame, seat pad + 5 point safety harness.
                                  curved, single-piece baltic birch wood frame
                                  with plush
                                </Typography>
                              </Col>
                            </Row>
                            <Row gutter={[0, 16]} align="middle" justify="space-between">
                              <Col span={24}></Col>
                              <Col>
                                <Space direction="horizontal">
                                  <LazyLoadImage
                                    effect="blur"
                                    size={(57, 62)}
                                    src={SellerImgTwo}
                                  />
                                  <Row gutter={[0, 8]}>
                                    <Col span={24}></Col>
                                    <Col>
                                      <Typography className="reviewedSellerName">
                                        &nbsp;Amber Frushon
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Space>
                              </Col>
                              <Col>
                                <Rate
                                  defaultValue={5}
                                  className="smallStart"
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row gutter={[0, {xs:15, sm:15, md:21, lg:21, xl:21, xxl:21}]} justify="end">
                        <Col span={24}></Col>
                        <Col>
                          <Button type="text" className="viewMoreButton">
                            View more
                          </Button>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              </Row> */}

              {/* <Divider style={{ color: "#707070", margin: isXS ? "20px 0px": "40px 0px" }} />
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Collapse
                    style={{ backgroundColor: "white" }}
                    defaultActiveKey={["0"]}
                    onChange={callback}
                    expandIconPosition="end"
                    bordered={false}
                    ghost
                    expandIcon={({ isActive }) => {
                      return !isActive ? (
                        <DownOutlined className="collapsIcon" />
                      ) : (
                        <UpOutlined className="collapsIcon" />
                      );
                    }}
                  >
                    <Panel
                      header={
                        // <Row>
                          <Text className="pLPanelTitle">Disscusion</Text>
                        // </Row>
                      }
                      key="1"
                    >
                      <Row align="middle" gutter={[0,25]}> 
                      <Col span={24}/>
                        <Col span={24}>
                        <TextArea
                          // value={value}
                          // onChange={this.onChange}
                          className="discussionTextArea"
                          placeholder={"Start a discussion"}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                        </Col>
                        <Col span={24}/>
                      </Row>
                      <Row justify="space-between" >
                        <Col>
                          <Row gutter={[15, 0]}>
                            <Col>
                              <Avatar
                                shape="square"
                                size={(57, 62)}
                                src={SellerImgOne}
                              />
                            </Col>
                            <Col>
                              <Typography className="pLDes">
                                Would love to buy this for my newborn?
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                        <Row gutter={[{xs:12, sm:12, md:15, lg:15, xl:15, xxl:15},0]} align="middle">
                          <Col>
                          <img alt="" src={RemoveIcon} />
                          </Col>
                          <Col>
                          <Divider type="vertical" style={{ height:25, background:'#A5A5A5', opacity:0.3}} />
                          </Col>
                          <Col>
                          <Button type="text" className="viewMoreButton">
                            Reply
                          </Button>
                          </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider style={{color:'#707070', margin:isXS ? "22px 0px 25px":"21px 0px"}} />
                      <Row justify="space-between">
                        <Col>
                          <Row gutter={[15, 0]}>
                            <Col>
                              <Avatar
                                shape="square"
                                size={(57, 62)}
                                src={SellerImgOne}
                              />
                            </Col>
                            <Col>
                              <Typography className="pLDes">
                                Can I use this for my 2 yeras old?
                              </Typography>
                              <Row>
                                <Typography className="pLDes">
                                  Yes, You can.
                                </Typography>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                          <Col>
                          <Row gutter={[{xs:12, sm:12, md:15, lg:15, xl:15, xxl:15},0]} align="middle">
                          <Col>
                          <img alt="" src={RemoveIcon} />
                          </Col>
                          <Col>
                          <Divider type="vertical" style={{ height:25, background:'#A5A5A5', opacity:0.3}} />
                          </Col>
                          <Col>
                          <Button type="text" className="viewMoreButton">
                            Reply
                          </Button>
                          </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider style={{color:'#707070', margin:isXS ? "22px 0px 25px":"21px 0px"}} />
                      <Row justify="space-between">
                        <Col>
                          <Row gutter={[15, 0]}>
                            <Col>
                              <Avatar
                                shape="square"
                                size={(57, 62)}
                                src={SellerImgOne}
                              />
                            </Col>
                            <Col>
                              <Typography className="pLDes">
                                Duis aute irure dolor in reprehenderit in
                                voluptate velit esse
                                <Row>
                                  <Typography className="pLDes">
                                    cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat.
                                  </Typography>
                                </Row>
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row gutter={[{xs:12, sm:12, md:15, lg:15, xl:15, xxl:15},0]} align="middle">
                          <Col>
                          <img alt="" src={RemoveIcon} />
                          </Col>
                          <Col>
                          <Divider type="vertical" style={{ height:25, background:'#A5A5A5', opacity:0.3}} />
                          </Col>
                          <Col>
                          <Button type="text" className="viewMoreButton">
                            Reply
                          </Button>
                          </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
              <Divider style={{color:'#707070', margin:isXS ? "20px 0px 26px":"40px 0px 30px"}} /> */}
              {/* </Card> */}
            </Col>

            <Col xl={{ span: 8, offset: 0 }}>
              {isRenewed && (
                <div className="reNewedInfoweb">
                  <Row gutter={[0, 0]}>
                    <Col span={24}>
                      <Card className="rIBackground">
                        <Row>
                          <Col>
                            <Typography className="rIText">
                              reNEWED Information
                            </Typography>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="rIDesBackground">
                        <Row justify="start">
                          <Col>
                            <Typography className="riDes">
                              The simplest way to sell kid's gently used,
                              high-quality furniture and gear-effortlessly,
                              sustainably and purposefully.
                            </Typography>
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                          <Col span={24}></Col>
                          <Col span={24}>
                            <Button
                              block
                              className={"button button-bordered pLMakeOffer"}
                            >
                              KNOW MORE
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>

                  {/* <Row gutter={[0,30]} justify='center'>
          <Col span={24}></Col>
          <Col span={24}>
          <Card className='rIBackground'>
          <Row>
                <Col>
                  <Typography className="rIText">
                  Need help?
                  </Typography>
                </Col>
              </Row>
              </Card>
            <Card className="rIDesBackground">
              <Row justify="start">
                <Col>
                  <Typography className="riDes">
                  Request a call, chat and more
                  </Typography>
                </Col>
              </Row>
              <Row gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Button
                    block
                    href={process.env.REACT_APP_STATIC_DOMAIN+'contact'}
                    target="_"
                    className={"pLMakeOffer"}
                  >
                  Contact us
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
                </div>
              )}
            </Col>
            <Col span={24}></Col>
          </Row>
        </div>
      </div>
      <div className="">
        <Spin
          style={{ top: 200, color: "#FF533F" }}
          indicator={antIcon}
          spinning={isMoreFromThisSellerLoading}
        >
          <Row
            gutter={[
              0,
              { xs: 70, sm: 70, md: 120, lg: 120, xl: 120, xxl: 120 },
            ]}
            justify="center"
          >
            <Col span={24}></Col>
          </Row>
        </Spin>
        <Spin
          style={{ top: 200, color: "#FF533F" }}
          indicator={antIcon}
          spinning={isSimilarItemsLoading}
        >
          <Row
            gutter={[
              0,
              { xs: 70, sm: 70, md: 120, lg: 120, xl: 120, xxl: 120 },
            ]}
            justify="center"
          >
            <Col span={24}></Col>
          </Row>
{isActive===true&&  (<div className="commonShowcase ProductsFromThisSeller">
            <div className="app-wrapper">
              <Typography className="sectionTitles">
                More From This Seller{" "}
                <a onClick={() => {
                  gaEventClick("PDP", "More from seller", "See All");
                  history.push(`/seller-profile/${sellerId}`)
                }}>
                  see all
                </a>
              </Typography>
              <Row className="container">
                {moreLots &&
                  moreLots.map((val, i) => {
                    if (i <= 4) {
                      return (
                        <Col
                          xl={4}
                          lg={{ span: 4 }}
                          md={24}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <MoreItemsCard moreItems={val} />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </div>
          </div>)}
          
          {isActive===true &&( <div className="commonShowcase ProductsSimilar">
            <div className="app-wrapper">
              <Typography className="sectionTitles">
                Similar items{" "}
                <a
                  onClick={() => {
                    gaEventClick("PDP", "Similar items", "See All");
                    history.push(`/simillar-product/${productId}`);
                  }}
                >
                  see all
                </a>
              </Typography>
              <Row className="container">
                {similarLots &&
                  similarLots.map((val, i) => {
                    if (i <= 4) {
                      return (
                        <Col
                          xl={4}
                          lg={{ span: 4 }}
                          md={24}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <PLSimilarCard similarItems={val} />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </div>
          </div>)}
         
        </Spin>
      </div>
      <MarkNewsLetter />
      {/* <Row gutter={[0, {xs:70, sm:70, md:120, lg:120, xl:120, xxl:120}]}>
        <Col span={24}/>
        <Col span={24}>
          <div className="recommendedCard">
            <Row gutter={[0, {xs:30, sm:30, md:70, lg:70, xl:70, xxl:70}]} justify="center">
              <Col span={24}></Col>
              <Col>
                <Typography className="pLSHeading">
                  Recommended for you
                </Typography>
              </Col>
              <Col>
                <img alt="" src={HeadingHearts} className="rFYHeadingHearts" />
              </Col>
            </Row>
            <Row
              gutter={[0, { xs: 15, sm: 15, md: 50, lg: 50, xl:50,xxl:50 }]}
              justify="center"
            >
              <Col span={24}></Col>
              <Col xl={20} lg={20} md={24} sm={22} xs={22}>
                <Row gutter={[15, 0]} justify="center">
                  <Carousel
                    itemsToShow={4}
                    itemsToScroll={4}
                    breakPoints={breakPoints}
                    renderArrow={myArrow}
                    renderPagination={myDots}
                    showArrows={isXS ? false : true}
                  >
                    {PlSimilarCardData && PlSimilarCardData.map((val, i) => {
                      return (
                        <Col span={24} key={i}>
                          <RecommendedCard
                            cover={val.pLImage}
                            itemName={val.pLName}
                            price={val.pLPrice}
                            shipping={val.pLShipping}
                            image={val.pLImage}
                          />
                        </Col>
                      );
                    })}
                  </Carousel>
                </Row>
              </Col>
            </Row>
            <Row justify="center" gutter={[0, {xs:36, sm:36, md:88, lg:88, xl:88, xxl:88}]}>
              <Col span={24}></Col>
              <Col>
                <Button className="cSeeAllButton">See all</Button>
              </Col>
              <Col span={24}></Col>
              <Col span={24}></Col>
            </Row>
          </div>
          <img
            alt=""
            src={WMBgshape}
            style={{ width: "100%", marginTop: "-17%" }}
          />
        </Col>
      </Row> */}

      {/* <Row justify="center" gutter={[0, 60]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col>
          <Typography className="pLSHeading">
            How does reNEWED work?
          </Typography>
        </Col>
      </Row>
     <Row>
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Row gutter={[0, 48]} justify='space-around'>
            <Col span={24}></Col>
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={SubmitItemImg}/>}
            title="1 - Submit your item"
            description="Submit a description of your item and a member of our team will
          review and confirm your submission within one bussiness day. (We 
          only accept select items that meet our criteria for gently used,
          high-quality, high-demand kids gear)"
          />
        </Col>
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={ScheduleImg}/>}
            title="2 - Schedule pick-up"
            description="We'll handle the heavy lifting,just pick a convenient time and meet us 
          at the door. We'll pick up your item and deliver it to the buyer at no charge."
          />
        </Col>
      
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={RestImg}/>}
            title="3 - Let us do the rest"
            description="Our team of experts will professionally inspect,clean,sanitize, and 
          restore the gear to bring it to a lovingly-renewed state."
          />
        </Col>
        <Col xl={10} lg={10} md={11} sm={22} xs={22}>
          <ReNewedWorkCard
            cover={<img src={PaidImg}/>}
            title="4 - Get paid"
            description="Sit back and relax. We photograph,list,promote,and sell your item so
          that you can get the best price for it. When your item sells,we pay 
          you based on the final sale price,up to 80%"
          />
        </Col>
      </Row>
      </Col>
      </Row> */}
      {/* <MainHomePageFooter /> */}
      <NotifySoon
        onCancelNotifySoonMethod={() => setShowNotifySoonDialog(false)}
        showNotifySoonDialog={showNotifySoonDialog}
        notifyData={lot}
      />
      <MessageSeller
        onCancelMessageSellerMethod={() => setShowMessageSellerDialog(false)}
        showMessageSellerDialog={showMessageSellerDialog}
        lotDetail={lot}
      />
      <SignIn
        onCancelSignInMethod={() => setShowSignInDialog(false)}
        showSignInDialog={showSignInDialog}
      />
    </div>
  );
};
export default ProductListDetail;
