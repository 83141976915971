import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Row, Col, Button, Input, Spin } from "antd";
import {
  LoadingOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../App.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Logo from "../assets/logo.svg";
import ShippingLogo from "../assets/Slice.svg";
import OBIllustration from "../assets/OBIllustration.svg";
import LocationPinIcon from "../assets/LocationPinIcon.svg";
import { addZipcode } from "../Actions/userAction";
import _ from "lodash";
export const ZipCodeForm = (props) => {
  const { current, setCurrent } = props
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const {
    isUpdateUserLoading,
  } = useSelector((state) => {
    const { isUpdateUserLoading } = state.user;
    return {
      isUpdateUserLoading,
    };
  });
  const [zipCode, setZipCode] = useState("");
  const dispatch = useDispatch();
  const { innerHeight } = window;
  const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const schema = yup.object().shape({
    zip: yup.string().matches(isValidZip, 'Valid Zipcode is required.').required(),
  });

  const { handleSubmit, control, watch, errors, setValue, formState, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { zip: "" },
  });
  // const handleVisibleChange = (flag) => {
  //   setVisibleInput(flag);
  // };

  // useEffect(() => {
  //   handleCurrentLocation()
  //   // dispatch(getSearchLot());
  // }, []);
  const { zip } = watch();
  const { isValid } = formState;
  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(handleGeoLocationSuccess, handleGeoLocationFaliure, {});
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(handleGeoLocationSuccess, handleGeoLocationFaliure, {});
          } else if (result.state === "denied") {
            setZipCode("")
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
          };
        });
    } else {
      alert("Sorry Not available!");
    }
  };
  const handleGeoLocationSuccess = async (s) => {
    const { coords } = s;
    const { latitude, longitude } = coords;
    const zipCode = await getZipcode({ latitude, longitude })
    // setZipCode(zipCode)
    setValue('zip', zipCode)
    clearErrors("zip")
  }

  const getZipcode = async ({ latitude, longitude }) => {
    const apiKey = "AIzaSyC5n11G2cpe5WL1PN6l2RocIkUSNTofCe8"
    let zipCode = 10100;
    const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    const getResponse = await fetch(googleUrl, { method: 'GET', redirect: 'follow', 'Content-Type': 'application/json', }).then((response) => response.json()).then((data) => {
      if (data.results && data.results.length > 0) {
        const pcs = _.filter(data.results, { types: ["postal_code"] })
        const addressCompnents = _.forEach(pcs, function (value) {
          const { address_components } = value;
          const codes = _.filter(address_components, { types: ["postal_code"] })
          if (codes && codes.length > 0) {
            zipCode = codes[0].long_name
          }
        })
      }

    }).catch((err) => err);

    return zipCode;
  }
  const handleGeoLocationFaliure = (e) => {
    let zipCode = '10100';
    setValue('zip', zipCode)

  }
  const handleUpdateUserZipcode = (data) => {
    dispatch(
      addZipcode({ zipCode: data.zip }, successCallBackZipcode, failureCallBackZipcode)
    );
  };
  const successCallBackZipcode = () => {
    setCurrent(current + 1);
  };
  const failureCallBackZipcode = () => { };
  return (
    <div className="zipcode-wrap"
      style={{ overflow: 'hidden', overflowY: 'scroll', height: innerHeight }}
    >
      <Spin indicator={antIcon} spinning={isUpdateUserLoading}>
        <div className="sendOtpParent">
          <Card bordered={false}>
            <form onSubmit={handleSubmit(handleUpdateUserZipcode)}>
              <div className='onboardingWeb'>
                <div className="closeicon-wrap">
                  <CloseOutlined className='oBCloseIcon' />
                </div>
                <Row gutter={[0, 0]}>
                  <Col span={24}></Col>
                  <Col>
                    {/* <Link to="/homepage"> */}
                    <Link to="/">
                      <img alt="logo" src={Logo} className="logoStyle weblogo" />
                    </Link>
                  </Col>
                </Row>
              </div>
              <div className='onboardingMob'>
                <div className="closeicon-wrap">
                  <CloseOutlined className='oBCloseIcon' />
                </div>
                <Row gutter={[0, 0]} justify='space-between'>
                  <Col span={24}></Col>
                  <Col>
                    {/* <Link to="/homepage"> */}
                    <Link to="/">
                      <img alt="logo" src={ShippingLogo} className='markidLogoMob' />
                    </Link>
                  </Col>
                  {/* <Col>
                    <CloseOutlined className='oBCloseIcon' />
                  </Col> */}
                </Row>
              </div>
              <Row justify='center' gutter={[0, { xs: 22.12, sm: 22.12, md: 15, lg: 32.5, xl: 32.5, xxl: 32.5 }]}>
                <Col span={24}></Col>
                {/* <Col span={24}></Col> */}
                <Col>
                  <Typography className='createAccountText  text-center'>
                    Enter your zipcode
                  </Typography>
                  <div className="heading-desc">
                    Find items around you. Arrange for a local meet up or have it shipped!
                  </div>

                </Col>
              </Row>

              {/* <Row gutter={[0, { xs: 2, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }]} justify='center'>
                <Col span={24}></Col>
                <Col sm={22} xs={22} lg={16}>
                  <Typography className='alreadyAccountText sub-titles text-center'>
                   
                  </Typography>
                </Col>
              </Row> */}

              <Row gutter={[0, { xs: 27, sm: 27, md: 31, lg: 31, xl: 31, xxl: 31 }]} justify='center'>
                <Col span={24}></Col>
                <Col>
                  <img alt="" src={OBIllustration} />
                </Col>
              </Row>

              <Row gutter={[0, { xs: 66, sm: 66, md: 65, lg: 65, xl: 65, xxl: 65 }]} justify='center'>
                <Col span={24}></Col>
                {/* <Col span={24}></Col> */}

                <Col>
                  <Typography className='enterMobNumText'>
                    Zipcode
                  </Typography>
                </Col>
              </Row>

              <Row justify="center" gutter={[0, 10]}>
                <Col span={24}></Col>
                <Col xl={23} lg={23} md={23} sm={22} xs={22} className="text-center">
                  <Controller
                    as={<Input bordered={false} name="zip" className="phoneInput" size='large' />}
                    name="zip"
                    control={control}
                    placeholder="Enter zipcode"
                  />

                  {errors.zip && zip.length === 0 && (
                    <p style={{ color: "red" }}>
                      Zipcode is required
                    </p>
                  )}
                  {formState.isSubmitted && errors.zip && errors.zip?.message && (
                    <p style={{ color: "red" }}>
                      {errors.zip.message}
                    </p>
                  )}
                </Col>
              </Row>

              <Row justify='center' gutter={[9, { xs: 8, sm: 8, md: 10, lg: 10, xl: 10, xxl: 10 }]}>
                <Col span={24}></Col>
                <Button type='text' icon={<img alt="" src={LocationPinIcon} style={{ marginRight: 9.57}} />} onClick={() => handleCurrentLocation()} className='loctionText'>
                  Get current location
                  {/* <Col>
              <Row gutter={[0,3]}>
                  <Col span={24}></Col>
                  <img alt="" src={LocationPinIcon} />
              </Row>
              </Col>
              <Col>
              <Typography className='loctionText'>
              Get current location
              </Typography>
              </Col> */}
                </Button>
              </Row>
              <Row justify="center" gutter={[0, { xs: 22, sm: 22, md: 10, lg: 26, xl: 26, xxl: 26 }]}>
                <Col span={24}></Col>
                <Col>
                  <Button htmlType='submit' className={isValid ? "oBenabledSignupButton" : "oBdisabledSignupButton"}>
                    Continue
                  </Button>
                </Col>
                <Col span={24}></Col>
              </Row>
            </form>
          </Card>
        </div>
      </Spin>
    </div>
  );
};
