import API from '../Utils/API';

export const getChatsByLot = (data) => dispatch => {
  dispatch({ type: "GET_CHATS_BY_LOT_ID_REQUEST" })
  API.get(`/api/v2/chats/getChat/${data}`)
    .then(response => {
      dispatch({ type: "GET_CHATS_BY_LOT_ID_SUCCESS", payload: response.data })
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CHATS_BY_LOT_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const getChats = () => dispatch => {
  dispatch({ type: "GET_CHATS_REQUEST" })
  API.get(`/api/v2/chats`)
    .then(response => {
      dispatch({ type: "GET_CHATS_SUCCESS", payload: response.data })
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CHATS_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const getChatById = (chatId) => dispatch => {
  dispatch({ type: "GET_CHAT_BY_ID_REQUEST" })
  API.get(`/api/v2/chats/${chatId}`)
    .then(response => {
      dispatch({ type: "GET_CHAT_BY_ID_SUCCESS", payload: response.data })
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CHAT_BY_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const getMessagesByChatId = (chatId) => dispatch => {
  dispatch({ type: "GET_MESSAGES_BY_CHAT_ID_REQUEST" })
  API.get(`/api/v2/chats/${chatId}/messages`)
    .then(response => {
      dispatch({ type: "GET_MESSAGES_BY_CHAT_ID_SUCCESS", payload: response.data })
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_MESSAGES_BY_CHAT_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const getChatsWithSeller = (lotId, sellerId, messageSellerSuccess, messageSellerFailure) => dispatch => {
  dispatch({ type: "GET_CHATS_WITH_SELLER_REQUEST" })
  API.get(`/api/v2/chats/getChatWithSeller?lotId=${lotId}&sellerId=${sellerId}`)
    .then(response => {
      dispatch({ type: "GET_CHATS_WITH_SELLER_SUCCESS", payload: response.data })
      getChats()
      messageSellerSuccess()
    })
    .catch((error) => {
      try {
        dispatch({
          type: "GET_CHATS_WITH_SELLER_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        messageSellerFailure()
      } catch {
        // console.log(error);
      }
    });
}

export const postMessageByChatId = (chatId, data) => dispatch => {
  dispatch({ type: "POST_MESSAGE_BY_CHAT_ID_REQUEST" })
  API.post(`/api/v2/chats/${chatId}`, data)
    .then(response => {
      dispatch({ type: "POST_MESSAGE_BY_CHAT_ID_SUCCESS", payload: response.data })
      dispatch(getMessagesByChatId(chatId))
    })
    .catch((error) => {
      try {
        dispatch({
          type: "POST_MESSAGE_BY_CHAT_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const postMarkAsSeenByChatId = (chatId) => dispatch => {
  dispatch({ type: "POST_MARK_AS_SEEN_BY_CHAT_ID_REQUEST" })
  API.post(`/api/v2/chats/${chatId}/markAsSeen`)
    .then(response => {
      dispatch({ type: "POST_MARK_AS_SEEN_BY_CHAT_ID_SUCCESS", payload: response.data })
      dispatch(getMessagesByChatId(chatId))
    })
    .catch((error) => {
      try {
        dispatch({
          type: "POST_MARK_AS_SEEN_BY_CHAT_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const postMarkAsRatedByChatId = (chatId) => dispatch => {
  dispatch({ type: "POST_MARK_AS_RATED_BY_CHAT_ID_REQUEST" })
  API.post(`/api/v2/chats/${chatId}/mark-as-rated`)
    .then(response => {
      dispatch({ type: "POST_MARK_AS_RATED_BY_CHAT_ID_SUCCESS", payload: response.data })
    })
    .catch((error) => {
      try {
        dispatch({
          type: "POST_MARK_AS_RATED_BY_CHAT_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
      } catch {
        // console.log(error);
      }
    });
}

export const deleteChatById = (chatId, successDeleteChat, failiureDeleteChat) => dispatch => {
  dispatch({ type: "DELETE_CHAT_BY_ID_REQUEST" })
  API.post(`/api/v2/chats/${chatId}/remove`)
    .then(response => {
      dispatch({ type: "DELETE_CHAT_BY_ID_SUCCESS" })
      successDeleteChat()
    })
    .catch((error) => {
      try {
        dispatch({
          type: "DELETE_CHAT_BY_ID_FAILURE",
          payload: error.response.data,
        });
        setTimeout(() => dispatch({ type: "CLEAR ERROR STATE" }), 3000);
        failiureDeleteChat()
      } catch {
        // console.log(error);
      }
    });
}