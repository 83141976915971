/*eslint-disable*/
import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Avatar,
  Divider,
  Row, Col,
} from "antd";
import File1 from "../assets/File1.JPG";
import File2 from "../assets/File2.JPG";
import CartItemList from "./CartItemList";
import SellerImg from "../assets/SellerImg.svg";
import { getPartnerProductsShippingPrice } from "../Actions/partnerProductShippingPriceAction";
import { availPromotions } from "../Actions/cartAction";
const { Text, Title, Paragraph } = Typography;
const ShoppingCart = () => {
  const dispatch = useDispatch();

  const { currentCart } = useSelector((state) => {
    const { currentCart } = state.cart;
    return {
      currentCart,
    };
  });

  let group = currentCart.reduce((r, a) => { r[a.createdBy] = [...r[a.createdBy] || [], a]; return r; }, {});

  useEffect(() =>{
    dispatch(availPromotions())
  },[])

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

const grouped = groupBy(currentCart, currentCart => currentCart.shippingPrice);

let group2 = grouped.get("0") ? grouped.get("0").reduce((r, a) => { r[a.createdBy] = [...r[a.createdBy]  || [], a._id]; return r; }, {}) : '';
let groupProducts = []
Object.keys(group2).forEach(element => {
  var item = {
    partnerId: element,
    products: group2[element]
  }
  groupProducts.push(item)
})

useEffect(()=>{
  if(groupProducts && currentCart){
    dispatch(getPartnerProductsShippingPrice(groupProducts));
  }
},[currentCart.length])

  // const ItemDescription = [
  //   {
  //     closeIcon: <CloseOutlined />,
  //     itemImg:   <Avatar shape="square" size={140} src={File1} />,
  //     itemName: "Bloom Coco Lounger",
  //     itemShippingPrice: "$150 + $13.42 shipping",
  //     shippingPrice: "$13.42",
  //     totalPrice: "$163.42",
  //     mode: "Shipping",
  //   },
  //   {
  //     closeIcon: <CloseOutlined />,
  //     itemImg:   <Avatar shape="square" size={140} src={File2} />,
  //     itemName: "Gymboree Babygirl Dress",
  //     itemShippingPrice: "$12 + $2.19 shipping",
  //     shippingPrice: "$2.19",
  //     totalPrice: "$14.19",
  //     mode: "Shipping",
  //   },
  // ]
  return (
    <>
      {/* {currentCart && currentCart.map((val, i) => (
      <CartItemList currentCart={val} key={i} />
      ))} */}
      {currentCart && group && Object.keys(group).map(cat => (
        <ul className="cartItemsList">
          {group[cat].map((ord, i) => (
            <li className="cartItemWrapper">
            <CartItemList currentCart={ord} key={i} />
            </li>
          ))}
        </ul>
      ))}
    </>
  );
};
export default ShoppingCart;
